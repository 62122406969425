import { CustomComponentRepository } from '../repos';
import { CustomComponent } from '../types';

export class CustomComponentServ {
  async getCustomComponents(appId: string) {
    return await CustomComponentRepository.getCustomComponents(appId);
  }

  async getCustomComponent(
    customComponentId: string,
    moduleId: string,
    IsTemplate: boolean
  ): Promise<CustomComponent> {
    return await CustomComponentRepository.getCustomComponent(
      customComponentId,
      moduleId,
      IsTemplate
    );
  }

  async updateCustomComponent(customComponentId: string, updatedCustomComponent: CustomComponent) {
    return await CustomComponentRepository.updateCustomComponent(
      customComponentId,
      updatedCustomComponent
    );
  }

  async deleteCustomComponent(customComponentId: string) {
    return await CustomComponentRepository.deleteCustomComponent(customComponentId);
  }

  async createEmptyCustomComponent(customComponent: any, appId: string) {
    return await CustomComponentRepository.createEmptyCustomComponent(customComponent, appId);
  }
}
