import { AppLanguages, AppTranslation } from '../types';

import { AppLanguagesSchema } from './schemas/LanguageComponent';
import { AppTranslationsResponseSchema } from './schemas/AppTranslationsResponseSchema';

export const API_URL = process.env.REACT_APP_API_URL;

export class AppLanguagesRepo {
  private buildSchemaLanguages(languageComponent: AppLanguages) {
    const languageComponentSchema: AppLanguagesSchema = {
      locale: languageComponent.locale,
      name: languageComponent.name
    };
    return languageComponentSchema;
  }

  async createLanguage(uuid: string, appLanguage: AppLanguages) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const appLanguagesSchema: AppLanguagesSchema = this.buildSchemaLanguages(appLanguage);

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(appLanguagesSchema)
    };

    const url = `${API_URL}/apps/${uuid}/languages`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<AppLanguages>;
      else if (response.status === 409) return null;
      throw new Error('Something went wrong while trying to fetch app languages.');
    });
  }

  async getLanguages(uuid: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${uuid}/languages`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<AppLanguages[]>;
      throw new Error('Something went wrong while trying to fetch app languages.');
    });
  }

  async updateLanguages(uuid: string, appLanguage: AppLanguages) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const appLanguagesSchema: AppLanguagesSchema = this.buildSchemaLanguages(appLanguage);

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(appLanguagesSchema)
    };

    const url = `${API_URL}/apps/${uuid}/languages`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<AppLanguages[]>;
      throw new Error('Something went wrong while trying to fetch app languages.');
    });
  }

  async deleteLanguages(uuid: string, appLanguage: AppLanguages) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const appLanguagesSchema: AppLanguagesSchema = this.buildSchemaLanguages(appLanguage);

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(appLanguagesSchema)
    };

    const url = `${API_URL}/apps/${uuid}/languages`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<void>;
      throw new Error('Something went wrong while trying to fetch app languages.');
    });
  }

  async createTranslation(uuid: string, id: string, appLanguage: AppTranslation) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(appLanguage)
    };

    const url = `${API_URL}/apps/${uuid}/languages/${id}/translates`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json();
      if (response.status === 409) throw new Error('This translation key already exists');
      throw new Error('Something went wrong while trying to fetch app languages.');
    });
  }

  async getTranslation(uuid: string, id: string, page: number) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${uuid}/languages/${id}/translates?page=${page}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<AppTranslationsResponseSchema>;
      throw new Error('Something went wrong while trying to fetch app languages.');
    });
  }
}
