import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableUUID, columnID, Column } from 'modules/modeler/types';
import { DatabaseStudioState } from 'modules/modeler/studio/store';
import styles from './styles.module.css';
import ActionsBar from '../../shared/actions_bar';
import ColumnsList from './components/columns_list';
import ColumnEditor from './components/column_editor';
import { makeColumn } from '../../../../../../../routes/studio/data/elements/factory';
import { addColumn } from '../../../../store/actions/root';

type TableFieldsProps = {
  tableID: TableUUID;
};

/**
 * fields tab, used to manage columns data
 * ex: (name, type, nullable, default)
 *
 *  * @component
 */
function TableFields({ tableID }: TableFieldsProps) {
  // context tables array
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  // context columns array
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  // used to manage the selected column
  const [selectedColumn, setSelectedColumn] = useState(tables[tableID].content.data.columns[0]);
  // used to filter the column array by name
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  function createColumnAndAddToContext() {
    const column = makeNewColumn();
    if (column) addCreatedColumnToContext(column);
    setSelectedColumn(column?.uuid ?? '');
  }

  function makeNewColumn() {
    let name = `column_${tables[tableID].content.data.columns.length}`;
    if (checkExistingName(name)) name += '_1';

    const column: Column = Object.assign(
      makeColumn(
        tableID,
        name,
        'VARCHAR',
        0,
        '',
        false,
        undefined,
        false,
        false,
        undefined,
        undefined,
        undefined
      )
    );
    return column;
  }

  const checkExistingName = (name: string) => {
    for (const col of Object.values(columns)) {
      if (col.tableUUID === tableID && name === col.name) {
        return true;
      }
    }
    return false;
  };

  function addCreatedColumnToContext(column: Column) {
    const columnOrder = getLastColumnOrder();
    dispatch(
      addColumn(
        column.uuid,
        column.tableUUID,
        column.name,
        columnOrder,
        column.type,
        column.nullable,
        column.defaultData,
        column.description,
        column?.enumUUID,
        column.properties
      )
    );
  }

  function getLastColumnOrder(): number {
    const tableColumns = tables[tableID].content.data.columns;
    return tableColumns.length;
  }

  function getTableColumns() {
    const tableColumns: Column[] = [];
    tables[tableID].content.data.columns
      .filter((columnID: columnID) => !!columns[columnID])
      .forEach((columnID: columnID) => {
        if (searchText && !columns[columnID].name.toLowerCase().includes(searchText)) return;
        tableColumns.push(columns[columnID]);
      });
    return tableColumns;
  }

  return (
    <div id={'columnEditorWrapper'} className={styles.ColumnEditorWrapper}>
      <ActionsBar
        addNew={createColumnAndAddToContext}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div className={styles.ViewAndPropertyWrapper}>
        <ColumnsList
          tableColumns={getTableColumns()}
          selectedColumnID={selectedColumn}
          selectColumn={setSelectedColumn}
        />
        <ColumnEditor selectedColumnID={selectedColumn} />
      </div>
    </div>
  );
}

export default TableFields;
