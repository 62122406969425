import React, { useState } from 'react';
import { ProjectsService } from 'modules/project/services';
import { Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApiAppInfo, AppVersionInfo } from '../../types';
import styles from './styles.module.css';

export type CreateVersionModalProps = {
  /** Source App Info **/
  appInfo: ApiAppInfo;
  /** Modal visibility **/
  show: boolean;
  /** Callback on modal closing **/
  onClose: () => void;
};

/**
 * Create Module Modal.
 *
 * @component
 */
export default function CreateVersionModal(props: CreateVersionModalProps) {
  const [versionInfo, setVersionInfo] = useState({
    name: props.appInfo.name,
    description: props.appInfo.description,
    version: ''
  } as AppVersionInfo);

  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleModalVersion() {
    ProjectsService.createAppVersion(props.appInfo.id, versionInfo)
      .then((value) => navigate(`/app/${value.id}/dashboard/modules`))
      .catch(() => alert(t('appResume.CreateVersionConflict')));
    handleCloseModalVersion();
  }

  function handleCloseModalVersion() {
    setVersionInfo({
      name: props.appInfo.name,
      description: props.appInfo.description,
      version: ''
    } as AppVersionInfo);
    props.onClose();
  }

  return (
    <Modal centered show={props.show} onHide={props.onClose} className={styles.newVersionDialog}>
      <Modal.Header closeButton>
        <Modal.Title>{t('appResume.CreateVersion')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>{t('Name')}</Form.Label>
          <Form.Control
            type="text"
            id="formName"
            value={versionInfo.name}
            defaultValue={props.appInfo.name}
            onChange={(e) => {
              setVersionInfo({
                ...versionInfo,
                name: e.target.value
              });
            }}
            readOnly={false}
            maxLength={64}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('Description')}</Form.Label>
          <Form.Control
            type="text"
            id="formDescription"
            value={versionInfo.description}
            defaultValue={props.appInfo.description}
            onChange={(e) => {
              setVersionInfo({
                ...versionInfo,
                description: e.target.value
              });
            }}
            readOnly={false}
            maxLength={255}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('Version')}</Form.Label>
          <Form.Control
            type="text"
            id="formVersion"
            value={versionInfo.version}
            isInvalid={versionInfo.version.length === 0}
            onChange={(e) => {
              setVersionInfo({
                ...versionInfo,
                version: e.target.value
              });
            }}
            maxLength={64}
            required
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button id="cancelButton" variant="secondary" onClick={handleCloseModalVersion}>
          {t('Cancel')}
        </Button>
        <Button
          id="saveButton"
          variant="success"
          onClick={handleModalVersion}
          disabled={versionInfo.version.length === 0}
        >
          {t('appResume.Create')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
