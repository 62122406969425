import * as React from 'react';
import { FunctionActionManifest } from 'web_ui/function_editor/store/types/manifestsAndInputs';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { t } from 'i18next';

const AuthenticationLogoutIcon: React.ReactNode = (
  <span style={{ whiteSpace: 'nowrap' }}>
    <i
      className="fa-solid fa-right-from-bracket text-secondary-emphasis"
      style={{ fontSize: '1.4rem' }}
    />
  </span>
);

export const AUTHENTICATION_LOGOUT_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.AUTHENTICATION_LOGOUT,
  name: t('Logout'),
  description: 'User authentication logout.',
  color: '',
  category: FRONTEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.FUNCTION,
  icon: AuthenticationLogoutIcon,
  inlineInputs: [],
  inputs: []
};
