import { Action } from 'redux';
import { VariablesState } from '../index';
import { FrontendVariable, VariableTypes } from 'modules/designer/types';

export const SET_VARIABLES = 'SET_VARIABLES';
export const ADD_VARIABLE = 'ADD_VARIABLE';
export const UPDATE_VARIABLE = 'UPDATE_VARIABLE';
export const CHANGE_VARIABLE_TYPE = 'CHANGE_VARIABLE_TYPE';
export const DELETE_VARIABLE = 'DELETE_VARIABLE';

export interface SetVariablesAction extends Action {
  type: 'SET_VARIABLES';
  payload: {
    variables: VariablesState;
  };
}

export interface AddVariableAction extends Action {
  type: 'ADD_VARIABLE';
  payload: FrontendVariable;
}

export interface UpdateVariableAction extends Action {
  type: 'UPDATE_VARIABLE';
  payload: {
    uuid: string;
    name: string;
    type: VariableTypes;
    list: boolean;
    initialValueFixed?: string;
    initialValuePropId?: string;
    initialValueParamId?: string;
    objectId?: string;
  };
}

export interface ChangeVariableTypeAction extends Action {
  type: 'CHANGE_VARIABLE_TYPE';
  payload: {
    uuid: string;
    type: VariableTypes;
    required: boolean;
    list: boolean;
    objectUuid?: string;
    enumUuid?: string;
  };
}

export interface DeleteVariableAction extends Action {
  type: 'DELETE_VARIABLE';
  payload: {
    uuid: string;
    view: string;
  };
}

export const setVariables = (variables: VariablesState): SetVariablesAction => ({
  type: SET_VARIABLES,
  payload: { variables }
});

export const addVariable = (variable: FrontendVariable): AddVariableAction => ({
  type: ADD_VARIABLE,
  payload: { ...variable }
});

export const updateVariable = (
  uuid: string,
  name: string,
  type: VariableTypes,
  list: boolean,
  initialValueFixed?: string,
  initialValuePropId?: string,
  initialValueParamId?: string,
  objectId?: string
): UpdateVariableAction => ({
  type: UPDATE_VARIABLE,
  payload: {
    uuid,
    name,
    type,
    list,
    initialValueFixed,
    initialValuePropId,
    initialValueParamId,
    objectId
  }
});

export const changeVariableType = (
  uuid: string,
  type: VariableTypes,
  required: boolean,
  list: boolean,
  objectUuid?: string,
  enumUuid?: string
): ChangeVariableTypeAction => ({
  type: 'CHANGE_VARIABLE_TYPE',
  payload: {
    uuid,
    type,
    required,
    list,
    objectUuid,
    enumUuid
  }
});

export const deleteVariable = (viewId: string, variableId: string): DeleteVariableAction => ({
  type: DELETE_VARIABLE,
  payload: { view: viewId, uuid: variableId }
});
