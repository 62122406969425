import { Action } from 'redux';
import { ThemeState } from '..';

export const SET_THEME = 'SET_THEME';
export const SET_SELECTED_THEME = 'SET_SELECTED_THEME';
export const CHANGE_THEME_VALUE = 'CHANGE_THEME_VALUE';

export interface SetThemeAction extends Action {
  type: typeof SET_THEME;
  payload: {
    theme: ThemeState;
  };
}

export interface SetSelectedThemeAction extends Action {
  type: typeof SET_SELECTED_THEME;
  payload: {
    uuid: string;
  };
}

export interface ChangeThemeValueAction extends Action {
  type: typeof CHANGE_THEME_VALUE;
  payload: {
    key: string;
    value: string;
    section: string;
  };
}

export const setTheme = (theme: ThemeState): SetThemeAction => ({
  type: SET_THEME,
  payload: { theme }
});

export const setSelectedTheme = (uuid: string): SetSelectedThemeAction => ({
  type: SET_SELECTED_THEME,
  payload: { uuid }
});

export const changeThemeValue = (
  key: string,
  value: string,
  section: string
): ChangeThemeValueAction => ({
  type: CHANGE_THEME_VALUE,
  payload: { key, value, section }
});
