import { AppProperties } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

export class AppPropertiesRepo {
  async getProperties(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${appId}/properties`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<AppProperties[]>;
      throw new Error('Something went wrong while trying to fetch app properties.');
    });
  }

  async updateProperties(uuid: string, appProperties: AppProperties[]) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(appProperties)
    };

    const url = `${API_URL}/apps/${uuid}/properties`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<AppProperties[]>;
      throw new Error('Something went wrong while trying to fetch app properties.');
    });
  }
}
