import { DataType, EnumUUID, IndexType, TableUUID } from '../../../../types';
import styles from './styles.module.css';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeColumn } from '../../../../../../routes/studio/data/elements/factory';
import { addColumn, addKeyColumn } from '../../../store/actions/root';
import { useDispatch, useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../store';
import ColumnTypeIcon from './common/column_type_icon';
import { ColumnNameInput } from './common/column_name_input';
import { ColumnTypeSelector } from './common/column_type_selector';
import ColumnDragIcon from './common/column_drag_icon';
import { setTypeBasedOnColumnName } from '../utils/helpers';
import { useRefState } from 'hooks/useRefState';
import { KEYS } from 'utils/keys';
import { setErrorMessage } from 'modules/modeler/studio/store/actions/studio';

type EmptyColumnProps = {
  tableID: TableUUID;
  fieldTypes: { [key: string]: DataType[] };
  selectedColumnID: string;
  selectColumn: React.Dispatch<React.SetStateAction<string>>;
  isPK: boolean;
  newColumnLabel: string;
};

export default function EmptyColumn({
  tableID,
  fieldTypes,
  selectedColumnID,
  selectColumn,
  isPK,
  newColumnLabel
}: EmptyColumnProps) {
  const dispatch = useDispatch();
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const orderedColumns: string[] = useSelector(
    (state: DatabaseStudioState) => state.tables[tableID].content.data.columns
  );
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const relationships = useSelector((state: DatabaseStudioState) => state.relationships);
  const indexes = useSelector((state: DatabaseStudioState) => state.indexes);
  const [column, setColumn] = useState({
    uuid: uuidv4(),
    tableUUID: tableID,
    name: '',
    type: 'VARCHAR',
    columnOrder: 0,
    nullable: false,
    defaultData: '',
    enumUUID: '',
    isPK: isPK,
    isFK: false,
    native: false,
    description: '',
    properties: { '': '' }
  });
  const [showContextButton, setShowContextButton] = useState(false);
  const [nameInputNode, setNameInputNode] = useRefState<HTMLInputElement>();
  const [typeInputNode, setTypeInputNode] = useRefState<HTMLInputElement>();

  useEffect(() => {
    if (!nameInputNode) return;
    if (newColumnLabel !== selectedColumnID) {
      return;
    }
    const { activeElement } = document;
    if (activeElement !== nameInputNode && activeElement !== typeInputNode) {
      nameInputNode.focus();
    }
  }, [nameInputNode, newColumnLabel, selectedColumnID, typeInputNode]);

  async function createNewColumn(name: string) {
    if (!column.uuid) return;

    const fkColumns = getFkColumns();
    const type = column.type;
    const newColumn = Object.assign(
      {},
      makeColumn(
        tableID,
        name,
        type,
        getLastColumnOrder('NORMAL'),
        '',
        column.nullable,
        '',
        isPK,
        false,
        column.uuid,
        column.enumUUID,
        fkColumns
      )
    );

    if (newColumn.name.length < 2) {
      return;
    }

    if (isPK) {
      const pkIndexID = getPkIndexID();
      const pkColumn = {
        UUID: newColumn.uuid,
        tableUUID: newColumn.tableUUID,
        name: newColumn.name,
        columnOrder: getLastColumnOrder('KEY'),
        type: newColumn.type,
        nullable: newColumn.nullable,
        defaultData: newColumn.defaultData,
        description: newColumn.description,
        fkColumns: newColumn.fkColumns,
        enumUUID: column.enumUUID
      };
      dispatch(addKeyColumn(pkColumn, pkIndexID, uuidv4()));
    } else {
      dispatch(
        addColumn(
          newColumn.uuid,
          newColumn.tableUUID,
          newColumn.name,
          newColumn.columnOrder,
          newColumn.type,
          newColumn.nullable,
          newColumn.defaultData,
          newColumn.description,
          column?.enumUUID ?? ''
        )
      );
    }
    const newUUID = uuidv4();
    setColumn({
      uuid: newUUID,
      tableUUID: tableID,
      name: '',
      type: 'VARCHAR',
      columnOrder: 0,
      nullable: false,
      defaultData: '',
      enumUUID: '',
      isPK: isPK,
      isFK: false,
      native: false,
      description: '',
      properties: { '': '' }
    });
  }

  const getFkColumns = () => {
    const fkColumns: string[] = [];
    Object.keys(tables[tableID].content.data.relationships).map((relationshipKey) => {
      const relationshipID = tables[tableID].content.data.relationships[relationshipKey];
      relationships[relationshipID].to === tableID
        ? fkColumns.push(relationships[relationshipID].from)
        : fkColumns.push(relationships[relationshipID].to);
      return null;
    });
    return fkColumns;
  };

  const getLastColumnOrder = (columnType: string): number => {
    if (columnType === 'KEY' || columnType === 'NORMAL') {
      for (let i = orderedColumns.length - 1; i >= 0; i--) {
        const col = columns[orderedColumns[i]];
        const isKey = col.isFK || (col.isPK && columnType === 'KEY');
        const isNotKey = !col.isFK && !col.isPK && columnType === 'NORMAL';
        if (isKey || isNotKey) return col.columnOrder + 1;
      }
      return 0;
    } else {
      throw new Error('Failed to calculate the columnOrder for the new column');
    }
  };

  const getPkIndexID = () => {
    let pkIndexID = '';
    Object.keys(tables[tableID].content.data.indexes).forEach((indexKey) => {
      const indexID = tables[tableID].content.data.indexes[indexKey];
      if (indexes[indexID] && indexes[indexID].type === IndexType.PRIMARY) {
        pkIndexID = indexID;
        return null;
      }
    });
    return pkIndexID;
  };

  const handleChangeType = (dataType: string, enumUUID?: EnumUUID) => {
    if (!dataType) return;
    if (enumUUID) {
      setColumn({ ...column, type: dataType, enumUUID: enumUUID });
    } else {
      setColumn({ ...column, type: dataType });
    }
  };

  const handleChangeName = async (newName: string) => {
    setColumn({ ...column, name: newName });
  };

  useEffect(() => {
    const type = setTypeBasedOnColumnName(column.name, column.type);
    handleChangeType(type, undefined);
  }, [column.name]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (!typeInputNode || !nameInputNode) {
      return;
    }

    const { activeElement } = document;
    if (e.key === KEYS.TAB && !e.shiftKey) {
      e.preventDefault();
      if (nameInputNode === activeElement) {
        typeInputNode.focus();
      } else if (typeInputNode === activeElement) {
        handleNewColumn(nameInputNode.value);
      }
    } else if (e.key === KEYS.ENTER && !e.shiftKey) {
      e.preventDefault();
      handleNewColumn(nameInputNode.value);
    }
  };

  const isNameValid = (name: string) => {
    const regex = /^[a-zA-Z_][0-9a-zA-Z_]*$/;
    return regex.test(name);
  };

  const checkExistingName = (name: string) => {
    const tableID = column.tableUUID;
    for (const col of Object.values(columns)) {
      if (col.tableUUID === tableID && name.toLowerCase() === col.name.toLowerCase()) {
        return true;
      }
    }
    return false;
  };

  const handleNewColumn = (newName: string) => {
    if (newName && !isNameValid(newName)) {
      dispatch(setErrorMessage('modeler.IllegalNameCharacters'));
    } else if (newName && checkExistingName(newName)) {
      dispatch(setErrorMessage('modeler.ExistingNameMessage'));
    } else {
      let columnName = newName
        ? newName
        : `column_${tables[column?.tableUUID].content.data.columns.length}`;
      if (checkExistingName(columnName)) columnName += '_1';
      createNewColumn(columnName);
      if (nameInputNode) {
        nameInputNode.focus();
      }
    }
  };

  return (
    <div
      id={'emptyColumnWrapper'}
      className={`${styles.EmptyColumnWrapper} mb-2 row gx-3 rounded-1 ${
        showContextButton && 'bg-body-tertiary'
      } ${newColumnLabel === selectedColumnID && 'bg-body-secondary'}`}
      onClick={() => selectColumn(newColumnLabel)}
      onMouseEnter={() => setShowContextButton(true)}
      onMouseLeave={() => setShowContextButton(false)}
      onKeyDown={handleKeyDown}
    >
      <ColumnDragIcon show={false} />
      <ColumnNameInput
        ref={setNameInputNode}
        columnName={column.name}
        createColumn={true}
        handleChangeName={handleChangeName}
      />
      <ColumnTypeSelector
        ref={setTypeInputNode}
        column={column}
        handleChange={handleChangeType}
        fieldTypes={fieldTypes}
      />
      <ColumnTypeIcon column={column} />
    </div>
  );
}
