import React from 'react';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';

interface WalkthroughButtonProps {
  onClick: () => void;
}

export function WalkthroughButton({ onClick }: WalkthroughButtonProps) {
  return (
    <span
      className={`Walkthrough_TriggerButton ${styles.feedbackButton} d-flex justify-content-center align-items-center`}
      onClick={onClick}
      role="button"
    >
      <Icon iconName="circle-info" />
    </span>
  );
}
