import React, { useEffect, useState } from 'react';
import { ControlProps } from '..';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { Badge, Form } from 'react-bootstrap';
import VariableControl from '../VariableControl';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../../../../modules/designer/studio/store';
import { SchemaObject } from '../../../../../modules/logic_builder/types';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';
import { FRONTEND_VARIABLE_TYPES, VariableTypes } from 'modules/designer/types';
import ErrorIcon from '../components/ErrorIcon';

function ListSelectorControl(props: ControlProps) {
  const { t } = useTranslation();
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const variables = useSelector((state: InterfaceStudioState) => state.variables);
  const [listObj, setListObj] = useState({} as SchemaObject);

  useEffect(() => {
    if (!props?.value?.variable?.uuid) return;
    const varId = props.value.variable.uuid;
    const variable = variables[varId];
    if (!variable?.objectUuid) return;
    const parentObj = objects[variable.objectUuid];
    if (props.value?.variable?.objectItem) {
      const objItemId = props.value.variable.objectItem;
      if (parentObj?.objectItems === undefined || parentObj?.objectItems?.length === 0) return;
      const objItem = parentObj.objectItems.filter((i) => i.uuid === objItemId)[0];
      // TODO: check this in backend, as the type in FE differs from the data sent by the API
      // @ts-ignore
      const targetObjId = objItem?.object.id ?? '.';
      const targetObj = objects[targetObjId];
      setListObj(targetObj);
    } else {
      setListObj(parentObj);
    }
  }, [props, variables, objects]);

  function handleSelectKeyItemAttributes(attrId: string) {
    if (
      props.value.selectKeyItemAttributes &&
      props.value.selectKeyItemAttributes.length === 1 &&
      props.value.selectKeyItemAttributes.includes(attrId)
    ) {
      return null;
    }
    const newKeyColumns = props.value.selectKeyItemAttributes
      ? props.value.selectKeyItemAttributes.includes(attrId)
        ? props.value.selectKeyItemAttributes.filter((keyItemId: string) => keyItemId !== attrId)
        : [...props.value.selectKeyItemAttributes, attrId]
      : [attrId];

    props.onChange && props.onChange(newKeyColumns, 'selectKeyItemAttributes');
  }

  return (
    <div className="mb-3 pb-2 border-bottom text-body-secondary">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div style={{ display: 'flex' }}>
          <label className="position-relative mb-1 text-body">{props.label}</label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.controls.ListSelectableTitle') ||
                'designer.right_side.controls.ListSelectableTitle',
              description:
                t('designer.right_side.controls.ListSelectableDescription') ||
                'designer.right_side.controls.ListSelectableDescription',
              note: [
                t('designer.right_side.controls.ListSelectableNote01'),
                t('designer.right_side.controls.ListSelectableNote02')
              ]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </div>
        <Form.Check
          type="switch"
          id="custom-switch"
          checked={props.value?.selectable}
          value={props.value?.selectable}
          onChange={(e) => {
            props.onChange && props.onChange(e.target.checked, 'selectable');
          }}
        />
      </div>

      {props.value?.selectable && (
        <div>
          {props.value?.selectable && (
            <>
              <div className="mb-3">
                <div>
                  <MissingMessage
                    type={MissingMessageType.VARIABLE}
                    uuid={props.value?.selectedItems?.uuid}
                    requiredTypes={[FRONTEND_VARIABLE_TYPES.OBJECT as VariableTypes]}
                  />
                  <Form.Label>{t('designer.right_side.controls.ListSelectVariable')}</Form.Label>
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('designer.right_side.controls.ListSelectVariableTitle') ||
                        'designer.right_side.controls.ListSelectVariableTitle',
                      description:
                        t('designer.right_side.controls.ListSelectVariableDescription') ||
                        'designer.right_side.controls.ListSelectVariableDescription',
                      note: [t('designer.right_side.controls.ListSelectVariableNote01')]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </div>
                <VariableControl
                  id={'selectedItems'}
                  key={'selectedItems'}
                  label=""
                  value={props.value?.selectedItems}
                  onChange={props.onChange}
                  options={['list', FRONTEND_VARIABLE_TYPES.OBJECT]}
                />
              </div>

              <div className="mb-3">
                <div>
                  <Form.Label>{t('designer.right_side.Type')}</Form.Label>
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('designer.right_side.controls.ListSelectTypeTitle') ||
                        'designer.right_side.controls.ListSelectTypeTitle',
                      description:
                        t('designer.right_side.controls.ListSelectTypeDescription') ||
                        'designer.right_side.controls.ListSelectTypeDescription',
                      note: [
                        t('designer.right_side.controls.ListSelectTypeNote01'),
                        t('designer.right_side.controls.ListSelectTypeNote02')
                      ]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </div>
                <Form.Select
                  value={props.value?.selectType}
                  onChange={(e) => props.onChange && props.onChange(e.target.value, 'selectType')}
                  isValid={props.value?.selectType}
                  required
                >
                  <option defaultChecked key="SINGULAR" value="SINGULAR">
                    Singular
                  </option>
                  <option key="MULTI" value="MULTI">
                    Multi
                  </option>
                </Form.Select>
              </div>

              <div className="mb-3">
                <div>
                  {(!props.value.selectKeyItemAttributes ||
                    props.value.selectKeyItemAttributes?.length < 1) && (
                    <ErrorIcon title={'designer.right_side.controls.errors.NoSelectKeyColumn'} />
                  )}
                  <Form.Label>{t('designer.right_side.KeyAttributes')}</Form.Label>
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('designer.right_side.controls.KeyItemAttributesTitle') ||
                        'designer.right_side.controls.KeyItemAttributesTitle',
                      description:
                        t('designer.right_side.controls.KeyItemAttributesDescription') ||
                        'designer.right_side.controls.KeyItemAttributesDescription',
                      note: [t('designer.right_side.controls.KeyItemAttributesNote01')]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </div>

                <div>
                  {listObj &&
                    listObj?.objectItems &&
                    listObj.objectItems.map((item, index: number) => (
                      <p
                        key={index}
                        className="d-inline me-2"
                        onClick={(e) => handleSelectKeyItemAttributes(item.uuid ?? '')}
                      >
                        <Badge
                          bg={`${
                            props.value.selectKeyItemAttributes &&
                            props.value.selectKeyItemAttributes.includes(item.uuid)
                              ? 'primary'
                              : 'secondary'
                          }`}
                          style={{ cursor: 'pointer' }}
                        >
                          {item.name}
                        </Badge>
                      </p>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default ListSelectorControl;
