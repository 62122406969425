export interface VariablesState {
  [key: string]: FunctionVariableType;
}

export interface FunctionVariableType {
  uuid: string;
  name: string;
  desc: string;
  type: string;
  objectUuid?: string;
  enumUuid?: string;
  list: boolean;
  required: boolean;
  native?: boolean;
}

export const FORBIDDEN_VARIABLE_NAMES = ['var', 'const', 'variable'];

export const PRIMITIVE_TYPES = [
  'NUMBER',
  'STRING',
  'BIGINT',
  'BOOLEAN',
  'OBJECT',
  'UUID',
  'VARCHAR',
  'INTEGER',
  'AUTOINCREMENT',
  'ENUM'
];
