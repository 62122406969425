import React, { useRef, useState, useEffect } from 'react';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';

interface ColumnProps {
  title: string;
  size: number;
  onResize: (columnTitle: string, newSize: number) => void;
  onDelete: (columnTitle: string) => void;
  columnWidth: number;
  totalWidth: number;
}

function Column({ title, size, onResize, onDelete, columnWidth, totalWidth }: ColumnProps) {
  const [isResizing, setIsResizing] = useState(false);
  const columnRef = useRef<HTMLDivElement>(null);
  const startXRef = useRef<number>(0);
  const sizeRef = useRef(size);
  const RESIZE_LIMIAR = columnWidth / 1.5;

  const handleMouseDown = (e: React.MouseEvent) => {
    // if (e.nativeEvent.offsetX < BORDER_SIZE) {
    setIsResizing(true);
    startXRef.current = e.clientX;
    // }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing) return;
    const dx = e.clientX - startXRef.current;
    const direction = dx > 0 ? 1 : -1;
    if (direction === -1 && sizeRef.current <= 1) return;
    if (direction === +1 && totalWidth + 1 > 12) return;

    if (Math.abs(dx) > RESIZE_LIMIAR) {
      onResize(title, sizeRef.current + direction);
      sizeRef.current += direction;
      startXRef.current = e.clientX;
      totalWidth += direction;
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  // Configura os listeners do mouse
  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  useEffect(() => {
    sizeRef.current = size;
  }, [size]);

  return (
    <div
      className={`col-${size} ${styles.resizeArea}`}
      ref={columnRef}
      onMouseDown={handleMouseDown}
      style={{
        position: 'relative',
        userSelect: 'none'
      }}
    >
      <div
        className="w-100 rounded"
        style={{ backgroundColor: 'rgba(0,140,140,1)', height: '64px', fontSize: '14px' }}
      >
        <div className="h-100 d-flex flex-column justify-content-between align-items-end">
          <span className={`pe-1 ${styles.deleteButton}`} onClick={() => onDelete(title)}>
            <Icon iconName="xmark"></Icon>
          </span>
          <span className="w-100 ps-1">col</span>
        </div>
      </div>
    </div>
  );
}

export default Column;
