import produce from 'immer';

import { initialState, LinksState } from '../index';
import { COMPONENTS_MANIFEST } from '../../exocode_components';
import { ComponentUUID, LayoutComponent, MoveComponentPayload } from '../../../types';
import { MoveSource, MoveTarget, SET_LINKS, SetLinksAction } from '../actions/links';
import {
  ADD_COMPONENT,
  AddComponentAction,
  DELETE_COMPONENT,
  DeleteComponentAction,
  ADD_CUSTOM_COMPONENT,
  CREATE_CUSTOM_COMPONENT,
  AddCustomComponentAction,
  CreateCustomComponentAction,
  ExtractFormAction,
  EXTRACT_FORM,
  AddDetachedCustomComponentAction,
  ADD_DETACHED_CUSTOM_COMPONENT,
  ADD_COMPONENT_SET,
  AddComponentSetAction,
  AddAndMoveComponentsIntoAction,
  ADD_AND_MOVE_COMPONENTS_INTO,
  LinkInfo,
  MoveComponentAction,
  MOVE_COMPONENT,
  AddMultiplesAndMoveComponentsIntoAction,
  ComponentPayload,
  ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO
} from '../actions/root';
import { CHANGE_LAYOUT, ChangeLayoutAction } from '../actions/views';
import { COPY_COMPONENT, DuplicateComponentAction } from '../actions/components';
import { sleep } from 'utils/utils';

type LinksActions =
  | SetLinksAction
  | MoveComponentAction
  | AddComponentAction
  | DeleteComponentAction
  | AddCustomComponentAction
  | AddDetachedCustomComponentAction
  | CreateCustomComponentAction
  | ChangeLayoutAction
  | ExtractFormAction
  | DuplicateComponentAction
  | AddComponentSetAction
  | AddAndMoveComponentsIntoAction
  | AddMultiplesAndMoveComponentsIntoAction;

export const linksReducer = (
  state: LinksState = initialState.links,
  action: LinksActions
): LinksState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_LINKS:
        return doSetLinks(draft, action);
      case ADD_COMPONENT:
        return doAddComponentLinks(draft, action);
      case MOVE_COMPONENT:
        return doMoveComponent(draft, action);
      case DELETE_COMPONENT:
        return doDeleteComponentLinks(draft, action);
      case ADD_CUSTOM_COMPONENT:
        return doAddCustomComponentLinks(draft, action);
      case ADD_DETACHED_CUSTOM_COMPONENT:
        return doAddDetachedCustomComponentLinks(draft, action);
      case CREATE_CUSTOM_COMPONENT:
        return doCreateCustomComponentLinks(draft, action);
      case CHANGE_LAYOUT:
        return doChangeLayout(draft, action);
      case EXTRACT_FORM:
        return doExtractForm(draft, action);
      case COPY_COMPONENT:
        return doDuplicateComponent(draft, action);
      case ADD_COMPONENT_SET:
        return doAddComponentLinksSet(draft, action);
      case ADD_AND_MOVE_COMPONENTS_INTO:
        return doAddAndMoveComponentsInto(draft, action);
      case ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO:
        return doAddMultiplesAndMoveComponentsInto(draft, action);
      default:
        return draft;
    }
  });
};

export function doSetLinks(state: LinksState, action: SetLinksAction): LinksState {
  return action.payload.links;
}

/**
 * Create links for custom components.
 */
// ...so create a new one. They already have an id because of the customcomp links.
export function doAddCustomComponentLinks(state: LinksState, action: AddCustomComponentAction) {
  // Complete LinksState for a specific custom component.
  const links = action.payload.customComponentsChildren.links;
  // The parent component uuid.
  const parentUUID = action.payload.link.parentUUID;
  // The new uuid for rendering purposes.
  const newCustomComponentUUID = action.payload.newCustomComponentUUID;

  // Add every link from links into LinksState.
  for (const linkId of Object.keys(links)) {
    state[linkId] = links[linkId];
  }

  // Link custom component with its parent.
  // * This is going to work as long as a custom component is composed by only one...
  // ...component (e.g. button, container, row, etc.).
  if (!state[parentUUID]) state[parentUUID] = [];
  state[parentUUID].push(newCustomComponentUUID);
}

/**
 * Update the LinksState: insert the custom component after the parent component and
 * before the children component.
 */
export function doCreateCustomComponentLinks(
  state: LinksState,
  action: CreateCustomComponentAction
) {
  const { componentUUID, customComponentUUID, custom_uuid } = action.payload;

  // Update links. Every occurrence of componentUUID in the children array of each state[key] should be replaced
  // by customComponentUUID.
  outerLoop: for (const linkKey of Object.keys(state)) {
    const componentIdList = state[linkKey];
    for (let i = 0; i < componentIdList.length; i++) {
      if (componentIdList[i] === componentUUID) {
        state[linkKey][i] = customComponentUUID; // ? is this right?
        // Break since componentUUID can exist in only one list.
        break outerLoop;
      }
    }
  }

  // Add a new link between custom component and source component.
  state[custom_uuid] = [`${componentUUID}`];

  return state;
}

export function doAddDetachedCustomComponentLinks(
  state: LinksState,
  action: AddDetachedCustomComponentAction
): LinksState {
  const { link, customComponentsState, rootId, oldIdsNewIds } = action.payload;
  const customComponents = customComponentsState.components;
  const links = customComponentsState.links;

  // The component of type CUSTOM has only 1 children.
  const rootComponentId = links[rootId][0];
  const rootComponent = customComponents[rootComponentId];
  const rootParent = `${link.parent}${link.section ? '_' + link.section : ''}`;
  // Start by adding the first component referenced by the custom component.
  const allowDrop = COMPONENTS_MANIFEST[rootComponent.type].allowDrop;
  const newComponentId = oldIdsNewIds[rootComponentId];
  if (allowDrop && !state[newComponentId]) state[newComponentId] = [];
  if (!state[rootParent]) state[rootParent] = [];
  state[rootParent].push(newComponentId);

  // Add the other components.
  for (const parentKey of Object.keys(customComponentsState.links)) {
    // Discard the id of the custom component (root component).
    if (rootId === parentKey) {
      continue;
    }

    const newParentId = oldIdsNewIds[parentKey];
    if (!state[newParentId]) state[newParentId] = [];
    for (const oldChildrenId of links[parentKey]) {
      const newChildrenId = oldIdsNewIds[oldChildrenId];

      const allowDrop = COMPONENTS_MANIFEST[rootComponent.type].allowDrop;
      if (allowDrop && !state[newChildrenId]) state[newChildrenId] = [];

      state[newParentId].push(newChildrenId);
    }
  }

  return state;
}

export function doAddComponentLinks(state: LinksState, action: AddComponentAction): LinksState {
  const { component, link } = action.payload;

  handleAddComponentLink(state, component, link);
  return state;
}

export function doMoveComponent(state: LinksState, action: MoveComponentAction): LinksState {
  const { source, target } = action.payload;

  handleMoveComponent(state, source, target, target.adjacentSide);

  return state;
}

// TODO: special case for custom components: dont try to delete custom components links.
// Probably need to iterate through all components to see if that custom_uuid is gone, then delete link.
export function doDeleteComponentLinks(
  state: LinksState,
  action: DeleteComponentAction
): LinksState {
  const componentID = action.payload.uuid;
  const parentID = action.payload.parentUUID;
  const section = action.payload.section;

  handleDeleteComponentLinks(state, componentID);
  if (parentID && section) {
    delete state[parentID + '_' + section];
  }

  if (parentID && state[parentID] && action.payload.emptyParent) {
    delete state[parentID];
  }
  return state;
}

export function doChangeLayout(state: LinksState, action: ChangeLayoutAction): LinksState {
  if (action.payload.layout) {
    const links = action.payload.layout.links;
    for (const linkKey of Object.keys(links)) {
      state[linkKey] = links[linkKey];
    }
  }

  return state;
}

export function doExtractForm(state: LinksState, action: ExtractFormAction): LinksState {
  const formID = action.payload.form;
  const link = action.payload.link;

  let targetIndex = state[link.parentUUID].findIndex(
    (id: ComponentUUID) => id === action.payload.form
  );

  action.payload.components.forEach((component) => {
    state[link.parentUUID].splice(targetIndex, 0, component.uuid);
    targetIndex += 1;
  });

  state[link.parentUUID] = state[link.parentUUID].filter((id) => id !== formID);
  return state;
}

export function doDuplicateComponent(
  state: LinksState,
  action: DuplicateComponentAction
): LinksState {
  const componentId = action.payload.component;
  const newIds = action.payload.oldIdsNewIds;
  const targetId = action.payload.target;
  state[targetId].push(newIds[componentId]);

  const toDuplicate = [componentId];
  while (toDuplicate.length) {
    const duplicateThisParent = toDuplicate.pop();
    if (!duplicateThisParent) break;
    const newParentId = newIds[duplicateThisParent];
    if (!state[newParentId]) {
      state[newParentId] = [];
    }

    if (!state[duplicateThisParent]) continue;

    const newIdsList = [];
    for (const child of state[duplicateThisParent]) {
      toDuplicate.push(child);
      newIdsList.push(newIds[child]);
    }
    state[newParentId] = newIdsList;
  }

  return state;
}

export function doAddComponentLinksSet(
  state: LinksState,
  action: AddComponentSetAction
): LinksState {
  const { componentSet } = action.payload;

  componentSet.forEach((componentInfo) => {
    const { component, link } = componentInfo;

    const allowDrop = COMPONENTS_MANIFEST[component.type].allowDrop;
    if (allowDrop && !state[component.uuid]) state[component.uuid] = [];

    const key = `${link.parentUUID}${link.section ? '_' + link.section : ''}`;

    if (!state[key]) state[key] = [];

    if (link.targetUuid && link.adjacentSide) {
      const index = state[key].indexOf(link.targetUuid);

      if (index !== -1) {
        const insertIndex = link.adjacentSide === 'left' ? index : index + 1;
        state[key].splice(insertIndex, 0, component.uuid);
      }
    } else {
      state[key].push(component.uuid);
    }
  });

  return state;
}

export function doAddAndMoveComponentsInto(
  state: LinksState,
  action: AddAndMoveComponentsIntoAction
): LinksState {
  const { componentsToMove } = action.payload;
  const { component, link } = action.payload.componentToAdd;

  handleAddComponentLink(state, component, link);

  componentsToMove.map(({ source, target }: MoveComponentPayload) => {
    handleMoveComponent(state, source, target);
  });

  return state;
}

function handleMoveComponent(
  state: LinksState,
  source: MoveSource,
  target: MoveTarget,
  adjacentSide?: string
): void {
  /* When target.uuid is undefined that means target is the page_view.
  If target is page_view then use target.parentUUID instead. */

  const sourceKey = `${source.parentUUID}${source.section ? '_' + source.section : ''}`;
  const targetKey = `${target.parentUUID}${target.section ? '_' + target.section : ''}`;

  if (!state[targetKey]) state[targetKey] = [];

  // source parent can be view or component
  // remove current link with view/component
  // updates source order
  const sourceIndex = state[sourceKey].findIndex((id: ComponentUUID) => id === source.uuid);
  const targetIndex = state[targetKey].findIndex((id: ComponentUUID) => id === target.uuid);
  state[sourceKey].splice(sourceIndex, 1);

  // Target parent can be view or component.
  // If target.uuid is set then target component is not a row/container/view/column...
  // ...(e.g. button, input, etc.).
  if (target.uuid) {
    if (adjacentSide) {
      state[targetKey].splice(
        ['right', 'bottom'].includes(adjacentSide)
          ? sourceIndex > targetIndex
            ? targetIndex + 1
            : targetIndex
          : targetIndex,
        0,
        source.uuid
      );
    } else state[targetKey].splice(targetIndex, 0, source.uuid);
    // Row/container/column etc.
  } else {
    // Push to the end of the view/component array.
    state[targetKey].push(source.uuid);
  }

  if (source.deletePastParent && source.parentUUID) {
    handleDeleteComponentLinks(state, source.parentUUID);
  }
}

function handleAddComponentLink(state: LinksState, component: LayoutComponent, link: LinkInfo) {
  const allowDrop = COMPONENTS_MANIFEST[component.type].allowDrop;
  if (allowDrop && !state[component.uuid]) state[component.uuid] = [];

  const key = `${link.parentUUID}${link.section ? '_' + link.section : ''}`;

  if (!state[key]) state[key] = [];

  if (link.targetUuid && link.adjacentSide) {
    const index = state[key].indexOf(link.targetUuid);

    if (index !== -1) {
      const insertIndex = ['left', 'top'].includes(link.adjacentSide) ? index : index + 1;
      state[key].splice(insertIndex, 0, component.uuid);
    }
  } else {
    state[key].push(component.uuid);
  }
}

function handleDeleteComponentLinks(state: LinksState, componentID: string) {
  for (const key of Object.keys(state)) {
    state[key] = state[key].filter((id) => id !== componentID);
  }

  delete state[componentID];
}

export function doAddMultiplesAndMoveComponentsInto(
  state: LinksState,
  action: AddMultiplesAndMoveComponentsIntoAction
): LinksState {
  const { componentsToAdd, componentsToMove } = action.payload;

  for (const { component, link } of componentsToAdd) {
    handleAddComponentLink(state, component, link);
  }

  for (const { source, target } of componentsToMove) {
    handleMoveComponent(state, source, target, target.adjacentSide);
  }

  return state;
}
