import * as React from 'react';
import { DeclareVariableActionType, ARGUMENT_TYPES } from '../../types';
import {
  Action,
  FunctionParameter,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import ArgumentPicker from '../ArgumentPicker';
import { Form } from 'react-bootstrap';

type Props = {
  newAction: Action<DeclareVariableActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<DeclareVariableActionType>>>;
  objects: ObjectSimple[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
};

export default function RenderDeclareVariable(props: Props) {
  return (
    <div>
      <Form.Group>
        <div className="d-flex w-100 mb-2">
          <div className="me-3">
            <input
              id="sortable-read"
              type="checkbox"
              onChange={(e) => {
                props.onChange((currentVal: any) => {
                  const auxVal = { ...currentVal };
                  auxVal.data.initialize = e.target.checked;
                  return auxVal;
                });
              }}
              checked={props.newAction.data.initialize ?? false}
              className="form-check-input me-1"
            />
            <label htmlFor="sortable-read">initializable</label>
          </div>
        </div>

        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Select Input"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.input.value = value;
              auxVal.data.arguments.input.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.input.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={props.newAction.data.arguments.input.type}
        />

        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={false}
          allowVars={true}
          allowObject={false}
          label="Select Variable to return"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.returnVariableUuid = value;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.returnVariableUuid}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={ARGUMENT_TYPES.VAR}
        />
      </Form.Group>
    </div>
  );
}
