import { Relationship, relationshipID } from '../../../../../types';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import { changeProperty } from '../../../../store/actions/relationship';
import { TablesState } from 'modules/modeler/studio/store';

export type RelationshipComponentProps = {
  relationships: { [key: relationshipID]: Relationship };
  selectedRelationshipId: relationshipID;
  tables: TablesState;
};

function RelationshipComponent(props: RelationshipComponentProps) {
  const [toName, setToName] = useState('');
  const [fromName, setFromName] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.selectedRelationshipId) return;
    const relationship = props.relationships[props.selectedRelationshipId];
    setToName(relationship.toName ?? '');
    setFromName(relationship.fromName ?? '');
  }, [props.relationships, props.selectedRelationshipId]);

  function handleFromName(event: ChangeEvent<any>) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(changeProperty(props.selectedRelationshipId, 'fromName', fromName));
  }

  function handleToName(event: ChangeEvent<any>) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(changeProperty(props.selectedRelationshipId, 'toName', toName));
  }

  function blockEventListener(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <div
      className={`border-top ${styles.relationshipPropsContainer}`}
      onClick={(event) => {
        blockEventListener(event);
      }}
    >
      <h6 className="text-body">{t('modeler.FromName')}</h6>
      <div className={styles.relationshipContainer}>
        <input
          id="relationshipFromNameInput"
          className={`${styles.relationshipNameInput} border form-control form-control-sm`}
          type={'text'}
          required
          maxLength={64}
          placeholder={
            props.tables[props.relationships[props.selectedRelationshipId!].from].content.data
              .name ?? ''
          }
          value={fromName}
          onChange={(event) => {
            setFromName(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
              handleFromName(event);
            }
          }}
          onBlur={(event) => {
            handleFromName(event);
          }}
        />
        <div />
      </div>
      <h6 className="text-body">{t('modeler.ToName')}</h6>
      <div className={styles.relationshipContainer}>
        <input
          id="relationshipToNameInput"
          className={`${styles.relationshipNameInput} border form-control form-control-sm`}
          type={'text'}
          required
          maxLength={64}
          placeholder={
            props.tables[props.relationships[props.selectedRelationshipId!].to].content.data.name ??
            ''
          }
          value={toName}
          onChange={(event) => {
            setToName(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
              handleToName(event);
            }
          }}
          onBlur={(event) => {
            handleToName(event);
          }}
        />
        <div />
      </div>
    </div>
  );
}

export default RelationshipComponent;
