import React, { useEffect } from 'react';
import { FormatterProps, FormatterTypes, TICKCROSS_MANIFEST } from '../../types';
import { useTranslation } from 'react-i18next';

function TickCrossFormatter(props: FormatterProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.TICKCROSS) {
      props.onChange && props.onChange(props.index, 'formatterParams', TICKCROSS_MANIFEST);
    }
  }, []);

  return <></>;
}

export default TickCrossFormatter;
