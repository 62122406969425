import { DATA_TYPES } from 'modules/logic_builder/types';
import { ComponentType, ReactNode } from 'react';
import { types } from 'util';
import TextEditorPreview from './TextEditorPreview';

export interface EditComponentPreviewProps {
  value: string;
}

export const EDITOR_PREVIEW: { [key: string]: ReactNode } = {
  [DATA_TYPES.VARCHAR]: TextEditorPreview as ComponentType<EditComponentPreviewProps>,
  [DATA_TYPES.STRING]: TextEditorPreview as ComponentType<EditComponentPreviewProps>
};
