import React from 'react';

function SetVariableIcon() {
  return (
    <span className="text-primary" style={{ whiteSpace: 'nowrap' }}>
      <span style={{ fontStyle: 'italic', fontFamily: 'serif', fontWeight: 'bolder' }}>x</span>
    </span>
  );
}

export default SetVariableIcon;
