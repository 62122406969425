import { useEffect, useState } from 'react';
import { ObjectsRepository } from '../../../../modules/logic_builder/repos';
import { ObjectSimple } from '../../../../modules/logic_builder/types';
import { useParams } from 'react-router-dom';

function useFetchObjects(backend: boolean, frontend: boolean) {
  const { module_id } = useParams();
  const [objects, setObjects] = useState<ObjectSimple[]>([]);

  useEffect(() => {
    const fetchObjects = async () => {
      const objs = await ObjectsRepository.getObjectsByModule(
        module_id ?? '',
        backend ? true : undefined,
        frontend ? true : undefined,
        true
      );
      setObjects(objs);
    };
    fetchObjects().then(() => {});
  }, [module_id]);

  return objects;
}

export default useFetchObjects;
