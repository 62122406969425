import { Modal } from '../types';
import { ModalSchema } from './schemas';

export const API_URL = process.env.REACT_APP_API_URL;

export class ModalRepo {
  private buildSchema(
    modalName: string,
    modalDescription: string,
    template: string,
    folderId: string
  ) {
    const modalSchema: ModalSchema = {
      name: modalName,
      description: modalDescription,
      template,
      folder_id: folderId
    };
    return modalSchema;
  }

  /**
   * Create a new modal for a specific module.
   */
  async createModal(moduleId: string, modalName: string, template: string, folderId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const body: ModalSchema = this.buildSchema(modalName, '', template, folderId);

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    const url = `${API_URL}/modules/${moduleId}/modals`;

    return await fetch(url, options).then((response) => {
      if (response.status === 201) return response.json() as Promise<Modal>;
      throw new Error('Something went wrong while trying to create a new modal.');
    });
  }

  /**
   * Fetch all modals for a specific module.
   */
  async getModalsByModule(moduleId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modules/${moduleId}/modals`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Modal[]>;
      throw new Error('Something went wrong while trying to fetch module modals.');
    });
  }

  /**
   * Fetch a single modal.
   */
  async getModal(modalId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modals/${modalId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Modal>;
      throw new Error('Something went wrong while trying to fetch modal.');
    });
  }

  async updateModal(modalId: string, updatedModal: Modal) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(updatedModal)
    };

    const url = `${API_URL}/modals/${modalId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Modal>;
      throw new Error('Something went wrong while trying to update modal.');
    });
  }

  async moveModal(modalId: string, moduleId: string, folderId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const moveData = {
      folder_id: folderId
    };

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(moveData)
    };

    const url = `${API_URL}/modules/${moduleId}/modals/${modalId}/move`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Modal>;
      throw new Error('Something went wrong while trying to move modal.');
    });
  }

  async deleteModal(modalId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modals/${modalId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response;
      throw new Error('Something went wrong while trying to delete modal.');
    });
  }
}
