import {
  AddListActionType,
  BeginCycleActionType,
  ClearListActionType,
  CreateActionType,
  CustomCodeActionType,
  DeclareVariableActionType,
  DeleteActionType,
  DeleteFileActionType,
  ReadActionType,
  ReadFileActionType,
  RemoveListActionType,
  SaveFileActionType,
  SetVariableActionType,
  ARGUMENT_TYPES,
  UpdateActionType,
  RunFunctionActionType,
  EndCycleActionType,
  CustomQueryActionType
} from '../types';

const CREATE_ENTITY_ACTION_INITIAL_STATE: CreateActionType = {
  objectId: '',
  arguments: {
    entity: {
      type: ARGUMENT_TYPES.VAR,
      value: '',
      objectNode: null
    }
  }
};

const READ_ENTITY_ACTION_INITIAL_STATE: ReadActionType = {
  objectId: '',
  sortable: false,
  filterable: false,
  arguments: {
    id: {
      type: ARGUMENT_TYPES.VAR,
      value: '',
      objectNode: null
    }
  }
};

const UPDATE_ENTITY_ACTION_INITIAL_STATE: UpdateActionType = {
  objectId: '',
  arguments: {
    entity: {
      type: ARGUMENT_TYPES.VAR,
      value: '',
      objectNode: null
    }
  }
};

const DELETE_ENTITY_ACTION_INITIAL_STATE: DeleteActionType = {
  objectId: '',
  arguments: {
    entity: {
      type: ARGUMENT_TYPES.VAR,
      value: '',
      objectNode: null
    },
    id: {
      type: ARGUMENT_TYPES.VAR,
      value: '',
      objectNode: null
    }
  }
};

const RUN_FUNCTION_ACTION_INITIAL_STATE: RunFunctionActionType = {
  function: {
    functionId: '',
    arguments: {}
  }
};

const SET_VARIABLE_ACTION_INITIAL_STATE: SetVariableActionType = {
  arguments: {
    source: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    },
    target: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    }
  }
};

const DECLARE_VARIABLE_ACTION_INITIAL_STATE: DeclareVariableActionType = {
  initialize: false,
  arguments: {
    input: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    }
  }
};

const ADD_LIST_ACTION_INITIAL_STATE: AddListActionType = {
  arguments: {
    list: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    },
    object: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    }
  }
};

const REMOVE_LIST_ACTION_INITIAL_STATE: RemoveListActionType = {
  arguments: {
    list: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    },
    object: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    }
  }
};

const CLEAR_LIST_ACTION_INITIAL_STATE: ClearListActionType = {
  arguments: {
    list: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    }
  }
};

const BEGIN_CYCLE_ACTION_INITIAL_STATE: BeginCycleActionType = {
  arguments: {
    list: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    },
    object: {
      type: ARGUMENT_TYPES.VAR, // VAR | PARAM | FIXED
      value: '',
      objectNode: null
    }
  }
};

export const END_CYCLE_ACTION_INITIAL_STATE: EndCycleActionType = {};

export const SAVE_FILE_ACTION_INITIAL_STATE: SaveFileActionType = {
  objectId: '',
  arguments: {
    entity: {
      type: ARGUMENT_TYPES.VAR,
      value: '',
      objectNode: null
    },
    content: {
      type: ARGUMENT_TYPES.PARAM,
      value: '',
      objectNode: null
    }
  }
};

export const DELETE_FILE_ACTION_INITIAL_STATE: DeleteFileActionType = {
  objectId: '',
  arguments: {
    id: {
      type: ARGUMENT_TYPES.VAR,
      value: '',
      objectNode: null
    }
  }
};

export const READ_FILE_ACTION_INITIAL_STATE: ReadFileActionType = {
  objectId: '',
  arguments: {
    id: {
      type: ARGUMENT_TYPES.VAR,
      value: '',
      objectNode: null
    }
  }
};
export const CUSTOM_CODE_ACTION_INITIAL_STATE: CustomCodeActionType = {
  code: '',
  imports: []
};

export const CUSTOM_QUERY_ACTION_INITIAL_STATE: CustomQueryActionType = {
  query: ''
};

export const INITIAL_STATES_ACTIONS = {
  CREATE_ENTITY: CREATE_ENTITY_ACTION_INITIAL_STATE,
  READ_ENTITY: READ_ENTITY_ACTION_INITIAL_STATE,
  UPDATE_ENTITY: UPDATE_ENTITY_ACTION_INITIAL_STATE,
  DELETE_ENTITY: DELETE_ENTITY_ACTION_INITIAL_STATE,
  RUN_FUNCTION: RUN_FUNCTION_ACTION_INITIAL_STATE,
  SET_VARIABLE: SET_VARIABLE_ACTION_INITIAL_STATE,
  DECLARE_VARIABLE: DECLARE_VARIABLE_ACTION_INITIAL_STATE,
  ADD_LIST: ADD_LIST_ACTION_INITIAL_STATE,
  REMOVE_LIST: REMOVE_LIST_ACTION_INITIAL_STATE,
  CLEAR_LIST: CLEAR_LIST_ACTION_INITIAL_STATE,
  BEGIN_CYCLE: BEGIN_CYCLE_ACTION_INITIAL_STATE,
  END_CYCLE: END_CYCLE_ACTION_INITIAL_STATE,
  SAVE_FILE: SAVE_FILE_ACTION_INITIAL_STATE,
  DELETE_FILE: DELETE_FILE_ACTION_INITIAL_STATE,
  READ_FILE: READ_FILE_ACTION_INITIAL_STATE,
  CUSTOM_CODE: CUSTOM_CODE_ACTION_INITIAL_STATE,
  CUSTOM_QUERY: CUSTOM_QUERY_ACTION_INITIAL_STATE
};
