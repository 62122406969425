import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { t } from 'i18next';

export const EMPTY_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.EMPTY,
  name: t('Empty'),
  description: 'Empty action, to be replaced by any other',
  color: 'secondary',
  category: BACKEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.CYCLE,
  icon: null,
  inlineInputs: [],
  inputs: []
};
