import React from 'react';
import { AppTranslation } from '../../../../../../../modules/designer/types';
import { useTranslation } from 'react-i18next';
import TranslationInstance from './translation_instance';
import { LANGUAGES_PAGE_SIZE } from '../../index';
import useSetTableBodyData from './useSetTableBodyData';
import EditTranslationModal from '../../../../../../../modules/project/components/edit_translation_modal';

type TableBodyProps = {
  selectedPage: number;
  selectedLanguage: string;
  mainLanguageId: string;
  setTranslationsCount: (count: number) => void;
  showAddTranslationModal: boolean;
  setShowAddTranslationModal: (show: boolean) => void;
};

function TableBody({
  selectedPage,
  selectedLanguage,
  mainLanguageId,
  setTranslationsCount,
  showAddTranslationModal,
  setShowAddTranslationModal
}: TableBodyProps) {
  const { t } = useTranslation();
  const { mainTranslationsList, translations, setData } = useSetTableBodyData(
    selectedPage,
    selectedLanguage,
    mainLanguageId,
    setTranslationsCount
  );

  async function handleCloseAddTranslationModal() {
    setShowAddTranslationModal(false);
    await setData();
  }

  return (
    <>
      <tbody>
        {!mainTranslationsList || mainTranslationsList.length === 0 ? (
          <tr>
            <td id={'bodyMessage'} colSpan={4} style={{ textAlign: 'center' }}>
              {t('appResume.languages.NoTranslations')}
            </td>
          </tr>
        ) : (
          mainTranslationsList?.map((t: AppTranslation, index: number) => (
            <TranslationInstance
              key={index}
              translation={{
                id: translations.get(t.key)?.id ?? '',
                key: t.key,
                value: translations.get(t.key)?.value ?? ''
              }}
              index={LANGUAGES_PAGE_SIZE * (selectedPage - 1) + index + 1}
              selectedLanguage={selectedLanguage}
              mainLanguageId={mainLanguageId}
              handleOnTranslationsUpdate={setData}
            />
          ))
        )}
      </tbody>
      <EditTranslationModal
        onClose={handleCloseAddTranslationModal}
        showModal={showAddTranslationModal}
        languageId={selectedLanguage}
        mainLanguageId={mainLanguageId}
        translation={{ id: '', key: '', value: '' }}
      />
    </>
  );
}

export default TableBody;
