import { Action } from 'modules/logic_builder/types';
import * as React from 'react';
import { CustomQueryActionType } from '../../types';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import SessionContext from 'modules/auth/store';
import { t } from 'i18next';

type Props = {
  newAction: Action<CustomQueryActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<CustomQueryActionType>>>;
};

export default function RenderCustomQuery(props: Props) {
  const session = React.useContext(SessionContext);

  function handleEditorChange(value: string | undefined, event: editor.IModelContentChangedEvent) {
    props.onChange((currentVal) => {
      const auxVal = { ...currentVal };
      if (value) {
        auxVal.data.query = value;
      }
      // override the new Action
      return auxVal;
    });
  }

  return (
    <Editor
      height={'40vh'}
      defaultLanguage="sql" //ou pgsql
      defaultValue={`//${t('TypeQueryHere')}`}
      onChange={handleEditorChange}
      value={props.newAction.data.query}
      theme={session.preferences['exocode-theme'] === true ? 'vs-dark' : 'light'}
    />
  );
}
