import React from 'react';
import styles from '../editor_panel/styles.module.css';
import Icon from '../../../../../icon';

type AddParameterButtonProps = {
  handleClick: () => void;
};

function AddParameterButton({ handleClick }: AddParameterButtonProps) {
  return (
    <div onClick={handleClick} className={`${styles.AddParameterButton}`} id="createParamButton">
      <Icon iconName={'plus'} />
    </div>
  );
}

export default AddParameterButton;
