import React from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

export type OverflowItemProps = {
  type: 'auto' | 'hidden' | 'scroll' | 'visible';
  label: string;
  value: any;
  selectType: (overflowType: string) => void;
  orientation: 'horizontal' | 'vertical';
};
function OverflowItem({ type, label, value, selectType, orientation }: OverflowItemProps) {
  const { t } = useTranslation();
  let itemClass;

  switch (type) {
    case 'auto':
      itemClass = `${styles.overflowItem} ${styles.auto}`;
      break;
    case 'hidden':
      itemClass = `${styles.overflowItem} ${styles.hidden}`;
      break;
    case 'scroll':
      itemClass = `${styles.overflowItem} ${styles.scroll}`;
      break;
    default:
      itemClass = styles.overflowItem;
  }

  return (
    <div
      className={`d-flex flex-column justify-content-between ${styles.overflowItemWrapper} text-center`}
      onClick={() => selectType(type)}
    >
      <div
        className={`${
          value?.[`overflow${orientation === 'horizontal' ? 'x' : 'y'}`] === type
            ? 'border-primary'
            : ''
        } fw-2 mb-2 text-center border rounded ${itemClass}`}
      >
        <div
          className={`${orientation === 'horizontal' ? styles.horizontal : ''} ${
            styles.orientationWrapper
          } position-absolute w-100 h-100`}
        >
          <div className={`${styles.overflowContent}`}>
            <div className={`${styles.sampleContent1} ${styles.sampleColors}`}></div>
            <div className={`${styles.sampleContent2} ${styles.sampleColors}`}></div>
            <div className={`${styles.sampleContent3} ${styles.sampleColors}`}></div>
          </div>
          <div className={`${styles.scrollBar}`}>
            <div className={`${styles.scrollBarScroll}`}></div>
          </div>
        </div>
      </div>
      <label
        className={`${styles.overflowItemLabel} ${
          value?.[`overflow${orientation === 'horizontal' ? 'x' : 'y'}`] === type
            ? 'text-primary'
            : 'text-body-tertiary'
        }`}
      >
        {t('designer.right_side.controls.' + label)}
      </label>
    </div>
  );
}

export default OverflowItem;
