import React, { useCallback } from 'react';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';

interface ColorIconProps {
  colorKey?: string;
  colorValue: string;
  isSelected?: boolean;
  onClick?: (selectedColor: string) => void;
}

function ColorIcon({ colorKey, colorValue, isSelected, onClick }: ColorIconProps) {
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  const handleGetColor = useCallback(
    (colorValue: string) => {
      return theme.colors[colorValue] ? theme.colors[colorValue] : colorValue;
    },
    [theme]
  );

  const handleOnClick = () => {
    if (!onClick) return;
    onClick(colorKey ? colorKey : colorValue);
  };

  return (
    <div
      className={`${styles.colorIcon} ${isSelected && styles.selected}`}
      title={colorKey}
      style={{
        backgroundColor: handleGetColor(colorKey ? colorKey : colorValue)
          ? handleGetColor(colorKey ? colorKey : colorValue)
          : '#ffffff'
      }}
      onClick={handleOnClick}
    >
      {isSelected && (
        <span className={styles.selectedIcon}>
          <Icon iconName="check" />
        </span>
      )}
    </div>
  );
}

export default ColorIcon;
