import React, { ChangeEvent, useEffect } from 'react';
import { BootstrapColors, FormatterProps, FormatterTypes, PROGRESSBAR_MANIFEST } from '../../types';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../../../components/Popover';
import HelpIcon from '../../../components/HelpIcon';

function ProgressBarFormatter(props: FormatterProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.value && props.value.type !== FormatterTypes.PROGRESSBAR) {
      props.onChange && props.onChange(props.index, 'formatterParams', PROGRESSBAR_MANIFEST);
    }
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: string) {
    const updatedDatetimeParams = { ...props.value };
    if (e.target instanceof HTMLInputElement) {
      if (e.target.type === 'checkbox') {
        const checkbox = e.target as HTMLInputElement;
        updatedDatetimeParams[field] = checkbox.checked;
      } else {
        updatedDatetimeParams[field] = e.target.value;
      }
    } else if (e.target instanceof HTMLSelectElement) {
      updatedDatetimeParams[field] = e.target.value;
    }
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedDatetimeParams);
  }

  return (
    <div className="mb-3 border-bottom ">
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <Col sm={5}>
            <Form.Group>
              <Form.Label column>
                {t('designer.right_side.FormatterControls.ProgressBarFormatterMinTitle')}
              </Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.ProgressBarFormatterMinTitle') ||
                    'designer.right_side.FormatterControls.ProgressBarFormatterMinTitle',
                  description:
                    t('designer.right_side.FormatterControls.ProgressBarFormatterMinDescription') ||
                    'designer.right_side.FormatterControls.ProgressBarFormatterMinDescription'
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="number"
                placeholder="e.g.: 0"
                value={props.value && props.value.min}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e, 'min');
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={5}>
            <Form.Group>
              <Form.Label column>
                {t('designer.right_side.FormatterControls.ProgressBarFormatterMaxTitle')}
              </Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.ProgressBarFormatterMaxTitle') ||
                    'designer.right_side.FormatterControls.ProgressBarFormatterMaxTitle',
                  description:
                    t('designer.right_side.FormatterControls.ProgressBarFormatterMaxDescription') ||
                    'designer.right_side.FormatterControls.ProgressBarFormatterMaxDescription'
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="number"
                placeholder="e.g.: 100"
                value={props.value && props.value.max}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e, 'max');
                }}
              />
            </Form.Group>
          </Col>
        </div>

        <Form.Group className="mb-3">
          <Form.Label column>
            {t('designer.right_side.FormatterControls.ProgressBarFormatterVariant')}
          </Form.Label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.FormatterControls.ProgressBarFormatterVariantTitle') ||
                'designer.right_side.FormatterControls.ProgressBarFormatterVariantTitle',
              description:
                t('designer.right_side.FormatterControls.ProgressBarFormatterVariantDescription') ||
                'designer.right_side.FormatterControls.ProgressBarFormatterVariantDescription'
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
          <Form.Select
            placeholder="color"
            value={props.value && props.value.variant}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              handleChange(e, 'variant');
            }}
          >
            <option defaultChecked value={''}>
              {'---'}
            </option>
            {Object.values(BootstrapColors).map((color) => (
              <option key={color} value={color}>
                {color}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <div>
          <Form.Group className="d-flex me-2">
            <div className="form-check form-switch">
              <input
                id="animated-read"
                type="checkbox"
                onChange={(e) => {
                  handleChange(e, 'animated');
                }}
                checked={(props.value && props.value.animated) ?? false}
                className="form-check-input me-1"
                role="switch"
              />
            </div>
            <label htmlFor="filterable-read">
              {t('designer.right_side.FormatterControls.ProgressBarFormatterAnimated')}
            </label>
          </Form.Group>

          <Form.Group className="d-flex">
            <div className="form-check form-switch">
              <input
                id="label-read"
                type="checkbox"
                onChange={(e) => {
                  handleChange(e, 'label');
                }}
                checked={(props.value && props.value.label) ?? false}
                className="form-check-input me-1"
                role="switch"
              />
            </div>
            <label htmlFor="filterable-read">
              {t('designer.right_side.FormatterControls.ProgressBarFormatterLabel')}
            </label>
          </Form.Group>
          <Form.Group className="d-flex">
            <div className="form-check form-switch">
              <input
                id="stripe-read"
                type="checkbox"
                onChange={(e) => {
                  handleChange(e, 'striped');
                }}
                checked={(props.value && props.value.striped) ?? false}
                className="form-check-input me-1"
                role="switch"
              />
            </div>
            <label htmlFor="filterable-read">
              {t('designer.right_side.FormatterControls.ProgressBarFormatterStripe')}
            </label>
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default ProgressBarFormatter;
