import { Action } from 'redux';
import { EnumFrame, Schema } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

export default class Data {
  async getSchema(module_id: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const endpoint = `${API_URL}/modules/${module_id}/schema`;

    const response = await fetch(endpoint, options);
    const body = await response.json();

    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body;
  }

  async updateSchema(module_id: string, schema: Schema) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(schema)
    };

    const endpoint = `${API_URL}/modules/${module_id}/schema`;

    const response = await fetch(endpoint, options);
    const body = await response.json();

    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body;
  }

  async sendAction(module_id: string, schema_id: string, action: Action) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(action)
    };

    const endpoint = `${API_URL}/modules/${module_id}/schema/action`;

    return await fetch(endpoint, options).then((response) => response);
  }

  async getDataTypes(module_id: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const endpoint = `${API_URL}/modules/${module_id}/dataTypes`;

    const response = await fetch(endpoint, options);
    const body = await response.json();

    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body;
  }

  async getEnums(module_id: string): Promise<EnumFrame[]> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const requestUrl = `${API_URL}/modules/${module_id}/enums`;

    const response = await fetch(requestUrl, options);
    const body = await response.json();

    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body as EnumFrame[];
  }

  async getExternalTables(module_id: string, table_id: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const endpoint = `${API_URL}/modules/${module_id}/entities/${table_id}/available-entities`;

    const response = await fetch(endpoint, options);
    const body = await response.json();

    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body;
  }
}
