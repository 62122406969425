import { ComponentType, ReactNode } from 'react';
import PageView, { PageViewProps } from '../../../modules/designer/studio/frames/page_view';
import ModalFrame, { ModalViewProps } from '../../../modules/designer/studio/frames/modal_view';
import CustomComponentView, {
  CustomComponentViewProps
} from 'modules/designer/studio/frames/custom_component_view';
import LayoutFrame, { LayoutViewProps } from '../../../modules/designer/studio/frames/layout_view';
import FrameWrapper from '../../../modules/modeler/studio/frames/frame_wrapper';
import TableFrame, { TableFrameProps } from '../../../modules/modeler/studio/frames/table';
import EnumFrame, { EnumFrameProps } from '../../../modules/modeler/studio/frames/enum';
import WidgetsRenderer, { WidgetsRendererProps } from '../widgets/widgets_renderer';

export type FrameUUID = string;

export interface Frame {
  uuid: FrameUUID;
  type: string;
  posX?: number;
  posY?: number;
}

export enum FrameTypes {
  PAGE = 'PAGE',
  MODAL = 'MODAL',
  LAYOUT = 'LAYOUT',
  TABLE = 'TABLE',
  ENUM = 'ENUM',
  CUSTOM_COMPONENT = 'CUSTOM_COMPONENT',
  WIDGET = 'WIDGET'
}

export const FramesList: { [key: string]: ReactNode } = {
  [FrameTypes.PAGE]: PageView as ComponentType<PageViewProps>,
  [FrameTypes.MODAL]: ModalFrame as ComponentType<ModalViewProps>,
  [FrameTypes.LAYOUT]: LayoutFrame as ComponentType<LayoutViewProps>,
  [FrameTypes.CUSTOM_COMPONENT]: CustomComponentView as ComponentType<CustomComponentViewProps>,
  [FrameTypes.TABLE]: FrameWrapper(TableFrame as ComponentType<TableFrameProps>),
  [FrameTypes.ENUM]: FrameWrapper(EnumFrame as ComponentType<EnumFrameProps>),
  [FrameTypes.WIDGET]: WidgetsRenderer as ComponentType<WidgetsRendererProps>
};
