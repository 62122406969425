import { PropertiesState } from '../index';
import produce from 'immer';
import {
  ADD_PROPERTY,
  AddPropertyAction,
  DELETE_PROPERTY,
  DeletePropertyAction,
  SET_PROPERTIES,
  SetPropertiesAction,
  UPDATE_PROPERTY,
  UpdatePropertyAction
} from '../actions/properties';

type PropertiesActions =
  | SetPropertiesAction
  | AddPropertyAction
  | UpdatePropertyAction
  | DeletePropertyAction;

export const propertiesReducer = (
  state: PropertiesState = {},
  action: PropertiesActions
): PropertiesState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_PROPERTIES:
        return doSetProperties(draft, action);
      case ADD_PROPERTY:
        return doAddProperty(draft, action);
      case UPDATE_PROPERTY:
        return doUpdateProperty(draft, action);
      case DELETE_PROPERTY:
        return doDeleteProperty(draft, action);
      default:
        return draft;
    }
  });
};

function doSetProperties(_state: PropertiesState, action: SetPropertiesAction): PropertiesState {
  return action.payload.properties;
}

function doAddProperty(state: PropertiesState, action: AddPropertyAction): PropertiesState {
  state[action.payload.property.uuid] = action.payload.property;
  return state;
}

function doUpdateProperty(state: PropertiesState, action: UpdatePropertyAction): PropertiesState {
  state[action.payload.property.uuid] = action.payload.property;
  return state;
}

function doDeleteProperty(state: PropertiesState, action: DeletePropertyAction): PropertiesState {
  delete state[action.payload.property];
  return state;
}
