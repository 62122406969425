import React, { ComponentType, useState } from 'react';
import { DataTableColumn, TableAction } from '.';
import { Button, Form, Popover } from 'react-bootstrap';
import { FORMATTER_PREVIEW } from './formatters';
import { EDITOR_PREVIEW } from './editors';
import { faker } from '@faker-js/faker';
import TableActionsPreview from './actions';

export interface FakeLineProps {
  columns: DataTableColumn[];
  opacity: number;
  selectable?: boolean;
  editable?: boolean;
  selectType?: 'SINGULAR' | 'MULTI';
  index: number;
  actions: TableAction[];
}

function FakeLine(props: FakeLineProps) {
  const [words, setWords] = useState(faker.lorem.words());
  return (
    <tr style={{ opacity: props.opacity / 100 }}>
      {props.selectable && (
        <td>
          <Form.Check
            type="checkbox"
            id="custom-switch"
            checked={props.selectType === 'MULTI' ? props.index < 5 : props.index < 1}
          />
        </td>
      )}
      {props.columns
        .filter((column) => column.show)
        .map((column) => {
          let FormatterComponent: ComponentType<any> | undefined;
          let EditorComponent: ComponentType<any> | undefined;

          if (column.formatter && column.formatterParams) {
            FormatterComponent = FORMATTER_PREVIEW[
              column.formatterParams!.type
            ] as ComponentType<any>;
          }
          if (column.editable && column.type) {
            EditorComponent = EDITOR_PREVIEW[column.type] as ComponentType<any>;
          }
          return (
            <td key={column.title} className="text-center">
              <div>
                {column.formatter && column.formatterParams && FormatterComponent ? (
                  <FormatterComponent formatterParams={column.formatterParams} value={words} />
                ) : (
                  `${words}`
                )}
              </div>
            </td>
          );
        })}

      {props.actions && props.actions.length > 0 && <TableActionsPreview actions={props.actions} />}
    </tr>
  );
}

export default FakeLine;
