import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

export const NAV_MANIFEST: ComponentManifest = {
  type: 'NAV',
  name: 'Nav',
  descriptionId: 'NavDescription',
  description: 'A component to insert links in different ways.',
  icon: 'fa-solid fa-ship',
  group: ComponentGroups['MENU'],
  previewHeight: 70,
  previewWidth: 100,
  properties: [
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Direction',
      key: 'direction',
      controlOptions: ['left', 'center', 'right'],
      tooltip: 'designer.right_side.controls.components.nav.direction'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.nav.tooltip'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Vertical',
      key: 'column',
      tooltip: 'designer.right_side.controls.components.nav.column'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.LIST_LINKS,
      controlLabel: 'SettingLinks',
      key: 'links',
      tooltip: 'designer.right_side.controls.components.nav.links'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      tooltip: 'designer.right_side.controls.components.nav.textColor'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Font Weight',
      key: 'fontWeight',
      controlOptions: ['normal', 'bold', '100', '300', '500', '600', '800', '900'],
      tooltip: 'designer.right_side.controls.components.nav.fontWeight'
    },
    {
      controlType: ControlsTypes.TEXTALIGN,
      controlLabel: 'Text Align',
      key: 'textAlign',
      tooltip: 'designer.right_side.controls.components.nav.textAlign'
      // controlOptions: ['left', 'center', 'right']
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.nav.fontSize'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
