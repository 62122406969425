import * as React from 'react';
import styles from './styles.module.css';
import { JobStatus } from '../../../../modules/project/types';

type Props = {
  status: JobStatus;
};

function CircleStatus(props: Props) {
  switch (props.status) {
    case JobStatus.SUCCESS:
      return <div className={styles.greenBall} />;
    case JobStatus.WARNING:
      return <div className={styles.orangeBall} />;
    default:
      return <div className={styles.redBall} />;
  }
}

export default CircleStatus;
