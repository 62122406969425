import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

type FilterMockProps = {
  searchable: boolean;
};

function FilterMock({ searchable }: FilterMockProps) {
  if (!searchable) return null;
  return (
    <InputGroup className={'float-start'} style={{ width: '20%', minWidth: '140px' }}>
      <Form.Control type="text" placeholder="Search..." />
      <Button variant={'light'}>
        <span className={'fa fa-magnifying-glass text-primary'} />
      </Button>
    </InputGroup>
  );
}

export default FilterMock;
