import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useSession from 'hooks/useSession';
import {
  ContextRole,
  Role,
  RoleAuthorities,
  SystemRoleAuthorityName,
  UserProfile
} from '../types/auth_types';
import { AccountService } from '../services';
import { AppContext } from 'modules/project/store/app_context';

type AuthorizationProps = {
  has_studio?: boolean;
  allowedAuthorities?: RoleAuthorities[];
  context?: ContextRole;
  contextUuid?: string;
  contextId?: string;
  allowedSystemAuthorities?: SystemRoleAuthorityName[];
  fallback?: JSX.Element;
  children: JSX.Element;
};

export const Authorization = (props: AuthorizationProps) => {
  const { org_id, app_id } = useParams();
  const [session, loading] = useSession();
  const [accessGranted, setAccessGranted] = useState<boolean>();

  /**
   * Check if user has access to a certain action.
   *
   * @param user The user info.
   * @param contextRole The ContextRole: ORG | APP.
   * @param contextId The application id.
   * @param orgId The organization id.
   * @param allowedSystemAuthorities
   * @param allowedAuthorities
   * @returns True if user has access to the action. False if otherwise.
   */
  const checkAccess = (
    user: UserProfile,
    contextRole: ContextRole | undefined,
    contextId: string | undefined,
    orgId: string | undefined,
    allowedSystemAuthorities: SystemRoleAuthorityName[] | undefined,
    allowedAuthorities: RoleAuthorities[] | undefined,
    has_studio?: boolean
  ): boolean => {
    let canAccess = false;
    if (allowedSystemAuthorities) {
      const sysAuths: SystemRoleAuthorityName[] = [];
      user.systemRoles[0].systemRoleAuthorities.forEach((role) => {
        sysAuths.push(role.name);
      });
      canAccess = allowedSystemAuthorities.some((sysAuth) => sysAuths.includes(sysAuth));
      if (canAccess) return true;
    }
    if (contextRole && user.roles) {
      let role: Role | undefined = undefined;
      if (contextRole === ContextRole.APP) {
        role = user.roles.find((roleA) => {
          if (!roleA.contextUuid) return false;
          return roleA.context === ContextRole.APP && roleA.contextUuid === contextId;
        });
      } else if (contextRole === ContextRole.ORGANIZATION) {
        role = user.roles.find(
          (roleO) =>
            roleO.context === ContextRole.ORGANIZATION && roleO.contextId?.toString() === orgId
        );
      }
      if (!allowedAuthorities) {
        canAccess = role !== undefined;
      } else {
        if (role !== undefined && allowedAuthorities !== undefined) {
          canAccess = allowedAuthorities.some((authority) => {
            return role && role.roleAuthorities && role.roleAuthorities.includes(authority);
          });
        } else {
          canAccess = true; // If it does not pass the 'props.allowedAuthorities'.
        }
      }
      if (has_studio != undefined) {
        return has_studio;
      }
    }
    return canAccess;
  };

  const getUpdatedUserProfile = useCallback(async (): Promise<UserProfile> => {
    const updatedUser = await AccountService.getAccountInfo();
    return updatedUser;
  }, []);

  const checkUserHasAccess = useCallback(
    async (user: UserProfile): Promise<void> => {
      let contextId = app_id;
      if (!contextId) {
        contextId = props.contextUuid;
      }

      // First try.
      const canAccessFirstTry = checkAccess(
        user,
        props.context,
        contextId,
        org_id,
        props.allowedSystemAuthorities,
        props.allowedAuthorities
      );
      if (canAccessFirstTry) {
        setAccessGranted(true);
        return;
      }

      // Second try with updated user.
      const updatedUser = await getUpdatedUserProfile();
      if (updatedUser == null) {
        setAccessGranted(false);
        return;
      }
      const canAccessSecondTry = checkAccess(
        updatedUser,
        props.context,
        contextId,
        org_id,
        props.allowedSystemAuthorities,
        props.allowedAuthorities
      );
      setAccessGranted(canAccessSecondTry);
    },
    [
      app_id,
      props.context,
      props.allowedSystemAuthorities,
      props.allowedAuthorities,
      props.contextUuid,
      org_id,
      getUpdatedUserProfile
    ]
  );

  useEffect(() => {
    if (loading) return;
    // We already checked if user can access, just return.
    if (accessGranted !== undefined) return;
    // If no context is specified, and there is a user logged in then we don't have to validate the permissions.
    if (props.context === undefined && !props.allowedSystemAuthorities) return;
    // In this case, navigate to '/login'.
    if (!session.isLoggedIn || !session.user) return;

    checkUserHasAccess(session.user);
  }, [
    accessGranted,
    checkUserHasAccess,
    loading,
    props.allowedSystemAuthorities,
    props.context,
    session.isLoggedIn,
    session.user
  ]);

  if (loading) {
    return null;
  }

  if (!session.isLoggedIn || !session.user) {
    return <>{<Navigate to="/login" />}</>;
  }

  // If no context is specified, and there is a user logged in then we don't have to validate the permissions.
  if (
    !props.context &&
    (!props.allowedSystemAuthorities ||
      (props.allowedSystemAuthorities && !props.allowedSystemAuthorities.length))
  ) {
    return props.children;
  }

  return (
    <>
      {accessGranted === undefined && <></>}
      {accessGranted && props.children}
      {accessGranted === false && props.fallback}
    </>
  );
};
