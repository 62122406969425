import React, { useState } from 'react';
import styles from './styles.module.css';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { t } from 'i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type ImportsEditorProps = {
  customImports?: string[];
  onChange: (imports: string[]) => void;
};

function ImportsEditor({ customImports, onChange }: ImportsEditorProps) {
  const [value, setValue] = useState('');

  const handleIncludeImport = () => {
    if (!value) return;
    const newImports = customImports ? [...customImports] : [];
    if (newImports.includes(value)) return;
    newImports.push(value);
    onChange(newImports);
    setValue('');
  };

  const handleRemoveImport = (index: number) => {
    if (!customImports) return;
    if (index > customImports.length) return;
    const newImports = [...customImports];
    newImports.splice(index, 1);
    onChange(newImports);
  };

  const handleSwapElements = (index1: number, moveUp: boolean) => {
    const index2 = moveUp ? index1 + 1 : index1 - 1;
    if (
      !customImports ||
      index1 >= customImports.length ||
      index1 < 0 ||
      index2 >= customImports.length ||
      index2 < 0
    )
      return;

    const newImports = [...customImports];
    const temp = newImports[index1];
    newImports[index1] = newImports[index2];
    newImports[index2] = temp;

    onChange(newImports);
  };

  return (
    <div className={styles.ImportsEditor}>
      <div style={{ marginBottom: '1rem' }}>
        <Form>
          <InputGroup>
            <Form.Control
              value={value}
              onChange={(e) => setValue(e.target.value)}
              size="sm"
              placeholder={`${t('importHere')}`}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  handleIncludeImport();
                }
              }}
              autoComplete={'off'}
            />
            <Button style={{ opacity: '.9' }} onClick={handleIncludeImport}>
              {t('Add')}
            </Button>
          </InputGroup>
        </Form>
      </div>
      <ul
        style={{
          paddingLeft: '0',
          textAlign: 'left',
          width: '100%',
          flex: '1'
        }}
      >
        {customImports &&
          customImports.map((i, index) => (
            <li
              key={i}
              className={'bg-transparent mb-2 d-flex align-items-center justify-content-between'}
              style={{ padding: '0.1rem' }}
            >
              <span className={styles.MaxText}>import {i}</span>
              <div style={{ display: 'flex', marginLeft: '2px', gap: '0.2rem' }}>
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('modeler.Delete')!
                  }}
                >
                  <div
                    className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
                    onClick={() => handleRemoveImport(index)}
                  >
                    <span className={'fa fa-solid fa-trash'} />
                  </div>
                </HelpPopover>
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('MoveUp')!
                  }}
                >
                  <div
                    className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
                    onClick={() => handleSwapElements(index, false)}
                  >
                    <span className={'fa fa-arrow-up'} />
                  </div>
                </HelpPopover>
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('MoveDown')!
                  }}
                >
                  <div
                    className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
                    onClick={() => handleSwapElements(index, true)}
                  >
                    <span className={'fa fa-arrow-down'} />
                  </div>
                </HelpPopover>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default ImportsEditor;
