export type UserPersonalInfoProps = {
  step: number;
  setStep: any;
  name: string;
  setName: any;
};

export type UserRegisterProps = {
  step: number;
  setStep: any;
  email: string;
  setEmail: any;
  username: string;
  setUsername: any;
  pwd: string;
  setPwd: any;
  pwdConfirmation: string;
  setPwdConfirmation: any;
  name: string;
  loading: boolean;
  setLoading: any;
  redirectTo(): void;
};

export type PwdProps = {
  id: string;
  typeField: string;
  action: string; // login ou signup
  pwd: string;
  getPwd: any;
  confirmPassErr?: string;
};

export type UserProfile = {
  id: number;
  username: string;
  email: string;
  welcomeForm: string;
  // When the user visited a certain page.
  pageVisits?: Record<string, number>;
  name: string;
  gitHubIntegration: boolean;
  openAIKey?: string;
  deleteByDate: string;
  preferences: { [key: string]: any };
  systemRoles: SystemRole[];
  roles: Role[];
  totals: UserTotals;
  creationTime: Date;
  teams?: Teams[];
};

export type Teams = {
  id: number;
  name: string;
  orgId: number;
};

export type Team = {
  name: string;
  orgId: number;
};

export type MembersAndTeams = {
  users: UserProfile[];
  teams: Teams[];
};

export type UserPreferences = {
  key: string;
  value: any;
};

export type SystemRole = {
  id?: number;
  name: string;
  systemRoleAuthorities: SystemRoleAuthority[];
};

export type SystemRoleAuthority = {
  name: SystemRoleAuthorityName;
  subscriptionPlanLimits: SubscriptionPlanLimits;
  systemRoleId: number;
};

export type SubscriptionPlanLimits = {
  id: string;
  featureLimit: number;
};

export enum SystemRoleAuthorityName {
  ADD_ORGANIZATION = 'ADD_ORGANIZATION',
  ADD_APP = 'ADD_APP',
  ADD_USER = 'ADD_USER',
  UPLOAD_ASSETS = 'UPLOAD_ASSETS',
  GENERATE_CODE = 'GENERATE_CODE',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  AI_TOKEN_LIMIT = 'AI_TOKEN_LIMIT',
  MANAGE_LANGUAGES = 'MANAGE_LANGUAGES'
}

export type Role = {
  context: ContextRole;
  contextId?: number;
  contextUuid?: string;
  name: RoleOptions;
  roleAuthorities: RoleAuthorities[];
};

export enum ContextRole {
  APP = 'APP',
  ORGANIZATION = 'ORGANIZATION'
}

export enum RoleOptions {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  DEV = 'DEV',
  BILLING = 'BILLING'
}

export enum RoleAuthorities {
  // Org
  CREATE_TEAMS = 'CREATE_TEAMS',
  INVITE_USER = 'INVITE_USER',
  ADD_ADMIN = 'ADD_ADMIN',
  CREATE_CUSTOM_ROLE = 'CREATE_CUSTOM_ROLE',
  MANAGE_ORG = 'MANAGE_ORG',

  // APP
  MANAGE_APP = 'MANAGE_APP', // Create and update
  DELETE_APP = 'DELETE_APP',
  MANAGE_MODULE = 'MANAGE_MODULE',
  DELETE_MODULE = 'DELETE_MODULE',
  GENERATE_CODE = 'GENERATE_CODE',
  PUBLISH_APP = 'PUBLISH_APP',
  MANAGE_ACCESS = 'MANAGE_ACCESS',

  // CODE
  MANAGE_INTERFACE = 'MANAGE_INTERFACE', // Interface design
  MANAGE_ID_OPERATIONS = 'MANAGE_ID_OPERATIONS', // Functions, Variables, Events, Params in ID
  MANAGE_LOGIC_BUILDER = 'MANAGE_LOGIC_BUILDER',
  MANAGE_DB_MODELER = 'MANAGE_DB_MODELER',
  SHARE = 'SHARE',

  // MARKETPLACE
  MARKETPLACE = 'MARKETPLACE',

  // BILLING
  BILLING = 'BILLING', // Management/Update of Subscriptions, Approve Purchases
  PURCHASE = 'PURCHASE', // Purchase new modules, templates, etc.
  AUTOMATIC_PURCHASE = 'AUTOMATIC_PURCHASE',
  MANAGE_PAYMENT_METHODS = 'MANAGE_PAYMENT_METHODS',

  // SUPPORT
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  MANAGE_TEAM = 'MANAGE_TEAM'
}

export type UserTotals = {
  storageUsed: number;
  numberOfDailyCodeGenerations: number;
  numberOfInvitedUsers: number;
  numberOfCreatedApplications: number;
  limitOfApplications: number;
  numberOfUsedAiToken: number;
};

export type UserTeam = {
  id: number;
  username: string;
  email: string;
  roleInApp?: RoleOptions;
  roleInOrg?: RoleOptions;
  belongs: boolean;
  isIn: boolean;
};

export type UpdateUserApp = {
  usersToAdd: UserWithRole[];
  usersToRemove: UserWithRole[];
  teamsToAdd: number[];
  teamsToRemove: number[];
};

export type UserWithRole = {
  id: number;
  roleInApp: RoleOptions;
};
