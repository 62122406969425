import { DataTableData, DataTableStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const DATA_TABLE_TEMPLATE: LayoutComponent<DataTableData, DataTableStyles> = {
  uuid: 'empty',
  type: 'DATATABLE',
  data: {
    dataSource: {
      variable: '',
      columns: [],
      selectedItem: ''
    },
    sortable: false,
    selectColumnKeys: [],
    actions: [],
    width: 'fit-content',
    widthUnit: '',
    maxWidth: 'none',
    minWidth: 'none',
    height: 'fit-content',
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    optionSizeWidth: 'auto',
    optionSizeHeight: 'auto'
  },
  styles: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    variant: 'light',
    css: ''
  },
  events: {}
};
