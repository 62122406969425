import React from 'react';
import { ControlProps } from '../index';
import { DefaultLabel } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';
import { IconPicker } from 'packages/react-fa-icon-picker';

function IconPickerControl({ value, onChange, id, label, tooltip, errorMessages }: ControlProps) {
  const { t } = useTranslation();
  const handleChange = React.useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value, id);
      }
    },
    [id, onChange]
  );

  return (
    <div
      className="mb-3"
      style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
    >
      <div style={{ marginBottom: '.5rem' }}>
        <DefaultLabel
          style={{ display: 'flex', justifyContent: 'flex-start', whiteSpace: 'nowrap' }}
        >
          {label}
          {tooltip && (
            <HelpPopover
              placement="top"
              helpBoxProps={{
                description: t(`${tooltip}`) ?? ''
              }}
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </DefaultLabel>
      </div>
      <div className="border-1 border p-1 rounded" style={{ width: 'fit-content' }}>
        <IconPicker
          value={value}
          onChange={handleChange}
          containerStyles={{ left: '0px', display: 'flex', top: '-30px' }}
          buttonIconStyles={{
            color: 'var(--bs-secondary-color)!important',
            border: '1px',
            fontSize: '1.25rem',
            display: 'flex'
          }}
          buttonStyles={{
            border: 0,
            padding: '0',
            width: '1.5rem',
            height: '1.5rem',
            minHeight: '0',
            fontSize: '1rem'
          }}
        />
      </div>
    </div>
  );
}

export default IconPickerControl;
