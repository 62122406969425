import React, { useContext, useEffect, useState } from 'react';
import styles from 'modules/logic_builder/styles.module.css';
import { Button, Form, Col, Row } from 'react-bootstrap';
import LogicBuilderContext from '../../../store';
import EmptyMessage from 'web_ui/empty';
import { FunctionCreatorDialog } from 'modules/logic_builder/components/dialogs/function_creator_dialog';
import { useParams } from 'react-router-dom';
import { FunctionsList } from 'modules/logic_builder/components/functions_list';
import SearchBar from 'web_ui/search_bar';
import { ServiceCreatorDialog } from 'modules/logic_builder/components/dialogs/service_creator_dialog';
import { ServiceEditorDialog } from 'modules/logic_builder/components/dialogs/service_editor_dialog';
import Icon from 'web_ui/icon';
import { ObjectsService } from '../../../services';
import { Table } from '../../../../modeler/types';
import { FunctionService } from 'modules/logic_builder/services';
import { t } from 'i18next';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';

type LogicBuilderProps = Record<string, unknown>;

function ServiceEditor(props: LogicBuilderProps) {
  const [showServiceCreatorModal, setShowServiceCreatorModal] = useState(false);
  const [showServiceEditorModal, setShowServiceEditorModal] = useState(false);
  const [showFunctionCreatorDialog, setShowFunctionCreatorDialog] = useState(false);
  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);
  const { service_id, module_id } = useParams();
  const { services, functions, fetchFunctions } = useContext(LogicBuilderContext);
  const [filter, setFilter] = useState('');
  const selectedService = services[service_id ?? ''];
  const [serviceName, setServiceName] = useState('');
  const [tables, setTables] = useState<Table[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setServiceName(services[service_id ?? '']?.name ?? '');
  }, [service_id, services]);

  useEffect(() => {
    if (!module_id) return;

    ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setTables(schema?.tables ? schema.tables : []);
    });
  }, [module_id]);

  async function handleDelete(functionId: string) {
    if (isLoading) return;
    if (!module_id) return;

    setIsLoading(true);
    await FunctionService.deleteFunction(functionId)
      .then(() => {
        fetchFunctions(module_id);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className={styles.LogicEditorWrapper}>
      {/* Service Wrapper*/}
      <Row className="mb-3">
        <Col sm={7}>
          <Form.Group className="d-flex align-items-center mb-3">
            <Form.Label style={{ marginRight: '1rem', minWidth: '5rem' }}>
              {t('logicBuilder.service.Service')}
            </Form.Label>
            <Form.Control
              id={'serviceName'}
              onChange={(e) => setServiceName(e.target.value)}
              value={serviceName}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={5}>
          <div className={styles.editButtonWrapper}>
            {selectedService && !selectedService.native && (
              <Button
                id={'editServiceButton'}
                variant="primary"
                onClick={() => setShowServiceEditorModal(true)}
                disabled={!selectedService}
              >
                <Icon iconName="pen-to-square" extraProps="pe-1"></Icon>
                {t('designer.componentCardDropdown.Edit')}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <hr />
      {/* Functions Wrapper */}
      {selectedService?.uuid && (
        <div className={styles.LogicEditorListWrapper}>
          {/* Search bar*/}
          <div className={styles.LogicBuilderControl}>
            <Form className={styles.LogicBuilderControlSearch}>
              <SearchBar
                id={'searchField'}
                placeholder={t('Search') ?? ''}
                text={filter}
                setText={setFilter}
                focus={true}
              />
            </Form>
            <div className={styles.LogicBuilderControlButtons}>
              {selectedService && !selectedService.native && (
                <>
                  <Button
                    id="codePreview"
                    className={styles.ControlButton}
                    variant="primary"
                    onClick={() => setShowCodePreviewDialog(true)}
                  >
                    <Icon iconName="code" extraProps="pe-1"></Icon>
                    {t('CodePreview')}
                  </Button>
                  <Button
                    id={'createButton'}
                    className={styles.ControlButton}
                    variant="primary"
                    onClick={() => setShowFunctionCreatorDialog(true)}
                  >
                    <Icon iconName="plus" extraProps="pe-1"></Icon>
                    {t('organizations.new_organization.Create')}
                  </Button>
                </>
              )}
            </div>
          </div>
          {/* Functions */}
          <div>
            <FunctionsList
              key={selectedService.uuid}
              serviceUuid={selectedService.uuid}
              search={filter.toLowerCase().trim()}
              functions={functions[selectedService.uuid] ?? []}
              onCreate={() => setShowFunctionCreatorDialog(true)}
              onDelete={handleDelete}
            />
            <ServiceEditorDialog
              serviceUuid={selectedService.uuid}
              show={showServiceEditorModal}
              onClose={() => setShowServiceEditorModal(false)}
            />
            <FunctionCreatorDialog
              serviceUuid={selectedService.uuid}
              show={showFunctionCreatorDialog}
              onClose={() => setShowFunctionCreatorDialog(false)}
            />
          </div>
        </div>
      )}

      {(!selectedService || !selectedService.uuid) && (
        <div className="w20 h100">
          <EmptyMessage
            message={t('logicBuilder.NoServiceSelected')}
            icon="arrow-left"
            actionMessage={t('logicBuilder.CreateService') ?? ''}
            linkAction={() => setShowServiceCreatorModal(true)}
          ></EmptyMessage>
          <ServiceCreatorDialog
            tables={tables}
            show={showServiceCreatorModal}
            onClose={() => setShowServiceCreatorModal(false)}
          />
        </div>
      )}
      {selectedService?.uuid && (
        <CodeEditorModal
          show={showCodePreviewDialog}
          handleClose={() => setShowCodePreviewDialog(false)}
          id={selectedService.uuid}
          previewType={CodePreviewType.SERVICE}
        />
      )}
    </div>
  );
}

export default ServiceEditor;
