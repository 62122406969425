import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Nav, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { OrganizationContext } from '../../../organization_page';
import InviteOrganizationModal from 'routes/organizations/components/invite_organization_modal';
import { ContextRole, Role, RoleAuthorities, UserProfile } from 'modules/auth/types/auth_types';
import {
  AdminRole,
  ManagerRole,
  DevRole,
  BillingRole,
  FiltersMembersOptions
} from 'modules/organization/types';
import { OrganizationService } from 'modules/organization/services';
import MembersList from 'modules/organization/components/members_list';
import { AddInvite, ComplexMember } from 'modules/invitation/types';
import { InvitationService } from 'modules/invitation/service';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

type options = 'Collaborators' | 'Pending';

function OrganizationMembers() {
  const [session] = useSession();
  const orgContext = useContext(OrganizationContext);
  const [simpleMembers, setSimpleMembers] = useState<AddInvite[]>([]);
  const [showInviteModal, setShowInviteModal] = React.useState<boolean>(false);
  const [complexMembers, setComplexMembers] = React.useState<ComplexMember[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState<FiltersMembersOptions>(FiltersMembersOptions.NAME);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingRoles, setLoadingRoles] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { org_id } = useParams();

  const [selectedItem, setSelectedItem] = React.useState<options>();

  const { t } = useTranslation();

  const search = t('Search');
  const filter = t('Filter');

  useEffect(() => {
    findRoles();
  }, []);

  useEffect(() => {
    const location_option = location.pathname.split('/').pop();
    if (location_option === 'pending') setSelectedItem('Pending');
    else if (location_option === 'collaborators') setSelectedItem('Collaborators');
    else {
      setSelectedItem('Collaborators');
      navigate(`/organization/${org_id}/members/collaborators`);
    }
  }, []);

  /**
   * Fetch custom roles of organization
   */
  const findRoles = async () => {
    const canSeeAdmin = session.user?.roles.some((roleO) => {
      return (
        roleO.context === ContextRole.ORGANIZATION &&
        roleO.contextId?.toString() === org_id &&
        roleO?.roleAuthorities.includes(RoleAuthorities.ADD_ADMIN)
      );
    });
    orgContext.setRoles(canSeeAdmin ? [AdminRole] : []);
    orgContext.setRoles((prev) => prev.concat([ManagerRole, DevRole, BillingRole]));
    try {
      setLoadingRoles(true);
      if (orgContext.organization) {
        const roles = await OrganizationService.getCustomRolesOfOrg(orgContext.organization.id);
        await roles.map((role) => (role.isCustom = true));
        orgContext.setRoles((prev) => prev.concat(roles));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRoles(false);
    }
  };

  useEffect(() => {
    reloadSelectedList();
  }, [selectedItem]);

  const reloadSelectedList = async () => {
    if (selectedItem === 'Collaborators') {
      findMembers();
    } else if (selectedItem === 'Pending') {
      findMembersPending();
    }
  };

  /**
   * Fetch organizations from the current user
   */
  const findMembers = async () => {
    try {
      setLoading(true);
      if (orgContext.organization) {
        const orgId: number = orgContext.organization.id;
        const members = await OrganizationService.getMembersOfOrg(orgId);
        const simpleMembers: AddInvite[] = members.map((m) => {
          return convertToSimpleMember(m, orgId);
        });
        setSimpleMembers([...simpleMembers]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const convertToSimpleMember = (user: UserProfile, orgId: number): AddInvite => {
    return {
      email: user.email,
      nameRole: getRoleOrg(user.roles, orgId).name,
      username: user.username,
      userId: user.id
    };
  };

  const getRoleOrg = (roles: Role[], orgId: number): Role => {
    return roles.filter((role) => {
      return role.context === ContextRole.ORGANIZATION && role.contextId === orgId;
    })[0];
  };

  const findMembersPending = async () => {
    try {
      setLoading(true);
      if (orgContext.organization) {
        const orgId: number = orgContext.organization.id;
        const gettingMembers = await InvitationService.getInvitesOrg(orgId);
        setComplexMembers(gettingMembers);
        const simpleMembers: AddInvite[] = gettingMembers.map((m) => {
          return convertComplexToSimpleMember(m, orgId);
        });
        setSimpleMembers([...simpleMembers]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const convertComplexToSimpleMember = (inviteMember: ComplexMember, orgId: number): AddInvite => {
    return {
      email: inviteMember.email,
      nameRole: inviteMember.roleName,
      username: '',
      userId: inviteMember.id
    };
  };
  const deleteMember = async (member: AddInvite) => {
    if (selectedItem === 'Pending') {
      try {
        if (orgContext.organization && member.userId) {
          await InvitationService.deleteInvite(orgContext.organization.id, member.userId);
        }
        reloadSelectedList();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    } else {
      try {
        setLoading(true);
        if (orgContext.organization && member.userId !== undefined) {
          await OrganizationService.deleteMemberOfOrg(orgContext.organization.id, member.userId!);
        }
      } catch (error) {
        console.error(error);
        alert(error);
      } finally {
        findMembers();
        setLoading(false);
      }
    }
  };

  const changeRole = async (role: string, member: AddInvite) => {
    try {
      setLoading(true);
      if (orgContext.organization && member.userId !== undefined) {
        const simpleMember = await OrganizationService.updateMemberOfOrg(
          orgContext.organization.id,
          member.userId,
          role
        );
        const index = simpleMembers.findIndex((value) => value.userId === member.userId);
        simpleMembers[index] = convertToSimpleMember(simpleMember, orgContext.organization.id);
        setSimpleMembers([...simpleMembers]);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col className={`d-flex align-items-center`}>
          <h4 style={{ fontWeight: '400', margin: 0 }}>{t('organizations.organization.member')}</h4>
        </Col>
        <Col>
          <div className={'d-flex justify-content-end'}>
            <Button id={'inviteButton'} onClick={() => setShowInviteModal(true)}>
              {t('invitation.Invite')}
            </Button>
          </div>
        </Col>
      </Row>
      <div className="pt-3">
        <Card>
          <Card.Body>
            <div className={`mb-4 ${styles.teamOptions}`}>
              <Nav
                variant="pills"
                className={`py-2 ps-2 ${styles.navItems}`}
                defaultActiveKey="#first"
              >
                <Nav.Item>
                  <Nav.Link
                    id="collaboratorsButton"
                    className={`text-body-emphasis bg-body ${
                      selectedItem === 'Collaborators' ? styles.active : ''
                    }`}
                    onClick={() => {
                      navigate(`/organization/${org_id}/members/collaborators`);
                      setSelectedItem('Collaborators');
                    }}
                  >
                    {t('organizations.organization.Collaborators')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    id="pendingButton"
                    className={`text-body-emphasis bg-body ${
                      selectedItem === 'Pending' ? styles.active : ''
                    }`}
                    onClick={() => {
                      navigate(`/organization/${org_id}/members/pending`);
                      setSelectedItem('Pending');
                    }}
                    active={selectedItem === 'Pending'}
                  >
                    {t('organizations.organization.Pending')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Row className="d-flex align-items-center p-2">
              <Col md={4}>
                <input
                  id={'searchField'}
                  type={'search'}
                  className="form-control"
                  placeholder={search}
                  onChange={(ev) => setSearchText(ev.target.value)}
                />
              </Col>
              <Col md={8}>
                {selectedItem === 'Collaborators' && (
                  <div className="d-flex align-items-center justify-content-end">
                    <h6 className="ps-1 pe-1" style={{ margin: 0 }}>
                      {t('OrderBy')}
                    </h6>
                    <div className="ps-1">
                      <select
                        id={'filterDropdown'}
                        className={`form-select form-select-sm`}
                        placeholder={filter}
                        defaultValue={filterType}
                        onChange={(e) => {
                          setFilterType(e.target.value as FiltersMembersOptions);
                        }}
                      >
                        {Object.values(FiltersMembersOptions)
                          .filter((v) => isNaN(Number(v)))
                          .map((type) => {
                            return (
                              <option id={type} key={type} value={type}>
                                {t('order_options.' + type)}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {/*<div className="ps-1 pe-1">
                    <Button
                      id="filter-button"
                      variant="secondary"
                      // onClick={handleFilter}
                    >
                      {t('Filter')}
                    </Button>
                      </div>*/}
                  </div>
                )}
              </Col>
            </Row>
            <div className="pt-4">
              {isLoading || isLoadingRoles ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <MembersList
                  members={simpleMembers
                    .sort((a, b) =>
                      selectedItem === 'Collaborators' && filterType === FiltersMembersOptions.NAME
                        ? a.username &&
                          b.username &&
                          a.username.toLocaleLowerCase() > b.username.toLocaleLowerCase()
                          ? 1
                          : -1
                        : a.email.split('@')[0].toLocaleLowerCase() >
                          b.email.split('@')[0].toLocaleLowerCase()
                        ? 1
                        : -1
                    )
                    .filter((item) => {
                      return (
                        item.username != orgContext.organization?.owner.username &&
                        (!searchText ||
                          (selectedItem === 'Collaborators' &&
                            item.username &&
                            item.username
                              .toLocaleLowerCase()
                              .includes(searchText.toLocaleLowerCase())) ||
                          (selectedItem === 'Pending' &&
                            item.email
                              .toLocaleLowerCase()
                              .includes(searchText.toLocaleLowerCase())))
                      );
                    })}
                  showUsername={selectedItem === 'Collaborators'}
                  inModal={false}
                  onDeleteMember={deleteMember}
                  onChangeRole={changeRole}
                  listRoles={orgContext.roles}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      <InviteOrganizationModal
        showModal={showInviteModal}
        onCloseRequest={() => {
          setShowInviteModal(false);
        }}
        listRoles={orgContext.roles}
        reloadSelectedList={() => {
          reloadSelectedList();
        }}
      />
    </>
  );
}

export default OrganizationMembers;
