type Color = string;

interface ExoColor {
  white: Color;
  gray1: Color;
  gray2: Color;
  gray3: Color;
  black: Color;
  red1: Color;
  red2: Color;
  red: Color;
  red3: Color;
  red4: Color;
  green1: Color;
  green2: Color;
  green: Color;
  green3: Color;
  green4: Color;
  blue1: Color;
  blue2: Color;
  blue: Color;
  blue3: Color;
  blue4: Color;
  purple1: Color;
  purple2: Color;
  purple: Color;
  purple3: Color;
  purple4: Color;
  pink1: Color;
  pink2: Color;
  pink: Color;
  pink3: Color;
  pink4: Color;
}

export const defaultColors: ExoColor = {
  white: '#f4f4f5',
  gray1: '#d4d4d8',
  gray2: '#71717a',
  gray3: '#3f3f47',
  black: '#18181b',
  red1: '#ffe2e3',
  red2: '#fda5a5',
  red: '#ef4444',
  red3: '#b91c1b',
  red4: '#7f1e1d',
  green1: '#dcfce8',
  green2: '#86f0ad',
  green: '#22c55f',
  green3: '#17803d',
  green4: '#14532d',
  blue1: '#dbeafe',
  blue2: '#94c5fd',
  blue: '#3b83f6',
  blue3: '#1e4ed8',
  blue4: '#1f3a8a',
  purple1: '#f3e9ff',
  purple2: '#d8b5fe',
  purple: '#a856f7',
  purple3: '#7e22cf',
  purple4: '#581c87',
  pink1: '#fde7f4',
  pink2: '#f9a9d4',
  pink: '#ec4899',
  pink3: '#bf185d',
  pink4: '#831943'
};
