import { Relationship } from '../../../../../../../types';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';
import React from 'react';
import useSetRelationshipTableName from '../../hooks/useSetRelationshipTableName';
import { useTranslation } from 'react-i18next';

type FromInputProps = { relationship: Relationship };

export default function FromInput({ relationship }: FromInputProps) {
  const tableName = useSetRelationshipTableName(relationship, 'from');
  const { t } = useTranslation();

  return (
    <Form.Group className="col-sm-6" controlId="relationFrom">
      <Form.Label className={styles.SmallFont}>{t('modeler.From')}</Form.Label>
      <Form.Control
        value={tableName}
        placeholder={t('modeler.From') ?? ''}
        type="text"
        size="sm"
        disabled={true}
      />
    </Form.Group>
  );
}
