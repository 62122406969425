import React, { ChangeEvent, useEffect } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultLabel, DefaultInput } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function ReadOnlyControl(props: ControlProps) {
  const [val, setVal] = React.useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  const handleChange = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
    if (props.onChange) props.onChange(e.target.value, props.id);
  }, []);

  return (
    <div
      className="mb-3"
      style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
    >
      <div style={{ display: 'flex', marginBottom: '.5rem' }}>
        <DefaultLabel
          title={props.label}
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {props.label}
        </DefaultLabel>
        {props.tooltip && (
          <HelpPopover
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>
      <div className={styles.defaultInput}>
        <DefaultInput
          title={val ?? '("myText").readOnly = true'}
          id={`'input'${val}`.replace(/[^a-z0-9_.]/g, '')}
          type="text"
          className={`${styles.defaultInput} form-control form-control-sm`}
          style={{ width: '85%', height: '30px' }}
          placeholder={`("myText").readOnly = true`}
          value={val ?? ''}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default ReadOnlyControl;
