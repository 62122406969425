import { AppTypeOption } from './index';
import { AppType, CustomApp } from 'modules/project/enum';

export const FullAppList: AppTypeOption<AppType>[] = [
  {
    id: AppType.WEBAPP,
    type: AppType.WEBAPP,
    i18nKey: 'newProjectWizard.WebApplication',
    icon: 'desktop'
  },
  {
    id: AppType.MOBILE,
    type: AppType.MOBILE,
    i18nKey: 'newProjectWizard.Mobile',
    icon: 'mobile',
    unavailable: true
  }
];

export const CustomAppList: AppTypeOption<CustomApp>[] = [
  {
    id: CustomApp.DATABASE,
    type: CustomApp.DATABASE,
    i18nKey: 'newProjectWizard.Database',
    icon: 'database',
    unavailable: false
  },
  {
    id: CustomApp.BACKEND,
    type: CustomApp.BACKEND,
    i18nKey: 'newProjectWizard.Backend',
    icon: 'gear',
    unavailable: false
  },
  {
    id: CustomApp.FRONTEND,
    type: CustomApp.FRONTEND,
    i18nKey: 'newProjectWizard.Frontend',
    icon: 'object-group',
    unavailable: false
  }
];
