import React, { useContext } from 'react';
import { ThemeValues } from 'modules/designer/types';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import SessionContext from 'modules/auth/store';

export type ThemeColorSectionProps = {
  themeValues: ThemeValues[];
  updateValue: (newValue: string, key: string) => void;
};

export function ThemeColorSection(props: ThemeColorSectionProps) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  return (
    <>
      {props.themeValues.map((themeValue) => {
        return (
          <div
            key={themeValue.key}
            className={`${styles.colorContainer} col-6 col-sm-4 col-md-4 col-lg-4 mb-4`}
          >
            <div className={`${styles.colorWrapper}  pb-1`}>
              <div
                style={{ backgroundColor: themeValue.value }}
                className={`${styles.colorPreview}`}
              >
                <input
                  className={`${styles.colorPicker}`}
                  type="color"
                  value={themeValue.value}
                  onChange={(ev) => {
                    props.updateValue(ev.target.value, themeValue.key);
                  }}
                />
              </div>
              <div className={`${styles.colorProperty}`}>
                <input
                  id={`colorInput_${themeValue.key.toLowerCase()}`}
                  type="text"
                  value={themeValue.value}
                  onChange={(ev) => {
                    props.updateValue(ev.target.value, themeValue.key);
                  }}
                />
              </div>
            </div>
            <div className={`${styles.colorKey} text-center`}>
              <label
                htmlFor="colorpicker"
                style={
                  session && session.preferences['exocode-theme'] === true ? { color: 'white' } : {}
                }
              >
                {themeValue.key === 'BACKGROUND_COLOR'
                  ? t('designer.themes.background')
                  : t('designer.themes.' + themeValue.key)}
              </label>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ThemeColorSection;
