import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { ObjectsService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import Confirmation from 'web_ui/confirmation';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ObjectEditor } from '../../object_editor';
import { useTranslation } from 'react-i18next';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { DataType, ObjectSimple } from '../../../types';
import { Entity } from 'routes/automation_wizard/types';
import { ModuleInfo } from '../../../../dashboard/types';
import {
  buildObject,
  buildObjectsForEntityMode,
  PreSelectedEntity,
  Property
} from '../../object_editor/editor_utils';
import { validateObjectName } from 'utils/inputValidation';
import { CrudData } from 'routes/automation_wizard/components/wizard_steps/crud';
import { PopupAlert } from '../../../../../web_ui/popup_alert';

type ObjectDialogProps = {
  show: boolean;
  onClose: () => void;
  objectId?: string;
  editMode: boolean;
  entityMode: boolean;
  dialogTitle: string;
  modules: ModuleInfo[];
  columns: Column[];
  tables: Table[];
  relationships: Relationship[];
  enums: EnumFrame[];
  preselectedEntity?: string;
  crudEntityList?: Entity[];
  loadingData?: boolean;
  gettingObjects?: (createdObj?: string[]) => void;
  objectsWithDeps?: ObjectSimple[];
  crudData?: CrudData;
};

export function ObjectDialog(props: ObjectDialogProps) {
  const { t } = useTranslation();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [objectName, setObjectName] = useState('');
  const [objectDescription, setObjectDescription] = useState('');
  const [isNative, setIsNative] = useState(false);
  const [isBackend, setIsBackend] = useState(true);
  const [isFrontend, setIsFrontend] = useState(true);
  const { fetchObjects } = useContext(LogicBuilderContext);
  const { module_id, view_id } = useParams();
  const [objects, setObjects] = useState<ObjectSimple[]>(
    props.objectsWithDeps ? props.objectsWithDeps : []
  );
  const [moduleObjects, setModuleObjects] = useState<ObjectSimple[]>([]);

  const [entityMode, setEntityMode] = useState(props.entityMode);
  const [properties, setProperties] = useState<Record<string, Property[]>>();
  const [rootObject, setRootObject] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [entityId, setEntityId] = useState<string>();
  const [fieldErrorMessage, setFieldErrorMessage] = useState<string>('');
  const [inputNameErrorMessage, setInputNameErrorMessage] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState('');

  // This is only used to show the entity name in editMode.
  const [preSelectedEntity, setPreSelectedEntity] = useState<PreSelectedEntity>(
    props.preselectedEntity && props.crudEntityList
      ? {
          id: props.preselectedEntity,
          name:
            props.crudEntityList.find((ent) => ent.entityUuid == props.preselectedEntity)
              ?.entityName ?? ''
        }
      : { id: '', name: '' }
  );

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  function objectEditorOnChange(properties: Record<string, Property[]>) {
    setProperties(properties);
  }

  useEffect(() => {
    if (props.objectsWithDeps) setObjects(props.objectsWithDeps);
  }, [props.objectsWithDeps]);

  const fetchObject = useCallback(async () => {
    if (!props.objectId) return;
    await ObjectsService.getObject(props.objectId).then((fetchedObject) => {
      const propertiesList: Property[] = [];
      const columnsList: string[] = [];

      let isCompleteObject = false;
      for (const schemaItem of Object.values(fetchedObject.items)) {
        const prop: Property = {
          id: schemaItem.uuid ?? uuidv4(),
          type: schemaItem.type,
          name: schemaItem.name,
          description: schemaItem.description || '',
          list: schemaItem.list,
          readOnly: schemaItem.readOnly || false,
          exported: schemaItem.exported,
          exportedName: schemaItem.exportedName || '',
          object: schemaItem.objectUuid,
          enum: schemaItem.enumUuid,
          fromEntity: !!schemaItem.columnUuid,
          relationshipId: schemaItem.relationshipUuid,
          columnId: schemaItem.columnUuid,
          checked: true,
          isFK: false,
          isPK: false,
          itemOrder: schemaItem.itemOrder,
          required: false
        };

        if (schemaItem.objectUuid) {
          const findObject = objects?.find((o) => o.uuid === schemaItem.objectUuid);
          if (findObject) {
            prop.objectName = findObject.name;
            if (findObject.moduleUuid !== module_id) {
              const module = props.modules?.find((mod) => mod.id === findObject.moduleUuid);
              prop.moduleName = module?.name;
            }
          }
        } else if (schemaItem.enumUuid) {
          const findEnum = props.enums.find((e) => e.uuid === schemaItem.enumUuid);
          prop.enumName = findEnum?.content.data.name;
        }

        if (schemaItem.columnUuid) {
          columnsList.push(schemaItem.columnUuid);
        }
        if (schemaItem.relationshipUuid) {
          isCompleteObject = true;
        }

        const column = props.columns.find((c) => c.uuid === schemaItem.columnUuid);
        if (column) {
          prop.isFK = column.isFK;
          prop.isPK = column.isPK;
        }

        propertiesList.push(prop);
      }

      let lastOrder = 0;
      if (propertiesList.length) {
        propertiesList.sort((a, b) => a.itemOrder - b.itemOrder);
        lastOrder = propertiesList[propertiesList.length - 1].itemOrder;
      }

      if (fetchedObject.entityUuid) {
        const table = props.tables.find((t) => t.uuid === fetchedObject.entityUuid);
        if (table) {
          setPreSelectedEntity({ id: table.uuid, name: table.content.data.name });

          const columns = props.columns.filter((t) => t.tableUUID === table.uuid);

          for (const column of columns) {
            if (columnsList.includes(column.uuid)) continue;
            if (isCompleteObject && column.isFK) continue; // ignore FK columns given the object includes entity related sub-objects.

            lastOrder++;
            const prop: Property = {
              id: uuidv4(),
              name: column.name,
              description: column.description,
              type: column.type as DataType,
              list: false,
              readOnly: false,
              exported: true,
              exportedName: column.name,
              checked: false,
              entityName: table.content.data.name,
              fromEntity: true,
              isFK: column.isFK,
              isPK: column.isPK,
              columnId: column.uuid,
              entityId: entityId,
              itemOrder: lastOrder,
              enum: column.enumUUID,
              required: false
            };

            if (prop.enum) {
              const findEnum = props.enums.find((e) => e.uuid === prop.enum);
              prop.enumName = findEnum?.content.data.name;
            }

            propertiesList.push(prop);
          }
        }
      }

      setObjectName(fetchedObject.name);
      setObjectDescription(fetchedObject.description);
      setProperties({ [fetchedObject.uuid || '0']: propertiesList });
      setRootObject(fetchedObject.uuid);
      setEntityId(fetchedObject.entityUuid);
      setIsNative(fetchedObject.native || false);
      setIsBackend(fetchedObject.backend);
      setIsFrontend(fetchedObject.frontend);
    });
  }, [
    entityId,
    objects,
    props.columns,
    props.enums,
    props.objectId,
    props.tables,
    props.modules,
    module_id
  ]);

  const generetingNameObjFromAutomation = () => {
    if (!props.crudData || !props.crudData.entityUuid) {
      return '';
    }
    const entityName = props.crudData.entities[props.crudData.entityUuid].entityName;
    if (entityName) {
      return entityName[0].toUpperCase() + entityName.slice(1).toLowerCase();
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!props.show) return;

    setFieldErrorMessage('');
    setInputNameErrorMessage('');
    if (props.editMode) {
      fetchObject();
    } else {
      if (preSelectedEntity.id) {
        setRootObject(preSelectedEntity.id);
      } else {
        const newUUID = uuidv4();
        setRootObject(newUUID);
        setProperties({ [newUUID]: [] });
      }
      if (props.crudData) {
        setObjectName(generetingNameObjFromAutomation());
      } else {
        setObjectName('');
      }
      setObjectDescription('');
      setIsBackend(true);
      setIsFrontend(true);
    }
  }, [fetchObject, props.editMode, props.show]);

  async function onDelete() {
    if (!module_id || !rootObject) return;

    await ObjectsService.deleteObject(rootObject).then(() => {
      fetchObjects(module_id);
      setShowConfirmationDialog(false);
      if (props.gettingObjects) {
        props.gettingObjects();
      }
      props.onClose();
    });
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isLoading) return;
    if (!module_id) return;

    // Invalid object name
    if (!properties || !validateInputs()) {
      setIsLoading(false);
      return;
    }

    // Empty object
    if (Object.values(properties)[0].length === 0) {
      setFieldErrorMessage(`${t('inputValidationErrorMessages.ObjectEmptyProperty')}`);
      return;
    }

    // Invalid fields/sub-objects
    for (const [, fields] of Object.entries(properties)) {
      for (const prop of Object.values(fields)) {
        if (!prop.checked) continue;
        if (prop.type === 'OBJECT') {
          const { code, valid } = validateObjectName(prop.objectName ?? '', 64);
          if (!valid) {
            let errorMsg = '';
            switch (code) {
              case 'EXCEEDS_MAX_LENGTH':
                errorMsg = `${t('inputValidationErrorMessages.ObjectNameExceedsMaxLength')}`;
                break;
              case 'EMPTY_STRING':
                errorMsg = `${t('inputValidationErrorMessages.ObjectNameContainsEmptyString')}`;
                break;
              case 'CONTAIN_SPACES':
                errorMsg = `${t('inputValidationErrorMessages.ObjectNameContainsSpaces')}`;
                break;
              case 'CONTAIN_SPECIAL_CHARACTERS':
                errorMsg = `${t(
                  'inputValidationErrorMessages.ObjectNameContainsSpecialCharacters'
                )}`;
                break;
              case 'STARTS_WITH':
              default:
                errorMsg = `${t('inputValidationErrorMessages.GenericErrorMessage')}`;
                break;
            }
            setFieldErrorMessage(`${prop.name} - ${errorMsg}`);
            return;
          }
        }
      }
    }

    setIsLoading(true);

    const createdObj: string[] = [];
    if (props.editMode) {
      const fullObject = buildObject(
        objectName,
        objectDescription,
        isBackend,
        isFrontend,
        rootObject || '',
        entityId || '',
        properties
      );

      if (!fullObject) {
        setIsLoading(false);
        return;
      }

      await ObjectsService.updateObject(fullObject.uuid ?? '', fullObject)
        .then(() => {
          fetchObjects(module_id);
          props.onClose();
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      if (entityId) {
        // Check for empty tables.
        const tablesToCheck = [rootObject];
        while (tablesToCheck.length) {
          const table = tablesToCheck.pop();
          if (!table) break;
          const propList = properties[table];
          if (!propList) continue;
          let count = 0;
          for (const prop of propList) {
            if (prop.checked && prop.object && prop.fromEntity /*&& isObjectComplete*/) {
              tablesToCheck.push(prop.object);
            }
            if (entityMode) {
              if (prop.checked && prop.fromEntity) count++;
            } else {
              count++;
            }
          }
          if (count === 0) {
            setIsLoading(false);
            return;
          }
        }
      }

      if (!entityMode) {
        const fullObject = buildObject(
          objectName,
          objectDescription,
          isBackend,
          isFrontend,
          rootObject || '',
          entityId || '',
          properties
        );
        if (!fullObject) {
          setIsLoading(false);
          return;
        }

        await ObjectsService.createObject(module_id, fullObject)
          .then(() => {
            fetchObjects(module_id);
            props.onClose();
          })
          .catch((error) => {
            setIsLoading(false);
            if (error instanceof Error) {
              setAlertMessage(error.message);
            }
          });
      } else {
        const fullObjects = buildObjectsForEntityMode(
          objectName,
          objectDescription,
          isBackend,
          isFrontend,
          rootObject || '',
          true,
          properties
        );
        if (!fullObjects) {
          setIsLoading(false);
          return;
        }

        await ObjectsService.batchCreateObjects(module_id, fullObjects)
          .then((objFromBatch) => {
            fetchObjects(module_id);
            createdObj.push(objFromBatch[0].uuid!);
            props.onClose();
          })
          .catch((error) => {
            setIsLoading(false);
            if (error instanceof Error) {
              setAlertMessage(error.message);
            }
          });
      }
    }
    if (props.gettingObjects) props.gettingObjects(createdObj);
    setIsLoading(false);
  }

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateInputs = (): boolean => {
    const isValidName = checkObjectNameIsValid(objectName);
    return isValidName;
  };

  const checkObjectNameIsValid = (input: string): boolean => {
    const { code, valid } = validateObjectName(input, 64);
    let errorMessage = '';
    if (code === 'CONTAIN_SPACES') {
      errorMessage = 'inputValidationErrorMessages.ObjectNameContainsSpaces';
    } else if (code === 'CONTAIN_SPECIAL_CHARACTERS') {
      errorMessage = 'inputValidationErrorMessages.ObjectNameContainsSpecialCharacters';
    } else if (code === 'EMPTY_STRING') {
      errorMessage = 'inputValidationErrorMessages.ObjectNameContainsEmptyString';
    } else if (code === 'EXCEEDS_MAX_LENGTH') {
      errorMessage = 'inputValidationErrorMessages.ObjectNameExceedsMaxLength';
    } else if (code) {
      errorMessage = 'inputValidationErrorMessages.GenericErrorMessage';
    } else if (moduleObjects.find((o) => o.name === objectName && o.uuid !== rootObject)) {
      errorMessage = 'inputValidationErrorMessages.ObjectNameDuplicatedName';
    }
    setInputNameErrorMessage(errorMessage);
    return valid;
  };

  const handleObjectNameChange = (name: string) => {
    checkObjectNameIsValid(name);
    setObjectName(name);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.onClose();
        }}
        size="lg"
        centered
        scrollable
      >
        <Form onSubmit={onSubmit} className={styles.MainWrapperForm} id="formModal">
          <Modal.Header closeButton>
            <Modal.Title>{t('logicBuilder.objectsTab.' + props.dialogTitle)}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
            <Form.Group as={Row} className="mb-3" controlId="formName">
              <Form.Label column sm={3} lg={2}>
                {t('logicBuilder.objectsTab.Name')}
              </Form.Label>
              <Col sm={9} lg={10}>
                <Form.Control
                  type="text"
                  value={objectName}
                  disabled={isNative}
                  onChange={(e) => handleObjectNameChange(e.target.value)}
                  isInvalid={inputNameErrorMessage !== ''}
                />
                <Form.Control.Feedback type={'invalid'}>
                  {t(inputNameErrorMessage)}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={`mb-3`} controlId="formDescription">
              <Form.Label column sm={3} lg={2}>
                {t('logicBuilder.objectsTab.Description')}
              </Form.Label>
              <Col sm={9} lg={10}>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  disabled={isNative}
                  value={objectDescription}
                  onChange={(e) => {
                    setObjectDescription(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={`mb-3 ${styles.Gap}`} controlId="formAvailability">
              <Form.Label column sm={3} lg={2}>
                {t('logicBuilder.objectsTab.Availability')}
              </Form.Label>
              <Col sm={9} lg={10} style={{ alignContent: 'center' }}>
                <Form.Check
                  inline
                  id={`availability-backend`}
                  type={'checkbox'}
                  label={`Backend`}
                  checked={isBackend}
                  disabled={
                    // CRUD || Create objects in Logic Builder || Only Backend flag
                    !!props.crudData || (!props.editMode && !view_id) || (isBackend && !isFrontend)
                  }
                  onChange={(e) => {
                    setIsBackend(e.target.checked);
                  }}
                />
                <Form.Check
                  inline
                  id={`availability-frontend`}
                  type={'checkbox'}
                  label={`Frontend`}
                  checked={isFrontend}
                  disabled={
                    // CRUD || Create objects in Designer || Only Frontend flag
                    !!props.crudData || (!props.editMode && !!view_id) || (isFrontend && !isBackend)
                  }
                  onChange={(e) => {
                    setIsFrontend(e.target.checked);
                  }}
                />
              </Col>
            </Form.Group>

            <div style={{ height: 'calc(100% - 220px)' }}>
              <ObjectEditor
                objects={objects?.filter((obj) => obj.uuid !== props.objectId) ?? []}
                onChange={objectEditorOnChange}
                properties={properties}
                rootObject={rootObject || ''}
                setEntityMode={(entityMode: boolean) => setEntityMode(entityMode)}
                setRootObject={(root: string) => setRootObject(root)}
                editMode={props.editMode}
                modules={props.modules}
                tables={props.tables}
                columns={props.columns}
                enums={props.enums}
                relationships={props.relationships}
                preSelectedEntity={preSelectedEntity}
                setFieldErrorMessage={(msg: string) => {
                  setFieldErrorMessage(msg);
                }}
                entityId={props.editMode ? entityId : ''}
                loadingData={props.loadingData}
                fieldErrorMessage={fieldErrorMessage}
                showExported={isBackend && isFrontend}
              />
            </div>
          </Modal.Body>
          {/* Footer buttons */}
          <Modal.Footer>
            {props.editMode && (
              <>
                <Button
                  id="deleteButton"
                  variant="danger"
                  onClick={() => setShowConfirmationDialog(true)}
                >
                  {t('logicBuilder.objectsTab.Delete')}
                </Button>
                <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
                  {t('logicBuilder.objectsTab.Cancel')}
                </Button>
                <Button id="saveButton" variant="primary" type="submit" disabled={isNative}>
                  {t('logicBuilder.objectsTab.Save')}
                </Button>
              </>
            )}
            {!props.editMode && (
              <>
                <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
                  {t('logicBuilder.objectsTab.Cancel')}
                </Button>
                <Button id="saveButton" variant="primary" type="submit">
                  {t('logicBuilder.objectsTab.Create')}
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.object')}${' '}(${objectName})`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={'danger'} />
      )}
    </>
  );
}
