import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { AccessLevel, ExoRole } from 'modules/logic_builder/types';
import { AppRoleService } from 'modules/logic_builder/services';
import { useTranslation } from 'react-i18next';

type SecuritySelectorProps = {
  currentAccessLevel?: AccessLevel;
  setAccessLevel?: (accessLevel: AccessLevel) => void;
  setAllowRoles: (allowRoles: boolean) => void;
  allowAllRoles: boolean;
  handleToggleSelectedRole: (roleId: number, checked: boolean) => void;
  selectedRoles: number[];
  setRoles: (roles: number[]) => void;
};

const SecuritySelector = (props: SecuritySelectorProps) => {
  const { app_id } = useParams();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState('');
  const [roleList, setRoleList] = useState<ExoRole[]>([]);

  useEffect(() => {
    if (!app_id) return;
    const fetchRoles = async () => {
      try {
        const roles = await AppRoleService.getRoles(app_id);
        setRoleList(roles);
      } catch (error) {
        console.error('Erro ao obter roles:', error);
      }
    };

    fetchRoles();
  }, [app_id]);

  useEffect(() => {
    // Instantiated from CRUD Pages
    if (!props.currentAccessLevel && !selectedOption) {
      // Apply selection based on selectedRoles
      // only when creating the component
      if (props.selectedRoles.length === 0) {
        setSelectedOption('Public');
      } else if (
        props.allowAllRoles ||
        roleList.length === 0 ||
        roleList.length === props.selectedRoles.length
      ) {
        setSelectedOption('All roles');
      } else {
        setSelectedOption('Custom');
      }
      return;
    }

    // Instantiated from CRUD Endpoints
    switch (props.currentAccessLevel) {
      case 'PUBLIC':
        setSelectedOption('Public');
        break;

      case 'PROTECTED':
        if (
          props.allowAllRoles ||
          roleList.length === 0 ||
          roleList.length === props.selectedRoles.length
        ) {
          setSelectedOption('All roles');
        } else {
          setSelectedOption('Custom');
        }
        break;
    }
  }, [props, roleList, selectedOption]);

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
    switch (option) {
      case 'Public':
        // Clear all selected roles
        props.setRoles([]);
        props.setAllowRoles(false);
        if (props.setAccessLevel) {
          props.setAccessLevel('PUBLIC');
        }
        break;

      case 'All roles':
        // Select all roles
        props.setRoles(roleList.filter((r) => r.id !== undefined).map((r) => r.id ?? 0));
        props.setAllowRoles(true);
        if (props.setAccessLevel) {
          props.setAccessLevel('PROTECTED');
        }
        break;

      case 'Custom':
        // Clear all selected roles
        props.setRoles([]);
        props.setAllowRoles(false);
        if (props.setAccessLevel) {
          props.setAccessLevel('PROTECTED');
        }
        break;
    }
  };

  const handleSelectRole = (event: ChangeEvent<HTMLInputElement>) => {
    props.handleToggleSelectedRole(+event.target.value, event.target.checked);
  };

  const checkRoleIsSelected = (roleId?: number): boolean => {
    if (!roleId) return false;
    return props.allowAllRoles || props.selectedRoles.includes(roleId);
  };

  return (
    <div className={styles.securitySelector}>
      <label>{t('automation.step4.Security')}</label>
      <div>
        <div className={styles.options}>
          <Button
            id="publicButton"
            size="sm"
            className={selectedOption === 'Public' ? styles.selected : ''}
            style={{ cursor: 'default' }}
            onClick={() => handleSelectOption('Public')}
          >
            {t('automation.step4.public')}
          </Button>
          <Button
            id="allButton"
            size="sm"
            className={selectedOption === 'All roles' ? styles.selected : ''}
            style={{ cursor: 'default' }}
            onClick={() => handleSelectOption('All roles')}
          >
            {t('automation.step4.allRoles')}
          </Button>
          <Button
            id="customButton"
            size="sm"
            className={selectedOption === 'Custom' ? styles.selected : ''}
            disabled={roleList.length === 0}
            style={{ cursor: 'default' }}
            onClick={() => handleSelectOption('Custom')}
          >
            Custom
          </Button>
        </div>
        <div className={styles.viewRoles}>
          {roleList &&
            roleList.map((role) => {
              return (
                <Form.Check
                  key={role.id}
                  label={role.name}
                  name="view-roles"
                  type="checkbox"
                  id={role.name}
                  checked={checkRoleIsSelected(role.id)}
                  disabled={
                    selectedOption === 'Public' ||
                    props.allowAllRoles ||
                    roleList.length === 0 ||
                    (props.setAccessLevel && roleList.length === props.selectedRoles.length)
                  }
                  value={role.id}
                  onChange={handleSelectRole}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SecuritySelector;
