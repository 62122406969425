import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { t } from 'i18next';

export const BUTTON_MANIFEST: ComponentManifest = {
  type: 'BUTTON',
  name: 'Button',
  descriptionId: 'ButtonDescription',
  description:
    'Buttons are clickable elements of your application, analogous to the HTML `button` tag.',
  icon: 'fa-solid fa-computer-mouse',
  group: ComponentGroups['BUTTON'],
  previewHeight: 30,
  previewWidth: 80,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Text',
      key: 'text',
      tooltip: 'designer.right_side.controls.components.button.text'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.button.tooltip'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.button.visibleVariable'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'DisabledVariable',
      descriptionId: 'DisabledVariableDescription',
      key: 'disabledVar',
      tooltip: 'designer.right_side.controls.components.button.visibleVariableOne'
    },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Disabled',
    //   key: 'disabled'
    // },
    {
      controlType: ControlsTypes.ICONS,
      controlLabel: 'Icons'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    // {
    //   controlType: ControlsTypes.TEXTFORMAT,
    //   controlLabel: 'LeftIcon',
    //   key: 'leftIcon'
    // },
    // {
    //   controlType: ControlsTypes.TEXTFORMAT,
    //   controlLabel: 'RightIcon',
    //   key: 'rightIcon'
    // },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.button.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.button.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.ICON_STYLES,
      controlLabel: 'IconStyles'
    },
    {
      controlType: ControlsTypes.FONT,
      controlLabel: 'Font',
      tooltip: 'designer.right_side.controls.components.button.font'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('IconColor'),
      key: 'iconColor',
      tooltip: 'designer.right_side.controls.components.button.iconColor'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Background',
      tooltip: 'designer.right_side.controls.components.button.themePicker'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.button.colorPicker'
    },
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Font Weight',
      key: 'fontWeight',
      controlOptions: ['normal', 'bold', '100', '300', '500', '600', '800', '900'],
      tooltip: 'designer.right_side.controls.components.button.selectOne'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: t('Spacing'),
      key: 'lineHeight',
      controlOptions: ['normal', 'inherit', 'initial', 'revert', 'revert-layer', 'unset'],
      tooltip: 'designer.right_side.controls.components.button.selectTwo'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.button.number'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'LineHeight',
      key: 'lineHeight',
      tooltip: 'designer.right_side.controls.components.button.numberTwo'
    },
    {
      controlType: ControlsTypes.TEXTALIGN,
      controlLabel: 'Text Align',
      key: 'textAlign',
      tooltip: 'designer.right_side.controls.components.button.textAlign'
    },
    {
      controlType: ControlsTypes.TEXTDECORATION,
      controlLabel: 'Text Decoration',
      key: 'textDecoration',
      tooltip: 'designer.right_side.controls.components.button.textDecoration'
    },
    {
      controlType: ControlsTypes.TEXTTRANSFORM,
      controlLabel: 'Text Transform',
      key: 'textTransform',
      tooltip: 'designer.right_side.controls.components.button.textTransform'
    },
    {
      controlType: ControlsTypes.FONTSTYLE,
      controlLabel: 'Font Style',
      key: 'fontStyle',
      tooltip: 'designer.right_side.controls.components.button.fontstyle'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CLICK', 'HOVER']
};
