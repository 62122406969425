import { ParametersState } from '../types/parameters';
import produce from 'immer';
import { doUpdateParam, UPDATE_PARAM, UpdateParamAction } from '../events/parameters/update_param';
import {
  CHANGE_PARAM_TYPE,
  ChangeParamTypeAction,
  doChangeParamType
} from '../events/parameters/change_param_type';
import { ADD_PARAM, AddParamAction, doAddParam } from '../events/parameters/add_param';
import {
  doSetPagination,
  SET_PAGINATION,
  SetPaginationAction
} from '../events/parameters/set_pagination';
import { doSetSort, SET_SORT, SetSortAction } from '../events/parameters/set_sort';
import { doSetFilter, SET_FILTER, SetFilterAction } from '../events/parameters/set_filter';
import { initialState } from '../initial_empty_state';

type ParametersActions =
  | AddParamAction
  | UpdateParamAction
  | ChangeParamTypeAction
  | SetPaginationAction
  | SetSortAction
  | SetFilterAction;

export const parametersReducer = (
  state: ParametersState = initialState.parameters,
  action: ParametersActions
): ParametersState => {
  return produce(state, (draft: ParametersState) => {
    switch (action.type) {
      case ADD_PARAM:
        return doAddParam(draft, action);
      case UPDATE_PARAM:
        return doUpdateParam(draft, action);
      case CHANGE_PARAM_TYPE:
        return doChangeParamType(draft, action);
      case SET_PAGINATION:
        return doSetPagination(draft, action);
      case SET_SORT:
        return doSetSort(draft, action);
      case SET_FILTER:
        return doSetFilter(draft, action);
      default:
        return state;
    }
  });
};
