import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from '../../../types';
import { t } from 'i18next';

export const RADIO_MANIFEST: ComponentManifest = {
  type: 'RADIO',
  name: 'Radio',
  descriptionId: 'RadioDescription',
  description:
    'A collection of related options. Only one radio button in a group can be selected at the same time.',
  icon: 'fa-solid fa-circle-dot',
  group: ComponentGroups['INPUT'],
  previewHeight: 120,
  previewWidth: 100,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Label',
      key: 'label',
      tooltip: 'designer.right_side.controls.components.radio.label'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'Visible',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.radio.visible'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.radio.tooltip'
    },
    {
      controlType: ControlsTypes.LIST,
      controlLabel: 'Options',
      key: 'options',
      tooltip: 'designer.right_side.controls.components.radio.options'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Required',
    //   key: 'required'
    // },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Readonly',
    //   key: 'readonly'
    // },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Disabled',
    //   key: 'disabled'
    // },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.radio.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.radio.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.radio.color'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.radio.fontSize'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CHANGE']
};
