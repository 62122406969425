import { externalTablesList, TableUUID } from '../../../../../../types';
import React, { useEffect, useState } from 'react';
import { DataService } from '../../../../../../services';

/**
 * Load the available External Tables from API
 * @param tableID
 * @param moduleID
 */
export default function useFetchAvailableTables(tableID: TableUUID, moduleID: string | undefined) {
  const [availableTables, setAvailableTables] = useState<externalTablesList>();

  const fetchAvailableTables = React.useCallback(async () => {
    if (!moduleID) return;
    const availableTables: externalTablesList = await DataService.getExternalTables(
      moduleID,
      tableID
    );
    setAvailableTables(availableTables);
  }, [moduleID, tableID]);

  useEffect(() => {
    try {
      fetchAvailableTables();
    } catch (error) {
      console.error(error);
    }
  }, [fetchAvailableTables]);

  return { availableTables, setAvailableTables, fetchAvailableTables };
}
