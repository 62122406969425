import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { DATA_TYPES, DataType, FunctionParameter, ObjectSimple } from 'modules/logic_builder/types';
import { FunctionService, ObjectsService } from 'modules/logic_builder/services';
import Confirmation from 'web_ui/confirmation';
import { useParams } from 'react-router-dom';
import { EnumFrame } from 'modules/modeler/types';
import { parameterNameRegex } from '../../../../../utils/regex';
import { useTranslation } from 'react-i18next';

type FunctionParameterEditorProps = {
  parameter?: FunctionParameter;
  fetchParameters: () => void;
  functionId: string;
  show: boolean;
  onClose: () => void;
  enumsList: EnumFrame[];
};

export function FunctionParameterEditorDialog(props: FunctionParameterEditorProps) {
  const [parameter, setParameter] = useState<FunctionParameter>();
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const [isNative, setIsNative] = useState(false);
  const { module_id } = useParams();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

  const fetchObjects = useCallback(async () => {
    if (!module_id) return;
    await ObjectsService.getObjectsByModule(module_id).then((fetchedObjects) => {
      setObjects(fetchedObjects);
    });
  }, [module_id]);

  useEffect(() => {
    if (!props.show) return;

    fetchObjects();
    setErrorMessage(false);
  }, [fetchObjects, props.show]);

  useEffect(() => {
    if (!props.show || !props.parameter) return;

    setParameter(props.parameter);
    setIsNative(props.parameter.native ?? false);
  }, [props.parameter, props.show]);

  async function onDelete() {
    if (!parameter || !parameter.uuid) return;

    await FunctionService.deleteParameter(props.functionId, parameter.uuid).then(() => {
      props.fetchParameters();
      setShowConfirmationDialog(false);
      props.onClose();
    });
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (!parameter || !parameter.uuid || !module_id) return;

    // todo: [backend] fix remove objectUuid from parameter.
    try {
      await FunctionService.updateParameter(props.functionId, parameter.uuid, parameter).then(
        () => {
          props.fetchParameters();
          props.onClose();
        }
      );
    } catch (err) {
      setErrorMessage(true);
    }
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onClose} className={styles.ParameterEditor} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('logicBuilder.createEndpointDialog.ParameterEditor')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {errorMessage && (
              <span className={styles.errorMsg}>
                {`*${t('logicBuilder.errorCreatingEditingParameters')}`}
              </span>
            )}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="name..."
                required
                disabled={isNative}
                isInvalid={isNameInvalid}
                value={parameter?.name ?? ''}
                onChange={(e) => {
                  setIsNameInvalid(!parameterNameRegex.test(e.target.value));
                  setParameter({ ...parameter, name: e.target.value } as FunctionParameter);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please inform a valid parameter name
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>Type</Form.Label>
              <Form.Select
                required
                disabled={isNative}
                onChange={(e) =>
                  setParameter({
                    ...parameter,
                    type: e.target.value as DataType,
                    objectUuid: '',
                    enumUuid: ''
                  } as FunctionParameter)
                }
                value={parameter?.type ?? 'default'}
              >
                {!parameter?.type && <option value="default">Select parameter type</option>}
                {Object.keys(DATA_TYPES).map((typeKey) => {
                  return (
                    <option key={typeKey} value={typeKey}>
                      {DATA_TYPES[typeKey as DataType]}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                disabled={isNative}
                maxLength={64}
                placeholder="description..."
                className="ml-3"
                onChange={(e) =>
                  setParameter({ ...parameter, description: e.target.value } as FunctionParameter)
                }
                value={parameter?.description ?? ''}
              />
            </Form.Group>

            <Row className="mb-3">
              <Col sm={6}>
                <Form.Group controlId="formIsArray">
                  <Form.Check
                    inline
                    disabled={isNative || parameter?.type === 'PAGE'}
                    label="Is Array"
                    type="checkbox"
                    name="isArray"
                    onChange={(e) =>
                      setParameter({ ...parameter, list: e.target.checked } as FunctionParameter)
                    }
                    checked={(parameter?.list || parameter?.type === 'PAGE') ?? false}
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group controlId="formIsRequired">
                  <Form.Check
                    inline
                    disabled={isNative}
                    label="Is Required"
                    type="checkbox"
                    name="isRequired"
                    onChange={(e) =>
                      setParameter({
                        ...parameter,
                        required: e.target.checked
                      } as FunctionParameter)
                    }
                    checked={parameter?.required ?? false}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>Objects</Form.Label>
              <Form.Select
                required
                disabled={(parameter?.type !== 'OBJECT' && parameter?.type !== 'PAGE') || isNative}
                onChange={(e) =>
                  setParameter({
                    ...parameter,
                    objectUuid: e.target.value
                  } as FunctionParameter)
                }
                value={parameter?.objectUuid ?? 'default'}
              >
                {!parameter?.objectUuid && <option value={'default'}>Select object</option>}
                {objects.map((object) => {
                  return (
                    <option key={object.uuid} value={object.uuid}>
                      {object.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSelectEnum">
              <Form.Label>Enums</Form.Label>
              <Form.Select
                required
                disabled={parameter?.type !== 'ENUM'}
                onChange={(e) =>
                  setParameter({
                    ...parameter,
                    enumUuid: e.target.value
                  } as FunctionParameter)
                }
                value={parameter?.enumUuid ?? 'default'}
              >
                {!parameter?.enumUuid && <option value={'default'}>Select enum</option>}
                {props.enumsList.map((enumItem) => {
                  return (
                    <option key={enumItem.uuid} value={enumItem.uuid}>
                      {enumItem.content.data.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="deleteButton"
              variant="danger"
              onClick={() => setShowConfirmationDialog(true)}
              disabled={isNative}
            >
              {t('logicBuilder.Delete')}
            </Button>
            <Button id="cancelButton" variant="secondary" onClick={props.onClose}>
              {t('logicBuilder.Cancel')}
            </Button>
            <Button
              id="saveButton"
              variant="primary"
              type="submit"
              disabled={isNameInvalid || !parameter?.type || isNative}
            >
              {t('logicBuilder.Save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message="Delete parameter?"
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
