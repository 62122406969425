import { GridData, GridStyles } from '.';
import { LayoutComponent } from '../../../../designer/types';

export const GRID_TEMPLATE: LayoutComponent<GridData, GridStyles> = {
  uuid: 'empty',
  type: 'GRID',
  data: {
    name: '',
    columns: [
      {
        title: 'COLUMN_0',
        width: 4
      },
      {
        title: 'COLUMN_1',
        width: 4
      },
      {
        title: 'COLUMN_2',
        width: 4
      }
    ],
    height: 'fit-content',
    heightUnit: '',
    maxHeight: 'none',
    minHeight: '100',
    minHeightUnit: 'px',
    optionSizeWidth: 'auto',
    optionSizeHeight: 'auto'
  },
  styles: {
    css: '',
    marginLeft: 0,
    marginRight: 0,
    borderTop: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 10,
    paddingRight: 10
  }
};

export function getGridTemplate(columns: number) {
  const gridColumns = [];
  for (let i = 0; i < columns; i++) {
    gridColumns.push({ title: `COLUMN_${i}`, width: 12 / columns });
  }
  const template = {
    ...GRID_TEMPLATE,
    data: {
      ...GRID_TEMPLATE.data,
      columns: gridColumns
    }
  };
  return template;
}
