import { Action } from 'redux';
import {
  addColumnAction,
  addIndexAction,
  addRelationshipAction,
  addTableAction,
  deleteIndexAction,
  deleteRelationshipAction,
  deleteTableAction,
  duplicateTableAction
} from './root';
import { setColumnsAction } from './columns';
import { SetIndexesAction } from './indexes';
import { columnID, Table, TableUUID } from 'modules/modeler/types';

export const SET_TABLES = 'SET_TABLES';
export const SET_POSITION = 'SET_POSITION';
export const CHANGE_TABLE_NAME = 'CHANGE_TABLE_NAME';
export const CHANGE_TABLE_FOLDER = 'CHANGE_TABLE_FOLDER';

export const DELETE_TABLE = 'DELETE_TABLE';
export const CHANGE_TABLE_PROPERTY = 'CHANGE_TABLE_PROPERTY';
export const CHANGE_TABLE_COLUMNS_ORDER = 'CHANGE_TABLE_COLUMNS_ORDER';
export const SET_INDEX = 'SET_INDEX';
export const TABLE_DELETE_INDEX = 'TABLE_DELETE_INDEX';

export const ADD_ENUM = 'ADD_ENUM';
export const ADD_ENUMCOLUMN = 'ADD_ENUMCOLUMN';
export const DELETE_ENUM = 'DELETE_ENUM';

export type TableActions =
  | setTablesAction
  | changeTableNameAction
  | changeTableFolderAction
  | setPositionAction
  | setColumnsAction
  | deleteTableAction
  | changePropertyAction
  | changeTableColumnsOrderAction
  | SetIndexesAction
  | addTableAction
  | addRelationshipAction
  | deleteRelationshipAction
  | addColumnAction
  | addIndexAction
  | deleteIndexAction
  | duplicateTableAction;

export interface setTablesAction extends Action {
  type: 'SET_TABLES';
  payload: {
    tables: Table[];
  };
}

export interface setPositionAction extends Action {
  type: 'SET_POSITION';
  payload: {
    uuid: TableUUID;
    posX: number;
    posY: number;
  };
}

export interface changeTableNameAction extends Action {
  type: 'CHANGE_TABLE_NAME';
  payload: {
    uuid: TableUUID;
    name: string;
  };
}

export interface changeTableFolderAction extends Action {
  type: 'CHANGE_TABLE_FOLDER';
  payload: {
    id: TableUUID;
    folderId: string;
  };
}

export interface changePropertyAction extends Action {
  type: 'CHANGE_TABLE_PROPERTY';
  payload: {
    uuid: TableUUID;
    key: string;
    value: any;
  };
}

export interface changeTableColumnsOrderAction extends Action {
  type: 'CHANGE_TABLE_COLUMNS_ORDER';
  payload: {
    tableUUID: TableUUID;
    originUUID: columnID;
    targetUUID: columnID;
  };
}

export const setTables = (tables: Table[]) => ({
  type: 'SET_TABLES',
  payload: { tables }
});

export const setPosition = (uuid: TableUUID, posX: number, posY: number) => ({
  type: SET_POSITION,
  payload: { uuid: uuid, posX: posX, posY: posY }
});

export const changeTableName = (uuid: TableUUID, name: string) => ({
  type: CHANGE_TABLE_NAME,
  payload: { uuid: uuid, name: name }
});

export const changeTableFolder = (id: TableUUID, folderId: string) => ({
  type: CHANGE_TABLE_FOLDER,
  payload: { id: id, folderId: folderId }
});

export const changeTableColumnsOrder = (
  tableUUID: TableUUID,
  originUUID: columnID,
  targetUUID: columnID
): changeTableColumnsOrderAction => ({
  type: CHANGE_TABLE_COLUMNS_ORDER,
  payload: { tableUUID, originUUID, targetUUID }
});

export const deleteTable = (uuid: TableUUID) => ({
  type: DELETE_TABLE,
  payload: { uuid: uuid }
});

export const changeTableProperty = (uuid: TableUUID, key: string, value: any) => ({
  type: CHANGE_TABLE_PROPERTY,
  payload: { uuid: uuid, key: key, value: value }
});
