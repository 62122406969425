import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import {
  ACTION_TYPES,
  Action,
  ActionType,
  FunctionParameter,
  FunctionType,
  ParameterEndpoint
} from 'modules/logic_builder/types';
import { Button, Form, Modal } from 'react-bootstrap';
import { EndpointsService, FunctionService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import { RunFunctionActionType } from '../function_action_creator_dialog/types';
import { useTranslation } from 'react-i18next';
import { FunctionListDropdown } from '../../functions_list/function_list_dropdown';

type EndpointActionCreatorDialogProps = {
  show: boolean;
  onClose: () => void;
  fetchActions: () => void;
  endpointId: string;
  editMode: boolean;
  dialogTitle: string;
  action?: Action<any>;
  setdeleted?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function EndpointActionCreatorDialog(props: EndpointActionCreatorDialogProps) {
  const { t } = useTranslation();
  const { functions, services } = useContext(LogicBuilderContext);

  const [action, setAction] = useState<Action<RunFunctionActionType>>({
    order: 0,
    data: {
      function: {
        functionId: '',
        arguments: {}
      }
    },
    type: 'RUN_FUNCTION',
    returnVariableUuid: ''
  });
  const [functionsList, setFunctionsList] = useState<FunctionType[]>([]);
  const [parameters, setParameters] = useState<ParameterEndpoint[]>([]);
  const [selectedFunctionParameters, setSelectedFunctionParameters] = useState<FunctionParameter[]>(
    []
  );
  useEffect(() => {
    const fetchEndpointsParameters = async () => {
      await EndpointsService.getParameters(props.endpointId).then((fetchedParameters) => {
        setParameters(fetchedParameters);
      });
    };
    fetchEndpointsParameters();
  }, [props.endpointId]);

  const fetchSelectedFunctionParameters = useCallback(async (functionId: string) => {
    await FunctionService.getParameters(functionId).then((fetchedParameters) => {
      setSelectedFunctionParameters(fetchedParameters);
    });
  }, []);

  useEffect(() => {
    if (!props.show) return;

    setFunctionsList(Object.values(functions).flat());
    if (!props.editMode) {
      setAction({
        order: 0,
        data: {
          function: {
            functionId: '',
            arguments: {}
          }
        },
        type: 'RUN_FUNCTION',
        returnVariableUuid: ''
      });
      setSelectedFunctionParameters([]);
    } else if (props.action) {
      setAction(props.action);
      fetchSelectedFunctionParameters(props.action.data.function.functionId);
    }
  }, [fetchSelectedFunctionParameters, functions, props.action, props.editMode, props.show]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (!action) return;
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      return;
    }
    await EndpointsService.updateEndpointAction(props.endpointId, action).then(() => {
      props.fetchActions();
      props.onClose();
    });
  }

  const handleListOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>, functionParameter: string) => {
      const localInputs = { ...action.data } as RunFunctionActionType;
      if (!localInputs.function.arguments[functionParameter])
        localInputs.function.arguments[functionParameter] = {
          type: '',
          value: ''
        };
      localInputs.function.arguments[functionParameter].value = e.target.value;
      setAction({ ...action, data: localInputs });
    },
    [action]
  );

  const handleTypeOnChange = useCallback(
    (e: ChangeEvent<any>, functionParameter: string) => {
      const localInputs = { ...action.data } as RunFunctionActionType;
      if (!localInputs.function.arguments[functionParameter])
        localInputs.function.arguments[functionParameter] = {
          type: '',
          value: ''
        };
      localInputs.function.arguments[functionParameter].type = e.target.value;
      localInputs.function.arguments[functionParameter].value = '';
      setAction({ ...action, data: localInputs });
    },
    [action]
  );

  const handleFixedArgumentOnChange = useCallback(
    (e: any, functionParameter: string) => {
      const localInputs = { ...action.data } as RunFunctionActionType;
      if (!localInputs.function.arguments[functionParameter])
        localInputs.function.arguments[functionParameter] = {
          type: '',
          value: ''
        };
      localInputs.function.arguments[functionParameter].value = e.target.value;
      setAction({ ...action, data: localInputs });
    },
    [action]
  );

  const handleFunctionOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const localInputs = { ...action.data } as RunFunctionActionType;
      localInputs.function.functionId = e.target.value;
      localInputs.function.arguments = {};
      setAction({ ...action, data: localInputs });
      fetchSelectedFunctionParameters(localInputs.function.functionId);
    },
    [action, fetchSelectedFunctionParameters]
  );

  const handleUpdatedFunction = useCallback(
    (functionId: string) => {
      const localInputs = { ...action.data } as RunFunctionActionType;
      localInputs.function.functionId = functionId;
      localInputs.function.arguments = {};
      setAction({ ...action, data: localInputs });
      fetchSelectedFunctionParameters(localInputs.function.functionId);
    },
    [action, fetchSelectedFunctionParameters]
  );

  const checkTypeIsValid = useCallback(
    (functionParameter: string): boolean => {
      const args = action.data.function.arguments;
      if (!args[functionParameter]) return false;

      if (!args[functionParameter].type) return false;

      return true;
    },
    [action.data.function.arguments]
  );

  const checkFixedValueIsValid = useCallback(
    (functionParameter: string): boolean => {
      const args = action.data.function.arguments;
      if (!args[functionParameter]) return false;

      if (!args[functionParameter].value) return false;

      return true;
    },
    [action.data.function.arguments]
  );

  const checkSelectedParameterIsValid = useCallback(
    (functionParameter: string): boolean => {
      const args = action.data.function.arguments;
      if (!args[functionParameter]) return false;

      const mappedEndpointParameter = args[functionParameter].value;
      if (!mappedEndpointParameter) return false;

      const findParameter = parameters.find((p) => p.uuid === mappedEndpointParameter);
      if (!findParameter) return false;

      return true;
    },
    [action.data.function.arguments, parameters]
  );

  const renderParametersList = useCallback(
    (
      onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
      functionParameter: string,
      parametersList: ParameterEndpoint[],
      value?: string,
      required?: boolean,
      disabled?: boolean
    ) => {
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t('logicBuilder.SelectParameter')}</Form.Label>
          <Form.Select
            aria-label="selectParameter"
            onChange={onChange}
            value={value ?? ''}
            required={required ?? false}
            disabled={disabled ?? false}
            isInvalid={!checkSelectedParameterIsValid(functionParameter)}
          >
            {!value && <option value="">{t('logicBuilder.SelectParameter')}</option>}
            {parametersList.map((parameter) => {
              return (
                <option key={parameter.uuid} value={parameter.uuid}>
                  {parameter.name}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please provide a valid mapped parameter.
          </Form.Control.Feedback>
        </Form.Group>
      );
    },
    [checkSelectedParameterIsValid, t]
  );

  const renderRunFunctionInputs = useCallback(() => {
    const actionData = { ...action.data };
    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>{t('logicBuilder.Function')}</Form.Label>

          <FunctionListDropdown
            selectedFunctionId={actionData.function.functionId}
            functionlist={functionsList}
            handleSelectModule={handleUpdatedFunction}
            services={services}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid function.
          </Form.Control.Feedback>
        </Form.Group>
        {selectedFunctionParameters.map((parameter) => {
          const argument = actionData.function.arguments[parameter.uuid ?? ''];
          const filteredParameters = parameters.filter((param) => {
            return param.type === parameter.type;
          });
          let argumentTypes: string[];
          if (parameter.type === 'OBJECT') {
            argumentTypes = ['PARAM'];
          } else {
            argumentTypes = ['PARAM', 'FIXED'];
          }
          return (
            <div key={parameter.uuid}>
              <Form.Label>
                {t('logicBuilder.Parameter')} {parameter.name}
              </Form.Label>
              <Form.Group className="mb-3">
                <Form.Label>{t('logicBuilder.Type')} </Form.Label>
                <Form.Select
                  aria-label="type"
                  onChange={(e) => handleTypeOnChange(e, parameter.uuid ?? '')}
                  value={argument?.type ?? ''}
                  isInvalid={!checkTypeIsValid(parameter.uuid ?? '')}
                  required
                >
                  {!argument?.type && <option value="">{t('logicBuilder.SelectType')}</option>}
                  {argumentTypes.map((type) => {
                    return (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              {argument?.type === 'PARAM' &&
                renderParametersList(
                  (e: ChangeEvent<HTMLSelectElement>) =>
                    handleListOnChange(e, parameter.uuid ?? ''),
                  parameter.uuid ?? '',
                  filteredParameters,
                  argument?.value,
                  parameter.required
                )}

              {argument?.type === 'FIXED' && (
                <Form.Group>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleFixedArgumentOnChange(e, parameter.uuid ?? '')}
                    value={argument?.value || ''}
                    isInvalid={!checkFixedValueIsValid(parameter.uuid ?? '')}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid fixed value.
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <hr />
            </div>
          );
        })}
      </>
    );
  }, [
    action.data,
    checkFixedValueIsValid,
    functionsList,
    handleFixedArgumentOnChange,
    handleFunctionOnChange,
    handleListOnChange,
    handleTypeOnChange,
    parameters,
    renderParametersList,
    selectedFunctionParameters,
    t
  ]);

  function actionTypeOnChange(e: ChangeEvent<HTMLSelectElement>) {
    const actionType = e.target.value;
    if (actionType === 'RUN_FUNCTION') {
      setAction({
        ...action,
        type: actionType,
        data: { function: { functionId: '', arguments: {} } }
      });
    }
  }

  async function removeAction() {
    await EndpointsService.updateEndpointAction(props.endpointId, {
      order: 0,
      data: null,
      type: 'RUN_FUNCTION',
      returnVariableUuid: ''
    }).then(() => {
      props.fetchActions();
      if (props.setdeleted) {
        props.setdeleted(true);
      }
      props.onClose();
    });
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.onClose()}
      className={styles.CallFunctionCreator}
      centered
    >
      <Modal.Header closeButton>{props.dialogTitle}</Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>{t('logicBuilder.ActionType')}</Form.Label>
            <Form.Select
              id={'action-type'}
              aria-label={'action-type'}
              onChange={actionTypeOnChange}
              value={action.type ?? ''}
              disabled
              required
            >
              {!action?.type && <option value="">{t('logicBuilder.SelectType')}</option>}
              {Object.keys(ACTION_TYPES).map((type) => {
                return (
                  <option id={type} key={type} value={type}>
                    {ACTION_TYPES[type as ActionType]}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          {action.type === 'RUN_FUNCTION' && renderRunFunctionInputs()}
        </Modal.Body>
        <Modal.Footer>
          {props.editMode && (
            <Button id={'deleteButton'} variant="danger" onClick={removeAction}>
              {t('logicBuilder.Remove')}
            </Button>
          )}
          <Button id={'cancelButton'} variant="secondary" onClick={() => props.onClose()}>
            {t('Cancel')}
          </Button>
          <Button id={'saveButton'} variant="primary" type="submit">
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
