import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { Combobox } from '@headlessui/react';
import Icon from '../../../../icon';
import useResizeInput from '../../utils/useResizeInput';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { useDispatch, useSelector } from 'react-redux';
import { updateAction } from '../../../store/events/actions/update_action';
import { FunctionEditorState } from '../../../store/types/function_editor_state';

function Select({ placeholder, label, actionUuid, dataKey, options }: ActionInputProps) {
  const [items, setItems] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const action = useSelector((state: FunctionEditorState) => state.actions[actionUuid]);
  const dispatch = useDispatch();

  useEffect(() => {
    setItems(options as string[]);
  }, [options]);

  useEffect(() => {
    resizeInput(inputRef, query, placeholder);
  }, [query]);

  const handleChange = (value: any) => {
    dispatch(updateAction(actionUuid, dataKey, value));
  };

  const { resizeInput } = useResizeInput();

  const filteredTypes =
    query === ''
      ? items
      : items?.filter((item) => {
          return item.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      onChange={(text: string) => {
        setQuery(text);
        handleChange(text);
      }}
    >
      <Combobox.Label className={styles.ActionInputLabel}>{label}</Combobox.Label>
      <div style={{ position: 'relative' }}>
        <Combobox.Input
          ref={inputRef}
          className={`${styles.ActionInput} ${
            isOpen ? 'rounded-top-2 rounded-bottom-0' : 'rounded-2'
          }`}
          placeholder={placeholder}
          onChange={(e) => setQuery(e.target.value)}
          autoComplete={'off'}
          displayValue={() => action?.data[dataKey]}
        />
        <Combobox.Button style={{ fontSize: '0.7rem' }} as={'span'}>
          {({ open }) => {
            setIsOpen(open);
            if (open) {
              return <Icon iconName={'chevron-up'} />;
            } else {
              return <Icon iconName={'chevron-down'} />;
            }
          }}
        </Combobox.Button>
        <Combobox.Options className={`${styles.Options}`}>
          {filteredTypes?.map((item) => (
            <Combobox.Option
              className={({ active }) =>
                active ? `bg-body-secondary ${styles.Option}` : `${styles.Option}`
              }
              value={item}
              key={item}
            >
              {item}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

export default Select;
