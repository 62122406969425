import { FolderRepository } from '../repos';
import { FolderCreateDTO, FolderType } from '../types';

export class FolderServ {
  async createFolder(moduleId: string, folderName: string, parentId: string, type: string) {
    return await FolderRepository.createFolder(moduleId, folderName, parentId, type);
  }

  async getFoldersByModule(moduleId: string) {
    return await FolderRepository.getFoldersByModule(moduleId);
  }

  async getFolderByModuleIdAndLocalType(moduleId: string, type: FolderType) {
    return await FolderRepository.getFolderByModuleIdAndLocalType(moduleId, type);
  }

  async createFolderByType(moduleId: string, type: FolderType, folderDTO: FolderCreateDTO) {
    return await FolderRepository.createFolderByType(moduleId, type, folderDTO);
  }

  async getFolder(moduleId: string, folderId: string) {
    return await FolderRepository.getFolder(moduleId, folderId);
  }

  async updateFolder(moduleId: string, folderId: string, folderName: string) {
    return await FolderRepository.updateFolder(moduleId, folderId, folderName);
  }

  async moveFolder(moduleId: string, folderId: string, parentId: string) {
    return await FolderRepository.moveFolder(moduleId, folderId, parentId);
  }

  async deleteFolder(moduleId: string, folderId: string) {
    return await FolderRepository.deleteFolder(moduleId, folderId);
  }
}
