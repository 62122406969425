import React from 'react';
import { ControlProps } from '..';
import HelpIcon from '../components/HelpIcon';
import HelpPopover from '../components/Popover';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

function GapControl(props: ControlProps) {
  const { t } = useTranslation();

  function handleChange(e: React.MouseEvent, value: string) {
    e.stopPropagation();

    if (props.onChange) props.onChange(value, props.id);
  }

  return (
    <div className="mb-3 border-bottom text-body-secondary w-100">
      <div style={{ display: 'flex' }}>
        <label className="position-relative mb-1 text-body">{props.label}</label>
        <HelpPopover
          helpBoxProps={{
            title:
              t('designer.right_side.controls.GapTitle') || 'designer.right_side.controls.GapTitle',
            description:
              t('designer.right_side.controls.GapDescription') ||
              'designer.right_side.controls.GapDescription'
          }}
          placement="top"
        >
          <HelpIcon />
        </HelpPopover>
      </div>
      <div
        className="mb-3"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <div className={`${styles.contentButtons}  form-control form-control-sm`}>
          <button
            className={`${styles.buttonContent} btn btn-secondary`}
            style={{
              background: `${props?.value === '0.25rem' ? 'var(--bs-btn-hover-bg)' : 'transparent'}`
            }}
            onClick={(e) => handleChange(e, '0.25rem')}
          >
            sm
          </button>
          <button
            className={`${styles.buttonContent} btn btn-secondary`}
            style={{
              background: `${props?.value === '0.5rem' ? 'var(--bs-btn-hover-bg)' : 'transparent'}`
            }}
            onClick={(e) => handleChange(e, '0.5rem')}
          >
            md
          </button>
          <button
            className={`${styles.buttonContent} btn btn-secondary`}
            style={{
              background: `${props?.value === '0.75rem' ? 'var(--bs-btn-hover-bg)' : 'transparent'}`
            }}
            onClick={(e) => handleChange(e, '0.75rem')}
          >
            lg
          </button>
          <button
            className={`${styles.buttonContent} btn btn-secondary`}
            style={{
              background: `${props?.value === '1rem' ? 'var(--bs-btn-hover-bg)' : 'transparent'}`
            }}
            onClick={(e) => handleChange(e, '1rem')}
          >
            xl
          </button>
        </div>
      </div>
    </div>
  );
}

export default GapControl;
