import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import React from 'react';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { DataType } from '../../../types';
import ReturnTypeIcon from './return_type_icon';
import { t } from 'i18next';

function filterByFunctionReturnDataType(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: DataType
): boolean {
  const functionId = state.editor.functionId;
  const func = state.functions[functionId];
  return dataType?.toUpperCase() === func?.returnType?.type?.toUpperCase();
}

export const RETURN_TYPE_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.RETURN_TYPE,
  name: t('Return'),
  description: `${t('ActionReturnVariable')}`,
  color: 'warning',
  category: BACKEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.FUNCTION,
  icon: <ReturnTypeIcon />,
  inlineInputs: [
    {
      label: t('Return'),
      placeholder: `${t('toReturn')}`,
      key: 'uuid',
      type: ACTION_INPUTS_TYPES.VARIABLES_INPUT,
      options: { filterByDataType: filterByFunctionReturnDataType }
    }
  ],
  inputs: []
};
