import { functionEditorCrud } from 'web_ui/function_editor/store/types/functions';
import { CrudAutomationRepository } from '../repos';
import {
  CrudCreateEndpoint,
  CrudCreateFunction,
  CrudInputEndpoint,
  CrudInputFunction,
  CrudInputViews,
  CrudPage,
  Endpoint,
  FunctionSimple,
  ObjectSimple,
  Service,
  Controller
} from '../types';

export class CrudAutomationServ {
  async getEntityObjects(moduleId: string, entityId: string): Promise<ObjectSimple[]> {
    return await CrudAutomationRepository.getEntityObjects(moduleId, entityId);
  }

  async createBaseObject(moduleId: string, entityId: string): Promise<ObjectSimple> {
    return await CrudAutomationRepository.createBaseObject(moduleId, entityId);
  }

  async getEntityServiceResume(moduleId: string, entityId: string): Promise<Service[]> {
    return await CrudAutomationRepository.getEntityServiceResume(moduleId, entityId);
  }

  async getEntityControllersResume(moduleId: string, entityId: string): Promise<Controller[]> {
    return await CrudAutomationRepository.getEntityControllersResume(moduleId, entityId);
  }

  async getFunctionsPrototypes(
    moduleId: string,
    entityId: string,
    crudInputFunction: CrudInputFunction
  ): Promise<functionEditorCrud[]> {
    return await CrudAutomationRepository.getFunctionsPrototypes(
      moduleId,
      entityId,
      crudInputFunction
    );
  }

  async saveFunctions(
    moduleId: string,
    entityId: string,
    crudCreateFunction: CrudCreateFunction
  ): Promise<FunctionSimple> {
    return await CrudAutomationRepository.saveFunctions(moduleId, entityId, crudCreateFunction);
  }

  async getEndpointsPrototypes(
    moduleId: string,
    entityId: string,
    crudInputEndpoint: CrudInputEndpoint
  ): Promise<Endpoint[]> {
    return await CrudAutomationRepository.getEndpointsPrototypes(
      moduleId,
      entityId,
      crudInputEndpoint
    );
  }

  async createEndpoints(
    moduleId: string,
    entityId: string,
    crudCreateEndpoint: CrudCreateEndpoint
  ): Promise<Endpoint[]> {
    return await CrudAutomationRepository.createEndpoints(moduleId, entityId, crudCreateEndpoint);
  }

  async getViewsPrototypes(
    moduleId: string,
    entityId: string,
    crudInputViews: CrudInputViews
  ): Promise<CrudPage[]> {
    return await CrudAutomationRepository.getViewsPrototypes(moduleId, entityId, crudInputViews);
  }
}
