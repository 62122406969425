import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { initialState } from '../initial_empty_state';
import { GlobalsState } from '../types/function_editor_state';

type ObjectsActions = PayloadAction;

export const globalsReducer = (
  state: GlobalsState = initialState.globals,
  action: ObjectsActions
): GlobalsState => {
  return produce(state, (draft: GlobalsState) => {
    switch (action.type) {
      default:
        return state;
    }
  });
};
