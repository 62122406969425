import { CssClass } from 'web_ui/workboard/sidebar/controls/CssClassesControl/types';
import { CssStylesRepository } from '../repos';

export const API_URL = process.env.REACT_APP_API_URL;

export class CssStylesServ {
  async createCssClass(appId: string, cssClass: CssClass): Promise<CssClass> {
    return await CssStylesRepository.createCssClass(appId, cssClass);
  }

  async getCssClassesByApp(appId: string): Promise<CssClass[]> {
    return await CssStylesRepository.getCssClassesByApp(appId);
  }

  async deleteCssClass(appId: string, cssClassId: string) {
    return await CssStylesRepository.deleteCssClass(appId, cssClassId);
  }

  async updateCssClass(appId: string, cssClass: CssClass): Promise<CssClass> {
    return await CssStylesRepository.updateCssClass(appId, cssClass);
  }
}
