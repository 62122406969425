import React, { ChangeEvent } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultInput, DefaultLabel } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function TextControl(props: ControlProps) {
  const { t } = useTranslation();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (props.onChange) props.onChange(e.target.checked, props.id);
  }

  return (
    <div className="d-flex" style={{ flexDirection: 'column' }}>
      <DefaultLabel
        style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '.5rem' }}
        htmlFor={props.id}
      >
        {props.label}
        {props.tooltip && (
          <HelpPopover
            placement="top"
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </DefaultLabel>

      <DefaultInput
        className={`${styles.defaultInput} mb-2 form-check-input`}
        type="checkbox"
        value={props.value}
        id={props.id}
        onChange={handleChange}
        checked={props.value ?? false}
      />
    </div>
  );
}

export default TextControl;
