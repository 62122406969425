import { SelectData, SelectStyles } from './index';
import { LayoutComponent } from '../../../types';

export const SELECT_TEMPLATE: LayoutComponent<SelectData, SelectStyles> = {
  uuid: 'empty',
  type: 'SELECT',
  data: {
    width: 'calc(100% - 20px)',
    widthUnit: '',
    maxWidth: 'none',
    minWidth: 'none',
    height: 75,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    label: 'Dropdown',
    options: ['One', 'Two', 'Three'],
    optionSizeWidth: 'full',
    optionSizeHeight: 'Fixed'
  },
  styles: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    css: ''
  }
};
