import { FunctionEditorState } from '../../types/function_editor_state';
import { FunctionVariableType } from '../../types/variables';
import { AddBlockAction } from '../actions/add_block';

export function doAddBlockAction(
  state: FunctionEditorState,
  action: AddBlockAction
): FunctionEditorState {
  state.editor.selectedAction = action.payload.openBlockAction.uuid;
  if (action.payload.openBlockAction.type !== 'BEGIN_CYCLE') return state;

  // Create control variable in local state
  const data = action.payload.openBlockAction.data;
  const variable: FunctionVariableType = {
    uuid: data.uuid,
    name: data.name,
    desc: '',
    type: data.dataType.type,
    objectUuid: data.dataType?.objectUuid ?? '',
    enumUuid: data.dataType?.enumUuid ?? '',
    list: false,
    required: false
  };

  state.variables[variable.uuid] = variable;
  return state;
}
