import { ReturnType } from '../store/types/functions';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../store/types/function_editor_state';

function useHandleDisplayTypeName(type: ReturnType, types: ReturnType[]) {
  const state = useSelector((state: FunctionEditorState) => state);

  if (!type) return 'VOID';
  if (!type.type) return 'VOID';

  if (type.enumUuid) {
    if (state.enums[type.enumUuid]) {
      return state.enums[type.enumUuid].name;
    }
    const returnType = types.find((t) => t.type === 'ENUM' && t.enumUuid === type.enumUuid);
    if (returnType) {
      return returnType.name ?? '';
    }
  }

  if (type.objectUuid) {
    if (state.objects[type.objectUuid]) {
      return state.objects[type.objectUuid].name;
    }
    const returnType = types.find((t) => t.type === 'OBJECT' && t.objectUuid === type.objectUuid);
    if (returnType) {
      return returnType.name ?? '';
    }
  }

  return type.type;
}

export default useHandleDisplayTypeName;
