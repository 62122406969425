import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

export const ALERT_MANIFEST: ComponentManifest = {
  type: 'ALERT',
  name: 'Alert',
  descriptionId: 'AlertDescription',
  description: 'It is a custom Alert component for custom layouts.',
  icon: 'fa-solid fa-circle-info',
  group: ComponentGroups['ALERT'],
  previewHeight: 60,
  previewWidth: 300,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'BodyText',
      key: 'text',
      tooltip: 'designer.right_side.controls.components.alert.bodyText'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.alert.visible'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'HeaderText',
      key: 'title',
      tooltip: 'designer.right_side.controls.components.alert.title'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.alert.tooltip'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'ShowHeader',
      key: 'header',
      tooltip: 'designer.right_side.controls.components.alert.header'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.alert.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.alert.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Background',
      key: 'background',
      controlOptions: [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark'
      ],
      tooltip: 'designer.right_side.controls.components.alert.selectBackground'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Text Color',
      tooltip: 'designer.right_side.controls.components.alert.textColor'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.alert.fontSize'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
