import React from 'react';
import styles from './styles.module.css';
import ParameterEditor from '../parameter_editor/parameter_editor';
import ParameterWrapper from '../parameter_editor/parameter_wrapper';
import AddParameterButton from '../parameter_editor/add_parameter_button';
import ParametersSpacerRoot from '../parameter_editor/parameter_spacer';
import { FunctionParameterType } from '../../../../store/types/parameters';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { changeParamOrder } from '../../../../store/events/functions/change_param_order';
import { addParam } from '../../../../store/events/parameters/add_param';
import { v4 as uuidv4 } from 'uuid';
import { ReturnType } from '../../../../store/types/functions';
import { useTranslation } from 'react-i18next';

type ParametersListEditorProps = {
  types: ReturnType[];
};

function ParameterListEditor({ types }: ParametersListEditorProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const function_id = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const params = useSelector(
    (state: FunctionEditorState) => state.functions[function_id ?? ''].parameters
  );

  const handleDrop = (e: React.DragEvent, index: number) => {
    const draggedIndex = e.dataTransfer.getData('exocode/dragged-parameter-index');

    if (draggedIndex === '') return;
    const origindUuid = params[Number.parseInt(draggedIndex)];
    const targetUuid = index === params.length ? params[index - 1] : params[index];
    dispatch(changeParamOrder(function_id ?? '', origindUuid, targetUuid));
  };

  const handleAddParameter = () => {
    const param: FunctionParameterType = {
      uuid: uuidv4(),
      name: `param${params.length}`,
      type: 'STRING',
      desc: '',
      list: false,
      required: false
    };
    dispatch(
      addParam(
        function_id ?? '',
        param.uuid,
        param.name,
        param.type,
        param.desc,
        param.list,
        param.required
      )
    );
  };

  return (
    <div className={`${styles.Parameters}`}>
      <label style={{ textDecoration: 'underline', color: 'white', fontWeight: '100' }}>
        {t('Params')}:
      </label>
      <div className={styles.ParametersWrapper}>
        {params.map((p, index) => (
          <React.Fragment key={index}>
            <ParametersSpacerRoot index={index} handleDrop={handleDrop} />
            <ParameterWrapper index={index} handleDrop={handleDrop}>
              <ParameterEditor uuid={p} types={types} />
            </ParameterWrapper>
          </React.Fragment>
        ))}
        {params.length !== 0 && (
          <ParametersSpacerRoot index={params.length} handleDrop={handleDrop} />
        )}
        <AddParameterButton handleClick={handleAddParameter} />
      </div>
    </div>
  );
}

export default ParameterListEditor;
