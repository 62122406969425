import React from 'react';
import { Provider } from 'react-redux';

import { DesignerMode, InterfaceStudioState, store } from './store';
import StoreUpdater from './store/store_updater';
import { ViewUUID, Editor } from '../types';
import Studio from './studio';

type InterfaceStudioProps = {
  editor: keyof typeof Editor;
  module_id: ViewUUID;
  view_id: ViewUUID;
  toggleTheme(): void;
  state: InterfaceStudioState;
  initialTab: DesignerMode;
};

function InterfaceStudio(props: InterfaceStudioProps) {
  return (
    <Provider store={store}>
      {props.view_id && (
        <StoreUpdater
          editor={props.editor}
          module_id={props.module_id}
          view_id={props.view_id}
          state={props.state}
          initialTab={props.initialTab}
        />
      )}
      <Studio toggleTheme={props.toggleTheme} />
    </Provider>
  );
}

export default InterfaceStudio;
