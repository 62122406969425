import React from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

export type IconStyleProps = {
  isSimpleIcon: boolean;
  value?: any;
  position?: 'left' | 'center' | 'right';
  onChange?: (value: any, key: any) => any;
  handleChangeStaticColor: (staticColor: string) => void;
  handleChangeThemeColor: (themeColor: string) => void;
};

function IconStyle(props: IconStyleProps) {
  const { t } = useTranslation();
  return (
    <div className="mb-4">
      <div className="d-flex flex-column justify-content-center align-items-center mt-2">
        <label className={`${styles.rangeLabel} mb-0 form-label`}>
          {props.value ? (props.value.iconSize ? props.value.iconSize + 'px' : '-') : '-'}
        </label>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div style={{ fontSize: '1rem' }}>{t('designer.right_side.Size')}</div>
          <div className=" d-flex" style={{ width: '60%' }}>
            {
              <input
                type="range"
                value={props.value ? (props.value.iconSize ? props.value.iconSize : '-') : ''}
                min="8"
                max="40"
                className="form-range"
                onChange={(ev) => {
                  props.onChange && props.onChange?.(ev.target.value, 'iconSize');
                }}
              />
            }
          </div>
        </div>
      </div>

      {(!props.position || props.position !== 'center') && props.value && (
        <div className="d-flex flex-column justify-content-center align-items-center mt-2">
          <label className={`${styles.rangeLabel} mb-0 form-label`}>
            {props.value ? (props.value.iconMargin ? props.value.iconMargin + 'px' : '-') : '-'}{' '}
          </label>

          <div className="d-flex w-100 justify-content-between align-items-center">
            <div style={{ fontSize: '1rem' }}>{t('designer.right_side.Distance')}</div>
            <div className="w-60 d-flex" style={{ width: '60%' }}>
              <input
                type="range"
                value={props.value ? props.value.iconMargin : ''}
                min="0"
                max="40"
                className="form-range"
                onChange={(ev) => {
                  props.onChange && props.onChange?.(ev.target.value, 'iconMargin');
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IconStyle;
