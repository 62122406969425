import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import cronstrue from 'cronstrue';
import { FunctionService, ScheduledsService } from 'modules/logic_builder/services';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import { FunctionExtended, FunctionSimple, SchedulerJob } from '../../../types';
import { useParams } from 'react-router-dom';

type ScheduledDialogProps = {
  show: boolean;
  onClose: () => void;
  schedulerJob?: SchedulerJob;
  editMode: boolean;
  dialogTitle: string;
  onSuccess?: (newJob: SchedulerJob) => void;
  onDelete?: (id: string) => void;
};

export function ScheduledDialog({
  show,
  onClose,
  editMode,
  dialogTitle,
  schedulerJob,
  onSuccess,
  onDelete
}: ScheduledDialogProps) {
  const isNative = false;
  const { module_id } = useParams();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [cronExpression, setCronExpression] = useState('* * * * *');
  const [scheduledName, setScheduledName] = useState('');
  const [scheduledDescription, setScheduledDescription] = useState('');
  const [functionId, setFunctionId] = useState('');

  const [isValid, setIsValid] = useState(true);
  const [editingSection, setEditingSection] = useState('');
  const [expressionFeedback, setExpressionFeedback] = useState<string>();
  const [functionsList, setFunctionsList] = useState<FunctionSimple[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fetchFunctions = useCallback(async () => {
    if (!module_id) {
      return;
    }
    await FunctionService.getFunctions(module_id).then(async (fetchedFunctions) => {
      const functionsWithParameters: FunctionExtended[] = [];
      for (const fn of fetchedFunctions) {
        if (!fn.uuid) {
          continue;
        }
        // TODO: do not make these requests.
        await FunctionService.getFunction(fn.uuid).then((functionInfo) => {
          if (!functionInfo.parameters.length) {
            functionsWithParameters.push(functionInfo);
          }
        });
      }
      setFunctionsList(functionsWithParameters);
    });
  }, [module_id]);

  useEffect(() => {
    if (!show) {
      return;
    }
    if (editMode && schedulerJob) {
      fetchFunctions();
      setScheduledName(schedulerJob.name);
      setScheduledDescription(schedulerJob.description);
      setFunctionId(schedulerJob.functionUuid);
      setCronExpression(schedulerJob.cronExpression);
    } else {
      fetchFunctions();
      setScheduledName('');
      setScheduledDescription('');
      setFunctionId('');
      setCronExpression('* * * * *');
    }
  }, [editMode, fetchFunctions, schedulerJob, show]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isLoading) return;
    if (!functionId || !module_id) return;

    if (editMode && schedulerJob) {
      const newSchedulerJob: SchedulerJob = {
        ...schedulerJob,
        name: scheduledName,
        description: scheduledDescription,
        cronExpression: cronExpression,
        functionUuid: functionId
      };
      await ScheduledsService.updateScheduled(
        newSchedulerJob,
        module_id,
        newSchedulerJob.uuid ?? ''
      )
        .then((updated) => {
          onSuccess && onSuccess(updated);
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const newScheduled: SchedulerJob = {
        moduleUuid: module_id,
        functionUuid: functionId,
        cronExpression: cronExpression,
        name: scheduledName,
        description: scheduledDescription
      };
      setIsLoading(true);
      await ScheduledsService.createScheduled(module_id, newScheduled)
        .then((created) => {
          onSuccess && onSuccess(created);
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const handleCronChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCronExpression(event.target.value);
    const cronRegex = /^[\d\-,*/]+\s[\d\-,*/]+\s[\d\-,*/]+\s[\d\-,*/]+\s[\d\-,*/]+$/;
    setIsValid(cronRegex.test(event.target.value));
    try {
      const description = cronstrue.toString(cronExpression);
      setExpressionFeedback(description);
    } catch (error) {
      setExpressionFeedback(
        t('logicBuilder.scheduledTab.Invalid') ?? 'logicBuilder.scheduledTab.Invalid'
      );
    }
  };

  const handleSectionSelect = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart;
    if (cursorPosition) {
      const sections = [
        t('logicBuilder.scheduledTab.Minute'),
        t('logicBuilder.scheduledTab.Hour'),
        t('logicBuilder.scheduledTab.DayMonth'),
        t('logicBuilder.scheduledTab.Month'),
        t('logicBuilder.scheduledTab.DayWeek')
      ];
      const sectionIndex = Math.floor(cursorPosition / (input.value.length / sections.length));
      setEditingSection(sections[sectionIndex]);
    }
  };

  const handleDeleteSchedulerJob = useCallback(async () => {
    if (isLoading) return;
    if (!module_id) return;
    if (!editMode || !schedulerJob || !schedulerJob.uuid) return;

    setIsLoading(true);
    await ScheduledsService.deleteScheduled(schedulerJob.uuid, module_id)
      .then(() => {
        onDelete && onDelete(schedulerJob.uuid ?? '');
        setShowConfirmationDialog(false);
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [editMode, isLoading, module_id, onClose, onDelete, schedulerJob]);

  return (
    <>
      <Modal show={show} onHide={() => onClose()} size="lg" centered scrollable>
        <Form onSubmit={onSubmit} className={styles.MainWrapperForm} id="formModal">
          <Modal.Header closeButton>
            <Modal.Title>{t('logicBuilder.scheduledTab.' + dialogTitle)}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
            <Form.Group as={Row} className="mb-3" controlId="formName">
              <Col sm={10}>
                <Form.Label column sm={5}>
                  {t('logicBuilder.scheduledTab.Name')}
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={64}
                  value={scheduledName}
                  required
                  disabled={isNative}
                  onChange={(e) => setScheduledName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDescription">
              <Col sm={10}>
                <Form.Label column sm={5}>
                  {t('logicBuilder.scheduledTab.Description')}
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={255}
                  value={scheduledDescription}
                  required
                  disabled={isNative}
                  onChange={(e) => setScheduledDescription(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formCron">
              <Col sm={10}>
                <Form.Label column sm={5}>
                  {t('logicBuilder.scheduledTab.CronExpression')}
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={45}
                  value={cronExpression}
                  required
                  disabled={isNative}
                  onChange={handleCronChange}
                  onKeyUp={handleSectionSelect}
                />
                <p id="bodyMessage">&quot;{expressionFeedback}&quot;</p>
                {!isValid && (
                  <p id="invalidMessage" style={{ color: 'red' }}>
                    {t('logicBuilder.scheduledTab.Invalid')}
                  </p>
                )}
                {editingSection && (
                  <p>
                    {t('logicBuilder.scheduledTab.Editing')}: {editingSection}
                  </p>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formFunc">
              <Col sm={10}>
                <Form.Label>{t('logicBuilder.scheduledTab.Function')}</Form.Label>
                <Form.Select
                  id={'selectFunction'}
                  aria-label="object"
                  onChange={(e) => setFunctionId(e.target.value)}
                  value={functionId}
                  required
                >
                  <option value={''}>Select function</option>
                  {functionsList.map((fn) => {
                    return (
                      <option id={fn.uuid} key={fn.uuid} value={fn.uuid}>
                        {fn.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {editMode && (
              <>
                <Button
                  id="deleteButton"
                  variant="danger"
                  onClick={() => setShowConfirmationDialog(true)}
                >
                  {t('logicBuilder.scheduledTab.Delete')}
                </Button>
                <Button id="cancelButton" variant="secondary" onClick={() => onClose()}>
                  {t('logicBuilder.scheduledTab.Cancel')}
                </Button>
                <Button id="saveButton" variant="primary" type="submit" disabled={isNative}>
                  {t('logicBuilder.scheduledTab.Save')}
                </Button>
              </>
            )}
            {!editMode && (
              <>
                <Button id="cancelButton" variant="secondary" onClick={() => onClose()}>
                  {t('logicBuilder.scheduledTab.Cancel')}
                </Button>
                <Button id="saveButton" variant="primary" type="submit">
                  {t('logicBuilder.scheduledTab.Create')}
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={t('deleteQuotes.scheduled')}
        onConfirmation={handleDeleteSchedulerJob}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
