import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useSession from 'hooks/useSession';
import { ApiAppInfo } from 'modules/project/types';
import {
  ContextRole,
  MembersAndTeams,
  Role,
  RoleOptions,
  UpdateUserApp,
  UserProfile,
  UserTeam,
  UserWithRole
} from 'modules/auth/types/auth_types';
import { OrganizationService, TeamServ } from 'modules/organization/services';
import { ProjectsService } from 'modules/project/services';
import Icon from 'web_ui/icon';

import styles from './style.module.css';
import { useParams } from 'react-router-dom';
import { Team } from './components/team';
import { Member } from './components/member';
import { AddPeopleModal } from './components/add_people_modal';
import { AddTeamModal } from './components/add_team_modal';

export type InviteAppModalProps = {
  showModal: boolean;
  onCloseRequest: () => void;
  onCloseModal: () => void;
  app: ApiAppInfo;
  membersOfApp: UserProfile[];
};

function InviteAppModal({
  showModal,
  onCloseRequest,
  onCloseModal,
  app,
  membersOfApp
}: InviteAppModalProps) {
  const [session] = useSession();
  const { app_id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currMembersAndTeams, setCurrMembersAndTeams] = useState<MembersAndTeams>();
  const [membersToRemove, setMembersToRemove] = useState<UserWithRole[]>([]);
  const [teamToRemove, setTeamToRemove] = useState<number[]>([]);
  const [openModalAddPeople, setOpenModalAddPeople] = useState<boolean>(false);
  const [openModalAddTeams, setOpenModalAddTeams] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    allColaborators();
  }, [app_id]);

  const allColaborators = async () => {
    if (!app_id) return;
    const dataRetrieved = await ProjectsService.getMembersOfApp(app_id);
    if (dataRetrieved) setCurrMembersAndTeams(dataRetrieved);
    setMembersToRemove([]);
  };

  const saving = async () => {
    if (!app_id) return;

    const dataToSend: UpdateUserApp = {
      usersToAdd: [],
      usersToRemove: membersToRemove,
      teamsToAdd: [],
      teamsToRemove: teamToRemove
    };

    const dataRetrieved = await ProjectsService.updateMembersApp(app_id, dataToSend);
    if (dataRetrieved) allColaborators();
  };

  const addingMemberToRemove = (userToRemove: UserWithRole) => {
    setMembersToRemove((val) => [...val, userToRemove]);
  };

  const addingTeamToRemove = (teamId: number) => {
    setTeamToRemove((val) => [...val, teamId]);
  };

  const closeModalAddPeople = () => {
    setOpenModalAddPeople(false);
  };

  const closeModalAddTeam = () => {
    setOpenModalAddTeams(false);
  };

  return (
    <Modal
      centered
      show={showModal}
      onHide={() => {
        onCloseModal();
      }}
      backdrop={'static'}
      size={'lg'}
      className={styles.containerModal}
    >
      <form>
        <Modal.Header closeButton className="bg-body">
          <Modal.Title className={`text-body-emphasis ${styles.newAppModalTitle}`}>
            <strong>Manage Access</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-body">
          <div className={styles.containerButtons}>
            <Button variant="success" onClick={() => setOpenModalAddPeople(true)}>
              Add people
            </Button>
            <Button
              variant="success"
              onClick={() => {
                setOpenModalAddTeams(true);
              }}
            >
              Add teams
            </Button>
          </div>
          <div style={{ marginTop: 10, height: 400, overflowY: 'auto' }}>
            {/* Teams */}
            {currMembersAndTeams &&
              currMembersAndTeams.teams &&
              currMembersAndTeams.teams.map((item, index) => (
                <Team
                  key={index}
                  team={item}
                  isFromAddTeam={false}
                  owner_id={undefined}
                  addingTeamToRemove={addingTeamToRemove}
                  teamsToRemove={teamToRemove}
                />
              ))}
            {/* Members */}
            {currMembersAndTeams &&
              currMembersAndTeams.users &&
              currMembersAndTeams.users.map((item, index) => (
                <Member
                  membersToRemove={membersToRemove}
                  key={index}
                  user={item}
                  addingMemberToRemove={addingMemberToRemove}
                  isFromAddPeople={false}
                  owner_id={undefined}
                />
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saving()}>
            Save
          </Button>
        </Modal.Footer>
      </form>
      <AddPeopleModal
        showModal={openModalAddPeople}
        onCloseModal={closeModalAddPeople}
        currMembersAndTeams={currMembersAndTeams}
        reloadRootList={allColaborators}
      />
      <AddTeamModal
        showModal={openModalAddTeams}
        onCloseModal={closeModalAddTeam}
        currMembersAndTeams={currMembersAndTeams}
        reloadRootList={allColaborators}
      />
    </Modal>
  );
}

export default InviteAppModal;
