import { ComponentManifest } from 'modules/designer/types';

export const CUSTOM_COMPONENT_MANIFEST: ComponentManifest = {
  type: 'CUSTOM',
  name: 'Custom component',
  descriptionId: 'CustomComponentDescription',
  description: 'Custom component.',
  icon: 'fa-solid fa-border-none',
  properties: [],
  styles: [],
  previewHeight: 100,
  previewWidth: 100
};
