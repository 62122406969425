import React from 'react';
import { ControlProps } from '..';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { Badge, Form, InputGroup } from 'react-bootstrap';
import { DataTableColumn } from 'modules/designer/studio/exocode_components/data_table';
import VariableControl from '../VariableControl';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { FRONTEND_VARIABLE_TYPES, VariableTypes } from 'modules/designer/types';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';
import ErrorIcon from '../components/ErrorIcon';

function TableSelectorControl(props: ControlProps) {
  const UIFunctions = useSelector((state: InterfaceStudioState) => state.functions);
  const { t } = useTranslation();

  function handleSelectKeyColumns(columnUuid: string) {
    if (
      props.value.selectColumnKeys.length === 1 &&
      props.value.selectColumnKeys.includes(columnUuid)
    ) {
      return null;
    }
    const newKeyColumns = props.value.selectColumnKeys
      ? props.value.selectColumnKeys.includes(columnUuid)
        ? props.value.selectColumnKeys.filter((columnKey: string) => columnKey !== columnUuid)
        : [...props.value.selectColumnKeys, columnUuid]
      : [columnUuid];

    props.onChange && props.onChange(newKeyColumns, 'selectColumnKeys');
  }

  return (
    <div className="mb-3 pb-2 border-bottom text-body-secondary">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div style={{ display: 'flex' }}>
          <label className="position-relative mb-1 text-body">{props.label}</label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.controls.TableSelectableTitle') ||
                'designer.right_side.controls.TableSelectableTitle',
              description:
                t('designer.right_side.controls.TableSelectableDescription') ||
                'designer.right_side.controls.TableSelectableDescription',
              note: [
                t('designer.right_side.controls.TableSelectableNote01'),
                t('designer.right_side.controls.TableSelectableNote02')
              ]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </div>
        <Form.Check
          type="switch"
          id="custom-switch"
          checked={props.value?.selectable}
          value={props.value?.selectable}
          onChange={(e) => {
            props.onChange && props.onChange(e.target.checked, 'selectable');
          }}
        />
      </div>

      {props.value?.selectable && (
        <div>
          {props.value?.selectable && (
            <>
              <div className="mb-3">
                <div>
                  <MissingMessage
                    type={MissingMessageType.VARIABLE}
                    uuid={props.value?.selectedItems?.uuid}
                    requiredTypes={[FRONTEND_VARIABLE_TYPES.OBJECT as VariableTypes]}
                  />
                  <Form.Label>{t('designer.right_side.controls.SelectVariable')}</Form.Label>
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('designer.right_side.controls.SelectVariableTitle') ||
                        'designer.right_side.controls.SelectVariableTitle',
                      description:
                        t('designer.right_side.controls.SelectVariableDescription') ||
                        'designer.right_side.controls.SelectVariableDescription',
                      note: [t('designer.right_side.controls.SelectVariableNote01')]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </div>

                <VariableControl
                  id={'selectedItems'}
                  key={'selectedItems'}
                  label=""
                  value={props.value?.selectedItems}
                  onChange={props.onChange}
                  options={['list', FRONTEND_VARIABLE_TYPES.OBJECT]}
                />
              </div>

              <div className="mb-3">
                <div>
                  <Form.Label>{t('designer.right_side.Type')}</Form.Label>
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('designer.right_side.controls.SelectTypeTitle') ||
                        'designer.right_side.controls.SelectTypeTitle',
                      description:
                        t('designer.right_side.controls.SelectTypeDescription') ||
                        'designer.right_side.controls.SelectTypeDescription',
                      note: [
                        t('designer.right_side.controls.SelectTypeNote01'),
                        t('designer.right_side.controls.SelectTypeNote02')
                      ]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </div>
                <Form.Select
                  value={props.value?.selectType}
                  onChange={(e) => props.onChange && props.onChange(e.target.value, 'selectType')}
                  isValid={props.value?.selectType}
                  required
                >
                  <option defaultChecked key="SINGULAR" value="SINGULAR">
                    Singular
                  </option>
                  <option key="MULTI" value="MULTI">
                    Multi
                  </option>
                </Form.Select>
              </div>

              <div className="mb-3">
                <div>
                  <Form.Label>
                    {props.value?.selectColumnKeys?.length < 1 && (
                      <ErrorIcon title={'designer.right_side.controls.errors.NoSelectKeyColumn'} />
                    )}

                    {t('designer.right_side.KeyColumns')}
                  </Form.Label>
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('designer.right_side.controls.KeyColumnsTitle') ||
                        'designer.right_side.controls.KeyColumnsTitle',
                      description:
                        t('designer.right_side.controls.KeyColumnsDescription') ||
                        'designer.right_side.controls.KeyColumnsDescription',
                      note: [t('designer.right_side.controls.KeyColumnsNote01')]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </div>

                <div>
                  {props.value?.dataSource?.columns.map(
                    (column: DataTableColumn, index: number) => (
                      <p
                        key={index}
                        className="d-inline me-2"
                        onClick={(e) => handleSelectKeyColumns(column.schemaItemUuid)}
                      >
                        <Badge
                          bg={`${
                            props.value.selectColumnKeys &&
                            props.value.selectColumnKeys.includes(column.schemaItemUuid)
                              ? 'primary'
                              : 'secondary'
                          }`}
                          style={{ cursor: 'pointer' }}
                        >
                          {column.title}
                        </Badge>
                      </p>
                    )
                  )}
                </div>
              </div>

              {/* {column.editable && (
                <div className="mb-3 pb-3 border-bottom">
                  <label className="mb-1">{t('designer.right_side.controls.EditFunction')}</label>

                  <InputGroup size="sm" className="mt-2">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      {t('designer.right_side.controls.ActionFunction')}
                    </InputGroup.Text>
                    <Form.Select
                      value={editFunction}
                      onChange={async (e) => setEditFunction(e.target.value)}
                    >
                      <option value="">---</option>

                      {UIFunctions &&
                        Object.keys(UIFunctions)?.map((functionKey) => (
                          <option
                            key={UIFunctions[functionKey].name}
                            value={UIFunctions[functionKey].uuid}
                          >
                            {UIFunctions[functionKey].name}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                </div>
              )} */}
              {props.value?.dataSource?.columns.some(
                (column: DataTableColumn) => column.editable
              ) && (
                <div className="mb-3 pb-1">
                  <label className="mb-1">{t('designer.right_side.controls.EditFunction')}</label>

                  <InputGroup size="sm" className="mt-2">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      {t('designer.right_side.controls.ActionFunction')}
                    </InputGroup.Text>
                    <Form.Select
                      value={props.value?.editFunction}
                      // onChange={async (e) => setEditFunction(e.target.value)}
                      onChange={(e) =>
                        props.onChange && props.onChange(e.target.value, 'editFunction')
                      }
                    >
                      <option value="">---</option>

                      {UIFunctions &&
                        Object.keys(UIFunctions)?.map((functionKey) => (
                          <option
                            key={UIFunctions[functionKey].name}
                            value={UIFunctions[functionKey].uuid}
                          >
                            {UIFunctions[functionKey].name}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default TableSelectorControl;
