import { AutomationsRepo } from './AutomationsRepo';
import { EndpointsRepo } from './EndpointsRepo';
import { FunctionsRepo } from './FunctionsRepo';
import { ControllersRepo } from './ControllersRepo';
import { ObjectsRepo } from './ObjectsRepo';
import { AppRoleRepo } from './AppRoleRepo';
import { ActionsRepo } from './ActionsRepo';
import { ServicesRepo } from './ServicesRepo';
import { ScheduledsRepo } from './ScheduledsRepo';
import { CrudAutomationRepo } from './CrudAutomationRepo';
import { TagsRepo } from './TagsRepo';
import { CodePreviewRepo } from './CodePreviewRepo';

export const AutomationsRepository = new AutomationsRepo();
export const EndpointsRepository = new EndpointsRepo();
export const TagsRepository = new TagsRepo();
export const FunctionsRepository = new FunctionsRepo();
export const ControllersRepository = new ControllersRepo();
export const ServicesRepository = new ServicesRepo();
export const ObjectsRepository = new ObjectsRepo();
export const ScheduledsRepository = new ScheduledsRepo();
export const AppRoleRepository = new AppRoleRepo();
export const ActionsRepository = new ActionsRepo();
export const CrudAutomationRepository = new CrudAutomationRepo();
export const CodePreviewRepository = new CodePreviewRepo();
