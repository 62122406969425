import React from 'react';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import BeginCycleIcon from './begin_cycle_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { t } from 'i18next';
import BeginIfIcon from './begin_if_icon';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';

function validateExpression(state: FunctionEditorState, name: string): boolean {
  return false;
}

export const BEGIN_IF_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.BEGIN_IF,
  name: 'IF',
  description: 'Action for create a IF statement',
  color: 'secondary',
  category: BACKEND_ACTIONS_CATEGORIES.CONDITION,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.CONDITION,
  icon: <BeginIfIcon />,
  openBlock: true,
  createIf: true,
  closeBlockType: BACKEND_ACTIONS_TYPES.END_IF,
  inlineInputs: [
    {
      label: '',
      placeholder: `a == b`,
      key: 'if',
      type: ACTION_INPUTS_TYPES.TEXT,
      options: { validator: validateExpression }
    }
  ],
  inputs: []
};
