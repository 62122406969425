import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import {
  ACTION_TYPES,
  Action,
  ActionType,
  FunctionParameter,
  FunctionType,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import { Button, Form, Modal } from 'react-bootstrap';
import { ActionsService, FunctionService, ObjectsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import LogicBuilderContext from 'modules/logic_builder/store';
import {
  AddListActionType,
  BeginCycleActionType,
  ClearListActionType,
  CreateActionType,
  CustomCodeActionType,
  CustomQueryActionType,
  DeclareVariableActionType,
  DeleteActionType,
  DeleteFileActionType,
  ReadActionType,
  ReadFileActionType,
  RemoveListActionType,
  RunFunctionActionType,
  SaveFileActionType,
  SetVariableActionType,
  UpdateActionType
} from './types';
import RenderCreateEntityInputs from './components/CreateEntityInputs';
import RenderReadEntityInputs from './components/ReadEntityInputs';
import RenderUpdateEntityInputs from './components/UpdateEntityInputs';
import RenderDeleteEntityInputs from './components/DeleteEntityInputs';
import RenderRunFunctionInputs from './components/RunFunctionInputs';
import RenderSetVariableInputs from './components/SetVariable';
import RenderDeclareVariable from './components/DeclareVariable/renderDeclareVariable';
import RenderAddListInputs from './components/AddList';
import RenderRemoveList from './components/RemoveList';
import RenderClearList from './components/ClearList';
import RenderBeginCycle from './components/BeginCycle';
import RenderEndCycle from './components/EndCycle';
import RenderSaveFile from './components/SaveFile';
import RenderDeleteFile from './components/DeleteFile';
import RenderReadFile from './components/ReadFile';
import RenderCustomCode from './components/CustomCode';
import RenderCustomQuery from './components/CustomQuery';
import { INITIAL_STATES_ACTIONS } from './initial_states_actions';
import { useTranslation } from 'react-i18next';

type FunctionActionCreatorDialogProps = {
  show: boolean;
  onClose: () => void;
  functionId: string;
  fetchActions: () => void;
  editMode: boolean;
  dialogTitle: string;
  action?: Action<any>;
};

export function FunctionActionCreatorDialog(props: FunctionActionCreatorDialogProps) {
  const { module_id } = useParams();
  const { functions } = useContext(LogicBuilderContext);
  const [newAction, setNewAction] = useState<Action<any>>({
    order: 0,
    type: 'CREATE_ENTITY',
    data: {
      objectId: '',
      list: false,
      arguments: {
        entity: {
          type: 'VAR',
          value: ''
        }
      }
    },
    returnVariableUuid: ''
  });

  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const [variables, setVariables] = useState<FunctionVariable[]>([]);
  const [parameters, setParameters] = useState<FunctionParameter[]>([]);
  const [functionsList, setFunctionsList] = useState<FunctionType[]>([]);
  const [messageError, setMessageError] = useState<string[]>([]);
  const { t } = useTranslation();

  const DeleteAction = useCallback(async () => {
    if (!newAction.uuid) return;
    await FunctionService.DeleteFunctionAction(props.functionId, newAction.uuid);
    props.fetchActions();
    props.onClose();
  }, [newAction.uuid]);

  const fetchVariables = useCallback(async () => {
    await FunctionService.getFunctionVariables(props.functionId).then((variables) => {
      setVariables(variables);
    });
  }, [props.functionId]);

  const fetchObjects = useCallback(async () => {
    if (!module_id) return;

    await ObjectsService.getObjectsByModule(module_id).then((fetchedObjects) => {
      setObjects(fetchedObjects);
    });
  }, [module_id]);

  const fetchParameters = useCallback(async () => {
    await FunctionService.getParameters(props.functionId).then((fetchedParameters) => {
      setParameters(fetchedParameters);
    });
  }, [props.functionId]);

  useEffect(() => {
    if (!props.show) return;

    if (!props.editMode) {
      setNewAction({
        order: 0,
        type: 'CREATE_ENTITY',
        data: {
          objectId: '',
          list: false,
          arguments: {
            entity: {
              type: 'VAR',
              value: ''
            }
          }
        },
        returnVariableUuid: ''
      });
    } else if (props.action) {
      setNewAction(props.action);
    }
    setMessageError([]);
  }, [props.action, props.editMode, props.show]);

  useEffect(() => {
    if (!props.show) return;

    let functionList: FunctionType[] = [];
    for (const fnList of Object.values(functions)) {
      functionList = functionList.concat(fnList);
    }
    functionList.filter((fn) => fn.uuid !== props.functionId);
    setFunctionsList(functionList);
  }, [functions, props.functionId, props.show]);

  // Set this function's variables list.
  useEffect(() => {
    if (!props.show) return;
    fetchVariables();
  }, [fetchVariables, props.show]);

  // Set this module's objects list.
  useEffect(() => {
    if (!props.show) return;
    fetchObjects();
  }, [fetchObjects, props.show]);

  // Set this function's parameters list.
  useEffect(() => {
    if (!props.show) return;
    fetchParameters();
  }, [fetchParameters, props.show]);

  useEffect(() => {
    setMessageError([]);
  }, [newAction.type]);

  const validationDataToSubmit = (): boolean => {
    setMessageError([]);
    let auxCount = 0;
    switch (newAction.type) {
      case 'CREATE_ENTITY':
        // selected the wrong option
        if (!newAction.data.objectId || newAction.data.objectId.split('-').length <= 1) {
          setMessageError((currentVal) => [...currentVal, 'Object was not filled']);
          auxCount++;
        }
        if (
          !newAction.data.arguments.entity.value ||
          newAction.data.arguments.entity.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'Entity was not filled']);
          auxCount++;
        }
        break;
      case 'READ_ENTITY':
        if (!newAction.data.objectId || newAction.data.objectId.split('-').length <= 1) {
          setMessageError((currentVal) => [...currentVal, 'Object was not filled']);
          auxCount++;
        }
        break;
      case 'UPDATE_ENTITY':
        if (!newAction.data.objectId || newAction.data.objectId.split('-').length <= 1) {
          setMessageError((currentVal) => [...currentVal, 'Object was not filled']);
          auxCount++;
        }
        if (
          !newAction.data.arguments.entity.value ||
          newAction.data.arguments.entity.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'Entity was not filled']);
          auxCount++;
        }
        break;
      case 'DELETE_ENTITY':
        if (!newAction.data.objectId || newAction.data.objectId.split('-').length <= 1) {
          setMessageError((currentVal) => [...currentVal, 'Object was not filled']);
          auxCount++;
        }
        if (
          (!newAction.data.arguments.entity.value ||
            newAction.data.arguments.entity.value.split('-').length <= 1) &&
          (!newAction.data.arguments.id.value ||
            newAction.data.arguments.id.value.split('-').length <= 1)
        ) {
          setMessageError((currentVal) => [...currentVal, 'Entity or id were not filled']);
          auxCount++;
        }

        break;
      case 'RUN_FUNCTION':
        newAction.data.function.functionId = props.functionId;
        if (!newAction.data.function.functionId) {
          setMessageError((currentVal) => [...currentVal, 'Function was not filled']);
          auxCount++;
        }
        break;
      case 'SET_VARIABLE':
        if (
          !newAction.data.arguments.source.value ||
          newAction.data.arguments.source.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'Source was not filled']);
          auxCount++;
        }
        if (
          !newAction.data.arguments.target.value ||
          newAction.data.arguments.target.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'Target was not filled']);
          auxCount++;
        }
        break;
      case 'DECLARE_VARIABLE':
        if (
          !newAction.data.arguments.input.value ||
          newAction.data.arguments.input.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'Input was not filled']);
          auxCount++;
        }
        break;
      case 'ADD_LIST':
        if (
          !newAction.data.arguments.list.value ||
          newAction.data.arguments.list.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'List was not filled']);
          auxCount++;
        }
        if (
          !newAction.data.arguments.object.value ||
          newAction.data.arguments.object.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'Object was not filled']);
          auxCount++;
        }
        break;
      case 'REMOVE_LIST':
        if (
          !newAction.data.arguments.list.value ||
          newAction.data.arguments.list.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'List was not filled']);
          auxCount++;
        }
        if (
          !newAction.data.arguments.object.value ||
          newAction.data.arguments.object.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'Object was not filled']);
          auxCount++;
        }
        break;
      case 'CLEAR_LIST':
        if (
          !newAction.data.arguments.list.value ||
          newAction.data.arguments.list.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'List was not filled']);
          auxCount++;
        }
        break;
      case 'BEGIN_CYCLE':
        if (
          !newAction.data.arguments.list.value ||
          newAction.data.arguments.list.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'List was not filled']);
          auxCount++;
        }
        if (
          !newAction.data.arguments.object.value ||
          newAction.data.arguments.object.value.split('-').length <= 1
        ) {
          setMessageError((currentVal) => [...currentVal, 'Object was not filled']);
          auxCount++;
        }
        break;
      case 'END_CYCLE':
        break;
    }

    if (auxCount !== 0) {
      // if error exist we need to cancel the submit
      return false;
    } else {
      return true;
    }
  };

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (!newAction) return;

    if (validationDataToSubmit()) {
      if (!props.editMode) {
        await FunctionService.addFunctionAction(props.functionId, newAction).then(() => {
          props.fetchActions();
          props.onClose();
        });
      } else {
        await ActionsService.updateAction(newAction.uuid ?? '', newAction).then(() => {
          props.fetchActions();
          props.onClose();
        });
      }
    }
  }

  function actionTypeOnChange(e: ChangeEvent<HTMLSelectElement>) {
    const actionType = e.target.value;
    switch (actionType) {
      case 'CREATE_ENTITY':
        setNewAction({
          ...(newAction as Action<CreateActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.CREATE_ENTITY }
        });
        break;
      case 'READ_ENTITY':
        setNewAction({
          ...(newAction as Action<ReadActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.READ_ENTITY }
        });
        break;
      case 'UPDATE_ENTITY':
        setNewAction({
          ...(newAction as Action<UpdateActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.UPDATE_ENTITY }
        });
        break;
      case 'DELETE_ENTITY':
        setNewAction({
          ...(newAction as Action<DeleteActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.DELETE_ENTITY }
        });
        break;
      case 'RUN_FUNCTION':
        setNewAction({
          ...(newAction as Action<RunFunctionActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.RUN_FUNCTION }
        });
        break;
      case 'SET_VARIABLE':
        setNewAction({
          ...(newAction as Action<SetVariableActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.SET_VARIABLE }
        });
        break;
      case 'DECLARE_VARIABLE':
        setNewAction({
          ...(newAction as Action<DeclareVariableActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.DECLARE_VARIABLE }
        });
        break;
      case 'ADD_LIST':
        setNewAction({
          ...(newAction as Action<AddListActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.ADD_LIST }
        });
        break;
      case 'REMOVE_LIST':
        setNewAction({
          ...(newAction as Action<RemoveListActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.REMOVE_LIST }
        });
        break;
      case 'CLEAR_LIST':
        setNewAction({
          ...(newAction as Action<ClearListActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.CLEAR_LIST }
        });
        break;
      case 'BEGIN_CYCLE':
        setNewAction({
          ...(newAction as Action<BeginCycleActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.BEGIN_CYCLE }
        });
        break;
      case 'END_CYCLE':
        setNewAction({
          ...(newAction as Action<any>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.END_CYCLE }
        });
        break;
      case 'SAVE_FILE':
        setNewAction({
          ...(newAction as Action<SaveFileActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.SAVE_FILE }
        });
        break;
      case 'DELETE_FILE':
        setNewAction({
          ...(newAction as Action<DeleteFileActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.DELETE_FILE }
        });
        break;
      case 'READ_FILE':
        setNewAction({
          ...(newAction as Action<ReadFileActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.READ_FILE }
        });
        break;
      case 'CUSTOM_CODE':
        setNewAction({
          ...(newAction as Action<CustomCodeActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.CUSTOM_CODE }
        });
        break;
      case 'CUSTOM_QUERY':
        setNewAction({
          ...(newAction as Action<CustomQueryActionType>),
          type: actionType as ActionType,
          data: { ...INITIAL_STATES_ACTIONS.CUSTOM_QUERY }
        });
        break;
      default: {
        console.error('Action ' + actionType + ' not implemented');
      }
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.onClose()}
      className={styles.CallFunctionCreator}
      centered
    >
      <Modal.Header closeButton>{props.dialogTitle}</Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>{t('logicBuilder.ActionType')}</Form.Label>
            <Form.Select
              id={newAction.type ?? 'default'}
              aria-label="action-type"
              onChange={actionTypeOnChange}
              value={newAction.type ?? 'default'}
              required
              style={{ cursor: 'move' }}
            >
              {!newAction.type && <option value="default">Select type</option>}
              {Object.keys(ACTION_TYPES).map((type) => {
                return (
                  <option key={type} value={type}>
                    {ACTION_TYPES[type as ActionType]}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          {newAction.type === 'CREATE_ENTITY' && (
            <RenderCreateEntityInputs
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'READ_ENTITY' && (
            <RenderReadEntityInputs
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'UPDATE_ENTITY' && (
            <RenderUpdateEntityInputs
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'DELETE_ENTITY' && (
            <RenderDeleteEntityInputs
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'RUN_FUNCTION' && (
            <RenderRunFunctionInputs
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
              functionId={props.functionId}
              functionsList={functionsList}
              editMode={props.editMode}
            />
          )}
          {newAction.type === 'SET_VARIABLE' && (
            <RenderSetVariableInputs
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'DECLARE_VARIABLE' && (
            <RenderDeclareVariable
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'ADD_LIST' && (
            <RenderAddListInputs
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'REMOVE_LIST' && (
            <RenderRemoveList
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'CLEAR_LIST' && (
            <RenderClearList
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'BEGIN_CYCLE' && (
            <RenderBeginCycle
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'END_CYCLE' && (
            <RenderEndCycle
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'SAVE_FILE' && (
            <RenderSaveFile
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'DELETE_FILE' && (
            <RenderDeleteFile
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'READ_FILE' && (
            <RenderReadFile
              newAction={newAction}
              onChange={setNewAction}
              objects={objects}
              parameters={parameters}
              variables={variables}
            />
          )}
          {newAction.type === 'CUSTOM_CODE' && (
            <RenderCustomCode newAction={newAction} onChange={setNewAction} />
          )}
          {newAction.type === 'CUSTOM_QUERY' && (
            <RenderCustomQuery newAction={newAction} onChange={setNewAction} />
          )}
          <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
            {messageError && messageError.length > 0 && (
              <>
                {messageError.map((message, index) => (
                  <p key={index} className={styles.errorMessage}>
                    {message}
                  </p>
                ))}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={
            props.editMode
              ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
              : {}
          }
        >
          <Button
            id={'deleteButton'}
            variant="danger"
            onClick={() => {
              DeleteAction();
            }}
            style={props.editMode ? {} : { display: 'none' }}
          >
            {t('Delete')}
          </Button>
          <div>
            <Button
              id={'cancelButton'}
              variant="secondary"
              onClick={() => props.onClose()}
              style={{ marginRight: '0.5rem' }}
            >
              {t('Cancel')}
            </Button>
            <Button id={'saveButton'} variant="primary" type="submit">
              {t('Save')}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
