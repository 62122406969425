import { Col, Form, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import React, { ChangeEvent } from 'react';
import { DatabaseStudioState } from 'modules/modeler/studio/store';
import { changeTableProperty } from 'modules/modeler/studio/store/actions/frames';
import { TableUUID } from 'modules/modeler/types';
import { useTranslation } from 'react-i18next';

type TableDataProps = {
  // selected table id
  tableID: TableUUID;
};

/**
 * Advanced editor tab, used to manage extra
 * settings of the table ex: (plural name)
 *
 * @component
 */
function TableAdvanced({ tableID }: TableDataProps) {
  // context table arrays
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const plurals: string = t('modeler.entity_editor.advanced.Plurals');

  function handleChangePluralName(e: ChangeEvent<HTMLInputElement>) {
    if (!tableID || !tables[tableID]) return;
    dispatch(changeTableProperty(tableID, 'plural', e.target.value));
  }

  return (
    <Row>
      <Col xs={12} style={{ height: '45.7vh', overflowY: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t('modeler.entity_editor.advanced.Properties')}</th>
              <th>{t('modeler.entity_editor.advanced.Value')}</th>
            </tr>
          </thead>
          <tbody className="align-middle">
            <tr>
              <td>{t('modeler.entity_editor.advanced.Plural')}</td>
              <td>
                <Form.Control
                  placeholder={plurals}
                  value={tables[tableID].content.data.plural}
                  onChange={handleChangePluralName}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default TableAdvanced;
