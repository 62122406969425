import React from 'react';
import styles from './styles.module.css';
import { Tag } from '../../../../types';
import Popover from '../../../../../../web_ui/workboard/sidebar/controls/components/Popover';
import { useTranslation } from 'react-i18next';
import Confirmation from 'web_ui/confirmation';

type TagItemProps = {
  // Tag to be shown.
  tag: Tag;
  // Callback function on tag double click.
  onClick?: (tagId: number) => void;
  // Callback function on tag edit.
  onEdit?: (tagId: number) => void;
  // Callback function on tag delete.
  onDelete?: (tagId: number) => void;
  // To show tags on a list
  viewOnly?: boolean;
};

export function TagItem(props: TagItemProps) {
  const { t } = useTranslation();
  const [isShowConfirmationDialog, setIsShowConfirmationDialog] = React.useState(false);

  return (
    <div
      key={props.tag.id}
      className={props.viewOnly ? styles.TagItemViewOnly : styles.TagItem}
      onDoubleClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (props.onClick && props.tag.id) {
          props.onClick(props.tag.id);
        }
      }}
      style={{ display: 'flex', justifyContent: 'space-between', width: 'fit-content' }}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
      >
        {props.tag.name}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flex: 1
        }}
      >
        {props.onEdit && (
          <div>
            <Popover placement={'top'} helpBoxProps={{ title: t('Edit') ?? 'Edit' }}>
              {' | '}
              <i
                className={`fa fa-light fa-pen me-1`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (props.onEdit && props.tag.id) {
                    props.onEdit(props.tag.id);
                  }
                }}
              />
            </Popover>
          </div>
        )}
        {props.onDelete && (
          <div>
            <Popover placement={'top'} helpBoxProps={{ title: t('Delete') ?? 'Delete' }}>
              {' | '}
              <i
                className={`fa fa-light fa-xmark`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (props.onDelete) {
                    setIsShowConfirmationDialog(true);
                  }
                }}
              />
            </Popover>
          </div>
        )}
      </div>
      <Confirmation
        show={isShowConfirmationDialog}
        message={`${t('logicBuilder.tags.deleteTag')} (${props.tag.name})`}
        onConfirmation={() => props.onDelete && props.tag.id && props.onDelete(props.tag.id)}
        onCancel={() => setIsShowConfirmationDialog(false)}
        onClose={() => setIsShowConfirmationDialog(false)}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
      />
    </div>
  );
}
