import { ReservedNames } from 'modules/designer/types';

export const isEmpty = (input: string): boolean => {
  if (!input) return true;
  return false;
};

export const isEmptyOrOnlySpaces = (input: string | undefined): boolean => {
  if (!input || !input.trim()) return true;
  return false;
};

export const containSpaces = (input: string): boolean => {
  const exp = new RegExp('\\s', 'm');
  if (input.match(exp)) {
    return true;
  }
  return false;
};

export const containSpecialCharacters = (input: string, allowed: string[]): boolean => {
  const allowedCharacters = allowed.join('');
  const specialCharactersExp = new RegExp(`[^a-zA-Z0-9${allowedCharacters}]`, 'm');
  if (input.match(specialCharactersExp)) {
    return true;
  }
  return false;
};

export const containFolderSpecialCharacters = (input: string): boolean => {
  const regex = new RegExp('[-.,<>:*?\'\\"\\/|\\\\ ]', 'm');
  if (input.match(regex)) {
    return true;
  }
  return false;
};

export const exceedsMaxLength = (input: string, maxLength: number): boolean => {
  if (input.length > maxLength) {
    return true;
  }
  return false;
};

export const startsWith = (input: string, chars: string): boolean => {
  const firstCharacter = input.at(0);
  if (firstCharacter) {
    if (chars.includes(firstCharacter)) {
      return true;
    }
  }
  return false;
};

// These inputs are used to display the correct error message.
// E.g.: if the code returned is 'EMPTY_STRING', then show message 'The custom component name input
// must contain at least one letter'.
export const INPUT_ERROR_CODES = {
  EMPTY_STRING: 'Empty string',
  CONTAIN_SPACES: 'Contain spaces',
  CONTAIN_SPECIAL_CHARACTERS: 'Contain special characters',
  EXCEEDS_MAX_LENGTH: 'Exceeds max length',
  STARTS_WITH: 'Starts with',
  CONTAIN_FOLDER_SPECIAL_CHARACTERS: 'Contain not allowed folder characters'
} as const;
export type InputErrorCode = keyof typeof INPUT_ERROR_CODES;

export type InputValidationOutput = {
  code: null | InputErrorCode;
  valid: boolean;
};

export type InputValidationInput = {
  code: InputErrorCode;
  // Used for input length validation, for example.
  extra?: Record<string, unknown>;
};

const testChain = (validations: InputValidationInput[], input: string): InputValidationOutput => {
  for (const validation of validations) {
    switch (validation.code) {
      case 'EMPTY_STRING': {
        if (isEmpty(input)) {
          return {
            code: 'EMPTY_STRING',
            valid: false
          };
        }
        break;
      }
      case 'CONTAIN_SPACES': {
        if (containSpaces(input)) {
          return {
            code: 'CONTAIN_SPACES',
            valid: false
          };
        }
        break;
      }
      case 'CONTAIN_SPECIAL_CHARACTERS': {
        const allowed: string[] =
          validation.extra && validation.extra.allowed
            ? (validation.extra.allowed as string[])
            : [];
        if (containSpecialCharacters(input, allowed)) {
          return {
            code: 'CONTAIN_SPECIAL_CHARACTERS',
            valid: false
          };
        }
        break;
      }
      case 'EXCEEDS_MAX_LENGTH': {
        const maxLength = validation.extra?.maxLength as number;
        if (exceedsMaxLength(input, maxLength)) {
          return {
            code: 'EXCEEDS_MAX_LENGTH',
            valid: false
          };
        }
        break;
      }
      case 'STARTS_WITH': {
        const chars = validation.extra?.chars as string;
        if (startsWith(input, chars)) {
          return {
            code: 'STARTS_WITH',
            valid: false
          };
        }
        break;
      }
      case 'CONTAIN_FOLDER_SPECIAL_CHARACTERS': {
        if (containFolderSpecialCharacters(input)) {
          return {
            code: 'CONTAIN_FOLDER_SPECIAL_CHARACTERS',
            valid: false
          };
        }
        break;
      }
      default:
        break;
    }
  }
  return {
    code: null,
    valid: true
  };
};

/**
 * Validates the custom component name in the create custom component dialog.
 */
export const validateCustomComponentName = (input: string): InputValidationOutput => {
  const tests: InputValidationInput[] = [
    { code: 'EMPTY_STRING' },
    {
      code: 'STARTS_WITH',
      extra: {
        chars: '0123456789'
      }
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_SPECIAL_CHARACTERS',
      extra: {
        allowed: ['_']
      }
    }
  ];
  return testChain(tests, input);
};

/**
 * Validates the object name in the object editor dialog.
 */
export const validateObjectName = (input: string, maxLength: number): InputValidationOutput => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: maxLength
      }
    },
    { code: 'EMPTY_STRING' },
    {
      code: 'STARTS_WITH',
      extra: {
        chars: '0123456789'
      }
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_SPECIAL_CHARACTERS',
      extra: {
        allowed: ['_']
      }
    }
  ];
  return testChain(tests, input);
};

// Validate table and enum names.
export const validateFrameName = (input: string): InputValidationOutput => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: 64
      }
    },
    { code: 'EMPTY_STRING' },
    {
      code: 'STARTS_WITH',
      extra: {
        chars: '0123456789'
      }
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_SPECIAL_CHARACTERS',
      extra: {
        allowed: ['_']
      }
    }
  ];
  return testChain(tests, input);
};

export const validateModuleName = (input: string, maxLength: number): InputValidationOutput => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: maxLength
      }
    },
    { code: 'EMPTY_STRING' },
    {
      code: 'STARTS_WITH',
      extra: {
        chars: '0123456789'
      }
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_SPECIAL_CHARACTERS',
      extra: {
        allowed: ['_']
      }
    }
  ];
  return testChain(tests, input);
};

export const validateVariableName = (input: string, maxLength: number) => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: maxLength
      }
    },
    { code: 'EMPTY_STRING' },
    {
      code: 'STARTS_WITH',
      extra: {
        chars: '0123456789'
      }
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_SPECIAL_CHARACTERS',
      extra: {
        allowed: ['_']
      }
    }
  ];
  return testChain(tests, input);
};

// Validate every description input.
export const validateDescriptionInputs = (
  input: string,
  maxLength: number,
  testEmptyString?: boolean
) => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: maxLength
      }
    }
  ];
  if (testEmptyString) {
    tests.push({ code: 'EMPTY_STRING' });
  }
  return testChain(tests, input);
};

// ? This can actually be removed, just modify the callback in the view manifest and
// use the function validateDescriptionInputs instead.
export const validateDescriptionInputsForDesigner = (input: string): InputValidationOutput => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: 255
      }
    }
  ];
  return testChain(tests, input);
};

export const validateViewName = (input: string): InputValidationOutput => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: 64
      }
    },
    {
      code: 'EMPTY_STRING'
    },
    {
      code: 'STARTS_WITH',
      extra: {
        chars: '0123456789'
      }
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_SPECIAL_CHARACTERS',
      extra: {
        allowed: []
      }
    }
  ];
  return testChain(tests, input);
};

export const validateClassName = (input: string): InputValidationOutput => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: 64
      }
    },
    {
      code: 'EMPTY_STRING'
    },
    {
      code: 'STARTS_WITH',
      extra: {
        chars: '0123456789'
      }
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_SPECIAL_CHARACTERS',
      extra: {
        allowed: []
      }
    }
  ];
  return testChain(tests, input);
};

export const validateFolderName = (input: string): InputValidationOutput => {
  if (Object.keys(ReservedNames).includes(input.trim().toLowerCase())) {
    return {
      code: null,
      valid: false
    };
  }
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: 64
      }
    },
    {
      code: 'EMPTY_STRING'
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_FOLDER_SPECIAL_CHARACTERS'
    }
  ];
  return testChain(tests, input);
};

export const validateControllerName = (input: string, maxLength: number): InputValidationOutput => {
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: maxLength
      }
    },
    {
      code: 'EMPTY_STRING'
    },
    {
      code: 'STARTS_WITH',
      extra: {
        chars: '0123456789'
      }
    },
    { code: 'CONTAIN_SPACES' },
    {
      code: 'CONTAIN_SPECIAL_CHARACTERS',
      extra: {
        allowed: []
      }
    }
  ];
  return testChain(tests, input);
};

/**
 * The path validation is done in three steps:
 *  1. Validate if it starts with "/" and contains only valid characters
 *     (lowercase, digits, "-" and "/")
 *  2. Validate if it has no invalid sequences ("//", "/-", "--" or "-/")
 *  3. Validate if it doesn't end with '-' or '/'
 */
export const validPathRegex = /^\/[a-z0-9A-Z{}]{0,1}[{}a-zA-Z0-9-/]*$/;
export const invalidPathCharSequence = /^.*(\/\/|\/-|--|-\/).*$/;
export const invalidPathEnding = /.+[-/]$/;
export const validatePathInput = (input: string, maxLength: number): InputValidationOutput => {
  if (isEmpty(input)) {
    return { valid: true, code: null };
  }
  const tests: InputValidationInput[] = [
    {
      code: 'EXCEEDS_MAX_LENGTH',
      extra: {
        maxLength: maxLength
      }
    }
  ];
  const test = testChain(tests, input);
  if (!test.valid) {
    return test;
  }
  const isInvalid =
    !validPathRegex.test(input) ||
    invalidPathCharSequence.test(input) ||
    invalidPathEnding.test(input);
  return { valid: !isInvalid, code: null };
};

export const validateEmail = (email: string): boolean => {
  if (
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return true;
  }
  return false;
};
