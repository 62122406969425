import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

export const DATA_TABLE_MANIFEST: ComponentManifest = {
  type: 'DATATABLE',
  name: 'DataTable',
  descriptionId: 'LayoutDataTableDescription',
  description: '',
  icon: 'fa-solid fa-table',
  group: ComponentGroups['DATA'],
  previewHeight: 170,
  previewWidth: 200,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.dataTable.tooltip'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.dataTable.visible'
    },
    {
      controlType: ControlsTypes.DATA_TABLE_SOURCE,
      controlLabel: 'Data Source',
      key: 'dataSource'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.ACTION,
      controlLabel: 'Action'
    },
    {
      controlType: ControlsTypes.ACTION,
      controlLabel: 'actionButtons',
      key: 'actionBtn'
    },
    {
      controlType: ControlsTypes.CONTEXT_MENU,
      controlLabel: 'Context Menu'
    },
    {
      controlType: ControlsTypes.SEARCH,
      controlLabel: 'Search'
    },
    {
      controlType: ControlsTypes.PAGINATION,
      controlLabel: 'Pagination'
    },
    {
      controlType: ControlsTypes.TABLE_SELECTOR,
      controlLabel: 'TableSelector'
    }
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Sortable',
    //   key: 'sortable'
    // },
  ],
  styles: [
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'color',
      key: 'variant',
      controlOptions: ['light', 'dark']
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['SELECT']
};
