import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../studio/store';
import { COMPONENT_TYPES, ComponentTypes } from '../studio/exocode_components';
import produce from 'immer';
import { GridColumnData } from '../studio/exocode_components/grid';
import { changeComponentProperty } from '../studio/store/actions/components';

export function useDeleteGridColumn() {
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const dispatch = useDispatch();

  return useCallback(
    (componentUuid: string) => {
      if (components[componentUuid] && components[componentUuid].type !== COMPONENT_TYPES.CONTAINER)
        return false;

      return Object.keys(links).some((linkKey: string) => {
        // index 0: componentUUID, index 1: section, index 2: sectionNumber
        const splitedLinkKey = linkKey.split('_');
        const parentComponentId = splitedLinkKey[0];

        const section = splitedLinkKey[2]
          ? splitedLinkKey[1] + '_' + splitedLinkKey[2]
          : splitedLinkKey[1];

        if (
          parentComponentId &&
          links[linkKey].includes(componentUuid) &&
          components[parentComponentId] &&
          (components[parentComponentId].type as ComponentTypes) === COMPONENT_TYPES.GRID &&
          section
        ) {
          const newChildrenState = produce(
            components[parentComponentId].data.columns,
            (draft: GridColumnData[]) => {
              // Find the column to be removed and get its index
              const removedColumnIndex = draft.findIndex((column) => column.title === section);

              if (removedColumnIndex === -1) return; // If the column is not found, do nothing

              // Remove the column directly from the draft
              draft.splice(removedColumnIndex, 1);

              // We need to redistribute to ensure the total width sums to 12
              const targetWidth = 12;

              // Sum of the widths of the remaining columns
              const totalRemainingWidth = draft.reduce((sum, column) => sum + column.width, 0);

              // Redistribute the widths proportionally based on the previous column sizes
              let remainingWidth = targetWidth;

              draft.forEach((column, index) => {
                // Calculate the new proportional width based on the original size of the column
                const proportionalWidth = (column.width / totalRemainingWidth) * targetWidth;

                // Round down to the nearest integer
                const newWidth = Math.floor(proportionalWidth);

                // Update the column width directly in the draft
                column.width = newWidth;

                // Adjust the remaining width
                remainingWidth -= newWidth;
              });

              // Adjust the remaining width by adding 1 to the first columns to ensure the total is exactly 12
              draft.forEach((column) => {
                if (remainingWidth > 0) {
                  column.width += 1;
                  remainingWidth--;
                }
              });
            }
          );
          dispatch(changeComponentProperty(parentComponentId, 'columns', newChildrenState));
        }

        return false;
      });
    },
    [components, links, dispatch]
  );
}
