import React from 'react';
import { COLUMN_SIZE_TO_SHORTCUTS_MAPPER } from './shortcutsMapper';
import { GridColumnSizes } from './gridManagerModal';
import GridColumnSizeCardShortcut from './sizesShortcuts';
import GridColumnsQuantityCard from './quantityShortcuts';
import { ControlProps } from '../..';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface GridColumnShortcutsProps {
  columnsQuantity: number;
  onChangeSize: (newSize: GridColumnSizes) => void;
  settings: ControlProps;
}

function GridColumnShortcuts({
  columnsQuantity,
  onChangeSize,
  settings
}: GridColumnShortcutsProps) {
  const { view_id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      {columnsQuantity < 12 && (
        <h5>{t('designer.right_side.controls.components.grid.Shortcuts')}</h5>
      )}

      {columnsQuantity < 7 && (
        <>
          <h6 className="pt-3 pb-1 text-body-secondary">
            {t('designer.right_side.controls.components.grid.SizeShortcuts')}
          </h6>
          <div className="d-flex">
            {COLUMN_SIZE_TO_SHORTCUTS_MAPPER[columnsQuantity]?.map((columnSizeOptions, index) => {
              return (
                <GridColumnSizeCardShortcut
                  key={index}
                  columnSizeOptions={columnSizeOptions}
                  onChangeSize={onChangeSize}
                />
              );
            })}
          </div>
        </>
      )}

      {view_id && columnsQuantity < 12 && (
        <>
          <h6 className="pt-3 pb-1 text-body-secondary">
            {t('designer.right_side.controls.components.grid.QuantitiesShortcuts')}
          </h6>
          <div className="d-flex">
            {Array.from({ length: 5 }, (_, index) => {
              const i = columnsQuantity + index;
              if (i >= 12) return null;
              return (
                <GridColumnsQuantityCard
                  key={'quantityCard' + i}
                  columnQuantity={i + 1}
                  currentColumnQuantity={columnsQuantity}
                  view_id={view_id}
                  settings={settings}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default GridColumnShortcuts;
