import React, { forwardRef, memo, useEffect, useState } from 'react';
import { ComponentUUID, ViewUUID } from '../../../types';
import { Container } from 'react-bootstrap';
import { InterfaceStudioState } from '../../store';
import { ListData } from './types/list_data';
import { ListStyles } from './types/list_styles';
import { useSelector } from 'react-redux';
import ComponentsRenderer from '../components_renderer';
import NoDatasourceWarning from './components/no_datasource_warning';
import PaginationMock from './components/pagination/pagination_mock';
import FilterMock from './components/search_and_sort/filter_mock';
import SortMock from './components/search_and_sort/sort_mock';
import RepeaterMock from './components/repeater/repeater_mock';

export type ListProps = {
  viewUUID: ViewUUID;
  uuid: ComponentUUID;
  data: ListData;
  styles: ListStyles;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function ListComponent(props: ListProps, ref: React.Ref<any>) {
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const [hasDataSource, setHasDataSource] = useState(false);

  useEffect(() => {
    setHasDataSource(!!props?.data?.variable?.uuid);
  }, [props]);

  const styles = {
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  const componentRenderer = React.useCallback(() => {
    const hasChildren = links[props.uuid]?.length > 0;
    if (!hasChildren) return null;
    return (
      <ComponentsRenderer
        viewUUID={props.viewUUID}
        parentUUID={props.uuid}
        custom_uuid={props.custom_uuid}
        new_custom_uuid={props.new_custom_uuid}
      />
    );
  }, [props, links]);

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <Container
      ref={ref}
      className={`d-flex flex-column align-items-center justify-content-center gap-3 p-3 h-auto ${classes}`}
      fluid={'fluid'}
      style={{ ...styles }}
    >
      {!hasDataSource ? (
        <NoDatasourceWarning />
      ) : (
        <>
          <div className={'w-100 ps-3 pe-3'}>
            <FilterMock searchable={!!props?.data?.searchable} />
            <SortMock sortable={!!props?.data?.sortable} />
          </div>
          <RepeaterMock
            gap={props?.data?.gap ?? '1rem'}
            numberOfColumns={props?.data?.numberOfColumns ?? 1}
            type={props.data.type}
            actions={props?.data?.actions}
            selectable={props?.data?.selectable}
            componentRenderer={componentRenderer}
          />
          <PaginationMock pageable={!!props?.data?.pageable} />
        </>
      )}
    </Container>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(ListComponent));
