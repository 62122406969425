import React from 'react';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import Dropdown from '../dropdown';
import useCheckSameType from '../../inputs/endpoint_picker/hooks/useCheckSameType';
import { DataType } from 'modules/logic_builder/types';
import { VariableTypes } from 'modules/designer/types';

type ObjectNodePickerProps = {
  objectUuid: string;
  filterByList: boolean;
  value: string;
  handleChange: (text: string) => void;
  paramType?: DataType;
  isFromSideBar?: boolean;
};

function ObjectNodePicker({
  objectUuid,
  filterByList,
  value,
  handleChange,
  paramType,
  isFromSideBar
}: ObjectNodePickerProps) {
  const state = useSelector((state: FunctionEditorState) => state);
  const objects = state.objects;
  const objectNodes = state.objects_items;

  function convertToVariableType(type: DataType): VariableTypes {
    switch (type) {
      case 'CHAR':
      case 'VARCHAR':
      case 'STRING':
      case 'DATE':
      case 'TIME':
      case 'TIMESTAMP':
        return 'STRING';
      case 'SMALLINT':
      case 'INTEGER':
      case 'BIGINT':
      case 'DECIMAL':
      case 'FLOAT':
      case 'DOUBLE':
      case 'AUTOINCREMENT':
        return 'NUMBER';
      case 'BOOLEAN':
        return 'BOOLEAN';
      case 'OBJECT':
        return 'OBJECT';
      default:
        return 'ANY';
    }
  }

  const validateType = useCheckSameType();

  const filterObjectItems = () => {
    let items = objects[objectUuid]?.fields?.map((fieldId) => objectNodes[fieldId]);

    if (!items) return [];
    if (filterByList) items = items?.filter((i) => i.list);
    items = items?.filter((i) => {
      if (paramType) {
        return validateType(paramType, convertToVariableType(i.dataType as DataType));
      }
      return true;
    });

    return items;
  };

  return (
    <Dropdown
      items={filterObjectItems()}
      placeholder={'Whole object'}
      label={''}
      value={objectNodes[value]}
      handleChange={handleChange}
      isFromSideBar={isFromSideBar}
    />
  );
}

export default ObjectNodePicker;
