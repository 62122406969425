import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles.module.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { DATA_TYPES, DataType, FunctionVariable, ObjectSimple } from 'modules/logic_builder/types';
import { FunctionService, ObjectsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import Confirmation from 'web_ui/confirmation';
import { EnumFrame } from 'modules/modeler/types';
import { variableNameRegex } from '../../../../../utils/regex';
import { useTranslation } from 'react-i18next';

type VariableEditorDialogProps = {
  functionId: string;
  fetchVariables: () => void;
  variable?: FunctionVariable;
  show: boolean;
  onClose: () => void;
  enumsList: EnumFrame[];
};

export function VariableEditorDialog(props: VariableEditorDialogProps) {
  const { module_id } = useParams();
  const [variable, setVariable] = useState<FunctionVariable>();
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const [isNative, setIsNative] = useState(false);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

  const fetchObjects = useCallback(async () => {
    if (!module_id) return;
    await ObjectsService.getObjectsByModule(module_id).then((fetchedObjects) => {
      setObjects(fetchedObjects);
    });
  }, [module_id]);

  useEffect(() => {
    if (!props.show) return;

    fetchObjects();
    setErrorMessage(false);
  }, [fetchObjects, props.show]);

  useEffect(() => {
    if (!props.show || !props.variable) return;

    setVariable(props.variable);
    setIsNative(props.variable.native ?? false);
  }, [props.show, props.variable]);

  async function onDelete() {
    if (!variable || !variable.uuid) return;

    await FunctionService.deleteFunctionVariable(props.functionId, variable.uuid).then(() => {
      setShowConfirmationDialog(false);
      props.fetchVariables();
      props.onClose();
    });
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (!variable || !module_id) return;

    try {
      await FunctionService.updateFunctionVariable(props.functionId, variable).then(() => {
        props.fetchVariables();
        props.onClose();
      });
    } catch (err) {
      setErrorMessage(true);
    }
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onClose} className={styles.VariableEditor} centered>
        <Modal.Header closeButton>
          <Modal.Title>Variable Editor</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {errorMessage && (
              <span className={styles.errorMsg}>
                {`*${t('logicBuilder.errorCreatingEditingVariable')}`}
              </span>
            )}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                required
                disabled={isNative}
                isInvalid={isInvalidName}
                value={variable?.name ?? ''}
                onChange={(e) => {
                  setIsInvalidName(!variableNameRegex.test(e.target.value));
                  setVariable({ ...variable, name: e.target.value } as FunctionVariable);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>Type</Form.Label>
              <Form.Select
                required
                disabled={isNative}
                value={variable?.type ?? 'default'}
                onChange={(e) =>
                  setVariable({
                    ...variable,
                    type: e.target.value,
                    objectUuid: '',
                    enumUuid: ''
                  } as FunctionVariable)
                }
              >
                {!variable?.type && <option value="default">Select type</option>}
                {Object.keys(DATA_TYPES).map((type) => {
                  return (
                    <option key={type} value={type}>
                      {DATA_TYPES[type as DataType]}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className={`mb-3`} controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                disabled={isNative}
                placeholder="Description..."
                rows={2}
                maxLength={64}
                value={variable?.description ?? ''}
                onChange={(e) =>
                  setVariable({ ...variable, description: e.target.value } as FunctionVariable)
                }
              />
            </Form.Group>
            <Form.Group controlId="formSpec">
              <Form.Check
                inline
                label="Is Array"
                type="checkbox"
                value="IsArray"
                name="IsArray"
                disabled={isNative || variable?.type === 'PAGE'}
                checked={(variable?.list || variable?.type === 'PAGE') ?? false}
                onChange={(e) =>
                  setVariable({ ...variable, list: e.target.checked } as FunctionVariable)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>Objects</Form.Label>
              <Form.Select
                required
                disabled={(variable?.type !== 'OBJECT' && variable?.type !== 'PAGE') || isNative}
                onChange={(e) =>
                  setVariable({
                    ...variable,
                    objectUuid: e.target.value
                  } as FunctionVariable)
                }
                value={variable?.objectUuid ?? 'default'}
              >
                {!variable?.objectUuid && <option value={'default'}>Select object</option>}
                {objects.map((object) => {
                  return (
                    <option key={object.uuid} value={object.uuid}>
                      {object.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formSelectEnum">
              <Form.Label>Enums</Form.Label>
              <Form.Select
                required
                disabled={variable?.type !== 'ENUM'}
                onChange={(e) =>
                  setVariable({
                    ...variable,
                    enumUuid: e.target.value
                  } as FunctionVariable)
                }
                value={variable?.enumUuid ?? 'default'}
              >
                {!variable?.enumUuid && <option value={'default'}>Select enum</option>}
                {props.enumsList.map((enumItem) => {
                  return (
                    <option key={enumItem.uuid} value={enumItem.uuid}>
                      {enumItem.content.data.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="deleteButton"
              variant="danger"
              onClick={() => setShowConfirmationDialog(true)}
              disabled={isNative}
            >
              Delete
            </Button>
            <Button id="cancelButton" variant="secondary" onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              id="saveButton"
              variant="primary"
              type="submit"
              disabled={isInvalidName || !variable?.name || !variable.type || isNative}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message="Delete variable?"
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
