import React from 'react';
import Icon from '../../../../../icon';

function ParameterDragIcon() {
  return (
    <button
      className="btn"
      type="button"
      aria-expanded="false"
      style={{
        border: 'var(--bs-border-width) solid var(--bs-border-color)',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }}
    >
      <Icon iconName={'fa-solid fa-ellipsis-vertical'} />
    </button>
  );
}

export default ParameterDragIcon;
