import React from 'react';
import { ControlProps } from '../index';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import VariableControl from '../VariableControl';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { InterfaceStudioState, VariablesState } from 'modules/designer/studio/store';
import ErrorIcon from '../components/ErrorIcon';
import { FRONTEND_VARIABLE_TYPES, VariableTypes } from 'modules/designer/types';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';

function PaginationControl(props: ControlProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-3 pb-2 border-bottom text-body-secondary">
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <div>
            <label className="position-relative mb-1 text-body">{props.label}</label>
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.PaginationTitle') ||
                  'designer.right_side.controls.PaginationTitle',
                description:
                  t('designer.right_side.controls.PaginationDescription') ||
                  'designer.right_side.controls.PaginationDescription',
                note: [
                  t('designer.right_side.controls.PaginationNote01'),
                  t('designer.right_side.controls.PaginationNote02'),
                  t('designer.right_side.controls.PaginationNote03')
                ]
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          </div>

          <Form.Check
            type="switch"
            id="custom-switch"
            checked={props.value?.pageable}
            value={props.value?.pageable}
            onChange={(e) => {
              props.onChange && props.onChange(e.target.checked, 'pageable');
            }}
          />
        </div>

        {props.value?.pageable && (
          <div>
            <label>
              <MissingMessage
                type={MissingMessageType.VARIABLE}
                uuid={props.value?.currentPage?.uuid}
                requiredTypes={[FRONTEND_VARIABLE_TYPES.NUMBER as VariableTypes]}
              />
              {t('designer.right_side.CurrentPage')}
            </label>
            <VariableControl
              id={'currentPage'}
              key={'currentPage'}
              label={''}
              value={props.value?.currentPage}
              onChange={props.onChange}
              options={[FRONTEND_VARIABLE_TYPES.NUMBER]}
            />

            <label>
              <MissingMessage
                type={MissingMessageType.VARIABLE}
                uuid={props.value?.totalItems?.uuid}
                requiredTypes={[FRONTEND_VARIABLE_TYPES.NUMBER as VariableTypes]}
              />
              {t('designer.right_side.TotalItems')}
            </label>
            <VariableControl
              id={'totalItems'}
              key={'totalItems'}
              label={''}
              value={props.value?.totalItems}
              onChange={props.onChange}
              options={[FRONTEND_VARIABLE_TYPES.NUMBER]}
            />
            {props.value?.pageable && props.value?.pageSize && (
              <div className="mb-3">
                <label className="form-label">Itens por página</label>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  value={props.value?.pageItems}
                  onChange={(e) => props.onChange && props.onChange(e.target.value, 'pageItems')}
                />
              </div>
            )}

            <label>
              <MissingMessage
                type={MissingMessageType.VARIABLE}
                uuid={props.value?.totalPages?.uuid}
                requiredTypes={[FRONTEND_VARIABLE_TYPES.NUMBER as VariableTypes]}
              />
              {t('designer.right_side.TotalPages')}
            </label>
            <VariableControl
              id={'totalPages'}
              key={'totalPages'}
              label={''}
              value={props.value?.totalPages}
              onChange={props.onChange}
              options={[FRONTEND_VARIABLE_TYPES.NUMBER]}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PaginationControl;
