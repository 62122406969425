import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';
export const COLUMN_MANIFEST: ComponentManifest = {
  type: 'COLUMN',
  name: 'Column',
  descriptionId: 'LayoutColumnDescription',
  description:
    'Use our powerful mobile-first flexbox grid to build layouts of all shapes and sizes thanks to a twelve column system.',
  icon: 'fa-solid fa-border-none',
  group: ComponentGroups['LAYOUT'],
  validParents: ['ROW'],
  allowDrop: false,
  previewHeight: 100,
  previewWidth: 600,
  properties: [
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.column.visible'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Width',
      key: 'width',
      controlOptions: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      tooltip: 'designer.right_side.controls.components.column.select'
    },
    {
      controlType: ControlsTypes.POSITION,
      controlLabel: 'Position'
    },

    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.SELECT_PICS,
      controlLabel: 'Background Image',
      key: 'url',
      tooltip: 'designer.right_side.controls.components.column.selectPics'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Background',
      tooltip: 'designer.right_side.controls.components.column.themePicker'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('BorderColor'),
      key: 'borderColor',
      tooltip: 'designer.right_side.controls.components.button.borderColor'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('TextColor'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.column.colorPicker'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.column.number'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
