import * as React from 'react';

function TwoColumnsIcon(props: any) {
  return (
    <svg
      width="99"
      height="100"
      viewBox="0 0 99 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.375 26.8388C14.6136 26.8388 12.375 29.0774 12.375 31.8388V66.8388C12.375 69.6003 14.6136 71.8388 17.375 71.8388H43C45.7614 71.8388 48 69.6003 48 66.8388V31.8388C48 29.0774 45.7614 26.8388 43 26.8388H17.375Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 71.8388C14.6136 71.8388 12.375 69.6003 12.375 66.8388V31.8388C12.375 29.0774 14.6136 26.8388 17.375 26.8388H43C45.7614 26.8388 48 29.0774 48 31.8388V66.8388C48 69.6003 45.7614 71.8388 43 71.8388H17.375Z"
        fill="#79828A"
      />
      <path
        d="M17.375 26.8389C14.6136 26.8389 12.375 29.0774 12.375 31.8389V66.8389C12.375 69.6003 14.6136 71.8389 17.375 71.8389H43C45.7614 71.8389 48 69.6003 48 66.8389V31.8389C48 29.0774 45.7614 26.8389 43 26.8389H17.375Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 71.8389C14.6136 71.8389 12.375 69.6003 12.375 66.8389V31.8389C12.375 29.0774 14.6136 26.8389 17.375 26.8389H43C45.7614 26.8389 48 29.0774 48 31.8389V66.8389C48 69.6003 45.7614 71.8389 43 71.8389H17.375Z"
        fill="#79828A"
      />
      <path
        d="M56.5 26.8388C53.7386 26.8388 52 29.0774 52 31.8388V66.8388C52 69.6003 53.7386 71.8388 56.5 71.8388L82 71.8389C84.7614 71.8389 87 69.6003 87 66.8389V31.8389C87 29.0774 84.7614 26.8389 82 26.8389L56.5 26.8388Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.5 71.8388C53.7386 71.8388 52 69.6003 52 66.8388V31.8388C52 29.0774 53.7386 26.8388 56.5 26.8388L82 26.8389C84.7614 26.8389 87 29.0774 87 31.8389V66.8389C87 69.6003 84.7614 71.8389 82 71.8389L56.5 71.8388Z"
        fill="#79828A"
      />
    </svg>
  );
}

export default TwoColumnsIcon;
