import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { updateParam } from '../../../../store/events/parameters/update_param';
import useValidateParamName from '../../../../hooks/useValidateParamName';

type ParameterNameEditorProps = {
  uuid: string;
  onFocus?: () => void;
};

function ParameterNameEditor({ uuid, onFocus }: ParameterNameEditorProps) {
  const param = useSelector((state: FunctionEditorState) => state?.parameters[uuid]);
  const [validName, setValidName] = useState('');
  const validateParamName = useValidateParamName();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!param || !param.name) return;
    setValidName(param.name);
  }, [param]);

  const handleChangeName = (name: string) => {
    if (validateParamName(name)) {
      setValidName(name);
    } else {
      validateParamName(param.name) && setValidName(param.name);
    }
    dispatch(updateParam(uuid, name, param.desc));
  };

  const handleChangeNameOnBlur = () => {
    if (param.name !== validName) dispatch(updateParam(uuid, validName, param.desc));
  };

  return (
    <Form.Control
      type="text"
      className="form-control"
      aria-label="Text input with dropdown button"
      value={param.name}
      onChange={(e) => handleChangeName(e.target.value)}
      onBlur={() => handleChangeNameOnBlur()}
      isInvalid={param.name !== validName}
      disabled={param?.native}
      style={{
        flexGrow: '4',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: '265px'
      }}
      onFocus={onFocus}
    />
  );
}

export default ParameterNameEditor;
