import React from 'react';
import { t } from 'i18next';
import { UserProfile } from 'modules/auth/types/auth_types';
import { Row, Col, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';

type Props = {
  data: UserProfile;
  index: number;
  isFromTeamMember: boolean;
  deleteTeamMember: (userId: number) => void;
  addingTeamMember: (userId: number) => void;
};

const Member = ({ data, index, isFromTeamMember, deleteTeamMember, addingTeamMember }: Props) => {
  return (
    <Row
      id={
        data.name
          ? data.name.substring(0, 5).toUpperCase()
          : data.username.substring(0, 5).toUpperCase()
      }
      className={`d-flex  p-2 ${styles.container}`}
      key={data.id}
    >
      {/* Avatar */}
      <Col xs={'auto'} className={`d-flex align-items-center`}>
        <div className={`${isFromTeamMember ? styles.avatarTeam : styles.avatar} me-2}`}>
          {data.name
            ? data.name.substring(0, 1).toUpperCase()
            : data.username.substring(0, 1).toUpperCase()}
        </div>
      </Col>
      {/* Username */}
      <Col className={`${styles.infoCol} d-flex ps-0`}>
        <Col
          style={{
            fontWeight: '500',
            overflowWrap: 'break-word',
            paddingLeft: '0.75rem'
          }}
        >
          {data.name ? data.name : data.username}
        </Col>

        <Col className={'d-flex'}>
          {/* Operations in Members */}
          <Col className={`${styles.iconsMember}`}>
            {!isFromTeamMember && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover>
                    <div className="p-2">{t('Add')}</div>
                  </Popover>
                }
                delay={{ show: 50, hide: 0 }}
              >
                <Button
                  id={`deleteButton-${index}`}
                  className={styles.iconButton}
                  onClick={() => {
                    addingTeamMember(data.id);
                  }}
                >
                  <Icon iconName="plus" brands="regular" padding="0.5rem" />
                </Button>
              </OverlayTrigger>
            )}
            {isFromTeamMember && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover>
                    <div className="p-2">{t('Delete')}</div>
                  </Popover>
                }
                delay={{ show: 50, hide: 0 }}
              >
                <Button
                  id={`deleteButton-${index}`}
                  className={styles.iconButton}
                  onClick={() => {
                    deleteTeamMember(data.id);
                  }}
                >
                  <Icon iconName="minus" padding="0.5rem" />
                </Button>
              </OverlayTrigger>
            )}
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default Member;
