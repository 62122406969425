import { SchedulerJob } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class ScheduledsRepo {
  /**
   * Create Scheduled.
   */
  async createScheduled(moduleId: string, scheduled: SchedulerJob): Promise<SchedulerJob> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(scheduled)
    };

    const url = `${API_URL}/modules/${moduleId}/scheduleds`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<SchedulerJob>;
      throw new Error('Something went wrong while trying to create Scheduled.');
    });
  }

  /**
   *  Fetch Scheduleds by moduleId.
   */
  async getScheduleds(moduleId: string): Promise<SchedulerJob[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/scheduleds`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<SchedulerJob[]>;
      throw new Error('Something went wrong while trying to fetch Scheduleds.');
    });
  }

  /**
   * Get Scheduled.
   */
  async getScheduled(moduleId: string, scheduledId: string): Promise<SchedulerJob> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };
    const url = `${API_URL}/modules/${moduleId}/scheduleds/${scheduledId}`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<SchedulerJob>;
      throw new Error('Something went wrong while trying to fetch Scheduled.');
    });
  }

  /**
   * Update Scheduled.
   */
  async updateScheduled(
    scheduled: SchedulerJob,
    moduleId: string,
    scheduleId: string
  ): Promise<SchedulerJob> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(scheduled)
    };

    const url = `${API_URL}/modules/${moduleId}/scheduleds/${scheduleId}`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<SchedulerJob>;
      throw new Error('Something went wrong while trying to update Scheduled.');
    });
  }

  /**
   * Delete Scheduled.
   */
  async deleteScheduled(scheduledId: string, moduleId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/modules/${moduleId}/scheduleds/${scheduledId}`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return;
      throw new Error('Something went wrong while trying to delete Scheduled.');
    });
  }

  /**
   * Change folder.
   */
  async changeFolder(schedulerJobId: string, folderId: string) {
    const schema = {
      schedulerJobId: schedulerJobId,
      folder_id: folderId
    };

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(schema)
    };

    const url = `${API_URL}/schedulers/${schema.schedulerJobId}/changefolder`;
    const response = await fetch(url, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Something went wrong while trying to update folder.');
    }
  }
}
