import React from 'react';
import { SideMenuData, SideMenuStyle } from '../..';
import { ComponentUUID, fixPostion } from 'modules/designer/types';
import * as Icons from 'react-icons/fa';
import { IconText } from 'web_ui/workboard/sidebar/controls/IconTextControl';
import RecursiveLink from './recursiveLink';
import styles from '../../styles.module.css';

interface sideBySideItemProps {
  uuid: ComponentUUID;
  data: SideMenuData;
  styles: SideMenuStyle;
  fixed: fixPostion;
}

function SideBySideLinks(props: sideBySideItemProps) {
  const liStyle = {
    backgroundColor: `${props.styles.staticColor}`,
    color: `${props.styles.color}`,
    borderColor: 'rgb(174, 173, 173)'
  };

  const iconStyle = {
    marginRight: `${props.styles && props.styles.iconMargin ? props.styles.iconMargin : '0'}px`,
    color: props.styles.iconStaticColor
  };

  const styleClasses = {
    iconThemeColor: props.styles.iconThemeColor
  };

  return (
    <ul className="list-group list-group-flush dropend">
      {/* first layer */}
      {props.data.links.children[props.uuid]
        .filter((key) => props.data.links.items[key].fixed === props.fixed)
        .map((key: string) => {
          const iconName = props.data.links.items[key].icon;
          const IconDiv = iconName ? Icons[iconName] : null;
          return (
            <li
              key={key}
              className={`dropend list-group-item list-group-item-action pe-0 list-group-item-${props.styles.themeColor} border-0`}
              style={liStyle}
            >
              <div
                className={`w-100 dropend pe-3`}
                style={{ width: '100%', display: 'block' }}
                role="button"
                id="dropdownMenuLink"
                aria-expanded="false"
                data-bs-toggle={props.data.links.children[key] ? 'dropdown' : null}
              >
                <span
                  className={`d-flex ${
                    props.data.linkText === IconText.ICON ? 'justify-content-center' : ''
                  } align-items-center ${
                    props.data.buttonOrientation === 'Vertical' ? 'flex-column' : ''
                  }`}
                  data-bs-toggle="popover"
                  data-bs-trigger="hover focus"
                  data-bs-content="Disabled popover"
                >
                  {(props.data.linkText === IconText.BOTH ||
                    props.data.linkText === IconText.ICON) && (
                    <div
                      className={`d-inline text-${props.styles.iconThemeColor} ${
                        props.data.buttonOrientation === 'Vertical' ? 'mb-2' : ''
                      }`}
                      style={iconStyle}
                    >
                      {IconDiv && <IconDiv size={props.styles.iconSize} />}
                    </div>
                  )}
                  {(props.data.linkText === IconText.BOTH ||
                    props.data.linkText === IconText.TEXT) && (
                    <span
                      className={`d-flex ${
                        props.data.links.items[key].title &&
                        props.data.links.items[key].title!.length > 0 &&
                        props.data.links.items[key].icon &&
                        props.data.buttonOrientation !== 'Vertical'
                          ? 'ms-2'
                          : ''
                      }`}
                      style={{ color: props.styles.color }}
                    >
                      {props.data.links.items[key].title}
                    </span>
                  )}
                </span>

                {/* second layer */}
                {props.data.links.children[key] && (
                  <ul
                    className={`${styles.dropdownMenu} ${styles.dropdownSubmenu} dropdown-menu dropdown-submenu dropend p-0`}
                  >
                    <RecursiveLink
                      linkUUID={key}
                      links={props.data.links}
                      styles={props.styles}
                      iconStyle={iconStyle}
                      styleClasses={styleClasses}
                      linkText={props.data.linkText}
                      liStyle={liStyle}
                      menuType={props.data.menuType}
                    />
                  </ul>
                )}
              </div>
            </li>
          );
        })}
    </ul>
  );
}

export default SideBySideLinks;
