import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { AuthService } from 'modules/auth/services';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

/**
 * Password reset process
 *
 * @component
 */
function PasswordResetForm() {
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [completeReset, setCompleteReset] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const enter_email = t('password_reset.enter_email');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await AuthService.resetPassword(email);
      setIsLoading(false);
      setCompleteReset(true);
    } catch (error) {
      setIsLoading(false);
      setCompleteReset(true);
    }
  };

  const handleCloseDialog = () => {
    navigate('/');
    setCompleteReset(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Container className={`text-white ${styles.FindAccountInText}`}>
          {t('password_reset.FindAccount')}
        </Container>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label className={`text-secondary ${styles.LabelInfo}`}>
            {t('password_reset.EnterEmail')}
          </Form.Label>
          <Form.Control
            className={`bg-dark border-secondary text-white-50 ${styles.placeholder}`}
            onChange={(e) => setEmail(e.currentTarget.value)}
            type="email"
            placeholder={enter_email}
            value={email}
          />
        </Form.Group>
        <Container className={styles.ButtonContainer}>
          <Button
            id={'backButton'}
            variant="primary"
            type="button"
            onClick={() => navigate('/login')}
            className={styles.Buttons}
            disabled={isLoading}
          >
            {t('navigation_options.Back')}
          </Button>
          <Button
            id={'nextButton'}
            variant="primary"
            type="submit"
            className={styles.Buttons}
            disabled={isLoading}
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`}
            />
            {t('navigation_options.Next')}
          </Button>
        </Container>
      </Form>
      <Modal show={completeReset} onHide={handleCloseDialog}>
        <Modal.Header
          closeButton
          data-bs-theme="dark"
          className="bg-dark text-white border border border-secondary "
        >
          <Modal.Title id="headerMessage">{t('password_reset.OneMoreStep')}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          id={'bodyMessage'}
          className="bg-dark text-white border border-secondary rounded-bottom"
        >
          {t('password_reset.CheckEmail')}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PasswordResetForm;
