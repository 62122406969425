import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { FrontendProperty } from '../../../types';
import { VariableTypes } from '../../../types';
import { FRONTEND_VARIABLE_TYPES } from '../../../types';
import { useParams } from 'react-router-dom';
import Icon from 'web_ui/icon';
import { InterfaceStudioState } from '../../store';
import { addProperty, updateProperty } from '../../store/actions/properties';
import { useTranslation } from 'react-i18next';

type ManagePropertyDialogProps = {
  viewId: string;
  show: boolean;
  onClose: () => void;
  property?: FrontendProperty;
  // Create property mode if false.
  // Edit property mode if true.
  editMode: boolean;
};

export function ManagePropertyDialog({
  viewId,
  show,
  onClose,
  property,
  editMode
}: ManagePropertyDialogProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { module_id } = useParams();
  const [name, setName] = useState('');
  const [type, setType] = useState<VariableTypes>('STRING');
  const [isNative, setIsNative] = useState(false);
  const [isArray, setIsArray] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [selectedObject, setSelectedObject] = useState<string>();
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const editorMode = useSelector((state: InterfaceStudioState) => state.studio.editor);

  useEffect(() => {
    if (!show) return;
    if (!module_id) return;

    if (!editMode) {
      setName('');
      setType('STRING');
      setIsNative(false);
      setIsArray(false);
      setIsRequired(false);
      setSelectedObject(undefined);
    } else if (editMode && property != null) {
      setName(property.name);
      setType(property.type as VariableTypes);
      setIsNative(property.native);
      setIsArray(property.list);
      setIsRequired(property.required);
      setSelectedObject(property.object);
    }
  }, [editMode, module_id, property, show]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    let propertyId;
    if (editMode && property != null) {
      propertyId = property.uuid;
    } else {
      propertyId = uuidv4();
    }

    const propertyInfo: FrontendProperty = {
      uuid: propertyId,
      view: editorMode !== 'CUSTOM_COMPONENT' ? viewId : '',
      name: name,
      type: type,
      native: isNative,
      list: isArray,
      required: isRequired,
      customComponent: editorMode === 'CUSTOM_COMPONENT' ? viewId : ''
    };

    if (type === 'OBJECT') {
      propertyInfo.object = selectedObject;
    } else {
      propertyInfo.object = '';
    }

    if (editMode) {
      dispatch(updateProperty(propertyInfo));
    } else {
      dispatch(addProperty(propertyInfo));
    }
    onClose();
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value as VariableTypes);
  };

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {/* Add translations. */}
        <Modal.Title>{editMode ? 'Update' : 'Create'} Property</Modal.Title>
        <div className="ms-2" title="Native variable">
          <Icon iconName="laptop-code" />
        </div>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>{t('Name')}</Form.Label>
            <Form.Control type="text" value={name} onChange={handleChangeName} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Select value={type} onChange={handleChangeType} disabled={isNative}>
              {Object.keys(FRONTEND_VARIABLE_TYPES).map((propertyType) => (
                <option key={propertyType} value={propertyType}>
                  {FRONTEND_VARIABLE_TYPES[propertyType as VariableTypes]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {type === 'OBJECT' && (
            <Form.Group className="mb-3">
              <Form.Label>Select an object</Form.Label>
              <Form.Select
                value={selectedObject ?? ''}
                onChange={(e) => setSelectedObject(e.target.value)}
                disabled={isNative}
              >
                {selectedObject == null && <option value="">---</option>}
                {Object.values(objects).map((object) => (
                  <option key={object.uuid} value={object.uuid}>
                    {object.moduleUuid !== module_id && `${object.moduleName} - `}
                    {object.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Check
              inline
              label="Array" // TODO: add translations.
              name="ArrayProperty"
              type="checkbox"
              checked={isArray}
              onChange={() => setIsArray(!isArray)}
              disabled={isNative}
            />
            <Form.Check
              inline
              label="Required" // TODO: add translations.
              name="RequiredProperty"
              type="checkbox"
              checked={isRequired}
              onChange={() => setIsRequired(!isRequired)}
              disabled={isNative}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button id="saveButton" type="submit">
            {/* TODO: add translations. */}
            {editMode ? 'Save' : 'Create'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
