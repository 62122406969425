import React from 'react';
import TypePicker, { TYPE_PICKER_TYPES } from '../argument_picker/type_picker';
import ValuePicker from '../argument_picker/value_picker';
import ObjectNodePicker from '../../components/object_node_picker';
import useCheckValueIsObject from '../argument_picker/useCheckValueIsObject';
import { Argument } from '../../../store/types/manifestsAndInputs';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';

type ParameterEditorProps = {
  paramId: string;
  param: Argument;
  label: string;
  actionUuid: string;
  handleChange: (paramId: string, value: Argument) => void;
  options?: any;
  isFromSideBar?: boolean;
};

function ParameterEditor({
  paramId,
  param,
  label,
  actionUuid,
  handleChange,
  options,
  isFromSideBar
}: ParameterEditorProps) {
  const parameters = useSelector((state: FunctionEditorState) => state.parameters);
  const { isObject, objectUuid } = useCheckValueIsObject(param.type, param.value);

  const handleChangeType = (t: TYPE_PICKER_TYPES) => {
    handleChange(paramId, { type: t, value: '', objectNode: '' });
  };

  const handleChangeValue = (v: string) => {
    handleChange(paramId, { ...param, value: v, objectNode: '' });
  };

  const handleChangeObjectNode = (o: string) => {
    handleChange(paramId, { ...param, objectNode: o });
  };

  const filterByDataType = (state: FunctionEditorState, actionUuid: string, dataType: string) => {
    if (!dataType) return false;
    if (param.type === 'PAGE_PARAM') {
      return (
        parameters[paramId]?.type.toUpperCase() !== 'OBJECT' &&
        parameters[paramId]?.type.toUpperCase() !== 'VOID'
      );
    }
    return (
      dataType.toUpperCase() === parameters[paramId]?.type.toUpperCase() ||
      dataType.toUpperCase() === 'OBJECT'
    );
  };

  return (
    <div className={'ps-3 d-flex align-items-end flex-wrap'}>
      <p className={'text-secondary-emphasis mb-0 me-1'}>{parameters[paramId]?.name} = </p>
      <TypePicker
        placeholder={'Select argument type'}
        label={label}
        value={param.type}
        handleChange={handleChangeType}
        argumentTypes={
          parameters[paramId]?.list
            ? [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM]
            : options.argumentTypes
            ? options.argumentTypes
            : []
        }
        isFromSideBar={isFromSideBar}
      />
      <ValuePicker
        type={param.type}
        label={label}
        actionUuid={actionUuid}
        filterByList={parameters[paramId] && parameters[paramId].list}
        filterByDataType={filterByDataType}
        filterByGlobalVars={options?.onlyGlobals}
        value={param.value}
        handleChange={handleChangeValue}
        isFromSideBar={isFromSideBar}
      />
      {isObject && (
        <ObjectNodePicker
          objectUuid={objectUuid}
          filterByList={parameters[paramId]?.list}
          value={param.objectNode ?? ''}
          handleChange={handleChangeObjectNode}
          isFromSideBar={isFromSideBar}
        />
      )}
    </div>
  );
}

export default ParameterEditor;
