import React, { DragEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'modules/logic_builder/styles.module.css';
import LogicBuilderContext from 'modules/logic_builder/store';
import SearchBar from 'web_ui/search_bar';
import { useParams } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Icon from 'web_ui/icon';
import { Folder, FolderType } from 'modules/designer/types';
import { ObjectDialog } from 'modules/logic_builder/components/dialogs/object_dialog';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { ObjectsService } from 'modules/logic_builder/services';
import { LocalStorageManager } from 'utils/localStorage/localstorage';
import CreateFolderDialog from 'modules/designer/studio/toolbars/views_toolbar/create_folder_modal';
import { ContextMenuItem, FolderItem, RenderFolders } from 'web_ui/folders';
import {
  checkFolderNameAlreadyExists,
  deletefolder,
  lb_dragleave,
  lb_dragover,
  lb_dragstart,
  lb_drop,
  lb_saveitem,
  savefolder,
  updateFoldersOnCreate,
  updateFoldersOnDelete,
  updateFoldersOnMoveFolder,
  updateFoldersOnRename
} from 'web_ui/folders/callbacks';
import { ObjectSimple } from 'modules/logic_builder/types';
import { validateFolderName, validateObjectName } from 'utils/inputValidation';
import {
  DraggableResource,
  TargetType
} from 'web_ui/folders/draggable_resource/draggable_resource';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';

function ObjectsToolbar() {
  const { t } = useTranslation();
  const { module_id, app_id } = useParams();
  const [filterValue, setFilterValue] = useState('');
  const [tables, setTables] = useState<Table[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [enums, setEnums] = useState<EnumFrame[]>([]);
  const getNestedItems = (): Record<string, boolean> => {
    if (!module_id || !app_id) return {};
    const getItems = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (getItems[module_id] && getItems[module_id].logicBuilder.folderState.objects) {
      return getItems[module_id].logicBuilder.folderState.objects;
    }
    return {};
  };
  const [showNested, setShowNested] = useState<Record<string, boolean>>(() => getNestedItems());
  const [folderItems, setFolderItems] = useState<FolderItem[]>([]);
  const [showFolderDialog, setShowFolderDialog] = useState<string>();
  const [showEditObjectDialog, setShowEditObjectDialog] = useState<string>();
  const [showObjectCreatorDialog, setShowObjectCreatorDialog] = useState(false);
  const [objectsWithDeps, setObjectsWithDeps] = useState<ObjectSimple[]>([]);
  const {
    objects,
    changeObject,
    updateFolders,
    objectsFolders,
    deleteObject,
    checkRepeatedObject
  } = useContext(LogicBuilderContext);
  const [previewObjectUuid, setPreviewObjectUuid] = useState<string>('');

  const updateShowNestedLocalStorage = useCallback(
    (next: Record<string, boolean>) => {
      if (!app_id || !module_id) return;
      const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      copying[module_id] = {
        ...copying[module_id],
        logicBuilder: {
          ...copying[module_id].logicBuilder,
          folderState: {
            ...copying[module_id].logicBuilder.folderState,
            objects: next
          }
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, copying);
    },
    [app_id, module_id]
  );

  const toggleCollapseFolder = useCallback(
    (folderId: string, collapsed: boolean): void => {
      const next: Record<string, boolean> = {
        ...showNested,
        [folderId]: collapsed
      };
      setShowNested(next);
      updateShowNestedLocalStorage(next);
    },
    [showNested, updateShowNestedLocalStorage]
  );

  const reloadFolderItems = React.useCallback(async () => {
    const items: FolderItem[] = [];
    for (const object of objects) {
      items.push({ ...object, type: 'OBJECT' });
    }
    setFolderItems(items);
  }, [objects]);

  useEffect(() => {
    reloadFolderItems();
  }, [reloadFolderItems]);

  const fetchObjectsContext = React.useCallback(async (module_id: string) => {
    await ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setEnums(schema?.enums ? schema.enums : []);
      setTables(schema?.tables ? schema.tables : []);
      setColumns(schema?.columns ? schema.columns : []);
      setRelationships(schema?.relationships ? schema.relationships : []);
    });
  }, []);

  useEffect(() => {
    if (!module_id) return;
    fetchObjectsContext(module_id);
  }, [fetchObjectsContext, module_id]);

  const openAllFolders = useCallback(() => {
    const next: Record<string, boolean> = {};
    for (const folder of objectsFolders ?? []) {
      next[folder.uuid] = true;
    }
    setShowNested(next);
    updateShowNestedLocalStorage(next);
  }, [objectsFolders, updateShowNestedLocalStorage]);

  const closeAllFolders = useCallback(() => {
    setShowNested({});
    updateShowNestedLocalStorage({});
  }, [updateShowNestedLocalStorage]);

  const folderNameIsValid = (nextParent: string, nextName: string) => {
    if (checkFolderNameAlreadyExists(nextParent, nextName, objectsFolders ?? [])) {
      return false;
    } else if (!validateFolderName(nextName)) {
      return false;
    }
    return true;
  };

  const handleUpdateFolders = useCallback(
    (f: Folder[]) => {
      updateFolders(f, 'objects');
    },
    [updateFolders]
  );

  const handleDrop = useCallback(
    (sourceId: string, sourceType: TargetType, newParentFolder: string) => {
      if (!objectsFolders) return;
      updateFoldersOnMoveFolder(
        objectsFolders,
        sourceId,
        newParentFolder,
        sourceType,
        handleUpdateFolders
      );
      const findObject = objects.find((o) => o.uuid === sourceId);
      if (findObject) {
        changeObject({ ...findObject, folder_id: newParentFolder });
      }
    },
    [changeObject, handleUpdateFolders, objects, objectsFolders]
  );

  const getCallbacks = useCallback((): Record<string, Record<string, any>> | undefined => {
    if (!module_id || !objectsFolders) return undefined;
    const callbacks: Record<string, Record<string, any>> = {};
    // Folder items callbacks.
    for (const folderItem of folderItems) {
      callbacks[folderItem.uuid] = {};
      callbacks[folderItem.uuid].select = () => {};
      callbacks[folderItem.uuid].save = (newName: string) => {
        lb_saveitem(folderItem.uuid, 'OBJECT', newName); // Send request.
        changeObject({ ...folderItem, name: newName } as unknown as ObjectSimple); // Update context.
      };
      callbacks[folderItem.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await lb_drop(e, 'folder', el, folderItem.folder_id ?? '', module_id).then((source) => {
          if (!source) return;
          handleDrop(source.sourceId, source.sourceType, folderItem.folder_id ?? '');
        });
      callbacks[folderItem.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        lb_dragstart(e, folderItem.uuid, folderItem.folder_id ?? '', 'OBJECT', 'folderItem');
      callbacks[folderItem.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragleave(e, el);
      callbacks[folderItem.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragover(e, el, 'folderItem');
      callbacks[folderItem.uuid].delete = async () =>
        await ObjectsService.deleteObject(folderItem.uuid).then(() =>
          deleteObject(folderItem.uuid)
        );
      callbacks[folderItem.uuid].validate = (newName: string): string => {
        const renamedObject = { ...folderItem, name: newName } as unknown as ObjectSimple;
        if (!validateObjectName(newName, 64).valid) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        } else if (checkRepeatedObject(renamedObject)) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        }
        return '';
      };
    }
    // Folders callbacks.
    let checkFolders: Folder[] = [...objectsFolders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) continue;
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = {};
      callbacks[folder.uuid].save = (newName: string) => {
        savefolder(module_id, folder.uuid, newName);
        updateFoldersOnRename(objectsFolders, folder.uuid, newName, handleUpdateFolders);
      };
      callbacks[folder.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await lb_drop(e, 'folder', el, folder.uuid, module_id).then((source) => {
          if (!source) return;
          handleDrop(source.sourceId, source.sourceType, folder.uuid);
        });
      callbacks[folder.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        lb_dragstart(e, folder.uuid, folder.parent ?? '', '', 'folder');
      callbacks[folder.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragleave(e, el);
      callbacks[folder.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragover(e, el, 'folder');
      callbacks[folder.uuid].delete = async () =>
        await deletefolder(module_id, folder.uuid).then(() =>
          updateFoldersOnDelete(objectsFolders, folder.uuid, handleUpdateFolders)
        );
    }
    return callbacks;
  }, [
    module_id,
    objectsFolders,
    folderItems,
    changeObject,
    handleDrop,
    deleteObject,
    checkRepeatedObject,
    handleUpdateFolders
  ]);

  const getMenuCallbacks = useCallback((): Record<string, ContextMenuItem[]> | undefined => {
    const callbacks: Record<string, ContextMenuItem[]> = {};
    if (!module_id || !objectsFolders) return undefined;
    // Folder callbacks.
    let checkFolders: Folder[] = [...objectsFolders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) continue;
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = [];
    }
    // Folder items callbacks.
    for (const viewInfo of folderItems) {
      callbacks[viewInfo.uuid] = [];
      callbacks[viewInfo.uuid].push({
        label: 'Edit',
        icon: 'pencil',
        onClick: () => setShowEditObjectDialog(viewInfo.uuid)
      });
      callbacks[viewInfo.uuid].push({
        label: 'CodePreview',
        icon: 'code',
        onClick: () => setPreviewObjectUuid(viewInfo.uuid)
      });
    }
    return callbacks;
  }, [folderItems, objectsFolders, module_id]);

  const getRootCallbacks = useCallback(() => {
    const callbacks: Record<string, Record<string, any>> = {};
    if (!module_id || !objectsFolders) return callbacks;
    callbacks['root'] = {};
    callbacks['root'].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      await lb_drop(e, 'root', el, '', module_id).then((source) => {
        if (!source) return;
        handleDrop(source.sourceId, source.sourceType, '');
      });
    callbacks['root'].dragstart = (e: DragEvent<HTMLDivElement>) =>
      lb_dragstart(e, '', '', '', 'root');
    callbacks['root'].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      lb_dragleave(e, el);
    callbacks['root'].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      lb_dragover(e, el, 'root');
    return callbacks;
  }, [handleDrop, module_id, objectsFolders]);

  const rootCallbacks = getRootCallbacks()['root'];

  useEffect(() => {
    const fetchObjectWithDeps = async () => {
      if (!module_id) return;
      await ObjectsService.getObjectsByModuleWithDependencies(module_id).then((fetchedObj) => {
        setObjectsWithDeps(fetchedObj);
      });
    };
    fetchObjectWithDeps();
  }, [module_id, objects]);

  return (
    <div className={'d-flex flex-column h-100 flex-grow-1'} style={{ width: '100%' }}>
      <div className="p-2">
        <SearchBar
          id={'findObj'}
          placeholder={t('Search') ?? ''}
          text={filterValue}
          setText={setFilterValue}
        />
      </div>
      <div className={`${styles.layersContainer}`}>
        <div
          className="font-weight-bold ms-3 pb-2 border-bottom border-3 border-body-emphasis me-2 mb-1 d-flex justify-content-between align-items-center"
          style={{ fontWeight: 600 }}
        >
          <label id="label">{'DTO'}</label>
          <ListGroup horizontal>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.FolderCollapse')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0 me-2"
                onClick={() => closeAllFolders()}
              >
                <Icon iconName="down-left-and-up-right-to-center" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.FolderExpand')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0 me-2"
                onClick={() => openAllFolders()}
              >
                <Icon iconName="up-right-and-down-left-from-center" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.NewRootFolder')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                id="createFolderButton"
                action
                className="border-0 m-0 p-0"
                onClick={() => {
                  setShowFolderDialog('');
                }}
              >
                <Icon iconName="folder-plus" />
              </ListGroup.Item>
            </HelpPopover>
          </ListGroup>
        </div>
        <div style={{ overflowX: 'auto', width: '100%', height: '100%' }}>
          {rootCallbacks && (
            <DraggableResource
              handleDrop={getRootCallbacks()['root'].drop}
              handleDragStart={getRootCallbacks()['root'].dragstart}
              handleDragOver={getRootCallbacks()['root'].dragover}
              handleLeave={getRootCallbacks()['root'].dragleave}
              className={styles.rootDraggableArea}
            >
              <div className={`p-3 pt-0`} style={{ height: '100%' }}>
                <RenderFolders
                  folders={objectsFolders ?? []}
                  collapsedFolders={showNested}
                  toggleCollapsedFolder={toggleCollapseFolder}
                  items={folderItems}
                  selectedItem={''}
                  callbacks={getCallbacks()}
                  itemFilter={filterValue}
                  contextMenuItems={getMenuCallbacks()}
                  setFolders={(f: Folder[]) => updateFolders(f, 'objects')}
                  checkFolderNameExists={(parentId: string, name: string, folderId: string) =>
                    checkFolderNameAlreadyExists(parentId, name, objectsFolders ?? [], folderId)
                  }
                />
              </div>
            </DraggableResource>
          )}
        </div>
      </div>
      {showFolderDialog != null && (
        <CreateFolderDialog
          show={showFolderDialog != null}
          onClose={() => setShowFolderDialog(undefined)}
          parentUuid={showFolderDialog}
          onCreate={(f: Folder) =>
            updateFoldersOnCreate(objectsFolders ?? [], f, handleUpdateFolders)
          }
          type={FolderType.LG_OBJ}
          validateFolderName={(parent: string, name: string) => folderNameIsValid(parent, name)}
        />
      )}
      {(showObjectCreatorDialog || showEditObjectDialog != null) && (
        <ObjectDialog
          show={showObjectCreatorDialog || !!showEditObjectDialog}
          onClose={() => {
            setShowObjectCreatorDialog(false);
            setShowEditObjectDialog(undefined);
          }}
          editMode={!!showEditObjectDialog}
          dialogTitle={showEditObjectDialog ? 'EditObject' : 'CreateObject'}
          modules={[]}
          tables={tables}
          columns={columns}
          enums={enums}
          relationships={relationships}
          objectId={showEditObjectDialog}
          entityMode={!!showEditObjectDialog}
          loadingData={false}
          objectsWithDeps={objectsWithDeps}
        />
      )}
      {previewObjectUuid && (
        <CodeEditorModal
          show={previewObjectUuid !== ''}
          handleClose={() => setPreviewObjectUuid('')}
          id={previewObjectUuid ?? ''}
          previewType={CodePreviewType.OBJECT}
        />
      )}
    </div>
  );
}

export default ObjectsToolbar;
