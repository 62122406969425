import React from 'react';
import Navbar from 'web_ui/navbar';
import SidebarSettings from './components';

function Settings() {
  return (
    <>
      <Navbar context="settings" />
      <SidebarSettings />
    </>
  );
}

export default Settings;
