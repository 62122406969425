import React, { ChangeEvent, useEffect, useState } from 'react';
import { ControlProps } from '../index';
import { Form } from 'react-bootstrap';
import { DefaultLabel } from '../styles';
import styles from '../styles.module.css';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { FormContent } from '../../../../../modules/designer/studio/exocode_components/form';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../../../../modules/designer/studio/store';
import { DataType } from '../../../../../modules/logic_builder/types';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';
import { FRONTEND_VARIABLE_TYPES, VariableTypes } from 'modules/designer/types';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { WritableDraft } from 'immer/dist/internal';
import { ObjectsService } from '../../../../../modules/logic_builder/services';

export type EndpointRequest = {
  description: string;
  httpStatus: number;
  schema: Schema;
};

export type Schema = {
  description: string;
  uuid: string;
  properties: { [key: string]: string[] };
  items: { [key: string]: SchemaItem };
};

export type SchemaItem = {
  type: string;
  id: string;
  name: string;
  list: boolean;
};

function FormContentControl(props: ControlProps & { value: FormContent }) {
  const { t } = useTranslation();
  const [content, setContent] = useState<FormContent>();
  const variables = useSelector((state: InterfaceStudioState) => state.variables);
  const objects = useSelector((state: InterfaceStudioState) => state.objects);

  useEffect(() => {
    props.value && setContent(props.value);
  }, [props.value]);

  function convertToInputType(type: DataType): string {
    switch (type) {
      case 'ENUM':
        return 'select';
      case 'AUTOINCREMENT':
      case 'FLOAT':
      case 'DECIMAL':
      case 'BIGINT':
      case 'SMALLINT':
      case 'DOUBLE':
      case 'INTEGER':
        return 'number';
      case 'BLOB':
      case 'CLOB':
        return 'file';
      case 'BOOLEAN':
        return 'checkbox';
      case 'DATE':
      case 'TIME':
      case 'TIMESTAMP':
        return 'date';
      default:
        return 'text';
    }
  }

  async function handleVariableChange(e: ChangeEvent<HTMLSelectElement>) {
    if (!props.onChange || !content) return;

    const newContent = await produce(content, async (draft) => {
      draft.inputs = [];
      draft.variable = e.target.value;

      if (e.target.value === '') return;

      const variableSchema = variables[e.target.value]?.objectUuid;
      if (!variableSchema) return;

      const schema = objects[variableSchema];
      if (!schema || !schema.uuid || !schema.objectItems) return;

      draft.nestedObjectsPath = {};
      await generateInputs(schema, draft);
    });
    props.onChange(newContent, 'content');
    setContent(newContent);
  }

  async function generateInputs(schema: any, draft: WritableDraft<FormContent>) {
    const schemaCopy = [...schema.objectItems];

    const objId = schema?.uuid ?? '';

    const obj = await ObjectsService.getObject(objId);

    const shouldShowAllFields = obj.frontend && !obj.backend;

    schemaCopy.sort(handleSortObjectLast).forEach((objectItem: any) => {
      if (
        !objectItem ||
        objectItem.readOnly ||
        objectItem.list ||
        (!objectItem.exported && !shouldShowAllFields) ||
        objectItem.dataType === 'AUTOINCREMENT'
      )
        return;
      const label = objectItem.exportedName
        ? objectItem.exportedName.charAt(0).toUpperCase() + objectItem.exportedName.slice(1)
        : (objectItem?.name ?? '').charAt(0).toUpperCase() + (objectItem?.name ?? '').slice(1);

      if (objectItem.dataType !== 'OBJECT') {
        draft.inputs.push({
          label,
          objectItemUuid: objectItem.uuid ?? '',
          type: convertToInputType(objectItem.dataType ?? 'STRING')
        });
      } else if (schema.uuid && objectItem.object?.id && objectItem.uuid) {
        generateRecursiveNestedObjectItems(objectItem.object.id, objectItem, draft);
      }
    });
  }

  function handleSortObjectLast(a: any, b: any) {
    if (a.dataType === 'OBJECT' && b.dataType !== 'OBJECT') {
      return 1;
    } else if (a.dataType !== 'OBJECT' && b.dataType === 'OBJECT') {
      return -1;
    } else {
      return 0;
    }
  }

  async function generateRecursiveNestedObjectItems(
    nestedObject: string,
    objectItemParent: any,
    draft: WritableDraft<FormContent>
  ) {
    const subSchema = objects[nestedObject];
    if (!subSchema?.uuid || !subSchema.objectItems) return;

    const obj = await ObjectsService.getObject(subSchema.uuid);

    const shouldShowAllFields = obj.frontend && !obj.backend;

    const subObjectItems = subSchema.objectItems
      .filter(
        (objectItem: any) =>
          objectItem &&
          !objectItem.readOnly &&
          !objectItem.list &&
          (objectItem.exported || shouldShowAllFields)
      )
      .sort(handleSortObjectLast);
    subObjectItems.forEach((objectItem: any) => {
      if (objectItem.uuid) {
        draft.nestedObjectsPath[objectItem.uuid] = {
          parentObjectUuid: nestedObject,
          parentObjectItemUuid: objectItemParent.uuid
        };
      }
      if (!(objectItemParent.relationshipId && subSchema.entityUuid && !objectItem.isPk)) {
        if (objectItem.dataType === 'OBJECT' && objectItem.uuid) {
          generateRecursiveNestedObjectItems(objectItem.object?.id, objectItem, draft);
        } else {
          draft.inputs.push({
            label:
              (objectItem.exportedName || objectItem.name).charAt(0).toUpperCase() +
              (objectItem.exportedName || objectItem.name).slice(1),
            objectItemUuid: objectItem.uuid ?? '',
            type: convertToInputType(objectItem.dataType ?? 'STRING'),
            parentObject: subSchema.uuid
          });
        }
      }
    });
  }

  return (
    <div className="mb-3">
      <DefaultLabel className={`${styles.defaultLabel} form-label`}>
        <MissingMessage
          type={MissingMessageType.SOURCE_VARIABLE}
          uuid={content?.variable ? content?.variable : ''}
          requiredTypes={[FRONTEND_VARIABLE_TYPES.OBJECT as VariableTypes]}
        />
        {t('designer.right_side.DataSource')}
        {props.tooltip && (
          <HelpPopover
            placement="top"
            helpBoxProps={{
              description: t(`${props.tooltip}`) ?? ''
            }}
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </DefaultLabel>
      <Form.Select value={content?.variable} onChange={handleVariableChange}>
        <option value="">---</option>
        {Object.values(variables)
          .filter((variable) => variable.type === 'OBJECT' && !variable.list)
          .map((variable) => (
            <option key={variable.uuid} value={variable.uuid}>
              ${variable.name}
            </option>
          ))}
      </Form.Select>
    </div>
  );
}

export default FormContentControl;
