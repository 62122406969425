import { DataService } from '../../../modules/modeler/services';
import { Entity, EntityColumn, Relationship, TableType } from '../types';

export async function fetchEntities(moduleId: string, onlyInternalEntities?: boolean) {
  if (!moduleId) return;

  // Fetch project DB schema, with all the entities
  const schema = await fetchSchema(moduleId);

  // Get the table list, that is the entities list
  if (!schema.tables || !schema.tables.length) return;
  const tables = schema.tables as TableType[];
  const columns = schema.columns as EntityColumn[];

  // Get the relationships list
  const relationships = schema.relationships as Relationship[];

  if (onlyInternalEntities) {
    return mapTablesToEntities(tables, columns, relationships, onlyInternalEntities);
  } else {
    return mapTablesToEntities(tables, columns, relationships, false);
  }
}

async function fetchSchema(moduleId: string) {
  return await DataService.getSchema(moduleId);
}

/*
 * This function take the tables and relationships array from the
 * schema and build an entity map.
 *
 * @param TableType[] tables;
 * @param Relationship[] moduleRelationships;
 * */
function mapTablesToEntities(
  tables: TableType[],
  columns: EntityColumn[],
  moduleRelationships: Relationship[],
  onlyInternalEntities: boolean
) {
  // Create an object with {[key = entityUuid]: Entity} format
  const entities: { [key: string]: Entity } = {};
  // Loop through all the tables, that are the entities
  tables.forEach((table) => {
    const entity = mapTableToEntity(table, columns, moduleRelationships);
    entities[entity.entityUuid] = entity;
  });
  // Loop through all the relations, to find external entities
  const externalEntities = fetchExternalEntities(moduleRelationships);

  if (onlyInternalEntities) {
    return { ...entities };
  }

  return { ...entities, ...externalEntities };
}

function mapTableToEntity(
  table: TableType,
  columns: EntityColumn[],
  moduleRelationships: Relationship[]
) {
  const relatedEntities = fetchEntityRelations(
    moduleRelationships,
    table.content.data.relationships,
    table.uuid
  );
  const entityColumns = fetchEntityColumns(table.uuid, columns);
  // Then, create the entity in the entities object
  const entity = {
    entityUuid: table.uuid,
    entityName: table.content.data.name,
    entityDescription: table.content.data.description,
    entityColumns,
    relatedEntities
  };
  return entity;
}

function fetchEntityRelations(
  moduleRelationships: Relationship[],
  entityRelationships: string[],
  entityUuid: string
) {
  const relatedEntities = [] as string[];
  entityRelationships.forEach((relationUuid) => {
    const relation = moduleRelationships.find((r) => r.id === relationUuid);
    if (!relation || !relation.id) return;
    relatedEntities.push(relation.from === entityUuid ? relation.to : relation.from);
  });
  return relatedEntities;
}

function fetchEntityColumns(entityUuid: string, columns: EntityColumn[]) {
  return columns.filter((c) => c.tableUUID === entityUuid);
}

// If there is any relation with an Entity from other module, add it to entities list
function fetchExternalEntities(moduleRelationships: Relationship[] = []) {
  const foreignEntities: { [uuid: string]: Entity } = {} as { [uuid: string]: Entity };
  moduleRelationships.forEach((relation) => {
    if (relation.info)
      foreignEntities[relation.to] = {
        entityUuid: relation.to,
        entityName: relation.info.tableName,
        entityDescription: '',
        entityColumns: [],
        relatedEntities: [relation.from]
      };
  });
  return foreignEntities;
}
