import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

export type PopupAlertVariant = 'success' | 'danger' | 'warning';

type PopupAlertProps = {
  i18nKey?: string;
  onClose: () => void;
  variant?: PopupAlertVariant;
  extraStyle?: CSSProperties;
  dismissTime?: number;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export function PopupAlert({
  i18nKey,
  onClose,
  variant = 'danger',
  extraStyle = {},
  dismissTime,
  children,
  onClick
}: PopupAlertProps) {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  const close = useCallback(() => {
    setShow(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      close();
    }, dismissTime || 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [close]);

  const handleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  return (
    <Alert
      id="alertMessage"
      className={`${styles.ErrorAlertWrapper}`}
      show={show}
      variant={variant}
      onClose={close}
      dismissible
      style={extraStyle}
      onClick={handleClick}
    >
      {i18nKey && <p>{t(i18nKey)}</p>}
      {children && children}
    </Alert>
  );
}
