import produce from 'immer';
import { InterfaceStudioState, initialState } from '..';
import {
  DesignerStudioActions,
  SET_INITIAL_DESIGNER_STATE,
  SetInitialDesignerStateAction
} from '../actions/designerStudio';

export const designerStudioReducer = (
  state: InterfaceStudioState = initialState,
  action: DesignerStudioActions
): InterfaceStudioState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_INITIAL_DESIGNER_STATE:
        return doSetInitialDesignerState(draft, action);
      default:
        return state;
    }
  });
};

// Set initial designer state and last valid entry.
function doSetInitialDesignerState(
  _state: InterfaceStudioState,
  action: SetInitialDesignerStateAction
): InterfaceStudioState {
  const newState = { ...action.state };
  const lastValidEntry = JSON.stringify(newState);
  newState.studio.history = {
    lastValidEntry: lastValidEntry,
    undoStack: [],
    redoStack: []
  };
  return newState;
}
