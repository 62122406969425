import { EnumColumn, EnumColumnID } from '../../../types';
import { Action } from 'redux';

export const ADD_ENUMCOLUMN = 'ADD_ENUMCOLUMN';
export const DELETE_ENUMCOLUMN = 'DELETE_ENUMCOLUMN';
export const CHANGE_ENUMCOL_LITERAL = 'CHANGE_ENUMCOL_LITERAL';
export const CHANGE_ENUMCOL_ORDINAL = 'CHANGE_ENUMCOL_ORDINAL';
export const SET_ENUMCOLUMNS = 'SET_ENUMCOLUMNS';
export const CHANGE_ENUMCOL_DESCRIPTION = 'CHANGE_ENUMCOL_DESCRIPTION';
export const MOVE_ENUM_COLUMN = 'MOVE_ENUM_COLUMN';

export type EnumColumnsActions =
  | addEnumColumnAction
  | deleteEnumColumnAction
  | changeEnumColLiteralAction
  | changeEnumColOrdinalAction
  | changeEnumColDescriptionAction
  | setEnumColumnsAction
  | MoveEnumColumnAction;

export interface MoveEnumColumnAction extends Action {
  type: 'MOVE_ENUM_COLUMN';
  payload: {
    table: string;
    column: string;
    swappedColumn: string;
    direction: string;
  };
}

export interface deleteEnumColumnAction extends Action {
  type: 'DELETE_ENUMCOLUMN';
  payload: {
    id: EnumColumnID;
    table: string;
  };
}

export interface addEnumColumnAction extends Action {
  type: 'ADD_ENUMCOLUMN';
  payload: EnumColumn;
}

export interface changeEnumColDescriptionAction extends Action {
  type: 'CHANGE_ENUMCOL_DESCRIPTION';
  payload: { id: EnumColumnID; value: string };
}

export interface changeEnumColLiteralAction extends Action {
  type: 'CHANGE_ENUMCOL_LITERAL';
  payload: { id: EnumColumnID; value: string };
}

export interface changeEnumColOrdinalAction extends Action {
  type: 'CHANGE_ENUMCOL_ORDINAL';
  payload: { id: EnumColumnID; value: number };
}

export interface setEnumColumnsAction extends Action {
  type: 'SET_ENUMCOLUMNS';
  payload: {
    enumColumns: EnumColumn[];
  };
}

export const moveEnumColumn = (
  table: string,
  column: string,
  swappedColumn: string,
  direction: string
) => ({
  type: MOVE_ENUM_COLUMN,
  payload: { table, column, swappedColumn, direction }
});

export const addEnumColumn = (enumColumn: EnumColumn) => ({
  type: ADD_ENUMCOLUMN,
  payload: enumColumn
});

export const deleteEnumColumn = (id: EnumColumnID, table: string) => ({
  type: DELETE_ENUMCOLUMN,
  payload: { id, table }
});

export const changeLiteralValue = (id: EnumColumnID, literalValue: string) => ({
  type: CHANGE_ENUMCOL_LITERAL,
  payload: { id: id, value: literalValue }
});

export const changeOrdinalValue = (id: EnumColumnID, ordinalValue: number) => ({
  type: CHANGE_ENUMCOL_ORDINAL,
  payload: { id: id, value: ordinalValue }
});

export const changeDescription = (id: EnumColumnID, description: string) => ({
  type: CHANGE_ENUMCOL_DESCRIPTION,
  payload: { id: id, value: description }
});

export const setEnumColumns = (enumColumns: EnumColumn[]) => ({
  type: SET_ENUMCOLUMNS,
  payload: { enumColumns }
});
