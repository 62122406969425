import React from 'react';
import * as Icons from 'react-icons/fa';

type FaIconName = keyof typeof Icons | '';

interface ButtonTickFormatterPreviewProps {
  formatterParams: { icon: FaIconName };
  value: string;
}

function ButtonTickFormatterPreview({ formatterParams, value }: ButtonTickFormatterPreviewProps) {
  const Icon = formatterParams.icon ? Icons[formatterParams.icon] : null;
  return Icon && (value[0] === 'a' ? <Icon style={{ color: '#0d6efd' }} /> : <Icon />);
}

export default ButtonTickFormatterPreview;
