import styles from './styles.module.css';
import React, { useState } from 'react';
import { Column, DataType, TableUUID } from '../../../../types';
import { SimpleColumn } from './simple_column';
import Confirmation from '../../../../../../web_ui/confirmation';
import { t } from 'i18next';
import { deleteColumn } from '../../../store/actions/columns';
import { useDispatch, useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../store';
import EmptyColumn from './empty_column';
import { setErrorMessage } from '../../../store/actions/studio';

type SimpleColumnListProps = {
  tableID: TableUUID;
  tableColumns: Column[];
  selectedColumnID: string;
  selectColumn: React.Dispatch<React.SetStateAction<string>>;
  fieldTypes: { [key: string]: DataType[] };
};

export default function SimpleColumnList({
  tableID,
  tableColumns,
  selectedColumnID,
  selectColumn,
  fieldTypes
}: SimpleColumnListProps) {
  const [showDeleteColumnModal, setShowDeleteColumnModal] = useState<boolean>(false);
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const dispatch = useDispatch();

  const handleDeleteColumn = async () => {
    if (isLastPk()) {
      dispatch(setErrorMessage('modeler.DeleteLastPk'));
      setShowDeleteColumnModal(false);
      return;
    }

    dispatch(deleteColumn(columns[selectedColumnID].uuid));
    setShowDeleteColumnModal(false);
  };

  function isLastPk() {
    const filteringByPk = tableColumns.filter((column) => {
      return column.isPK;
    });
    return columns[selectedColumnID].isPK && filteringByPk.length === 1;
  }

  const jumpToNextColumn = (selectedColumn: string) => {
    let getNext = false;
    let nextColumn = '';
    for (const column of tableColumns) {
      if (getNext) {
        nextColumn = column.uuid;
        break;
      }
      if (column.uuid === selectedColumn) {
        getNext = true;
      }
    }
    if (nextColumn) {
      selectColumn(nextColumn);
    } else {
      if (tableColumns[0]?.isPK) {
        selectColumn('NEW_COLUMN_PK');
      } else {
        selectColumn('NEW_COLUMN');
      }
    }
  };

  const renderColumns = () => {
    if (!tableColumns) return;
    return tableColumns.map((column) => {
      return (
        <SimpleColumn
          key={column.uuid}
          column={column}
          jumpToNextColumn={() => jumpToNextColumn(column.uuid)}
          selectedColumnID={selectedColumnID}
          selectColumn={selectColumn}
          showDeleteModal={setShowDeleteColumnModal}
          fieldTypes={fieldTypes}
        />
      );
    });
  };

  const getEmptyColumnLabel = (): string => {
    if (tableColumns[0]?.isPK) {
      return 'NEW_COLUMN_PK';
    } else {
      return 'NEW_COLUMN';
    }
  };

  return (
    <>
      <div
        id={tableColumns[0]?.isPK ? 'pkColumnsList' : 'simpleColumnsList'}
        className={styles.ColumnsListWrapper}
      >
        {renderColumns()}
        <EmptyColumn
          tableID={tableID}
          selectedColumnID={selectedColumnID}
          selectColumn={selectColumn}
          isPK={tableColumns[0]?.isPK}
          fieldTypes={fieldTypes}
          newColumnLabel={getEmptyColumnLabel()}
        />
      </div>
      <Confirmation
        show={showDeleteColumnModal}
        onCancel={() => setShowDeleteColumnModal(false)}
        onConfirmation={handleDeleteColumn}
        message={
          t('modeler.Delete Column') +
          ' (' +
          (columns[selectedColumnID] ? columns[selectedColumnID].name : '') +
          ')'
        }
        onClose={() => setShowDeleteColumnModal(false)}
      />
    </>
  );
}
