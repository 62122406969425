import React from 'react';
import Studio from './studio';

type LogicBuilderProps = Record<string, unknown>;

function LogicBuilder(props: LogicBuilderProps) {
  return <Studio toggleTheme={props.toggleTheme} />;
}

export default LogicBuilder;
