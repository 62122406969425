import styles from './styles.module.css';
import { Button, ButtonGroup, ButtonToolbar, Form } from 'react-bootstrap';
import React from 'react';
import { t } from 'i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type ActionsBarProps = {
  addNew: () => void;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

export default function ActionsBar({ addNew, searchText, setSearchText }: ActionsBarProps) {
  return (
    <div className={styles.ActionsToolbar}>
      <ButtonToolbar aria-label="Object toolbar actions">
        <ButtonGroup className="me-2 mb-2">
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Create')!
            }}
          >
            <Button
              id={'createProprietiesButton'}
              onClick={() => addNew()}
              variant="outline-success"
              className={styles.ToolbarButtons}
            >
              <i className={'fa fa-plus'} />
            </Button>
          </HelpPopover>
          <Form.Group className="mb-2" controlId="columnSearchBox">
            <Form.Control
              className={styles.SearchBox}
              placeholder={t('Search') ?? ''}
              value={searchText}
              type="text"
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            />
          </Form.Group>
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
}
