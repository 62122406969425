import { GitRepo } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

export default class Integrations {
  async getAvailableRepositories(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/integrations/app/${appId}/repos`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<GitRepo[]>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async getAccountRepositories(accountId: number, isOrg: boolean) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    let endpoint;
    if (isOrg) {
      endpoint = `${API_URL}/orgs/${accountId}/integrations/github/repos`;
    } else {
      endpoint = `${API_URL}/integrations/github/repos`;
    }

    const request = new Request(endpoint, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<GitRepo[]>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async attatchRepositoryToApp(appId: string, repo: string, createPR: boolean, autoMerge: boolean) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        name: repo,
        createPR: createPR,
        autoMerge: autoMerge
      })
    };

    const url = `${API_URL}/integrations/app/${appId}/repos`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<any>;
      throw new Error('Something went wrong while trying to fetch app properties.');
    });
  }

  async detatchRepositoryFromApp(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/integrations/app/${appId}/repos`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<any>;
      throw new Error('Something went wrong while trying to fetch app properties.');
    });
  }

  async uninstallFromUser() {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/integrations/github`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.text() as Promise<any>;
      throw new Error('Something went wrong while trying to fetch app properties.');
    });
  }

  async uninstallFromOrg(orgId: number) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/orgs/${orgId}/integrations/github`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.text() as Promise<any>;
      throw new Error('Something went wrong while trying to fetch app properties.');
    });
  }
}
