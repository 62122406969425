import { Action } from 'redux';
import { EditorState } from '../../types/function_editor_state';

export const SELECT_ACTION = 'SELECT_ACTION';

export interface SelectActionAction extends Action {
  type: 'SELECT_ACTION';
  payload: string;
}

export const selectAction = (uuid: string): SelectActionAction => ({
  type: SELECT_ACTION,
  payload: uuid
});

export function doSelectAction(state: EditorState, action: SelectActionAction): EditorState {
  if (!action.payload) return state;
  state.selectedAction = action.payload;
  return state;
}
