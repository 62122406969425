import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormatterManifest, FormatterProps, FormatterTypes, LOOKUP_MANIFEST } from '../../types';
import { Col, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../../../components/Popover';
import HelpIcon from '../../../components/HelpIcon';
import Icon from 'web_ui/icon';
import Button from 'react-bootstrap/Button';

function LookupFormatter(props: FormatterProps) {
  const { t } = useTranslation();
  const [newKey, setNewKey] = useState<string>('');
  const [newValue, setNewValue] = useState<string>('');

  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.LOOKUP) {
      props.onChange && props.onChange(props.index, 'formatterParams', LOOKUP_MANIFEST);
    }
  }, []);

  function handleAddMapper() {
    const newValues: Record<string, any> = { ...props.value.values };
    newValues[newKey as string] = newValue;
    handleChange(newValues);
    setNewKey('');
    setNewValue('');
  }

  function handleRemoveMapper(keyToRemove: string) {
    const newValues: Record<string, any> = { ...props.value.values };
    for (const key in newValues) {
      if (key === keyToRemove) {
        delete newValues[key];
      }
    }
    handleChange(newValues);
  }

  function handleChange(newValues: Record<string, any>) {
    const updatedLookupParams: Record<string, any> = {
      values: newValues,
      type: FormatterTypes.LOOKUP
    };
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedLookupParams);
  }

  return (
    <div className="mb-3 border-bottom ">
      <div className="mb-3">
        <div className="mb-3 d-flex justify-content-between align-items-end">
          <Col sm={5}>
            <Form.Group>
              <Form.Label column>
                {t('designer.right_side.FormatterControls.LookupFormatterNewKey')}
              </Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.LookupFormatterNewKeyTitle') ||
                    'designer.right_side.FormatterControls.LookupFormatterNewKeyTitle',
                  description:
                    t('designer.right_side.FormatterControls.LookupFormatterNewKeyDescription') ||
                    'designer.right_side.FormatterControls.LookupFormatterNewKeyDescription',
                  note: [
                    t('designer.right_side.FormatterControls.LookupFormatterNewKeyNote01') ||
                      'designer.right_side.FormatterControls.LookupFormatterNewKeyNote01'
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="text"
                placeholder="key"
                value={newKey}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewKey(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col sm={5}>
            <Form.Group>
              <Form.Label column>
                {t('designer.right_side.FormatterControls.LookupFormatterNewValue')}
              </Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.LookupFormatterNewValueTitle') ||
                    'designer.right_side.FormatterControls.LookupFormatterNewValueTitle',
                  description:
                    t('designer.right_side.FormatterControls.LookupFormatterNewValueDescription') ||
                    'designer.right_side.FormatterControls.LookupFormatterNewValueDescription',
                  note: [
                    t('designer.right_side.FormatterControls.LookupFormatterNewValueNote01') ||
                      'designer.right_side.FormatterControls.LookupFormatterNewValueNote01'
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="text"
                placeholder="value"
                value={newValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Button
            variant="secondary"
            size="sm"
            disabled={newKey === '' || newValue === ''}
            onClick={handleAddMapper}
            style={{ height: '37px' }}
          >
            <Icon iconName="plus" />
          </Button>
        </div>

        <div className="">
          {props.value && props.value.values && Object.keys(props.value.values).length > 0 ? (
            <ListGroup>
              {Object.keys(props.value.values).map((mapperKey: string, index) => (
                <ListGroup.Item key={index} className="d-flex justify-content-between pe-4 ps-4">
                  <span>{mapperKey}</span>
                  <span className="text-body-tertiary">
                    <Icon iconName="arrow-right"></Icon>
                  </span>
                  <div>
                    <span className="pe-4">{props.value.values[mapperKey]}</span>
                    <span
                      onClick={(e) => handleRemoveMapper(mapperKey)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon iconName="trash" />
                    </span>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <span className="text-body-tertiary">Sem mapeamentos</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default LookupFormatter;
