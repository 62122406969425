import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { Template } from 'modules/designer/types';

type TemplatesListProps = {
  selectedTemplate: string;
  setSelectedTemplate: (template: string) => void;
  templates: Template[];
  nullable?: boolean;
};

const API_URL = process.env.REACT_APP_API_URL;

export function TemplatesList(props: TemplatesListProps) {
  function handleTemplateOnClick(template: string) {
    props.nullable && template === props.selectedTemplate
      ? props.setSelectedTemplate('')
      : props.setSelectedTemplate(template);
  }

  const thumbnailSource = (template: Template) => {
    if (template.thumbnail) {
      return `${API_URL}${template.thumbnail}`;
    } else if (template.isNative) {
      return `/assets/templates/${template.name.toLowerCase().replaceAll(' ', '_')}.png`;
    } else {
      return `/assets/default.png`;
    }
  };

  return (
    <>
      <div className={styles.TemplateWrapper}>
        <div className={styles.TemplateThumbnailWrapper}>
          {props.templates.map((template) => {
            return (
              <div
                key={template.uuid}
                className={`${styles.TemplateThumbnail} ${
                  props.selectedTemplate === template.uuid && styles.SelectedTemplate
                }`}
                onClick={() => handleTemplateOnClick(template.uuid)}
              >
                <img
                  src={thumbnailSource(template)}
                  alt={template.name}
                  style={{ width: '150px' }}
                />
                <div
                  id={`${template.name.replaceAll(' ', '').toLocaleLowerCase()}Template`}
                  className={styles.ThumbnailDescription}
                >
                  <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {template.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
