import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { TeamServ } from 'modules/organization/services';
import { Team } from 'modules/auth/types/auth_types';

type Props = {
  show: boolean;
  close: () => void;
  orgId: number | null;
  updateList: () => void;
};

const NewTeamModal = ({ show, close, orgId, updateList }: Props) => {
  const [name, setName] = useState<string>('');

  const resetName = () => {
    setName('');
  };

  const creatingTeam = async () => {
    if (!orgId || !name) return;

    const data: Team = {
      name: name,
      orgId: orgId
    };

    const dataCreated = await TeamServ.createTeam(data);
    if (dataCreated) {
      updateList();
      close();
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        close();
        resetName();
      }}
      centered
    >
      <Modal.Header closeButton>
        <span>Create Team</span>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Label>Team name:</Form.Label>
          <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            close();
            resetName();
          }}
        >
          Cancel
        </Button>
        <Button onClick={() => creatingTeam()}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTeamModal;
