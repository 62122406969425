import React, { useEffect, useState } from 'react';
import { OrganizationService, TeamServ } from 'modules/organization/services';
import { Modal, Button, Form, Nav } from 'react-bootstrap';
import { UserProfile } from 'modules/auth/types/auth_types';
import Member from '../Member';

type Props = {
  show: boolean;
  close: () => void;
  orgId: number | null;
  teamId: number | null;
  updateList: () => void;
};

const ManageTeamModal = ({ show, close, orgId, teamId, updateList }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>('teamMember');
  const [teamMembers, setTeamMembers] = useState<UserProfile[]>([]);
  const [orgMembers, setOrgMembers] = useState<UserProfile[]>([]);
  const [idsFromTeamMembers, setIdsFromTeamMembers] = useState<number[]>([]);

  useEffect(() => {
    teamMembersFetch();
    orgMembersFetch();
  }, [orgId, teamId]);

  const refetching = () => {
    teamMembersFetch();
    orgMembersFetch();
  };

  const teamMembersFetch = async () => {
    if (!orgId || !teamId) return;
    if (teamId === -1) return;
    const dataRetrieved = await TeamServ.getAllTeamMembers(orgId, teamId);
    if (dataRetrieved) {
      setTeamMembers(dataRetrieved);
      dataRetrieved.forEach((item) => {
        setIdsFromTeamMembers((val) => [...val, item.id]);
      });
    }
  };

  const orgMembersFetch = async () => {
    if (!orgId) return;
    const dataRetrieved = await OrganizationService.getMembersOfOrg(orgId);
    if (dataRetrieved) setOrgMembers(dataRetrieved);
  };

  const addingIntoTeam = async (userId: number) => {
    if (!orgId || !teamId) return;
    if (teamId === -1) return;
    const dataRetrieved = await TeamServ.insertMember(orgId, teamId, userId);
    if (dataRetrieved) {
      setIdsFromTeamMembers([]);
      refetching();
    }
  };

  const removeFromTeam = async (userId: number) => {
    if (!orgId || !teamId) return;
    if (teamId === -1) return;
    const dataRetrieved = await TeamServ.deleteTeamMember(orgId, teamId, userId);
    if (dataRetrieved) {
      setIdsFromTeamMembers([]);
      refetching();
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        close();
      }}
      centered
    >
      <Modal.Header closeButton>
        <span>Manage Team</span>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Nav
            variant="tabs"
            onSelect={(k) => k && setSelectedTab(k)}
            defaultActiveKey={'teamMember'}
          >
            <Nav.Item>
              <Nav.Link eventKey={'teamMember'}>Team Member</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={'orgMember'}>Org Member</Nav.Link>
            </Nav.Item>
          </Nav>
          {selectedTab === 'teamMember' ? (
            <>
              {teamMembers.map((item, index) => (
                <Member
                  key={index}
                  data={item}
                  index={index}
                  isFromTeamMember={true}
                  addingTeamMember={addingIntoTeam}
                  deleteTeamMember={removeFromTeam}
                />
              ))}
            </>
          ) : selectedTab === 'orgMember' ? (
            <>
              {orgMembers
                .filter((item) => {
                  return !idsFromTeamMembers.includes(item.id);
                })
                .map((item, index) => (
                  <Member
                    key={index}
                    data={item}
                    index={index}
                    isFromTeamMember={false}
                    addingTeamMember={addingIntoTeam}
                    deleteTeamMember={removeFromTeam}
                  />
                ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ManageTeamModal;
