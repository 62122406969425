import { InvitationRepo } from '../repos';
import { AddInvite } from '../types';

export default class Organization {
  async createInviteOrg(orgId: number, data: AddInvite[]) {
    return InvitationRepo.createInviteOrg(orgId, data);
  }

  async getInvitesOrg(orgId: number) {
    return InvitationRepo.getInvitesOrg(orgId);
  }

  async createInviteApp(appId: string, data: AddInvite[]) {
    return InvitationRepo.createInviteApp(appId, data);
  }

  async deleteInvite(orgId: number, invitedId: number) {
    return InvitationRepo.deleteInvite(orgId, invitedId);
  }
}
