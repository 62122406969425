import { FunctionEditorState } from '../../types/function_editor_state';
import { UpdateActionAction } from '../actions/update_action';

export function doUpdateAction(
  state: FunctionEditorState,
  action: UpdateActionAction
): FunctionEditorState {
  const { uuid, key, value } = action.payload;
  const a = state.actions[uuid];
  if (a.type !== 'DECLARE_VARIABLE' && a.type !== 'BEGIN_CYCLE') return state;

  if (key === 'dataType') {
    state.variables[a.data.uuid].type = value.type;
    state.variables[a.data.uuid].objectUuid = value?.objectUuid ?? '';
    state.variables[a.data.uuid].enumUuid = value?.enumUuid ?? '';
    return state;
  }

  // @ts-ignore
  if (key !== 'initialize') state.variables[a.data.uuid][key] = value;

  return state;
}
