import React from 'react';
import Icon from '../../../../../icon';
import styles from './styles.module.css';

type ActionIconProps = {
  icon: React.ReactNode;
};

function ActionIcon({ icon }: ActionIconProps) {
  return (
    <div className={'d-flex align-items-center'}>
      <div className={'ps-1 pe-2 d-flex nowrap'}>
        <Icon iconName={'fa-solid fa-ellipsis-vertical'} />
        <Icon iconName={'fa-solid fa-ellipsis-vertical'} />
      </div>
      <div className={styles.ActionIcon}>{icon}</div>
    </div>
  );
}

export default ActionIcon;
