import React, { useCallback, useState } from 'react';
import style from './style.module.css';
import { Button, ListGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Icon from 'web_ui/icon';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import useSession from '../../../../../../hooks/useSession';

type Props = {
  id: number;
  title: string;
  extension: string;
  updateFiles: () => Promise<void>;
};

export function FileInstance({ id, title, extension, updateFiles }: Props) {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const { app_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [session] = useSession();

  const deleteAsset = useCallback(
    async (assetId: number) => {
      if (loading) {
        return;
      }
      setLoading(true);
      fetch(`${API_URL}/apps/${app_id}/assets/${assetId}`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'default',
        credentials: 'include',
        referrerPolicy: 'no-referrer',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async () => {
          await updateFiles().then(() => {
            setShowModal(false);
          });
          await session.reloadUser();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [API_URL, app_id, loading, updateFiles]
  );

  function onCancelModal() {
    setShowModal(false);
  }

  return (
    <>
      <ListGroup.Item className="border-0 p-0" variant="body" action>
        <div title={title} className={style.mainContainer}>
          <div className={style.containerTitle}>
            <p
              id="label"
              className="text-body-emphasis m-0"
              style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {title}
            </p>
          </div>
          <div className={style.containerMimeType}>
            <p className=" text-body-secondary m-0">{extension}</p>
          </div>
          <div>
            <HelpPopover
              helpBoxProps={{
                title: t('appResume.assets.Delete') ?? 'appResume.assets.Delete'
              }}
              placement="top"
            >
              <Button
                id={'deleteButton'}
                variant="body"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <Icon iconName="trash"></Icon>
              </Button>
            </HelpPopover>
          </div>
        </div>
      </ListGroup.Item>
      <Confirmation
        show={showModal}
        message={t('modeler.DeleteImage') + ` (${title ?? ''})`}
        onConfirmation={() => deleteAsset(id)}
        onClose={onCancelModal}
        onCancel={onCancelModal}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
      />
    </>
  );
}
