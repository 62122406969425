import { RowData, RowStyles } from './index';
import { LayoutComponent } from '../../../types';

export const ROW_TEMPLATE: LayoutComponent<RowData, RowStyles> = {
  uuid: 'empty',
  type: 'ROW',
  data: {
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 'none',
    height: 'fit-content',
    heightUnit: 'none',
    maxHeight: 'none',
    minHeight: '100',
    minHeightUnit: 'px',
    overflowx: 'auto',
    overflowy: 'auto',
    verticalAlign: 'stretch',
    horizontalAlign: 'start',
    flexDirection: 'row'
  },
  styles: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 12,
    paddingRight: 12,
    borderTop: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    css: ''
  }
};
