import React from 'react';
import { GridColumnSizes } from './gridManagerModal';
import styles from './styles.module.css';

interface GridColumnSizeCardColumnProps {
  sizeOption: number;
  columnSizeOptions: number;
  index: number;
}

function GridColumnSizeCardColumn({
  sizeOption,
  columnSizeOptions,
  index
}: GridColumnSizeCardColumnProps) {
  return (
    <div
      className={` col-${sizeOption} p-1 ${index + 1 === columnSizeOptions ? 'ps-1 pe-0' : null} ${
        index === 0 ? 'ps-0 pe-1' : null
      }`}
    >
      <div className={`${styles.shortcutColumn} w-100 h-100 bg-body-secondary`}></div>
    </div>
  );
}

interface GridColumnSizeCardShortcutProps {
  columnSizeOptions: GridColumnSizes;
  onChangeSize: (newSize: GridColumnSizes) => void;
}

function GridColumnSizeCardShortcut({
  columnSizeOptions,
  onChangeSize
}: GridColumnSizeCardShortcutProps) {
  return (
    <div
      className="w-100 d-flex flex-column align-items-center me-2"
      onClick={() => onChangeSize(columnSizeOptions)}
    >
      <div
        className={`${styles.columnSizeShortcutOption} w-100 border border-body rounded mb-2 p-1`}
      >
        <div className="row m-0 p-1" style={{ height: '60px' }}>
          {columnSizeOptions.map((sizeOption, index) => {
            return (
              <GridColumnSizeCardColumn
                key={'columnSize' + index + sizeOption}
                sizeOption={sizeOption}
                columnSizeOptions={columnSizeOptions.length}
                index={index}
              />
            );
          })}
        </div>
      </div>
      <label className={`${styles.shortcutColumn} text-body-tertiary`} style={{ fontSize: '12px' }}>
        {columnSizeOptions.map((sizeOption, index) => {
          return sizeOption + (index + 1 !== columnSizeOptions.length ? ' - ' : '');
        })}
      </label>
    </div>
  );
}

export default GridColumnSizeCardShortcut;
