import React, { ComponentType } from 'react';
import { ACTION_INPUTS, INPUTS_VALIDATION } from '../../../../../action_inputs';
import {
  ActionInputProps,
  FunctionActionInputType
} from '../../../../../store/types/manifestsAndInputs';

function useRenderInput(isFromSideBar?: boolean) {
  const renderInput = (i: FunctionActionInputType, actionUuid: string, noLabel = false) => {
    const Input = ACTION_INPUTS[i.type] as ComponentType<ActionInputProps>;

    return (
      <Input
        key={i.key}
        placeholder={i.placeholder ?? ''}
        label={noLabel ? '' : i.label}
        dataKey={i.key}
        actionUuid={actionUuid}
        options={i?.options ?? []}
        // TODO i.options has its own input validation, remove it overtime and replace by 'inputValidation'.
        inputValidation={INPUTS_VALIDATION[i.type]}
        isFromSideBar={isFromSideBar}
      />
    );
  };

  return { renderInput };
}

export default useRenderInput;
