import React from 'react';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { ObjectSimple } from '../../../types';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import UpdateEntityIcon from './update_entity_icon';
import { filterByPrimaryKey } from '../../../../../web_ui/function_editor/action_inputs/utils/filterByPrimaryKey';
import { t } from 'i18next';

function filterByEntityObject(
  state: FunctionEditorState,
  actionUuid: string,
  obj: ObjectSimple
): boolean {
  return !!obj?.entityUuid;
}

function filterByObject(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: string,
  objectUuid: string
): boolean {
  const action = state.actions[actionUuid];
  if (!action) return false;
  const actionObjectId = action.data?.objectId;
  return dataType === 'OBJECT' && objectUuid === actionObjectId;
}

function filterByObjectPk(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: string,
  objectUuid: string
) {
  return filterByPrimaryKey(state, actionUuid, 'objectId', dataType, objectUuid);
}

export const UPDATE_ENTITY_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.UPDATE_ENTITY,
  name: t('UpdateEntity'),
  description: 'Action to make a repo call to update an entity',
  color: 'danger',
  category: BACKEND_ACTIONS_CATEGORIES.ENTITY,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.ENTITY,
  icon: <UpdateEntityIcon />,
  shouldHaveDatabase: true,
  inlineInputs: [
    {
      label: t('UpdateActionParam0'),
      placeholder: t('AnObject') ?? 'an object',
      key: 'objectId',
      type: ACTION_INPUTS_TYPES.OBJECTS_INPUT,
      options: { filterObjs: filterByEntityObject }
    },
    {
      label: t('UpdateActionParam1'),
      placeholder: '',
      key: 'entity',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: {
        argumentTypes: [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM],
        filterByDataType: filterByObject,
        hideNodePicker: true
      }
    },
    {
      label: t('UpdateActionParam2'),
      placeholder: '',
      key: 'id',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: {
        argumentTypes: [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM],
        filterByDataType: filterByObjectPk
      }
    },
    {
      label: t('UpdateActionParam3'),
      placeholder: '',
      type: ACTION_INPUTS_TYPES.RETURN_INPUT
    }
  ],
  inputs: [
    {
      label: t('ConsiderChildren'),
      placeholder: '',
      key: 'objectChildren',
      type: ACTION_INPUTS_TYPES.CHECKBOX,
      showInEditor: false
    }
  ]
};
