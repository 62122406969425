import React from 'react';
import styles from './style.module.css';

type ElementHoverProps = {
  width: number;
  height: number;
  offsetLeft: number;
  offsetTop: number;
  componentType: string;
  offsetHeight: number;
  offsetWidth: number;
  selected: boolean;
};

export function ElementHover({
  offsetLeft,
  offsetTop,
  componentType,
  offsetHeight,
  offsetWidth,
  selected
}: ElementHoverProps) {
  const selectionBoxStyles = {
    height: offsetHeight,
    width: offsetWidth,
    left: offsetLeft,
    top: offsetTop
  };

  const hoverLinesStyles = {
    borderColor: selected ? '#0d6efd' : '#93beff',
    background: selected ? '#0d6efd' : '#93beff'
  };

  return (
    <>
      <div className={`${styles.selectionBox}`} style={selectionBoxStyles}>
        <div className={`${styles.Hover} ${styles.HoverTop}`} style={hoverLinesStyles}></div>
        <div className={`${styles.Hover} ${styles.HoverLeft}`} style={hoverLinesStyles}></div>
        <div className={`${styles.Hover} ${styles.HoverRight}`} style={hoverLinesStyles}></div>
        <div className={`${styles.Hover} ${styles.HoverBottom}`} style={hoverLinesStyles}></div>
      </div>
    </>
  );
}
