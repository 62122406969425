import { InterfaceStudioState } from 'modules/designer/studio/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ControlProps } from '..';
import { ITableContextMenu } from 'modules/designer/studio/exocode_components/data_table';
import HelpPopover from '../components/Popover';
import { Button, Form, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import HelpIcon from '../components/HelpIcon';
import { IconList, IconPicker } from 'packages/react-fa-icon-picker';
import Icon from 'web_ui/icon';
import { InputGroup } from 'react-bootstrap';
import ErrorIcon from '../components/ErrorIcon';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';

interface ContextMenu {
  title: string;
  icon: string;
  function: string;
  useSelectedRows?: boolean;
}

function ContextMenuControl(props: ControlProps) {
  const [actionFunction, setActionFunction] = useState('');
  const [actionTitle, setActionTitle] = useState('');
  const [actionIcon, setActionIcon] = useState('');
  const [actionUseSelectedRows, setActionUseSelectedRows] = useState(false);
  const UIFunctions = useSelector((state: InterfaceStudioState) => state.functions);
  const { t } = useTranslation();

  function handleAddAction() {
    if (actionFunction == null) return;

    const { contextMenu } = props.value;

    let newAction: ContextMenu = {
      title: actionTitle,
      icon: actionIcon,
      function: actionFunction
    };
    if (actionUseSelectedRows) newAction = { ...newAction, useSelectedRows: actionUseSelectedRows };

    const newActions = contextMenu ? [...contextMenu, newAction] : [newAction];

    props.onChange?.(newActions, 'contextMenu');
    formReset();
  }

  function handleChangeAction(
    key: keyof ITableContextMenu,
    value: string | boolean,
    action: ITableContextMenu,
    index: number
  ) {
    const updatedAction = { ...action, [key]: value };

    const newActions = [
      ...props.value.contextMenu.slice(0, index),
      updatedAction,
      ...props.value.contextMenu.slice(index + 1)
    ];

    props.onChange && props.onChange(newActions, 'contextMenu');
  }

  function handleDeleteAction(index: number) {
    const newActions = [
      ...props.value.contextMenu.slice(0, index),
      ...props.value.contextMenu.slice(index + 1)
    ];
    props.onChange && props.onChange(newActions, 'contextMenu');
  }

  function formReset() {
    setActionFunction('');
    setActionTitle('');
    setActionIcon('');
    setActionUseSelectedRows(false);
  }

  return (
    <div className="mb-3 pb-4 border-bottom text-body-secondary">
      <div className="mb-2 d-flex justify-content-between align-items-center">
        <div>
          <label className="position-relative mb-1 text-body">
            {t('designer.right_side.controls.ContextMenuTitle')}
          </label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.controls.ContextMenuTitle') ||
                'designer.right_side.controls.ContextMenuTitle',
              description:
                t('designer.right_side.controls.ContextMenuDescription') ||
                'designer.right_side.controls.ContextMenuDescription',
              note: [
                t('designer.right_side.controls.ContextMenuNote01'),
                t('designer.right_side.controls.ContextMenuNote02')
              ]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </div>
      </div>

      <div></div>

      <ListGroup className="d-flex">
        <OverlayTrigger
          trigger="click"
          placement="left"
          rootClose
          overlay={
            <Popover id="popover-positioned-left">
              <Popover.Body>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {t('designer.right_side.controls.TitleAction')}
                  </InputGroup.Text>
                  <Form.Control
                    value={actionTitle}
                    onChange={(e) => setActionTitle(e.target.value)}
                  />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {t('designer.right_side.controls.ActionIcon')}
                  </InputGroup.Text>
                  <div
                    className="border d-flex p-1 rounded align-items-center "
                    style={{ width: 'fit-content' }}
                  >
                    <IconPicker
                      value={(actionIcon as IconList) || 'FaRegCaretSquareDown'}
                      onChange={(e) => setActionIcon(e)}
                      containerStyles={{ left: '-210px', display: 'flex', top: '-260px' }}
                      buttonIconStyles={{
                        color: 'var(--bs-secondary-color)!important',
                        border: '0',
                        fontSize: '1.25rem',
                        display: 'flex'
                      }}
                      buttonStyles={{
                        border: 0,
                        padding: '0',
                        width: '1.5rem',
                        height: '1.5rem',
                        minHeight: '0',
                        fontSize: '1rem'
                      }}
                      searchInputStyles={{
                        display: 'flex'
                      }}
                    />
                    <span className="ms-2">{actionIcon}</span>
                  </div>
                </InputGroup>

                <InputGroup size="sm" className="mt-3 mb-3">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {t('designer.right_side.controls.ActionFunction')}
                  </InputGroup.Text>
                  <Form.Select
                    value={actionFunction}
                    onChange={async (e) => setActionFunction(e.target.value)}
                  >
                    <option value="">---</option>

                    {UIFunctions &&
                      Object.keys(UIFunctions)?.map((functionKey) => (
                        <option
                          key={UIFunctions[functionKey].name}
                          value={UIFunctions[functionKey].uuid}
                        >
                          {UIFunctions[functionKey].name}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>

                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={t('designer.right_side.controls.ContextMenuUseSelectedRows')}
                  checked={actionUseSelectedRows}
                  onChange={(e) => setActionUseSelectedRows(!actionUseSelectedRows)}
                />

                <div>
                  <Button
                    onClick={handleAddAction}
                    disabled={(actionTitle === '' && actionIcon === '') || actionFunction === ''}
                    className="mt-3"
                  >
                    {t('designer.right_side.controls.AddAction')}
                  </Button>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <ListGroup.Item action variant="body-secondary" className="text-body-secondary">
            <Icon iconName="plus"></Icon>
            <label className="ms-2">New item</label>
          </ListGroup.Item>
        </OverlayTrigger>

        {props.value &&
          props.value.contextMenu &&
          props.value.contextMenu.map((action: ITableContextMenu, index: number) => {
            return (
              <OverlayTrigger
                key={index}
                trigger="click"
                placement="left"
                rootClose
                overlay={
                  <Popover id="popover-positioned-left">
                    <Popover.Body>
                      <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          {t('designer.right_side.controls.TitleAction')}
                        </InputGroup.Text>
                        <Form.Control
                          value={action.title}
                          onChange={(e) =>
                            handleChangeAction('title', e.target.value, action, index)
                          }
                        />
                      </InputGroup>

                      <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          {t('designer.right_side.controls.ActionIcon')}
                        </InputGroup.Text>
                        <div
                          className="border d-flex p-1 rounded align-items-center "
                          style={{ width: 'fit-content' }}
                        >
                          <IconPicker
                            value={(action.icon as IconList) || 'FaRegCaretSquareDown'}
                            onChange={(e) => handleChangeAction('icon', e, action, index)}
                            containerStyles={{ left: '-210px', display: 'flex', top: '-260px' }}
                            buttonIconStyles={{
                              color: 'var(--bs-secondary-color)!important',
                              border: '0',
                              fontSize: '1.25rem',
                              display: 'flex'
                            }}
                            buttonStyles={{
                              border: 0,
                              padding: '0',
                              width: '1.5rem',
                              height: '1.5rem',
                              minHeight: '0',
                              fontSize: '1rem'
                            }}
                            searchInputStyles={{
                              display: 'flex'
                            }}
                          />
                          <span className="ms-2">{action.icon}</span>
                        </div>
                      </InputGroup>

                      <InputGroup size="sm" className="mt-3 mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          {t('designer.right_side.controls.ActionFunction')}
                        </InputGroup.Text>
                        <Form.Select
                          value={action.function}
                          onChange={async (e) =>
                            handleChangeAction('function', e.target.value, action, index)
                          }
                        >
                          <option value="">---</option>
                          {action.function && !UIFunctions[action.function] && (
                            <option className="text-danger" value={action.function}>
                              {t('designer.right_side.controls.errors.NotFound')}
                            </option>
                          )}
                          {UIFunctions &&
                            Object.keys(UIFunctions)?.map((functionKey) => (
                              <option
                                key={UIFunctions[functionKey].name}
                                value={UIFunctions[functionKey].uuid}
                              >
                                {UIFunctions[functionKey].name}
                              </option>
                            ))}
                        </Form.Select>
                      </InputGroup>

                      <Form.Check
                        inline
                        type="switch"
                        id="use-selected-rows-switch"
                        className="me-0"
                        checked={action.useSelectedRows && props.value.selectable}
                        disabled={!props.value.selectable}
                        onChange={(e) =>
                          handleChangeAction(
                            'useSelectedRows',
                            !action.useSelectedRows,
                            action,
                            index
                          )
                        }
                      />
                      <span
                        className={!props.value.selectable ? 'text-body-tertiary' : 'text-body'}
                      >
                        {t('designer.right_side.controls.ContextMenuUseSelectedRows')}
                        <HelpPopover
                          helpBoxProps={{
                            title:
                              t('designer.right_side.controls.ContextMenuUseSelectRowsTitle') ||
                              'designer.right_side.controls.ContextMenuUseSelectRowsTitle',
                            description:
                              t(
                                'designer.right_side.controls.ContextMenuUseSelectRowsDescription'
                              ) ||
                              'designer.right_side.controls.ContextMenuUseSelectRowsDescription'
                          }}
                          placement="top"
                        >
                          <HelpIcon />
                        </HelpPopover>
                      </span>
                    </Popover.Body>
                  </Popover>
                }
              >
                <ListGroup.Item action key={index} className="d-flex justify-content-between">
                  <label className="text-truncate">
                    <MissingMessage type={MissingMessageType.FUNCTION} uuid={action.function} />
                    {action.title}
                  </label>
                  <span onClick={(ev) => handleDeleteAction(index)}>
                    <Icon iconName="trash"></Icon>
                  </span>
                </ListGroup.Item>
              </OverlayTrigger>
            );
          })}
      </ListGroup>
    </div>
  );
}

export default ContextMenuControl;
