import { ReturnType } from '../../store/types/functions';
import useFetchEnums from './useFetchEnums';
import useFetchObjects from './useFetchObjects';

function useFetchTypes(rawTypes: string[], framework: string) {
  const enums = useFetchEnums();
  const objects = useFetchObjects(framework === 'spring', framework === 'react');

  const processedTypes: ReturnType[] = [];
  rawTypes.forEach((t) => {
    if (t.toUpperCase() !== 'ENUM' && t.toUpperCase() !== 'OBJECT')
      processedTypes.push({ type: t.toUpperCase(), list: false });
  });
  objects.forEach((o) =>
    processedTypes.push({ type: 'OBJECT', objectUuid: o.uuid, list: false, name: o.name })
  );
  enums.forEach((e) =>
    processedTypes.push({
      type: 'ENUM',
      enumUuid: e.uuid,
      list: false,
      name: e.content.data.name
    })
  );
  processedTypes.unshift({ type: 'VOID', list: false });
  return { fetchedTypes: processedTypes, objects, enums };
}

export default useFetchTypes;
