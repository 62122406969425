import { Action } from 'redux';
import { Enum, EnumFrame, EnumUUID } from '../../../types';
import { addEnumColumnAction, deleteEnumColumnAction } from './enum_column';

export const ADD_ENUM = 'ADD_ENUM';
export const ADD_ENUMCOLUMN = 'ADD_ENUMCOLUMN';
export const DELETE_ENUM = 'DELETE_ENUM';
export const DELETE_ENUMCOLUMN = 'DELETE_ENUMCOLUMN';
export const SET_ENUM_POSITION = 'SET_ENUM_POSITION';
export const CHANGE_ENUM_PROPERTY = 'CHANGE_ENUM_PROPERTY';
export const CHANGE_ENUM_NAME = 'CHANGE_ENUM_NAME';
export const CHANGE_ENUM_FOLDER = 'CHANGE_ENUM_FOLDER';
export const SET_ENUMS = 'SET_ENUMS';

export type EnumsActions =
  | addEnumAction
  | deleteEnumFrameAction
  | setEnumPositionAction
  | changeEnumNameAction
  | changeEnumFolderAction
  | changeEnumPropertyAction
  | setEnumsAction
  | addEnumColumnAction
  | deleteEnumColumnAction;

export interface addEnumAction extends Action {
  type: 'ADD_ENUM';
  payload: EnumFrame;
}

export interface deleteEnumFrameAction extends Action {
  type: 'DELETE_ENUM';
  payload: {
    uuid: EnumUUID;
  };
}

export interface setEnumPositionAction extends Action {
  type: 'SET_ENUM_POSITION';
  payload: {
    uuid: EnumUUID;
    posX: number;
    posY: number;
  };
}

export interface changeEnumNameAction extends Action {
  type: 'CHANGE_ENUM_NAME';
  payload: {
    uuid: EnumUUID;
    name: string;
  };
}

export interface changeEnumFolderAction extends Action {
  type: 'CHANGE_ENUM_FOLDER';
  payload: {
    id: EnumUUID;
    folderId: string;
  };
}

export interface changeEnumPropertyAction extends Action {
  type: 'CHANGE_ENUM_PROPERTY';
  payload: {
    uuid: EnumUUID;
    key: string;
    value: string;
  };
}

export interface setEnumsAction extends Action {
  type: 'SET_ENUMS';
  payload: {
    enums: EnumFrame[];
  };
}

export const addEnum = (enumFrame: EnumFrame) => ({
  type: ADD_ENUM,
  payload: enumFrame
});

export const deleteEnumFrame = (uuid: EnumUUID) => ({
  type: DELETE_ENUM,
  payload: { uuid: uuid }
});

export const setEnumPosition = (uuid: EnumUUID, posX: number, posY: number) => ({
  type: SET_ENUM_POSITION,
  payload: { uuid: uuid, posX: posX, posY: posY }
});

export const changeEnumName = (uuid: EnumUUID, name: string) => ({
  type: CHANGE_ENUM_NAME,
  payload: { uuid: uuid, name: name }
});

export const changeEnumFolder = (id: EnumUUID, folderId: string) => ({
  type: CHANGE_ENUM_FOLDER,
  payload: { id: id, folderId: folderId }
});

export const changeEnumProperty = (uuid: EnumUUID, key: string, value: string) => ({
  type: CHANGE_ENUM_PROPERTY,
  payload: { uuid: uuid, key: key, value: value }
});

export const setEnums = (enums: EnumFrame[]) => ({
  type: SET_ENUMS,
  payload: { enums }
});
