import React from 'react';
import { Pagination } from 'react-bootstrap';
import useLanguagesPagination, { DOTS } from '../useLanguagesPagination';
import { LANGUAGES_PAGE_SIZE } from '../../index';

type PagesNavigationProps = {
  translationsCount: number;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
};

function PagesNavigation({
  selectedPage,
  translationsCount,
  setSelectedPage
}: PagesNavigationProps) {
  const pagesToDisplay = useLanguagesPagination(
    translationsCount,
    LANGUAGES_PAGE_SIZE,
    selectedPage
  );

  return (
    <Pagination>
      <Pagination.Prev
        disabled={selectedPage === 1}
        onClick={() => setSelectedPage(selectedPage - 1)}
      />
      {pagesToDisplay &&
        pagesToDisplay.map((page) => {
          return page === DOTS ? (
            <Pagination.Item key={page}>...</Pagination.Item>
          ) : (
            <Pagination.Item
              key={page}
              active={selectedPage === page}
              onClick={() => setSelectedPage(page)}
            >
              {page}
            </Pagination.Item>
          );
        })}
      <Pagination.Next
        disabled={selectedPage === translationsCount}
        onClick={() => setSelectedPage(selectedPage + 1)}
      />
    </Pagination>
  );
}

export default PagesNavigation;
