import { StructToSaveFunctionCrud } from 'web_ui/function_editor/store/types/functions';
import { EndpointsRepository } from '../repos';
import {
  Action,
  Endpoint,
  EndpointExtended,
  EndpointRequest,
  EndpointResponse,
  EndpointResponseSimple,
  EndpointSimple,
  ParameterEndpoint
} from '../types';
import { StructToSaveEndpointCrud } from 'web_ui/function_editor/store/types/endpoint';

export class EndpointsServ {
  async createEnpointAutomationCrud(
    dataDTO: StructToSaveEndpointCrud,
    moduleId: string,
    entityId: string
  ): Promise<StructToSaveEndpointCrud | undefined> {
    return await EndpointsRepository.createEnpointAutomationCrud(dataDTO, moduleId, entityId);
  }
  async getEndpoint(endpointId: string): Promise<EndpointExtended> {
    return await EndpointsRepository.getEndpoint(endpointId);
  }

  async getEndpointsByModule(moduleId: string, method?: string): Promise<Endpoint[]> {
    return await EndpointsRepository.getEndpointsByModule(moduleId, method);
  }

  async getEndpointsByController(controllerId: string, moduleId: string): Promise<Endpoint[]> {
    return await EndpointsRepository.getEndpointsByControllerId(controllerId, moduleId);
  }

  async getEndpointAction(endpointId: string): Promise<Action<any>> {
    return await EndpointsRepository.getEndpointAction(endpointId);
  }

  async getParameters(endpointId: string): Promise<ParameterEndpoint[]> {
    return await EndpointsRepository.getParameters(endpointId);
  }

  async deleteEndpoint(endpointId: string) {
    await EndpointsRepository.deleteEndpoint(endpointId);
  }

  async deleteParameter(endpointId: string, parameterId: string) {
    await EndpointsRepository.deleteParameter(endpointId, parameterId);
  }

  async createEndpoint(moduleId: string, endpoint: EndpointSimple): Promise<Endpoint> {
    return await EndpointsRepository.createEndpoint(moduleId, endpoint);
  }

  async createParameter(
    endpointId: string,
    parameter: ParameterEndpoint
  ): Promise<ParameterEndpoint> {
    return await EndpointsRepository.createParameter(endpointId, parameter);
  }

  async updateEndpoint(endpointId: string, endpoint: Endpoint): Promise<Endpoint> {
    return await EndpointsRepository.updateEndpoint(endpointId, endpoint);
  }

  async updateEndpointAction(endpointId: string, action: Action<any>): Promise<Action<any>> {
    return await EndpointsRepository.updateEndpointAction(endpointId, action);
  }

  async updateParameter(
    endpointId: string,
    parameterId: string,
    parameter: ParameterEndpoint
  ): Promise<ParameterEndpoint> {
    return await EndpointsRepository.updateParameter(endpointId, parameterId, parameter);
  }

  async createEndpointResponse(
    endpointId: string,
    response: EndpointResponse
  ): Promise<EndpointResponse> {
    return await EndpointsRepository.createEndpointResponse(endpointId, response);
  }

  async getEndpointResponses(endpointId: string): Promise<EndpointResponseSimple[]> {
    return await EndpointsRepository.getEndpointResponses(endpointId);
  }

  async getEndpointResponse(endpointId: string, status: string): Promise<EndpointResponse> {
    return await EndpointsRepository.getEndpointResponse(endpointId, status);
  }

  async updateEndpointResponse(
    endpointId: string,
    status: string,
    response: EndpointResponse
  ): Promise<EndpointResponse> {
    return await EndpointsRepository.updateEndpointResponse(endpointId, status, response);
  }

  async deleteEndpointResponse(endpointId: string, status: string) {
    return await EndpointsRepository.deleteEndpointResponse(endpointId, status);
  }

  async getEndpointRequest(endpointId: string): Promise<EndpointRequest> {
    return await EndpointsRepository.getEndpointRequest(endpointId);
  }
}
