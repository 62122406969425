import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from '../../../../designer/types';
import { t } from 'i18next';

export const INPUT_MANIFEST: ComponentManifest = {
  type: 'INPUT',
  name: 'Input',
  descriptionId: 'InputDescription',
  description:
    'Inputs are common form controls that allow the user to enter text values using their keyboard.',
  icon: 'fa-solid fa-font',
  group: ComponentGroups['INPUT'],
  previewHeight: 70,
  previewWidth: 150,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Label',
      key: 'label',
      tooltip: 'designer.right_side.controls.components.input.text'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Placeholder',
      key: 'placeholder',
      tooltip: 'designer.right_side.controls.components.input.textTwo'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.input.VisibleVariable'
    },
    {
      controlType: ControlsTypes.VARIABLEREADONLY,
      controlLabel: 'ReadonlyVariable',
      key: 'readonlyVar',
      tooltip: 'designer.right_side.controls.components.input.readonlyVar'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.input.tooltip'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Required',
    //   key: 'required'
    // },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Readonly',
    //   key: 'readonly'
    // },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Disabled',
    //   key: 'disabled'
    // },
    // {
    //   controlType: ControlsTypes.VARIABLE,
    //   controlLabel: 'DisabledVariable',
    //   key: 'disabledVar'
    // },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.input.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.input.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      tooltip: 'designer.right_side.controls.components.input.themePicker'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Bg color'),
      key: 'bgColor',
      tooltip: 'designer.right_side.controls.components.input.themePickerBg'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('placeholderColor'),
      key: 'placeholderColor',
      tooltip: 'designer.right_side.controls.components.input.placeholder'
    },
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.input.number'
    },
    {
      controlType: ControlsTypes.TEXTALIGN,
      controlLabel: 'Text Align',
      key: 'textAlign',
      tooltip: 'designer.right_side.controls.components.input.textAlign'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CHANGE']
};
