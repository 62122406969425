import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { LayoutService, TemplatesService } from '../../../services';
import { useTranslation } from 'react-i18next';
import { VIEWS_TYPES } from '../../frames';
import { Template } from 'modules/designer/types';
import { TemplatesList } from './templates_list';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';
import data from './reserved_words.json';

type CreateLayoutDialogProps = {
  show: boolean;
  onClose: () => void;
  onCreate: (uuid: string) => void;
  parentUuid: string;
};

function CreateLayoutDialog(props: CreateLayoutDialogProps) {
  const { module_id, app_id } = useParams();
  const [layoutName, setLayoutName] = useState('');
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState<Template[]>();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  const fetchTemplates = useCallback(async (app_id) => {
    await TemplatesService.getTemplates('createPage', VIEWS_TYPES.LAYOUT, app_id).then(
      (fetchedTemplates) => {
        setTemplates(fetchedTemplates);
        if (fetchedTemplates.length) {
          setSelectedTemplate(fetchedTemplates[0].uuid);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!app_id || !props.show) return;
    fetchTemplates(app_id);
  }, [app_id, fetchTemplates, props.show]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!module_id) return;

    if (data.reservedWords.includes(layoutName.toLowerCase())) {
      showErrorPopup(t('designer.layout.errorReservedWord'));
      return;
    }

    await LayoutService.createLayout(
      module_id,
      layoutName,
      selectedTemplate ?? '',
      props.parentUuid
    ).then((Layout) => {
      props.onCreate(Layout.uuid);
      onClose();
    });
  };

  const onClose = () => {
    props.onClose();
    setLayoutName('');
    setSelectedTemplate('');
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  return (
    <>
      {alertMessage && (
        <Modal
          show={alertMessage ? true : false}
          style={{ background: 'transparent', width: 'auto' }}
          centered={false}
        >
          <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
        </Modal>
      )}
      <Modal centered show={props.show} onHide={onClose}>
        <form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t('designer.layout.CreateLayout')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form.Group className="mb-3">
                <Form.Label>{t('Name')}</Form.Label>
                <Form.Control
                  type="text"
                  id="formName"
                  onChange={(e) => setLayoutName(e.target.value)}
                  value={layoutName}
                  required={!selectedTemplate}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t('designer.page.TemplateSelect')}</Form.Label>
                <TemplatesList
                  templates={templates ?? []}
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={(template: string) => setSelectedTemplate(template)}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="saveButton"
              disabled={layoutName == null || layoutName === ''}
              type="submit"
            >
              {t('designer.layout.CreateLayout')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default CreateLayoutDialog;
