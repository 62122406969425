import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import { messageCallback } from 'routes/organizations';
import { OrganizationService } from 'modules/organization/services';
import {
  AddOrganization,
  AdminRole,
  ManagerRole,
  DevRole,
  BillingRole
} from 'modules/organization/types';
import { InvitationService } from 'modules/invitation/service';
import { AddInvite } from 'modules/invitation/types';
import { useTranslation } from 'react-i18next';
import InvitesList from 'modules/organization/components/invites_list';
import { PopupAlert } from 'web_ui/popup_alert';

import styles from './style.module.css';

export type CreateOrganizationModalProps = {
  showModal: boolean;
  onCloseRequest: (message: messageCallback) => void;
};

function CreateOrganizationModal({ showModal, onCloseRequest }: CreateOrganizationModalProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState<AddInvite[]>([]);

  const { t } = useTranslation();

  const about_organization: string = t('organizations.new_organization.AboutOrganization');
  const organization: string = t('organizations.new_organization.organization');

  const buildQuery = (): AddOrganization => {
    const newOrg: AddOrganization = {
      name: name,
      description: description
    };
    return newOrg;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);
      const newOrg = buildQuery();
      const responseOrg = await OrganizationService.createOrg(newOrg);
      let okInvites = true;
      if (invites.length > 0) {
        okInvites = await submitInvites(responseOrg.id);
      }
      onClose(okInvites ? 'success' : 'inviteError');
    } catch (error) {
      setShowError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const submitInvites = async (orgId: number) => {
    try {
      await InvitationService.createInviteOrg(orgId, invites);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const onClose = (message: messageCallback) => {
    setName('');
    setDescription('');
    setInvites([]);
    onCloseRequest(message);
  };

  useEffect(() => {
    setShowError(false);
  }, []);

  const closeErroPopUp = () => {
    setShowError(false);
  };

  return (
    <>
      {showError && (
        <Modal show={showError ? true : false} className={styles.containerModal} centered={false}>
          <PopupAlert
            i18nKey={
              t('organizations.new_organization.errorMessage') ??
              'organizations.new_organization.errorMessage'
            }
            onClose={closeErroPopUp}
            variant={'danger'}
          />
        </Modal>
      )}
      <Modal
        centered
        show={showModal}
        onHide={() => onClose('nothing')}
        backdrop={'static'}
        size={'lg'}
        style={{ height: '95vh' }}
      >
        <form onSubmit={onSubmit} id="formModal">
          <Modal.Header closeButton className={`bg-body ${styles.newOrgModalHeader}`}>
            <Modal.Title className={`text-body-emphasis ${styles.newOrgModalTitle}`}>
              <strong>{t('organizations.new_organization.createOrganization')}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-body-tertiary" id="bodyModal">
            <Container>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Name')}</Form.Label>
                    <Form.Control
                      type="text"
                      id="formName"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={organization}
                      value={name}
                      disabled={isLoading}
                      maxLength={255}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>{t('Description')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  id="formDescription"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  placeholder={about_organization}
                  maxLength={255}
                  disabled={isLoading}
                />
              </Form.Group>
              {/* Invites */}
              <InvitesList
                invites={invites}
                setInvites={setInvites}
                listRoles={[AdminRole, ManagerRole, DevRole, BillingRole]}
              />
              <div className={styles.dialogSaveButton}>
                <Button id="saveButton" type="submit" disabled={isLoading}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={
                      isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`
                    }
                  />
                  {t('organizations.new_organization.Create')}
                </Button>
              </div>
            </Container>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
}

export default CreateOrganizationModal;
