import * as React from 'react';

function FourColumnsIcon(props: any) {
  return (
    <svg
      width="99"
      height="100"
      viewBox="0 0 99 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.375 26.8388C14.6136 26.8388 12.375 29.0774 12.375 31.8388V66.8388C12.375 69.6003 14.6136 71.8388 17.375 71.8388L23.5 71.8389C26.2614 71.8389 28.5 69.6003 28.5 66.8389V31.8389C28.5 29.0774 26.2614 26.8389 23.5 26.8389L17.375 26.8388Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 71.8388C14.6136 71.8388 12.375 69.6003 12.375 66.8388V31.8388C12.375 29.0774 14.6136 26.8388 17.375 26.8388L23.5 26.8389C26.2614 26.8389 28.5 29.0774 28.5 31.8389V66.8389C28.5 69.6003 26.2614 71.8389 23.5 71.8389L17.375 71.8388Z"
        fill="#79828A"
      />
      <path
        d="M17.375 26.8389C14.6136 26.8389 12.375 29.0774 12.375 31.8389V66.8389C12.375 69.6003 14.6136 71.8389 17.375 71.8389L23.5 71.8389C26.2614 71.8389 28.5 69.6003 28.5 66.8389V31.8389C28.5 29.0775 26.2614 26.8389 23.5 26.8389L17.375 26.8389Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 71.8389C14.6136 71.8389 12.375 69.6003 12.375 66.8389V31.8389C12.375 29.0774 14.6136 26.8389 17.375 26.8389L23.5 26.8389C26.2614 26.8389 28.5 29.0775 28.5 31.8389V66.8389C28.5 69.6003 26.2614 71.8389 23.5 71.8389L17.375 71.8389Z"
        fill="#79828A"
      />
      <path
        d="M36.875 26.8389C34.1136 26.8389 31.875 29.0775 31.875 31.8389V66.8389C31.875 69.6003 34.1136 71.8389 36.875 71.8389L43 71.8389C45.7614 71.8389 48 69.6004 48 66.8389V31.8389C48 29.0775 45.7614 26.8389 43 26.8389L36.875 26.8389Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.875 71.8389C34.1136 71.8389 31.875 69.6003 31.875 66.8389V31.8389C31.875 29.0775 34.1136 26.8389 36.875 26.8389L43 26.8389C45.7614 26.8389 48 29.0775 48 31.8389V66.8389C48 69.6004 45.7614 71.8389 43 71.8389L36.875 71.8389Z"
        fill="#79828A"
      />
      <path
        d="M36.875 26.8389C34.1136 26.8389 31.875 29.0775 31.875 31.8389V66.8389C31.875 69.6003 34.1136 71.8389 36.875 71.8389L43 71.8389C45.7614 71.8389 48 69.6004 48 66.8389V31.8389C48 29.0775 45.7614 26.8389 43 26.8389L36.875 26.8389Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.875 71.8389C34.1136 71.8389 31.875 69.6003 31.875 66.8389V31.8389C31.875 29.0775 34.1136 26.8389 36.875 26.8389L43 26.8389C45.7614 26.8389 48 29.0775 48 31.8389V66.8389C48 69.6004 45.7614 71.8389 43 71.8389L36.875 71.8389Z"
        fill="#79828A"
      />
      <path
        d="M56.375 26.8389C53.6136 26.8389 51.375 29.0774 51.375 31.8389V66.8389C51.375 69.6003 53.6136 71.8389 56.375 71.8389L62.5 71.8389C65.2614 71.8389 67.5 69.6003 67.5 66.8389V31.8389C67.5 29.0775 65.2614 26.8389 62.5 26.8389L56.375 26.8389Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.375 71.8389C53.6136 71.8389 51.375 69.6003 51.375 66.8389V31.8389C51.375 29.0774 53.6136 26.8389 56.375 26.8389L62.5 26.8389C65.2614 26.8389 67.5 29.0775 67.5 31.8389V66.8389C67.5 69.6003 65.2614 71.8389 62.5 71.8389L56.375 71.8389Z"
        fill="#79828A"
      />
      <path
        d="M56.375 26.8389C53.6136 26.8389 51.375 29.0774 51.375 31.8389V66.8389C51.375 69.6003 53.6136 71.8389 56.375 71.8389L62.5 71.8389C65.2614 71.8389 67.5 69.6003 67.5 66.8389V31.8389C67.5 29.0775 65.2614 26.8389 62.5 26.8389L56.375 26.8389Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.375 71.8389C53.6136 71.8389 51.375 69.6003 51.375 66.8389V31.8389C51.375 29.0774 53.6136 26.8389 56.375 26.8389L62.5 26.8389C65.2614 26.8389 67.5 29.0775 67.5 31.8389V66.8389C67.5 69.6003 65.2614 71.8389 62.5 71.8389L56.375 71.8389Z"
        fill="#79828A"
      />
      <path
        d="M75.875 26.8389C73.1136 26.8389 70.875 29.0774 70.875 31.8389V66.8389C70.875 69.6003 73.1136 71.8389 75.875 71.8389L82 71.8389C84.7614 71.8389 87 69.6003 87 66.8389V31.8389C87 29.0775 84.7614 26.8389 82 26.8389L75.875 26.8389Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.875 71.8389C73.1136 71.8389 70.875 69.6003 70.875 66.8389V31.8389C70.875 29.0774 73.1136 26.8389 75.875 26.8389L82 26.8389C84.7614 26.8389 87 29.0775 87 31.8389V66.8389C87 69.6003 84.7614 71.8389 82 71.8389L75.875 71.8389Z"
        fill="#79828A"
      />
      <path
        d="M75.875 26.8389C73.1136 26.8389 70.875 29.0774 70.875 31.8389V66.8389C70.875 69.6003 73.1136 71.8389 75.875 71.8389L82 71.8389C84.7614 71.8389 87 69.6003 87 66.8389V31.8389C87 29.0775 84.7614 26.8389 82 26.8389L75.875 26.8389Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.875 71.8389C73.1136 71.8389 70.875 69.6003 70.875 66.8389V31.8389C70.875 29.0774 73.1136 26.8389 75.875 26.8389L82 26.8389C84.7614 26.8389 87 29.0775 87 31.8389V66.8389C87 69.6003 84.7614 71.8389 82 71.8389L75.875 71.8389Z"
        fill="#79828A"
      />
    </svg>
  );
}

export default FourColumnsIcon;
