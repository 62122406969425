import produce from 'immer';
import { initialState, StudioState } from '../index';
import {
  SET_SELECTED_FRAME,
  SET_CURRENT_PAGE,
  SetCurrentPageAction,
  StudioActions,
  SET_SELECTED_CONNECTION,
  SetSelectedFrameAction,
  setSelectedConnectionAction,
  SET_ERROR_MESSAGE,
  SetErrorMessageAction,
  SetCreatingRelationshipAction,
  SET_CREATING_RELATIONSHIP
} from '../actions/studio';

export const studioReducer = (
  state: StudioState = initialState.studio,
  action: StudioActions
): StudioState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_SELECTED_FRAME:
        return doSetSelectedFrame(draft, action);
      case SET_SELECTED_CONNECTION:
        return doSetSelectedConnection(draft, action);
      case SET_CURRENT_PAGE:
        return doSetCurrentPage(draft, action);
      case SET_ERROR_MESSAGE:
        return doSetErrorMessage(draft, action);
      case SET_CREATING_RELATIONSHIP:
        return doSetCreatingRelationshipAction(draft, action);
      default:
        return state;
    }
  });
};

function doSetSelectedFrame(state: StudioState, action: SetSelectedFrameAction): StudioState {
  state.selectedFrame = action.payload;
  return state;
}

function doSetSelectedConnection(
  state: StudioState,
  action: setSelectedConnectionAction
): StudioState {
  state.selectedConnector = action.payload;
  return state;
}

function doSetCurrentPage(state: StudioState, action: SetCurrentPageAction): StudioState {
  state.module_id = action.payload.module_id;
  state.schema_id = action.payload.page_id;
  return state;
}

function doSetErrorMessage(state: StudioState, action: SetErrorMessageAction): StudioState {
  state.errorMessage = action.payload;
  return state;
}

function doSetCreatingRelationshipAction(
  state: StudioState,
  action: SetCreatingRelationshipAction
): StudioState {
  state.isCreatingRelationship = action.payload;
  return state;
}
