import React, { useEffect, useState } from 'react';
import {
  UserProfile,
  UserWithRole,
  UpdateUserApp,
  Teams,
  MembersAndTeams
} from 'modules/auth/types/auth_types';
import { OrganizationService, TeamServ } from 'modules/organization/services';
import { ProjectsService } from 'modules/project/services';
import { ApiAppInfo } from 'modules/project/types';
import { Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Member } from '../member';
import styles from './styles.module.css';
import { Team } from '../team';

export type Props = {
  showModal: boolean;
  onCloseModal: () => void;
  currMembersAndTeams: MembersAndTeams | undefined;
  reloadRootList: () => void;
};

export const AddTeamModal = ({
  showModal,
  onCloseModal,
  currMembersAndTeams,
  reloadRootList
}: Props) => {
  const { app_id } = useParams();
  const [teamsToAdd, setTeamsToAdd] = useState<number[]>([]);
  const [currProjectState, setCurrProjectState] = useState<ApiAppInfo>();
  const [currAllTeams, setCurrAllTeams] = useState<Teams[]>([]);

  useEffect(() => {
    getAllOrgTeams();
  }, []);

  const getAllOrgTeams = async () => {
    if (!app_id) return;
    const currProject = await ProjectsService.getProjectById(app_id);
    const teamsFromOrg = await TeamServ.getAllTeams(currProject.owner_id);
    setCurrProjectState(currProject);
    if (teamsFromOrg) {
      setCurrAllTeams(teamsFromOrg);
    }
  };

  const addingMemberToAdd = (teamId: number) => {
    setTeamsToAdd((val) => [...val, teamId]);
  };

  const saving = async () => {
    if (!app_id) return;

    const dataToSend: UpdateUserApp = {
      usersToAdd: [],
      usersToRemove: [],
      teamsToAdd: teamsToAdd,
      teamsToRemove: []
    };

    const dataRetrieved = await ProjectsService.updateMembersApp(app_id, dataToSend);
    if (dataRetrieved) reloadRootList();
  };

  return (
    <Modal
      centered
      show={showModal}
      onHide={() => {
        setTeamsToAdd([]);
        onCloseModal();
      }}
      backdrop={'static'}
      size={'lg'}
      className={styles.containerModal}
    >
      <form>
        <Modal.Header closeButton className="bg-body">
          <Modal.Title className={`text-body-emphasis ${styles.newAppModalTitle}`}>
            <strong>Add Team</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-body">
          <div style={{ marginTop: 10, height: 400, overflowY: 'auto' }}>
            {/* Members */}{' '}
            {currAllTeams &&
              currAllTeams
                .filter((item) => {
                  if (currMembersAndTeams) {
                    return !currMembersAndTeams.teams.find(
                      (itemIterator) => itemIterator.id === item.id
                    );
                  }
                  return true;
                })
                .map((item, index) => (
                  <Team
                    teamsToAdd={teamsToAdd}
                    key={index}
                    team={item}
                    addingTeamToAdd={addingMemberToAdd}
                    isFromAddTeam={true}
                    owner_id={
                      currProjectState && currProjectState.owner_id && currProjectState.owner_id
                    }
                  />
                ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => saving()} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
