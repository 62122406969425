import React, { CSSProperties, forwardRef, HTMLAttributes, MouseEvent } from 'react';
import styles from './styles.module.css';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  classNameLabel: string;
  removeClass?: (id: string) => void;
};

const inlineFixedStyles: CSSProperties = {
  position: 'relative',
  transformOrigin: '50% 50%',
  height: 'auto',
  width: '155px',
  borderRadius: '20px',
  display: 'inline-block',
  color: 'black'
};

const inlineNameLabel: CSSProperties = {
  width: '140px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  fontSize: 'small',
  fontWeight: 400,
  margin: '2px'
};

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ id, removeClass, withOpacity, isDragging, style, ...props }, ref) => {
    const inlineStyles: CSSProperties = {
      ...inlineFixedStyles,
      opacity: withOpacity ? '0.5' : '1',
      cursor: isDragging ? 'grabbing' : 'grab',
      boxShadow: isDragging
        ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
        : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      color: '#51cf66',
      border: '1px solid #51cf66',
      display: 'flex',
      flexDirection: 'row-reverse',
      ...style
    };

    const preventDefault = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
    };

    return (
      <div ref={ref} style={inlineStyles} {...props}>
        <div
          className={styles.DeleteButton}
          onMouseDown={preventDefault}
          onClick={() => removeClass && removeClass(id)}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <div style={inlineNameLabel}>{props.classNameLabel}</div>
      </div>
    );
  }
);

interface AddItemProps {
  classNameLabel: string;
  addClass: () => void;
}

export const AddItem = forwardRef<HTMLDivElement, AddItemProps>(
  ({ classNameLabel, addClass }, ref) => {
    const inlineStyles: CSSProperties = {
      ...inlineFixedStyles,
      backgroundColor: 'var(--bs-body-color)',
      color: 'var(--bs-body-bg)',
      cursor: 'pointer'
    };

    return (
      <div ref={ref} style={inlineStyles} onClick={addClass} className={styles.item}>
        <div style={inlineNameLabel}>{classNameLabel}</div>
      </div>
    );
  }
);
