import { AppLanguageRepository } from '../repos';
import { AppLanguages, AppTranslation } from '../types';
export class AppLanguagesServ {
  async createLanguage(uuid: string, languages: AppLanguages) {
    return await AppLanguageRepository.createLanguage(uuid, languages);
  }

  async getLanguages(uuid: string) {
    return await AppLanguageRepository.getLanguages(uuid);
  }

  async updateLanguage(uuid: string, languages: AppLanguages) {
    return await AppLanguageRepository.updateLanguages(uuid, languages);
  }

  async deleteLanguage(uuid: string, languages: AppLanguages) {
    return await AppLanguageRepository.deleteLanguages(uuid, languages);
  }

  async createTranslation(uuid: string, id: string, translates: AppTranslation) {
    return await AppLanguageRepository.createTranslation(uuid, id, translates);
  }

  async getTranslations(uuid: string, id: string, page: number) {
    return await AppLanguageRepository.getTranslation(uuid, id, page);
  }
}
