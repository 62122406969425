import React, { useEffect, useState } from 'react';
import styles from '../../styles.module.css';
import { Button } from 'react-bootstrap';
import Icon from '../../../../../../../web_ui/icon';
import { useTranslation } from 'react-i18next';
import { AppLanguages } from '../../../../../../../modules/designer/types';
import CreateLanguageModal from '../../../../../../../modules/project/components/create_language_modal';
import { useParams } from 'react-router-dom';
import { AppLanguagesService } from '../../../../../../../modules/designer/services';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type LanguagesListProps = {
  selectedLanguage: string;
  setSelectedLanguage: (languageId: string) => void;
  mainLanguageId: string;
};

function LanguagesList({
  selectedLanguage,
  setSelectedLanguage,
  mainLanguageId
}: LanguagesListProps) {
  const { t } = useTranslation();
  const { app_id } = useParams();
  const [showCreateLanguageModal, setShowCreateLanguageModal] = useState<boolean>(false);
  const [languagesList, setLanguagesList] = useState<AppLanguages[]>([]);

  useEffect(() => {
    async function getLanguages() {
      if (!app_id) return;
      const languages = await AppLanguagesService.getLanguages(app_id);
      setLanguagesList(languages ?? []);
      setSelectedLanguage(mainLanguageId ?? languages[0].id);
    }
    getLanguages().then();
  }, [app_id]);

  const handleCloseCreateLanguageModal = async (languageId: string) => {
    if (!app_id) return;
    setShowCreateLanguageModal(false);
    const languages = await AppLanguagesService.getLanguages(app_id);
    setLanguagesList(languages);
    setSelectedLanguage(languageId);
  };

  return (
    <>
      <div className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
        <span className={styles.language} style={{ margin: 0 }}>
          {t('appResume.languages.Languages')}
        </span>

        <select
          id={'selectLanguages'}
          className={styles.select}
          onChange={(e) => {
            setSelectedLanguage(e.target.value);
          }}
          value={selectedLanguage}
        >
          {Object.values(languagesList).map((language) => {
            return (
              <option id={language.locale} key={language.id} value={language.id}>
                {language.name} {mainLanguageId === language.id && '*'}
              </option>
            );
          })}
        </select>

        <HelpPopover
          helpBoxProps={{
            title: t('appResume.module.New')!
          }}
          placement="top"
        >
          <Button id={'createButton'} onClick={() => setShowCreateLanguageModal(true)}>
            <Icon iconName="plus"></Icon>
          </Button>
        </HelpPopover>
      </div>

      {app_id && (
        <CreateLanguageModal
          onCloseRequest={handleCloseCreateLanguageModal}
          showModal={showCreateLanguageModal}
          setShowModal={setShowCreateLanguageModal}
        />
      )}
    </>
  );
}

export default LanguagesList;
