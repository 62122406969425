import { Relationship } from '../../../../../../../types';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';
import React from 'react';
import { useDispatch } from 'react-redux';
import { changeProperty } from '../../../../../../store/actions/relationship';
import { useTranslation } from 'react-i18next';

type BidirectionalInputProps = {
  relationship: Relationship;
};

export default function BidirectionalInput({ relationship }: BidirectionalInputProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleBidirectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeProperty(relationship.id, 'bidirectional', e.target.checked));
  };

  return (
    <Form.Group className="col-sm-6" controlId="relationBidirectional">
      <Form.Label className={styles.SmallFont}>{t('modeler.Bidirectional')}</Form.Label>
      <Form.Check
        checked={relationship?.bidirectional}
        type={'switch'}
        onChange={handleBidirectionChange}
      />
    </Form.Group>
  );
}
