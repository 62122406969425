import React, { ComponentType, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConnectionList } from './index';
import styles from './styles.module.css';
import { DatabaseStudioState } from '../store';

function ConnectorRenderer() {
  // TODO: this state with type any must be changed
  const relationships = useSelector((state: any) => state.relationships);
  const tables = useSelector((state: any) => state.tables);
  const selectedConnector = useSelector(
    (state: DatabaseStudioState) => state.studio.selectedConnector
  );
  const selectedTable = useSelector((state: DatabaseStudioState) => state.studio.selectedFrame);
  const [lastSelectedConnection, setLastSelectedConnection] = useState('');
  const [lastSelectedTable, setLastSelectedTable] = useState('');
  const influenceFactor = 5000;

  interface TablePos {
    uuid: string;
    posX: number;
    posY: number;
  }

  useEffect(() => {
    handleHighlightColumns();
  }, [selectedConnector]);

  useEffect(() => {
    handleHighLightConnectors();
  }, [selectedTable]);

  function calculatePoints(
    table: TablePos,
    width: number,
    height: number
  ): { x: number; y: number }[] {
    const points = [
      { x: table.posX, y: table.posY },
      { x: table.posX + width / 2, y: table.posY },
      { x: table.posX + width, y: table.posY },
      { x: table.posX + width, y: table.posY + height / 2 },
      { x: table.posX + width, y: table.posY + height },
      { x: table.posX + width / 2, y: table.posY + height },
      { x: table.posX, y: table.posY + height },
      { x: table.posX, y: table.posY + height / 2 }
    ];
    return points;
  }

  function calcDistance(x1: number, y1: number, x2: number, y2: number): number {
    const distance: number = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    return distance;
  }

  function calcMinimumDistance(
    originTable: TablePos,
    targetTable: TablePos
  ): { originPoint: string; targetPoint: string } {
    const originTableRef = document.getElementById(originTable.uuid);
    const targetTableRef = document.getElementById(targetTable.uuid);
    let originCoordinate = -1;
    let targetCoordinate = -1;
    let minimumDistance = 9999999;

    if (!originTableRef || !targetTableRef) return { originPoint: '0', targetPoint: '4' };

    const originPoints = calculatePoints(
      originTable,
      originTableRef.clientWidth,
      originTableRef.clientHeight
    );
    const targetPoints = calculatePoints(
      targetTable,
      targetTableRef.clientWidth,
      targetTableRef.clientHeight
    );

    originPoints.forEach((originPoint, originIndex) => {
      targetPoints.forEach((targetPoint, targetIndex) => {
        let distance = calcDistance(originPoint.x, originPoint.y, targetPoint.x, targetPoint.y);
        distance = [1, 3, 5, 7].includes(originIndex) ? distance - influenceFactor : distance;
        distance = [1, 3, 5, 7].includes(targetIndex) ? distance - influenceFactor : distance;
        if (distance < minimumDistance) {
          originCoordinate = originIndex;
          targetCoordinate = targetIndex;
          minimumDistance = distance;
        }
      });
    });

    return { originPoint: String(originCoordinate), targetPoint: String(targetCoordinate) };
  }

  function handleHighlightColumns() {
    if (
      (selectedConnector && !relationships[selectedConnector]) ||
      !selectedConnector ||
      selectedConnector === lastSelectedConnection
    ) {
      removeRelationshipColumnStyles();
      return;
    }
    Object.keys(relationships[selectedConnector].components).map((pkUuid) => {
      document
        .getElementById(relationships[selectedConnector].components[pkUuid])!
        .style.setProperty(
          'color',
          'rgba(var(--bs-primary-rgb),var(--bs-text-opacity))',
          'important'
        );
    });
    removeRelationshipColumnStyles();
    setLastSelectedConnection(selectedConnector ? selectedConnector : '');
  }

  function handleHighLightConnectors() {
    if (
      !selectedTable ||
      !tables[selectedTable] ||
      !tables[selectedTable].content.data.relationships
    ) {
      removeConnectorHightlights();
      return;
    }
    removeConnectorHightlights();

    Object.values(tables[selectedTable].content.data.relationships).map((id) => {
      const connectionRef = document.getElementById(id as string);
      const connectionCardiRef = document.getElementById(('text-' + id) as string);
      if (connectionRef && connectionCardiRef) {
        connectionRef.style.stroke = 'var(--bs-emphasis-color)';
        connectionCardiRef.style.stroke = 'var(--bs-emphasis-color)';
      }
    });
    setLastSelectedTable(selectedTable ? selectedTable : '');
  }

  function removeRelationshipColumnStyles() {
    if (lastSelectedConnection && relationships[lastSelectedConnection]) {
      Object.keys(relationships[lastSelectedConnection].components).map((pkUuid) => {
        document
          .getElementById(relationships[lastSelectedConnection].components[pkUuid])!
          .style.removeProperty('color');
      });
      setLastSelectedConnection('');
    }
  }

  function removeConnectorHightlights() {
    if (
      lastSelectedTable &&
      tables[lastSelectedTable] &&
      tables[lastSelectedTable].content.data.relationships
    ) {
      Object.values(tables[lastSelectedTable].content.data.relationships).map((id) => {
        const connectionRef = document.getElementById(id as string);
        const connectionCardiRef = document.getElementById(('text-' + id) as string);

        if (connectionRef && connectionCardiRef) {
          connectionRef.style.removeProperty('stroke');
          connectionCardiRef.style.removeProperty('stroke');
        }
      });
      setLastSelectedTable('');
    }
  }

  return (
    <React.Fragment>
      <svg className={`${styles.connectorSvg}`}>
        <g className={`${styles.svgGroup}`}>
          {relationships &&
            Object.keys(relationships).map((key) => {
              let sourceFrame;
              let targetFrame;
              if (relationships[key].type === 'ONE2MANY') {
                sourceFrame = tables[relationships[key].from];
                targetFrame = tables[relationships[key].to];
              } else {
                sourceFrame = tables[relationships[key].from];
                targetFrame = tables[relationships[key].to];
              }

              if (!sourceFrame || !targetFrame) return null;

              const ConnectorComponent = ConnectionList['EDGE'] as ComponentType;
              return ConnectorComponent ? (
                <ConnectorComponent
                  key={key}
                  connectorID={key}
                  sourceRef={sourceFrame}
                  targetRef={targetFrame}
                  coordinates={calcMinimumDistance(sourceFrame, targetFrame)}
                  {...sourceFrame}
                />
              ) : null;
            })}
          <use href={`#group-${selectedConnector}`} xlinkHref={`#test-${selectedConnector}`} />
        </g>
      </svg>
    </React.Fragment>
  );
}

export default ConnectorRenderer;
