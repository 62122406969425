import { DragEvent } from 'react';
import { FolderService, LayoutService, ModalService, PageService } from 'modules/designer/services';
import { Folder, Layout, Modal, Page } from 'modules/designer/types';
import { TargetType } from './draggable_resource/draggable_resource';
import {
  ControllersService,
  ObjectsService,
  ScheduledsService,
  ServicesService
} from 'modules/logic_builder/services';
import { Controller, ObjectType, SchedulerJob, Service } from 'modules/logic_builder/types';
import { changeTableFolder, changeTableName } from 'modules/modeler/studio/store/actions/frames';
import { changeEnumFolder, changeEnumName } from 'modules/modeler/studio/store/actions/enums';
import { Dispatch } from 'redux';

// * Common callbacks.

export const updateFoldersOnMoveFolder = (
  folders: Folder[],
  movedFolderId: string,
  newParentId: string,
  sourceType: TargetType,
  setFolders: (folders: Folder[]) => void
): void => {
  if (sourceType !== 'folder') return;
  // Delete folder.
  let movedFolder: Folder | null = null;
  const next = [...folders];
  const rootIndex = next.findIndex((f) => f.uuid === movedFolderId);
  if (rootIndex !== -1) {
    movedFolder = next.splice(rootIndex, 1)[0];
  } else {
    const foldersToCheck: Folder[] = [...next];
    while (foldersToCheck.length) {
      const checkFolder = foldersToCheck.pop();
      if (!checkFolder) continue;
      if (checkFolder.folders) {
        const index = checkFolder.folders.findIndex((f) => f.uuid === movedFolderId);
        if (index !== -1) {
          movedFolder = checkFolder.folders.splice(index, 1)[0];
          break;
        }
        foldersToCheck.push(...checkFolder.folders);
      }
    }
  }
  if (!movedFolder) return;
  movedFolder.parent = newParentId;
  // Add folder.
  if (!newParentId) {
    next.push(movedFolder);
  } else {
    const foldersToCheck: Folder[] = [...next];
    while (foldersToCheck.length) {
      const checkFolder = foldersToCheck.pop();
      if (!checkFolder) continue;
      if (checkFolder.uuid === newParentId) {
        if (!checkFolder.folders) {
          checkFolder.folders = [];
        }
        checkFolder.folders.push(movedFolder);
        break;
      }
      foldersToCheck.push(...(checkFolder.folders ?? []));
    }
  }
  setFolders(next);
};

export const updateFoldersOnCreate = (
  folders: Folder[],
  newFolder: Folder,
  setFolders: (folders: Folder[]) => void
): void => {
  const next = [...folders];
  if (!newFolder.parent) {
    next.push(newFolder);
    setFolders(next);
  }
  const foldersToCheck: Folder[] = [...next];
  while (foldersToCheck.length) {
    const checkFolder = foldersToCheck.pop();
    if (!checkFolder) continue;
    if (checkFolder.uuid === newFolder.parent) {
      if (!checkFolder.folders) {
        checkFolder.folders = [];
      }
      checkFolder.folders.push(newFolder);
      setFolders(next);
    }
    foldersToCheck.push(...(checkFolder.folders ?? []));
  }
  setFolders(next);
};

export const updateFoldersOnDelete = (
  folders: Folder[],
  deletedFolder: string,
  setFolders: (folders: Folder[]) => void
): void => {
  const next = [...folders];
  const index = next.findIndex((f) => f.uuid === deletedFolder);
  if (index !== -1) {
    next.splice(index, 1);
  } else {
    const foldersToCheck: Folder[] = [...next];
    while (foldersToCheck.length) {
      const checkFolder = foldersToCheck.pop();
      if (!checkFolder) continue;
      if (checkFolder.folders) {
        const index = checkFolder.folders.findIndex((f) => f.uuid === deletedFolder);
        if (index !== -1) {
          checkFolder.folders.splice(index, 1);
          break;
        }
        foldersToCheck.push(...checkFolder.folders);
      }
    }
  }
  setFolders(next);
};

export const updateFoldersOnRename = (
  folders: Folder[],
  folderId: string,
  newName: string,
  setFolders: (folders: Folder[]) => void
): void => {
  const next = [...folders];
  const foldersToCheck: Folder[] = [...next];
  while (foldersToCheck.length) {
    const checkFolder = foldersToCheck.pop();
    if (!checkFolder) continue;
    if (checkFolder.uuid === folderId) {
      checkFolder.name = newName;
      setFolders(next);
    }
    foldersToCheck.push(...(checkFolder.folders ?? []));
  }
  setFolders(next);
};

export const checkFolderNameAlreadyExists = (
  parentId: string,
  folderName: string,
  folders: Folder[],
  folderId?: string
): boolean => {
  let parent = parentId;
  if (parent === '/' || !parent) {
    parent = '';
  }
  const toCheck = [...folders];
  while (toCheck.length) {
    const folderToCheck = toCheck.pop();
    if (!folderToCheck) continue;
    if (!folderToCheck.parent && !parent) {
      if (
        folderName.toLowerCase() === folderToCheck.name.toLowerCase() &&
        !(folderToCheck.uuid === folderId)
      ) {
        return true;
      }
    }
    if (folderToCheck.parent === parent) {
      if (
        folderName.toLowerCase() === folderToCheck.name.toLowerCase() &&
        !(folderToCheck.uuid === folderId)
      ) {
        return true;
      }
    }
  }
  return false;
};

export const deletefolder = async (moduleId: string, folderId: string): Promise<void> => {
  await FolderService.deleteFolder(moduleId, folderId);
};

export const savefolder = async (
  moduleId: string,
  folderId: string,
  newName: string
): Promise<Folder> => {
  return await FolderService.updateFolder(moduleId, folderId, newName);
};

// * Designer: folder and folder items (views) callbacks.

// Send request to save view name and returns the i18n error message.
export const designer_saveitem = async (
  viewId: string,
  fetchViews: () => Promise<void>,
  newName: string,
  viewType: string
) => {
  const schema = {
    name: newName
  };
  switch (viewType) {
    case 'PAGE': {
      await PageService.updatePage(viewId, schema as Page);
      break;
    }
    case 'LAYOUT': {
      await LayoutService.updateLayout(viewId, schema as Layout);
      break;
    }
    case 'MODAL': {
      await ModalService.updateModal(viewId, schema as Modal);
      break;
    }
    default:
      console.error('Missing save folder item implementation for type: ', viewType);
  }
  fetchViews();
};

export const designer_dragleave = (
  event: React.DragEvent<HTMLElement>,
  dragWrapperRef: HTMLDivElement
) => {
  dragWrapperRef.style.border = '';
  dragWrapperRef.style.boxShadow = '';
};

export const designer_dragstart = (
  event: DragEvent<HTMLDivElement>,
  sourceId: string,
  currentParent: string,
  type: string, // The view type: 'LAYOUT' | 'PAGE' | 'MODAL'.
  typetype: TargetType
) => {
  if (typetype === 'root') {
    return;
  }
  event.dataTransfer.setData('exocode/dragged-view-id', sourceId);
  event.dataTransfer.setData('exocode/dragged-view-type', type);
  event.dataTransfer.setData('exocode/dragged-source-type', typetype);
  event.dataTransfer.setData('exocode/dragged-element-parent', currentParent);
  event.dataTransfer.dropEffect = 'copy';
};

export const designer_dragover = (
  event: React.DragEvent<HTMLElement>,
  dragWrapperRef: HTMLDivElement,
  type: TargetType
) => {
  event.preventDefault();
  event.stopPropagation();
  dragWrapperRef.style.border = '1px solid #86b7fe';
  dragWrapperRef.style.boxShadow = '0 0 0 0.25rem rgba(13,110,253,.25)';
};

export const designer_drop = async (
  event: React.DragEvent<HTMLElement>,
  fetchViews: () => Promise<void>,
  fetchFolders: () => Promise<void>,
  targetType: TargetType,
  dragWrapperRef: HTMLDivElement,
  targetId: string,
  moduleId: string
): Promise<{ sourceId: string; sourceType: TargetType } | null> => {
  event.stopPropagation();
  if (targetType !== 'folder' && targetType !== 'root') {
    return null;
  }
  dragWrapperRef.style.border = '';
  dragWrapperRef.style.boxShadow = '';
  const draggedSourceId = event.dataTransfer.getData('exocode/dragged-view-id');
  // PAGE | MODAL | LAYOUT.
  const draggedSourceItemType = event.dataTransfer.getData('exocode/dragged-view-type');
  // folder | root | folderItem.
  const draggedSourceType = event.dataTransfer.getData('exocode/dragged-source-type') as TargetType;
  const currentElementParentId = event.dataTransfer.getData('exocode/dragged-element-parent');
  if (currentElementParentId === targetId) return null;
  if (draggedSourceId === targetId) {
    return null;
  }
  if (draggedSourceType === 'root') {
    return null;
  }
  if (targetType === 'root' && draggedSourceType !== 'folder') {
    return null;
  }
  if (draggedSourceType === 'folder') {
    await FolderService.moveFolder(moduleId, draggedSourceId, targetId);
    fetchFolders();
  } else if (draggedSourceType === 'folderItem') {
    switch (draggedSourceItemType) {
      case 'PAGE': {
        await PageService.movePage(draggedSourceId, moduleId, targetId);
        fetchViews();
        break;
      }
      case 'MODAL': {
        await ModalService.moveModal(draggedSourceId, moduleId, targetId);
        fetchViews();
        break;
      }
      case 'LAYOUT': {
        await LayoutService.moveLayout(draggedSourceId, moduleId, targetId);
        fetchViews();
        break;
      }
      default: {
        break;
      }
    }
  }
  return { sourceId: draggedSourceId, sourceType: draggedSourceType };
};

// * Logic builder: folder and folder items (views) callbacks.

export const lb_saveitem = async (
  elementId: string,
  type: string,
  newName: string,
  moduleId?: string
): Promise<void> => {
  const schema = {
    name: newName
  };
  switch (type) {
    case 'SERVICE': {
      await ServicesService.updateService(elementId, schema as Service);
      break;
    }
    case 'OBJECT': {
      await ObjectsService.updateObject(elementId, schema as ObjectType);
      break;
    }
    case 'CONTROLLER': {
      await ControllersService.updateController(elementId, schema as Controller);
      break;
    }
    case 'SCHEDULER': {
      const job = schema as SchedulerJob;
      await ScheduledsService.updateScheduled(job, moduleId ?? '', elementId);
      break;
    }
    default:
      console.error('Missing save implementation for logic builder type: ', type);
  }
};

export const lb_dragstart = function (
  event: DragEvent<HTMLDivElement>,
  sourceId: string,
  currentParent: string,
  type: string,
  typetype: TargetType
) {
  if (typetype === 'root') {
    return;
  }
  event.dataTransfer.setData('exocode/dragged-element-id', sourceId);
  // The type of the element being dragged.
  event.dataTransfer.setData('exocode/dragged-element-type', type);
  event.dataTransfer.setData('exocode/dragged-source-type', typetype);
  event.dataTransfer.setData('exocode/dragged-element-parent', currentParent);
  event.dataTransfer.dropEffect = 'copy';
};

export const lb_dragover = (
  event: React.DragEvent<HTMLElement>,
  dragWrapperRef: HTMLDivElement,
  type: TargetType
) => {
  event.preventDefault();
  event.stopPropagation();
  dragWrapperRef.style.border = '1px solid #86b7fe';
  dragWrapperRef.style.boxShadow = '0 0 0 0.25rem rgba(13,110,253,.25)';
};

export const lb_dragleave = (
  event: React.DragEvent<HTMLElement>,
  dragWrapperRef: HTMLDivElement
) => {
  dragWrapperRef.style.border = '';
  dragWrapperRef.style.boxShadow = '';
};

export const lb_drop = async (
  e: React.DragEvent<HTMLElement>,
  targetType: TargetType,
  dragWrapperRef: HTMLDivElement,
  targetId: string,
  moduleId: string
): Promise<{ sourceId: string; sourceType: TargetType } | null> => {
  e.stopPropagation();
  if (targetType !== 'folder' && targetType !== 'root') {
    return null;
  }
  dragWrapperRef.style.border = '';
  dragWrapperRef.style.boxShadow = '';
  const draggedElementId = e.dataTransfer.getData('exocode/dragged-element-id');
  const draggedElementType = e.dataTransfer.getData('exocode/dragged-element-type');
  const draggedSourceType = e.dataTransfer.getData('exocode/dragged-source-type') as TargetType;
  const currentElementParentId = e.dataTransfer.getData('exocode/dragged-element-parent');
  if (currentElementParentId === targetId) return null;
  if (draggedElementId === targetId) {
    return null;
  }
  if (draggedSourceType === 'root') {
    return null;
  }
  if (draggedSourceType === 'folder') {
    await FolderService.moveFolder(moduleId, draggedElementId, targetId);
  } else if (draggedSourceType === 'folderItem') {
    switch (draggedElementType) {
      case 'OBJECT':
        await ObjectsService.changeFolder(draggedElementId, targetId);
        break;
      case 'SERVICE':
        await ServicesService.changeFolder(draggedElementId, targetId);
        break;
      case 'CONTROLLER':
        await ControllersService.changeFolder(draggedElementId, targetId);
        break;
      case 'SCHEDULER':
        await ScheduledsService.changeFolder(draggedElementId, targetId);
        break;
    }
  }
  return { sourceId: draggedElementId, sourceType: draggedSourceType };
};

// * Modeler: folder and folder items (views) callbacks.

export const mod_saveitem = async (
  elementId: string,
  type: string, // ENUM | TABLE.
  newName: string,
  dispatch: any
) => {
  switch (type) {
    case 'TABLE': {
      dispatch(changeTableName(elementId, newName));
      break;
    }
    case 'ENUM': {
      dispatch(changeEnumName(elementId, newName));
      break;
    }
    default:
      console.error('Missing save implementation for modeler type: ', type);
  }
};

export const mod_dragstart = function (
  event: DragEvent<HTMLDivElement>,
  sourceId: string,
  currentParent: string,
  type: string,
  typetype: TargetType
) {
  if (typetype === 'root') {
    return;
  }
  event.dataTransfer.setData('exocode/dragged-element-id', sourceId);
  // The type of the element being dragged (TABLE | ENUM).
  event.dataTransfer.setData('exocode/dragged-element-type', type);
  event.dataTransfer.setData('exocode/dragged-source-type', typetype);
  event.dataTransfer.setData('exocode/dragged-element-parent', currentParent);
  event.dataTransfer.dropEffect = 'copy';
};

export const mod_dragover = (
  event: React.DragEvent<HTMLElement>,
  dragWrapperRef: HTMLDivElement,
  type: TargetType
) => {
  event.preventDefault();
  event.stopPropagation();
  dragWrapperRef.style.border = '1px solid #86b7fe';
  dragWrapperRef.style.boxShadow = '0 0 0 0.25rem rgba(13,110,253,.25)';
};

export const mod_dragleave = (
  event: React.DragEvent<HTMLElement>,
  dragWrapperRef: HTMLDivElement
) => {
  dragWrapperRef.style.border = '';
  dragWrapperRef.style.boxShadow = '';
};

export const mod_drop = async (
  e: React.DragEvent<HTMLElement>,
  targetType: TargetType,
  dragWrapperRef: HTMLDivElement,
  targetId: string,
  moduleId: string,
  dispatch: Dispatch<any>
): Promise<{ sourceId: string; sourceType: TargetType } | null> => {
  e.stopPropagation();
  if (targetType !== 'folder' && targetType !== 'root') {
    return null;
  }
  dragWrapperRef.style.border = '';
  dragWrapperRef.style.boxShadow = '';
  const draggedElementId = e.dataTransfer.getData('exocode/dragged-element-id');
  const draggedElementType = e.dataTransfer.getData('exocode/dragged-element-type');
  const draggedSourceType: TargetType = e.dataTransfer.getData(
    'exocode/dragged-source-type'
  ) as TargetType;
  const currentElementParentId = e.dataTransfer.getData('exocode/dragged-element-parent');
  if (currentElementParentId === targetId) return null;
  if (draggedElementId === targetId) {
    return null;
  }
  if (draggedSourceType === 'root') {
    return null;
  }
  if (draggedSourceType === 'folder') {
    await FolderService.moveFolder(moduleId, draggedElementId, targetId);
  } else if (draggedSourceType === 'folderItem') {
    switch (draggedElementType) {
      case 'TABLE': {
        dispatch(changeTableFolder(draggedElementId, targetId));
        break;
      }
      case 'ENUM': {
        dispatch(changeEnumFolder(draggedElementId, targetId));
        break;
      }
      default: {
        console.error('Missing implementation for modeler frame type: ', draggedElementType);
      }
    }
  }
  return { sourceId: draggedElementId, sourceType: draggedSourceType };
};
