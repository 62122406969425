import React, { useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import styles from './styles.module.css';
import { EndpointExtended } from 'modules/logic_builder/types';
import { useTranslation } from 'react-i18next';
import { TagItem } from './tag_item';
import LogicBuilderContext from '../../../store';
import Icon from 'web_ui/icon';
import SelectTagDialog from './components/select_tag_dialog';
import { EndpointsService } from 'modules/logic_builder/services';

type OtherTabProps = {
  endpointExtended: EndpointExtended;
  setEndpointExtended: (ee: EndpointExtended) => void;
};

export function OtherTab(props: OtherTabProps) {
  const [isNative, setIsNative] = React.useState(false);
  const { tags } = useContext(LogicBuilderContext);
  const { t } = useTranslation();
  const [openSelectTag, setOpenSelectTag] = React.useState<boolean>(false);

  const handleOpenSelectTag = () => {
    setOpenSelectTag(true);
  };

  const handleCloseSelectTag = () => {
    setOpenSelectTag(false);
  };

  React.useEffect(() => {
    setIsNative(props.endpointExtended.native ?? false);
  }, [props.endpointExtended.native]);

  const deleteTag = async (id: number) => {
    const nextTags = props.endpointExtended.tags.filter((tId) => id !== tId);
    const nextEndpoint = { ...props.endpointExtended, tags: nextTags };
    await EndpointsService.updateEndpoint(props.endpointExtended.uuid, nextEndpoint).then(() => {
      props.setEndpointExtended(nextEndpoint);
    });
  };

  return (
    <>
      <Form.Group className={styles.OtherCheckboxes}>
        <Row>
          <Col sm={12}>
            <div className={`${styles.labelBtnPlusWrapper}`}>
              <Form.Label>{t('logicBuilder.Tags')}</Form.Label>
              <Button
                variant="primary"
                className={`${styles.plusButton}`}
                onClick={() => handleOpenSelectTag()}
              >
                <Icon iconName="plus" extraProps="small" />
              </Button>
            </div>
            <Form.Control as={Card} className={`${styles.TagsWrapper}`}>
              {props.endpointExtended.tags?.map((tagId) => {
                const tag = tags[tagId];
                if (!tag) return null;
                return (
                  <TagItem
                    tag={tag}
                    key={tag.id}
                    onDelete={(id) => {
                      deleteTag(id);
                    }}
                  />
                );
              })}
            </Form.Control>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className={styles.OtherCheckboxes}>
        <Form.Label>{t('logicBuilder.Description')}</Form.Label>
        <Form.Control
          id={'formEndpointDescription'}
          as="textarea"
          rows={3}
          placeholder={`${t('logicBuilder.Description')}`}
          value={props.endpointExtended.description}
          onChange={(e) => {
            props.setEndpointExtended({
              ...props.endpointExtended,
              description: e.target.value
            });
          }}
        />
      </Form.Group>
      <Form.Group className={styles.OtherCheckboxes}>
        <Form.Check
          id={'Deprecated'}
          inline
          label={`${t('logicBuilder.Deprecated')}`}
          type="checkbox"
          checked={props.endpointExtended.deprecated}
          disabled={isNative}
          onChange={(e) =>
            props.setEndpointExtended({ ...props.endpointExtended, deprecated: e.target.checked })
          }
          name={`${t('logicBuilder.Deprecated')}`}
        />
        <Form.Check
          id={'Pageable'}
          inline
          label={`${t('logicBuilder.Pageable')}`}
          type="checkbox"
          checked={props.endpointExtended.pageable}
          disabled={isNative}
          onChange={(e) =>
            props.setEndpointExtended({ ...props.endpointExtended, pageable: e.target.checked })
          }
          name={`${t('logicBuilder.Pageable')}`}
        />
        <Form.Check
          id={'Sortable'}
          inline
          label={`${t('logicBuilder.Sortable')}`}
          type="checkbox"
          checked={props.endpointExtended.sortable}
          disabled={isNative}
          onChange={(e) =>
            props.setEndpointExtended({ ...props.endpointExtended, sortable: e.target.checked })
          }
          name={`${t('logicBuilder.Sortable')}`}
        />
        <Form.Check
          id={'Filterable'}
          inline
          label={`${t('logicBuilder.Filterable')}`}
          type="checkbox"
          checked={props.endpointExtended.filterable}
          disabled={isNative}
          onChange={(e) =>
            props.setEndpointExtended({ ...props.endpointExtended, filterable: e.target.checked })
          }
          name={`${t('logicBuilder.Filterable')}`}
        />
        <SelectTagDialog
          open={openSelectTag}
          onHide={handleCloseSelectTag}
          endpointExtended={props.endpointExtended}
          setEndpointExtended={props.setEndpointExtended}
        />
      </Form.Group>
    </>
  );
}
