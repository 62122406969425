import React, { useCallback, useEffect } from 'react';
import styles from './styles.module.css';
import { PageParam } from 'modules/designer/types';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { Button, Form } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { paramPageRegex } from 'utils/regex';
import { Validation } from '../..';

interface Props {
  index: number;
  param: PageParam;
  handleRemove: (id: number) => void;
  handleChange: (id: number, val: string) => void;
  validation: React.Dispatch<React.SetStateAction<Validation>>;
  isFromPageEditor?: boolean;
}

function ParamInstance({
  index,
  param,
  handleRemove,
  handleChange,
  validation,
  isFromPageEditor
}: Props) {
  const { t } = useTranslation();
  const [validParam, setValidParam] = React.useState<boolean>();

  const validating = useCallback((val: boolean) => {
    validation((currVal) => {
      const newVal = { ...currVal };
      newVal.params = { ...newVal.params, [index]: val };
      return newVal;
    });
  }, []);

  return (
    <React.Fragment key={index}>
      <div
        className={`border-body-tertiary ${styles.ParameterEditor}`}
        style={isFromPageEditor ? { marginBottom: 10 } : {}}
      >
        <Form.Control
          id={`${param.name}-Field`}
          style={{
            marginLeft: 0,
            marginRight: '0.5rem'
          }}
          onChange={(e) => {
            handleChange(index, e.target.value);
            setValidParam(paramPageRegex.test(e.target.value));
            validating(paramPageRegex.test(e.target.value));
          }}
          value={param.name}
          required
          isValid={isFromPageEditor ? undefined : validParam}
          isInvalid={validParam !== undefined ? !validParam : false}
        />
        <HelpPopover
          placement={'top'}
          helpBoxProps={{
            title: t('Delete') ?? ''
          }}
        >
          <Button
            id="deleteParamButton"
            className={`btn btn-sm ${styles.deleteButtonContainer}`}
            onClick={() => handleRemove(index)}
          >
            <Icon iconName={'trash'} extraProps={'text-white'} />
          </Button>
        </HelpPopover>
      </div>
    </React.Fragment>
  );
}

export default ParamInstance;
