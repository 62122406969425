import React, { MouseEvent } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CodeEditor from './editor';
import styles from './styles.module.css';

type CodeEditorModalProps = {
  show: boolean;
  handleClose: () => void;
  previewType: string;
  id: string;
};

const CodeEditorModal: React.FC<CodeEditorModalProps> = ({
  show,
  handleClose,
  previewType,
  id
}) => {
  function handleOnMouseDown(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
  }

  return (
    <div onMouseDown={handleOnMouseDown} style={{ transition: 'ease-in-out 0.1s', zIndex: '1' }}>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={styles.modalMain}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Code Editor</Modal.Title>
        </Modal.Header>
        <div style={{ minHeight: '100px' }}>
          <Modal.Body>
            <CodeEditor id={id} previewType={previewType} />
          </Modal.Body>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CodeEditorModal;
