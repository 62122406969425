import React, { useState } from 'react';
import styles from './styles.module.css';
import { Button, Form, InputGroup } from 'react-bootstrap';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { t } from 'i18next';

type QueryVarsEditorProps = {
  queryVars?: string[];
  onChange: (vars: string[]) => void;
};

function QueryVarsEditor({ queryVars, onChange }: QueryVarsEditorProps) {
  const [value, setValue] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const handleAddVar = () => {
    if (!value) return;
    const newVars = queryVars ? [...queryVars] : [];
    if (newVars.includes(value)) return;
    newVars.push(value);
    onChange(newVars);
    setValue('');
  };

  const handleDelVar = (index: number) => {
    if (!queryVars) return;
    if (index > queryVars.length) return;
    const newVars = [...queryVars];
    newVars.splice(index, 1);
    onChange(newVars);
  };

  const handleSwapElements = (index1: number, moveUp: boolean) => {
    const index2 = moveUp ? index1 + 1 : index1 - 1;
    if (
      !queryVars ||
      index1 >= queryVars.length ||
      index1 < 0 ||
      index2 >= queryVars.length ||
      index2 < 0
    )
      return;

    const newVars = [...queryVars];
    const temp = newVars[index1];
    newVars[index1] = newVars[index2];
    newVars[index2] = temp;

    onChange(newVars);
  };

  return (
    <div className={styles.QueryVarsEditor}>
      <div style={{ marginBottom: '1rem' }}>
        <Form>
          <InputGroup>
            <Form.Control
              value={value}
              onChange={(e) => setValue(e.target.value)}
              size="sm"
              placeholder={t('AddVarHere') ?? ''}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAddVar();
                }
              }}
              autoComplete={'off'}
            />
            <Button style={{ opacity: '.9' }} onClick={handleAddVar}>
              Add
            </Button>
          </InputGroup>
        </Form>
      </div>
      <ul
        style={{
          paddingLeft: '0',
          textAlign: 'left',
          width: '100%',
          flex: '1'
        }}
      >
        {queryVars &&
          queryVars.map((v, index) => (
            <li
              key={v}
              className={'bg-transparent mb-2 d-flex align-items-center justify-content-between'}
              style={{ padding: '0.1rem' }}
            >
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: isCopied ? 'Copied' : `${t('CopyToClipboard')}: '?${index + 1}'`
                }}
              >
                <span
                  className={styles.MaxText}
                  onClick={() => {
                    navigator.clipboard.writeText(`?${index + 1}`);
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 2000);
                  }}
                >{`${index + 1}: ${v}`}</span>
              </HelpPopover>
              <div style={{ display: 'flex', marginLeft: '2px', gap: '0.2rem' }}>
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('modeler.Remove')!
                  }}
                >
                  <div
                    className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
                    onClick={() => handleDelVar(index)}
                  >
                    <span className={'fa fa-solid fa-trash'} />
                  </div>
                </HelpPopover>
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('MoveUp')!
                  }}
                >
                  <div
                    className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
                    onClick={() => handleSwapElements(index, false)}
                  >
                    <span className={'fa fa-arrow-up'} />
                  </div>
                </HelpPopover>
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('MoveDown')!
                  }}
                >
                  <div
                    className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
                    onClick={() => handleSwapElements(index, true)}
                  >
                    <span className={'fa fa-arrow-down'} />
                  </div>
                </HelpPopover>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default QueryVarsEditor;
