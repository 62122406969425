import { DATA_TYPES } from 'modules/logic_builder/types';
import { ComponentType, ReactNode } from 'react';
import ColorFormatter from '../formatterControls/Color';
import { DataTableColumn } from 'modules/designer/studio/exocode_components/data_table';
import ImageFormatter from '../formatterControls/Image';
import LinkFormatter from '../formatterControls/Link';
import LookupFormatter from '../formatterControls/LookupControl';
import TickCrossFormatter from '../formatterControls/TickCross';
import ButtonTickFormatter from '../formatterControls/ButtonTick';
import ProgressBarFormatter from '../formatterControls/ProgressBar';
import StarsFormatter from '../formatterControls/Stars';
import TrafficLightFormatter from '../formatterControls/TrafficLight';
import DatetimeFormatter from '../formatterControls/Datetime';
import BadgeFormatter from '../formatterControls/BadgeControl';

export enum FormatterTypes {
  TICKCROSS = 'TICKCROSS',
  BUTTONTICK = 'BUTTONTICK',
  TRAFFICLIGHT = 'TRAFFICLIGHT',
  STARS = 'STARS',
  PROGRESSBAR = 'PROGRESSBAR',
  DATETIME = 'DATETIME',
  LINK = 'LINK',
  IMAGE = 'IMAGE',
  COLOR = 'COLOR',
  LOOKUP = 'LOOKUP',
  BADGE = 'BADGE'
}

export interface FormatterManifest {
  [key: string]: any;
}

export interface FormatterTemplate {
  [key: string]: any;
}

export interface FormattersDatatype {
  [key: string]: any;
}

export const TICKCROSS_MANIFEST: FormatterManifest = {
  type: FormatterTypes.TICKCROSS,
  allowEmpty: true,
  allowTruthy: true,
  tickElement: 'check',
  crossElement: 'uncheck'
};

export const BUTTONTICK_MANIFEST: FormatterManifest = {
  type: FormatterTypes.BUTTONTICK,
  icon: ''
};

export const TRAFFICLIGHT_MANIFEST: FormatterManifest = {
  type: FormatterTypes.TRAFFICLIGHT,
  min: 0,
  max: 3,
  colors: []
};

export const STARS_MANIFEST: FormatterManifest = {
  type: FormatterTypes.STARS,
  stars: 5
};

export const PROGRESSBAR_MANIFEST: FormatterManifest = {
  type: FormatterTypes.PROGRESSBAR,
  min: 0,
  max: 100,
  variant: 'primary',
  label: false,
  animated: false,
  striped: false
};

export const DATETIME_MANIFEST: FormatterManifest = {
  type: FormatterTypes.DATETIME,
  outputFormat: 'dd/mm/yyyy',
  invalidPlaceholder: '(invalid date)',
  timezone: ''
};

export const LINK_MANIFEST: FormatterManifest = {
  type: FormatterTypes.LINK,
  urlPrefix: '',
  label: '',
  target: '_blank'
};

export const IMAGE_MANIFEST: FormatterManifest = {
  type: FormatterTypes.IMAGE,
  width: 50,
  height: 50,
  urlPrefix: '',
  urlSufix: ''
};

export const COLOR_MANIFEST: FormatterManifest = {
  type: FormatterTypes.COLOR
};

export const LOOKUP_MANIFEST: FormatterManifest = {
  type: FormatterTypes.LOOKUP,
  values: {}
};

export const BADGE_MANIFEST: FormatterManifest = {
  type: FormatterTypes.BADGE,
  values: {}
};

export const FORMATTER_MANIFEST: { [key: string]: FormatterManifest } = {
  [FormatterTypes.BUTTONTICK]: BUTTONTICK_MANIFEST,
  [FormatterTypes.TICKCROSS]: TICKCROSS_MANIFEST,
  [FormatterTypes.TRAFFICLIGHT]: TRAFFICLIGHT_MANIFEST,
  [FormatterTypes.STARS]: STARS_MANIFEST,
  [FormatterTypes.PROGRESSBAR]: PROGRESSBAR_MANIFEST,
  [FormatterTypes.DATETIME]: DATETIME_MANIFEST,
  [FormatterTypes.LINK]: LINK_MANIFEST,
  [FormatterTypes.IMAGE]: IMAGE_MANIFEST,
  [FormatterTypes.COLOR]: COLOR_MANIFEST,
  [FormatterTypes.LOOKUP]: LOOKUP_MANIFEST,
  [FormatterTypes.BADGE]: BADGE_MANIFEST
};

export const FORMATTER_MAPPER: { [key: string]: string[] } = {
  [DATA_TYPES.STRING]: [
    FormatterTypes.LINK,
    FormatterTypes.IMAGE,
    FormatterTypes.DATETIME,
    FormatterTypes.COLOR,
    FormatterTypes.LOOKUP,
    FormatterTypes.BADGE
  ],
  [DATA_TYPES.VARCHAR]: [
    FormatterTypes.LINK,
    FormatterTypes.IMAGE,
    FormatterTypes.DATETIME,
    FormatterTypes.COLOR,
    FormatterTypes.LOOKUP,
    FormatterTypes.BADGE
  ],
  [DATA_TYPES.ENUM]: [
    FormatterTypes.LINK,
    FormatterTypes.IMAGE,
    FormatterTypes.DATETIME,
    FormatterTypes.COLOR,
    FormatterTypes.LOOKUP,
    FormatterTypes.BADGE
  ],
  [DATA_TYPES.BOOLEAN]: [FormatterTypes.TICKCROSS, FormatterTypes.BUTTONTICK],
  [DATA_TYPES.SMALLINT]: [
    FormatterTypes.TRAFFICLIGHT,
    FormatterTypes.STARS,
    FormatterTypes.PROGRESSBAR
  ],
  [DATA_TYPES.BIGINT]: [
    FormatterTypes.TRAFFICLIGHT,
    FormatterTypes.STARS,
    FormatterTypes.PROGRESSBAR
  ],
  [DATA_TYPES.INTEGER]: [
    FormatterTypes.TRAFFICLIGHT,
    FormatterTypes.STARS,
    FormatterTypes.PROGRESSBAR
  ],
  [DATA_TYPES.DOUBLE]: [FormatterTypes.PROGRESSBAR],
  [DATA_TYPES.FLOAT]: [FormatterTypes.PROGRESSBAR],
  [DATA_TYPES.DATE]: [FormatterTypes.DATETIME]
};

export type FormatterProps = {
  id: any;
  label: string;
  value?: any;
  index: number;
  onChange?: <K extends keyof DataTableColumn>(
    column: number,
    key: K,
    value: DataTableColumn[K]
  ) => any;
  options?: { [key: string]: any };
};

export const FORMATTER_LIST: { [key: string]: ReactNode } = {
  [FormatterTypes.BUTTONTICK]: ButtonTickFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.TICKCROSS]: TickCrossFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.TRAFFICLIGHT]: TrafficLightFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.STARS]: StarsFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.PROGRESSBAR]: ProgressBarFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.DATETIME]: DatetimeFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.LINK]: LinkFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.IMAGE]: ImageFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.COLOR]: ColorFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.LOOKUP]: LookupFormatter as ComponentType<FormatterProps>,
  [FormatterTypes.BADGE]: BadgeFormatter as ComponentType<FormatterProps>
};

export enum LinkTargets {
  BLANK = '_blank',
  SELF = '_self',
  PARENT = '_parent',
  TOP = '_top'
}

export enum Timezones {
  'America/New_York' = 'America/New_York',
  'America/Chicago' = 'America/Chicago',
  'America/Denver' = 'America/Denver',
  'America/Los_Angeles' = 'America/Los_Angeles',
  'America/Sao_Paulo' = 'America/Sao_Paulo',
  'Europe/Lisbon' = 'Europe/Lisbon',
  'Europe/Berlin' = 'Europe/Berlin',
  'Europe/Bucharest' = 'Europe/Bucharest'
}

export enum DateFormats {
  'dd/mm/yyyy' = '09/11/2023',
  'mm/yyyy' = '11/2023',
  'hh:mm' = '14:30',
  'dd/mm/yyyyThh:mm' = '09/11/2023 14:30',
  'yyyy/mm/dd' = '2023/11/09',
  'yyyy/mm' = '2023/11'
}

export enum BootstrapColors {
  'primary' = 'primary',
  'secondary' = 'secondary',
  'success' = 'success',
  'info' = 'info',
  'warning' = 'warning',
  'danger' = 'danger',
  'light' = 'light',
  'dark' = 'dark'
}
