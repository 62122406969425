import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Item } from './item';
import { SortableCssClass } from '.';

interface Props {
  sortableClass: SortableCssClass;
  removeClass: (id: string) => void;
}

export const SortableItem = (props: Props) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.sortableClass.id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      id={props.sortableClass.id}
      classNameLabel={props.sortableClass.className}
      removeClass={props.removeClass}
      {...attributes}
      {...listeners}
    />
  );
};
