import { FrontendTechOption, ThemeOption } from './index';
import { Dependency, FrontendFramework, Theme } from '../../../../modules/project/enum';

export const FrontendFrameworkList: FrontendTechOption<FrontendFramework>[] = [
  {
    id: FrontendFramework.REACT,
    name: 'React',
    icon: 'gear'
  },
  {
    id: FrontendFramework.ANGULAR,
    name: 'Angular',
    icon: 'gear',
    unavailable: true
  }
];

export const DesignSystemList: FrontendTechOption<Dependency>[] = [
  {
    id: Dependency.BOOTSTRAP,
    name: 'Bootstrap',
    icon: 'gear'
  },
  {
    id: Dependency.MATERIAL_DESIGN,
    name: 'Material',
    icon: 'gear',
    unavailable: true
  }
];

export const ThemesList: ThemeOption[] = [
  {
    id: Theme.WHITE,
    backgroundColor: '#ffffff',
    primaryColor: '#0d6efd',
    secondaryColor: '#6c757d',
    textColor: '#000000'
  },
  {
    id: Theme.DARK,
    backgroundColor: '#212121',
    primaryColor: 'blue',
    secondaryColor: 'green',
    textColor: '#e3e3e3'
  }
];
