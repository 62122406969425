import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ViewManifest } from '../../../types';
import {
  validateCustomComponentName,
  validateDescriptionInputsForDesigner
} from 'utils/inputValidation';

export const CUSTOM_COMPONENT_MANIFEST: ViewManifest = {
  type: 'CUSTOM_COMPONENT',
  name: 'CustomComponent',
  description: '',
  icon: 'fa-desktop',
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Name',
      key: 'name',
      maxLength: 64,
      validation: (input: string) => validateCustomComponentName(input)
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Description',
      key: 'description',
      validation: (input: string) => validateDescriptionInputsForDesigner(input),
      errorMessages: {
        EXCEEDS_MAX_LENGTH: 'inputValidationErrorMessages.ViewDescriptionExceedsMaxLength'
      }
    }
  ],
  styles: []
};
