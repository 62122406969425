import { Action } from 'redux';
import { ViewUUID } from 'modules/designer/types';

export const SET_ROLE_VIEW = 'SET_ROLE_VIEW';

export interface SetRoleToView extends Action {
  type: 'SET_ROLE_VIEW';
  payload: {
    roles: number[];
    view: ViewUUID;
  };
}

export const setRoleToView = (roles: number[], view: ViewUUID): SetRoleToView => ({
  type: SET_ROLE_VIEW,
  payload: { roles, view }
});
