import React from 'react';
import styles from './styles.module.css';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type ErrorMessageProps = {
  setgettingValueError: React.Dispatch<React.SetStateAction<boolean>>;
  messageToRender: string;
};

export default function ErrorMessageCreateApp(props: ErrorMessageProps) {
  const { t } = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      props.setgettingValueError(false);
    }, 5000);
  }, [props]);

  return (
    <Alert className={styles.container} variant={'danger'}>
      {props.messageToRender}
    </Alert>
  );
}
