import { AppTranslation } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

export class AppTranslatesRepo {
  async getTranslation(uuid: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/translates/${uuid}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<AppTranslation[]>;
      throw new Error('Something went wrong while trying to fetch app translations.');
    });
  }

  async updateTranslation(uuid: string, value: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const appTranslatesSchema: { value: string } = { value };

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(appTranslatesSchema)
    };

    const url = `${API_URL}/translates/${uuid}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json();
      throw new Error('Something went wrong while trying to update an app translation.');
    });
  }

  async deleteTranslation(uuid: string, appTranslate: AppTranslation) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(appTranslate)
    };

    const url = `${API_URL}/translates/${uuid}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response;
      throw new Error('Something went wrong while trying to delete an app translation.');
    });
  }
}
