import { Relationship } from '../../../../../../../types';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';
import React from 'react';
import useChangeRelationToOneToOne from '../../hooks/useChangeRelationToOneToOne';
import { useTranslation } from 'react-i18next';

type CardinalityInputProps = {
  relationship: Relationship;
};

export default function CardinalityInput({ relationship }: CardinalityInputProps) {
  const handleCardinalityChange = useChangeRelationToOneToOne(relationship);
  const { t } = useTranslation();
  return (
    <Form.Group className="col-sm-6" controlId="relationType">
      <Form.Label className={styles.SmallFont}>{t('modeler.Cardinality')}</Form.Label>
      <Form.Check
        className={`${styles.SmallFont} ms-1`}
        checked={relationship.type === 'ONE2ONE'}
        label={'OneToOne'}
        type="radio"
        name={'relationType'}
        onChange={() => handleCardinalityChange()}
      />
      <Form.Check
        className={`${styles.SmallFont} ms-1`}
        checked={relationship.type === 'ONE2MANY' || relationship.type === 'MANY2ONE'}
        label={'OneToMany'}
        type="radio"
        name={'relationType'}
        disabled={relationship.type === 'ONE2ONE'}
        onChange={() => handleCardinalityChange()}
      />
    </Form.Group>
  );
}
