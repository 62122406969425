import React, { ChangeEvent, useCallback } from 'react';
import { SchemaObject } from 'modules/logic_builder/types';
import { Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface ObjectItemSelectorProps {
  value: string;
  typeFilter: string[];
  listFilter: boolean;
  currentObject: SchemaObject;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

function ObjectItemSelector({
  value,
  typeFilter,
  listFilter,
  currentObject,
  onChange
}: ObjectItemSelectorProps) {
  const { t } = useTranslation();
  const checkItem = useCallback(() => {
    return currentObject.objectItems?.find((item) => item.uuid === value) ? true : false;
  }, [currentObject, value]);

  return (
    <InputGroup hasValidation>
      <Form.Select
        className={`form-select mt-2`}
        value={value ?? ''}
        onChange={onChange}
        isInvalid={!checkItem()}
      >
        {(!typeFilter || typeFilter.length === 0) && <option value="">---</option>}

        {currentObject.objectItems &&
          currentObject.objectItems
            .filter((item) =>
              typeFilter.length
                ? typeFilter.map((filter) => filter.toUpperCase()).includes(item.dataType ?? '')
                : true
            )
            .filter((item) => (listFilter ? item.list : true))
            .map((item) => (
              <option key={item.uuid} value={item.uuid}>
                {item.name}
              </option>
            ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {value
          ? t('designer.right_side.controls.errors.ObjectItemDoesNotExist')
          : t('designer.right_side.controls.errors.MissingObjectItem')}
      </Form.Control.Feedback>
    </InputGroup>
  );
}

export default ObjectItemSelector;
