import React from 'react';
import styles from './styles.module.css';
import ReturnTypeEditor from './return_type_editor';
import NameEditor from './name_editor';
import { ReturnType } from '../../../../store/types/functions';
import { AvailableFrameworks } from '../function_signatures';
import CrudTypeEditor from '../crudtype_editor/crudtype_editor';

type ReturnTypeAndNameEditorProps = {
  types: ReturnType[];
  framework: AvailableFrameworks;
};

function ReturnTypeAndNameEditor({ types, framework = 'spring' }: ReturnTypeAndNameEditorProps) {
  return (
    <div className={styles.ReturnTypeAndName}>
      <ReturnTypeEditor types={types} />
      <NameEditor framework={framework} />
    </div>
  );
}

export default ReturnTypeAndNameEditor;
