import { FunctionsState } from '../../types/functions';
import { AddBlockAction } from '../actions/add_block';
import { CreateIfAction } from '../actions/create_if';

export function doCreateIf(state: FunctionsState, action: CreateIfAction): FunctionsState {
  const {
    functionId,
    ifBeginAction,
    ifBodyAction,
    ifEndAction,
    elseBeginAction,
    elseBodyAction,
    elseEndAction
  } = action.payload;

  state[functionId].actions.splice(
    ifBeginAction?.order ?? 0,
    0,
    ifBeginAction.uuid,
    ifBodyAction.uuid,
    ifEndAction.uuid,
    elseBeginAction.uuid,
    elseBodyAction.uuid,
    elseEndAction.uuid
  );
  return state;
}
