import React, { useCallback, useEffect, useState } from 'react';
import ErrorIcon from '../components/ErrorIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { VariableTypes } from 'modules/designer/types';
import { EnumFrame } from 'modules/modeler/types';
import { DataService } from 'modules/modeler/services';
import { useParams } from 'react-router-dom';

export enum MissingMessageType {
  FUNCTION = 'FUNCTION',
  VARIABLE = 'VARIABLE',
  SOURCE_VARIABLE = 'SOURCE_VARIABLE',
  OBJECT = 'OBJECT',
  ENUM = 'ENUM'
}

interface MissingMessageProps {
  uuid: string;
  type: MissingMessageType;
  requiredTypes?: VariableTypes[];
}

function MissingMessage({ uuid, type, requiredTypes }: MissingMessageProps) {
  const UIVariables = useSelector((state: InterfaceStudioState) => state.variables);
  const UIFunctions = useSelector((state: InterfaceStudioState) => state.functions);
  const UIObjects = useSelector((state: InterfaceStudioState) => state.objects);
  const { module_id } = useParams();
  const [enumsList, setEnumsList] = useState<EnumFrame[]>([]);

  const fetchEnums = useCallback(async (moduleId: string) => {
    await DataService.getEnums(moduleId).then((enums) => {
      setEnumsList(enums);
    });
  }, []);

  useEffect(() => {
    if (!module_id) return;
    fetchEnums(module_id);
  }, [fetchEnums, module_id]);

  const { t } = useTranslation();

  const renderVariableErrorIcon = (variableUUID: string, isSource?: boolean) => {
    let titleKey = null;
    let description = '';
    let note = undefined;

    if (!variableUUID) {
      titleKey = 'designer.right_side.controls.errors.MissingVariable';
      if (isSource)
        description = 'designer.right_side.controls.errors.MissingSourceVariableDescription';
    } else if (!UIVariables[variableUUID])
      titleKey = 'designer.right_side.controls.errors.VariableDoesNotExist';
    else if (
      requiredTypes &&
      requiredTypes?.length > 0 &&
      !requiredTypes?.includes(UIVariables[variableUUID].type)
    ) {
      titleKey = 'designer.right_side.controls.errors.WrongVariableType';
      description = `${t('designer.right_side.controls.errors.WrongVariableType')} - 
        ${UIVariables[variableUUID].name} (${UIVariables[variableUUID].type})
        `;
      note = [
        `${t('designer.right_side.controls.errors.WrongVariableTypeNote')} ${requiredTypes?.map(
          (type) => ' ' + type
        )}`
      ];
    } else if (
      UIVariables[variableUUID].type === 'OBJECT' &&
      (!UIVariables[variableUUID].objectUuid ||
        (UIVariables[variableUUID].objectUuid && !UIObjects[UIVariables[variableUUID].objectUuid!]))
    ) {
      titleKey = 'designer.right_side.controls.errors.WrongVariableObjectType';
      description = `${t(
        'designer.right_side.controls.errors.WrongVariableObjectTypeDescription'
      )}`;
    }
    return titleKey ? (
      <ErrorIcon title={t(titleKey)} description={description} note={note} />
    ) : null;
  };

  const renderFunctionErrorIcon = (functionUUID: string) => {
    const shouldRenderErrorIcon = !functionUUID || !UIFunctions[functionUUID];
    const titleKey =
      functionUUID && !UIFunctions[functionUUID]
        ? 'designer.right_side.controls.errors.FunctionDoesNotExist'
        : 'designer.right_side.controls.errors.MissingFunction';
    return shouldRenderErrorIcon ? <ErrorIcon title={t(titleKey)} /> : null;
  };

  const renderEnumErrorIcon = (enumUUID: string) => {
    const shouldRenderErrorIcon =
      !enumUUID || !enumsList.some((enumItem) => enumItem.uuid === enumUUID);
    const titleKey =
      enumUUID && !enumsList.some((enumItem) => enumItem.uuid === enumUUID)
        ? 'designer.right_side.controls.errors.EnumDoesNotExist'
        : 'designer.right_side.controls.errors.MissingEnum';
    return shouldRenderErrorIcon ? <ErrorIcon title={t(titleKey)} /> : null;
  };

  switch (type) {
    case MissingMessageType.VARIABLE:
      return renderVariableErrorIcon(uuid);
    case MissingMessageType.SOURCE_VARIABLE:
      return renderVariableErrorIcon(uuid, true);
    case MissingMessageType.FUNCTION:
      return renderFunctionErrorIcon(uuid);
    case MissingMessageType.ENUM:
      return renderEnumErrorIcon(uuid);

    default:
      return null;
  }
}

export default MissingMessage;
