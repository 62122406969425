import * as React from 'react';
import { ControlProps } from '..';
import { DefaultInput, DefaultLabel } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

type Options = {
  x: string;
  small: string;
  medium: string;
  large: string;
};

function BoxShadowControl({
  value,
  onChange,
  id,
  label,
  maxLength,
  validation,
  tooltip,
  errorMessages
}: ControlProps) {
  const { t } = useTranslation();
  const refx = React.useRef<HTMLDivElement>(null);
  const refSmall = React.useRef<HTMLDivElement>(null);
  const refMedium = React.useRef<HTMLDivElement>(null);
  const refLarge = React.useRef<HTMLDivElement>(null);
  const [chosen, setChosen] = React.useState<string>('');

  React.useEffect(() => {
    // removing from all
    reseting();

    // set based on value
    definingInitalValue();
  }, [value]);

  const reseting = () => {
    refSmall.current?.classList.remove(styles.containerBtnClicked);
    refMedium.current?.classList.remove(styles.containerBtnClicked);
    refLarge.current?.classList.remove(styles.containerBtnClicked);
    refx.current?.classList.remove(styles.containerBtnClicked);
  };

  const definingInitalValue = () => {
    if (value === undefined) return refx.current?.classList.add(styles.containerBtnClicked);
    switch (value) {
      case `${0}px ${0}px ${0}px ${0}px ${'#000000'}`:
        refx.current?.classList.add(styles.containerBtnClicked);
        return;
      case '0 .125rem .25rem rgba(0, 0, 0, .075)':
        refSmall.current?.classList.add(styles.containerBtnClicked);
        return;
      case '0 .5rem 1rem rgba(0, 0, 0, .15)':
        refMedium.current?.classList.add(styles.containerBtnClicked);
        return;
      case '0 1rem 3rem rgba(0, 0, 0, .175)':
        refLarge.current?.classList.add(styles.containerBtnClicked);
        return;
    }
  };

  const removing = () => {
    refSmall.current?.classList.remove(styles.containerBtnClicked);
    refMedium.current?.classList.remove(styles.containerBtnClicked);
    refLarge.current?.classList.remove(styles.containerBtnClicked);

    refx.current?.classList.add(styles.containerBtnClicked);

    // send the data
    const moutingValue = `${0}px ${0}px ${0}px ${0}px ${'#000000'}`;
    setChosen('removing');
    if (onChange) onChange(moutingValue, id);
  };

  const small = () => {
    refx.current?.classList.remove(styles.containerBtnClicked);
    refMedium.current?.classList.remove(styles.containerBtnClicked);
    refLarge.current?.classList.remove(styles.containerBtnClicked);

    refSmall.current?.classList.add(styles.containerBtnClicked);

    const moutingValue = `0 .125rem .25rem rgba(0, 0, 0, .075)`;
    setChosen('small');
    if (onChange) onChange(moutingValue, id);
  };

  const medium = () => {
    refx.current?.classList.remove(styles.containerBtnClicked);
    refSmall.current?.classList.remove(styles.containerBtnClicked);
    refLarge.current?.classList.remove(styles.containerBtnClicked);

    refMedium.current?.classList.add(styles.containerBtnClicked);

    const moutingValue = `0 .5rem 1rem rgba(0, 0, 0, .15)`;
    setChosen('medium');
    if (onChange) onChange(moutingValue, id);
  };

  const large = () => {
    refx.current?.classList.remove(styles.containerBtnClicked);
    refSmall.current?.classList.remove(styles.containerBtnClicked);
    refMedium.current?.classList.remove(styles.containerBtnClicked);

    refLarge.current?.classList.add(styles.containerBtnClicked);

    const moutingValue = `0 1rem 3rem rgba(0, 0, 0, .175)`;
    setChosen('large');
    if (onChange) onChange(moutingValue, id);
  };

  return (
    <div className={`mb-3 border-bottom ${styles.mainContainer}`}>
      <div style={{ marginBottom: '.5rem', display: 'flex', alignItems: 'center' }}>
        <DefaultLabel className={`${styles.labelContainer}`}>
          {label}
          {tooltip && (
            <HelpPopover
              placement="top"
              helpBoxProps={{ description: t('designer.right_side.controls.' + tooltip) ?? '' }}
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </DefaultLabel>
      </div>
      <div className={`${styles.inputsContainer}`}>
        <div
          className={`${styles.containerBtn}`}
          onClick={() => {
            removing();
          }}
          ref={refx}
        >
          x
        </div>
        <span style={{ margin: 0, padding: 0, color: 'black' }}>|</span>
        <div
          className={`${styles.containerBtn}`}
          ref={refSmall}
          onClick={() => {
            small();
          }}
        >
          Small
        </div>
        <span style={{ margin: 0, padding: 0, color: 'black' }}>|</span>
        <div
          className={`${styles.containerBtn}`}
          ref={refMedium}
          onClick={() => {
            medium();
          }}
        >
          Medium
        </div>
        <span style={{ margin: 0, padding: 0, color: 'black' }}>|</span>
        <div
          className={`${styles.containerBtn}`}
          ref={refLarge}
          onClick={() => {
            large();
          }}
        >
          Large
        </div>
      </div>
    </div>
  );
}

export default BoxShadowControl;
