export type MyProject = {
  id: string;
  name: string;
  description: string;
  avatars: Avatar[];
  labels: Label[];
};

type Avatar = {
  name: string;
  color: string;
};

type Label = {
  name: string;
};

export const AVAILABLE_MODULES = {
  S3: 'S3',
  BLOB: 'Blob',
  FILE_SYSTEM: 'File System'
};
