import React from 'react';
import { FaRegStar, FaStar } from 'react-icons/fa';

interface StarsFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function StarsFormatterPreview({ formatterParams, value }: StarsFormatterPreviewProps) {
  const totalStars = formatterParams.stars ? formatterParams.stars : 5;
  const activatedStars = 3;

  return (
    <>
      {Array.from({ length: totalStars }).map((_, index) => (
        <span key={index} style={{ color: '#ffc107' }}>
          {index < activatedStars ? <FaStar /> : <FaRegStar />}
        </span>
      ))}
    </>
  );
}

export default StarsFormatterPreview;
