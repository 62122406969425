import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormatterManifest, FormatterProps, FormatterTypes, IMAGE_MANIFEST } from '../../types';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../../../components/Popover';
import HelpIcon from '../../../components/HelpIcon';

function ImageFormatter(props: FormatterProps) {
  const { t } = useTranslation();
  const [imageParams, setImageParams] = useState<FormatterManifest>(IMAGE_MANIFEST);

  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.IMAGE) {
      props.onChange && props.onChange(props.index, 'formatterParams', IMAGE_MANIFEST);
    }
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>, field: string) {
    const updatedImageParams = { ...imageParams };
    updatedImageParams[field] = e.target.value;
    setImageParams(updatedImageParams);
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedImageParams);
  }

  return (
    <div className="mb-3 border-bottom ">
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <Col sm={5}>
            <Form.Group>
              <Form.Label column>{t('designer.right_side.FormatterControls.Width')}</Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.ImageFormatterWidthTitle') ||
                    'designer.right_side.FormatterControls.ImageFormatterWidthTitle',
                  description:
                    t('designer.right_side.FormatterControls.ImageFormatterWidthDescription') ||
                    'designer.right_side.FormatterControls.ImageFormatterWidthDescription',
                  note: [
                    t('designer.right_side.FormatterControls.ImageFormatterWidthNote01') ||
                      'designer.right_side.FormatterControls.ImageFormatterWidthNote01'
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="number"
                placeholder="e.g.: 10"
                value={props.value && props.value.width}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e, 'width');
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={5}>
            <Form.Group>
              <Form.Label column>{t('designer.right_side.FormatterControls.Height')}</Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.ImageFormatterHeightTitle') ||
                    'designer.right_side.FormatterControls.ImageFormatterHeightTitle',
                  description:
                    t('designer.right_side.FormatterControls.ImageFormatterHeightDescription') ||
                    'designer.right_side.FormatterControls.ImageFormatterHeightDescription',
                  note: [
                    t('designer.right_side.FormatterControls.ImageFormatterHeightNote01') ||
                      'designer.right_side.FormatterControls.ImageFormatterHeightNote01'
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="number"
                placeholder="e.g.: 10"
                value={props.value && props.value.height}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e, 'height');
                }}
              />
            </Form.Group>
          </Col>
        </div>
        <Form.Group>
          <Form.Label column>{t('designer.right_side.FormatterControls.UrlPrefix')}</Form.Label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.FormatterControls.ImageFormatterPrefixTitle') ||
                'designer.right_side.FormatterControls.ImageFormatterPrefixTitle',
              description:
                t('designer.right_side.FormatterControls.ImageFormatterPrefixDescription') ||
                'designer.right_side.FormatterControls.ImageFormatterPrefixDescription',
              note: [
                t('designer.right_side.FormatterControls.ImageFormatterPrefixNote01') ||
                  'designer.right_side.FormatterControls.ImageFormatterPrefixNote01',
                t('designer.right_side.FormatterControls.ImageFormatterPrefixNote02') ||
                  'designer.right_side.FormatterControls.ImageFormatterPrefixNote02'
              ]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
          <Form.Control
            type="text"
            placeholder="./images/"
            value={props.value && props.value.urlPrefix}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e, 'urlPrefix');
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label column>{t('designer.right_side.FormatterControls.UrlSuffix')}</Form.Label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.FormatterControls.ImageFormatterSuffixTitle') ||
                'designer.right_side.FormatterControls.ImageFormatterSuffixTitle',
              description:
                t('designer.right_side.FormatterControls.ImageFormatterSuffixDescription') ||
                'designer.right_side.FormatterControls.ImageFormatterSuffixDescription',
              note: [
                t('designer.right_side.FormatterControls.ImageFormatterSuffixNote01') ||
                  'designer.right_side.FormatterControls.ImageFormatterSuffixNote01',
                t('designer.right_side.FormatterControls.ImageFormatterSuffixNote02') ||
                  'designer.right_side.FormatterControls.ImageFormatterSuffixNote02'
              ]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
          <Form.Control
            type="text"
            placeholder=".png"
            value={props.value && props.value.urlSufix}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e, 'urlSufix');
            }}
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default ImageFormatter;
