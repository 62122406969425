import { createContext } from 'react';
import { UserProfile } from '../types/auth_types';
import { JobInfo } from '../../project/types';

export type SessionContextType = {
  isLoggedIn: boolean;
  setLoggedIn: (value: boolean) => void;
  user: UserProfile | null;
  setUser: (user: UserProfile | null) => void;
  preferences: { [key: string]: any };
  setPreferences: (preferences: { [key: string]: any }) => void;
  changePreferences: (key: string, value: any) => void;
  language: string;
  setLanguage: (value: string) => void;
  downloads: Record<string, JobInfo>;
  setDownloads: (download: Record<string, JobInfo>) => void;
  reloadUser: () => Promise<UserProfile> | null;
  pageVisits: Record<string, number> | undefined;
  updatePageVisits: (page: string) => void;
  setPageVisits: (pageVisits: Record<string, number> | undefined) => void;
};

const initialState: SessionContextType = {
  isLoggedIn: false,
  setLoggedIn: () => {},
  user: null,
  setUser: () => {},
  preferences: {},
  setPreferences: () => {},
  changePreferences: () => {},
  language: 'pt',
  setLanguage: () => {},
  downloads: {},
  setDownloads: () => {},
  reloadUser: () => null,
  pageVisits: undefined,
  updatePageVisits: () => {},
  setPageVisits: () => {}
};

export const initialPreferences = {
  'designer-show_grids': false,
  'modeler-number_of_columns': 3,
  'modeler-hide_columns': false,
  'exocode-theme': true
};

const SessionContext = createContext<SessionContextType>(initialState);

export default SessionContext;
