import { DataRepo } from '../repos';
import { Action } from 'redux';
import { EnumFrame, Schema, externalTablesList } from '../types';

export default class Data {
  async getSchema(module_id: string) {
    return DataRepo.getSchema(module_id);
  }

  async updateSchema(module_id: string, schema: Schema) {
    return await DataRepo.updateSchema(module_id, schema);
  }

  async sendAction(module_id: string, schema_id: string, action: Action) {
    return await DataRepo.sendAction(module_id, schema_id, action);
  }

  async getDataTypes(module_id: string) {
    return DataRepo.getDataTypes(module_id);
  }

  async getEnums(module_id: string): Promise<EnumFrame[]> {
    return await DataRepo.getEnums(module_id);
  }

  async getExternalTables(module_id: string, table_id: string): Promise<externalTablesList> {
    return await DataRepo.getExternalTables(module_id, table_id);
  }
}
