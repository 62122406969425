import React from 'react';
import Dropdown from 'web_ui/function_editor/action_inputs/components/dropdown';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { FunctionVariableType } from 'web_ui/function_editor/store/types/variables';
import { t } from 'i18next';

type VariablePickerProps = {
  label: string;
  actionUuid: string;
  value: string;
  handleChange: (text: string) => void;
  filterByList: boolean;
  filterByDataType?: (
    state: FunctionEditorState,
    actionUuid: string,
    dataType: string,
    objectUuid?: string
  ) => boolean;
  filterByGlobalVars?: boolean;
  isFromSideBar?: boolean;
  isFromArgument?: boolean;
};

export function VariablePicker({
  label,
  actionUuid,
  value,
  handleChange,
  filterByList,
  filterByDataType,
  filterByGlobalVars,
  isFromSideBar,
  isFromArgument
}: VariablePickerProps) {
  const state = useSelector((state: FunctionEditorState) => state);

  const globalVariables = state.globals && state.globals.variables ? state.globals.variables : [];

  let filtered: FunctionVariableType[] = Object.values(state.variables);
  if (filterByGlobalVars) {
    filtered = filtered.filter((v) => globalVariables.includes(v.uuid));
  }
  if (filterByList) {
    filtered = filtered.filter((v) => v.list);
  }
  if (filterByDataType) {
    filtered = filtered.filter((v) => {
      return filterByDataType(state, actionUuid, v.type, v?.objectUuid);
    });
  }

  return (
    <Dropdown
      items={filtered}
      placeholder={`${t('SelectVariable')}`}
      label={label}
      value={state.variables[value]}
      handleChange={handleChange}
      isFromSideBar={isFromSideBar}
      isFromArgument={isFromArgument}
    />
  );
}
