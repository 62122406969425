import { Editor, EditorModeOptions } from 'modules/designer/types';
import React, { ComponentType, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MoveSource } from '../store/actions/links';
import {
  setSelectedComponent,
  setSelectedView,
  setDraggedComponent,
  setHoveredComponent
} from '../store/actions/studio';
import { ComponentProps } from './component_wrapper';
import { InterfaceStudioState } from '../store';
import useDropZone from './drop_zone_hook';
import AdjacenteDropzones from './adjacente_dropzones';
import { COMPONENT_TYPES, COMPONENTS_MANIFEST } from '.';
import AnchorAutomationZones from './anchor_automation_zones';
import { useParams } from 'react-router-dom';

export type DropzoneSide = 'LEFT' | 'RIGHT' | undefined;

type ElementWrapperProps = {
  width?: number;
  height?: number;
  offsetLeft?: number;
  offsetTop?: number;
  setIsBeingHoveredWhenDragged: (hovered: boolean) => void;
  setElementNode: (el: React.RefObject<any> | undefined) => void;
  Element: ComponentType<any>;
  elementProps: ComponentProps;
  section?: string;
};

export const ElementWrapper = ({
  width,
  height,
  offsetLeft,
  offsetTop,
  setIsBeingHoveredWhenDragged,
  setElementNode,
  Element,
  elementProps,
  section
}: ElementWrapperProps) => {
  const elementNodeRef = React.createRef<HTMLElement>();
  const [dropzone, setDropzone] = useState<string | null>(null);
  const dropZoneRef = useRef<string | null>(null);
  const anchorZoneRef = useRef<string | null>(null);
  const [hovering, setHovering] = useState<boolean>(false);
  const [anchorZone, setAnchorZone] = useState<string | null>(null);
  const dispatch = useDispatch();
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const draggedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.draggedComponent
  );
  const linksRef = useRef(links);
  const { view_id } = useParams();

  useEffect(() => {
    linksRef.current = links;
  }, [links]);

  let leaveTimer: NodeJS.Timeout;

  useEffect(() => {
    setElementNode(elementNodeRef);
  }, [setElementNode, elementNodeRef]);

  useDropZone(
    elementNodeRef,
    elementProps.uuid,
    elementProps.type,
    elementProps.viewUUID,
    elementProps.parentUUID,
    elementProps.custom_uuid,
    COMPONENTS_MANIFEST[elementProps.type].hasSections ? section : undefined,
    handleGetAdjacentAreas,
    setIsBeingHoveredWhenDragged,
    handleGetAnchorZone
  );

  const handleClick = React.useCallback(
    (ev: any) => {
      if (
        ev.target.id.includes('component-selection-toolbar') ||
        ev.target.id.includes('component-selection-resizehandle') ||
        ev.target.id.includes('component-selection-label')
      )
        return;

      ev.stopPropagation();
      // If target component is a custom component do not allow drop nor select.
      if (elementProps.custom_uuid && editor !== Editor.CUSTOM_COMPONENT) {
        dispatch(setSelectedComponent(elementProps.new_custom_uuid ?? elementProps.uuid));
        dispatch(setSelectedView(null));
      } else {
        dispatch(setSelectedComponent(elementProps.uuid));
        dispatch(setSelectedView(null));
      }
    },
    [dispatch, editor, elementProps]
  );

  function handleGetAdjacentAreas(): string | null {
    setHovering(false);
    return dropZoneRef.current;
  }

  function handleGetAnchorZone(): string | null {
    setHovering(false);
    return anchorZoneRef.current;
  }

  const handleDragStart = React.useCallback(
    (ev: any) => {
      ev.stopPropagation();
      if (
        ev.srcElement.children['0'] &&
        ev.srcElement.children['0'].id.includes('component-selection-toolbar')
      ) {
        return;
      }

      if (ev.dataTransfer == null) return;
      let deletePastParent = false;
      if (
        elementProps.parentUUID &&
        components[elementProps.parentUUID]?.data.autoGenerated &&
        (!linksRef.current[elementProps.parentUUID] ||
          linksRef.current[elementProps.parentUUID]?.length === 1)
      )
        deletePastParent = true;

      ev.dataTransfer.setData(
        'exocode/move-source',
        JSON.stringify({
          parentUUID: elementProps.parentUUID ?? elementProps.viewUUID,
          uuid: elementProps.uuid,
          type: elementProps.type,
          section: section,
          deletePastParent: deletePastParent
        } as MoveSource)
      );

      dispatch(
        setDraggedComponent({
          componentId: elementProps.uuid,
          componentType: elementProps.type
        })
      );
    },
    [
      dispatch,
      elementProps.parentUUID,
      elementProps.type,
      elementProps.uuid,
      elementProps.viewUUID,
      section
    ]
  );

  const handleDragOver = React.useCallback(
    (ev: DragEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      const containerRect = (ev.currentTarget as HTMLElement)!.getBoundingClientRect();

      setIsBeingHoveredWhenDragged(true);
      setHovering(true);

      const newDropzone = elementProps.section
        ? null
        : handleCheckIfIsAdjacentZone(containerRect, ev.clientX, ev.clientY);

      if (newDropzone) {
        setAnchorZone(null);
        anchorZoneRef.current = null;
      }

      if (containerRect.width && containerRect.height && newDropzone === null) {
        handleCheckIfIsAnchorZone(
          containerRect.width,
          containerRect.height,
          ev.offsetX,
          ev.offsetY
        );
      }

      if (ev.dataTransfer == null) return;

      ev.dataTransfer.dropEffect = 'move';
    },
    [setIsBeingHoveredWhenDragged]
  );

  const handleCheckIfIsAdjacentZone = (
    containerRect: DOMRect,
    clientX: number,
    clientY: number
  ): 'top' | 'bottom' | 'left' | 'right' | null => {
    const leftAdjacentDropZone = containerRect.left + 20;
    const topAdjacentDropzone = containerRect.top + 20;
    const rightAdjacentDropZone = containerRect.left + containerRect.width - 20;
    const bottomAdjacentDropzone = containerRect.top + containerRect.height - 20;

    const newDropzone =
      clientY < topAdjacentDropzone
        ? 'top'
        : clientY > bottomAdjacentDropzone
        ? 'bottom'
        : clientX < leftAdjacentDropZone
        ? 'left'
        : clientX > rightAdjacentDropZone
        ? 'right'
        : null;

    setDropzone(newDropzone);
    dropZoneRef.current = newDropzone;
    return newDropzone;
  };

  const handleCheckIfIsAnchorZone = (
    width: number,
    height: number,
    offsetLeft: number,
    offsetTop: number
  ) => {
    const zoneWidth = width / 3;
    const zoneHeight = height / 3;

    let currentZone = null;

    const zones = [
      ['top-left', 'top-center', 'top-right'],
      ['middle-left', 'middle-center', 'middle-right'],
      ['bottom-left', 'bottom-center', 'bottom-right']
    ];

    const row = Math.floor(offsetTop / zoneHeight);
    const col = Math.floor(offsetLeft / zoneWidth);

    if (row >= 0 && row < zones.length && col >= 0 && col < zones[0].length) {
      currentZone = zones[row][col];
    }

    setAnchorZone(currentZone);
    anchorZoneRef.current = currentZone;
  };

  const handleDragLeave = React.useCallback(
    async (ev: any) => {
      if (ev.relatedTarget?.id === 'anchorPoint01') return;
      ev.preventDefault();
      ev.stopPropagation();
      setIsBeingHoveredWhenDragged(false);
      setHovering(false);
      clearTimeout(leaveTimer);
    },
    [setIsBeingHoveredWhenDragged, anchorZone]
  );

  // Limpeza do timer quando o componente é desmontado
  useEffect(() => {
    return () => {
      clearTimeout(leaveTimer);
    };
  }, []);

  const handleDragEnd = React.useCallback(() => {
    dispatch(setDraggedComponent(null));
    setAnchorZone(null);
  }, [dispatch]);

  const handleMouseOver = React.useCallback(
    (event: any) => {
      event.stopPropagation();
      if (elementProps.custom_uuid && editor !== 'CUSTOM_COMPONENT') {
        dispatch(setHoveredComponent(elementProps.new_custom_uuid ?? elementProps.uuid));
      } else {
        dispatch(setHoveredComponent(elementProps.uuid));
      }
    },
    [dispatch, editor, elementProps.custom_uuid, elementProps.new_custom_uuid, elementProps.uuid]
  );

  const handleMouseOut = React.useCallback(
    (event: any) => {
      event.stopPropagation();
      dispatch(setHoveredComponent(null));
    },
    [dispatch]
  );

  const handleDoubleClick = React.useCallback(
    (event: any) => {
      event.stopPropagation();
      if (elementProps.type === 'CUSTOM') return;
      if (elementProps.custom_uuid && editor !== 'CUSTOM_COMPONENT') return;
      dispatch(setSelectedView(null));
      dispatch(setSelectedComponent(elementProps.uuid, 'FOCUS_TEXT'));
    },
    [dispatch, editor, elementProps.custom_uuid, elementProps.type, elementProps.uuid]
  );

  const handleDragEnter = () => {
    // Cancela o timer se o mouse entra novamente na div principal antes que as divs laterais sejam ocultas
    clearTimeout(leaveTimer);
  };

  const isGridChild = () => {
    return (
      elementProps?.parentUUID &&
      components[elementProps.parentUUID] &&
      components[elementProps.parentUUID].type === COMPONENT_TYPES.GRID
    );
  };

  useEffect(() => {
    if (!elementNodeRef.current) return;

    const node = elementNodeRef.current;
    node.draggable = isGridChild() ? false : true;
    node.style.cursor = 'pointer';
    node.addEventListener('click', handleClick);
    node.addEventListener('dragover', handleDragOver);
    node.addEventListener('dragstart', handleDragStart);
    node.addEventListener('dragend', handleDragEnd);
    node.addEventListener('dragleave', handleDragLeave);
    node.addEventListener('dragenter', handleDragEnter);
    node.addEventListener('mouseover', handleMouseOver);
    node.addEventListener('mouseout', handleMouseOut);
    node.addEventListener('dblclick', handleDoubleClick);
    return () => {
      node.removeEventListener('click', handleClick);
      node.removeEventListener('dragover', handleDragOver);
      node.removeEventListener('dragstart', handleDragStart);
      node.removeEventListener('dragend', handleDragEnd);
      node.removeEventListener('dragleave', handleDragLeave);
      node.removeEventListener('dragenter', handleDragEnter);
      node.removeEventListener('mouseover', handleMouseOver);
      node.removeEventListener('mouseout', handleMouseOut);
      node.removeEventListener('dblclick', handleDoubleClick);
    };
  }, [
    elementNodeRef,
    handleClick,
    handleDoubleClick,
    handleDragEnd,
    handleDragLeave,
    handleDragOver,
    handleDragStart,
    handleMouseOut,
    handleMouseOver,
    anchorZone
  ]);

  return (
    <>
      {hovering &&
        draggedComponent?.componentId !== elementProps.uuid &&
        elementProps.type !== COMPONENT_TYPES.GRID &&
        !elementProps.section && (
          <AdjacenteDropzones
            elementProps={elementProps}
            width={width}
            height={height}
            offsetLeft={offsetLeft}
            offsetTop={offsetTop}
            dropzone={dropzone}
            elementRef={elementNodeRef}
          />
        )}

      {hovering &&
        !dropzone &&
        elementProps.type === COMPONENT_TYPES.CONTAINER &&
        draggedComponent?.componentId !== elementProps.uuid &&
        elementProps.viewUUID &&
        views[elementProps.viewUUID].editorMode === EditorModeOptions.NORMAL && (
          <AnchorAutomationZones
            width={width}
            height={height}
            offsetLeft={offsetLeft}
            offsetTop={offsetTop}
            setAnchorZone={setAnchorZone}
            hoveredAnchorZone={anchorZone}
          />
        )}
      <Element ref={elementNodeRef} {...elementProps} />
    </>
  );
};
