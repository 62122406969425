import React, { Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';
import styles from './style.module.css';

interface AnchorAutomationZonesProps {
  setAnchorZone: Dispatch<SetStateAction<string | null>>;
  width?: number;
  height?: number;
  offsetLeft?: number;
  offsetTop?: number;
  hoveredAnchorZone?: string | null;
}

function AnchorAutomationZones({
  setAnchorZone,
  width = 0,
  height = 0,
  offsetLeft = 0,
  offsetTop = 0,
  hoveredAnchorZone
}: AnchorAutomationZonesProps) {
  const [anchorStyle, setAnchorStyle] = useState<{ [key: string]: React.CSSProperties }>({});

  const createAnchorZone = useCallback(
    (row: number, col: number) => ({
      left: (col * width) / 3,
      top: (row * height) / 3,
      width: width / 3,
      height: height / 3
    }),
    [width, height]
  );

  useEffect(() => {
    const zones: any = {};
    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < 3; col++) {
        const zoneKey = `${row}-${col}`;
        zones[zoneKey] = createAnchorZone(row, col);
      }
    }

    setAnchorStyle(zones);
  }, [createAnchorZone]);

  const renderAnchor = useCallback(
    (zoneKey: string, className: string) => {
      const anchorMapper: Record<string, string> = {
        '0-0': 'top-left',
        '0-1': 'top-center',
        '0-2': 'top-right',
        '1-0': 'middle-left',
        '1-1': 'middle-center',
        '1-2': 'middle-right',
        '2-0': 'bottom-left',
        '2-1': 'bottom-center',
        '2-2': 'bottom-right'
      };

      return (
        <div
          className={`d-absolute ${className} ${styles.automaticAnchor}`}
          style={anchorStyle[zoneKey]}
        >
          <div
            className={`${styles.anchorPoint} ${
              anchorMapper[zoneKey] === hoveredAnchorZone ? styles.selectedAnchorPoint : null
            }`}
          ></div>
        </div>
      );
    },
    [anchorStyle, hoveredAnchorZone]
  );

  return (
    <div
      style={{
        position: 'absolute',
        width: `${width}px`,
        height: `${height}px`,
        top: offsetTop,
        left: offsetLeft,
        pointerEvents: 'none',
        zIndex: 2
      }}
    >
      {renderAnchor('0-0', '')}
      {renderAnchor('0-1', '')}
      {renderAnchor('0-2', '')}
      {renderAnchor('1-0', '')}
      {renderAnchor('1-1', '')}
      {renderAnchor('1-2', '')}
      {renderAnchor('2-0', '')}
      {renderAnchor('2-1', '')}
      {renderAnchor('2-2', '')}
    </div>
  );
}

export default AnchorAutomationZones;
