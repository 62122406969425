import React from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DATA_TYPES, DataType, ObjectSimple } from 'modules/logic_builder/types';
import { useTranslation } from 'react-i18next';
import { Property } from './editor_utils';
import { EnumFrame, Relationship } from 'modules/modeler/types';
import { objectItemRegex } from '../../../../utils/regex';
import { isEmptyOrOnlySpaces } from '../../../../utils/inputValidation';

type PropertyEditorProps = {
  selectedProperty: Property | undefined;
  relationships: Relationship[];
  handleUpdateProperty: (property: Property) => void;
  objects: ObjectSimple[];
  isObjectComplete: boolean;
  enums: EnumFrame[];
  handleDeleteProperty: (property: string) => void;
  selectedTable: string | undefined;
  showExported: boolean;
};

export function PropertyEditor({
  selectedProperty,
  handleUpdateProperty,
  objects,
  isObjectComplete,
  enums,
  handleDeleteProperty,
  relationships,
  selectedTable,
  showExported
}: PropertyEditorProps) {
  const { t } = useTranslation();

  function updateProperty<T extends keyof Property>(propertyKey: T, value: Property[T]): void {
    if (!selectedProperty) return;

    const property = { ...selectedProperty };

    if (propertyKey === 'type') {
      if (value === 'OBJECT') {
        property.type = value;
        property.object = objects[0].uuid;
        property.objectName = objects[0].name;
        property.enum = undefined;
      } else if (value === 'ENUM') {
        property.type = value;
        property.enum = enums[0].uuid;
        property.enumName = enums[0].content.data.name;
        property.object = undefined;
      } else {
        property.object = undefined;
        property.enum = undefined;
      }
    }

    if (propertyKey === 'object') {
      const findObject = objects.find((o) => o.uuid === value);
      if (findObject !== undefined) {
        property.objectName = findObject.name;
      } else {
        // Composite property derived from a relationship (can be related to one/many columns)
        const relationship = relationships
          .filter((rel) => rel.id === property.relationshipId)
          .pop();
        if (relationship !== undefined && property.entityId !== undefined) {
          const fromToName =
            relationship.from === property.entityId ? relationship.toName : relationship.fromName;
          property.objectName = isEmptyOrOnlySpaces(fromToName) ? property.entityName : fromToName;
        } else {
          property.objectName = property.entityName;
        }
      }
    }

    if (propertyKey === 'enum') {
      const findEnum = enums.find((e) => e.uuid === value);
      property.enumName = findEnum?.content.data.name;
    }

    property[propertyKey] = value;
    handleUpdateProperty(property);
  }

  const getDataType = (): DataType | undefined => {
    if (isObjectComplete && selectedProperty?.object) {
      return 'OBJECT';
    }

    return selectedProperty?.type;
  };

  return (
    <>
      <div className={`${styles.PropertyEditorWrapper}`}>
        <Form.Group className={`mb-2`} as={Row} controlId="propertyName">
          <Form.Label column sm={3} className={styles.SmallFont}>
            {t('logicBuilder.objectsTab.Name')}
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              value={selectedProperty?.name ?? ''}
              type="text"
              size="sm"
              pattern={objectItemRegex}
              onChange={(e) => updateProperty('name', e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group className={`mb-2`} as={Row} controlId="propertyDescription">
          <Form.Label column sm={3} className={styles.SmallFont}>
            {t('logicBuilder.objectsTab.Description')}
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              value={selectedProperty?.description ?? ''}
              as="textarea"
              size="sm"
              onChange={(e) => updateProperty('description', e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group className={`mb-2`} as={Row} controlId="propertyType">
          <Form.Label column sm={3} className={styles.SmallFont}>
            {t('logicBuilder.objectsTab.Type')}
          </Form.Label>
          <Col sm={9}>
            <Form.Select
              size="sm"
              value={getDataType()}
              disabled={selectedProperty?.fromEntity}
              onChange={(e) => updateProperty('type', e.target.value as DataType)}
            >
              {Object.keys(DATA_TYPES).map((dataType) => {
                let disabled = false;
                if (dataType === 'ENUM' && !enums.length) {
                  disabled = true;
                } else if (dataType === 'OBJECT' && !objects.length) {
                  disabled = true;
                }

                return (
                  <option id={dataType} key={dataType} value={dataType} disabled={disabled}>
                    {DATA_TYPES[dataType as DataType]}
                  </option>
                );
              })}
            </Form.Select>

            <Form.Check
              id={'listPropertyCheck'}
              style={{ marginTop: '10px' }}
              type="checkbox"
              label={t('logicBuilder.objectsTab.List')}
              checked={selectedProperty?.list ?? false}
              className={styles.SmallFont}
              onChange={(e) => updateProperty('list', e.target.checked)}
              disabled={selectedProperty?.fromEntity}
            />
          </Col>
        </Form.Group>

        {selectedProperty?.type === 'ENUM' && (
          <Form.Group className={`mb-2`} as={Row} controlId="propertyEnum">
            <Form.Label column sm={3} className={styles.SmallFont}>
              {t('logicBuilder.objectsTab.Enum')}
            </Form.Label>
            <Col sm={9}>
              <Form.Select
                size="sm"
                value={selectedProperty?.enum}
                onChange={(e) => updateProperty('enum', e.target.value)}
              >
                {enums.find((e) => e.uuid === selectedProperty?.enum) === undefined && (
                  <option value="">---</option>
                )}
                {enums.map((e) => {
                  return (
                    <option key={e.uuid} value={e.uuid}>
                      {e.content.data.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Form.Group>
        )}

        {selectedProperty?.type === 'OBJECT' && (
          <>
            <Form.Group className={`mb-2`} as={Row} controlId="propertyObject">
              <Form.Label column sm={3} className={styles.SmallFont}>
                {t('logicBuilder.objectsTab.Object')}
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  size="sm"
                  value={selectedProperty?.object}
                  onChange={(e) => updateProperty('object', e.target.value)}
                >
                  {selectedProperty?.fromEntity && (
                    <option key={selectedProperty.entityId} value={selectedProperty.entityId}>
                      ---
                    </option>
                  )}
                  {objects
                    .filter((object) => {
                      if (!selectedProperty?.fromEntity) return true;
                      return (
                        selectedProperty?.fromEntity &&
                        selectedProperty.entityId === object.entityUuid
                      );
                    })
                    .map((object) => {
                      return (
                        <option key={object.uuid} value={object.uuid}>
                          {object.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Col>
            </Form.Group>

            {selectedTable && (
              <Form.Group className={`mb-2`} as={Row} controlId="propertyObject">
                <Form.Label column sm={3} className={styles.SmallFont}>
                  {t('modeler.entity_editor.Relations')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Select
                    size="sm"
                    value={selectedProperty?.relationshipId}
                    onChange={(e) => updateProperty('relationshipId', e.target.value)}
                  >
                    <option value="">---</option>
                    {relationships
                      .filter((relation) => {
                        return selectedTable === relation.from || selectedTable === relation.to;
                      })
                      .map((relation) => {
                        return (
                          <option key={relation.id} value={relation.id}>
                            {relation.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Form.Group>
            )}
          </>
        )}

        {/* When object is complete and property is object let user choose object name. */}
        {isObjectComplete &&
          selectedProperty?.fromEntity &&
          selectedProperty?.entityId &&
          selectedProperty?.relationshipId && (
            <Form.Group className={`mb-2`} as={Row} controlId="propertyObject">
              <Form.Label column sm={3} className={styles.SmallFont}>
                {t('automation.step2.CustomObjectDialog.ObjectName')}
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  value={selectedProperty?.objectName ?? ''}
                  type="text"
                  size="sm"
                  onChange={(e) => updateProperty('objectName', e.target.value)}
                  disabled={selectedProperty?.object !== selectedProperty?.entityId}
                />
              </Col>
            </Form.Group>
          )}

        <Form.Group className={`mb-2`} id="readOnlyProperty">
          <Form.Check
            id={'readOnlyPropertyCheck'}
            type="checkbox"
            label={t('logicBuilder.objectsTab.ReadOnly')}
            checked={selectedProperty?.readOnly ?? false}
            className={styles.SmallFont}
            onChange={(e) => updateProperty('readOnly', e.target.checked)}
          />
        </Form.Group>

        {showExported && (
          <Row className={`mb-2`}>
            <Col sm={3} className={`${styles.SmallFont}`}>
              <Form.Check
                id={'exportedCheck'}
                type="checkbox"
                label={t('logicBuilder.objectsTab.Exported')}
                checked={selectedProperty?.exported ?? false}
                onChange={(e) => updateProperty('exported', e.target.checked)}
              />
            </Col>

            <Col sm={9}>
              <Form.Control
                id={'exportedName'}
                value={selectedProperty?.exported === true ? selectedProperty?.exportedName : ''}
                type="text"
                className={`${styles.SmallFont}`}
                placeholder={t('logicBuilder.objectsTab.ExportedName') || ''}
                disabled={selectedProperty?.exported === false}
                onChange={(e) => updateProperty('exportedName', e.target.value)}
                pattern={objectItemRegex}
              />
              <Form.Text id="passwordHelpBlock" muted>
                {t('logicBuilder.objectsTab.ExportedHelpText')}
              </Form.Text>
            </Col>
          </Row>
        )}

        {selectedProperty && !selectedProperty.fromEntity && (
          <Button
            id="deleteButton"
            variant="outline-danger"
            onClick={() => {
              handleDeleteProperty(selectedProperty.id);
            }}
          >
            <i className={'fa fa-trash text-danger'} style={{ marginRight: '8px' }} />
            {t('logicBuilder.objectsTab.Remove')}
          </Button>
        )}
      </div>
    </>
  );
}
