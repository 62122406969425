import React, { useState } from 'react';
import styles from './styles.module.css';
import { Container, Image } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ChooseNewPassword from './components/choose_new_password';
import PasswordResetForm from './components/password_reset_form';
import useTitle from 'hooks/useTitle';
import { useTranslation } from 'react-i18next';
import Navbar from 'web_ui/navbar';

/**
 * Password reset process
 *
 * @component
 * @route /password_reset
 */
function PasswordReset() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | null>(null);
  React.useEffect(() => {
    setToken(searchParams.get('token'));
  }, [searchParams]);

  useTitle(t('tab.pass_reset'));

  return (
    <div className={styles.PageWrap}>
      <Navbar context="login" />
      <Container className={styles.ResetPasswordContainer}>
        <Container className={styles.FormCard}>
          {!token && (
            <>
              {/* Password Reset form */}
              <PasswordResetForm />
            </>
          )}
          {token && (
            <>
              {/* Change Password form */}
              <ChooseNewPassword token={token} />
            </>
          )}
        </Container>
      </Container>
    </div>
  );
}

export default PasswordReset;
