import React from 'react';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import BeginElseIcon from './begin_else_icon';

export const BEGIN_ELSE_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.BEGIN_ELSE,
  name: 'Else',
  description: 'Action to start an "Else" statement',
  color: 'secondary',
  category: BACKEND_ACTIONS_CATEGORIES.CONDITION,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.CONDITION,
  icon: <BeginElseIcon />,
  openBlock: true,
  inlineInputs: [],
  beginElse: true,
  inputs: []
};
