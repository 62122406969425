import React from 'react';
import styles from './styles.module.css';
import { Button, ButtonGroup, ButtonToolbar, Form } from 'react-bootstrap';
import { Table } from 'modules/modeler/types';
import { Property } from './editor_utils';
import { useParams } from 'react-router-dom';
import LoaderSimple from './loader_simple/loader';
import { useTranslation } from 'react-i18next';

type ActionsToolProps = {
  handleAddEmptyProperty: () => void;
  selectedTable: Table | undefined;
  selectedProperty: Property | undefined;
  tables: Table[];
  handleSelectRootTable: (
    table: string,
    isObjectComplete: boolean,
    showOldProperties: boolean
  ) => void;
  handleUnselectTable: () => void;
  editMode: boolean;
  toggleIsObjectComplete: (isComplete: boolean) => void;
  isObjectComplete: boolean;
  preSelectedEntityId?: string;
  entityId?: string;
  loadingData?: boolean;
  fieldErrorMessage?: string;
};

export function ActionsTool({
  handleAddEmptyProperty,
  selectedTable,
  tables,
  handleSelectRootTable,
  handleUnselectTable,
  editMode,
  toggleIsObjectComplete,
  isObjectComplete,
  preSelectedEntityId,
  entityId,
  loadingData,
  fieldErrorMessage
}: ActionsToolProps) {
  const { module_id } = useParams();
  const [ignore, setIgnore] = React.useState<boolean>(editMode);
  const { t } = useTranslation();

  const selectTable = (tableId: string) => {
    if (tableId === 'no-entity') {
      handleUnselectTable();
    } else {
      handleSelectRootTable(tableId, isObjectComplete, true);
    }
    setIgnore(false);
  };

  // no edit mode, o rootObject estava sendo feito o set com o id do object
  // no new mode estava sendo feito o set com o id da entity
  // por isso passei a entity para baixo tmb
  const selectingValue = () => {
    if (editMode && ignore) {
      return entityId;
    } else {
      return selectedTable && selectedTable.uuid ? selectedTable.uuid : 'no-entity';
    }
  };

  return (
    <div className={styles.ActionsToolbar}>
      <ButtonToolbar aria-label="Object toolbar actions">
        <ButtonGroup className="me-2 mb-2" style={{ height: '2rem' }}>
          <Button
            id={'createProprietiesButton'}
            onClick={handleAddEmptyProperty}
            variant="outline-success"
            className={styles.ToolbarButtons}
          >
            <i className={'fa fa-plus'} />
          </Button>
          <Form.Group controlId="selectEntity">
            <Form.Select
              disabled={editMode}
              className={`${styles.SelectTable}`}
              value={selectingValue()}
              onChange={(e) => selectTable(e.target.value)}
              style={{
                maxWidth: 150,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              <option value={'no-entity'}>---</option>
              {tables
                .filter((table) => module_id === table.moduleId)
                .map((table) => {
                  return (
                    <option key={table.uuid} value={table.uuid}>
                      {table.content.data.name}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
          {loadingData && <LoaderSimple />}
          {!editMode && selectedTable?.uuid && (
            <Form.Group className={`mb-2`} id="isObjectComplete">
              <Form.Check
                id={'ObjectCompleteCheckbox'}
                type="checkbox"
                label={'Com Ligações'}
                checked={isObjectComplete}
                className={`${styles.SmallFont} ${styles.ObjectCompleteCheckbox}`}
                onChange={(e) => toggleIsObjectComplete(e.target.checked)}
              />
            </Form.Group>
          )}
        </ButtonGroup>
      </ButtonToolbar>
      {fieldErrorMessage && (
        <div style={{ marginBottom: '0.5rem' }}>
          <span className={styles.errorMsg}>{fieldErrorMessage}</span>
        </div>
      )}
    </div>
  );
}
