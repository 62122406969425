import styles from './styles.module.css';
import { Button, Form } from 'react-bootstrap';
import { Column, columnID, Index, IndexID, IndexType, TableUUID } from 'modules/modeler/types';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sleep } from '../../../../../../../../../utils/utils';
import { DatabaseStudioState } from '../../../../../../store';
import { changeIndexProperty } from '../../../../../../store/actions/indexes';
import IndexColumnsList from './index_columns_list';
import { deleteIndex } from '../../../../../../store/actions/root';
import Confirmation from '../../../../../../../../../web_ui/confirmation';
import { setErrorMessage } from '../../../../../../store/actions/studio';

type IndexEditorProps = {
  selectedTable: TableUUID;
  selectedIndex: IndexID;
  selectIndex: React.Dispatch<React.SetStateAction<string>>;
};

export default function IndexEditor(props: IndexEditorProps) {
  const indexes = useSelector((state: DatabaseStudioState) => state.indexes);
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const [index, setIndex] = useState<Index>({} as Index);
  const [hasPrimaryIndex, setHasPrimaryIndex] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [previousName, setPreviousName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!indexes[props.selectedIndex]) return;
    setIndex(indexes[props.selectedIndex]);
  }, [props.selectedIndex, indexes]);

  useEffect(() => {
    const findIndex = tables[props.selectedTable].content.data.indexes.find((id: string) => {
      return indexes[id] && indexes[id].type === 'PRIMARY';
    });
    if (findIndex) {
      setHasPrimaryIndex(true);
    } else {
      setHasPrimaryIndex(false);
    }
  }, [indexes, props.selectedTable, tables]);

  const handleUpdateName = (e: ChangeEvent<any>) => {
    e.stopPropagation();
    if (!index.name || index.name.length < 2) {
      dispatch(setErrorMessage('modeler.NameTooShortMessage'));
      return setIndex({ ...index, name: previousName });
    }
    if (!isNameValid(e.target.value)) {
      dispatch(setErrorMessage('modeler.IllegalNameCharacters'));
      return setIndex({ ...index, name: previousName });
    }
    setPreviousName(e.target.value);
    dispatch(changeIndexProperty(index.id, 'name', index.name));
  };

  const handleUpdateDescription = () => {
    dispatch(changeIndexProperty(index.id, 'description', index.description));
  };

  const isNameValid = (name: string) => {
    const regex = /^[a-zA-Z_][0-9a-zA-Z_]*$/;
    return regex.test(name);
  };

  const handleUpdateType = (e: ChangeEvent<any>) => {
    e.stopPropagation();
    if (!index.type) return;
    dispatch(changeIndexProperty(props.selectedIndex, 'type', index.type));
  };

  const handleChangeIndexType = async (e: ChangeEvent<any>) => {
    setIndex({ ...index, type: e.target.value });
    if (e.target.value === 'PRIMARY') {
      await sleep(100);
      if (!index.type) return;
      dispatch(changeIndexProperty(props.selectedIndex, 'type', e.target.value));
    }
  };

  const renderIndexOptions = () => {
    return (
      indexes[props.selectedIndex] &&
      Object.values(IndexType)
        .filter((v) => isNaN(Number(v)))
        .filter(handleFilterPrimaryType)
        .map((type, index) => {
          return (
            <option key={type + index} value={type}>
              {type}
            </option>
          );
        })
    );
  };

  const handleFilterPrimaryType = (type: string) => {
    if (type !== 'PRIMARY') return true;
    if (hasPrimaryIndex) {
      return indexes[props.selectedIndex].type === 'PRIMARY';
    } else {
      return true;
    }
  };

  const getTableColumns = () => {
    const tableColumns: Column[] = [];
    tables[props.selectedTable].content.data.columns
      .filter((columnID: columnID) => !!columns[columnID])
      .forEach((columnID: columnID) => {
        tableColumns.push(columns[columnID]);
      });
    return tableColumns;
  };

  const handleDeleteIndex = async () => {
    if (!props.selectedIndex) return;
    if (!canDeleteIndex(props.selectedIndex)) {
      dispatch(setErrorMessage('modeler.CannotDeleteIndex'));
      setShowDeleteModal(false);
      return;
    }

    // delete de index from context
    dispatch(deleteIndex(props.selectedIndex, props.selectedTable));
    // shift focus to the first index if it exists
    // ?
    await sleep(100);
    indexes[tables[props.selectedTable].content.data.indexes[0]] &&
      props.selectIndex(tables[props.selectedTable].content.data.indexes[0]);
    setShowDeleteModal(false);
  };

  const canDeleteIndex = (indexID: IndexID): boolean => {
    const tableIndexes = Object.values(tables[props.selectedTable].content.data.indexes);
    const indexType = indexes[indexID]?.type;

    if (tableIndexes.length < 2) return false;
    if (indexType !== IndexType.PRIMARY) return true;

    const primaryIndexes = tableIndexes.filter((id) => {
      const index = indexes[id as string];
      return index?.type === IndexType.PRIMARY && Object.values(index.columns).length !== 0;
    });

    return primaryIndexes.length > 1;
  };

  const handleDescriptionChange = (value: string): void => {
    if (value.length > 255) {
      dispatch(setErrorMessage('modeler.NameTooLongMessage'));
      return;
    }
    setIndex({ ...index, description: value });
  };

  return (
    <>
      <div className={styles.IndexEditorWrapper}>
        <Form.Group className="mb-2" style={{ height: '70px' }} controlId="columnName">
          <Form.Label className={styles.SmallFont}>{t('Name') ?? ''}</Form.Label>
          <Form.Control
            value={index.name}
            placeholder={t('Name') ?? ''}
            type="text"
            size="sm"
            onFocus={() => setPreviousName(index.name)}
            onBlur={(e) => handleUpdateName(e)}
            onChange={(e) => {
              if (e.target.value.length > 64) {
                return dispatch(setErrorMessage('modeler.NameTooLongMessage'));
              }
              setIndex({ ...index, name: e.target.value });
            }}
            onKeyDown={(e) => e.key === 'Enter' && handleUpdateName(e)}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formDescription">
          <Form.Label className={styles.SmallFont}>{t('Description') ?? ''}</Form.Label>
          <Form.Control
            value={index.description}
            as="textarea"
            size="sm"
            onBlur={() => handleUpdateDescription()}
            onChange={(e) => handleDescriptionChange(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2" style={{ height: '70px' }} controlId="selectTypeIndexes">
          <Form.Label className={styles.SmallFont}>{t('modeler.Type')}</Form.Label>
          <Form.Select
            placeholder={t('modeler.Type') ?? ''}
            value={index.type}
            onChange={(e) => handleChangeIndexType(e)}
            onKeyDown={(e) => e.key === 'Enter' || (e.key === 'Escape' && handleUpdateType(e))}
            onBlur={(e) => handleUpdateType(e)}
            disabled={index.type === 'PRIMARY'}
          >
            {renderIndexOptions()}
          </Form.Select>
        </Form.Group>
        <IndexColumnsList
          tableColumns={getTableColumns()}
          indexColumns={index?.columns}
          indexID={props.selectedIndex}
        />
        <Button
          id={'deleteButton'}
          onClick={() => setShowDeleteModal(true)}
          variant="outline-danger"
          className={'mt-2'}
          style={{ height: '38px', alignSelf: 'flex-start' }}
        >
          {t('modeler.Remove')}
        </Button>
      </div>
      <Confirmation
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirmation={() => handleDeleteIndex()}
        message={
          t('modeler.Delete Column') +
          ' (' +
          (indexes[props.selectedIndex] ? indexes[props.selectedIndex].name : '') +
          ')'
        }
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
}
