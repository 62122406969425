import { PaginationData, PaginationStyles } from './index';
import { LayoutComponent } from 'modules/designer/types';

export const PAGINATION_TEMPLATE: LayoutComponent<PaginationData, PaginationStyles> = {
  uuid: 'empty',
  type: 'PAGINATION',
  data: {},
  styles: {
    size: 'sm'
  }
};
