import * as React from 'react';
import { ActionInputProps } from 'web_ui/function_editor/store/types/manifestsAndInputs';
import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import useSession from 'hooks/useSession';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { updateAction } from 'web_ui/function_editor/store/events/actions/update_action';
import useWindowDimensions from 'modules/designer/hooks/window';

function TextAreaInput({
  label,
  placeholder,
  actionUuid,
  dataKey,
  options,
  inputValidation
}: ActionInputProps) {
  const dispatch = useDispatch();
  const isDarkTheme = useSession()[0].preferences['exocode-theme'];
  const state = useSelector((state: FunctionEditorState) => state);
  const action = state.actions[actionUuid];
  const [inputValue, setInputValue] = React.useState('');
  const [isValid, setIsValid] = React.useState<boolean>(true);

  React.useEffect(() => {
    setInputValue(action?.data[dataKey]);
  }, [action, dataKey]);

  const handleChange = (value: any) => {
    setInputValue(value);
    let inputIsValid = true;
    if (inputValidation) {
      const validationOutput = inputValidation(value);
      inputIsValid = validationOutput.valid;
    } else if (options?.validator) {
      inputIsValid = inputIsValid && !!options.validator(state, value);
    }
    if (inputIsValid) {
      dispatch(updateAction(actionUuid, dataKey, value));
    }
    setIsValid(inputIsValid);
  };

  const handleBlur = () => {
    setIsValid(true);
    setInputValue(action?.data[dataKey] ?? '');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <textarea
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onBlur={handleBlur}
        className={`${
          isDarkTheme ? styles.InputDarkThemeVariables : styles.InputLightThemeVariables
        } ${styles.ActionInput} ${!isValid && styles.InvalidInput}`}
        placeholder={placeholder}
        autoComplete={'off'}
        value={inputValue}
        style={{
          width: '100%',
          height: 100
        }}
      />
    </div>
  );
}

export default TextAreaInput;
