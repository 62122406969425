import { Action } from 'redux';
import {
  EditorModeOptions,
  EventTypes,
  FrontendFunctionUUID,
  View,
  ViewUUID
} from '../../../types';
import { ViewStateResponse } from 'modules/designer/repos/schemas/ViewState';
import { ViewData, ViewStyles } from '../../frames/page_view';

export const SET_VIEWS = 'SET_VIEWS';
// export const SET_POSITION = 'SET_POSITION';
export const CHANGE_VIEW_PROPERTY = 'CHANGE_VIEW_PROPERTY';
export const CHANGE_VIEW_STYLE = 'CHANGE_VIEW_STYLE';
export const ATTACH_MODAL = 'ATTACH_MODAL';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const ADD_EVENT = 'ADD_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const CHANGE_EDITOR_MODE = 'CHANGE_EDITOR_MODE';

export interface SetViewsAction extends Action {
  type: 'SET_VIEWS';
  payload: {
    views: { [key: ViewUUID]: View };
  };
}

// export interface SetPositionAction extends Action {
//   type: 'SET_POSITION';
//   payload: {
//     uuid: ViewUUID;
//     posX: number;
//     posY: number;
//   };
// }

export interface ChangeViewPropertyAction extends Action {
  type: 'CHANGE_VIEW_PROPERTY';
  payload: {
    uuid: ViewUUID;
    key: keyof ViewData;
    value: any;
  };
}

export interface ChangeViewStyleAction extends Action {
  type: 'CHANGE_VIEW_STYLE';
  payload: {
    uuid: ViewUUID;
    key: keyof ViewStyles;
    value: any;
  };
}

export interface AttachModalAction extends Action {
  type: 'ATTACH_MODAL';
  payload: {
    uuid: ViewUUID;
    modal_uuid: ViewUUID;
  };
}

export interface ChangeLayoutAction extends Action {
  type: 'CHANGE_LAYOUT';
  payload: {
    uuid: ViewUUID;
    layout_uuid: ViewUUID;
    layout?: ViewStateResponse;
  };
}

export interface AddEventAction extends Action {
  type: 'ADD_EVENT';
  payload: {
    view: ViewUUID;
    event: EventTypes;
    function: FrontendFunctionUUID;
  };
}

export interface DeleteEventAction extends Action {
  type: 'DELETE_EVENT';
  payload: {
    view: ViewUUID;
    event: EventTypes;
  };
}

export interface ChangeEditorModeAction extends Action {
  type: 'CHANGE_EDITOR_MODE';
  payload: {
    uuid: ViewUUID;
    value: EditorModeOptions;
  };
}

export const setViews = (views: { [key: ViewUUID]: View }): SetViewsAction => ({
  type: SET_VIEWS,
  payload: { views }
});

// export const setPosition = (uuid: ViewUUID, posX: number, posY: number): SetPositionAction => ({
//   type: SET_POSITION,
//   payload: { uuid, posX: posX, posY: posY }
// });

export const changeViewProperty = (
  uuid: ViewUUID,
  key: keyof ViewData,
  value: any
): ChangeViewPropertyAction => ({
  type: CHANGE_VIEW_PROPERTY,
  payload: { uuid, key, value }
});

export const changeViewStyle = (
  uuid: ViewUUID,
  key: keyof ViewStyles,
  value: any
): ChangeViewStyleAction => ({
  type: CHANGE_VIEW_STYLE,
  payload: { uuid, key, value }
});

export const attachModal = (uuid: ViewUUID, modal_uuid: ViewUUID): AttachModalAction => ({
  type: ATTACH_MODAL,
  payload: { uuid, modal_uuid }
});

export const changeLayout = (
  uuid: ViewUUID,
  layout_uuid: ViewUUID,
  layout?: ViewStateResponse
): ChangeLayoutAction => ({
  type: CHANGE_LAYOUT,
  payload: { uuid, layout_uuid, layout }
});

export const addEvent = (
  view: ViewUUID,
  event: EventTypes,
  functionUuid: FrontendFunctionUUID
): AddEventAction => ({
  type: ADD_EVENT,
  payload: { view, event, function: functionUuid }
});

export const deleteEvent = (view: ViewUUID, event: EventTypes): DeleteEventAction => ({
  type: DELETE_EVENT,
  payload: { view, event }
});

export const changeEditorMode = (
  uuid: ViewUUID,
  value: EditorModeOptions
): ChangeEditorModeAction => ({
  type: CHANGE_EDITOR_MODE,
  payload: { uuid, value }
});
