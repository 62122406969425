import {
  StructToSaveFunctionCrud,
  StructToSaveFunctionResponse,
  functionEditorCrud
} from 'web_ui/function_editor/store/types/functions';
import {
  FunctionSimple,
  FunctionType,
  FunctionExtended,
  FunctionParameter,
  FunctionVariable,
  Action,
  FunctionReturn
} from '../types';
import { ApiError } from '../../project/types';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class FunctionsRepo {
  /**
   * Create a function for a specific module.
   */
  async createFunction(
    moduleId: string,
    functionProperties: FunctionSimple
  ): Promise<FunctionType> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(functionProperties)
    };

    const url = `${API_URL}/modules/${moduleId}/functions`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionType>;

      throw new Error('Something went wrong while trying to create function.');
    });
  }

  async createFunctionCrud(
    moduleId: string,
    entityId: string,
    data: StructToSaveFunctionCrud
  ): Promise<StructToSaveFunctionResponse> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(data)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/functions`;
    const response = await fetch(url, options);

    if (response.ok) {
      return await response.json();
    } else if (response.status === 409) {
      const apiError: ApiError = await response.json();
      throw new Error(apiError.message);
    }
    throw new Error('Something went wrong while trying to create function.');
  }

  /**
   * Returns a list of functions from a specific module.
   */
  async getFunctionsByModule(moduleId: string): Promise<FunctionType[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/functions`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionType[]>;

      throw new Error('Something went wrong while trying to get functions.');
    });
  }

  async getFunctionByServiceId(serviceId: string, moduleId: string): Promise<functionEditorCrud[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/service/${serviceId}/functions`;

    return await fetch(url, options).then((response) => {
      if (response.ok) {
        return response.json() as Promise<functionEditorCrud[]>;
      }

      throw new Error('Something went wrong while trying to get functions.');
    });
  }

  /**
   * Returns the information of specific Function.
   */
  async getFunction(functionId: string): Promise<FunctionExtended> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/functions/${functionId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionExtended>;

      throw new Error('Something went wrong while trying to get function.');
    });
  }

  /**
   * Updates the information of a specific function.
   */
  async updateFunction(
    functionId: string,
    functionProperties: FunctionType
  ): Promise<FunctionType> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(functionProperties)
    };

    const url = `${API_URL}/functions/${functionId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionType>;

      throw new Error('Something went wrong while trying to update function.');
    });
  }

  /**
   * Deletes a specific function and all related information.
   */
  async deleteFunction(functionId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/functions/${functionId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete function.');
    });
  }

  async checkDuplicateSignature(functionId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST'
    };

    const url = `${API_URL}/functions/${functionId}/signature`;
    const response = await fetch(url, options);
    if (!response.ok) {
      return false;
    } else {
      const res = await response.text();
      return res === 'true';
    }
  }

  /**
   * Creates a parameter for a specific function.
   */
  async createParameter(
    functionId: string,
    parameter: FunctionParameter
  ): Promise<FunctionParameter> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(parameter)
    };

    const url = `${API_URL}/functions/${functionId}/parameters`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionParameter>;

      throw new Error('Something went wrong while trying to create a function parameter.');
    });
  }

  /**
   * Returns the information of the parameters for a specific Function.
   */
  async getParameters(functionId: string): Promise<FunctionParameter[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/functions/${functionId}/parameters`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionParameter[]>;

      throw new Error('Something went wrong while trying to get function parameters.');
    });
  }

  /**
   * Updates the information of a parameter of a specific function.
   */
  async updateParameter(
    functionId: string,
    parameterId: string,
    parameter: FunctionParameter
  ): Promise<FunctionParameter> {
    const functionParameterSchema = parameter as FunctionParameter;

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(functionParameterSchema)
    };

    const url = `${API_URL}/functions/${functionId}/parameters/${parameterId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionParameter>;

      throw new Error('Something went wrong while trying to update function parameter.');
    });
  }

  /**
   * Deletes a specific function parameter and all related information.
   */
  async deleteParameter(functionId: string, parameterId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/functions/${functionId}/parameters/${parameterId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete function parameter.');
    });
  }

  /**
   * Returns the variables set to this function.
   */
  async getFunctionVariables(functionId: string): Promise<FunctionVariable[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/functions/${functionId}/variables`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionVariable[]>;

      throw new Error('Something went wrong while trying to get function variables.');
    });
  }

  /**
   * Create a variable to this function.
   */
  async createFunctionVariable(
    functionId: string,
    variable: FunctionVariable
  ): Promise<FunctionVariable> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(variable)
    };

    const url = `${API_URL}/functions/${functionId}/variables`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionVariable>;

      throw new Error('Something went wrong while trying to create a function variable.');
    });
  }

  /**
   * Updates the information of a variable of a Function.
   */
  async updateFunctionVariable(
    functionId: string,
    variable: FunctionVariable
  ): Promise<FunctionVariable> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(variable)
    };

    const url = `${API_URL}/functions/${functionId}/variables/${variable.uuid}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionVariable>;

      throw new Error('Something went wrong while trying to update a function variable.');
    });
  }

  /**
   * Delete a specific function variable.
   */
  async deleteFunctionVariable(functionId: string, variableId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/functions/${functionId}/variables/${variableId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete a function variable.');
    });
  }

  /**
   * Returns the information of the actions executed by the Function.
   */
  async getFunctionActions(functionId: string): Promise<Action<any>[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/functions/${functionId}/actions`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Action<any>[]>;

      throw new Error('Something went wrong while trying to get list of Actions.');
    });
  }

  /**
   * Add an Action to the Function execution.
   */
  async addFunctionAction(functionId: string, action: Action<any>): Promise<Action<any>> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(action)
    };

    const url = `${API_URL}/functions/${functionId}/actions`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Action<any>>;

      throw new Error('Something went wrong while trying to add an Action.');
    });
  }

  /**
   * Returns the information of a specific Function return.
   */
  async getFunctionReturn(functionId: string): Promise<FunctionReturn[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/functions/${functionId}/return`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionReturn[]>;

      throw new Error('Something went wrong while trying to fetch Function return output.');
    });
  }

  /**
   * delete a function action
   */
  async deleteFunctionAction(functionId: string, actionId: string): Promise<boolean> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/actions/${actionId}`;

    return await fetch(url, options).then((res) => {
      if (res.ok) {
        return true;
      } else {
        throw new Error('Something went wrong while deleting the actions');
      }
    });
  }
}
