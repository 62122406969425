import React, { ChangeEvent } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultLabel, DefaultSelect } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function SelectControl(props: ControlProps) {
  const { t } = useTranslation();
  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    if (props.onChange) props.onChange(e.target.value, props.id);
  }

  return (
    <div
      className="mb-3"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <div style={{ display: 'flex', marginBottom: '.5rem' }}>
        <DefaultLabel title={props.label}>{props.label}</DefaultLabel>
        {props.tooltip && (
          <HelpPopover
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>
      <DefaultSelect
        id={'selectType'}
        className={`${styles.defaultInput} form-select form-select-sm`}
        value={props.value}
        onChange={handleChange}
      >
        <option value={''}>---</option>
        {props.options?.map((item, index) => {
          return <option key={index}>{item}</option>;
        })}
      </DefaultSelect>
    </div>
  );
}

export default SelectControl;
