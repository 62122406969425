import SessionContext from 'modules/auth/store';
import { TemplatesService } from 'modules/designer/services';
import { ProjectsService } from 'modules/project/services';
import { ApiAppInfo } from 'modules/project/types';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { Button, Form, Image, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InterfaceStudioState } from '../../store';

const formData = new FormData();

const VIEW_TYPES = {
  VIEW: 'View',
  MASTER: 'Master',
  DETAIL: 'Detail'
};

type ViewType = keyof typeof VIEW_TYPES;

type CreateTemplateModalProps = {
  onClose: () => void;
  show: boolean;
  viewId: string;
  appId: string;
};

export function CreateTemplateModal(props: CreateTemplateModalProps) {
  const { app_id } = useParams();
  const { t } = useTranslation();
  const session = React.useContext(SessionContext);

  const [appInfo, setAppInfo] = useState<ApiAppInfo>();
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [thumbnailPreview, setThumbnailPreview] = useState('');
  const [ownerCheckbox, setOwnerCheckbox] = useState('PERSONAL'); // PERSONAL / ORGANIZATION
  const [globalCheckbox, setGlobalCheckbox] = useState('GLOBAL'); // GLOBAL / APPLICATION
  const [selectedViewType, setSelectedViewType] = useState<ViewType>('VIEW');
  const [masterDataTable, setMasterDataTable] = useState<string>();
  const [detailForm, setDetailForm] = useState<string>();
  const [titleLabel, setTitleLabel] = useState<string>();
  const [descriptionLabel, setDescriptionLabel] = useState<string>();
  const [addButton, setAddButton] = useState<string>();

  const views = useSelector((state: InterfaceStudioState) => state.views);
  const selectedTheme = useSelector((state: InterfaceStudioState) => state.studio.selectedTheme);
  const components = useSelector((state: InterfaceStudioState) => state.components);

  const fetchAppInfo = useCallback(async (appId: string) => {
    const apiAppInfo = await ProjectsService.getProjectById(appId);
    setAppInfo(apiAppInfo);
  }, []);

  useEffect(() => {
    if (!props.show || !app_id) return;

    setThumbnailPreview('');
    setTemplateName('');
    setTemplateDescription('');
    setOwnerCheckbox('PERSONAL');
    setGlobalCheckbox('GLOBAL');
    formData.delete('file');
    formData.delete('template');
    setSelectedViewType('VIEW');
    fetchAppInfo(app_id);
  }, [app_id, components, fetchAppInfo, props.show]);

  const handleThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const image = e.target.files[0];
    if (!image) {
      formData.delete('file');
      setThumbnailPreview('');
    } else {
      formData.set('file', image);
      const preview = URL.createObjectURL(image);
      setThumbnailPreview(preview);
    }
  };

  const ownerOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOwnerCheckbox(e.target.value);
  };

  const globalCheckboxOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalCheckbox(e.target.value);
  };

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // If owner is organization then props.appId is used to fetch the organization
    // id in the backend.
    const ownerId = ownerCheckbox === 'PERSONAL' ? session.user?.id : props.appId;

    const templateInfo: any = {
      name: templateName,
      description: templateDescription,
      createdByUser: session.user?.id,
      appId: globalCheckbox === 'GLOBAL' ? '' : props.appId,
      owner: ownerId,
      isOwnerOrg: ownerCheckbox !== 'PERSONAL',
      type: selectedViewType === 'VIEW' ? views[props.viewId].type : selectedViewType,
      theme: selectedTheme,
      masterDataTable: masterDataTable,
      detailForm: detailForm,
      titleLabel: titleLabel,
      descriptionLabel: descriptionLabel,
      addButton: addButton
    };

    formData.set('template', JSON.stringify(templateInfo));

    await TemplatesService.convertViewToTemplate(props.viewId, formData).then(() => {
      props.onClose();
    });
  }

  const selectedViewTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const viewType = e.target.value as ViewType;
    setSelectedViewType(viewType);
  };

  return (
    <>
      <Modal centered show={props.show} onHide={props.onClose}>
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t('designer.createTemplateDialog.createTemplate')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>{t('designer.createTemplateDialog.name')}</Form.Label>
              <Form.Control
                type="text"
                id="TemplateNameInput"
                onChange={(e) => setTemplateName(e.target.value)}
                value={templateName}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('designer.createTemplateDialog.description')}</Form.Label>
              <Form.Control
                type="text"
                id="TemplateDescriptionInput"
                onChange={(e) => setTemplateDescription(e.target.value)}
                value={templateDescription}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="TemplateThumbnailInput">
                {t('designer.createTemplateDialog.thumbnail')}
              </Form.Label>
              <Form.Control
                type="file"
                id="TemplateThumbnailInput"
                onChange={handleThumbnailChange}
                accept="image/*"
                multiple={false}
              />
              {thumbnailPreview && (
                <Image
                  style={{ marginTop: '6px' }}
                  src={thumbnailPreview}
                  height={150}
                  width={150}
                  thumbnail
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="owner"
                type={'radio'}
                label={t('designer.createTemplateDialog.myAccount')}
                value={'PERSONAL'}
                onChange={ownerOnChange}
                checked={ownerCheckbox === 'PERSONAL'}
              />
              {appInfo?.is_owner_org && (
                <Form.Check
                  inline
                  name="owner"
                  type={'radio'}
                  label={t('designer.createTemplateDialog.organization')}
                  value={'ORGANIZATION'}
                  onChange={ownerOnChange}
                  checked={ownerCheckbox === 'ORGANIZATION'}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="global"
                type={'radio'}
                label={t('designer.createTemplateDialog.global')}
                value={'GLOBAL'}
                onChange={globalCheckboxOnChange}
                checked={globalCheckbox === 'GLOBAL'}
              />
              <Form.Check
                inline
                name="global"
                type={'radio'}
                label={t('designer.createTemplateDialog.application')}
                value={'APPLICATION'}
                onChange={globalCheckboxOnChange}
                checked={globalCheckbox === 'APPLICATION'}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>{t('designer.createTemplateDialog.TemplateType')}</Form.Label>
              <Form.Select
                value={selectedViewType}
                onChange={selectedViewTypeChange}
                className="mb-2"
              >
                {Object.keys(VIEW_TYPES).map((viewType) => {
                  return (
                    <option key={viewType} value={viewType}>
                      {VIEW_TYPES[viewType as ViewType]}
                    </option>
                  );
                })}
              </Form.Select>
              {selectedViewType === 'MASTER' && (
                <>
                  <Form.Label>{t('designer.components.DataTable')}</Form.Label>
                  <Form.Select
                    value={masterDataTable}
                    required
                    onChange={(e) => setMasterDataTable(e.target.value)}
                  >
                    <option value="">---</option>
                    {Object.values(components)
                      .filter((component) => component.type == 'DATATABLE')
                      .map((component) => {
                        return (
                          <option key={component.uuid} value={component.uuid}>
                            {component.data.name}
                          </option>
                        );
                      })}
                  </Form.Select>

                  <Form.Label>Botão Adicionar</Form.Label>
                  <Form.Select value={addButton} onChange={(e) => setAddButton(e.target.value)}>
                    <option value="">---</option>
                    {Object.values(components)
                      .filter((component) => component.type == 'BUTTON')
                      .map((component) => {
                        return (
                          <option key={component.uuid} value={component.uuid}>
                            {component.data.name}
                          </option>
                        );
                      })}
                  </Form.Select>

                  <Form.Label>Label Title</Form.Label>
                  <Form.Select value={titleLabel} onChange={(e) => setTitleLabel(e.target.value)}>
                    <option value="">---</option>
                    {Object.values(components)
                      .filter((component) => component.type == 'LABEL')
                      .map((component) => {
                        return (
                          <option key={component.uuid} value={component.uuid}>
                            {component.data.name}
                          </option>
                        );
                      })}
                  </Form.Select>

                  <Form.Label>Label Description</Form.Label>
                  <Form.Select
                    value={descriptionLabel}
                    onChange={(e) => setDescriptionLabel(e.target.value)}
                  >
                    <option value="">---</option>
                    {Object.values(components)
                      .filter((component) => component.type == 'LABEL')
                      .map((component) => {
                        return (
                          <option key={component.uuid} value={component.uuid}>
                            {component.data.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </>
              )}
              {selectedViewType === 'DETAIL' && (
                <>
                  <Form.Label>{t('designer.components.Form')}</Form.Label>
                  <Form.Select
                    value={detailForm}
                    required
                    onChange={(e) => setDetailForm(e.target.value)}
                  >
                    <option value="">---</option>
                    {Object.values(components)
                      .filter((component) => component.type == 'FORM')
                      .map((component) => {
                        return (
                          <option key={component.uuid} value={component.uuid}>
                            {component.data.name}
                          </option>
                        );
                      })}
                  </Form.Select>

                  <Form.Label>Label Title</Form.Label>
                  <Form.Select value={titleLabel} onChange={(e) => setTitleLabel(e.target.value)}>
                    <option value="">---</option>
                    {Object.values(components)
                      .filter((component) => component.type == 'LABEL')
                      .map((component) => {
                        return (
                          <option key={component.uuid} value={component.uuid}>
                            {component.data.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="saveButton"
              type="submit"
              disabled={
                (selectedViewType === 'DETAIL' && !detailForm) ||
                (selectedViewType === 'MASTER' && !masterDataTable)
              }
            >
              {t('designer.createTemplateDialog.create')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
