import React from 'react';
import { ManifestLinks } from 'modules/designer/types';
import * as FontIcon from 'react-icons/fa';
import { SideMenuStyle } from '../../..';
import * as Icons from 'react-icons/fa';
import { IconText } from 'web_ui/workboard/sidebar/controls/IconTextControl';
import { MenuType } from 'web_ui/workboard/sidebar/controls/MenuTypeControl/menu_type_selector';

export type RecursiveLinkProps = {
  linkUUID: string;
  links: ManifestLinks;
  styles: SideMenuStyle;
  iconStyle: { [key: string]: string };
  styleClasses: { [key: string]: string };
  linkText: IconText;
  liStyle: { [key: string]: string };
  menuType: string;
};

function RecursiveLink(props: RecursiveLinkProps) {
  return (
    <>
      {props.links.children[props.linkUUID].map((key) => {
        const iconName = props.links.items[key].icon;
        const IconDiv = iconName ? Icons[iconName] : null;
        return (
          <li
            className={`d-flex list-group-item list-group-item-action list-group-item-${props.styles.themeColor}`}
            key={key}
            style={props.liStyle}
          >
            {(props.linkText === IconText.BOTH || props.linkText === IconText.ICON) && (
              <div
                className={`d-flex align-items-center ${
                  props.links.items[key].title &&
                  props.links.items[key].title!.length > 0 &&
                  props.links.items[key].icon
                    ? 'ms-1'
                    : ''
                } text-${props.styleClasses.iconThemeColor}`}
                style={props.iconStyle}
              >
                {IconDiv && <IconDiv />}
              </div>
            )}
            <span className="w-100 d-flex justify-content-between align-items-center pe-2 ps-2">
              {props.links.children[key] && props.menuType === MenuType.RIGHTSIDEBYSIDE && (
                <FontIcon.FaChevronLeft></FontIcon.FaChevronLeft>
              )}
              <div className="d-flex flex-column">
                <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                  {props.links.items[key].title}
                </span>
                <span style={{ width: 'max-content', maxWidth: '33vw' }}>
                  {props.links.items[key].description}
                </span>
              </div>

              {props.links.children[key] && props.menuType === MenuType.SIDEBYSIDE && (
                <FontIcon.FaChevronRight></FontIcon.FaChevronRight>
              )}
            </span>
            {props.links.children[key] ? (
              <ul className="p-0 dropdown-menu dropdown-submenu">
                <RecursiveLink
                  linkUUID={key}
                  links={props.links}
                  styles={props.styles}
                  iconStyle={props.iconStyle}
                  styleClasses={props.styleClasses}
                  linkText={props.linkText}
                  liStyle={props.liStyle}
                  menuType={props.menuType}
                />
              </ul>
            ) : null}
          </li>
        );
      })}
    </>
  );
}

export default RecursiveLink;
