import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import { AppProperties as AppPropertiesType } from 'modules/designer/types';
import Icon from 'web_ui/icon';
import AddCustomPropModal from './modal';

import styles from './styles.module.css';
import { Properties } from 'modules/designer/enum';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import HelpIcon from 'web_ui/workboard/sidebar/controls/components/HelpIcon';
import { produce } from 'immer';
import { AppContext } from 'modules/project/store/app_context';

export const API_URL = process.env.REACT_APP_API_URL;

interface PropertiesAppProps {
  properties: AppPropertiesType[];
  setProperties: React.Dispatch<React.SetStateAction<AppPropertiesType[]>>;
  isLoading: boolean;
  nameApp: string;
}

function PropertiesApp(props: PropertiesAppProps) {
  const { t } = useTranslation();
  const appInfo = useContext(AppContext).projectInformation;

  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const index = props.properties.findIndex((obj) => obj.key === key);
    if (index === -1) {
      const newProperties = produce(props.properties, (draft) => {
        draft.push({
          key: key,
          value: e.target.value,
          comment: false,
          description: ''
        });
      });
      props.setProperties(newProperties);
    } else {
      const newProperties = produce(props.properties, (draft) => {
        draft[index].value = e.target.value;
      });
      props.setProperties(newProperties);
    }
  };

  const onChangeCustomValue = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newProperties = produce(props.properties, (draft) => {
      draft[index].value = e.target.value;
    });
    props.setProperties(newProperties);
  };
  const onChangeCustomKey = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newProperties = produce(props.properties, (draft) => {
      draft[index].key = e.target.value;
    });
    props.setProperties(newProperties);
  };

  const addCustomProp = (key: string, value: string) => {
    const newCustomProp: AppPropertiesType = {
      comment: false,
      description: '',
      key: key,
      value: value
    };
    const newProperties = produce(props.properties, (draft) => {
      draft.push(newCustomProp);
    });
    props.setProperties(newProperties);
  };

  const deletePropertie = (index: number) => {
    if (index >= 0) {
      const newProperties = produce(props.properties, (draft) => {
        draft.splice(index, 1);
      });
      props.setProperties(newProperties);
    }
  };

  return (
    <>
      {!props.isLoading && (
        <>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            {appInfo?.has_database && (
              <Accordion.Item id={'accordionDatabase'} eventKey="0">
                <Accordion.Header id={'HeaderAccordionDatabase'}>
                  <h6 className={`text-body-emphasis`}>{t('appResume.properties.Database')}</h6>
                </Accordion.Header>
                <Accordion.Body className={`${styles.bodyProperties}`}>
                  {/* URL */}
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t('appResume.properties.Url.url')}
                      <HelpPopover
                        helpBoxProps={{
                          title:
                            t('appResume.properties.Url.url') || 'appResume.properties.Url.url',
                          description:
                            t('appResume.properties.Url.description') ||
                            'appResume.properties.Url.description',
                          note: ['Ex: spring-boot-starter-data-jpa=jdbc:h2:mem:default']
                        }}
                        placement="top"
                      >
                        <HelpIcon />
                      </HelpPopover>
                    </Form.Label>
                    <Form.Control
                      id="formURL"
                      type="text"
                      value={
                        props.properties.find((obj) => {
                          return obj.key === Properties.DB_URL;
                        })?.value
                      }
                      disabled={props.isLoading}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeValue(e, Properties.DB_URL)
                      }
                    />
                  </Form.Group>
                  {/* Username */}
                  <Form.Group className="mb-3 pt-2">
                    <Form.Label>
                      {t('appResume.properties.Username.username')}
                      <HelpPopover
                        helpBoxProps={{
                          title:
                            t('appResume.properties.Username.username') ||
                            'appResume.properties.Username.username',
                          description:
                            t('appResume.properties.Username.description') ||
                            'appResume.properties.Username.description',
                          note: ['Ex: spring.datasource.username=sa']
                        }}
                        placement="top"
                      >
                        <HelpIcon />
                      </HelpPopover>
                    </Form.Label>
                    <Form.Control
                      id="formUsername"
                      type="text"
                      value={
                        props.properties.find((obj) => {
                          return obj.key === Properties.DB_USERNAME;
                        })?.value
                      }
                      disabled={props.isLoading}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeValue(e, Properties.DB_USERNAME)
                      }
                    />
                  </Form.Group>
                  {/* Password */}
                  <Form.Group className="mb-3 pt-2">
                    <Form.Label>
                      {t('appResume.properties.Password.password')}
                      <HelpPopover
                        helpBoxProps={{
                          title:
                            t('appResume.properties.Password.password') ||
                            'appResume.properties.Password.password',
                          description:
                            t('appResume.properties.Password.description') ||
                            'appResume.properties.Password.description',
                          note: ['Ex: spring.datasource.password=123']
                        }}
                        placement="top"
                      >
                        <HelpIcon />
                      </HelpPopover>
                    </Form.Label>
                    <Form.Control
                      id="formPassword"
                      type="password"
                      value={
                        props.properties.find((obj) => {
                          return obj.key === Properties.DB_PASSWORD;
                        })?.value
                      }
                      disabled={props.isLoading}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeValue(e, Properties.DB_PASSWORD)
                      }
                    />
                  </Form.Group>
                  {/* Driver name */}
                  <Form.Group className="mb-3 pt-2">
                    <Form.Label>
                      {t('appResume.properties.Driver.driver')}
                      <HelpPopover
                        helpBoxProps={{
                          title:
                            t('appResume.properties.Driver.driver') ||
                            'appResume.properties.Driver.driver',
                          description:
                            t('appResume.properties.Driver.description') ||
                            'appResume.properties.Driver.description',
                          note: ['Ex: spring.datasource.driver-class-name=org.h2.Driver']
                        }}
                        placement="top"
                      >
                        <HelpIcon />
                      </HelpPopover>
                    </Form.Label>
                    <Form.Control
                      id="formDriverName"
                      type="text"
                      value={
                        props.properties.find((obj) => {
                          return obj.key === Properties.DB_DRIVER;
                        })?.value
                      }
                      disabled={props.isLoading}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeValue(e, Properties.DB_DRIVER)
                      }
                    />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {appInfo?.has_backend && (
              <Accordion.Item eventKey="1">
                <Accordion.Header id={'apiCollapse'}>
                  <h6 className={`text-body-emphasis`}>{t('appResume.properties.Api')}</h6>
                </Accordion.Header>
                <Accordion.Body className={`${styles.bodyProperties}`}>
                  {/* API Port */}
                  <Form.Group as={Row} className="mb-3 pt-2">
                    <Form.Label column sm="3">
                      {t('appResume.properties.Port.port')}
                      <HelpPopover
                        helpBoxProps={{
                          title:
                            `${t('appResume.properties.Api')}-${t(
                              'appResume.properties.Port.port'
                            )}` || 'appResume.properties.Port.port',
                          description:
                            t('appResume.properties.Port.ApiDescription') ||
                            'appResume.properties.Port.ApiDescription',
                          note: ['Ex: server.port=8080']
                        }}
                        placement="top"
                      >
                        <HelpIcon />
                      </HelpPopover>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        id="formAPIPort"
                        type="text"
                        value={
                          props.properties.find((obj) => {
                            return obj.key === Properties.API_PORT;
                          })?.value
                        }
                        disabled={props.isLoading}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChangeValue(e, Properties.API_PORT)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {appInfo?.has_frontend && (
              <Accordion.Item eventKey="2">
                <Accordion.Header id={'webappCollapse'}>
                  <h6 className={`text-body-emphasis`}>{t('appResume.properties.Webapp')}</h6>
                </Accordion.Header>
                <Accordion.Body className={`${styles.bodyProperties}`}>
                  {/* Webapp Port */}
                  <Form.Group as={Row} className="mb-3 pt-2">
                    <Form.Label column sm="3">
                      {t('appResume.properties.Port.port')}
                      <HelpPopover
                        helpBoxProps={{
                          title:
                            `${t('appResume.properties.Webapp')}-${t(
                              'appResume.properties.Port.port'
                            )}` || 'appResume.properties.Port.port',
                          description:
                            t('appResume.properties.Port.WebappDescription') ||
                            'appResume.properties.Port.WebappDescription',
                          note: ['Ex: PORT=3001']
                        }}
                        placement="top"
                      >
                        <HelpIcon />
                      </HelpPopover>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        id="formWebappPort"
                        type="text"
                        value={
                          props.properties.find((obj) => {
                            return obj.key === Properties.WEBAPP_PORT;
                          })?.value
                        }
                        disabled={props.isLoading}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChangeValue(e, Properties.WEBAPP_PORT)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            )}
            <Accordion.Item eventKey="3">
              <Accordion.Header id={'customCollapse'}>
                <h6 className={`text-body-emphasis`}>{t('appResume.properties.Custom.custom')}</h6>
                <HelpPopover
                  helpBoxProps={{
                    title:
                      t('appResume.properties.Custom.custom') ||
                      'appResume.properties.Custom.custom',
                    description:
                      t('appResume.properties.Custom.description') ||
                      'appResume.properties.Custom.description',
                    note: [`Ex: ${props.nameApp}.custom=abc`]
                  }}
                  placement="top"
                >
                  <HelpIcon />
                </HelpPopover>
              </Accordion.Header>
              <Accordion.Body className={`${styles.bodyProperties}`}>
                <>
                  {props.properties.map((propertie, indexProp) => {
                    return (
                      <React.Fragment key={indexProp}>
                        <div></div>
                        {!Object.values(Properties).includes(propertie.key as Properties) && (
                          <Form.Group
                            key={indexProp}
                            as={Row}
                            className="mb-3 pt-2 align-items-center"
                          >
                            <Col sm="3">
                              <Form.Control
                                plaintext
                                type="text"
                                value={propertie.key}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  onChangeCustomKey(e, indexProp)
                                }
                              />
                            </Col>
                            <Col sm="8">
                              <Form.Control
                                type="text"
                                value={propertie.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  onChangeCustomValue(e, indexProp)
                                }
                              />
                            </Col>
                            <Col sm="1">
                              <Button
                                className={`${styles.addPropButton}`}
                                variant="danger"
                                id={`deleteCustomPropertie${indexProp}`}
                                disabled={props.isLoading}
                                onClick={() => {
                                  deletePropertie(indexProp);
                                }}
                              >
                                X
                              </Button>
                            </Col>
                          </Form.Group>
                        )}
                      </React.Fragment>
                    );
                  })}
                  <Button
                    className={`${styles.addPropButton}`}
                    id="addCustomPropertie"
                    disabled={props.isLoading}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <Icon iconName="plus" />
                  </Button>
                </>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <AddCustomPropModal
            showModal={openModal}
            onClose={() => setOpenModal(false)}
            addCustomProp={addCustomProp}
          />
        </>
      )}
    </>
  );
}

export default PropertiesApp;
