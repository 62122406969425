import React from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function TextDecoration(props: ControlProps) {
  const { t } = useTranslation();
  function handleChange(AlignText: string) {
    if (props.onChange) props.onChange(AlignText, props.id);
  }

  return (
    <div style={{ display: 'flex' }}>
      {props.tooltip && (
        <div>
          <HelpPopover
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </div>
      )}
      <div
        className="mb-3"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <div className={`form-control form-control-sm`}>
          <button
            className={` ${styles.cleanButton}  btn btn-secondary`}
            onClick={() => handleChange('')}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
          <button
            className={` ${styles.buttonContent} btn btn-secondary`}
            onClick={() => handleChange('underline')}
          >
            <i className="fa-solid fa-underline"></i>
          </button>
          <button
            className={` ${styles.buttonContent}  btn btn-secondary`}
            onClick={() => handleChange('line-through')}
          >
            <i className="fa-solid fa-strikethrough"></i>
          </button>
        </div>
        {props.options?.map((item, index) => {
          return <option key={index}>{item}</option>;
        })}
      </div>
    </div>
  );
}

export default TextDecoration;
