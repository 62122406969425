import { useSelector } from 'react-redux';
import { InterfaceStudioState, ObjectsState } from 'modules/designer/studio/store';
import { FRONTEND_TO_BACKEND_TYPES, FrontendVariable, VariableTypes } from 'modules/designer/types';
import { DataType } from 'modules/logic_builder/types';

type VariableControlItem = FrontendVariable & { isProp: boolean };

export function useVariablesAndObjects(
  list: boolean,
  shouldUseProps: boolean,
  filterTypes?: VariableTypes[]
): {
  variables: Record<string, VariableControlItem>;
  objects: ObjectsState;
} {
  const vars = useSelector((state: InterfaceStudioState) => state.variables);
  const props = useSelector((state: InterfaceStudioState) => state.properties);
  const objects = useSelector((state: InterfaceStudioState) => state.objects);

  const variablesAndProperties: VariableControlItem[] = [];
  Object.values(vars).forEach((v) => {
    variablesAndProperties.push({ ...v, isProp: false });
  });
  if (shouldUseProps) {
    Object.values(props).forEach((p) => {
      variablesAndProperties.push({
        uuid: p.uuid,
        name: p.name,
        type: p.type,
        native: p.native,
        list: p.list,
        objectUuid: p.object,
        view: p.view,
        customComponent: p.customComponent,
        isProp: true
      });
    });
  }

  let finalVariables = [...variablesAndProperties];

  if (filterTypes && filterTypes.length) {
    const backendFilterTypes = (convertFrontendToBackendDatatype(filterTypes) as DataType[]).map(
      (lowercase) => {
        return lowercase.toUpperCase();
      }
    );
    const filteredVariablesByDataType = variablesAndProperties.filter((variable) =>
      filterTypes.includes(variable.type.toUpperCase() as VariableTypes)
    );
    const filteredObjectsByDataType = Object.values(objects)
      .filter(
        (object) =>
          object.uuid &&
          object.objectItems &&
          object.objectItems.find(
            (objectItem) => objectItem.dataType && backendFilterTypes.includes(objectItem.dataType)
          )
      )
      .map((o) => o.uuid);
    const filteredObjectVariableByFieldsDatatype = variablesAndProperties.filter(
      (variable) =>
        variable.type === 'OBJECT' &&
        variable.objectUuid &&
        filteredObjectsByDataType.includes(variable.objectUuid)
    );

    finalVariables = [...filteredVariablesByDataType, ...filteredObjectVariableByFieldsDatatype];
  }

  if (list) {
    const listObjects = Object.values(objects)
      .filter(
        (object) =>
          object.uuid &&
          object.objectItems &&
          object.objectItems.find((objectItem) => objectItem.list)
      )
      .map((o) => o.uuid);
    const listVariables = finalVariables.filter((v) => v.list);
    const objectTypeVariablesWithListFields = variablesAndProperties.filter(
      (v) => v.type === 'OBJECT' && v.objectUuid && listObjects.includes(v.objectUuid)
    );

    finalVariables = [...listVariables, ...objectTypeVariablesWithListFields].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
  }

  const toState: Record<string, VariableControlItem> = {};
  finalVariables.forEach((variable) => {
    toState[variable.uuid] = variable;
  });
  return { variables: toState, objects };
}

export function convertFrontendToBackendDatatype(dataTypeFilters: VariableTypes[]): string[] {
  const backendDataTypeFilters = dataTypeFilters.flatMap(
    (filter) => FRONTEND_TO_BACKEND_TYPES[filter]
  );
  return backendDataTypeFilters;
}
