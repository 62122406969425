import { Folder, FolderCreateDTO, FolderType } from '../types';
import { FolderSchema } from './schemas/Folder';

export const API_URL = process.env.REACT_APP_API_URL;

export class FolderRepo {
  private buildSchema(folderName?: string, parent?: string) {
    const folderSchema: FolderSchema = {
      ...(folderName && { name: folderName }),
      parent: parent
    };

    return folderSchema;
  }

  async createFolder(moduleId: string, name: string, parentId: string, type: string) {
    const headers = new Headers({ 'Content-Type': 'application/json' });

    const body: FolderSchema = this.buildSchema(name, parentId);

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    const url = `${API_URL}/modules/${moduleId}/${type}/folders`;

    return await fetch(url, options).then((response) => {
      if (response.status === 201) return response.json() as Promise<Folder>;
      throw new Error('Something went wrong while trying to create a new folder.');
    });
  }

  async getFoldersByModule(moduleId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modules/${moduleId}/folders`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Folder[]>;
      throw new Error('Something went wrong while trying to fetch module folders.');
    });
  }

  async getFolder(moduleId: string, folderId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modules/${moduleId}/folders/${folderId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Folder>;
      throw new Error('Something went wrong while trying to fetch layout.');
    });
  }

  async updateFolder(moduleId: string, folderId: string, folderName: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const folderSchema: FolderSchema = this.buildSchema(folderName);

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(folderSchema)
    };

    const url = `${API_URL}/modules/${moduleId}/folders/${folderId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Folder>;
      throw new Error('Something went wrong while trying to update folder.');
    });
  }

  async moveFolder(moduleId: string, folderId: string, parentId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const folderSchema: FolderSchema = this.buildSchema(undefined, parentId);

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(folderSchema)
    };

    const url = `${API_URL}/modules/${moduleId}/folders/${folderId}/move`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Folder>;
      throw new Error('Something went wrong while trying to update folder.');
    });
  }

  async deleteFolder(moduleId: string, folderId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modules/${moduleId}/folders/${folderId}`;

    return await fetch(url, options).then(async (response: Response) => {
      if (response.ok) return response;
      const error = await response.json();
      if (error) throw new Error(error.message);
      else throw new Error('Something went wrong while trying to delete folder.');
    });
  }

  async getFolderByModuleIdAndLocalType(moduleId: string, type: FolderType) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modules/${moduleId}/folders?folderType=${type}`;

    return await fetch(url, options).then(async (response: Response) => {
      if (response.ok) {
        return await response.json();
      } else {
        const error = await response.json();
        if (error) {
          throw new Error(error.message);
        } else {
          throw new Error('Something went wrong while trying to delete folder.');
        }
      }
    });
  }

  async createFolderByType(moduleId: string, type: FolderType, folderDTO: FolderCreateDTO) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(folderDTO)
    };

    const url = `${API_URL}/modules/${moduleId}/${type}/folders`;

    return await fetch(url, options).then(async (response: Response) => {
      if (response.ok) {
        return await response.json();
      } else {
        const error = await response.json();
        if (error) {
          throw new Error(error.message);
        } else {
          throw new Error('Something went wrong while trying to delete folder.');
        }
      }
    });
  }
}
