import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import React from 'react';
import EndCycleIcon from './end_cycle_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { t } from 'i18next';

export const END_CYCLE_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.END_CYCLE,
  name: t('EndFor'),
  description: 'Action to close a "for" loop',
  color: 'secondary',
  category: FRONTEND_ACTIONS_CATEGORIES.CYCLE,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.CYCLE,
  icon: <EndCycleIcon />,
  closeBlock: true,
  inlineInputs: [],
  inputs: []
};
