import React from 'react';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import QueryIcon from './custom_query_icon';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { t } from 'i18next';

export const CUSTOM_QUERY_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.CUSTOM_QUERY,
  name: t('DBQuery'),
  description: t('CreateDBQuery'),
  color: 'danger',
  category: BACKEND_ACTIONS_CATEGORIES.ENTITY,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.ENTITY,
  icon: <QueryIcon />,
  shouldHaveDatabase: true,
  inlineInputs: [
    {
      label: t('GenQueryIn'),
      placeholder: t('SelectEntity') ?? '',
      key: 'entityId',
      type: ACTION_INPUTS_TYPES.ENTITY_INPUT
    },
    {
      label: t('AndReturnIn'),
      placeholder: t('SelectVar') ?? '',
      key: 'id',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: {
        argumentTypes: [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM],
        // filterByDataType: filterByFunctionVariables,
        hideNodePicker: false
      }
    }
  ],
  inputs: [
    {
      label: t('ExecQuery'),
      placeholder: `// ${t('TypeQueryHere')}`,
      type: ACTION_INPUTS_TYPES.DBQUERY,
      key: 'query',
      options: { language: 'sql' }, //ou pgsql
      showInToolbar: false
    }
  ]
};
