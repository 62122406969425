import useMakeNewRelationship from './useMakeNewRelationship';
import { sleep } from '../../../../../../../../utils/utils';
import produce from 'immer';
import { TableUUID } from '../../../../../../types';
import useFetchAvailableTables from './useFetchAvailableTables';

export default function useCreateExternalRelationship(
  moduleID: string,
  tableID: TableUUID,
  selectedExternalModule: number,
  selectedExternalTable: number,
  setSelectedExternalTable: React.Dispatch<React.SetStateAction<number>>,
  selectedExternalTableIndex: number
) {
  const { availableTables, setAvailableTables } = useFetchAvailableTables(tableID, moduleID);

  const makeNewRelationship = useMakeNewRelationship({
    moduleID,
    fromTableID: tableID,
    toTableID: getExternalTableID() ?? '',
    pkColumns: getPkColumns() ?? [],
    selectedExternalModule,
    selectedExternalTable,
    selectedExternalTableIndex
  });

  function getPkColumns() {
    if (!availableTables) return;
    return availableTables?.modules[selectedExternalModule]?.tables[selectedExternalTable]?.indexes[
      selectedExternalTableIndex
    ]?.columns;
  }

  function getExternalTableID() {
    if (!availableTables) return;
    return availableTables?.modules[selectedExternalModule]?.tables[selectedExternalTable]?.id;
  }

  async function createExternalRelationship() {
    if (
      availableTables &&
      availableTables.modules[selectedExternalModule].tables[selectedExternalTable] != null
    ) {
      setSelectedExternalTable(0);
      makeNewRelationship();
      await sleep(50);

      const newExternalTables = produce(availableTables, (draft) => {
        draft.modules[selectedExternalModule].tables.splice(selectedExternalTable, 1);
      });
      setAvailableTables(newExternalTables);
    }
  }

  return createExternalRelationship;
}
