import React, { useContext } from 'react';
import ObjectsToolbar from './toolbars/objects';
import ClassesToolbar from './toolbars/classes';
import ControllersToolbar from './toolbars/controllers';
import ObjectsEditor from './logic_editor/objects_editor';
import ServiceEditor from './logic_editor/service_editor_functions';
import ControllerEditor from './logic_editor/controller_editor_endpoints';
import EditorNav from './logic_editor/nav';
import { Sidebar } from 'web_ui/function_editor/components/sidebar';
import { Editor } from 'web_ui/function_editor/components/editor';
import { SidebarPosition } from 'web_ui/workboard/sidebar';
import { LogicBuilderConcept } from '../types';
import LogicBuilderContext from '../store';
import { ErrorBoundary } from 'react-error-boundary';
import GenericFallback from 'error_boundaries/genericFallback';
import { globalReport } from 'error_boundaries';
import { SchedulerEditor } from './logic_editor/scheduler_editor';
import { SchedulerToolbar } from './toolbars/scheduler';

type LogicBuilderProps = Record<string, unknown>;

function Studio(props: LogicBuilderProps) {
  const { getSelectedConcept } = useContext(LogicBuilderContext);

  return (
    <div className={'d-flex bg-body-tertiary'}>
      <Sidebar.Root position={SidebarPosition.LEFT}>
        <Sidebar.Content>
          {getSelectedConcept() === LogicBuilderConcept.OBJECT && <ObjectsToolbar />}
          {getSelectedConcept() === LogicBuilderConcept.FUNCTION && <ClassesToolbar />}
          {getSelectedConcept() === LogicBuilderConcept.ENDPOINT && <ControllersToolbar />}
          {getSelectedConcept() === LogicBuilderConcept.SCHEDULER && <SchedulerToolbar />}
        </Sidebar.Content>
      </Sidebar.Root>
      <Editor.Root hasContainer={false}>
        <EditorNav />
        <div>
          {getSelectedConcept() === LogicBuilderConcept.OBJECT && (
            <ErrorBoundary
              key={'OBJECT'}
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <GenericFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  title="automation.step4.Objects"
                />
              )}
              onError={globalReport}
            >
              <ObjectsEditor />
            </ErrorBoundary>
          )}
          {getSelectedConcept() === LogicBuilderConcept.FUNCTION && (
            <ErrorBoundary
              key={'FUNCTIONS'}
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <GenericFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  title="automation.step4.Functions"
                />
              )}
              onError={globalReport}
            >
              <ServiceEditor toggleTheme={props.toggleTheme} />
            </ErrorBoundary>
          )}
          {getSelectedConcept() === LogicBuilderConcept.ENDPOINT && (
            <ErrorBoundary
              key={'Endpoints'}
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <GenericFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  title="automation.step4.Endpoints"
                />
              )}
              onError={globalReport}
            >
              <ControllerEditor toggleTheme={props.toggleTheme} />
            </ErrorBoundary>
          )}
          {getSelectedConcept() === LogicBuilderConcept.SCHEDULER && (
            <ErrorBoundary
              key={'Endpoints'}
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <GenericFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  title="automation.step4.Endpoints"
                />
              )}
              onError={globalReport}
            >
              <SchedulerEditor />
            </ErrorBoundary>
          )}
        </div>
      </Editor.Root>
    </div>
  );
}

export default Studio;
