import { Combobox } from '@headlessui/react';
import React from 'react';
import { ReturnType } from 'web_ui/function_editor/store/types/functions';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { EnumFrame } from 'modules/modeler/types';
import { ObjectSimple } from 'modules/logic_builder/types';

type DataTypePickerProps = {
  handleChange: (type: ReturnType) => void;
  type: ReturnType;
  types: ReturnType[];
  disabled?: boolean;
  enums: EnumFrame<any>[];
  objects: ObjectSimple[];
};

function DataTypePicker({
  handleChange,
  type,
  types,
  disabled = false,
  objects,
  enums
}: DataTypePickerProps) {
  const [query, setQuery] = React.useState<string>('');
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   (async () => {
  //     if (type.type === 'OBJECT') {
  //       setObjectName((await findAllObjecs()).find((item) => item.uuid == type.objectUuid)!.name!);
  //     } else if (type.type === 'ENUM') {
  //       // content ????
  //       setEnumName(enums.find((item) => item.uuid == type.enumUuid)!.content!);
  //     }
  //   })();
  // }, []);

  // const findAllObjecs = async () => {
  //   const allObjects = await ObjectsService.getObjectsByModule(module_id!);
  //   return allObjects;
  // };

  const filteredTypes =
    query === ''
      ? types
      : types?.filter((item) => {
          return item.name && item.name.toLowerCase().includes(query.toLowerCase());
        });

  // const displayName = (): string => {
  //   if (type.type === 'OBJECT') {
  //     return objectName;
  //   } else if (type.type === 'ENUM') {
  //     // content ????
  //     return enumName;
  //   } else {
  //     return type.name!;
  //   }
  // };

  return (
    <Combobox
      onChange={(type: ReturnType) => {
        setQuery(type?.name ?? '');
        handleChange(type);
      }}
      value={type}
      disabled={disabled}
    >
      <div className={`${styles.ReturnTypeCombo} ${disabled ? 'bg-body-secondary' : ''}`}>
        <Combobox.Input
          id="selectType"
          ref={inputRef}
          className={`${styles.ReturnTypeComboInput} ${
            isOpen ? 'rounded-top-2 rounded-bottom-0' : 'rounded-2'
          }`}
          onChange={(e) => setQuery(e.target.value)}
          autoComplete={'off'}
          displayValue={() => (type.type ? type.type : '')}
        />
        <Combobox.Button
          style={{ fontSize: '0.7rem', float: 'right', height: '100%', paddingTop: '3px' }}
          as={'span'}
        >
          {({ open }) => {
            setIsOpen(open);
            if (open) {
              return <Icon iconName={'chevron-up'} />;
            } else {
              return <Icon iconName={'chevron-down'} />;
            }
          }}
        </Combobox.Button>
        <Combobox.Options className={`${styles.ReturnTypeComboOptions}`}>
          {filteredTypes?.map((item, index) => (
            <Combobox.Option
              className={({ active }) =>
                active
                  ? `bg-secondary ${styles.ReturnTypeComboOption}`
                  : `${styles.ReturnTypeComboOption}`
              }
              value={item}
              key={index}
            >
              {item.name ?? item.type}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

export default DataTypePicker;
