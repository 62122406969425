import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Editor, EventTypes, FrontendFunctionUUID } from '../../../types';
import { addEvent, deleteEvent } from '../../store/actions/views';
import { COMPONENTS_MANIFEST } from '../../exocode_components';
import { addComponentEvent, deleteComponentEvent } from '../../store/actions/components';
import styles from './styles.module.css';

function EventsTool() {
  const dispatch = useDispatch();
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const functions = useSelector((state: InterfaceStudioState) => state.functions);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const [componentEvents, setComponentEvents] = useState<
    | (
        | 'LOAD'
        | 'LEAVE'
        | 'CLICK'
        | 'HOVER'
        | 'SELECT'
        | 'MOUNT'
        | 'UNMOUNT'
        | 'SUBMIT'
        | 'SEARCH'
        | 'CHANGE'
      )[]
    | undefined
  >([]);
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const selectedComponentInfo = components[selectedComponent ?? ''];

  useEffect(() => {
    if (selectedComponentInfo) {
      setComponentEvents(COMPONENTS_MANIFEST[selectedComponentInfo.type].events);
    }
  }, [selectedComponentInfo]);

  function handleEventChange(type: EventTypes, functionUuid: FrontendFunctionUUID) {
    if (!selectedView && !selectedComponent) return;

    const view = selectedView as NonNullable<typeof selectedView>;
    const component = selectedComponent as NonNullable<typeof selectedComponent>;

    if (functionUuid !== '---') {
      view
        ? dispatch(addEvent(view, type, functionUuid))
        : dispatch(
            addComponentEvent(
              component,
              type,
              functionUuid,
              editor === Editor.CUSTOM_COMPONENT ? component : undefined
            )
          );
    } else {
      view ? dispatch(deleteEvent(view, type)) : dispatch(deleteComponentEvent(component, type));
    }
  }

  if (!selectedView && !selectedComponent) {
    return <></>;
  }

  if (
    selectedComponent &&
    (!components[selectedComponent] ||
      !COMPONENTS_MANIFEST[components[selectedComponent].type].events)
  )
    return <></>;

  return (
    <div className={`p-3 pt-0 ${styles.controlList}`}>
      <h6 className="mt-2">
        <span>Events</span>
      </h6>
      <hr />

      {selectedView && !selectedComponent && views && views[selectedView] && (
        <div>
          <FloatingLabel className="mb-2" label="On Load">
            <Form.Select
              id="selectLoad"
              value={views[selectedView].events.LOAD}
              onChange={(e) => handleEventChange('LOAD', e.target.value)}
            >
              <option>---</option>
              {Object.values(functions).map((func, index) => (
                <option key={index} value={func.uuid}>
                  {func.name}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel className="mb-2" label="On Leave">
            <Form.Select
              id="selectLeave"
              value={views[selectedView].events.UNLOAD}
              onChange={(e) => handleEventChange('LEAVE', e.target.value)}
            >
              <option>---</option>
              {Object.values(functions).map((func, index) => (
                <option key={index} value={func.uuid}>
                  {func.name}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </div>
      )}

      {selectedComponent && !selectedView && (
        <div>
          {componentEvents?.map((eventType, index) => (
            <div key={index}>
              {selectedComponent && (
                <FloatingLabel className="mb-2" label={eventType}>
                  <Form.Select
                    id={`select${eventType}`}
                    value={
                      components[selectedComponent].events &&
                      components[selectedComponent].events[eventType]
                        ? components[selectedComponent].events[eventType]
                        : ''
                    }
                    onChange={(e) => handleEventChange(eventType, e.target.value)}
                  >
                    <option>---</option>
                    {Object.values(functions).map((func, index) => (
                      <option key={index} value={func.uuid}>
                        {func.name}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EventsTool;
