import React, { useState } from 'react';
import styles from './styles.module.css';
import { Button, Table } from 'react-bootstrap';
import { FunctionVariable } from 'modules/logic_builder/types';
import { VariableCreatorDialog } from '../variable_creator_dialog';
import { VariableEditorDialog } from '../variable_editor_dialog';
import { EnumFrame } from 'modules/modeler/types';

type VariablesTabProps = {
  functionId: string;
  variablesList: FunctionVariable[];
  fetchVariables: () => void;
  enumsList: EnumFrame[];
};

export function VariablesTab(props: VariablesTabProps) {
  const [showVariableCreatorDialog, setShowVariableCreatorDialog] = useState(false);
  const [showVariableEditorDialog, setShowVariableEditorDialog] = useState<FunctionVariable>();

  return (
    <>
      <div className={styles.TableWrapper}>
        <div className={styles.TableTitle}>
          Variables
          <Button
            id={'addVariablesButton'}
            variant="primary"
            className="btn-sm"
            onClick={() => setShowVariableCreatorDialog(true)}
          >
            Add
          </Button>
        </div>
        <div>
          <Table size="sm" className={styles.Table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {props.variablesList.map((variable) => {
                return (
                  <tr
                    key={variable.uuid}
                    onClick={() => setShowVariableEditorDialog(variable)}
                    className={styles.Variable}
                  >
                    <td>{variable.name}</td>
                    {variable.list ? <td>{`List<${variable.type}>`}</td> : <td>{variable.type}</td>}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <VariableCreatorDialog
        functionId={props.functionId}
        fetchVariables={props.fetchVariables}
        show={showVariableCreatorDialog}
        onClose={() => setShowVariableCreatorDialog(false)}
        enumsList={props.enumsList}
      />
      <VariableEditorDialog
        functionId={props.functionId}
        fetchVariables={props.fetchVariables}
        variable={showVariableEditorDialog}
        show={showVariableEditorDialog != null}
        onClose={() => setShowVariableEditorDialog(undefined)}
        enumsList={props.enumsList}
      />
    </>
  );
}
