import { Action } from 'redux';
import { PageParam, ViewUUID } from 'modules/designer/types';
import { PageParamsState } from '..';
import { ParamsUUID } from 'modules/designer/types';

export const ADD_PARAMS = 'ADD_PAGE_PARAM';
export const DELETE_PARAMS = 'DELETE_PAGE_PARAM';
export const SET_PARAMS = 'SET_PARAMS';

export interface SetParamsAction extends Action {
  type: 'SET_PARAMS';
  payload: {
    params: PageParamsState;
  };
}

export interface AddParamsAction extends Action {
  type: 'ADD_PAGE_PARAM';
  payload: {
    params: PageParam;
  };
}

export interface DeleteParamsAction extends Action {
  type: 'DELETE_PAGE_PARAM';
  payload: {
    params: ParamsUUID;
    view: ViewUUID;
  };
}

export const setParams = (params: PageParamsState): SetParamsAction => ({
  type: SET_PARAMS,
  payload: { params: params }
});

export const addParam = (param: PageParam): AddParamsAction => ({
  type: ADD_PARAMS,
  payload: {
    params: param
  }
});

export const deleteParam = (paramId: ParamsUUID, viewId: ViewUUID): DeleteParamsAction => ({
  type: DELETE_PARAMS,
  payload: { params: paramId, view: viewId }
});
