import produce from 'immer';

import { ThemeState } from '../index';
import {
  CHANGE_THEME_VALUE,
  ChangeThemeValueAction,
  SET_THEME,
  SetThemeAction
} from '../actions/theme';

type ThemeActions = SetThemeAction | ChangeThemeValueAction;

export const themeReducer = (state: ThemeState = {}, action: ThemeActions): ThemeState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_THEME:
        return doSetTheme(draft, action);
      case CHANGE_THEME_VALUE:
        return doChangeThemeValue(draft, action);
      default:
        return draft;
    }
  });
};

function doSetTheme(state: ThemeState, action: SetThemeAction): ThemeState {
  return action.payload.theme;
}

function doChangeThemeValue(state: ThemeState, action: ChangeThemeValueAction): ThemeState {
  state[action.payload.section][action.payload.key] = action.payload.value;
  return state;
}
