import { ExoRole } from '../types';
import { RoleResponse, RoleSchema } from './schemas/Roles';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class AppRoleRepo {
  /**
   * Get roles (from a specific app).
   */
  async getRoles(appId: string): Promise<RoleResponse[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/apps/${appId}/roles`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<RoleResponse[]>;

      throw new Error('Something went wrong while trying to fetch Roles.');
    });
  }

  /**
   * Create a new role (attached to a app).
   */
  async createRole(appId: string, role: ExoRole): Promise<RoleResponse> {
    const roleSchema = role as RoleSchema;

    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(roleSchema)
    };

    const url = `${API_URL}/apps/${appId}/roles`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<RoleResponse>;

      throw new Error('Something went wrong while trying to create Role.');
    });
  }

  /**
   * Delete Role.
   */
  async deleteRole(roleId: number) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/roles/${roleId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete Role.');
    });
  }

  /**
   * Update role.
   */
  async updateRole(roleId: number, role: ExoRole): Promise<RoleResponse> {
    const roleSchema = role as RoleSchema;

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(roleSchema)
    };

    const url = `${API_URL}/roles/${roleId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<RoleResponse>;

      throw new Error('Something went wrong while trying to update Role.');
    });
  }
}
