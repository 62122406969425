import React, { ChangeEvent, useContext } from 'react';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';
import SessionContext from 'modules/auth/store';
import { Preferences } from 'modules/auth/enum';
import { useTranslation } from 'react-i18next';

function Modeler() {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  const handleNumberOfColumnsOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) >= 65 || Number(event.target.value) < 0) return;
    session.changePreferences(Preferences.NUMBER_OF_COLUMNS, event.target.value);
  };

  const keyDown = (ev: React.KeyboardEvent) => {
    // avoiding reload
    if (ev.key === 'Enter') {
      ev.stopPropagation();
      ev.preventDefault();
    }
  };

  return (
    <>
      <Form className={styles.Form}>
        <Form.Group className={styles.FormSection}>
          <Form.Label className={`text-body-primary ${styles.FormTitle}`}>
            {t('studioSettings.modelerSettings.NumberOfColumns')}
          </Form.Label>
          <Form.Control
            id="formNumberColumns"
            className={styles.FormControl}
            type="number"
            max={64}
            min={0}
            value={session.preferences[Preferences.NUMBER_OF_COLUMNS]}
            onChange={handleNumberOfColumnsOnChange}
            onKeyDown={(ev) => keyDown(ev)}
          />
          <Form.Label className={`text-body-tertiary ${styles.FormDescription}`}>
            {t('studioSettings.modelerSettings.NumberOfColumnsDescription')}
          </Form.Label>
        </Form.Group>
      </Form>
    </>
  );
}

export default Modeler;
