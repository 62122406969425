import React from 'react';
import { Theme } from 'modules/designer/types';
import styles from './style.module.css';

export type ThemePreviewProps = {
  theme: Theme;
};

function ThemePreview({ theme }: ThemePreviewProps) {
  return (
    <div
      style={{
        backgroundColor:
          theme.values && theme.values.find((theme) => theme.key === 'BACKGROUND_COLOR')?.value
      }}
      className={`${styles.previewWrapper} p-2`}
    >
      <div className={`container-fluid ${styles.previewContainer}`}>
        <div
          style={{
            color: theme.values && theme.values.find((theme) => theme.key === 'primary')?.value
          }}
          className={`${styles.label} row`}
        >
          Form
        </div>
        <div className={`${styles.inputs} row`}>
          <label
            style={{
              color:
                theme.values &&
                theme.values.find((theme) => theme.key === 'GLOBAL_FONT_COLOR')?.value
            }}
            className={`${styles.inputLabel}`}
          >
            input1
          </label>
          <div
            style={{
              backgroundColor:
                theme.values && theme.values.find((theme) => theme.key === 'dark')?.value
            }}
            className={`${styles.inputBox}`}
          ></div>
          <label
            style={{
              color:
                theme.values &&
                theme.values.find((theme) => theme.key === 'GLOBAL_FONT_COLOR')?.value
            }}
            className={`${styles.inputLabel}`}
          >
            input2
          </label>
          <div
            style={{
              backgroundColor:
                theme.values && theme.values.find((theme) => theme.key === 'dark')?.value
            }}
            className={`${styles.inputBox}`}
          ></div>
        </div>
        <div className={`${styles.buttons} row`}>
          <div
            style={{
              backgroundColor:
                theme.values && theme.values.find((theme) => theme.key === 'danger')?.value
            }}
            className={`${styles.previewButton} ${styles.btnSecondary}`}
          ></div>
          <div
            style={{
              backgroundColor:
                theme.values && theme.values.find((theme) => theme.key === 'success')?.value
            }}
            className={`${styles.previewButton} ${styles.btnPrimary}`}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ThemePreview;
