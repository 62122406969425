import React from 'react';
import { Provider } from 'react-redux';

import { store } from './store';
import { Column, Index, Relationship, EnumColumn, Table, EnumFrame } from '../types';
import StoreUpdater from './store/StoreUpdater';
import Studio from './studio';
import { WidgetsState } from 'modules/designer/studio/store';

type DatabaseStudioProps = {
  module_id: string;
  schema_id: string;
  tables: Table[];
  columns: Column[];
  indexes: Index[];
  enums: EnumFrame[];
  enumColumns: EnumColumn[];
  relationships: Relationship[];
  widgets: WidgetsState;
  toggleTheme(): void;
};

function DatabaseStudio(props: DatabaseStudioProps) {
  return (
    <Provider store={store}>
      <StoreUpdater
        module_id={props.module_id}
        schema_id={props.schema_id}
        tables={props.tables}
        columns={props.columns}
        indexes={props.indexes}
        enums={props.enums}
        enumColumns={props.enumColumns}
        relationships={props.relationships}
        widgets={props.widgets}
      />
      <Studio toggleTheme={props.toggleTheme} />
    </Provider>
  );
}

export default DatabaseStudio;
