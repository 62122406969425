import React, {
  useEffect,
  useRef,
  ReactNode,
  ComponentType,
  memo,
  useContext,
  useState
} from 'react';
import { setSelectedConnector, setSelectedFrame } from '../store/actions/studio';
import { useDispatch, useSelector } from 'react-redux';
import { FrameUUID } from '../../../../web_ui/workboard/frame/index';
import { WorkboardContext } from 'web_ui/workboard';
import ContextMenu from 'web_ui/workboard/ContextMenu';
import ContextMenuItem from 'web_ui/workboard/ContextMenuItem';
import { DatabaseStudioState } from 'modules/modeler/studio/store';
import { setPosition } from 'modules/modeler/studio/store/actions/frames';
import { deleteEnumFrame, setEnumPosition } from 'modules/modeler/studio/store/actions/enums';
import { deleteTable, dupIndex, dupIndexColumn, duplicateTable } from '../store/actions/root';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import AdvancedEditor from '../components/advanced_editor';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import { columnID, IndexID, IndexType } from 'modules/modeler/types';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { AppContext } from 'modules/project/store/app_context';
import { Dropdown } from 'react-bootstrap';

type FrameWrapperProps = {
  uuid: FrameUUID;
  posX?: number;
  posY?: number;
  children?: ReactNode;
};

const frameWrapper = (Element: ComponentType<any>) => {
  const FrameWrapper = (props: FrameWrapperProps) => {
    const workboard = useContext(WorkboardContext);
    const tables = useSelector((state: DatabaseStudioState) => state.tables);
    const enums = useSelector((state: DatabaseStudioState) => state.enums);
    const columns = useSelector((state: DatabaseStudioState) => state.columns);
    const indexes = useSelector((state: DatabaseStudioState) => state.indexes);
    // Used to show or hide the delete confirmation modal
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const scale = React.useRef(workboard.scale);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLElement>(null);
    // context selected table\
    const selectedTable = useSelector((state: DatabaseStudioState) => state.studio.selectedFrame);
    // used to show or hide the advanced editor
    const [showModal, setShowModal] = useState(false);
    const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);
    const navigate = useNavigate();
    const { app_id, module_id } = useParams();
    const appInfo = useContext(AppContext).projectInformation;
    const queryParameters = useQuery();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isFromVsCodeExt = (): boolean => {
      const itemFound = queryParameters.get('vscode');
      if (itemFound) {
        return Boolean(itemFound);
      } else {
        return false;
      }
    };

    useEffect(() => {
      scale.current = workboard.scale;
    }, [workboard.scale]);

    useEffect(() => {
      const handleMouseDown = (e: MouseEvent) => {
        let initialCursorX = e.clientX;
        let initialCursorY = e.clientY;
        let newPosX: number;
        let newPosY: number;
        dispatch(setSelectedFrame(props.uuid));

        const handleMouseMove = (ev: MouseEvent) => {
          ev.preventDefault();
          ev.stopPropagation();

          const currentCursorX = initialCursorX - ev.clientX;
          const currentCursorY = initialCursorY - ev.clientY;
          initialCursorX = ev.clientX;
          initialCursorY = ev.clientY;

          if (wrapperRef.current) {
            newPosX = wrapperRef.current.offsetLeft - currentCursorY / scale.current;
            newPosY = wrapperRef.current.offsetTop - currentCursorX / scale.current;
            wrapperRef.current.style.top =
              wrapperRef.current.offsetTop - currentCursorY / scale.current + 'px';
            wrapperRef.current.style.left =
              wrapperRef.current.offsetLeft - currentCursorX / scale.current + 'px';
            wrapperRef.current.style.cursor = 'grabbing';
          }
        };

        const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);

          if (wrapperRef.current) wrapperRef.current.style.cursor = 'grab';
          if (newPosX && newPosY && (props.posX !== newPosX || props.posY !== newPosY)) {
            if (tables[props.uuid]) {
              dispatch(setPosition(props.uuid, newPosX, newPosY));
            } else {
              dispatch(setEnumPosition(props.uuid, newPosX, newPosY));
            }
          }
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      };

      elementRef.current?.addEventListener('mousedown', handleMouseDown);
    }, []);

    useEffect(() => {
      if (wrapperRef.current) {
        wrapperRef.current.style.cursor = 'pointer';
      }
      elementRef.current?.addEventListener('click', (e) => {
        dispatch(setSelectedFrame(props.uuid));
        dispatch(setSelectedConnector(null));
      });
    }, [dispatch, props.uuid]);

    const sleep = (milliseconds: number) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    async function handleDeleteTable() {
      const el = document.getElementById(props.uuid);
      if (el) {
        el.style.opacity = '0';
        await sleep(220);
        dispatch(setSelectedFrame(null));
        tables[props.uuid]
          ? dispatch(deleteTable(props.uuid))
          : dispatch(deleteEnumFrame(props.uuid));
      }
      setShowDeleteModal(false);
    }

    function makeDuplicateTableName(oldName: string): string {
      const duplicateSuffix = '_COPY';
      for (
        let newName = oldName + duplicateSuffix, i = 1;
        ;
        newName = oldName + duplicateSuffix + '_' + i++
      ) {
        // Check for duplicates
        const isDuplicate =
          Object.values(tables).filter((table) => {
            const name = table.content.data.name as string;
            return name === newName;
          }).length > 0;
        if (!isDuplicate) {
          return newName;
        }
      }
    }

    function getRandomInt(minimum: number, range: number): number {
      return Math.floor(minimum + Math.random() * range);
    }

    const handleDuplicateTable = () => {
      if (!props.uuid) {
        return;
      }
      const oldTable = tables[props.uuid];

      // Select which columns to copy and set their new Ids
      const newColumns: Record<columnID, columnID> = {};
      const tableColumns: string[] = oldTable.content.data.columns;
      tableColumns
        .map((columnId) => columns[columnId])
        .filter((column) => !column.isFK) // exclude all FKs
        .forEach((column) => {
          newColumns[column.uuid] = uuidv4();
        });

      // Select which indexes to copy and define their new Ids (Indexes / IndexColumns)
      const newIndexes: Record<IndexID, dupIndex> = {};
      const tableIndexes: string[] = oldTable.content.data.indexes;
      tableIndexes
        .map((indexId) => indexes[indexId])
        .filter((index) => index.type === IndexType.PRIMARY) //only primary indexes
        .forEach((index) => {
          const newIndexColumns: Record<string, dupIndexColumn> = {};

          Object.values(index.columns).forEach((indexColumn) => {
            newIndexColumns[indexColumn.id] = {
              indexColumnId: uuidv4(),
              columnId: newColumns[indexColumn.columnId]
            } as dupIndexColumn;
          });

          newIndexes[index.id] = {
            indexId: uuidv4(),
            indexColumns: newIndexColumns
          } as dupIndex;
        });

      // Define the new table
      const newTable = {
        uuid: uuidv4(),
        type: 'TABLE',
        name: makeDuplicateTableName(oldTable.content.data.name),
        description: oldTable.content.description ?? '',
        posX: getRandomInt(oldTable.posX ? oldTable.posX + 400 : 0, 200),
        posY: getRandomInt(oldTable.posY ? oldTable.posY + 40 : 0, 100),
        indexes: newIndexes,
        columns: newColumns
      };
      // Dispatch this event to close open editors
      const openFrameEditorEvent = new Event('openFrameEditor');
      document.dispatchEvent(openFrameEditorEvent);

      // Dispatch duplicate action
      dispatch(duplicateTable(newTable));
      dispatch(setSelectedFrame(newTable.uuid));
    };

    return (
      <div
        id={props.uuid}
        ref={wrapperRef}
        style={{
          cursor: 'grad',
          position: 'absolute',
          left: tables[props.uuid] ? tables[props.uuid].posX + 'px' : enums[props.uuid].posX + 'px',
          top: tables[props.uuid] ? tables[props.uuid].posY + 'px' : enums[props.uuid].posY + 'px',
          transition: 'opacity ease-in-out 0.2s',
          zIndex: selectedTable === props.uuid ? 3 : 2
        }}
      >
        <Element ref={elementRef} {...props} />
        {(tables[props.uuid] ? !tables[props.uuid].content.data.native : true) && (
          <ContextMenu elementRef={wrapperRef} offsetX={96} offsetY={79}>
            {tables[props.uuid] && tables[props.uuid].type === 'TABLE' ? (
              <>
                <ContextMenuItem
                  icon="clone me-2"
                  label={t('modeler.Duplicate')}
                  onClick={handleDuplicateTable}
                />
                <ContextMenuItem
                  icon="trash"
                  label={t('Delete')}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                />
                <ContextMenuItem
                  icon="fa-solid fa-sliders"
                  label={t('modeler.Details')}
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
                <Dropdown.Divider />
                <ContextMenuItem
                  icon="fa-solid fa-code"
                  label={t('CodePreview')}
                  onClick={() => setShowCodePreviewDialog(true)}
                />
                {appInfo?.has_backend && (
                  <ContextMenuItem
                    icon="wand-magic-sparkles"
                    label={t('CrudAssistant')}
                    onClick={() =>
                      navigate(
                        `/app/${app_id}/module/${module_id}/logic/automation-wizard?entity=${
                          props.uuid
                        }${isFromVsCodeExt() ? '&vscode=true' : ''}`
                      )
                    }
                  />
                )}
              </>
            ) : (
              <ContextMenuItem
                icon="trash"
                label={t('Delete')}
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              />
            )}
          </ContextMenu>
        )}
        <Confirmation
          show={showDeleteModal}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirmation={handleDeleteTable}
          message={
            tables[props.uuid]
              ? t('modeler.Delete Table') + ' (' + tables[props.uuid].content.data.name + ')'
              : t('modeler.Delete Enum') + ' (' + enums[props.uuid].content.data.name + ')'
          }
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
        {selectedTable && tables[selectedTable] && (
          <AdvancedEditor
            tableID={selectedTable}
            showModal={showModal}
            onCloseRequest={() => {
              setShowModal(false);
            }}
          />
        )}
        {selectedTable && (
          <CodeEditorModal
            show={showCodePreviewDialog}
            handleClose={() => setShowCodePreviewDialog(false)}
            id={selectedTable}
            previewType={CodePreviewType.TABLE}
          />
        )}
      </div>
    );
  };

  return memo(FrameWrapper);
};

export default frameWrapper;
