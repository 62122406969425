import { TablesState, initialState, DatabaseStudioState } from '../index';
import {
  SET_POSITION,
  setPositionAction,
  changePropertyAction,
  SET_TABLES,
  CHANGE_TABLE_PROPERTY,
  setTablesAction,
  TableActions,
  changeTableColumnsOrderAction,
  CHANGE_TABLE_COLUMNS_ORDER
} from '../actions/frames';
import {
  addTableAction,
  ADD_TABLE,
  ADD_COLUMN,
  addColumnAction,
  ADD_RELATIONSHIP,
  addRelationshipAction,
  ADD_INDEX,
  addIndexAction,
  DUPLICATE_TABLE,
  duplicateTableAction
} from '../actions/root';
import produce from 'immer';
import { Table } from '../../../types';

export const tablesReducer = (state = initialState.tables, action: TableActions) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_TABLES:
        return doSetTables(draft, action);
      case SET_POSITION:
        return doSetPosition(draft, action);
      case ADD_TABLE:
        return doAddTable(draft, action);
      case CHANGE_TABLE_PROPERTY:
        return doChangeProperty(draft, action);
      case CHANGE_TABLE_COLUMNS_ORDER:
        return doChangeTableColumnsOrder(draft, action);
      case ADD_COLUMN:
        return doSetColumn(draft, action);
      case ADD_RELATIONSHIP:
        return doAddRelationship(draft, action);
      case ADD_INDEX:
        return doAddIndex(draft, action);
      case DUPLICATE_TABLE:
        return doDuplicateTable(draft, action);
      default:
        return draft;
    }
  });
};

function doSetTables(state = initialState.tables, action: setTablesAction) {
  state = {};
  action.payload.tables.forEach((table: Table) => {
    state[table.uuid] = table;
  });
  return state;
}

function doSetPosition(state: TablesState, action: setPositionAction) {
  state[action.payload.uuid].posX = action.payload.posX;
  state[action.payload.uuid].posY = action.payload.posY;
  return state;
}

function doAddTable(state: TablesState, action: addTableAction) {
  const newTable: Table<any> = {
    uuid: action.payload.uuid,
    type: 'TABLE',
    posX: action.payload.posX,
    posY: action.payload.posY,
    content: {
      data: {
        name: action.payload.name,
        description: action.payload.description,
        columns: [action.payload.idPkColumn],
        indexes: [action.payload.idPkIndex],
        relationships: []
      }
    },
    data: undefined
  };
  state[newTable.uuid] = newTable;
}

function doChangeProperty(state: TablesState, action: changePropertyAction) {
  state[action.payload.uuid].content.data[action.payload.key] = action.payload.value;
  return state;
}

function doChangeTableColumnsOrder(state: TablesState, action: changeTableColumnsOrderAction) {
  const table = state[action.payload.tableUUID];

  const originIndex = table.content.data.columns.indexOf(action.payload.originUUID);
  const targetIndex = table.content.data.columns.indexOf(action.payload.targetUUID);

  table.content.data.columns.splice(originIndex, 1);
  table.content.data.columns.splice(targetIndex, 0, action.payload.originUUID);

  return state;
}

function doSetColumn(state: TablesState, action: addColumnAction) {
  state[action.payload.tableUUID].content.data.columns.push(action.payload.UUID);
}

function doAddRelationship(state: TablesState, action: addRelationshipAction) {
  Object.keys(action.payload.components).map((key: string) => {
    if (action.payload.type === 'ONE2MANY') {
      state[action.payload.to].content.data.columns.push(action.payload.components[key]);
    } else {
      state[action.payload.from].content.data.columns.push(key);
    }
    return null;
  });
  state[action.payload.from].content.data.relationships.push(action.payload.id);
  state[action.payload.to] &&
    state[action.payload.to].content.data.relationships.push(action.payload.id);
  return state;
}

function doAddIndex(state: TablesState, action: addIndexAction) {
  state[action.payload.tableID].content.data.indexes.push(action.payload.index.id);
}

function doDuplicateTable(state: TablesState, action: duplicateTableAction) {
  const newIndexIds = Object.values(action.payload.indexes).map((idx) => idx.indexId);
  const newColumnIds = Object.values(action.payload.columns).map((value) => value);
  const newTable: Table<any> = {
    type: 'TABLE',
    uuid: action.payload.uuid,
    posX: action.payload.posX,
    posY: action.payload.posY,
    content: {
      data: {
        name: action.payload.name,
        description: action.payload.description,
        columns: newColumnIds,
        indexes: newIndexIds,
        relationships: []
      }
    },
    data: undefined
  };
  state[newTable.uuid] = newTable;
}
