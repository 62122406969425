import React, { useMemo } from 'react';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { SelectedAutomation } from '../../types';

const beforelineStyle = {
  stroke: 'var(--bs-emphasis-color)'
};

const afterlineStyle = {
  stroke: 'var(--bs-dark-border-subtle)'
};

const lineWrapper = {
  height: '200px',
  width: '100%',
  paddingTop: '50px'
};

type WizardProgressProps = {
  currentStep: number;
  selectedAutomation: SelectedAutomation;
};

export function WizardProgress(props: WizardProgressProps) {
  const { t } = useTranslation();

  const svgCircle = useMemo(() => {
    return (
      <svg className={styles.SvgCircle} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" />
      </svg>
    );
  }, []);

  const svgLine = useMemo(() => {
    // There are four circles totaling 3 sections.
    // 100 / 3 = 33.33. Each step equals to 33.33% of the total line width.
    const breakPoint = props.currentStep * (100 / (props.selectedAutomation.numberOfSteps - 1));
    return (
      <svg style={lineWrapper} xmlns="http://www.w3.org/2000/Svg">
        <line
          style={beforelineStyle}
          x1="0%"
          x2={`${breakPoint}%`}
          y1="50"
          y2="50"
          strokeWidth="0.6"
        />
        <line
          style={afterlineStyle}
          x1={`${breakPoint}%`}
          x2="100%"
          y1="50"
          y2="50"
          strokeWidth="0.6"
        />
      </svg>
    );
  }, [props.currentStep, props.selectedAutomation.numberOfSteps]);

  return (
    <div className={styles.WizardProgressWrapper}>
      <div
        className={styles.WizardProgress}
        // style={{ width: `${props.selectedAutomation.numberOfSteps * 20}%` }}
      >
        {/* Icons */}
        <div className={styles.StepIconsWrapper}>
          {props.selectedAutomation.stepsIcons.map((icon, index) => {
            return (
              <div
                key={index}
                className={`${styles.StepIconDiv} ${
                  props.currentStep === index && styles.BlueElement
                }
                ${props.currentStep < index && styles.GrayElement}
                ${props.currentStep > index && styles.DarkElement}`}
              >
                <Icon iconName={icon} extraProps="fa-2xl" />
              </div>
            );
          })}
        </div>

        {/* Circles */}
        <div className={styles.SvgCirclesWrapper}>
          <div className={styles.CirclesWrapper}>
            {Array.from(Array(props.selectedAutomation.numberOfSteps).keys()).map((order) => {
              return (
                <div
                  key={order}
                  className={`${styles.SvgCircleDiv} ${
                    props.currentStep === order && styles.BlueCircle
                  }
                ${props.currentStep < order && styles.GrayCircle}
                ${props.currentStep > order && styles.DarkCircle}`}
                >
                  {svgCircle}
                </div>
              );
            })}
          </div>
          {/* Line */}
          <div className={styles.SvgLineDiv}>{svgLine}</div>
        </div>

        {/* Titles */}
        <div className={styles.StepTitleWrapper}>
          {props.selectedAutomation.stepsTitles.map((title, index) => {
            return (
              <div
                key={index}
                className={`${styles.StepTitleDiv} ${
                  props.currentStep === index && styles.BlueElement
                }
                ${props.currentStep < index && styles.GrayElement}
                ${props.currentStep > index && styles.DarkElement}`}
              >
                {t('automation.steps.' + title)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
