import React, { useContext, useState } from 'react';
import CategoriesFilter from './categories_filter';
import ActionsList from './actions_list';

import {
  ActionsCategory,
  ActionsCategoriesIcons,
  FunctionActionManifest
} from '../../store/types/manifestsAndInputs';
import SearchBar from '../../../search_bar';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'modules/project/store/app_context';
import { FRONTEND_ACTIONS_TYPES } from 'modules/designer/function_editor/function_actions/types/actions';

type ActionsToolbarProps = {
  actions: FunctionActionManifest[];
  categories: ActionsCategory[];
  categoriesIcons: ActionsCategoriesIcons;
};

function ActionsToolbar({ actions, categories, categoriesIcons }: ActionsToolbarProps) {
  const [text, setText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const hasAuthenticationActions = useContext(AppContext).projectInformation?.has_authentication;
  const hasFrontend = useContext(AppContext).projectInformation?.has_frontend;
  const hasBackend = useContext(AppContext).projectInformation?.has_backend;
  const hasDatabase = useContext(AppContext).projectInformation?.has_database;
  const { t } = useTranslation();

  const onTextChange = (newText: string) => {
    setText(newText);
    setSelectedCategory('');
  };

  const filterBySelectedCategory = (a: FunctionActionManifest) =>
    !selectedCategory || a.category === selectedCategory;

  const filterBySearchText = (a: FunctionActionManifest) =>
    !text.trim() || a.name.toLowerCase().includes(text.trim().toLowerCase());

  const filterByHasAuthentication = (a: FunctionActionManifest) => {
    if (!hasAuthenticationActions && a.type === FRONTEND_ACTIONS_TYPES.AUTHENTICATION_LOGOUT) {
      return false;
    }
    return true;
  };

  const filterByHasProjectStudio = (a: FunctionActionManifest) => {
    if (a.shouldHaveFrontend && !hasFrontend) return false;
    if (a.shouldHaveBackend && !hasBackend) return false;
    if (a.shouldHaveDatabase && !hasDatabase) return false;
    return true;
  };

  const filteredActions = actions
    .filter(filterBySelectedCategory)
    .filter(filterBySearchText)
    .filter(filterByHasAuthentication)
    .filter(filterByHasProjectStudio);

  return (
    <div className={'d-flex flex-column h-100 flex-grow-1 pt-2'}>
      <SearchBar
        id={'searchField'}
        placeholder={t('Search') ?? ''}
        text={text}
        setText={onTextChange}
      />
      <hr />
      {!text && (
        <CategoriesFilter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={categories}
          categoriesIcons={categoriesIcons}
        />
      )}
      <ActionsList actions={filteredActions} />
    </div>
  );
}

export default ActionsToolbar;
