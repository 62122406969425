import React from 'react';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../../store/types/function_editor_state';
import useHandleDisplayTypeName from '../../../../../hooks/useHandleDisplayTypeName';
import { ReturnType } from '../../../../../store/types/functions';

export type ReturnTypeProps = {
  types: ReturnType[];
};
function FunctionReturnType({ types }: ReturnTypeProps) {
  const function_id = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const func = useSelector((state: FunctionEditorState) => state.functions[function_id ?? '']);
  const returnType = func?.returnType;
  let typeName = useHandleDisplayTypeName(returnType, types);

  if (returnType && returnType.list) {
    typeName = `List<${typeName}>`;
  }

  return <span className={styles.ReturnType}>{typeName}</span>;
}

export default FunctionReturnType;
