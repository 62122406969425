import React, { forwardRef, memo } from 'react';
import { ComponentUUID } from '../../../types';
import Alert from 'react-bootstrap/Alert';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';

export type AlertData = {
  text: string;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  header?: boolean;
  title?: string;
  translateKey?: string;
  hasTranslation?: string;
  tooltip?: string;
  visible?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type AlertStyles = {
  background?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  staticColor?: string;
  themeColor?: string;
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  classes?: string[];
};

export type AlertComponentProps = {
  uuid: ComponentUUID;
  data: AlertData;
  styles: AlertStyles;
};

function AlertComponent(props: AlertComponentProps, ref: React.Ref<any>) {
  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.staticColor,
    fontSize: `${props.styles.fontSize}px`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  if (props.data.widthCalc && props.data.width !== 'fit-content' && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <Alert
      ref={ref}
      className={`text-${props.styles.themeColor} ${classes}`}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      variant={props.styles.background}
      dismissible={true}
      title={props.data.tooltip}
    >
      {props.data.header && (
        <>
          <Alert.Heading>{props.data.title}</Alert.Heading>
          <hr />
        </>
      )}
      {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}
    </Alert>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(AlertComponent));
