import { Action } from 'redux';
import { VariablesState } from '../../types/variables';

export const CHANGE_VARIABLE_TYPE = 'CHANGE_VARIABLE_TYPE';

export interface ChangeVariableTypeAction extends Action {
  type: 'CHANGE_VARIABLE_TYPE';
  payload: {
    uuid: string;
    type: string;
    required: boolean;
    list: boolean;
    objectUuid?: string;
    enumUuid?: string;
  };
}

export const changeVariableType = (
  uuid: string,
  type: string,
  required: boolean,
  list: boolean,
  objectUuid?: string,
  enumUuid?: string
): ChangeVariableTypeAction => ({
  type: 'CHANGE_VARIABLE_TYPE',
  payload: {
    uuid,
    type,
    required,
    list,
    objectUuid,
    enumUuid
  }
});

export function doChangeVariableType(
  state: VariablesState,
  action: ChangeVariableTypeAction
): VariablesState {
  const { uuid, type, required, list, objectUuid, enumUuid } = action.payload;
  if (!state[uuid]) return state;
  state[uuid] = { ...state[uuid], uuid, type, required, list, objectUuid, enumUuid };
  return state;
}
