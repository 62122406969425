import { createContext } from 'react';
import {
  Endpoint,
  EndpointExtended,
  FunctionExtended,
  FunctionType,
  ObjectSimple,
  Service,
  Controller,
  SchedulerJob,
  LogicBuilderConcept,
  Tag
} from '../types';
import { Folder } from 'modules/designer/types';
import { FolderContext } from 'routes/studio/logic';

export type LogicBuilderContextType = {
  app_id: string | undefined;
  tags: { [key: number]: Tag };
  fetchTags: (appId: string) => void;
  module_id: string | undefined;
  editingEndpoint: EndpointExtended;
  setEditingEndpoint: (e: EndpointExtended) => void;
  endpoints: { [key: string]: Endpoint[] };
  createEndpoint: (e: Endpoint) => void;
  changeEndpoint: (e: Endpoint) => void;
  deleteEndpoint: (endpointUuid: string, controllerUuid: string) => void;
  editingFunction: FunctionExtended;
  setEditingFunction: (f: FunctionExtended) => void;
  functions: { [key: string]: FunctionType[] };
  createFunction: (e: FunctionType) => void;
  changeFunction: (e: FunctionType) => void;
  deleteFunction: (functionUuid: string, serviceUuid: string) => void;
  checkRepeatedService: (service: Service) => boolean;
  controllers: { [key: string]: Controller };
  createController: (e: Controller) => void;
  changeController: (e: Controller) => void;
  deleteController: (controllerUuid: string) => void;
  fetchControllers: (moduleId: string) => void;
  checkRepeatedController: (e: Controller) => boolean;
  fetchEndpoints: (moduleId: string) => void;
  fetchFunctions: (moduleId: string) => void;
  objects: ObjectSimple[];
  createObject: (object: ObjectSimple) => void;
  deleteObject: (id: string) => void;
  changeObject: (object: ObjectSimple) => void;
  fetchObjects: (moduleId: string) => Promise<ObjectSimple[]>;
  schedulerJobs: SchedulerJob[];
  fetchSchedulerJobs: (moduleId: string) => void;
  checkRepeatedObject: (object: ObjectSimple) => boolean;
  services: { [key: string]: Service };
  createService: (e: Service) => void;
  changeService: (e: Service) => void;
  deleteService: (serviceUuid: string) => void;
  fetchServices: (moduleId: string) => void;
  getSelectedConcept: () => LogicBuilderConcept;
  navigateToConcept: (concept: LogicBuilderConcept, id?: string) => void;
  lastSelectedConcept: { obj: string; fnc: string; sch: string; ept: string };
  setLastSelectedConcept: (concept: LogicBuilderConcept, id: string) => void;
  updateFolders: (folders: Folder[], context: FolderContext) => void;
  objectsFolders?: Folder[];
  controllersFolders?: Folder[];
  servicesFolders?: Folder[];
  schedulerFolders?: Folder[];
  changeSchedulerJob: (newSchedulerJob: SchedulerJob) => void;
  deleteSchedulerJob: (schedulerJobId: string) => void;
  createSchedulerJob: (schedulerJob: SchedulerJob) => void;
  checkRepeatedSchedulerJob: (schedulerJob: SchedulerJob) => boolean;
};

const initialState: LogicBuilderContextType = {
  app_id: '',
  tags: {},
  fetchTags: () => {},
  module_id: '',
  editingEndpoint: {} as EndpointExtended,
  setEditingEndpoint: () => {},
  endpoints: {},
  createEndpoint: () => {},
  changeEndpoint: () => {},
  deleteEndpoint: () => {},
  editingFunction: {} as FunctionExtended,
  setEditingFunction: () => {},
  functions: {},
  createFunction: () => {},
  changeFunction: () => {},
  deleteFunction: () => {},
  controllers: {},
  createController: () => {},
  changeController: () => {},
  deleteController: () => {},
  fetchControllers: () => {},
  checkRepeatedController: () => true,
  fetchEndpoints: () => {},
  fetchFunctions: () => {},
  objects: [],
  createObject: () => {},
  deleteObject: () => {},
  changeObject: () => {},
  fetchObjects: async () => [],
  schedulerJobs: [],
  fetchSchedulerJobs: () => {},
  checkRepeatedObject: () => true,
  services: {},
  createService: () => {},
  changeService: () => {},
  deleteService: () => {},
  fetchServices: async () => [],
  checkRepeatedService: () => true,
  getSelectedConcept: () => LogicBuilderConcept.OBJECT,
  navigateToConcept: () => {},
  lastSelectedConcept: { obj: '', fnc: '', sch: '', ept: '' },
  setLastSelectedConcept: () => {},
  updateFolders: () => {},
  objectsFolders: [],
  controllersFolders: [],
  servicesFolders: [],
  schedulerFolders: [],
  changeSchedulerJob: () => {},
  deleteSchedulerJob: () => {},
  createSchedulerJob: () => {},
  checkRepeatedSchedulerJob: () => true
};

const LogicBuilderContext = createContext<LogicBuilderContextType>(initialState);

export default LogicBuilderContext;
