import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import style from './style.module.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PopupAlert, PopupAlertVariant } from '../../../../../../web_ui/popup_alert';
import useSession from '../../../../../../hooks/useSession';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from 'modules/designer/studio/store/actions/studio';

type Props = {
  show: boolean;
  onHide: () => void;
  updateFiles: () => Promise<void>;
};

export interface FileMetaData {
  extension: string;
  id: number;
  name: string;
  size: number;
  url: string;
}

const formData = new FormData();

export function ModalFile({ show, onHide, updateFiles }: Props) {
  const API_URL = process.env.REACT_APP_API_URL;
  const { app_id } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');
  const [session] = useSession();

  useEffect(() => {
    if (!show) {
      return;
    }
    // Restart states.
    formData.delete('file');
  }, [show]);

  const showSuccessPopup = (message: string) => {
    setAlertVariant('success');
    setAlertMessage(message);
  };

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const createFile = React.useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!formData.get('file')) {
      return showErrorPopup('appResume.assets.EmptyAssetError');
    }
    fetch(`${API_URL}/apps/${app_id}/assets`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData
    })
      .then(async (res) => {
        if (res.status === 413) {
          formData.delete('file');
          showErrorPopup('appResume.assets.tooLarge');
          return;
        }
        // Clear file inside formdata after request.
        await updateFiles().then(() => {
          showSuccessPopup('appResume.assets.UploadSuccessful');
          onHide();
        });
        await session.reloadUser();
      })
      .catch(() => {
        showErrorPopup('appResume.assets.UploadError');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [API_URL, app_id, loading, onHide, updateFiles]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('appResume.assets.modalSubmitFile.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className={'mb-3'}>
            <Form.Label>{t('appResume.assets.modalSubmitFile.subtitle')}</Form.Label>
            <Form.Control
              id={'uploadFile'}
              type="file"
              onChange={(ev: any) => {
                formData.append('file', ev.target.files[0]);
              }}
              required
            />
          </Form.Group>
          <div className={style.containerButton}>
            <Button id={'saveButton'} variant="success" onClick={createFile}>
              {t('Upload')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
      )}
    </>
  );
}
