export const onlyNumberRegex = /^\d*\.?\d*$/;
export const moduleNameRegex = /^[ -\\_.a-zA-Z0-9]+$/;
export const objectItemRegex = '^[ \\-_.a-zA-Z0-9]+$';
export const functionNameRegex = /^[a-z][-a-zA-Z0-9_]*$/;
export const endpointNameRegex = /^[a-z][a-zA-Z0-9_]*$/;
export const parameterNameRegex = functionNameRegex;
export const variableNameRegex = functionNameRegex;
export const pageNameRegex = /^[a-zA-Z][-a-zA-Z0-9_]*$/;
export const routeNameRegex = /^\/[/_a-zA-Z0-9:]*$/;
export const paramPageRegex = functionNameRegex;
export const hexadecimalColorRegex = /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
export const onlyHexadecimalColorRegex = /^#[a-fA-F0-9]{1,7}$/;
