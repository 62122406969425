import styles from './styles.module.css';
import React from 'react';
import { Column } from '../../../../../../../types';
import { DatabaseStudioState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { changeTableColumnsOrder } from '../../../../../../store/actions/frames';
import ColumnInstance from './column_instance';

type ColumnsListProps = {
  tableColumns: Column[];
  selectedColumnID: string;
  selectColumn: React.Dispatch<React.SetStateAction<string>>;
};
export default function ColumnsList({
  tableColumns,
  selectedColumnID,
  selectColumn
}: ColumnsListProps) {
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const dispatch = useDispatch();

  const handleDrop = async function (e: React.DragEvent<HTMLElement>, columnID: string) {
    const draggedColumnID = e.dataTransfer.getData('exocode/dragged-column-id');
    const tableID = columns[columnID].tableUUID;

    // Can only reorder PKs if both the target and origin are PK
    if (columns[columnID].isPK && !columns[draggedColumnID].isPK) return;
    if (columns[draggedColumnID].isPK && !columns[columnID].isPK) return;

    handleChangeOrder(tableID, draggedColumnID, columnID);
  };

  function handleChangeOrder(tableID: string, originID: string, targetID: string) {
    dispatch(changeTableColumnsOrder(tableID, originID, targetID));
  }

  return (
    <div id="bodyModal" className={`card p-2 ${styles.ColumnsListWrapper}`}>
      {tableColumns &&
        tableColumns.map((column) => {
          return (
            <ColumnInstance
              key={column?.uuid}
              column={column}
              selectedColumnID={selectedColumnID}
              selectColumn={selectColumn}
              handleDrop={handleDrop}
            />
          );
        })}
    </div>
  );
}
