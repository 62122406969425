import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { DATA_TYPES, DataType, FunctionParameter, ObjectSimple } from 'modules/logic_builder/types';
import { FunctionService, ObjectsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import { EnumFrame } from 'modules/modeler/types';
import { parameterNameRegex } from '../../../../../utils/regex';
import { useTranslation } from 'react-i18next';

type FunctionParameterCreatorProps = {
  show: boolean;
  onClose: () => void;
  functionId: string;
  fetchParameters: () => void;
  enumsList: EnumFrame[];
};

export function FunctionParameterCreatorDialog(props: FunctionParameterCreatorProps) {
  const [newParameter, setNewParameter] = useState<FunctionParameter>();
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const { module_id } = useParams();
  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

  const fetchObjects = useCallback(async () => {
    if (!module_id) return;
    await ObjectsService.getObjectsByModule(module_id).then((fetchedObjects) => {
      setObjects(fetchedObjects);
    });
  }, [module_id]);

  useEffect(() => {
    if (!props.show) return;

    fetchObjects();
    setErrorMessage(false);
  }, [fetchObjects, props.show]);

  useEffect(() => {
    if (!props.show) return;

    setNewParameter({
      name: '',
      description: '',
      type: 'BOOLEAN',
      list: false,
      required: false,
      objectUuid: '',
      order: 0
    } as FunctionParameter);
    setIsNameInvalid(false);
  }, [props.show]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    setErrorMessage(false);

    if (!newParameter || !module_id) return;

    try {
      await FunctionService.createParameter(props.functionId, newParameter).then(() => {
        props.fetchParameters();
        props.onClose();
      });
    } catch (err) {
      setErrorMessage(true);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.onClose()}
      centered
      className={styles.ParameterCreator}
    >
      <Modal.Header closeButton>
        <Modal.Title>Parameter creator</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          {errorMessage && (
            <span className={styles.errorMsg}>
              {`*${t('logicBuilder.errorCreatingEditingParameters')}`}
            </span>
          )}
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="name..."
              required
              isInvalid={isNameInvalid}
              value={newParameter?.name ?? ''}
              onChange={(e) => {
                setIsNameInvalid(!parameterNameRegex.test(e.target.value));
                setNewParameter({ ...newParameter, name: e.target.value } as FunctionParameter);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please inform a valid parameter name
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formType">
            <Form.Label>Type</Form.Label>
            <Form.Select
              required
              onChange={(e) =>
                setNewParameter({
                  ...newParameter,
                  type: e.target.value,
                  objectUuid: '',
                  enumUuid: ''
                } as FunctionParameter)
              }
              value={newParameter?.type ?? 'default'}
            >
              {!newParameter?.type && <option value="default">Select parameter type</option>}
              {Object.keys(DATA_TYPES).map((typeKey) => {
                return (
                  <option key={typeKey} value={typeKey}>
                    {DATA_TYPES[typeKey as DataType]}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              maxLength={64}
              placeholder="description..."
              className="ml-3"
              onChange={(e) =>
                setNewParameter({
                  ...newParameter,
                  description: e.target.value
                } as FunctionParameter)
              }
              value={newParameter?.description ?? ''}
            />
          </Form.Group>

          <Row className="mb-3">
            <Col sm={6}>
              <Form.Group controlId="formIsArray">
                <Form.Check
                  inline
                  label="Is Array"
                  type="checkbox"
                  name="isArray"
                  onChange={(e) =>
                    setNewParameter({
                      ...newParameter,
                      list: e.target.checked
                    } as FunctionParameter)
                  }
                  checked={newParameter?.list ?? false}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group controlId="formIsRequired">
                <Form.Check
                  inline
                  label="Is Required"
                  type="checkbox"
                  name="isRequired"
                  onChange={(e) =>
                    setNewParameter({
                      ...newParameter,
                      required: e.target.checked
                    } as FunctionParameter)
                  }
                  checked={newParameter?.required ?? false}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="formType">
            <Form.Label>Objects</Form.Label>
            <Form.Select
              required
              disabled={newParameter?.type !== 'OBJECT'}
              onChange={(e) =>
                setNewParameter({
                  ...newParameter,
                  objectUuid: e.target.value
                } as FunctionParameter)
              }
              value={newParameter?.objectUuid ?? 'default'}
            >
              {!newParameter?.objectUuid && <option value={'default'}>Select object</option>}
              {objects.map((object) => {
                return (
                  <option key={object.uuid} value={object.uuid}>
                    {object.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formSelectEnum">
            <Form.Label>Enums</Form.Label>
            <Form.Select
              required
              disabled={newParameter?.type !== 'ENUM'}
              onChange={(e) =>
                setNewParameter({
                  ...newParameter,
                  enumUuid: e.target.value
                } as FunctionParameter)
              }
              value={newParameter?.enumUuid ?? 'default'}
            >
              {!newParameter?.enumUuid && <option value={'default'}>Select enum</option>}
              {props.enumsList.map((enumItem) => {
                return (
                  <option key={enumItem.uuid} value={enumItem.uuid}>
                    {enumItem.content.data.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button
            id="saveButton"
            variant="primary"
            type="submit"
            disabled={isNameInvalid || !newParameter?.type}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
