import { Theme, ThemeValues } from '../types';
import { ThemeSchema } from './schemas';

export const API_URL = process.env.REACT_APP_API_URL;

export class ThemeRepo {
  private buildSchemaTheme(Theme: Theme) {
    const themeSchema: ThemeSchema = {
      name: Theme.name,
      values: Theme.values
    };
    return themeSchema;
  }

  async createTheme(appUuid: string, theme: Theme) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const themeSchema: ThemeSchema = this.buildSchemaTheme(theme);

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(themeSchema)
    };

    const url = `${API_URL}/apps/${appUuid}/themes`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Theme>;
      throw new Error('Something went wrong while trying to fetch the Theme.');
    });
  }

  async getThemesByAppUuid(appUuid: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${appUuid}/themes`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Theme[]>;
      throw new Error('Something went wrong while trying to fetch themes.');
    });
  }

  async deleteTheme(appUuid: string, themeUuid: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${appUuid}/themes/${themeUuid}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response;
      throw new Error('Something went wrong while trying to delete theme.');
    });
  }

  async updateThemeValue(appUuid: string, themeValue: ThemeValues) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const themeValueSchema = themeValue as ThemeValues;

    const options: RequestInit = {
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(themeValueSchema)
    };

    const url = `${API_URL}/apps/${appUuid}/themesvalue`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ThemeValues>;

      throw new Error('Something went wrong while trying to update theme value.');
    });
  }

  async updateTheme(appUuid: string, theme: Theme) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const themeSchema = theme as Theme;

    const options: RequestInit = {
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(themeSchema)
    };

    const url = `${API_URL}/apps/${appUuid}/themes`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ThemeValues>;

      throw new Error('Something went wrong while trying to update theme value.');
    });
  }
}
