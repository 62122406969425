import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ModuleInfo } from 'modules/dashboard/types';
import styles from './styles.module.css';
import { DropdownMenu } from './DropdownMenu';
import { DropdownToggle } from './DropdownToggle';
import CreateModuleModal from '../../../../../project/components/create_module_modal';
import { useParams } from 'react-router-dom';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import { useTranslation } from 'react-i18next';

type BreadcrumbModuleDropdownProps = {
  modulesList: ModuleInfo[] | undefined;
  selectedModule: ModuleInfo | undefined;
  handleSelectModule: (module: ModuleInfo) => void;
  handleUpdateModulesList: () => void;
};

export function BreadcrumbModuleDropdown(props: BreadcrumbModuleDropdownProps) {
  const [showCreateModuleModal, setShowCreateModuleModal] = React.useState(false);
  const { app_id } = useParams();
  const { t } = useTranslation();

  const modalOnCloseRequest = React.useCallback(() => {
    setShowCreateModuleModal(false);
  }, []);

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={DropdownToggle}
        id="dropdown-toggle"
        handleUpdateList={props.handleUpdateModulesList}
      >
        <p
          id={props.selectedModule?.name}
          className={styles.hidingName}
          style={{ padding: 0, margin: 0 }}
        >
          {props.selectedModule?.name}
        </p>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={DropdownMenu}
        openModal={() => setShowCreateModuleModal(true)}
        buttonLabel={t('designer.NewModule')}
        filterPlaceholder={t('designer.FilterModules')}
        authorityContext={ContextRole.APP}
        allowedAuthorities={[RoleAuthorities.MANAGE_MODULE]}
      >
        {props.modulesList?.map((module) => (
          <Dropdown.Item
            key={module.id}
            eventKey={module.id}
            onClick={() => props.handleSelectModule(module)}
            className={`${styles.DropdownItem} ${
              props.selectedModule?.id === module.id && styles.DropdownItemSelected
            }`}
          >
            {/* This is used to filter pages,
                if you want filter to keep working this hidden <span>
                should stay as the first children of <Dropdown.Item> */}
            <span id={module.name} className={styles.HiddenFilterParameter}>
              {module.name}
            </span>
            {module.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>

      {app_id && (
        <CreateModuleModal
          modules={props.modulesList}
          onCloseRequest={modalOnCloseRequest}
          showModal={showCreateModuleModal}
          updateModulesList={props.handleUpdateModulesList}
        />
      )}
    </Dropdown>
  );
}
