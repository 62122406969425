import { columnID, EnumColumn, EnumColumnID, EnumUUID } from '../../../types';
import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../store';
import { makeEnumColumn } from 'routes/studio/data/elements/factory';
import { sleep } from 'utils/utils';
import styles from './styles.module.css';
import { Dropdown } from 'react-bootstrap';
import {
  addEnumColumn,
  changeDescription,
  changeLiteralValue,
  changeOrdinalValue,
  deleteEnumColumn,
  moveEnumColumn
} from 'modules/modeler/studio/store/actions/enum_column';
import { changeEnumName, deleteEnumFrame } from 'modules/modeler/studio/store/actions/enums';
import Confirmation from 'web_ui/confirmation';
import Icon from 'web_ui/icon';
import { SiGithubactions } from 'react-icons/si';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from '../../store/actions/studio';
import useSession from 'hooks/useSession';

export type TableEnumProps = {
  // selected enum id
  enumUUID: EnumUUID;
  // column list of the table
  keyList: EnumColumn[];
  overflow: { overflowX: number; overflowY: number };
  editorScale: number;
};

/**
 * Float box for quick editing of enums
 * ex: (create, delete column, etc...)
 *
 * @component
 */
function EnumEditor(props: TableEnumProps) {
  // context enums array
  const enums = useSelector((state: DatabaseStudioState) => state.enums);
  // Ordered list of enum columns.
  const enumIdsList: string[] = useSelector(
    (state: DatabaseStudioState) => state.enums[props.enumUUID].content.data.columns
  );
  // context columns array
  const enumColumns = useSelector((state: DatabaseStudioState) => state.enum_columns);
  // UUID of selected column used to change properties and graphically highlight them
  const [selectedColumn, setSelectedColumn] = useState<string>('');
  // used to show or hide the column options ex: (delete)
  const [showOptions, setShowOptions] = useState<columnID>('');
  // used to store the new enum literal value
  const [newLiteralValue, setNewLiteralValue] = useState('');
  // used to store the new enum ordinal input
  const [newOrdinalValue, setNewOrdinalValue] = useState(0);
  // used to store the new enum description input
  const [newDescription, setNewDescription] = useState('');
  // boolean value used to show or hide the input to change the enum name
  const [toggleEditName, setToggleEditName] = useState(true);
  // used to store and update the enum name
  const [enumName, setEnumName] = useState(
    enums[props.enumUUID] ? enums[props.enumUUID].content.data.name : ''
  );
  // Use this to check if the name remains the same, so it doesn't throw an error
  const [previousEnumName, setPreviousEnumName] = useState('');
  // Used to show or hide the delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Used to show or hide the delete confirmation modal
  const [showColumnDeleteModal, setShowColumnDeleteModal] = useState<boolean>(false);
  // new pk column input reference, used to create an automatic focus effect
  const newLiteralRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [session] = useSession();

  const [previousLiteralValue, setPreviousLiteralValue] = useState('');
  const [enumLiteralValues, setEnumLiteralValues] = useState<Record<string, string>>({});

  useEffect(() => {
    const enumNames: Record<string, string> = {};
    for (const enumInfo of Object.values(enumColumns)) {
      enumNames[enumInfo.id] = enumInfo.literalValue;
    }
    setEnumLiteralValues(enumNames);
  }, [enumColumns]);

  function setErrorMessageIdentifier(message: string) {
    if (!message) return;
    dispatch(setErrorMessage(message));
  }

  // Check if the table name is the default. If it is, then open the tableNameInput
  useEffect(() => {
    const checkDefaultName = /^ENUM_[0-9]$/;
    const enumName = props.enumUUID ? enums[props.enumUUID]?.content.data.name : '';
    const isDefaultName = checkDefaultName.test(enumName);
    isDefaultName && editEnumName();
  }, []);

  async function editEnumName() {
    setToggleEditName(false);
    setPreviousEnumName(enumName);
    await sleep(50);
    const input: HTMLInputElement | null = document.getElementById(
      'enumNameInput'
    ) as HTMLInputElement;
    input?.focus();
    input?.select();
  }

  // Used to start the component with focus on first input
  useEffect(() => {
    newLiteralRef.current?.focus();
  }, []);

  /***  create a new Column object with the specificied data, attach it on the enum
   */
  async function handleCreateNewColumn(e: FocusEvent<HTMLInputElement, Element>) {
    if (!newLiteralValue || newLiteralValue === '' || !props.enumUUID) return;
    if (!newLiteralValue || newLiteralValue.length < 2)
      return setErrorMessageIdentifier('modeler.NameTooShortMessage');
    if (checkExistingLiteralValue(newLiteralValue))
      return setErrorMessageIdentifier('modeler.ExistingNameMessage');

    let newColumnOrder = 0;
    if (enumIdsList.length) {
      newColumnOrder =
        enumColumns[enumIdsList[Object.values(enumIdsList).length - 1]].columnOrder + 1;
    }
    // create using specified name
    const enumColumn: EnumColumn = Object.assign(
      // that function return the enum object with the correct structure.
      makeEnumColumn(
        newLiteralValue.split(' ').join(''),
        newOrdinalValue ? newOrdinalValue : enumHigherOrdinalValue(),
        props.enumUUID,
        newDescription,
        newColumnOrder
      )
    );
    // add the enum column on enum column context array
    dispatch(addEnumColumn(enumColumn));
    // clean the input and set focus
    e.target.value = '';
    if (newLiteralRef.current) newLiteralRef.current.value = '';
    setNewLiteralValue('');
    setNewOrdinalValue(0);
    setNewDescription('');
    await sleep(100);
    newLiteralRef.current?.focus();
    enumHigherOrdinalValue();
  }

  // checks whether the given name falls within the enum's naming rules
  function HandleCheckEnumName(enumName: string, event: any): void {
    // the name must be at least 2 characters long
    if (!enumName || enumName.length < 2 || !props.enumUUID) {
      setErrorMessageIdentifier('modeler.NameTooShortMessage');
    } else if (enumName.length > 64) {
      setErrorMessageIdentifier('modeler.NameTooLongMessage');
    } else if (checkNameExists(enumName)) {
      setErrorMessageIdentifier('modeler.ExistingNameMessage');
      setEnumName(previousEnumName);
    }
    // prevent other click events and update the enum name on context
    else if (enumName && enumName.length > 1) {
      event.preventDefault();
      event.stopPropagation();
      setToggleEditName(true);
      dispatch(changeEnumName(props.enumUUID, enumName.split(' ').join('')));
    }
  }

  const checkNameExists = (name: string): boolean => {
    const findEnum = Object.keys(enums).find((enumId: EnumUUID) => {
      return enums[enumId].content.data.name.toLowerCase() === name.toLowerCase();
    });
    return !!findEnum;
  };

  // Show column options ex: (delete) and set the column as selected
  function handleColumnOptions(event: React.MouseEvent, columnID: string) {
    event.stopPropagation();
    event.preventDefault();
    setShowOptions(columnID);
    if (columnID !== '') setSelectedColumn(columnID);
  }

  // delete the column from the context
  async function handleDeleteEnumColumn() {
    // apply a visual effect on delete column, remove the column from column array on context
    const columnRef = document.getElementById(selectedColumn);
    if (columnRef) columnRef.style.opacity = '0';
    await sleep(220);
    dispatch(deleteEnumColumn(selectedColumn, props.enumUUID));
    setShowColumnDeleteModal(false);
  }

  // return the higher enum ordinal value or 0 if the enum hasn't columns
  function enumHigherOrdinalValue() {
    let higherNumber = 0;
    const columns: string[] = Object.keys(enumColumns).filter(
      (columnID) => enumColumns[columnID].idEnum === props.enumUUID
    );

    if (columns.length === 0) {
      higherNumber = 0;
    } else {
      higherNumber =
        Math.max(...columns.map((enumColumnID) => enumColumns[enumColumnID].ordinalValue)) + 1;
    }
    return higherNumber;
  }

  // function used to change the ordinal values from the enum column context
  function handleChangeOrdinalValue(
    event: React.ChangeEvent<HTMLInputElement>,
    columnID: EnumColumnID
  ) {
    if (!event.target.value) return;

    dispatch(changeOrdinalValue(columnID, Number(event.target.value)));
  }

  function handleChangeDescription(
    event: React.ChangeEvent<HTMLInputElement>,
    columnID: EnumColumnID
  ) {
    if (event.target.value.length > 255) {
      return setErrorMessageIdentifier('modeler.DescriptionTooLongMessage');
    }
    dispatch(changeDescription(columnID, event.target.value));
  }

  const checkExistingLiteralValue = (literalValue: string): boolean => {
    const enumId = props.enumUUID;
    for (const enumCol of Object.values(enumColumns)) {
      if (
        enumCol.idEnum === enumId &&
        enumCol.idEnum !== selectedColumn &&
        literalValue.toLowerCase() === enumCol.literalValue.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  };

  // function used to change the literal values from the enum column context
  function handleChangeLiteralValue(
    event: React.ChangeEvent<HTMLInputElement>,
    columnID: EnumColumnID
  ): void {
    if (!columnID) return;

    if (!event.target.value || event.target.value.length < 2 || !props.enumUUID) {
      setEnumLiteralValues({ ...enumLiteralValues, [columnID]: event.target.value });
      setErrorMessageIdentifier('modeler.LiteralValueTooShortMessage');
    } else if (event.target.value.length > 64) {
      setErrorMessageIdentifier('modeler.LiteralValueTooLongMessage');
    } else if (checkExistingLiteralValue(event.target.value)) {
      setEnumLiteralValues({ ...enumLiteralValues, [columnID]: event.target.value });
      setErrorMessageIdentifier('modeler.ExistingNameMessage');
    } else {
      setEnumLiteralValues({ ...enumLiteralValues, [columnID]: event.target.value });
      dispatch(changeLiteralValue(columnID, event.target.value));
    }
  }

  function handleEnumNameBlur(event: React.FocusEvent<HTMLInputElement>, columnId: string): void {
    if (!columnId) return;

    if (!event.target.value || event.target.value.length < 2 || !props.enumUUID) {
      setEnumLiteralValues({ ...enumLiteralValues, [columnId]: previousLiteralValue });
      dispatch(changeLiteralValue(columnId, previousLiteralValue));
      setErrorMessageIdentifier('modeler.NameTooShortMessage');
    } else if (event.target.value.length > 64) {
      setErrorMessageIdentifier('modeler.NameTooLongMessage');
    } else if (event.target.value.trim() === '') {
      setEnumLiteralValues({ ...enumLiteralValues, [columnId]: previousLiteralValue });
      dispatch(changeLiteralValue(columnId, previousLiteralValue));
    }
  }

  // used to delete an enum from enum column context
  function handleDeleteEnum() {
    if (props.enumUUID && enums[props.enumUUID]) dispatch(deleteEnumFrame(props.enumUUID));
    setShowDeleteModal(false);
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleCreateNewColumn(event);
    }
  };

  const handleMoveUp = (e: React.MouseEvent<HTMLLIElement>, columnId: string) => {
    setShowOptions('');

    e.stopPropagation();
    e.preventDefault();

    const columnIndex = enumIdsList.findIndex((c) => c === columnId);
    if (columnIndex === -1 || columnIndex === 0) return;

    dispatch(moveEnumColumn(props.enumUUID, columnId, enumIdsList[columnIndex - 1], 'UP'));
  };

  const handleMoveDown = (e: React.MouseEvent<HTMLLIElement>, columnId: string) => {
    setShowOptions('');

    e.stopPropagation();
    e.preventDefault();

    const columnIndex = enumIdsList.findIndex((c) => c === columnId);
    if (columnIndex === -1 || columnIndex === enumIdsList.length - 1) return;

    dispatch(moveEnumColumn(props.enumUUID, columnId, enumIdsList[columnIndex + 1], 'DOWN'));
  };

  return (
    <>
      <section
        className={`${styles.columnModal} `}
        style={{
          transform: `translate(${props.overflow.overflowX}px, ${props.overflow.overflowY}px) scale(${props.editorScale})`,
          backgroundColor: session.preferences['exocode-theme'] ? '' : '#FFFFFF'
        }}
      >
        <div className={`${styles.columnContainer}`} onClick={(e) => setShowOptions('')}>
          <div className={`${styles.tableHeader}`}>
            <div
              style={{
                color: '#6ea8fe',
                display: 'flex',
                whiteSpace: 'nowrap',
                width: '80%',
                overflow: 'hidden'
              }}
            >
              <div
                className={`${styles.editIcon}`}
                onClick={(e) => {
                  e.stopPropagation();
                  editEnumName().then();
                }}
                onDoubleClick={editEnumName}
              >
                <Icon iconName="pencil-square"></Icon>
              </div>
              {toggleEditName ? (
                <p
                  id="formName"
                  onDoubleClick={editEnumName}
                  style={{
                    position: 'relative',
                    paddingRight: '10px'
                  }}
                >
                  {props.enumUUID && enums[props.enumUUID].content.data.name}
                </p>
              ) : (
                <input
                  id="formName"
                  type="text"
                  value={enumName}
                  onChange={(event) => {
                    if (event.target.value.split(' ').join('').length > 64) {
                      setErrorMessageIdentifier('modeler.NameTooLongMessage');
                      return;
                    }
                    setEnumName(event.target.value.split(' ').join(''));
                  }}
                  onKeyDown={(event) => {
                    if (
                      event.key === 'Enter' ||
                      (event.key === 'Escape' &&
                        // Check this so it doesn't throw an error when the name remains the same
                        previousEnumName !== enumName)
                    ) {
                      HandleCheckEnumName(enumName, event);
                    } else if (event.key === 'Enter' || event.key === 'Escape') {
                      setToggleEditName(true);
                    }
                  }}
                  onBlur={(event) => {
                    // Check this so it doesn't throw an error when the name remains the same
                    if (previousEnumName !== enumName) {
                      HandleCheckEnumName(enumName, event);
                    } else {
                      setToggleEditName(true);
                    }
                  }}
                />
              )}
            </div>
            <p style={{ color: '#ea868f' }}>Enum</p>
          </div>
          <table className={`${styles.columnTable}`}>
            <thead className={`${styles.columnTableHeader}`}>
              <tr
                style={{
                  width: '100%',
                  display: 'flex',
                  color: session.preferences['exocode-theme'] ? '' : '#1D2A34'
                }}
              >
                <th
                  className={`${styles.columnName}`}
                  style={{ paddingLeft: '16px', display: 'flex', justifyContent: 'flex-start' }}
                >
                  {t('modeler.enumKey')}
                </th>
                <th
                  style={{
                    paddingLeft: '7px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '70px'
                  }}
                >
                  {t('modeler.Value')}
                </th>
                <th
                  className={`${styles.description}`}
                  style={{ paddingLeft: '7px', display: 'flex', justifyContent: 'flex-start' }}
                >
                  {t('modeler.description')}
                </th>
              </tr>
            </thead>
            <tbody>
              <div className={styles.scrollDiv}>
                {
                  // Used to display the enum columns
                  enumIdsList.map((columnId) => {
                    const column = enumColumns[columnId];
                    if (
                      enumColumns[column.id] &&
                      enumColumns[column.id].idEnum === props.enumUUID
                    ) {
                      return (
                        <tr
                          id="simpleColumnsList"
                          key={column.id}
                          className={`${styles.columnRow} ${
                            selectedColumn === column.id && styles.selectedColumnRow
                          }`}
                          onClick={() => setSelectedColumn(column.id)}
                        >
                          <td className={`${styles.columnName}`}>
                            <input
                              type="text"
                              value={enumLiteralValues[column.id]}
                              onFocus={() => setPreviousLiteralValue(enumLiteralValues[column.id])}
                              onBlur={(e) => handleEnumNameBlur(e, column.id)}
                              onChange={(e) => handleChangeLiteralValue(e, column.id)}
                              style={{
                                color: session.preferences['exocode-theme'] ? '' : '#1D2A34',
                                backgroundColor: session.preferences['exocode-theme']
                                  ? ''
                                  : '#FFFFFF'
                              }}
                            />
                          </td>
                          <td
                            style={{
                              paddingLeft: '7px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              width: '70px'
                            }}
                          >
                            <input
                              type="number"
                              value={enumColumns[column.id].ordinalValue}
                              style={{
                                color: session.preferences['exocode-theme'] ? '' : '#1D2A34',
                                backgroundColor: session.preferences['exocode-theme']
                                  ? ''
                                  : '#FFFFFF'
                              }}
                              onChange={(e) => handleChangeOrdinalValue(e, column.id)}
                            />
                          </td>
                          <td className={`${styles.description}`}>
                            <input
                              type="text"
                              value={enumColumns[column.id].description}
                              onChange={(e) => handleChangeDescription(e, column.id)}
                              maxLength={255}
                              style={{
                                color: session.preferences['exocode-theme'] ? '' : '#1D2A34',
                                backgroundColor: session.preferences['exocode-theme']
                                  ? ''
                                  : '#FFFFFF'
                              }}
                            />
                          </td>
                          <td
                            id="settingsButton"
                            className={`${styles.options}`}
                            onClick={(e) => {
                              handleColumnOptions(e, column.id);
                            }}
                            style={{
                              color: session.preferences['exocode-theme'] ? '' : '#1D2A34'
                            }}
                          >
                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            {showOptions === column.id && (
                              <div className={`${styles.optionsContainer}`}>
                                <ul className={`${styles.optionsGroup}`}>
                                  <li
                                    id="deleteButton"
                                    className={`${styles.optionsItem}`}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      event.preventDefault();
                                      setShowColumnDeleteModal(true);
                                    }}
                                  >
                                    <i
                                      style={{ marginRight: '8px' }}
                                      className="fa-solid fa-trash"
                                    ></i>
                                    {t('modeler.Remove')}
                                  </li>
                                  <li
                                    id="moveUp"
                                    className={`${styles.optionsItem}`}
                                    onClick={(e) => handleMoveUp(e, column.id)}
                                  >
                                    <i
                                      style={{ marginRight: '8px' }}
                                      className="fa-solid fa-chevron-up"
                                    ></i>
                                    {t('modeler.MoveUp')}
                                  </li>
                                  <li
                                    id="moveDown"
                                    className={`${styles.optionsItem}`}
                                    onClick={(e) => handleMoveDown(e, column.id)}
                                  >
                                    <i
                                      style={{ marginRight: '8px' }}
                                      className="fa-solid fa-chevron-down"
                                    ></i>
                                    {t('modeler.MoveDown')}
                                  </li>
                                </ul>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })
                }
                <tr
                  id={'newColumn'}
                  className={`${styles.columnRow} ${
                    selectedColumn === props.enumUUID && styles.selectedColumnRow
                  }`}
                  onClick={() => setSelectedColumn(props.enumUUID)}
                >
                  <td className={`${styles.columnName}`}>
                    <input
                      id="formLiteral"
                      ref={newLiteralRef}
                      type="text"
                      onChange={(event) => {
                        if (event.target.value.length > 64) {
                          return setErrorMessageIdentifier('modeler.LiteralValueTooLongMessage');
                        }
                        setNewLiteralValue(event.target.value);
                      }}
                      onFocus={(e) => e.persist()}
                      placeholder={t('modeler.literalValue') ?? ''}
                      onBlur={(e) => handleCreateNewColumn(e)}
                      onKeyDown={handleKeyDown}
                      style={{
                        color: session.preferences['exocode-theme'] ? '' : '#1D2A34',
                        backgroundColor: session.preferences['exocode-theme'] ? '' : '#FFFFFF'
                      }}
                    />
                  </td>
                  <td
                    style={{
                      paddingLeft: '7px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '70px'
                    }}
                  >
                    <input
                      id="newOrdinal"
                      type="number"
                      defaultValue={newOrdinalValue}
                      value={enumHigherOrdinalValue()}
                      onChange={(event) => setNewOrdinalValue(Number(event.target.value))}
                      placeholder={t('modeler.ordinalValue') ?? ''}
                      style={{
                        color: session.preferences['exocode-theme'] ? '' : '#1D2A34',
                        backgroundColor: session.preferences['exocode-theme'] ? '' : '#FFFFFF'
                      }}
                    />
                  </td>
                  <td className={`${styles.description}`}>
                    <input
                      id="newDescription"
                      type="text"
                      defaultValue={''}
                      onChange={(event) => {
                        if (event.target.value.length > 64) {
                          return setErrorMessageIdentifier('modeler.NameTooLongMessage');
                        }
                        setNewDescription(event.target.value);
                      }}
                      placeholder={t('modeler.description') ?? ''}
                      style={{
                        color: session.preferences['exocode-theme'] ? '' : '#1D2A34',
                        backgroundColor: session.preferences['exocode-theme'] ? '' : '#FFFFFF'
                      }}
                    />
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
          <div
            className="d-flex justify-content-between pt-3 border-top border-light
         border-opacity-25"
          >
            <div className="d-flex gap-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="actionsButton"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <SiGithubactions color="white" size={24} style={{ marginRight: 5 }} />
                  {t('Actions')}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    id="deleteButton"
                    href="#/action-2"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    <Icon iconName="trash me-2"></Icon>
                    {t('Delete')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex gap-2"></div>
          </div>
        </div>
        <Confirmation
          show={showDeleteModal}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirmation={handleDeleteEnum}
          message={`${t('modeler.Delete Enum')} ${enumName ? '(' + enumName + ')' : ''}`}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
        <Confirmation
          show={showColumnDeleteModal}
          onCancel={() => {
            setShowColumnDeleteModal(false);
          }}
          onConfirmation={handleDeleteEnumColumn}
          message={
            `${t('modeler.DeleteEnumColumn')}` +
            ' (' +
            (enumColumns[selectedColumn] ? enumColumns[selectedColumn].literalValue : '') +
            ')'
          }
          onClose={() => {
            setShowColumnDeleteModal(false);
          }}
        />
      </section>
    </>
  );
}

export default EnumEditor;
