import { Action } from 'redux';
import { VariablesState } from '../../types/variables';

export const UPDATE_VARIABLE = 'UPDATE_VARIABLE';

export interface UpdateVariableAction extends Action {
  type: 'UPDATE_VARIABLE';
  payload: {
    uuid: string;
    name: string;
    desc: string;
  };
}

export const updateVariable = (uuid: string, name: string, desc: string): UpdateVariableAction => ({
  type: 'UPDATE_VARIABLE',
  payload: { uuid, name, desc }
});

export function doUpdateVariable(
  state: VariablesState,
  action: UpdateVariableAction
): VariablesState {
  const { uuid, name, desc } = action.payload;
  if (!state[uuid]) return state;
  state[uuid] = { ...state[uuid], name, desc };
  return state;
}
