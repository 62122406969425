import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from 'modules/modeler/studio/store/actions/studio';
import { v4 as uuid } from 'uuid';

type ColumnNameInputProps = {
  columnName: string;
  createColumn: boolean;
  handleChangeName: (newName: string) => void;
};

export const ColumnNameInput = React.forwardRef<HTMLInputElement, ColumnNameInputProps>(
  ({ createColumn, handleChangeName, columnName }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleInputBlur = (newName: string) => {
      if (newName.length < 2 && newName.length > 0) {
        dispatch(setErrorMessage('modeler.NameTooShortMessage'));
        return;
      }

      if (createColumn) {
        return;
      }
      if (newName === columnName) {
        return;
      }

      handleChangeName(columnName);
    };

    const preventDrag = (e: React.DragEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <Form.Group className="col-sm-4">
        <Form.Control
          id={columnName}
          ref={ref}
          value={columnName}
          type="text"
          size="sm"
          placeholder={t('modeler.ColumnName') ?? ''}
          onChange={(e) => {
            if (e.target.value.length > 64) {
              dispatch(setErrorMessage('modeler.NameTooLongMessage'));
              return;
            }
            if (handleChangeName) handleChangeName(e.target.value);
          }}
          onBlur={(e) => handleInputBlur(e.target.value)}
          tabIndex={0}
          onDragStart={preventDrag}
          draggable
        />
      </Form.Group>
    );
  }
);
