import { ComponentMap, Relationship } from '../../../../../../types';
import { changeRelationshipType } from '../../../../../store/actions/relationship';
import { useDispatch } from 'react-redux';

export default function useChangeRelationToOneToOne(sourceRelationship: Relationship) {
  const dispatch = useDispatch();
  async function handleChangeToOneToOne() {
    if (sourceRelationship.type === 'ONE2MANY') {
      // Invert relationship components
      const componentsMap: ComponentMap = {};
      Object.entries(sourceRelationship.components).forEach((entry) => {
        const key = entry[0];
        const value = entry[1];
        componentsMap[value] = key;
      });

      // Invert relationship from/to
      dispatch(
        changeRelationshipType(
          sourceRelationship.id,
          sourceRelationship.to,
          sourceRelationship.from,
          componentsMap
        )
      );
    } else if (sourceRelationship.type === 'MANY2ONE') {
      dispatch(
        changeRelationshipType(
          sourceRelationship.id,
          sourceRelationship.from,
          sourceRelationship.to,
          sourceRelationship.components
        )
      );
    }
  }

  return handleChangeToOneToOne;
}
