import React, { ReactNode } from 'react';
import styles from './styles.module.css';

type EditorPanelRootProps = {
  children: ReactNode;
};

function EditorPanelRoot({ children }: EditorPanelRootProps) {
  return <div className={styles.EditorPanelRoot}>{children}</div>;
}

export default EditorPanelRoot;
