import { PageParamsState } from '../index';
import produce from 'immer';
import {
  AddParamsAction,
  ADD_PARAMS,
  DeleteParamsAction,
  DELETE_PARAMS,
  SET_PARAMS,
  SetParamsAction
} from '../actions/params';

type ParamsActions = AddParamsAction | DeleteParamsAction | SetParamsAction;

export const ParamsReducer = (
  state: PageParamsState = {},
  action: ParamsActions
): PageParamsState => {
  return produce(state, (draft: PageParamsState) => {
    switch (action.type) {
      case ADD_PARAMS:
        return doAddParams(draft, action);
      case DELETE_PARAMS:
        return doDeleteParams(draft, action);
      case SET_PARAMS:
        return doSetParams(draft, action);
      default:
        return draft;
    }
  });
};

function doAddParams(state: PageParamsState, action: AddParamsAction): PageParamsState {
  // uuid: params
  state[action.payload.params.uuid] = action.payload.params;
  return state;
}

function doDeleteParams(state: PageParamsState, action: DeleteParamsAction): PageParamsState {
  delete state[action.payload.params];
  return state;
}

function doSetParams(state: PageParamsState, action: SetParamsAction): PageParamsState {
  return action.payload.params;
}
