import { FunctionsState } from '../../types/functions';
import { AddBlockAction } from '../actions/add_block';

export function doAddBlock(state: FunctionsState, action: AddBlockAction): FunctionsState {
  const { functionUuid, openBlockAction, emptyAction, closeBlockAction } = action.payload;
  state[functionUuid].actions.splice(
    openBlockAction?.order ?? 0,
    0,
    openBlockAction.uuid,
    emptyAction.uuid,
    closeBlockAction.uuid
  );
  return state;
}
