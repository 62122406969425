import React, { ChangeEvent, useEffect } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultLabel, DefaultInput } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function VisibleVariableControl({ value, onChange, id, label, tooltip }: ControlProps) {
  const { t } = useTranslation();
  const [val, setVal] = React.useState<string>('');

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setVal(e.target.value);
      if (onChange) {
        onChange(e.target.value, id);
      }
    },
    [id, onChange]
  );

  return (
    <div
      className="mb-3"
      style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
    >
      <div style={{ display: 'flex', marginBottom: '.5rem' }}>
        <DefaultLabel title={label}>{label}</DefaultLabel>
        {tooltip && (
          <HelpPopover
            helpBoxProps={{
              description: t(`${tooltip}`) ?? ''
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>
      <DefaultInput
        title={val ?? `\${myVar} == true`}
        id={`form${label}`}
        type="text"
        className={`${styles.defaultInput} form-control form-control-sm`}
        placeholder="myVar == true"
        value={val ?? ''}
        onChange={handleChange}
      />
    </div>
  );
}

export default VisibleVariableControl;
