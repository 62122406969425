import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SessionContext from 'modules/auth/store';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function UpgradePlanModal(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const session = React.useContext(SessionContext);
  const [isUltimatePlan, setIsUltimatePlan] = React.useState<boolean>(false);

  const upgradePlan = () => {
    navigate('/settings/billing');
  };

  const close = () => {
    props.setShow(false);
  };

  useEffect(() => {
    if (session.user) {
      const orgPlan = session.user.systemRoles.find((item) => item.name === 'organization_plan');
      if (orgPlan) setIsUltimatePlan(true);
    }
  }, []);

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)} backdrop={'static'} centered>
      <Modal.Header closeButton>
        {isUltimatePlan ? t('upgradePlanModal.titleMax') : t('upgradePlanModal.title')}
      </Modal.Header>
      <Modal.Body>
        <div className={styles.containerButtons}>
          {isUltimatePlan ? (
            <Button
              className={`${styles.buttons} bg-success`}
              onClick={() =>
                (window.location = 'mailto:comingsoon@exocoding.com' as unknown as Location)
              }
            >
              {t('upgradePlanModal.contactSales')}
            </Button>
          ) : (
            <Button className={`${styles.buttons} bg-success`} onClick={() => upgradePlan()}>
              {t('upgradePlanModal.upgradePlan')}
            </Button>
          )}
          <Button className={`${styles.buttons} bg-danger`} onClick={() => close()}>
            {t('upgradePlanModal.close')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
