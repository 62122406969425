import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../../../store';
import { TableUUID } from '../../../../../../types';

/**
 * Load and filter only relationships that have the selected table as input or output
 * @param tableID
 */
export default function useFetchTableRelationships(tableID: TableUUID) {
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const relationships = useSelector((state: DatabaseStudioState) => state.relationships);
  const [tableRelationships, setTableRelationships] = useState<string[]>([]);

  useEffect(() => {
    const currentRelationships = Object.keys(relationships).filter((relationshipID) => {
      return (
        relationships[relationshipID].from === tableID ||
        relationships[relationshipID].to === tableID
      );
    });
    if (currentRelationships) setTableRelationships(currentRelationships);
  }, [relationships, tableID, tables]);

  return { tableRelationships, setTableRelationships };
}
