import { AppTranslatesRepository } from '../repos';
import { AppTranslation } from '../types';
export class AppTranslatesServ {
  async getTranslation(id: string) {
    return await AppTranslatesRepository.getTranslation(id);
  }

  async updateTranslation(id: string, value: string) {
    return await AppTranslatesRepository.updateTranslation(id, value);
  }

  async deleteTranslation(id: string, appTranslates: AppTranslation) {
    return await AppTranslatesRepository.deleteTranslation(id, appTranslates);
  }
}
