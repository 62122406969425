import React from 'react';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import styles from './styles.module.css';
import { ModuleInfo, ModuleSqlOptions } from 'modules/dashboard/types/global_types';
import { useTranslation } from 'react-i18next';
import HelpIcon from '../../../../../../web_ui/workboard/sidebar/controls/components/HelpIcon';
import HelpPopover from '../../../../../../web_ui/workboard/sidebar/controls/components/Popover';

type Props = {
  module: ModuleInfo;
  onChangeModuleName: (e: string) => void;
  onChangeModuleDescription: (e: string) => void;
  onChangeModuleStatus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeModuleSql: (value: ModuleSqlOptions) => void;
  errorMessages: Record<string, string>;
};

function InformationModule(props: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.appSettingsDialog}>
      <Form.Group className="mb-3">
        <Form.Label>{t('Name')}</Form.Label>
        <Form.Control
          type="text"
          id="formName"
          onChange={(e) => {
            props.onChangeModuleName(e.target.value);
          }}
          value={props.module.name}
          isInvalid={!!props.errorMessages.moduleName}
          disabled={props.module.isAssets || props.module.isAuth}
        />
        <Form.Control.Feedback type={'invalid'} id="nameInvalidText">
          {t(props.errorMessages.moduleName)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control
          type="text"
          id="formDescription"
          onChange={(e) => {
            props.onChangeModuleDescription(e.target.value);
          }}
          value={props.module.description}
          isInvalid={!!props.errorMessages.moduleDescription}
        />
        <Form.Control.Feedback type={'invalid'} id="descriptionInvalidText">
          {t(props.errorMessages.moduleDescription)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t('State')}</Form.Label>
        <Form.Check
          type="switch"
          id="stateCheck"
          label={t('Active')}
          onChange={props.onChangeModuleStatus}
          checked={props.module.isActive}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          {t('appResume.RepositoryType')}
          <HelpPopover
            helpBoxProps={{
              title: `${t('appResume.RepositoryType')}` || 'appResume.RepositoryType',
              description: t('appResume.JdbcAppDescription') || 'appResume.JdbcAppDescription'
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </Form.Label>
        <Form.Check>
          <ToggleButtonGroup
            type="radio"
            name="jdbc"
            className="mb-2"
            onChange={props.onChangeModuleSql}
            value={
              props.module.isSql === undefined
                ? ModuleSqlOptions.APP
                : props.module.isSql
                ? ModuleSqlOptions.JDBC
                : ModuleSqlOptions.JPA
            }
          >
            <ToggleButton id="jpaButton" value={ModuleSqlOptions.JPA}>
              {t('appResume.Jpa')}
            </ToggleButton>
            <ToggleButton id="appButton" value={ModuleSqlOptions.APP}>
              {t('appResume.Application')}
            </ToggleButton>
            <ToggleButton id="jdbcButton" value={ModuleSqlOptions.JDBC}>
              {t('appResume.Jdbc')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Form.Check>
      </Form.Group>
    </div>
  );
}

export default InformationModule;
