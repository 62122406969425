import styles from './styles.module.css';
import { Button, Form } from 'react-bootstrap';
import { Column, columnID } from 'modules/modeler/types';
import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  changeColumnDefault,
  changeColumnDescription,
  changeColumnName,
  changeColumnNullable,
  changeColumnProperty,
  COLUMN_PROPERTY_BASED_ON_FIELD_TYPE,
  deleteColumn,
  PROPERTY_LIMITS
} from '../../../../../../store/actions/columns';
import { useDispatch, useSelector } from 'react-redux';
import { sleep } from 'utils/utils';
import Confirmation from 'web_ui/confirmation';
import { DatabaseStudioState } from '../../../../../../store';
import { ColumnTypeSelector } from '../../../../../table_editor/components/common/column_type_selector';
import useFetchDataTypes from '../../../../../../hooks/useFetchDataTypes';
import { setErrorMessage } from '../../../../../../store/actions/studio';
import HelpPopover from '../../../../../../../../../web_ui/workboard/sidebar/controls/components/Popover';
import HelpIcon from '../../../../../../../../../web_ui/workboard/sidebar/controls/components/HelpIcon';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

export function getDefaultColumnPropertiesBasedOnColumnType(columnType: string) {
  const propertiesKeys = getColumnPropertiesKeys(columnType);
  const properties: Record<string, string> = {};
  propertiesKeys && propertiesKeys.map((key) => (properties[key] = ''));
  return properties;
}

function getColumnPropertiesKeys(fieldType: string) {
  return COLUMN_PROPERTY_BASED_ON_FIELD_TYPE[fieldType];
}

type ColumnEditorProps = {
  selectedColumnID: columnID;
};

export default function ColumnEditor({ selectedColumnID }: ColumnEditorProps) {
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const [columnProperties, setColumnProperties] = useState<Record<string, string>>({});
  const [showDeleteColumnModal, setShowDeleteColumnModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const fieldTypes = useFetchDataTypes();

  const [previousName, setPreviousName] = useState('');
  const [columnName, setColumnName] = useState<string>();

  useEffect(() => {
    setColumnName(columns[selectedColumnID]?.name || '');
  }, [columns, selectedColumnID]);

  useEffect(() => setupInitialLocalStateColumnProperties(), [columns[selectedColumnID]?.uuid]);

  function setupInitialLocalStateColumnProperties() {
    columnContextHasProperties()
      ? setColumnProperties({ ...columns[selectedColumnID]?.properties })
      : setDefaultColumnProperties(columns[selectedColumnID]?.type);
  }

  function columnContextHasProperties() {
    return !!columns[selectedColumnID]?.properties;
  }

  function setDefaultColumnProperties(dataType: string) {
    const defaultProperties = getDefaultColumnPropertiesBasedOnColumnType(dataType);
    defaultProperties && setColumnProperties({ ...defaultProperties });
  }

  useEffect(() => {
    updatePropertiesContextWithLocalState().then(() => {});
  }, [columnProperties]);

  async function updatePropertiesContextWithLocalState() {
    Object.keys(columnProperties).forEach((key) => {
      if (
        !columnContextHasProperties() ||
        columnProperties[key] !== columns[selectedColumnID]?.properties[key]
      )
        columnProperties[key] !== '' &&
          dispatchChangeColumnPropertyAction(key, columnProperties[key]);
    });
  }

  function handleChangeProperty(e: ChangeEvent<HTMLInputElement>, key: string) {
    if (!columns[selectedColumnID].uuid && e.target.value !== '') return;
    if (!e.target.validity.valid) {
      return;
    }
    setColumnProperties({ ...columnProperties, [key]: e.target.value.toString() });
  }

  function handleChangeBooleanProperty(e: ChangeEvent<HTMLInputElement>, key: string) {
    if (!columns[selectedColumnID].uuid) return;
    setColumnProperties({ ...columnProperties, [key]: e.target.checked ? 'true' : 'false' });
  }

  function dispatchChangeColumnPropertyAction(key: string, value: string) {
    const payload = { columnId: columns[selectedColumnID].uuid, key, value };
    dispatch(changeColumnProperty(payload));
  }

  function handleChangeName(e: ChangeEvent<HTMLInputElement>): void {
    if (!columns[selectedColumnID].uuid) return;

    setColumnName(e.target.value);
  }

  function handleChangeNullable(e: ChangeEvent<HTMLInputElement>) {
    if (!columns[selectedColumnID].uuid) return;
    dispatch(changeColumnNullable(columns[selectedColumnID].uuid, e.target.checked));
  }

  function handleChangeDefault(e: ChangeEvent<HTMLInputElement>) {
    if (!columns[selectedColumnID].uuid && e.target.value !== '') return;
    dispatch(changeColumnDefault(columns[selectedColumnID].uuid, e.target.value));
  }

  function handleChangeDescription(e: ChangeEvent<HTMLInputElement>) {
    if (!columns[selectedColumnID].uuid && e.target.value !== '') return;
    if (e.target.value.length > 255) {
      return dispatch(setErrorMessage('modeler.DescriptionTooLongMessage'));
    }
    dispatch(changeColumnDescription(columns[selectedColumnID].uuid, e.target.value));
  }

  const handleDeleteColumn = async () => {
    if (isLastPk()) {
      dispatch(setErrorMessage('modeler.DeleteLastPk'));
      setShowDeleteColumnModal(false);
      return;
    }

    dispatch(deleteColumn(columns[selectedColumnID].uuid));
    await sleep(100);
    // Object.keys(columns) ? selectColumn(Object.keys(columns)[0]) : selectColumn('NEWCOLUMN');
    setShowDeleteColumnModal(false);
  };

  function isLastPk() {
    const filteringByPk = Object.values(columns).filter((column) => {
      return column.isPK && column.tableUUID === columns[selectedColumnID].tableUUID;
    });
    return columns[selectedColumnID].isPK && filteringByPk.length === 1;
  }

  function handleNameInputBlur(e: FocusEvent<HTMLInputElement>): void {
    if (!columns[selectedColumnID].uuid) return;

    const newColumnName = e.target.value.trim();
    if (!newColumnName || newColumnName.length < 2) {
      dispatch(setErrorMessage('modeler.NameTooShortMessage'));
    } else if (!isNameValid(newColumnName)) {
      dispatch(setErrorMessage('modeler.IllegalNameCharacters'));
    } else if (newColumnName.length > 64) {
      dispatch(setErrorMessage('modeler.NameTooLongMessage'));
    } else if (checkExistingName(newColumnName, columns[selectedColumnID])) {
      dispatch(setErrorMessage('modeler.ExistingNameMessage'));
    } else {
      dispatch(changeColumnName(columns[selectedColumnID].uuid, newColumnName));
    }
  }

  const isNameValid = (name: string) => {
    const regex = /^[a-zA-Z_][0-9a-zA-Z_]*$/;
    return regex.test(name);
  };

  const checkExistingName = (name: string, column: Column) => {
    const tableID = column.tableUUID;
    for (const col of Object.values(columns)) {
      if (
        col.tableUUID === tableID &&
        name.toLowerCase() === col.name.toLowerCase() &&
        col.uuid !== selectedColumnID
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <div id={'columnEditorWrapper'} className={styles.ColumnEditorWrapper}>
        <Form.Group className="mb-2" controlId="table-column-name">
          <Form.Label className={styles.SmallFont}>{t('Name') ?? ''}</Form.Label>
          <Form.Control
            value={columnName}
            type="text"
            size="sm"
            onChange={handleChangeName}
            onFocus={() => setPreviousName(columnName || '')}
            onBlur={handleNameInputBlur}
          />
        </Form.Group>
        <Form.Group
          className="mb-2"
          controlId="columnType"
          id={WALKTHROUGH_STEPS_ELEMENTS['column-datatype']}
        >
          <Form.Label className={styles.SmallFont}>{t('logicBuilder.objectsTab.Type')}</Form.Label>
          <ColumnTypeSelector
            column={columns[selectedColumnID]}
            fieldTypes={fieldTypes}
            advanced={true}
          />
        </Form.Group>
        {COLUMN_PROPERTY_BASED_ON_FIELD_TYPE[columns[selectedColumnID]?.type] &&
          COLUMN_PROPERTY_BASED_ON_FIELD_TYPE[columns[selectedColumnID].type].map((key) => {
            return (
              <Form.Group key={key} className="mb-2" controlId="columnDefaultValue">
                <Form.Label className={styles.SmallFont}>
                  {t(`modeler.entity_editor.fields.${key}`) ?? ''}
                </Form.Label>
                <Form.Control
                  type={'number'}
                  placeholder={t(`modeler.entity_editor.fields.${key}`) ?? ''}
                  value={columnProperties[key]}
                  min={PROPERTY_LIMITS[key].min}
                  max={PROPERTY_LIMITS[key].max}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeProperty(e, key)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Group>
            );
          })}

        <Form.Group className="mb-2" controlId="columnDescription">
          <Form.Label className={styles.SmallFont}>{t('modeler.description') ?? ''}</Form.Label>
          <Form.Control
            value={columns[selectedColumnID]?.description ?? ''}
            as="textarea"
            maxLength={255}
            size="sm"
            onChange={(e) => handleChangeDescription(e as ChangeEvent<HTMLInputElement>)}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="columnDefaultValue">
          <Form.Label className={styles.SmallFont}>
            {t('modeler.entity_editor.fields.Default data') ?? ''}
          </Form.Label>
          <Form.Control
            value={columns[selectedColumnID]?.defaultData ?? ''}
            type="text"
            size="sm"
            onChange={(e) => handleChangeDefault(e as ChangeEvent<HTMLInputElement>)}
          />
        </Form.Group>

        <Form.Check
          id={'checkboxSmallFont'}
          label={t('modeler.entity_editor.fields.Nullable')}
          checked={columns[selectedColumnID]?.nullable ?? false}
          className={`${styles.SmallFont} mb-2`}
          onChange={(e) => handleChangeNullable(e as ChangeEvent<HTMLInputElement>)}
        />

        {/* Natural Key / Business Key */}
        <Form.Check id={'checkboxNaturalKey'} className={`${styles.SmallFont} mb-2`}>
          <Form.Check.Input
            checked={columnProperties['natural_key'] === 'true'}
            onChange={(e) =>
              handleChangeBooleanProperty(e as ChangeEvent<HTMLInputElement>, 'natural_key')
            }
          />
          <Form.Check.Label>
            {t('modeler.entity_editor.fields.NaturalKey')}
            <HelpPopover
              placement={'right'}
              helpBoxProps={{
                title:
                  t('modeler.entity_editor.fields.NaturalKey') ??
                  'modeler.entity_editor.fields.NaturalKey',
                description:
                  t('modeler.entity_editor.fields.NaturalKeyHelp') ??
                  'modeler.entity_editor.fields.NaturalKeyHelp'
              }}
            >
              <HelpIcon />
            </HelpPopover>
          </Form.Check.Label>
        </Form.Check>

        <Button
          id={'deleteButton'}
          onClick={() => setShowDeleteColumnModal(true)}
          variant="outline-danger"
        >
          {t('modeler.Remove')}
        </Button>
      </div>
      <Confirmation
        show={showDeleteColumnModal}
        onCancel={() => setShowDeleteColumnModal(false)}
        onConfirmation={handleDeleteColumn}
        message={
          t('modeler.Delete Column') +
          ' (' +
          (columns[selectedColumnID] ? columns[selectedColumnID].name : '') +
          ')'
        }
        onClose={() => setShowDeleteColumnModal(false)}
      />
    </>
  );
}
