import React, { useContext, useState } from 'react';
import styles from 'modules/logic_builder/styles.module.css';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SearchBar from 'web_ui/search_bar';
import Icon from 'web_ui/icon';
import {
  ScheduledDialog,
  ScheduledDialog as ScheduledEditorDialog
} from 'modules/logic_builder/components/dialogs/scheduled_dialog';
import LogicBuilderContext from 'modules/logic_builder/store';
import { ScheduledsList } from 'modules/logic_builder/components/scheduleds_list';
import CodeEditorModal from '../../../../../web_ui/code_editor_modal';
import { CodePreviewType } from '../../../../../web_ui/code_editor_modal/editor';

export function SchedulerEditor() {
  const [showCreateSchedulerModal, setShowCreateSchedulerModal] = useState(false);
  const [showUpdateSchedulerJobModal, setShowUpdateSchedulerJobModal] = useState<string>();
  const { schedulerJobs, createSchedulerJob, changeSchedulerJob, deleteSchedulerJob } =
    useContext(LogicBuilderContext);
  const [filter, setFilter] = useState('');
  const { t } = useTranslation();
  const searchTxt = t('Search');
  const [previewSchedulerUuid, setPreviewSchedulerUuid] = useState<string>('');

  return (
    <div className={styles.LogicEditorWrapper}>
      <div className={styles.LogicEditorListWrapper}>
        <div className={styles.LogicBuilderControl}>
          <Form id={'searchField'} className={styles.LogicBuilderControlSearch}>
            <SearchBar
              id={'searchField'}
              placeholder={searchTxt}
              text={filter}
              setText={setFilter}
              focus={true}
            />
          </Form>
          <div className={styles.LogicBuilderControlButtons}>
            <Button
              id={'createButton'}
              className={styles.ControlButton}
              variant="primary"
              onClick={() => setShowCreateSchedulerModal(true)}
            >
              <Icon iconName="plus" extraProps="pe-1"></Icon>
              {t('organizations.new_organization.Create')}
            </Button>
          </div>
        </div>
        <ScheduledsList
          key={'schLst'}
          search={filter.toLowerCase().trim()}
          scheduleds={schedulerJobs}
          onCreate={() => setShowCreateSchedulerModal(true)}
          onEdit={(uuid: string) => setShowUpdateSchedulerJobModal(uuid)}
          onPreviewScheduler={(uuid: string) => setPreviewSchedulerUuid(uuid)}
        />
      </div>
      <ScheduledDialog
        show={showCreateSchedulerModal}
        onClose={() => setShowCreateSchedulerModal(false)}
        editMode={false}
        dialogTitle={'CreateScheduled'}
        onSuccess={createSchedulerJob}
      />
      {showUpdateSchedulerJobModal && (
        <ScheduledEditorDialog
          show={showUpdateSchedulerJobModal !== null}
          onClose={() => setShowUpdateSchedulerJobModal(undefined)}
          editMode={true}
          dialogTitle={'EditScheduled'}
          schedulerJob={schedulerJobs.find((s) => s.uuid === showUpdateSchedulerJobModal)}
          onSuccess={changeSchedulerJob}
          onDelete={deleteSchedulerJob}
        />
      )}
      <CodeEditorModal
        show={previewSchedulerUuid !== ''}
        handleClose={() => setPreviewSchedulerUuid('')}
        id={previewSchedulerUuid}
        previewType={CodePreviewType.SCHEDULER_JOB}
      />
    </div>
  );
}
