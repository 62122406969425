import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ViewManifest } from '../../../types';
import { validateDescriptionInputsForDesigner } from 'utils/inputValidation';

export const PAGE_MANIFEST: ViewManifest = {
  type: 'PAGE',
  name: 'Page',
  description:
    'Page views are generally used to create user pages for the application, with routes, API connections and user interface.',
  icon: 'fa-desktop',
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Title',
      key: 'title',
      maxLength: 64
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Description',
      key: 'description',
      validation: (input: string) => validateDescriptionInputsForDesigner(input),
      errorMessages: {
        EXCEEDS_MAX_LENGTH: 'inputValidationErrorMessages.ViewDescriptionExceedsMaxLength'
      }
    },
    {
      controlType: ControlsTypes.SELECT_PICS,
      controlLabel: 'Background Image',
      key: 'url'
    }
  ],
  styles: []
};
