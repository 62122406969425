import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { updateAction } from '../../../store/events/actions/update_action';
import Dropdown from '../../components/dropdown';

function VariableInput({
  placeholder,
  label,
  actionUuid,
  dataKey,
  options,
  isFromSideBar
}: ActionInputProps) {
  const state = useSelector((state: FunctionEditorState) => state);
  const variables = state.variables;
  const value = state?.actions[actionUuid]?.data[dataKey];
  const dispatch = useDispatch();

  const handleChange = (newValue: any) => {
    dispatch(updateAction(actionUuid, dataKey, newValue));
  };

  const filteredVariables = () => {
    if (!options) return Object.values(variables);
    let filtered = Object.values(variables);
    if (options?.onlyGlobals) {
      filtered = filtered.filter((v) => state.globals?.variables?.includes(v.uuid));
    }
    if (options?.dataType)
      filtered = filtered?.filter((v) =>
        options.dataType ? v.type.toUpperCase() === options.dataType.toUpperCase() : false
      );
    if (typeof options?.filterByDataType === 'function')
      filtered = filtered?.filter(
        (v) => options?.filterByDataType && options.filterByDataType(state, actionUuid, v.type)
      );
    return filtered;
  };

  return (
    <Dropdown
      items={filteredVariables()}
      placeholder={placeholder}
      label={label}
      value={variables[value]}
      handleChange={handleChange}
      isFromSideBar={isFromSideBar}
    />
  );
}

export default VariableInput;
