import React, { memo, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { WidgetType } from '../types';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { DatabaseStudioState } from 'modules/modeler/studio/store';
import { useTranslation } from 'react-i18next';

export type StickyNoteWidgetProps = {
  uuid: string;
  type: keyof typeof WidgetType;
  shouldActivateWidget: boolean;
  setShouldActivateWidget: (value: boolean) => void;
  allowedToMove: (value: boolean) => void;
  sendChangeAction: (widgetData: any) => void;
};

const StickyNoteWidget = React.forwardRef<HTMLDivElement, StickyNoteWidgetProps>(
  (props: StickyNoteWidgetProps, ref) => {
    const [text, setText] = useState('');
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const { t } = useTranslation();
    // TODO: these values (initial posX and posY) should be in a class instead, we do not
    //       need to know about updated position or textarea dimensions.
    // TODO: the problem is that the class itself resets because widgetsRenderer rerenders too...
    const widget = useSelector(
      (state: InterfaceStudioState | DatabaseStudioState) => state.widgets[props.uuid]
    );

    const resizeRef = React.useRef<HTMLDivElement>(null);
    const textareaRef = React.createRef<HTMLTextAreaElement>();

    const [width, setWidth] = useState(widget.data.width || 200);
    const [height, setHeight] = useState(widget.data.height || 200);

    const handleResize = (newWidth: number, newHeight: number) => {
      setWidth(newWidth);
      setHeight(newHeight);

      const updatedWidgetData = {
        ...widget.data,
        width: newWidth,
        height: newHeight
      };
      props.sendChangeAction(updatedWidgetData);
    };

    useEffect(() => {
      setWidth(widget.data.width || 200);
      setHeight(widget.data.height || 200);
    }, [widget.data.width, widget.data.height]);

    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          handleResize(width, height);
        }
      });

      if (resizeRef.current) {
        resizeObserver.observe(resizeRef.current);
      }

      return () => {
        if (resizeRef.current) {
          resizeObserver.unobserve(resizeRef.current);
        }
      };
    }, [resizeRef]);

    useEffect(() => {
      setText(widget.data.text);
    }, [widget.data.text]);

    const handleInputOnChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newText = event.target.value;

        setText(newText);

        const widgetData = { ...widget.data, text: newText };
        props.sendChangeAction(widgetData);
      },
      [props, widget.data]
    );

    const handleInputOnBLur = React.useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        props.setShouldActivateWidget(false);
        props.allowedToMove(true);

        setIsFocused(false);
        setTimeout(() => {
          checkOverflow();
        }, 0);
      },
      [props]
    );

    const checkOverflow = React.useCallback(() => {
      if (textareaRef.current) {
        const isTextOverflowing =
          textareaRef.current.scrollHeight > textareaRef.current.clientHeight;
        setIsOverflowing(isTextOverflowing);
        setShowMoreButton(isTextOverflowing && isCollapsed && !isFocused);
      }
    }, [isCollapsed, isFocused]);

    useEffect(() => {
      checkOverflow();
    }, [text, isCollapsed, isFocused]);

    const handleInputOnFocus = React.useCallback(() => {
      setIsFocused(true);
      setShowMoreButton(false);
    }, []);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.scrollTop = 0;
      }

      if (textareaRef.current == null) return;

      if (props.shouldActivateWidget) {
        textareaRef.current.focus();
      }
    }, [props.shouldActivateWidget, textareaRef]);

    function formattedDate(isoDate: string) {
      const date = new Date(isoDate);

      const newDate = date.toLocaleDateString();
      const newTime =
        String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');

      return newDate + ' ' + newTime;
    }

    const stickyNote = React.useMemo(() => {
      return (
        <div
          id="note"
          className={`${
            props.shouldActivateWidget ? styles.ActivatedStickyNote : styles.StickyNote
          }`}
          style={{
            resize: props.shouldActivateWidget ? 'both' : 'none'
          }}
        >
          <div className={styles.StickyNoteProfileName}>
            <div>{widget.data.author}</div>
            <div className={styles.StickyNoteDate}>{formattedDate(widget.data.date)}</div>
          </div>
          <textarea
            id="teste"
            ref={textareaRef}
            name="note"
            className={`text-dark ${styles.StickyNoteInput}  `}
            value={text}
            onChange={handleInputOnChange}
            rows={14}
            onBlur={handleInputOnBLur}
            onFocus={handleInputOnFocus}
            style={{ overflowY: 'auto' }}
          />
        </div>
      );
    }, [
      handleInputOnBLur,
      handleInputOnChange,
      props.shouldActivateWidget,
      text,
      textareaRef,
      widget.data.author,
      widget.data.date,
      showMoreButton,
      handleInputOnBLur
    ]);

    return (
      <div
        ref={ref}
        className={styles.WidgetWrapper}
        style={{
          zIndex: 1,
          userSelect: 'none',
          position: 'absolute',
          left: widget.posX + 'px',
          top: widget.posY + 'px',
          height: widget.data.height + 'px',
          width: widget.data.width + 'px',
          resize: props.shouldActivateWidget ? 'both' : 'none',
          overflow: 'auto'
        }}
      >
        {stickyNote}
      </div>
    );
  }
);

export default memo(StickyNoteWidget);
export * from './template';
