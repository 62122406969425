import { Team, Teams, UserProfile } from 'modules/auth/types/auth_types';

export const API_URL = process.env.REACT_APP_API_URL;

export default class TeamRepository {
  async createTeam(data: Team) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const options: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const request = new Request(`${API_URL}/orgs/${data.orgId}/teams`, options);

    return fetch(request).then(async (response: Response) => {
      if (response.status === 201) {
        return response.json() as Promise<Teams>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async getAllTeams(orgId: number) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const options: RequestInit = {
      method: 'GET',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/orgs/${orgId.toString()}/teams`, options);

    return fetch(request).then(async (response: Response) => {
      if (response.status === 200) {
        return response.json() as Promise<Teams[]>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async putTeamName(orgId: number, teamId: number, newName: string) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const data = {
      name: newName
    };

    const options: RequestInit = {
      method: 'PUT',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const request = new Request(
      `${API_URL}/orgs/${orgId.toString()}/teams/${teamId.toString()}`,
      options
    );

    return fetch(request).then(async (response: Response) => {
      if (response.status === 200) {
        return response.json() as Promise<Teams>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async deleteTeam(orgId: number, teamId: number) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const options: RequestInit = {
      method: 'DELETE',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(
      `${API_URL}/orgs/${orgId.toString()}/teams/${teamId.toString()}`,
      options
    );

    return fetch(request).then(async (response: Response) => {
      if (response.status === 200) {
        return response.json() as Promise<Teams>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async insertMember(orgId: number, teamId: number, userId: number) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const data = {
      userId: userId
    };

    const options: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const request = new Request(
      `${API_URL}/orgs/${orgId.toString()}/teams/${teamId.toString()}/members`,
      options
    );

    return fetch(request).then(async (response: Response) => {
      if (response.status === 200) {
        return response.json() as Promise<{ userId: number }>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async getAllTeamMembers(orgId: number, teamId: number) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const options: RequestInit = {
      method: 'GET',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(
      `${API_URL}/orgs/${orgId.toString()}/teams/${teamId.toString()}/members`,
      options
    );

    return fetch(request).then(async (response: Response) => {
      if (response.status === 200) {
        return response.json() as Promise<UserProfile[]>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async deleteTeamMember(orgId: number, teamId: number, userId: number) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const options: RequestInit = {
      method: 'DELETE',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(
      `${API_URL}/orgs/${orgId.toString()}/teams/${teamId.toString()}/members/${userId.toString()}`,
      options
    );

    return fetch(request).then(async (response: Response) => {
      if (response.status === 200) {
        return response.json() as Promise<UserProfile>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }
}
