import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { EndpointSimple, Method } from 'modules/logic_builder/types';
import { METHODS } from 'modules/logic_builder/types';
import { EndpointsService } from 'modules/logic_builder/services';
import { validatePath } from '../controller_creator_dialog';
import LogicBuilderContext from 'modules/logic_builder/store';
import styles from './styles.module.css';
import { endpointNameRegex } from 'utils/regex';
import { useTranslation } from 'react-i18next';

type EndpointCreatorDialogProps = {
  controllerUuid?: string;
  show: boolean;
  onClose: () => void;
};

const emptyNewEndpoint: EndpointSimple = {
  controllerUuid: '',
  name: '',
  path: '',
  method: METHODS.GET,
  summary: ''
};

export function EndpointCreatorDialog(props: EndpointCreatorDialogProps) {
  const [endpoint, setEndpoint] = useState<EndpointSimple>(emptyNewEndpoint);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isPathInvalid, setIsPathInvalid] = useState(false);
  const [hasChangedPath, setHasChangedPath] = useState(false);
  const { module_id, fetchEndpoints } = useContext(LogicBuilderContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const { t } = useTranslation();

  function handleNameChange(name: string, method: Method) {
    let path = endpoint?.path;
    if (!hasChangedPath) {
      path = convertNameToPath(name);
    }

    setEndpoint({ ...endpoint, name: name, method: method, path: path });
    setIsPathInvalid(validatePath(path));
  }

  function convertNameToPath(name: string): string {
    return '/' + name.replaceAll(' ', '').toLowerCase();
  }

  // Set initial values.
  useEffect(() => {
    if (!props.show || !props.controllerUuid) return;

    setEndpoint({ ...emptyNewEndpoint, controllerUuid: props.controllerUuid });
    setIsPathInvalid(false);
    setHasChangedPath(false);
  }, [props.show, props.controllerUuid]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isLoading) return;
    if (!module_id || !endpoint) return;

    setIsLoading(true);
    await EndpointsService.createEndpoint(module_id, endpoint)
      .then(() => {
        fetchEndpoints(module_id);
        props.onClose();
      })
      .catch((err) => {
        setErrorMessage(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setErrorMessage(false);
        props.onClose();
      }}
      centered
      scrollable
    >
      <Form onSubmit={onSubmit} className={styles.formWrapper} id="formModal">
        <Modal.Header closeButton>
          <Modal.Title>{t('logicBuilder.createEndpointDialog.EndpointCreator')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
          {errorMessage && (
            <span className={styles.errorMsg}>
              {`*${t('logicBuilder.errorCreatingEditingEndPoint')}`}
            </span>
          )}
          {/* Form */}
          <Form.Group className="mb-3" controlId="formEndpointMethod">
            <Form.Label>{t('logicBuilder.createEndpointDialog.Method')}</Form.Label>
            <Form.Select
              required
              onChange={(e) => handleNameChange(endpoint.name, e.target.value as Method)}
              value={endpoint.method}
            >
              {/* Make this option disappear after user selects something. */}
              {/* {!endpoint.method && <option>Select method</option>} */}
              {Object.keys(METHODS).map((method) => {
                return (
                  <option key={method} value={method}>
                    {method}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{t('logicBuilder.createEndpointDialog.Name')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('logicBuilder.createEndpointDialog.EndpointName')}`}
              required
              value={endpoint.name}
              isInvalid={isNameInvalid}
              maxLength={64}
              onChange={(e) => {
                setIsNameInvalid(!endpointNameRegex.test(e.target.value));
                handleNameChange(e.target.value ?? '', endpoint.method);
              }}
            />
            <Form.Control.Feedback type={'invalid'}>
              {endpoint.name.length === 0 && t('RequiredField')}
              {endpoint.name.length !== 0 && t('InvalidField')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPath">
            <Form.Label>{t('logicBuilder.createEndpointDialog.Path')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`/${t('logicBuilder.createEndpointDialog.Path')}`}
              value={endpoint.path}
              isInvalid={isPathInvalid}
              maxLength={255}
              onChange={(e) => {
                setEndpoint({ ...endpoint, path: e.target.value });
                setIsPathInvalid(validatePath(e.target.value));
                setHasChangedPath(e.target.value.length > 0);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {t('logicBuilder.createEndpointDialog.InvalidPath')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>{t('logicBuilder.Summary')}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={`${t('logicBuilder.Summary')}`}
              rows={2}
              maxLength={255}
              value={endpoint.summary}
              onChange={(e) => setEndpoint({ ...endpoint, summary: e.target.value })}
            />
          </Form.Group>
        </Modal.Body>
        {/* Footer buttons */}
        <Modal.Footer>
          <Button id={'cancelButton'} variant="secondary" onClick={() => props.onClose()}>
            {t('logicBuilder.createEndpointDialog.cancel')}
          </Button>
          <Button
            id={'saveButton'}
            variant="primary"
            type="submit"
            disabled={
              isNameInvalid || isPathInvalid || !endpoint.method || !endpoint.name || !endpoint.path
            }
          >
            {t('logicBuilder.createEndpointDialog.Create')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
