import { Service } from '../types';
import { ServiceResponse, ServiceSchema } from './schemas/Services';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class ServicesRepo {
  /**
   * Returns the complete description of a service.
   */
  async getService(serviceId: string): Promise<ServiceResponse> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/services/${serviceId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ServiceResponse>;

      throw new Error('Something went wrong while trying to fetch a Service.');
    });
  }

  /**
   * Get services (from a specific module).
   */
  async getServices(moduleId: string, name?: string): Promise<ServiceResponse[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    let url = `${API_URL}/modules/${moduleId}/services`;
    if (name) url = url + `?name=${name}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ServiceResponse[]>;

      throw new Error('Something went wrong while trying to fetch Services.');
    });
  }

  /**
   * Create a new service (attached to a module).
   */
  async createService(moduleId: string, service: Service): Promise<ServiceResponse> {
    const serviceSchema = service as ServiceSchema;

    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(serviceSchema)
    };

    const url = `${API_URL}/modules/${moduleId}/services`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ServiceResponse>;

      throw new Error('Something went wrong while trying to create a Service.');
    });
  }

  /**
   * Delete service.
   */
  async deleteService(serviceId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/services/${serviceId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete Service.');
    });
  }

  /**
   * Update service.
   */
  async updateService(serviceId: string, service: Service): Promise<ServiceResponse> {
    const serviceSchema = service as ServiceSchema;

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(serviceSchema)
    };

    const url = `${API_URL}/services/${serviceId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ServiceResponse>;

      throw new Error('Something went wrong while trying to update Service.');
    });
  }

  /**
   * Update folder of a Service
   */
  async changeFolder(serviceId: string, folderId: string) {
    const moutingStructure = {
      serviceId: serviceId,
      folder_id: folderId
    };

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(moutingStructure)
    };

    const url = `${API_URL}/services/${serviceId}/changefolder`;
    const response = await fetch(url, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Something went wrong while trying to delete object.');
    }
  }
}
