import { BACKEND_ACTIONS_TYPES } from './types/actions';
import { DECLARE_VARIABLE_MANIFEST } from './actions/declare_variable_manifest';
import { SET_VARIABLE_MANIFEST } from './actions/set_variable_manifest';
import { BEGIN_CYCLE_MANIFEST } from './actions/begin_cycle_manifest';
import { END_CYCLE_MANIFEST } from './actions/end_cycle_manifest';
import { ADD_LIST_MANIFEST } from './actions/add_list_manifest';
import { REMOVE_LIST_MANIFEST } from './actions/remove_list_manifest';
import { CLEAR_LIST_MANIFEST } from './actions/clear_list_manifest';
import { ActionsManifests } from '../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { RUN_FUNCTION_MANIFEST } from './actions/run_function_manifest';
import { CUSTOM_CODE_MANIFEST } from './actions/custom_code_manifest';
import { CUSTOM_QUERY_MANIFEST } from './actions/custom_query_manifest';
import { EMPTY_MANIFEST } from './actions/empty_manifest';
import { RETURN_TYPE_MANIFEST } from './actions/return_type_manifest';
import { CREATE_ENTITY_MANIFEST } from './actions/create_entity_manifest';
import { UPDATE_ENTITY_MANIFEST } from './actions/update_entity_manifest';
import { DELETE_ENTITY_MANIFEST } from './actions/delete_entity_manifest';
import { READ_ENTITY_MANIFEST } from './actions/read_entity_manifest';
import { BEGIN_IF_MANIFEST } from './actions/begin_if_manifest';
import { END_IF_MANIFEST } from './actions/end_if_manifest';
import { BEGIN_ELSE_MANIFEST } from './actions/begin_else_manifest';
import { END_ELSE_MANIFEST } from './actions/end_else_manifest';
import { COMMENT_MANIFEST } from './actions/comment_manifest';

export const BACKEND_ACTIONS_MANIFEST: ActionsManifests = {
  [BACKEND_ACTIONS_TYPES.CREATE_ENTITY]: CREATE_ENTITY_MANIFEST,
  [BACKEND_ACTIONS_TYPES.READ_ENTITY]: READ_ENTITY_MANIFEST,
  [BACKEND_ACTIONS_TYPES.UPDATE_ENTITY]: UPDATE_ENTITY_MANIFEST,
  [BACKEND_ACTIONS_TYPES.DELETE_ENTITY]: DELETE_ENTITY_MANIFEST,
  [BACKEND_ACTIONS_TYPES.CUSTOM_QUERY]: CUSTOM_QUERY_MANIFEST,
  [BACKEND_ACTIONS_TYPES.DECLARE_VARIABLE]: DECLARE_VARIABLE_MANIFEST,
  [BACKEND_ACTIONS_TYPES.SET_VARIABLE]: SET_VARIABLE_MANIFEST,
  [BACKEND_ACTIONS_TYPES.ADD_LIST]: ADD_LIST_MANIFEST,
  [BACKEND_ACTIONS_TYPES.REMOVE_LIST]: REMOVE_LIST_MANIFEST,
  [BACKEND_ACTIONS_TYPES.CLEAR_LIST]: CLEAR_LIST_MANIFEST,
  [BACKEND_ACTIONS_TYPES.BEGIN_CYCLE]: BEGIN_CYCLE_MANIFEST,
  [BACKEND_ACTIONS_TYPES.END_CYCLE]: END_CYCLE_MANIFEST,
  [BACKEND_ACTIONS_TYPES.BEGIN_IF]: BEGIN_IF_MANIFEST,
  [BACKEND_ACTIONS_TYPES.END_IF]: END_IF_MANIFEST,
  [BACKEND_ACTIONS_TYPES.BEGIN_ELSE]: BEGIN_ELSE_MANIFEST,
  [BACKEND_ACTIONS_TYPES.END_ELSE]: END_ELSE_MANIFEST,
  [BACKEND_ACTIONS_TYPES.RUN_FUNCTION]: RUN_FUNCTION_MANIFEST,
  [BACKEND_ACTIONS_TYPES.CUSTOM_CODE]: CUSTOM_CODE_MANIFEST,
  [BACKEND_ACTIONS_TYPES.RETURN_TYPE]: RETURN_TYPE_MANIFEST,
  [BACKEND_ACTIONS_TYPES.EMPTY]: EMPTY_MANIFEST,
  [BACKEND_ACTIONS_TYPES.COMMENT]: COMMENT_MANIFEST
};
