import { useCallback, useEffect } from 'react';

interface UseClickOutsideArgs {
  id: string;
  action: () => void;
}

export function useClickOutsideEvent({ id, action }: UseClickOutsideArgs) {
  const handleClickOutside = useCallback(
    (event: any) => {
      const element = document.getElementById(id);
      if (element && !element.contains(event.target)) {
        action();
      }
    },
    [id, action]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
}
