import styles from './styles.module.css';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import Icon from '../../../../../../../../../web_ui/icon';
import React, { useEffect, useState } from 'react';
import { CrudPage, Method, METHODS } from '../../../../../../../../../modules/logic_builder/types';
import { RestDataInterface } from '../../../../../../../../../modules/designer/function_editor/function_actions/types/action_data';
import { TemplateEndpoint } from '../../../../../../../../../modules/designer/types';
import { TYPE_PICKER_TYPES } from '../../../../../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { t } from 'i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import SessionContext from 'modules/auth/store';
import { Validation } from '..';

type Props = {
  page: CrudPage;
  method: Method[];
  action?: RestDataInterface;
  endpoints: TemplateEndpoint[];
  crudType: string[];
  onChange: (action: RestDataInterface) => void;
  isFromPageEdit?: boolean;
};

export default function EndpointAction(props: Props) {
  const session = React.useContext(SessionContext);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState<TemplateEndpoint>();

  useEffect(() => {
    if (!props.action || !props.action.endpoint) return;

    const endpoint = props.endpoints.find(
      (endpoint) => endpoint.uuid === props.action?.endpoint.endpoint
    );

    setSelectedEndpoint(endpoint);
  }, [props.action?.endpoint.endpoint]);

  function formatCrudType(crudType: string) {
    if (!crudType) return;
    const words = crudType.split('_').map((word) => word.toLowerCase());
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  function handleEndpointChange(endpointUuid: string) {
    let action: RestDataInterface;
    if (!props.action) {
      action = {
        endpoint: {
          endpoint: '',
          pathParams: {},
          queryParams: {},
          request: { type: TYPE_PICKER_TYPES.VAR, value: '' },
          response: ''
        }
      };
    } else {
      action = props.action;
    }

    action.endpoint.endpoint = endpointUuid;
    action.endpoint.pathParams = {};

    const endpoint = props.endpoints.find((endpoint) => endpoint.uuid === endpointUuid);
    endpoint?.pathParams.forEach((param) => {
      if (!param.uuid) return;
      action.endpoint.pathParams[param.uuid] = {
        type: TYPE_PICKER_TYPES.PAGEPARAM,
        value: props.page.params.length > 0 ? props.page.params[0].uuid : ''
      };
    });

    props.onChange(action);
  }

  function handleParamsChange(pathParamId: string | undefined, pageParamId: string) {
    if (!props.action || !pathParamId) return;

    const action = props.action;
    action.endpoint.pathParams[pathParamId].value = pageParamId;
    props.onChange(action);
  }

  const backgroundColor = (): React.CSSProperties => {
    return session.preferences['exocode-theme']
      ? { background: '#1b2532' }
      : { background: 'white' };
  };

  const color = (): React.CSSProperties => {
    return session.preferences['exocode-theme'] ? { color: '#1b2532' } : { color: 'white' };
  };

  return (
    <div
      className={`border`}
      style={{ borderRadius: '7px', padding: 0, marginBottom: '1rem', ...backgroundColor() }}
    >
      <div
        className={`${styles.EndpointWrapper}`}
        style={{
          borderRadius: '5px'
        }}
      >
        <div className={styles.wrapMethodAndPath}>
          <div style={{ marginRight: '0.8rem' }}>
            <Badge className={`${styles.Badge}`}>
              <Icon iconName={'arrows-up-down-left-right'} />
            </Badge>
          </div>
          <div className={styles.containerPathDesc}>
            <div>
              <Form.Select
                aria-label="Default select example"
                value={props.action?.endpoint.endpoint}
                required={!props.method.includes(METHODS.DELETE)}
                onChange={(e) => handleEndpointChange(e.target.value)}
                isValid={
                  props.isFromPageEdit ? undefined : props.action?.endpoint.endpoint ? true : false
                }
                isInvalid={!(props.action?.endpoint.endpoint ? true : false)}
              >
                <option value="">---</option>
                {props.endpoints
                  .filter(
                    (endpoint) =>
                      props.method.includes(endpoint.method) &&
                      props.crudType.includes(endpoint.crudType)
                  )
                  .map((endpoint) => (
                    <option key={endpoint.uuid} value={endpoint.uuid}>
                      {endpoint.method} {endpoint.path}
                    </option>
                  ))}
              </Form.Select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.crudType.map((crudType) => (
                <div key={crudType} className={styles.footerTag} style={color()}>
                  {formatCrudType(crudType)}
                </div>
              ))}
              {!props.method.includes(METHODS.DELETE) && (
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('editButton')!
                  }}
                >
                  <Button
                    className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                    size="sm"
                    onClick={() => setShowEdit(!showEdit)}
                  >
                    <Icon iconName="pen-to-square" extraProps="text-secondary h7 pt-2" />
                  </Button>
                </HelpPopover>
              )}
            </div>
          </div>
        </div>
        {/* This div helps with alignment between roles */}
        <div className={styles.EndpointToggle}></div>
      </div>
      <div
        className={`border-primary bg-body-tertiary p-3`}
        style={{ display: showEdit ? 'block' : 'none' }}
      >
        {selectedEndpoint &&
          !props.method.includes(METHODS.DELETE) &&
          selectedEndpoint.pathParams.map((param) => (
            <>
              {param.uuid && (
                <Form.Group
                  as={Row}
                  className="mb-3"
                  style={{ marginTop: '15px' }}
                  key={param.uuid}
                >
                  <Form.Label column sm="2">
                    {param.name}:
                  </Form.Label>
                  <Col sm="10">
                    {props.action?.endpoint.pathParams[param.uuid] && (
                      <>
                        <Form.Select
                          aria-label="Default select example"
                          value={props.action?.endpoint.pathParams[param.uuid].value}
                          onChange={(e) => handleParamsChange(param.uuid, e.target.value)}
                          required
                        >
                          {props.page.params.map((pageParam) => (
                            <option key={pageParam.uuid} value={pageParam.uuid}>
                              {pageParam.name}
                            </option>
                          ))}
                        </Form.Select>
                      </>
                    )}
                  </Col>
                </Form.Group>
              )}
            </>
          ))}
      </div>
    </div>
  );
}
