import React from 'react';
import styles from './styles.module.css';
import useSetRelationshipTableName from '../../hooks/useSetRelationshipTableName';
import { Relationship } from 'modules/modeler/types';
import { RelationshipTypes } from 'modules/modeler/studio/frames/table';

type RelationshipLineProps = {
  relationship: Relationship;
  selectedRelationshipID: string;
  selectRelationship: React.Dispatch<React.SetStateAction<string>>;
};

export default function RelationshipLine({
  relationship,
  selectRelationship,
  selectedRelationshipID
}: RelationshipLineProps) {
  const fromTableName = useSetRelationshipTableName(relationship, 'from');
  const toTableName = useSetRelationshipTableName(relationship, 'to');

  function renderRelationshipSeparator(relationship: Relationship): string {
    switch (relationship.type) {
      case RelationshipTypes.ONE2MANY:
        return relationship.bidirectional === true ? ` «-> ` : ` -> `;
      case RelationshipTypes.MANY2ONE:
      case RelationshipTypes.ONE2ONE:
        return relationship.bidirectional === true ? ` <-» ` : ` <- `;
      default:
        return ` - `;
    }
  }

  return (
    <div
      className={`${styles.RelationshipWrapper} ${
        selectedRelationshipID === relationship?.id && 'bg-body-tertiary'
      }`}
      key={relationship?.id}
      onClick={() => selectRelationship(relationship?.id)}
    >
      <div
        id={relationship.id}
        className={`${styles.KeyValue} ${styles.SelectedRelationship} ${styles.RelationshipDetailsWrapper}`}
      >
        <span className={styles.Key}>
          {relationship.name
            ? relationship.name
            : `(${relationship.fromName ? relationship.fromName : fromTableName} ` +
              `${renderRelationshipSeparator(relationship)} ` +
              `${relationship.toName ? relationship.toName : toTableName})`}
        </span>
        <span
          className={`text-muted ${styles.Value}`}
        >{`${relationship?.type.toLowerCase()}`}</span>
      </div>
    </div>
  );
}
