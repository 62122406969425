import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Col, Container, Form, ListGroup, Row, Tooltip } from 'react-bootstrap';
import {
  columnID,
  ColumnIndexType,
  IndexColumn,
  IndexID,
  IndexType,
  sortOrder,
  TableUUID
} from '../../../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.css';
import { v1 as uuidv1 } from 'uuid';
import { DatabaseStudioState, indexColumnState } from 'modules/modeler/studio/store';
import {
  changeIndexProperty,
  updateIndexColumns
} from 'modules/modeler/studio/store/actions/indexes';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { deleteIndex } from 'modules/modeler/studio/store/actions/root';
import { sleep } from 'utils/utils';
import { updatePrimaryKeys } from 'modules/modeler/studio/store/actions/columns';
import { setErrorMessage } from '../../../../../store/actions/studio';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type IndexSelectorProps = {
  selectedTable: TableUUID;
  selectedIndex: IndexID;
  selectedColums?(table: ColumnIndexType[]): void;
  onExit(): void;
};

function IndexSelector(props: IndexSelectorProps) {
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const indexes = useSelector((state: DatabaseStudioState) => state.indexes);
  const [tableColumns, setTableColumns] = useState<ColumnIndexType[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<ColumnIndexType>();
  const [indexName, setIndexName] = useState<string>(
    indexes[props.selectedIndex] ? indexes[props.selectedIndex].name : ''
  );
  const [hasPrimaryIndex, setHasPrimaryIndex] = useState<boolean>(false);
  const [indexType, setIndexType] = useState<string>(
    indexes[props.selectedIndex] ? indexes[props.selectedIndex].type : ''
  );
  const dispatch = useDispatch();
  const [previousName, setPreviousName] = useState('');

  const { t } = useTranslation();
  const name: string = t('Name');
  const type: string = t('modeler.entity_editor.indexes.Type');

  // Used to load the selected columns that are already saved.
  useEffect(() => {
    const savedColumns: ColumnIndexType[] = [];
    tables[props.selectedTable].content.data.columns.map((columnID: columnID) => {
      const savedColumn =
        indexes[props.selectedIndex] &&
        Object.values(indexes[props.selectedIndex].columns).find(
          (indexColumn: IndexColumn) => indexColumn.columnId === columnID
        );
      // Check if column exists on table and on the index loaded from context;
      //  * If exists: add the orders and the boolean selector to show in screen.
      if (indexes[props.selectedIndex] && savedColumn) {
        savedColumns.push({
          id: savedColumn.id,
          columnId: indexes[props.selectedIndex].columns[savedColumn.id].columnId,
          sortOrder: indexes[props.selectedIndex].columns[savedColumn.id].sortOrder,
          columnOrder: indexes[props.selectedIndex].columns[savedColumn.id].columnOrder,
          selected: true
        });
        //  * If not exists: add default values.
      } else {
        savedColumns.push({
          id: uuidv1(),
          columnId: columnID,
          sortOrder: 'ASC' as sortOrder,
          columnOrder: -1,
          selected: false
        });
      }
      return null;
    });
    // Add the loaded index columns from context to the state, use 'sortColumn' function
    // to sort the list by the 'sortColumn' property
    setTableColumns(handleSortColumns(savedColumns));
  }, [indexes, props.selectedIndex, props.selectedTable, tables]);

  useEffect(() => {
    if (!indexes[props.selectedIndex]) return;
    setIndexName(indexes[props.selectedIndex].name);
    setIndexType(indexes[props.selectedIndex].type);
  }, [props.selectedIndex, indexes]);

  useEffect(() => {
    if (props.selectedColums) {
      props.selectedColums(tableColumns);
    }
  }, [tableColumns]);

  useEffect(() => {
    setHasPrimaryIndex(false);
    tables[props.selectedTable].content.data.indexes.map((indexUUID: IndexID) => {
      if (indexes[indexUUID] && indexes[indexUUID].type === 'PRIMARY') {
        setHasPrimaryIndex(true);
      }
    });
  }, [tables, props.selectedTable, indexes]);

  // Used to update the index name.
  function handleUpdateName(e: ChangeEvent<any>) {
    e.stopPropagation();
    if (!indexName || indexName.length < 2) {
      dispatch(setErrorMessage('modeler.NameTooShortMessage'));
      return setIndexName(previousName);
    }
    dispatch(changeIndexProperty(props.selectedIndex, 'name', indexName));
  }

  // Used to update the index type.
  function handleUpdateType(e: ChangeEvent<any>) {
    e.stopPropagation();
    if (!indexType) return;
    dispatch(changeIndexProperty(props.selectedIndex, 'type', indexType));
  }

  // Used to add or remove a column from the index.
  function handleSelectColumn(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    e.stopPropagation();
    const currentColumns = [...tableColumns];
    // If this is a "add column to index": puts this new column on the end of the list of selected columns.
    if (!currentColumns[index].selected) {
      const orderLength = tableColumns.filter((indexColumn) => {
        return indexColumn.selected;
      }).length;
      currentColumns[index].columnOrder = orderLength === 0 ? 0 : orderLength;
      setSelectedColumn(currentColumns[index]);
    } else {
      // If this is a "remove column of index": remove the column and decrement all the next columns "sortColumn" number.
      currentColumns[index].columnOrder = 999;
      for (let i = index; i < currentColumns.length; i++) {
        if (currentColumns[i].columnOrder) currentColumns[i].columnOrder--;
      }
      setSelectedColumn(undefined);
    }

    currentColumns[index].selected = !currentColumns[index].selected;
    setTableColumns(handleSortColumns(currentColumns));
  }

  // Used to update the "sortOrder" property on every change.
  function handleChangeSortOrder(e: React.ChangeEvent<HTMLSelectElement>, index: number) {
    e.stopPropagation();
    const currentColumns = [...tableColumns];
    currentColumns[index].sortOrder = e.target.value as sortOrder;
    setTableColumns(currentColumns);
  }

  // Decrement the "columnOrder" priority of the selected column and update the next (if exists) column order.
  function handleDecrementOrder(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedRow: ColumnIndexType | undefined
  ) {
    e.stopPropagation();
    if (!selectedRow) return;

    const currentColumns = [...tableColumns];
    const columnIndex = currentColumns.findIndex(
      (columnIndex: ColumnIndexType) => columnIndex.id === selectedRow.id
    );

    if (currentColumns[columnIndex + 1].selected) {
      currentColumns[columnIndex].columnOrder++;
      currentColumns[columnIndex + 1].columnOrder--;
    }
    setTableColumns(handleSortColumns(currentColumns));
  }

  // Increment the "columnOrder" priority of the selected column and update the previous (if exists) column order.
  function handleIncrementOrder(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedRow: ColumnIndexType | undefined
  ) {
    e.stopPropagation();
    if (!selectedRow) return;

    const currentColumns = [...tableColumns];
    const columnIndex = tableColumns.findIndex(
      (currentColumns: ColumnIndexType) => currentColumns.id === selectedRow.id
    );

    if (currentColumns[columnIndex] && currentColumns[columnIndex - 1].selected) {
      currentColumns[columnIndex].columnOrder--;
      currentColumns[columnIndex - 1].columnOrder++;
    }
    setTableColumns(handleSortColumns(currentColumns));
  }

  // Simple ascendent sort by the 'columnSort' property
  function handleSortColumns(columnList: ColumnIndexType[]) {
    return [...columnList].sort((a, b) => a.columnOrder - b.columnOrder);
  }

  // Used to enabled or disable the buttons to reorder the index columns
  function checkDisableReorder(op: 'UP' | 'DOWN') {
    if (!selectedColumn) return;
    const columnIndex = tableColumns.findIndex(
      (columnIndex: ColumnIndexType) => columnIndex.id === selectedColumn.id
    );

    if (op === 'UP' && tableColumns[columnIndex - 1]) {
      return tableColumns[columnIndex - 1].selected ? false : true;
    } else if (op === 'DOWN' && tableColumns[columnIndex + 1]) {
      return tableColumns[columnIndex + 1].selected ? false : true;
    } else return true;
  }

  function addColumnsToIndex(columnIndexList: ColumnIndexType[]) {
    const IndexColumns: indexColumnState = {};

    for (const columnIndex of columnIndexList) {
      IndexColumns[columnIndex.id] = {
        id: columnIndex.id,
        columnId: columnIndex.columnId,
        sortOrder: columnIndex.sortOrder as sortOrder,
        columnOrder:
          columnIndex.columnOrder || columnIndex.columnOrder === 0 ? columnIndex.columnOrder : 999
      };
    }

    dispatch(updateIndexColumns(props.selectedIndex, IndexColumns));

    const savedIndexColumns: string[] = Object.keys(indexes[props.selectedIndex].columns).map(
      (indexColumnID: string) => indexes[props.selectedIndex].columns[indexColumnID].columnId
    );
    const currentIndexColumn = Object.keys(IndexColumns).map(
      (indexColumnID: string) => IndexColumns[indexColumnID].columnId
    );
    if (
      !checkEqualityOfArrays(savedIndexColumns, currentIndexColumn) &&
      indexes[props.selectedIndex].type === IndexType.PRIMARY
    ) {
      updateColumnsPrimaryKey(savedIndexColumns, currentIndexColumn);
    }

    props.onExit();
  }

  function checkEqualityOfArrays(array1: string[], array2: string[]) {
    return array1.length === array2.length && array1.every((item) => array2.includes(item));
  }

  function updateColumnsPrimaryKey(oldIndexColumns: string[], newIndexColumns: string[]) {
    const updatedPKColumns: { [key: columnID]: boolean } = {};

    newIndexColumns.forEach((columnID) => {
      if (!oldIndexColumns.includes(columnID)) updatedPKColumns[columnID] = true;
    });
    oldIndexColumns.forEach((columnID) => {
      if (!newIndexColumns.includes(columnID)) updatedPKColumns[columnID] = false;
    });

    dispatch(updatePrimaryKeys(updatedPKColumns));
  }

  function handleFilterPrimaryType(type: string) {
    if (type !== 'PRIMARY') return true;
    if (hasPrimaryIndex) {
      return indexes[props.selectedIndex].type === 'PRIMARY' ? true : false;
    } else {
      return true;
    }
  }

  async function handleChangeIndexType(e: ChangeEvent<any>) {
    setIndexType(e.target.value);
    if (e.target.value === 'PRIMARY') {
      await sleep(100);
      if (!indexType) return;
      dispatch(changeIndexProperty(props.selectedIndex, 'type', e.target.value));
    }
  }

  function checkColumns() {
    return tableColumns.every((indexColumn) => !indexColumn.selected);
  }

  function checkAlreadyUsedPK(columnID: string) {
    const tableIndexes = Object.values(tables[props.selectedTable].content.data.indexes)
      .filter((indexID) => indexID !== props.selectedIndex)
      .filter((indexID) => !!indexID && !!indexes[indexID as string]);

    return tableIndexes.some((indexID) =>
      Object.values(indexes[indexID as string].columns).some(
        (column) => column.columnId === columnID
      )
    );
  }

  const isNameValid = (name: string) => {
    const regex = /^[a-zA-Z_][0-9a-zA-Z_]*$/;
    return regex.test(name);
  };

  return (
    <Container
      className="p-4"
      onClick={() => setSelectedColumn(undefined)}
      style={{ overflowY: 'auto', maxHeight: '750px', display: 'flex', flexDirection: 'column' }}
    >
      {/* 1/3 Parts - index data (name, type, etc...)  */}
      <Row className="mb-1">
        {/* inputs to change the name and description of index */}
        <Col xs={12}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Col xs="12">
              <Form.Group as={Row} className="mb-2" controlId="formName">
                <Form.Label column sm={3}>
                  {t('Name')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    placeholder={name}
                    value={indexName}
                    onFocus={() => setPreviousName(indexName)}
                    onBlur={(event) => {
                      handleUpdateName(event);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length > 64) {
                        return dispatch(setErrorMessage('modeler.NameTooLongMessage'));
                      }
                      if (!isNameValid(e.target.value)) {
                        dispatch(setErrorMessage('modeler.IllegalNameCharacters'));
                        return setIndexName(previousName);
                      }
                      setIndexName(e.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleUpdateName(event);
                      }
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group as={Row} className="mb-2" controlId="formTableType">
                <Form.Label column sm={3}>
                  {t('modeler.entity_editor.indexes.Type')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Select
                    placeholder={type}
                    value={indexType}
                    onChange={(e) => {
                      handleChangeIndexType(e);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === 'Escape') {
                        handleUpdateType(event);
                      }
                    }}
                    onBlur={(event) => {
                      handleUpdateType(event);
                    }}
                    disabled={indexType === 'PRIMARY'}
                  >
                    {indexes[props.selectedIndex] &&
                      Object.values(IndexType)
                        .filter((v) => isNaN(Number(v)))
                        .filter(handleFilterPrimaryType)
                        .map((type, index) => {
                          return (
                            <option key={type + index} value={type}>
                              {type}
                            </option>
                          );
                        })}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          </Form>
        </Col>
      </Row>
      {/* 2/3 Parts - index columns data (column properties, list of selected columns)  */}
      <Row className="pt-2 pb-2" style={{ overflowY: 'auto' }}>
        {/* Buttons to reorder the index columns */}
        <Col className="mb-2" xs={12}>
          <button
            type="button"
            className={`btn ${selectedColumn ? 'btn-primary' : 'btn-secondary'} btn-sm m-1`}
            onClick={(e) => handleIncrementOrder(e, selectedColumn)}
            disabled={checkDisableReorder('UP')}
          >
            <Icon iconName="arrow-up" />
          </button>
          <button
            type="button"
            className={`btn ${selectedColumn ? 'btn-primary' : 'btn-secondary'} btn-sm m-1`}
            onClick={(e) => handleDecrementOrder(e, selectedColumn)}
            disabled={checkDisableReorder('DOWN')}
          >
            <Icon iconName="arrow-down" />
          </button>
        </Col>

        {/* List of selecteds columns to the index */}
        <Col xs={12}>
          <Form>
            <ListGroup as="ol">
              {tableColumns &&
                tableColumns.map((columnIndex: ColumnIndexType, index) => {
                  return columnIndex.selected && columns[columnIndex.columnId] ? (
                    <ListGroup.Item
                      active={columnIndex.id === selectedColumn?.id}
                      key={columnIndex.id}
                      className="d-flex justify-content-between align-items-start"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedColumn(columnIndex);
                      }}
                    >
                      <div className={styles.indexColumnItem}>
                        <div className={styles.indexColumnItem}>
                          <Form.Check
                            onChange={(e) => {
                              handleSelectColumn(e, index);
                            }}
                            checked={tableColumns[index].selected}
                            aria-label="column checkbox selector"
                            style={{ marginRight: 8 }}
                          />
                          {columns[columnIndex.columnId].name.length > 11 ? (
                            <div
                              className={`text-body`}
                              style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                              {columns[columnIndex.columnId].name}
                            </div>
                          ) : (
                            columns[columnIndex.columnId].name
                          )}
                          <span className="ms-1"> [{columnIndex.columnOrder}]</span>
                          <p style={{ fontSize: '12px', marginLeft: 5 }}>
                            ({columns[columnIndex.columnId].type})
                          </p>

                          <select
                            className={`${styles.columnTypeSelect} form-select form-select-sm`}
                            placeholder="order"
                            defaultValue={columnIndex.sortOrder}
                            onChange={(e) => {
                              handleChangeSortOrder(e, index);
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {Object.values(sortOrder)
                              .filter((v) => isNaN(Number(v)))
                              .map((type) => {
                                return (
                                  <option key={type} value={type}>
                                    {type}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ) : null;
                })}

              {/* List of columns available to append in the index */}
              <div className="mt-4">
                {tableColumns &&
                  tableColumns.map((columnIndex: ColumnIndexType, index) => {
                    return columns[columnIndex.columnId] &&
                      !checkAlreadyUsedPK(columnIndex.columnId) &&
                      !columnIndex.selected ? (
                      <ListGroup.Item
                        active={columnIndex.id === selectedColumn?.id}
                        key={columnIndex.id}
                        className="d-flex justify-content-between align-items-start"
                        onClick={() => setSelectedColumn(columnIndex)}
                      >
                        <div className={styles.indexColumnItem}>
                          <Form.Check
                            onChange={(e) => {
                              handleSelectColumn(e, index);
                            }}
                            checked={tableColumns[index].selected}
                            aria-label="column checkbox selector"
                            style={{ marginRight: 8 }}
                          />
                          {columns[columnIndex.columnId].name.length > 11 ? (
                            <b style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                              {columns[columnIndex.columnId].name}
                            </b>
                          ) : (
                            <b>{columns[columnIndex.columnId].name}</b>
                          )}
                          <p>({columns[columnIndex.columnId].type})</p>
                          <p></p>
                        </div>
                      </ListGroup.Item>
                    ) : null;
                  })}
              </div>
            </ListGroup>
          </Form>
        </Col>
      </Row>
      {/* 3/3 Parts - Action buttons (save, etc...)  */}
      <Row>
        <Col className="text-end">
          <button
            className={`${styles.saveIndex} btn d-inline-flex p-2 align-items-center btn-primary `}
            onClick={() => {
              const ListToSave = tableColumns.filter((indexColumn) => {
                return indexColumn.selected;
              });

              if (ListToSave.length === 0) {
                dispatch(deleteIndex(props.selectedIndex, props.selectedTable));
                dispatch(setErrorMessage('modeler.InsertEmptyIndex'));
                props.onExit();
                return;
              }
              addColumnsToIndex(
                tableColumns.filter((indexColumn) => {
                  return indexColumn.selected;
                })
              );
            }}
            disabled={checkColumns()}
          >
            <Icon iconName="save" margin="0 0.5rem 0 0"></Icon>
            {t('modeler.entity_editor.indexes.save')}
          </button>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(IndexSelector);
