import { Action } from 'redux';
import { ActionsState } from '../../types/actions';

export const DELETE_BLOCK = 'DELETE_BLOCK';

export interface DeleteBlockAction extends Action {
  type: 'DELETE_BLOCK';
  payload: {
    functionUuid: string;
    uuid: string;
    closeBlockActionUuid: string;
  };
}

export const deleteBlock = (
  functionUuid: string,
  uuid: string,
  closeBlockActionUuid: string
): DeleteBlockAction => ({
  type: DELETE_BLOCK,
  payload: {
    functionUuid,
    uuid,
    closeBlockActionUuid
  }
});

export function doDeleteBlock(state: ActionsState, action: DeleteBlockAction): ActionsState {
  const { uuid, closeBlockActionUuid } = action.payload;
  delete state[closeBlockActionUuid];
  delete state[uuid];
  return state;
}
