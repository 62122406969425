import React from 'react';

interface ColorFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function ColorFormatterPreview({ formatterParams, value }: ColorFormatterPreviewProps) {
  return <div style={{ width: '25px', height: '25px', backgroundColor: '#34ff02' }}></div>;
}

export default ColorFormatterPreview;
