import { ComponentType, ReactNode } from 'react';
import OneColumnIcon from './oneColumn';
import TwoColumnsIcon from './twoColumn copy';
import ThreeColumnsIcon from './threeColumns';
import FourColumnsIcon from './fourColumn';

export const CUSTOM_ICONS_TYPES = {
  oneColumn: 'oneColumn',
  twoColumns: 'twoColumns',
  threeColumns: 'threeColumns',
  fourColumns: 'fourColumns'
};

export const CUSTOM_ICONS_LIST: Record<string, ComponentType> = {
  [CUSTOM_ICONS_TYPES.oneColumn]: OneColumnIcon,
  [CUSTOM_ICONS_TYPES.twoColumns]: TwoColumnsIcon,
  [CUSTOM_ICONS_TYPES.threeColumns]: ThreeColumnsIcon,
  [CUSTOM_ICONS_TYPES.fourColumns]: FourColumnsIcon
};
