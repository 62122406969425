import React from 'react';

function BeginElseIcon() {
  return (
    <span className="fa-layers fa-fw" style={{ whiteSpace: 'nowrap' }}>
      <label
        className={'text-secondary-emphasis text-sm-end fas fa-solid fa-less-than'}
        style={{ fontSize: '0.7rem' }}
      />
      <label>Else</label>
      <label
        className={'text-secondary-emphasis text-sm-end fas fa-solid fa-greater-than'}
        style={{ fontSize: '0.7rem' }}
      />
    </span>
  );
}

export default BeginElseIcon;
