import { DataType } from '../../../../../../modules/logic_builder/types';
import { FRONTEND_VARIABLE_TYPES, VariableTypes } from '../../../../../../modules/designer/types';

function useCheckSameType() {
  function validateType(dataType: DataType, targetType: VariableTypes) {
    switch (dataType) {
      case 'CHAR':
      case 'VARCHAR':
      case 'STRING':
      case 'DATE':
      case 'TIME':
      case 'TIMESTAMP':
        return targetType === FRONTEND_VARIABLE_TYPES.STRING;
      case 'SMALLINT':
      case 'INTEGER':
      case 'BIGINT':
      case 'DECIMAL':
      case 'FLOAT':
      case 'DOUBLE':
      case 'AUTOINCREMENT':
        return targetType === FRONTEND_VARIABLE_TYPES.NUMBER;
      case 'BOOLEAN':
        return targetType === FRONTEND_VARIABLE_TYPES.BOOLEAN;
      default:
        return targetType === FRONTEND_VARIABLE_TYPES.ANY;
    }
  }
  return validateType;
}

export default useCheckSameType;
