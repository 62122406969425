import { Button, Form, Modal } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Confirmation from '../../../../web_ui/confirmation';
import { ProjectsService } from '../../../../modules/project/services';
import { ApiAppInfo, JobOptions } from '../../../../modules/project/types';
import { useTranslation } from 'react-i18next';
import SessionContext from '../../../../modules/auth/store';
import { useNavigate, useParams } from 'react-router-dom';
import { ModuleInfo } from '../../../../modules/dashboard/types';
import HelpIcon from '../../../../web_ui/workboard/sidebar/controls//components/HelpIcon';
import HelpPopover from '../../../../web_ui/workboard/sidebar/controls/components/Popover';
import { FaCode } from 'react-icons/all';

export type CodeDownloadDialogProps = {
  app: ApiAppInfo;
  show: boolean;
  withPR?: boolean;
  onClose: () => void;
};

const API_URL = process.env.REACT_APP_API_URL;

export function CodeDownloadDialog(props: CodeDownloadDialogProps) {
  const { app, onClose } = props;
  const session = useContext(SessionContext);
  const [hasDependencies, setHasDependencies] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedCodeToDownload, setSelectedCodeToDownload] = useState<JobOptions>({
    frontend: false,
    backend: false,
    sql: false,
    prTitle: '',
    prDescription: ''
  });
  const { t } = useTranslation();
  const { app_id } = useParams();
  const navigate = useNavigate();

  const cleanSelectedCodeState = useCallback(() => {
    setSelectedCodeToDownload({
      frontend: false,
      backend: false,
      sql: false,
      prTitle: '',
      prDescription: ''
    });
  }, []);

  const handleDownloadCode = useCallback(async () => {
    const jobInfo = await ProjectsService.runJob(app.id, selectedCodeToDownload);

    session.setDownloads({ ...session.downloads, [jobInfo.id]: jobInfo });
    cleanSelectedCodeState();
    onClose();
  }, [app.id, onClose, selectedCodeToDownload]);

  const fetchModules = useCallback(async () => {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    if (!API_URL) {
      throw new Error('Error in the API url');
    } else {
      return fetch(
        `${API_URL}/modules/filterByApp?appId=${app_id}&includeDependencies=true`,
        options
      ).then(async (response: Response) => {
        if (response.ok) {
          return response.json() as Promise<ModuleInfo[]>;
        }
        const message = await response.text();
        throw new Error(message);
      });
    }
  }, [app_id]);

  useEffect(() => {
    if (!props.show) {
      cleanSelectedCodeState();
      return;
    }

    fetchModules().then((modules: ModuleInfo[]) => {
      const hasDependencies = modules.some((module) => module.dependencies.length > 0);
      setHasDependencies(hasDependencies);
    });
  }, [fetchModules, props.show]);

  const disableDownload = (): boolean => {
    // if anyone is true will enter
    if (
      selectedCodeToDownload.backend ||
      selectedCodeToDownload.frontend ||
      selectedCodeToDownload.sql
    ) {
      // if anyone is true will enter and disabled will be enabled
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onClose}
        style={{
          background: 'rgba(0, 0, 0, 0.35)'
        }}
      >
        <Modal.Header
          closeButton
          style={{
            border: '2px solid black',
            borderBottom: 'none'
          }}
        >
          {t('appResume.DownloadCode')}
        </Modal.Header>
        <Modal.Body
          style={{
            borderLeft: '2px solid black',
            borderRight: '2px solid black'
          }}
        >
          {/*<hr />*/}
          <section style={{ display: 'flex', justifyContent: 'space-between' }}>
            {props.app.has_frontend && (
              <div className="d-flex">
                <Form.Check
                  id={'frontendCheck'}
                  checked={selectedCodeToDownload.frontend}
                  label={'Frontend'}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    setSelectedCodeToDownload({
                      ...selectedCodeToDownload,
                      frontend: e.target.checked
                    })
                  }
                />
              </div>
            )}
            {props.app.has_backend && (
              <Form.Check
                id={'backendCheck'}
                checked={selectedCodeToDownload.backend}
                label={'Backend'}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  setSelectedCodeToDownload({
                    ...selectedCodeToDownload,
                    backend: e.target.checked
                  })
                }
              />
            )}

            {props.app.has_database && (
              <div className="d-flex">
                <Form.Check
                  id={'sqlCheck'}
                  checked={selectedCodeToDownload.sql}
                  label={'SQL Scripts'}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    setSelectedCodeToDownload({
                      ...selectedCodeToDownload,
                      sql: e.target.checked
                    })
                  }
                  disabled={hasDependencies}
                />
                {hasDependencies && (
                  <HelpPopover
                    helpBoxProps={{
                      title: t('code_download.sql_script') || 'code_download.sql_script',
                      description: t('code_download.sql_question') || 'code_download.sql_question',
                      note: [t('code_download.sql_reason')]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                )}
              </div>
            )}
          </section>
          {props.withPR && (
            <section style={{ marginTop: '20px' }}>
              <Form.Label htmlFor="prTitle">Pull Request Title</Form.Label>
              <Form.Control
                type="text"
                id="prTitle"
                value={selectedCodeToDownload.prTitle}
                onChange={(e) =>
                  setSelectedCodeToDownload({
                    ...selectedCodeToDownload,
                    prTitle: e.target.value
                  })
                }
              />
              <Form.Label htmlFor="prDescription">Pull Request Description</Form.Label>
              <Form.Control
                as="textarea"
                id="prDescription"
                value={selectedCodeToDownload.prDescription}
                style={{ height: '100px' }}
                onChange={(e) =>
                  setSelectedCodeToDownload({
                    ...selectedCodeToDownload,
                    prDescription: e.target.value
                  })
                }
              />
            </section>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            border: '2px solid black',
            borderTop: 'none'
          }}
        >
          <Button id="cancelButton" variant="danger" onClick={props.onClose}>
            {t('Cancel')}
          </Button>
          <Button
            id="downloadButton"
            variant="success"
            onClick={() => setShowConfirmationModal(true)}
            disabled={disableDownload()}
          >
            <FaCode />
            <span style={{ marginLeft: '5px' }}>{t('appResume.DownloadCode')}</span>
          </Button>
        </Modal.Footer>
      </Modal>
      <Confirmation
        message={t('appResume.DownloadMessageOpt')}
        show={showConfirmationModal}
        onConfirmation={() => {
          handleDownloadCode();
          setShowConfirmationModal(false);
          navigate(`/app/${app_id}/jobs`, { state: { location: 'fromCodeDialog' } });
        }}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
        confirmationLabel={t('Confirm') as string}
        confirmationVariant={'success'}
        cancelLabel={t('Cancel') as string}
        cancelVariant={'secondary'}
      />
    </>
  );
}
