import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';

function ErrorFallbackIframe({ error, resetErrorBoundary, title, size }: any) {
  const { t } = useTranslation();
  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#212529' }} role="alert">
      <div
        className="d-flex justify-content-center align-items-center h-100 w-100"
        style={size !== 'sm' ? { padding: '4rem' } : { padding: '1rem' }}
      >
        <div className="row">
          {size !== 'sm' && (
            <div
              style={{ position: 'relative' }}
              className={`col-12 col-xs-12 col-md-${size === 'sm' ? '3' : '8'} text-center`}
            >
              <img style={{ width: '100%' }} src="/assets/bug_fixing.svg" alt="Error image" />
              <span
                className="text-body-tertiary"
                style={{ position: 'absolute', left: '40%', fontSize: '2rem', fontWeight: '800' }}
              >
                {t(title)}
              </span>
            </div>
          )}

          <div
            className={`col-12 col-xs-12 col-md-${
              size === 'sm' ? '12' : '3'
            } d-flex align-items-center flex-column justify-content-center`}
          >
            {size !== 'sm' && (
              <h1
                className="w-100 text-danger d-inline"
                style={{ fontWeight: '200', fontSize: '1.2rem' }}
              >
                {t('errors.SomethingWent')}

                <b style={{ fontWeight: '900' }}> {t('errors.Wrong')}!</b>
              </h1>
            )}

            <span
              className=" d-block mt-3 p-3 rounded w-100"
              style={{ backgroundColor: '#210e10', color: '#e76171' }}
            >
              {t('errors.GlobalError')}
            </span>
            <div className="w-100">
              <button
                className="mt-4 btn btn-secondary p-2 pe-5 ps-5"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Icon iconName="refresh"></Icon> {size !== 'sm' && t('errors.Refresh')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorFallbackIframe;
