import React from 'react';
import { Button, Popover } from 'react-bootstrap';
import { TableAction } from '..';
import * as Icons from 'react-icons/fa';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

interface TableActionsPreviewProps {
  actions: TableAction[];
}

function TableActionsPreview(props: TableActionsPreviewProps) {
  return (
    <td style={{ whiteSpace: 'nowrap', width: '1%' }}>
      {props.actions.map((action: TableAction, index: number) => {
        const Icon = action.icon ? Icons[action.icon as keyof typeof Icons] : null;

        return (
          <Button
            key={index}
            className="p-0 me-1"
            size="sm"
            style={{ minWidth: '24px', width: 'fit-content', height: '24px' }}
            variant={action.variant}
          >
            {action.icon && Icon ? (
              <HelpPopover
                placement="top"
                helpBoxProps={{
                  title: action.title
                }}
              >
                <div>
                  <Icon />
                </div>
              </HelpPopover>
            ) : (
              <p className="ps-1 pe-1">{action.title}</p>
            )}
          </Button>
        );
      })}
    </td>
  );
}

export default TableActionsPreview;
