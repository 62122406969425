import React from 'react';
import { Argument } from '../../../store/types/manifestsAndInputs';
import { ViewParam } from './hooks/useGetExtendedView';
import ViewParameterEditor from './view_parameter_editor';
import { AllowedModes } from './index';

type ViewParametersProps = {
  params: { [key: string]: ViewParam };
  paramsValues: { [key: string]: Argument };
  actionUuid: string;
  handleChange: (paramId: string, value: Argument) => void;
  mode: AllowedModes;
  isFromSideBar?: boolean;
};

function ViewParameters({
  params,
  paramsValues,
  handleChange,
  actionUuid,
  mode,
  isFromSideBar
}: ViewParametersProps) {
  return (
    <div
      className={
        isFromSideBar ? '' : 'd-flex flex-column text-white align-items-start justify-content-end'
      }
    >
      {paramsValues && Object.keys(paramsValues).length > 0 && (
        <span style={{ fontSize: '0.9rem' }}>{mode === 'page' ? 'Params:' : 'Props:'}</span>
      )}
      {paramsValues && Object.keys(paramsValues).length > 0 && (
        <span style={{ fontSize: '0.9rem' }}>{mode === 'page' ? 'Params:' : 'Props:'}</span>
      )}
      {paramsValues &&
        Object.keys(paramsValues).map((p, index) => (
          <ViewParameterEditor
            key={index}
            param={params[p]}
            paramValue={paramsValues[p]}
            handleChange={handleChange}
            actionUuid={actionUuid}
            mode={mode}
            isFromSideBar={isFromSideBar}
          />
        ))}
    </div>
  );
}

export default ViewParameters;
