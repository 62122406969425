import { AppRoleRepository } from '../repos';
import { ExoRole } from '../types';

export class AppRoleServ {
  async createRole(appId: string, role: ExoRole) {
    return await AppRoleRepository.createRole(appId, role);
  }

  async getRoles(appId: string) {
    return await AppRoleRepository.getRoles(appId);
  }

  async updateRole(roleId: number, role: ExoRole) {
    return await AppRoleRepository.updateRole(roleId, role);
  }

  async deleteRole(roleId: number) {
    return await AppRoleRepository.deleteRole(roleId);
  }
}
