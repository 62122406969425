import React, { DragEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'modules/logic_builder/styles.module.css';
import {
  LogicBuilderConcept,
  getLogicBuilderConceptInfo,
  SHOW_ALL_URL,
  Service
} from 'modules/logic_builder/types';
import { ServiceCreatorDialog } from 'modules/logic_builder/components/dialogs/service_creator_dialog';
import LogicBuilderContext from 'modules/logic_builder/store';
import { ObjectsService, ServicesService } from 'modules/logic_builder/services';
import { useNavigate, useParams } from 'react-router-dom';
import SearchBar from 'web_ui/search_bar';
import { LocalStorageManager } from 'utils/localStorage/localstorage';
import { Table } from '../../../../modeler/types';
import { Folder, FolderType } from 'modules/designer/types';
import { ListGroup } from 'react-bootstrap';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Icon from 'web_ui/icon';
import { ServiceEditorDialog } from 'modules/logic_builder/components/dialogs/service_editor_dialog';
import CreateFolderDialog from 'modules/designer/studio/toolbars/views_toolbar/create_folder_modal';
import { ContextMenuItem, FolderItem, RenderFolders } from 'web_ui/folders';
import {
  lb_drop,
  lb_dragstart,
  lb_dragleave,
  lb_dragover,
  deletefolder,
  lb_saveitem,
  savefolder,
  updateFoldersOnCreate,
  updateFoldersOnMoveFolder,
  updateFoldersOnRename,
  updateFoldersOnDelete,
  checkFolderNameAlreadyExists
} from 'web_ui/folders/callbacks';
import { validateClassName, validateFolderName } from 'utils/inputValidation';
import {
  DraggableResource,
  TargetType
} from 'web_ui/folders/draggable_resource/draggable_resource';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';

function ClassesToolbar() {
  const { t } = useTranslation();
  const { app_id, module_id, service_id } = useParams();
  const [filterValue, setFilterValue] = useState('');
  const [showServiceModal, setShowServiceModal] = useState(false);
  const {
    services,
    deleteService,
    changeService,
    servicesFolders,
    updateFolders,
    navigateToConcept,
    setLastSelectedConcept,
    checkRepeatedService
  } = useContext(LogicBuilderContext);
  const [tables, setTables] = useState<Table[]>([]);
  const navigate = useNavigate();
  const getNestedItems = (): Record<string, boolean> => {
    if (!app_id || !module_id) {
      return {};
    }
    const getItems = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (getItems[module_id] && getItems[module_id].logicBuilder.folderState.functions) {
      return getItems[module_id].logicBuilder.folderState.functions;
    } else {
      return {};
    }
  };
  const [showNested, setShowNested] = useState<Record<string, boolean>>(() => getNestedItems());
  const [showFolderDialog, setShowFolderDialog] = useState<string>();
  const [folderItems, setFolderItems] = useState<FolderItem[]>([]);
  const [showUpdateDialog, setShowUpdateDialog] = useState<string>();
  const [previewServiceUuid, setPreviewServiceUuid] = useState<string>('');

  const servicesKeys = Object.keys(services);
  const CONCEPT = LogicBuilderConcept.FUNCTION;

  const updateShowNestedLocalStorage = useCallback(
    (next: Record<string, boolean>) => {
      if (!app_id || !module_id) {
        return;
      }
      const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      copying[module_id] = {
        ...copying[module_id],
        logicBuilder: {
          ...copying[module_id].logicBuilder,
          folderState: {
            ...copying[module_id].logicBuilder.folderState,
            functions: next
          }
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, copying);
    },
    [app_id, module_id]
  );

  const toggleCollapseFolder = useCallback(
    (folderId: string, collapsed: boolean): void => {
      const next: Record<string, boolean> = {
        ...showNested,
        [folderId]: collapsed
      };
      setShowNested(next);
      updateShowNestedLocalStorage(next);
    },
    [showNested, updateShowNestedLocalStorage]
  );

  const reloadServices = useCallback(async () => {
    if (!module_id) return;
    const items: FolderItem[] = [];
    for (const s of Object.values(services)) {
      items.push({ ...s, uuid: s.uuid ?? '', type: 'CLASS' });
    }
    setFolderItems(items);
  }, [module_id, services]);

  useEffect(() => {
    reloadServices();
  }, [reloadServices]);

  const openAllFolders = useCallback(() => {
    const next: Record<string, boolean> = {};
    for (const folder of servicesFolders ?? []) {
      next[folder.uuid] = true;
    }
    setShowNested(next);
    updateShowNestedLocalStorage(next);
  }, [servicesFolders, updateShowNestedLocalStorage]);

  const closeAllFolders = useCallback(() => {
    setShowNested({});
    updateShowNestedLocalStorage({});
  }, [updateShowNestedLocalStorage]);

  const folderNameIsValid = (nextParent: string, nextName: string) => {
    if (checkFolderNameAlreadyExists(nextParent, nextName, servicesFolders ?? [])) {
      return false;
    } else if (!validateFolderName(nextName)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!module_id) return;
    ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setTables(schema?.tables ? schema.tables : []);
    });
  }, [module_id]);

  const updateLocalStorage = useCallback(
    (serviceId: string) => {
      if (!module_id || !app_id) return;
      const currState = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      currState[module_id] = {
        ...currState[module_id],
        logicBuilder: {
          ...currState[module_id].logicBuilder,
          lastSelectedService: serviceId
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, currState);
    },
    [app_id, module_id]
  );

  // ! Fix deps: error is probably in navigate. These probably shouldn't be in here.
  const handleSelectService = useCallback(
    (selectedKey?: string) => {
      navigateToConcept(CONCEPT, selectedKey);
      setLastSelectedConcept(CONCEPT, selectedKey ?? SHOW_ALL_URL);
      updateLocalStorage(selectedKey ?? '');
    },
    [CONCEPT, updateLocalStorage]
  );
  const handleDeleteService = useCallback(
    async (deleteService: string): Promise<void> => {
      if (!module_id) return;
      await ServicesService.deleteService(deleteService).then(() => {
        if (service_id === deleteService) {
          navigate(`/app/${app_id}/module/${module_id}/logic/service/${SHOW_ALL_URL}`, {
            replace: true
          });
          setLastSelectedConcept(CONCEPT, SHOW_ALL_URL);
        }
      });
    },
    [CONCEPT, app_id, module_id, service_id]
  );
  const firstServiceId = servicesKeys[0];
  useEffect(() => {
    if (!module_id || !app_id) {
      return;
    }
    const currState = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (!currState[module_id].logicBuilder.lastSelectedService) {
      navigateToConcept(CONCEPT, firstServiceId);
      setLastSelectedConcept(CONCEPT, firstServiceId);
    }
  }, [CONCEPT, app_id, firstServiceId, module_id]);

  const onCreateServiceDialog = (serviceId: string) => {
    updateLocalStorage(serviceId);
    setLastSelectedConcept(CONCEPT, serviceId);
    navigateToConcept(CONCEPT, serviceId);
  };

  const toSingular = (word: string) => {
    if (word.endsWith('s')) {
      return word.slice(0, -1);
    }
    return word;
  };

  const handleUpdateFolders = useCallback(
    (f: Folder[]): void => {
      updateFolders(f, 'services');
    },
    [updateFolders]
  );

  const handleDrop = useCallback(
    (sourceId: string, sourceType: TargetType, newParentFolder: string) => {
      if (!servicesFolders) return;
      updateFoldersOnMoveFolder(
        servicesFolders,
        sourceId,
        newParentFolder,
        sourceType,
        handleUpdateFolders
      );
      const service = Object.values(services).find((o) => o.uuid === sourceId);
      if (service) {
        changeService({ ...service, folder_id: newParentFolder });
      }
    },
    [changeService, handleUpdateFolders, services, servicesFolders]
  );

  const getCallbacks = useCallback((): Record<string, Record<string, any>> | undefined => {
    if (!module_id || !servicesFolders) {
      return undefined;
    }
    const callbacks: Record<string, Record<string, any>> = {};
    // Folder items callbacks.
    for (const folderItem of folderItems) {
      callbacks[folderItem.uuid] = {};
      callbacks[folderItem.uuid].select = () => handleSelectService(folderItem.uuid);
      callbacks[folderItem.uuid].save = (newName: string) => {
        lb_saveitem(folderItem.uuid, 'SERVICE', newName);
        changeService({ ...folderItem, name: newName } as unknown as Service);
      };
      callbacks[folderItem.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await lb_drop(e, 'folder', el, folderItem.folder_id ?? '', module_id).then((s) => {
          if (!s) return;
          handleDrop(s.sourceId, s.sourceType, folderItem.folder_id ?? '');
        });
      callbacks[folderItem.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        lb_dragstart(e, folderItem.uuid, folderItem.folder_id ?? '', 'SERVICE', 'folderItem');
      callbacks[folderItem.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragleave(e, el);
      callbacks[folderItem.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragover(e, el, 'folderItem');
      callbacks[folderItem.uuid].delete = async () =>
        await handleDeleteService(folderItem.uuid).then(() => deleteService(folderItem.uuid));
      callbacks[folderItem.uuid].select = async () => handleSelectService(folderItem.uuid);
      callbacks[folderItem.uuid].validate = (name: string): string => {
        const renamedService = { ...folderItem, name: name } as unknown as Service;
        if (!validateClassName(name).valid) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        } else if (checkRepeatedService(renamedService)) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        }
        return '';
      };
    }
    // Folders callbacks.
    let checkFolders: Folder[] = [...servicesFolders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) {
        continue;
      }
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = {};
      callbacks[folder.uuid].save = (newName: string) => {
        savefolder(module_id, folder.uuid, newName);
        updateFoldersOnRename(servicesFolders, folder.uuid, newName, handleUpdateFolders);
      };
      callbacks[folder.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await lb_drop(e, 'folder', el, folder.uuid, module_id).then((s) => {
          if (!s) return;
          handleDrop(s.sourceId, s.sourceType, folder.uuid);
        });
      callbacks[folder.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        lb_dragstart(e, folder.uuid, folder.parent ?? '', '', 'folder');
      callbacks[folder.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragleave(e, el);
      callbacks[folder.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragover(e, el, 'folder');
      callbacks[folder.uuid].delete = async () =>
        await deletefolder(module_id, folder.uuid).then(() =>
          updateFoldersOnDelete(servicesFolders, folder.uuid, handleUpdateFolders)
        );
    }
    return callbacks;
  }, [
    changeService,
    checkRepeatedService,
    deleteService,
    folderItems,
    handleDeleteService,
    handleDrop,
    handleSelectService,
    handleUpdateFolders,
    module_id,
    servicesFolders
  ]);

  const getMenuCallbacks = useCallback((): Record<string, ContextMenuItem[]> | undefined => {
    const callbacks: Record<string, ContextMenuItem[]> = {};
    if (!module_id || !servicesFolders) {
      return undefined;
    }
    // Folder callbacks.
    let checkFolders: Folder[] = [...servicesFolders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) {
        continue;
      }
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = [];
    }
    // Folder items callbacks.
    for (const viewInfo of folderItems) {
      callbacks[viewInfo.uuid] = [];
      callbacks[viewInfo.uuid].push({
        label: 'Edit',
        icon: 'pencil',
        onClick: () => setShowUpdateDialog(viewInfo.uuid)
      });
      callbacks[viewInfo.uuid].push({
        label: 'CodePreview',
        icon: 'code',
        onClick: () => {
          setPreviewServiceUuid(viewInfo.uuid ?? '');
        }
      });
    }
    return callbacks;
  }, [folderItems, module_id, servicesFolders]);

  const getRootCallbacks = useCallback(() => {
    const callbacks: Record<string, Record<string, any>> = {};
    if (!module_id) {
      return callbacks;
    }
    callbacks['root'] = {};
    callbacks['root'].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      await lb_drop(e, 'root', el, '', module_id).then((s) => {
        if (!s) return;
        handleDrop(s.sourceId, s.sourceType, '');
      });
    callbacks['root'].dragstart = (e: DragEvent<HTMLDivElement>) =>
      lb_dragstart(e, '', '', '', 'root');
    callbacks['root'].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      lb_dragleave(e, el);
    callbacks['root'].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      lb_dragover(e, el, 'root');
    return callbacks;
  }, [handleDrop, module_id]);

  const rootCallbacks = getRootCallbacks()['root'];

  return (
    <div className={'d-flex flex-column h-100 flex-grow-1'} style={{ width: '100%' }}>
      <div className="p-2">
        <SearchBar
          id={'findObj'}
          placeholder={t('Search') ?? ''}
          text={filterValue}
          setText={setFilterValue}
        />
      </div>
      <div className={`${styles.layersContainer}`}>
        <div
          className="font-weight-bold ms-3 pb-2 border-bottom border-3 border-body-emphasis me-2 mb-1 d-flex justify-content-between align-items-center"
          style={{ fontWeight: 600 }}
        >
          <label id="label">{'Services'}</label>

          <ListGroup horizontal>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.FolderCollapse')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0 me-2"
                onClick={() => closeAllFolders()}
              >
                <Icon iconName="down-left-and-up-right-to-center" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.FolderExpand')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0 me-2"
                onClick={() => openAllFolders()}
              >
                <Icon iconName="up-right-and-down-left-from-center" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.NewRootFolder')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                id="createFolderButton"
                action
                className="border-0 m-0 p-0"
                onClick={() => setShowFolderDialog('')}
              >
                <Icon iconName="folder-plus" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              placement={'top'}
              helpBoxProps={
                {
                  title: `${t('New')} ${toSingular(
                    t(getLogicBuilderConceptInfo(CONCEPT).parentTitle)
                  )}`
                } ?? ''
              }
            >
              <div
                id={`create${t(getLogicBuilderConceptInfo(CONCEPT).parentTitle)}Button`}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  setShowServiceModal(true);
                }}
                style={{ cursor: 'pointer', marginLeft: 5 }}
              >
                <Icon iconName="plus" extraProps="fa-lg" />
              </div>
            </HelpPopover>
          </ListGroup>
        </div>
        <div style={{ overflowX: 'auto', width: '100%', height: '100%' }}>
          {rootCallbacks && (
            <DraggableResource
              handleDrop={getRootCallbacks()['root'].drop}
              handleDragStart={getRootCallbacks()['root'].dragstart}
              handleDragOver={getRootCallbacks()['root'].dragover}
              handleLeave={getRootCallbacks()['root'].dragleave}
              className={styles.rootDraggableArea}
            >
              <div className={`p-3 pt-0`}>
                <RenderFolders
                  folders={servicesFolders ?? []}
                  collapsedFolders={showNested}
                  toggleCollapsedFolder={toggleCollapseFolder}
                  items={folderItems}
                  selectedItem={service_id ?? ''}
                  callbacks={getCallbacks()}
                  itemFilter={filterValue}
                  contextMenuItems={getMenuCallbacks()}
                  setFolders={handleUpdateFolders}
                  checkFolderNameExists={(parentId: string, name: string, folderId: string) =>
                    checkFolderNameAlreadyExists(parentId, name, servicesFolders ?? [], folderId)
                  }
                />
              </div>
            </DraggableResource>
          )}
        </div>
      </div>
      {showFolderDialog != null && (
        <CreateFolderDialog
          show={showFolderDialog != null}
          onClose={() => {
            setShowFolderDialog(undefined);
          }}
          parentUuid={showFolderDialog}
          onCreate={(f: Folder) =>
            updateFoldersOnCreate(servicesFolders ?? [], f, handleUpdateFolders)
          }
          type={FolderType.LG_SRV}
          validateFolderName={(parent: string, name: string) => folderNameIsValid(parent, name)}
        />
      )}
      <ServiceCreatorDialog
        show={showServiceModal}
        tables={tables}
        onClose={() => setShowServiceModal(false)}
        onCreate={onCreateServiceDialog}
      />
      {showUpdateDialog != null && (
        <ServiceEditorDialog
          serviceUuid={showUpdateDialog}
          show={showUpdateDialog != null}
          onClose={() => setShowUpdateDialog(undefined)}
        />
      )}
      {previewServiceUuid && (
        <CodeEditorModal
          show={previewServiceUuid !== ''}
          handleClose={() => setPreviewServiceUuid('')}
          id={previewServiceUuid}
          previewType={CodePreviewType.SERVICE}
        />
      )}
    </div>
  );
}

export default ClassesToolbar;
