import produce from 'immer';

import { ComponentsState, initialState } from '../index';
import {
  CHANGE_COMPONENT_PROPERTY,
  CHANGE_COMPONENT_STYLE,
  ChangeComponentPropertyAction,
  ChangeComponentStyleAction,
  SET_COMPONENTS,
  SetComponentsAction,
  AddComponentEventAction,
  ADD_COMPONENT_EVENT,
  DeleteComponentEventAction,
  DELETE_COMPONENT_EVENT,
  CHANGE_CUSTOM_COMPONENT_VIEW_PROPERTY,
  ChangeCustomComponentViewPropertyAction,
  DuplicateComponentAction,
  COPY_COMPONENT,
  CHANGE_COMPONENT_PROPERTIES,
  ChangeComponentPropertiesAction,
  CHANGE_ALL_COMPONENT_PROPERTIES,
  ChangeAllComponentPropertiesAction
} from '../actions/components';
import {
  ADD_COMPONENT,
  AddComponentAction,
  DELETE_COMPONENT,
  DeleteComponentAction,
  ADD_CUSTOM_COMPONENT,
  AddCustomComponentAction,
  CREATE_CUSTOM_COMPONENT,
  CreateCustomComponentAction,
  EXTRACT_FORM,
  ExtractFormAction,
  ADD_DETACHED_CUSTOM_COMPONENT,
  AddDetachedCustomComponentAction,
  ComponentPayload,
  AddComponentSetAction,
  ADD_COMPONENT_SET,
  ADD_AND_MOVE_COMPONENTS_INTO,
  AddAndMoveComponentsIntoAction,
  MOVE_COMPONENT,
  MoveComponentAction,
  ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO,
  AddMultiplesAndMoveComponentsIntoAction
} from '../actions/root';
import { COMPONENT_TYPES } from '../../exocode_components';
import { LayoutComponent, MoveComponentPayload } from 'modules/designer/types';
import { CHANGE_LAYOUT, ChangeLayoutAction } from '../actions/views';
import { Component, ComponentState } from 'react';
import { GridColumnData } from '../../exocode_components/grid';

type ComponentsActions =
  | SetComponentsAction
  | ChangeComponentPropertyAction
  | ChangeComponentStyleAction
  | AddComponentAction
  | DeleteComponentAction
  | AddCustomComponentAction
  | AddDetachedCustomComponentAction
  | CreateCustomComponentAction
  | ChangeLayoutAction
  | AddComponentEventAction
  | DeleteComponentEventAction
  | ExtractFormAction
  | DuplicateComponentAction
  | ChangeCustomComponentViewPropertyAction
  | AddComponentSetAction
  | AddAndMoveComponentsIntoAction
  | MoveComponentAction
  | AddMultiplesAndMoveComponentsIntoAction
  | ChangeComponentPropertiesAction
  | ChangeAllComponentPropertiesAction;

export const componentsReducer = (
  state: ComponentsState = initialState.components,
  action: ComponentsActions
): ComponentsState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_COMPONENTS:
        return doSetComponents(draft, action);
      case ADD_COMPONENT:
        return doAddComponent(draft, action);
      case CHANGE_COMPONENT_PROPERTY:
        return doChangeComponentProperty(draft, action);
      case CHANGE_COMPONENT_STYLE:
        return doChangeComponentStyle(draft, action);
      case DELETE_COMPONENT:
        return doDeleteComponent(draft, action);
      case ADD_CUSTOM_COMPONENT:
        return doAddCustomComponent(draft, action);
      case ADD_DETACHED_CUSTOM_COMPONENT:
        return doAddDetachedCustomComponent(draft, action);
      case CREATE_CUSTOM_COMPONENT:
        return doCreateCustomComponent(draft, action);
      case CHANGE_LAYOUT:
        return doChangeLayout(draft, action);
      case ADD_COMPONENT_EVENT:
        return doAddEvent(draft, action);
      case DELETE_COMPONENT_EVENT:
        return doDeleteEvent(draft, action);
      case EXTRACT_FORM:
        return doExtractForm(draft, action);
      // Custom components.
      case CHANGE_CUSTOM_COMPONENT_VIEW_PROPERTY:
        return doChangeCustomComponentViewProperty(draft, action);
      case COPY_COMPONENT:
        return doDuplicateComponent(draft, action);
      case ADD_COMPONENT_SET:
        return doAddComponentSet(draft, action);
      case ADD_AND_MOVE_COMPONENTS_INTO:
        return doAddAndMoveComponentsInto(draft, action);
      case MOVE_COMPONENT:
        return doDeleteAutoContainer(draft, action);
      case ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO:
        return doAddMultiplesAndMoveComponentsInto(draft, action);
      case CHANGE_COMPONENT_PROPERTIES:
        return doChangeComponentProperties(draft, action);
      case CHANGE_ALL_COMPONENT_PROPERTIES:
        return doChangeAllComponentProperties(draft, action);
      default:
        return draft;
    }
  });
};

export function doSetComponents(
  state: ComponentsState,
  action: SetComponentsAction
): ComponentsState {
  return action.payload.components;
}

export function doAddComponent(
  state: ComponentsState,
  action: AddComponentAction
): ComponentsState {
  const { component } = action.payload;
  state[component.uuid] = component;
  return state;
}

export function doAddComponentSet(
  state: ComponentsState,
  action: AddComponentSetAction
): ComponentsState {
  const { componentSet } = action.payload;
  componentSet.forEach(({ component }: ComponentPayload) => {
    state[component.uuid] = component;
  });
  return state;
}

export function doChangeComponentProperty(
  state: ComponentsState,
  action: ChangeComponentPropertyAction
): ComponentsState {
  state[action.payload.uuid].data[action.payload.key] = action.payload.value;
  return state;
}

export function doChangeCustomComponentViewProperty(
  state: ComponentsState,
  action: ChangeCustomComponentViewPropertyAction
): ComponentsState {
  state[action.payload.uuid].data[action.payload.key] = action.payload.value;
  return state;
}

export function doChangeComponentStyle(
  state: ComponentsState,
  action: ChangeComponentStyleAction
): ComponentsState {
  state[action.payload.uuid].styles[action.payload.key] = action.payload.value;
  return state;
}

export function doDeleteComponent(
  state: ComponentsState,
  action: DeleteComponentAction
): ComponentsState {
  const componentID = action.payload.uuid;
  const parentID = action.payload.parentUUID;
  const section = action.payload.section;

  delete state[componentID];
  if (parentID && section) {
    state[parentID].data.columns = state[parentID].data.columns.filter(
      (column: GridColumnData) => column.title !== section
    );
  }
  if (parentID && state[parentID]?.data.autoGenerated && action.payload.emptyParent) {
    delete state[parentID];
  }
  return state;
}

export function doAddCustomComponent(
  state: ComponentsState,
  action: AddCustomComponentAction
): ComponentsState {
  // Components and links for the custom component.
  const components = action.payload.customComponentsChildren.components;
  // The new uuid for rendering purposes.
  const newCustomComponentUUID = action.payload.newCustomComponentUUID;
  // The custom component original uuid.
  const uuid = action.payload.uuid;

  // Add all components/replace uuids.
  for (const componentId of Object.keys(components)) {
    state[componentId] = components[componentId];
  }

  // Add the custom component.
  const component: LayoutComponent = {
    uuid: newCustomComponentUUID,
    type: COMPONENT_TYPES.CUSTOM,
    custom_uuid: uuid
  };
  state[newCustomComponentUUID] = component;

  return state;
}

export function doAddDetachedCustomComponent(
  state: ComponentsState,
  action: AddDetachedCustomComponentAction
): ComponentsState {
  const customComponents = action.payload.customComponentsState.components;
  const oldIdsNewIds = action.payload.oldIdsNewIds;

  for (const componentId of Object.keys(customComponents)) {
    const newId = oldIdsNewIds[componentId];
    const newComponent: LayoutComponent = { ...customComponents[componentId], custom_uuid: '' };
    newComponent.uuid = newId;
    state[newId] = newComponent;
  }

  return state;
}

/**
 * Update the ComponentsState: add a new entry for the custom component.
 */
export function doCreateCustomComponent(
  state: ComponentsState,
  action: CreateCustomComponentAction
): ComponentsState {
  const customComponentUUID = action.payload.customComponentUUID;

  const component: LayoutComponent = {
    uuid: customComponentUUID,
    type: COMPONENT_TYPES.CUSTOM,
    custom_uuid: action.payload.custom_uuid,
    data: {
      name: action.payload.name,
      description: action.payload.description
    }
  };
  state[customComponentUUID] = component;

  return state;
}

// Replace the entire component state.
export function doChangeLayout(
  state: ComponentsState,
  action: ChangeLayoutAction
): ComponentsState {
  if (action.payload.layout) {
    const components = action.payload.layout.components;
    // Add all components/replace uuids.
    for (const componentId of Object.keys(components)) {
      state[componentId] = components[componentId];
    }
  }

  return state;
}

// Add an event to component state.
export function doAddEvent(
  state: ComponentsState,
  action: AddComponentEventAction
): ComponentsState {
  if (state[action.payload.component] && state[action.payload.component].events) {
    state[action.payload.component].events[action.payload.event] = action.payload.function;
  } else {
    state[action.payload.component].events = {
      [action.payload.event]: action.payload.function
    };
  }
  return state;
}

// Delete an event to component state.
export function doDeleteEvent(
  state: ComponentsState,
  action: DeleteComponentEventAction
): ComponentsState {
  delete state[action.payload.component].events[action.payload.event];
  return state;
}

export function doExtractForm(state: ComponentsState, action: ExtractFormAction): ComponentsState {
  action.payload.components.forEach((component) => {
    state[component.uuid] = component;
  });

  delete state[action.payload.form];
  return state;
}

export function doDuplicateComponent(
  state: ComponentsState,
  action: DuplicateComponentAction
): ComponentsState {
  const newIds = action.payload.oldIdsNewIds;
  for (const oldId of Object.keys(newIds)) {
    const oldComponent = state[oldId];
    const newComponent: LayoutComponent = {
      uuid: newIds[oldComponent.uuid],
      type: oldComponent.type,
      data: { ...oldComponent.data },
      styles: { ...oldComponent.styles },
      custom_uuid: oldComponent.custom_uuid,
      events: { ...oldComponent.events },
      name: oldComponent.name,
      description: oldComponent.description
    };
    state[newIds[oldComponent.uuid]] = newComponent;
  }
  return state;
}

export function doAddAndMoveComponentsInto(
  state: ComponentsState,
  action: AddAndMoveComponentsIntoAction
): ComponentsState {
  const { componentToAdd, componentsToMove } = action.payload;
  const { component } = componentToAdd;
  state[component.uuid] = component;

  for (const moveComponent of componentsToMove) {
    const source = moveComponent.source;
    if (source.deletePastParent) {
      delete state[source.parentUUID];
    }
  }
  return state;
}

export function doDeleteAutoContainer(
  state: ComponentsState,
  action: MoveComponentAction
): ComponentsState {
  const source = action.payload.source;
  if (source.deletePastParent) {
    delete state[source.parentUUID];
  }
  return state;
}

export function doAddMultiplesAndMoveComponentsInto(
  state: ComponentsState,
  action: AddMultiplesAndMoveComponentsIntoAction
): ComponentsState {
  action.payload.componentsToAdd.forEach(({ component }: ComponentPayload) => {
    state[component.uuid] = component;
  });

  return state;
}

export function doChangeComponentProperties(
  state: ComponentsState,
  action: ChangeComponentPropertiesAction
): ComponentsState {
  action.payload.forEach(({ uuid, key, value }) => {
    if (!state[uuid]) return;
    state[uuid].data[key] = value;
  });
  return state;
}

export function doChangeAllComponentProperties(
  state: ComponentState,
  action: ChangeAllComponentPropertiesAction
): ComponentsState {
  // data
  action.payload.data.forEach(({ uuid, key, value }) => {
    state[uuid].data[key] = value;
  });

  // style
  action.payload.style.forEach(({ uuid, key, value }) => {
    state[uuid].styles[key] = value;
  });

  return state;
}
