import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ListGroup } from 'react-bootstrap';
import { AppRoleService } from 'modules/logic_builder/services';
import { ExoRole } from 'modules/logic_builder/types';
import RoleModal from './modal';
import Icon from 'web_ui/icon';
import EmptyMessage from 'web_ui/empty';

import styles from './styles.module.css';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

function AppRoles() {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editRole, setEditRole] = React.useState<ExoRole>();
  const [roles, setRoles] = React.useState<ExoRole[]>([]);
  const { app_id } = useParams();
  const [loading, setLoading] = React.useState<boolean>(true);

  const { t } = useTranslation();

  const getRoles = React.useCallback(async () => {
    try {
      if (app_id) {
        const rolesResponse = await AppRoleService.getRoles(app_id);
        setRoles(rolesResponse);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [app_id]);

  React.useEffect(() => {
    getRoles();
  }, [getRoles]);

  const onClose = () => {
    setOpenModal(false);
    setEditRole(undefined);
    getRoles();
  };

  const handleEditRole = (role: ExoRole) => {
    setEditRole(role);
    setOpenModal(true);
  };

  return (
    <>
      <div className={styles.containerButton}>
        <HelpPopover
          helpBoxProps={{
            title: t('appResume.module.New')!
          }}
          placement="top"
        >
          <Button
            id={'createButton'}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <Icon iconName="plus"></Icon>
          </Button>
        </HelpPopover>
      </div>
      <div
        className={`border bg-body ${styles.rolesContainer}  ${styles.moduleList} ${
          roles.length < 1 ? styles.alignVertical : ''
        }`}
      >
        {loading === false ? (
          <>
            {roles.length > 0 && (
              <ListGroup>
                {roles.map((role) => {
                  return (
                    <ListGroup.Item className="border-0 p-0" key={role.id} variant="body" action>
                      <div className={`text-body-emphasis ${styles.roleItem}`} id={`${role.name}`}>
                        {role.name}
                        <HelpPopover
                          helpBoxProps={{
                            title: t('appResume.roles.EditRole')!
                          }}
                          placement="top"
                        >
                          <Button variant="body" onClick={() => handleEditRole(role)}>
                            <Icon iconName="pen-to-square" />
                          </Button>
                        </HelpPopover>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            )}
            {roles.length <= 0 && (
              <div id="bodyMessage" className="w-100 h-100">
                <EmptyMessage
                  message={t('appResume.roles.NoRoles')}
                  icon="exclamation"
                  actionMessage={t('appResume.roles.CreateNewRole') ?? ''}
                  linkAction={() => setOpenModal(true)}
                />
              </div>
            )}
          </>
        ) : (
          <div className="w-100 h-100 d-flex justify-content-center">
            <div className={`fa-3x ${styles.spinner}`}>
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          </div>
        )}
      </div>

      <RoleModal
        showModal={openModal}
        onClose={onClose}
        editRole={editRole !== undefined}
        role={editRole}
        roles={roles}
      />
    </>
  );
}
export default AppRoles;
