import TableActionsDropdown from '../../table_actions_dropdown';
import { Button } from 'react-bootstrap';
import { FaMagic } from 'react-icons/fa';
import React, { useContext } from 'react';
import { TableUUID } from '../../../../types';
import { useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../web_ui/icon';
import styles from './styles.module.css';
import { useQuery } from 'hooks/useQuery';
import { AppContext } from 'modules/project/store/app_context';

type TableActionsBarProps = {
  tableID: TableUUID;
  showAdvancedEditor: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function TableActionsBar({ tableID, showAdvancedEditor }: TableActionsBarProps) {
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const { app_id, module_id } = useParams();
  const appInfo = useContext(AppContext).projectInformation;
  const navigate = useNavigate();
  const queryParameters = useQuery();
  const { t } = useTranslation();

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  return (
    <div className="d-flex justify-content-between">
      {appInfo?.has_backend && (
        <div className="d-flex gap-2">
          <TableActionsDropdown
            tableID={tableID}
            disabled={tableID && tables[tableID] && tables[tableID].content.data.native}
          />
          {appInfo.has_backend && (
            <Button
              id="assistantButton"
              onClick={(e) => {
                navigate(
                  `/app/${app_id}/module/${module_id}/logic/automation-wizard?entity=${tableID}${
                    isFromVsCodeExt() ? '&vscode=true' : ''
                  }`
                );
              }}
              className={`d-flex align-items-center ${styles.button}`}
              disabled={tableID && tables[tableID] && tables[tableID].content.data.native}
            >
              <FaMagic
                className={styles.icons}
                size={13}
                color="white"
                style={{ marginRight: 4 }}
              />
              {t('CrudAssistant')}
            </Button>
          )}
        </div>
      )}
      <div className="d-flex gap-2">
        <Button
          id="advancedButton"
          onClick={() => showAdvancedEditor(true)}
          className={`d-flex align-items-center ${styles.button}`}
          disabled={tableID && tables[tableID] && tables[tableID].content.data.native}
        >
          <span>
            <Icon margin={'0 5px 0 0'} iconName={'sliders'} />
            {t('modeler.Details')}
          </span>
        </Button>
      </div>
    </div>
  );
}
