import React, { useState } from 'react';
import LanguagesList from './components/languages_list';
import TranslationsTable from './components/translations_table';
import { useAppInfo } from '../../app_tabs_navbar';

export const LANGUAGES_PAGE_SIZE = 8;

function LanguageModules() {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const { appInfo } = useAppInfo();

  return (
    <>
      <LanguagesList
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        mainLanguageId={appInfo?.mainLanguageId ?? ''}
      />
      <div>
        <TranslationsTable
          selectedLanguage={selectedLanguage}
          mainLanguageId={appInfo?.mainLanguageId ?? ''}
        />
      </div>
    </>
  );
}

export default LanguageModules;
