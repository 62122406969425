import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller } from 'modules/logic_builder/types';
import { ControllersService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { useParams } from 'react-router-dom';
import { Entity } from 'routes/automation_wizard/types';
import { CrudData } from 'routes/automation_wizard/components/wizard_steps/crud';
import { v4 } from 'uuid';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';
import { Table } from '../../../../modeler/types';
import {
  InputErrorCode,
  validateDescriptionInputs,
  validatePathInput,
  validateControllerName
} from 'utils/inputValidation';
import { EMPTY_CONTROLLER } from '../controller_editor_dialog';

type ControllerCreatorDialogProps = {
  show: boolean;
  onClose: () => void;
  preSelectedEntity?: Entity;
  onChange?: (data: CrudData) => void;
  crudData?: CrudData;
  nameOfControllerCreated?: (val: string) => void;
  tables?: Table[];
  onCreate?: (controller: Controller) => void;
  loading?: (val: boolean) => void;
};

export const validNameRegex = /^[a-zA-Z0-9]{1,100}$/;
export const validPathRegex = /^\/[a-z0-9A-Z{}]{0,1}[{}a-zA-Z0-9-/]*$/;
export const invalidPathCharSequence = /^.*(\/\/|\/-|--|-\/).*$/;
export const invalidPathEnding = /.+[-/]$/;

export function validatePath(path: string) {
  if (!path) return false;
  return (
    !validPathRegex.test(path) || invalidPathCharSequence.test(path) || invalidPathEnding.test(path)
  );
}

export function validateName(name: string) {
  if (!name) return true;
  return !validNameRegex.test(name);
}

export const INPUT_NAME_ERROR_MESSAGES: Record<InputErrorCode, string> = {
  EMPTY_STRING: 'inputValidationErrorMessages.TagNameEmptyString',
  CONTAIN_SPACES: 'inputValidationErrorMessages.TagNameContainSpaces',
  CONTAIN_SPECIAL_CHARACTERS: 'inputValidationErrorMessages.TagNameSpecialCharacters',
  EXCEEDS_MAX_LENGTH: 'inputValidationErrorMessages.TagNameExceedsMaxLength',
  STARTS_WITH: '',
  CONTAIN_FOLDER_SPECIAL_CHARACTERS: ''
};

export const INPUT_DESCRIPTION_ERROR_MESSAGES: Record<InputErrorCode, string> = {
  EMPTY_STRING: 'inputValidationErrorMessages.TagDescriptionEmptyString',
  CONTAIN_SPACES: '',
  CONTAIN_SPECIAL_CHARACTERS: '',
  EXCEEDS_MAX_LENGTH: 'inputValidationErrorMessages.TagDescriptionExceedsMaxLength',
  STARTS_WITH: '',
  CONTAIN_FOLDER_SPECIAL_CHARACTERS: ''
};

export const INPUT_PATH_ERROR_MESSAGES: Record<InputErrorCode, string> = {
  EMPTY_STRING: 'inputValidationErrorMessages.TagPathEmptyString',
  CONTAIN_SPACES: 'inputValidationErrorMessages.TagPathContainSpaces',
  CONTAIN_SPECIAL_CHARACTERS: 'inputValidationErrorMessages.TagPathSpecialCharacters',
  EXCEEDS_MAX_LENGTH: 'inputValidationErrorMessages.TagPathExceedsMaxLength',
  STARTS_WITH: '',
  CONTAIN_FOLDER_SPECIAL_CHARACTERS: ''
};

export const checkNameIsValid = (input: string): string => {
  const { code, valid } = validateControllerName(input, 64);
  if (!code && !valid) {
    return 'inputValidationErrorMessages.GenericErrorMessage';
  }
  return INPUT_NAME_ERROR_MESSAGES[(code ?? '') as InputErrorCode] ?? '';
};

export const checkPathIsValid = (input: string): string => {
  const { code, valid } = validatePathInput(input, 255);
  if (!code && !valid) {
    return 'inputValidationErrorMessages.GenericErrorMessage';
  }
  return INPUT_PATH_ERROR_MESSAGES[(code ?? '') as InputErrorCode] ?? '';
};

export const checkDescriptionIsValid = (input: string): string => {
  const { code, valid } = validateDescriptionInputs(input, 255, false);
  if (!code && !valid) {
    return 'inputValidationErrorMessages.GenericErrorMessage';
  }
  return INPUT_DESCRIPTION_ERROR_MESSAGES[(code ?? '') as InputErrorCode] ?? '';
};

export function ControllerCreatorDialog(props: ControllerCreatorDialogProps) {
  const { t } = useTranslation();
  const { module_id } = useParams();

  const [newController, setNewController] = useState<Controller>({ ...EMPTY_CONTROLLER });
  const [hasChangedPath, setHasChangedPath] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});

  const { fetchControllers } = useContext(LogicBuilderContext);

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (isLoading) return;
    props.loading && props.loading(true);
    try {
      setIsLoading(true);

      if (!module_id || !newController) return;
      if (!validateInputs()) return;

      if (props.crudData && props.onChange) {
        const moduleControllers = await ControllersService.getControllers(
          module_id,
          newController.name
        );
        if (moduleControllers.some((controller) => controller.name === newController.name)) {
          showErrorPopup(t('automation.errorMessageController'));
          return;
        }
        // Exclude other new controller (since CRUD only creates one controller).
        const copyData = props.crudData.controllers.filter((controller) => controller.new !== true);
        const newOne = {
          description: newController.description,
          name: newController.name,
          path: newController.path,
          uuid: newController.uuid ? newController.uuid : v4(),
          entityUuid: newController.entityUuid,
          new: true,
          native: false
        };
        copyData.push(newOne);
        const crud: CrudData = {
          ...props.crudData,
          controllers: copyData,
          selectedController: newOne
        };
        props.onChange(crud);
        props.onClose();
      } else {
        await ControllersService.createController(module_id, newController)
          .then((controller) => {
            fetchControllers(module_id);
            props.onCreate && props.onCreate(controller);
            props.onClose();
          })
          .catch(() => {
            showErrorPopup(t('automation.errorMessageController'));
          })
          .finally(() => {
            if (props.nameOfControllerCreated) {
              props.nameOfControllerCreated(newController.name);
            }
          });
      }
    } finally {
      setIsLoading(false);
      props.loading && props.loading(false);
    }
  }

  function handleNameChange(e: any) {
    const name = e.target.value ?? '';
    let path = newController?.path ?? '';
    if (!hasChangedPath) {
      path = convertNameToPath(name);
    }
    setNewController({
      ...newController,
      name: name,
      path: path
    } as Controller);
    const nextErrorMessages = {
      ...errorMessages,
      name: checkNameIsValid(name),
      path: checkPathIsValid(path)
    };
    setErrorMessages(nextErrorMessages);
  }

  function convertNameToPath(name: string): string {
    return '/' + name.toLowerCase().replaceAll(' ', '');
  }

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setNewController({ ...EMPTY_CONTROLLER });
    setErrorMessages({});
    setHasChangedPath(false);
    setIsLoading(false);
    setAlertMessage('');
    setAlertVariant('success');
  }, [props.show]);

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateInputs = (): boolean => {
    const nextErrorMessages = {
      ...errorMessages,
      name: checkNameIsValid(newController.name),
      path: checkPathIsValid(newController.path),
      description: checkDescriptionIsValid(newController.description)
    };
    setErrorMessages(nextErrorMessages);
    for (const errorMessage of Object.values(nextErrorMessages)) {
      if (errorMessage) {
        return false;
      }
    }
    return true;
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onClose();
      }}
      centered
      scrollable
    >
      <Form onSubmit={onSubmit} className={styles.formWrapper} id="formModal">
        <Modal.Header closeButton>
          <Modal.Title>{t('logicBuilder.ControllerCreator')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
          {alertMessage && (
            <Modal
              show={alertMessage ? true : false}
              className={styles.containerModal}
              centered={false}
            >
              <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
            </Modal>
          )}
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{t('logicBuilder.newController.name')}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Controller"
              value={newController.name ?? ''}
              isInvalid={!!errorMessages.name}
              onChange={(e) => handleNameChange(e)}
              autoFocus
            />
            <Form.Control.Feedback type="invalid">{t(errorMessages.name)}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPath">
            <Form.Label>{t('logicBuilder.newController.path')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('logicBuilder./path')}`}
              value={newController.path ?? ''}
              isInvalid={!!errorMessages.path}
              onChange={(e) => {
                setNewController({ ...newController, path: e.target.value } as Controller);
                setHasChangedPath(e.target.value.length > 0);
                const nextErrorMessages = {
                  ...errorMessages,
                  path: checkPathIsValid(e.target.value)
                };
                setErrorMessages(nextErrorMessages);
              }}
            />
            <Form.Control.Feedback type="invalid">{t(errorMessages.path)}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>{t('logicBuilder.newController.description')}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={t('logicBuilder.newController.DescriptionPlaceholder') || ''}
              rows={2}
              value={newController.description ?? ''}
              isInvalid={!!errorMessages.description}
              onChange={(e) => {
                setNewController({ ...newController, description: e.target.value } as Controller);
                const nextErrorMessages = {
                  ...errorMessages,
                  description: checkDescriptionIsValid(e.target.value)
                };
                setErrorMessages(nextErrorMessages);
              }}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t(errorMessages.description)}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formEntity">
            <Form.Label style={{ marginTop: 10 }}>{t('modeler.Entity')}</Form.Label>
            <Form.Select
              style={{ marginTop: 10 }}
              disabled={!!props.preSelectedEntity}
              onChange={(e) =>
                setNewController({
                  ...newController,
                  entityUuid: e.target.value
                } as Controller)
              }
            >
              {props.preSelectedEntity && (
                <option
                  key={props.preSelectedEntity.entityUuid}
                  value={props.preSelectedEntity.entityUuid}
                  selected
                >
                  {props.preSelectedEntity.entityName}
                </option>
              )}
              {props.tables && <option value="">---</option>}
              {props.tables?.map((entity) => (
                <option key={entity.uuid} value={entity.uuid}>
                  {entity.content.data.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
            {t('logicBuilder.newController.cancel')}
          </Button>
          <Button id="saveButton" variant="primary" type="submit">
            {t('logicBuilder.newController.Create')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
