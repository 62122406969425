import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { ObjectsItemsState } from '../types/object_items';
import { initialState } from '../initial_empty_state';

type ObjectItemsActions = PayloadAction;

export const objectItemsReducer = (
  state: ObjectsItemsState = initialState.objects_items,
  action: ObjectItemsActions
): ObjectsItemsState => {
  return produce(state, (draft: ObjectsItemsState) => {
    switch (action.type) {
      default:
        return state;
    }
  });
};
