import React, { useState } from 'react';
import { ControlProps } from '..';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputGroup, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { ITableAction } from 'modules/designer/studio/exocode_components/data_table';
import Icon from 'web_ui/icon';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { IconList, IconPicker } from 'packages/react-fa-icon-picker';
import { BootstrapColors } from '../DataTableSource/types';
import { ControlRequirements } from '../requirement_messages/ControlRequirements';
import { CONTROL_REQUIREMENT_TYPES, ControlRequirementsTypes } from '../requirement_messages';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';
import CreateFunctionDialog from 'modules/designer/studio/components/create_function_dialog';
import { useParams } from 'react-router-dom';

function ActionControl(props: ControlProps) {
  const [actionFunction, setActionFunction] = useState('');
  const [actionTitle, setActionTitle] = useState('');
  const [actionIcon, setActionIcon] = useState('');
  const [actionVariant, setActionVariant] = useState('');
  const UIFunctions = useSelector((state: InterfaceStudioState) => state.functions);
  const [showCreateFunctionDialog, setShowCreateFunctionDialog] = useState(false);
  const { view_id } = useParams();
  const { t } = useTranslation();

  function handleAddAction() {
    if (!actionFunction || actionFunction === '') return;
    const actions = props.value;
    const newAction = {
      title: actionTitle,
      icon: actionIcon,
      variant: actionVariant,
      function: actionFunction
    };

    const newActions = actions ? [...actions, newAction] : [newAction];

    if (props.id) {
      props.onChange && props.onChange(newActions, props.id);
    } else {
      props.onChange?.(newActions, 'actions');
    }
    formReset();
  }

  function handleChangeAction(
    key: keyof ITableAction,
    value: string,
    action: ITableAction,
    index: number
  ) {
    const updatedAction = { ...action, [key]: value };

    // if it has id get from value
    let newActions: any = [];
    if (props.id) {
      newActions = [...props.value.slice(0, index), updatedAction, ...props.value.slice(index + 1)];
    } else {
      newActions = [
        ...props.value.actions.slice(0, index),
        updatedAction,
        ...props.value.actions.slice(index + 1)
      ];
    }

    if (props.id) {
      props.onChange && props.onChange(newActions, props.id);
    } else {
      props.onChange && props.onChange(newActions, 'actions');
    }
  }

  function handleDeleteAction(index: number) {
    let newActions = [];
    if (props.id) {
      newActions = [...props.value.slice(0, index), ...props.value.slice(index + 1)];
    } else {
      newActions = [
        ...props.value.actions.slice(0, index),
        ...props.value.actions.slice(index + 1)
      ];
    }

    if (props.id) {
      props.onChange && props.onChange(newActions, props.id);
    } else {
      props.onChange && props.onChange(newActions, 'actions');
    }
  }

  function formReset() {
    setActionFunction('');
    setActionTitle('');
    setActionIcon('');
    setActionVariant('');
  }

  const handleShowCreateFunctionDialog = (): void => {
    setShowCreateFunctionDialog(true);
  };

  return (
    <>
      <div className="mb-3 pb-4 border-bottom text-body-secondary">
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <div style={{ display: 'flex' }}>
            <label className="position-relative mb-1 text-body">{props.label}</label>
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.ActionTitle') ||
                  'designer.right_side.controls.ActionTitle',
                description:
                  t('designer.right_side.controls.ActionDescription') ||
                  'designer.right_side.controls.ActionDescription',
                note: [t('designer.right_side.controls.ActionNote01')]
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          </div>
        </div>

        <div></div>

        {Object.values(UIFunctions).length === 0 &&
        (!props.value || !props.value.actions || props.value.actions.length === 0) ? (
          <ControlRequirements
            onClick={handleShowCreateFunctionDialog}
            controlRequirementType={CONTROL_REQUIREMENT_TYPES.FUNCTION as ControlRequirementsTypes}
          />
        ) : (
          <ListGroup className="d-flex">
            <OverlayTrigger
              trigger="click"
              placement="left"
              rootClose
              overlay={
                <Popover id="popover-positioned-left">
                  <Popover.Body>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        {t('designer.right_side.controls.TitleAction')}
                      </InputGroup.Text>
                      <Form.Control
                        value={actionTitle}
                        onChange={(e) => setActionTitle(e.target.value)}
                      />
                    </InputGroup>

                    <div className="align-items-center">
                      <div
                        className="border d-flex p-1 rounded align-items-center"
                        style={{ width: 'fit-content' }}
                      >
                        {' '}
                        <label className="mb-1">
                          {t('designer.right_side.controls.ActionIcon')}
                        </label>
                        <IconPicker
                          value={(props.value && props.value.icon) || 'FaRegCaretSquareDown'}
                          onChange={(e) => setActionIcon(e)}
                          containerStyles={{ left: '-210px', display: 'flex', top: '-260px' }}
                          buttonIconStyles={{
                            color: 'var(--bs-secondary-color)!important',
                            border: '0',
                            fontSize: '1.25rem',
                            display: 'flex'
                          }}
                          buttonStyles={{
                            border: 0,
                            padding: '0',
                            width: '1.5rem',
                            height: '1.5rem',
                            minHeight: '0',
                            fontSize: '1rem'
                          }}
                          searchInputStyles={{
                            display: 'flex'
                          }}
                        />
                        <span
                          className="ms-2"
                          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                        >
                          {actionIcon}
                        </span>
                      </div>
                    </div>

                    <InputGroup size="sm" className="mt-2">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        {t('designer.right_side.controls.ActionFunction')}
                      </InputGroup.Text>
                      <Form.Select
                        value={actionFunction}
                        onChange={async (e) => {
                          e.target.value == 'addFunction'
                            ? handleShowCreateFunctionDialog()
                            : setActionFunction(e.target.value);
                        }}
                      >
                        {actionFunction === '' && <option value="">---</option>}
                        <option value="addFunction" style={{ color: '#446089' }}>
                          {t('designer.right_side.controls.AddFunciton')}
                        </option>
                        {UIFunctions &&
                          Object.keys(UIFunctions)?.map((functionKey) => (
                            <option
                              key={UIFunctions[functionKey].name}
                              value={UIFunctions[functionKey].uuid}
                            >
                              {UIFunctions[functionKey].name}
                            </option>
                          ))}
                      </Form.Select>
                    </InputGroup>

                    <InputGroup size="sm" className="mt-2 mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        {t('designer.right_side.controls.ActionVariant')}
                      </InputGroup.Text>
                      <Form.Select
                        value={actionVariant}
                        onChange={async (e) => setActionVariant(e.target.value)}
                      >
                        <option value="">---</option>

                        {BootstrapColors &&
                          Object.keys(BootstrapColors)?.map((variant) => (
                            <option key={variant} value={variant}>
                              {variant}
                            </option>
                          ))}
                      </Form.Select>
                    </InputGroup>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {/* Botão para adicionar função */}
                      {/* <Button
                        onClick={handleShowCreateFunctionDialog}
                        className="btn-secondary btn-sm"
                        style={{ height: '2rem', marginTop: '0.25rem' }}
                      >
                        {t('designer.right_side.controls.AddFunciton')}
                      </Button> */}
                      <Button
                        disabled={!actionFunction || actionFunction === '' || actionTitle === ''}
                        onClick={handleAddAction}
                      >
                        {t('designer.right_side.controls.AddAction')}
                      </Button>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <ListGroup.Item action variant="body-secondary" className="text-body-secondary">
                <Icon iconName="plus"></Icon>
                <label className="ms-2">New item</label>
              </ListGroup.Item>
            </OverlayTrigger>

            {props.id ? (
              <>
                {props.value &&
                  props.value.map((action: ITableAction, index: number) => {
                    return (
                      <OverlayTrigger
                        key={index}
                        trigger="click"
                        placement="left"
                        rootClose
                        overlay={
                          <Popover id="popover-positioned-left">
                            <Popover.Body>
                              <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  {t('designer.right_side.controls.TitleAction')}
                                </InputGroup.Text>
                                <Form.Control
                                  value={action.title}
                                  onChange={(e) =>
                                    handleChangeAction('title', e.target.value, action, index)
                                  }
                                />
                              </InputGroup>

                              <div className="align-items-center">
                                <label className="mb-1">
                                  {t('designer.right_side.controls.ActionIcon')}
                                </label>
                                <div
                                  className="border d-flex p-1 rounded align-items-center "
                                  style={{ width: 'fit-content' }}
                                >
                                  <IconPicker
                                    value={(action.icon as IconList) || 'FaRegCaretSquareDown'}
                                    onChange={(e) => handleChangeAction('icon', e, action, index)}
                                    containerStyles={{
                                      left: '-210px',
                                      display: 'flex',
                                      top: '-260px'
                                    }}
                                    buttonIconStyles={{
                                      color: 'var(--bs-secondary-color)!important',
                                      border: '0',
                                      fontSize: '1.25rem',
                                      display: 'flex'
                                    }}
                                    buttonStyles={{
                                      border: 0,
                                      padding: '0',
                                      width: '1.5rem',
                                      height: '1.5rem',
                                      minHeight: '0',
                                      fontSize: '1rem'
                                    }}
                                    searchInputStyles={{
                                      display: 'flex'
                                    }}
                                  />
                                  <span className="ms-2">{action.icon}</span>
                                </div>
                              </div>

                              <InputGroup size="sm" className="mt-2">
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  {t('designer.right_side.controls.ActionFunction')}
                                </InputGroup.Text>
                                <Form.Select
                                  value={action.function}
                                  onChange={async (e) =>
                                    handleChangeAction('function', e.target.value, action, index)
                                  }
                                  required
                                  isInvalid={!action.function}
                                >
                                  <option value="">---</option>

                                  {action.function && !UIFunctions[action.function] && (
                                    <option className="text-danger" value={action.function}>
                                      {t('designer.right_side.controls.errors.NotFound')}
                                    </option>
                                  )}

                                  {UIFunctions &&
                                    Object.keys(UIFunctions)?.map((functionKey) => (
                                      <option
                                        key={UIFunctions[functionKey].name}
                                        value={UIFunctions[functionKey].uuid}
                                      >
                                        {UIFunctions[functionKey].name}
                                      </option>
                                    ))}
                                </Form.Select>
                              </InputGroup>

                              <InputGroup size="sm" className="mt-2 mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  {t('designer.right_side.controls.ActionVariant')}
                                </InputGroup.Text>
                                <Form.Select
                                  value={action.variant}
                                  onChange={async (e) =>
                                    handleChangeAction('variant', e.target.value, action, index)
                                  }
                                >
                                  {action.function === ''}

                                  {BootstrapColors &&
                                    Object.keys(BootstrapColors)?.map((variant) => (
                                      <option key={variant} value={variant}>
                                        {variant}
                                      </option>
                                    ))}
                                </Form.Select>
                              </InputGroup>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <ListGroup.Item
                          action
                          key={index}
                          className="d-flex justify-content-between"
                        >
                          <label className="text-truncate">
                            <MissingMessage
                              type={MissingMessageType.FUNCTION}
                              uuid={action.function}
                            />
                            {action.title}
                          </label>
                          <span onClick={(ev) => handleDeleteAction(index)}>
                            <Icon iconName="trash"></Icon>
                          </span>
                        </ListGroup.Item>
                      </OverlayTrigger>
                    );
                  })}
              </>
            ) : (
              // if it does not have an id keep with actions
              <>
                {props.value &&
                  props.value.actions &&
                  props.value.actions.map((action: ITableAction, index: number) => {
                    return (
                      <OverlayTrigger
                        key={index}
                        trigger="click"
                        placement="left"
                        rootClose
                        overlay={
                          <Popover id="popover-positioned-left">
                            <Popover.Body>
                              <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  {t('designer.right_side.controls.TitleAction')}
                                </InputGroup.Text>
                                <Form.Control
                                  value={action.title}
                                  onChange={(e) =>
                                    handleChangeAction('title', e.target.value, action, index)
                                  }
                                />
                              </InputGroup>

                              <div className="align-items-center">
                                <label className="mb-1">
                                  {t('designer.right_side.controls.ActionIcon')}
                                </label>
                                <div
                                  className="border d-flex p-1 rounded align-items-center "
                                  style={{ width: 'fit-content' }}
                                >
                                  <IconPicker
                                    value={(action.icon as IconList) || 'FaRegCaretSquareDown'}
                                    onChange={(e) => handleChangeAction('icon', e, action, index)}
                                    containerStyles={{
                                      left: '-210px',
                                      display: 'flex',
                                      top: '-260px'
                                    }}
                                    buttonIconStyles={{
                                      color: 'var(--bs-secondary-color)!important',
                                      border: '0',
                                      fontSize: '1.25rem',
                                      display: 'flex'
                                    }}
                                    buttonStyles={{
                                      border: 0,
                                      padding: '0',
                                      width: '1.5rem',
                                      height: '1.5rem',
                                      minHeight: '0',
                                      fontSize: '1rem'
                                    }}
                                    searchInputStyles={{
                                      display: 'flex'
                                    }}
                                  />
                                  <span className="ms-2">{action.icon}</span>
                                </div>
                              </div>

                              <InputGroup size="sm" className="mt-2">
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  {t('designer.right_side.controls.ActionFunction')}
                                </InputGroup.Text>
                                <Form.Select
                                  value={action.function}
                                  onChange={async (e) =>
                                    handleChangeAction('function', e.target.value, action, index)
                                  }
                                  required
                                  isInvalid={!action.function}
                                >
                                  <option value="">---</option>

                                  {action.function && !UIFunctions[action.function] && (
                                    <option className="text-danger" value={action.function}>
                                      {t('designer.right_side.controls.errors.NotFound')}
                                    </option>
                                  )}

                                  {UIFunctions &&
                                    Object.keys(UIFunctions)?.map((functionKey) => (
                                      <option
                                        key={UIFunctions[functionKey].name}
                                        value={UIFunctions[functionKey].uuid}
                                      >
                                        {UIFunctions[functionKey].name}
                                      </option>
                                    ))}
                                </Form.Select>
                              </InputGroup>

                              <InputGroup size="sm" className="mt-2 mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  {t('designer.right_side.controls.ActionVariant')}
                                </InputGroup.Text>
                                <Form.Select
                                  value={action.variant}
                                  onChange={async (e) =>
                                    handleChangeAction('variant', e.target.value, action, index)
                                  }
                                >
                                  {action.function === ''}

                                  {BootstrapColors &&
                                    Object.keys(BootstrapColors)?.map((variant) => (
                                      <option key={variant} value={variant}>
                                        {variant}
                                      </option>
                                    ))}
                                </Form.Select>
                              </InputGroup>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <ListGroup.Item
                          action
                          key={index}
                          className="d-flex justify-content-between"
                        >
                          <label className="text-truncate">
                            <MissingMessage
                              type={MissingMessageType.FUNCTION}
                              uuid={action.function}
                            />
                            {action.title}
                          </label>
                          <span onClick={(ev) => handleDeleteAction(index)}>
                            <Icon iconName="trash"></Icon>
                          </span>
                        </ListGroup.Item>
                      </OverlayTrigger>
                    );
                  })}
              </>
            )}
          </ListGroup>
        )}
      </div>
      {view_id && (
        <CreateFunctionDialog
          view_id={view_id}
          show={showCreateFunctionDialog}
          onClose={() => setShowCreateFunctionDialog(false)}
        />
      )}
    </>
  );
}

export default ActionControl;
