import { ActionsCategoriesIcons } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';

export enum FRONTEND_ACTIONS_TYPES {
  DECLARE_VARIABLE = 'DECLARE_VARIABLE',
  SET_VARIABLE = 'SET_VARIABLE',
  ADD_LIST = 'ADD_LIST',
  REMOVE_LIST = 'REMOVE_LIST',
  CLEAR_LIST = 'CLEAR_LIST',
  BEGIN_CYCLE = 'BEGIN_CYCLE',
  END_CYCLE = 'END_CYCLE',
  BEGIN_IF = 'BEGIN_IF',
  END_IF = 'END_IF',
  BEGIN_ELSE = 'BEGIN_ELSE',
  END_ELSE = 'END_ELSE',
  RUN_FUNCTION = 'RUN_FUNCTION',
  CUSTOM_CODE = 'CUSTOM_CODE',
  RETURN_TYPE = 'RETURN_TYPE',
  REST = 'REST',
  NAVIGATE = 'NAVIGATE',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  EMPTY = 'EMPTY',
  COMMENT = 'COMMENT',
  AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT'
}

export type FrontendActions = keyof typeof FRONTEND_ACTIONS_TYPES;

export enum FRONTEND_ACTIONS_CATEGORIES {
  VARIABLE = 'VARIABLE',
  LIST = 'LIST',
  CYCLE = 'CYCLE',
  FUNCTION = 'FUNCTION',
  CONDITION = 'CONDITION'
}

export type FrontendActionsCategories = keyof typeof FRONTEND_ACTIONS_CATEGORIES;

export const FRONTEND_ACTION_CATEGORY_ICONS: ActionsCategoriesIcons = {
  VARIABLE: 'file-code',
  LIST: 'list',
  CYCLE: 'arrows-rotate',
  FUNCTION: 'cog',
  CONDITION: 'condition'
};
