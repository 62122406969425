import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { ComponentUUID, Message, MessageTypes, ViewUUID } from '../../../types';
import { InterfaceStudioState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Confirmation from 'web_ui/confirmation';
import ConvertCustomModal from '../component_context_menu/convert_component_modal';
import SessionContext from '../../../../auth/store';
import { FONTS } from '../../toolbars/appTheme_toolbar/theme_manager_modal/bootstrap/text_section';
import { getCssDeclarations } from 'utils/cssUtils';

export type ViewportProps = {
  uuid: ViewUUID;
  styles?: Record<string, string | undefined>;
};

export type ConfirmationInfo = {
  message: string;
  confirmationLabel: string;
  cancelLabel: string;
  componentId?: string;
};

export type ConvertCustomComponentInfo = {
  componentUUID: ComponentUUID;
};

function Viewport(props: ViewportProps) {
  const state = useSelector((state: InterfaceStudioState) => state);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState<ConfirmationInfo>();
  const [showConvertCustomModal, setShowConvertCustomModal] = React.useState(false);
  const [convertCustomComponentInfo, setConvertCustomComponentInfo] =
    React.useState<ConvertCustomComponentInfo>();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { app_id, module_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  useEffect(() => {
    const message: Message = {
      type: MessageTypes.PREFERENCES,
      content: session.preferences
    };

    iframeRef.current?.contentWindow?.postMessage(message);
  }, [session.preferences]);

  iframeRef.current?.addEventListener('contextmenu', (event) => {
    event.preventDefault();
    event.stopPropagation();
  });

  useEffect(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) return;

    if (!props.styles) return;

    const iframe = iframeRef.current.contentWindow.document;
    const body = iframe.querySelector('body');
    const head = iframe.querySelector('head');

    if (head) {
      // ['Times', 'Times New Roman'] -> Times|Times+New+Roman
      const fonts = FONTS.join('|').replaceAll(' ', '+');
      let link = iframe.getElementById('font-link') as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.id = 'font-link';
        link.href = `https://fonts.googleapis.com/css?family=${fonts}`;
        head.appendChild(link);
      }

      let style = iframe.getElementById('custom-css') as HTMLStyleElement;
      if (!style) {
        style = document.createElement('style');
        style.type = 'text/css';
        style.id = 'custom-css';
        head.appendChild(style);
      }

      let fullCss = '';
      if (state && state.globalCssClasses) {
        for (const cssClass of state.globalCssClasses) {
          fullCss += `${cssClass.cssName}${cssClass.cssSelector} {
          ${cssClass.cssBlock.replaceAll('"', '')}
        }`;
        }
      }
      style.replaceChildren(document.createTextNode(fullCss));
    }

    if (body) {
      body.style.backgroundImage = props.styles.backgroundImage ?? body.style.backgroundImage;
      body.style.backgroundRepeat = props.styles.backgroundRepeat ?? body.style.backgroundRepeat;
      body.style.backgroundSize = props.styles.backgroundSize ?? body.style.backgroundSize;
    }
  }, [props.styles, state.globalCssClasses]);

  const sendInitialState = useCallback(() => {
    const message: Message = {
      type: MessageTypes.LOAD,
      content: state
    };

    iframeRef.current?.contentWindow?.postMessage(message);
  }, [state]);

  const startListening = useCallback(
    (event: MessageEvent<Message>) => {
      if (event.origin !== window.location.origin) return;

      if (event.data.type === MessageTypes.READY) {
        sendInitialState();
        return;
      }

      if (event.data.type === MessageTypes.ACTION) {
        const action = { ...event.data.content, stopReplay: true };
        dispatch(action);
        return;
      }

      if (event.data.type === MessageTypes.NAVIGATE) {
        navigate(event.data.content);
        return;
      }

      if (event.data.type === MessageTypes.CONFIRMATION) {
        setShowConfirmation(true);
        setConfirmationInfo(event.data.content as ConfirmationInfo);
        return;
      }

      if (event.data.type === MessageTypes.CONVERT_CUSTOM_COMPONENT) {
        setShowConvertCustomModal(true);
        setConvertCustomComponentInfo(event.data.content as ConvertCustomComponentInfo);
        return;
      }
    },
    [dispatch, navigate, sendInitialState]
  );

  const sendConfirmationResult = useCallback((result: boolean, componentId?: string) => {
    const message: Message = {
      type: MessageTypes.CONFIRMATION_RESULT,
      content: { result, componentId }
    };

    iframeRef.current?.contentWindow?.postMessage(message);
    // Send message to window too so the Layers component can listen to it
    window.parent.postMessage(message);
    setShowConfirmation(false);
  }, []);

  useEffect(() => {
    window.addEventListener('message', startListening);

    return () => {
      window.removeEventListener('message', startListening);
    };
  }, []);

  return (
    <>
      <iframe
        ref={iframeRef}
        title="viewport"
        id="viewport"
        src={`/app/${app_id}/module/${module_id}/preview/${props.uuid}?height=${iframeRef.current?.clientHeight}&width=${iframeRef.current?.clientWidth}`}
        style={{ width: '100%', height: '100%' }}
      />

      {convertCustomComponentInfo && (
        <ConvertCustomModal
          show={showConvertCustomModal}
          componentUUID={convertCustomComponentInfo.componentUUID}
          onClose={() => setShowConvertCustomModal(false)}
        />
      )}

      {confirmationInfo && (
        <Confirmation
          show={showConfirmation}
          message={confirmationInfo?.message}
          confirmationLabel={confirmationInfo?.confirmationLabel}
          cancelLabel={confirmationInfo?.cancelLabel}
          onCancel={() => sendConfirmationResult(false)}
          onConfirmation={() => sendConfirmationResult(true, confirmationInfo.componentId)}
          onClose={() => sendConfirmationResult(false)}
        />
      )}
    </>
  );
}

export default Viewport;
