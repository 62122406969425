export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function byteConverter(bytes: number, decimals: number, only?: string) {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (bytes == 0) return '0 Byte';

  if (only === 'MB') return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB';

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + ' ' + SIZES[i];

  return resp;
}

export function dynamicSort(property: any) {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any, b: any) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function validateRegex(pattern: RegExp, str: string): boolean {
  return pattern.test(str);
}

export function toCamelCase(str: string, capitalize?: boolean) {
  const camelCase = str.replace(/^\w|[A-Z]|\b\w|\s+/g, (match, index) => {
    if (/\s+/.test(match)) return ''; // for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
  return capitalize ? camelCase.charAt(0).toUpperCase() + camelCase.slice(1) : camelCase;
}

export function toSnakeCase(str: string) {
  if (!str) return '';
  const matchedString = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  );
  if (!matchedString) return '';
  return matchedString.map((s) => s.toLowerCase()).join('_');
}

export function convertingCSS(val: string, toReturn: string) {
  // console.log(val, toReturn);
  if (val && val.includes('#')) {
    return val;
  }
  switch (val) {
    case 'primary':
      return toReturn;
    case 'info':
      return toReturn;
    case 'danger':
      return toReturn;
    case 'warning':
      return toReturn;
    case 'dark':
      return toReturn;
    case 'success':
      return toReturn;
    case 'light':
      return toReturn;
    case 'secondary':
      return toReturn;
    default:
      return val;
  }
}
