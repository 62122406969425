import React from 'react';

function ToggleModalIcon() {
  return (
    <span className="fa-layers fa-fw text-secondary-emphasis" style={{ whiteSpace: 'nowrap' }}>
      <i className="fas fa-window-restore" style={{ fontSize: '1.2rem' }}></i>
    </span>
  );
}

export default ToggleModalIcon;
