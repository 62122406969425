import React from 'react';
import styles from './styles.module.css';
import { FaFileUpload } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import * as Icons from 'react-icons/fa';

interface FileInputProps {
  icon?: faIconName;
  label?: string;
  buttonText?: string;

  color?: string;
}

export type faIconName = keyof typeof Icons | '';

function FileInput({ icon, label, buttonText, color }: FileInputProps) {
  const Icon = icon ? Icons[icon] : FaFileUpload;

  return (
    <>
      <Icon className={styles.uploadIcon} style={{ color: color ? color : '#027bff' }} />
      <input type="file" style={{ display: 'none' }} />

      <p className={styles.placeholder}>
        {label ? label : 'Drag and drop the file here, or click to select'}
      </p>

      <span className={`${styles.fileName} justify-content-center w-100`}>
        <p style={{ textOverflow: 'ellipsis', maxWidth: '98%', overflow: 'hidden' }}>
          No file chosen...
        </p>
      </span>

      <Button
        className={`${styles.uploadButton} border-0`}
        style={{ backgroundColor: color ? color : '#027bff' }}
      >
        {buttonText ? buttonText : 'Upload file'}
      </Button>
    </>
  );
}

export default FileInput;
