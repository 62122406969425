import React from 'react';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../../store/types/function_editor_state';
import useHandleDisplayTypeName from '../../../../../hooks/useHandleDisplayTypeName';
import { ReturnType } from '../../../../../store/types/functions';

type FunctionParameterProps = {
  uuid: string;
  types: ReturnType[];
};

function FunctionParameter({ uuid, types }: FunctionParameterProps) {
  const parameter = useSelector((state: FunctionEditorState) => state.parameters[uuid]);
  const typeName = useHandleDisplayTypeName(
    {
      type: parameter?.type,
      objectUuid: parameter?.objectUuid,
      enumUuid: parameter?.enumUuid,
      list: parameter?.list
    },
    types
  );

  if (!parameter) return null;

  return <span className={styles.FunctionParameter}>{`${typeName} ${parameter.name}`}</span>;
}

export default FunctionParameter;
