import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { updateAction } from '../../../store/events/actions/update_action';
import Dropdown, { ItemType } from '../../components/dropdown';
import { useTranslation } from 'react-i18next';

function RelationshipInput({
  placeholder,
  label,
  actionUuid,
  dataKey,
  isFromSideBar
}: ActionInputProps) {
  const state = useSelector((state: FunctionEditorState) => state);
  const value = state?.actions[actionUuid]?.data[dataKey];
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = (newValue: any) => {
    dispatch(updateAction(actionUuid, dataKey, newValue));
  };

  const noneRelationship = {
    id: '',
    name: t('PrimaryKey')
  } as ItemType;

  const makeRelationshipItems = () => {
    const relationships = [noneRelationship] as ItemType[];
    if (!state.globals || state.globals.relationships === undefined) {
      return relationships;
    }
    Object.values(
      state.globals && state.globals.relationships ? state.globals.relationships : []
    ).forEach((relationship) => {
      relationships.push({ uuid: relationship.id, name: relationship.name } as ItemType);
    });
    return relationships;
  };

  const relationships = makeRelationshipItems();

  return (
    <Dropdown
      items={relationships}
      placeholder={placeholder}
      label={label}
      value={relationships.find((rel) => rel.uuid === value) ?? noneRelationship}
      handleChange={handleChange}
      isFromSideBar={isFromSideBar}
    />
  );
}

export default RelationshipInput;
