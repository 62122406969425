import { LayoutRepository } from '../repos';
import { Layout } from '../types';

export class LayoutServ {
  async createLayout(moduleId: string, layoutName: string, template: string, folderId: string) {
    return await LayoutRepository.createLayout(moduleId, layoutName, template, folderId);
  }

  async getDefaultLayout(appId: string) {
    return await LayoutRepository.getDefaultLayout(appId);
  }

  async getLayoutsByModule(moduleId: string) {
    return await LayoutRepository.getLayoutsByModule(moduleId);
  }

  async getLayout(layoutId: string) {
    return await LayoutRepository.getLayout(layoutId);
  }

  async getLayoutByApp(appId: string) {
    return await LayoutRepository.getLayoutsByApp(appId);
  }

  async updateLayout(layoutId: string, updatedLayout: Layout) {
    return await LayoutRepository.updateLayout(layoutId, updatedLayout);
  }

  async moveLayout(layoutId: string, moduleId: string, folderId: string) {
    return await LayoutRepository.moveLayout(layoutId, moduleId, folderId);
  }

  async deleteLayout(layoutId: string) {
    return await LayoutRepository.deleteLayout(layoutId);
  }
}
