import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { t } from 'i18next';

export const PROGRESS_BAR_MANIFEST: ComponentManifest = {
  type: 'PROGRESSBAR',
  name: 'Progress Bar',
  descriptionId: 'ProgressBarDescription',
  description: 'Custom progress bar to visualize a loading state.',
  icon: 'fa-sharp fa-solid fa-spinner',
  group: ComponentGroups['STATUS'],
  previewHeight: 20,
  previewWidth: 200,
  properties: [
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'Variable',
      key: 'variable',
      tooltip: 'designer.right_side.controls.components.progressBar.visible'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'ProgressBarAnimated',
      key: 'isAnimated',
      tooltip: 'designer.right_side.controls.components.progressBar.animated'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'ProgressBarLabel',
      key: 'hasLabel',
      tooltip: 'designer.right_side.controls.components.progressBar.hasLabel'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Background',
      key: 'background',
      controlOptions: [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark'
      ],
      tooltip: 'designer.right_side.controls.components.progressBar.background'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.progressBar.fontSize'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
