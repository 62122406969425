import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { FunctionExtended, FunctionType } from 'modules/logic_builder/types';
import { CrudData } from 'routes/automation_wizard/components/wizard_steps/crud';
import { FunctionService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';

import styles from './styles.module.css';
import { useQuery } from 'hooks/useQuery';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Confirmation from 'web_ui/confirmation';
import useSession from 'hooks/useSession';
import { useState } from 'react';
import CodeEditorModal from '../../../../web_ui/code_editor_modal';
import { CodePreviewType } from '../../../../web_ui/code_editor_modal/editor';

type FunctionInstaceProps = {
  serviceUuid: string;
  // Apply custom styles.
  className?: string;
  cursor?: string;
  functionInstance: FunctionExtended | FunctionType;
  crudData?: CrudData;
  onlyOpenWindow?: boolean;
};
export default function FunctionInstanceNew(props: FunctionInstaceProps) {
  const { t } = useTranslation();
  const queryParameters = useQuery();
  const navigate = useNavigate();
  const { app_id, module_id, service_id } = useParams();
  const [returnNameType, setReturnNameType] = React.useState<string>('');
  const [functionName, setFunctionName] = React.useState<string>('');
  const [functionParameters, setFunctionParameters] = React.useState<string>('');
  const [previewFunctionUuid, setPreviewFunctionUuid] = useState<string>('');
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const { fetchFunctions } = React.useContext(LogicBuilderContext);
  const [session] = useSession();
  const [mouseHovering, setMouseHovering] = useState<boolean>(false);

  const buildFunctionSignature = React.useCallback(() => {
    const returned = props.functionInstance.functionDefinition;
    const returnName = returned.split(' ');
    setReturnNameType(returnName[0]);
    returnName.shift();
    const functionName = returnName.toString();
    setFunctionName(functionName.split('(')[0]);
    const parameters = '(' + functionName.split('(')[1].replaceAll(',', ' ');
    setFunctionParameters(parameters.replaceAll('  ', ', '));
  }, [props.functionInstance.functionDefinition]);

  React.useEffect(() => {
    buildFunctionSignature();
  }, [buildFunctionSignature]);

  const handleDeleteFunction = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  };

  const deleteFn = () => {
    if (!props.functionInstance.uuid || !module_id) return;
    FunctionService.deleteFunction(props.functionInstance.uuid).then(() =>
      fetchFunctions(module_id)
    );
  };

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  async function handleShowFunctionEditorDialog(
    event: React.MouseEvent<HTMLDivElement>,
    openNewWindow?: boolean
  ) {
    event.stopPropagation();

    if (!app_id || !module_id) return;

    const lnk = isFromVsCodeExt()
      ? `/app/${app_id}/module/${module_id}/logic/service/${service_id}/function-editor/${props.functionInstance.uuid}?vscode=true`
      : `/app/${app_id}/module/${module_id}/logic/service/${service_id}/function-editor/${props.functionInstance.uuid}`;
    if (openNewWindow || props.onlyOpenWindow) {
      window.open(lnk, '_blank');
    } else {
      navigate(lnk, { replace: true });
    }
  }

  return (
    <div
      id="list-group"
      className={`border ${styles.FunctionWrapperNew} ${props.className}`}
      style={{
        backgroundColor: mouseHovering
          ? 'rgba(62, 71, 77, 1) !important'
          : session.preferences['exocode-theme']
          ? '#1c2025'
          : '#FFFFFF'
      }}
      onMouseEnter={() => setMouseHovering(true)}
      onMouseLeave={() => setMouseHovering(false)}
    >
      <div
        id={props.functionInstance.description}
        className={`${styles.FunctionDescription}`}
        onClick={handleShowFunctionEditorDialog}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: mouseHovering
              ? 'rgba(67, 76, 83, 0)'
              : session.preferences['exocode-theme']
              ? '#1c2025'
              : '#FFFFFF'
          }}
        >
          <div className={`${styles.Badge} ${styles.BadgeBackground}`}>
            <span style={{ color: '#8E619A' }}>{returnNameType} </span>
            <span style={{ color: '#CFCC9B' }}>{functionName}</span>
            <span style={{ color: '#63A189' }}>{functionParameters}</span>
          </div>
          {props.functionInstance.native ? (
            <div className={styles.nativeBadge}>{t('Native')}</div>
          ) : (
            <div className={styles.wrapperIcons}>
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: t('CodePreview') ?? 'CodePreview'
                }}
              >
                <div
                  id="codePreview"
                  className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setPreviewFunctionUuid(props.functionInstance.uuid ?? '');
                  }}
                >
                  <Icon iconName="fa-solid fa-code" extraProps={`text-secondary h7`} />
                </div>
              </HelpPopover>
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: t('editButton') ?? 'editButton'
                }}
              >
                <div
                  id="editButton"
                  className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                  onClick={handleShowFunctionEditorDialog}
                >
                  <Icon iconName="pen-to-square" extraProps={`text-secondary h7`} />
                </div>
              </HelpPopover>
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: t('Delete') ?? 'Delete'
                }}
              >
                <div
                  id="removeButton"
                  className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                  onClick={handleDeleteFunction}
                >
                  <Icon iconName="trash" extraProps={`text-secondary h7`} />
                </div>
              </HelpPopover>
            </div>
          )}
        </div>
      </div>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.function') ?? 'deleteQuotes.function'} (${functionName})`}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={deleteFn}
        onClose={() => setShowConfirmationDialog(false)}
      />
      <CodeEditorModal
        show={previewFunctionUuid !== ''}
        handleClose={() => setPreviewFunctionUuid('')}
        id={previewFunctionUuid}
        previewType={CodePreviewType.FUNCTION}
      />
    </div>
  );
}
