import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './styles.module.css';
import useRenderInput from './hooks/useRenderInput';
import { FunctionActionInputType } from '../../../../store/types/manifestsAndInputs';

type ActionInputsProps = {
  uuid: string;
  inputs: FunctionActionInputType[];
};

function ActionInputs({ uuid, inputs }: ActionInputsProps) {
  const { renderInput } = useRenderInput();
  return (
    <Card.Body className={styles.ActionInputs}>
      {inputs.map((input, index) => (
        <div key={index} className={styles.ActionInputsWrapper}>
          {renderInput(input, uuid)}
        </div>
      ))}
    </Card.Body>
  );
}

export default ActionInputs;
