import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle';
import { SupportService } from 'modules/project/services';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';

const SupportCategories = {
  GENERAL: 'General',
  ACCOUNT: 'Account',
  SUBSCRIPTIONS: 'Subscriptions',
  DATABASE_MODELER: 'Database Modeler',
  LOGIC_BUILDER: 'Logic Builder',
  INTERFACE_DESIGNER: 'Interface Designer',
  TEST_SUITE: 'Test Suite',
  CODE_ASSISTANT: 'Code Assistant'
} as const;

type SupportCategory = keyof typeof SupportCategories;

export type SupportType = {
  subject: string;
  content: string;
  category: SupportCategory;
};

export function Support() {
  const { t } = useTranslation();
  useTitle(t('tab.settingsAcc.Support'));
  const [support, setSupport] = useState<SupportType>({
    content: '',
    subject: '',
    category: 'GENERAL'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      if (isLoading) return;
      setIsLoading(true);
      if (!e.currentTarget.checkValidity()) {
        setIsLoading(false);
        return;
      }
      SupportService.contactSupport(support)
        .then(() => {
          setSupport({ ...support, content: '', subject: '' });
          showSuccessPopup('account_settings.supportSettings.SuccessMessage');
        })
        .catch(() => {
          showErrorPopup('account_settings.supportSettings.ErrorMessage');
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, support]
  );

  const handleContentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setSupport({ ...support, content: e.target.value });
    },
    [support]
  );

  const handleSubjectChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setSupport({ ...support, subject: e.target.value });
    },
    [support]
  );

  const showSuccessPopup = (message: string): void => {
    setAlertVariant('success');
    setAlertMessage(message);
  };

  const showErrorPopup = (message: string): void => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = (): void => {
    setAlertMessage('');
  };

  const handleChangeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    setSupport({ ...support, category: e.target.value as SupportCategory });
  };

  const getTranslation = (category: string): string => {
    return t(
      `account_settings.supportSettings.${
        category[0].toUpperCase() + category.slice(1).toLowerCase()
      }`
    );
  };

  return (
    <>
      <Col className={styles.Section}>
        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('account_settings.supportSettings.Support')}
          </span>
        </div>
        <Form onSubmit={onSubmit} className={styles.Form}>
          <Container className={`mt-3`}>
            <Row>
              <Form.Label>{t('Subject')}</Form.Label>
              <Col xs="12" lg="6">
                <Form.Group className={`${styles.FormGroup} mb-3`} controlId="subject">
                  <Form.Control
                    value={support.subject}
                    maxLength={64}
                    onChange={handleSubjectChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs="12" lg="6">
                <Form.Select value={support.category} onChange={handleChangeCategory}>
                  {Object.keys(SupportCategories).map((category) => (
                    <option key={category} value={category}>
                      {getTranslation(category)}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Container>
          <Container>
            <Form.Group className={`${styles.FormGroup}`} controlId="content">
              <Form.Label>{t('account_settings.supportSettings.Help')}</Form.Label>
              <Form.Control
                value={support.content}
                as={'textarea'}
                rows={16}
                maxLength={1024}
                onChange={handleContentChange}
                required
              />
            </Form.Group>
          </Container>
          <Container>
            <Button type="submit" className={`${styles.SubmitButton}`}>
              {t('Send')}
            </Button>
          </Container>
        </Form>
      </Col>
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
      )}
    </>
  );
}
