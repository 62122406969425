import React from 'react';
import { ControlProps } from '..';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';
import Switch from '../components/Switch';
import { sleep } from 'utils/utils';

export enum IconText {
  ICON = 'ICON',
  BOTH = 'BOTH',
  TEXT = 'TEXT'
}

function IconTextControl(props: ControlProps) {
  const { t } = useTranslation();

  const iconTextTypes = [
    { label: 'Icon', onClick: () => props.onChange!(IconText.ICON, 'linkText') },
    { label: 'Both', onClick: () => props.onChange!(IconText.BOTH, 'linkText') },
    { label: 'Text', onClick: () => props.onChange!(IconText.TEXT, 'linkText') }
  ];

  async function handleChangeStaticColor(staticColor: string) {
    if (!props.onChange) return;
    props.onChange(staticColor, 'iconStaticColor');
    await sleep(50);
    props.onChange('', 'iconThemeColor');
  }

  async function handleChangeThemeColor(themeColor: string) {
    if (!props.onChange) return;
    props.onChange(themeColor, 'iconThemeColor');
    await sleep(50);
    props.onChange('', 'iconStaticColor');
  }

  return (
    <div className="mb-3 border-bottom text-body-secondary">
      <div style={{ display: 'flex' }}>
        <label className="position-relative text-body">{props.label}</label>
        <HelpPopover
          helpBoxProps={{
            title:
              t('designer.right_side.controls.ListTypeTitle') ||
              'designer.right_side.controls.ListTypeTitle',
            description:
              t('designer.right_side.controls.ListTypeDescription') ||
              'designer.right_side.controls.ListTypeDescription',
            note: []
          }}
          placement="top"
        >
          <HelpIcon />
        </HelpPopover>
      </div>
      <div>
        <Switch
          switches={iconTextTypes}
          defaultActivatedIndex={
            props.value && Object.values(IconText).indexOf(props.value['linkText']) + 1
          }
        />
      </div>
    </div>
  );
}

export default IconTextControl;
