import * as React from 'react';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SessionContext from 'modules/auth/store';
import { OrganizationApiInfo } from 'modules/organization/types';
import { ApiAppInfo } from 'modules/project/types';
import { AccountService } from 'modules/auth/services';
import useCreateApp from '../../../../hooks/useCreateApp';
import UpgradePlanModal from '../upgrade_plan_modal';
import CantCreateAppModal from '../cant_create_app_modal';
import Icon from 'web_ui/icon';

import styles from './styles.module.css';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  orgId?: OrganizationApiInfo[];
  projects: ApiAppInfo[];
};

export default function CreateProjectModal(props: Props) {
  const session = React.useContext(SessionContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [canCreateApp] = useCreateApp(props.projects);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = React.useState<boolean>(false);
  const [showCanCreateOrgModal, setShowCanCreateOrgModal] = React.useState<boolean>(false);

  const personalProject = () => {
    if (canCreateApp) {
      navigate('/wizard');
    } else {
      setShowUpgradePlanModal(true);
    }
  };

  const orgProject = async (currentOrg: OrganizationApiInfo) => {
    const totalsOfOwner = await AccountService.getTotalsUser(currentOrg.owner.id);

    if (totalsOfOwner.numberOfCreatedApplications < totalsOfOwner.limitOfApplications) {
      navigate('/wizard', { state: { orgId: currentOrg.id } });
    } else {
      currentOrg.owner.id === session.user?.id
        ? setShowUpgradePlanModal(true)
        : setShowCanCreateOrgModal(true);
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={() => props.setShow(false)} backdrop={'static'} centered>
        <Modal.Header closeButton id="modalHeaderCreateProjectModal">
          {t('dashboard.modalCreateProject.modalTitle')}
        </Modal.Header>
        <Modal.Body id="modalBodyCreateProjectModal">
          <div className={styles.containerBodyModal} id="containerBodyCreateProjectModal">
            {props.orgId && props.orgId.length > 0 ? ( // if has the org I will show the two opt
              <>
                <Button onClick={personalProject} id="personalButton">
                  <Icon iconName="user" /> {t('dashboard.modalCreateProject.PersonalProject')}
                </Button>
                <Dropdown>
                  <Dropdown.Toggle id="organizationButton">
                    <Icon iconName="building" />{' '}
                    {t('dashboard.modalCreateProject.OrganizationProject')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {props.orgId &&
                      props.orgId.length > 0 &&
                      props.orgId.map((orgItem, index) => (
                        <Dropdown.Item
                          id={orgItem.name}
                          key={index}
                          onClick={() => orgProject(orgItem)}
                        >
                          {orgItem.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Button onClick={personalProject} id="personalProjectBtnTwo">
                  {t('dashboard.modalCreateProject.PersonalProject')}
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
      {showCanCreateOrgModal && (
        <CantCreateAppModal setShow={setShowCanCreateOrgModal} show={showCanCreateOrgModal} />
      )}
    </>
  );
}
