import { EnumsState, initialState } from '..';
import produce from 'immer';
import {
  ADD_ENUM,
  addEnumAction,
  DELETE_ENUM,
  deleteEnumFrameAction,
  SET_ENUM_POSITION,
  setEnumPositionAction,
  EnumsActions,
  CHANGE_ENUM_NAME,
  changeEnumNameAction,
  SET_ENUMS,
  setEnumsAction,
  CHANGE_ENUM_PROPERTY,
  changeEnumPropertyAction,
  ADD_ENUMCOLUMN,
  changeEnumFolderAction,
  CHANGE_ENUM_FOLDER
} from '../actions/enums';
import { EnumFrame } from '../../../types';
import {
  DELETE_ENUMCOLUMN,
  addEnumColumnAction,
  deleteEnumColumnAction
} from '../actions/enum_column';

export const enumsReducer = (state = initialState.enums, action: EnumsActions) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_ENUM:
        return doAddEnumFrame(draft, action);
      case DELETE_ENUM:
        return doDeleteEnumFrame(draft, action);
      case SET_ENUM_POSITION:
        return doSetEnumPostion(draft, action);
      case CHANGE_ENUM_NAME:
        return doChangeEnumName(draft, action);
      case CHANGE_ENUM_FOLDER:
        return doChangeEnumFolder(draft, action);
      case CHANGE_ENUM_PROPERTY:
        return doChangeEnumProperty(draft, action);
      case SET_ENUMS:
        return doSetEnums(draft, action);
      case ADD_ENUMCOLUMN:
        return doAddEnumColumn(draft, action);
      case DELETE_ENUMCOLUMN:
        return doDeleteEnumColumn(draft, action);
      default:
        return draft;
    }
  });
};

function doAddEnumFrame(state: EnumsState, action: addEnumAction) {
  state[action.payload.uuid] = action.payload;
}

function doDeleteEnumFrame(state: EnumsState, action: deleteEnumFrameAction) {
  const enumFrameID = action.payload.uuid;

  // TODO: delete links

  delete state[enumFrameID];
  return state;
}

function doSetEnumPostion(state: EnumsState, action: setEnumPositionAction) {
  state[action.payload.uuid].posX = action.payload.posX;
  state[action.payload.uuid].posY = action.payload.posY;
  return state;
}

function doChangeEnumName(state: EnumsState, action: changeEnumNameAction) {
  state[action.payload.uuid].content.data.name = action.payload.name;
  return state;
}

function doChangeEnumFolder(state: EnumsState, action: changeEnumFolderAction) {
  if (action.payload.folderId) {
    state[action.payload.id].folder_id = action.payload.folderId;
  } else {
    state[action.payload.id].folder_id = '';
  }
  return state;
}

function doChangeEnumProperty(state: EnumsState, action: changeEnumPropertyAction) {
  state[action.payload.uuid].content.data[action.payload.key] = action.payload.value;
  return state;
}

function doSetEnums(state: EnumsState, action: setEnumsAction) {
  state = {};
  action.payload.enums.forEach((enumFrame: EnumFrame) => {
    state[enumFrame.uuid] = enumFrame;
  });
  return state;
}

function doAddEnumColumn(state: EnumsState, action: addEnumColumnAction) {
  state[action.payload.idEnum].content.data.columns.push(action.payload.id);
  return state;
}

function doDeleteEnumColumn(state: EnumsState, action: deleteEnumColumnAction) {
  const tableId = action.payload.table;
  const columnId = action.payload.id;
  state[tableId].content.data.columns.splice(
    state[tableId].content.data.columns.indexOf(columnId),
    1
  );
  return state;
}
