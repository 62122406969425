import { CrudTemplate, Template } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class TemplatesRepo {
  async convertViewToTemplate(view: string, formData: FormData): Promise<Template> {
    const options: RequestInit = {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    };

    const url = `${API_URL}/templates/${view}/convert`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Template>;

      throw new Error('Something went wrong while trying to create Template.');
    });
  }

  async getTemplateThumbnail(templateId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/templates/${templateId}/thumbnail`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response;

      throw new Error('Something went wrong while trying to fetch template thumbnail.');
    });
  }

  async getTemplates(type: string, viewType: string, app?: string): Promise<Template[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    let url = `${API_URL}/templates?type=${type}&viewType=${viewType}`;
    if (app) {
      url += `&appId=${app}`;
    }

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Template[]>;

      throw new Error('Something went wrong while trying to fetch templates.');
    });
  }

  async getCrudTemplates(
    moduleId: string,
    entityId: string,
    objectId: string,
    controllerId: string
  ): Promise<CrudTemplate> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/objects/${objectId}/controllers/${controllerId}/templates`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CrudTemplate>;

      throw new Error('Something went wrong while trying to fetch templates.');
    });
  }
}
