import { ModuleDependencyInfo, ModuleInfo, ModuleSettings } from '../types';

const API_URL = process.env.REACT_APP_API_URL;

export default class Dashboard {
  async getModulesByApp(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    if (!API_URL) {
      throw new Error('Error in the API url');
    } else {
      return fetch(`${API_URL}/modules/filterByApp?appId=${appId}`, options).then(
        async (response: Response) => {
          if (response.ok) {
            return response.json() as Promise<ModuleInfo[]>;
          }

          const message = await response.text();
          throw new Error(message);
        }
      );
    }
  }

  async getModuleDependencies(moduleId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: headers
    };

    if (!API_URL) {
      throw new Error('Error in the API url');
    } else {
      return fetch(`${API_URL}/modules/${moduleId}?includeDependencies=true`, options).then(
        async (response: Response) => {
          if (response.ok) {
            return response.json() as Promise<ModuleInfo>;
          }
          const message = await response.text();
          throw new Error(message);
        }
      );
    }
  }

  async getModuleAvailableDependencies(moduleId: string, currentSelection: ModuleDependencyInfo[]) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const moduleUuids = currentSelection.map((value) => value.id);

    const options: RequestInit = {
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify(moduleUuids)
    };

    if (!API_URL) {
      throw new Error('Error in the API url');
    } else {
      return fetch(`${API_URL}/modules/${moduleId}/available-dependencies`, options).then(
        async (response: Response) => {
          if (response.ok) {
            return response.json() as Promise<ModuleDependencyInfo[]>;
          }
          const message = await response.text();
          throw new Error(message);
        }
      );
    }
  }

  async createModuleSettings(moduleId: string, configuration: ModuleSettings) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify(configuration)
    };

    if (!API_URL) {
      throw new Error('Error in the API url');
    } else {
      return fetch(`${API_URL}/modules/${moduleId}/settings`, options).then(
        async (response: Response) => {
          if (response.ok) {
            return response.json() as Promise<ModuleSettings>;
          } else {
            const msg = await response.text();
            throw new Error(msg);
          }
        }
      );
    }
  }

  async getModuleSettings(moduleId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: headers
    };

    if (!API_URL) {
      throw new Error('Error in the API url');
    } else {
      return fetch(`${API_URL}/modules/${moduleId}/settings`, options).then(
        async (response: Response) => {
          if (response.ok) {
            return response.json() as Promise<ModuleSettings[]>;
          } else {
            const msg = await response.text();
            throw new Error(msg);
          }
        }
      );
    }
  }

  async updateModuleSettings(moduleId: string, configuration: ModuleSettings[]) {
    const headers = new Headers({
      'Content-type': 'application/json'
    });

    const options: RequestInit = {
      mode: 'cors',
      method: 'PUT',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify(configuration)
    };

    if (!API_URL) {
      throw new Error('Error in the API url');
    } else {
      return fetch(`${API_URL}/modules/${moduleId}/settings`, options).then(
        async (response: Response) => {
          if (response.ok) {
            return response.json() as Promise<ModuleSettings[]>;
          } else {
            const msg = await response.text();
            throw new Error(msg);
          }
        }
      );
    }
  }
}
