import { TYPE_PICKER_TYPES } from './type_picker';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';

export default function useCheckValueIsObject(type: TYPE_PICKER_TYPES, uuid: string) {
  const variables = useSelector((state: FunctionEditorState) => state.variables);
  const parameters = useSelector((state: FunctionEditorState) => state.parameters);
  const globals = useSelector((state: FunctionEditorState) => state.globals);
  const [isObject, setIsObject] = useState(false);
  const [objectUuid, setObjectUuid] = useState('');

  useEffect(() => {
    if (!type || !uuid) return;

    let varType = '';
    let objUuid = '';
    if (type === 'VAR') {
      varType = variables[uuid]?.type ?? '';
      objUuid = variables[uuid]?.objectUuid ?? '';
    } else if (type === 'PARAM') {
      varType = parameters[uuid]?.type ?? '';
      objUuid = parameters[uuid]?.objectUuid ?? '';
    } else if (type === 'PROP') {
      if (globals && globals.properties) {
        varType = globals.properties[uuid]?.type ?? '';
        objUuid = globals.properties[uuid]?.object ?? '';
      }
    }

    if (varType.toLowerCase() === 'object') {
      setIsObject(true);
    } else {
      setIsObject(false);
    }
    setObjectUuid(objUuid);
  }, [globals, parameters, type, uuid, variables]);

  return { isObject, objectUuid };
}
