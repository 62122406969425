import React from 'react';
import { ControlProps } from '..';
import styles from './styles.module.css';
import HelpPopover from '../components/Popover';
import { useTranslation } from 'react-i18next';
import HelpIcon from '../components/HelpIcon';

function ModalSizeControl(props: ControlProps) {
  const types = [
    { label: 'Small', width: '35', size: 'sm' },
    { label: 'Default', width: '45', size: '' },
    { label: 'Large', width: '60', size: 'lg' },
    { label: 'XLarge', width: '85', size: 'xl' }
  ];
  const { t } = useTranslation();

  function handleChange(selectedSize: string) {
    if (props.onChange) props.onChange(selectedSize, props.id);
  }

  return (
    <div className={styles.modalControlContainer}>
      <label className="d-flex">
        {props.label}
        <HelpPopover
          helpBoxProps={{
            title:
              t('designer.right_side.controls.ModalSizeTitle') ||
              'designer.right_side.controls.ModalSizeTitle',
            description:
              t('designer.right_side.controls.ModalSizeDescription') ||
              'designer.right_side.controls.ModalSizeDescription',
            note: [
              t('designer.right_side.controls.ModalSizeNote01'),
              t('designer.right_side.controls.ModalSizeNote02'),
              t('designer.right_side.controls.ModalSizeNote03'),
              t('designer.right_side.controls.ModalSizeNote04'),
              t('designer.right_side.controls.ModalSizeNote05')
            ]
          }}
          placement="top"
        >
          <HelpIcon></HelpIcon>
        </HelpPopover>
      </label>

      <div className={`mt-3 ${styles.modalControlContent}`}>
        {types.map((sizeType, index) => {
          return (
            <div
              key={sizeType.label}
              onClick={(e) => handleChange(sizeType.size)}
              className={` mb-2 ${styles.sizeTypeItem}`}
            >
              <div
                className={`${
                  props.value === sizeType.size ? 'border border-primary' : ''
                } mb-2 bg-secondary ${styles.modalContainer}`}
              >
                <div
                  style={{ width: sizeType.width + '%' }}
                  className={`bg-body-secondary border ${styles.modalContent}`}
                ></div>
              </div>
              <span className={`${props.value === sizeType.size ? 'text-primary' : ''}`}>
                {t(`designer.right_side.controls.${sizeType.label}`)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ModalSizeControl;
