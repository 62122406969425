import React from 'react';
import styles from './styles.module.css';
import EmptyMessage from 'web_ui/empty';
import { useTranslation } from 'react-i18next';
import { ScheduledInstance } from './scheduleds_instance';
import { SchedulerJob } from 'modules/logic_builder/types';

type ScheduledsListProps = {
  search: string;
  scheduleds: SchedulerJob[];
  onCreate: () => void;
  onEdit: (e: string) => void;
  onPreviewScheduler: (schedulerUuid: string) => void;
};

export function ScheduledsList(props: ScheduledsListProps) {
  const { t } = useTranslation();

  function handleCreate(event?: React.MouseEvent<HTMLButtonElement>) {
    event && event.stopPropagation();
    props.onCreate();
  }

  const applyFilter = (o: SchedulerJob): boolean => {
    return (
      !props.search ||
      o.name.toLowerCase().includes(props.search) ||
      o.description.toLowerCase().includes(props.search)
    );
  };

  return (
    <>
      <div className={styles.ScheduledsGroupWrapper}>
        {props.scheduleds
          .filter((o) => applyFilter(o))
          .map((scheduled, index) => {
            return (
              <ScheduledInstance
                key={index}
                cursor="pointer"
                scheduledInstance={scheduled}
                onEdit={(e: SchedulerJob) => props.onEdit(e.uuid ?? '')}
                onPreviewScheduler={props.onPreviewScheduler}
              />
            );
          })}
        {!props.scheduleds.length && (
          <div className="w100 h100 border p-4 rounded">
            <EmptyMessage
              message={t('logicBuilder.NoScheduleds')}
              icon="exclamation"
              actionMessage={t('logicBuilder.CreateScheduled') ?? ''}
              linkAction={handleCreate}
            ></EmptyMessage>
          </div>
        )}
      </div>
    </>
  );
}
