import React, { forwardRef, memo } from 'react';
import { ComponentUUID, ViewUUID } from '../../../types';
import ComponentsRenderer from '../components_renderer';

export type CustomComponentProps = {
  viewUUID: ViewUUID;
  uuid: ComponentUUID;
  custom_uuid: ComponentUUID;
};

function CustomComponent(props: CustomComponentProps, ref: React.Ref<any>) {
  return (
    <div
      style={{
        opacity: 1,
        padding: 0,
        margin: 0,
        width: 'auto',
        height: 'auto'
      }}
      ref={ref}
    >
      <ComponentsRenderer
        viewUUID={props.viewUUID}
        parentUUID={props.custom_uuid}
        custom_uuid={props.custom_uuid}
        new_custom_uuid={props.uuid}
      />
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(CustomComponent));
