import React from 'react';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';

function HelpIcon() {
  return (
    <span className={`text-body-secondary ${styles.questionIcon}`}>
      <Icon iconName="circle-question" />
    </span>
  );
}

export default HelpIcon;
