import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

export const NAVBAR_MANIFEST: ComponentManifest = {
  type: 'NAVBAR',
  name: 'Navbar',
  descriptionId: 'NavbarDescription',
  description: 'A custom Navbar for user insert into the page.',
  icon: 'fa-solid fa-ship',
  group: ComponentGroups['MENU'],
  allowDrop: true,
  previewHeight: 50,
  previewWidth: 100,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Title',
      key: 'title',
      tooltip: 'designer.right_side.controls.components.navbar.title'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'TitleLink',
      key: 'linkTitle',
      tooltip: 'designer.right_side.controls.components.navbar.linkTitle'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.navbar.visible'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Placement',
      key: 'placement',
      controlOptions: ['top', 'bottom', ''],
      tooltip: 'designer.right_side.controls.components.navbar.placement'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'TitlePosition',
      key: 'titlePosition',
      controlOptions: ['left', 'right'],
      tooltip: 'designer.right_side.controls.components.navbar.titlePosition'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'LinksPosition',
      key: 'linksPosition',
      controlOptions: ['left', 'center', 'right'],
      tooltip: 'designer.right_side.controls.components.navbar.linksPosition'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'ShowTitle',
      key: 'showTitle',
      tooltip: 'designer.right_side.controls.components.navbar.showTitle'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.SELECT_PICS,
      controlLabel: 'Logo',
      key: 'logo',
      tooltip: 'designer.right_side.controls.components.navbar.logo'
    },
    {
      controlType: ControlsTypes.LIST_LINKS,
      controlLabel: 'SettingLinks',
      key: 'links',
      tooltip: 'designer.right_side.controls.components.navbar.links'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'AutomaticLinks',
      key: 'automaticLabels',
      tooltip: 'designer.right_side.controls.components.navbar.automaticLabels'
    },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.navbar.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.navbar.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Background',
      tooltip: 'designer.right_side.controls.components.navbar.background'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.navbar.color'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Icon Color',
      key: 'iconColor'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Font Weight',
      key: 'fontWeight',
      controlOptions: ['normal', 'bold', '100', '300', '500', '600', '800', '900'],
      tooltip: 'designer.right_side.controls.components.navbar.fontWeight'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.navbar.fontSize'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'LogoWidth',
      key: 'logoWidth',
      tooltip: 'designer.right_side.controls.components.navbar.logoWidth'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'LogoHeight',
      key: 'logoHeight',
      tooltip: 'designer.right_side.controls.components.navbar.logoHeight'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
