import { createStore, compose, applyMiddleware } from 'redux';

import {
  Column,
  columnID,
  ConnectorID,
  EnumColumn,
  EnumColumnID,
  EnumFrame,
  EnumUUID,
  Index,
  IndexColumn,
  IndexID,
  Relationship,
  relationshipID,
  Table,
  TableUUID
} from '../../types';
import { studioReducer } from './reducers/studio';
import { tablesReducer } from './reducers/frames';
import { columnsReducer } from './reducers/columns';
import { indexesReducer } from './reducers/indexes';
import { relationshipsReducer } from './reducers/relationship';
import { saveMiddleware } from './middlewares/save';
import { enumsReducer } from './reducers/enums';
import { enumColumnsReducer } from './reducers/enum_column';
import { FrameUUID } from 'web_ui/workboard/frame';
import { rootReducer } from './reducers/root';
import { combineReducers } from 'packages/redux-utils';
import { WidgetsState } from 'modules/designer/studio/store';
import { widgetsReducer } from 'modules/designer/studio/store/reducers/widgets';

export type StateHistory = {
  redoStack: string[];
  undoStack: string[];
  // Last valid entry is the last action the backend processed successfully.
  lastValidEntry: string | null;
};

export interface StudioState {
  history: StateHistory;
  selectedFrame: FrameUUID | null;
  selectedConnector: ConnectorID | null;
  schema_id: string;
  module_id: string;
  errorMessage: string;
  isCreatingRelationship: boolean;
}

export interface ColumnState {
  [key: columnID]: Column;
}

export interface RelationshipState {
  [key: relationshipID]: Relationship;
}

export interface EnumsState {
  [key: EnumUUID]: EnumFrame;
}

export interface EnumColumnsState {
  [key: EnumColumnID]: EnumColumn;
}

export interface IndexesState {
  [key: IndexID]: Index;
}

export interface indexColumnState {
  [key: columnID]: IndexColumn;
}

export interface TablesState {
  [key: TableUUID]: Table;
}

export interface ErrorMessage {
  message: string | null;
}

export interface RootState {
  studio: StudioState;
  tables: TablesState;
  enums: EnumsState;
  columns: ColumnState;
  enum_columns: EnumColumnsState;
  indexes: IndexesState;
  relationships: RelationshipState;
  widgets: WidgetsState;
}

export interface DatabaseStudioState {
  studio: StudioState;
  tables: TablesState;
  enums: EnumsState;
  columns: ColumnState;
  enum_columns: EnumColumnsState;
  indexes: IndexesState;
  relationships: RelationshipState;
  widgets: WidgetsState;
}

export const initialState: DatabaseStudioState = {
  studio: {
    history: {
      redoStack: [],
      undoStack: [],
      lastValidEntry: null
    },
    selectedFrame: null,
    selectedConnector: null,
    schema_id: '',
    module_id: '',
    errorMessage: '',
    isCreatingRelationship: false
  },
  tables: {},
  enums: {},
  columns: {},
  enum_columns: {},
  indexes: {},
  relationships: {},
  widgets: {}
};

const appReducers = combineReducers(
  {
    studio: studioReducer || (() => null),
    tables: tablesReducer || (() => null),
    enums: enumsReducer || (() => null),
    columns: columnsReducer || (() => null),
    enum_columns: enumColumnsReducer || (() => null),
    indexes: indexesReducer || (() => null),
    relationships: relationshipsReducer || (() => null),
    widgets: widgetsReducer || (() => null)
  },
  rootReducer
);

export const REDO_ONCE = 'REDO_ONCE';
export const UNDO_ONCE = 'UNDO_ONCE';
export const RESTORE_LAST_VALID_STATE = 'RESTORE_LAST_VALID_STATE';
export const SAVE_LAST_VALID_STATE = 'SAVE_LAST_VALID_STATE';

const enhancedReducer = (state: any, action: any) => {
  if (action.type === RESTORE_LAST_VALID_STATE) {
    if (state.studio.history.lastValidEntry == null) {
      return state;
    }

    const toRestoreState: DatabaseStudioState = JSON.parse(state.studio.history.lastValidEntry);

    if (toRestoreState != null) {
      toRestoreState.studio.history.lastValidEntry = state.studio.history.lastValidEntry;
      return toRestoreState;
    }

    return state;
  } else if (action.type === SAVE_LAST_VALID_STATE) {
    const newState: DatabaseStudioState = { ...state };
    const lastValidEntry = JSON.stringify({
      tables: state.tables,
      enums: state.enums,
      columns: state.columns,
      enum_columns: state.enum_columns,
      indexes: state.indexes,
      relationships: state.relationships,
      widgets: state.widgets
    });
    if (lastValidEntry) {
      newState.studio = {
        ...newState.studio,
        history: {
          ...newState.studio.history,
          lastValidEntry: lastValidEntry
        }
      };
    }

    return newState;
  }

  if (action.type === UNDO_ONCE) {
    if (state.studio.history.undoStack.length === 1) {
      return state;
    }

    const current = state.studio.history.undoStack.pop();

    const toRestore = state.studio.history.undoStack[state.studio.history.undoStack.length];

    if (current != null) {
      state.studio.history.redoStack.push(current);
      const toRestoreState: DatabaseStudioState = JSON.parse(toRestore);
      toRestoreState.studio = state.studio;
      return toRestoreState;
    }

    return state;
  } else if (action.type === REDO_ONCE) {
    if (state.studio.history.redoStack.length === 0) {
      return state;
    }

    const toRestore = state.studio.history.redoStack.pop();

    if (toRestore != null) {
      state.studio.history.undoStack.push(toRestore);
      const toRestoreState: DatabaseStudioState = JSON.parse(toRestore);
      toRestoreState.studio = state.studio;
      return toRestoreState;
    }

    return state;
  }

  const appState = appReducers(state, action);

  // Create shouldCreateEntry function to avoid saving state after every action.
  // const entry = {
  //   tables: appState.tables,
  //   enums: appState.enums,
  //   columns: appState.columns,
  //   enum_columns: appState.enum_columns,
  //   indexes: appState.indexes,
  //   relationships: appState.relationships,
  //   widgets: appState.widgets
  // };
  // const dehydratedEntry = JSON.stringify(entry);
  // if (dehydratedEntry) {
  //   return {
  //     ...appState,
  //     studio: {
  //       ...appState.studio,
  //       history: {
  //         ...appState.studio.history,
  //         undoStack: [...appState.studio.history.undoStack, dehydratedEntry],
  //         redoStack: []
  //       }
  //     }
  //   };
  // }

  return appState;
};

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  enhancedReducer,
  initialState,
  composeEnhancers(applyMiddleware(saveMiddleware))
);
