import React from 'react';
import styles from './styles.module.css';
import { MoldureProps } from '../../../page_view';

function TabletMoldure(props: MoldureProps) {
  return (
    <div
      style={{
        height: 'calc(100% - 16px)',
        position: 'relative'
      }}
    >
      <>
        <div className={styles.browserControls} onClick={props.unselectComponent}></div>
        <div className={styles.topBarScreen}></div>
        <div className={styles.topBar} onClick={props.unselectComponent}>
          <input
            id="formRouteTablet"
            className={styles.urlBar}
            type="text"
            value={props.rootpage ? '/' : props.route}
            onChange={(e) => props.updateUrlBar(e.target.value)}
          />
        </div>
        <div className={styles.leftBar}></div>
        <div className={styles.rightBar}></div>
        <div className={styles.bottomBar}></div>
        {props.children}
      </>
    </div>
  );
}

export default TabletMoldure;
