import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import React from 'react';
import BeginCycleIcon from './begin_cycle_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { DATA_TYPES } from '../../../types';
import { FORBIDDEN_VARIABLE_NAMES } from '../../../../../web_ui/function_editor/store/types/variables';
import { variableNameRegex } from '../../../../../utils/regex';
import { t } from 'i18next';

function validateVariableName(state: FunctionEditorState, name: string): boolean {
  if (!name) return false;
  if (!variableNameRegex.test(name)) return false;
  if (FORBIDDEN_VARIABLE_NAMES.includes(name.toLowerCase())) return false;
  for (const v of Object.values(state.variables)) {
    if (v.name === name) return false;
  }
  return true;
}

export const BEGIN_CYCLE_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.BEGIN_CYCLE,
  name: t('For'),
  description: t('ForDescription'),
  color: 'secondary',
  category: BACKEND_ACTIONS_CATEGORIES.CYCLE,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.CYCLE,
  icon: <BeginCycleIcon />,
  openBlock: true,
  closeBlockType: BACKEND_ACTIONS_TYPES.END_CYCLE,
  inlineInputs: [
    {
      type: ACTION_INPUTS_TYPES.TEXT,
      label: t('ForEach'),
      placeholder: t('Item') ?? 'item',
      key: 'name',
      options: { validator: validateVariableName }
    },
    {
      type: ACTION_INPUTS_TYPES.DATA_TYPE_INPUT,
      label: t('ofType'),
      placeholder: t('type') ?? 'type',
      key: 'dataType',
      options: { dataTypes: DATA_TYPES }
    },
    {
      label: t('In'),
      placeholder: t('List') ?? 'list',
      key: 'list',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: { list: true }
    }
  ],
  inputs: []
};
