import React, { MouseEvent, ReactNode, useState } from 'react';
import styles from './styles.module.css';
import { DATA_TYPES, DataType } from 'modules/logic_builder/types';
import { Form } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { ObjectItemSource, Property, SelectedProperty } from './editor_utils';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type ObjectViewProps = {
  properties: Record<string, Property[]> | undefined;
  rootObject: string;
  handleSelectProperty: (property: SelectedProperty | undefined) => void;
  selectedProperty: SelectedProperty | undefined;
  handleCheckedProperty: (property: string, objectId: string, checked: boolean) => void;
  isObjectComplete: boolean;
  moveProperty: (source: ObjectItemSource, target: ObjectItemSource) => void;
};

export function ObjectView({
  properties,
  rootObject,
  handleSelectProperty,
  selectedProperty,
  handleCheckedProperty,
  isObjectComplete,
  moveProperty
}: ObjectViewProps) {
  const [draggedObject, setDraggedObject] = useState<ObjectItemSource>();
  const { t } = useTranslation();

  const selectProperty = (property: Property, objectId: string) => {
    const newSelectedProperty: SelectedProperty = {
      id: property.id,
      objectId: objectId
    };

    if (selectedProperty && selectedProperty.id === newSelectedProperty.id) {
      handleSelectProperty(undefined);
    } else {
      handleSelectProperty(newSelectedProperty);
    }
  };

  const findPropertyValue = (property: Property, isObject: boolean) => {
    if (!isObject) {
      // The next line is a bit strange, but the thing is isObject depends on
      // isObjectComplete and fromEntity, so it only represents properties generated
      // from an entity. For normal properties this line returns the correct dataType.
      if (property.type === 'OBJECT') {
        return property.objectName;
      } else if (property.type === 'ENUM') {
        return property.enumName;
      }
      return DATA_TYPES[property.type as DataType];
    } else {
      return property.objectName;
    }
  };

  const icon = (property: Property) => {
    if (property.isFK || !!property.relationshipId) {
      return <Icon iconName="link" />;
    } else if (property.isPK) {
      return <Icon iconName="key" extraProps={styles.KeyIcon} />;
    }
  };

  const toggleCheckProperty = (
    event: MouseEvent<HTMLInputElement>,
    property: Property,
    objectId: string
  ) => {
    event.stopPropagation();
    handleCheckedProperty(property.id, objectId, !property.checked);
  };

  const dragStart = (
    event: React.DragEvent<HTMLDivElement>,
    objectId: string,
    propertyId: string
  ) => {
    // event.dataTransfer.setDragImage(image, 0, 0);
    const transferData: ObjectItemSource = {
      object: objectId,
      property: propertyId
    };
    setDraggedObject(transferData);
  };

  const dragEnter = (
    event: React.DragEvent<HTMLDivElement>,
    objectId: string,
    propertyId: string
  ) => {
    if (draggedObject == null) return;

    const target: ObjectItemSource = {
      object: objectId,
      property: propertyId
    };
    moveProperty(draggedObject, target);
  };

  const dragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    setDraggedObject(undefined);
  };

  const renderProperties = (
    objectId: string | undefined,
    marginLeft: number,
    currentObjectHistory: string[]
  ): ReactNode => {
    if (!properties || !objectId) return null;

    const propertyList = properties[objectId];

    if (!propertyList) return null;

    return propertyList.map((property) => {
      if (property.showWhenObjectIsComplete && !isObjectComplete) return null;
      // showWhenObjectIsComplete means that the property was generated manually in order to represent
      // the entity relationship. In that case, the property should disappear when the object is already
      // in use.
      if (
        property.object &&
        property.showWhenObjectIsComplete &&
        currentObjectHistory.includes(property.object)
      )
        return null;

      const localCurrentObjectHistory = [...currentObjectHistory];
      if (property.object) {
        localCurrentObjectHistory.push(property.object);
      }

      let isObject = false;
      // If isObjectComplete = true and property should also be an object then set
      // isObject = true.
      if (isObjectComplete) {
        if (property.object && property.fromEntity) {
          isObject = true;
        }
        // If isObjectComplete = false then properties are never represented
        // as objects referencing other entities.
      } else {
        isObject = false;
      }

      return (
        <React.Fragment key={property.id}>
          <div style={{ marginLeft: marginLeft + 'px' }}>
            <div
              id={`${property.name}`}
              className={`${styles.KeyValue} ${styles.SelectedProperty} ${
                selectedProperty?.id === property.id && 'bg-body-tertiary'
              }`}
              onClick={() => selectProperty(property, objectId)}
              onDragEnter={(e) => dragEnter(e, objectId, property.id)}
              onDragEnd={dragEnd}
              draggable
            >
              {property.moduleName && (
                <HelpPopover
                  helpBoxProps={{
                    title: `${t('InModule')} "${property.moduleName}"`
                  }}
                  placement="right"
                >
                  <div>{renderPropertyLine(property, isObject, objectId)}</div>
                </HelpPopover>
              )}
              {!property.moduleName && renderPropertyLine(property, isObject, objectId)}
              <div
                className={`${styles.DragIcon}`}
                onDragStart={(e) => dragStart(e, objectId, property.id)}
                onClick={(e) => e.stopPropagation()}
                draggable
              >
                <i className="fa-solid fa-ellipsis-vertical"></i>
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </div>
            </div>
          </div>
          {property.checked &&
            property.fromEntity &&
            isObject &&
            renderProperties(property.object, marginLeft + 16, [...localCurrentObjectHistory])}
        </React.Fragment>
      );
    });
  };

  const renderPropertyLine = (
    property: Property,
    isObject: boolean,
    objectId: string
  ): ReactNode => {
    return (
      <div className={`${styles.LeftSideWrapper}`}>
        {property.fromEntity && (
          <>
            <Form.Check
              type="checkbox"
              name="checkProperty"
              id={`${property.name}`}
              style={{ marginRight: '8px' }}
              className={`${styles.SmallFont}`}
              checked={property.checked}
              disabled={property.required}
              onClick={(e) => toggleCheckProperty(e, property, objectId)}
              readOnly
            />
            <i className={`fa-solid fa-database ${styles.EntityIcon}`}></i>
          </>
        )}
        <div className={styles.Key}>{property.name}</div>
        <div className={`text-muted ${styles.Value}`}>
          {property.list
            ? `List<${findPropertyValue(property, isObject)}>`
            : findPropertyValue(property, isObject)}
        </div>
        <span style={{ fontSize: '13px' }}>{icon(property)}</span>
      </div>
    );
  };

  return (
    <div className={`card p-2 ${styles.ObjectViewWrapper}`}>
      {renderProperties(rootObject, 0, [rootObject])}
    </div>
  );
}
