import { ThemeRepository } from '../repos';
import { Theme, ThemeValues } from '../types';

export class ThemeServ {
  async createTheme(app_uuid: string, theme: Theme) {
    return await ThemeRepository.createTheme(app_uuid, theme);
  }

  async getThemesByAppUuid(app_uuid: string) {
    return await ThemeRepository.getThemesByAppUuid(app_uuid);
  }

  async deleteTheme(app_uuid: string, themeUuid: string) {
    return await ThemeRepository.deleteTheme(app_uuid, themeUuid);
  }

  async updateThemeValue(app_uuid: string, themeValue: ThemeValues) {
    return await ThemeRepository.updateThemeValue(app_uuid, themeValue);
  }

  async updateTheme(app_uuid: string, themeUuid: string, theme: Theme) {
    return await ThemeRepository.updateTheme(app_uuid, theme);
  }
}
