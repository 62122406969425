import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Modal } from 'react-bootstrap';
import {
  MembersAndTeams,
  UpdateUserApp,
  UserProfile,
  UserWithRole
} from 'modules/auth/types/auth_types';
import { OrganizationService } from 'modules/organization/services';
import { OrganizationContext } from 'routes/organizations/organization_page';
import { ProjectsService } from 'modules/project/services';
import { useParams } from 'react-router-dom';
import { Member } from '../member';
import { ApiAppInfo } from 'modules/project/types';

export type Props = {
  showModal: boolean;
  onCloseModal: () => void;
  currMembersAndTeams: MembersAndTeams | undefined;
  reloadRootList: () => void;
};

export const AddPeopleModal = ({
  showModal,
  onCloseModal,
  currMembersAndTeams,
  reloadRootList
}: Props) => {
  const [allOrgMembers, setAllOrgMembers] = useState<UserProfile[]>([]);
  const { app_id } = useParams();
  const [membersToAdd, setMembersToAdd] = useState<UserWithRole[]>([]);
  const [currProjectState, setCurrProjectState] = useState<ApiAppInfo>();

  useEffect(() => {
    getAllOrgMembers();
  }, []);

  const getAllOrgMembers = async () => {
    if (!app_id) return;
    const currProject = await ProjectsService.getProjectById(app_id);
    setCurrProjectState(currProject);
    if (currProject.is_owner_org) {
      const dataRetrieved = await OrganizationService.getMembersOfOrg(currProject.owner_id);
      if (dataRetrieved) setAllOrgMembers(dataRetrieved);
    }
  };

  const addingMemberToAdd = (userToAdd: UserWithRole) => {
    setMembersToAdd((val) => [...val, userToAdd]);
  };

  const saving = async () => {
    if (!app_id) return;

    const dataToSend: UpdateUserApp = {
      usersToAdd: membersToAdd,
      usersToRemove: [],
      teamsToAdd: [],
      teamsToRemove: []
    };

    const dataRetrieved = await ProjectsService.updateMembersApp(app_id, dataToSend);
    if (dataRetrieved) reloadRootList();
  };

  return (
    <Modal
      centered
      show={showModal}
      onHide={() => {
        setMembersToAdd([]);
        onCloseModal();
      }}
      backdrop={'static'}
      size={'lg'}
      className={styles.containerModal}
    >
      <form>
        <Modal.Header closeButton className="bg-body">
          <Modal.Title className={`text-body-emphasis ${styles.newAppModalTitle}`}>
            <strong>Add People</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-body">
          <div style={{ marginTop: 10, height: 400, overflowY: 'auto' }}>
            {/* Members */}{' '}
            {allOrgMembers &&
              allOrgMembers
                .filter((item) => {
                  if (currMembersAndTeams) {
                    return !currMembersAndTeams.users.find(
                      (itemIterator) => itemIterator.id === item.id
                    );
                  }
                  return true;
                })
                .filter((item) => {
                  if (item.teams && currMembersAndTeams && currMembersAndTeams.teams) {
                    const allTeamIds: number[] = [];
                    currMembersAndTeams.teams.forEach((team) => allTeamIds.push(team.id));
                    if (allTeamIds.length === 0) return true;
                    return !item.teams.filter((teamItem) => allTeamIds.includes(teamItem.id));
                  }
                  return true;
                })
                .map((item, index) => (
                  <Member
                    membersToAdd={membersToAdd}
                    key={index}
                    user={item}
                    addingMemberToAdd={addingMemberToAdd}
                    isFromAddPeople={true}
                    owner_id={
                      currProjectState && currProjectState.owner_id && currProjectState.owner_id
                    }
                  />
                ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => saving()} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
