import { useQuery } from 'hooks/useQuery';
import { useNavigate, useParams } from 'react-router-dom';

function useReturnToStudio() {
  const queryParameters = useQuery();
  const { app_id, module_id, view_id, service_id } = useParams();
  const navigate = useNavigate();

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  function returnToStudio() {
    if (!app_id || !module_id) return;
    const path = view_id
      ? `/app/${app_id}/module/${module_id}/ui/${view_id}`
      : `/app/${app_id}/module/${module_id}/logic/service/${service_id}`;

    const newPath = isFromVsCodeExt() ? path.concat('?vscode=true') : path;
    navigate(newPath);
  }

  return returnToStudio;
}

export default useReturnToStudio;
