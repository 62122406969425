export class XY {
  public x: number;
  public y: number;
  public initialX: number;
  public initialY: number;
  // If allowedToMove is false then we do not execute the action that updates the widget coordinates.
  public allowedToMove: boolean;

  public initialDivX: number;
  public initialDivY: number;

  constructor() {
    this.x = 0;
    this.y = 0;
    this.initialX = 0;
    this.initialY = 0;
    this.allowedToMove = true;

    this.initialDivX = 0;
    this.initialDivY = 0;
  }

  initiate(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.initialX = x;
    this.initialY = y;
  }

  setAllowedToMove(allowed: boolean) {
    this.allowedToMove = allowed;
  }
}
