import React from 'react';
import { Column } from '../../../../../types';
import styles from '../styles.module.css';
import Icon from 'web_ui/icon';

type ColumnTypeIconProps = {
  column: Column;
};

export default function ColumnTypeIcon({ column }: ColumnTypeIconProps) {
  const icon = (column: Column) => {
    if (column.isFK) {
      return <Icon iconName="link" />;
    }
    if (column.isPK) {
      return <Icon iconName="key" extraProps={styles.KeyIcon} />;
    }
  };

  return (
    <div
      className="col-sm-1 d-flex align-items-center"
      id="columnCategory"
      style={{ cursor: 'drag' }}
    >
      <div className={styles.SmallFont}>{icon(column)}</div>
    </div>
  );
}
