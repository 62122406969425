import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';
import Icon from 'web_ui/icon';

export const GRID_MANIFEST: ComponentManifest = {
  type: 'GRID',
  name: 'Grid',
  descriptionId: 'GridDescription',
  description: '',
  icon: 'threeColumns',
  group: ComponentGroups['LAYOUT'],
  previewHeight: 100,
  previewWidth: 400,
  allowDrop: false,
  hasSections: true,
  properties: [
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.GRID,
      controlLabel: 'Grid',
      tooltip: 'designer.right_side.controls.components.grid.extraText'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['SUBMIT', 'CLICK']
};

export function getGridManifest(columns?: number): ComponentManifest {
  const manifest = {
    ...GRID_MANIFEST,
    name: `Grid${columns && columns > 1 ? columns : ''}`,
    icon: getIcon(columns),
    type: 'GRID' + (columns && columns > 1 ? columns : '')
  };
  return manifest as ComponentManifest;
}

const getIcon = (columns?: number) => {
  switch (columns) {
    case 1:
      return 'oneColumn';
    case 2:
      return 'twoColumns';
    case 3:
      return 'threeColumns';
    case 4:
      return 'fourColumns';
    default:
      return 'threeColumns';
  }
};
