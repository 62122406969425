import { UserProfile, UserTotals } from '../types/auth_types';

export const API_URL = process.env.REACT_APP_API_URL;

export default class Account {
  async getAccountInfo(): Promise<UserProfile> {
    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const options: RequestInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
        credentials: 'include'
      };

      const response = await fetch(`${API_URL}/account`, options);
      const body: any = await response.json();

      if (response.status !== 200) {
        throw new Error(body.message);
      }

      return body;
    } catch {
      throw new Error();
    }
  }

  async changeUserPreferences(key: string, value: any): Promise<void> {
    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const dict: any = { preferences: {} };
      dict.preferences[key] = value;

      const options: RequestInit = {
        method: 'PUT',
        headers: headers,
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(dict)
      };

      const response = await fetch(`${API_URL}/account/preferences`, options);

      // atualizar variável na sessão
      if (response.status !== 200) {
        throw new Error();
      }
    } catch {
      throw new Error().name;
    }
  }

  async getTotalsOrg(id: number): Promise<UserTotals> {
    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const options: RequestInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
        credentials: 'include'
      };

      const response = await fetch(`${API_URL}/user-totals/${id}`, options);
      const body: any = await response.json();

      if (response.status !== 200) {
        throw new Error(body.message);
      }

      return body;
    } catch {
      throw new Error();
    }
  }
}
