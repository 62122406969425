import { ServicesRepository } from '../repos';
import { Service } from '../types';
import { ServiceResponse } from '../repos/schemas/Services';

export class ServicesServ {
  async getService(serviceId: string): Promise<ServiceResponse> {
    return await ServicesRepository.getService(serviceId);
  }

  async getServices(moduleId: string, name?: string): Promise<ServiceResponse[]> {
    return await ServicesRepository.getServices(moduleId, name);
  }

  async createService(moduleId: string, service: Service): Promise<ServiceResponse> {
    return await ServicesRepository.createService(moduleId, service);
  }

  async deleteService(serviceId: string) {
    await ServicesRepository.deleteService(serviceId);
  }

  async updateService(serviceId: string, service: Service): Promise<ServiceResponse> {
    return await ServicesRepository.updateService(serviceId, service);
  }

  async changeFolder(serviceId: string, folderId: string) {
    return await ServicesRepository.changeFolder(serviceId, folderId);
  }
}
