import React from 'react';
import { ControlProps } from '../index';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import VariableControl from '../VariableControl';
import { Form } from 'react-bootstrap';
import { FRONTEND_VARIABLE_TYPES, VariableTypes } from 'modules/designer/types';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';

function SearchControl(props: ControlProps) {
  const { t } = useTranslation();

  return (
    <div className="mb-3 pb-2 border-bottom text-body-secondary">
      <div className="mb-2 mt-3 d-flex justify-content-between align-items-center">
        <div style={{ display: 'flex' }}>
          <label className="position-relative mb-1 text-body">
            {t('designer.right_side.controls.Search')}
          </label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.controls.SearchTitle') ||
                'designer.right_side.controls.SearchTitle',
              description:
                t('designer.right_side.controls.SearchDescription') ||
                'designer.right_side.controls.SearchDescription'
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </div>

        <Form.Check
          type="switch"
          id="custom-switch"
          checked={props.value?.searchable}
          value={props.value?.searchable}
          onChange={(e) => {
            props.onChange && props.onChange(e.target.checked, 'searchable');
          }}
        />
      </div>
      {props.value?.searchable && (
        <div className="mt-3">
          <label>
            <MissingMessage
              type={MissingMessageType.VARIABLE}
              uuid={props.value?.searchParam?.uuid}
              requiredTypes={[FRONTEND_VARIABLE_TYPES.STRING as VariableTypes]}
            />
            {t('designer.right_side.controls.SearchVariable')}
          </label>
          <VariableControl
            id={'searchParam'}
            key={'searchParam'}
            label={''}
            value={props.value?.searchParam}
            onChange={props.onChange}
            options={[FRONTEND_VARIABLE_TYPES.STRING]}
          />
        </div>
      )}
    </div>
  );
}

export default SearchControl;
