import { PaymentsService } from './services';
import { t } from 'i18next';

export type ActiveSubscriptionPlan = {
  recurrence: string;
  status: string;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  cancelAtDueDate: boolean;
  last4?: string;
  stripeLookupKey: StripeLookupKey;
  stripePriceId: string;
};

export const SUBSCRIPTION_STATUS: Record<string, string> = {
  past_due: t('PastDue') ?? 'PastDue',
  cancelled: t('Cancelled') ?? 'Cancelled',
  active: t('Active') ?? 'Active'
};

export const LOOKUP_KEYS: Record<string, string> = {
  free_plan: 'Free',
  basic_plan: 'Basic',
  starter_plan: 'Plus',
  professional_plan: 'Professional',
  enterprise_plan: 'Enterprise'
} as const;
export type LookupKey = keyof typeof LOOKUP_KEYS;

export const STRIPE_LOOKUP_KEYS: Record<string, string> = {
  free_plan: 'Free',
  basic_plan_yearly: 'Basic',
  starter_plan_yearly: 'Plus',
  professional_plan_yearly: 'Professional',
  basic_plan_monthly: 'Basic',
  starter_plan_monthly: 'Plus',
  professional_plan_monthly: 'Professional',
  enterprise_plan: 'Enterprise'
} as const;
export type StripeLookupKey = keyof typeof STRIPE_LOOKUP_KEYS;

export const StripeLookupKeyMap: Record<string, string> = {
  free_plan: 'free_plan',
  basic_plan_yearly: 'basic_plan',
  starter_plan_yearly: 'starter_plan',
  professional_plan_yearly: 'professional_plan',
  basic_plan_monthly: 'basic_plan',
  starter_plan_monthly: 'starter_plan',
  professional_plan_monthly: 'professional_plan',
  enterprise_plan: 'enterprise_plan'
};

export type SubscriptionPlanFeature = {
  name: LookupKey;
  description?: string;
  available: boolean;
  limit?: string;
  tooltip?: string;
};

/**
 * Subscription plans features.
 * The ORDER of features matters.
 * If available = true and limit = null then show 'checkmark' icon.
 */
export const SUBSCRIPTION_PLANS_FEATURES: Record<LookupKey, SubscriptionPlanFeature[]> = {
  free_plan: [
    {
      name: t('billing.DatabaseLogicInterface'),
      description: `${t('billing.DatabaseLogicInterfaceDesc')}`,
      available: true
    },
    {
      name: t('billing.VSCodePlugin'),
      description: `${t('billing.VSCodePluginDesc')}`,
      available: true
    },
    {
      name: t('billing.AdditionalModules'),
      description: `${t('billing.AdditionalModulesDesc')}`,
      available: false
    },
    {
      name: t('billing.GitHubSync'),
      description: `${t('billing.GitHubSyncDesc')}`,
      available: false
    },
    {
      name: t('billing.i18n'),
      description: `${t('billing.i18nDesc')}`,
      available: false
    },
    {
      name: t('billing.AutomatedTests'),
      description: `${t('billing.AutomatedTestsDesc')}`,
      available: false
    },
    {
      name: t('billing.FileStorage'),
      description: `${t('billing.FileStorageDesc')}`,
      available: true,
      limit: '100MB'
    },
    {
      name: t('billing.Support'),
      description: `${t('billing.SupportDesc')}`,
      available: true,
      limit: t('Community') ?? 'Community'
    },
    {
      name: t('billing.MaxUsers'),
      description: `${t('billing.MaxUsersDesc')}`,
      available: true,
      limit: '1'
    },
    {
      name: t('billing.MaxApps'),
      description: `${t('billing.MaxAppsDesc')}`,
      available: true,
      limit: '1'
    },
    {
      name: t('billing.Limitations'),
      available: false,
      tooltip: `${t('billing.LimitationsDesc')}`
    },
    {
      name: t('billing.Teams'),
      description: `${t('billing.TeamsDesc')}`,
      available: false
    }
  ],
  basic_plan: [
    { name: t('billing.DatabaseLogicInterface'), available: true },
    { name: t('billing.VSCodePlugin'), available: true },
    { name: t('billing.AdditionalModules'), available: true },
    { name: t('billing.GitHubSync'), available: true },
    { name: t('billing.i18n'), available: false },
    { name: t('billing.AutomatedTests'), available: false },
    { name: t('billing.FileStorage'), available: true, limit: '1GB' },
    { name: t('billing.Support'), available: true, limit: t('Online') ?? 'Online' },
    { name: t('billing.MaxUsers'), available: true, limit: '1' },
    { name: t('billing.MaxApps'), available: true, limit: '2' },
    { name: t('Limitations'), available: true, limit: t('Unlimited') ?? 'Unlimited' },
    { name: t('billing.Teams'), available: false }
  ],
  starter_plan: [
    { name: t('billing.DatabaseLogicInterface'), available: true },
    { name: t('billing.VSCodePlugin'), available: true },
    { name: t('billing.AdditionalModules'), available: true },
    { name: t('billing.GitHubSync'), available: true },
    { name: t('billing.i18n'), available: true },
    { name: t('billing.AutomatedTests'), available: true },
    { name: t('billing.FileStorage'), available: true, limit: '3GB' },
    {
      name: t('billing.Support'),
      available: true,
      limit: t('Online') ?? 'Online'
    },
    { name: t('billing.MaxUsers'), available: true, limit: '3' },
    { name: t('billing.MaxApps'), available: true, limit: '5' },
    { name: t('Limitations'), available: true, limit: t('Unlimited') ?? 'Unlimited' },
    { name: t('billing.Teams'), available: true, limit: '1' }
  ],
  professional_plan: [
    { name: t('billing.DatabaseLogicInterface'), available: true },
    { name: t('billing.VSCodePlugin'), available: true },
    { name: t('billing.AdditionalModules'), available: true },
    { name: t('billing.GitHubSync'), available: true },
    { name: t('billing.i18n'), available: true },
    { name: t('billing.AutomatedTests'), available: true },
    { name: t('billing.FileStorage'), available: true, limit: '10GB' },
    {
      name: t('billing.Support'),
      available: true,
      limit: t('billing.OnlinePlusPhone') ?? 'billing.OnlinePlusPhone'
    },
    { name: t('billing.MaxUsers'), available: true, limit: '10' },
    { name: t('billing.MaxApps'), available: true, limit: '15' },
    { name: t('Limitations'), available: true, limit: t('Unlimited') ?? 'Unlimited' },
    { name: t('billing.Teams'), available: true, limit: '5' }
  ],
  enterprise_plan: [
    { name: t('billing.DatabaseLogicInterface'), available: true },
    { name: t('billing.VSCodePlugin'), available: true },
    { name: t('billing.AdditionalModules'), available: true },
    { name: t('billing.GitHubSync'), available: true },
    { name: t('billing.i18n'), available: true },
    { name: t('billing.AutomatedTests'), available: true },
    { name: t('billing.FileStorage'), available: true, limit: t('Custom') ?? 'Custom' },
    {
      name: t('billing.Support'),
      available: true,
      limit: t('billing.OnlinePlusPhone') ?? 'billing.OnlinePlusPhone'
    },
    { name: t('billing.MaxUsers'), available: true, limit: t('Custom') ?? 'Custom' },
    { name: t('billing.MaxApps'), available: true, limit: t('Custom') ?? 'Custom' },
    { name: t('Limitations'), available: true, limit: t('Unlimited') ?? 'Unlimited' },
    { name: t('billing.Teams'), available: true, limit: t('Custom') ?? 'Custom' }
  ]
};

// Fetch available subscription plans from stripe.
export const getSubscriptions = async () => {
  const fetchedPlans = await PaymentsService.getSubscriptionPlans();
  const plans: any[] = [];
  for (const price of fetchedPlans.prices) {
    const plan: any = {};
    plan.price = price;
    plans.push(plan);
  }
  return plans;
};

export const getActiveSubscription = async (userId: number): Promise<ActiveSubscriptionPlan> => {
  const fetchedSubscription = await PaymentsService.getUserActiveSubscriptions(userId);
  const sub: ActiveSubscriptionPlan = {
    recurrence: fetchedSubscription.paymentRecurrence,
    status: fetchedSubscription.status,
    currentPeriodStart: fetchedSubscription.cycleStart,
    currentPeriodEnd: fetchedSubscription.cycleEnd,
    cancelAtDueDate: fetchedSubscription.cancelAtDueDate,
    last4: fetchedSubscription.last4,
    stripeLookupKey: fetchedSubscription.stripeLookupKey,
    stripePriceId: fetchedSubscription.stripePriceId
  };
  return sub;
};

export const disablePortalCreator = (email: string): boolean => {
  let isAdmin = false;
  if (email === 'admin@mail.com' && process.env.NODE_ENV === 'development') {
    isAdmin = true;
  }
  return isAdmin;
};
