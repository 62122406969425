import React, { useState } from 'react';
import { CrudData } from '../../..';
import {
  CrudBodyType,
  CrudBodyTypeKeys,
  DATA_TYPES,
  FunctionParameterCrud
} from 'modules/logic_builder/types';
import { functionEditorCrud, ReturnType } from 'web_ui/function_editor/store/types/functions';
import NameEditor from './signature/name_editor';
import styles from './styles.module.css';
import DataTypePicker from './signature/data_picker';
import { Form } from 'react-bootstrap';
import useFetchTypes from 'web_ui/function_editor/action_inputs/utils/useFetchTypes';
import ParameterListEditorCrud from './params';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import HeaderSignatureCheck from '../../../../../../../../web_ui/function_editor/components/editor/function_header/header_signature_check';

type Props = {
  crudData: CrudData;
  currentFunction: functionEditorCrud;
  onChange: (crudData: CrudData) => void;
};

export default function FunctionEditor({ crudData, currentFunction, onChange }: Props) {
  const indexForFunctToUpdate = crudData.functions
    .filter((f) => f.isNew)
    .findIndex((item) => item.uuid === currentFunction.uuid);
  const { fetchedTypes, enums, objects } = useFetchTypes(Object.values(DATA_TYPES), 'spring');
  const [currentType, setCurrentType] = useState<ReturnType>({
    type: crudData.functions.filter((f) => f.isNew)[indexForFunctToUpdate].returnType,
    enumUuid: crudData.functions.filter((f) => f.isNew)[indexForFunctToUpdate].returnEnumUuid,
    list: crudData.functions.filter((f) => f.isNew)[indexForFunctToUpdate].returnList,
    name: crudData.functions.filter((f) => f.isNew)[indexForFunctToUpdate].name,
    objectUuid: crudData.functions.filter((f) => f.isNew)[indexForFunctToUpdate].returnObjectUuid
  });
  const { t } = useTranslation();

  /* CHANGE TYPE */
  const handleChangeDataType = (val: ReturnType) => {
    const allFunc = crudData.functions;
    const indexForFunctToUpdate = allFunc.findIndex((item) => item.uuid === currentFunction.uuid);

    // changing object data
    allFunc[indexForFunctToUpdate].returnType = val.type;
    allFunc[indexForFunctToUpdate].returnEnumUuid = val.enumUuid!;
    allFunc[indexForFunctToUpdate].returnList = val.list!;
    if (val.name) {
      allFunc[indexForFunctToUpdate].name = val.name;
    }
    allFunc[indexForFunctToUpdate].returnObjectUuid = val.objectUuid!;

    const crud = {
      ...crudData,
      functions: allFunc
    };

    onChange(crud);

    setCurrentType({
      type: allFunc[indexForFunctToUpdate].returnType,
      enumUuid: allFunc[indexForFunctToUpdate].returnEnumUuid,
      list: allFunc[indexForFunctToUpdate].returnList,
      name: allFunc[indexForFunctToUpdate].name,
      objectUuid: allFunc[indexForFunctToUpdate].returnObjectUuid
    });
  };

  /* CHANGE NAME */
  const handleChangeName = (val: string) => {
    const allFunc = crudData.functions;
    const indexForFunctToUpdate = allFunc.findIndex((item) => item.uuid === currentFunction.uuid);

    // updating
    allFunc[indexForFunctToUpdate].name = val;

    // updating description
    allFunc[indexForFunctToUpdate].description = val + ' function'; // someName function

    const crud = {
      ...crudData,
      functions: allFunc
    };

    onChange(crud);
  };

  function handleChangeList(checked: boolean): void {
    const allFunc = crudData.functions;
    const indexForFunctToUpdate = allFunc.findIndex((item) => item.uuid === currentFunction.uuid);

    // updating
    allFunc[indexForFunctToUpdate].returnList = checked;

    const crud = {
      ...crudData,
      functions: allFunc
    };

    onChange(crud);

    setCurrentType({ ...currentType, list: checked });
  }

  const handleChangeParameters = (parameters: FunctionParameterCrud[]) => {
    const allFunc = crudData.functions;
    const indexForFunctToUpdate = allFunc.findIndex((item) => item.uuid === currentFunction.uuid);

    // updating
    allFunc[indexForFunctToUpdate].parameters = parameters;

    const crud = {
      ...crudData,
      functions: allFunc
    };

    onChange(crud);
  };

  const handlePagination = (checked: boolean) => {
    if (checked) {
      const funcUpdate =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ];

      funcUpdate.returnType = 'PAGE';
      funcUpdate.returnList = true;
      funcUpdate.pageable = true;

      const allParams =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ].parameters;

      const newParamPaginationSize: FunctionParameterCrud = {
        uuid: uuidv4(),
        name: 'size',
        description: '...',
        list: false,
        order: allParams.length,
        required: true,
        type: 'INTEGER',
        enumUuid: undefined,
        native: true,
        objectUuid: undefined
      };

      const newParamPaginationPage: FunctionParameterCrud = {
        uuid: uuidv4(),
        name: 'page',
        description: '...',
        list: false,
        order: allParams.length + 1,
        required: true,
        type: 'INTEGER',
        enumUuid: undefined,
        native: true,
        objectUuid: undefined
      };

      allParams.push(newParamPaginationSize);
      allParams.push(newParamPaginationPage);

      funcUpdate.parameters = allParams;

      // updating the crudData
      const allFunc = crudData.functions;
      const indexFunc = crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid); // correct func

      setCurrentType({
        type: funcUpdate.returnType,
        enumUuid: funcUpdate.returnEnumUuid,
        list: funcUpdate.returnList,
        name: funcUpdate.name,
        objectUuid: funcUpdate.returnObjectUuid
      });

      allFunc[indexFunc] = funcUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        functions: allFunc
      };

      onChange(crud);
    } else {
      const funcUpdate =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ];

      funcUpdate.pageable = false;
      funcUpdate.returnType = 'OBJECT';
      funcUpdate.returnList = funcUpdate.crudType === 'READ_MANY';

      const allParams =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ].parameters;

      allParams.splice(
        allParams.findIndex((item) => item.name === 'size'),
        1
      );
      allParams.splice(
        allParams.findIndex((item) => item.name === 'page'),
        1
      );

      funcUpdate.parameters = allParams;

      // updating the crudData
      const allFunc = crudData.functions;
      const indexFunc = crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid); // correct func

      setCurrentType({
        type: funcUpdate.returnType,
        enumUuid: funcUpdate.returnEnumUuid,
        list: funcUpdate.returnList,
        name: funcUpdate.name,
        objectUuid: funcUpdate.returnObjectUuid
      });

      allFunc[indexFunc] = funcUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        functions: allFunc
      };

      onChange(crud);
    }
  };

  const handleSort = (checked: boolean) => {
    if (checked) {
      const funcUpdate =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ];

      funcUpdate.sortable = true;

      const allParams =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ].parameters;

      const newParamPaginationSort: FunctionParameterCrud = {
        uuid: uuidv4(),
        name: 'sort',
        description: '...',
        list: false,
        order: allParams.length,
        required: true,
        type: 'STRING',
        enumUuid: undefined,
        native: true,
        objectUuid: undefined
      };

      allParams.push(newParamPaginationSort);

      funcUpdate.parameters = allParams;

      // updating the crudData
      const allFunc = crudData.functions;
      const indexFunc = crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid); // correct func

      allFunc[indexFunc] = funcUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        functions: allFunc
      };

      onChange(crud);
    } else {
      const funcUpdate =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ];

      funcUpdate.sortable = false;

      const allParams =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ].parameters;

      allParams.splice(
        allParams.findIndex((item) => item.name === 'sort'),
        1
      );

      funcUpdate.parameters = allParams;

      // updating the crudData
      const allFunc = crudData.functions;
      const indexFunc = crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid); // correct func

      allFunc[indexFunc] = funcUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        functions: allFunc
      };

      onChange(crud);
    }
  };

  const handleFilterable = (checked: boolean) => {
    if (checked) {
      const funcUpdate =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ];

      funcUpdate.filterable = true;

      const allParams =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ].parameters;

      const newParamPaginationSort: FunctionParameterCrud = {
        uuid: uuidv4(),
        name: 'filter',
        description: '...',
        list: false,
        order: allParams.length,
        required: true,
        type: 'STRING',
        enumUuid: undefined,
        native: true,
        objectUuid: undefined
      };

      allParams.push(newParamPaginationSort);

      funcUpdate.parameters = allParams;

      // updating the crudData
      const allFunc = crudData.functions;
      const indexFunc = crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid); // correct func

      allFunc[indexFunc] = funcUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        functions: allFunc
      };

      onChange(crud);
    } else {
      const funcUpdate =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ];

      funcUpdate.filterable = false;

      const allParams =
        crudData.functions[
          crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
        ].parameters;

      allParams.splice(
        allParams.findIndex((item) => item.name === 'filter'),
        1
      );

      funcUpdate.parameters = allParams;

      // updating the crudData
      const allFunc = crudData.functions;
      const indexFunc = crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid); // correct func

      allFunc[indexFunc] = funcUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        functions: allFunc
      };

      onChange(crud);
    }
  };

  const handleChangeRelatedEntityData = (val: string) => {
    const convertStr = val as CrudBodyTypeKeys;

    const funcUpdate =
      crudData.functions[
        crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid)
      ];

    funcUpdate.crudBodyType = CrudBodyType[convertStr];

    // updating Crud
    const allFunc = crudData.functions;
    const indexFunc = crudData.functions.findIndex((item) => item.uuid === currentFunction.uuid);

    allFunc[indexFunc] = funcUpdate;

    const crud = {
      ...crudData,
      functions: allFunc
    };

    onChange(crud);
  };

  return (
    <div className={`border-primary bg-body-tertiary ${styles.HeaderEditorWrapper}`}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.ReturnTypeAndName}>
          <HeaderSignatureCheck functionId={currentFunction.uuid} />
          <div className={styles.ReturnType}>
            <DataTypePicker
              type={currentType!}
              types={fetchedTypes}
              handleChange={handleChangeDataType}
              enums={enums}
              objects={objects}
              disabled={true}
            />
            <div className={styles.ReturnTypeListCheck}>
              <Form.Label style={{ marginBottom: '0' }}>{t('automation.step3.list')}</Form.Label>
              <Form.Check
                disabled
                checked={currentType && currentType.list ? currentType.list : false}
                onChange={(e) => handleChangeList(e.target.checked)}
              />
            </div>
          </div>
          <NameEditor
            handleChangeName={handleChangeName}
            name={
              crudData.functions.filter((f) => f.isNew)[
                crudData.functions
                  .filter((f) => f.isNew)
                  .findIndex((item) => item.uuid === currentFunction.uuid)
              ].name
            }
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              marginLeft: '1rem'
            }}
          >
            <p style={{ color: '#3B6863', padding: 0, margin: 0 }}>{`(`}</p>
            <p
              style={{
                padding: 0,
                margin: 0,
                marginRight: 10,
                marginLeft: 10,
                textDecoration: 'underline',
                color: '#446089'
              }}
            >
              params
            </p>
            <p style={{ color: '#3B6863', padding: 0, margin: 0 }}>{`)`}</p>
          </div>
        </div>
        <ParameterListEditorCrud
          types={fetchedTypes}
          params={
            crudData.functions.filter((f) => f.isNew)[
              crudData.functions
                .filter((f) => f.isNew)
                .findIndex((item) => item.uuid === currentFunction.uuid)
            ].parameters
          }
          handleChangeParameters={handleChangeParameters}
        />
      </div>
      <div className={styles.container}>
        {crudData.functions.filter((f) => f.isNew)[
          crudData.functions
            .filter((f) => f.isNew)
            .findIndex((item) => item.uuid === currentFunction.uuid)
        ].crudType === 'READ_MANY' && (
          <div className={styles.optionsWrapper}>
            <div className={styles.checkOption}>
              <label style={{ marginRight: '.5rem' }}>{t('automation.step3.pagination')}: </label>
              <Form.Check
                checked={
                  crudData.functions.filter((f) => f.isNew)[
                    crudData.functions
                      .filter((f) => f.isNew)
                      .findIndex((item) => item.uuid === currentFunction.uuid)
                  ].pageable
                }
                onChange={(val) => handlePagination(val.target.checked)}
              />
            </div>
            <div className={styles.checkOption}>
              <label style={{ marginRight: '.5rem' }}>{t('automation.step3.sort')}: </label>
              <Form.Check
                checked={
                  crudData.functions.filter((f) => f.isNew)[
                    crudData.functions
                      .filter((f) => f.isNew)
                      .findIndex((item) => item.uuid === currentFunction.uuid)
                  ].sortable
                }
                onChange={(val) => handleSort(val.target.checked)}
              />
            </div>
            <div className={styles.checkOption}>
              <label style={{ marginRight: '.5rem' }}>{t('automation.step3.filter')}: </label>
              <Form.Check
                checked={
                  crudData.functions.filter((f) => f.isNew)[
                    crudData.functions
                      .filter((f) => f.isNew)
                      .findIndex((item) => item.uuid === currentFunction.uuid)
                  ].filterable
                }
                onChange={(val) => handleFilterable(val.target.checked)}
              />
            </div>
          </div>
        )}
        <div className={styles.containerRelatedEntityData}>
          <Form.Label style={{ marginLeft: '2rem', marginRight: '0.5rem' }}>
            {t('automation.step3.RED')}:
          </Form.Label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Select
              id="selectRelatedEntity"
              style={{ width: '300px' }}
              value={currentFunction.crudBodyType}
              onChange={(e) => handleChangeRelatedEntityData(e.target.value)}
            >
              <option id={CrudBodyType.OBJECT.toLowerCase()} value={CrudBodyType.OBJECT}>
                {t('automation.step3.bodyTypeObject')}
              </option>
              <option
                id={CrudBodyType.PARENT_CHILD.toLowerCase()}
                value={CrudBodyType.PARENT_CHILD}
              >
                {t('automation.step3.bodyTypeParentChild')}
              </option>
              <option
                id={CrudBodyType.BUSINESS_RULES.toLowerCase()}
                value={CrudBodyType.BUSINESS_RULES}
              >
                {t('automation.step3.bodyTypeBusinessRules')}
              </option>
            </Form.Select>
            <HelpPopover
              helpBoxProps={{
                description: `${t('automation.step3.overlayObject')!}\n
                ${t('automation.step3.overlayParentChild')!}\n
                ${t('automation.step3.overlayBusinessRules')!}`
              }}
              placement="top"
            >
              <div style={{ marginLeft: '0.5rem' }}>
                <Icon iconName="circle-question" brands="regular" extraProps="fa-xl" />
              </div>
            </HelpPopover>
          </div>
        </div>
      </div>
    </div>
  );
}
