import { AppFullInfo } from '../../modules/project/types';
import { FullAppList } from './components/step0/data';
import { TemplateList } from './components/step1/data';
import { BackendFrameworkList, DatabaseList, additionalmodules } from './components/step4/data';
import { FrontendFrameworkList, ThemesList } from './components/step5/data';
import { Architecture, Dependency } from 'modules/project/enum';

export const initialAppValue: AppFullInfo = {
  name: '',
  domain: '',
  description: '',
  mainLanguage: { id: '', locale: '', name: '' },
  type: FullAppList[0].type,
  template: TemplateList[0].id,
  architecture: Architecture.MONOLITH,
  backend: {
    modules: additionalmodules[0].id,
    framework: BackendFrameworkList[0].id,
    options: {}
  },
  frontend: {
    framework: FrontendFrameworkList[0].id,
    theme: ThemesList[0].id,
    dependencies: [Dependency.BOOTSTRAP],
    options: {}
  },
  data_sources: [
    {
      type: DatabaseList[0].id,
      options: {}
    }
  ],
  has_authentication: false,
  has_assets: false,
  isSql: false,
  has_frontend: true,
  has_backend: true,
  has_database: true
};
