import { Action } from 'redux';
import { ComponentUUID, LayoutComponent, MoveComponentPayload, ViewUUID } from '../../../types';
import { MoveSource, MoveTarget } from './links';

export const ADD_COMPONENT = 'ADD_COMPONENT';
export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const ADD_CUSTOM_COMPONENT = 'ADD_CUSTOM_COMPONENT';
export const ADD_DETACHED_CUSTOM_COMPONENT = 'ADD_DETACHED_CUSTOM_COMPONENT';
export const CREATE_CUSTOM_COMPONENT = 'CREATE_CUSTOM_COMPONENT';
export const CREATE_DETACHED_CUSTOM_COMPONENT = 'CREATE_DETACHED_CUSTOM_COMPONENT';
export const EXTRACT_FORM = 'EXTRACT_FORM';
export const ADD_COMPONENT_SET = 'ADD_COMPONENT_SET';
export const ADD_AND_MOVE_COMPONENTS_INTO = 'ADD_AND_MOVE_COMPONENTS_INTO';
export const ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO = 'ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO';
export const MOVE_COMPONENT = 'MOVE_COMPONENT';

export type LinkInfo = {
  viewUUID: ViewUUID;
  parentUUID: ComponentUUID;
  section?: string;
  targetUuid?: string;
  adjacentSide?: string;
};

export type LinkInfoModified = {
  view: ViewUUID;
  parent: ComponentUUID;
  section?: string;
};

// todo(custom): rename to CustomComponents and add other data aswell
// maybe match response from backend
export type CustomComponentsChildren = {
  components: { [key: ComponentUUID]: LayoutComponent };
  links: { [key: ComponentUUID]: ComponentUUID[] };
};

export type ComponentPayload = { component: LayoutComponent; link: LinkInfo };

export interface CreateCustomComponentAction extends Action {
  type: 'CREATE_CUSTOM_COMPONENT';
  payload: {
    componentUUID: ComponentUUID;
    customComponentUUID: ComponentUUID;
    custom_uuid: string;
    name: string;
    description: string;
  };
}

export interface AddCustomComponentAction extends Action {
  type: 'ADD_CUSTOM_COMPONENT';
  payload: {
    uuid: ComponentUUID;
    link: LinkInfo;
    customComponentsChildren: CustomComponentsChildren;
    newCustomComponentUUID: ComponentUUID;
  };
}

export interface AddDetachedCustomComponentAction extends Action {
  type: 'ADD_DETACHED_CUSTOM_COMPONENT';
  payload: {
    rootId: string;
    link: LinkInfoModified;
    customComponentsState: CustomComponentsChildren;
    oldIdsNewIds: Record<string, string>;
  };
}

export interface AddComponentAction extends Action {
  type: 'ADD_COMPONENT';
  payload: ComponentPayload;
}

export interface AddComponentSetAction extends Action {
  type: 'ADD_COMPONENT_SET';
  payload: { componentSet: ComponentPayload[] };
}

export interface DeleteComponentAction extends Action {
  type: 'DELETE_COMPONENT';
  payload: {
    uuid: ComponentUUID;
    parentUUID?: ComponentUUID;
    section?: string;
    emptyParent?: boolean;
  };
}

export interface CreateDetachedCustomComponentAction extends Action {
  type: 'CREATE_DETACHED_CUSTOM_COMPONENT';
  payload: {
    // Converted component.
    component: string;
    name: string;
    description: string;
  };
}

export interface ExtractFormAction extends Action {
  type: 'EXTRACT_FORM';
  payload: { form: ComponentUUID; link: LinkInfo; components: LayoutComponent[] };
}

export interface AddAndMoveComponentsIntoAction extends Action {
  type: 'ADD_AND_MOVE_COMPONENTS_INTO';
  payload: {
    componentToAdd: ComponentPayload;
    componentsToMove: MoveComponentPayload[];
  };
}

export interface MoveComponentAction extends Action {
  type: 'MOVE_COMPONENT';
  payload: {
    source: MoveSource;
    target: MoveTarget;
  };
}

export interface AddMultiplesAndMoveComponentsIntoAction extends Action {
  type: 'ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO';
  payload: {
    componentsToAdd: ComponentPayload[];
    componentsToMove: MoveComponentPayload[];
  };
}

export const addComponent = (component: LayoutComponent, link: LinkInfo): AddComponentAction => ({
  type: ADD_COMPONENT,
  payload: { component, link }
});

export const addComponentSet = (componentSet: ComponentPayload[]): AddComponentSetAction => ({
  type: ADD_COMPONENT_SET,
  payload: { componentSet }
});

export const deleteComponent = (
  uuid: ComponentUUID,
  parentUUID?: ComponentUUID,
  section?: string,
  emptyParent?: boolean
): DeleteComponentAction => ({
  type: DELETE_COMPONENT,
  payload: { uuid, parentUUID, section, emptyParent }
});

export const createCustomComponent = (
  componentUUID: ComponentUUID,
  customComponentUUID: ComponentUUID,
  custom_uuid: string,
  name: string,
  description: string
): CreateCustomComponentAction => ({
  type: CREATE_CUSTOM_COMPONENT,
  payload: {
    componentUUID,
    customComponentUUID,
    custom_uuid,
    name,
    description
  }
});

export const addCustomComponent = (
  uuid: ComponentUUID,
  link: LinkInfo,
  customComponentsChildren: CustomComponentsChildren,
  newCustomComponentUUID: ComponentUUID
): AddCustomComponentAction => ({
  type: ADD_CUSTOM_COMPONENT,
  payload: { uuid, link, customComponentsChildren, newCustomComponentUUID }
});

export const addDetachedCustomComponent = (
  rootId: string,
  link: LinkInfoModified,
  customComponentsState: CustomComponentsChildren,
  oldIdsNewIds: Record<string, string>
): AddDetachedCustomComponentAction => ({
  type: ADD_DETACHED_CUSTOM_COMPONENT,
  payload: { rootId, link, customComponentsState, oldIdsNewIds }
});

export const createDetachedCustomComponent = (
  component: string,
  name: string,
  description: string
): CreateDetachedCustomComponentAction => ({
  type: CREATE_DETACHED_CUSTOM_COMPONENT,
  payload: { component, name, description }
});

export const extractForm = (
  form: ComponentUUID,
  link: LinkInfo,
  components: LayoutComponent[]
): ExtractFormAction => ({
  type: EXTRACT_FORM,
  payload: { form, link, components }
});

export const addAndMoveComponentsInto = (
  componentToAdd: ComponentPayload,
  componentsToMove: MoveComponentPayload[]
): AddAndMoveComponentsIntoAction => ({
  type: ADD_AND_MOVE_COMPONENTS_INTO,
  payload: { componentToAdd, componentsToMove }
});

export const moveComponent = (source: MoveSource, target: MoveTarget): MoveComponentAction => ({
  type: MOVE_COMPONENT,
  payload: { source, target }
});

export const addMultiplesAndMoveComponentsInto = (
  componentsToAdd: ComponentPayload[],
  componentsToMove: MoveComponentPayload[]
): AddMultiplesAndMoveComponentsIntoAction => ({
  type: ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO,
  payload: { componentsToAdd, componentsToMove }
});
