import React from 'react';

function RestIcon() {
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <i className="fa fa-cloud text-secondary-emphasis" style={{ fontSize: '1.4rem' }} />
      <i
        className="fas fa-cog "
        style={{
          fontSize: '.7rem',
          marginLeft: '-10px',
          borderRadius: '50%',
          padding: '0.1rem',
          color: 'var(--bs-primary)',
          background: 'var(--bs-secondary-text-emphasis)'
        }}
      />
    </span>
  );
}

export default RestIcon;
