import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Index, IndexID, IndexType, sortOrder, TableUUID } from 'modules/modeler/types';
import { DatabaseStudioState, indexColumnState } from 'modules/modeler/studio/store';
import { makeIndex } from 'routes/studio/data/elements/factory';
import { addIndex } from 'modules/modeler/studio/store/actions/root';
import ActionsBar from '../../shared/actions_bar';
import IndexesList from './components/indexes_list';
import styles from './styles.module.css';
import IndexEditor from './components/index_editor';
import { v4 as uuidv4 } from 'uuid';
import { sleep } from '../../../../../../../utils/utils';
import { updateIndexColumns } from '../../../../store/actions/indexes';

type TableIndexesProps = {
  tableID: TableUUID;
};

/**
 * Indexes tab, used to manage table indexes data.
 * ex: (name, type, order, sort, etc...)
 *
 * @component
 */
function TableIndexes({ tableID }: TableIndexesProps) {
  const dispatch = useDispatch();
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const indexes = useSelector((state: DatabaseStudioState) => state.indexes);
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  // Select first available Index.
  const [selectedIndex, setSelectedIndex] = useState(
    indexes[tables[tableID].content.data.indexes[0]] ? tables[tableID].content.data.indexes[0] : ''
  );

  // Filter indexes.
  const [searchText, setSearchText] = useState('');
  const tableIndexes: Index[] = [];
  tables[tableID].content.data.indexes.forEach((indexID: IndexID) => {
    if (searchText && !indexes[indexID].name.toLowerCase().includes(searchText)) {
      return;
    }
    tableIndexes.push(indexes[indexID]);
  });

  const handleCreateIndex = async () => {
    if (!tableID) return;
    let newIndexName = `index_${tables[tableID].content.data.indexes.length}`;
    if (checkExistingName(newIndexName)) newIndexName += '_1';
    const firstTableColumnId = getFirstTableColumnId();
    if (!firstTableColumnId) return;
    // create an index object with the specified data
    const newIndex: Index = makeIndex(newIndexName, IndexType.INDEX);
    // add the index on the index array context
    dispatch(addIndex(newIndex, tableID));
    // ?
    await sleep(100);
    const newIndexColumn = {
      id: uuidv4(),
      columnId: firstTableColumnId,
      sortOrder: sortOrder.ASC,
      columnOrder: 0
    };
    const indexColumns: indexColumnState = {};
    indexColumns[newIndexColumn.id] = { ...newIndexColumn };
    dispatch(updateIndexColumns(newIndex.id, indexColumns));
    // select the new index
    setSelectedIndex(newIndex.id);
  };

  const checkExistingName = (name: string) => {
    for (const indexID of Object.values(tables[tableID].content.data.indexes)) {
      if (!indexID) return false;
      if (indexes[indexID as IndexID]?.name === name) {
        return true;
      }
    }
    return false;
  };

  const getFirstTableColumnId = () => {
    if (!tableID) return null;
    for (const c of Object.values(columns)) {
      if (c.tableUUID === tableID) return c.uuid;
    }
  };

  return (
    <div id={'IndexEditorWrapper'} className={styles.IndexEditorWrapper}>
      <ActionsBar
        addNew={handleCreateIndex}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div className={styles.ViewAndPropertyWrapper}>
        <IndexesList
          tableIndexes={tableIndexes}
          selectedIndexID={selectedIndex}
          selectIndex={setSelectedIndex}
        />
        <IndexEditor
          selectedIndex={selectedIndex}
          selectedTable={tableID}
          selectIndex={setSelectedIndex}
        />
      </div>
    </div>
  );
}

export default TableIndexes;
