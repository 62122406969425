export type WidgetUUID = string;

export const WidgetType = {
  STICKY_NOTE: 'STICKY_NOTE'
} as const;

export interface Widget {
  uuid: WidgetUUID;
  parent: string;
  type: keyof typeof WidgetType;
  posX: number;
  posY: number;
  // Is there a way to enforce the type of WidgetData based on the WidgetTyoe?
  data: any;
}
