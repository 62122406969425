import React from 'react';
import { Form, Button } from 'react-bootstrap';
import styles from './styles.module.css';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import { Authorization } from 'modules/auth/session/authorization';

type DropdownMenuProps = {
  children: React.ReactNode;
  openModal?: () => void;
  className?: string;
  filterPlaceholder?: string;
  buttonLabel?: string;
  authorityContext?: ContextRole;
  allowedAuthorities?: RoleAuthorities[];
};

export const DropdownMenu = React.forwardRef(
  (props: DropdownMenuProps, ref: React.Ref<HTMLDivElement>) => {
    const [search, setSearch] = React.useState('');

    /**
     * If props.children is modified this also needs to be adapted.
     * This assumes that the first children (children[0]) contains the filter text.
     */
    const filterPages = (child: React.ReactElement) => {
      return (
        !search ||
        child.props.children[0].props.children.trim().toLowerCase().includes(search.toLowerCase())
      );
    };

    const handleOpenModal = () => {
      if (props.openModal) {
        props.openModal();
      }
    };

    return (
      <div
        ref={ref}
        className={props.className}
        style={{
          position: 'absolute'
        }}
      >
        {props.filterPlaceholder && (
          <div className="border-bottom border-dark">
            <Form.Control
              id="searchField"
              autoFocus
              className="mx-3 my-2 w-auto "
              placeholder={props.filterPlaceholder}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        )}
        <ul className={`list-unstyled ${styles.DropdownItemList}`}>
          {React.Children.toArray(props.children).filter((child) =>
            filterPages(child as React.ReactElement)
          )}
        </ul>
        {props.buttonLabel && !props.allowedAuthorities && !props.authorityContext && (
          <div className="border-top border-dark">
            <Button className={styles.NewItemButton} onClick={() => handleOpenModal()}>
              {props.buttonLabel}
            </Button>
          </div>
        )}
        {props.buttonLabel && props.allowedAuthorities && props.authorityContext && (
          <Authorization
            context={props.authorityContext}
            allowedAuthorities={props.allowedAuthorities}
          >
            <div className="border-top border-dark">
              <Button
                id="createModuleButton"
                className={styles.NewItemButton}
                onClick={() => handleOpenModal()}
              >
                {props.buttonLabel}
              </Button>
            </div>
          </Authorization>
        )}
      </div>
    );
  }
);
