import { Action } from 'redux';
import { ParametersState } from '../../types/parameters';

export const UPDATE_PARAM = 'UPDATE_PARAM';

export interface UpdateParamAction extends Action {
  type: 'UPDATE_PARAM';
  payload: {
    uuid: string;
    name: string;
    desc: string;
    relationshipId?: string;
  };
}

export const updateParam = (
  uuid: string,
  name: string,
  desc: string,
  relationshipId = ''
): UpdateParamAction => ({
  type: UPDATE_PARAM,
  payload: { uuid, name, desc, relationshipId }
});

export function doUpdateParam(state: ParametersState, action: UpdateParamAction): ParametersState {
  const { uuid, name, desc, relationshipId } = action.payload;
  state[uuid] = { ...state[uuid], name, desc, relationshipId };
  return state;
}
