import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormatterProps, FormatterTypes, TRAFFICLIGHT_MANIFEST } from '../../types';
import { Button, Col, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../../../components/Popover';
import HelpIcon from '../../../components/HelpIcon';
import Icon from 'web_ui/icon';

function TrafficLightFormatter(props: FormatterProps) {
  const [newColor, setNewColor] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.TRAFFICLIGHT) {
      props.onChange && props.onChange(props.index, 'formatterParams', TRAFFICLIGHT_MANIFEST);
    }
  }, []);

  function handleChange(field: string, colors?: string[], e?: ChangeEvent<HTMLInputElement>) {
    const updatedDatetimeParams = { ...props.value };
    updatedDatetimeParams[field] = e ? e.target.value : colors;
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedDatetimeParams);
  }

  function handleAddColor() {
    if (!props.value) return;

    const newColors: string[] = [...props.value.colors];
    newColors.push(newColor);

    handleChange('colors', newColors);
  }

  function handleRemoveColor(keyToRemove: number) {
    if (!props.value) return;

    const newColors = props.value.colors.filter(
      (key: string, index: number) => index !== keyToRemove
    );
    handleChange('colors', newColors);
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Col sm={5}>
          <Form.Group>
            <Form.Label column>
              {t('designer.right_side.FormatterControls.TrafficLightFormatterMin')}
            </Form.Label>
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.FormatterControls.TrafficLightFormatterMinTitle') ||
                  'designer.right_side.FormatterControls.TrafficLightFormatterMinTitle',
                description:
                  t('designer.right_side.FormatterControls.TrafficLightFormatterMinDescription') ||
                  'designer.right_side.FormatterControls.TrafficLightFormatterMinDescription'
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
            <Form.Control
              type="number"
              placeholder="e.g.: 10"
              value={props.value && props.value.min}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange('min', undefined, e);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={5}>
          <Form.Group>
            <Form.Label column>
              {t('designer.right_side.FormatterControls.TrafficLightFormatterMax')}
            </Form.Label>
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.FormatterControls.TrafficLightFormatterMaxTitle') ||
                  'designer.right_side.FormatterControls.TrafficLightFormatterMaxTitle',
                description:
                  t('designer.right_side.FormatterControls.TrafficLightFormatterMaxDescription') ||
                  'designer.right_side.FormatterControls.TrafficLightFormatterMaxDescription'
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
            <Form.Control
              type="number"
              placeholder="e.g.: 10"
              value={props.value && props.value.max}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange('max', undefined, e);
              }}
            />
          </Form.Group>
        </Col>
      </div>

      <div className="mb-3 d-flex justify-content-between align-items-end">
        <Col sm={10}>
          <Form.Group>
            <Form.Label column>
              {t('designer.right_side.FormatterControls.TrafficLightFormatterColor')}
            </Form.Label>
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.FormatterControls.TrafficLightFormatterColorTitle') ||
                  'designer.right_side.FormatterControls.TrafficLightFormatterColorTitle',
                description:
                  t(
                    'designer.right_side.FormatterControls.TrafficLightFormatterColorDescription'
                  ) ||
                  'designer.right_side.FormatterControls.TrafficLightFormatterColorDescription',
                note: [
                  t('designer.right_side.FormatterControls.TrafficLightFormatterColorNote01') ||
                    'designer.right_side.FormatterControls.TrafficLightFormatterColorNote01'
                ]
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
            <Form.Control
              type="color"
              placeholder="value"
              value={newColor}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setNewColor(e.target.value)}
            />
          </Form.Group>
        </Col>

        <Button
          variant="secondary"
          size="sm"
          disabled={newColor === ''}
          onClick={handleAddColor}
          style={{ height: '37px' }}
        >
          <Icon iconName="plus" />
        </Button>
      </div>
      <div>
        {props.value && props.value.colors && props.value.colors.length > 0 ? (
          <ListGroup>
            {props.value.colors.map((colorKey: string, index: number) => (
              <ListGroup.Item key={index} className="d-flex justify-content-between pe-4 ps-4">
                <span>{index + parseFloat(props.value.min)}</span>
                <span className="text-body-tertiary">
                  <Icon iconName="arrow-right"></Icon>
                </span>

                <div className="d-flex align-items-center">
                  <div
                    className="me-5"
                    style={{
                      width: '16px',
                      height: '16px',
                      borderRadius: '50px',
                      backgroundColor: props.value.colors[index]
                    }}
                  />
                  <span onClick={(e) => handleRemoveColor(index)} style={{ cursor: 'pointer' }}>
                    <Icon iconName="trash" />
                  </span>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <span className="text-body-tertiary">Sem mapeamentos</span>
        )}
      </div>
    </div>
  );
}

export default TrafficLightFormatter;
