import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { AuthService } from 'modules/auth/services';
import { UserProfile } from 'modules/auth/types/auth_types';
import { Button, Container, Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { PopupAlert, PopupAlertVariant } from '../../../../web_ui/popup_alert';
import { useTranslation } from 'react-i18next';
import PasswordFields, { validatePassword } from 'web_ui/password_fields';

type PasswordChangeFormProps = {
  user: UserProfile;
};

function PasswordChangeForm(props: PasswordChangeFormProps) {
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [oldPasswordType, setOldPasswordType] = React.useState('password');
  const [validConfirmation, setValidConfirmation] = React.useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');
  const [passwordValidity, setPasswordValidity] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setAlertVariant('danger');
      return showErrorPopup('PasswordsDontMatch');
    }
    if (newPassword === oldPassword) {
      setAlertVariant('danger');
      return showErrorPopup('PasswordSameOldOne');
    }
    if (!passwordValidity) {
      setAlertVariant('danger');
      return showErrorPopup('PasswordInvalid');
    }
    setIsLoading(true);
    try {
      await AuthService.updatePasswordWithOldPassword(props.user.id, newPassword, oldPassword);
      setIsLoading(false);
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      showSuccessPopup('account_settings.password_settings.PasswordUpdated');
    } catch (error) {
      showErrorPopup('UpdatePasswordError');
      setIsLoading(false);
    }
  };

  const showSuccessPopup = (message: string) => {
    setAlertVariant('success');
    setAlertMessage(message);
  };

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  useEffect(() => {
    setValidConfirmation(
      newPassword !== '' && confirmPassword !== '' && confirmPassword !== newPassword
    );
  }, [newPassword, confirmPassword]);

  function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
    setNewPassword(e.target.value);
    setPasswordValidity(validatePassword(e.target.value));
  }

  return (
    <>
      <Form className={styles.Form} onSubmit={handleSubmit}>
        <Form.Group className={styles.FormSection} controlId="formOldPassword">
          <Form.Label className={`text-body-primary ${styles.FormTitle}`}>
            {t('account_settings.password_settings.OldPassword')}
          </Form.Label>
          <InputGroup className={styles.MainInputGroup}>
            <Form.Control
              className={styles.FormControl}
              onChange={(e) => setOldPassword(e.target.value)}
              type={oldPasswordType}
              value={oldPassword}
              disabled={isLoading}
              maxLength={128}
              style={{ width: '25rem' }}
            />
            <InputGroup.Text
              id={'inputeButtonSeePassword'}
              className={styles.inputGroup}
              onClick={() => {
                oldPasswordType === 'password'
                  ? setOldPasswordType('text')
                  : setOldPasswordType('password');
              }}
            >
              {oldPasswordType === 'password' ? (
                <i id={'iconEyeSlash'} className="fa-regular fa-eye-slash"></i>
              ) : (
                <i id={'iconEye'} className="fa-regular fa-eye"></i>
              )}
            </InputGroup.Text>
          </InputGroup>
          <Form.Label className={`text-body-tertiary ${styles.FormDescription}`}>
            {t('account_settings.password_settings.OldPasswordSub')}
          </Form.Label>
        </Form.Group>
        <PasswordFields
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          handleNewPassword={handlePasswordChange}
          handleConfirmPassword={(e) => setConfirmPassword(e.target.value)}
          isLoading={isLoading}
        />
        <Container className={styles.SubmitButton}>
          <Button id={'saveButton-2'} variant="primary" type="submit" disabled={isLoading}>
            {t('navigation_options.Submit')}
          </Button>
        </Container>
      </Form>
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
      )}
    </>
  );
}

export default PasswordChangeForm;
