import Point from './point';

export default class Transform {
  public k: number;
  public x: number;
  public y: number;

  constructor(k: number, x: number, y: number) {
    this.k = k;
    this.x = x;
    this.y = y;
  }

  scale(k: number) {
    return k === 1 ? this : new Transform(this.k * k, this.x, this.y);
  }

  translate(x: number, y: number) {
    return x === 0 && y === 0
      ? this
      : new Transform(this.k, this.x + this.k * x, this.y + this.k * y);
  }

  apply(point: Point) {
    return [point.x * this.k + this.x, point.y * this.k + this.y];
  }

  applyX(x: number) {
    return x * this.k + this.x;
  }

  applyY(y: number) {
    return y * this.k + this.y;
  }

  invert(location: Point) {
    return new Point((location.x - this.x) / this.k, (location.y - this.y) / this.k);
  }

  invertX(x: number) {
    return (x - this.x) / this.k;
  }

  invertY(y: number) {
    return (y - this.y) / this.k;
  }

  toString() {
    return 'translate(' + this.x + ',' + this.y + ') scale(' + this.k + ')';
  }
}

export const Identity = new Transform(1, 0, 0);
