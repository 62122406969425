import React, { useCallback, useEffect, useState } from 'react';
import {
  Action,
  FunctionExtended,
  FunctionParameter,
  FunctionVariable
} from 'modules/logic_builder/types';
import { Button } from 'react-bootstrap';
import styles from './styles.module.css';
import { FunctionService } from 'modules/logic_builder/services';
import { FunctionActionCreatorDialog } from '../function_action_creator_dialog';
import { FunctionActionCreatorDialog as FunctionActionEditorDialog } from '../function_action_creator_dialog';
import IconActionsTab from './IconActionsTab';
import { displayingNameActions } from './convertingActionName';
import Icon from 'web_ui/icon';
import LogicBuilderContext from 'modules/logic_builder/store';
import SessionContext from 'modules/auth/store';
import { useTranslation } from 'react-i18next';

type ActionsTabProps = {
  functionExtended: FunctionExtended;
};

export function ActionsTab(props: ActionsTabProps) {
  const [actionsList, setActionsList] = useState<Action<any>[]>([]);
  const [showAddActionDialog, setShowAddActionDialog] = useState(false);
  const [showActionEditorDialog, setShowActionEditorDialog] = useState<Action<any>>();
  const { functions, objects } = React.useContext(LogicBuilderContext);
  const [variables, setVariables] = useState<FunctionVariable[]>([]);
  const [parameters, setParameters] = useState<FunctionParameter[]>([]);
  const session = React.useContext(SessionContext);
  const { t } = useTranslation();

  const fetchActions = useCallback(async () => {
    if (!props.functionExtended.uuid) return;
    await FunctionService.getFunctionActions(props.functionExtended.uuid).then((actions) => {
      setActionsList(actions);
    });
  }, [props.functionExtended]);

  const fetchVariables = useCallback(async () => {
    if (!props.functionExtended.uuid) return;
    await FunctionService.getFunctionVariables(props.functionExtended.uuid).then((variables) => {
      setVariables(variables);
    });
  }, [props.functionExtended.uuid]);

  const fetchParameters = useCallback(async () => {
    if (!props.functionExtended.uuid) return;
    await FunctionService.getParameters(props.functionExtended.uuid).then((fetchedParameters) => {
      setParameters(fetchedParameters);
    });
  }, [props.functionExtended.uuid]);

  useEffect(() => {
    fetchActions();
    fetchVariables();
    fetchParameters();
  }, [fetchActions, fetchParameters, fetchVariables, props.functionExtended.uuid]);

  return (
    <>
      <div className={styles.TableWrapper}>
        <div className={styles.TableTitle}>
          <p></p>
          <Button
            id={'addImplementation'}
            variant="primary"
            className="btn-sm"
            onClick={() => setShowAddActionDialog(true)}
          >
            Add
          </Button>
        </div>
        <div>
          {actionsList?.length === 0 && (
            <div className={styles.containerNoFunction}>
              No action found.
              <br />
              Add some action
            </div>
          )}
          {actionsList
            ?.sort((a: Action<any>, b: Action<any>) => {
              if (a.order > b.order) return 1;
              if (b.order > a.order) return -1;
              return 0;
            })
            .map((action, index) => {
              const objToShow = objects.filter((item) => item.uuid === action.data?.objectId)[0];
              const funcToShow = Object.values(functions)[0].filter(
                (item) => item.uuid === action.data?.functionId
              )[0];
              let varToShow;
              if (
                action.data.arguments &&
                action.data.arguments.input &&
                action.data.arguments.input.type === 'VAR'
              ) {
                varToShow = variables.filter((it) => {
                  return it.uuid === action.data.arguments.input.value;
                })[0];
              } else if (
                action.data.arguments &&
                action.data.arguments.input &&
                action.data.arguments.input.type === 'PARAM'
              ) {
                varToShow = parameters.filter((it) => {
                  return it.uuid === action.data.arguments.input.value;
                })[0];
              } else if (
                action.data.arguments &&
                action.data.arguments.target &&
                action.data.arguments.target.type === 'VAR'
              ) {
                varToShow = variables.filter((it) => {
                  return it.uuid === action.data.arguments.target.value;
                })[0];
              } else if (
                action.data.arguments &&
                action.data.arguments.target &&
                action.data.arguments.target.type === 'PARAM'
              ) {
                varToShow = parameters.filter((it) => {
                  return it.uuid === action.data.arguments.target.value;
                })[0];
              }
              return (
                <div id={action.type} key={index} className={styles.containerActionsList}>
                  <div className={styles.containerIconAndNameAction}>
                    <IconActionsTab ActionType={action.type} />
                    <span>
                      {`${displayingNameActions(action.type)} ${
                        objToShow
                          ? objToShow.name
                          : funcToShow
                          ? funcToShow.name
                          : varToShow
                          ? varToShow.name
                          : ''
                      }`}{' '}
                    </span>
                  </div>
                  <div
                    id={action.type}
                    style={
                      session.preferences['exocode-theme'] === true
                        ? {}
                        : { border: '1px solid gray' }
                    }
                    className={styles.containerSettingIconActionList}
                    onClick={(ev) => setShowActionEditorDialog(action)}
                  >
                    <Icon
                      id={action.type}
                      iconName="gear"
                      extraProps={
                        session.preferences['exocode-theme'] === true ? 'text-white' : 'text-black'
                      }
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {props.functionExtended.uuid && (
        <FunctionActionEditorDialog
          fetchActions={fetchActions}
          functionId={props.functionExtended.uuid}
          show={showActionEditorDialog != null}
          onClose={() => setShowActionEditorDialog(undefined)}
          editMode={true}
          action={showActionEditorDialog}
          dialogTitle="Edit action"
        />
      )}
      {props.functionExtended.uuid && (
        <FunctionActionCreatorDialog
          fetchActions={fetchActions}
          functionId={props.functionExtended.uuid}
          show={showAddActionDialog}
          onClose={() => setShowAddActionDialog(false)}
          editMode={false}
          dialogTitle={t('logicBuilder.CreateAction')}
        />
      )}
    </>
  );
}
