import {
  ObjectSimple,
  FunctionParameter,
  FunctionVariable,
  Action
} from 'modules/logic_builder/types';
import * as React from 'react';
import { DeleteActionType } from '../../types';
import ArgumentPicker from '../ArgumentPicker';
import { Form } from 'react-bootstrap';
import ObjectPicker from '../ObjectPicker';

type Props = {
  newAction: Action<DeleteActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<DeleteActionType>>>;
  objects: ObjectSimple[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
};

export default function RenderDeleteEntityInputs(props: Props) {
  const [radioButtonChosen, setRadioButtonChosen] = React.useState<string>('');

  return (
    <Form.Group>
      <ObjectPicker
        label="Select a object"
        objects={props.objects}
        onChange={(uuidObjt) => {
          props.onChange((currentVal) => {
            const aux = { ...currentVal };
            aux.data.objectId = uuidObjt;
            return aux; // override
          });
        }}
        value={props.newAction.data.objectId}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginBottom: '0.5rem'
        }}
      >
        <Form.Check
          type="radio"
          id="check-radio-one"
          label={'Entity - Multiple Delete'}
          name="groupOne"
          onChange={(ev) => {
            if (ev.target.checked) {
              setRadioButtonChosen('entity');
            }
          }}
        />
        <Form.Check
          type="radio"
          id="check-radio-two"
          label={'ID - Single Delete'}
          name="groupOne"
          onChange={(ev) => {
            if (ev.target.checked) {
              setRadioButtonChosen('id');
            }
          }}
        />
      </div>
      {radioButtonChosen === 'id' ? (
        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Select id to delete"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.id.value = value;
              auxVal.data.arguments.id.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.id?.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={props.newAction.data.arguments.id?.type}
        />
      ) : (
        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Select object to delete"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.entity.value = value;
              auxVal.data.arguments.entity.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.entity?.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={props.newAction.data.arguments.entity?.type}
        />
      )}
    </Form.Group>
  );
}
