import React from 'react';

interface DatetimeFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function DatetimeFormatterPreview({ formatterParams, value }: DatetimeFormatterPreviewProps) {
  const options = {
    timeZone: formatterParams.timezone ? formatterParams.timezone : 'Europe/Lisbon'
  };

  const inputDate: Date = new Date();
  inputDate.toLocaleString(undefined, options);
  let outputDate;

  switch (formatterParams.outputFormat) {
    case 'dd/mm/yyyy':
      outputDate =
        String(inputDate.getDay()) +
        '/' +
        String(inputDate.getMonth()) +
        '/' +
        String(inputDate.getFullYear());
      break;
    case 'mm/yyyy':
      outputDate = String(inputDate.getMonth()) + '/' + String(inputDate.getFullYear());
      break;
    case 'hh:mm':
      outputDate = String(inputDate.getHours()) + ':' + String(inputDate.getMinutes());
      break;
    case 'dd/mm/yyyyThh:mm':
      outputDate =
        String(inputDate.getDay()) +
        '/' +
        String(inputDate.getMonth()) +
        '/' +
        String(inputDate.getFullYear()) +
        ' ' +
        String(inputDate.getHours()) +
        ':' +
        String(inputDate.getMinutes());
      break;
    case 'yyyy/mm/dd':
      outputDate =
        String(inputDate.getFullYear()) +
        '/' +
        String(inputDate.getMonth()) +
        '/' +
        String(inputDate.getDay());
      break;
    case 'yyyy/mm':
      outputDate = String(inputDate.getFullYear()) + '/' + String(inputDate.getMonth());
      break;
    default:
      outputDate = inputDate.toLocaleString;
  }

  return <label>{outputDate}</label>;
}

export default DatetimeFormatterPreview;
