import React from 'react';

interface ImageFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function ImageFormatterPreview({ formatterParams, value }: ImageFormatterPreviewProps) {
  return (
    <img
      src={'/assets/default.png'}
      className="figure-img"
      alt={'example'}
      title={'example'}
      style={{ width: parseInt(formatterParams.width), height: parseInt(formatterParams.height) }}
    />
  );
}

export default ImageFormatterPreview;
