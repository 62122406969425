import React from 'react';

function UpdateEntityIcon() {
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <i className="fas fa-database text-secondary-emphasis" style={{ fontSize: '1.4rem' }} />
      <i
        className="fas fa-database text-warning "
        style={{
          fontSize: '.8rem',
          marginLeft: '-9px',
          marginRight: '1px',
          borderRadius: '50%',
          padding: '0.2rem'
        }}
      />
    </span>
  );
}

export default UpdateEntityIcon;
