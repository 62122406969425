import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import Dropdown, { ItemType } from '../../components/dropdown';
import { updateAction } from '../../../store/events/actions/update_action';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import useFetchEntities from '../../utils/useFetchEntities';
import { Table } from 'modules/modeler/types';

function EntityInput({
  placeholder,
  label,
  dataKey,
  actionUuid,
  options,
  isFromSideBar
}: ActionInputProps) {
  const entities = useFetchEntities();
  const state = useSelector((state: FunctionEditorState) => state);
  const action = state.actions[actionUuid];
  const value = action?.data[dataKey];
  const dispatch = useDispatch();
  const [dropEntities, setDropEntities] = useState<ItemType[]>([]);

  const handleChange = (value: any) => {
    dispatch(updateAction(actionUuid, dataKey, value));
  };

  useEffect(() => {
    const newEnt: ItemType[] = [];
    entities.forEach((ent: Table) => {
      if (!options?.filterEnts || !options.filterEnts(state, actionUuid, ent)) {
        newEnt.push({ name: ent.content?.data?.name, uuid: ent.uuid });
      }
    });
    setDropEntities(newEnt);
  }, [entities]);

  if (!action) return null;

  return (
    <Dropdown
      items={dropEntities}
      placeholder={placeholder}
      label={label}
      value={dropEntities.filter((e) => e.uuid === value)[0]}
      handleChange={handleChange}
      isFromSideBar={isFromSideBar}
    />
  );
}

export default EntityInput;
