import React from 'react';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { TYPE_PICKER_TYPES } from 'web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import ClearListIcon from './clear_list_icon';
import { t } from 'i18next';

export const CLEAR_LIST_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.CLEAR_LIST,
  name: t('ClearList'),
  description: 'Action to remove all items from a list',
  color: 'primary',
  category: FRONTEND_ACTIONS_CATEGORIES.LIST,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.LIST,
  icon: <ClearListIcon />,
  inlineInputs: [
    {
      label: t('ClearList'),
      placeholder: '',
      key: 'list',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: {
        list: true,
        argumentTypes: [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM]
      }
    }
  ],
  inputs: []
};
