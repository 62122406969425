import React from 'react';

interface BadgeFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function BadgeFormatterPreview({ formatterParams, value }: BadgeFormatterPreviewProps) {
  const { values } = formatterParams || {};
  const backgroundColor =
    typeof values === 'object' && values !== null ? (Object.values(values)[0] as string) : 'none';
  const style =
    Object.values(formatterParams).length > 0
      ? { padding: '0.2rem', borderRadius: '0,375rem', backgroundColor }
      : {};

  return (
    <label style={style} className="rounded">
      {formatterParams.values[value] ? formatterParams.values[value] : value}
    </label>
  );
}

export default BadgeFormatterPreview;
