import * as React from 'react';
import SessionContext from 'modules/auth/store';
import { ApiAppInfo } from 'modules/project/types';

const useCreateApp = (projects: ApiAppInfo[]): [boolean] => {
  const session = React.useContext(SessionContext);
  const [allowed, setAllowed] = React.useState<boolean>(false);

  async function verifyinfPlan() {
    if (session.user) {
      session.user.systemRoles.every((item) => {
        const addAppAuht = item.systemRoleAuthorities.find((at) => at.name === 'ADD_APP');
        if (session.user?.totals && addAppAuht) {
          if (
            session.user.totals.numberOfCreatedApplications <
            addAppAuht.subscriptionPlanLimits.featureLimit
          ) {
            setAllowed(true);
            return;
          }
        }
      });
    }
  }

  React.useEffect(() => {
    // verify the plan and set the bool var
    verifyinfPlan();
  }, [projects]);

  return [allowed];
};

export default useCreateApp;
