import { initialState, RelationshipState } from '../index';

import {
  SET_RELATIONSHIPS,
  CHANGE_PROPERTY,
  setRelationshipsAction,
  RelationshipActions,
  changePropertyAction,
  CHANGE_RELATIONSHIP_TYPE,
  changeRelationshipTypeAction
} from '../actions/relationship';

import produce from 'immer';
import { Relationship } from '../../../types';
import { ADD_RELATIONSHIP, addRelationshipAction } from '../actions/root';

export const relationshipsReducer = (
  state = initialState.relationships,
  action: RelationshipActions
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_RELATIONSHIPS:
        return doSetRelationships(draft, action);
      case ADD_RELATIONSHIP:
        return doAddRelationship(draft, action);
      case CHANGE_PROPERTY:
        return doChangeProperty(draft, action);
      case CHANGE_RELATIONSHIP_TYPE:
        return doChangeRelationshipType(draft, action);
      default:
        return state;
    }
  });
};

function doSetRelationships(
  state: RelationshipState,
  action: setRelationshipsAction
): RelationshipState {
  state = {};
  action.payload.relationships.forEach((relationship: Relationship) => {
    state[relationship.id] = relationship;
  });
  return state;
}

function doAddRelationship(
  state: RelationshipState,
  action: addRelationshipAction
): RelationshipState {
  state[action.payload.id] = action.payload;
  return state;
}

function doChangeProperty(
  state: RelationshipState,
  action: changePropertyAction
): RelationshipState {
  const relationship: any = state[action.payload.id];
  if (action.payload.key === 'cascadeTypes') {
    relationship[action.payload.key] = action.payload.value.toString();
  } else {
    relationship[action.payload.key] = action.payload.value;
  }
  return state;
}

function doChangeRelationshipType(
  state: RelationshipState,
  action: changeRelationshipTypeAction
): RelationshipState {
  const relationship: any = state[action.payload.id];
  relationship.type = action.payload.type;
  relationship.from = action.payload.from;
  relationship.to = action.payload.to;
  relationship.components = action.payload.components;
  return state;
}
