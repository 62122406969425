import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../studio/store';
import { COMPONENT_TYPES, ComponentTypes } from '../studio/exocode_components';

export function useCheckShowGrid() {
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const components = useSelector((state: InterfaceStudioState) => state.components);

  return useCallback(
    (componentUuid: string): boolean => {
      const isGridColumn = Object.keys(links).some((linkKey: string) => {
        const parentComponentId = linkKey.split('_')[0];

        if (
          parentComponentId &&
          links[linkKey].includes(componentUuid) &&
          components[parentComponentId] &&
          (components[parentComponentId].type as ComponentTypes) === COMPONENT_TYPES.GRID
        ) {
          return true;
        }

        return false;
      });

      return isGridColumn;
    },
    [components, links]
  );
}
