import React, { useState } from 'react';
import styles from './styles.module.css';
import { FunctionType } from 'modules/logic_builder/types';
import EmptyMessage from 'web_ui/empty';
import FunctionInstanceNew from './function_instance_new';
import Confirmation from 'web_ui/confirmation';
import { t } from 'i18next';

type FunctionsListProps = {
  serviceUuid: string;
  search: string;
  functions: FunctionType[];
  onCreate: () => void;
  onDelete: (uuid: string) => void;
};

export function FunctionsList(props: FunctionsListProps) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [delFunc, setDelFunc] = useState<FunctionType | undefined>();

  function handleCreate(event?: React.MouseEvent): void {
    event && event.stopPropagation();
    props.onCreate();
  }

  function applyFilter(f: FunctionType): boolean {
    return (
      !props.search ||
      f.functionDefinition.toLowerCase().includes(props.search) ||
      f.description.toLowerCase().includes(props.search)
    );
  }

  return (
    <>
      <div className={styles.FunctionsListWrapper}>
        {props.functions
          .filter((f) => applyFilter(f))
          .map((func) => {
            return (
              <FunctionInstanceNew
                key={func.uuid}
                serviceUuid={func.serviceUuid}
                cursor="pointer"
                functionInstance={func}
              />
            );
          })}
        {props.functions.length === 0 && (
          <div className="w100 h100">
            <EmptyMessage
              message={t('logicBuilder.NoFunctions')}
              icon="exclamation"
              actionMessage={t('logicBuilder.CreateFunction') ?? ''}
              linkAction={handleCreate}
            />
          </div>
        )}
      </div>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.function')} (${delFunc?.name ?? ''})`}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={() => {
          props.onDelete(delFunc?.uuid ?? '');
          setDelFunc(undefined);
          setShowConfirmationDialog(false);
        }}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
