import React, { useEffect, useState } from 'react';
import { ControlProps } from '..';
import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { onlyNumberRegex } from 'utils/regex';
import MaxSizeControl from './AdvancedSizeControls/MaxSizeControl';
import { sleep } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { useTranslation } from 'react-i18next';
import { COMPONENTS_TEMPLATE } from 'modules/designer/studio/exocode_components';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import AdvancedSizeControls from './AdvancedSizeControls';
import CalcSizeControl from './AdvancedSizeControls/CalcSizeControl';
import { changeComponentProperties } from 'modules/designer/studio/store/actions/components';
//! To run fully functional, the component template must be complete,
//! with max and min width and height specificied.
function SizeControl(props: ControlProps) {
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const dispatch = useDispatch();
  const widthOptions = ['px', '%', 'vw'];
  const heightOptions = ['px', '%', 'vh'];
  const { t } = useTranslation();
  // HEIGHT
  const [heightSelected, setHeightSelected] = useState<string>('');
  const [maxHeight, setMaxHeight] = useState<string>('');
  const [maxHeightOption, setMaxHeightOption] = useState<string>('');
  const [minHeight, setMinHeight] = useState<string>('');
  const [minHeightOption, setMinHeightOption] = useState<string>('');
  const [heightFixed, setHeighFixed] = useState<string>('');
  const [heightFixedOption, setHeighFixedOption] = useState<string>('');

  // WIDTH
  const [widthSelected, setWidthSelected] = useState<string>('');
  const [maxWidth, setMaxWidth] = useState<string>('');
  const [maxWidthOption, setMaxWidthOption] = useState<string>('');
  const [minWidth, setMinWidth] = useState<string>('');
  const [minWidthOption, setMinWidthOption] = useState<string>('');
  const [widthFixed, setWidthFixed] = useState<string>('');
  const [widthFixedOption, setWidthFixedOption] = useState<string>('');

  enum SizeType {
    'height',
    'width'
  }

  const setCorrectInitialData = () => {
    if (selectedComponent === null) return;
    if (props.value) {
      if (props.value.optionSizeHeight) {
        setHeightSelected(props.value.optionSizeHeight);
        if (
          props.value.optionSizeHeight === 'Fixed' &&
          props.value.height &&
          props.value.heightUnit
        ) {
          setHeighFixed(props.value.height);
          setHeighFixedOption(props.value.heightUnit);
        } else if (
          props.value.optionSizeHeight === 'AutoWLimits' &&
          props.value.maxHeight &&
          props.value.maxHeightUnit &&
          props.value.minHeight &&
          props.value.minHeightUnit
        ) {
          // max height
          setMaxHeight(props.value.maxHeight);
          setMaxHeightOption(props.value.maxHeightUnit);

          // min height
          setMinHeight(props.value.minHeight);
          setMinHeightOption(props.value.minHeightUnit);
        }
      }
      if (props.value.optionSizeWidth) {
        setWidthSelected(props.value.optionSizeWidth);
        if (props.value.optionSizeWidth === 'fixed' && props.value.width && props.value.widthUnit) {
          setWidthFixed(props.value.width);
          setWidthFixedOption(props.value.widthUnit);
        } else if (
          props.value.optionSizeWidth === 'AutoWLimits' &&
          props.value.maxWidth &&
          props.value.maxWidthUnit &&
          props.value.minWidth &&
          props.value.minWidthUnit
        ) {
          // max width
          setMaxWidth(props.value.maxWidth);
          setMaxWidthOption(props.value.maxWidthUnit);

          // min width
          setMinWidth(props.value.minWidth);
          setMinWidthOption(props.value.minWidthUnit);
        }
      }
    }
  };

  useEffect(() => {
    setCorrectInitialData();
  }, [selectedComponent]);

  const fullOption = (sizeType: SizeType) => {
    if (!props.onChange || !selectedComponent) return;
    if (sizeType === SizeType.height) {
      const marginTop = components[selectedComponent]?.styles.marginTop;
      const marginBottom = components[selectedComponent]?.styles.marginBottom;
      dispatch(
        changeComponentProperties([
          { uuid: selectedComponent, key: 'maxHeight', value: 'none' },
          { uuid: selectedComponent, key: 'minHeight', value: 'none' },
          {
            uuid: selectedComponent,
            key: 'height',
            value: `calc(100% - ${marginTop ?? 0}px - ${marginBottom ?? 0}px)`
          },
          {
            uuid: selectedComponent,
            key: 'heightUnit',
            value: ``
          },
          {
            uuid: selectedComponent,
            key: 'overflowY',
            value: `auto`
          },
          {
            uuid: selectedComponent,
            key: 'optionSizeHeight',
            value: 'full'
          }
        ])
      );
    } else {
      const marginLeft = components[selectedComponent]?.styles.marginLeft;
      const marginRight = components[selectedComponent]?.styles.marginRight;
      dispatch(
        changeComponentProperties([
          { uuid: selectedComponent, key: 'maxWidth', value: 'none' },
          { uuid: selectedComponent, key: 'minWidth', value: 'none' },
          {
            uuid: selectedComponent,
            key: 'width',
            value: `calc(100% - ${marginLeft ?? 0}px - ${marginRight ?? 0}px)`
          },
          {
            uuid: selectedComponent,
            key: 'widthUnit',
            value: ``
          },
          {
            uuid: selectedComponent,
            key: 'optionSizeWidth',
            value: 'full'
          }
        ])
      );
    }
  };

  const autoOption = (sizeType: SizeType) => {
    // true -> height
    // false -> width
    //sendData of auto
    if (!props.onChange || !selectedComponent) return;
    if (sizeType === SizeType.height) {
      dispatch(
        changeComponentProperties([
          { uuid: selectedComponent, key: 'maxHeight', value: 'none' },
          { uuid: selectedComponent, key: 'minHeight', value: 'none' },
          {
            uuid: selectedComponent,
            key: 'height',
            value: `fit-content`
          },
          {
            uuid: selectedComponent,
            key: 'heightUnit',
            value: ``
          },
          {
            uuid: selectedComponent,
            key: 'optionSizeHeight',
            value: 'auto'
          }
        ])
      );
    } else {
      dispatch(
        changeComponentProperties([
          { uuid: selectedComponent, key: 'maxWidth', value: '' },
          { uuid: selectedComponent, key: 'minWidth', value: '' },
          {
            uuid: selectedComponent,
            key: 'width',
            value: `fit-content`
          },
          {
            uuid: selectedComponent,
            key: 'widthUnit',
            value: ``
          },
          {
            uuid: selectedComponent,
            key: 'optionSizeWidth',
            value: 'auto'
          }
        ])
      );
    }
  };

  const autoWLimitsOption = (isFrom: boolean) => {
    // true -> height
    // false -> width
    if (!props.onChange || !selectedComponent) return;
    if (isFrom) {
      dispatch(
        changeComponentProperties([
          {
            uuid: selectedComponent,
            key: 'height',
            value: `fit-content`
          },
          {
            uuid: selectedComponent,
            key: 'overflowY',
            value: `auto`
          }
        ])
      );
    } else {
      dispatch(
        changeComponentProperties([
          {
            uuid: selectedComponent,
            key: 'width',
            value: `fit-content`
          }
        ])
      );
    }
  };

  const fixedOption = (sizeType: SizeType) => {
    if (!props.onChange || !selectedComponent) return;
    if (sizeType === SizeType.height) {
      dispatch(
        changeComponentProperties([
          { uuid: selectedComponent, key: 'maxHeight', value: 'none' },
          { uuid: selectedComponent, key: 'minHeight', value: 'none' },
          {
            uuid: selectedComponent,
            key: 'overflowY',
            value: `auto`
          },
          {
            uuid: selectedComponent,
            key: 'optionSizeHeight',
            value: 'Fixed'
          }
        ])
      );
    } else {
      dispatch(
        changeComponentProperties([
          { uuid: selectedComponent, key: 'maxWidth', value: 'none' },
          { uuid: selectedComponent, key: 'minWidth', value: 'none' },
          {
            uuid: selectedComponent,
            key: 'optionSizeWidth',
            value: 'Fixed'
          }
        ])
      );
    }
  };

  const handleChangeHeightMode = (heightMode: string) => {
    switch (heightMode) {
      case 'full':
        fullOption(SizeType.height);
        break;
      case 'auto':
        autoOption(SizeType.height);
        break;
      case 'Fixed':
        fixedOption(SizeType.height);
        break;
    }
  };

  const renderFixedHeightOptions = () => {
    return (
      <div
        style={{
          marginTop: 5,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 5
        }}
      >
        <span style={{ width: 50 }}>Height</span>
        <Form.Control
          style={{ width: 60 }}
          value={heightFixed}
          onChange={(ev) => {
            setHeighFixed(ev.target.value);
            if (props.onChange) {
              if (!heightFixedOption && selectedComponent) {
                dispatch(
                  changeComponentProperties([
                    { uuid: selectedComponent, key: 'height', value: ev.target.value },
                    { uuid: selectedComponent, key: 'heightUnit', value: 'px' }
                  ])
                );
                setHeighFixedOption('px');
                return;
              }
              props.onChange(ev.target.value === '' ? '' : parseInt(ev.target.value), 'height');
            }
          }}
          type="number"
        />
        <Form.Select
          style={{ width: 70 }}
          value={heightFixedOption}
          onChange={(ev) => {
            setHeighFixedOption(ev.target.value);
            if (props.onChange) {
              props.onChange(ev.target.value, 'heightUnit');
            }
          }}
        >
          <option>---</option>
          {heightOptions.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </Form.Select>
      </div>
    );
    // }
  };

  const renderFixedWidthOptions = () => {
    return (
      <div
        style={{
          marginTop: 5,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 5
        }}
      >
        <span style={{ width: 50 }}>Width</span>
        <Form.Control
          style={{ width: 60 }}
          value={widthFixed}
          onChange={(ev) => {
            setWidthFixed(ev.target.value);
            if (props.onChange) {
              props.onChange(ev.target.value === '' ? '' : parseInt(ev.target.value), 'width');
            }
          }}
          type="number"
        />
        <Form.Select
          style={{ width: 70 }}
          value={widthFixedOption}
          onChange={(ev) => {
            setWidthFixedOption(ev.target.value);
            if (props.onChange) {
              props.onChange(ev.target.value, 'widthUnit');
            }
          }}
        >
          <option>---</option>
          {widthOptions.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </Form.Select>
      </div>
    );
  };

  const handleChangeWidthMode = (widthMode: string) => {
    switch (widthMode) {
      case 'full':
        fullOption(SizeType.width);
        return <></>;
      case 'auto':
        autoOption(SizeType.width);
        return <></>;
      case 'Fixed':
        fixedOption(SizeType.width);
    }
  };

  return (
    <div className="mb-3 pb-4 pt-3 border-bottom text-body-secondary">
      <div className="d-flex flex-column justify-content-between">
        <div style={{ display: 'flex' }}>
          <label className="mb-3 text-body">{props.label}</label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.controls.SizeTitle') ||
                'designer.right_side.controls.SizeTitle',
              description:
                t('designer.right_side.controls.SizeDescription') ||
                'designer.right_side.controls.SizeSizeDescription',
              note: [t('designer.right_side.controls.SizeNote01')]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </div>
        <div>
          {/* HEIGHT */}
          <div>
            <span>{t('designer.right_side.Height')}</span>
            <Form.Select
              value={heightSelected}
              onChange={(ev) => {
                // selectedOptionHeight(ev.target.value);
                handleChangeHeightMode(ev.target.value);
                setHeightSelected(ev.target.value);
              }}
            >
              <option>---</option>
              <option value={'full'}>{t('designer.right_side.full')}</option>
              <option value={'auto'}>{t('designer.right_side.Auto')}</option>
              <option value={'Fixed'}>{t('designer.right_side.Fixed')}</option>
            </Form.Select>
            {heightSelected === 'Fixed' && renderFixedHeightOptions()}
          </div>
          {/* WIDTH */}
          <div>
            <span>{t('designer.right_side.Width')}</span>
            <Form.Select
              value={widthSelected}
              onChange={(ev) => {
                handleChangeWidthMode(ev.target.value);
                setWidthSelected(ev.target.value);
              }}
            >
              <option>---</option>
              <option value={'full'}>{t('designer.right_side.full')}</option>
              <option value={'auto'}>{t('designer.right_side.Auto')}</option>
              <option value={'Fixed'}>{t('designer.right_side.Fixed')}</option>
            </Form.Select>
            {widthSelected === 'Fixed' && renderFixedWidthOptions()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SizeControl;
