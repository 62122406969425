import React from 'react';
import { IconList } from '../../../../../../../packages/react-fa-icon-picker';
import * as FontIcon from 'react-icons/fa';

type ActionIconProps = {
  icon: IconList;
  style?: React.CSSProperties;
};

function ActionIcon({ icon, style }: ActionIconProps) {
  return icon ? React.createElement(FontIcon[icon], { style: style ? { ...style } : {} }) : <div />;
}

export default ActionIcon;
