import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { t } from 'i18next';

export const BADGES_BUTTON_MANIFEST: ComponentManifest = {
  type: 'BADGESBUTTON',
  name: 'BadgesButton',
  descriptionId: 'ButtonDescription',
  description: 'Small counting and labeling component.',
  icon: 'fa-solid fa-exclamation',
  group: ComponentGroups['BUTTON'],
  previewHeight: 30,
  previewWidth: 140,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Text',
      key: 'text'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Type',
      key: 'type',
      controlOptions: ['Normal', 'Rounded-Pill']
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Count',
      key: 'count'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Tooltip',
      key: 'tooltip'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'DisabledVariable',
      descriptionId: 'DisabledVariableDescription',
      key: 'disabledVar'
    },
    {
      controlType: ControlsTypes.ICONS,
      controlLabel: 'Icons'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.ICON_STYLES,
      controlLabel: 'IconStyles'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Background'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'BadgesColor',
      key: 'badgesColor',
      controlOptions: [
        'secondary',
        'primary',
        'success',
        'info text-dark',
        'warning text-dark',
        'danger',
        'light text-dark',
        'dark'
      ]
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'BorderBadges',
      key: 'borderBadges',
      controlOptions: [' ', 'light', 'dark', 'success']
    },
    {
      controlType: ControlsTypes.FONT,
      controlLabel: t('Font')
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      key: 'color'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Font Weight',
      key: 'fontWeight',
      controlOptions: ['normal', 'bold', '100', '300', '500', '600', '800', '900']
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: t('Spacing'),
      key: 'lineHeight',
      controlOptions: ['normal', 'inherit', 'initial', 'revert', 'revert-layer', 'unset']
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'LineHeight',
      key: 'lineHeight'
    },
    {
      controlType: ControlsTypes.TEXTALIGN,
      controlLabel: 'Text Align',
      key: 'textAlign'
    },
    {
      controlType: ControlsTypes.TEXTDECORATION,
      controlLabel: 'Text Decoration',
      key: 'textDecoration'
    },
    {
      controlType: ControlsTypes.TEXTTRANSFORM,
      controlLabel: 'Text Transform',
      key: 'textTransform'
    },
    {
      controlType: ControlsTypes.FONTSTYLE,
      controlLabel: 'Font Style',
      key: 'fontStyle'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CLICK', 'HOVER']
};
