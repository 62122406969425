import React, { useState } from 'react';
import { ControlProps } from '..';
import styles from './styles.module.css';
import { IconPicker } from 'packages/react-fa-icon-picker';
import { ListGroup } from 'react-bootstrap';
import Switch from '../components/Switch';
import { sleep } from 'utils/utils';
import Icon from 'web_ui/icon';
import HelpPopover from '../components/Popover';
import { useTranslation } from 'react-i18next';
import HelpIcon from '../components/HelpIcon';

function IconControl(props: ControlProps) {
  const [position, setPosition] = useState<'left' | 'center' | 'right'>('left');
  const { t } = useTranslation();

  async function setOnlyIcon() {
    if (!props.onChange) return;
    if (props.value.text || props.value.text === '') {
      props.onChange('', 'text');
      await sleep(20);
      props.onChange('', 'leftIcon');
      await sleep(20);
    }
    setPosition('center');
  }

  function getDefaultSelected(): number {
    if (props.value?.leftIcon !== '' && props.value?.leftIcon) {
      return 1;
    } else if (props.value?.rightIcon !== '' && props.value?.text) {
      return 3;
    } else {
      return 2;
    }
  }

  async function handleRemoveIcon() {
    if (!props.onChange) return;
    props.onChange('', position === 'left' ? 'leftIcon' : 'rightIcon');
  }

  return (
    <div className=" mt-3 pb-3 pt-2 border-top border-bottom text-body-secondary">
      <div style={{ display: 'flex' }}>
        <label className="mb-1 text-body">{props.label}</label>
        <HelpPopover
          helpBoxProps={{
            title:
              t('designer.right_side.controls.IconTitle') ||
              'designer.right_side.controls.IconTitle',
            description:
              t('designer.right_side.controls.IconDescription') ||
              'designer.right_side.controls.IconIconDescription',
            note: [t('designer.right_side.controls.IconNote01')]
          }}
          placement="top"
        >
          <HelpIcon />
        </HelpPopover>
      </div>
      <div className={styles.iconWrapper}>
        <Switch
          switches={[
            {
              label: 'Left',
              onClick: () => {
                setPosition('left');
              }
            },
            {
              label: 'Center',
              onClick: setOnlyIcon
            },
            {
              label: 'Right',
              onClick: () => {
                setPosition('right');
              }
            }
          ]}
          defaultActivatedIndex={getDefaultSelected()}
        />

        <div className={styles.iconContainer}>
          <div
            className={`d-flex justify-content-center align-items-center  ${styles.iconContent}`}
          >
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.AddLeftIconTitle') ||
                  'designer.right_side.controls.AddLeftIconTitle',
                description:
                  t('designer.right_side.controls.AddLeftIconDescription') ||
                  'designer.right_side.controls.AddLeftIconDescription',
                note: ['']
              }}
              placement="top"
            >
              {position === 'left' && (
                <ListGroup as={'div'}>
                  <ListGroup.Item style={{ position: 'relative' }} action>
                    {props.value?.leftIcon && (
                      <div className={`${styles.removeIcon}`} onClick={(ev) => handleRemoveIcon()}>
                        <Icon iconName="close" />
                      </div>
                    )}
                    {!props.value?.leftIcon && (
                      <p
                        id="addIconButton"
                        className={`text-body-secondary ${styles.addIconPlaceholder}`}
                      >
                        add
                      </p>
                    )}
                    <IconPicker
                      value={props.value?.leftIcon}
                      onChange={(newIcon) => props.onChange?.(newIcon, 'leftIcon')}
                      containerStyles={{ left: '-20px', display: 'flex' }}
                      buttonIconStyles={{
                        color: 'var(--bs-secondary-color)!important',
                        border: '0',
                        fontSize: '1.25rem',
                        display: 'flex'
                      }}
                      buttonStyles={{
                        border: 0,
                        padding: '0',
                        width: '1.5rem',
                        height: '1.5rem',
                        minHeight: '0',
                        fontSize: '1rem'
                      }}
                      searchInputStyles={{
                        display: 'flex'
                      }}
                    />
                  </ListGroup.Item>
                </ListGroup>
              )}
            </HelpPopover>

            {position !== 'center' && (
              <div
                className={`rounded border border-left-0 ${
                  position === 'left' ? 'ms-2' : position === 'right' ? 'me-2' : ''
                } ${styles.elementExample}`}
              >
                content
              </div>
            )}

            <HelpPopover
              helpBoxProps={{
                title:
                  position === 'center'
                    ? t('designer.right_side.controls.AddCenterIconTitle') ||
                      'designer.right_side.controls.AddCenterIconTitle'
                    : t('designer.right_side.controls.AddRightIconTitle') ||
                      'designer.right_side.controls.AddRightIconTitle',
                description:
                  position === 'center'
                    ? t('designer.right_side.controls.AddCenterIconDescription') ||
                      'designer.right_side.controls.AddCenterIconDescription'
                    : t('designer.right_side.controls.AddRightIconDescription') ||
                      'designer.right_side.controls.AddRightIconDescription',
                note: ['']
              }}
              placement="top"
            >
              {(position === 'right' || position === 'center') && (
                <ListGroup>
                  <ListGroup.Item action>
                    {props.value?.rightIcon && (
                      <div className={`${styles.removeIcon}`} onClick={(ev) => handleRemoveIcon()}>
                        <Icon iconName="close" />
                      </div>
                    )}
                    {!props.value?.rightIcon && (
                      <p className={`text-body-secondary ${styles.addIconPlaceholder}`}>add</p>
                    )}
                    <IconPicker
                      value={props.value?.rightIcon}
                      onChange={(newIcon) => props.onChange?.(newIcon, 'rightIcon')}
                      containerStyles={{ left: position === 'center' ? '-88px' : '-150px' }}
                      buttonIconStyles={{
                        color: 'var(--bs-secondary-color)!important',
                        border: 0,
                        fontSize: '1.25rem',
                        display: 'flex'
                      }}
                      buttonStyles={{
                        border: 0,
                        padding: '0',
                        width: '1.5rem',
                        height: '1.5rem',
                        minHeight: '0',
                        fontSize: '1rem'
                      }}
                    />
                  </ListGroup.Item>
                </ListGroup>
              )}
            </HelpPopover>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IconControl;
