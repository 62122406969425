import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ComponentProps } from './component_wrapper';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../store';
import styles from './style.module.css';
import { EditorModeOptions } from 'modules/designer/types';

interface AdjacenteDropzonesProps {
  width?: number;
  height?: number;
  offsetLeft?: number;
  offsetTop?: number;
  dropzone?: string | null;
  elementProps: ComponentProps;
  elementRef: React.RefObject<HTMLElement>;
}

function AdjacenteDropzones({
  width,
  height,
  offsetLeft,
  offsetTop,
  elementProps,
  dropzone,
  elementRef
}: AdjacenteDropzonesProps) {
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const [dropzoneStyle, setDropzoneStyle] = useState<any>();
  const views = useSelector((state: InterfaceStudioState) => state.views);

  useEffect(() => {
    const isColumnDirection =
      elementProps.parentUUID &&
      components[elementProps.parentUUID] &&
      components[elementProps.parentUUID].data &&
      components[elementProps.parentUUID].data.flexDirection === 'column';

    const adjacentDropzoneContainerStyles = {
      width: '20px',
      height: elementRef.current ? elementRef.current.clientHeight : height,
      left: elementRef.current ? elementRef.current.offsetLeft : offsetLeft!,
      top: elementRef.current ? elementRef.current.offsetTop : offsetTop,
      padding: '0',
      backgroundColor: 'rgba(0, 0, 255, 0.3))'
    };

    const rightAdjacentDropzoneContainerStyles = {
      width: '20px',
      height: elementRef.current ? elementRef.current.clientHeight : height,
      left: (elementRef.current ? elementRef.current.offsetLeft : offsetLeft!) + width! - 20,
      top: elementRef.current ? elementRef.current.offsetTop : offsetTop,
      padding: '0',
      backgroundColor: 'rgba(0, 0, 255, 0.3))'
    };

    const topAdjacentDropzoneContainerStyles = {
      width: elementRef.current ? elementRef.current.clientWidth : width,
      height: '20px',
      left: elementRef.current ? elementRef.current.offsetLeft : offsetLeft! + 1,
      top: elementRef.current ? elementRef.current.offsetTop : offsetTop,
      padding: '0',
      backgroundColor: 'rgba(0, 0, 255, 0.3))'
    };

    const bottomAdjacentDropzoneContainerStyles = {
      width: elementRef.current ? elementRef.current.clientWidth : width,
      height: '20px',
      left: elementRef.current ? elementRef.current.offsetLeft : offsetLeft,
      top:
        (elementRef.current ? elementRef.current.offsetTop : offsetTop!) +
        (elementRef.current ? elementRef.current?.clientHeight : height!) -
        20,
      padding: '0',
      backgroundColor: 'rgba(0, 0, 255, 0.3))'
    };

    if (!dropzone) {
      if (dropzoneStyle !== null) handleUpdateDropzoneStyle(null);
      return;
    }

    const defaultStylesMap: Record<string, any> = {
      top: topAdjacentDropzoneContainerStyles,
      bottom: bottomAdjacentDropzoneContainerStyles,
      left: adjacentDropzoneContainerStyles,
      right: rightAdjacentDropzoneContainerStyles
    };

    const columnStylesMap: Record<'column' | 'row', Record<string, any>> = {
      column: {
        top: topAdjacentDropzoneContainerStyles,
        bottom: bottomAdjacentDropzoneContainerStyles
      },
      row: {
        left: adjacentDropzoneContainerStyles,
        right: rightAdjacentDropzoneContainerStyles
      }
    };

    const directionKey = isColumnDirection ? 'column' : 'row';
    let style = columnStylesMap[directionKey]?.[dropzone] || null;

    const viewUUID = elementProps.viewUUID;
    if (viewUUID && views?.[viewUUID]?.editorMode === EditorModeOptions['NORMAL']) {
      style = defaultStylesMap[dropzone] || null;
    }

    handleUpdateDropzoneStyle(style);
  }, [
    offsetLeft,
    offsetTop,
    width,
    height,
    elementProps,
    dropzone,
    elementRef.current?.clientWidth
  ]);

  async function handleUpdateDropzoneStyle(style: any) {
    setDropzoneStyle(style);
  }

  return <div className={`${styles.adjacentDropzoneContainer}`} style={{ ...dropzoneStyle }} />;
}

export default AdjacenteDropzones;
