import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { PageService } from 'modules/designer/services';
import { Layout, Modal as ModalType, Page } from 'modules/designer/types';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { validateCustomComponentName } from 'utils/inputValidation';

export type DuplicateViewInfo = {
  // The view id.
  view: string;
  // The view name.
  viewName: string;
  // Whether or not the logic should also be duplicated.
  withLogic: boolean;
};

type DuplicatePageModalProps = {
  show: boolean;
  view: Page | Layout | ModalType;
  onClose: () => void;
  moduleId: string;
};

export function DuplicatePageModal(props: DuplicatePageModalProps) {
  const { t } = useTranslation();
  const [viewName, setViewName] = useState('');
  const [checkedRadio, setCheckedRadio] = useState('ALL'); // ALL | INTERFACE
  const [isLoading, setIsLoading] = useState(false);
  const [viewNameErrorMessage, setViewNameErrorMessage] = useState('');

  useEffect(() => {
    if (!props.show) return;
    setViewName('');
    setCheckedRadio('ALL');
    setViewNameErrorMessage('');
    setIsLoading(false);
  }, [props.show]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    const isValid = validateAllInputs();
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const duplicateViewInfo: DuplicateViewInfo = {
      view: props.view.uuid,
      viewName: viewName,
      withLogic: checkedRadio === 'ALL'
    };
    PageService.duplicatePage(props.moduleId, duplicateViewInfo)
      .then(() => {
        props.onClose();
      })
      .catch(() => {
        setViewNameErrorMessage('designer.duplicatePageDialog.duplicatedViewNameError');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const radioOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedRadio(event.target.value);
  };

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateAllInputs = (): boolean => {
    const isValidName = checkNameIsValid(viewName);
    return isValidName;
  };

  const checkNameIsValid = (input: string): boolean => {
    const { valid } = validateCustomComponentName(input);
    if (valid) {
      setViewNameErrorMessage('');
    } else {
      setViewNameErrorMessage('designer.duplicatePageDialog.invalidViewNameError');
    }
    return valid;
  };

  const handleViewNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    checkNameIsValid(event.target.value);
    setViewName(event.target.value);
  };

  return (
    <>
      <Modal centered show={props.show} onHide={props.onClose}>
        <Form noValidate={true} onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t('designer.duplicatePageDialog.duplicatePage')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Name</Form.Label>
            <Form.Group className="mb-3">
              <Form.Control
                isInvalid={viewNameErrorMessage !== ''}
                type="text"
                value={viewName}
                onChange={handleViewNameChange}
              />
              <Form.Control.Feedback type={'invalid'}>
                {t(viewNameErrorMessage)}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Check
                inline
                name="duplicateOptions"
                type={'radio'}
                label={t('designer.duplicatePageDialog.duplicateEverything')}
                value="ALL"
                onChange={radioOnChange}
                checked={checkedRadio === 'ALL'}
              />
              <Form.Check
                inline
                name="duplicateOptions"
                type={'radio'}
                label={t('designer.duplicatePageDialog.duplicateInterface')}
                value="INTERFACE"
                onChange={radioOnChange}
                checked={checkedRadio === 'INTERFACE'}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button id="saveButton" type="submit">
              {t('designer.duplicatePageDialog.duplicate')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
