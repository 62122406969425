import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

export const CARD_COMPONENT_MANIFEST: ComponentManifest = {
  type: 'CARD',
  name: 'Card',
  descriptionId: 'CardDescription',
  description: 'A Editable card to use in layout.',
  icon: 'fa-sharp fa-solid fa-file',
  group: ComponentGroups['CONTAINER'],
  allowDrop: true,
  previewHeight: 150,
  previewWidth: 200,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'HeaderTitle',
      key: 'title',
      tooltip: 'designer.right_side.controls.components.card.headerTitle'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.card.tooltip'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.card.visible'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'ShowHeader',
      key: 'header',
      tooltip: 'designer.right_side.controls.components.card.showHeader'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.card.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.card.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'BackgroundColor',
      tooltip: 'designer.right_side.controls.components.card.backgroundColor'
    },
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CLICK', 'HOVER']
};
