import React from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { FunctionActionManifest } from '../../../../store/types/manifestsAndInputs';
import Confirmation from '../../../../../confirmation';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type ActionOptionsButtonsProps = {
  showMore: boolean;
  setShowMore: (show: boolean) => void;
  manifest: FunctionActionManifest;
  handleDelete: () => void;
};

function ActionOptionsButtons({
  showMore,
  setShowMore,
  manifest,
  handleDelete
}: ActionOptionsButtonsProps) {
  const { t } = useTranslation();
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);

  const manifestInputs = manifest?.inputs?.filter((input) => input.showInEditor !== false);

  return (
    <>
      <div className={styles.ActionOptions}>
        {manifest && manifestInputs?.length > 0 && (
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Edit') ?? ''
            }}
          >
            <div
              id={`edit${manifest.name.replace(' ', '')}`}
              className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
              onClick={() => setShowMore(!showMore)}
            >
              <span className={'fa fa-solid fa-pen-to-square'} />
            </div>
          </HelpPopover>
        )}
        {manifest && !manifest.closeBlock && !manifest.conditionStatement && (
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Delete') ?? ''
            }}
          >
            <div
              id={`delete${manifest.name.replace(' ', '')}`}
              className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
              onClick={() => {
                setShowConfirmationModal(true);
              }}
            >
              <span className={'fa fa-solid fa-trash'} />
            </div>
          </HelpPopover>
        )}
      </div>
      <Confirmation
        message={`${t('designer.functions.DeleteComponent')} (${manifest.name})`}
        show={showConfirmationModal}
        onConfirmation={() => {
          handleDelete();
          setShowConfirmationModal(false);
        }}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
      />
    </>
  );
}

export default ActionOptionsButtons;
