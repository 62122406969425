import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';

import ComponentHeader from '../components/component_header';
import EventsTool from '../properties_toolbar/events';

type EventsToolbarProps = {
  name?: string;
  icon?: string;
};

function EventsToolbar(props: EventsToolbarProps) {
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent
  );
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);

  return (
    <>
      {(selectedView || selectedComponent) && (
        <>
          <ComponentHeader name={props.name} icon={props.icon} />
          <EventsTool />
        </>
      )}
    </>
  );
}

export default memo(EventsToolbar);
