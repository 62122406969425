import React from 'react';
import { useTranslation } from 'react-i18next';

function NoDatasourceWarning() {
  const { t } = useTranslation();
  return (
    <div
      className="p-5 border rounded-3 bg-light text-secondary-emphasis text-center"
      style={{ opacity: 0.75 }}
    >
      <h5>{t('designer.right_side.NoDataSource')}</h5>
      <p>
        {t('designer.right_side.ListNoSource')}
        <br />
        {t('designer.right_side.SelectDataSource')}
      </p>
    </div>
  );
}

export default NoDatasourceWarning;
