import React, { useState, useEffect } from 'react';
import { ControlProps } from '..';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { ElementProperty } from 'modules/designer/studio/toolbars/properties_toolbar';
import { sleep } from 'utils/utils';
import HelpIcon from '../components/HelpIcon';
import HelpPopover from '../components/Popover';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeAllComponentsProperties } from 'modules/designer/studio/store/actions/components';
import { InterfaceStudioState } from 'modules/designer/studio/store';

function PositionControl(props: ControlProps) {
  const [selectedPosition, setSelectedPosition] = useState<string>('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );

  useEffect(() => {
    if (props.value) {
      setSelectedPosition(props.value['verticalAlign'] + '-' + props.value['horizontalAlign']);
    }
  }, [props.value]);

  function handleChange(advancedChanges: ElementProperty[]) {
    advancedChanges.map(async ({ key, value }: ElementProperty) => {
      await sleep(100);
      if (props.onChange) {
        props.onChange(value, key);
      }
    });
  }

  function handleSelectDirection(ev: React.MouseEvent<HTMLDivElement>, direction: string) {
    if (!props.onChange) return;
    props.onChange(direction, 'flexDirection');
  }

  function handleSelectPosition(ev: React.MouseEvent<HTMLDivElement>, position: string) {
    const advancedChanges: ElementProperty[] = [];
    switch (position) {
      case 'top-left':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'start' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'start' }
            ],
            style: []
          })
        );
        break;
      case 'top-middle':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'start' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'center' }
            ],
            style: []
          })
        );
        break;
      case 'top-right':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'start' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'end' }
            ],
            style: []
          })
        );
        break;
      case 'middle-left':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'center' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'start' }
            ],
            style: []
          })
        );
        break;
      case 'middle-middle':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'center' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'center' }
            ],
            style: []
          })
        );
        break;
      case 'middle-right':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'center' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'end' }
            ],
            style: []
          })
        );
        break;
      case 'bottom-left':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'end' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'start' }
            ],
            style: []
          })
        );
        break;
      case 'bottom-middle':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'end' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'center' }
            ],
            style: []
          })
        );
        break;
      case 'bottom-right':
        if (!selectedComponent) return;
        dispatch(
          changeAllComponentsProperties({
            data: [
              { uuid: selectedComponent, key: 'verticalAlign', value: 'end' },
              { uuid: selectedComponent, key: 'horizontalAlign', value: 'end' }
            ],
            style: []
          })
        );
        break;
      default:
    }

    handleChange(advancedChanges);
  }

  return (
    <div className="mb-3 pb-4 border-bottom pt-3 border-top display-flex">
      {/* <label className="mb-3">{props.label}</label>
      <HelpPopover
        helpBoxProps={{
          title:
            t('designer.right_side.controls.PositionTitle') ||
            'designer.right_side.controls.PositionTitle',
          description:
            t('designer.right_side.controls.PositionDescription') ||
            'designer.right_side.controls.PositionPositionDescription',
          note: [t('designer.right_side.controls.PositionNote01')]
        }}
        placement="top"
      >
        <HelpIcon />
      </HelpPopover> */}
      <div className={`${styles.positionWrapper}`}>
        <div className={`${styles.alignContainer}`}>
          <label className={`${styles.labelName}`}>
            {t(`designer.right_side.Guidance`)}
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.PositionTitleGuidance') ||
                  'designer.right_side.controls.PositionTitleGuidance',
                description:
                  t('designer.right_side.controls.PositionDescriptionGuidance') ||
                  'designer.right_side.controls.PositionDescriptionGuidance',
                note: [t('designer.right_side.controls.PositionNoteGuidance')]
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          </label>
          <div className={`text-body0tertiary ${styles.orientationContainer}`}>
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.PositionHorizontalTitle') ||
                  'designer.right_side.controls.PositionHorizontalTitle',
                description:
                  t('designer.right_side.controls.PositionHorizontalDescription') ||
                  'designer.right_side.controls.PositionHorizontalDescription'
              }}
              placement="top"
            >
              <div
                className={`${styles.orientationButton} ${
                  props.value?.flexDirection === 'row' ? styles.selected : null
                }`}
                onClick={(ev) => handleSelectDirection(ev, 'row')}
              >
                <Icon iconName="grip-lines-vertical"></Icon>
              </div>
            </HelpPopover>

            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.PositionVerticalTitle') ||
                  'designer.right_side.controls.PositionVerticalTitle',
                description:
                  t('designer.right_side.controls.PositionVerticalDescription') ||
                  'designer.right_side.controls.PositionVerticalDescription'
              }}
              placement="top"
            >
              <div
                className={`${styles.orientationButton}  ${
                  props.value?.flexDirection === 'column' ? styles.selected : null
                }`}
                onClick={(ev) => handleSelectDirection(ev, 'column')}
              >
                <Icon iconName="grip-lines"></Icon>
              </div>
            </HelpPopover>
          </div>
          <label className={`${styles.labelName}`}>
            {t(`designer.right_side.Spacing`)}
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.PositionTitleSpacing') ||
                  'designer.right_side.controls.PositionTitleSpacing',
                description:
                  t('designer.right_side.controls.PositionDescritptionSpacing') ||
                  'designer.right_side.controls.PositionDescritptionSpacing',
                note: [t('designer.right_side.controls.PositionNoteSpacing')]
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          </label>
          <div className={`${styles.justifyContainer}`}>
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.PositionBetweenTitle') ||
                  'designer.right_side.controls.PositionBetweenTitle',
                description:
                  t('designer.right_side.controls.PositionBetweenDescription') ||
                  'designer.right_side.controls.PositionBetweenDescription'
              }}
              placement="top"
            >
              <div
                className={`border rounded text-body-tertiary ${styles.justifyEvenlyWrapper} ${
                  props.value?.horizontalAlign === 'space-between' ? styles.selectedBorder : null
                }`}
              >
                <div
                  className={`${styles.justifyEvenlyContainer} `}
                  onClick={(ev) =>
                    props.onChange ? props.onChange('space-between', 'horizontalAlign') : null
                  }
                >
                  <div className={`${styles.justifyEvenlyIcon}`}>
                    <Icon iconName="square"></Icon>
                  </div>
                  <div className={`${styles.justifyEvenlyIcon}`}>
                    <Icon iconName="square"></Icon>
                  </div>
                  <div className={`${styles.justifyEvenlyIcon}`}>
                    <Icon iconName="square"></Icon>
                  </div>
                </div>
              </div>
            </HelpPopover>

            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.PositionEvenlyTitle') ||
                  'designer.right_side.controls.PositionEvenlyTitle',
                description:
                  t('designer.right_side.controls.PositionEvenlyDescription') ||
                  'designer.right_side.controls.PositionEvenlyDescription'
              }}
              placement="top"
            >
              <div
                className={`border rounded text-body-tertiary ${styles.justifyBetweenWrapper} ${
                  props.value?.horizontalAlign === 'space-evenly' ? styles.selectedBorder : null
                }`}
              >
                <div
                  className={`${styles.justifyBetweenContainer} `}
                  onClick={(ev) =>
                    props.onChange ? props.onChange('space-evenly', 'horizontalAlign') : null
                  }
                >
                  <div className={`${styles.justifyBetweenIcon}`}>
                    <Icon iconName="square"></Icon>
                  </div>
                  <div className={`${styles.justifyBetweenIcon}`}>
                    <Icon iconName="square"></Icon>
                  </div>
                  <div className={`${styles.justifyBetweenIcon}`}>
                    <Icon iconName="square"></Icon>
                  </div>
                </div>
              </div>
            </HelpPopover>
          </div>
        </div>

        <label className={`${styles.labelName}`}>
          {t(`designer.right_side.Anchors`)}
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.controls.PositionTitleAnchors') ||
                'designer.right_side.controls.PositionTitleAnchors',
              description:
                t('designer.right_side.controls.PositionDescriptionAnchors') ||
                'designer.right_side.controls.PositionDescriptionAnchors',
              note: [t('designer.right_side.controls.PositionNoteAnchors')]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </label>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            className={`border rounded ${styles.positionContainer} ${
              props.value?.flexDirection === 'column' ? styles.revertPositionContainer : null
            } container`}
          >
            <div className="row text-center">
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'start-start' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) => handleSelectPosition(ev, 'top-left')}
              >
                <Icon iconName="square"></Icon>
              </div>
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'start-center' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) => handleSelectPosition(ev, 'top-middle')}
              >
                <Icon iconName="square"></Icon>
              </div>
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'start-end' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) => handleSelectPosition(ev, 'top-right')}
              >
                <Icon iconName="square"></Icon>
              </div>
            </div>
            <div className="row text-center">
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'center-start' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) =>
                  selectedPosition !== 'center-start' ? handleSelectPosition(ev, 'middle-left') : {}
                }
              >
                <Icon iconName="square"></Icon>
              </div>
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'center-center' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) =>
                  selectedPosition !== 'center-center'
                    ? handleSelectPosition(ev, 'middle-middle')
                    : {}
                }
              >
                <Icon iconName="square"></Icon>
              </div>
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'center-end' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) =>
                  selectedPosition !== 'center-end' ? handleSelectPosition(ev, 'middle-right') : {}
                }
              >
                <Icon iconName="square"></Icon>
              </div>
            </div>
            <div className="row text-center">
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'end-start' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) =>
                  selectedPosition !== 'end-start' ? handleSelectPosition(ev, 'bottom-left') : {}
                }
              >
                <Icon iconName="square"></Icon>
              </div>
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'end-center' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) =>
                  selectedPosition !== 'end-center' ? handleSelectPosition(ev, 'bottom-middle') : {}
                }
              >
                <Icon iconName="square"></Icon>
              </div>
              <div
                className={`text-body-tertiary ${
                  selectedPosition === 'end-end' ? styles.selected : null
                } ${styles.positionIcon} col-4 p-2 text-center`}
                onClick={(ev) =>
                  selectedPosition !== 'end-end' ? handleSelectPosition(ev, 'bottom-right') : {}
                }
              >
                <Icon iconName="square"></Icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PositionControl;
