import { SocialNetwork } from '../enum';
import { UserProfile } from '../types/auth_types';
import { ApiError } from '../../project/types';

export const API_URL = process.env.REACT_APP_API_URL;

export default class Auth {
  updatePlan(name: string): UserProfile | PromiseLike<UserProfile> {
    throw new Error('Method not implemented.');
  }
  async login(email: string, password: string): Promise<UserProfile> {
    const data = { email, password };

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const url = new URL(`${API_URL}/login`);
    const request = new Request(url.href, options);
    return this.fetchUser(request);
  }

  async signUp(
    email: string,
    password: string,
    username: string,
    promotionalCode: string,
    recaptchaToken: string | null
  ): Promise<boolean> {
    const data = { username, password, email, promotionalCode, recaptchaToken };

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const url = new URL(`${API_URL}/signup`);
    const request = new Request(url.href, options);
    return this.fetchBoolean(request);
  }

  async logout() {
    try {
      const options: RequestInit = {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include'
      };

      await fetch(`${API_URL}/logout`, options);

      return true;
    } catch {
      return false;
    }
  }

  async resendVerificationEmail(email: string) {
    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const options: RequestInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
        credentials: 'include'
      };

      const url = new URL(`${API_URL}/resend_registration_token?email=${email}`);

      const request = new Request(url.href, options);

      await fetch(request);

      return true;
    } catch {
      return false;
    }
  }

  async resetPassword(email: string): Promise<boolean> {
    const options: RequestInit = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include'
    };

    const url = new URL(`${API_URL}/users/reset_password?email=${email}`);

    const request = new Request(url.href, options);
    return this.fetchBoolean(request);
  }

  async updatePasswordWithToken(token: string, password: string): Promise<boolean> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const data = { password };

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const url = new URL(`${API_URL}/users/password?token=${token}`);
    const request = new Request(url.href, options);
    return this.fetchBoolean(request);
  }

  async updatePasswordWithOldPassword(
    userId: number,
    password: string,
    oldPassword: string
  ): Promise<boolean> {
    const data = { password, oldPassword };

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const url = new URL(`${API_URL}/users/${userId}/password`);
    const request = new Request(url.href, options);
    return this.fetchBoolean(request);
  }

  async changeEmail(userId: number, newEmail: string): Promise<boolean> {
    const data = { email: newEmail };

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const url = new URL(`${API_URL}/users/${userId}/change_email`);
    const response = await fetch(url, options);
    if (response.ok) {
      return true;
    } else if (response.status >= 400 && response.status <= 500) {
      const apiError: ApiError = await response.json();
      throw new Error(apiError.message);
    }
    return false;
  }

  async updateUserInfo(idUser: number, name: string): Promise<UserProfile> {
    const data = { name };

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const url = new URL(`${API_URL}/users/${idUser}`);
    const request = new Request(url.href, options);
    return this.fetchUser(request);
  }

  async updateOpenAIKey(idUser: number, openAIKey: string): Promise<UserProfile> {
    const data = { openAIKey };

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const url = new URL(`${API_URL}/users/${idUser}`);
    const request = new Request(url.href, options);
    return this.fetchUser(request);
  }

  async saveWelcomeForm(userId: number, welcomeForm: string): Promise<UserProfile> {
    const data = { welcomeForm };

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const url = new URL(`${API_URL}/users/${userId}/welcomeform`);
    const request = new Request(url.href, options);
    return this.fetchUser(request);
  }

  async updatePageVisits(userId: number, pageVisits: Record<string, number>): Promise<UserProfile> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(pageVisits)
    };

    const url = new URL(`${API_URL}/users/${userId}/page-visits`);

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<UserProfile>;
      throw new Error('Something went wrong while trying to update page visits.');
    });
  }

  async getAssociatedAccounts(): Promise<string[]> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = new URL(`${API_URL}/oauth2/accounts`);
    const request = new Request(url.href, options);
    return this.fetchString(request);
  }

  async disconnectAssociatedAccounts(network: SocialNetwork): Promise<boolean> {
    const options: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include'
    };

    const url = new URL(`${API_URL}/oauth2/accounts?provider=${network}`);
    const request = new Request(url.href, options);
    return this.fetchBoolean(request);
  }

  private async fetchUser(request: Request): Promise<UserProfile> {
    const response = await fetch(request);
    if (response.ok) {
      return (await response.json()) as UserProfile;
    }
    const error = await response.json();
    if (error.error == 'Bad Request') throw new Error('Bad Request');
    if (error) throw new Error(error.message);
    else throw new Error('');
  }

  private async fetchBoolean(request: Request): Promise<boolean> {
    const response = await fetch(request);
    if (response.ok) {
      return true;
    }
    const error = await response.json();
    if (error) throw new Error(error.message);
    else throw new Error('');
  }

  private async fetchString(request: Request): Promise<string[]> {
    const response = await fetch(request);
    if (response.ok) {
      return (await response.json()) as string[];
    }
    const message = await response.text();
    throw new Error(message);
  }

  async deleteUser(userId: number, isUndo: boolean): Promise<boolean> {
    const url = new URL(`${API_URL}/users/${userId}/${isUndo ? 'delete_undo' : 'delete'}`);

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const response = await fetch(url, options);
    if (response.ok) {
      return true;
    } else if (response.status >= 400 && response.status <= 500) {
      const apiError: ApiError = await response.json();
      throw new Error(apiError.message);
    }
    return false;
  }
}
