import { Action } from 'redux';
import { ComponentMap, Relationship, relationshipID } from '../../../types';
import { addRelationshipAction, deleteRelationshipAction } from './root';
import { FrameUUID } from '../../../../../web_ui/workboard/frame';

export const SET_RELATIONSHIPS = 'SET_RELATIONSHIPS';
export const CHANGE_PROPERTY = 'CHANGE_PROPERTY';
export const CHANGE_RELATIONSHIP_TYPE = 'CHANGE_RELATIONSHIP_TYPE';

export type RelationshipActions =
  | setRelationshipsAction
  | changePropertyAction
  | addRelationshipAction
  | deleteRelationshipAction
  | changeRelationshipTypeAction;

export interface setRelationshipsAction extends Action {
  type: 'SET_RELATIONSHIPS';
  payload: {
    relationships: Relationship[];
  };
}

export interface changePropertyAction extends Action {
  type: 'CHANGE_PROPERTY';
  payload: {
    id: relationshipID;
    key: string;
    value: any;
  };
}

export interface changeRelationshipTypeAction extends Action {
  type: 'CHANGE_RELATIONSHIP_TYPE';
  payload: {
    type: string;
    id: relationshipID;
    from: FrameUUID;
    to: FrameUUID;
    components: ComponentMap;
  };
}

export const setRelationships = (relationships: Relationship[]): setRelationshipsAction => ({
  type: SET_RELATIONSHIPS,
  payload: { relationships }
});

export const changeProperty = (id: relationshipID, key: string, value: any) => ({
  type: CHANGE_PROPERTY,
  payload: { id: id, key: key, value: value }
});

export const changeRelationshipType = (
  id: relationshipID,
  from: string,
  to: string,
  components: ComponentMap
) => ({
  type: CHANGE_RELATIONSHIP_TYPE,
  // currently, this action only supports converting to ONE2ONE.
  payload: { type: 'ONE2ONE', id: id, from: from, to: to, components: components }
});
