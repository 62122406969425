import React from 'react';
import { AppFullInfo } from 'modules/project/types';
import { Col, Row } from 'react-bootstrap';
import { BackendFrameworkList, DatabaseList } from '../step4/data';
import { FrontendFrameworkList, ThemesList } from '../step5/data';
import ThemePreview from 'web_ui/theme_preview';
import { useTranslation } from 'react-i18next';

export type Step7Props = {
  /** Application info to use on the form */
  appInfo: AppFullInfo;
};

/**
 * Step7 of the application creation wizard.
 * This step shows a review of the application.
 *
 * @component
 */
function Step7(props: Step7Props) {
  const { t } = useTranslation();
  return (
    <>
      <h4 className="text-body-emphasis">{t('new_application.step5.start')}</h4>
      <div className="text-center mt-5" style={{ wordWrap: 'break-word' }}>
        <h4 className="text-body-primary">{props.appInfo.name}</h4>
        <div className="text-body-secondary">{props.appInfo.description}</div>
      </div>
      <Row className="text-center">
        {props.appInfo.has_database && (
          <Col>
            <h5 className="text-primary mt-5 mb-3">{t('new_application.step5.DataSources')}</h5>
            {DatabaseList.map(
              (database) =>
                props.appInfo.data_sources.some((item) => item.type === database.id) && (
                  <p key={database.id} className="text-body-primary">
                    <span className={`fa fa-${database.icon}`} /> {database.name}
                  </p>
                )
            )}
          </Col>
        )}
        {props.appInfo.has_backend && (
          <Col>
            <h5 className="text-primary mt-5 mb-3">{t('new_application.step5.Backend')}</h5>
            {BackendFrameworkList.map(
              (framework) =>
                props.appInfo.backend.framework === framework.id && (
                  <p key={framework.id}>
                    <span className={`fa fa-${framework.icon}`} /> {framework.name}
                  </p>
                )
            )}
          </Col>
        )}
        {props.appInfo.has_frontend && (
          <Col>
            <h5 className="text-primary mt-5 mb-3">{t('new_application.step5.Frontend')}</h5>
            {FrontendFrameworkList.map(
              (framework) =>
                props.appInfo.frontend.framework === framework.id && (
                  <p key={framework.id}>
                    <span className={`fa fa-${framework.icon}`} /> {framework.name}
                  </p>
                )
            )}
          </Col>
        )}
      </Row>
      {props.appInfo.has_frontend && (
        <Row>
          <Col sm="6" md="4" lg="4" xl="4" className="offset-md-4 offset-lg-4 offset-xl-4">
            <h5 className="text-primary mt-5 mb-4 text-center">{t('Theme')}</h5>
            {ThemesList.map(
              (theme) =>
                props.appInfo.frontend.theme === theme.id && (
                  <ThemePreview
                    id={`${theme.id.toLowerCase()}`}
                    key={theme.id}
                    type="radio"
                    group="ThemeSelector"
                    backgroundColor={theme.backgroundColor}
                    primaryColor={theme.primaryColor}
                    secondaryColor={theme.secondaryColor}
                    textColor={theme.textColor}
                    checked={true}
                    onChange={() => {}}
                  />
                )
            )}
          </Col>
        </Row>
      )}
    </>
  );
}

export default Step7;
