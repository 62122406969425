import { TagsRepository } from '../repos';
import { Tag } from '../types';

export class TagsServ {
  async getTags(appId: string): Promise<Tag[]> {
    return await TagsRepository.getTags(appId);
  }

  async getTag(appId: string, tagId: number): Promise<Tag> {
    return await TagsRepository.getTag(appId, tagId);
  }

  async createTag(appId: string, tag: Tag): Promise<Tag> {
    return await TagsRepository.crateTag(appId, tag);
  }

  async updateTag(appId: string, tagId: number, tag: Tag): Promise<Tag> {
    return await TagsRepository.updateTag(appId, tagId, tag);
  }

  async deleteTag(appId: string, tagId: number) {
    await TagsRepository.deleteTag(appId, tagId);
  }
}
