import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Dropdown } from 'react-bootstrap';
import Icon from 'web_ui/icon';

type ColumnDropdownMenuProps = {
  options: Option[];
};

type Option = {
  label: string;
  action: () => void;
};

export default function ColumnDropdownMenu({ options }: ColumnDropdownMenuProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = React.createRef<HTMLDivElement>();

  const toggleDropdown = useCallback(() => {
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  const preventDrag = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Dropdown
      className={`col-sm-1 ${styles.ColumnButtonWrapper} d-flex align-items-center justify-content-center px-0 py-0`}
      tabIndex={1}
      style={{
        cursor: 'pointer'
      }}
      onClick={toggleDropdown}
      show={showDropdown}
      ref={dropdownRef}
      draggable={true}
      onDragStart={preventDrag}
    >
      <Dropdown.Toggle
        as="i"
        id="columnDropdownButton"
        className="w-100 d-flex align-items-center justify-content-center"
      >
        <Icon iconName="ellipsis-v"></Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options &&
          options.map((option, index) => {
            return (
              <Dropdown.Item key={index} onClick={() => option.action()}>
                <i id="deleteButton" style={{ marginRight: '8px' }} className="fa-solid fa-trash" />
                {option.label}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
