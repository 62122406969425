import { Action } from 'redux';
import { indexColumnState } from '..';
import { Index, IndexID } from '../../../types';

export const SET_INDEXES = 'SET_INDEXES';
export const CHANGE_INDEX_PROPERTY = 'CHANGE_INDEX_PROPERTY';
export const UPDATE_INDEX_COLUMNS = 'UPDATE_INDEX_COLUMNS';

export interface SetIndexesAction extends Action {
  type: 'SET_INDEXES';
  payload: {
    indexes: Index[];
  };
}

export interface ChangeIndexPropertyAction extends Action {
  type: 'CHANGE_INDEX_PROPERTY';
  payload: {
    id: IndexID;
    key: string;
    value: any;
  };
}

export interface UpdateIndexColumnsAction extends Action {
  type: 'UPDATE_INDEX_COLUMNS';
  payload: {
    id: IndexID;
    indexColumns: indexColumnState;
  };
}

export const setIndexes = (indexes: Index[]): SetIndexesAction => ({
  type: SET_INDEXES,
  payload: { indexes }
});

export const changeIndexProperty = (
  indexID: IndexID,
  key: string,
  value: any
): ChangeIndexPropertyAction => ({
  type: CHANGE_INDEX_PROPERTY,
  payload: {
    id: indexID,
    key: key,
    value: value
  }
});

export const updateIndexColumns = (
  indexID: IndexID,
  indexColumns: indexColumnState
): UpdateIndexColumnsAction => ({
  type: UPDATE_INDEX_COLUMNS,
  payload: {
    id: indexID,
    indexColumns: indexColumns
  }
});
