import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

// link translate name property
export const IMAGE_BUTTON_MANIFEST: ComponentManifest = {
  type: 'IMAGEBUTTON',
  name: 'Imagebutton',
  descriptionId: 'ImageButton',
  description: 'button with image background.',
  icon: 'fa-solid fa-image',
  group: ComponentGroups['BUTTON'],
  previewHeight: 100,
  previewWidth: 150,
  properties: [
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.imageButton.visible'
    },
    {
      controlType: ControlsTypes.VARIABLEREADONLY,
      controlLabel: 'ReadonlyVariable',
      key: 'readonlyVar',
      tooltip: 'designer.right_side.controls.components.imageButton.readonlyVar'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Readonly',
      key: 'readonly',
      tooltip: 'designer.right_side.controls.components.imageButton.readOnly'
    },
    {
      controlType: ControlsTypes.SELECT_PICS,
      controlLabel: 'Image',
      key: 'source',
      tooltip: 'designer.right_side.controls.components.imageButton.pics'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.imageButton.tooltip'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Disabled',
      key: 'disabled',
      tooltip: 'designer.right_side.controls.components.imageButton.disabled'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'DisabledVariable',
      key: 'disabledVar'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.imageButton.textColor'
    },
    {
      controlType: ControlsTypes.FONT,
      controlLabel: t('Font'),
      tooltip: 'designer.right_side.controls.components.imageButton.Font'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Font Weight',
      key: 'fontWeight',
      controlOptions: ['normal', 'bold', '100', '300', '500', '600', '800', '900'],
      tooltip: 'designer.right_side.controls.components.imageButton.fontWeight'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: t('Spacing'),
      key: 'lineHeight',
      controlOptions: ['normal', 'inherit', 'initial', 'revert', 'revert-layer', 'unset'],
      tooltip: 'designer.right_side.controls.components.imageButton.lineHeight'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.imageButton.fontSize'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'LineHeight',
      key: 'lineHeight',
      tooltip: 'designer.right_side.controls.components.imageButton.lineHeight'
    },
    {
      controlType: ControlsTypes.TEXTALIGN,
      controlLabel: 'Text Align',
      key: 'textAlign',
      tooltip: 'designer.right_side.controls.components.imageButton.textAlign'
      // controlOptions: ['left', 'center', 'right']
    },
    {
      controlType: ControlsTypes.TEXTDECORATION,
      controlLabel: 'Text Decoration',
      key: 'textDecoration',
      tooltip: 'designer.right_side.controls.components.imageButton.textDecoration'
    },
    {
      controlType: ControlsTypes.TEXTTRANSFORM,
      controlLabel: 'Text Transform',
      key: 'textTransform',
      tooltip: 'designer.right_side.controls.components.imageButton.textTransform'
    },
    {
      controlType: ControlsTypes.FONTSTYLE,
      controlLabel: 'Font Style',
      key: 'fontStyle',
      tooltip: 'designer.right_side.controls.components.imageButton.fontStyle'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CLICK', 'HOVER']
};
