import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import { ReturnType } from 'web_ui/function_editor/store/types/functions';
import {
  DataType,
  InputType,
  Method,
  METHODS,
  ParameterEndpointCrud
} from 'modules/logic_builder/types';
import Icon from 'web_ui/icon';
import DataTypePicker from '../params/signature/data_picker';
import useFetchTypes from 'web_ui/function_editor/action_inputs/utils/useFetchTypes';
import { FRONTEND_VARIABLE_TYPES } from 'modules/designer/types';
import { useTranslation } from 'react-i18next';

type ParameterEditorProps = {
  uuid: string;
  types: ReturnType[];
  currParam: ParameterEndpointCrud;
  parameters: ParameterEndpointCrud[];
  endpointMethod: Method;
  handleChangeParameters: (parameters: ParameterEndpointCrud[]) => void;
};

function ParameterEditor({
  uuid,
  types,
  currParam,
  parameters,
  endpointMethod,
  handleChangeParameters
}: ParameterEditorProps) {
  const { t } = useTranslation();
  //const function_id = useSelector((state: FunctionEditorState) => state.editor.functionId);
  //const param = useSelector((state: FunctionEditorState) => state.parameters[uuid]);
  const [validName, setValidName] = useState('');
  //const validateParamName = useValidateParamName();
  const { enums, objects } = useFetchTypes(Object.values(FRONTEND_VARIABLE_TYPES), 'react');

  useEffect(() => {
    if (!currParam || !currParam.name) return;
    setValidName(currParam.name);
  }, [currParam]);

  const handleChangeName = (name: string) => {
    setValidName(name);

    const myParams = parameters;
    const editingIndexParam = myParams.indexOf(currParam);
    myParams[editingIndexParam].name = name;

    handleChangeParameters(myParams);
  };

  const handleChangeNameOnBlur = () => {
    //if (param.name !== validName) dispatch(updateParam(uuid, validName, param.desc));
  };

  const handleChangeType = (newType: ReturnType) => {
    const myParams = parameters;
    const editingIndexParam = myParams.indexOf(currParam);

    myParams[editingIndexParam].type = newType.type as DataType;
    myParams[editingIndexParam].objectUuid = newType.objectUuid;
    myParams[editingIndexParam].enumUuid = newType.enumUuid;
    myParams[editingIndexParam].name = newType.name!;

    handleChangeParameters(myParams);
  };

  const handleDelete = (uuid: string) => {
    const myParams = parameters;
    const paramsFound = myParams.find((item) => item.uuid === uuid);
    if (paramsFound) {
      const editingIndexParam = myParams.indexOf(paramsFound);
      myParams.splice(editingIndexParam, 1);
      handleChangeParameters(myParams);
    }
  };

  if (!currParam) return null;

  const handleChangeList = (val: boolean) => {
    const myParams = parameters;
    const editingIndexParam = myParams.indexOf(currParam);

    myParams[editingIndexParam].list = val;

    handleChangeParameters(myParams);
  };

  const handleChangeParamMethod = (val: string) => {
    const paramCopy = parameters;
    const currEditingParamIndex = paramCopy.indexOf(currParam); // get the index current
    paramCopy[currEditingParamIndex].inputType = val as InputType;
    handleChangeParameters(paramCopy);
  };

  return (
    <div className={`border-body-tertiary ${styles.ParameterEditor}`}>
      <div className={'d-flex'}>
        <Icon iconName={'fa-solid fa-ellipsis-vertical'} />
        <Icon iconName={'fa-solid fa-ellipsis-vertical'} />
      </div>
      <div className={styles.ReturnType} style={{ width: '350px', marginLeft: '0.5rem' }}>
        <DataTypePicker
          handleChange={handleChangeType}
          type={{
            type: currParam.type,
            objectUuid: currParam.objectUuid,
            enumUuid: currParam.enumUuid,
            list: currParam.list
          }}
          types={types}
          disabled={currParam?.native}
          enums={enums}
          objects={objects}
        />
        <div>
          <Form.Select
            className={styles.methodEndpoint}
            value={currParam.inputType}
            onChange={(e) => {
              handleChangeParamMethod(e.target.value);
            }}
            disabled={currParam.native}
          >
            <option value={'NONE'}>NONE</option>
            <option value={'PATH'}>PATH</option>
            <option value={'QUERY'}>QUERY</option>
            <option value={'HEADER'}>HEADER</option>
            <option value={'COOKIE'}>COOKIE</option>
            {(endpointMethod === METHODS.POST ||
              endpointMethod === METHODS.PUT ||
              endpointMethod === METHODS.PATCH) && <option value={'BODY'}>BODY</option>}
          </Form.Select>
        </div>
        <div className={styles.ReturnTypeListCheck}>
          <Form.Label style={{ marginBottom: '0' }}>{t('automation.step4.list')}</Form.Label>
          <Form.Check
            disabled={currParam.native}
            checked={currParam && currParam.list ? currParam.list : false}
            onChange={(e) => handleChangeList(e.target.checked)}
          />
        </div>
      </div>
      <Form.Control
        isInvalid={currParam.name !== validName}
        value={currParam.name}
        onChange={(e) => handleChangeName(e.target.value)}
        onBlur={() => handleChangeNameOnBlur()}
        style={{
          borderBottomLeftRadius: '0',
          borderTopLeftRadius: '0',
          marginLeft: 0,
          marginRight: '0.5rem',
          width: '9rem'
        }}
        disabled={currParam?.native}
      />
      {currParam?.native ? (
        <div
          id="deleteParamButton"
          className={`btn btn-sm ${styles.deleteButtonContainerDisabled}`}
        >
          <Icon iconName={'trash'} extraProps={'text-white'} />
        </div>
      ) : (
        <div
          className={`btn btn-sm ${styles.deleteButtonContainer}`}
          onClick={() => handleDelete(currParam.uuid!)}
        >
          <Icon iconName={'trash'} extraProps={'text-white'} />
        </div>
      )}
    </div>
  );
}

export default ParameterEditor;
