import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { InvitationService } from 'modules/invitation/service';
import { AddInvite } from 'modules/invitation/types';
import { useTranslation } from 'react-i18next';
import InvitesList from 'modules/organization/components/invites_list';
import { RoleType } from 'modules/organization/types';

import styles from './style.module.css';

export type InviteOrganizationModalProps = {
  showModal: boolean;
  onCloseRequest: () => void;
  listRoles: RoleType[];
  reloadSelectedList: () => void;
};

function InviteOrganizationModal({
  showModal,
  onCloseRequest,
  listRoles,
  reloadSelectedList
}: InviteOrganizationModalProps) {
  const { org_id } = useParams();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState<AddInvite[]>([]);

  const { t } = useTranslation();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!org_id) return;

    try {
      setLoading(true);
      await InvitationService.createInviteOrg(+org_id, invites);
      reloadSelectedList();
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setInvites([]);
    onCloseRequest();
  };

  return (
    <Modal centered show={showModal} onHide={() => onClose()} backdrop={'static'} size={'lg'}>
      <form onSubmit={onSubmit} id="formModal">
        <Modal.Header closeButton className={`bg-body ${styles.newOrgModalHeader}`}>
          <Modal.Title className={styles.newOrgModalTitle}>
            <strong>{t('invitation.InviteToOrganization')}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="bodyModal">
          {/* Invites */}
          <InvitesList invites={invites} setInvites={setInvites} listRoles={listRoles} />
          <div className={styles.dialogSaveButton}>
            <Button
              id={'inviteButton-2'}
              type="submit"
              disabled={isLoading || invites.length === 0}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={
                  isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`
                }
              />
              {t('invitation.SendInvite')}
            </Button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
}

export default InviteOrganizationModal;
