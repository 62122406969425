import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateFunction } from '../../../../store/events/functions/update_function';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import useValidateFunctionName from '../../../../hooks/useValidateFunctionName';

type Props = {
  framework?: string;
};

function NameEditor(props: Props) {
  const functionId = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const state = useSelector((state: FunctionEditorState) => state);
  const name = useSelector((state: FunctionEditorState) => state.functions[functionId ?? ''].name);
  const [validName, setValidName] = useState('');
  const id = state.editor.functionId;
  const currFunc = state.functions[id];
  const dispatch = useDispatch();
  const validateFunctionName = useValidateFunctionName();

  useEffect(() => {
    setValidName(name);
  }, []);

  const onNameChange = (newName: string) => {
    if (props.framework === 'react') {
      if (validateFunctionName(newName)) {
        setValidName(newName);
      } else {
        validateFunctionName(name) && setValidName(name);
      }
      dispatch(
        updateFunction(functionId, newName, currFunc?.desc ?? '', currFunc?.crudType ?? 'null')
      );
      return;
    } // it will run this way in designer
    setValidName(newName);
    dispatch(
      updateFunction(functionId, newName, currFunc?.desc ?? '', currFunc?.crudType ?? 'null')
    );
  };

  const handleChangeNameOnBlur = () => {
    if (name !== validName) dispatch(updateFunction(functionId, validName, '', ''));
  };

  return (
    <Form.Control
      isInvalid={name !== validName}
      value={name}
      onChange={(e) => onNameChange(e.target.value)}
      onBlur={() => handleChangeNameOnBlur()}
    />
  );
}

export default NameEditor;
