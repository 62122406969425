import { t } from 'i18next';
import { Teams } from 'modules/auth/types/auth_types';
import React, { useState } from 'react';
import { Row, Col, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';
import ManageTeamModal from '../manage_team_modal';
import { TeamServ } from 'modules/organization/services';

type Props = {
  teams: Teams[];
  orgId: number | null;
  updateList: () => void;
};

const TeamList = ({ teams, orgId, updateList }: Props) => {
  const [openManageTeam, setOpenManageTeam] = useState<boolean>(false);
  const [currentTeamId, setCurrentTeamId] = useState<number>(-1);

  const closingManageTeam = () => {
    setOpenManageTeam(false);
  };

  const openingManageTeam = (teamId: number) => {
    setCurrentTeamId(teamId);
    setOpenManageTeam(true);
  };

  const deletingTeam = async (teamId: number) => {
    if (!orgId) return;
    const dataRetrieved = await TeamServ.deleteTeam(orgId, teamId);
    updateList();
  };

  return (
    <div>
      {teams.map((item, index) => (
        <Row
          id={item.name.substring(0, 5).toUpperCase()}
          className={`d-flex  p-2 ${styles.container}`}
          key={item.id}
        >
          {/* Avatar */}
          <Col xs={'auto'} className={`d-flex align-items-center`}>
            <div className={`${styles.avatar} me-2}`}>
              {item.name.substring(0, 1).toUpperCase()}
            </div>
          </Col>
          {/* Username */}
          <Col className={`${styles.infoCol} d-flex ps-0`}>
            <Col
              style={{
                fontWeight: '500',
                overflowWrap: 'break-word',
                paddingLeft: '0.75rem'
              }}
            >
              {item.name}
            </Col>

            <Col className={'d-flex'}>
              {/* Operations in Members */}
              <Col className={`${styles.iconsMember}`}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover>
                      <div className="p-2">{t('Update')}</div>
                    </Popover>
                  }
                  delay={{ show: 50, hide: 0 }}
                >
                  <Button
                    id={`deleteButton-${index}`}
                    className={styles.iconButton}
                    onClick={() => {
                      openingManageTeam(item.id);
                    }}
                  >
                    <Icon iconName="pen-to-square" brands="regular" padding="0.5rem" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover>
                      <div className="p-2">{t('Delete')}</div>
                    </Popover>
                  }
                  delay={{ show: 50, hide: 0 }}
                >
                  <Button
                    id={`deleteButton-${index}`}
                    className={styles.iconButton}
                    onClick={() => {
                      deletingTeam(item.id);
                    }}
                  >
                    <Icon iconName="trash-can" brands="regular" padding="0.5rem" />
                  </Button>
                </OverlayTrigger>
              </Col>
            </Col>
          </Col>
        </Row>
      ))}
      <ManageTeamModal
        show={openManageTeam}
        close={closingManageTeam}
        orgId={orgId}
        updateList={updateList}
        teamId={currentTeamId}
      />
    </div>
  );
};

export default TeamList;
