import React from 'react';
import styles from '../../styles.module.css';
import { Button } from 'react-bootstrap';
import PagesNavigation from './pages_navigation';
import { useTranslation } from 'react-i18next';

type BottomContentProps = {
  selectedLanguage: string;
  mainLanguageId: string;
  translationsCount: number;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  setShowAddTranslationModal: (show: boolean) => void;
};

function BottomContent({
  selectedLanguage,
  mainLanguageId,
  selectedPage,
  setSelectedPage,
  translationsCount,
  setShowAddTranslationModal
}: BottomContentProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.bottomContent}>
      {selectedLanguage === mainLanguageId ? (
        <Button
          size="sm"
          id="addTanslationButton"
          onClick={() => setShowAddTranslationModal(true)}
          className={styles.btnAddTranslation}
          variant="secondary"
        >
          {t('appResume.languages.AddTranslation')}
        </Button>
      ) : (
        <div />
      )}
      <PagesNavigation
        selectedPage={selectedPage}
        translationsCount={translationsCount}
        setSelectedPage={setSelectedPage}
      />
    </div>
  );
}

export default BottomContent;
