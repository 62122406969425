import { Table } from 'react-bootstrap';
import React from 'react';
import { TableUUID } from 'modules/modeler/types';
import { useTranslation } from 'react-i18next';

type TableDataProps = {
  // selected table id
  tableID: TableUUID;
};

/**
 * data editor tab, used to manage mocked example data
 *
 * @component
 */
function TableData({ tableID }: TableDataProps) {
  const { t } = useTranslation();

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>{t('modeler.entity_editor.data.First Name')}</th>
          <th>{t('modeler.entity_editor.data.Last Name')}</th>
          <th>{t('modeler.entity_editor.data.Username')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <td>3</td>
          <td colSpan={2}>Larry the Bird</td>
          <td>@twitter</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default TableData;
