import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ItemType } from '../../../components/dropdown';
import { ModalRepository, PageRepository } from '../../../../../../modules/designer/repos';
import { AllowedModes } from '../index';

function useGetViews(mode: AllowedModes) {
  const { module_id, app_id } = useParams();
  const [items, setItems] = useState({} as { [key: string]: ItemType });

  useEffect(() => {
    if (!mode) return;
    const getViewItems = async () => {
      const i = await allowedModesFunctions[mode]();
      setItems(i);
    };
    getViewItems();
  }, [mode, module_id]);

  const allowedModesFunctions: {
    [Property in AllowedModes]: () => Promise<{ [key: string]: ItemType }>;
  } = {
    page: getPagesByApp,
    modal: getModalsByModule,
    layout: getLayoutsByModule
  };

  async function getPagesByApp(): Promise<{ [key: string]: ItemType }> {
    if (!app_id) return {};
    const pages = await PageRepository.getPageByApp(app_id);
    if (!pages) return {};
    const i: { [key: string]: ItemType } = {};
    pages.forEach((p) => (i[p.uuid] = { name: p.name, uuid: p.uuid }));
    return i;
  }

  async function getModalsByModule(): Promise<{ [key: string]: ItemType }> {
    if (!module_id) return {};
    const modals = await ModalRepository.getModalsByModule(module_id);
    if (!modals) return {};
    const i: { [key: string]: ItemType } = {};
    modals.forEach((p) => (i[p.uuid] = { name: p.name, uuid: p.uuid }));
    return i;
  }

  async function getLayoutsByModule(): Promise<{ [key: string]: ItemType }> {
    // TODO: implement this
    return {};
  }

  return items;
}

export default useGetViews;
