import { Column, DataType, EnumUUID } from '../../../../../types';
import React from 'react';
import { changeColumnType } from '../../../../store/actions/columns';
import { useDispatch } from 'react-redux';
import { ReturnType } from '../../../../../../../web_ui/function_editor/store/types/functions';
import { ColumnDataTypePicker } from './column_data_type_picker';

type ColumnTypeSelectorProps = {
  column: Column;
  fieldTypes: { [key: string]: DataType[] };
  handleChange?: (type: string, enumUUID?: EnumUUID) => void;
  advanced?: boolean;
};

export const ColumnTypeSelector = React.forwardRef<HTMLInputElement, ColumnTypeSelectorProps>(
  ({ column, fieldTypes, handleChange, advanced }, ref) => {
    const dispatch = useDispatch();

    const handleChangeType = (dataType: ReturnType) => {
      if (!dataType) return;

      handleChange
        ? handleChange(dataType.type, dataType?.enumUuid)
        : dispatch(changeColumnType(column.uuid, dataType.type, dataType?.enumUuid));
    };

    const mapFieldTypesToReturnTypes = (): ReturnType[] => {
      if (!fieldTypes) return [] as ReturnType[];
      const columnType = column?.isPK ? 'KEYS' : 'FIELDS';
      return (
        fieldTypes[columnType] &&
        fieldTypes[columnType].map((t, index) => ({
          type: t.type,
          enumUuid: t.id,
          name: t?.name
        }))
      );
    };

    return (
      <ColumnDataTypePicker
        handleChange={handleChangeType}
        ref={ref}
        type={{ type: column?.type, enumUuid: column?.enumUUID }}
        types={mapFieldTypesToReturnTypes()}
        advanced={advanced}
      />
    );
  }
);
