import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { GitRepo } from '../../../../../../modules/integrations/types';
import { IntegrationsService } from '../../../../../../modules/integrations/services';
import { useTranslation } from 'react-i18next';

interface InformationAppProps {
  appId: string;
  gitRepository: string;
  createPR: boolean;
  autoMerge: boolean;
  setGitRepository: React.Dispatch<React.SetStateAction<string>>;
  setCreatePR: React.Dispatch<React.SetStateAction<boolean>>;
  setAutoMergeR: React.Dispatch<React.SetStateAction<boolean>>;
}

function IntegrationsApp(props: InformationAppProps) {
  const { t } = useTranslation();
  const [repos, setRepos] = useState<GitRepo[]>([]);

  useEffect(() => {
    const fetchRepos = async () => {
      const result = await IntegrationsService.getAvailableRepositories(props.appId);
      setRepos(result);
    };
    fetchRepos();
  }, []);

  return (
    <>
      <div className="mt-3 ms-3 me-3">
        <Form.Group className="mb-3">
          <Form.Label>GitHub</Form.Label>
          <Form.Select
            id="selectRepository"
            value={props.gitRepository}
            onChange={(e) => props.setGitRepository(e.target.value)}
          >
            <option value="">---</option>
            {repos.map((repo) => (
              <option id={`${repo.name}`} key={repo.name}>
                {repo.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {props.gitRepository && (
          <>
            <Form.Group className="mb-3">
              <Form.Check
                id="createPullRequestCheck"
                label="Create Pull Request"
                onChange={(e) => props.setCreatePR(e.target.checked)}
                checked={props.createPR}
              />
            </Form.Group>
            {props.createPR && (
              <Form.Group className="mb-3">
                <Form.Check
                  id="automaticallyMergeCheck"
                  label="Automatically Merge"
                  onChange={(e) => props.setAutoMergeR(e.target.checked)}
                  checked={props.autoMerge}
                />
              </Form.Group>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default IntegrationsApp;
