import { AppPropertiesRepository } from '../repos';
import { AppProperties } from '../types';
export class AppPropertiesServ {
  async getProperties(appId: string) {
    return await AppPropertiesRepository.getProperties(appId);
  }

  async updateProperties(appId: string, properties: AppProperties[]) {
    return await AppPropertiesRepository.updateProperties(appId, properties);
  }
}
