import React from 'react';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { EndpointResponseSimple } from '../../../../../modules/logic_builder/types';
import Dropdown from '../../components/dropdown';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

type ResponseEditorProps = {
  response: EndpointResponseSimple;
  value: string;
  handleChange: (value: string) => void;
  actionUuid: string;
  isFromSideBar?: boolean;
};

function ResponseEditor({
  response,
  value,
  handleChange,
  actionUuid,
  isFromSideBar
}: ResponseEditorProps) {
  const variables = useSelector((state: FunctionEditorState) => state.variables);

  const filteredVariables = () => {
    let filtered = Object.values(variables);
    if (response?.list) filtered = filtered.filter((v) => v.list);
    if (response?.objectUuid)
      filtered = filtered.filter((v) => v.objectUuid === response.objectUuid);
    return filtered;
  };

  return (
    <div className={'d-flex align-items-end'}>
      <Dropdown
        items={filteredVariables()}
        placeholder={`${t('SelectVariable')}`}
        label={'Response'}
        value={variables[value]}
        handleChange={handleChange}
        isFromSideBar={isFromSideBar}
      />
    </div>
  );
}

export default ResponseEditor;
