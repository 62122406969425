import React, { ChangeEvent, useRef, useState, useEffect, MouseEvent, useContext } from 'react';
import { Button, Card, Col, Container, Form, Modal, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TableAdvanced from './tabs/advanced';
import TableData from './tabs/data';
import TableFields from './tabs/columns';
import TableIndexes from './tabs/indexes';
import TableRelations from './tabs/relations';
import { DatabaseStudioState } from 'modules/modeler/studio/store';
import { changeTableProperty, changeTableName } from 'modules/modeler/studio/store/actions/frames';
import { TableUUID } from 'modules/modeler/types';
import { setErrorMessage, setSelectedFrame } from '../../store/actions/studio';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaMagic } from 'react-icons/fa';
import TableActionsDropdown from '../table_actions_dropdown';
import { AppContext } from 'modules/project/store/app_context';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import SessionContext from 'modules/auth/store';
import { getAdvancedDialogActions } from 'web_ui/walkthrough/actions';
import { useClickOutsideEvent } from 'hooks/useClickOutside';
import { WalkthroughButton } from 'web_ui/walkthrough/walkthrough_button';

export type AdvancedEditorProps = {
  // selected table id
  tableID: TableUUID;
  // show or hide the modal
  showModal: boolean;
  // callback function trigged on modal close
  onCloseRequest: () => void;
};

/**
 * Modal with advanced settings of a table
 * ex: (advanced setting of table, columns, indexes, relationships, etc)
 *
 * @component
 */
function AdvancedEditor({ tableID, showModal, onCloseRequest }: AdvancedEditorProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageVisits, updatePageVisits } = useContext(SessionContext);
  const { module_id, app_id } = useParams();
  const appInfo = useContext(AppContext).projectInformation;
  // context tables array
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  // used to manage the selected tab
  const [selectedSection, setSelectedSection] = useState<string>('FIELDS');
  // used to manage the selected tab
  const [newTableName, setNewTableName] = useState<string>('');
  // reference of the modal div, to make visual effects
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const driverInstance = useRef<any>();
  const currentContext = 'modelerAdvandedTableModal';

  const name = t('Name');
  const description = t('Description');

  useEffect(() => {
    dispatch(setSelectedFrame(tableID));
  }, [dispatch, tableID]);

  const tableName = tables[tableID].content.data.name;
  useEffect(() => {
    setNewTableName(tableName);
  }, [tableName]);

  // change the selected context table name
  function handleChangeTableName() {
    if (!tableID) return;

    const oldName = tables[tableID].content.data.name;
    if (!newTableName) {
      setNewTableName(oldName);
      return;
    }

    if (newTableName.length < 2) {
      dispatch(setErrorMessage('modeler.NameTooShortMessage'));
      setNewTableName(oldName);
      return;
    }

    const newName = newTableName.split(' ').join('');
    if (!handleCheckNameExists(newName)) {
      dispatch(changeTableName(tableID, newName));
    } else {
      if (newName.toLowerCase() !== tables[tableID].content.data.name.toLowerCase()) {
        setNewTableName(oldName);
        dispatch(setErrorMessage('modeler.ExistingNameMessage'));
      }
    }
  }

  // Search on context, returd the table id with it exists, otherwise return false or undefined.
  function handleCheckNameExists(name: string) {
    const nameAlreadyExists = Object.keys(tables).find((tableID: TableUUID) => {
      return tables[tableID].content.data.name.toLowerCase() === name.toLowerCase() ? true : false;
    });

    return nameAlreadyExists;
  }

  function handleChangeTableDescription(e: ChangeEvent<HTMLInputElement>) {
    if (!tableID) return;
    dispatch(changeTableProperty(tableID, 'description', e.target.value));
  }

  function handleOnMouseDown(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
  }

  useEffect(() => {
    if (!showModal) {
      return;
    }
    if (!pageVisits) {
      return;
    }
    if (!modalRef.current) {
      return;
    }

    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done',
      onClose: () => {
        updatePageVisits(currentContext);
      },
      actions: getAdvancedDialogActions(() => setSelectedSection('FIELDS'))
    });
  }, [pageVisits, showModal, updatePageVisits]);

  function handleOpenWalkthrough() {
    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done',
      actions: getAdvancedDialogActions(() => setSelectedSection('FIELDS'))
    });
  }

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  return (
    <div onMouseDown={handleOnMouseDown} style={{ zIndex: '1' }}>
      <Modal
        size="lg"
        id={'advancedEditor'}
        centered
        show={showModal}
        onHide={onCloseRequest}
        scrollable
      >
        <div
          id={WALKTHROUGH_STEPS_ELEMENTS['advanced-editor']}
          style={{
            transition: 'ease-in-out 0.2s',
            height: '925px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowY: 'hidden'
          }}
          ref={modalRef}
        >
          <Modal.Header style={{ height: '71px' }}>
            <Modal.Title>
              <div className="d-flex justify-content-between align-items-center">
                <span className="me-2">{t('modeler.entity_editor.Entity Editor')}</span>
                <WalkthroughButton onClick={handleOpenWalkthrough} />
              </div>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ overflowY: 'auto' }} className={'d-flex flex-1 overflow-hidden'}>
            <Container className={'d-flex flex-1 flex-column overflow-hidden'}>
              <Row style={{ height: '92px' }}>
                <Col xs="12">
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId={WALKTHROUGH_STEPS_ELEMENTS['entity-name']}
                  >
                    <Form.Label column sm={3}>
                      {t('Name')}
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        value={newTableName}
                        type="text"
                        maxLength={64}
                        placeholder={name}
                        onChange={(e) => setNewTableName(e.target.value)}
                        onBlur={handleChangeTableName}
                        ref={inputRef}
                      />
                    </Col>
                  </Form.Group>
                </Col>

                <Col xs="12">
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId={WALKTHROUGH_STEPS_ELEMENTS['entity-description']}
                  >
                    <Form.Label column sm={3}>
                      {t('Description')}
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        value={tables[tableID] ? tables[tableID].content.data.description : ''}
                        as={'textarea'}
                        rows={1}
                        maxLength={255}
                        placeholder={description}
                        onChange={handleChangeTableDescription}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ maxHeight: '90%' }} className="mt-2 d-flex flex-grow-1 overflow-hidden">
                <Card
                  style={{ maxHeight: '100%' }}
                  className="p-0 h-10 d-flex flex-column overflow-hidden"
                >
                  <Card.Header style={{ height: '50px' }}>
                    <Nav variant="tabs" defaultActiveKey="#first">
                      <Nav.Item>
                        <Nav.Link
                          id="fieldsTab"
                          active={selectedSection === 'FIELDS'}
                          href="#"
                          onClick={() => setSelectedSection('FIELDS')}
                        >
                          {t('modeler.entity_editor.Fields')}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          id="indexesTab"
                          active={selectedSection === 'INDEXES'}
                          href="#"
                          onClick={() => setSelectedSection('INDEXES')}
                        >
                          {t('modeler.entity_editor.Indexes')}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          id="relationsTab"
                          active={selectedSection === 'RELATIONS'}
                          href="#"
                          onClick={() => setSelectedSection('RELATIONS')}
                        >
                          {t('modeler.entity_editor.Relations')}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          id="advancedTab"
                          active={selectedSection === 'ADVANCED'}
                          href="#"
                          onClick={() => setSelectedSection('ADVANCED')}
                        >
                          {t('modeler.entity_editor.Advanced')}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Header>
                  <Card.Body className={'flex-grow-1 overflow-hidden'}>
                    {selectedSection === 'FIELDS' && <TableFields tableID={tableID} />}
                    {selectedSection === 'INDEXES' && <TableIndexes tableID={tableID} />}
                    {selectedSection === 'DATA' && <TableData tableID={tableID} />}
                    {selectedSection === 'RELATIONS' && (
                      <TableRelations tableID={tableID} modalRef={modalRef} />
                    )}
                    {selectedSection === 'ADVANCED' && <TableAdvanced tableID={tableID} />}
                  </Card.Body>
                </Card>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer style={{ height: '71px' }} className="d-flex justify-content-between">
            <div className="d-flex gap-2">
              <TableActionsDropdown
                tableID={tableID}
                disabled={tableID && tables[tableID] && tables[tableID].content.data.native}
              />
              {appInfo?.has_backend && (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={(e) => {
                    navigate(
                      `/app/${app_id}/module/${module_id}/logic/automation-wizard?entity=${tableID}`
                    );
                  }}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FaMagic
                    size={15}
                    style={{ marginRight: 4, marginBottom: 1, padding: '0px', height: '1.5rem' }}
                    color="white"
                  />
                  {t('CrudAssistant')}
                </Button>
              )}
            </div>
            <Button
              onClick={onCloseRequest}
              size="sm"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <AiOutlineCloseCircle
                size={24}
                style={{ marginRight: 4, padding: '0px', height: '1.5rem' }}
              />
              {t('modeler.entity_editor.Exit')}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}

export default AdvancedEditor;
