import * as React from 'react';
import { ActionType } from 'modules/logic_builder/types';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';

type Props = {
  ActionType: ActionType;
};

export default function IconActionsTab(props: Props) {
  switch (props.ActionType) {
    case 'RUN_FUNCTION':
      return (
        <div className={`bg-secondary ${styles.iconsPatterns}`}>
          <Icon iconName="florin-sign" extraProps="text-white" />
        </div>
      );
    case 'READ_ENTITY':
      return (
        <div className={`bg-info ${styles.iconsPatterns}`}>
          <Icon iconName="code" extraProps="text-white" />
        </div>
      );
    case 'CREATE_ENTITY':
      return (
        <div className={`bg-info ${styles.iconsPatterns}`}>
          <Icon iconName="code" extraProps="text-white" />
        </div>
      );
    case 'DELETE_ENTITY':
      return (
        <div className={`bg-info ${styles.iconsPatterns}`}>
          <Icon iconName="code" extraProps="text-white" />
        </div>
      );
    case 'SET_VARIABLE':
      return (
        <div className={`bg-warning ${styles.iconsPatterns}`}>
          <Icon iconName="equals" extraProps="text-white" />
        </div>
      );
    case 'UPDATE_ENTITY':
      return (
        <div className={`bg-info ${styles.iconsPatterns}`}>
          <Icon iconName="code" extraProps="text-white" />
        </div>
      );
    case 'DECLARE_VARIABLE':
      return (
        <div className={`bg-warning ${styles.iconsPatterns}`}>
          <Icon iconName="subscript" extraProps="text-white" />
        </div>
      );
    case 'BEGIN_CYCLE':
      return (
        <div className={`bg-secondary ${styles.iconsPatterns}`}>
          <Icon iconName="repeat" extraProps="text-white" />
        </div>
      );
    case 'END_CYCLE':
      return (
        <div className={`bg-secondary ${styles.iconsPatterns}`}>
          <Icon iconName="flag-checkered" extraProps="text-white" />
        </div>
      );
    case 'ADD_LIST':
      return (
        <div className={`bg-warning ${styles.iconsPatterns}`}>
          <Icon iconName="bars-staggered" extraProps="text-white rotate-180" />
        </div>
      );
    case 'REMOVE_LIST':
      return (
        <div className={`bg-warning ${styles.iconsPatterns}`}>
          <Icon iconName="bars-staggered" extraProps="text-white" />
        </div>
      );
    case 'CLEAR_LIST':
      return (
        <div className={`bg-warning ${styles.iconsPatterns}`}>
          <Icon iconName="bars" extraProps="text-white" />
        </div>
      );
    case 'SAVE_FILE':
      return (
        <div className={`bg-warning ${styles.iconsPatterns}`}>
          <Icon iconName="file-arrow-down" extraProps="text-white" />
        </div>
      );
    case 'DELETE_FILE':
      return (
        <div className={`bg-warning ${styles.iconsPatterns}`}>
          <Icon iconName="folder-minus" extraProps="text-white" />
        </div>
      );
    case 'READ_FILE':
      return (
        <div className={`bg-warning ${styles.iconsPatterns}`}>
          <Icon iconName="bookmark" extraProps="text-white" />
        </div>
      );
    case 'CUSTOM_CODE':
      return (
        <div className={`bg-info ${styles.iconsPatterns}`}>
          <Icon iconName="code" extraProps="text-white" />
        </div>
      );
    case 'CUSTOM_QUERY':
      return (
        <div className={`bg-info ${styles.iconsPatterns}`}>
          <Icon iconName="database" extraProps="text-white" />
        </div>
      );
    default:
      return null;
  }
}
