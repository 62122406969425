import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  FormatterManifest,
  FormatterProps,
  FormatterTypes,
  LINK_MANIFEST,
  LinkTargets
} from '../../types';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../../../components/Popover';
import HelpIcon from '../../../components/HelpIcon';

function LinkFormatter(props: FormatterProps) {
  const { t } = useTranslation();
  const [linkParams, setLinkParams] = useState<FormatterManifest>(LINK_MANIFEST);

  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.LINK) {
      props.onChange && props.onChange(props.index, 'formatterParams', LINK_MANIFEST);
    }
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: string) {
    const updatedLinkParams = { ...linkParams };
    updatedLinkParams[field] = e.target.value;
    setLinkParams(updatedLinkParams);
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedLinkParams);
  }

  return (
    <div className="mb-3 border-bottom ">
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <Col sm={5}>
            <Form.Group>
              <Form.Label column>
                {t('designer.right_side.FormatterControls.LinkFormatterUrlPrefix')}
              </Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.LinkFormatterUrlPrefixTitle') ||
                    'designer.right_side.FormatterControls.LinkFormatterUrlPrefixTitle',
                  description:
                    t('designer.right_side.FormatterControls.LinkFormatterUrlPrefixDescription') ||
                    'designer.right_side.FormatterControls.LinkFormatterUrlPrefixDescription',
                  note: [
                    t('designer.right_side.FormatterControls.LinkFormatterUrlPrefixNote01') ||
                      'designer.right_side.FormatterControls.LinkFormatterUrlPrefixNote01'
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="text"
                placeholder="mailto:"
                value={props.value && props.value.urlPrefix}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e, 'urlPrefix');
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={5}>
            <Form.Group>
              <Form.Label column>
                {t('designer.right_side.FormatterControls.LinkFormatterLabel')}
              </Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.LinkFormatterLabelTitle') ||
                    'designer.right_side.FormatterControls.LinkFormatterLabelTitle',
                  description:
                    t('designer.right_side.FormatterControls.LinkFormatterLabelDescription') ||
                    'designer.right_side.FormatterControls.LinkFormatterLabelDescription',
                  note: [
                    t('designer.right_side.FormatterControls.LinkFormatterLabelNote01') ||
                      'designer.right_side.FormatterControls.LinkFormatterLabelNote01',
                    t('designer.right_side.FormatterControls.LinkFormatterLabelNote02') ||
                      'designer.right_side.FormatterControls.LinkFormatterLabelNote02'
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="text"
                placeholder="ext. link"
                value={props.value && props.value.label}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e, 'label');
                }}
              />
            </Form.Group>
          </Col>
        </div>
        <Form.Group>
          <Form.Label column>
            {t('designer.right_side.FormatterControls.LinkFormatterTarget')}
          </Form.Label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.FormatterControls.LinkFormatterTargetTitle') ||
                'designer.right_side.FormatterControls.LinkFormatterTargetTitle',
              description:
                t('designer.right_side.FormatterControls.LinkFormatterTargetDescription') ||
                'designer.right_side.FormatterControls.LinkFormatterTargetDescription',
              note: [
                t('designer.right_side.FormatterControls.LinkFormatterTargetNote01') ||
                  'designer.right_side.FormatterControls.LinkFormatterTargetNote01',
                t('designer.right_side.FormatterControls.LinkFormatterTargetNote02') ||
                  'designer.right_side.FormatterControls.LinkFormatterTargetNote02',
                t('designer.right_side.FormatterControls.LinkFormatterTargetNote03') ||
                  'designer.right_side.FormatterControls.LinkFormatterTargetNote03',
                t('designer.right_side.FormatterControls.LinkFormatterTargetNote04') ||
                  'designer.right_side.FormatterControls.LinkFormatterTargetNote04'
              ]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
          <Form.Select
            placeholder="target"
            value={props.value && props.value.target}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              handleChange(e, 'target');
            }}
          >
            {Object.keys(LinkTargets).map((key) => (
              <option key={key} value={LinkTargets[key as keyof typeof LinkTargets]}>
                {key}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
    </div>
  );
}

export default LinkFormatter;
