import React, { ComponentType, memo, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRelationship } from '../store/actions/root';
import { setSelectedConnector, setSelectedFrame } from '../store/actions/studio';
import { ConnectorID, Table, TableUUID, columnID } from 'modules/modeler/types';
import ContextMenu from 'web_ui/workboard/ContextMenu';
import ContextMenuItem from 'web_ui/workboard/ContextMenuItem';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';

type ConnectorWrapperProps = {
  sourceRef: Table;
  targetRef: Table;
  connectorID: ConnectorID;
  toColumnID: columnID;
  toTableID: TableUUID;
};

export function ConnectorWrapper(Element: ComponentType<any>) {
  const Connector = (props: ConnectorWrapperProps) => {
    // Used to show or hide the delete confirmation modal
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const elementRef = useRef<HTMLElement>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      if (elementRef.current) {
        elementRef.current.style.cursor = 'pointer';
      }
      elementRef.current?.addEventListener('click', (e) => {
        e.stopPropagation();
        dispatch(setSelectedConnector(props.connectorID));
        dispatch(setSelectedFrame(null));
      });
    }, [dispatch, props.connectorID]);

    function handleDeleteConnector() {
      if (props.connectorID) {
        dispatch(deleteRelationship(props.connectorID));
      }
    }

    return (
      <React.Fragment>
        <Element ref={elementRef} {...props} />
        <ContextMenu elementRef={elementRef} offsetX={96} offsetY={79}>
          <ContextMenuItem icon="trash" label="Delete" onClick={() => setShowDeleteModal(true)} />
        </ContextMenu>
        <Confirmation
          show={showDeleteModal}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirmation={handleDeleteConnector}
          message={
            `${t('modeler.Delete Relationship')} (from: ` +
            (props.sourceRef ? props.sourceRef.content.data.name || '' : '') +
            ' to:' +
            (props.targetRef ? props.targetRef.content.data.name || '' : '') +
            ')'
          }
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      </React.Fragment>
    );
  };

  return memo(Connector);
}
