import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { BsFunnelFill } from 'react-icons/bs';
import { Form, FormGroup, ListGroup } from 'react-bootstrap';
import VariableControl from '../VariableControl';
import { FRONTEND_VARIABLE_TYPES } from 'modules/designer/types';
import { DataService } from 'modules/modeler/services';
import { EnumFrame } from 'modules/modeler/types';
import { useParams } from 'react-router-dom';
import { sleep } from 'utils/utils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { useTranslation } from 'react-i18next';
import { SchemaItem } from 'modules/logic_builder/types';
import HelpIcon from '../components/HelpIcon';
import HelpPopover from '../components/Popover';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';
import ErrorIcon from '../components/ErrorIcon';

export enum sourceOptionTypes {
  'FIXED' = 'FIXED',
  'ENUM' = 'ENUM',
  'VAR' = 'VAR'
}

function ListControl(props: ControlProps) {
  const [newItem, setNewItem] = useState('');
  const [optionSourceType, setOptionSourceType] = useState<sourceOptionTypes>(
    sourceOptionTypes.FIXED
  );
  const referenceBottom = useRef<HTMLDivElement>(null);
  const [filtering, setFiltering] = useState<boolean>(false);
  const { module_id } = useParams();
  const [enumsList, setEnumsList] = useState<EnumFrame[]>([]);
  const UIVariables = useSelector((state: InterfaceStudioState) => state.variables);
  const UIObjects = useSelector((state: InterfaceStudioState) => state.objects);

  const { t } = useTranslation();

  useEffect(() => {
    referenceBottom.current?.scrollIntoView({ behavior: 'smooth' });
  }, [props.value]);

  const fetchEnums = useCallback(async (moduleId: string) => {
    await DataService.getEnums(moduleId).then((enums) => {
      setEnumsList(enums);
    });
  }, []);

  useEffect(() => {
    if (!module_id) return;
    fetchEnums(module_id);
  }, [fetchEnums, module_id]);

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    const list = props.value.options instanceof Array ? [...props.value.options] : [];
    list.push(newItem);
    if (props.onChange) props.onChange(list, 'options');
    // every time that one thing is setted the state will reset
    setNewItem('');
  }

  async function handleChangeOptionSourceType(type: string) {
    if (props.onChange) props.onChange(type, 'optionSourceType');
    await sleep(200);
    resetOptionsParams();
  }

  async function resetOptionsParams() {
    if (!props.onChange) return;
    props.onChange([], 'options');
    await sleep(100);
    props.onChange({}, 'optionRef');
    await sleep(100);
    props.onChange('', 'optionRefKey');
    await sleep(100);
    props.onChange('', 'optionRefValue');
  }

  function handleDelete(index: number) {
    const list = props.value.options instanceof Array ? [...props.value.options] : [];
    list.splice(index, 1);

    if (props.onChange) props.onChange(list, 'options');
  }

  function handleUpdateOptionEnumUUID(enumUUID: string) {
    if (props.onChange) props.onChange({ uuid: enumUUID }, 'optionRef');
  }

  async function handleUpdateOptionVariableUUID(
    varUUID: { uuid?: string; isProp?: boolean },
    key: string
  ) {
    if (props.onChange && varUUID) {
      props.onChange(!varUUID.uuid ? {} : { uuid: varUUID.uuid }, 'optionRef');
      await sleep(100);
      props.onChange('', 'optionRefKey');
      await sleep(100);
      props.onChange('', 'optionRefValue');
    }
  }

  React.useEffect(() => {
    (() => {
      const listToSort =
        props.value && props.value.options && props.value.options instanceof Array
          ? [...props.value.options]
          : [];
      listToSort.sort();
      if (
        props.onChange &&
        props.value &&
        props.value.optionSourceType &&
        props.value.optionSourceType === sourceOptionTypes.FIXED
      ) {
        props.onChange(listToSort, 'options');
      }
    })();
  }, [filtering]);

  function handleUpdateOptionField(value: string, key: string) {
    if (props.onChange && value) {
      props.onChange(value, key === 'OPTKEY' ? 'optionRefKey' : 'optionRefValue');
    }
  }

  return (
    <div className="mb-3 pt-3 pb-3 border-top border-bottom">
      <div
        className={`${styles.defaultLabel} form-label`}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem'
        }}
      >
        <div style={{ display: 'flex' }}>
          {props.label}
          {props.tooltip && (
            <HelpPopover
              helpBoxProps={{
                description: t(`${props.tooltip}`)!
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </div>
        <BsFunnelFill
          size={20}
          color="black"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setFiltering(!filtering);
          }}
        />
      </div>

      <div>
        <div className="mb-3">
          <FormGroup>
            <Form.Label>
              {t(`designer.right_side.controls.OptionSource`)}
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.controls.OptionSourceTitle') ||
                    'designer.right_side.controls.OptionSourceTitle',
                  description:
                    t('designer.right_side.controls.OptionSourceDescription') ||
                    'designer.right_side.controls.OptionSourceDescription',
                  note: [
                    t('designer.right_side.controls.OptionSourceNote01'),
                    t('designer.right_side.controls.OptionSourceNote02'),
                    t('designer.right_side.controls.OptionSourceNote03')
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
            </Form.Label>
            <Form.Select
              value={props.value && props.value.optionSourceType}
              onChange={(e) =>
                handleChangeOptionSourceType(e.target.value as keyof typeof sourceOptionTypes)
              }
              // onChange={(e) => setOptionSourceType(parseInt(e.target.value) as sourceOptionTypes)}
            >
              <option value={sourceOptionTypes.FIXED}>Fixed</option>
              <option value={sourceOptionTypes.ENUM}>Enum</option>
              <option value={sourceOptionTypes.VAR}>Variable</option>
            </Form.Select>
          </FormGroup>
        </div>

        {props.value &&
          props.value.optionSourceType &&
          props.value.optionSourceType === sourceOptionTypes.FIXED && (
            <div>
              <ListGroup
                style={{ overflow: 'auto', maxHeight: 140, overflowX: 'hidden' }}
                className="p-0 border- rounded"
              >
                {props.value &&
                props.value.options &&
                props.value.options instanceof Array &&
                props.value.options.length > 0 ? (
                  props.value.options.map((item: string, index: number) => (
                    <ListGroup.Item
                      action
                      key={`${item}-${index}`}
                      className="d-flex justify-content-between"
                    >
                      {item}
                      <i className="fa fa-trash text-danger" onClick={() => handleDelete(index)} />
                    </ListGroup.Item>
                  ))
                ) : (
                  <li>A lista está vazia.</li>
                )}
                <div ref={referenceBottom} />
              </ListGroup>
              <div className="input-group input-group-sm mb-3 mt-2">
                <input
                  value={newItem}
                  type="text"
                  className="form-control"
                  onChange={(e) => setNewItem(e.target.value)}
                />
                <button className="btn btn-outline-secondary" type="button" onClick={handleClick}>
                  Add
                </button>
              </div>
            </div>
          )}

        {props.value &&
          props.value.optionSourceType &&
          props.value.optionSourceType === sourceOptionTypes.ENUM && (
            <div>
              <FormGroup>
                {props.value && props.value.optionRef && (
                  <MissingMessage
                    type={MissingMessageType.ENUM}
                    uuid={props.value.optionRef.uuid}
                  />
                )}
                <Form.Label>
                  {t(`designer.right_side.controls.EnumSource`)}
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('designer.right_side.controls.EnumSourceTitle') ||
                        'designer.right_side.controls.EnumSourceTitle',
                      description:
                        t('designer.right_side.controls.EnumSourceDescription') ||
                        'designer.right_side.controls.EnumSourceDescription'
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </Form.Label>
                <Form.Select
                  value={props.value && props.value.optionRef && props.value.optionRef.uuid}
                  onChange={(e) => handleUpdateOptionEnumUUID(e.target.value)}
                >
                  <option value={''}>---</option>
                  {enumsList.map((enumFrame: EnumFrame) => {
                    return (
                      <option key={enumFrame.uuid} value={enumFrame.uuid}>
                        {enumFrame.content.data.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </FormGroup>
            </div>
          )}

        {props.value &&
          props.value.optionSourceType &&
          props.value.optionSourceType === sourceOptionTypes.VAR && (
            <div>
              <VariableControl
                id={'optionRef'}
                value={props.value && props.value.optionRef && props.value.optionRef}
                label="Variable"
                onChange={handleUpdateOptionVariableUUID}
                options={['list', FRONTEND_VARIABLE_TYPES.STRING, FRONTEND_VARIABLE_TYPES.OBJECT]}
              />
              {props.value &&
                props.value.optionRef &&
                props.value.optionRef.uuid &&
                UIVariables[props.value.optionRef.uuid] && (
                  <div>
                    <FormGroup className="mb-3">
                      <Form.Label>
                        {(!props.value?.optionRefKey || props.value?.optionRefKey == '') && (
                          <ErrorIcon
                            title={t('designer.right_side.controls.errors.MissingObjectItem')}
                          />
                        )}

                        {t(`designer.right_side.controls.OptionKey`)}
                        <HelpPopover
                          helpBoxProps={{
                            title:
                              t('designer.right_side.controls.OptionKeyTitle') ||
                              'designer.right_side.controls.OptionKeyTitle',
                            description:
                              t('designer.right_side.controls.OptionKeyDescription') ||
                              'designer.right_side.controls.OptionKeyDescription'
                          }}
                          placement="top"
                        >
                          <HelpIcon />
                        </HelpPopover>
                      </Form.Label>
                      <Form.Select
                        value={props.value?.optionRefKey || ''}
                        onChange={(e) => handleUpdateOptionField(e.target.value, 'OPTKEY')}
                      >
                        <option value={''}>---</option>
                        {props.value?.optionRef?.uuid &&
                          UIVariables[props.value.optionRef.uuid]?.objectUuid &&
                          UIObjects[
                            UIVariables[props.value.optionRef.uuid].objectUuid!
                          ]?.objectItems?.map((objectItem: SchemaItem) => (
                            <option key={objectItem.uuid} value={objectItem.uuid}>
                              {objectItem.name}
                            </option>
                          ))}
                      </Form.Select>
                    </FormGroup>
                    <FormGroup>
                      <Form.Label>
                        {(!props.value?.optionRefValue || props.value?.optionRefValue == '') && (
                          <ErrorIcon
                            title={t('designer.right_side.controls.errors.MissingObjectItem')}
                          />
                        )}
                        {t(`designer.right_side.controls.OptionValue`)}
                        <HelpPopover
                          helpBoxProps={{
                            title:
                              t('designer.right_side.controls.OptionValueTitle') ||
                              'designer.right_side.controls.OptionValueTitle',
                            description:
                              t('designer.right_side.controls.OptionValueDescription') ||
                              'designer.right_side.controls.OptionValueDescription'
                          }}
                          placement="top"
                        >
                          <HelpIcon />
                        </HelpPopover>
                      </Form.Label>
                      <Form.Select
                        value={props.value?.optionRefValue || ''}
                        onChange={(e) => handleUpdateOptionField(e.target.value, 'OPTVALUE')}
                      >
                        <option value={''}>---</option>
                        {props.value?.optionRef?.uuid &&
                          UIVariables[props.value.optionRef.uuid]?.objectUuid &&
                          UIObjects[
                            UIVariables[props.value.optionRef.uuid].objectUuid!
                          ]?.objectItems?.map((objectItem: SchemaItem) => (
                            <option key={objectItem.uuid} value={objectItem.uuid}>
                              {objectItem.name}
                            </option>
                          ))}
                      </Form.Select>
                    </FormGroup>
                  </div>
                )}
            </div>
          )}
      </div>
    </div>
  );
}

export default ListControl;
