import React, { ComponentType, useMemo } from 'react';
import { WidgetsState } from 'modules/designer/studio/store';
import { WIDGETS_LIST } from '.';
import { XY } from './MoveElement';

export type WidgetsRendererProps = {
  widgets: WidgetsState;
};

function WidgetsRenderer(props: WidgetsRendererProps) {
  const widgetRender = useMemo(
    () =>
      Object.keys(props.widgets).map((id) => {
        const widget = props.widgets[id];
        const WidgetElement = WIDGETS_LIST[widget.type] as ComponentType<any>;
        const xy = new XY();
        return (
          WidgetElement && (
            <WidgetElement
              key={widget.uuid}
              uuid={widget.uuid}
              type={widget.type}
              point={xy}
              data={widget.data}
            />
          )
        );
      }),
    [props.widgets]
  );

  return <>{props.widgets && widgetRender}</>;
}

export default WidgetsRenderer;
