import React from 'react';
import Icon from 'web_ui/icon';

export type EmptyMessageProps = {
  icon?: string;
  message: string;
  actionMessage?: string;
  linkAction?: () => void;
};

function EmptyMessage(props: EmptyMessageProps) {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <div className="text-secondary fs-1">{props.icon && <Icon iconName={props.icon} />}</div>
      <div className="d-flex flex-column justify-content-center  ms-3">
        <h5 id="alertMessage" className="text-secondary m-0">
          {props.message}
        </h5>
        <p className="m-0">
          <a
            id={'callToActionLink'}
            onClick={props.linkAction}
            href="#"
            className="text-tertiary link-underline-secondary"
          >
            {props.actionMessage}
          </a>
        </p>
      </div>
    </div>
  );
}

export default EmptyMessage;
