import React, { useContext, useEffect, useState } from 'react';
import { CrudPage } from 'modules/logic_builder/types';
import { Button, Form, Modal } from 'react-bootstrap';
import SecuritySelector from '../endpoint_edition/params/signature/security_selector';
import PageBasicInfo from './basic_info';
import PageParams from './params';
import { PageParam, TemplateEndpoint } from '../../../../../../../../modules/designer/types';
import PageEndpoints from './endpoints';
import { RestDataInterface } from '../../../../../../../../modules/designer/function_editor/function_actions/types/action_data';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../../../../../modules/project/store/app_context';

type Props = {
  currentPage: CrudPage;
  onSave: (page: CrudPage) => void;
  dialogOnClose: () => void;
  showDialog: boolean;
  masterEndpoints: TemplateEndpoint[];
  detailEndpoints: TemplateEndpoint[];
};

export interface Validation {
  params: { [key: number]: boolean | undefined };
}

export default function PageEditor({
  currentPage,
  onSave,
  dialogOnClose,
  showDialog,
  masterEndpoints,
  detailEndpoints
}: Props) {
  const appInfo = useContext(AppContext).projectInformation;
  const [page, setPage] = useState(currentPage);
  const [validatedParams, setValidatedParams] = useState<Validation>({} as Validation);
  const [allowAllRoles, setAllowAllRoles] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();

  // validations
  const [namevld, setNamevld] = useState<boolean | undefined>();
  const [routevld, setRoutevld] = useState<boolean | undefined>();

  const handleGetValidationName = (value: boolean | undefined) => {
    setNamevld(value);
  };

  const handleGetValidationRoute = (value: boolean | undefined) => {
    setRoutevld(value);
  };

  useEffect(() => {
    if (!showDialog) return;
    setPage(currentPage);
  }, [showDialog]);

  const handleChangeName = (val: string) => {
    const newPage = {
      ...page,
      name: val
    };

    setPage(newPage);
  };

  const handleChangeRoute = (val: string) => {
    const newPage = {
      ...page,
      route: val
    };

    setPage(newPage);
  };

  const handleExtractFormChange = (val: boolean) => {
    const newPage = {
      ...page,
      extractForm: val
    };

    setPage(newPage);
  };

  const handleParamsChange = (params: PageParam[]) => {
    const newPage: CrudPage = {
      ...page,
      params: params,
      includeInMenu: params.length ? false : page.includeInMenu
    };

    setPage(newPage);
  };

  function handleSave(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (namevld === false) {
      setErrorMessage(t('automation.step5.vldName')!);
      return;
    }

    if (routevld === false) {
      setErrorMessage(t('automation.step5.vldRoute')!);
      return;
    }

    // find into the indexed signture the false one
    if (validatedParams.params) {
      const paramsValues = Object.values(validatedParams.params);
      if (paramsValues.includes(false)) {
        setErrorMessage(t('automation.step5.vldParameters')!);
        return;
      }
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setErrorMessage(t('automation.step5.vldEndpoints')!);
      return;
    }

    onSave(page);
    setErrorMessage('');
    dialogOnClose();
  }

  function handleChangeEndpoints(
    submitAction: RestDataInterface | undefined,
    fetchAction: RestDataInterface | undefined,
    deleteAction: RestDataInterface | undefined
  ) {
    const newPage = {
      ...page,
      submitAction: submitAction,
      fetchAction: fetchAction,
      deleteAction: deleteAction
    };

    setPage(newPage);
  }

  const handleIncludeInEveryMenuChange = (checked: boolean) => {
    const nextPage: CrudPage = {
      ...page,
      includeInMenu: checked
    };
    setPage(nextPage);
  };

  const handleToggleSelectedRole = (roleId: number, checked: boolean) => {
    let nextRoles: number[];
    if (checked) {
      nextRoles = [...page.roles, roleId];
    } else {
      nextRoles = page.roles.filter((r) => r !== roleId);
    }
    setPage({ ...page, roles: nextRoles });
  };

  const handleSetRoles = (roles: number[]) => {
    const nextPage = {
      ...page,
      roles: roles
    };
    setPage(nextPage);
  };

  const resetErrors = () => {
    setErrorMessage('');
  };

  return (
    <Modal
      centered
      show={showDialog}
      onHide={() => {
        dialogOnClose();
        resetErrors();
      }}
      size="xl"
    >
      <Modal.Header closeButton id="formModal">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Modal.Title>{t('automation.step4.EditPage')}</Modal.Title>
          <div>{errorMessage && <span style={{ color: '#d9534f' }}>{errorMessage}</span>}</div>
        </div>
      </Modal.Header>
      <Form noValidate onSubmit={handleSave}>
        <Modal.Body id="bodyModal">
          <PageBasicInfo
            page={page}
            onNameChange={handleChangeName}
            onRouteChange={handleChangeRoute}
            onExtractFormChange={handleExtractFormChange}
            handleIncludeInEveryMenuChange={handleIncludeInEveryMenuChange}
            handleValidationName={handleGetValidationName}
            handleGetValidationRoute={handleGetValidationRoute}
          />
          <PageParams
            page={page}
            onParamsChange={handleParamsChange}
            validation={setValidatedParams}
          />
          {appInfo?.has_authentication && (
            <SecuritySelector
              setAllowRoles={setAllowAllRoles}
              allowAllRoles={allowAllRoles}
              handleToggleSelectedRole={handleToggleSelectedRole}
              selectedRoles={page.roles}
              setRoles={handleSetRoles}
            />
          )}
          <PageEndpoints
            page={page}
            masterEndpoints={masterEndpoints}
            detailEndpoints={detailEndpoints}
            onEndpointChange={handleChangeEndpoints}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="cancelButton"
            onClick={() => {
              dialogOnClose();
              resetErrors();
            }}
            variant="secondary"
          >
            {t('cancel')}
          </Button>
          <Button id="saveButton" type="submit">
            {t('save')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
