import Confirmation from '../../../../../../../../../web_ui/confirmation';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteRelationship } from '../../../../../../store/actions/root';
import { useTranslation } from 'react-i18next';
import { Relationship } from '../../../../../../../types';

type DeleteButtonProps = {
  relationship: Relationship;
};

export default function DeleteButton({ relationship }: DeleteButtonProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleDeleteRelation() {
    dispatch(deleteRelationship(relationship.id));
    setShowDeleteModal(false);
  }

  if (!relationship) return null;

  return (
    <>
      <Button
        id={'deleteButton'}
        onClick={() => setShowDeleteModal(true)}
        variant="outline-danger"
        className={'mt-2'}
      >
        {t('modeler.Remove')}
      </Button>
      <Confirmation
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirmation={handleDeleteRelation}
        message={`${t('modeler.Delete Relationship')}
        (
          ${relationship.name}
        )`}
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
}
