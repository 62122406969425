import React from 'react';

function CustomCodeIcon() {
  return (
    <span className="fa-layers fa-fw text-secondary-emphasis" style={{ whiteSpace: 'nowrap' }}>
      <i className="fas fa-code" data-fa-transform="grow-2"></i>
    </span>
  );
}

export default CustomCodeIcon;
