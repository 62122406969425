import { AddOrganization, AddRole, OrganizationApiInfo, RoleType } from '../types';
import { ApiAppInfo, AppFullInfo } from 'modules/project/types';
import { UserProfile } from 'modules/auth/types/auth_types';

export const API_URL = process.env.REACT_APP_API_URL;

export default class Organization {
  async createOrg(data: AddOrganization): Promise<OrganizationApiInfo> {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const options: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };
    const request = new Request(`${API_URL}/orgs`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.status === 201) {
        return response.json() as Promise<OrganizationApiInfo>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async getOrganizationById(orgId: number): Promise<OrganizationApiInfo> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };
    const request = new Request(`${API_URL}/orgs/${orgId}`, options);
    return this.fetchOrganization(request);
  }

  async updateOrganizationById(orgId: number, data: AddOrganization): Promise<OrganizationApiInfo> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };
    const request = new Request(`${API_URL}/orgs/${orgId}`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<OrganizationApiInfo>;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async deleteOrganizationById(orgId: number) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };
    const request = new Request(`${API_URL}/orgs/${orgId}`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return true;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async getOrganizations() {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/orgs`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<OrganizationApiInfo[]>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async getProjectsOfOrg(orgId: number) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/orgs/${orgId}/apps`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<ApiAppInfo[]>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async createProject(orgId: number, data: AppFullInfo = {} as AppFullInfo) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const options: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const request = new Request(`${API_URL}/orgs/${orgId}/apps`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<ApiAppInfo>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async getMembersOfOrg(orgId: number) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/orgs/${orgId}/members`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<UserProfile[]>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async deleteMemberOfOrg(orgId: number, memberId: number) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/orgs/${orgId}/members/${memberId}`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return true;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async updateMemberOfOrg(orgId: number, memberId: number, newRole: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const body = {
      roleName: newRole
    };

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    const request = new Request(`${API_URL}/orgs/${orgId}/members/${memberId}`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<UserProfile>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async getCustomRolesOfOrg(orgId: number) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/orgs/${orgId}/roles`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<RoleType[]>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async createCustomRoleOrg(orgId: number, newRole: AddRole) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(newRole)
    };

    const request = new Request(`${API_URL}/orgs/${orgId}/roles`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return true;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async updateCustomRole(orgId: number, role: RoleType) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(role)
    };

    const request = new Request(`${API_URL}/orgs/${orgId}/roles/${role.name}`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return true;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  private async fetchOrganization(request: Request): Promise<OrganizationApiInfo> {
    const response = await fetch(request);
    if (response.ok) {
      return (await response.json()) as OrganizationApiInfo;
    }
    const message = await response.text();
    throw new Error(message);
  }
}
