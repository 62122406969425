import { Action } from 'redux';
import { FunctionEditorState } from '../../types/function_editor_state';

export const DELETE_PARAM = 'DELETE_PARAM';

export interface DeleteParamAction extends Action {
  type: 'DELETE_PARAM';
  payload: { functionUUID: string; parameterId: string };
}

export const deleteParam = (functionUUID: string, paramUUID: string): DeleteParamAction => ({
  type: DELETE_PARAM,
  payload: { functionUUID, parameterId: paramUUID }
});

export function doDeleteParam(
  state: FunctionEditorState,
  action: DeleteParamAction
): FunctionEditorState {
  const { functionUUID, parameterId } = action.payload;
  const index = state.functions[functionUUID].parameters.indexOf(parameterId);
  state.functions[functionUUID].parameters.splice(index, 1);
  delete state.parameters[parameterId];
  return state;
}
