import * as React from 'react';
import ArgumentPicker from '../ArgumentPicker';
import { Form } from 'react-bootstrap';
import {
  Action,
  FunctionParameter,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import { RemoveListActionType, ARGUMENT_TYPES } from '../../types';

type Props = {
  newAction: Action<RemoveListActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<RemoveListActionType>>>;
  objects: ObjectSimple[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
};

export default function RenderRemoveList(props: Props) {
  const varTypeBasedOnTypeOfList = props.variables.filter(
    (varItem) =>
      props.newAction.data.arguments.list.value &&
      varItem.uuid === props.newAction.data.arguments.list.value
  )[0];

  const paramTypeBasedOnTypeOfList = props.parameters.filter(
    (parItem) =>
      props.newAction.data.arguments.list.value &&
      parItem.uuid === props.newAction.data.arguments.list.value
  )[0];
  return (
    <div>
      <Form.Group>
        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Remove into list"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.list.value = value;
              auxVal.data.arguments.list.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.list.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={true}
          currentType={props.newAction.data.arguments.list.type}
        />
      </Form.Group>
      {varTypeBasedOnTypeOfList && (
        <ArgumentPicker
          allowDataTypes={[varTypeBasedOnTypeOfList.type]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Item to Remove"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.object.value = value;
              auxVal.data.arguments.object.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.object.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={props.newAction.data.arguments.object.type}
        />
      )}
      {paramTypeBasedOnTypeOfList && (
        <ArgumentPicker
          allowDataTypes={[paramTypeBasedOnTypeOfList.type]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Item to Remove"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.object.value = value;
              auxVal.data.arguments.object.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.object.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={props.newAction.data.arguments.object.type}
        />
      )}
      <ArgumentPicker
        allowDataTypes={[]}
        allowFixed={false}
        allowParams={false}
        allowVars={true}
        allowObject={false}
        label="Select Variable to return"
        onChange={(value, typeChosen) => {
          props.onChange((currentVal) => {
            const auxVal = { ...currentVal };
            auxVal.returnVariableUuid = value;
            return auxVal; // override the action
          });
        }}
        value={props.newAction.returnVariableUuid}
        variables={props.variables}
        params={props.parameters}
        objects={props.objects}
        onlyList={false}
        currentType={ARGUMENT_TYPES.VAR}
      />
    </div>
  );
}
