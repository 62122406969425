import React from 'react';
import styles from './styles.module.css';
// import UIDesigner from './UIDesigner';
import Modeler from './Modeler';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle';

function Studios() {
  const { t } = useTranslation();

  useTitle(t('tab.settingsAcc.estudio'));

  return (
    <>
      <Col className={styles.Section}>
        <Col className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('studioSettings.modeler')}
          </span>
        </Col>
        <Col className={styles.FormContent}>
          <Modeler></Modeler>
        </Col>
      </Col>
    </>
  );
}

export default Studios;
