export const CONTROL_REQUIREMENT_TYPES = {
  FUNCTION: 'FUNCTION',
  VARIABLE: 'VARIABLE',
  LIST_VARIABLE: 'LIST_VARIABLE',
  OBJECT: 'OBJECT'
};

export type ControlRequirementsTypes = keyof typeof CONTROL_REQUIREMENT_TYPES;

export interface ControlRequirement {
  message?: string;
  actionButtonMessage?: string;
  onClick?: () => void;
}

export const FunctionControlRequirement: ControlRequirement = {
  message: 'designer.right_side.controls.requirements.FunctionControlRequirement',
  actionButtonMessage: 'designer.right_side.controls.requirements.FunctionControlRequirementButton'
};

export const VariableControlRequirement: ControlRequirement = {
  message: 'designer.right_side.controls.requirements.VariableControlRequirement',
  actionButtonMessage: 'designer.right_side.controls.requirements.VariableControlRequirementButton'
};

export const ListVariableControlRequirement: ControlRequirement = {
  message: 'designer.right_side.controls.requirements.ListVariableControlRequirement',
  actionButtonMessage:
    'designer.right_side.controls.requirements.ListVariableControlRequirementButton'
};

export const CONTROL_REQUIREMENT_TYPES_MAPPER: { [key: string]: ControlRequirement } = {
  [CONTROL_REQUIREMENT_TYPES.FUNCTION]: FunctionControlRequirement,
  [CONTROL_REQUIREMENT_TYPES.VARIABLE]: VariableControlRequirement,
  [CONTROL_REQUIREMENT_TYPES.LIST_VARIABLE]: ListVariableControlRequirement
};
