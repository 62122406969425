import React from 'react';
import styles from './styles.module.css';
import { v4 as uuidv4 } from 'uuid';
import { ReturnType } from 'web_ui/function_editor/store/types/functions';
import { Method, ParameterEndpointCrud } from 'modules/logic_builder/types';
import ParametersSpacerRoot from 'web_ui/function_editor/components/editor/function_header/parameter_editor/parameter_spacer';
import ParameterWrapper from 'web_ui/function_editor/components/editor/function_header/parameter_editor/parameter_wrapper';
import AddParameterButton from 'web_ui/function_editor/components/editor/function_header/parameter_editor/add_parameter_button';
import ParameterEditor from './parameter_editor';
import { useTranslation } from 'react-i18next';

type ParametersListEditorProps = {
  types: ReturnType[];
  params: ParameterEndpointCrud[];
  endpointMethod: Method;
  handleChangeParameters: (parameters: ParameterEndpointCrud[]) => void;
};

function ParameterListEditorCrud({
  types,
  params,
  endpointMethod,
  handleChangeParameters
}: ParametersListEditorProps) {
  const { t } = useTranslation();
  const handleDrop = (e: React.DragEvent, index: number) => {
    const draggedIndex = e.dataTransfer.getData('exocode/dragged-parameter-index');
    const myParams = params;

    if (draggedIndex === '') return;
    const origindUuid = params[Number.parseInt(draggedIndex)];
    const targetUuid = index === params.length ? params[index - 1] : params[index];

    // indexes
    const indexOrigin = params.indexOf(origindUuid);
    const indexTarget = params.indexOf(targetUuid);

    myParams.splice(indexOrigin, 1); // remove origin
    myParams.splice(indexTarget, 0, origindUuid); // inserting

    handleChangeParameters(myParams);
  };

  const handleAddParameter = () => {
    const myParams = params;
    const newParam: ParameterEndpointCrud = {
      uuid: uuidv4(),
      name: `param${params.length}`,
      type: 'STRING',
      description: '',
      list: false,
      required: false,
      order: 0,
      inputType: 'PATH',
      inputName: ''
    };
    myParams.push(newParam);
    handleChangeParameters(myParams);
  };

  return (
    <div className={'d-flex'}>
      <p
        style={{
          padding: 0,
          margin: 0,
          textDecoration: 'underline',
          marginRight: '1rem',
          marginTop: '0.8rem'
        }}
      >
        {t('automation.step4.param')}:
      </p>
      <div className={`${styles.Parameters} pb-2`}>
        {params.map((p, index) => (
          <React.Fragment key={index}>
            <ParametersSpacerRoot index={index} handleDrop={handleDrop} />
            <ParameterWrapper index={index} handleDrop={handleDrop}>
              <ParameterEditor
                uuid={p.uuid!}
                types={types}
                currParam={p}
                parameters={params}
                endpointMethod={endpointMethod}
                handleChangeParameters={handleChangeParameters}
              />
            </ParameterWrapper>
          </React.Fragment>
        ))}
        {params.length !== 0 && (
          <ParametersSpacerRoot index={params.length} handleDrop={handleDrop} />
        )}
        <AddParameterButton handleClick={handleAddParameter} />
      </div>
    </div>
  );
}

export default ParameterListEditorCrud;
