import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CssStylesService } from 'modules/designer/services';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { CssClass } from 'web_ui/workboard/sidebar/controls/CssClassesControl/types';
import { CssClassDialog } from './css_class_dialog';
import Confirmation from 'web_ui/confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { deleteCssClass } from '../../store/actions/cssClasses';

export function CssClassToolbar() {
  const { app_id } = useParams();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [showCreateCssClassDialog, setShowCreateCssClassDialog] = useState(false);
  const [showEditCssClassDialog, setShowEditCssClassDialog] = useState<CssClass>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<string>();
  const globalCssClasses = useSelector((state: InterfaceStudioState) => state.globalCssClasses);
  const dispatch = useDispatch();

  function handleShowEditDialog(css: CssClass) {
    setShowEditCssClassDialog(css);
  }

  function handleCloseEditDialog() {
    setShowEditCssClassDialog(undefined);
  }

  function handleShowCreateDialog() {
    setShowCreateCssClassDialog(true);
  }

  function handleCloseCreateDialog() {
    setShowCreateCssClassDialog(false);
  }

  const handleContextMenu = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const handleDelete = async () => {
    if (!app_id) return;
    if (showDeleteConfirmation == null) return;

    await CssStylesService.deleteCssClass(app_id, showDeleteConfirmation).then(() => {
      dispatch(deleteCssClass(showDeleteConfirmation));
      setShowDeleteConfirmation(undefined);
    });
  };

  return (
    <>
      <div className="input-group input-group-sm p-2 pt-0">
        <Form.Group className="position-relative w-100">
          <Form.Control
            value={searchText}
            id="css-classes-search-field"
            className="ps-4"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search..."
          />
          {searchText.length === 0 ? (
            <i
              className={`${styles.searchIcon} position-absolute fa-solid fa-magnifying-glass top-12 right-12`}
            ></i>
          ) : (
            <i
              role="button"
              onClick={() => setSearchText('')}
              className={`${styles.searchIcon} position-absolute fa-solid fa-xmark top-12 right-12 `}
            ></i>
          )}
        </Form.Group>
      </div>
      <div className={`mt-2 p-2 pt-0 ${styles.viewHeader}`}>
        <div className={`${styles.viewHeaderCollapse}`} aria-controls="collapse-custom-comps">
          <div className={styles.viewHeaderName}>{t('designer.cssClass.CssClasses')}</div>
        </div>
        <Button id="createButton" className="btn-sm" onClick={handleShowCreateDialog}>
          {t('designer.themes.Create')}
        </Button>
      </div>
      <div className="list-group p-2 pe-0" onContextMenu={handleContextMenu}>
        <div className="row ps-2 pe-3">
          <div className="p-0 border-top pt-3 mt-2">
            {globalCssClasses &&
              globalCssClasses
                .filter((cssClass) => {
                  return (
                    !searchText ||
                    cssClass.cssName
                      .toLocaleLowerCase()
                      .includes(searchText.trim().toLocaleLowerCase())
                  );
                })
                .map((cssClass) => (
                  <div key={cssClass.id} style={{ padding: '0.1rem', paddingBottom: '0.5rem' }}>
                    <div className={`${styles.cssClassInstance}`}>
                      <div className={`${styles.cssClassInstanceSelector}`}>
                        {cssClass.cssName}
                        {cssClass.cssSelector}
                      </div>
                      <div className={`${styles.cssClassInstanceActions}`}>
                        <div onClick={() => handleShowEditDialog(cssClass)}>
                          <i className="fa-solid fa-pen-to-square"></i>
                        </div>
                        <div onClick={() => setShowDeleteConfirmation(cssClass.id)}>
                          <i className="fa-solid fa-trash"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <CssClassDialog
        editMode={false}
        show={showCreateCssClassDialog}
        onClose={handleCloseCreateDialog}
      />
      <CssClassDialog
        editMode={true}
        show={showEditCssClassDialog != null}
        classInfo={showEditCssClassDialog}
        onClose={handleCloseEditDialog}
      />
      <Confirmation
        show={showDeleteConfirmation != null}
        message={t('designer.cssClass.DeleteConfirmation')}
        onConfirmation={handleDelete}
        onCancel={() => setShowDeleteConfirmation(undefined)}
        onClose={() => setShowDeleteConfirmation(undefined)}
        confirmationLabel={t('Confirm') ?? 'Confirm'}
        cancelLabel={t('Cancel') ?? 'Cancel'}
      />
    </>
  );
}
