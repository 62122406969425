import React from 'react';

function DeclareVariableIcon() {
  return (
    <span className="text-success" style={{ whiteSpace: 'nowrap' }}>
      <span>( </span>
      <span style={{ fontStyle: 'italic', fontFamily: 'serif', fontWeight: 'bolder' }}>x</span>
      <span> )</span>
    </span>
  );
}

export default DeclareVariableIcon;
