import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styles from './styles.module.css';
import { AppLanguagesService, AppTranslateService } from '../../../designer/services';
import { AppTranslation } from '../../../designer/types';

import { useTranslation } from 'react-i18next';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';

export type EditTranslationModalProps = {
  showModal: boolean;
  onClose: () => void;
  translation: AppTranslation;
  languageId: string;
  mainLanguageId: string;
};

/**
 * Create Module Modal.
 *
 * @component
 */
export default function EditTranslationModal({
  showModal,
  onClose,
  translation,
  languageId,
  mainLanguageId
}: EditTranslationModalProps) {
  const { t } = useTranslation();
  const { app_id } = useParams();
  const [value, setValue] = useState(translation?.value ?? '');
  const [key, setKey] = useState(translation?.key ?? '');
  const [keyTouched, setkeyTouched] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  useEffect(() => {
    if (!translation) return;
    setKey(translation.key);
    setValue(translation.value);
  }, [translation]);

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) return;
    try {
      if (languageId === mainLanguageId && translation?.id) {
        await handleEditMainLanguageTranslation();
      } else if (languageId === mainLanguageId) {
        await handleAddMainLanguageTranslation();
      } else if (languageId !== mainLanguageId && translation?.id && !value) {
        await handleDeleteSecondaryLanguageTranslation();
      } else if (languageId !== mainLanguageId && translation?.id) {
        await handleEditSecondaryLanguageTranslation();
      } else {
        await handleAddSecondaryLanguageTranslation();
      }
      handleClose();
    } catch (e) {
      if (e instanceof Error) {
        if (e.message == 'This translation key already exists') {
          showErrorPopup('appResume.new_module.translationKey');
        } else {
          showErrorPopup(e.message);
        }
      }
    }
  }

  async function handleEditMainLanguageTranslation() {
    if (!app_id) return;
    if (!value) throw new Error(t('appResume.languages.emptyValue') ?? '');
    await AppTranslateService.updateTranslation(translation.id, value);
  }

  async function handleAddMainLanguageTranslation() {
    if (!app_id) return;
    if (!key) throw new Error(t('appResume.languages.emptyKey') ?? '');
    if (!value) throw new Error(t('appResume.languages.emptyValue') ?? '');
    await AppLanguagesService.createTranslation(app_id, languageId, { key, value, id: '' });
  }

  async function handleDeleteSecondaryLanguageTranslation() {
    if (!app_id) return;
    await AppTranslateService.deleteTranslation(languageId, { id: translation.id, key, value });
  }

  async function handleEditSecondaryLanguageTranslation() {
    if (!app_id) return;
    await AppTranslateService.updateTranslation(translation.id, value);
  }

  async function handleAddSecondaryLanguageTranslation() {
    if (!app_id) return;
    await AppLanguagesService.createTranslation(app_id, languageId, { key, value, id: '' });
  }

  function handleClose() {
    clearData();
    onClose();
  }

  function clearData() {
    setKey('');
    setValue('');
    setkeyTouched(false);
  }

  return (
    <div>
      {alertMessage && alertVariant === 'danger' && (
        <PopupAlert
          i18nKey={alertMessage}
          onClose={hideAlertPopup}
          variant={alertVariant}
          extraStyle={{ left: 20 }}
        />
      )}
      <Modal centered show={showModal} onHide={handleClose}>
        <Form onSubmit={handleOnSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t('appResume.languages.EditTranslation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.appSettingsDialog}>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: '500' }}>
                  {t('appResume.languages.Key')}
                </Form.Label>
                <Form.Control
                  id="formName"
                  type="text"
                  maxLength={255}
                  onChange={(e) => {
                    if (e.target.value.length >= 255)
                      return alert(t('appResume.languages.maxKeyLength') ?? '');
                    setKey(e.target.value);
                  }}
                  onBlur={() => setkeyTouched(true)}
                  value={key}
                  disabled={mainLanguageId !== languageId || !!translation?.id}
                  isInvalid={keyTouched && !key}
                />
                <Form.Control.Feedback type={'invalid'}>
                  {keyTouched && !key ? `${t('appResume.languages.emptyKey')}` : null}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: '500' }}>
                  {t('appResume.languages.Value')}
                </Form.Label>
                <Form.Control
                  id="formValue"
                  type="text"
                  maxLength={255}
                  onChange={(e) => {
                    if (e.target.value.length >= 255)
                      return alert(t('appResume.languages.maxValueLength') ?? '');
                    setValue(e.target.value);
                  }}
                  onBlur={() => setkeyTouched(true)}
                  value={value}
                  isInvalid={keyTouched && mainLanguageId === languageId && !value}
                />
                <Form.Control.Feedback type={'invalid'}>
                  {keyTouched && value ? `${t('appResume.languages.emptyValue')}` : null}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={styles.dialogSaveButton}>
              <Button id="saveButton" type="submit">
                {t('Save')}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
