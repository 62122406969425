import React, { useState } from 'react';
import styles from './styles.module.css';

import { ElementsManifest } from '../../../../../routes/studio/data/elements';
import ComponentCard from './ComponentCard';
import WidgetCard from './WidgetCard';
import { useTranslation } from 'react-i18next';
import { Form, ListGroup } from 'react-bootstrap';

function DataElementsToolbar() {
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();
  // const search: string = t('Search');

  return (
    <React.Fragment>
      <div className="input-group input-group-sm p-3 pt-0">
        {/* <input
          type="text"
          className={`${styles.searchInput} form-control`}
          placeholder={search}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <span className={`${styles.searchButton} input-group-text`}>
          <i className="fa-solid fa-magnifying-glass"></i>
        </span> */}
        <Form.Group className="position-relative w-100">
          <Form.Control
            value={searchText}
            id="searchField"
            className="ps-4"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search..."
          />
          {searchText.length === 0 ? (
            <i
              className={`${styles.searchIcon} position-absolute fa-solid fa-magnifying-glass top-12 right-12`}
            ></i>
          ) : (
            <i
              role="button"
              onClick={() => setSearchText('')}
              className={`${styles.searchIcon} position-absolute fa-solid fa-xmark top-12 right-12 `}
            ></i>
          )}
        </Form.Group>
      </div>
      {/* // Frames */}
      <div className={`border-bottom p-3 pt-0`}>
        <ListGroup>
          {Object.keys(ElementsManifest)
            .filter((index) => {
              return (
                !searchText ||
                ElementsManifest[index].name
                  .toLocaleLowerCase()
                  .includes(searchText.toLocaleLowerCase())
              );
            })
            .map((index) => (
              <ComponentCard
                key={index}
                type={ElementsManifest[index].type}
                name={t('modeler.components.' + ElementsManifest[index].name)}
                description={t('modeler.components.' + ElementsManifest[index].descriptionId)}
                icon={ElementsManifest[index].icon}
              />
            ))}
        </ListGroup>
      </div>
      {/* // Widgets */}
      <div className={`p-3`}>
        <ListGroup>
          <WidgetCard
            type="STICKY_NOTE"
            name={t('modeler.components.Note')} //"Note"
            description={t('modeler.components.Note_description')} //"Used to fix comments around the workboard"
            icon="sticky-note"
          ></WidgetCard>
        </ListGroup>
      </div>
    </React.Fragment>
  );
}

export default DataElementsToolbar;
