import { Action } from 'redux';
import { VariablesState } from '../../types/variables';

export const ADD_VARIABLE = 'ADD_VARIABLE';

export interface AddVariableAction extends Action {
  type: 'ADD_VARIABLE';
  payload: {
    uuid: string;
    name: string;
    desc: string;
    type: string;
    objectUuid?: string;
    enumUuid?: string;
    list: boolean;
    required: boolean;
  };
}

export const addVariable = (
  uuid: string,
  name: string,
  desc: string,
  type: string,
  list: boolean,
  required: boolean,
  objectUuid?: string,
  enumUuid?: string
): AddVariableAction => ({
  type: ADD_VARIABLE,
  payload: {
    uuid,
    name,
    desc,
    type,
    objectUuid,
    enumUuid,
    list,
    required
  }
});

export function doAddVariable(state: VariablesState, action: AddVariableAction): VariablesState {
  const { uuid, name, desc, type, objectUuid, enumUuid, list, required } = action.payload;
  state[uuid] = {
    uuid,
    name,
    desc,
    type,
    objectUuid,
    enumUuid,
    list,
    required,
    native: false
  };

  return state;
}
