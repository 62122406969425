import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { ObjectsState } from '../types/objects';
import { initialState } from '../initial_empty_state';

type ObjectsActions = PayloadAction;

export const objectsReducer = (
  state: ObjectsState = initialState.objects,
  action: ObjectsActions
): ObjectsState => {
  return produce(state, (draft: ObjectsState) => {
    switch (action.type) {
      default:
        return state;
    }
  });
};
