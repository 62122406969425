import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';

export const PAGINATION_MANIFEST: ComponentManifest = {
  type: 'PAGINATION',
  name: 'Pagination',
  descriptionId: 'PaginationId',
  description: 'It is a component to insert accordions.',
  icon: 'fa-solid fa-pager',
  group: ComponentGroups['LAYOUT'],
  allowDrop: false,
  previewHeight: 24,
  previewWidth: 150,
  properties: [
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'CurrentPage',
      key: 'currentPage'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'PageSize',
      key: 'pageSize'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'TotalPages',
      key: 'totalPages'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CLICK']
};
