import React from 'react';

function ReturnTypeIcon() {
  return (
    <span className="fa-layers fa-fw" style={{ whiteSpace: 'nowrap' }}>
      <i className="fas fa-share-from-square fa-flip-vertical" data-fa-transform="grow-2"></i>
    </span>
  );
}

export default ReturnTypeIcon;
