import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { ControlProps } from '../..';
import GridColumn, { handleCreateContainer } from './gridColumn';
import { useTranslation } from 'react-i18next';
import GridColumnShortcuts from './shortcuts';
import produce from 'immer';
import { GridColumnData } from 'modules/designer/studio/exocode_components/grid';
import HelpIcon from '../../components/HelpIcon';
import HelpPopover from '../../components/Popover';
import {
  addAndMoveComponentsInto,
  ComponentPayload
} from 'modules/designer/studio/store/actions/root';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Message, MessageTypes, MoveComponentPayload } from 'modules/designer/types';
import { MoveSource, MoveTarget } from 'modules/designer/studio/store/actions/links';
import { ConfirmationInfo } from 'modules/designer/studio/components/viewport';

export type GridManagerModalProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setting: ControlProps;
};

export type GridColumnSizes = number[];

function GridManagerModal({ show, setShow, setting }: GridManagerModalProps) {
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const { t } = useTranslation();
  const { view_id } = useParams();
  const dispatch = useDispatch();

  const renderBackgroudColumns = () => (
    <div
      style={{
        position: 'absolute',
        height: '80%',
        width: '100%',
        zIndex: '1'
      }}
    >
      <div className="row h-100 w-100 gx-2 m-0">
        {[...Array(12)].map((_, i) => (
          <div key={i} className="col text-center">
            <span className="text-body-tertiary" style={{ fontSize: '12px' }}>
              {i + 1}
            </span>
            <div
              className="h-100 w-100 rounded"
              style={{ backgroundColor: 'rgba(140,140,140,0.1)' }}
            />
          </div>
        ))}
      </div>
    </div>
  );

  const handleUpdateSizes = (newSize: GridColumnSizes) => {
    const newChildrenState = produce(setting.value.columns, (draft: GridColumnData[]) => {
      draft.forEach((column, index) => {
        column.width = newSize[index];
      });
    });

    if (!newChildrenState) return;

    setting.onChange && setting.onChange(newChildrenState, 'columns');
  };

  const handleResetColumns = useCallback(() => {
    if (!selectedComponent || !view_id || !setting) return;

    const firstColumn = components[selectedComponent]?.data?.columns[0]?.title;
    if (!firstColumn) return;

    // const firstSectionId = selectedComponent + '_' + firstColumn;
    const { component: containerComponent, link: containerLink }: ComponentPayload =
      handleCreateContainer(view_id, selectedComponent, firstColumn);
    containerComponent.data.autoGenerated = true;
    containerComponent.data.flexDirection = 'row';

    const columnsToMove: MoveComponentPayload[] = [];

    for (const section of components[selectedComponent].data.columns) {
      const parentSectionId: string = selectedComponent + '_' + section.title;

      if (!links[parentSectionId]) continue;
      const columnId: string = links[parentSectionId][0];

      const sourceInfo: MoveSource = {
        parentUUID: selectedComponent,
        uuid: columnId,
        type: components[columnId]?.type,
        section: section.title
      };

      const targetInfo: MoveTarget = {
        parentUUID: containerComponent.uuid
      };

      const movePayload: MoveComponentPayload = {
        source: sourceInfo,
        target: targetInfo
      };

      columnsToMove.push(movePayload);
    }
    dispatch(
      addAndMoveComponentsInto(
        { component: containerComponent, link: containerLink },
        columnsToMove
      )
    );

    handleResetGridColumns();
  }, [components, selectedComponent, view_id, links]);

  function handleResetGridColumns() {
    const newChildrenColumnsState = produce(setting.value.columns, (draft: GridColumnData[]) => {
      return { ...draft[0], width: 12 };
    });

    if (!newChildrenColumnsState) return;

    setting.onChange && setting.onChange([newChildrenColumnsState], 'columns');
  }

  const waitForResult = useCallback(
    (event: MessageEvent<Message>) => {
      if (event.origin !== window.location.origin) return;
      if (event.data.type === MessageTypes.CONFIRMATION_RESULT) {
        window.removeEventListener('message', waitForResult);
        if (event.data.content.result === true) {
          console.log('confirmou');
          handleResetColumns();
        }
        return;
      }
    },
    [handleResetColumns]
  );

  const requestConfirmation = React.useCallback(() => {
    const content: ConfirmationInfo = {
      message: `${t('designer.right_side.controls.components.grid.ResetConfirm')}`,
      confirmationLabel: t('Confirm'),
      cancelLabel: t('Cancel')
    };

    const navigateMessage: Message = {
      type: MessageTypes.CONFIRMATION,
      content: content
    };

    window.parent.postMessage(navigateMessage);

    window.addEventListener('message', waitForResult);
  }, [waitForResult, t]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setShow(false);
      }}
      animation={false}
      centered={true}
      backdropClassName={''}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('designer.right_side.controls.components.grid.ModalColumnManagerTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="pt-2 pb-2 d-flex justify-content-between">
          {t('designer.right_side.controls.components.grid.Columns')}
          <button
            disabled={setting?.value?.columns?.length <= 1}
            className={`h4 me-1 mb-0 btn-link btn `}
            onClick={() => requestConfirmation()}
          >
            {t('designer.right_side.controls.components.grid.Reset')}
            <HelpPopover
              helpBoxProps={{
                title:
                  t('designer.right_side.controls.components.grid.ColumnsReset') ||
                  'designer.right_side.controls.components.grid.ColumnsReset',
                description:
                  t('designer.right_side.controls.components.grid.ColumnsResetDescription') ||
                  'designer.right_side.controls.components.grid.ColumnsResetDescription',
                note: [
                  t('designer.right_side.controls.components.grid.ColumnsResetNote') ||
                    'designer.right_side.controls.components.grid.ColumnsResetNote'
                ]
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          </button>
        </h5>
        <div className="pt-2 " style={{ position: 'relative', height: '180px' }}>
          {renderBackgroudColumns()}
          <GridColumn settings={setting} />
        </div>

        {setting?.value?.columns && (
          <GridColumnShortcuts
            columnsQuantity={setting?.value?.columns.length}
            onChangeSize={handleUpdateSizes}
            settings={setting}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default GridManagerModal;
