import { ADD_ACTION } from './actions/add_action';
import { ADD_BLOCK } from './actions/add_block';
import { DELETE_BLOCK } from './actions/delete_block';
import { UPDATE_ACTION } from './actions/update_action';
import { ADD_PARAM } from './parameters/add_param';
import { CHANGE_PARAM_TYPE } from './parameters/change_param_type';
import { UPDATE_PARAM } from './parameters/update_param';
import { SET_FILTER } from './parameters/set_filter';
import { SET_PAGINATION } from './parameters/set_pagination';
import { SET_SORT } from './parameters/set_sort';
import { DELETE_PARAM } from './root/delete_param';
import { CHANGE_ACTION_ORDER } from './root/change_action_order';
import { CHANGE_PARAM_ORDER } from './functions/change_param_order';
import { CHANGE_RETURN_TYPE } from './functions/change_return_type';
import { UPDATE_FUNCTION } from './functions/update_function';
import { DELETE_ACTION } from './root/delete_action';
import { REPLACE_EMPTY } from './actions/replace_empty';
import { CHANGE_RETURN } from './actions/change_return';
import { CREATE_IF } from './actions/create_if';
import { CREATE_ELSE } from './actions/create_else';

export const storeAPIEvents = {
  ADD_ACTION,
  ADD_BLOCK,
  CREATE_IF,
  CREATE_ELSE,
  DELETE_ACTION,
  DELETE_BLOCK,
  UPDATE_ACTION,
  REPLACE_EMPTY,
  ADD_PARAM,
  CHANGE_PARAM_TYPE,
  UPDATE_PARAM,
  DELETE_PARAM,
  SET_FILTER,
  SET_PAGINATION,
  SET_SORT,
  CHANGE_ACTION_ORDER,
  CHANGE_PARAM_ORDER,
  CHANGE_RETURN_TYPE,
  UPDATE_FUNCTION,
  CHANGE_RETURN
};
