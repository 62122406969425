import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from '../../../../designer/types';
import { t } from 'i18next';

export const LABEL_MANIFEST: ComponentManifest = {
  type: 'LABEL',
  name: 'Text',
  descriptionId: 'TextDescription',
  description:
    'Text is a fundamental building block of web applications. Use text to display content inline in your app.',
  icon: 'fa-solid fa-tag',
  group: ComponentGroups['TEXT'],
  previewHeight: 28,
  previewWidth: 100,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Text',
      key: 'text',
      tooltip: 'designer.right_side.controls.components.label.text'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Type',
      key: 'labelType',
      controlOptions: ['text', 'title', 'link', 'email', 'tel', 'anchor'],
      tooltip: 'designer.right_side.controls.components.label.labelType'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'URL',
      key: 'url',
      tooltip: 'designer.right_side.controls.components.label.url'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.label.tooltip'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.label.visible'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Disabled',
    //   key: 'disabled'
    // },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.label.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.label.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      tooltip: 'designer.right_side.controls.components.label.themePicker'
    },
    {
      controlType: ControlsTypes.FONT,
      controlLabel: t('Font'),
      tooltip: 'designer.right_side.controls.components.label.font'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Font Weight',
      key: 'fontWeight',
      controlOptions: ['normal', 'bold', '100', '300', '500', '600', '800', '900'],
      tooltip: 'designer.right_side.controls.components.label.fontWeight'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: t('Spacing'),
      key: 'lineHeight',
      controlOptions: ['normal', 'inherit', 'initial', 'revert', 'revert-layer', 'unset'],
      tooltip: 'designer.right_side.controls.components.label.lineHeight'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.label.fontSize'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'LineHeight',
      key: 'lineHeight',
      tooltip: 'designer.right_side.controls.components.label.lineHeightNumber'
    },
    {
      controlType: ControlsTypes.TEXTALIGN,
      controlLabel: 'Text Align',
      key: 'textAlign',
      tooltip: 'designer.right_side.controls.components.label.textAlign'
    },
    {
      controlType: ControlsTypes.TEXTDECORATION,
      controlLabel: 'Text Decoration',
      key: 'textDecoration',
      tooltip: 'designer.right_side.controls.components.label.textDecoration'
    },
    {
      controlType: ControlsTypes.TEXTTRANSFORM,
      controlLabel: 'Text Transform',
      key: 'textTransform',
      tooltip: 'designer.right_side.controls.components.label.textTransform'
    },
    {
      controlType: ControlsTypes.FONTSTYLE,
      controlLabel: 'Font Style',
      key: 'fontStyle',
      tooltip: 'designer.right_side.controls.components.label.fontStyle'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
