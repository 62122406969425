import React, { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { TagsService } from '../../../services';
import LogicBuilderContext from '../../../store';
import { Tag } from '../../../types';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

type TagCreateEditDialogProps = {
  tagId?: number;
  show: boolean;
  onClose: () => void;
};

export function TagCreateEditDialog(props: TagCreateEditDialogProps) {
  const { app_id, tags, fetchTags } = useContext(LogicBuilderContext);
  const [tag, setTag] = useState<Tag>(() => {
    if (props.tagId && tags[props.tagId]) {
      return tags[props.tagId];
    }
    return { name: '', description: '' };
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  const { t } = useTranslation();

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    if (isLoading || !app_id) return;

    setIsLoading(true);
    setIsNameInvalid(false);
    if (props.tagId && props.tagId !== -1) {
      if (!tag) return;
      // Editing
      await TagsService.updateTag(app_id, props.tagId, tag)
        .then(() => {
          fetchTags(app_id);
          props.onClose();
        })
        .catch(() => {
          setIsNameInvalid(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (!tag) return;
      // Creating
      await TagsService.createTag(app_id, tag)
        .then(() => {
          fetchTags(app_id);
          props.onClose();
        })
        .catch(() => {
          setIsNameInvalid(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onClose();
      }}
      centered
      scrollable
      className={styles.TagCreateEditDialog}
    >
      <Form onSubmit={onSubmit} id="formModal">
        <Modal.Header closeButton>
          <Modal.Title>
            {t(props.tagId ? 'logicBuilder.tags.EditTag' : 'logicBuilder.tags.CreateTag')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
          {/* Form */}
          <Form.Group className="mb-3" controlId="formTagName">
            <Form.Label>{t('logicBuilder.tags.TagName')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('logicBuilder.tags.TagName') ?? 'logicBuilder.tags.TagName'}
              required
              autoFocus
              maxLength={64}
              value={tag && tag.name ? tag.name : ''}
              isInvalid={isNameInvalid}
              onChange={(e) => {
                setTag({ ...tag, name: e.target.value });
              }}
            />
            <Form.Control.Feedback type="invalid">
              {`${t('logicBuilder.tags.NameConflict')}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formTagDescription">
            <Form.Label>{t('logicBuilder.tags.TagDescription')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={
                t('logicBuilder.tags.TagDescription') ?? 'logicBuilder.tags.TagDescription'
              }
              maxLength={255}
              value={tag?.description ?? ''}
              onChange={(e) => {
                if (!tag) return;
                setTag({ ...tag, description: e.target.value });
              }}
            />
          </Form.Group>
        </Modal.Body>
        {/* Footer buttons */}
        <Modal.Footer>
          <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
            {t('logicBuilder.tags.Cancel')}
          </Button>
          <Button id="saveButton" variant="primary" type="submit">
            {t('logicBuilder.tags.Save')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
