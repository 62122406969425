import React, { useCallback, useEffect, useState } from 'react';
import {
  ModuleDependencyInfo,
  ModuleInfo,
  ModuleQuery,
  ModuleSettings,
  ModuleSqlOptions
} from 'modules/dashboard/types';
import { ProjectsService } from 'modules/project/services';
import { Button, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import ModuleDependencyGroup from '../module_dendency_group';
import { useTranslation } from 'react-i18next';
import Confirmation from 'web_ui/confirmation';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import styles from './styles.module.css';
import { DashboardService } from 'modules/dashboard/services';
import InformationModule from '../create_module_modal/components/information_module';
import EditModuleTemplate from './components/edit_module_template';
import { validateDescriptionInputs, validateModuleName } from 'utils/inputValidation';

export type EditModuleModalProps = {
  /** Modal visibility **/
  showModal: boolean;
  /** Module under edit **/
  moduleInfo: ModuleInfo;
  /** Callback on modal closing **/
  onCloseRequest: (refresh: boolean) => void;
  /** Callback on modal confirm, to update an external ModuleInfo **/
  updateModule: (module: ModuleInfo) => void;
  currentModules: ModuleInfo[];
};

/**
 * Edit Module Modal.
 *
 * @component
 */
export default function EditModuleModal(props: EditModuleModalProps) {
  const [module, setModule] = useState(props.moduleInfo);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { t } = useTranslation();
  const [dataForS3, setDataForS3] = React.useState<ModuleSettings[]>([]);
  const buildQuery = (): ModuleQuery => {
    return {
      appId: module.appId,
      name: module.name,
      description: module.description,
      dependencies: module.dependencies,
      isActive: module.isActive,
      isAssets: module.isAssets,
      isAuth: module.isAuth,
      isSql: module.isSql
    };
  };
  const [dataToAssetsModule, setDataToAssetsModule] = React.useState<ModuleSettings>({
    key: '',
    value: ''
  });
  const [S3AccessKey, setS3AccessKey] = React.useState<string>('');
  const [secretKey, setSecretKey] = React.useState<string>('');
  const [region, setRegion] = React.useState<string>('');
  const [bucketName, setBucketName] = React.useState<string>('');
  const [blockSave, setBlockSave] = React.useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});

  const onChangeModuleName = (value: string) => {
    handleErrorMessages('moduleName', checkNameIsValid(value));
    setModule({ ...module, name: value });
  };

  const onChangeModuleDescription = (value: string) => {
    handleErrorMessages('moduleDescription', checkDescriptionIsValid(value));
    setModule({ ...module, description: value });
  };

  const onChangeModuleDependencies = (moduleDependencies: ModuleDependencyInfo[]) => {
    setModule({ ...module, dependencies: moduleDependencies });
  };

  const onChangeModuleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModule({ ...module, isActive: e.target.checked });
  };

  const onChangeModuleSql = (value: ModuleSqlOptions) => {
    if (value === ModuleSqlOptions.JPA) {
      setModule({ ...module, isSql: false });
    } else if (value === ModuleSqlOptions.JDBC) {
      setModule({ ...module, isSql: true });
    } else {
      setModule({ ...module, isSql: undefined });
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateInputs()) {
      return;
    }
    try {
      const query = buildQuery();
      const res = await ProjectsService.updateModuleById(props.moduleInfo.id, query);
      const mod = res as ModuleInfo;
      setModule(mod);
      if (dataToAssetsModule.key !== '' && dataToAssetsModule.value !== '' && mod) {
        const arrayToUpdate: ModuleSettings[] = [dataToAssetsModule, ...dataForS3]; // getting the module type and the data to s3 if it has
        const response = await DashboardService.updateModuleSettings(mod.id, arrayToUpdate);
        if (!response) {
          throw new Error('impossible to create assets module');
        }
      }
      props.updateModule(module);
    } catch (error) {
      console.error(error);
    }
    props.onCloseRequest(true);
  };

  const fetchingModuleSetting = useCallback(async () => {
    const response = await DashboardService.getModuleSettings(module.id);
    if (response) {
      const verifying = response.filter((item) => item.value === 's3');
      if (verifying.length > 0) {
        // find and we have sure that is s3 assets module
        setDataToAssetsModule({ key: verifying[0].key, value: verifying[0].value });
        response.forEach((itemIt, index) => {
          switch (itemIt.key) {
            case 'S3_access':
              setS3AccessKey(itemIt.value);
              break;
            case 'S3_secret':
              setSecretKey(itemIt.value);
              break;
            case 'S3_region':
              setRegion(itemIt.value);
              break;
            case 'S3_bucket':
              setBucketName(itemIt.value);
              break;
            default:
              return null;
          }
        });
      } else {
        setDataToAssetsModule({ key: response[0].key, value: response[0].value });
      }
    } else {
      throw new Error('Impossible to get the assets settigs');
    }
  }, [module.id]);

  useEffect(() => {
    fetchingModuleSetting();
  }, [fetchingModuleSetting]);

  async function deleteModule() {
    await ProjectsService.deleteModuleById(props.moduleInfo.id);
    setShowConfirmationDialog(false);
    setRefresh(!refresh);
    props.onCloseRequest(true);
  }

  const choosingTheStorage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case 'File System':
        setDataToAssetsModule({ key: 'storage', value: 'file_system' });
        break;
      case 'S3':
        setDataToAssetsModule({ key: 'storage', value: 's3' });
        break;
      case 'Blob':
        setDataToAssetsModule({ key: 'storage', value: 'blob' });
        break;
    }
  };

  const submitTheS3 = () => {
    if (dataToAssetsModule.value === 's3') {
      setDataForS3((currentVal: ModuleSettings[]) => {
        const auxVal: ModuleSettings[] = [...currentVal];
        auxVal.push({ key: 'S3_access', value: S3AccessKey });
        auxVal.push({ key: 'S3_secret', value: secretKey });
        auxVal.push({ key: 'S3_region', value: region });
        auxVal.push({ key: 'S3_bucket', value: bucketName });
        return auxVal;
      });
    }
    setBlockSave(true);
  };

  const clearS3Fields = () => {
    setRegion('');
    setS3AccessKey('');
    setSecretKey('');
    setBucketName('');
    setBlockSave(false);
  };

  const handleErrorMessages = (key: string, errorMessage: string) => {
    const nextErrorMessages = {
      ...errorMessages,
      [key]: errorMessage
    };
    setErrorMessages(nextErrorMessages);
  };

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateInputs = (): boolean => {
    const nextErrorMessages = {
      ...errorMessages,
      moduleName: checkNameIsValid(module.name),
      moduleDescription: checkDescriptionIsValid(module.description)
    };
    setErrorMessages(nextErrorMessages);
    for (const errorMessage of Object.values(nextErrorMessages)) {
      if (errorMessage) {
        return false;
      }
    }
    return true;
  };

  const checkDescriptionIsValid = (input: string) => {
    const { code } = validateDescriptionInputs(input, 255, false);
    let errorMessage = '';
    if (code === 'EXCEEDS_MAX_LENGTH') {
      errorMessage = 'inputValidationErrorMessages.ModuleDescriptionExceedsMaxLength';
    } else if (code === 'EMPTY_STRING') {
      errorMessage = 'inputValidationErrorMessages.ModuleDescriptionEmptyString';
    }
    return errorMessage;
  };

  const checkNameIsValid = (input: string) => {
    let errorMessage = '';
    const { code } = validateModuleName(input, 64);

    const filteringByNameModule = props.currentModules
      .filter((mod) => mod.id !== module.id)
      .filter((moduleProp) => moduleProp.name === input);
    if (filteringByNameModule.length > 0) {
      errorMessage = 'appResume.new_module.SameName';
    }
    if (code === 'EXCEEDS_MAX_LENGTH') {
      errorMessage = 'inputValidationErrorMessages.ModuleNameExceedsMaxLength';
    } else if (code) {
      errorMessage = 'inputValidationErrorMessages.GenericErrorMessage';
    }
    return errorMessage;
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={props.showModal}
        onHide={() => props.onCloseRequest(false)}
        scrollable
      >
        <Form onSubmit={onSubmit} className={styles.formWrapper} id="formModal">
          <Modal.Header closeButton>
            <Modal.Title>{t('appResume.module.EditModule')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
            <Tabs defaultActiveKey={'informations'} id="module">
              <Tab eventKey={'informations'} title={t('appResume.module.informations')}>
                <InformationModule
                  module={module}
                  onChangeModuleDescription={onChangeModuleDescription}
                  onChangeModuleName={onChangeModuleName}
                  onChangeModuleStatus={onChangeModuleStatus}
                  onChangeModuleSql={onChangeModuleSql}
                  errorMessages={errorMessages}
                />
              </Tab>
              <Tab eventKey={'template'} title={t('appResume.module.template')}>
                <EditModuleTemplate
                  s3AccessKey={S3AccessKey}
                  blockSave={blockSave}
                  bucketName={bucketName}
                  choosingTheStorage={choosingTheStorage}
                  clearS3Fields={clearS3Fields}
                  dataToAssetsModule={dataToAssetsModule}
                  module={module}
                  region={region}
                  secretKey={secretKey}
                  setS3AccessKey={setS3AccessKey}
                  setBlockSave={setBlockSave}
                  setBucketName={setBucketName}
                  setRegion={setRegion}
                  setSecretKey={setSecretKey}
                  submitTheS3={submitTheS3}
                />
              </Tab>
              <Tab eventKey={'dependency'} title={t('appResume.module.Dependencies')}>
                <div className={styles.appSettingsDialog}>
                  <ModuleDependencyGroup
                    moduleId={module.id}
                    onChange={onChangeModuleDependencies}
                    refreshAfterDeleted={refresh}
                  />
                </div>
              </Tab>
            </Tabs>
            <Authorization
              context={ContextRole.APP}
              allowedAuthorities={[RoleAuthorities.DELETE_MODULE]}
            >
              <Form.Group className="mb-2">
                <Form.Label className={styles.dangerZoneTitle}>
                  {t('appResume.AttentionWarning')}
                </Form.Label>
                <div className="border border-2 border-danger rounded p-2">
                  <div className={styles.dangerZoneContainer}>
                    <div className={styles.dangerZoneLabels}>
                      <Form.Label id={'dangerZoneButton'} className={styles.dangerZoneTitle}>
                        {t('appResume.module.DeleteModule')}
                      </Form.Label>
                      <Form.Label className={styles.dangerZoneDescription}>
                        {t('appResume.module.DeleteModuleDescription')}
                      </Form.Label>
                    </div>
                    <Button
                      id={'deleteButton'}
                      variant="danger"
                      onClick={() => setShowConfirmationDialog(true)}
                    >
                      {t('Delete')}
                    </Button>
                  </div>
                </div>
              </Form.Group>
            </Authorization>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: 'inherit' }}>
            <div className={styles.dialogSaveButton}>
              <Button id={'saveButton'} type="submit">
                {t('Save')}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={t('appResume.module.ConfirmDelete')}
        showItemNameInput={true}
        itemName={props.moduleInfo.name}
        onConfirmation={() => deleteModule()}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => {
          setShowConfirmationDialog(false);
          props.onCloseRequest(true);
        }}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
      />
    </>
  );
}
