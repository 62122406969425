import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import React from 'react';
import EndCycleIcon from './end_cycle_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { t } from 'i18next';

export const END_CYCLE_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.END_CYCLE,
  name: t('EndFor'),
  description: 'Action to close a "for" loop',
  color: 'secondary',
  category: BACKEND_ACTIONS_CATEGORIES.CYCLE,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.CYCLE,
  icon: <EndCycleIcon />,
  closeBlock: true,
  inlineInputs: [],
  inputs: []
};

/*
 * AddAction
 *  - if openBlock = create a CLOSE_BLOCK action
 *  - put the CLOSE_BLOCK action uuid in the closeBlockActionUuid attribute of the OPEN_BLOCK action
 *  - put the OPEN_BLOCK action uuid in the openBlockActionUuid attribute of the CLOSE_BLOCK action
 *
 * Remove action
 *  - it is not possible to remove CLOSE_BLOCK actions
 *  - if OPEN_BLOCK = remove the CLOSE_BLOCK action stated in closeBlockActionUuid
 *  - remove OPEN_BLOCK action
 *
 * Reorder action
 *  - if OPEN_BLOCK = reorder linked CLOSE_BLOCK one position bellow
 *  - if CLOSE_BLOCK = can't be reordered upwards the linked OPEN_BLOCK
 */
