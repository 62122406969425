import React, { useState } from 'react';
import styles from '../../styles.module.css';
import { Button } from 'react-bootstrap';
import Icon from '../../../../../../../web_ui/icon';
import { AppTranslation } from '../../../../../../../modules/designer/types';
import { useTranslation } from 'react-i18next';
import EditTranslationModal from '../../../../../../../modules/project/components/edit_translation_modal';
import Confirmation from '../../../../../../../web_ui/confirmation';
import { AppTranslateService } from '../../../../../../../modules/designer/services';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type TranslationInstanceProps = {
  translation: AppTranslation;
  index: number;
  selectedLanguage: string;
  handleOnTranslationsUpdate: () => Promise<void>;
  mainLanguageId: string;
};

function TranslationInstance({
  translation,
  index,
  selectedLanguage,
  mainLanguageId,
  handleOnTranslationsUpdate
}: TranslationInstanceProps) {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleCloseEditTranslationModal = async () => {
    setShowEditModal(false);
    await handleOnTranslationsUpdate();
  };

  async function handleDeleteTranslation() {
    setShowDeleteModal(false);
    try {
      await AppTranslateService.deleteTranslation(selectedLanguage, translation);
      await handleOnTranslationsUpdate();
    } catch (e) {
      alert(e);
    }
  }

  return (
    <>
      <tr key={translation.id}>
        <td style={{ fontWeight: 'bold' }} className={'col-sm-2'}>
          {index}
        </td>
        <td className={'col-sm-4'}>
          <HelpPopover
            helpBoxProps={{
              title: translation.key
            }}
            placement="top"
          >
            <span className={styles.translationKey}>{translation.key}</span>
          </HelpPopover>
        </td>
        <td className={'col-sm-4'}>
          <HelpPopover
            helpBoxProps={{
              title: translation.value
            }}
            placement="top"
          >
            <span className={styles.translationValue}>{translation.value}</span>
          </HelpPopover>
        </td>
        <td className={'col-sm-2'}>
          {
            <div style={{ display: 'flex', gap: '8px' }}>
              <HelpPopover
                helpBoxProps={{
                  title: t('appResume.languages.EditTranslation')!
                }}
                placement="top"
              >
                <Button
                  id={'edit-' + translation.key}
                  variant="body"
                  onClick={(e) => {
                    setShowEditModal(true);
                  }}
                >
                  <Icon iconName="pen-to-square" />
                </Button>
              </HelpPopover>

              {selectedLanguage === mainLanguageId && (
                <HelpPopover
                  helpBoxProps={{
                    title: t('appResume.languages.DeleteTranslation')!
                  }}
                  placement="top"
                >
                  <Button
                    id={'delete-' + translation.key}
                    variant="body"
                    onClick={(e) => {
                      setShowDeleteModal(true);
                    }}
                  >
                    <Icon iconName="trash"></Icon>
                  </Button>
                </HelpPopover>
              )}
            </div>
          }
        </td>
      </tr>
      <EditTranslationModal
        onClose={handleCloseEditTranslationModal}
        showModal={showEditModal}
        translation={translation}
        mainLanguageId={mainLanguageId}
        languageId={selectedLanguage}
      />

      <Confirmation
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirmation={handleDeleteTranslation}
        message={
          t('appResume.languages.DeleteLanguage') +
          ' (' +
          (translation.key ? translation.key : '') +
          ')'
        }
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
}

export default TranslationInstance;
