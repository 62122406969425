import { CodePreviewRepository } from '../repos';

export class CodePreviewServ {
  async runPreviewJob(appId: string, id: string, previewType: string, signal: AbortSignal) {
    return await CodePreviewRepository.runPreviewJob(appId, id, previewType, signal);
  }

  async getPreviewResult(appId: string, jobId: string, signal: AbortSignal) {
    return await CodePreviewRepository.getPreviewResult(appId, jobId, signal);
  }
}
