import React from 'react';
import { Argument } from '../../../store/types/manifestsAndInputs';
import EndpointParameterEditor from './endpoint_parameter_editor';
import { ParameterEndpoint } from '../../../../../modules/logic_builder/types';

type EndpointParametersProps = {
  params: { [key: string]: ParameterEndpoint };
  paramsValues: { [key: string]: Argument };
  actionUuid: string;
  handleChange: (paramId: string, value: Argument) => void;
  label: string;
  isFromSideBar?: boolean;
};

function EndpointParameters({
  params,
  paramsValues,
  handleChange,
  actionUuid,
  label,
  isFromSideBar
}: EndpointParametersProps) {
  return (
    <div
      className={
        isFromSideBar ? '' : 'd-flex flex-column text-white align-items-start justify-content-end'
      }
    >
      {paramsValues && Object.keys(paramsValues).length > 0 && (
        <span style={{ fontSize: '0.9rem' }}>{label}</span>
      )}
      {paramsValues &&
        Object.keys(paramsValues).map((p, index) => (
          <EndpointParameterEditor
            key={index}
            param={params[p]}
            paramValue={paramsValues[p]}
            handleChange={handleChange}
            actionUuid={actionUuid}
            isFromSideBar={isFromSideBar}
          />
        ))}
    </div>
  );
}

export default EndpointParameters;
