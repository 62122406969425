import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Button, Container, Row, Col } from 'react-bootstrap';

import styles from './styles.module.css';

type AddCustomPropModalProps = {
  showModal: boolean;
  onClose: () => void;
  addCustomProp: (key: string, value: string) => void;
};

function AddCustomPropModal(props: AddCustomPropModalProps) {
  const { t } = useTranslation();

  const [keyCustom, setKeyCustom] = React.useState<string>('');
  const [valueCustom, setValueCustom] = React.useState<string>('');

  const onHide = () => {
    setKeyCustom('');
    setValueCustom('');
    props.onClose();
  };

  const handleSubmitProp = async () => {
    props.addCustomProp(keyCustom, valueCustom);
    setKeyCustom('');
    setValueCustom('');
    props.onClose();
  };

  return (
    <Modal show={props.showModal} onHide={onHide} size={'lg'} centered backdrop={'static'}>
      <Modal.Header closeButton>
        <Modal.Title>{t('appResume.properties.AddCustom')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form.Group as={Row} className={'mb-3'}>
            <Form.Label column sm="3">
              {t('appResume.properties.Key')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                id="keyPropInput"
                onChange={(e) => {
                  setKeyCustom(e.target.value);
                }}
                value={keyCustom}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 pt-2">
            <Form.Label column sm="3">
              {t('appResume.properties.Value')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                id="valuePropInput"
                onChange={(e) => {
                  setValueCustom(e.target.value);
                }}
                value={valueCustom}
              />
            </Col>
          </Form.Group>
          <div className={`${styles.containerButton}`}>
            <Button id="addProp" onClick={handleSubmitProp}>
              {t('appResume.module.Add')}
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default AddCustomPropModal;
