import produce from 'immer';

import { WidgetsState } from '../index';
import {
  ADD_WIDGET,
  AddWidgetAction,
  CHANGE_WIDGET,
  ChangeWidgetAction,
  DELETE_WIDGET,
  DeleteWidgetAction,
  SET_WIDGETS,
  SetWidgetsAction,
  UPDATE_WIDGET_POSITION,
  UpdateWidgetPositionAction
} from '../actions/widgets';

type WidgetsActions =
  | SetWidgetsAction
  | AddWidgetAction
  | ChangeWidgetAction
  | DeleteWidgetAction
  | UpdateWidgetPositionAction;

export const widgetsReducer = (state: WidgetsState = {}, action: WidgetsActions): WidgetsState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_WIDGETS:
        return doSetWidgets(draft, action);
      case ADD_WIDGET:
        return doAddWidget(draft, action);
      case CHANGE_WIDGET:
        return doChangeWidget(draft, action);
      case DELETE_WIDGET:
        return doDeleteWidget(draft, action);
      case UPDATE_WIDGET_POSITION:
        return doUpdateWidgetPosition(draft, action);
      default:
        return draft;
    }
  });
};

function doSetWidgets(state: WidgetsState, action: SetWidgetsAction): WidgetsState {
  return action.payload.widgets;
}

function doAddWidget(state: WidgetsState, action: AddWidgetAction): WidgetsState {
  const uuid = action.payload.widget.uuid;
  state[uuid] = action.payload.widget;
  return state;
}

function doChangeWidget(state: WidgetsState, action: ChangeWidgetAction): WidgetsState {
  const uuid = action.payload.widget.uuid;
  state[uuid] = action.payload.widget;
  return state;
}

function doDeleteWidget(state: WidgetsState, action: DeleteWidgetAction): WidgetsState {
  delete state[action.payload.uuid];
  return state;
}

function doUpdateWidgetPosition(
  state: WidgetsState,
  action: UpdateWidgetPositionAction
): WidgetsState {
  state[action.payload.uuid].posX = action.payload.posX;
  state[action.payload.uuid].posY = action.payload.posY;
  return state;
}
