import { DataFrameManifest } from '../../../../../modules/modeler/types';

const manifest: DataFrameManifest = {
  type: 'ENUM',
  name: 'Enum',
  descriptionId: 'Enum_description',
  description:
    'Is an abstract data type, whose values are assigned to exactly one element of a finite set of identifiers chosen by the programmer.',
  icon: 'columns'
};

export default manifest;
