import produce from 'immer';

import { initialState, ViewsState } from '../index';
import {
  ADD_EVENT,
  AddEventAction,
  CHANGE_EDITOR_MODE,
  CHANGE_LAYOUT,
  CHANGE_VIEW_PROPERTY,
  CHANGE_VIEW_STYLE,
  ChangeEditorModeAction,
  ChangeLayoutAction,
  ChangeViewPropertyAction,
  ChangeViewStyleAction,
  DELETE_EVENT,
  DeleteEventAction,
  SET_VIEWS,
  SetViewsAction
} from '../actions/views';
import { ADD_FUNCTION, AddFunctionAction } from '../actions/functions';

type ViewsActions =
  | SetViewsAction
  | ChangeLayoutAction
  | ChangeViewPropertyAction
  | ChangeViewStyleAction
  | AddEventAction
  | DeleteEventAction
  | AddFunctionAction
  | ChangeEditorModeAction;

export const viewsReducer = (
  state: ViewsState = initialState.views,
  action: ViewsActions
): ViewsState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_VIEWS:
        return doSetViews(draft, action);
      case CHANGE_VIEW_PROPERTY:
        return doChangeViewProperty(draft, action);
      case CHANGE_VIEW_STYLE:
        return doChangeViewStyle(draft, action);
      case CHANGE_LAYOUT:
        return doChangeLayout(draft, action);
      case ADD_EVENT:
        return doAddEvent(draft, action);
      case DELETE_EVENT:
        return doDeleteEvent(draft, action);
      case ADD_FUNCTION:
        return doAddFunction(draft, action);
      case CHANGE_EDITOR_MODE:
        return doChangeEditorMode(draft, action);
      default:
        return draft;
    }
  });
};

function doSetViews(state: ViewsState, action: SetViewsAction): ViewsState {
  return action.payload.views;
}

function doChangeViewProperty(state: ViewsState, action: ChangeViewPropertyAction) {
  if (action.payload.key === 'rootPage') {
    state[action.payload.uuid].data[action.payload.key] = action.payload.value;
  } else {
    state[action.payload.uuid].data[action.payload.key] = action.payload.value;
  }
  return state;
}

function doChangeViewStyle(state: ViewsState, action: ChangeViewStyleAction) {
  if (action.payload.key === 'globalFontSize') {
    state[action.payload.uuid].styles[action.payload.key] = action.payload.value;
  } else {
    state[action.payload.uuid].styles[action.payload.key] = action.payload.value;
  }
  return state;
}

function doChangeLayout(state: ViewsState, action: ChangeLayoutAction) {
  state[action.payload.uuid].layout_uuid = action.payload.layout_uuid;
  return state;
}

function doAddEvent(state: ViewsState, action: AddEventAction) {
  if (state[action.payload.view] && state[action.payload.view].events) {
    state[action.payload.view].events[action.payload.event] = action.payload.function;
  } else {
    state[action.payload.view].events = {
      [action.payload.event]: action.payload.function
    };
  }
  return state;
}

function doDeleteEvent(state: ViewsState, action: DeleteEventAction) {
  delete state[action.payload.view].events[action.payload.event];
  return state;
}

function doAddFunction(state: ViewsState, action: AddFunctionAction): ViewsState {
  if (action.payload.view) state[action.payload.view].functions.push(action.payload.uuid);
  return state;
}

function doChangeEditorMode(state: ViewsState, action: ChangeEditorModeAction): ViewsState {
  if (action.payload.value) state[action.payload.uuid].editorMode = action.payload.value;
  return state;
}
