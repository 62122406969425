import React from 'react';
import { COMPONENT_TYPES } from 'modules/designer/studio/exocode_components';
import styles from './style.module.css';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';

export enum MenuType {
  SIDEBYSIDE = 'SIDEBYSIDE',
  COLLAPSE = 'COLLAPSE',
  RIGHTSIDEBYSIDE = 'RIGHTSIDEBYSIDE'
}

export type MenuTypeSelectorProps = {
  onChange: (type: string) => void;
  value?: any;
};

function MenuTypeSelector(props: MenuTypeSelectorProps) {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center mb-4 overflow-hidden">
      <div
        className={`d-flex flex-column align-items-center me-2 ${styles.itemWrapper}`}
        onClick={() => props.onChange(MenuType.SIDEBYSIDE)}
      >
        <div
          className={`border-body fw-2 text-center ${
            props.value?.['menuType'] === MenuType.SIDEBYSIDE ? 'border-primary' : 'border-body'
          } border rounded ${styles.itemContainer}`}
        >
          <div className={`${styles.sampleContent1} ${styles.exampleBar}`}>
            <div className={`text-body-secondary ${styles.expandIcon}`}>
              <Icon iconName="arrow-right"></Icon>
            </div>
            <div className={`${styles.subSampleContent1} ${styles.exampleBar}`}>
              <div className={`text-body-secondary ${styles.expandIcon}`}>
                <Icon iconName="arrow-right"></Icon>
              </div>
              <div className={`${styles.subSubSampleContent1} ${styles.exampleBar}`}></div>
            </div>
          </div>
        </div>
        <label>{t('designer.right_side.controls.SideListType')}</label>
      </div>

      <div
        className={`d-flex me-2 flex-column align-items-center ${styles.itemWrapper}`}
        onClick={() => props.onChange(MenuType.COLLAPSE)}
      >
        <div
          className={`border-body fw-2 text-center ${
            props.value?.['menuType'] === MenuType.COLLAPSE ? 'border-primary' : 'border-body'
          } border rounded ${styles.itemContainer}`}
        >
          <div className={`${styles.collapseSampleContent1} ${styles.exampleBar}`}>
            <div className={`${styles.subCollapseSampleContent1} ${styles.exampleBar}`}>
              <div className={`text-body-secondary ${styles.expandIconCollapse}`}>
                <Icon iconName="arrow-right"></Icon>
              </div>
              <div className={`${styles.subSubCollapseSampleContent1} `}></div>
            </div>
          </div>
        </div>
        <label>{t('designer.right_side.controls.CollapseListType')}</label>
      </div>

      <div
        className={`d-flex flex-column align-items-center me-2 ${styles.itemWrapper}`}
        onClick={() => props.onChange(MenuType.RIGHTSIDEBYSIDE)}
      >
        <div
          className={`border-body fw-2 text-center ${
            props.value?.['menuType'] === MenuType.RIGHTSIDEBYSIDE
              ? 'border-primary'
              : 'border-body'
          } border rounded ${styles.itemContainer} ${styles.rightItemWrapper}`}
        >
          <div className={`${styles.sampleContent1} ${styles.exampleBar}`}>
            <div className={`text-body-secondary ${styles.expandIcon}`}>
              <Icon iconName="arrow-right"></Icon>
            </div>
            <div className={`${styles.subSampleContent1} ${styles.exampleBar}`}>
              <div className={`text-body-secondary ${styles.expandIcon}`}>
                <Icon iconName="arrow-right"></Icon>
              </div>
              <div className={`${styles.subSubSampleContent1} ${styles.exampleBar}`}></div>
            </div>
          </div>
        </div>
        <label>{t('designer.right_side.controls.RightSideListType')}</label>
      </div>
    </div>
  );
}

export default MenuTypeSelector;
