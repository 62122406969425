import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { EndpointParameterCreatorDialog } from '../endpoint_parameter_creator_dialog';
import {
  Action,
  EndpointExtended,
  EndpointResponseSimple,
  ParameterEndpoint
} from 'modules/logic_builder/types';
import { ResponseDialog } from '../response_dialog';
import { EndpointsService } from 'modules/logic_builder/services';
import { EndpointParameterEditorDialog } from '../endpoint_parameter_editor_dialog';
import { EndpointActionCreatorDialog } from '../endpoint_action_creator_dialog';
import { EndpointActionCreatorDialog as EndpointActionEditorDialog } from '../endpoint_action_creator_dialog';
import LogicBuilderContext from 'modules/logic_builder/store';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

type LogicTabProps = {
  endpointExtended: EndpointExtended;
};

export function LogicTab(props: LogicTabProps) {
  const [showEndpointParameterCreatorDialog, setShowEndpointParameterCreatorDialog] =
    useState(false);
  const [showEndpointResponseEditorDialog, setShowEndpointResponseEditorDialog] = useState(false);

  const [responseToBeEdited, setResponseToBeEdited] = useState<EndpointResponseSimple>();
  const [showParameterEditorDialog, setShowParameterEditorDialog] = useState<ParameterEndpoint>();

  const [endpointParameters, setEndpointParameters] = useState<ParameterEndpoint[]>([]);
  const [endpointResponses, setEndpointResponses] = useState<EndpointResponseSimple[]>([]);

  const [action, setAction] = useState<Action<any>>();
  const [showAddActionDialog, setShowAddActionDialog] = useState(false);
  const [showChangeActionDialog, setShowChangeActionDialog] = useState(false);
  const [isNative, setIsNative] = useState(false);
  const { functions } = useContext(LogicBuilderContext);
  const [deleted, setDeleted] = React.useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsNative(props.endpointExtended.native ?? false);
  }, [props.endpointExtended.native]);

  const fetchActions = useCallback(async () => {
    await EndpointsService.getEndpointAction(props.endpointExtended.uuid).then((action) => {
      setAction(action);
    });
  }, [props.endpointExtended.uuid]);

  useEffect(() => {
    setAction(props.endpointExtended.action);
  }, [props.endpointExtended.action]);

  // A Parameter with input type OBJECT exists. If that's the case then EndpointParameterCreator dialog
  // should not allow users to create new parameters with input type OBJECT.
  const [parameterIdWithTypeObject, setParameterIdWithTypeObject] = useState<string>();

  const fetchEndpointParameters = useCallback(async () => {
    await EndpointsService.getParameters(props.endpointExtended.uuid).then((parameters) => {
      setEndpointParameters(parameters);

      let parameterIdWithTypeObject = undefined;
      for (const parameter of parameters) {
        if (parameter.type === 'OBJECT') {
          parameterIdWithTypeObject = parameter.uuid;
          break;
        }
      }
      setParameterIdWithTypeObject(parameterIdWithTypeObject);
    });
  }, [props.endpointExtended.uuid]);

  const fetchEndpointResponses = useCallback(async () => {
    await EndpointsService.getEndpointResponses(props.endpointExtended.uuid).then((responses) => {
      setEndpointResponses(responses);
    });
  }, [props.endpointExtended.uuid]);

  useEffect(() => {
    fetchEndpointParameters();
  }, [fetchEndpointParameters]);

  useEffect(() => {
    fetchEndpointResponses();
  }, [fetchEndpointResponses]);

  function handleShowResponseDialog(response?: EndpointResponseSimple) {
    if (response) {
      setResponseToBeEdited(response);
    }
    setShowEndpointResponseEditorDialog(true);
  }

  async function handleShowActionDialogs() {
    if (!action) {
      setShowAddActionDialog(true);
    } else {
      setShowChangeActionDialog(true);
    }
  }

  function findFunctionFromAction() {
    const functionId = action?.data.function.functionId;
    if (!functionId) return;

    for (const fnList of Object.values(functions)) {
      for (const fn of fnList) {
        if (fn.uuid === functionId) {
          return fn.name;
        }
      }
    }

    return null;
  }

  return (
    <>
      <div className={styles.TableWrapper}>
        <div className={styles.CallFunctionWrapper}>
          <Row id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-service-function']}>
            <Col sm={9}>
              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  {t('logicBuilder.Function')}
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder={
                      deleted
                        ? `${t('logicBuilder.ChooseFunction')}`
                        : findFunctionFromAction() ?? `${t('logicBuilder.ChooseFunction')}`
                    }
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={3} className={styles.CallFunctionButton}>
              <Button
                id={'createActionButton'}
                variant="primary"
                className="btn-sm"
                onClick={() => handleShowActionDialogs()}
                disabled={isNative}
              >
                {action
                  ? `${t('logicBuilder.ChangeFunction')}`
                  : `${t('logicBuilder.ChooseFunction')}`}
              </Button>
            </Col>
          </Row>
        </div>

        <div id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-input-parameters']}>
          <div className={styles.TableTitle}>
            {t('logicBuilder.Parameters')}
            <Button
              id={'addParametersButton'}
              variant="primary"
              className="btn-sm"
              disabled={isNative}
              onClick={() => setShowEndpointParameterCreatorDialog(true)}
            >
              {t('logicBuilder.Add')}
            </Button>
          </div>
          <div>
            <Table size="sm" className={styles.Table}>
              <thead>
                <tr>
                  <th>{t('logicBuilder.Name')}</th>
                  <th>{t('logicBuilder.Type')}</th>
                  <th>{t('logicBuilder.In')}</th>
                  <th>{t('logicBuilder.Required')}</th>
                </tr>
              </thead>
              <tbody>
                {endpointParameters.map((parameter) => {
                  return (
                    <tr
                      key={parameter.uuid}
                      className={styles.Parameter}
                      onClick={() => setShowParameterEditorDialog(parameter)}
                    >
                      <td>{parameter.name}</td>
                      {parameter.list ? (
                        <td>{`List<${parameter.type}>`}</td>
                      ) : (
                        <td>{parameter.type}</td>
                      )}
                      <td>{parameter.inputType}</td>
                      <td>{parameter.required ? <Icon iconName="check" /> : ''}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <div
        className={styles.TableWrapper}
        id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-responses']}
      >
        <div className={styles.TableTitle}>
          {t('logicBuilder.Responses')}
          <Button
            id={'addResponsesButton'}
            variant="primary"
            className="btn-sm"
            disabled={isNative}
            onClick={() => setShowEndpointResponseEditorDialog(true)}
          >
            {t('logicBuilder.Add')}
          </Button>
        </div>
        <div>
          <Table size="sm" className={styles.Table}>
            <thead>
              <tr>
                <th>{t('logicBuilder.Code')}</th>
                <th>{t('logicBuilder.Description')}</th>
                <th>{t('logicBuilder.Data')}</th>
              </tr>
            </thead>
            <tbody>
              {endpointResponses.map((res) => {
                return (
                  <tr
                    key={res.uuid}
                    className={styles.Response}
                    onClick={() => handleShowResponseDialog(res)}
                  >
                    <td>{res.httpStatus}</td>
                    <td>{res.description}</td>
                    <td>...</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <EndpointParameterCreatorDialog
        fetchParameters={fetchEndpointParameters}
        parameterWithObjectTypeExists={parameterIdWithTypeObject != null}
        endpointId={props.endpointExtended.uuid}
        show={showEndpointParameterCreatorDialog}
        onClose={() => setShowEndpointParameterCreatorDialog(false)}
      />
      {showParameterEditorDialog && (
        <EndpointParameterEditorDialog
          fetchParameters={fetchEndpointParameters}
          parameter={showParameterEditorDialog}
          endpointId={props.endpointExtended.uuid}
          show={showParameterEditorDialog != null}
          onClose={() => setShowParameterEditorDialog(undefined)}
          parameterWithObjectTypeExists={
            parameterIdWithTypeObject != null &&
            showParameterEditorDialog.uuid !== parameterIdWithTypeObject
          }
        />
      )}
      <ResponseDialog
        endpointId={props.endpointExtended.uuid}
        fetchResponses={fetchEndpointResponses}
        response={responseToBeEdited}
        editMode={responseToBeEdited != null && !isNative}
        show={showEndpointResponseEditorDialog}
        onClose={() => {
          setShowEndpointResponseEditorDialog(false);
          setResponseToBeEdited(undefined);
        }}
      />
      <EndpointActionEditorDialog
        fetchActions={fetchActions}
        endpointId={props.endpointExtended.uuid}
        show={showChangeActionDialog}
        onClose={() => setShowChangeActionDialog(false)}
        editMode={true}
        action={action}
        dialogTitle={t('logicBuilder.ChangeAction')}
        setdeleted={setDeleted}
      />
      <EndpointActionCreatorDialog
        fetchActions={fetchActions}
        endpointId={props.endpointExtended.uuid}
        show={showAddActionDialog}
        onClose={() => setShowAddActionDialog(false)}
        editMode={false}
        dialogTitle={t('logicBuilder.CreateAction')}
      />
    </>
  );
}
