import React from 'react';
import styles from '../styles.module.css';

type ColumnDragIconProps = {
  show?: boolean;
};

export default function ColumnDragIcon({ show = true }: ColumnDragIconProps) {
  return (
    <div
      id={'columnDragButton'}
      className={`${styles.ColumnButtonWrapper} ${styles.ColumnReorderButton} col-sm-1  d-flex align-items-center`}
    >
      {show && <i className={`fa fa-grip-vertical text-secondary ${styles.ColumnButton}`} />}
    </div>
  );
}
