import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { EditComponentPreviewProps } from '.';

// export interface TextEditorPreviewProps {}

function TextEditorPreview({ value }: EditComponentPreviewProps) {
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <Form.Group className="mb-3 w-100" controlId="GenericTextInput">
      <Form.Control value={value} type="text" placeholder="" />
    </Form.Group>
  );
}

export default TextEditorPreview;
