import React from 'react';
import { AssetURI } from 'modules/modeler/types';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';
import { COMPONENT_TYPES, ComponentTypes } from '../../exocode_components';

interface ComponentItemProps {
  type: string;
  name: string;
  description: string;
  icon?: string;
  group?: string;
  preview?: AssetURI;
}

function ComponentItem({ name, type, description, icon, group, preview }: ComponentItemProps) {
  const isGridComponent = (type: string): type is ComponentTypes => {
    return Object.values(COMPONENT_TYPES).includes(type as ComponentTypes);
  };

  return (
    <div
      className={`${styles.componentItemContainer} bg-body-secondary w-100 me-1`}
      id={`${name.replaceAll(' ', '').toLowerCase()}Component`}
    >
      <div
        className={`${styles.componentItem} border rounded-top border-light d-flex justify-content-center align-items-center`}
        style={{ height: '48px' }}
      >
        {icon && <Icon iconName={icon} customIconName={isGridComponent(type) ? icon : undefined} />}
      </div>
      <div
        className={`${styles.componentItem} bg-body border border-light d-flex justify-content-center align-items-center text-center rounded-bottom`}
        style={{ fontSize: '12px', minHeight: '37px' }}
      >
        {name}
      </div>
    </div>
  );
}

export default ComponentItem;
