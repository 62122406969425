import { useEffect, useState } from 'react';
import { ObjectsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import { Table } from 'modules/modeler/types';

function useFetchEntities() {
  const { module_id } = useParams();
  const [entities, setEntities] = useState<Table[]>([]);

  useEffect(() => {
    if (!module_id) return;
    const fetchObjects = async () => {
      await ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
        setEntities(schema?.tables ? schema.tables : []);
      });
    };
    fetchObjects();
  }, [module_id]);

  return entities;
}

export default useFetchEntities;
