import React, { ChangeEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ModalService } from '../../../services';
import { useTranslation } from 'react-i18next';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';
import data from './reserved_words.json';

const Template = {};

type CreateModalDialogProps = {
  show: boolean;
  onClose: () => void;
  onCreate: (uuid: string) => void;
  parentUuid: string;
};

function CreateModalDialog(props: CreateModalDialogProps) {
  const { module_id } = useParams();
  const [modalName, setModalName] = useState('');
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState<keyof typeof Template>();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!module_id) return;

    if (data.reservedWords.includes(modalName.toLowerCase())) {
      showErrorPopup(t('designer.modal.errorReservedWord'));
      return;
    }

    await ModalService.createModal(
      module_id,
      modalName,
      selectedTemplate ?? '',
      props.parentUuid
    ).then((Modal) => {
      props.onCreate(Modal.uuid);
      onClose();
    });
  };

  const onClose = () => {
    props.onClose();
    setModalName('');
    setSelectedTemplate(undefined);
  };

  const handleTemplateSelectOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'default') {
      setSelectedTemplate(undefined);
    } else {
      setSelectedTemplate(e.target.value as keyof typeof Template);
    }
  };

  const t_TEMPLATE_SELECT = t('designer.page.TemplateSelect');

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  return (
    <>
      {alertMessage && (
        <Modal
          show={alertMessage ? true : false}
          style={{ background: 'transparent', width: 'auto' }}
          centered={false}
        >
          <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
        </Modal>
      )}
      <Modal centered show={props.show} onHide={onClose}>
        <form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t('designer.modal.CreateModal')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form.Group className="mb-3">
                <Form.Label>{t('Name')}</Form.Label>
                <Form.Control
                  type="text"
                  id="formName"
                  onChange={(e) => setModalName(e.target.value)}
                  value={modalName}
                  required={!selectedTemplate}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t('designer.page.TemplateSelect')}</Form.Label>
                <Form.Select
                  aria-label={t_TEMPLATE_SELECT}
                  onChange={handleTemplateSelectOnChange}
                  value={selectedTemplate ?? 'default'}
                  required
                >
                  <option value="default">None</option>
                  {Object.keys(Template).map((template) => {
                    return (
                      <option key={template} value={template}>
                        {Template[template as keyof typeof Template]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button id="saveButton" type="submit">
              {t('designer.modal.CreateModal')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default CreateModalDialog;
