import React, { ChangeEvent, useEffect } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultInput, DefaultLabel } from '../styles';

function TextFormatControl(props: ControlProps) {
  const [val, setVal] = React.useState<string>('');

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  const handleChange = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
    if (props.onChange) props.onChange(e.target.value, props.id);
  }, []);

  return (
    <div className="mb-3">
      <DefaultLabel className={`${styles.defaultLabel} form-label`}>{props.label}</DefaultLabel>
      <DefaultInput
        id={`'input'${val}`.replace(/[^a-z0-9_.]/g, '')}
        type="text"
        className={`${styles.defaultInput} form-control form-control-sm`}
        value={val ?? ''}
        onChange={handleChange}
      />
    </div>
  );
}

export default TextFormatControl;
