import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Button, Container, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { AppRoleService } from 'modules/logic_builder/services';
import { ExoRole } from 'modules/logic_builder/types';

import styles from './styles.module.css';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';
import ErrorIcon from 'web_ui/workboard/sidebar/controls/components/ErrorIcon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { BootstrapColors } from 'web_ui/workboard/sidebar/controls/DataTableSource/types';
import Icon from 'web_ui/icon';
import Confirmation from 'web_ui/confirmation';

const emptyNewRole: ExoRole = {
  name: ''
};

type CreateRoleModalProps = {
  showModal: boolean;
  onClose: () => void;
  editRole?: boolean;
  role?: ExoRole;
  roles: ExoRole[];
};

function RoleModal(props: CreateRoleModalProps) {
  const [role, setRole] = useState<ExoRole>(props.role !== undefined ? props.role : emptyNewRole);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const { app_id } = useParams();

  const { t } = useTranslation();
  const title = props.editRole ? t('appResume.roles.EditRole') : t('appResume.roles.CreateRole');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const showPopup = (message: string, variant: PopupAlertVariant) => {
    setAlertVariant(variant);
    setAlertMessage(message);
  };

  const hidePopup = () => {
    setAlertMessage('');
  };

  useEffect(() => {
    if (props.editRole && props.role !== undefined) {
      setRole(props.role);
    }
    if (!props.editRole) {
      setRole(emptyNewRole);
    }
  }, [props]);

  const onHide = () => {
    setRole(emptyNewRole);
    props.onClose();
  };

  const validateRoleName = (value: string) => {
    if (!value || value.trim().length === 0) {
      setIsInvalid(true);
      return false;
    }

    const nameExists = props.roles.some(
      (persistedRole) =>
        persistedRole.name.toUpperCase() === value.toUpperCase() && persistedRole.id !== role.id
    );

    if (nameExists) {
      setIsInvalid(true);
      showPopup('appResume.roles.NameAlreadyExists', 'danger');
      return false;
    }

    setIsInvalid(false);
    return true;
  };

  const handleSubmitRole = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateRoleName(role.name)) return;

    if (props.editRole) {
      await updateRole();
    } else {
      await createRole();
    }
  };

  const createRole = async () => {
    try {
      if (app_id) {
        setLoading(true);
        await AppRoleService.createRole(app_id, role);
      }
      props.onClose();
      showPopup('appResume.roles.SaveSuccessful', 'success');
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateRole = async () => {
    try {
      if (role.id) {
        setLoading(true);
        await AppRoleService.updateRole(role.id, role);
      }
      props.onClose();
      showPopup('appResume.roles.SaveSuccessful', 'success');
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const deleteRole = async () => {
    try {
      if (role.id) {
        setLoading(true);
        await AppRoleService.deleteRole(role.id);
      }
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setLoading(false);
      setShowConfirmationDialog(false);
      props.onClose();
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRole({ ...role, name: e.target.value });
    validateRoleName(e.target.value);
  };

  return (
    <>
      <Modal show={props.showModal} onHide={onHide} size={'lg'} centered backdrop={'static'}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={handleSubmitRole}>
              <Form.Group className={'mb-3'}>
                <Form.Label>{t('appResume.roles.RoleName')}</Form.Label>
                <Form.Control
                  type="text"
                  id="formName"
                  onChange={handleNameChange}
                  value={role.name}
                  isInvalid={isInvalid}
                />
              </Form.Group>
              <div className={`${styles.containerButton}`}>
                {props.editRole && (
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('appResume.roles.DependencyConstraint') ||
                        'appResume.roles.DependencyConstraint',
                      description: '',
                      note: [],
                      variant: BootstrapColors.danger
                    }}
                    placement="top"
                    hide={!role.hasDependency}
                  >
                    <Button
                      className="me-2"
                      id="deleteButton"
                      type="button"
                      variant="danger"
                      disabled={isLoading || role.hasDependency}
                      onClick={() => setShowConfirmationDialog(true)}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className={
                          isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`
                        }
                      />
                      {t('Delete')}
                    </Button>
                  </HelpPopover>
                )}
                <Button id="saveButton" type="submit" disabled={isLoading || isInvalid}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={
                      isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`
                    }
                  />
                  {t('Save')}
                </Button>
              </div>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('organizations.roles.DeleteProfile')} (${role.name})`}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={deleteRole}
        onClose={() => setShowConfirmationDialog(false)}
      />
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hidePopup} variant={alertVariant} />
      )}
      <Confirmation
        show={showConfirmationDialog}
        message={t('appResume.roles.ConfirmDelete')}
        showItemNameInput={true}
        itemName={role.name}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={() => deleteRole()}
        onClose={props.onClose}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
        isLoading={isLoading}
      />
    </>
  );
}

export default RoleModal;
