import React, { forwardRef, memo } from 'react';
import { ComponentUUID, ViewUUID } from '../../../types';
import Card from 'react-bootstrap/Card';
import ComponentsRenderer from '../components_renderer';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { PositionProperty } from 'csstype';
import { convertingCSS } from 'utils/utils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';

export type CardData = {
  title: string;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  header?: boolean;
  tooltip?: string;
  translateKey?: string;
  hasTranslation?: string;
  visible?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type CardStyles = {
  themeColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  staticColor?: string;
  color?: string;
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  borderColor?: string;
  boxshadow?: string;
  classes?: string[];
};

export type CardProps = {
  viewUUID: ViewUUID;
  uuid: ComponentUUID;
  data: CardData;
  styles: CardStyles;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function Cards(props: CardProps, ref: React.Ref<any>) {
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.color,
    fontSize: `${props.styles.fontSize}px`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    backgroundColor: `${props.styles.staticColor}`,
    position: 'relative' as PositionProperty, // Used on container tags to keep select tools available
    borderColor:
      props.styles.borderColor &&
      convertingCSS(props.styles.borderColor, theme.colors[props.styles.borderColor]),
    boxShadow: props.styles.boxshadow,
    overflow: props.styles.boxshadow ? 'visible' : undefined
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <div className={`${classes}`}>
      <Card
        bg={props.styles.themeColor}
        text={props.styles.themeColor === 'light' ? 'dark' : 'white'}
        style={{ ...style, ...parseCustomCss(props.styles.css) }}
        ref={ref}
        title={props.data.tooltip}
      >
        {props.data.header && (
          <Card.Header>
            {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.title}
          </Card.Header>
        )}
        <Card.Body>
          <ComponentsRenderer
            viewUUID={props.viewUUID}
            parentUUID={props.uuid}
            custom_uuid={props.custom_uuid}
            new_custom_uuid={props.new_custom_uuid}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default memo(forwardRef(Cards));
export * from './template';
export * from './manifest';
