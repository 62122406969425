import { ContainerData, ContainerStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const CONTAINER_TEMPLATE: LayoutComponent<ContainerData, ContainerStyles> = {
  uuid: 'empty',
  type: 'CONTAINER',
  data: {
    width: 'calc(100% - 40px)',
    widthUnit: '',
    maxWidth: 'none',
    minWidth: 'none',
    height: 'fit-content',
    heightUnit: 'none',
    maxHeight: 'none',
    minHeight: '100',
    minHeightUnit: 'px',
    overflowx: 'auto',
    overflowy: 'auto',
    flexDirection: 'column',
    optionSizeWidth: 'full',
    optionSizeHeight: 'auto'
  },
  styles: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    borderTop: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 12,
    paddingRight: 12,
    css: ''
  }
};
