import React from 'react';
import { ACCESS_LEVELS, AccessLevel, EndpointExtended } from 'modules/logic_builder/types';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { ExoRole } from 'modules/logic_builder/types';
import { AppRoleService } from 'modules/logic_builder/services';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

type SecurityTabProps = {
  endpointExtended: EndpointExtended;
  setEndpointExtended: (ee: EndpointExtended) => void;
};

export function SecurityTab(props: SecurityTabProps) {
  const { app_id } = useParams();
  const [roles, setRoles] = React.useState<ExoRole[]>([]);
  const [isNative, setIsNative] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    setIsNative(props.endpointExtended.native ?? false);
  });

  const getRoles = React.useCallback(async () => {
    if (!app_id) return;

    await AppRoleService.getRoles(app_id).then((roles) => {
      setRoles(roles);
    });
  }, [app_id]);

  React.useEffect(() => {
    getRoles();
  }, [getRoles]);

  const handleCheckboxOnClick = (e: React.ChangeEvent<HTMLInputElement>, role: ExoRole) => {
    const newRoles = props.endpointExtended.roles;
    if (e.target.checked && role.id) {
      newRoles.push(role.id);
    } else if (!e.target.checked && role.id) {
      const index = newRoles.indexOf(role.id);
      newRoles.splice(index, 1);
    }
    props.setEndpointExtended({ ...props.endpointExtended, roles: newRoles });
  };

  const handleCheckboxAllowAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setEndpointExtended({
      ...props.endpointExtended,
      allowAllRoles: e.target.checked,
      roles: roles
        .map((role) => role.id)
        .filter((role) => {
          return role != null;
        }) as number[]
    });
  };

  return (
    <>
      <Form.Group as={Row} className={styles.AccessSelectInput}>
        <Form.Label column sm={2}>
          {t('logicBuilder.Security')}
        </Form.Label>
        <Col sm={8}>
          <Form.Select
            id={props.endpointExtended.accessLevel}
            onChange={(e) =>
              props.setEndpointExtended({
                ...props.endpointExtended,
                accessLevel: e.target.value as AccessLevel
              })
            }
            value={props.endpointExtended.accessLevel}
            disabled={isNative}
          >
            {/* Make this option disappear after user selects something. */}
            {!props.endpointExtended.accessLevel && <option>Select access type</option>}
            {Object.keys(ACCESS_LEVELS).map((security) => {
              return (
                <option key={security} value={security}>
                  {ACCESS_LEVELS[security as AccessLevel]}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Form.Group>
      {props.endpointExtended.accessLevel === 'PROTECTED' && (
        <>
          <div className={`${styles.SecurityTitle} mt-3`}>{t('appResume.roles.Roles')}</div>
          <div className={styles.SecurityCheckboxes}>
            {roles.map((role) => {
              return (
                <Form.Check
                  inline
                  key={role.id}
                  label={role.name}
                  type="checkbox"
                  onChange={(e) => handleCheckboxOnClick(e, role)}
                  id={role.name}
                  name={role.name}
                  checked={role.id ? props.endpointExtended.roles.includes(role.id) : false}
                  disabled={props.endpointExtended.allowAllRoles}
                />
              );
            })}
          </div>
          <div className={styles.SecurityCheckboxes}>
            <Form.Check
              inline
              label={t('automation.step4.allRoles')}
              type="checkbox"
              onChange={handleCheckboxAllowAll}
              id="allowAllRoles"
              checked={props.endpointExtended.allowAllRoles}
            />
          </div>
        </>
      )}
    </>
  );
}
