import React from 'react';
import styles from './styles.module.css';
import { MoldureProps } from '../../../page_view';

function DesktopMoldure(props: MoldureProps) {
  return (
    <>
      <div
        id={props.route}
        style={{
          height: '100%'
        }}
      >
        <div className={styles.browserControls} onClick={props.unselectComponent}>
          <div className={styles.windowControls}>
            <span className={`${styles.windowControls} ${styles.close}`} />
            <span className={`${styles.windowControls} ${styles.minimize}`} />
            <span className={`${styles.windowControls} ${styles.maximise}`} />
          </div>
          <input
            id="formRouteDesktop"
            className={styles.urlBar}
            type="text"
            value={props.rootpage ? '/' : props.route}
            onChange={(e) => props.updateUrlBar(e.target.value)}
          />
        </div>
        {props.children}
      </div>
    </>
  );
}

export default DesktopMoldure;
