import { useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../../../store';
import { useEffect, useState } from 'react';
import { Relationship } from '../../../../../../types';

type Direction = 'to' | 'from';

export default function useSetRelationshipTableName(
  relationship: Relationship,
  direction: Direction
) {
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const [tableName, setTableName] = useState('');

  useEffect(() => {
    setRelationshipTableName(relationship[direction]);
  }, [relationship, direction]);

  const setRelationshipTableName = (relationshipID: string) => {
    tables[relationshipID]
      ? setTableName(tables[relationshipID]?.content?.data?.name)
      : setTableName(relationship?.info?.tableName ?? '');
  };

  return tableName;
}
