import React, { forwardRef, memo } from 'react';
import { ComponentUUID } from '../../../types';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';

export type RadioData = {
  label?: string;
  options?: string[];
  tooltip?: string;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  translateKey?: string;
  hasTranslation?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type RadioStyles = {
  color?: string;
  fontSize?: number;
  textAlign?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  classes?: string[];
};

export type RadioComponentProps = {
  uuid: ComponentUUID;
  data: RadioData;
  styles: RadioStyles;
};

function Radio(props: RadioComponentProps, ref: React.Ref<any>) {
  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.color,
    fontSize: `${props.styles.fontSize}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <div ref={ref} style={{ ...style, ...parseCustomCss(props.styles.css) }} className={classes}>
      {props.data.label && (
        <label className="form-label">
          {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.label}
        </label>
      )}
      {props.data.options?.map((option, index) => {
        return (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name={props.uuid}
              id={props.uuid}
              disabled={props.data.disabled}
              readOnly={props.data.readonly}
              required={props.data.required}
            />
            <label className="form-check-label">{option}</label>
          </div>
        );
      })}
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(Radio));
