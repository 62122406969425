import React from 'react';
import { Pagination as BSPagination } from 'react-bootstrap';

type PaginationMockProps = {
  pageable: boolean;
};

function PaginationMock({ pageable }: PaginationMockProps) {
  if (!pageable) return null;
  return (
    <BSPagination className="d-flex justify-content-center">
      <BSPagination.Prev />
      <BSPagination.Item active>{1}</BSPagination.Item>
      <BSPagination.Item>{2}</BSPagination.Item>
      <BSPagination.Item>{3}</BSPagination.Item>
      <BSPagination.Ellipsis />
      <BSPagination.Item>{20}</BSPagination.Item>
      <BSPagination.Next />
    </BSPagination>
  );
}

export default PaginationMock;
