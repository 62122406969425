import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FunctionType, Service } from 'modules/logic_builder/types';
import { DropdownToggle } from 'modules/designer/components/navbar/components/navbar_dropdowns/DropdownToggle';
import { DropdownMenu } from 'modules/designer/components/navbar/components/navbar_dropdowns/DropdownMenu';
import { useParams } from 'react-router-dom';
import { DashboardService } from 'modules/dashboard/services';
import { ModuleInfo } from 'modules/dashboard/types';
import styles from './styles.module.css';

type FunctionListDropdownProps = {
  functionlist: FunctionType[] | undefined;
  selectedFunctionId: string | undefined;
  handleSelectModule: (functionId: string) => void;
  services: {
    [key: string]: Service;
  };
};

export function FunctionListDropdown(props: FunctionListDropdownProps) {
  const { t } = useTranslation();
  const [currentModule, setCurrentModule] = useState<ModuleInfo>();
  const { app_id, module_id } = useParams();

  const selectedFunction = useMemo(() => {
    return props.functionlist?.find((fn) => fn.uuid === props.selectedFunctionId);
  }, [props.functionlist, props.selectedFunctionId]);

  const fetchModules = useCallback(async () => {
    if (app_id) {
      const modules = await DashboardService.getModulesByApp(app_id);
      if (module_id) {
        // set the selectedModule (default value shown in the dropdown list)
        const selectedModule = modules.find((x) => x.id === module_id);
        setCurrentModule(selectedModule);
      }
    }
  }, [app_id, module_id]);

  React.useEffect(() => {
    try {
      fetchModules();
    } catch (error) {
      console.error(error);
    }
  }, [fetchModules]);

  return (
    <Dropdown className="p-2 px-3 border border-body rounded">
      <Dropdown.Toggle as={DropdownToggle} id="dropdown-toggle" handleUpdateList={() => {}}>
        <p
          id={props.selectedFunctionId}
          className={'d-flex w-100 justify-content-between text-truncate'}
          style={{ padding: 0, margin: 0, maxWidth: '100%' }}
        >
          <span className={styles.dropdownItem} style={{ maxWidth: '75%' }}>
            {selectedFunction?.name}
          </span>

          {selectedFunction && (
            <span className="text-body-tertiary ms-1" style={{ fontSize: '0.875rem' }}>
              {selectedFunction?.serviceName
                ? '(' + selectedFunction?.serviceName + ')'
                : '(' + props.services[selectedFunction.serviceUuid].name + ')'}
            </span>
          )}
        </p>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="w-100"
        as={DropdownMenu}
        filterPlaceholder={t('logicBuilder.FilterFunctions')}
      >
        {props.functionlist?.map((fn) => (
          <Dropdown.Item
            key={fn.uuid}
            eventKey={fn.uuid}
            onClick={() => fn.uuid && props.handleSelectModule(fn?.uuid)}
            className={`${
              props.selectedFunctionId === fn.uuid && 'text-body-emphasis bg-body-secondary'
            }`}
          >
            {/* This is used to filter functions,
                if you want filter to keep working this hidden <span>
                should stay as the first children of <Dropdown.Item> */}
            <span id={fn.name} className={'d-none'}>
              {fn.name}
            </span>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-item-end">
                <span className={styles.dropdownItem}>{fn.name}</span>
                <span className="ms-1 text-body-tertiary" style={{ fontSize: '0.875rem' }}>
                  ({fn.serviceName ? fn.serviceName : props.services[fn.serviceUuid].name})
                </span>
              </div>
              <div className="text-body-secondary" style={{ fontSize: '0.875rem' }}>
                /{fn.moduleName ? fn.moduleName : currentModule?.name}
              </div>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
