import { FunctionEditorState } from '../../types/function_editor_state';
import { DeleteBlockAction } from '../actions/delete_block';

export function doDeleteBlockAction(
  state: FunctionEditorState,
  action: DeleteBlockAction
): FunctionEditorState {
  const { uuid } = action.payload;

  const a = state.actions[uuid];
  if (a?.type === 'BEGIN_CYCLE') delete state.variables[a.data.uuid];

  return state;
}
