import React, { useEffect } from 'react';
import { ControlProps } from '..';
import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeAllComponentsProperties } from 'modules/designer/studio/store/actions/components';
import { InterfaceStudioState } from 'modules/designer/studio/store';

const BorderRadiusControl = (props: ControlProps) => {
  const refRadioOne = React.useRef<HTMLDivElement>(null);
  const refRadioTwo = React.useRef<HTMLDivElement>(null);
  const refRadioThree = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );

  useEffect(() => {
    // cleaning
    refRadioOne.current?.classList.remove(styles.containerBtnClicked);
    refRadioTwo.current?.classList.remove(styles.containerBtnClicked);
    refRadioThree.current?.classList.remove(styles.containerBtnClicked);
    selectingCorrectOption();
  }, [props.value]);

  const selectingCorrectOption = () => {
    if (props.value && props.value.borderRadius >= 0)
      switch (props.value.borderRadius) {
        case 0:
          refRadioOne.current?.classList.add(styles.containerBtnClicked);
          break;
        case 10:
          refRadioTwo.current?.classList.add(styles.containerBtnClicked);
          break;
        case 100:
          refRadioThree.current?.classList.add(styles.containerBtnClicked);
          break;
      }
  };

  const pressingRadioOne = () => {
    refRadioTwo.current?.classList.remove(styles.containerBtnClicked);
    refRadioThree.current?.classList.remove(styles.containerBtnClicked);

    refRadioOne.current?.classList.add(styles.containerBtnClicked);
    if (!selectedComponent) return;
    // send data

    dispatch(
      changeAllComponentsProperties({
        data: [],
        style: [
          { uuid: selectedComponent, key: 'borderTop', value: 1 },
          { uuid: selectedComponent, key: 'borderLeft', value: 1 },
          { uuid: selectedComponent, key: 'borderRight', value: 1 },
          { uuid: selectedComponent, key: 'borderBottom', value: 1 },
          { uuid: selectedComponent, key: 'borderRadius', value: 0 }
        ]
      })
    );
  };

  const pressingRadioTwo = () => {
    refRadioOne.current?.classList.remove(styles.containerBtnClicked);
    refRadioThree.current?.classList.remove(styles.containerBtnClicked);

    refRadioTwo.current?.classList.add(styles.containerBtnClicked);

    if (!selectedComponent) return;
    // send data

    dispatch(
      changeAllComponentsProperties({
        data: [],
        style: [
          { uuid: selectedComponent, key: 'borderTop', value: 1 },
          { uuid: selectedComponent, key: 'borderLeft', value: 1 },
          { uuid: selectedComponent, key: 'borderRight', value: 1 },
          { uuid: selectedComponent, key: 'borderBottom', value: 1 },
          { uuid: selectedComponent, key: 'borderRadius', value: 10 }
        ]
      })
    );
  };

  const pressingRadioThree = () => {
    refRadioOne.current?.classList.remove(styles.containerBtnClicked);
    refRadioTwo.current?.classList.remove(styles.containerBtnClicked);

    refRadioThree.current?.classList.add(styles.containerBtnClicked);

    if (!selectedComponent) return;
    // send data

    dispatch(
      changeAllComponentsProperties({
        data: [],
        style: [
          { uuid: selectedComponent, key: 'borderTop', value: 1 },
          { uuid: selectedComponent, key: 'borderLeft', value: 1 },
          { uuid: selectedComponent, key: 'borderRight', value: 1 },
          { uuid: selectedComponent, key: 'borderBottom', value: 1 },
          { uuid: selectedComponent, key: 'borderRadius', value: 100 }
        ]
      })
    );
  };

  return (
    <div className={styles.mainContainer}>
      <span>{props.label}</span>
      <div className={styles.inputsContainer}>
        <div
          className={styles.containerBtn}
          ref={refRadioOne}
          onClick={() => {
            pressingRadioOne();
          }}
        >
          <span className={styles.iconOne} />
        </div>
        <span style={{ margin: 0, padding: 0, color: 'black' }}>|</span>
        <div
          className={styles.containerBtn}
          ref={refRadioTwo}
          onClick={() => {
            pressingRadioTwo();
          }}
        >
          <span className={styles.iconTwo} />
        </div>
        <span style={{ margin: 0, padding: 0, color: 'black' }}>|</span>
        <div
          className={styles.containerBtn}
          ref={refRadioThree}
          onClick={() => {
            pressingRadioThree();
          }}
        >
          <span className={styles.iconThree} />
        </div>
      </div>
    </div>
  );
};

export default BorderRadiusControl;
