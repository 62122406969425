export type ModuleDependencyInfo = {
  id: string;
  name: string;
};

export type ModuleInfo = {
  id: string;
  appId: string;
  name: string;
  description: string;
  dependencies: ModuleDependencyInfo[];
  isActive: boolean;
  isAssets: boolean;
  isAuth: boolean;
  isSql?: boolean;
  dependedModules?: ModuleDependencyInfo[];
};

export type ModuleQuery = Omit<ModuleInfo, 'id'>;

export type ModuleSettings = {
  key: string;
  value: string;
};

export enum ModuleSqlOptions {
  JDBC,
  JPA,
  APP
}
