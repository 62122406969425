import * as React from 'react';
import { FunctionActionManifest } from 'web_ui/function_editor/store/types/manifestsAndInputs';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { t } from 'i18next';
import CommentIcon from './comment_icon';
import { ACTION_INPUTS_TYPES } from 'web_ui/function_editor/action_inputs';

export const COMMENT_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.COMMENT,
  name: t('Comment'),
  description: 'comment for code',
  color: 'danger',
  category: FRONTEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.FUNCTION,
  icon: <CommentIcon />,
  inlineInputs: [],
  inputs: [
    {
      type: ACTION_INPUTS_TYPES.TEXT_AREA,
      label: t('Comments'),
      key: 'comment'
    }
  ]
};
