import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ApiAppInfo } from '../../project/types';
import { ProjectsService } from 'modules/project/services';
import { useParams } from 'react-router-dom';
import { AppContext } from './app_context';

type SessionWrapperProps = {
  children: ReactNode;
};

export function AppContextWrapper(props: SessionWrapperProps) {
  const { app_id } = useParams();
  const [projectInformation, setProjectInformation] = useState<ApiAppInfo>();

  const updateProjectInformation = useCallback(
    async (appId: string): Promise<ApiAppInfo | null> => {
      const project = await ProjectsService.getProjectById(appId);
      setProjectInformation(project);
      return project;
    },
    []
  );

  useEffect(() => {
    if (!app_id) {
      setProjectInformation(undefined);
    } else {
      updateProjectInformation(app_id);
    }
  }, [app_id, updateProjectInformation]);

  const setCurrentProjectInformation = useCallback((appInfo: ApiAppInfo) => {
    setProjectInformation(appInfo);
  }, []);

  return (
    <AppContext.Provider
      value={{
        projectInformation,
        updateProjectInformation,
        setCurrentProjectInformation
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}
