import React, { useEffect, useState } from 'react';
import { Button, Collapse, Form } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { useParams } from 'react-router-dom';
import { CustomComponentService, TemplatesService } from 'modules/designer/services';
import { useTranslation } from 'react-i18next';
import { CreateCustomComponentDialog } from './create_custom_component_dialog';
import { CustomComponent, Template } from 'modules/designer/types';
import { COMPONENTS_MANIFEST, COMPONENT_TYPES } from '../../exocode_components';
import styles from './styles.module.css';
import { VIEWS_TYPES } from '../../frames';
import ComponentCustom from './component_custom';
import ComponentItem from '../components_toolbar/component_item';
import ComponentItemDragger from '../components_toolbar/componentItemDragger';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

export function CustomComponentsToolbar() {
  const { app_id, module_id, custom_component_id } = useParams();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [customComponents, setCustomComponentsList] = useState<CustomComponent[]>([]);
  const [openCustoms, setOpenCustoms] = useState(true);
  const [showCreateCustomComponentDialog, setShowCreateCustomComponentDialog] = useState(false);
  const [customComponentTemplates, setCustomComponentTemplates] = useState<Template[]>([]);

  const getCustomComponents = React.useCallback(async () => {
    try {
      if (app_id) {
        const customComps = await CustomComponentService.getCustomComponents(app_id);
        setCustomComponentsList(customComps);
      }
    } catch (error) {
      console.log(error);
    }
  }, [app_id]);

  useEffect(() => {
    getCustomComponents();
  }, [getCustomComponents]);

  const CreateCustomComponentsByTemplates = React.useCallback(async () => {
    try {
      if (app_id) {
        try {
          const custons = await TemplatesService.getTemplates(
            'createPage',
            VIEWS_TYPES.CUSTOMCOMPONENTS,
            app_id
          );
          setCustomComponentTemplates(custons);
          getCustomComponents();
        } catch (e) {
          console.log(e);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [app_id, getCustomComponents, setCustomComponentTemplates]);

  useEffect(() => {
    CreateCustomComponentsByTemplates();
  }, [CreateCustomComponentsByTemplates]);

  useEffect(() => {
    getCustomComponents();
  }, [getCustomComponents]);

  function handleShowCreateCustomComponentDialog() {
    setShowCreateCustomComponentDialog(true);
  }

  const handleContextMenu = (event: { preventDefault: () => void }) => {
    event.preventDefault(); // Bloqueia o menu de contexto padrão do navegador
  };

  return (
    <>
      <div className="input-group input-group-sm p-2 pt-0">
        <Form.Group className="position-relative w-100">
          <Form.Control
            value={searchText}
            id="searchField"
            className="ps-4"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search..."
          />
          {searchText.length === 0 ? (
            <i
              className={`${styles.searchIcon} position-absolute fa-solid fa-magnifying-glass top-12 right-12`}
            ></i>
          ) : (
            <i
              role="button"
              onClick={() => setSearchText('')}
              className={`${styles.searchIcon} position-absolute fa-solid fa-xmark top-12 right-12 `}
            ></i>
          )}
        </Form.Group>
      </div>

      <div className={`mt-2 p-2 pt-0 ${styles.viewHeader}`}>
        <div
          className={`${styles.viewHeaderCollapse}`}
          onClick={() => setOpenCustoms(!openCustoms)}
          aria-controls="collapse-custom-comps"
          aria-expanded={openCustoms}
        >
          {openCustoms ? <Icon iconName="chevron-down" /> : <Icon iconName="chevron-right" />}
          <div className={styles.viewHeaderName}>{t('designer.Blocks')}</div>
        </div>
        <HelpPopover
          placement={'top'}
          helpBoxProps={{
            title: t('NewBlock')!
          }}
        >
          <Button
            id={'createBlockButton'}
            style={{ marginRight: '6px' }}
            variant="secondary"
            className={styles.viewHeaderNewView}
            onClick={handleShowCreateCustomComponentDialog}
          >
            <Icon iconName="plus" />
          </Button>
        </HelpPopover>
        <HelpPopover
          placement={'top'}
          helpBoxProps={{
            title: t('update')!
          }}
        >
          <div
            id={'updateIcon'}
            className={styles.viewHeaderNewView}
            onClick={() => getCustomComponents()}
          >
            <Icon iconName="arrows-rotate" />
          </div>
        </HelpPopover>
      </div>
      <Collapse in={openCustoms}>
        <div className="list-group p-2 pe-0" onContextMenu={handleContextMenu}>
          <div className="row ps-2 pe-3">
            {customComponentTemplates
              .filter((component) => {
                return (
                  !searchText ||
                  component.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                );
              })
              .map((component) => (
                <div
                  key={component.uuid}
                  className="col-4"
                  style={{ padding: '0.1rem', paddingBottom: '0.5rem' }}
                >
                  <ComponentItemDragger
                    componentId={component.uuid}
                    componentType={COMPONENTS_MANIFEST[COMPONENT_TYPES.CUSTOM].type}
                  >
                    <ComponentCustom
                      appId={app_id}
                      moduleId={module_id}
                      customId={component.uuid}
                      isNative
                      fetchCustomComponents={CreateCustomComponentsByTemplates}
                    >
                      <ComponentItem
                        name={component.name}
                        type={COMPONENTS_MANIFEST[COMPONENT_TYPES.CUSTOM].type}
                        description={COMPONENTS_MANIFEST[COMPONENT_TYPES.CUSTOM].description}
                        icon={'cubes'}
                      />
                    </ComponentCustom>
                  </ComponentItemDragger>
                </div>
              ))}
            <div className="p-0 border-top pt-3 mt-2">
              {customComponents
                .filter((item) => item.uuid !== custom_component_id)
                .filter((component) => {
                  return (
                    !searchText ||
                    component.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                  );
                })
                .map((component) => (
                  <div
                    key={component.uuid}
                    className="col-4"
                    style={{ padding: '0.1rem', paddingBottom: '0.5rem' }}
                  >
                    <ComponentItemDragger
                      componentId={component.uuid}
                      componentType={COMPONENTS_MANIFEST[COMPONENT_TYPES.CUSTOM].type}
                    >
                      <ComponentCustom
                        appId={app_id}
                        moduleId={module_id}
                        customId={component.uuid}
                        fetchCustomComponents={CreateCustomComponentsByTemplates}
                      >
                        <ComponentItem
                          name={component.name}
                          type={COMPONENTS_MANIFEST[COMPONENT_TYPES.CUSTOM].type}
                          description={COMPONENTS_MANIFEST[COMPONENT_TYPES.CUSTOM].description}
                          icon={'cubes'}
                        />
                      </ComponentCustom>
                    </ComponentItemDragger>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Collapse>
      {app_id && (
        <CreateCustomComponentDialog
          show={showCreateCustomComponentDialog}
          onClose={() => {
            setShowCreateCustomComponentDialog(false);
          }}
          appId={app_id}
          fetchCustomComponents={getCustomComponents}
        />
      )}
    </>
  );
}
