import * as React from 'react';

function CommentIcon() {
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <i className="fas fa-comment text-secondary-emphasis" />
    </span>
  );
}

export default CommentIcon;
