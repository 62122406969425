import { IntegrationsRepo } from '../repos';

export default class Integrations {
  async getAvailableRepositories(appId: string) {
    return IntegrationsRepo.getAvailableRepositories(appId);
  }

  async getAccountRepositories(accountId: number, isOrg: boolean) {
    return IntegrationsRepo.getAccountRepositories(accountId, isOrg);
  }

  async attatchRepositoryToApp(appId: string, repo: string, createPR: boolean, autoMerge: boolean) {
    return IntegrationsRepo.attatchRepositoryToApp(appId, repo, createPR, autoMerge);
  }

  async detatchRepositoryFromApp(appId: string) {
    return IntegrationsRepo.detatchRepositoryFromApp(appId);
  }

  async uninstallFromUser() {
    return IntegrationsRepo.uninstallFromUser();
  }

  async uninstallFromOrg(orgId: number) {
    return IntegrationsRepo.uninstallFromOrg(orgId);
  }
}
