import React from 'react';
import styles from './style.module.css';

export type GridBoxProps = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export const GridBox = (props: GridBoxProps) => {
  return (
    <div
      className={`${styles.selectionBox} ${styles.gridBox}`}
      style={{
        width: props.width,
        height: props.height,
        left: props.x,
        top: props.y
      }}
    />
  );
};
