import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import produce from 'immer';
import SessionContext from 'modules/auth/store';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import { ApiAppInfo } from 'modules/project/types';
import AppSettingsModal from 'routes/dashboard/components/app_settings_modal';
import { textLastModification } from 'utils/dateUtils';
import ApplicationAvatar from 'web_ui/application_avatar';
import Icon from 'web_ui/icon';

import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import HelpIcon from 'web_ui/workboard/sidebar/controls/components/HelpIcon';

interface Props {
  apiAppInfo: ApiAppInfo;
  setApiAppInfo: React.Dispatch<React.SetStateAction<ApiAppInfo[]>>;
  resume?: boolean;
  onAfterDeleteApp: () => void;
  isDisabled: boolean;
  reload: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProjectRow(props: Props) {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const [width, setWidth] = React.useState(window.innerWidth);

  function updateAppInfo(appinfo: ApiAppInfo) {
    props.setApiAppInfo((currentVal) => {
      const nextState = produce(currentVal, (draft) => {
        // get the id inside the array to modify
        const id: number = currentVal.findIndex((item, index) => item.id === appinfo.id);

        // modify array in specific id that I modify in modal and set the state
        draft[id] = appinfo;
      });
      return [...nextState];
    });
  }

  const handleCloseModal = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const navigateToDashboard = () => {
    if (props.isDisabled) {
      return;
    }
    navigate(`/app/${props.apiAppInfo.id}/dashboard`);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (width <= 550) {
    return (
      <>
        <ListGroup.Item
          action
          className={`border-0 ${props.isDisabled && styles.disabledRow}`}
          variant="body"
          id={props.apiAppInfo.name}
        >
          <Row
            as="a"
            onClick={navigateToDashboard}
            className={'nav-link d-flex align-items-center p-2 ' + styles.containerMain}
          >
            <div className={styles.firstContainerMobile}>
              <div style={{ width: '33%' }}>
                <ApplicationAvatar
                  name={props.apiAppInfo.name}
                  width="3.5rem"
                  borderRadius="10px"
                  fontSize="28px"
                />
              </div>
              <div style={{ width: '33%' }}>
                <p className="m-0">{props.apiAppInfo.name}</p>
                {!props.resume && (
                  <Col>
                    <p className="m-0">
                      {t('dashboard.projectRow.version')} {props.apiAppInfo.version}
                    </p>
                  </Col>
                )}
              </div>
              <div style={{ width: '33%' }}>
                {props.apiAppInfo.is_owner_org ? (
                  <Col className={styles.containerIconMobile}>
                    <HelpPopover
                      helpBoxProps={{
                        title: t('dashboard.projectRow.orgInfo') ?? 'dashboard.projectRow.orgInfo'
                      }}
                      placement="top"
                    >
                      <div className={styles.orgIcon}>
                        <Icon iconName="building" />
                      </div>
                    </HelpPopover>
                  </Col>
                ) : (
                  <Col className={styles.containerIconMobile}>
                    <HelpPopover
                      helpBoxProps={{
                        title:
                          t('dashboard.projectRow.projectInf') ?? 'dashboard.projectRow.projectInf'
                      }}
                      placement="top"
                    >
                      <div style={{ width: '10%' }} className={styles.hoverOpacity}>
                        <Icon iconName="user" />
                      </div>
                    </HelpPopover>
                  </Col>
                )}
              </div>
            </div>
            <div className={styles.secondContainerMobile}>
              {props.isDisabled && (
                <div
                  style={{
                    display: 'inline-block',
                    marginTop: '-15px'
                  }}
                >
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('dashboard.projectRow.PlanIssue') || 'dashboard.projectRow.PlanIssue',
                      description:
                        t('dashboard.projectRow.cantSelect') || 'dashboard.projectRow.cantSelect',
                      note: [t('dashboard.projectRow.currentPlans')]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </div>
              )}
              <p className="m-0">{textLastModification(props.apiAppInfo.changed_at, t, session)}</p>
              <Authorization
                context={ContextRole.APP}
                allowedAuthorities={[RoleAuthorities.MANAGE_APP]}
                contextUuid={props.apiAppInfo.id}
              >
                <HelpPopover
                  helpBoxProps={{
                    title: t('dashboard.projectRow.edit') ?? 'dashboard.projectRow.edit'
                  }}
                  placement="top"
                >
                  <Button
                    id={'editProjectHomepage'}
                    variant="body"
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      setShowModal(true);
                    }}
                    className={`${styles.iconButton} btn-body`}
                  >
                    <Icon iconName="pen-to-square" />
                  </Button>
                </HelpPopover>
              </Authorization>
            </div>
          </Row>
        </ListGroup.Item>
      </>
    );
  } else {
    return (
      <>
        <ListGroup.Item
          action
          className={`border-0 ${props.isDisabled && styles.disabledRow}`}
          variant="body"
          id={props.apiAppInfo.name}
        >
          <Row
            as="a"
            onClick={navigateToDashboard}
            className={'nav-link d-flex align-items-center p-2 ' + styles.containerMain}
          >
            <Col className="d-flex" xs="auto">
              <ApplicationAvatar
                name={props.apiAppInfo.name}
                width="3.5rem"
                borderRadius="10px"
                fontSize="28px"
              />
            </Col>
            <Col className={'text-body-secondary d-flex ps-0 ' + styles.infoCol}>
              <Col className="d-flex" /*xs={7} md*/ style={{ overflowWrap: 'anywhere' }}>
                <p className="m-0">{props.apiAppInfo.name}</p>
              </Col>

              <Col className="d-flex align-items-center" xs={{ order: '4' }} md={{ order: '3' }}>
                {!props.resume && (
                  <Col xs={4}>
                    <p className="m-0">
                      {t('dashboard.projectRow.version')} {props.apiAppInfo.version}
                    </p>
                  </Col>
                )}
                {props.apiAppInfo.is_owner_org ? (
                  <Col xs={3}>
                    <HelpPopover
                      helpBoxProps={{
                        title: t('dashboard.projectRow.orgInfo') ?? 'dashboard.projectRow.orgInfo'
                      }}
                      placement="top"
                    >
                      <div className={styles.orgIcon}>
                        <Icon iconName="building" />
                      </div>
                    </HelpPopover>
                  </Col>
                ) : (
                  <Col xs={3}>
                    <HelpPopover
                      helpBoxProps={{
                        title:
                          t('dashboard.projectRow.projectInf') ?? 'dashboard.projectRow.projectInf'
                      }}
                      placement="top"
                    >
                      <div style={{ width: '10%' }} className={styles.hoverOpacity}>
                        <Icon iconName="user" />
                      </div>
                    </HelpPopover>
                  </Col>
                )}
                <Col xs={6}>
                  <p className="m-0">
                    {textLastModification(props.apiAppInfo.changed_at, t, session)}
                  </p>
                </Col>
              </Col>
            </Col>
            <Col
              className="text-body-secondary d-flex justify-content-end"
              xs={{ span: 'auto', order: '3' }}
              md={{ span: 'auto', order: '4' }}
              style={{ marginLeft: 'auto', fontSize: '18px' }}
            >
              <Authorization
                context={ContextRole.APP}
                allowedAuthorities={[RoleAuthorities.MANAGE_APP]}
                contextUuid={props.apiAppInfo.id}
              >
                <HelpPopover
                  helpBoxProps={{
                    title: t('dashboard.projectRow.edit') ?? 'dashboard.projectRow.edit'
                  }}
                  placement="top"
                >
                  <Button
                    id={'editProjectHomepage'}
                    variant="body"
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      setShowModal(true);
                    }}
                    className={`${styles.iconButton} btn-body`}
                  >
                    <Icon iconName="pen-to-square" />
                  </Button>
                </HelpPopover>
              </Authorization>
              {props.isDisabled && (
                <div style={{ display: 'inline-block', marginTop: '-15px' }}>
                  <HelpPopover
                    helpBoxProps={{
                      title:
                        t('dashboard.projectRow.PlanIssue') || 'dashboard.projectRow.PlanIssue',
                      description:
                        t('dashboard.projectRow.cantSelect') || 'dashboard.projectRow.cantSelect',
                      note: [t('dashboard.projectRow.currentPlans')]
                    }}
                    placement="top"
                  >
                    <HelpIcon />
                  </HelpPopover>
                </div>
              )}
            </Col>
          </Row>
        </ListGroup.Item>

        <AppSettingsModal
          appinfo={props.apiAppInfo}
          onCloseRequest={handleCloseModal}
          showModal={showModal}
          updateAppInfo={updateAppInfo}
          onAfterDeleteApp={() => {
            props.onAfterDeleteApp();
            setShowModal(!showModal);
          }}
          usingAuth={true}
          reload={props.reload}
        />
      </>
    );
  }
}

export default ProjectRow;
