import { Argument } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { v4 as uuidv4 } from 'uuid';

export interface DataTypeInterface {
  type: string;
  objectUuid?: string;
  enumUuid?: string;
}

export interface AddListDataInterface {
  list: Argument;
  object: Argument;
}

export const AddListData: AddListDataInterface = {
  list: { type: TYPE_PICKER_TYPES.VAR, value: '' },
  object: { type: TYPE_PICKER_TYPES.FIXED, value: 'myValue' }
};

export interface RemoveListDataInterface {
  list: Argument;
  object: Argument;
}

export const RemoveListData: RemoveListDataInterface = {
  list: { type: TYPE_PICKER_TYPES.VAR, value: '' },
  object: { type: TYPE_PICKER_TYPES.FIXED, value: 'myValue' }
};

export interface ClearListDataInterface {
  list: Argument;
}

export const ClearListData: ClearListDataInterface = {
  list: { type: TYPE_PICKER_TYPES.VAR, value: '' }
};

export interface DeclareVariableDataInterface {
  uuid: string;
  name: string;
  description: string;
  list: boolean;
  dataType: DataTypeInterface;
  initialize: boolean;
}

export const DeclareVariableData: DeclareVariableDataInterface = {
  uuid: '',
  name: 'variable_',
  description: '',
  dataType: { type: 'ANY' },
  list: false,
  initialize: false
};

export interface SetVariableDataInterface {
  variable: Argument;
  value: Argument;
}

export const SetVariableData: SetVariableDataInterface = {
  variable: { type: TYPE_PICKER_TYPES.VAR, value: '' },
  value: { type: TYPE_PICKER_TYPES.FIXED, value: 'myValue' }
};

export interface BeginCycleDataInterface {
  list: Argument;
  uuid: string;
  name: string;
  dataType: DataTypeInterface;
  closeBlockActionUuid: string;
}

export const BeginCycleData: BeginCycleDataInterface = {
  uuid: uuidv4(),
  name: 'variable_',
  dataType: { type: 'STRING' },
  list: { type: TYPE_PICKER_TYPES.FIXED, value: 'myValue' },
  closeBlockActionUuid: ''
};

export interface EndCycleDataInterface {
  openBlockActionUuid: string;
}

export const EndCycleData: EndCycleDataInterface = {
  openBlockActionUuid: ''
};

export interface BeginIfDataInterface {
  if: string;
  then: {
    openBlockActionUuid: string;
    closeBlockActionUuid: string;
  };
  else: {
    openBlockActionUuid: string;
    closeBlockActionUuid: string;
  };
}

export const BeginIfData: BeginIfDataInterface = {
  if: '',
  then: {
    openBlockActionUuid: '',
    closeBlockActionUuid: ''
  },
  else: {
    openBlockActionUuid: '',
    closeBlockActionUuid: ''
  }
};

export interface EndIfDataInterface {
  openBlockActionUuid: string;
}

export const EndIfData: EndCycleDataInterface = {
  openBlockActionUuid: ''
};

export interface BeginElseDataInterface {
  closeBlockActionUuid: string;
}

export const BeginElseData: BeginElseDataInterface = {
  closeBlockActionUuid: ''
};

export interface EndElseDataInterface {
  openBlockActionUuid: string;
}

export const EndElseData: EndElseDataInterface = {
  openBlockActionUuid: ''
};

export interface RunFunctionDataInterface {
  function: {
    functionId: string;
    arguments?: { [key: string]: Argument };
  };
  returnVariableUuid: string;
}

export const RunFunctionData: RunFunctionDataInterface = {
  function: {
    functionId: '',
    arguments: {}
  },
  returnVariableUuid: ''
};

export interface CustomCode {
  code: string;
  imports?: string[];
}

export interface CustomCodeDataInterface {
  code: CustomCode;
}

export const CustomCodeData: CustomCodeDataInterface = {
  code: {
    code: '',
    imports: []
  }
};

export interface ReturnTypeDataInterface {
  uuid: string;
  returnType: 'VARIABLE';
}

export const ReturnTypeData: ReturnTypeDataInterface = {
  uuid: '',
  returnType: 'VARIABLE'
};

export interface RestDataInterface {
  endpoint: {
    endpoint: string;
    queryParams: { [key: string]: Argument };
    pathParams: { [key: string]: Argument };
    request: Argument;
    response: string;
  };
}

export const RestData: RestDataInterface = {
  endpoint: {
    endpoint: '',
    pathParams: {},
    queryParams: {},
    request: { type: TYPE_PICKER_TYPES.VAR, value: '' },
    response: ''
  }
};

export interface NavigateDataInterface {
  page: {
    uuid: string;
    params?: { [key: string]: Argument };
  };
  externalPath: string;
}

export const NavigateData: NavigateDataInterface = {
  page: {
    uuid: '',
    params: {}
  },
  externalPath: ''
};

export interface ToggleModalDataInterface {
  function: {
    functionId: string;
    arguments?: { [key: string]: Argument };
  };
  modal: {
    uuid: string;
    properties?: { [key: string]: Argument };
  };
  toggleVariable: string;
}

export const ToggleModalData: ToggleModalDataInterface = {
  function: {
    functionId: '',
    arguments: {}
  },
  modal: {
    uuid: '',
    properties: {}
  },
  toggleVariable: ''
};

export type EmptyDataInterface = Record<string, never>;

export const EmptyData: EmptyDataInterface = {};

export interface CommentDataInterface {
  comment: string;
}

export const CommentData: CommentDataInterface = {
  comment: ''
};
