import React from 'react';
import styles from './styles.module.css';
import useSession from 'hooks/useSession';

type FixedValueInputProps = {
  value: string;
  placeholder: string;
  handleChange: (text: string) => void;
};

function FixedValueInput({ value, placeholder, handleChange }: FixedValueInputProps) {
  const isDarkTheme = useSession()[0].preferences['exocode-theme'];

  return (
    <>
      <input
        value={value}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        className={`${
          isDarkTheme ? styles.InputDarkThemeVariables : styles.InputLightThemeVariables
        } ${styles.ActionInput}`}
        placeholder={placeholder}
        autoComplete={'off'}
      />
    </>
  );
}

export default FixedValueInput;
