import { Action } from 'redux';
import { FunctionsState } from '../index';
import { FrontendFunction } from '../../../types';

export const SET_FUNCTIONS = 'SET_FUNCTIONS';
export const ADD_FUNCTION = 'ADD_FUNCTION';
export const UPDATE_FUNCTION = 'UPDATE_FUNCTION';
export const DELETE_FUNCTION = 'DELETE_FUNCTION';

export interface SetFunctionsAction extends Action {
  type: 'SET_FUNCTIONS';
  payload: {
    functions: FunctionsState;
  };
}

export interface AddFunctionAction extends Action {
  type: 'ADD_FUNCTION';
  payload: FrontendFunction;
}

export interface UpdateFunctionAction extends Action {
  type: 'UPDATE_FUNCTION';
  payload: {
    function: FrontendFunction;
  };
}

export interface DeleteFunctionAction extends Action {
  type: 'DELETE_FUNCTION';
  payload: {
    function: string;
    view: string;
  };
}

export const setFunctions = (functions: FunctionsState): SetFunctionsAction => ({
  type: SET_FUNCTIONS,
  payload: { functions }
});

export const addFunction = (func: FrontendFunction): AddFunctionAction => ({
  type: ADD_FUNCTION,
  payload: { ...func }
});

export const updateFunction = (func: FrontendFunction): UpdateFunctionAction => ({
  type: UPDATE_FUNCTION,
  payload: { function: func }
});

export const deleteFunction = (viewId: string, functionId: string): DeleteFunctionAction => ({
  type: DELETE_FUNCTION,
  payload: { view: viewId, function: functionId }
});
