import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import styles from './style.module.css';
import ResizeHandle from './resize_handle';
import { COMPONENTS_MANIFEST } from '../../exocode_components';
import useWindowDimensions from 'modules/designer/hooks/window';
import { useCheckShowGrid } from 'modules/designer/hooks/useCheckIsGridColumn';

type SelectionBoxProps = {
  label?: string;
  border?: boolean;
  children: ReactNode;
  style: React.CSSProperties;
  componentUUID: string;
};

const SelectionBox = ({ label, border, children, style, componentUUID }: SelectionBoxProps) => {
  return (
    <div
      id={`${label?.toLowerCase()}Component`}
      className={`${styles.selectionBox} ${border ? styles.selectionBoxSolid : ''}`}
      style={style}
    >
      {children}
    </div>
  );
};

const OverlayBox = ({ style, showOverlayStripes }: any) => {
  return (
    <div
      className={`${styles.overlayBox} ${showOverlayStripes && styles.overlayStripes}`}
      style={style}
    />
  );
};

const MarginBox = ({ style }: any) => {
  return <div className={styles.marginBox} style={style} />;
};

const PaddingBox = ({ style }: any) => {
  return <div className={styles.paddingBox} style={style} />;
};

export type UnitsRatio = Record<string, { horizontal: number; vertical: number }>;

export type FloatingToolsProps = {
  componentUUID: string;
  clientWidth: number;
  clientHeight: number;
  x: number;
  y: number;
  dashed?: boolean;
  // The target component type
  componentType?: string;
  // The source component type
  sourceComponentType?: string | null;
  showDroppableArea: boolean;
  scrollHeight: number;
  scrollWidth: number;
  offsetHeight: number;
  offsetWidth: number;
};

function FloatingTools(props: FloatingToolsProps) {
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const windowDimenstions = useWindowDimensions();
  const [unitsRatio, setUnitsRatio] = useState<UnitsRatio>();
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const checkShowGrid = useCheckShowGrid();
  const isAGridColumn =
    selectedComponent &&
    components[selectedComponent] &&
    checkShowGrid(components[selectedComponent].uuid);

  useEffect(() => {
    const cssUnitsRatio: UnitsRatio = {
      '%': {
        horizontal: windowDimenstions.width / 100,
        vertical: windowDimenstions.height / 100
      },
      px: {
        horizontal: 1,
        vertical: 1
      },
      rem: {
        horizontal: 16,
        vertical: 16
      },
      vh: {
        horizontal: windowDimenstions.height / 100,
        vertical: windowDimenstions.height / 100
      },
      vw: {
        horizontal: windowDimenstions.width / 100,
        vertical: windowDimenstions.width / 100
      },
      grid: {
        horizontal: windowDimenstions.width / 12,
        vertical: 1
      },
      default: {
        horizontal: 1,
        vertical: 1
      }
    };
    setUnitsRatio(cssUnitsRatio);
  }, [windowDimenstions.height, windowDimenstions.width]);

  const showOverlayStripes = React.useCallback(() => {
    if (!props.sourceComponentType) return false;

    const validParents = COMPONENTS_MANIFEST[props.sourceComponentType].validParents;
    if (!validParents) return false;

    for (const parent of validParents) {
      if (parent === props.componentType) {
        return false;
      }
    }
    return true;
  }, [props.sourceComponentType, props.componentType]);

  const selectionBox = {
    height: props.offsetHeight,
    width: props.offsetWidth,
    left: props.x,
    top: props.y
  };

  const paddingBox = {
    borderTop: components[props.componentUUID].styles.paddingTop
      ? components[props.componentUUID].styles.paddingTop
      : 0,
    borderBottom: components[props.componentUUID].styles.paddingBottom
      ? components[props.componentUUID].styles.paddingBottom
      : 0,
    borderLeft: components[props.componentUUID].styles.paddingLeft
      ? components[props.componentUUID].styles.paddingLeft
      : 0,
    borderRight: components[props.componentUUID].styles.paddingRight
      ? components[props.componentUUID].styles.paddingRight
      : 0
  };

  const marginBox = {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    borderTop: components[props.componentUUID].styles.marginTop
      ? components[props.componentUUID].styles.marginTop
      : 0,
    borderLeft: components[props.componentUUID].styles.marginLeft
      ? components[props.componentUUID].styles.marginLeft
      : 0,
    borderRight: components[props.componentUUID].styles.marginRight
      ? components[props.componentUUID].styles.marginRight
      : 0,
    borderBottom: components[props.componentUUID].styles.marginBottom
      ? components[props.componentUUID].styles.marginBottom
      : 0
  };

  marginBox.width = props.clientWidth + marginBox.borderLeft + marginBox.borderRight;
  marginBox.height = props.clientHeight + marginBox.borderTop + marginBox.borderBottom;
  marginBox.left = props.x - marginBox.borderLeft;
  marginBox.top = props.y - marginBox.borderTop;

  const handleGetComponentName = useCallback(
    (name?: string) => {
      if (isAGridColumn) return 'COLUMN';

      return name;
    },
    [isAGridColumn]
  );

  return (
    <>
      {props.showDroppableArea ? (
        <OverlayBox style={selectionBox} showOverlayStripes={showOverlayStripes()} />
      ) : (
        <SelectionBox
          label={
            selectedComponent
              ? handleGetComponentName(components[selectedComponent]?.type)?.toUpperCase()
              : undefined
          }
          border={props.dashed}
          style={selectionBox}
          componentUUID={props.componentUUID}
        >
          {['top', 'left', 'right', 'bottom'].map((position) => {
            return (
              <ResizeHandle
                key={position}
                componentUUID={props.componentUUID}
                position={position}
                unitsRatio={unitsRatio}
                scrollHeight={props.scrollHeight}
                scrollWidth={props.scrollWidth}
                width={props.clientWidth}
                height={props.clientHeight}
                offsetHeight={props.offsetHeight}
                offsetWidth={props.offsetWidth}
              />
            );
          })}
        </SelectionBox>
      )}
      <PaddingBox style={{ ...selectionBox, ...paddingBox }} />
      <MarginBox style={marginBox} />
    </>
  );
}

export default FloatingTools;
