import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CantCreateAppModal(props: Props) {
  const { t } = useTranslation();

  const close = () => {
    props.setShow(false);
  };

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)} backdrop={'static'} centered>
      <Modal.Header closeButton>{t('upgradePlanModal.titleMax')}</Modal.Header>
      <Modal.Body>
        <div className={styles.containerButtons}>
          <Button className={`${styles.buttons} bg-danger`} onClick={() => close()}>
            {t('upgradePlanModal.close')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
