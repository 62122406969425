import { Action } from 'redux';
import { FunctionEditorState } from '../../types/function_editor_state';
import { FunctionActionType } from '../../types/actions';

export const CHANGE_ACTION_ORDER = 'CHANGE_ACTION_ORDER';

export interface ChangeActionOrderAction extends Action {
  type: 'CHANGE_ACTION_ORDER';
  payload: {
    functionUuid: string;
    originUuid: string;
    targetUuid: string;
  };
}

export const changeActionOrder = (
  functionUuid: string,
  originUuid: string,
  targetUuid: string
): ChangeActionOrderAction => ({
  type: CHANGE_ACTION_ORDER,
  payload: { functionUuid, originUuid, targetUuid }
});

export function doChangeActionOrder(
  state: FunctionEditorState,
  action: ChangeActionOrderAction
): FunctionEditorState {
  const { functionUuid, originUuid, targetUuid } = action.payload;
  if (!state.functions[functionUuid]) return state;

  const func = state.functions[functionUuid];

  const originIndex = func.actions.indexOf(originUuid);
  const targetIndex = func.actions.indexOf(targetUuid);

  func.actions.splice(originIndex, 1);
  if (originIndex < targetIndex) {
    func.actions.splice(targetIndex - 1, 0, originUuid);
  } else {
    func.actions.splice(targetIndex, 0, originUuid);
  }

  const originAction = state.actions[originUuid];
  const isOpenBlockAction = !!originAction?.data?.closeBlockActionUuid;
  if (isOpenBlockAction) return handleReorderOpenBlock(state, functionUuid, originAction);
  const isCloseBlockAction = !!originAction?.data?.openBlockActionUuid;
  if (isCloseBlockAction) return handleReorderCloseBlock(state, functionUuid, originAction);

  return state;
}

const handleReorderOpenBlock = (
  state: FunctionEditorState,
  functionUuid: string,
  openBlockAction: FunctionActionType
): FunctionEditorState => {
  const func = state.functions[functionUuid];

  const openBlockActionIndex = func.actions.indexOf(openBlockAction.uuid);
  const closeBlockActionIndex = func.actions.indexOf(openBlockAction.data.closeBlockActionUuid);
  if (openBlockActionIndex < closeBlockActionIndex) return state;

  func.actions.splice(closeBlockActionIndex, 1);
  func.actions.splice(openBlockActionIndex, 0, openBlockAction.data.closeBlockActionUuid);
  return state;
};

const handleReorderCloseBlock = (
  state: FunctionEditorState,
  functionUuid: string,
  closeBlockAction: FunctionActionType
): FunctionEditorState => {
  const func = state.functions[functionUuid];

  const openBlockActionIndex = func.actions.indexOf(closeBlockAction.data.openBlockActionUuid);
  const closeBlockActionIndex = func.actions.indexOf(closeBlockAction.uuid);
  if (openBlockActionIndex < closeBlockActionIndex) return state;

  func.actions.splice(closeBlockActionIndex, 1);
  func.actions.splice(openBlockActionIndex, 0, closeBlockAction.uuid);
  return state;
};
