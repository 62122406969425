import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../web_ui/icon';

type DropdownToggleProps = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  handleUpdateList?: () => void;
};

export const DropdownToggle = React.forwardRef(
  (props: DropdownToggleProps, ref: React.Ref<HTMLDivElement>) => {
    const toggleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (props.handleUpdateList) {
        props.handleUpdateList();
      }
      e.preventDefault();
      props.onClick(e);
    };

    return (
      <div
        ref={ref}
        onClick={toggleOnClick}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {props.children}
        <span className={styles.ToggleButton} id="dropdownModule">
          <Icon iconName="caret-down" />
        </span>
      </div>
    );
  }
);
