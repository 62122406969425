import React from 'react';
import styles from './styles.module.css';
import { DATA_TYPES, DataType } from '../../../../../../../../logic_builder/types';
import { Column } from '../../../../../../../types';
import Icon from '../../../../../../../../../web_ui/icon';

type ColumnInstanceProps = {
  column: Column;
  selectedColumnID: string;
  selectColumn: React.Dispatch<React.SetStateAction<string>>;
  handleDrop: (e: React.DragEvent<HTMLElement>, columnID: string) => void;
};

function ColumnInstance({
  column,
  selectedColumnID,
  selectColumn,
  handleDrop
}: ColumnInstanceProps) {
  const handleDragStart = function (e: React.DragEvent<HTMLElement>, columnID: string) {
    e.dataTransfer.setData('exocode/dragged-column-id', columnID);
    e.dataTransfer.dropEffect = 'copy';
    selectColumn(columnID);
  };

  const icon = (column: Column) => {
    if (column.isFK) {
      return <Icon iconName="link" />;
    }
    if (column.isPK) {
      return <Icon iconName="key" extraProps={styles.KeyIcon} />;
    }
  };

  if (!column) return null;

  return (
    <div
      id={column.name}
      className={`${styles.ColumnWrapper} ${
        selectedColumnID === column.uuid && 'bg-body-tertiary'
      }`}
      key={column?.uuid}
      onClick={() => selectColumn(column.uuid)}
      onDrop={(e) => handleDrop(e, column?.uuid)}
    >
      <div className={`${styles.KeyValue} ${styles.SelectedColumn} ${styles.ColumnDetailsWrapper}`}>
        <div className={styles.Key}>{column.name}</div>
        <div id="typeField" className={`text-muted ${styles.Value}`}>
          {DATA_TYPES[column.type as DataType]}
        </div>
        <span style={{ fontSize: '13px' }}>{icon(column)}</span>
      </div>
      <div className={`${styles.ColumnButtonWrapper} ${styles.ColumnReorderButton}`}>
        <i
          className={`fa fa-grip-vertical text-secondary ${styles.ColumnButton}`}
          draggable={true}
          onDragStart={(e) => handleDragStart(e, column?.uuid)}
        />
      </div>
    </div>
  );
}

export default ColumnInstance;
