export enum FetchType {
  LAZY,
  EAGER
}

export enum CascadeType {
  PERSIST,
  MERGE,
  REMOVE,
  REFRESH,
  DETACH,
  ALL
}
