import React from 'react';
import { Tag } from 'modules/logic_builder/types';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';

interface Props {
  tag: Tag;
  onSelect: (tag: Tag) => void;
  tags: number[];
}

function TagItemRadio({ tags, onSelect, tag }: Props) {
  return (
    <div className={styles.containerItemCheck}>
      <Form.Check
        type="checkbox"
        checked={tag.id != null && tags.includes(tag.id)}
        onClick={() => {
          onSelect(tag);
        }}
      />
      <span>{tag.name}</span>
    </div>
  );
}

export default TagItemRadio;
