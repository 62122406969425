import { Action } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class ActionsRepo {
  /**
   * Get the information of a specific Action.
   */
  async getAction(actionId: string): Promise<Action<any>> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/actions/${actionId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Action<any>>;

      throw new Error('Something went wrong while trying to fetch an Action.');
    });
  }

  /**
   * Update a specific Action.
   */
  async updateAction(actionId: string, action: Action<any>): Promise<Action<any>> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(action)
    };

    const url = `${API_URL}/actions/${actionId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Action<any>>;
      throw new Error('Something went wrong while trying to update an Action.');
    });
  }

  /**
   * Delete a specific Action.
   */
  async deleteAction(actionId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/actions/${actionId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;
      throw new Error('Something went wrong while trying to delete an Action.');
    });
  }
}
