import * as React from 'react';
import { ReadActionType, ARGUMENT_TYPES } from '../../types';
import ArgumentPicker from '../ArgumentPicker';
import {
  Action,
  FunctionParameter,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import ObjectPicker from '../ObjectPicker';

export type Props = {
  newAction: Action<ReadActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<ReadActionType>>>;
  objects: ObjectSimple[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
};

export default function RenderReadEntityInputs(props: Props) {
  if (props.newAction.data.arguments.id) {
    return (
      <>
        <ObjectPicker
          label="Select a object"
          objects={props.objects}
          onChange={(uuidObjt) => {
            props.onChange((currentVal) => {
              const aux = { ...currentVal };
              aux.data.objectId = uuidObjt;
              return aux; // override
            });
          }}
          value={props.newAction.data.objectId}
        />

        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Select Id"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.id.value = value;
              auxVal.data.arguments.id.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.id.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={props.newAction.data.arguments.id.type}
        />

        <div className="d-flex w-100 mb-2">
          <div className="me-3">
            <input
              id="sortable-read"
              type="checkbox"
              onChange={(e) => {
                props.onChange((currentVal: any) => {
                  const auxVal = { ...currentVal };
                  auxVal.data.sortable = e.target.checked;
                  return auxVal;
                });
              }}
              checked={props.newAction.data.sortable ?? false}
              className="form-check-input me-1"
            />
            <label htmlFor="sortable-read">Sortable</label>
          </div>
          <div>
            <input
              id="filterable-read"
              type="checkbox"
              onChange={(e) => {
                props.onChange((currentVal: any) => {
                  const auxVal = { ...currentVal };
                  auxVal.data.filterable = e.target.checked;
                  return auxVal;
                });
              }}
              checked={props.newAction.data.filterable ?? false}
              className="form-check-input me-1"
            />
          </div>
          <label htmlFor="filterable-read">Filterable</label>
        </div>

        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={false}
          allowVars={true}
          allowObject={false}
          label="Select Variable to return"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.returnVariableUuid = value;
              // auxVal.data.arguments.id.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.returnVariableUuid}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={ARGUMENT_TYPES.VAR}
        />
      </>
    );
  } else {
    return (
      <>
        <ObjectPicker
          label="Select a object"
          objects={props.objects}
          onChange={(uuidObjt) => {
            props.onChange((currentVal) => {
              const aux = { ...currentVal };
              aux.data.objectId = uuidObjt;
              return aux; // override
            });
          }}
          value={props.newAction.data.objectId}
        />

        <div className="d-flex w-100 mb-2">
          <div className="me-3">
            <input
              id="sortable-read"
              type="checkbox"
              onChange={(e) => {
                props.onChange((currentVal: any) => {
                  const auxVal = { ...currentVal };
                  auxVal.data.sortable = e.target.checked;
                  return auxVal;
                });
              }}
              checked={props.newAction.data.sortable ?? false}
              className="form-check-input me-1"
            />
            <label htmlFor="sortable-read">Sortable</label>
          </div>
          <div>
            <input
              id="filterable-read"
              type="checkbox"
              onChange={(e) => {
                props.onChange((currentVal: any) => {
                  const auxVal = { ...currentVal };
                  auxVal.data.filterable = e.target.checked;
                  return auxVal;
                });
              }}
              checked={props.newAction.data.filterable ?? false}
              className="form-check-input me-1"
            />
          </div>
          <label htmlFor="filterable-read">Filterable</label>
        </div>
      </>
    );
  }
}
