import { ComponentType, ReactNode } from 'react';
import { FormatterTypes } from 'web_ui/workboard/sidebar/controls/DataTableSource/types';
import ImageFormatterPreview from './ImageFormatterPreview';
import LinkFormatterPreview from './LinkFormatterPreview';
import DatetimeFormatterPreview from './DatetimeFormatterPreview';
import ColorFormatterPreview from './ColorFormatterPreview';
import LookupFormatterPreview from './LookupFormatterPreview';
import ProgressBarFormatterPreview from './ProgressBarFormatterPreview.tsx';
import StarsFormatterPreview from './StarsFormatterPreview';
import TrafficLightFormatterPreview from './TrafficLightFormatterPreview';
import TickCrossFormatterPreview from './TickCrossFormatterPreview';
import ButtonTickFormatterPreview from './ButtonTickFormatterPreview';
import BadgeFormatterPreview from './BadgeFormatterPreview';

export type PreviewProps = {
  formatterParams: { [key: string]: string };
  value: string;
};

export const FORMATTER_PREVIEW: { [key: string]: ReactNode } = {
  [FormatterTypes.BUTTONTICK]: ButtonTickFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.TICKCROSS]: TickCrossFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.TRAFFICLIGHT]: TrafficLightFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.STARS]: StarsFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.PROGRESSBAR]: ProgressBarFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.DATETIME]: DatetimeFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.LINK]: LinkFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.IMAGE]: ImageFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.COLOR]: ColorFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.LOOKUP]: LookupFormatterPreview as ComponentType<PreviewProps>,
  [FormatterTypes.BADGE]: BadgeFormatterPreview as ComponentType<PreviewProps>
};
