import { useClickOutsideEvent } from 'hooks/useClickOutside';
import SessionContext from 'modules/auth/store';
import React, { ReactNode, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';

type EditorRootProps = {
  children: ReactNode;
  hasContainer?: boolean;
};

function EditorRoot({ children, hasContainer = true }: EditorRootProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { pageVisits, updatePageVisits } = React.useContext(SessionContext);
  const driverInstance = useRef<any>();
  const currentContext = 'logic';

  useEffect(() => {
    if (!pageVisits) {
      return;
    }
    if (!ref.current) return;

    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done',
      onClose: () => {
        updatePageVisits(currentContext);
      }
    });
  }, [pageVisits, updatePageVisits]);

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  return (
    <div
      ref={ref}
      className={`flex-grow-1 ${hasContainer && 'ms-3 pe-3'}`}
      style={{ maxHeight: 'calc(100vh - 60px)', overflowY: 'auto' }}
    >
      {hasContainer ? <Container>{children}</Container> : <>{children}</>}
    </div>
  );
}

export default EditorRoot;
