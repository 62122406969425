import React from 'react';
import { AppFullInfo } from 'modules/project/types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GitRepo } from '../../../../modules/integrations/types';

export type Step3Props = {
  /* Application info to use on the form */
  appInfo: AppFullInfo;
  /* Callback on form changes */
  onChange: <K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) => void;
  repos: GitRepo[];
};

/**
 * Step3 of the application creation wizard.
 * In this step the user must config the app integrations.
 *
 * @component
 */
function Step3(props: Step3Props) {
  const { t } = useTranslation();

  function handleAppInfoChange<K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) {
    props.onChange(key, value);
  }

  return (
    <>
      <h4 className="text-body-emphasis">{t('appResume.Integrations')}</h4>

      <div className="mt-5 mb-3">
        <Form.Group className="mb-3">
          <Form.Label>GitHub</Form.Label>
          {!props.appInfo.createRepo && (
            <Form.Select
              id="selectRepository"
              value={props.appInfo.gitRepository}
              onChange={(e) => handleAppInfoChange('gitRepository', e.target.value)}
            >
              <option value="">---</option>
              {props.repos.map((repo) => (
                <option id={repo.name} key={repo.name}>
                  {repo.name}
                </option>
              ))}
            </Form.Select>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            id="newRepository"
            inline
            label="New Repository"
            name="CreateRepo"
            type="checkbox"
            checked={props.appInfo.createRepo}
            onChange={() => {
              handleAppInfoChange('createRepo', !props.appInfo.createRepo);
            }}
          />
        </Form.Group>
        {props.appInfo.createRepo && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control
                id="formName"
                onChange={(e) => handleAppInfoChange('gitRepository', e.target.value)}
                required={true}
                value={props.appInfo.gitRepository}
                maxLength={100}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('Description')}</Form.Label>
              <Form.Control
                id="formDescription"
                onChange={(e) => handleAppInfoChange('repoDescription', e.target.value)}
                as="textarea"
                required={true}
                value={props.appInfo.repoDescription}
                maxLength={255}
              />
              <Form.Check
                id="privateRepositoryCheck"
                inline
                label="Private"
                name="PrivateRepo"
                type="checkbox"
                checked={props.appInfo.privateRepo}
                onChange={() => handleAppInfoChange('privateRepo', !props.appInfo.privateRepo)}
              />
            </Form.Group>
          </>
        )}
        <Form.Group className="mb-3">
          <Form.Check
            id="createPullRequestCheck"
            label="Create Pull Request"
            onChange={() => {
              handleAppInfoChange('createPR', !props.appInfo.createPR);
            }}
            checked={props.appInfo.createPR}
          />
        </Form.Group>
        {props.appInfo.createPR && (
          <Form.Group className="mb-3">
            <Form.Check
              id="automaticallyMergeCheck"
              label="Automatically Merge"
              onChange={() => {
                handleAppInfoChange('autoMerge', !props.appInfo.autoMerge);
              }}
              checked={props.appInfo.autoMerge}
            />
          </Form.Group>
        )}
      </div>
    </>
  );
}

export default Step3;
