import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { updateParam } from '../../../../store/events/parameters/update_param';

type ParameterRelationshipEditorProps = {
  uuid: string;
};

function ParameterRelationshipEditor({ uuid }: ParameterRelationshipEditorProps) {
  const param = useSelector((state: FunctionEditorState) => state.parameters[uuid]);
  const globals = useSelector((state: FunctionEditorState) => state.globals);
  const relationships = useSelector((state: FunctionEditorState) => globals?.relationships);
  const dispatch = useDispatch();

  const handleChangeRelationship = (relatioshipId: string) => {
    dispatch(updateParam(uuid, param.name, param.desc, relatioshipId));
  };

  if (!uuid || !param) return null;

  return (
    <select
      value={param?.relationshipId}
      className={'form-select'}
      onChange={(e) => handleChangeRelationship(e.target.value)}
    >
      <option value={''}>Relationship</option>
      {relationships &&
        Object.values(relationships)?.length > 0 &&
        Object.values(relationships).map((r) => (
          <option key={r.id} value={r.id}>
            {r.name}
          </option>
        ))}
    </select>
  );
}

export default ParameterRelationshipEditor;
