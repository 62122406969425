import React from 'react';
import { ThemeValues } from 'modules/designer/types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export type ThemeTextSectionProps = {
  themeValues: ThemeValues[];
  updateValue: (newValue: string, key: string) => void;
};

export const FONTS: string[] = [
  'Arial',
  'Avant Garde',
  'Aleo',
  'Bookman',
  'Courier',
  'Courier New',
  'Helvetica',
  'Garamond',
  'Open Sans',
  'Georgia',
  'Montserrat',
  'PT Serif',
  'Palatino',
  'Roboto',
  'Serif',
  'Times',
  'Times New Roman',
  'Verdana',
  'Comic Neue',
  'Sofia',
  'Source Sans Pro',
  'Playfair Display',
  'Lato',
  'Abhaya Libre',
  'Merriweather',
  'Alegreya',
  'Muli'
];

const THEME_FONT_KEYS = ['GLOBAL_FONT', 'FONT_SIZE', 'GLOBAL_FONT_COLOR'];

export function ThemeTextSection(props: ThemeTextSectionProps) {
  const { t } = useTranslation();
  const SORTED_FONTS = FONTS.sort();

  return (
    <div>
      <Form>
        <div style={{ display: 'flex', justifyContent: 'start  ', alignItems: 'center' }}>
          {props.themeValues
            .sort((a, b) => {
              const aIndex = THEME_FONT_KEYS.indexOf(a.key);
              const bIndex = THEME_FONT_KEYS.indexOf(b.key);
              return aIndex - bIndex;
            })
            .map((value) => {
              switch (value.key) {
                case 'GLOBAL_FONT':
                  return (
                    <Form.Group key={value.key} className="me-1">
                      <Form.Label>{t('designer.themes.GlobalFont')}</Form.Label>
                      <Form.Select
                        onChange={(ev) => props.updateValue(ev.target.value, value.key)}
                        value={value.value}
                        required
                        style={{ width: 'fitContent' }}
                      >
                        {SORTED_FONTS.map((type) => {
                          return (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  );
                case 'GLOBAL_FONT_COLOR':
                  return (
                    <div
                      style={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '30px'
                      }}
                      className="ms-3"
                    >
                      <input
                        style={{ height: '26px', width: '26px' }}
                        key={value.key}
                        id="colorpicker"
                        type="color"
                        value={value.value}
                        onChange={(ev) => {
                          props.updateValue(ev.target.value, value.key);
                        }}
                      />
                    </div>
                  );
                case 'FONT_SIZE':
                  return (
                    <Form.Group key={value.key} className="">
                      <Form.Label style={{ opacity: '0' }}>.</Form.Label>
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <Form.Control
                          style={{ width: '70px' }}
                          type="number"
                          onChange={(ev) => props.updateValue(ev.target.value, value.key)}
                          value={value.value}
                          required
                        />
                        <p className="ms-1" style={{ color: '#999', margin: '0' }}>
                          px
                        </p>
                      </div>
                    </Form.Group>
                  );
                default: {
                  console.error('Key theme not implemented');
                  return null;
                }
              }
            })}
        </div>
      </Form>
    </div>
  );
}

export default ThemeTextSection;
