import { Team } from 'modules/auth/types/auth_types';
import { TeamRepo } from '../repos';

export default class TeamService {
  async createTeam(data: Team) {
    return TeamRepo.createTeam(data);
  }
  async getAllTeams(orgId: number) {
    return TeamRepo.getAllTeams(orgId);
  }
  async putTeamName(orgId: number, teamId: number, newName: string) {
    return TeamRepo.putTeamName(orgId, teamId, newName);
  }
  async deleteTeam(orgId: number, teamId: number) {
    return TeamRepo.deleteTeam(orgId, teamId);
  }

  /* TEAM MEMBERS ENDPOINTS */
  async insertMember(orgId: number, teamId: number, userId: number) {
    return TeamRepo.insertMember(orgId, teamId, userId);
  }
  async getAllTeamMembers(orgId: number, teamId: number) {
    return TeamRepo.getAllTeamMembers(orgId, teamId);
  }
  async deleteTeamMember(orgId: number, teamId: number, userId: number) {
    return TeamRepo.deleteTeamMember(orgId, teamId, userId);
  }
}
