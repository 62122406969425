import { Action } from 'redux';
import { ConnectorID } from '../../../types';
import { FrameUUID } from 'web_ui/workboard/frame';

export const SET_SELECTED_FRAME = 'SET_SELECTED_FRAME';
export const SET_SELECTED_CONNECTION = 'SET_SELECTED_CONNECTION';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_CREATING_RELATIONSHIP = 'SET_CREATING_RELATIONSHIP';

export type StudioActions =
  | SetSelectedFrameAction
  | setSelectedConnectionAction
  | SetCurrentPageAction
  | SetErrorMessageAction
  | SetCreatingRelationshipAction;

export interface SetSelectedFrameAction extends Action {
  type: 'SET_SELECTED_FRAME';
  payload: FrameUUID | null;
}

export interface setSelectedConnectionAction extends Action {
  type: 'SET_SELECTED_CONNECTION';
  payload: ConnectorID | null;
}

export interface SetCurrentPageAction extends Action {
  type: 'SET_CURRENT_PAGE';
  payload: {
    page_id: FrameUUID;
    module_id: FrameUUID;
  };
}

export interface SetErrorMessageAction extends Action {
  type: 'SET_ERROR_MESSAGE';
  payload: string;
}

export interface SetCreatingRelationshipAction extends Action {
  type: 'SET_CREATING_RELATIONSHIP';
  payload: boolean;
}

export const setSelectedFrame = (uuid: FrameUUID | null): SetSelectedFrameAction => ({
  type: SET_SELECTED_FRAME,
  payload: uuid
});

export const setSelectedConnector = (id: ConnectorID | null): setSelectedConnectionAction => ({
  type: SET_SELECTED_CONNECTION,
  payload: id
});

export const setCurrentPage = (module_id: string, page_id: string): SetCurrentPageAction => ({
  type: SET_CURRENT_PAGE,
  payload: { module_id, page_id }
});

export const setErrorMessage = (message: string): SetErrorMessageAction => ({
  type: SET_ERROR_MESSAGE,
  payload: message
});

export const setCreatingRelationship = (isCreating: boolean): SetCreatingRelationshipAction => ({
  type: SET_CREATING_RELATIONSHIP,
  payload: isCreating
});
