import React, { FormEvent, useEffect, useState } from 'react';
import { ThemeService } from 'modules/designer/services';
import { Theme, ThemeValues } from 'modules/designer/types';
import { Button, Card, Form, Modal, Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { THEME_TEMPLATE } from '../manifest';
import { useDispatch } from 'react-redux';
import { changeThemeValue } from 'modules/designer/studio/store/actions/theme';
import ThemeColorSection from './bootstrap/color_section';
import ThemeTextSection from './bootstrap/text_section';
import { useTranslation } from 'react-i18next';

export type ThemeManagerModalProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  theme?: Theme;
  setTheme: (theme: Theme, isNew: boolean) => void;
  themes: Theme[];
};

function ThemeManagerModal(props: ThemeManagerModalProps) {
  const [themeModel, setThemeModel] = useState<Theme>(THEME_TEMPLATE);
  const [sections, setSections] = useState<string[]>([]);
  const [activeSection, setActiveSection] = useState<string>('colors');
  const { app_id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.theme) {
      setThemeModel(props.theme);
    } else {
      const newTheme = THEME_TEMPLATE;
      newTheme.name = 'theme_' + (props.themes.length + 1);
      setThemeModel(THEME_TEMPLATE);
    }
  }, [props.theme]);

  useEffect(() => {
    if (themeModel.values) {
      setSections(getUniqueSections(themeModel.values));
    }
  }, []);

  async function handleUpdateValue(newValue: string, key: string) {
    if (!themeModel.values || !app_id) return;
    if (!themeModel.id) {
      setThemeModel({
        ...themeModel,
        values: themeModel.values.map((value) =>
          value.key === key ? { ...value, value: newValue } : value
        )
      });
      return;
    }

    const newThemeValue = themeModel.values.find((value) => value.key === key);

    if (!newThemeValue || newValue === newThemeValue.value) return;
    newThemeValue.value = newValue;
    try {
      const updatedValue = await ThemeService.updateThemeValue(app_id, newThemeValue);
      if (!updatedValue) return;
      if (props.theme?.selected)
        dispatch(changeThemeValue(updatedValue.key, updatedValue.value, updatedValue.section));
      setThemeModel({
        ...themeModel,
        values: themeModel.values.map((value) =>
          value.key === key ? { ...value, value: newValue } : value
        )
      });
      props.setTheme(themeModel, false);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleUpdateThemeName(newName: string) {
    if (!app_id || !themeModel.id || props.theme?.name === newName || newName.length < 2) return;
    try {
      const theme: Theme = { id: themeModel.id, name: newName };
      const updatedTheme = await ThemeService.updateTheme(app_id, themeModel.id, theme);
      if (updatedTheme) {
        props.setTheme(themeModel, false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleOnSubmit(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    if (!app_id || themeModel.id) {
      props.setShowModal(false);
      return;
    }
    try {
      const newTheme = await ThemeService.createTheme(app_id, themeModel);
      if (newTheme) props.setTheme(newTheme, true);
    } catch (error) {
      console.log(error);
    }
    props.setShowModal(false);
  }

  function getUniqueSections(arr: ThemeValues[]): string[] {
    const sectionsSet = new Set<string>();
    arr.forEach((obj) => sectionsSet.add(obj.section));
    return Array.from(sectionsSet); //.map((item) => item.charAt(0).toUpperCase() + item.slice(1));
  }

  return (
    <Modal size="lg" show={props.showModal} onHide={() => props.setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('designer.themes.Theme' + (props.theme ? 'Editor' : 'Creator'))}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={(ev) => handleOnSubmit(ev)}>
        <Modal.Body style={{ minHeight: '700px' }}>
          <div className="mb-3">
            <Form.Group className="mb-5" controlId="formName">
              <Form.Label>{t('designer.themes.ThemeName')}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tag"
                autoFocus
                required
                value={themeModel?.name}
                onChange={(ev) => {
                  setThemeModel({
                    ...themeModel,
                    name: ev.target.value
                  });
                }}
                onBlur={(ev) => handleUpdateThemeName(ev.target.value)}
              />
            </Form.Group>
            <div className="row mb-3">
              <div className="col-12 d-flex justify-content-center align-items-center">
                {/* <Card style={{ minWidth: '100%', minHeight: '68vh' }} className="p-0">
                  <Card.Header>
                    <Nav>
                      {sections.map((section, index) => {
                        return (
                          <Nav.Item key={section + index}>
                            <Nav.Link
                              active={true}
                              href="#"
                              onClick={() => setActiveSection(section)}
                            >
                              {t('designer.themes.' + section)}
                            </Nav.Link>
                          </Nav.Item>
                          // <p
                          //   onClick={() => setActiveSection(section)}
                          //   key={section}
                          //   className={`${styles.sectionTitle} ${
                          //     activeSection === section ? styles.activeSection : ''
                          //   }`}
                          // >
                          //   {t('designer.themes.' + section)}
                          // </p>
                        );
                      })}
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    <div className="row">
                      {themeModel.values && activeSection == 'colors' && (
                        <ThemeColorSection
                          themeValues={themeModel.values.filter(
                            (value) => value.section === activeSection
                          )}
                          updateValue={handleUpdateValue}
                        />
                      )}

                      {themeModel.values && activeSection == 'text' && (
                        <ThemeTextSection
                          themeValues={themeModel.values.filter(
                            (value) => value.section === activeSection
                          )}
                          updateValue={handleUpdateValue}
                        />
                      )}
                    </div>
                  </Card.Body>
                </Card> */}
                <Card style={{ minWidth: '100%', minHeight: '68vh' }} className="p-0">
                  <Card.Header>
                    <Nav variant="tabs" defaultActiveKey="#first">
                      {sections.map((section, index) => {
                        return (
                          <Nav.Item key={section}>
                            <Nav.Link
                              active={activeSection === section}
                              onClick={() => setActiveSection(section)}
                              href="#"
                            >
                              {t('designer.themes.' + section)}
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    {' '}
                    <div className="row">
                      {themeModel.values && activeSection === 'colors' && (
                        <ThemeColorSection
                          themeValues={themeModel.values.filter(
                            (value) => value.section === activeSection
                          )}
                          updateValue={handleUpdateValue}
                        />
                      )}

                      {themeModel.values && activeSection === 'text' && (
                        <ThemeTextSection
                          themeValues={themeModel.values.filter(
                            (value) => value.section === activeSection
                          )}
                          updateValue={handleUpdateValue}
                        />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancelButton" variant="secondary" onClick={() => props.setShowModal(false)}>
            {t('designer.themes.Cancel')}
          </Button>
          <Button id="saveButton" variant="primary" type="submit">
            {themeModel.id ? t('designer.themes.Save') : t('designer.themes.Create')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ThemeManagerModal;
