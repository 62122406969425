import { Action } from 'redux';
import { EditorState } from '../../types/function_editor_state';

export const SELECT_PARAM = 'SELECT_PARAM';

export interface SelectParamAction extends Action {
  type: 'SELECT_PARAM';
  payload: string;
}

export const selectParam = (uuid: string): SelectParamAction => ({
  type: SELECT_PARAM,
  payload: uuid
});

export function doSelectParam(state: EditorState, action: SelectParamAction): EditorState {
  if (!action.payload) return state;
  state.selectedParam = action.payload;
  return state;
}
