import { CrudPage } from '../../../../../../../../../modules/logic_builder/types';
import { Col, Form, Row } from 'react-bootstrap';
import React, { isValidElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageNameSchema } from 'modules/designer/repos/schemas';
import { pageNameRegex, routeNameRegex } from 'utils/regex';
import { Validation } from '..';

type Props = {
  page: CrudPage;
  onNameChange: (val: string) => void;
  onRouteChange: (val: string) => void;
  onExtractFormChange: (val: boolean) => void;
  handleIncludeInEveryMenuChange: (val: boolean) => void;
  handleValidationName: (val: boolean | undefined) => void;
  handleGetValidationRoute: (val: boolean | undefined) => void;
};

export default function PageBasicInfo(props: Props) {
  const { t } = useTranslation();
  const [vldName, setVldName] = React.useState<boolean>();
  const [vldRoute, setVldRoute] = React.useState<boolean>();

  const validatingName = useCallback((vld: boolean) => {
    props.handleValidationName(vld);
  }, []);

  const validatingRoute = useCallback((vld: boolean) => {
    props.handleGetValidationRoute(vld);
  }, []);

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          {t('automation.step4.Name')}:
        </Form.Label>
        <Col sm="10">
          <Form.Control
            value={props.page.name}
            onChange={(e) => {
              props.onNameChange(e.target.value);
              setVldName(pageNameRegex.test(e.target.value));
              validatingName(pageNameRegex.test(e.target.value));
            }}
            required
            isInvalid={vldName !== undefined ? !vldName : false}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          {t('automation.step4.Route')}:
        </Form.Label>
        <Col sm="10">
          <Form.Control
            value={props.page.route}
            onChange={(e) => {
              props.onRouteChange(e.target.value);
              setVldRoute(routeNameRegex.test(e.target.value));
              validatingRoute(routeNameRegex.test(e.target.value));
            }}
            isInvalid={vldRoute !== undefined ? !vldRoute : false}
            required
          />
        </Col>
      </Form.Group>
      {(props.page.allowCreateOne || props.page.allowUpdateOne) && (
        <Form.Group as={Row} className="mb-3">
          <Col sm="2" />
          <Col sm="10">
            <Form.Check
              checked={props.page.extractForm}
              onChange={(e) => props.onExtractFormChange(e.target.checked)}
              label={t('designer.layers.ExtractComponents')}
            />
          </Col>
        </Form.Group>
      )}
      <Form.Group as={Row} className="mb-3">
        <Col sm="2" />
        <Col sm="10">
          <Form.Check
            checked={props.page.includeInMenu}
            onChange={(e) => props.handleIncludeInEveryMenuChange(e.target.checked)}
            label={t('designer.layers.IncludeInLayoutMenu')}
            disabled={props.page.params.length > 0}
          />
        </Col>
      </Form.Group>
    </>
  );
}
