import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { FrontendVariable, ViewUUID } from '../../../types';
import { VariableTypes } from '../../../types';
import { FRONTEND_VARIABLE_TYPES } from '../../../types';
import { useParams } from 'react-router-dom';
import { addVariable, updateVariable } from '../../store/actions/variables';
import Icon from 'web_ui/icon';
import { validateRegex } from 'utils/utils';
import { InterfaceStudioState } from '../../store';
import { ObjectsService } from 'modules/logic_builder/services';
import { addObject } from '../../store/actions/schemas';
import { useTranslation } from 'react-i18next';

const DefaultValues = {
  UNDEFINED: 'Undefined',
  FIXED: 'Fixed',
  PROPERTY: 'Property',
  PARAMETER: 'Parameter'
} as const;

type DefaultValue = keyof typeof DefaultValues;

type CreateFunctionDialogProps = {
  view_id: ViewUUID;
  show: boolean;
  onClose: (idVariable?: string, variableType?: VariableTypes, isList?: boolean) => void;
  variable?: FrontendVariable;
  editMode: boolean;
};

// TODO: add restrictions for input for defaultValue: value should depend on type.
// TODO: add support for parameters list (commented).
function ManageVariableDialog({
  show,
  view_id,
  onClose,
  variable,
  editMode
}: CreateFunctionDialogProps) {
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<VariableTypes>(FRONTEND_VARIABLE_TYPES.STRING as VariableTypes);
  const [native, setNative] = useState<boolean>();
  const [list, setList] = useState<boolean>();
  const [object, setObject] = useState<any>();
  const [validated, setValidated] = useState(false);
  const { module_id } = useParams();
  const dispatch = useDispatch();
  const variableNamePattern = /^[a-z][a-zA-Z0-9]*$/;
  const globalStateObjects = useSelector((state: InterfaceStudioState) => state.objects);
  const [selectedDefaultValue, setSelectedDefaultValue] = useState<DefaultValue>('UNDEFINED');
  const [defaultValue, setDefaultValue] = useState<string>();
  const properties = useSelector((state: InterfaceStudioState) => state.properties);
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);

  const addObjectToGlobalState = useCallback(
    async (objectId: string) => {
      if (!objectId) return;
      if (globalStateObjects[objectId]) return;

      const object = await ObjectsService.getObject(objectId);
      dispatch(addObject(object));
    },
    [dispatch, globalStateObjects]
  );

  useEffect(() => {
    addObjectToGlobalState(object ?? '');
  }, [addObjectToGlobalState, object]);

  const setInitialValues = useCallback(() => {
    setName('');
    setNative(false);
    setList(false);
    setType(FRONTEND_VARIABLE_TYPES.STRING as VariableTypes);
    setObject(undefined);
    setSelectedDefaultValue('UNDEFINED');
    setDefaultValue(undefined);
  }, [variable]);

  useEffect(() => {
    if (!show) return;
    if (!editMode) {
      setInitialValues();
    } else if (editMode && variable) {
      setName(variable.name);
      setNative(variable.native);
      setList(variable.list);
      setObject(variable.objectUuid);
      setType(variable.type as VariableTypes);
      if (variable.initialValueFixed) {
        setSelectedDefaultValue('FIXED');
        setDefaultValue(variable.initialValueFixed);
      } else if (variable.initialValueParamId) {
        setSelectedDefaultValue('PARAMETER');
        setDefaultValue(variable.initialValueParamId);
      } else if (variable.initialValuePropId) {
        setSelectedDefaultValue('PROPERTY');
        setDefaultValue(variable.initialValuePropId);
      } else {
        setSelectedDefaultValue('UNDEFINED');
        setDefaultValue(undefined);
      }
    }
  }, [show]);

  // watching this propertie
  useEffect(() => {
    if (variable && variable.objectUuid) {
      addObjectToGlobalState(variable?.objectUuid);
    }
  }, [variable?.objectUuid]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    setValidated(false);

    if (!type) return;

    const variableInfo: FrontendVariable = {
      uuid: variable?.uuid || uuidv4(),
      type: type,
      name: name,
      native: native ?? false,
      list: list ?? false,
      view: editor !== 'CUSTOM_COMPONENT' ? view_id : '',
      customComponent: editor === 'CUSTOM_COMPONENT' ? view_id : ''
    };

    if (type === (FRONTEND_VARIABLE_TYPES.OBJECT as VariableTypes) && object) {
      variableInfo.objectUuid = object;
    }

    if (selectedDefaultValue === 'FIXED') {
      variableInfo.initialValueFixed = defaultValue;
    } else if (selectedDefaultValue === 'PARAMETER') {
      variableInfo.initialValueParamId = defaultValue;
    } else if (selectedDefaultValue === 'PROPERTY') {
      variableInfo.initialValuePropId = defaultValue;
    }

    if (editMode && variable) {
      dispatch(
        updateVariable(
          variable.uuid,
          name,
          type,
          variableInfo.list,
          variableInfo.initialValueFixed,
          variableInfo.initialValuePropId,
          variableInfo.initialValueParamId,
          variableInfo.objectUuid
        )
      );
    } else {
      dispatch(addVariable(variableInfo));
    }

    //cleaning values
    setInitialValues();

    // parameters needed for the auto select to work
    onClose(variableInfo.uuid, variableInfo.type, variableInfo.list);
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value as VariableTypes);
  };
  const { t } = useTranslation();
  function handleValidVariableName(varName: string) {
    if (name === '') {
      return false;
    }

    return validateRegex(variableNamePattern, varName);
  }

  function defaultValueSelectOnChange(e: ChangeEvent<HTMLSelectElement>) {
    const valueType = e.target.value as DefaultValue;
    setSelectedDefaultValue(e.target.value as DefaultValue);

    if (valueType === 'FIXED') {
      setDefaultValue('');
    } else if (valueType === 'PARAMETER') {
      setDefaultValue('');
    } else if (valueType === 'PROPERTY') {
      if (Object.keys(properties).length) {
        setDefaultValue(properties[Object.keys(properties)[0]].uuid);
      } else {
        setDefaultValue('');
      }
    } else {
      setDefaultValue(undefined);
    }
  }

  function handleSelectObject(object: string) {
    setObject(object);
  }

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {variable ? t('designer.behaviour.Update') : t('designer.behaviour.Create')}{' '}
          {t('designer.behaviour.Variable')}
        </Modal.Title>
        <div className="ms-2" title="Native variable">
          <Icon iconName="laptop-code"></Icon>
        </div>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit} id="formModal">
        <Modal.Body id="bodyModal">
          <Form.Group className="mb-3">
            <Form.Label>{t('designer.behaviour.Name')}</Form.Label>
            <Form.Control
              id="formName"
              type="text"
              value={name}
              onChange={handleChangeName}
              isValid={handleValidVariableName(name)}
              isInvalid={!handleValidVariableName(name)}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t('designer.behaviour.invalid')}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="mb-3">
            <Form.Label>{t('designer.behaviour.Type')}</Form.Label>
            <Form.Select
              id="selectType"
              value={type}
              onChange={handleChangeType}
              isValid={type !== undefined && FRONTEND_VARIABLE_TYPES[type] !== null}
              required
              disabled={native === true}
            >
              {Object.keys(FRONTEND_VARIABLE_TYPES).map((variable) => (
                <option key={variable} value={variable}>
                  {variable}
                </option>
              ))}
            </Form.Select>
          </div>
          {type === 'OBJECT' && (
            <div className="mb-3">
              <Form.Label>{t('designer.behaviour.SelectAnObject')}</Form.Label>
              <Form.Select
                value={object ?? ''}
                onChange={(e) => handleSelectObject(e.target.value)}
                isValid={object !== ''}
                disabled={native}
                required
              >
                <option value="">---</option>
                {Object.values(globalStateObjects)?.map((object) => (
                  <option key={object.uuid} value={object.uuid}>
                    {object.moduleUuid !== module_id && `${object.moduleName} - `}
                    {object.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}

          <div className="mb-3">
            <Form.Check
              id="arrayCheck"
              inline
              label="Array"
              name="ListVariable"
              type="checkbox"
              checked={list}
              onChange={() => setList(!list)}
              disabled={native === true}
            />
          </div>

          <div className="mb-3">
            <Form.Label>{t('designer.behaviour.DefaultValueType')}</Form.Label>
            <Form.Select
              id="selectDefaultType"
              value={selectedDefaultValue}
              onChange={defaultValueSelectOnChange}
              disabled={native}
            >
              {Object.keys(DefaultValues).map((value) => {
                if (value === 'PARAMETER') return null;

                if (editor !== 'CUSTOM_COMPONENT' && editor !== 'MODAL' && value === 'PROPERTY')
                  return null;

                return (
                  <option key={value} value={value}>
                    {DefaultValues[value as DefaultValue]}
                  </option>
                );
              })}
            </Form.Select>
          </div>

          {selectedDefaultValue === 'FIXED' && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>{t('designer.behaviour.Value')}</Form.Label>
                <Form.Control
                  type="text"
                  value={defaultValue}
                  onChange={(e) => setDefaultValue(e.target.value)}
                  required
                />
              </Form.Group>
            </>
          )}

          {/* {selectedDefaultValue === 'PARAMETER' && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Parameter</Form.Label>
                <Form.Select
                  value={defaultValue}
                  onChange={(e) => setDefaultValue(e.target.value)}
                  disabled={native}
                >
                  {Object.keys(parameters).map((parameter) => (
                    <option key={parameter.uuid} value={parameter.uuid}>
                      {parameter.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </>
          )} */}

          {selectedDefaultValue === 'PROPERTY' && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>{t('designer.behaviour.Property')}</Form.Label>
                <Form.Select
                  value={defaultValue}
                  onChange={(e) => setDefaultValue(e.target.value)}
                  disabled={native}
                >
                  {Object.keys(properties).map((propertyId) => (
                    <option key={propertyId} value={propertyId}>
                      {properties[propertyId].name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={!handleValidVariableName(name)}>
            {variable ? t('Save') : t('designer.behaviour.Create')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ManageVariableDialog;
