import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import Dropdown from '../../components/dropdown';
import { ActionInputProps, Argument } from '../../../store/types/manifestsAndInputs';
import { updateAction } from '../../../store/events/actions/update_action';
import RunFunctionArguments from './run_function_arguments';
import VariableInput from '../variable';
import { TYPE_PICKER_TYPES } from '../argument_picker/type_picker';
import { t } from 'i18next';
interface RunFunctionDataPayload {
  functionId: string;
  arguments: { [key: string]: Argument };
}

function FunctionInput({ label, actionUuid, dataKey, options, isFromSideBar }: ActionInputProps) {
  const functions = useSelector((state: FunctionEditorState) => state.functions);
  const data = useSelector(
    (state: FunctionEditorState) => state?.actions[actionUuid]?.data[dataKey]
  ) as RunFunctionDataPayload;
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data) return;
    setSelected(data.functionId);
  }, [actionUuid, dataKey, data]);

  useEffect(() => {
    if (!functions[selected] || selected === data?.functionId) return;
    const handleChangeFunction = () => {
      const args: { [key: string]: any } = {};
      functions[selected].parameters.forEach(
        (p) => (args[p] = { type: TYPE_PICKER_TYPES.FIXED, value: '' })
      );
      const func: RunFunctionDataPayload = {
        functionId: selected,
        arguments: args
      };
      dispatch(updateAction(actionUuid, dataKey, func));
    };
    handleChangeFunction();
  }, [selected]);

  const handleChangeSelected = (uuid: string) => {
    if (!uuid) return;
    setSelected(uuid);
  };

  const handleChangeArgument = (paramId: string, value: Argument) => {
    const args: { [key: string]: Argument } = { ...data.arguments, [paramId]: value };
    dispatch(updateAction(actionUuid, dataKey, { ...data, arguments: args }));
  };

  const makeFunctionsList = () => {
    if (Object?.values(functions)?.length <= 0) return [];
    return Object.values(functions).map((f) => ({
      name: f.name,
      uuid: f.uuid,
      id: f.id,
      subName: f.serviceName,
      description: f.moduleName
    }));
  };

  const makeValue = () => {
    if (!functions[data.functionId]) return;
    return {
      name: functions[data.functionId].name,
      uuid: functions[data.functionId].uuid,
      id: functions[data.functionId].id,
      subName: functions[data.functionId].serviceName,
      description: functions[data.functionId].moduleName
    };
  };

  return (
    <div className={'d-flex flex-column gap-2'}>
      <div className={'d-flex align-items-end flex-wrap'}>
        <Dropdown
          items={makeFunctionsList()}
          placeholder={`${t('SelectAFunction')}`}
          label={label}
          value={makeValue()}
          handleChange={handleChangeSelected}
          isFromSideBar={isFromSideBar}
        />
      </div>
      <RunFunctionArguments
        args={data.arguments}
        actionUuid={actionUuid}
        handleChange={handleChangeArgument}
        options={options}
        isFromSideBar={isFromSideBar}
      />
    </div>
  );
}

export default FunctionInput;
