import React, { DragEvent, useCallback } from 'react';
import styles from './styles.module.css';

export type TargetType = 'folder' | 'root' | 'folderItem';

interface DraggableResourceProps {
  children?: React.ReactNode;
  handleDrop: (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) => void;
  handleLeave: (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) => void;
  handleDragOver: (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) => void;
  handleDragStart: (e: DragEvent<HTMLDivElement>) => void;
  className?: string;
}

export function DraggableResource({
  children,
  handleDrop,
  handleLeave,
  handleDragOver,
  handleDragStart,
  className
}: DraggableResourceProps) {
  const dragWrapperRef = React.useRef<HTMLDivElement>(null);

  const handleDragOverWrapper = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!dragWrapperRef || !dragWrapperRef.current) {
        return;
      }
      handleDragOver(e, dragWrapperRef.current);
    },
    [handleDragOver]
  );

  const handleDragStartWrapper = useCallback(
    (e: React.DragEvent<HTMLDivElement>): void => {
      handleDragStart(e);
    },
    [handleDragStart]
  );

  const handleDragLeaveWrapper = useCallback(
    (e: DragEvent<HTMLDivElement>): void => {
      if (!dragWrapperRef || !dragWrapperRef.current) {
        return;
      }
      handleLeave(e, dragWrapperRef.current);
    },
    [handleLeave]
  );

  const handleDropWrapper = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      if (!dragWrapperRef || !dragWrapperRef.current) {
        return;
      }
      handleDrop(e, dragWrapperRef.current);
    },
    [handleDrop]
  );

  return (
    <div
      ref={dragWrapperRef}
      className={`${styles.rootDraggableArea} ${className}`}
      draggable={true}
      onDragStart={handleDragStartWrapper}
      onDragOver={handleDragOverWrapper}
      onDragLeave={handleDragLeaveWrapper}
      onDrop={handleDropWrapper}
    >
      <div>{children}</div>
    </div>
  );
}
