import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './styles.css';
import 'bootstrap/dist/js/bootstrap';
import './assets/i18n/i18n';
import './assets/fonts/fonts.css';

import Exocode from './exocode';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback="loading">
    <Exocode />
  </Suspense>,
  // </React.StrictMode>,
  document.getElementById('root')
);
