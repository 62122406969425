import { FunctionEditorState } from '../types/function_editor_state';
import { DELETE_PARAM, DeleteParamAction, doDeleteParam } from '../events/root/delete_param';
import produce from 'immer';
import {
  doSetInitialFunctionEditorState,
  SET_INITIAL_FUNCTION_EDITOR_STATE,
  SetInitialFunctionEditorStateAction
} from '../events/root/set_initial_state';
import { initialState } from '../initial_empty_state';
import {
  CHANGE_ACTION_ORDER,
  ChangeActionOrderAction,
  doChangeActionOrder
} from '../events/root/change_action_order';
import { ADD_ACTION, AddActionAction } from '../events/actions/add_action';
import { doAddAction } from '../events/root/add_action';
import { DELETE_ACTION, DeleteActionAction, doDeleteAction } from '../events/root/delete_action';
import { UPDATE_ACTION, UpdateActionAction } from '../events/actions/update_action';
import { doUpdateAction } from '../events/root/update_action';
import { REPLACE_EMPTY, ReplaceEmptyAction } from '../events/actions/replace_empty';
import { ADD_BLOCK, AddBlockAction } from '../events/actions/add_block';
import { doAddBlockAction } from '../events/root/add_block';
import { DELETE_BLOCK, DeleteBlockAction } from '../events/actions/delete_block';
import { doDeleteBlockAction } from '../events/root/delete_block';

type RootActions =
  | DeleteParamAction
  | SetInitialFunctionEditorStateAction
  | ChangeActionOrderAction
  | AddActionAction
  | AddBlockAction
  | DeleteBlockAction
  | UpdateActionAction
  | DeleteActionAction
  | ReplaceEmptyAction;

export const rootReducer = (
  state: FunctionEditorState = initialState,
  action: RootActions
): FunctionEditorState => {
  return produce(state, (draft: FunctionEditorState) => {
    switch (action.type) {
      case DELETE_PARAM:
        return doDeleteParam(draft, action);
      case SET_INITIAL_FUNCTION_EDITOR_STATE:
        return doSetInitialFunctionEditorState(draft, action);
      case CHANGE_ACTION_ORDER:
        return doChangeActionOrder(draft, action);
      case ADD_ACTION:
        return doAddAction(draft, action);
      case REPLACE_EMPTY:
        return doAddAction(draft, action);
      case ADD_BLOCK:
        return doAddBlockAction(draft, action);
      case DELETE_BLOCK:
        return doDeleteBlockAction(draft, action);
      case UPDATE_ACTION:
        return doUpdateAction(draft, action);
      case DELETE_ACTION:
        return doDeleteAction(draft, action);
      default:
        return state;
    }
  });
};
