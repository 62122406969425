import React from 'react';
import { ControlProps } from '..';
import { sleep } from 'utils/utils';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';
import IconStyle from '../IconControl/components/IconStyle';

function IconStylesControl(props: ControlProps) {
  const { t } = useTranslation();

  async function handleChangeStaticColor(staticColor: string) {
    if (!props.onChange) return;
    props.onChange(staticColor, 'iconStaticColor');
    await sleep(300);
    props.onChange('', 'iconThemeColor');
  }

  async function handleChangeThemeColor(themeColor: string) {
    if (!props.onChange) return;
    props.onChange(themeColor, 'iconThemeColor');
    await sleep(300);
    props.onChange('', 'iconStaticColor');
  }

  return (
    <div className="mb-3 border-bottom text-body-secondary">
      <div style={{ display: 'flex' }}>
        <label className="position-relative text-body">{props.label}</label>
        <HelpPopover
          helpBoxProps={{
            title:
              t('designer.right_side.controls.ListTypeTitle') ||
              'designer.right_side.controls.ListTypeTitle',
            description:
              t('designer.right_side.controls.ListTypeDescription') ||
              'designer.right_side.controls.ListTypeDescription',
            note: []
          }}
          placement="top"
        >
          <HelpIcon />
        </HelpPopover>
      </div>
      <div>
        <IconStyle
          value={props.value}
          onChange={props.onChange}
          isSimpleIcon={false}
          handleChangeStaticColor={handleChangeStaticColor}
          handleChangeThemeColor={handleChangeThemeColor}
        />
      </div>
    </div>
  );
}

export default IconStylesControl;
