import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FolderService } from '../../../services';
import { useTranslation } from 'react-i18next';
import { Folder, FolderType } from 'modules/designer/types';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';

type CreateModalDialogProps = {
  show: boolean;
  parentUuid: string;
  onClose: () => void;
  onCreate: (newFolder: Folder) => void;
  validateFolderName: (parentUuid: string, name: string) => boolean;
  type: FolderType;
  loading?: (val: boolean) => void;
};

function CreateFolderDialog(props: CreateModalDialogProps) {
  const { t } = useTranslation();
  const { module_id } = useParams();

  const [folderName, setFolderName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  const onSubmit = async () => {
    if (!module_id) return;
    props.loading && props.loading(true);
    await FolderService.createFolder(module_id, folderName, props.parentUuid, props.type)
      .then((folder) => {
        props.onCreate(folder);
        props.onClose();
      })
      .catch(() => {
        showErrorPopup(t('designer.folder.error') ?? '');
      })
      .finally(() => props.loading && props.loading(false));
  };

  useEffect(() => {
    if (!props.show) return;
    setErrorMessage('');
    setAlertMessage('');
    setAlertVariant('success');
    setFolderName('');
  }, [props.show]);

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  return (
    <>
      {alertMessage && (
        <Modal
          show={alertMessage ? true : false}
          style={{ background: 'transparent', width: 'auto' }}
          centered={false}
        >
          <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
        </Modal>
      )}
      <Modal centered show={props.show} onHide={props.onClose}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title>{t('designer.folder.CreateFolder')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessage && (
              <p style={{ padding: 0, margin: 0, color: '#DC4C64' }}>{errorMessage}</p>
            )}
            <div>
              <Form.Group className="mb-3">
                <Form.Label>{t('Name')}</Form.Label>
                <Form.Control
                  type="text"
                  id="formName"
                  onChange={(e) => setFolderName(e.target.value)}
                  value={folderName}
                  required
                  maxLength={64}
                  isInvalid={!props.validateFolderName(props.parentUuid, folderName.trim())}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                      if (props.validateFolderName(props.parentUuid, folderName.trim())) {
                        onSubmit();
                      }
                    }
                  }}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="saveButton"
              disabled={!props.validateFolderName(props.parentUuid, folderName.trim())}
              onClick={(ev) => {
                ev.preventDefault();
                onSubmit();
              }}
            >
              {t('designer.folder.CreateFolder')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default CreateFolderDialog;
