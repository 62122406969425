import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import SessionContext from '../../../../../modules/auth/store';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { useDispatch, useSelector } from 'react-redux';
import { updateAction } from '../../../store/events/actions/update_action';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { CustomQuery } from '../../../../../modules/logic_builder/function_editor/function_actions/types/action_data';
import QueryVarsEditor from './query_vars_editor';
import { t } from 'i18next';

function QueryInput({ placeholder, actionUuid, dataKey, options }: ActionInputProps) {
  const [customQuery, setCustomQuery] = useState({} as CustomQuery);
  const action = useSelector((state: FunctionEditorState) => state.actions[actionUuid]);
  const session = React.useContext(SessionContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!action) return;
    if (!action.data[dataKey]) return;
    const query = action.data[dataKey].query ?? '';
    const queryVars = action.data[dataKey].vars ?? [];
    setCustomQuery({ query, vars: queryVars });
  }, [action, dataKey]);

  const handleVarsChange = (vars: string[]) => {
    const newCustomQuery = { ...customQuery, vars: [...vars] };
    dispatch(updateAction(actionUuid, dataKey, newCustomQuery));
  };

  const handleQueryChange = (value: string | undefined) => {
    dispatch(updateAction(actionUuid, dataKey, { ...customQuery, query: value ?? '' }));
  };

  if (!action) {
    return <p>Loading</p>;
  } else {
    return (
      <>
        <span className={styles.ActionInputLabel}>{t('Variables')}</span>
        <div className={styles.CustomQueryInputsWrapper}>
          <QueryVarsEditor queryVars={customQuery.vars} onChange={handleVarsChange} />
          <div className={styles.CustomQueryWrapper}>
            <Editor
              defaultLanguage={options?.language ? options.language : 'sql'} //pgsql
              defaultValue={placeholder}
              onChange={handleQueryChange}
              value={customQuery.query}
              theme={session.preferences['exocode-theme'] ? 'vs-dark' : 'light'}
              height={'150px'}
            />
          </div>
        </div>
      </>
    );
  }
}

export default QueryInput;
