import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { endpointNameRegex } from 'utils/regex';

type Props = {
  name: string;
  handleChangeName: (val: string) => void;
};

function NameEditor({ name, handleChangeName }: Props) {
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  return (
    <div style={{ width: '30rem' }}>
      <FormControl
        value={name}
        onChange={(e) => {
          setIsNameInvalid(!endpointNameRegex.test(e.target.value));
          handleChangeName(e.target.value);
        }}
        isInvalid={isNameInvalid}
      />
    </div>
  );
}

export default NameEditor;
