import React, { useState } from 'react';
import { FunctionActionManifest } from '../../store/types/manifestsAndInputs';

type ActionProps = {
  manifest: FunctionActionManifest;
};

function ActionCard({ manifest }: ActionProps) {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(true);
    e.dataTransfer.setData('exocode/dragged-type', manifest?.type);
    e.dataTransfer.dropEffect = 'move';
    document.body.style.cursor = 'grabbing';
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    if (isDragging) {
      document.body.style.cursor = 'grabbing';
    }
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  if (!manifest) return null;

  return (
    <div
      id={`${manifest.name.replace(' ', '')}Action`}
      className={`
        border-${manifest.color} bg-body-secondary rounded border-primary border-0 border-start border-4 me-1 mb-2`}
      draggable={true}
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
    >
      <div className="card-body" style={{ padding: '0.4rem 0.5rem' }}>
        <h6
          className="card-subtitle fw-normal"
          style={{ marginTop: '0', fontSize: '15px', flexWrap: 'nowrap' }}
        >
          {manifest.icon}
          <span style={{ marginTop: '-20px', marginLeft: '5px' }}>{manifest.name}</span>
        </h6>
      </div>
    </div>
  );
}

export default ActionCard;
