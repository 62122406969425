import React from 'react';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import SetVariableIcon from './set_variable_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { FunctionVariableType } from '../../../../../web_ui/function_editor/store/types/variables';
import { FunctionParameterType } from '../../../../../web_ui/function_editor/store/types/parameters';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { ObjectItemType } from '../../../../../web_ui/function_editor/store/types/object_items';
import { FRONTEND_VARIABLE_TYPES, FrontendProperty } from '../../../types';
import { t } from 'i18next';

function filterByVariableDataType(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: keyof typeof FRONTEND_VARIABLE_TYPES
): boolean {
  if (!dataType) return false;
  const variable = state.actions[actionUuid].data?.variable;
  if (!variable) return false;
  let listObject: FunctionVariableType | FunctionParameterType | ObjectItemType | FrontendProperty;
  if (variable.objectNode) {
    listObject = state.objects_items[variable.objectNode];
    return dataType?.toUpperCase() === listObject?.type?.toUpperCase();
  }
  switch (variable?.type) {
    case TYPE_PICKER_TYPES.VAR:
      listObject = state.variables[variable?.value];
      break;
    case TYPE_PICKER_TYPES.PARAM:
      listObject = state.parameters[variable?.value];
      break;
    case TYPE_PICKER_TYPES.PROP:
      listObject = state.globals.properties
        ? state.globals.properties[variable?.value]
        : ({} as FrontendProperty);
      break;
    default:
      listObject = {} as FunctionVariableType;
  }
  return (
    dataType?.toUpperCase() === listObject?.type?.toUpperCase() ||
    dataType?.toUpperCase() === 'OBJECT'
  );
}

export const SET_VARIABLE_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.SET_VARIABLE,
  name: t('SetVariable'),
  description: t('ActionVariable'),
  color: 'success',
  category: FRONTEND_ACTIONS_CATEGORIES.VARIABLE,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.VARIABLE,
  icon: <SetVariableIcon />,
  inlineInputs: [
    {
      label: t('SetToVariable'),
      placeholder: '',
      key: 'variable',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: { argumentTypes: [TYPE_PICKER_TYPES.VAR] }
    },
    {
      label: t('TheValue'),
      placeholder: '',
      key: 'value',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: { filterByDataType: filterByVariableDataType }
    }
  ],
  inputs: []
};
