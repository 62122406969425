import { AutomationsServ } from './AutomationsService';
import { EndpointsServ } from './EndpointsService';
import { FunctionsServ } from './FunctionsService';
import { ControllersServ } from './ControllersService';
import { ObjectsServ } from './ObjectsService';
import { AppRoleServ } from './AppRolesService';
import { ActionsServ } from './ActionsService';
import { ServicesServ } from './ServicesService';
import { ScheduledsServ } from './ScheduledsService';
import { CrudAutomationServ } from './CrudAutomationService';
import { TagsServ } from './TagsService';
import Dashboard from '../../dashboard/services/dashboard';
import { CodePreviewServ } from './CodePreviewService';

export const AutomationsService = new AutomationsServ();
export const EndpointsService = new EndpointsServ();
export const TagsService = new TagsServ();
export const FunctionService = new FunctionsServ();
export const ControllersService = new ControllersServ();
export const ServicesService = new ServicesServ();
export const ObjectsService = new ObjectsServ();
export const ScheduledsService = new ScheduledsServ();
export const AppRoleService = new AppRoleServ();
export const ActionsService = new ActionsServ();
export const CrudAutomationService = new CrudAutomationServ();
export const DashboardService = new Dashboard();
export const CodePreviewService = new CodePreviewServ();
