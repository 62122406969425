import React, { useCallback, useEffect, useState } from 'react';
import { SETTINGS_MENU } from 'modules/auth/enum';
import { Outlet, useNavigate } from 'react-router-dom';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { Authorization } from 'modules/auth/session/authorization';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';

function SidebarSettings() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentOption = window.location.pathname.split('/').pop();
  const [selectedItem, setSelectedItem] = useState<SETTINGS_MENU>();
  const [searchText, setSearchText] = useState('');
  const searchButtonPlaceholder: string = t('Search');

  const handleSelected = useCallback(
    (option: SETTINGS_MENU) => {
      setSelectedItem(option);
      navigate('/settings/' + option.toLocaleLowerCase());
    },
    [navigate]
  );

  useEffect(() => {
    currentOption === 'settings' || currentOption == null
      ? handleSelected(SETTINGS_MENU.ACCOUNT)
      : handleSelected(currentOption.toUpperCase() as SETTINGS_MENU);
  }, [currentOption, handleSelected]);

  const options = [
    {
      name: SETTINGS_MENU.ACCOUNT,
      translation: t('settings_options.account'),
      icon: { name: 'user', style: 'solid' },
      authorities: {
        systemAuthorities: [],
        authorities: undefined,
        authorityContext: undefined
      }
    },
    {
      name: SETTINGS_MENU.GENERAL,
      translation: t('settings_options.general'),
      icon: { name: 'boxes-stacked', style: 'solid' },
      authorities: {
        systemAuthorities: [],
        authorities: undefined,
        authorityContext: undefined
      }
    },
    {
      name: SETTINGS_MENU.STUDIOS,
      translation: t('settings_options.studios'),
      icon: { name: 'circle-nodes', style: 'solid' },
      authorities: {
        systemAuthorities: [],
        authorities: undefined,
        authorityContext: undefined
      }
    },
    {
      name: SETTINGS_MENU.BILLING,
      translation: t('settings_options.billing'),
      icon: { name: 'credit-card', style: 'regular' },
      authorities: {
        systemAuthorities: [],
        authorities: undefined,
        authorityContext: undefined
      }
    },
    {
      name: SETTINGS_MENU.SUPPORT,
      translation: t('settings_options.support'),
      icon: { name: 'circle-question', style: 'regular' },
      authorities: {
        systemAuthorities: [SystemRoleAuthorityName.CONTACT_SUPPORT],
        authorities: undefined,
        authorityContext: undefined
      }
    }
  ];

  return (
    <>
      <Container fluid className={`${styles.container}`}>
        <Row className={`bg-body-secondary ${styles.Row} h-100`}>
          <Col md={3} className={`bg-body-secondary ${styles.LeftContainer} h-100 `}>
            <Col className={styles.TitleDiv}>
              <Icon iconName="gear" />
              <p className={`text-body-emphasis ${styles.TitleText}`}>{t('settings.Settings')}</p>
            </Col>
            <Col className={styles.searchInputDiv}>
              <div className="input-group">
                <input
                  id={'searchField'}
                  type="text"
                  className={` form-control`}
                  placeholder={searchButtonPlaceholder}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <span className={` input-group-text`} style={{ cursor: 'pointer' }}>
                  <Icon iconName="magnifying-glass" />
                </span>
              </div>
            </Col>
            <Nav
              variant="pills"
              className={`bg-body-secondary ${styles.tabsDiv}`}
              activeKey={selectedItem}
            >
              {options
                .filter((item) => item.translation.toLowerCase().includes(searchText.toLowerCase()))
                .map((option) => (
                  <React.Fragment key={option.name.toLocaleUpperCase()}>
                    <Authorization
                      allowedSystemAuthorities={option.authorities.systemAuthorities}
                      allowedAuthorities={option.authorities.authorities}
                      context={option.authorities.authorityContext}
                    >
                      <Nav.Item className={`pe-4 ${styles.tab} `}>
                        <Nav.Link
                          id={`${option}MenuItem`}
                          className={`
                         bg-body-secondary border-bottom rounded-0 ${
                           option.name === selectedItem ? `${styles.navLink}` : styles.navLink
                         }`}
                          active={selectedItem === option.name}
                          onClick={() => handleSelected(option.name)}
                        >
                          <div
                            className={`${
                              selectedItem === option.name
                                ? 'text-body-emphasis'
                                : 'text-body-tertiary'
                            } d-inline`}
                          >
                            <Icon iconName={option.icon.name} brands={option.icon.style} />
                          </div>
                          <span
                            id={`id${option}`}
                            className={`${
                              selectedItem === option.name
                                ? 'text-body-emphasis'
                                : 'text-body-tertiary'
                            } ${styles.tabText}`}
                          >
                            {option.translation}
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    </Authorization>
                  </React.Fragment>
                ))}
            </Nav>
          </Col>
          <Col md={9} className={`bg-body h-100 overflow-y-auto ${styles.RightContainer}`}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SidebarSettings;
