import { ModalRepository } from '../repos';
import { Modal } from '../types';

export class ModalServ {
  async createModal(module_id: string, modalName: string, template: string, folderId: string) {
    return await ModalRepository.createModal(module_id, modalName, template, folderId);
  }

  async getModalByModule(moduleId: string) {
    return await ModalRepository.getModalsByModule(moduleId);
  }

  async getModal(modalId: string) {
    return await ModalRepository.getModal(modalId);
  }

  async updateModal(modalId: string, updatedModal: Modal) {
    return await ModalRepository.updateModal(modalId, updatedModal);
  }

  async moveModal(modalId: string, moduleId: string, folderId: string) {
    return await ModalRepository.moveModal(modalId, moduleId, folderId);
  }

  async deleteModal(modalId: string) {
    return await ModalRepository.deleteModal(modalId);
  }
}
