import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from 'modules/logic_builder/styles.module.css';
import { Button, Form } from 'react-bootstrap';
import LogicBuilderContext from '../../../store';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SearchBar from 'web_ui/search_bar';
import { ObjectsList } from 'modules/logic_builder/components/objects_list';
import { ObjectDialog } from 'modules/logic_builder/components/dialogs/object_dialog';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { DashboardService, ObjectsService } from 'modules/logic_builder/services';
import Icon from 'web_ui/icon';
import { ModuleInfo } from '../../../../dashboard/types';
import { ObjectSimple } from 'modules/logic_builder/types';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';

function ObjectsEditor() {
  const [showObjectCreatorDialog, setShowObjectCreatorDialog] = useState(false);
  const [objectEditUuid, setObjectEditUuid] = useState<string>();
  const { objects } = useContext(LogicBuilderContext);
  const [filter, setFilter] = useState('');
  const { t } = useTranslation();
  const searchTxt = t('Search');

  const { app_id, module_id } = useParams();
  const [modules, setModules] = useState<ModuleInfo[]>([]);
  const [tables, setTables] = useState<Table[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [enums, setEnums] = useState<EnumFrame[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);
  const [moduleObject, setModuleObjects] = useState<ObjectSimple[]>([]);
  const [objectsWithDeps, setObjectsWithDeps] = useState<ObjectSimple[]>([]);
  const [previewObjectUuid, setPreviewObjectUuid] = useState<string>('');

  const fetchObjectsContext = useCallback(async (module_id: string) => {
    await ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setEnums(schema?.enums ? schema.enums : []);
      setTables(schema?.tables ? schema.tables : []);
      setColumns(schema?.columns ? schema.columns : []);
      setRelationships(schema?.relationships ? schema.relationships : []);
    });
  }, []);

  const fetchProjectModules = useCallback(async (app_id: string) => {
    await DashboardService.getModulesByApp(app_id).then((modules) => setModules(modules));
  }, []);

  useEffect(() => {
    setLoadingData(true);
    if (!app_id) return;
    if (!module_id) return;

    const load = async () => {
      try {
        await fetchObjectsContext(module_id);
        await fetchProjectModules(app_id);
      } finally {
        setLoadingData(false);
      }
    };

    load();
  }, [app_id, fetchObjectsContext, fetchProjectModules, module_id]);

  async function getModuleObjects() {
    if (!module_id) return;
    const objectList = await ObjectsService.getObjectsByModule(module_id);
    setModuleObjects(objectList);
  }

  async function fetchObjectWithDeps() {
    if (!module_id) return;
    await ObjectsService.getObjectsByModuleWithDependencies(module_id).then((fetchedObj) => {
      setObjectsWithDeps(fetchedObj);
    });
  }

  useEffect(() => {
    fetchObjectWithDeps();
  }, [objects]);

  useEffect(() => {
    getModuleObjects();
    fetchObjectWithDeps();
  }, [module_id]);

  return (
    <div className={styles.LogicEditorWrapper}>
      {/* Objects Wrapper */}
      <div className={styles.LogicEditorListWrapper}>
        {/* Search bar*/}
        <div className={styles.LogicBuilderControl}>
          <Form className={styles.LogicBuilderControlSearch}>
            <SearchBar
              id={'searchField'}
              placeholder={searchTxt}
              text={filter}
              setText={setFilter}
              focus={true}
            />
          </Form>
          <div className={styles.LogicBuilderControlButtons}>
            <Button
              id={'createButton'}
              className={styles.ControlButton}
              variant="primary"
              onClick={() => setShowObjectCreatorDialog(true)}
            >
              <Icon iconName="plus" extraProps="pe-1"></Icon>
              {t('organizations.new_organization.Create')}
            </Button>
          </div>
        </div>
        {/* Objects */}
        <ObjectsList
          key={'objLst'}
          search={filter.toLowerCase().trim()}
          objects={objects}
          onCreate={() => setShowObjectCreatorDialog(true)}
          onEdit={(uuid: string) => setObjectEditUuid(uuid)}
          onPreviewObject={(uuid: string) => setPreviewObjectUuid(uuid)}
          loadingData={loadingData}
        />
      </div>
      {(showObjectCreatorDialog || !!objectEditUuid) && (
        <ObjectDialog
          show={showObjectCreatorDialog || !!objectEditUuid}
          onClose={() => {
            setShowObjectCreatorDialog(false);
            setObjectEditUuid(undefined);
          }}
          editMode={!!objectEditUuid}
          dialogTitle={objectEditUuid ? 'EditObject' : 'CreateObject'}
          modules={modules}
          tables={tables}
          columns={columns}
          enums={enums}
          relationships={relationships}
          objectId={objectEditUuid}
          entityMode={!!objectEditUuid}
          loadingData={loadingData}
          objectsWithDeps={objectsWithDeps}
        />
      )}
      <CodeEditorModal
        show={previewObjectUuid !== ''}
        handleClose={() => setPreviewObjectUuid('')}
        id={previewObjectUuid}
        previewType={CodePreviewType.OBJECT}
      />
    </div>
  );
}

export default ObjectsEditor;
