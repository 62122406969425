import React, { useState } from 'react';
import { Button, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { makeIndex } from '../../../../../../../routes/studio/data/elements/factory';
import { DatabaseStudioState } from '../../../../store';
import {
  Column,
  columnID,
  ColumnIndexType,
  IndexID,
  IndexType,
  Table,
  TableUUID
} from '../../../../../types';
import IndexSelector from './index_selector';
import styles from './style.module.css';
import { addIndex, deleteIndex } from 'modules/modeler/studio/store/actions/root';
import Icon from '../../../../../../../web_ui/icon';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from '../../../../store/actions/studio';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

export type IndexProps = {
  tables: { [key: TableUUID]: Table };
  columns: { [key: columnID]: Column };
  selectedTable: TableUUID;
};

function IndexComponent(props: IndexProps) {
  const [indexName, setIndexName] = useState('');
  const [indexType, setIndexType] = useState<string>(IndexType.INDEX);
  const [showIndexSelector, setShowIndexSelector] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<string>('');
  // Used to show or hide the delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const indexes = useSelector((state: DatabaseStudioState) => state.indexes);
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const [tableColumns, setTableColumnsSelected] = useState<ColumnIndexType[]>([]);
  const [previousName, setPreviousName] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const name: string = t('Name');
  const type: string = t('modeler.Type');

  function handleCreateIndex() {
    if (!indexName || indexName.length < 2) {
      dispatch(setErrorMessage('modeler.NameTooShortMessage'));
      return setIndexName(previousName);
    }
    if (indexType === IndexType.PRIMARY && props.selectedTable) {
      for (const i of Object.values(indexes)) {
        if (!i) continue;
        if (i.type !== IndexType.PRIMARY) continue;
        if (tables[props.selectedTable]?.content?.data?.indexes.includes(i.id))
          return dispatch(setErrorMessage('modeler.ExistingPrimaryIndex'));
      }
    }
    if (indexName && props.selectedTable) {
      const newIndex = makeIndex(indexName, indexType as IndexType);
      setIndexName('');
      setIndexType(IndexType.INDEX);
      setSelectedIndex(newIndex.id);
      setShowIndexSelector(true);
      dispatch(addIndex(newIndex, props.selectedTable));
    }
  }

  function handleDeleteIndex() {
    if (selectedIndex && props.selectedTable) {
      dispatch(deleteIndex(selectedIndex, props.selectedTable));
    }
    setSelectedIndex('');
    setShowDeleteModal(false);
  }

  function editIndex(e: React.MouseEvent, indexID: IndexID) {
    e.preventDefault();
    e.stopPropagation();

    setSelectedIndex(indexID);
    setShowIndexSelector(true);
  }

  function geettingTableColumns(tableColumnS: ColumnIndexType[]) {
    const ListToSave = tableColumnS.filter((indexColumn) => {
      return indexColumn.selected;
    });
    setTableColumnsSelected(ListToSave);
  }

  function checkDeleteRequirements(indexID: IndexID) {
    const tableIndexes = Object.values(tables[props.selectedTable].content.data.indexes);
    const indexType = indexes[indexID]?.type;

    if (tableIndexes.length < 2) return false;
    if (indexType !== IndexType.PRIMARY) return true;

    const primaryIndexes = tableIndexes.filter(
      (id) => indexes[id as string]?.type === IndexType.PRIMARY
    );
    return primaryIndexes.length > 1;
  }

  const isNameValid = (name: string) => {
    const regex = /^[a-zA-Z_][0-9a-zA-Z_]*$/;
    return regex.test(name);
  };

  return (
    <div className={`border-top ${styles.indexContainer}`}>
      <h6 className="text-body">{t('modeler.right_side.Indexes')}</h6>
      <ul className={` ${styles.indexListContainer}`}>
        <div style={{ maxHeight: 370, overflowY: 'auto' }}>
          <ListGroup>
            {props.tables[props.selectedTable].content.data.indexes < 1 ? (
              <p>{t('modeler.right_side.No Index')}</p>
            ) : (
              props.tables[props.selectedTable].content.data.indexes.map(
                (indexID: IndexID) =>
                  indexes[indexID] && (
                    <ListGroup.Item key={indexID} action>
                      <li
                        className={styles.indexListItem}
                        onClick={(e) => {
                          editIndex(e, indexID);
                        }}
                      >
                        <div className={styles.containerIndexItem}>
                          <div
                            className="text-body fw-regular"
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden'
                            }}
                          >
                            {indexes[indexID].name}
                          </div>
                          <i className={styles.indexType}>{indexes[indexID].type}</i>
                          <b className={styles.indexType2}>
                            ({Object.values(indexes[indexID].columns).length})
                          </b>
                        </div>

                        <div className={styles.listItemActions}>
                          <div
                            onClick={(e) => {
                              editIndex(e, indexID);
                            }}
                          >
                            <HelpPopover
                              placement={'top'}
                              helpBoxProps={{
                                title: t('editButton')!
                              }}
                            >
                              <Button
                                variant="body"
                                className={`btn-body`}
                                style={{ padding: '0' }}
                              >
                                <Icon iconName="edit" margin="0 8px 0 0"></Icon>
                              </Button>
                            </HelpPopover>
                          </div>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              if (checkDeleteRequirements(indexID)) {
                                setSelectedIndex(indexID);
                                setShowDeleteModal(true);
                              }
                            }}
                            style={{ opacity: checkDeleteRequirements(indexID) ? '1' : '0' }}
                          >
                            <HelpPopover
                              placement={'top'}
                              helpBoxProps={{
                                title: t('Delete')!
                              }}
                            >
                              <Button
                                variant="body"
                                className={`btn-body`}
                                style={{ padding: '0' }}
                              >
                                <Icon iconName="trash"></Icon>
                              </Button>
                            </HelpPopover>
                          </div>
                        </div>
                      </li>
                    </ListGroup.Item>
                  )
              )
            )}
          </ListGroup>
        </div>
      </ul>

      <div className={styles.newIndexContainer}>
        <div>
          <input
            className={`${styles.indexInput} border form-control form-control-sm`}
            type={'text'}
            placeholder={name}
            value={indexName}
            onFocus={() => setPreviousName(indexName)}
            onChange={(e) => {
              if (e.target.value.length > 64) {
                return dispatch(setErrorMessage('modeler.NameTooLongMessage'));
              }
              if (!isNameValid(e.target.value)) {
                dispatch(setErrorMessage('modeler.IllegalNameCharacters'));
                return setIndexName(previousName);
              }
              setIndexName(e.target.value);
            }}
          />
        </div>
        <div className={styles.indexContainerActions}>
          <select
            className="form-select form-select-sm"
            placeholder={type}
            value={indexType ?? IndexType.INDEX}
            onChange={(e) => {
              setIndexType(e.target.value);
            }}
          >
            {Object.values(IndexType)
              .filter((v) => isNaN(Number(v)))
              .map((type) => {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                );
              })}
          </select>

          <button
            className={`${styles.newIndexButton} btn d-inline-flex p-2 align-items-center btn-primary`}
            onClick={handleCreateIndex}
          >
            <Icon iconName="circle-plus" margin="0 0.5rem 0 0"></Icon>
            <p>{t('modeler.right_side.Create')}</p>
          </button>
        </div>

        <Modal show={showIndexSelector}>
          <Container>
            <Row>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-between mb-4 pt-4 pr-4 pl-4"
              >
                <h5
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    paddingRight: '0.25rem',
                    marginBottom: '0'
                  }}
                >
                  {`${t('modeler.right_side.Index')} ${
                    indexes[selectedIndex] && indexes[selectedIndex].name
                  }`}
                </h5>
                <button
                  className="bg-transparent border-0"
                  style={{ width: 'fitContent' }}
                  onClick={() => {
                    if (tableColumns.length === 0) {
                      const primaryIndexes = Object.values(
                        tables[props.selectedTable].content.data.indexes
                      ).filter((indexID: any) => {
                        return indexes[indexID] && indexes[indexID].type === IndexType.PRIMARY;
                      });
                      if (
                        indexes[selectedIndex].type !== IndexType.PRIMARY ||
                        primaryIndexes.length > 1
                      )
                        dispatch(deleteIndex(selectedIndex, props.selectedTable));
                      dispatch(setErrorMessage('modeler.InsertEmptyIndex'));
                    }
                    setShowIndexSelector(false);
                  }}
                >
                  <Icon iconName="close" />
                </button>
              </Col>
              <Col xs={12}>
                <IndexSelector
                  selectedIndex={selectedIndex}
                  selectedTable={props.selectedTable}
                  selectedColums={geettingTableColumns}
                  onExit={() => {
                    setShowIndexSelector(false);
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Modal>
      </div>
      <Confirmation
        show={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onConfirmation={handleDeleteIndex}
        message={`${t('modeler.Delete Index')} (${indexes[selectedIndex]?.name})`}
        onClose={() => {
          setShowDeleteModal(false);
        }}
      />
    </div>
  );
}

export default IndexComponent;
