import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ColorSelectorOverlay from './colorSelectorOverlay';
import ColorIcon from './colorIcon';
import useGetColor from 'modules/designer/hooks/getColor';
import { hexadecimalColorRegex, onlyHexadecimalColorRegex } from 'utils/regex';
import { sleep } from 'utils/utils';

function ColorPickerControl(props: ControlProps) {
  const { t } = useTranslation();
  const getColor = useGetColor();
  const [colorValue, setColorValue] = useState('');

  const handleGetValue = useCallback(() => {
    if (!props.id && props.value) {
      return props.value['themeColor'] ? props.value['themeColor'] : props.value['staticColor'];
    }
    return props.value;
  }, [props.id, props.value]);

  useEffect(() => {
    setColorValue(handleGetValue());
  }, [handleGetValue]);

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    if (!onlyHexadecimalColorRegex.test(e.target.value)) return;
    setColorValue(e.target.value);
  }

  function onChangeFinish() {
    if (!hexadecimalColorRegex.test(colorValue)) {
      setColorValue(handleGetValue());
      return;
    }
    handleChange(colorValue);
  }

  async function handleChange(newColor: string) {
    if (!props.onChange) return;

    if (props.id) {
      props.onChange(newColor, props.id);
      return;
    }

    props.onChange(newColor, newColor[0] === '#' ? 'staticColor' : 'themeColor');
    await sleep(300);
    props.onChange('', newColor[0] === '#' ? 'themeColor' : 'staticColor');
  }

  async function handleResetColors() {
    if (!props.onChange) return;

    if (!props.id) {
      props.onChange('', 'themeColor');
      await sleep(300);
      props.onChange('', 'staticColor');
      return;
    }

    props.onChange('', props.id);
  }

  return (
    <div
      className="mb-3"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <div style={{ display: 'flex', marginBottom: '.5rem' }}>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div>
            <label style={{ width: 'fit-content' }}>{props.label}</label>
            {props.tooltip && (
              <HelpPopover
                placement="top"
                helpBoxProps={{
                  description: t(`${props.tooltip}`)!
                }}
              >
                <HelpIcon />
              </HelpPopover>
            )}
          </div>

          <button
            className={`${styles.resetButton} btn btn-link link-underline-opacity-0 pe-0`}
            onClick={handleResetColors}
          >
            {t('designer.right_side.reset')}
          </button>
        </div>
      </div>

      <OverlayTrigger
        trigger="click"
        placement="left"
        rootClose
        overlay={
          <Popover id="popover-positioned-left">
            <Popover.Body>
              <ColorSelectorOverlay onChange={handleChange} selectedColor={handleGetValue()} />
            </Popover.Body>
          </Popover>
        }
      >
        <div
          className={`d-flex align-items-center justify-content-between border border-body border-radius rounded px-2`}
        >
          <div className="d-flex align-items-center">
            <ColorIcon
              colorValue={getColor(handleGetValue())}
              colorKey={
                !props.id && props.value && props.value['themeColor']
                  ? props.value['themeColor']
                  : ''
              }
            />
            <input
              type="text"
              value={colorValue ? getColor(colorValue) : '---'}
              onChange={handleInputChange}
              onBlur={onChangeFinish}
              className={`${styles.defaultInput} ${styles.colorHexa} form-control form-control-sm border-0 ms-1`}
              style={{ width: '92px' }}
              max={7}
            />
          </div>

          {props.value &&
            ((!props.id && props.value['themeColor' as any]) ||
              (props.id && typeof props.value === 'string' && !props.value.includes('#'))) && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover>
                    <div className="p-2">{t('designer.right_side.controls.ThemeColor')}</div>
                  </Popover>
                }
                delay={{ show: 300, hide: 0 }}
              >
                <span className="text-body-tertiary" style={{ fontSize: '0.8rem' }}>
                  <Icon iconName="paint-brush"></Icon>
                </span>
              </OverlayTrigger>
            )}
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default ColorPickerControl;
