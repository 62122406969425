import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'modules/auth/services';

import styles from './styles.module.css';
import { PopupAlert } from 'web_ui/popup_alert';

type ErrorMessageProps = {
  setgettingValueError: React.Dispatch<React.SetStateAction<boolean>>;
  valueError: string;
  email: string;
};

export default function ErrorMessage(props: ErrorMessageProps) {
  const { t } = useTranslation();

  const resendVerificationEmail = async () => {
    if (props.valueError === "User isn't enabled") {
      props.setgettingValueError(false);
      await AuthService.resendVerificationEmail(props.email.toLowerCase());
    }
  };

  const onClose = () => {
    props.setgettingValueError(false);
  };

  return (
    <PopupAlert
      className={styles.container}
      onClose={onClose}
      variant={'danger'}
      onClick={resendVerificationEmail}
    >
      {props.valueError === 'Bad credentials' ? (
        t('login_page.PasswordEmailWrong')
      ) : props.valueError === "User isn't enabled" ? (
        <>
          <div>{"User isn't enabled"}</div>
          <div>Click here to request a new verification email.</div>
        </>
      ) : (
        props.valueError
      )}
    </PopupAlert>
  );
}
