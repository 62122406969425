import React, { ChangeEvent, ComponentType, useCallback, useEffect, useState } from 'react';
import { ControlProps } from '../index';
import { Button, Form, InputGroup, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { DefaultLabel } from '../styles';
import styles from '../styles.module.css';
import {
  DataSource,
  DataTableColumn
} from '../../../../../modules/designer/studio/exocode_components/data_table';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../../../../modules/designer/studio/store';
import { NUMERIC_DATA_TYPES, SchemaObject } from '../../../../../modules/logic_builder/types';
import { FRONTEND_VARIABLE_TYPES, VariableTypes } from '../../../../../modules/designer/types';
import { deleteVariable } from '../../../../../modules/designer/studio/store/actions/variables';
import { useParams } from 'react-router-dom';
import { FORMATTER_LIST, FORMATTER_MAPPER, FormatterTypes } from './types';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';
import { ControlRequirements } from '../requirement_messages/ControlRequirements';
import { CONTROL_REQUIREMENT_TYPES, ControlRequirementsTypes } from '../requirement_messages';
import ManageVariableDialog from 'modules/designer/studio/components/manage_variable_dialog';
import { WritableDraft } from 'immer/dist/internal';
import Icon from 'web_ui/icon';
import { ObjectsService } from 'modules/logic_builder/services';

export type Schema = {
  description: string;
  uuid: string;
  properties: { [key: string]: string[] };
  items: { [key: string]: SchemaItem };
};

export type SchemaItem = {
  type: string;
  id: string;
  uuid: string;
  name: string;
  list: boolean;
  object?: { id: string };
};

function DataTableSource(props: ControlProps & { value: DataSource }) {
  const { view_id } = useParams();
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<DataSource>();
  const [endpointObject, setEndpointObject] = useState<SchemaObject>();
  const variables = useSelector((state: InterfaceStudioState) => state.variables);
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const dispatch = useDispatch();
  const [showCreateVariableDialog, setShowCreateVariableDialog] = useState(false);
  const [createdVariableId, setCreatedVariableId] = useState<string>('');
  const [createdVariableType, setCreatedVariableType] = useState<VariableTypes>();
  const [createdVariableIsList, setCreatedVariableIsList] = useState<boolean>();
  const [searchString, setSearchString] = useState<string>('');
  const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    props.value && setDataSource(props.value);
    if (props.value && props.value.variable) {
      const variable = variables[props.value?.variable];
      if (variable && variable.objectUuid) setEndpointObject(objects[variable.objectUuid]);
    }
  }, [props.value, objects, variables]);

  useEffect(() => {
    if (!props.onChange) return;
    if (!Object.keys(variables).length) return;
    if (createdVariableId != '') {
      // data source variable
      if (
        Object.keys(variables).length &&
        createdVariableId &&
        createdVariableType == 'OBJECT' &&
        createdVariableIsList
      ) {
        handleVariableChange({
          target: { value: createdVariableId }
        } as ChangeEvent<HTMLSelectElement>);
        return;
      }
      // resets the control state
      setCreatedVariableId('');
    }
  }, [variables.length, createdVariableId]);

  function filterByBackendAndFrontendAndExported(item: SchemaItem) {
    const itemId = item.object?.id ?? '';
    const obj = objects[itemId];
    // obj.
  }

  /**
   * columns: [] // 106
   selectedItem: "" // 100
   variable: "" // 69
   */
  async function handleVariableChange(e: ChangeEvent<HTMLSelectElement>) {
    if (!props.onChange || !dataSource) return;

    const newDataSource = await produce(dataSource, async (draft) => {
      draft.variable = e.target.value;
      draft.columns = [];

      if (!e.target.value) {
        if (draft.selectedItem && view_id) dispatch(deleteVariable(view_id, draft.selectedItem));
        return;
      }

      const variableSchema = variables[e.target.value].objectUuid;
      if (!variableSchema) {
        if (draft.selectedItem && view_id) dispatch(deleteVariable(view_id, draft.selectedItem));
        return;
      }

      const obj = await ObjectsService.getObject(variableSchema);

      const shouldShowAllFields = obj.frontend && !obj.backend;

      const schema = objects[variableSchema];

      if (!schema?.uuid || !schema.objectItems) return;
      draft.nestedObjectsPath = {};
      schema.objectItems
        .filter((item) => shouldShowAllFields || item.exported)
        .forEach((objectItem, index) => {
          if (!objectItem.uuid) return;

          if ((objectItem as any as SchemaItem).object?.id) {
            handleGenerateSubItemsColumns(objectItem as any as SchemaItem, draft);
          } else {
            draft.columns.push({
              title: objectItem.exportedName
                ? objectItem.exportedName?.charAt(0).toUpperCase() +
                  objectItem.exportedName?.slice(1)
                : objectItem?.name.charAt(0).toUpperCase() + objectItem.name.slice(1),
              show: true,
              schemaItemUuid: objectItem.uuid,
              type: objectItem.type,
              order: index
            });
          }
        });
    });

    props.onChange(newDataSource, 'dataSource');
    setDataSource(newDataSource);
  }

  function handleColumnChange<K extends keyof DataTableColumn>(
    column: number,
    key: K,
    value: DataTableColumn[K]
  ) {
    if (!props.onChange || !dataSource) return;
    let newDataSource = null;

    if (key !== 'order') {
      newDataSource = produce(dataSource, (draft) => {
        draft.columns[column][key] = value;
      });
    } else {
      if (Number(value) >= 0 && Number(value) < dataSource.columns.length)
        newDataSource = produce(dataSource, (draft) => {
          const originOrder = draft.columns[column].order;
          const targetOrder = Number(value);
          draft.columns.forEach((columnDraft) => {
            if (
              (!originOrder && originOrder !== 0) ||
              (!targetOrder && targetOrder !== 0) ||
              (!columnDraft.order && columnDraft.order !== 0)
            )
              return;
            if (
              columnDraft.schemaItemUuid !== undefined &&
              columnDraft.schemaItemUuid !== draft.columns[column].schemaItemUuid
            ) {
              const isOriginBeforeTarget = originOrder < targetOrder;

              if (
                (isOriginBeforeTarget &&
                  columnDraft.order > originOrder &&
                  columnDraft.order <= targetOrder) ||
                (!isOriginBeforeTarget &&
                  columnDraft.order < originOrder &&
                  columnDraft.order >= targetOrder)
              ) {
                columnDraft.order = isOriginBeforeTarget
                  ? columnDraft.order - 1
                  : columnDraft.order + 1;
              }
            } else if (columnDraft.schemaItemUuid === draft.columns[column].schemaItemUuid) {
              columnDraft.order = targetOrder;
            }
          });
        });
    }
    if (newDataSource) {
      const sortedColumns = newDataSource?.columns
        .slice()
        .sort((columnA, columnB) => columnA.order! - columnB.order!);
      const updatedDataSource: DataSource = { ...dataSource, columns: sortedColumns };

      setDataSource(updatedDataSource);
      props.onChange(updatedDataSource, 'dataSource');
    }
  }

  const handleGetObjectItem = useCallback(
    (schemaUuid: string) => {
      if (!dataSource || !variables[dataSource.variable]) return null;
      const variable = variables[dataSource.variable];
      const objectUuid = variable.objectUuid;
      if (!objectUuid) return null;
      const object =
        !dataSource.nestedObjectsPath || !dataSource.nestedObjectsPath[schemaUuid]
          ? objects[objectUuid]
          : objects[dataSource.nestedObjectsPath[schemaUuid].parentObject];
      if (!object || !object.objectItems) return null;
      const objectItem = object.objectItems.find((item) => item.uuid === schemaUuid);
      return objectItem;
    },
    [dataSource, objects, variables]
  );

  const handleGetFormatters = useCallback(
    (schemaUuid: string) => {
      const objectItem = handleGetObjectItem(schemaUuid);
      if (!objectItem || !objectItem.dataType) return [];
      const dataType = objectItem.dataType.toLowerCase();
      const formatters = FORMATTER_MAPPER[dataType.charAt(0).toUpperCase() + dataType.slice(1)];
      return formatters || [];
    },
    [handleGetObjectItem]
  );

  const handleShowCreateVariableDialog = (): void => {
    setShowCreateVariableDialog(true);
  };

  const handleCloseCreateVariableDialog = (
    idVariable?: string,
    variableType?: VariableTypes,
    isList?: boolean
  ): void => {
    setShowCreateVariableDialog(false);
    if (idVariable) setCreatedVariableId(idVariable);
    if (variableType) setCreatedVariableType(variableType);
    if (isList) setCreatedVariableIsList(isList);
  };

  async function handleGenerateSubItemsColumns(
    objectItem: SchemaItem,
    draft: WritableDraft<DataSource>
  ) {
    if (!objectItem.object?.id) return;

    const schema = objects[objectItem.object?.id];
    if (!schema?.uuid || !schema.objectItems) return;

    const obj = await ObjectsService.getObject(schema.uuid);

    const shouldShowAllFields = obj.frontend && !obj.backend;

    schema.objectItems
      .filter((item) => shouldShowAllFields || item.exported)
      .forEach((subObjectItem, index) => {
        if (!subObjectItem.uuid) return;

        draft.nestedObjectsPath![subObjectItem.uuid] = {
          parentObject: schema.uuid!,
          parentObjectItem: objectItem.uuid
        };

        if ((subObjectItem as any as SchemaItem).object?.id && objectItem.object?.id) {
          handleGenerateSubItemsColumns(subObjectItem as any as SchemaItem, draft);
        } else {
          draft.columns.push({
            title: subObjectItem.exportedName
              ? subObjectItem.exportedName?.charAt(0).toUpperCase() +
                subObjectItem.exportedName?.slice(1)
              : subObjectItem?.name.charAt(0).toUpperCase() + subObjectItem.name.slice(1),
            show: true,
            schemaItemUuid: subObjectItem.uuid,
            objectUuid: (subObjectItem as any as SchemaItem).object?.id,
            type: subObjectItem.type,
            order: index
          });
        }
      });
  }

  function getSchemaItemName(
    schemaItemUuid: string,
    schemaObject?: SchemaObject
  ): string | undefined {
    return schemaObject?.objectItems?.find((item) => item.uuid === schemaItemUuid)?.name;
  }

  // Get leaf item name (for linear objects) or the nested objects path (with names of each layer item)
  const handleGetLeafSchemaItem = useCallback(
    (column: DataTableColumn) => {
      if (!column.schemaItemUuid) return;

      if (!dataSource?.nestedObjectsPath || !dataSource?.nestedObjectsPath[column.schemaItemUuid]) {
        const firstLevelItem = getSchemaItemName(column.schemaItemUuid, endpointObject);
        return '.' + firstLevelItem;
      } else {
        let schemaNestedObjectsPath = '';
        let currentSchemaItem = column.schemaItemUuid;
        while (dataSource.nestedObjectsPath[currentSchemaItem]) {
          const { parentObject, parentObjectItem } =
            dataSource.nestedObjectsPath[currentSchemaItem];
          schemaNestedObjectsPath +=
            getSchemaItemName(currentSchemaItem, objects[parentObject]) + '.';
          currentSchemaItem = parentObjectItem;
        }
        schemaNestedObjectsPath += getSchemaItemName(currentSchemaItem, endpointObject) + '.';

        return schemaNestedObjectsPath.split('.').reverse().join('.');
      }
    },
    [dataSource?.nestedObjectsPath, endpointObject, objects]
  );

  const handleGetObjectItems = useCallback(
    (column: DataTableColumn) => {
      if (!column.schemaItemUuid) return;

      const nestedObjectsPath = dataSource?.nestedObjectsPath;
      const parentObject = nestedObjectsPath?.[column.schemaItemUuid]?.parentObject;

      return parentObject ? objects[parentObject]?.objectItems : endpointObject?.objectItems;
    },
    [dataSource?.nestedObjectsPath, endpointObject?.objectItems, objects]
  );

  const checkIsNestedObject = useCallback(
    (schemaItemUuid: string) => {
      return !(dataSource?.nestedObjectsPath && dataSource.nestedObjectsPath[schemaItemUuid]);
    },
    [dataSource?.nestedObjectsPath]
  );

  return (
    <>
      <div className="mb-3 border-bottom pb-3">
        <DefaultLabel className={`${styles.defaultLabel} form-label`}>
          <MissingMessage
            type={MissingMessageType.SOURCE_VARIABLE}
            uuid={dataSource?.variable ? dataSource?.variable : ''}
            requiredTypes={[FRONTEND_VARIABLE_TYPES.OBJECT as VariableTypes]}
          />
          {t('designer.right_side.DataSource')}
        </DefaultLabel>

        {Object.values(variables).filter((variable) => variable.type === 'OBJECT' && variable.list)
          .length > 0 ? (
          <Form.Select value={dataSource?.variable} onChange={handleVariableChange}>
            <option value="">---</option>

            {Object.values(variables)
              .filter((variable) => variable.type === 'OBJECT' && variable.list)
              .map((variable) => (
                <option key={variable.uuid} value={variable.uuid}>
                  ${variable.name}
                </option>
              ))}
          </Form.Select>
        ) : (
          <ControlRequirements
            controlRequirementType={
              CONTROL_REQUIREMENT_TYPES.LIST_VARIABLE as ControlRequirementsTypes
            }
            onClick={handleShowCreateVariableDialog}
          />
        )}

        <br />
        {dataSource?.variable && (
          <>
            <DefaultLabel className={`${styles.defaultLabel} form-label`}>
              {t('designer.right_side.Columns')}
            </DefaultLabel>

            <div className="mb-2 d-flex justify-content-between">
              <Form.Control
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder={t('designer.right_side.Search') + '...'}
                style={{ width: '75%' }}
              />
              <Button
                variant="body"
                className="border rounded"
                style={{ width: 'fit-content' }}
                onClick={() => setOrderBy(orderBy === 'asc' ? 'desc' : 'asc')}
              >
                {<Icon iconName={orderBy === 'asc' ? 'arrow-up-a-z' : 'arrow-down-z-a'}></Icon>}
              </Button>
            </div>
          </>
        )}

        <ListGroup style={{ maxHeight: '330px', overflowY: 'auto' }}>
          {dataSource?.columns
            // .filter((column) => column.title.toLowerCase().includes(searchString.toLowerCase()))
            // .sort((column1, column2) => handleSortByField(column1, column2, 'title', orderBy))
            .map((column: DataTableColumn, index: number) => {
              let FormatterControlComponent = null;
              if (column.formatter) {
                FormatterControlComponent = FORMATTER_LIST[column.formatter] as ComponentType<any>;
              }

              return (
                <OverlayTrigger
                  key={index}
                  trigger="click"
                  placement="left"
                  rootClose
                  overlay={
                    <Popover id="popover-positioned-left" style={{ maxWidth: '500px' }}>
                      <Popover.Body>
                        <div className="mb-3 border-bottom">
                          ${variables[dataSource?.variable]?.name ?? 'Invalid var'}
                          {handleGetLeafSchemaItem(column)}
                        </div>
                        <div className="mb-3 border-bottom">
                          <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-sm">
                              {t('designer.right_side.Field')}
                            </InputGroup.Text>
                            <Form.Select
                              value={column.schemaItemUuid}
                              onChange={(e) => {
                                handleColumnChange(index, 'schemaItemUuid', e.target.value);
                              }}
                            >
                              {handleGetObjectItems(column)?.map((item) => {
                                return (
                                  <option key={item.uuid} value={item.uuid}>
                                    {item?.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </InputGroup>
                          <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-sm">
                              {t('designer.right_side.Title')}
                            </InputGroup.Text>
                            <Form.Control
                              value={column.title}
                              onChange={(e) => handleColumnChange(index, 'title', e.target.value)}
                            />
                          </InputGroup>

                          <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-sm">
                              {t('designer.right_side.Order')}
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              min={0}
                              max={dataSource?.columns.length - 1}
                              value={column.order}
                              onChange={(e) =>
                                handleColumnChange(index, 'order', Number(e.target.value))
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="mb-3 pb-2 border-bottom">
                          {checkIsNestedObject(column.schemaItemUuid) && (
                            <>
                              <Form.Check
                                type="switch"
                                id="custom-switch-sortable"
                                label={t('designer.right_side.Sortable')}
                                checked={column.sortable}
                                onChange={(e) =>
                                  handleColumnChange(index, 'sortable', !column.sortable)
                                }
                              />
                              <Form.Check
                                type="switch"
                                id="custom-switch-filterable"
                                label={t('designer.right_side.Filterable')}
                                checked={column.filterable}
                                onChange={(e) =>
                                  handleColumnChange(index, 'filterable', !column.filterable)
                                }
                              />
                            </>
                          )}

                          <Form.Check
                            type="switch"
                            id="custom-switch-editable"
                            label={t('designer.right_side.Editable')}
                            checked={column.editable}
                            onChange={(e) =>
                              handleColumnChange(index, 'editable', !column.editable)
                            }
                          />
                          <Form.Check
                            type="switch"
                            id="custom-switch-hidden"
                            label={t('designer.right_side.hide')}
                            checked={column.hidden}
                            onChange={(e) => handleColumnChange(index, 'hidden', !column.hidden)}
                          />
                          {column.type &&
                            Object.keys(NUMERIC_DATA_TYPES).includes(
                              column.type as (typeof NUMERIC_DATA_TYPES)[keyof typeof NUMERIC_DATA_TYPES]
                            ) && (
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={
                                  <>
                                    {t('designer.right_side.FooterSum')}
                                    <HelpPopover
                                      helpBoxProps={{
                                        title:
                                          t('designer.right_side.controls.FooterSumTitle') ||
                                          'designer.right_side.controls.FooterSumTitle',
                                        description:
                                          t('designer.right_side.controls.FooterSumDescription') ||
                                          'designer.right_side.controls.FooterSumDescription',
                                        note: [
                                          t('designer.right_side.controls.FooterSumNote01') ||
                                            'designer.right_side.controls.FooterSumNote01',
                                          t('designer.right_side.controls.FooterSumNote02') ||
                                            'designer.right_side.controls.FooterSumNote02'
                                        ]
                                      }}
                                      placement="top"
                                    >
                                      <HelpIcon />
                                    </HelpPopover>
                                  </>
                                }
                                checked={column.footerSum}
                                onChange={(e) =>
                                  handleColumnChange(index, 'footerSum', !column.footerSum)
                                }
                              />
                            )}
                        </div>

                        <div className="mb-3">
                          {t('designer.right_side.Formatter')}
                          <HelpPopover
                            helpBoxProps={{
                              title:
                                t('designer.right_side.FormatterControls.FormatterTitle') ||
                                'designer.right_side.FormatterControls.FormatterTitle',
                              description:
                                t('designer.right_side.FormatterControls.FormatterDescription') ||
                                'designer.right_side.FormatterControls.FormatterDescription',
                              note: [
                                t('designer.right_side.FormatterControls.FormatterNote01') ||
                                  'designer.right_side.FormatterControls.FormatterNote01'
                              ]
                            }}
                            placement="top"
                          >
                            <HelpIcon />
                          </HelpPopover>
                          <InputGroup size="sm" className="mt-2">
                            <InputGroup.Text id="inputGroup-sizing-sm">
                              {t('designer.right_side.Type')}
                            </InputGroup.Text>
                            <Form.Select
                              value={column.formatter}
                              onChange={async (e) => {
                                handleColumnChange(
                                  index,
                                  'formatter',
                                  e.target.value as FormatterTypes
                                );
                              }}
                            >
                              <option value="">---</option>

                              {column &&
                                column.schemaItemUuid &&
                                handleGetFormatters(column.schemaItemUuid)?.map((formatter) => (
                                  <option key={formatter} value={formatter}>
                                    {formatter}
                                  </option>
                                ))}
                            </Form.Select>
                          </InputGroup>

                          {FormatterControlComponent && (
                            <FormatterControlComponent
                              id="formatterParams"
                              label={column.formatter}
                              index={index}
                              onChange={handleColumnChange}
                              value={column.formatterParams}
                            />
                          )}
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <ListGroup.Item
                    className="d-flex justify-content-between align-items-center"
                    action
                  >
                    {column.title}
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleColumnChange(index, 'show', !column.show)}
                    >
                      {column.show && <i className="fa-sharp fa-solid fa-eye"></i>}
                      {!column.show && <i className="fa-solid fa-eye-slash"></i>}
                    </div>
                  </ListGroup.Item>
                </OverlayTrigger>
              );
            })}

          {dataSource?.variable &&
            dataSource?.columns.filter((column) =>
              column.title.toLowerCase().includes(searchString.toLowerCase())
            ).length === 0 && <span className="mt-2">{t('designer.right_side.NoData')}</span>}
        </ListGroup>
      </div>
      {view_id && (
        <ManageVariableDialog
          view_id={view_id}
          show={showCreateVariableDialog}
          onClose={handleCloseCreateVariableDialog}
          editMode={false}
          variable={{
            uuid: '',
            name: '',
            type: 'OBJECT',
            native: false,
            list: true
          }}
        />
      )}
    </>
  );
}

export default DataTableSource;
