import { AdditionalModules, BackendFramework, DataSource } from 'modules/project/enum';
import { BackendTechOption } from './index';

export const BackendFrameworkList: BackendTechOption<BackendFramework>[] = [
  {
    id: BackendFramework.SPRING,
    name: 'Java Spring Boot',
    icon: 'gear'
  }
];

export const DatabaseList: BackendTechOption<DataSource>[] = [
  {
    id: DataSource.POSTGRESQL,
    name: 'PostgreSQL',
    icon: 'gear'
  },
  {
    id: DataSource.H2,
    name: 'H2',
    icon: 'file-invoice'
  },
  {
    id: DataSource.MYSQL,
    name: 'MYSQL',
    icon: 'database'
  }
];

export const additionalmodules: BackendTechOption<AdditionalModules>[] = [
  {
    id: AdditionalModules.AUTHENTICATION,
    name: 'Authentication',
    icon: 'shield-halved'
  },
  {
    id: AdditionalModules.ASSETS,
    name: 'Assets',
    icon: 'images'
  }
];
