import { EnumColumnsState, initialState } from '..';
import produce from 'immer';
import {
  ADD_ENUMCOLUMN,
  addEnumColumnAction,
  DELETE_ENUMCOLUMN,
  deleteEnumColumnAction,
  EnumColumnsActions,
  changeEnumColOrdinalAction,
  CHANGE_ENUMCOL_ORDINAL,
  CHANGE_ENUMCOL_DESCRIPTION,
  changeEnumColLiteralAction,
  CHANGE_ENUMCOL_LITERAL,
  SET_ENUMCOLUMNS,
  setEnumColumnsAction,
  changeEnumColDescriptionAction,
  MOVE_ENUM_COLUMN,
  MoveEnumColumnAction
} from '../actions/enum_column';
import { EnumColumn } from '../../../types';

export const enumColumnsReducer = (
  state = initialState.enum_columns,
  action: EnumColumnsActions
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_ENUMCOLUMN:
        return doAddEnumColumn(draft, action);
      case DELETE_ENUMCOLUMN:
        return doDeleteEnumColumn(draft, action);
      case CHANGE_ENUMCOL_LITERAL:
        return doChangeEnumColLiteral(draft, action);
      case CHANGE_ENUMCOL_ORDINAL:
        return doChangeEnumColOrdinal(draft, action);
      case CHANGE_ENUMCOL_DESCRIPTION:
        return doChangeEnumColDescription(draft, action);
      case SET_ENUMCOLUMNS:
        return doSetEnumColumns(draft, action);
      case MOVE_ENUM_COLUMN:
        return doMoveEnumColumn(draft, action);
      default:
        return draft;
    }
  });
};

function doMoveEnumColumn(state: EnumColumnsState, action: MoveEnumColumnAction): EnumColumnsState {
  const swappedColumnOrder = state[action.payload.swappedColumn].columnOrder;
  state[action.payload.swappedColumn].columnOrder = state[action.payload.column].columnOrder;
  state[action.payload.column].columnOrder = swappedColumnOrder;
  return state;
}

function doAddEnumColumn(state: EnumColumnsState, action: addEnumColumnAction) {
  state[action.payload.id] = action.payload;
}

function doDeleteEnumColumn(state: EnumColumnsState, action: deleteEnumColumnAction) {
  const enumColumnID = action.payload.id;

  // TODO: delete links

  delete state[enumColumnID];
  return state;
}

function doChangeEnumColOrdinal(state: EnumColumnsState, action: changeEnumColOrdinalAction) {
  state[action.payload.id].ordinalValue = action.payload.value;
  return state;
}

function doChangeEnumColDescription(
  state: EnumColumnsState,
  action: changeEnumColDescriptionAction
) {
  state[action.payload.id].description = action.payload.value;
  return state;
}

function doChangeEnumColLiteral(state: EnumColumnsState, action: changeEnumColLiteralAction) {
  state[action.payload.id].literalValue = action.payload.value;
  return state;
}

function doSetEnumColumns(state: EnumColumnsState, action: setEnumColumnsAction) {
  state = {};
  action.payload.enumColumns.forEach((enumColumn: EnumColumn) => {
    state[enumColumn.id] = enumColumn;
  });
  return state;
}
