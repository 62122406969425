import { LayoutComponent } from '../../../types';
import { ListItemType } from './types/list_item_type';
import { ListData } from './types/list_data';

export const LIST_TEMPLATE: LayoutComponent<ListData, any> = {
  uuid: 'empty',
  type: 'LIST',
  data: {
    type: ListItemType.CARD,
    actions: [
      {
        function: (item: any) => null,
        title: 'Delete',
        icon: 'FaTrash',
        variant: 'danger',
        iconHeight: '1rem'
      },
      {
        function: (item: any) => null,
        title: 'Edit',
        icon: 'FaPenSquare',
        variant: 'secondary',
        iconHeight: '1rem'
      }
    ],
    numberOfColumns: 3,
    sortable: false,
    selectable: false,
    searchable: false,
    pageable: false,
    gap: '0.25rem'
  },
  styles: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    borderTop: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 12,
    paddingRight: 12,
    css: ''
  }
};
