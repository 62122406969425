import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-bootstrap';
import { RoleAuthorities } from 'modules/auth/types/auth_types';
import { RoleType } from 'modules/organization/types';

import styles from './styles.module.css';
import './role.css';

type AuthoritiesListProps = {
  role: RoleType;
  onChangeAuthority: (role: RoleType) => void;
};

function AuthoritiesList({ role, onChangeAuthority }: AuthoritiesListProps) {
  const { t } = useTranslation();

  const checkAuthority = (authority: RoleAuthorities) => {
    return role.authorities.includes(authority);
  };

  const disableAuthority = () => {
    return !role.isCustom;
  };

  const selectAuthority = (checked: boolean, authority: RoleAuthorities) => {
    if (checked) {
      role.authorities.push(authority);
    } else {
      const index = role.authorities.indexOf(authority);
      role.authorities.splice(index, 1);
    }
    onChangeAuthority(role);
  };

  return (
    <Accordion defaultActiveKey={['0']} alwaysOpen>
      <Accordion.Item id={'accordionOrganizations'} eventKey="0">
        <Accordion.Header id={'HeaderAccordionOrganizations'}>
          <h6 className={`${styles.sectionTitle} text-body-emphasis`}>
            {t('organizations.Organizations')}
          </h6>
        </Accordion.Header>
        <Accordion.Body className={`${styles.bodyPermissions}`}>
          {/* INVITE_USER */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.INVITE_USER.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.INVITE_USER)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.INVITE_USER)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.INVITE_USER')}</div>
          </div>
          {/* CREATE_CUSTOM_ROLE */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.CREATE_CUSTOM_ROLE.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.CREATE_CUSTOM_ROLE)}
              onChange={(e) =>
                selectAuthority(e.target.checked, RoleAuthorities.CREATE_CUSTOM_ROLE)
              }
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.CREATE_CUSTOM_ROLE')}</div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id={'accordionProjects'} eventKey="1">
        <Accordion.Header>
          <h6 className={`${styles.sectionTitle} text-body-emphasis`}>
            {t('organizations.organization.Projects')}
          </h6>
        </Accordion.Header>
        <Accordion.Body className={`${styles.bodyPermissions}`}>
          {/* MANAGE_APP */}
          <div className="d-flex">
            <input
              id={`${RoleAuthorities.MANAGE_APP.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_APP)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.MANAGE_APP)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_APP')}</div>
          </div>
          {/* DELETE_APP */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.DELETE_APP.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.DELETE_APP)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.DELETE_APP)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.DELETE_APP')}</div>
          </div>
          {/* MANAGE_MODULE */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.MANAGE_MODULE.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_MODULE)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.MANAGE_MODULE)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_MODULE')}</div>
          </div>
          {/* DELETE_MODULE */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.DELETE_MODULE.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.DELETE_MODULE)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.DELETE_MODULE)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.DELETE_MODULE')}</div>
          </div>
          {/* GENERATE_CODE */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.GENERATE_CODE.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.GENERATE_CODE)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.GENERATE_CODE)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.GENERATE_CODE')}</div>
          </div>
          {/* MANAGE_ACCESS */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.MANAGE_TEAM.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_ACCESS)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.MANAGE_ACCESS)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_ACCESS')}</div>
          </div>
          {/* MANAGE_TEAMS */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.MANAGE_TEAM.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_TEAM)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.MANAGE_TEAM)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_TEAM')}</div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id={'accordionCode'} eventKey="2">
        <Accordion.Header>
          <h6 className="text-body-emphasis">{t('organizations.organization.Code')}</h6>
        </Accordion.Header>
        <Accordion.Body className={`${styles.bodyPermissions}`}>
          {/* MANAGE_INTERFACE */}
          <div className="d-flex">
            <input
              id={`${RoleAuthorities.MANAGE_INTERFACE.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_INTERFACE)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.MANAGE_INTERFACE)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_INTERFACE')}</div>
          </div>
          {/* MANAGE_ID_OPERATIONS */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.MANAGE_ID_OPERATIONS.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_ID_OPERATIONS)}
              onChange={(e) =>
                selectAuthority(e.target.checked, RoleAuthorities.MANAGE_ID_OPERATIONS)
              }
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_ID_OPERATIONS')}</div>
          </div>
          {/* MANAGE_LOGIC_BUILDER */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.MANAGE_LOGIC_BUILDER.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_LOGIC_BUILDER)}
              onChange={(e) =>
                selectAuthority(e.target.checked, RoleAuthorities.MANAGE_LOGIC_BUILDER)
              }
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_LOGIC_BUILDER')}</div>
          </div>
          {/* MANAGE_DB_MODELER */}
          <div className="d-flex pt-2">
            <input
              id={`${RoleAuthorities.MANAGE_DB_MODELER.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_DB_MODELER)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.MANAGE_DB_MODELER)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_DB_MODELER')}</div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id={'accordionBilling'} eventKey="4">
        <Accordion.Header>
          <h6 className="text-body-emphasis">{t('organizations.organization.Billing')}</h6>
        </Accordion.Header>
        <Accordion.Body className={`${styles.bodyPermissions}`}>
          {/* BILLING */}
          <div className="d-flex">
            <input
              id={`${RoleAuthorities.BILLING.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.BILLING)}
              onChange={(e) => selectAuthority(e.target.checked, RoleAuthorities.BILLING)}
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.BILLING')}</div>
          </div>
          {/* MANAGE_PAYMENT_METHODS */}
          <div className="d-flex">
            <input
              id={`${RoleAuthorities.MANAGE_PAYMENT_METHODS.toLowerCase()}_checkbox`}
              className="form-check-input"
              type="checkbox"
              checked={checkAuthority(RoleAuthorities.MANAGE_PAYMENT_METHODS)}
              onChange={(e) =>
                selectAuthority(e.target.checked, RoleAuthorities.MANAGE_PAYMENT_METHODS)
              }
              disabled={disableAuthority()}
            />
            <div className="ps-4">{t('organizations.roles.MANAGE_PAYMENT_METHODS')}</div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AuthoritiesList;
