import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DateTime } from 'luxon';

import translationPT from './translation/pt.json';
import translationEN from './translation/en.json';
import translationBR from './translation/br.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  lng: localStorage.getItem('language') || 'en',
  resources: {
    en: {
      translation: translationEN
    },
    pt: {
      translation: translationPT
    },
    br: {
      translation: translationBR
    }
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value, format, lng) => {
      if (value instanceof Date) {
        return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format]);
      }
      return value;
    }
  }
});

export default i18n;
