import { Column, IndexType } from '../../../types';
import styles from './styles.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../store';

type ColumnIconProps = {
  // selected table id
  column: Column;
};

export default function ColumnIcon(props: ColumnIconProps) {
  // context indexes array
  const indexes = useSelector((state: DatabaseStudioState) => state.indexes);

  const isPK = props.column.isPK;
  const isFK = props.column.isFK;
  const isAK =
    Object.values(indexes)
      .filter((index) => index.type === IndexType.UNIQUE)
      .flatMap((index) => Object.values(index.columns))
      .filter((idxCol) => idxCol.columnId === props.column.uuid)?.length === 1;
  const styleIcon = isPK || isAK ? `fa-key` : isFK ? `fa-link` : ``;
  const styleColor = isPK ? styles.primaryKey : isFK ? styles.foreignKey : ``;
  return <i className={`fa ${styleIcon} ${styleColor}`} />;
}
