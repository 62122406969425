import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import BackendFunctionEditor from './editor';
import StoreUpdater from './store/store_updater';
import { FunctionEditorState } from '../../../web_ui/function_editor/store/types/function_editor_state';

type LogicBuilderFunctionEditorProps = {
  moduleId: string;
  functionId: string;
  state: FunctionEditorState;
};

function LogicBuilderFunctionEditor({
  moduleId,
  functionId,
  state
}: LogicBuilderFunctionEditorProps) {
  if (!state.editor.functionId) return null;
  return (
    <Provider store={store}>
      {moduleId && <StoreUpdater moduleId={moduleId} functionId={functionId} state={state} />}
      <BackendFunctionEditor />
    </Provider>
  );
}

export default LogicBuilderFunctionEditor;
