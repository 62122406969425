import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FunctionSimple } from 'modules/logic_builder/types';
import { FunctionService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import { useTranslation } from 'react-i18next';
import { functionNameRegex } from '../../../../../utils/regex';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';

type FunctionCreatorDialogProps = {
  serviceUuid: string;
  show: boolean;
  onClose: () => void;
};

export function FunctionCreatorDialog(props: FunctionCreatorDialogProps) {
  const [newFunction, setNewFunction] = useState<FunctionSimple>();
  const queryParameters = useQuery();
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const { fetchFunctions, module_id } = useContext(LogicBuilderContext);
  const { app_id, service_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (!props.show) return;

    setNewFunction({
      name: '',
      description: '',
      serviceUuid: props.serviceUuid
    });
  }, [props.show, props.serviceUuid]);

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    setErrorMessage('');
    event.preventDefault();
    if (isLoading) return;
    if (!module_id || !newFunction) return;

    setIsLoading(true);
    await FunctionService.createFunction(module_id, newFunction)
      .then((res) => {
        fetchFunctions(module_id);
        if (res?.uuid) {
          navigate(
            isFromVsCodeExt()
              ? `/app/${app_id}/module/${module_id}/logic/service/${service_id}/function-editor/${res.uuid}?vscode=true`
              : `/app/${app_id}/module/${module_id}/logic/service/${service_id}/function-editor/${res.uuid}`,
            {
              replace: true
            }
          );
        }
        props.onClose();
      })
      .catch((err) => {
        setErrorMessage(t('logicBuilder.service.errorCreatingFunction') ?? 'Error while saving');
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setErrorMessage('');
        props.onClose();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('logicBuilder.newController.FunctionCreator')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body id="bodyModal">
          {errorMessage && <span className="text-danger">* {errorMessage}</span>}
          {/* Form */}
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{t('logicBuilder.newController.name')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('logicBuilder.newController.name')}`}
              maxLength={64}
              required
              isInvalid={isNameInvalid}
              value={newFunction?.name ?? ''}
              onChange={(e) => {
                setNewFunction({ ...newFunction, name: e.target.value } as FunctionSimple);
                setIsNameInvalid(!functionNameRegex.test(e.target.value));
              }}
            />
            <Form.Control.Feedback type="invalid">
              {t('logicBuilder.createFunctionDialog.validatingOne')}
              <ul>
                <li>{t('logicBuilder.createFunctionDialog.validationTwo')}</li>
                <li>{t('logicBuilder.createFunctionDialog.validationThree')}</li>
              </ul>
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>{t('logicBuilder.newController.description')}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={`${t('logicBuilder.newController.description')}`}
              rows={2}
              maxLength={255}
              value={newFunction?.description ?? ''}
              onChange={(e) =>
                setNewFunction({ ...newFunction, description: e.target.value } as FunctionSimple)
              }
            />
          </Form.Group>
        </Modal.Body>
        {/* Footer buttons */}
        <Modal.Footer>
          <Button
            id="cancelButton"
            variant="secondary"
            onClick={() => {
              setErrorMessage('');
              props.onClose();
            }}
          >
            {t('logicBuilder.service.cancel')}
          </Button>
          <Button
            id="saveButton"
            variant="primary"
            type="submit"
            disabled={isNameInvalid || !newFunction?.name}
          >
            {t('logicBuilder.service.createOnly')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
