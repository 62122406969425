import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { t } from 'i18next';

export const SIDE_MENU_MANIFEST: ComponentManifest = {
  type: 'SIDEMENU',
  name: 'Side Menu',
  descriptionId: 'SideMenuDescription',
  description: 'It is a side menu.',
  icon: 'fa-solid fa-rectangle-list',
  group: ComponentGroups['MENU'],
  allowDrop: false,
  hasSections: true,
  previewHeight: 200,
  previewWidth: 60,
  properties: [
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.sideMenu.tooltip'
    },
    {
      controlType: ControlsTypes.MENU_TYPE,
      controlLabel: 'MenuType'
    },
    {
      controlType: ControlsTypes.ICON_TEXT,
      controlLabel: 'IconText'
    },
    {
      controlType: ControlsTypes.SELECT,
      key: 'buttonOrientation',
      controlOptions: ['Horizontal', 'Vertical'],
      controlLabel: 'ButtonOrientation',
      tooltip: 'designer.right_side.controls.components.sideMenu.ButtonOrientation'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.LIST_LINKS,
      controlLabel: 'SettingLinks',
      key: 'links',
      tooltip: 'designer.right_side.controls.components.sideMenu.links'
    },
    {
      controlType: ControlsTypes.SELECT_PICS,
      controlLabel: 'URL',
      key: 'imgSource',
      tooltip: 'designer.right_side.controls.components.image.selectPics'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'RoundedImg',
      key: 'roundedImg',
      tooltip: 'designer.right_side.controls.components.image.roundedImg'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Background',
      tooltip: 'designer.right_side.controls.components.sideMenu.background'
    },
    {
      controlType: ControlsTypes.ICON_STYLES,
      controlLabel: 'IconStyles'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.sideMenu.color'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.sideMenu.fontSize'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
