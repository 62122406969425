import { FRONTEND_ACTIONS_TYPES } from './actions';
import {
  AddListData,
  AddListDataInterface,
  BeginCycleData,
  BeginCycleDataInterface,
  BeginElseData,
  BeginElseDataInterface,
  BeginIfData,
  BeginIfDataInterface,
  ClearListData,
  ClearListDataInterface,
  CommentData,
  CommentDataInterface,
  CustomCodeData,
  CustomCodeDataInterface,
  DeclareVariableData,
  DeclareVariableDataInterface,
  EmptyData,
  EmptyDataInterface,
  EndCycleData,
  EndCycleDataInterface,
  EndElseData,
  EndElseDataInterface,
  EndIfData,
  EndIfDataInterface,
  NavigateData,
  NavigateDataInterface,
  RemoveListData,
  RemoveListDataInterface,
  RestData,
  RestDataInterface,
  ReturnTypeData,
  ReturnTypeDataInterface,
  RunFunctionData,
  RunFunctionDataInterface,
  SetVariableData,
  SetVariableDataInterface,
  ToggleModalData,
  ToggleModalDataInterface
} from './action_data';

type FrontendActionsDataIntefaces =
  | AddListDataInterface
  | RemoveListDataInterface
  | ClearListDataInterface
  | DeclareVariableDataInterface
  | SetVariableDataInterface
  | BeginCycleDataInterface
  | EndCycleDataInterface
  | BeginIfDataInterface
  | EndIfDataInterface
  | BeginElseDataInterface
  | EndElseDataInterface
  | RunFunctionDataInterface
  | CustomCodeDataInterface
  | ReturnTypeDataInterface
  | RestDataInterface
  | NavigateDataInterface
  | ToggleModalDataInterface
  | EmptyDataInterface
  | CommentDataInterface;

export type FrontendActionsDataType = {
  [key in FRONTEND_ACTIONS_TYPES]: FrontendActionsDataIntefaces;
};

export const FrontendActionsData: FrontendActionsDataType = {
  [FRONTEND_ACTIONS_TYPES.ADD_LIST]: AddListData,
  [FRONTEND_ACTIONS_TYPES.CLEAR_LIST]: ClearListData,
  [FRONTEND_ACTIONS_TYPES.REMOVE_LIST]: RemoveListData,
  [FRONTEND_ACTIONS_TYPES.DECLARE_VARIABLE]: DeclareVariableData,
  [FRONTEND_ACTIONS_TYPES.SET_VARIABLE]: SetVariableData,
  [FRONTEND_ACTIONS_TYPES.BEGIN_CYCLE]: BeginCycleData,
  [FRONTEND_ACTIONS_TYPES.END_CYCLE]: EndCycleData,
  [FRONTEND_ACTIONS_TYPES.BEGIN_IF]: BeginIfData,
  [FRONTEND_ACTIONS_TYPES.END_IF]: EndIfData,
  [FRONTEND_ACTIONS_TYPES.BEGIN_ELSE]: BeginElseData,
  [FRONTEND_ACTIONS_TYPES.END_ELSE]: EndElseData,
  [FRONTEND_ACTIONS_TYPES.RUN_FUNCTION]: RunFunctionData,
  [FRONTEND_ACTIONS_TYPES.CUSTOM_CODE]: CustomCodeData,
  [FRONTEND_ACTIONS_TYPES.RETURN_TYPE]: ReturnTypeData,
  [FRONTEND_ACTIONS_TYPES.REST]: RestData,
  [FRONTEND_ACTIONS_TYPES.NAVIGATE]: NavigateData,
  [FRONTEND_ACTIONS_TYPES.TOGGLE_MODAL]: ToggleModalData,
  [FRONTEND_ACTIONS_TYPES.EMPTY]: EmptyData,
  [FRONTEND_ACTIONS_TYPES.COMMENT]: CommentData,
  [FRONTEND_ACTIONS_TYPES.AUTHENTICATION_LOGOUT]: EmptyData
};
