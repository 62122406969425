import React, { ComponentType } from 'react';
import '@fortawesome/fontawesome-free/js/regular.min';
import styles from './style.module.css';
import { CUSTOM_ICONS_LIST } from './customIcons';

export type IconComponentProps = {
  id?: string;
  iconName: string;
  brands?: string;
  padding?: string;
  margin?: string;
  extraProps?: string;
  onClick?: () => void;
  role?: string;
  customIconName?: string;
};

function Icon(props: IconComponentProps) {
  const icon = `fa-${props.brands ?? 'solid'} fa-${props.iconName} ${styles.centerIcon} ${
    props.extraProps
  }`;

  const iconStyle = {
    padding: props.padding,
    margin: props.margin
  };

  if (props.customIconName && CUSTOM_ICONS_LIST[props.customIconName]) {
    const CustomIcon = CUSTOM_ICONS_LIST[props.customIconName] as ComponentType;
    return (
      <i
        className="d-flex justify-content-center align-items-center"
        style={{ ...iconStyle, width: '70%' }}
        onClick={props.onClick}
        role={props.role}
      >
        <CustomIcon />
      </i>
    );
  }
  return <i className={icon} style={iconStyle} onClick={props.onClick} role={props.role} />;
}

export default Icon;
