import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultLabel } from '../styles';
import { DATA_TYPES, SchemaObject } from '../../../../../modules/logic_builder/types';
import { FrontendVariable, VariableTypes } from 'modules/designer/types';
import { CONTROL_REQUIREMENT_TYPES, ControlRequirementsTypes } from '../requirement_messages';
import { ControlRequirements } from '../requirement_messages/ControlRequirements';
import MissingMessage, { MissingMessageType } from '../requirement_messages/missingMessage';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../../../../modules/designer/studio/store';
import ManageVariableDialog from 'modules/designer/studio/components/manage_variable_dialog';
import { useParams } from 'react-router-dom';
import { convertFrontendToBackendDatatype, useVariablesAndObjects } from './useVariablesAndObjects';
import NestedObjectItemMaker from './NestedObjectItemMaker';
import ObjectItemSelector from './ObjectItemSelector';
import { Form } from 'react-bootstrap';

function VariableControl(props: ControlProps) {
  const { view_id } = useParams();
  const editorMode = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const [showCreateVariableDialog, setShowCreateVariableDialog] = useState(false);
  const [variableObject, setVariableObject] = useState<SchemaObject>();
  const [subObjectItem, setSubObjectItem] = useState<any>();
  const [initialVariableDialogValues, setInitialVariableDialogValues] =
    useState<FrontendVariable>();

  let frontendFilters: VariableTypes[] = [];
  if (props.options) {
    frontendFilters = props.options.filter((option) => option !== 'list') as VariableTypes[];
  }

  const backendFilters = convertFrontendToBackendDatatype(frontendFilters);
  const showProperties = editorMode === 'MODAL' || editorMode === 'CUSTOM_COMPONENT';
  const { variables, objects } = useVariablesAndObjects(
    !!props.options?.includes('list'),
    showProperties,
    frontendFilters
  );
  const variable = variables[props.value?.uuid ?? ''];

  useEffect(() => {
    let object: SchemaObject | undefined = undefined;
    if (variable && variable.type === 'OBJECT') {
      object = objects[variable.objectUuid ?? ''];
    }
    setVariableObject(object);
  }, [objects, variable]);

  async function handleChangeVar(e: ChangeEvent<HTMLSelectElement>) {
    if (props.onChange && e.target.value) {
      props.onChange(
        { uuid: e.target.value, isProp: variables[e.target.value].isProp, nestedObjects: {} },
        props.id
      );
    }
    if (props.onChange && !e.target.value) {
      props.onChange({}, props.id);
    }
  }

  function handleChangeItem(e: ChangeEvent<HTMLSelectElement>) {
    if (props.onChange)
      props.onChange({ ...props.value, objectItem: e.target.value, nestedObjects: {} }, props.id);
  }

  useEffect(() => {
    const objectItem =
      props.value &&
      props.value.objectItem &&
      variableObject?.objectItems &&
      variableObject?.objectItems.filter((item) => item.uuid === props.value.objectItem)[0];

    setSubObjectItem(objectItem);
  }, [props.value, variableObject?.objectItems]);

  const handleShowCreateVariableDialog = (): void => {
    setShowCreateVariableDialog(true);

    let type = 'STRING' as VariableTypes;

    if (props.options && props.options.length > 0) {
      type = props.options[1]
        ? (props.options[1] as VariableTypes)
        : (props.options[0] as VariableTypes);
    }

    setInitialVariableDialogValues({
      uuid: '',
      name: '',
      type: type,
      native: false,
      list: props.options?.includes('list') ? true : false
    });
  };

  const handleCloseVariableDialog = (
    idVariable?: string,
    variableType?: VariableTypes,
    isList?: boolean
  ): void => {
    setShowCreateVariableDialog(false);
    setInitialVariableDialogValues(undefined);
    if (!props.onChange || !idVariable) return;
    props.onChange({ uuid: idVariable, isProp: false }, props.id);
  };

  return (
    <>
      <div className="mb-3">
        {props.label && (
          <>
            <MissingMessage
              type={MissingMessageType.VARIABLE}
              uuid={props.value?.uuid}
              requiredTypes={
                props.options && props.options.length > 0
                  ? props.options.map((option) => option as VariableTypes)
                  : []
              }
            />
            <DefaultLabel className={`${styles.defaultLabel} form-label`}>
              {props.label}
            </DefaultLabel>
          </>
        )}
        {Object.values(variables).length > 0 ? (
          <Form.Select
            className={`${styles.defaultInput} form-select`}
            value={props.value?.uuid || ''}
            onChange={handleChangeVar}
          >
            <option value="">---</option>

            {Object.values(variables).map(
              (variable) =>
                (props.options?.includes('list')
                  ? variable.list || variable.type === 'OBJECT'
                  : true) && (
                  <option key={variable.uuid} value={variable.uuid}>
                    {variable.name}
                  </option>
                )
            )}
          </Form.Select>
        ) : (
          <div className="mt-2">
            <ControlRequirements
              onClick={handleShowCreateVariableDialog}
              controlRequirementType={
                (props.options?.includes('list')
                  ? CONTROL_REQUIREMENT_TYPES.LIST_VARIABLE
                  : CONTROL_REQUIREMENT_TYPES.VARIABLE) as ControlRequirementsTypes
              }
            />
          </div>
        )}
        {variableObject && props.value?.uuid && !backendFilters.includes(DATA_TYPES.OBJECT) && (
          <ObjectItemSelector
            value={props.value ? props.value.objectItem : ''}
            onChange={handleChangeItem}
            listFilter={props.options ? props.options?.includes('list') : false}
            typeFilter={backendFilters}
            currentObject={variableObject}
          />
        )}
        {
          // Sub Items recursive inputs
          props.value && props.value.objectItem && subObjectItem && subObjectItem.object && (
            <NestedObjectItemMaker
              id={props.id}
              value={props.value}
              parentObjectItem={subObjectItem}
              onChange={props.onChange}
              typeFilter={backendFilters}
              listFilter={props.options?.includes('list') ?? false}
            />
          )
        }
      </div>
      {view_id && initialVariableDialogValues && (
        <ManageVariableDialog
          view_id={view_id}
          show={showCreateVariableDialog}
          onClose={handleCloseVariableDialog}
          variable={initialVariableDialogValues}
          editMode={false}
        />
      )}
    </>
  );
}

export default VariableControl;
