import React from 'react';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import DeclareVariableIcon from './declare_variable_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { variableNameRegex } from '../../../../../utils/regex';
import { FORBIDDEN_VARIABLE_NAMES } from '../../../../../web_ui/function_editor/store/types/variables';
import { FRONTEND_VARIABLE_TYPES } from '../../../types';
import { t } from 'i18next';

function validateVariableName(state: FunctionEditorState, name: string): boolean {
  if (!name) return false;
  if (!variableNameRegex.test(name)) return false;
  if (FORBIDDEN_VARIABLE_NAMES.includes(name.toLowerCase())) return false;
  for (const v of Object.values(state.variables)) {
    if (v.name === name) return false;
  }
  return true;
}

export const DECLARE_VARIABLE_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.DECLARE_VARIABLE,
  name: t('DeclareVariable'),
  description: t('DeclareDescription'),
  color: 'success',
  category: FRONTEND_ACTIONS_CATEGORIES.VARIABLE,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.VARIABLE,
  icon: <DeclareVariableIcon />,
  inputs: [],
  inlineInputs: [
    {
      type: ACTION_INPUTS_TYPES.TEXT,
      label: t('Declare'),
      placeholder: 'Name',
      key: 'name',
      options: { validator: validateVariableName }
    },
    {
      type: ACTION_INPUTS_TYPES.DATA_TYPE_INPUT,
      label: t('ofType'),
      placeholder: 'type',
      key: 'dataType',
      options: { dataTypes: FRONTEND_VARIABLE_TYPES }
    },
    {
      type: ACTION_INPUTS_TYPES.CHECKBOX,
      label: t('IsArray'),
      placeholder: 'Initialize',
      key: 'list'
    },
    {
      type: ACTION_INPUTS_TYPES.CHECKBOX,
      label: t('ShouldInitialize'),
      placeholder: 'Initialize',
      key: 'initialize'
    }
  ]
};
