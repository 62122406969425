import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller } from 'modules/logic_builder/types';
import { ObjectsService, ControllersService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { Table } from '../../../../modeler/types';
import {
  checkDescriptionIsValid,
  checkNameIsValid,
  checkPathIsValid
} from '../controller_creator_dialog';

type ControllerEditorDialogProps = {
  controllerUuid: string;
  show: boolean;
  onClose: () => void;
};

export const EMPTY_CONTROLLER: Controller = {
  name: '',
  path: '',
  description: '',
  native: false
};

export function ControllerEditorDialog(props: ControllerEditorDialogProps) {
  const { t } = useTranslation();

  const [controller, setController] = useState<Controller>({ ...EMPTY_CONTROLLER });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [tables, setTables] = useState<Table[]>([]);
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});

  const { fetchControllers, module_id } = useContext(LogicBuilderContext);

  useEffect(() => {
    if (!props.show || !module_id) return;

    const fetchController = () => {
      ControllersService.getController(props.controllerUuid).then((fetchedController) => {
        if (!fetchedController.path) fetchedController.path = '';
        if (!fetchedController.description) fetchedController.description = '';
        setController(fetchedController);
      });
    };

    const fetchObjectsSchema = () => {
      ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
        setTables(schema?.tables ? schema.tables : []);
      });
    };

    fetchObjectsSchema();
    fetchController();
    setShowConfirmationDialog(false);
    setErrorMessages({});
    setErrorMessage(false);
    setIsLoading(false);
  }, [props.show, props.controllerUuid, module_id]);

  async function onDelete() {
    if (isLoading) return;
    if (!controller.uuid || !module_id) return;

    try {
      setIsLoading(true);
      await ControllersService.deleteController(controller.uuid).then(() => {
        setShowConfirmationDialog(false);
        fetchControllers(module_id);
        props.onClose();
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isLoading) return;

    try {
      setIsLoading(true);
      if (!controller.uuid || !module_id) return;
      if (!validateInputs()) return;
      setIsLoading(true);
      await ControllersService.updateController(controller.uuid, controller)
        .then(() => {
          fetchControllers(module_id);
          props.onClose();
        })
        .catch(() => {
          setErrorMessage(true);
        });
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateInputs = (): boolean => {
    const nextErrorMessages = {
      ...errorMessages,
      name: checkNameIsValid(controller.name),
      path: checkPathIsValid(controller.path),
      description: checkDescriptionIsValid(controller.description)
    };
    setErrorMessages(nextErrorMessages);
    for (const errorMessage of Object.values(nextErrorMessages)) {
      if (errorMessage) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.onClose();
        }}
        centered
      >
        <Form onSubmit={onSubmit} className={styles.formWrapper} id="formModal">
          <Modal.Header closeButton>
            <Modal.Title>{t('logicBuilder.ControllerEditor')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
            {errorMessage && (
              <span className={styles.errorMsg}>
                {`*${t('logicBuilder.newController.errorTheSameName')}`}
              </span>
            )}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{t('logicBuilder.Name')}</Form.Label>

              <Form.Control
                type="text"
                autoFocus
                value={controller.name}
                isInvalid={!!errorMessages.name}
                onChange={(e) => {
                  setController({
                    ...controller,
                    name: e.target.value
                  });
                  const nextErrorMessages = {
                    ...errorMessages,
                    name: checkNameIsValid(e.target.value)
                  };
                  setErrorMessages(nextErrorMessages);
                }}
              />
              <Form.Control.Feedback type="invalid">{t(errorMessages.name)}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPath">
              <Form.Label>{t('logicBuilder.Path')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={`${t('logicBuilder./path')}`}
                autoFocus
                value={controller.path}
                isInvalid={!!errorMessages.path}
                onChange={(e) => {
                  setController({
                    ...controller,
                    path: e.target.value
                  });
                  const nextErrorMessages = {
                    ...errorMessages,
                    path: checkPathIsValid(e.target.value)
                  };
                  setErrorMessages(nextErrorMessages);
                }}
              />
              <Form.Control.Feedback type="invalid">{t(errorMessages.path)}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formControllerDescription">
              <Form.Label>{t('logicBuilder.Description')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={controller.description}
                isInvalid={!!errorMessages.description}
                onChange={(e) => {
                  setController({
                    ...controller,
                    description: e.target.value
                  });
                  const nextErrorMessages = {
                    ...errorMessages,
                    description: checkDescriptionIsValid(e.target.value)
                  };
                  setErrorMessages(nextErrorMessages);
                }}
              />
              <Form.Control.Feedback type={'invalid'}>
                {t(errorMessages.description)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEntity">
              <Form.Label style={{ marginTop: 10 }}>{t('modeler.Entity')}</Form.Label>
              <Form.Select
                value={controller.entityUuid}
                onChange={(e) =>
                  setController({
                    ...controller,
                    entityUuid: e.target.value
                  } as Controller)
                }
              >
                <option value="">---</option>
                {tables.map((entity) => (
                  <option key={entity.uuid} value={entity.uuid}>
                    {entity.content.data.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="deleteButton"
              variant="danger"
              onClick={(_) => setShowConfirmationDialog(true)}
            >
              {t('logicBuilder.Delete')}
            </Button>
            <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
              {t('logicBuilder.Cancel')}
            </Button>
            <Button id="saveButton" variant="primary" type="submit">
              {t('logicBuilder.Save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('logicBuilder.deleteControllerDialog')} (${controller.name}) `}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
