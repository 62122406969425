import { functionEditorCrud } from 'web_ui/function_editor/store/types/functions';
import {
  CrudCreateEndpoint,
  CrudCreateFunction,
  CrudInputEndpoint,
  CrudInputFunction,
  CrudInputViews,
  CrudPage,
  Endpoint,
  FunctionSimple,
  ObjectSimple,
  Service,
  Controller
} from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class CrudAutomationRepo {
  /**
   * get entity objects.
   */
  async getEntityObjects(moduleId: string, entityId: string): Promise<ObjectSimple[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/objects`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ObjectSimple[]>;
      throw new Error('Something went wrong while trying to get Entity Objects.');
    });
  }

  /**
   * create base objects.
   */
  async createBaseObject(moduleId: string, entityId: string): Promise<ObjectSimple> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST'
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/objects/base`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ObjectSimple>;
      throw new Error('Something went wrong while trying to create base Object.');
    });
  }

  /**
   * get entity services resume.
   */
  async getEntityServiceResume(moduleId: string, entityId: string): Promise<Service[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/services`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Service[]>;
      throw new Error('Something went wrong while trying to get entity services resume.');
    });
  }

  /**
   * get entity controllers resume.
   */
  async getEntityControllersResume(moduleId: string, entityId: string): Promise<Controller[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/controllers`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Controller[]>;
      throw new Error('Something went wrong while trying to get entity controllers resume.');
    });
  }

  /**
   * get function prototypes.
   */
  async getFunctionsPrototypes(
    moduleId: string,
    entityId: string,
    crudInputFunction: CrudInputFunction
  ): Promise<functionEditorCrud[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PATCH',
      body: JSON.stringify(crudInputFunction)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/functions`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<functionEditorCrud[]>;
      throw new Error('Something went wrong while trying to create function prototypes.');
    });
  }

  /**
   * create function.
   */
  async saveFunctions(
    moduleId: string,
    entityId: string,
    crudCreateFunction: CrudCreateFunction
  ): Promise<FunctionSimple> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(crudCreateFunction)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/functions`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<FunctionSimple>;
      throw new Error('Something went wrong while trying to create function.');
    });
  }

  /**
   * get endpoints prototypes.
   */
  async getEndpointsPrototypes(
    moduleId: string,
    entityId: string,
    crudInputEndpoint: CrudInputEndpoint
  ): Promise<Endpoint[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PATCH',
      body: JSON.stringify(crudInputEndpoint)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/endpoints`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Endpoint[]>;
      throw new Error('Something went wrong while trying to create function.');
    });
  }

  /**
   * create endpoints.
   */
  async createEndpoints(
    moduleId: string,
    entityId: string,
    crudCreateEndpoint: CrudCreateEndpoint
  ): Promise<Endpoint[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(crudCreateEndpoint)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/endpoints`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Endpoint[]>;
      throw new Error('Something went wrong while trying to create endpoints.');
    });
  }

  /**
   * get views prototypes.
   */
  async getViewsPrototypes(
    moduleId: string,
    entityId: string,
    crudInputViews: CrudInputViews
  ): Promise<CrudPage[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PATCH',
      body: JSON.stringify(crudInputViews)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/views`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CrudPage[]>;
      throw new Error('Something went wrong while trying to create function.');
    });
  }
}
