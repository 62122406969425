import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { DataType, ObjectSimple } from 'modules/logic_builder/types';
import { FunctionService, ObjectsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import { FunctionVariable } from 'modules/logic_builder/types';
import { DATA_TYPES } from 'modules/logic_builder/types';
import { EnumFrame } from 'modules/modeler/types';
import { variableNameRegex } from '../../../../../utils/regex';
import { useTranslation } from 'react-i18next';

type VariableCreatorDialogProps = {
  functionId: string;
  fetchVariables: () => void;
  show: boolean;
  onClose: () => void;
  enumsList: EnumFrame[];
};

export function VariableCreatorDialog(props: VariableCreatorDialogProps) {
  const { module_id } = useParams();
  const [newVariable, setNewVariable] = useState<FunctionVariable>();
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

  const fetchObjects = useCallback(async () => {
    if (!module_id) return;
    await ObjectsService.getObjectsByModule(module_id).then((fetchedObjects) => {
      setObjects(fetchedObjects);
    });
  }, [module_id]);

  useEffect(() => {
    if (!props.show) return;

    fetchObjects();
    setErrorMessage(false);
  }, [fetchObjects, props.show]);

  useEffect(() => {
    if (!props.show) return;

    setNewVariable({
      uuid: '',
      name: '',
      description: '',
      type: 'BOOLEAN',
      list: false,
      objectUuid: ''
    });
  }, [props.show]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (!module_id || !newVariable) return;

    try {
      await FunctionService.createFunctionVariable(props.functionId, newVariable).then(() => {
        props.fetchVariables();
        props.onClose();
      });
    } catch (err) {
      setErrorMessage(true);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.onClose()}
      className={styles.VariableCreator}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Variable creator</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          {errorMessage && (
            <span className={styles.errorMsg}>
              {`*${t('logicBuilder.errorCreatingEditingVariable')}`}
            </span>
          )}
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              required
              isInvalid={isInvalidName}
              value={newVariable?.name ?? ''}
              onChange={(e) => {
                setIsInvalidName(!variableNameRegex.test(e.target.value));
                setNewVariable({ ...newVariable, name: e.target.value } as FunctionVariable);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formType">
            <Form.Label>Type</Form.Label>
            <Form.Select
              required
              value={newVariable?.type ?? 'default'}
              onChange={(e) => {
                setNewVariable({
                  ...newVariable,
                  type: e.target.value,
                  objectUuid: '',
                  enumUuid: ''
                } as FunctionVariable);
              }}
            >
              {!newVariable?.type && <option value="default">Select type</option>}
              {Object.keys(DATA_TYPES).map((type) => {
                return (
                  <option key={type} value={type}>
                    {DATA_TYPES[type as DataType]}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className={`mb-3`} controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Description..."
              rows={2}
              maxLength={64}
              value={newVariable?.description ?? ''}
              onChange={(e) =>
                setNewVariable({ ...newVariable, description: e.target.value } as FunctionVariable)
              }
            />
          </Form.Group>

          <Form.Group controlId="formSpec">
            <Form.Check
              inline
              label="Is Array"
              type="checkbox"
              value="IsArray"
              name="IsArray"
              checked={newVariable?.list ?? false}
              onChange={(e) =>
                setNewVariable({ ...newVariable, list: e.target.checked } as FunctionVariable)
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formType">
            <Form.Label>Objects</Form.Label>
            <Form.Select
              required
              disabled={newVariable?.type !== 'OBJECT'}
              onChange={(e) =>
                setNewVariable({
                  ...newVariable,
                  objectUuid: e.target.value
                } as FunctionVariable)
              }
              value={newVariable?.objectUuid ?? 'default'}
            >
              {!newVariable?.objectUuid && <option value={'default'}>Select object</option>}
              {objects.map((object) => {
                return (
                  <option key={object.uuid} value={object.uuid}>
                    {object.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formSelectEnum">
            <Form.Label>Enums</Form.Label>
            <Form.Select
              required
              disabled={newVariable?.type !== 'ENUM'}
              onChange={(e) =>
                setNewVariable({
                  ...newVariable,
                  enumUuid: e.target.value
                } as FunctionVariable)
              }
              value={newVariable?.enumUuid ?? 'default'}
            >
              {!newVariable?.enumUuid && <option value={'default'}>Select enum</option>}
              {props.enumsList.map((enumItem) => {
                return (
                  <option key={enumItem.uuid} value={enumItem.uuid}>
                    {enumItem.content.data.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button
            id="saveButton"
            variant="primary"
            type="submit"
            disabled={isInvalidName || !newVariable?.name || !newVariable?.type}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
