import React from 'react';
import { FunctionActionInputType } from '../../store/types/manifestsAndInputs';
import useRenderInput from '../editor/function_body/action/hooks/useRenderInput';

type ActionInputsEditorProps = {
  uuid: string;
  inputs: FunctionActionInputType[];
};

function ActionInputsEditor({ uuid, inputs }: ActionInputsEditorProps) {
  const { renderInput } = useRenderInput(true);

  return (
    <div
      style={{
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center'
      }}
    >
      {inputs.map((input, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flexGrow: '1',
            gap: '0.5rem',
            padding: '0.5rem',
            width: 200
          }}
        >
          {renderInput(input, uuid)}
        </div>
      ))}
    </div>
  );
}

export default ActionInputsEditor;
