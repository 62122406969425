import React from 'react';

function AddListIcon() {
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <i
        className="fa-regular fa-rectangle-list text-secondary-emphasis"
        style={{ fontSize: '1.4rem' }}
      />
      <i
        className="fas fa-plus "
        style={{
          fontSize: '.7rem',
          marginLeft: '-8px',
          borderRadius: '50%',
          padding: '0.2rem',
          background: 'var(--bs-success)'
        }}
      />
    </span>
  );
}

export default AddListIcon;
