import * as React from 'react';
import { Form } from 'react-bootstrap';
import { CascadeType } from './relationsEnum';
import { useDispatch } from 'react-redux';
import { changeProperty } from 'modules/modeler/studio/store/actions/relationship';
import { Relationship } from 'modules/modeler/types';
import { useTranslation } from 'react-i18next';

type CascadeOptionsProps = {
  relationship: Relationship;
};

type Options = {
  key: CascadeType;
  value: string;
  status: boolean;
  disabled: boolean;
};

export default function CascadeOptions({ relationship }: CascadeOptionsProps) {
  const [options, setOptions] = React.useState<Options[]>([
    { key: CascadeType.PERSIST, value: 'PERSIST', status: false, disabled: false },
    { key: CascadeType.MERGE, value: 'MERGE', status: false, disabled: false },
    { key: CascadeType.REMOVE, value: 'REMOVE', status: false, disabled: false },
    { key: CascadeType.REFRESH, value: 'REFRESH', status: false, disabled: false },
    { key: CascadeType.DETACH, value: 'DETACH', status: false, disabled: false },
    { key: CascadeType.ALL, value: 'ALL', status: false, disabled: false }
  ]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const disabledAll = () => {
    const copy = [...options];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].key === CascadeType.ALL) continue;
      copy[i].disabled = true;
      copy[i].status = true;
    }
    setOptions(copy);
  };

  const removeDisabledAll = () => {
    const copy = [...options];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].key === CascadeType.ALL) continue;
      copy[i].disabled = false;
    }
    setOptions(copy);
  };

  const turnOnAll = () => {
    const copy = [...options];
    const id = copy.findIndex((item) => item.key === CascadeType.ALL);
    copy[id].status = true;
    setOptions(copy);
  };

  // editing options and editing the str
  const handleChange = (val: boolean, currItem: Options) => {
    if (val) {
      setOptions((val) => {
        // find the correct item and update the status
        const copying = [...val];
        copying.find((item) => item.key === currItem.key)!.status = true;
        return copying;
      });

      const arrayToSubmit: string[] = [];
      options.forEach((item) => {
        if (item.status) {
          arrayToSubmit.push(item.value);
        }
      });
      dispatch(changeProperty(relationship.id, 'cascadeTypes', arrayToSubmit.toString()));
      if (currItem.key === CascadeType.ALL) {
        disabledAll();
      }
      // if it choose all
      if (options.filter((item) => item.status).length === 5) {
        disabledAll();
        turnOnAll();
      }
    } else {
      setOptions((val) => {
        // find the correct item and update the status
        const copying = [...val];
        copying.find((item) => item.key === currItem.key)!.status = false;
        return copying;
      });
      const arrayToSubmit: string[] = [];
      options.forEach((item) => {
        if (item.status) {
          arrayToSubmit.push(item.value);
        }
      });
      dispatch(changeProperty(relationship.id, 'cascadeTypes', arrayToSubmit.toString()));
      removeDisabledAll();
    }
  };

  const settingValue = (curr: string[]) => {
    const copying = [...options];

    curr.forEach((itemX) => {
      const currIndex = copying.findIndex((item) => item.value == itemX);
      copying[currIndex].status = true;
    });
    if (copying.find((item) => item.key === CascadeType.ALL)!.status) {
      disabledAll();
    }
    return copying;
  };

  React.useEffect(() => {
    // if it's not an array it's a string
    if (
      relationship.id &&
      relationship.cascadeTypes &&
      relationship.cascadeTypes instanceof Array
    ) {
      setOptions(settingValue(relationship.cascadeTypes));
    } else if (relationship.id && relationship.cascadeTypes) {
      const currVal = relationship.cascadeTypes.toString().split(',');
      setOptions(settingValue(currVal));
    }
  }, [relationship.id, relationship.cascadeTypes]);
  return (
    <Form.Group>
      <Form.Label>{t('modeler.entity_editor.relations.CascadeOpt')}</Form.Label>
      <div
        style={{
          display: 'flex',
          width: '250px',
          flexWrap: 'wrap'
        }}
      >
        {options.map((item, index) => (
          <Form.Check
            key={index}
            label={item.value}
            checked={item.status}
            disabled={item.disabled}
            onChange={(ev) => handleChange(ev.target.checked, item)}
            style={{ marginRight: '.5rem' }}
          />
        ))}
      </div>
    </Form.Group>
  );
}
