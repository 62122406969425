import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import { ObjectSimple } from 'modules/logic_builder/types';
import { ObjectDialog } from '../dialogs/object_dialog';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { ModuleInfo } from 'modules/dashboard/types';
import Icon from '../../../../web_ui/icon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { t } from 'i18next';
import Confirmation from 'web_ui/confirmation';
import { ObjectsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import LogicBuilderContext from 'modules/logic_builder/store';

type ObjectInstanceProps = {
  objectInstance: ObjectSimple;
  cursor?: string;
  onEdit: (e: ObjectSimple) => void;
  onPreviewObject: (objectUuid: string) => void;
  tables: Table[];
  columns: Column[];
  relationships: Relationship[];
  enums: EnumFrame[];
  loadingData?: boolean;
  allObjects?: ObjectSimple[];
  modules?: ModuleInfo[];
  objectsWithDeps?: ObjectSimple[];
};

export function ObjectInstance(props: ObjectInstanceProps) {
  const [showObjectEditor, setShowObjectEditor] = useState<boolean>(false);
  const [objectEditUuid, setObjectEditUuid] = useState<string>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { fetchObjects } = useContext(LogicBuilderContext);
  const { module_id } = useParams();

  async function onDelete() {
    if (!module_id) return;
    await ObjectsService.deleteObject(props.objectInstance.uuid).then(() => {
      fetchObjects(module_id);
      setShowConfirmationDialog(false);
    });
  }

  return (
    <>
      <div
        id={props.objectInstance.name}
        title={props.objectInstance.native ? 'Native' : ''}
        className={styles.containerObj}
        onClick={(event) => {
          event.stopPropagation();
          setShowObjectEditor(true);
          setObjectEditUuid(props.objectInstance.uuid);
        }}
      >
        <div className={styles.headObj}>
          {props.objectInstance.name}
          <div className={styles.borderRight}></div>
        </div>
        <div id="objectBody" className={styles.bodyObj}>{`{ ${
          props.objectInstance.objectItems &&
          props.objectInstance.objectItems.length > 0 &&
          props.objectInstance.objectItems.map((item, index) => {
            return `${item.name}: ${item.dataType}`;
          })
        } }`}</div>
        {props.objectInstance.entityName != null ? (
          <div className={styles.footerObj}>
            <div className={styles.footerTag}>{props.objectInstance.entityName}</div>
          </div>
        ) : (
          <div className={styles.footerObj} />
        )}
        <div className={styles.wrapperIcons}>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('CodePreview') ?? 'CodePreview'
            }}
          >
            <div
              id="codePreview"
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={(event) => {
                event.stopPropagation();
                props.onPreviewObject(props.objectInstance.uuid);
              }}
            >
              <Icon iconName="fa-solid fa-code" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('editButton') ?? 'editButton'
            }}
          >
            <div
              id="editButton"
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={() => {
                setShowObjectEditor(true);
                setObjectEditUuid(props.objectInstance.uuid);
              }}
            >
              <Icon iconName="pen-to-square" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Delete') ?? 'Delete'
            }}
          >
            <div
              id="removeButton"
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={(event) => {
                event.stopPropagation();
                setShowConfirmationDialog(true);
              }}
            >
              <Icon iconName="trash" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
        </div>
      </div>
      <ObjectDialog
        show={showObjectEditor}
        onClose={() => {
          setShowObjectEditor(false);
          setObjectEditUuid(undefined);
        }}
        editMode={!!objectEditUuid}
        dialogTitle={objectEditUuid ? 'EditObject' : 'CreateObject'}
        objectId={objectEditUuid}
        tables={props.tables}
        columns={props.columns}
        enums={props.enums}
        relationships={props.relationships}
        entityMode={false}
        preselectedEntity={props.objectInstance.entityUuid}
        modules={props.modules ?? []}
        loadingData={props.loadingData}
        objectsWithDeps={props.objectsWithDeps}
      />
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.object')}${' '}(${props.objectInstance.name})`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
