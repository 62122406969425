import React from 'react';
import Icon from '../../../../../icon';
import { deleteParam } from '../../../../store/events/root/delete_param';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { changeParamOrder } from '../../../../store/events/functions/change_param_order';

type ParameterOptionsProps = {
  uuid: string;
  onFocus?: () => void;
};

function ParameterOptions({ uuid, onFocus }: ParameterOptionsProps) {
  const functionId = useSelector((state: FunctionEditorState) => state?.editor?.functionId);
  const param = useSelector((state: FunctionEditorState) => state?.parameters[uuid]);
  const funcParams = useSelector(
    (state: FunctionEditorState) => state?.functions[functionId]?.parameters
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleMoveUp = () => {
    if (!funcParams) return;
    const paramIndex = funcParams.indexOf(uuid);
    if (paramIndex === 0) return;
    dispatch(changeParamOrder(functionId, funcParams[paramIndex], funcParams[paramIndex - 1]));
  };

  const handleMoveDown = () => {
    if (!funcParams) return;
    const paramIndex = funcParams.indexOf(uuid);
    if (paramIndex === funcParams.length - 1) return;
    dispatch(changeParamOrder(functionId, funcParams[paramIndex], funcParams[paramIndex + 1]));
  };

  const handleDelete = () => {
    dispatch(deleteParam(functionId ?? '', uuid));
  };

  return (
    <>
      <button
        className="btn btn-outline-secondary bg-body rounded-end-2 me-1"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{
          border: 'var(--bs-border-width) solid var(--bs-border-color)',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }}
        onFocus={onFocus}
      >
        <Icon iconName={'fa-solid fa-ellipsis'} />
      </button>
      <ul className="dropdown-menu" id={'actionOptionsDropdown'}>
        <li onClick={() => handleMoveUp()}>
          <a className="dropdown-item" style={{ display: 'flex', gap: '0.5rem' }}>
            <Icon iconName={'arrow-up'} />
            {t('MoveUp')}
          </a>
        </li>
        <li onClick={() => handleMoveDown()}>
          <a className="dropdown-item" style={{ display: 'flex', gap: '0.5rem' }}>
            <Icon iconName={'arrow-down'} />
            {t('MoveDown')}
          </a>
        </li>
        {!param?.native && (
          <li onClick={() => handleDelete()}>
            <a className="dropdown-item" style={{ display: 'flex', gap: '0.5rem' }}>
              <Icon iconName={'trash'} />
              {t('Delete')}
            </a>
          </li>
        )}
      </ul>
    </>
  );
}

export default ParameterOptions;
