import React, { useContext, useEffect, useState } from 'react';
import { Step1Entity } from './step1Entity';
import { useNavigate, useParams } from 'react-router-dom';
import { Entity } from '../../../types';
import { Step2Objects } from './step2Objects';
import {
  CrudPage,
  Endpoint,
  ObjectTypeCrud,
  Service,
  Controller
} from '../../../../../modules/logic_builder/types';
import { Step3Functions } from './step3Functions';
import AutomationWizardProgressBar from '../../wizard_progress_bar/wizard_progress_bar';
import { Container } from 'react-bootstrap';
import { Step4Endpoints } from './step4Endpoints';
import { Folder, Page, TemplateEndpoint } from 'modules/designer/types';
import {
  functionEditorCrud,
  StructToSaveFunctionCrud
} from 'web_ui/function_editor/store/types/functions';
import { EndpointsService, FunctionService } from 'modules/logic_builder/services';
import { StructToSaveEndpointCrud } from 'web_ui/function_editor/store/types/endpoint';
import { Step6Summary } from './step6Summary';
import styles from './styles.module.css';
import { Step5Views } from './step5Views';
import { PageService } from '../../../../../modules/designer/services';
import { PopupAlert } from '../../../../../web_ui/popup_alert';
import { useQuery } from 'hooks/useQuery';
import { AppContext } from 'modules/project/store/app_context';

export type CrudData = {
  entityUuid?: string;
  entities: { [p: string]: Entity };
  objects: ObjectTypeCrud[];
  functions: functionEditorCrud[];
  endpoints: Endpoint[];
  services: Service[];
  controllers: Controller[];
  folders: Folder[];
  pages: Page[];
  crudPages: CrudPage[];
  selectedService: string;
  selectedController?: Controller;
  selectedFolder?: Folder;
  selectedObject?: string;
  masterEndpoints: TemplateEndpoint[];
  detailEndpoints: TemplateEndpoint[];
};

type CrudProps = {
  currentStep: number;
  selectedEntityId: string;
  setCurrentStep: (currentStep: number) => void;
  formValidity: boolean;
  setFormValidity: (isValid: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  entities?: { [p: string]: Entity };
};

const Crud = (props: CrudProps) => {
  const navigate = useNavigate();
  const { module_id, app_id } = useParams();
  const [alertMessage, setAlertMessage] = useState('');
  const [crudData, setCrudData] = useState<CrudData>({
    entityUuid: props.selectedEntityId ? props.selectedEntityId : '',
    entities: props.entities ?? {},
    objects: [],
    functions: [],
    endpoints: [],
    services: [],
    controllers: [],
    folders: [],
    pages: [],
    crudPages: [],
    selectedService: '',
    masterEndpoints: [],
    detailEndpoints: []
  });
  const queryParameters = useQuery();
  const [objectCreatedUUID, setObjectCreatedUUID] = useState<string[]>([]);
  const appInfo = useContext(AppContext).projectInformation;

  // loadings
  const [loadingObj, setLoadingObj] = useState<boolean>(false);
  const [loadingFunc, setLoadingFunc] = useState<boolean>(false);
  const [loadingEndpoint, setLoadingEndpoint] = useState<boolean>(false);
  const [loadingView, setLoadingView] = useState<boolean>(false);

  const loadingObjects = (val: boolean) => {
    setLoadingObj(val);
  };

  const loadingFunctions = (val: boolean) => {
    setLoadingFunc(val);
  };

  const loadingEndpoints = (val: boolean) => {
    setLoadingEndpoint(val);
  };

  const loadingViews = (val: boolean) => {
    setLoadingView(val);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  function handleFinish() {
    navigate(`/app/${app_id}/module/${module_id}/logic${isFromVsCodeExt() ? '?vscode=true' : ''}`, {
      replace: true
    });
  }

  // Initial loading
  useEffect(() => {
    async function initialSetup() {
      const crud: CrudData = {
        entityUuid: props.selectedEntityId ? props.selectedEntityId : '',
        entities: props.entities ?? {},
        objects: [],
        functions: [],
        endpoints: [],
        services: [],
        controllers: [],
        folders: [],
        pages: [],
        crudPages: [],
        selectedService: '',
        masterEndpoints: [],
        detailEndpoints: []
      } as CrudData;
      setCrudData(crud);
    }

    initialSetup();
  }, []);

  // avoiding over write of the null values
  useEffect(() => {
    if (!props.entities) return;

    const crud = {
      ...crudData,
      entities: props.entities
    };

    setCrudData(crud);
  }, [props.entities]);

  const insertingCorrectSelectedEntity = React.useCallback(() => {
    if (!props.selectedEntityId) return;

    const crud = {
      ...crudData,
      entityUuid: props.selectedEntityId
    };

    setCrudData(crud);
  }, [props.selectedEntityId]);

  useEffect(() => {
    insertingCorrectSelectedEntity();
  }, [props.selectedEntityId]);

  async function handlePostFunctions() {
    if (!module_id) return;
    if (!crudData.entityUuid) return;
    const functionsToBeCreated = crudData.functions.filter((f) => f.isNew);
    if (!functionsToBeCreated.length) {
      return;
    }

    const mountingRequest: StructToSaveFunctionCrud = {
      service: null,
      functions: []
    };

    mountingRequest.functions = functionsToBeCreated;
    const correctService = crudData.services.find((item) => item.uuid === crudData.selectedService);
    if (correctService && correctService.new) {
      mountingRequest.service = {
        description: correctService.description,
        entityUuid: correctService.entityUuid ?? '',
        name: correctService.name,
        uuid: correctService.uuid ?? ''
      };
    } else if (correctService) {
      mountingRequest.service = {
        uuid: correctService.uuid ?? ''
      };
    }
    await FunctionService.createFunctionCrud(module_id, crudData.entityUuid, mountingRequest);
  }

  async function handlePostEndpoints() {
    if (!module_id) return;
    if (!crudData.entityUuid) return;
    const endpointsToBeCreated = crudData.endpoints.filter((e) => e.isNew);
    if (!endpointsToBeCreated.length) {
      return;
    }

    const requestData: StructToSaveEndpointCrud = {
      controller: null,
      endpoints: []
    };

    requestData.endpoints = endpointsToBeCreated;
    const correctController = crudData.controllers.find(
      (controller) => controller.uuid === crudData.selectedController?.uuid
    );
    if (correctController && correctController.new) {
      requestData.controller = {
        description: correctController.description,
        entityUuid: correctController.entityUuid,
        name: correctController.name,
        uuid: correctController.uuid ?? '',
        path: correctController.path
      };
    } else if (correctController) {
      requestData.controller = {
        uuid: correctController.uuid ?? ''
      };
    }
    return await EndpointsService.createEnpointAutomationCrud(
      requestData,
      module_id,
      crudData.entityUuid
    );
  }

  async function handlePostPages() {
    if (!module_id) return;
    if (!crudData.entityUuid) {
      return;
    }
    if (crudData.crudPages.length === 0) {
      return;
    }

    await PageService.createPageAutomationCrud(crudData.crudPages, module_id, crudData.entityUuid);
  }

  async function onStepChange(step: number, isNext?: boolean) {
    try {
      if (step === 4 && isNext) {
        props.setFormValidity(crudData.endpoints.length > 0);
        loadingFunctions(true);
        await handlePostFunctions().finally(() => loadingFunctions(false));
      } else if (step === 5) {
        loadingEndpoints(true);
        const result = await handlePostEndpoints().finally(() => loadingEndpoints(false));
        const crud = {
          ...crudData,
          crudPages: []
        };

        if (result?.controller) {
          const newController: Controller = {
            description: result.controller.description ? result.controller.description : '',
            entityUuid: result.controller.entityUuid,
            name: result.controller.name ? result.controller.name : '',
            native: false,
            new: true,
            path: result.controller.path ? result.controller.path : '',
            uuid: result.controller.uuid
          };

          if (!crudData.controllers.find((item) => item.uuid === newController.uuid)) {
            crud.controllers = [...crudData.controllers, newController];
          }
        }

        setCrudData(crud);
      } else if (step === 6) {
        loadingViews(true);
        await handlePostPages().finally(() => loadingViews(false));
      }
      if (!appInfo?.has_frontend && step === 5) {
        step = 6;
      }
      props.setCurrentStep(step);
    } catch (error) {
      if (error instanceof Error) {
        setAlertMessage(error.message);
      }
    }
  }

  return (
    <>
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={'danger'} />
      )}
      {props.currentStep === 1 && (
        <Step1Entity
          crudData={crudData}
          onChange={(crudData: CrudData) => {
            props.setFormValidity(false);
            props.setCurrentStep(2);
            setCrudData(crudData);
          }}
        />
      )}
      {props.currentStep === 2 && (
        <Step2Objects
          crudData={crudData}
          onChange={(crudData: CrudData) => {
            if (crudData.objects.length > 0) {
              props.setFormValidity(true);
            }
            setCrudData(crudData);
          }}
          setFormValidity={props.setFormValidity}
          setUUIDCreated={setObjectCreatedUUID}
          loading={loadingObjects}
        />
      )}
      {props.currentStep === 3 && crudData && (
        <Step3Functions
          crudData={crudData}
          onChange={(crudData: CrudData) => {
            props.setFormValidity(
              crudData.functions.length > 0 ||
                crudData.functions.filter((f) => f.isNew === true).length > 0
            );
            setCrudData(crudData);
          }}
          setFormValidity={props.setFormValidity}
          objects={crudData.objects}
          loading={loadingFunctions}
        />
      )}
      {props.currentStep === 4 && crudData && (
        <Step4Endpoints
          crudData={crudData}
          onChange={(crudData: CrudData) => {
            props.setFormValidity(crudData.endpoints.length > 0);
            setCrudData(crudData);
          }}
          setFormValidity={props.setFormValidity}
          loading={loadingEndpoints}
        />
      )}
      {props.currentStep === 5 && crudData && (
        <Step5Views
          crudData={crudData}
          onChange={(crudData: CrudData) => {
            props.setFormValidity(crudData.endpoints.length > 0);
            setCrudData(crudData);
          }}
          setFormValidity={props.setFormValidity}
          loading={loadingViews}
        />
      )}
      {props.currentStep === 6 && crudData && (
        <Step6Summary
          crudData={crudData}
          onChange={(crudData: CrudData) => {
            props.setFormValidity(crudData.endpoints.length > 0);
            setCrudData(crudData);
          }}
          objectsCreatedIntoFlow={objectCreatedUUID}
        />
      )}
      <div className="fixed-bottom"></div>
      <div className={`fixed-bottom w-100 mb-4 ${styles.ProgressBar}`}>
        <div className={styles.ProgressBarWrapper}>
          <Container className="bg-body-tertiary p-4 pb-4">
            <AutomationWizardProgressBar
              totalSteps={6}
              step={props.currentStep}
              disabled={!props.formValidity}
              onStepChange={onStepChange}
              onFinish={handleFinish}
              loadingsCrudAutomation={[loadingObj, loadingFunc, loadingEndpoint, loadingView]}
            />
          </Container>
        </div>
      </div>
    </>
  );
};

export default Crud;
