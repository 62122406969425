import React from 'react';
import { ChangeEvent } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function FontStyleControl(props: ControlProps) {
  const { t } = useTranslation();
  function handleChange(value: string) {
    if (props.onChange) props.onChange(value, props.id);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      {props.tooltip && (
        <div>
          <HelpPopover
            placement="top"
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
          >
            <HelpIcon />
          </HelpPopover>
        </div>
      )}
      <div
        className="mb-3"
        style={{
          display: 'flex'
        }}
      >
        <div className={`form-control form-control-sm`}>
          <button
            className={` ${styles.cleanButton}  btn btn-secondary`}
            onClick={() => handleChange('')}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
          <button
            className={` ${styles.buttonContent} btn btn-secondary`}
            onClick={() => handleChange('italic')}
          >
            <i className="fa-solid fa-italic"></i>
          </button>
        </div>
        {props.options?.map((item, index) => {
          return <option key={index}>{item}</option>;
        })}
      </div>
    </div>
  );
}

export default FontStyleControl;
