import React, { forwardRef, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../store';
import { ConnectorID } from '../../../types';
import styles from './styles.module.css';
import { Frame } from 'web_ui/workboard/frame';
import { sleep } from 'utils/utils';
import Icon from 'web_ui/icon';

export type EdgeConnectorProps = {
  sourceRef: Frame;
  targetRef: Frame;
  connectorID: ConnectorID;
  coordinates: any;
};

function EdgeConnector(props: EdgeConnectorProps, ref: React.Ref<any>) {
  const [dAttr2, setDAttr] = useState<string>('');
  const [sameEntitiesRelationships, setSameEntitiesRelationships] = useState(-1);
  const relationships = useSelector((state: DatabaseStudioState) => state.relationships);
  const selectedConnector = useSelector(
    (state: DatabaseStudioState) => state.studio.selectedConnector
  );
  let horizontalIncrement = 0;
  let verticalIncrement = 0;

  useEffect(() => {
    let { posX: targetPosX, posY: targetPosY } = props.targetRef;
    let { posX: sourcePosX, posY: sourcePosY } = props.sourceRef;

    if (
      targetPosX === undefined ||
      targetPosY === undefined ||
      sourcePosX === undefined ||
      sourcePosY === undefined ||
      !props.coordinates
    ) {
      return;
    }
    handleCheckMultiplesConnection();

    const sourceElemRef = document.getElementById(props.sourceRef.uuid);
    const targetElemRef = document.getElementById(props.targetRef.uuid);

    if (sourceElemRef) {
      const sourceCoordinates = handleCalcPointCoordinates(
        sourceElemRef,
        sourcePosX,
        sourcePosY,
        props.coordinates.originPoint
      );
      sourcePosX = sourceCoordinates.tablePosX;
      sourcePosY = sourceCoordinates.tablePosY;
    }

    if (targetElemRef) {
      const targetCoordinates = handleCalcPointCoordinates(
        targetElemRef,
        targetPosX,
        targetPosY,
        props.coordinates.targetPoint
      );
      targetPosX = targetCoordinates.tablePosX;
      targetPosY = targetCoordinates.tablePosY;
    }

    handleCalcMultiplesConnections(sourceElemRef!, targetElemRef!);

    const xDistance = calcDistance(sourcePosX, targetPosX);
    const yDistance = calcDistance(sourcePosY, targetPosY);
    const highAxis = xDistance > yDistance ? 'x' : 'y';
    const highAxisDistance = (highAxis === 'x' ? xDistance : yDistance) / 2;

    handleDrawEdge(sourcePosX, sourcePosY, targetPosX, targetPosY, highAxis, highAxisDistance);
  }, [props.sourceRef, props.targetRef, props.coordinates]);

  function handleDrawEdge(
    sourcePosX: number,
    sourcePosY: number,
    targetPosX: number,
    targetPosY: number,
    highAxis: string,
    highAxisDistance: number
  ) {
    horizontalIncrement *= sameEntitiesRelationships;
    verticalIncrement *= sameEntitiesRelationships;

    const isSourcePosXLessThanTarget = sourcePosX < targetPosX;
    const isSourcePosYLessThanTarget = sourcePosY < targetPosY;

    const coordinates = [
      { x: 0, y: 0 }, // firstCoordinate
      { x: 0, y: 0 }, // secondCoordinate
      { x: 0, y: 0 }, // thirdCoordinate
      { x: 0, y: 0 } // fourthCoordinate
    ];

    if (highAxis === 'x') {
      coordinates[0].x = sourcePosX + horizontalIncrement;
      coordinates[0].y = sourcePosY - verticalIncrement;

      const middlePathCommonX =
        (isSourcePosXLessThanTarget ? sourcePosX : targetPosX) +
        horizontalIncrement +
        highAxisDistance;

      coordinates[1].x = middlePathCommonX;
      coordinates[1].y = sourcePosY - verticalIncrement;

      coordinates[2].x = middlePathCommonX;
      coordinates[2].y = targetPosY + verticalIncrement;

      coordinates[3].x = targetPosX;
      coordinates[3].y = targetPosY + verticalIncrement;
    } else {
      coordinates[0].x = sourcePosX + horizontalIncrement;
      coordinates[0].y = sourcePosY;

      const middlePathCommonY =
        (isSourcePosYLessThanTarget ? sourcePosY : targetPosY) +
        verticalIncrement +
        highAxisDistance;

      coordinates[1].x = sourcePosX + horizontalIncrement;
      coordinates[1].y = middlePathCommonY;

      coordinates[2].x = targetPosX - horizontalIncrement;
      coordinates[2].y = middlePathCommonY;

      coordinates[3].x = targetPosX - horizontalIncrement;
      coordinates[3].y = targetPosY + verticalIncrement;
    }

    setDAttr(
      `M ${coordinates[0].x},${coordinates[0].y} ${coordinates[1].x},${coordinates[1].y} ${coordinates[2].x},${coordinates[2].y} ${coordinates[3].x},${coordinates[3].y}`
    );
  }

  function handleCalcPointCoordinates(
    elemRef: HTMLElement,
    tablePosX: number,
    tablePosY: number,
    coordinates: any
  ): {
    tablePosX: number;
    tablePosY: number;
  } {
    if (elemRef) {
      switch (coordinates) {
        case 0: {
          tablePosX += 90;
          tablePosY += 90;
          break;
        }
        case '0': {
          break;
        }
        case '1': {
          tablePosX += elemRef.offsetWidth / 2;
          break;
        }
        case '2': {
          tablePosX += elemRef.offsetWidth;
          break;
        }
        case '3': {
          tablePosX += elemRef.offsetWidth;
          tablePosY += elemRef.offsetHeight / 2;
          break;
        }
        case '4': {
          tablePosX += elemRef.offsetWidth;
          tablePosY += elemRef.offsetHeight;
          break;
        }
        case '5': {
          tablePosX += elemRef.offsetWidth / 2;
          tablePosY += elemRef.offsetHeight;
          break;
        }
        case '6': {
          tablePosY += elemRef.offsetHeight;
          break;
        }
        case '7': {
          tablePosY += elemRef.offsetHeight / 2;
          break;
        }
      }
    }
    return { tablePosX, tablePosY };
  }

  function handleCheckMultiplesConnection() {
    setSameEntitiesRelationships(
      Object.keys(relationships)
        .filter((id) => {
          return (
            relationships[id].from === props.sourceRef.uuid &&
            relationships[id].to === props.targetRef.uuid
          );
        })
        .indexOf(props.connectorID)
    );
  }

  function handleCalcMultiplesConnections(sourceElemRef: HTMLElement, targetElemRef: HTMLElement) {
    const originPoint = props.coordinates.originPoint;
    const targetPoint = props.coordinates.targetPoint;
    switch (originPoint) {
      case '0':
        if (['2', '3', '4', '5', '6'].includes(targetPoint)) {
          verticalIncrement = 20;
          horizontalIncrement = 0;
          if (['4', '5'].includes(targetPoint)) {
            horizontalIncrement = -20;
          } else if (targetPoint === '6') {
            horizontalIncrement = 20;
          }
        }
        break;

      case '1':
        verticalIncrement = 0;
        horizontalIncrement = 20;
        if (['5', '6', '7'].includes(targetPoint)) {
          verticalIncrement = -20;
          if (
            targetPoint === '5' &&
            calcDistance(
              sourceElemRef.getBoundingClientRect().x,
              targetElemRef!.getBoundingClientRect().x
            ) < 30
          ) {
            verticalIncrement = 0;
          }
        }
        if (['3', '4'].includes(targetPoint)) {
          verticalIncrement = 20;
          horizontalIncrement = 20;
        }
        break;

      case '2':
        if (['0', '4', '5', '6', '7'].includes(targetPoint)) {
          verticalIncrement = 20;
          horizontalIncrement = 0;
          if (['5', '6'].includes(targetPoint)) {
            horizontalIncrement = 20;
          } else if (['2', '3'].includes(targetPoint)) {
            horizontalIncrement = -20;
            verticalIncrement = 20;
          }
        }
        break;

      case '3':
        if (['0', '1', '6', '7', '5'].includes(targetPoint)) {
          verticalIncrement = 20;
          if (['5', '7', '6'].includes(targetPoint)) {
            verticalIncrement = -20;
            horizontalIncrement = -20;
            if (
              targetPoint === '7' &&
              calcDistance(
                sourceElemRef.getBoundingClientRect().y,
                targetElemRef!.getBoundingClientRect().y
              ) < 45
            ) {
              horizontalIncrement = 0;
            }
          } else if (targetPoint === '1') {
            horizontalIncrement = -20;
          }
        }
        break;

      case '4':
        if (['0', '1', '2', '6', '7'].includes(targetPoint)) {
          verticalIncrement = -20;
          horizontalIncrement = 20;
          if (['1', '2'].includes(targetPoint)) {
            verticalIncrement = -20;
            if (targetPoint === '2') {
              horizontalIncrement = -20;
            }
          } else if (targetPoint === '0') {
            verticalIncrement = -20;
          } else if (['6', '7'].includes(targetPoint)) {
            verticalIncrement = -20;
            horizontalIncrement = 0;
          }
        }
        break;

      case '5':
        if (['0', '1', '7'].includes(targetPoint)) {
          horizontalIncrement = 20;
          if (
            targetPoint === '1' &&
            calcDistance(
              sourceElemRef.getBoundingClientRect().x,
              targetElemRef!.getBoundingClientRect().x
            ) > 40
          ) {
            verticalIncrement = 20;
          }
        }
        if (['2', '7'].includes(targetPoint)) {
          horizontalIncrement = -20;
          verticalIncrement = 20;
        }
        break;

      case '6':
        if (['0', '1', '2', '3', '4'].includes(targetPoint)) {
          verticalIncrement = -20;
          horizontalIncrement = -20;
          if (['1', '2'].includes(targetPoint)) {
            verticalIncrement = -20;
          } else if (['3', '4'].includes(targetPoint)) {
            verticalIncrement = -20;
            horizontalIncrement = 0;
          } else if (targetPoint === '0') {
            verticalIncrement = -20;
            horizontalIncrement = 20;
          }
        }
        break;

      case '7':
        if (['1', '2', '3', '4', '5'].includes(targetPoint)) {
          verticalIncrement = 20;
          if (['3', '4', '5'].includes(targetPoint)) {
            verticalIncrement = -20;
            horizontalIncrement = 20;
            if (
              targetPoint === '3' &&
              calcDistance(
                sourceElemRef.getBoundingClientRect().y,
                targetElemRef!.getBoundingClientRect().y
              ) < 30
            ) {
              horizontalIncrement = 0;
            }
          }
          if (targetPoint === '1') {
            horizontalIncrement = 20;
          }
        }
        break;
    }
  }

  function handleHighlightColumns() {
    if (
      (props.connectorID && !relationships[props.connectorID]) ||
      !props.connectorID ||
      props.connectorID === selectedConnector
    ) {
      removeRelationshipColumnStyles();
      return;
    }
    Object.keys(relationships[props.connectorID].components).map((pkUuid) => {
      document
        .getElementById(relationships[props.connectorID].components[pkUuid])!
        .style.setProperty('color', '#6ea8fe', 'important');
    });
  }

  function removeRelationshipColumnStyles() {
    if (
      props.connectorID &&
      relationships[props.connectorID] &&
      selectedConnector !== props.connectorID
    ) {
      Object.keys(relationships[props.connectorID].components).map((pkUuid) => {
        document
          .getElementById(relationships[props.connectorID].components[pkUuid])!
          .style.removeProperty('color');
      });
    }
  }

  function calcDistance(x1: number, x2: number): number {
    return Math.sqrt((x2 - x1) ** 2);
  }

  return (
    <g
      id={`group-${props.connectorID}`}
      ref={ref}
      className={`${styles.singleConnector}`}
      onMouseOver={handleHighlightColumns}
      onMouseLeave={removeRelationshipColumnStyles}
    >
      <path
        id={props.connectorID}
        fill="transparent"
        stroke="#000000"
        strokeWidth="4"
        d={dAttr2}
        className={
          selectedConnector === props.connectorID
            ? `${styles.edgePath} ${styles.selectedEdge}`
            : `${styles.edgePath}`
        }
      ></path>
      <text x="10" y="100" textAnchor="start" dominantBaseline="middle">
        <textPath
          id={`text-${props.connectorID}`}
          xlinkHref={'#' + props.connectorID}
          startOffset="75%"
          className={
            selectedConnector === props.connectorID
              ? `${styles.edgePath} ${styles.selectedEdge}`
              : `${styles.edgePath}`
          }
        >
          ===| |
        </textPath>
      </text>
    </g>
  );
}

export default memo(forwardRef(EdgeConnector));
