import React from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { Button, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PricingTable } from 'modules/payments/components/subscription_plan/pricing_table';
import SessionContext from '../../../modules/auth/store';

interface Step3Props {
  userName: string;
}

export function Step3({ userName }: Step3Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = React.useContext(SessionContext);

  const handleClick = async () => {
    await session.reloadUser();
    navigate('/homepage', { state: { isFirst: true } });
  };

  const description = t('login_page.welcomeForm.step4.description', { returnObjects: true });
  const descriptionArray = Array.isArray(description) ? description : [description];

  const getDescription = () => {
    return (
      <>
        <span>{descriptionArray[1]}</span>
        <span className={styles.professional_plan}>{descriptionArray[2]}</span>
        <span>{descriptionArray[3]}</span>
        <span className={styles.plan_period}>{descriptionArray[4]}</span>
        <span>{descriptionArray[5]}</span>
      </>
    );
  };

  return (
    <>
      <div className={styles.StepWrapper}>
        <div className={`${styles.DescriptionCardPricing}`}>
          <h1>
            {userName}
            {descriptionArray[0]}
          </h1>
          <p>{getDescription()}</p>
        </div>
        <div>
          <Button className={styles.startNow} onClick={handleClick}>
            {t('billing.StartNow')}
          </Button>
        </div>
        <div className={styles.PriceTableWrapper}>
          <ProgressBar variant={'info'} min={0} max={4} now={4} />
          <div className={`${styles.FormCardPricing}`}>
            <PricingTable
              onClick={handleClick}
              i18nKeyButtonLabel="billing.CurrentPlan"
              context="WELCOME_PAGE"
            />
          </div>
        </div>
      </div>
    </>
  );
}
