import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

export type ToolbarItem = {
  name: string;
  icon: string;
};

type SidebarHeaderProps = {
  tabs: ToolbarItem[];
  selectedTab: any;
  setSelectedTab: (id: any) => void;
  customId?: (elem: any) => any;
};

function SidebarHeader({ tabs, selectedTab, setSelectedTab, customId }: SidebarHeaderProps) {
  const compareId = customId ?? ((elem: ToolbarItem) => elem.name);
  return (
    <>
      <div className={styles.tabsListContainer}>
        <ul className="nav nav-pills m-2 mb-0 gap-1">
          {tabs?.map((content, index) => (
            <li id={'HelpPopover'} key={index} className={`${styles.liTabItem} nav-item`}>
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: content.name
                }}
              >
                <div
                  id={`toolbarItem${content.name}`}
                  className={
                    selectedTab === compareId(content)
                      ? `text-light bg-primary nav-link ${styles.button} ${styles.buttonActive}`
                      : `text-body-secondary bg-body-secondary nav-link ${styles.button} ${styles.buttonInactive}`
                  }
                  onClick={() => setSelectedTab(compareId(content))}
                >
                  <Icon iconName={content.icon}></Icon>
                </div>
              </HelpPopover>
            </li>
          ))}
        </ul>
      </div>
      <hr className={'border-body m-2 mb-0'} />
    </>
  );
}

export default SidebarHeader;
