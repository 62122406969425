import { Action } from 'redux';
import { ViewStateRepository } from '../repos';

export class ViewStateServ {
  async updateViewStateAction(viewId: string, action: Action) {
    return await ViewStateRepository.updateViewStateAction(viewId, action);
  }

  async updateCustomComponentAction(viewId: string, action: Action) {
    return await ViewStateRepository.updateCustomComponentAction(viewId, action);
  }

  async getViewState(viewId: string) {
    return await ViewStateRepository.getViewState(viewId);
  }

  async getCustomComponentState(customComponentId: string) {
    return await ViewStateRepository.getCustomComponentState(customComponentId);
  }
}
