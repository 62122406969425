import React, { useState } from 'react';
import { validatePath } from 'modules/logic_builder/components/dialogs/controller_creator_dialog';
import { FormControl } from 'react-bootstrap';

type Props = {
  path: string;
  handleChangePath: (val: string) => void;
};

function PathEditor({ path, handleChangePath }: Props) {
  const [isPathInvalid, setIsPathInvalid] = useState(false);
  return (
    <div style={{ width: '30rem' }}>
      <FormControl
        value={path}
        isInvalid={isPathInvalid}
        onChange={(e) => {
          setIsPathInvalid(validatePath(e.target.value));
          handleChangePath(e.target.value);
        }}
      />
    </div>
  );
}

export default PathEditor;
