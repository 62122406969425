import { Column, DataFrameManifest } from '../../../../modules/modeler/types';
import { ColumnTemplate, TableTemplate } from './factory';
import { EnumTemplate } from './factory';

import { default as TableManifest } from './Table/manifest';
import { default as EnumManifest } from './Enum/manifest';
import { Frame } from 'web_ui/workboard/frame';

export enum DataElementsTypes {
  TABLE = 'TABLE',
  ENUM = 'ENUM'
}

export enum DataAttributesTypes {
  COLUMN = 'COLUMN'
}

export type AttributesTemplatesList = {
  [key: string]: Column;
};

export type ElementsTemplatesList = {
  [key: string]: Frame;
};

export const ElementsTemplate: ElementsTemplatesList = {
  [DataElementsTypes.TABLE]: TableTemplate,
  [DataElementsTypes.ENUM]: EnumTemplate
};

export type ElementsManifestList = {
  [key: string]: DataFrameManifest;
};

export const ElementsManifest: ElementsManifestList = {
  [DataElementsTypes.TABLE]: TableManifest,
  [DataElementsTypes.ENUM]: EnumManifest
};
