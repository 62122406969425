import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LogicBuilder from 'modules/logic_builder/studio';
import {
  Controller,
  Endpoint,
  EndpointExtended,
  FunctionExtended,
  FunctionType,
  LogicBuilderConcept,
  ObjectSimple,
  SchedulerJob,
  Service,
  SHOW_ALL_URL,
  Tag
} from 'modules/logic_builder/types';
import {
  ControllersService,
  EndpointsService,
  FunctionService,
  ObjectsService,
  ScheduledsService,
  ServicesService,
  TagsService
} from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import { LocalStorageManager } from 'utils/localStorage/localstorage';
import { useQuery } from 'hooks/useQuery';
import { Folder, FolderType } from 'modules/designer/types';
import { FolderService } from 'modules/designer/services';
import AnimatedLoading from 'web_ui/animated_loading';
import { AppContext } from 'modules/project/store/app_context';

export type FolderContext = 'objects' | 'services' | 'controllers' | 'schedulers';

function LogicBuilderStudio() {
  const navigate = useNavigate();
  const queryParameters = useQuery();
  const [tags, setTags] = useState<{ [key: number]: Tag }>({});
  const [controllers, setControllers] = useState<{ [key: string]: Controller }>({});
  const [endpoints, setEndpoints] = useState<{ [key: string]: Endpoint[] }>({});
  const [services, setServices] = useState<{ [key: string]: Service }>({});
  const [functions, setFunctions] = useState<{ [key: string]: FunctionType[] }>({});
  const [schedulerJobs, setSchedulerJobs] = useState<SchedulerJob[]>([]);
  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const [editingEndpoint, setEditingEndpoint] = useState({} as EndpointExtended);
  const [editingFunction, setEditingFunction] = useState({} as FunctionExtended);
  const { app_id, module_id, service_id, controller_id, scheduler_id } = useParams();
  const [lastSelectedConcept, setStateLastSelectedConcept] = useState({
    obj: SHOW_ALL_URL,
    fnc: SHOW_ALL_URL,
    sch: SHOW_ALL_URL,
    ept: SHOW_ALL_URL
  });
  const [currentConcept, setCurrentConcept] = React.useState<LogicBuilderConcept>();
  const [objectsFolders, setObjectsFolders] = useState<Folder[]>();
  const [servicesFolders, setServicesFolders] = useState<Folder[]>();
  const [controllersFolders, setControllersFolders] = useState<Folder[]>();
  const [schedulerFolders, setSchedulerFolders] = useState<Folder[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const appInfo = useContext(AppContext).projectInformation;

  useEffect(() => {
    if (appInfo && !appInfo.has_backend) navigate('/homepage');
  }, [appInfo]);

  const updateFolders = useCallback((newFolders: Folder[], context: FolderContext): void => {
    switch (context) {
      case 'objects': {
        setObjectsFolders(newFolders);
        break;
      }
      case 'services': {
        setServicesFolders(newFolders);
        break;
      }
      case 'controllers': {
        setControllersFolders(newFolders);
        break;
      }
      case 'schedulers': {
        setSchedulerFolders(newFolders);
        break;
      }
      default:
        console.error('Missing update implementation for folder type: ', context);
    }
  }, []);

  useEffect(() => {
    if (!module_id || !app_id) return;
    const currState = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (
      currState[module_id] &&
      currState[module_id].logicBuilder.lastSelectedService &&
      currState[module_id].logicBuilder.lastSelectedTab === LogicBuilderConcept.FUNCTION
    ) {
      if (!Object.keys(services).includes(currState[module_id].logicBuilder.lastSelectedService)) {
        return;
      }
      //service
      setLastSelectedConcept(
        currState[module_id].logicBuilder.lastSelectedTab,
        currState[module_id].logicBuilder.lastSelectedService
      );
      navigateToConcept(
        currState[module_id].logicBuilder.lastSelectedTab,
        currState[module_id].logicBuilder.lastSelectedService
      );
    } else if (
      currState[module_id] &&
      currState[module_id].logicBuilder.lastSelectedController &&
      currState[module_id].logicBuilder.lastSelectedTab === LogicBuilderConcept.ENDPOINT
    ) {
      if (
        !Object.keys(controllers).includes(currState[module_id].logicBuilder.lastSelectedController)
      )
        return;
      //Controller
      setLastSelectedConcept(
        currState[module_id].logicBuilder.lastSelectedTab,
        currState[module_id].logicBuilder.lastSelectedController
      );
      navigateToConcept(
        currState[module_id].logicBuilder.lastSelectedTab,
        currState[module_id].logicBuilder.lastSelectedController
      );
    }
  }, [currentConcept, controllers, services, app_id, module_id]);

  const fetchTags = useCallback(async (appId: string) => {
    if (!appId) return;

    const fetchedTags = await TagsService.getTags(appId);
    const formattedTags: { [key: string]: Tag } = {};
    fetchedTags.forEach((tag) => {
      if (tag.id) formattedTags[tag.id] = tag;
    });
    setTags(formattedTags);
    return fetchedTags;
  }, []);

  const fetchControllers = useCallback(async (moduleId: string) => {
    if (!moduleId) return;

    const fetchedControllers = await ControllersService.getControllers(moduleId);
    const formattedControllers: { [key: string]: Controller } = {};
    fetchedControllers.forEach((controller) => {
      if (controller.uuid) formattedControllers[controller.uuid] = controller;
    });
    setControllers(formattedControllers);
  }, []);

  const fetchServices = useCallback(async (moduleId: string): Promise<Service[]> => {
    if (!moduleId) return [];
    const fetchedServices = await ServicesService.getServices(moduleId);
    const formattedServices: { [key: string]: Service } = {};
    fetchedServices.forEach((service) => {
      if (service.uuid) formattedServices[service.uuid] = service;
    });
    setServices(formattedServices);
    return fetchedServices;
  }, []);

  const fetchObjects = useCallback(async (moduleId: string): Promise<ObjectSimple[]> => {
    const fetchedObjects = await ObjectsService.getObjectsByModuleBackendFrontend(
      moduleId,
      true,
      undefined
    );
    setObjects(fetchedObjects);
    return fetchedObjects;
  }, []);

  const fetchSchedulerJobs = useCallback(async (moduleId: string) => {
    if (!moduleId) return;
    await ScheduledsService.getScheduleds(moduleId).then((fetchedScheduleds) => {
      setSchedulerJobs(fetchedScheduleds);
    });
  }, []);

  const fetchFunctions = useCallback(async (moduleId: string) => {
    if (!moduleId) return;

    const fetchedFunctions = await FunctionService.getFunctions(moduleId);

    const formattedFunctions: { [key: string]: FunctionType[] } = {};

    // Group functions by Controller. Map 'serviceUuid' : 'FunctionType[]'.
    fetchedFunctions.forEach((func) => {
      if (formattedFunctions[func.serviceUuid] == null) {
        formattedFunctions[func.serviceUuid] = [];
      }

      formattedFunctions[func.serviceUuid].push(func);
    });
    setFunctions(formattedFunctions);
  }, []);

  const fetchEndpoints = useCallback(async (moduleId: string) => {
    if (!moduleId) return;

    const fetchedEndpoints = await EndpointsService.getEndpointsByModule(moduleId);

    const formattedEndpoints: { [key: string]: Endpoint[] } = {};

    // Group endpoints by Controller. Map 'controllerUuid' : 'Endpoint[]'.
    fetchedEndpoints.forEach((endpoint) => {
      if (formattedEndpoints[endpoint.controllerUuid] == null) {
        formattedEndpoints[endpoint.controllerUuid] = [];
      }

      formattedEndpoints[endpoint.controllerUuid].push(endpoint);
    });
    setEndpoints(formattedEndpoints);
  }, []);

  const fetchFolders = useCallback(async (moduleId: string): Promise<void> => {
    FolderService.getFolderByModuleIdAndLocalType(moduleId, FolderType.LG_OBJ).then((folders) => {
      setObjectsFolders(folders);
    });
    FolderService.getFolderByModuleIdAndLocalType(moduleId, FolderType.LG_SRV).then((folders) => {
      setServicesFolders(folders);
    });
    FolderService.getFolderByModuleIdAndLocalType(moduleId, FolderType.LG_CONTROLLER).then(
      (folders) => {
        setControllersFolders(folders);
      }
    );
    FolderService.getFolderByModuleIdAndLocalType(moduleId, FolderType.LG_SCHEDULER).then(
      (folders) => {
        setSchedulerFolders(folders);
      }
    );
  }, []);

  const deleteObject = useCallback(
    (id: string) => {
      const next = [...objects];
      const index = next.findIndex((o) => o.uuid === id);
      if (index !== -1) {
        next.splice(index, 1);
      }
      setObjects(next);
    },
    [objects]
  );

  const changeObject = useCallback(
    (object: ObjectSimple) => {
      const next = objects.map((o) => {
        if (o.uuid === object.uuid) {
          return object;
        }
        return o;
      });
      setObjects(next);
    },
    [objects]
  );

  const createObject = useCallback(
    (object: ObjectSimple) => {
      const next = [...objects];
      next.push(object);
      setObjects(next);
    },
    [objects]
  );

  const checkRepeatedObject = useCallback(
    (newObject: ObjectSimple): boolean => {
      return objects.find((o) => o.name === newObject.name) != null;
    },
    [objects]
  );

  function createController(t: Controller) {
    if (t.uuid)
      setControllers({
        ...controllers,
        [t.uuid]: t
      });
  }

  function changeController(t: Controller) {
    if (t.uuid) {
      setControllers({
        ...controllers,
        [t.uuid]: t
      });
    }
  }

  function deleteController(controllerUuid: string) {
    const nextEndpoints = { ...endpoints };
    delete nextEndpoints[controllerUuid];
    setEndpoints(nextEndpoints);
    const nextControllers = { ...controllers };
    delete nextControllers[controllerUuid];
    setControllers(nextControllers);
  }

  const checkRepeatedController = useCallback(
    (changedCtrl: Controller): boolean => {
      return Object.values(controllers).find((t) => t.name === changedCtrl.name) != null;
    },
    [controllers]
  );

  const checkRepeatedSchedulerJob = useCallback(
    (changed: SchedulerJob): boolean => {
      return schedulerJobs.find((t) => t.name === changed.name) != null;
    },
    [schedulerJobs]
  );

  function createEndpoint(e: Endpoint) {
    const controllerEndpoints = endpoints[e.controllerUuid] ?? [];
    controllerEndpoints.push(e);
    setEndpoints({
      ...endpoints,
      [e.controllerUuid]: controllerEndpoints
    });
  }

  function changeEndpoint(e: Endpoint) {
    const controllerEndpoints = endpoints[e.controllerUuid];
    const remainingEndpoints = controllerEndpoints.map((endpoint) =>
      endpoint.uuid !== e.uuid ? endpoint : e
    );
    setEndpoints({
      ...endpoints,
      [e.controllerUuid]: remainingEndpoints
    });
  }

  function deleteEndpoint(endpointUuid: string, controllerUuid: string) {
    const controllerEndpoints = endpoints[controllerUuid];
    const remainingEndpoints = controllerEndpoints.filter(
      (endpoint) => endpoint.uuid !== endpointUuid
    );
    setEndpoints({
      ...endpoints,
      [controllerUuid]: remainingEndpoints
    });
  }

  function createService(s: Service) {
    if (s.uuid)
      setServices({
        ...services,
        [s.uuid]: s
      });
  }

  function changeService(s: Service) {
    if (s.uuid)
      setServices({
        ...services,
        [s.uuid]: s
      });
  }

  function deleteService(serviceUuid: string) {
    const nextFunctions = { ...functions };
    delete nextFunctions[serviceUuid];
    setFunctions(nextFunctions);
    const next = { ...services };
    delete next[serviceUuid];
    setServices(next);
  }

  const checkRepeatedService = useCallback(
    (changedService: Service): boolean => {
      return Object.values(services).find((s) => s.name === changedService.name) != null;
    },
    [services]
  );

  function createFunction(f: FunctionType) {
    const serviceFunctions = functions[f.serviceUuid] ?? [];
    serviceFunctions.push(f);
    setFunctions({
      ...functions,
      [f.serviceUuid]: serviceFunctions
    });
  }

  function changeFunction(f: FunctionType) {
    const serviceFunctions = functions[f.serviceUuid];
    const remainingFunctions = serviceFunctions.map((func) => (func.uuid !== f.uuid ? func : f));
    setFunctions({
      ...functions,
      [f.serviceUuid]: remainingFunctions
    });
  }

  const changeSchedulerJob = useCallback(
    (newJob: SchedulerJob) => {
      const next = schedulerJobs.map((o) => {
        if (o.uuid === newJob.uuid) {
          return newJob;
        }
        return o;
      });
      setSchedulerJobs(next);
    },
    [schedulerJobs]
  );

  const createSchedulerJob = useCallback(
    (newJob: SchedulerJob) => {
      const schedulers = [...schedulerJobs];
      schedulers.push(newJob);
      setSchedulerJobs(schedulers);
    },
    [schedulerJobs]
  );

  const deleteSchedulerJob = useCallback(
    (jobId: string) => {
      const newSchedulers = schedulerJobs.filter((job) => job.uuid !== jobId);
      setSchedulerJobs(newSchedulers);
    },
    [schedulerJobs]
  );

  function deleteFunction(functionUuid: string, controllerUuid: string) {
    const serviceFunctions = functions[controllerUuid];
    const remainingFunctions = serviceFunctions.filter((func) => func.uuid !== functionUuid);
    setFunctions({
      ...functions,
      [controllerUuid]: remainingFunctions
    });
  }

  function getCurrentTab(): LogicBuilderConcept {
    if (!module_id || !app_id) {
      return LogicBuilderConcept.OBJECT;
    }
    const currState = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (currState[module_id] && currState[module_id].logicBuilder.lastSelectedTab) {
      return currState[module_id].logicBuilder.lastSelectedTab;
    }
    return LogicBuilderConcept.OBJECT;
  }

  function getSelectedConcept(): LogicBuilderConcept {
    let concept = getCurrentTab();
    if (service_id) concept = LogicBuilderConcept.FUNCTION;
    else if (scheduler_id) concept = LogicBuilderConcept.SCHEDULER;
    else if (controller_id) concept = LogicBuilderConcept.ENDPOINT;
    return concept;
  }

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  function navigateToConcept(concept: LogicBuilderConcept, id?: string) {
    let navigateTo = `/app/${app_id}/module/${module_id}/logic/`;
    let lastConcept = null;
    setCurrentConcept(concept);
    switch (concept) {
      case LogicBuilderConcept.FUNCTION:
        navigateTo = navigateTo.concat('service/');
        lastConcept = lastSelectedConcept.fnc;
        break;
      case LogicBuilderConcept.SCHEDULER:
        navigateTo = navigateTo.concat('scheduler/');
        lastConcept = lastSelectedConcept.sch;
        break;
      case LogicBuilderConcept.ENDPOINT:
        navigateTo = navigateTo.concat('controller/');
        lastConcept = lastSelectedConcept.ept;
        break;
      default:
        navigateTo = navigateTo.concat('object/');
        lastConcept = lastSelectedConcept.obj;
        break;
    }
    navigateTo = navigateTo.concat(id ?? lastConcept ?? SHOW_ALL_URL);

    if (isFromVsCodeExt()) {
      navigateTo = navigateTo.concat('?vscode=true');
    }

    navigate(navigateTo);
  }

  function setLastSelectedConcept(concept: LogicBuilderConcept, id?: string) {
    switch (concept) {
      case LogicBuilderConcept.FUNCTION:
        setStateLastSelectedConcept({ ...lastSelectedConcept, fnc: id ?? SHOW_ALL_URL });
        break;
      case LogicBuilderConcept.SCHEDULER:
        setStateLastSelectedConcept({ ...lastSelectedConcept, sch: id ?? SHOW_ALL_URL });
        break;
      case LogicBuilderConcept.ENDPOINT:
        setStateLastSelectedConcept({ ...lastSelectedConcept, ept: id ?? SHOW_ALL_URL });
        break;
      default:
        setStateLastSelectedConcept({ ...lastSelectedConcept, obj: id ?? SHOW_ALL_URL });
        break;
    }
  }

  const delegateFun = useCallback(async () => {
    if (!module_id) return;
    if (!app_id) return;

    await Promise.all([
      fetchTags(app_id),
      fetchFolders(module_id),
      fetchControllers(module_id),
      fetchServices(module_id),
      fetchObjects(module_id),
      fetchEndpoints(module_id),
      fetchFunctions(module_id),
      fetchSchedulerJobs(module_id)
    ]);

    setLoading(false);
  }, [
    module_id,
    app_id,
    fetchTags,
    fetchFolders,
    fetchControllers,
    fetchServices,
    fetchObjects,
    fetchEndpoints,
    fetchFunctions,
    fetchSchedulerJobs
  ]);

  useEffect(() => {
    delegateFun();
  }, [delegateFun]);

  return loading ? (
    <AnimatedLoading />
  ) : (
    <LogicBuilderContext.Provider
      value={{
        app_id,
        tags,
        fetchTags,
        module_id,
        editingEndpoint,
        setEditingEndpoint,
        endpoints,
        createEndpoint,
        changeEndpoint,
        deleteEndpoint,
        editingFunction,
        setEditingFunction,
        functions,
        createFunction,
        changeFunction,
        deleteFunction,
        checkRepeatedService,
        controllers,
        createController,
        changeController,
        deleteController,
        fetchControllers,
        checkRepeatedController,
        fetchEndpoints,
        fetchFunctions,
        objects,
        fetchObjects,
        services,
        createService,
        changeService,
        deleteService,
        fetchServices,
        getSelectedConcept,
        navigateToConcept,
        lastSelectedConcept,
        setLastSelectedConcept,
        objectsFolders,
        controllersFolders,
        servicesFolders,
        schedulerFolders,
        updateFolders,
        deleteObject,
        changeObject,
        createObject,
        checkRepeatedObject,
        schedulerJobs,
        fetchSchedulerJobs,
        createSchedulerJob,
        deleteSchedulerJob,
        changeSchedulerJob,
        checkRepeatedSchedulerJob
      }}
    >
      <LogicBuilder />
    </LogicBuilderContext.Provider>
  );
}

export default LogicBuilderStudio;
