import { ActionsRepository } from '../repos';
import { Action } from '../types';

export class ActionsServ {
  async getAction(actionId: string): Promise<Action<any>> {
    return await ActionsRepository.getAction(actionId);
  }

  async updateAction(actionId: string, action: Action<any>): Promise<Action<any>> {
    return await ActionsRepository.updateAction(actionId, action);
  }

  async deleteAction(actionId: string) {
    return await ActionsRepository.deleteAction(actionId);
  }
}
