import React, { ChangeEvent, useState } from 'react';
import { ControlProps } from '../index';
import { useTranslation } from 'react-i18next';
import GridManagerModal from './components/gridManagerModal';
import { Button } from 'react-bootstrap';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';

function GridControl(props: ControlProps) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-3 border-bottom text-body-secondary w-100">
        <div style={{ display: 'flex' }}>
          <label className="position-relative mb-1 text-body">{props.label}</label>
          {props.tooltip && (
            <HelpPopover
              helpBoxProps={{
                description: t(`${props.tooltip}`)!
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </div>
        <div className="mb-3 mt-2">
          <Button onClick={() => setShow(true)}>Manage columns</Button>
        </div>
      </div>

      <GridManagerModal show={show} setShow={setShow} setting={props} />
    </>
  );
}

export default GridControl;
