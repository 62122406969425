import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import SessionContext from '../../../../../modules/auth/store';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { useDispatch, useSelector } from 'react-redux';
import { updateAction } from '../../../store/events/actions/update_action';
import ImportsEditor from './imports_editor';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { CustomCode } from '../../../../../modules/logic_builder/function_editor/function_actions/types/action_data';

function CodeInput({ label, placeholder, actionUuid, dataKey, options }: ActionInputProps) {
  const [customCode, setCustomCode] = useState({} as CustomCode);
  const action = useSelector((state: FunctionEditorState) => state.actions[actionUuid]);
  const session = React.useContext(SessionContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!action) return;
    if (!action.data[dataKey]) return;
    const code = action.data[dataKey].code ?? '';
    const customImports = action.data[dataKey].imports ?? [];
    setCustomCode({ code, imports: customImports });
  }, [action, dataKey]);

  const handleImportsChange = (imports: string[]) => {
    const newCustomCode = { ...customCode, imports: [...imports] };
    dispatch(updateAction(actionUuid, dataKey, newCustomCode));
  };

  const handleCodeChange = (value: string | undefined) => {
    dispatch(updateAction(actionUuid, dataKey, { ...customCode, code: value ?? '' }));
  };

  if (!action) {
    return <p>Loading</p>;
  } else {
    return (
      <>
        <span className={styles.ActionInputLabel}>{label}</span>
        <div className={styles.CustomCodeInputsWrapper}>
          <ImportsEditor customImports={customCode.imports} onChange={handleImportsChange} />
          <div className={styles.CustomCodeWrapper}>
            <Editor
              defaultLanguage={options?.language ? options.language : 'java'}
              defaultValue={placeholder}
              onChange={handleCodeChange}
              value={customCode.code}
              theme={session.preferences['exocode-theme'] ? 'vs-dark' : 'light'}
              height={'150px'}
            />
          </div>
        </div>
      </>
    );
  }
}

export default CodeInput;
