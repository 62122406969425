import { Action } from 'redux';
import { ComponentUUID, Editor, ViewUUID } from '../../../types';
import {
  DESIGNER_COMPONENT_SELECTION,
  DesignerMode,
  DraggedComponent,
  ViewportMode
} from '../index';

export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const SET_SELECTED_COMPONENT = 'SET_SELECTED_COMPONENT';
export const SET_HOVERED_COMPONENT = 'SET_HOVERED_COMPONENT';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const SET_DRAGGED_COMPONENT = 'SET_DRAGGED_COMPONENT';
export const SET_CURRENT_EDITOR = 'SET_CURRENT_EDITOR';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_DESIGNER_MODE = 'SET_DESIGNER_MODE';
export const SET_VIEWPORT_MODE = 'SET_VIEWPORT_MODE';

export interface SetCurrentPageAction extends Action {
  type: 'SET_CURRENT_VIEW';
  payload: {
    view_id: ViewUUID;
    module_id: ViewUUID;
  };
}

export interface SetSelectedComponentAction extends Action {
  type: 'SET_SELECTED_COMPONENT';
  payload: { uuid: ComponentUUID | null; type?: DESIGNER_COMPONENT_SELECTION };
}

export interface SetHoveredComponentAction extends Action {
  type: 'SET_HOVERED_COMPONENT';
  payload: ComponentUUID | null;
}

export interface SetCurrentEditorAction extends Action {
  type: 'SET_CURRENT_EDITOR';
  payload: keyof typeof Editor | null;
}

export interface SetSelectedViewAction extends Action {
  type: 'SET_SELECTED_VIEW';
  payload: ViewUUID | null;
}

export interface SetDraggedComponentAction extends Action {
  type: 'SET_DRAGGED_COMPONENT';
  payload: DraggedComponent | null;
}

export interface SetErrorMessageAction extends Action {
  type: 'SET_ERROR_MESSAGE';
  payload: string;
}

export interface SetDesignerModeAction extends Action {
  type: 'SET_DESIGNER_MODE';
  payload: DesignerMode;
}

export interface SetViewportModeAction extends Action {
  type: 'SET_VIEWPORT_MODE';
  payload: ViewportMode;
}

export const setCurrentView = (module_id: ViewUUID, view_id: ViewUUID): SetCurrentPageAction => ({
  type: 'SET_CURRENT_VIEW',
  payload: { module_id, view_id: view_id }
});

export const setSelectedComponent = (
  uuid: ComponentUUID | null,
  type?: DESIGNER_COMPONENT_SELECTION
): SetSelectedComponentAction => ({
  type: SET_SELECTED_COMPONENT,
  payload: { uuid, type }
});

export const setHoveredComponent = (uuid: ComponentUUID | null): SetHoveredComponentAction => ({
  type: SET_HOVERED_COMPONENT,
  payload: uuid
});

export const setSelectedView = (uuid: ViewUUID | null): SetSelectedViewAction => ({
  type: SET_SELECTED_VIEW,
  payload: uuid
});

export const setCurrentEditor = (editor: keyof typeof Editor | null): SetCurrentEditorAction => ({
  type: SET_CURRENT_EDITOR,
  payload: editor
});

export const setDraggedComponent = (
  draggedComponent: DraggedComponent | null
): SetDraggedComponentAction => ({
  type: SET_DRAGGED_COMPONENT,
  payload: draggedComponent
});

export const setErrorMessage = (message: string): SetErrorMessageAction => ({
  type: SET_ERROR_MESSAGE,
  payload: message
});

export const setDesignerMode = (designerMode: DesignerMode): SetDesignerModeAction => ({
  type: SET_DESIGNER_MODE,
  payload: designerMode
});

export const setViewportMode = (viewportMode: ViewportMode): SetViewportModeAction => ({
  type: SET_VIEWPORT_MODE,
  payload: viewportMode
});
