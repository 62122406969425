import React from 'react';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import ClearListIcon from './clear_list_icon';
import { t } from 'i18next';

export const CLEAR_LIST_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.CLEAR_LIST,
  name: t('ClearList'),
  description: t('ActionToRemove'),
  color: 'primary',
  category: BACKEND_ACTIONS_CATEGORIES.LIST,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.LIST,
  icon: <ClearListIcon />,
  inlineInputs: [
    {
      label: t('ClearList'),
      placeholder: '',
      key: 'list',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: { list: true }
    }
  ],
  inputs: []
};
