import React, { forwardRef, memo } from 'react';
import { ComponentUUID } from '../../../types';
import { Pagination as BSPagination } from 'react-bootstrap';

export type PaginationData = Record<string, string>;

export type PaginationStyles = {
  size?: 'sm' | 'lg';
};

export type PaginationComponentProps = {
  uuid: ComponentUUID;
  data: PaginationData;
  styles: PaginationStyles;
};

function Pagination(props: PaginationComponentProps, ref: React.Ref<any>) {
  return (
    <BSPagination size={props.styles.size} ref={ref}>
      <BSPagination.Prev />
      <BSPagination.Item active>{1}</BSPagination.Item>
      <BSPagination.Item>{2}</BSPagination.Item>
      <BSPagination.Item>{3}</BSPagination.Item>
      <BSPagination.Ellipsis />
      <BSPagination.Item>{20}</BSPagination.Item>
      <BSPagination.Next />
    </BSPagination>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(Pagination));
