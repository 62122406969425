import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { Column, DataType } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../store';
import { changeTableColumnsOrder } from '../../../store/actions/frames';
import { useTranslation } from 'react-i18next';
import { ColumnNameInput } from './common/column_name_input';
import ColumnDropdownMenu from './common/column_dropdown_menu';
import ColumnTypeIcon from './common/column_type_icon';
import ColumnDragIcon from './common/column_drag_icon';
import { ColumnTypeSelector } from './common/column_type_selector';
import { KEYS } from 'utils/keys';
import { changeColumnName } from 'modules/modeler/studio/store/actions/columns';
import { setErrorMessage } from 'modules/modeler/studio/store/actions/studio';

type SimpleColumnProps = {
  column: Column;
  fieldTypes: { [key: string]: DataType[] };
  selectedColumnID: string;
  selectColumn: React.Dispatch<React.SetStateAction<string>>;
  showDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  jumpToNextColumn: () => void;
  scroll?: number;
};

export const SimpleColumn = ({
  column,
  fieldTypes,
  selectedColumnID,
  selectColumn,
  showDeleteModal,
  jumpToNextColumn,
  scroll
}: SimpleColumnProps) => {
  const [showContextButton, setShowContextButton] = useState(false);
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const lineRef = useRef<HTMLDivElement>(null);
  const typeInputRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState('');

  const columnName = column.name;
  useEffect(() => {
    setName(columnName);
  }, [columnName]);

  useEffect(() => {
    if (!nameInputRef.current) {
      return;
    }
    if (selectedColumnID !== column.uuid) {
      return;
    }
    const { activeElement } = document;
    if (activeElement !== nameInputRef.current && activeElement !== typeInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [column.uuid, selectedColumnID]);

  const handleDragStart = function (e: React.DragEvent<HTMLElement>, columnID: string) {
    e.dataTransfer.setData('exocode/dragged-column-id', columnID);
    e.dataTransfer.dropEffect = 'copy';
    selectColumn(columnID);
  };

  const handleDragOver = () => {
    if (!lineRef?.current) return;
    lineRef.current.style.border = '1px solid #86b7fe';
    lineRef.current.style.boxShadow = '0 0 0 0.25rem rgba(13,110,253,.25)';
  };

  const handleDragleave = () => {
    if (!lineRef.current) return;
    lineRef.current.style.border = '';
    lineRef.current.style.boxShadow = '';
  };

  const handleDrop = async (e: React.DragEvent<HTMLElement>) => {
    if (lineRef && lineRef.current) {
      lineRef.current.style.border = '';
      lineRef.current.style.boxShadow = '';
    }
    const draggedColumnID = e.dataTransfer.getData('exocode/dragged-column-id');
    if (draggedColumnID === column.uuid) return;
    const tableID = column.tableUUID;
    // Can only reorder PKs if both the target and origin are PK
    if (column.isPK && !columns[draggedColumnID].isPK) return;
    if (columns[draggedColumnID].isPK && !column.isPK) return;
    handleChangeOrder(tableID, draggedColumnID, column.uuid);
  };

  const handleChangeOrder = (tableID: string, originID: string, targetID: string) => {
    dispatch(changeTableColumnsOrder(tableID, originID, targetID));
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (!nameInputRef.current || !typeInputRef.current) {
      return;
    }
    const typeInputNode = typeInputRef.current;
    const nameInputNode = nameInputRef.current;
    const { activeElement } = document;
    if (e.key === KEYS.TAB && !e.shiftKey) {
      e.preventDefault();
      if (nameInputNode === activeElement) {
        typeInputNode.focus();
      } else if (typeInputNode === activeElement) {
        jumpToNextColumn();
      }
    } else if (e.key === KEYS.ENTER && !e.shiftKey) {
      if (activeElement !== typeInputNode && activeElement !== nameInputNode) {
        return;
      }
      jumpToNextColumn();
    }
  };

  const isNameValid = (name: string) => {
    const regex = /^[a-zA-Z_][0-9a-zA-Z_]*$/;
    return regex.test(name);
  };

  const checkExistingName = (name: string): boolean => {
    const tableID = column.tableUUID;
    for (const col of Object.values(columns)) {
      if (
        col.tableUUID === tableID &&
        col.uuid !== column.uuid &&
        name.toLowerCase() === col.name.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  };

  const handleChangeName = (newName: string) => {
    setName(newName);
    if (newName.trim() === '') return;

    if (!isNameValid(newName)) {
      dispatch(setErrorMessage('modeler.IllegalNameCharacters'));
    } else if (checkExistingName(newName)) {
      dispatch(setErrorMessage('modeler.ExistingNameMessage'));
    } else if (newName.length < 2) {
      return;
    } else {
      dispatch(changeColumnName(column.uuid, newName));
    }
  };

  return (
    <div
      id={'columnWrapper'}
      ref={lineRef}
      className={`${styles.ColumnWrapper} row gx-3 ${showContextButton && 'bg-body-tertiary'} ${
        selectedColumnID === column.uuid && 'bg-body-secondary'
      } rounded-1`}
      onClick={() => selectColumn(column.uuid)}
      draggable={true}
      onDragStart={(e) => handleDragStart(e, column.uuid)}
      onDragOver={() => handleDragOver()}
      onDragLeave={() => handleDragleave()}
      onDrop={handleDrop}
      onMouseEnter={() => setShowContextButton(true)}
      onMouseLeave={() => setShowContextButton(false)}
      onKeyDown={handleKeyDown}
    >
      <ColumnDragIcon />
      <ColumnNameInput
        ref={nameInputRef}
        columnName={name}
        createColumn={false}
        handleChangeName={handleChangeName}
      />
      <ColumnTypeSelector column={column} ref={typeInputRef} fieldTypes={fieldTypes} />
      <ColumnTypeIcon column={column} />
      {(showContextButton || selectedColumnID === column.uuid) && (
        <ColumnDropdownMenu
          options={[{ label: t('Delete'), action: () => showDeleteModal(true) }]}
        />
      )}
    </div>
  );
};
