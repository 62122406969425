import * as React from 'react';
import styles from './styles.module.css';
import { BsFunnelFill } from 'react-icons/bs';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function ListParams(props: ControlProps) {
  const { t } = useTranslation();
  const [newItem, setNewItem] = React.useState('');
  const referenceBottom = React.useRef<HTMLDivElement>(null);
  const [filtering, setFiltering] = React.useState<boolean>(false);

  React.useEffect(() => {
    referenceBottom.current?.scrollIntoView({ behavior: 'smooth' });
  }, [props.value]);

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    const list = props.value instanceof Array ? [...props.value] : [];
    list.push(newItem);

    if (props.onChange) props.onChange(list, props.id);
    setNewItem('');
  }

  function handleDelete(index: number) {
    const list = props.value instanceof Array ? [...props.value] : [];
    list.splice(index, 1);

    if (props.onChange) props.onChange(list, props.id);
  }

  React.useEffect(() => {
    (() => {
      const listToSort = props.value instanceof Array ? [...props.value] : [];
      listToSort.sort(); // make the right sort
      if (props.onChange) props.onChange(listToSort, props.id);
    })();
  }, [filtering]);

  function paramString(): string {
    const list = props.value instanceof Array ? [...props.value] : [];
    if (list.length === 0) {
      return '';
    }
    const stringToShow = '/:' + props.value.toString().toLowerCase().trim().replaceAll(',', '/:');
    return stringToShow;
  }

  return (
    <div className="mb-3">
      <div
        className={`${styles.defaultLabel} form-label`}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem'
        }}
      >
        <div style={{ display: 'flex' }}>
          {props.label}
          {props.tooltip && (
            <HelpPopover
              helpBoxProps={{
                description: t(`${props.tooltip}`)!
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </div>
        <BsFunnelFill
          size={20}
          color="black"
          style={{ cursor: 'pointer' }}
          onClick={(evt) => {
            setFiltering(!filtering);
          }}
        />
      </div>
      <ul style={{ overflow: 'scroll', maxHeight: 140, overflowX: 'hidden' }}>
        {props.value &&
          props.value instanceof Array &&
          props.value.map((item: string, index: number) => {
            return (
              <li key={`${item}-${index}`}>
                <i className="fa fa-trash text-danger" onClick={() => handleDelete(index)} /> {item}
              </li>
            );
          })}
        <div ref={referenceBottom} />
      </ul>
      <div className="input-group input-group-sm mb-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setNewItem(e.target.value)}
          value={newItem}
        />
        <button className="btn btn-outline-secondary" type="button" onClick={handleClick}>
          Add
        </button>
      </div>
      <div>
        <input type="text" className="form-control" value={paramString()} disabled />
      </div>
    </div>
  );
}

export default ListParams;
