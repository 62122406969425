import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Step1 } from './step1';
import { AutomationsService } from 'modules/logic_builder/services';
import AutomationWizardProgressBar from '../../wizard_progress_bar/wizard_progress_bar';
import { Card, Container } from 'react-bootstrap';
import { WizardProgress } from '../../wizard_progress/wizard_progress';
import { SelectedAutomation } from 'routes/automation_wizard/types';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

export type ModelGeneratorAutomationData = {
  request: string;
};

export type ModelGeneratorAutomationState = {
  request: string;
};

type ModelGeneratorAutomationProps = {
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  setErrorMessage: (i18nKey: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  formValidity: boolean;
  setFormValidity: (isValid: boolean) => void;
  selectedAutomation: SelectedAutomation;
};

const ModelGeneratorAutomation = (props: ModelGeneratorAutomationProps) => {
  const { app_id, module_id } = useParams();
  const navigate = useNavigate();
  const [automationState, setAutomationState] = useState<ModelGeneratorAutomationState>();
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const initiateStates = useCallback(async () => {
    const emptyAutomationState: ModelGeneratorAutomationState = {
      request: ''
    };
    setAutomationState(emptyAutomationState);
  }, []);

  useEffect(() => {
    initiateStates();
  }, [initiateStates]);

  const handleFinish = async () => {
    if (!module_id || !app_id || !automationState || props.isLoading) return;

    props.setIsLoading(true);

    const automationData = {
      request: automationState.request
    };

    if (!automationData.request) {
      props.setIsLoading(false);
      return;
    }

    try {
      setLoading(true);
      AutomationsService.modelGeneratorAutomation(app_id, module_id, automationData).finally(() => {
        setLoading(false);
        navigate(`/app/${app_id}/module/${module_id}/schema/829fdea7-75fd-4202-9d0c-d237567ef52f`);
      });
    } catch (err: any) {
      props.setErrorMessage(`automation.errors.${err.message}`);
    } finally {
      props.setIsLoading(false);
    }
  };

  function handleUpdatedRequest(newRequest: string) {
    if (!automationState) return;
    setAutomationState({ ...automationState, request: newRequest });
    props.setFormValidity(isValidRequest());
  }

  function isValidRequest(): boolean {
    return automationState ? automationState.request?.length > 2 : false;
  }

  return (
    <Card className={`h-100 border-0 ${styles.StepWrapper}`}>
      <div className={`text-body ${styles.WizardTitle} text-center pb-2 mt-2 `}>
        <h4 className="fw-light">{t('automation.NewAutomation')}</h4>
      </div>
      <WizardProgress
        currentStep={props.currentStep}
        selectedAutomation={props.selectedAutomation}
      />
      {props.currentStep === 1 && (
        <Step1
          automationState={automationState}
          handleUpdatedRequest={handleUpdatedRequest}
          isLoading={loading}
        />
      )}

      <div className={`fixed-bottom w-100 mb-4 ${styles.ProgressBar}`}>
        <div className={styles.ProgressBarWrapper}>
          <Container className="bg-body-tertiary p-4 pb-4">
            <AutomationWizardProgressBar
              totalSteps={1}
              step={props.currentStep}
              disabled={!props.formValidity}
              onStepChange={(step) => props.setCurrentStep(step)}
              onFinish={handleFinish}
              isLoading={loading}
            />
          </Container>
        </div>
      </div>
    </Card>
  );
};

export default ModelGeneratorAutomation;
