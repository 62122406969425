import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { Navbar as BootstrapNavbar, Button, Container, Image } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SessionContext from 'modules/auth/store';
import { Preferences } from 'modules/auth/enum';
import UserProfileDropdown from '../../routes/layout/user_profile_dropdown';
import CodeDownloadList from 'routes/layout/code_download_list';
import { ProjectsService } from 'modules/project/services';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';
import UserFeedback from 'routes/layout/user_feedback';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import { WalkthroughButton } from 'web_ui/walkthrough/walkthrough_button';
import { CurrentPageLocation } from 'routes/layout';
import { CurrentPageLocationToWalkthroughContext } from 'modules/designer/components/navbar';
import { useClickOutsideEvent } from 'hooks/useClickOutside';

const allowedContexts = ['dashboard'];

export type NavbarLocationContext =
  | 'vscode'
  | 'login'
  | 'signup'
  | 'homepage'
  | 'settings'
  | 'default';

type NavbarProps = {
  context?: NavbarLocationContext;
  currentPageLocation?: CurrentPageLocation;
};

/**
 * Navbar used almost everywhere, except in the Studio
 *
 * @component
 */
function Navbar(props: NavbarProps) {
  const { t } = useTranslation();
  const { user, setDownloads, preferences, downloads } = React.useContext(SessionContext);
  const { app_id } = useParams();
  const navigate = useNavigate();
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const driverInstance = useRef<any>();

  // Fetch everytime the navbar is rendered to update the info of download.
  useEffect(() => {
    (async () => {
      if (props.context === 'settings') return;
      if (!app_id) return;
      const downloadList = await ProjectsService.getActiveJobsByApp(app_id);
      setDownloads(downloadList);
    })();
  }, [app_id, props.context, setDownloads]);

  const preventingClickFromVsCode = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.context === 'vscode') {
      ev.preventDefault();
      ev.stopPropagation();
    }
  };

  useEffect(() => {
    if (!user?.creationTime) return;

    const accountCreationDate = new Date(user.creationTime);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - accountCreationDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let formsOpened: string[] = [];
    const storedFormsOpened = localStorage.getItem('formsOpened');
    if (storedFormsOpened) {
      try {
        formsOpened = JSON.parse(storedFormsOpened);
        if (!Array.isArray(formsOpened)) {
          formsOpened = [];
        }
      } catch {
        formsOpened = [];
      }
    }

    let formKey: string | null = null;
    if (diffDays <= 4) {
      formKey = 'form1to4';
    } else if (diffDays <= 10) {
      formKey = 'form5to10';
    } else if (diffDays <= 20) {
      formKey = 'form11to20';
    } else {
      formKey = 'formAfter21';
    }

    if (formKey && !formsOpened.includes(formKey)) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [user?.creationTime]);

  const handleClick = () => {
    if (!user?.creationTime) return;

    setShowFeedback(true);
    setShowAlert(false);

    let formsOpened: string[] = [];
    const storedFormsOpened = localStorage.getItem('formsOpened');
    if (storedFormsOpened) {
      try {
        formsOpened = JSON.parse(storedFormsOpened);
        if (!Array.isArray(formsOpened)) {
          formsOpened = [];
        }
      } catch {
        formsOpened = [];
      }
    }

    const accountCreationDate = new Date(user.creationTime);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - accountCreationDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let formKey: string | null = null;
    if (diffDays <= 4) {
      formKey = 'form1to4';
    } else if (diffDays <= 10) {
      formKey = 'form5to10';
    } else if (diffDays <= 20) {
      formKey = 'form11to20';
    } else {
      formKey = 'formAfter21';
    }

    if (formKey && !formsOpened.includes(formKey)) {
      formsOpened.push(formKey);
      localStorage.setItem('formsOpened', JSON.stringify(formsOpened));
    }
  };

  function handleOpenWalkthrough(): void {
    if (!props.currentPageLocation) return;

    const currentContext = CurrentPageLocationToWalkthroughContext[props.currentPageLocation];
    if (!currentContext || !allowedContexts.includes(currentContext)) {
      return;
    }

    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done'
    });
  }

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        driverInstance.current.destroy();
      }
    }
  });

  function showQuestionMarkIcon(): boolean {
    if (!props.currentPageLocation) return false;

    const currentContext = CurrentPageLocationToWalkthroughContext[props.currentPageLocation];
    if (!currentContext || !allowedContexts.includes(currentContext)) {
      return false;
    }
    return true;
  }

  return (
    <BootstrapNavbar
      sticky="top"
      className={`bg-body-tertiary shadow-sm ${styles.ExocodeNavbar}`}
      expand="lg"
    >
      <Container
        className={`${
          props.context === 'login' || props.context === 'signup'
            ? styles.LoginNavbarContainer
            : styles.ExocodeNavbarContainer
        }`}
      >
        {/* Left side */}
        <BootstrapNavbar.Brand className={styles.ExocodeNavbarBrand}>
          <Link
            onClick={(ev) => preventingClickFromVsCode(ev)}
            id={'backToHomepage'}
            to="/homepage"
          >
            <div className={styles.imageLogoContainer}>
              <Image
                className={styles.ExocodeNavbarBrandLogo}
                src={
                  preferences[Preferences.DARK_THEME] === undefined ||
                  preferences[Preferences.DARK_THEME]
                    ? '/assets/horizontalWhiteLogo.svg'
                    : '/assets/horizontalBlackLogo.svg'
                }
                //
                width="200"
                height="150"
                alt="Exocoding Logo"
              />
            </div>
          </Link>
        </BootstrapNavbar.Brand>
        {/* Right side */}
        {props.context === 'vscode' || !props.context ? (
          <></>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {props.context === 'login' ? (
              <Container className={styles.rightContainer}>
                <div className={styles.LoginExternalLink}>{t('login_page.NewQuestion')}</div>
                <Button
                  className={styles.Button}
                  onClick={() => {
                    navigate('/signup');
                  }}
                >
                  {t('login_page.Register')}
                </Button>
              </Container>
            ) : props.context === 'signup' ? (
              <Container className={styles.rightContainer}>
                <div className={styles.LoginExternalLink}>{t('login_page.AlreadyHaveAccount')}</div>
                <Button
                  className={styles.Button}
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  {t('login_page.SignIn')}
                </Button>
              </Container>
            ) : (
              <>
                <div className="d-flex justify-content-center align-items-center d-none d-sm-flex">
                  <Link
                    to={'/settings/support'}
                    style={{ marginRight: '10px' }}
                    className="md-none"
                  >
                    <div className="d-sm-none">
                      <Icon iconName="headset" />
                    </div>
                  </Link>
                  {showQuestionMarkIcon() && (
                    <WalkthroughButton onClick={handleOpenWalkthrough}></WalkthroughButton>
                  )}
                  <button className={styles.feedbackButton}>
                    {showAlert && <span className={styles.alertIcon}>!</span>}
                    <Icon iconName="bullhorn" onClick={handleClick}></Icon>
                  </button>
                  {props.context !== 'homepage' && props.context !== 'settings' && (
                    <CodeDownloadList downloads={downloads} />
                  )}
                </div>
                <UserProfileDropdown
                  fromDashBoard={true}
                  showShowWalkthrough={showQuestionMarkIcon() ? handleOpenWalkthrough : undefined}
                  showShowFeedback={handleClick}
                  downloads={
                    props.context !== 'homepage' && props.context !== 'settings'
                      ? downloads
                      : undefined
                  }
                />
              </>
            )}
          </div>
        )}
      </Container>
      {user && (
        <UserFeedback
          show={showFeedback}
          onClose={() => setShowFeedback(false)}
          userCreationTime={user.creationTime}
        />
      )}
    </BootstrapNavbar>
  );
}

export default Navbar;
