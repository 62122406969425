import React, { ReactNode, useState, useMemo, memo, useEffect, CSSProperties } from 'react';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import RecursiveLayer from 'modules/designer/studio/toolbars/components_toolbar/layers/recursiveLayer';
import { useParams } from 'react-router-dom';
import { LocalStorageManager } from 'utils/localStorage/localstorage';
import { ErrorBoundary } from 'react-error-boundary';
import GenericFallback from 'error_boundaries/genericFallback';
import { globalReport } from 'error_boundaries';
import { useTranslation } from 'react-i18next';
import HelpPopover from './controls/components/Popover';
import { useQuery } from 'hooks/useQuery';
import DataElementsToolbar from 'modules/modeler/studio/toolbars/elements_toolbar';
import ComponentsToolbar from 'modules/designer/studio/toolbars/components_toolbar';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

export enum SidebarPosition {
  LEFT,
  RIGHT
}

export enum Studios {
  DB_MODELER = 'dbmodeler',
  DESIGNER = 'designer'
}

export type ToolbarItem = {
  id: string;
  name: string;
  icon: string;
  iconBrands?: string;
};

type SidebarProps = {
  position: SidebarPosition;
  toolbarItems?: ToolbarItem[];
  children?: ReactNode;
  isOpen?: (isOpen: boolean) => void;
  currentValueSaved?: number;
  from: Studios;
};

function Sidebar(props: SidebarProps) {
  const { app_id, module_id } = useParams();
  const queryParameters = useQuery();
  const [selectedToolbar, setSelectedToolbar] = useState(
    props.currentValueSaved ? props.currentValueSaved : 0
  );
  const [colapseSidebar, setColapseSidebar] = useState<boolean>(false);
  const [moreIconDirection, setMoreIconDirection] = useState<'LEFT' | 'RIGHT' | null>('RIGHT');
  const [scrollEventListenerAdded, setScrollEventListenerAdded] = useState(false);
  const { view_id, custom_component_id, schema_id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const horizontalMenu = document.getElementById('horizontalScrollMenu');

    if (horizontalMenu) {
      if (horizontalMenu?.scrollWidth <= horizontalMenu?.clientWidth) {
        setMoreIconDirection(null);
        return;
      }

      horizontalMenu.addEventListener('wheel', (ev) => {
        ev.preventDefault();
        horizontalMenu.scrollLeft += ev.deltaY;
      });

      const handleScroll = () => {
        const scrollPosition = horizontalMenu.scrollLeft;
        const maxScrollPosition = horizontalMenu.scrollWidth - horizontalMenu.clientWidth;

        if (scrollPosition < maxScrollPosition / 2) {
          setMoreIconDirection('RIGHT');
        } else {
          setMoreIconDirection('LEFT');
        }
      };

      const addScrollEventListener = () => {
        if (!scrollEventListenerAdded) {
          if (horizontalMenu) {
            horizontalMenu.addEventListener('scroll', handleScroll);
            setScrollEventListenerAdded(true);
          }
        }
      };

      addScrollEventListener();

      return () => {
        if (horizontalMenu && scrollEventListenerAdded) {
          horizontalMenu.removeEventListener('scroll', handleScroll);
          setScrollEventListenerAdded(false);
        }
      };
    }
  }, [scrollEventListenerAdded]);

  function handleAutoScroll() {
    const horizontalMenu = document.getElementById('horizontalScrollMenu');
    if (horizontalMenu) {
      moreIconDirection === 'RIGHT'
        ? (horizontalMenu.scrollLeft = horizontalMenu.scrollWidth)
        : (horizontalMenu.scrollLeft = 0);
    }
  }

  function handleColapse() {
    setColapseSidebar(!colapseSidebar);
    if (props.isOpen) props.isOpen(colapseSidebar);
  }

  function renderLayers() {
    if (view_id) return <RecursiveLayer componentUUID={view_id} isFirstLevel={true} />;
    if (custom_component_id)
      return <RecursiveLayer componentUUID={custom_component_id} isFirstLevel={true} />;
  }

  const savingLastStatusLeftSideBar = (index: number) => {
    if (props.from === Studios.DESIGNER) {
      const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);
      copying[module_id!] = {
        ...copying[module_id!],
        interface: {
          ...copying[module_id!].interface,
          lastSelectedLeftSidebarTab: index
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id!, copying);
    } else if (props.from === Studios.DB_MODELER) {
      const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);
      copying[module_id!] = {
        ...copying[module_id!],
        dbmodeler: {
          ...copying[module_id!].dbmodeler,
          lastSelectedLeftSidebarTab: index
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id!, copying);
    }
  };

  const savingLastStatusRightSideBar = (index: number) => {
    const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);
    copying[module_id!] = {
      ...copying[module_id!],
      interface: {
        ...copying[module_id!].interface,
        lastSelectedRightSidebarTab: index
      }
    };
    LocalStorageManager.setValueLocalStorageState(app_id!, copying);
  };

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  const styleForVsCode = (): CSSProperties => {
    return isFromVsCodeExt() ? { left: 0 } : {};
  };

  function handleMouseDown(e: React.MouseEvent<HTMLDivElement>, area: string): void {
    e.preventDefault();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }

  function handleMouseMove(e: MouseEvent): void {
    const newHeight = window.innerHeight - e.clientY;

    const toolbarContent = document.querySelector(`.${styles.toolbarContent}`) as HTMLElement;
    if (toolbarContent) {
      toolbarContent.style.height = `${newHeight}px`;
    }
  }

  function handleMouseUp(): void {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }

  const toolbars = useMemo(() => React.Children.toArray(props.children), [props.children]);
  return (
    <>
      {props.position === SidebarPosition.LEFT ? (
        <div
          className={`border-end ${styles.leftToolbarContainer}`}
          style={colapseSidebar ? { width: '0px', ...styleForVsCode() } : { ...styleForVsCode() }}
        >
          <div
            id="collapseArrowLeft"
            className={styles.leftColapseArrow}
            style={colapseSidebar ? { transform: 'rotate(180deg)', right: '-30px' } : {}}
            onClick={handleColapse}
          >
            <Icon iconName="angles-left"></Icon>
          </div>
          <aside
            className={`bg-body ${styles.leftAside}`}
            style={colapseSidebar ? { width: '0px' } : {}}
          >
            {props.from === Studios.DESIGNER && (
              <div className={`${styles.componentsContainer} mt-3`}>
                <ComponentsToolbar />
              </div>
            )}
            {schema_id && (
              <div className={`${styles.componentsContainer}`}>
                <div id="layers-content-modeler" className={`${styles.layersContent} p-1`}>
                  <DataElementsToolbar />
                </div>
              </div>
            )}

            <div
              style={view_id || custom_component_id || schema_id ? { position: 'relative' } : {}}
              className={`${styles.toolbarContent}`}
            >
              <div
                className={`border-top`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '10px',
                  cursor: 'ns-resize'
                }}
                onMouseDown={(e) => handleMouseDown(e, 'view')}
              ></div>

              <div className={`${styles.tabListContainer} ${view_id ? 'mt-3' : ''} `}>
                <ul
                  style={{ paddingBottom: '10px', overflowX: 'auto' }}
                  className={`${styles.tabList} nav nav-pills m-2`}
                >
                  {props.toolbarItems?.map((toolbarItem: ToolbarItem, index: number) => (
                    <li key={index} className={`${styles.liTabItem} nav-item`}>
                      <HelpPopover
                        placement={'top'}
                        helpBoxProps={{
                          title: toolbarItem.name
                        }}
                      >
                        <div
                          id={`tabButton-${index}`}
                          className={
                            selectedToolbar === index
                              ? `text-light bg-primary nav-link ${styles.button} ${styles.buttonActive}`
                              : `text-body-secondary bg-body-secondary nav-link ${styles.button} ${styles.buttonInactive}`
                          }
                          onClick={() => {
                            setSelectedToolbar(index);
                            savingLastStatusLeftSideBar(index);
                          }}
                        >
                          <Icon
                            brands={toolbarItem.iconBrands ? toolbarItem.iconBrands : undefined}
                            iconName={toolbarItem.icon}
                          ></Icon>
                        </div>
                      </HelpPopover>
                    </li>
                  ))}
                </ul>
              </div>
              {toolbars[selectedToolbar]}
            </div>
            {/* Icon to colapse the sidebar */}
          </aside>
        </div>
      ) : (
        <div
          className={`bg-body border-start ${styles.rightToolbarContainer}`}
          style={colapseSidebar ? { width: '0px' } : {}}
        >
          <div
            id="collapseArrowRight"
            className={styles.rightColapseArrow}
            style={colapseSidebar ? { transform: 'rotate(180deg)', left: '-30px' } : {}}
            onClick={handleColapse}
          >
            <Icon iconName="angles-right"></Icon>
          </div>
          <aside className={styles.rightAside} style={colapseSidebar ? { width: '0px' } : {}}>
            <div
              style={view_id || custom_component_id ? { resize: 'vertical' } : {}}
              className={`${styles.rightToolbarContent}`}
            >
              <div className={styles.tabListContainer}>
                <ul className="nav nav-pills m-2">
                  {props.toolbarItems?.map((toolbarItem: ToolbarItem, index: number) => (
                    <li key={index} className={`${styles.liTabItem} nav-item`}>
                      <HelpPopover
                        placement={'top'}
                        helpBoxProps={{
                          title: toolbarItem.name
                        }}
                      >
                        <div
                          id={`${toolbarItem.id.toLowerCase()}Button`}
                          className={
                            selectedToolbar === index
                              ? `text-light bg-primary nav-link ${styles.button} ${styles.buttonActive}`
                              : `text-body-secondary bg-body-secondary nav-link ${styles.button} ${styles.buttonInactive}`
                          }
                          onClick={() => {
                            setSelectedToolbar(index);
                            savingLastStatusRightSideBar(index);
                          }}
                        >
                          <Icon iconName={toolbarItem.icon}></Icon>
                        </div>
                      </HelpPopover>
                    </li>
                  ))}
                </ul>
              </div>

              {toolbars[selectedToolbar]}
            </div>
            {(view_id || custom_component_id) && (
              <div
                id={WALKTHROUGH_STEPS_ELEMENTS['designer-layers']}
                className={`${styles.layersWrapper} mt-3 pt-2 border-top`}
              >
                <label style={{ fontWeight: '600' }}>{t('designer.layers.Layers')}</label>
                <div className={`${styles.layersContent}`}>
                  <ErrorBoundary
                    key={'LAYERS'}
                    FallbackComponent={({ error, resetErrorBoundary }) => (
                      <GenericFallback
                        error={error}
                        resetErrorBoundary={resetErrorBoundary}
                        title="designer.layers.Layers"
                        size="sm"
                      />
                    )}
                    onError={globalReport}
                  >
                    {renderLayers()}
                  </ErrorBoundary>
                </div>
              </div>
            )}
          </aside>
        </div>
      )}

      <div
        id={
          props.position === SidebarPosition.LEFT ? 'left-sidebar-popover' : 'right-sidebar-popover'
        }
      />
    </>
  );
}

export default memo(Sidebar);
