import { ObjectSimple, ObjectType } from '../types';
import { ApiError } from '../../project/types';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class ObjectsRepo {
  /**
   * Create object.
   */
  async createObject(moduleId: string, object: ObjectType): Promise<ObjectType> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(object)
    };

    const url = `${API_URL}/modules/${moduleId}/objects`;
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    } else if (response.status === 409) {
      const apiError: ApiError = await response.json();
      throw new Error(apiError.message);
    } else {
      throw new Error('Something went wrong while trying to create object.');
    }
  }

  /**
   * Create an entity base object (without any relationships).
   */
  async createBaseObject(moduleId: string, entityId: string): Promise<ObjectSimple> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST'
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/objects/base`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ObjectSimple>;
      throw new Error('Something went wrong while trying to create object.');
    });
  }

  /**
   * Batch create object.
   */
  async batchCreateObjects(moduleId: string, object: ObjectType[]): Promise<ObjectType[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(object)
    };

    const url = `${API_URL}/modules/${moduleId}/objects/multiple`;
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    } else if (response.status === 409) {
      const apiError: ApiError = await response.json();
      throw new Error(apiError.message);
    } else {
      throw new Error('Something went wrong while trying to create objects.');
    }
  }

  /**
   *  Fetch objects.
   *  It can filter object by their 'backend'/'frontend' properties value, either true or false.
   *  If undefined no filter is applied.
   *  Optionally, it can include objects from dependent modules.
   */
  async getObjectsByModule(
    moduleId: string,
    backend?: boolean,
    frontend?: boolean,
    includeDependentModules?: boolean
  ): Promise<ObjectSimple[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const urlParams = new URLSearchParams();
    if (backend !== undefined) {
      urlParams.append('backend', backend ? 'true' : 'false');
    }
    if (frontend !== undefined) {
      urlParams.append('frontend', frontend ? 'true' : 'false');
    }
    if (includeDependentModules !== undefined) {
      urlParams.append('includeDependentModules', includeDependentModules ? 'true' : 'false');
    }
    const url = `${API_URL}/modules/${moduleId}/objects?`.concat(urlParams.toString());
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ObjectSimple[]>;
      throw new Error('Something went wrong while trying to fetch objects.');
    });
  }

  /**
   * Fetch objects by entity.
   */
  async getObjectsByEntity(moduleId: string, entityId: string): Promise<ObjectSimple[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/objects`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ObjectSimple[]>;
      throw new Error('Something went wrong while trying to fetch objects.');
    });
  }

  /**
   * Returns an entity schema, used to construct a new object.
   * Optionally, it can include tables/enums from dependent modules.
   */
  async getObjectsSchema(moduleId: string, includeDependentModules?: boolean) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const endpoint = `${API_URL}/modules/${moduleId}/objects/schema`.concat(
      includeDependentModules ? '?includeDependentModules=true' : ''
    );

    const response = await fetch(endpoint, options);
    const body = await response.json();

    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body;
  }

  /**
   * Get object.
   */
  async getObject(objectId: string): Promise<ObjectType> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/objects/${objectId}`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ObjectType>;
      throw new Error('Something went wrong while trying to fetch object.');
    });
  }

  /**
   * Update object.
   */
  async updateObject(objectId: string, object: ObjectType): Promise<ObjectType> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(object)
    };

    const url = `${API_URL}/objects/${objectId}`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ObjectType>;
      throw new Error('Something went wrong while trying to update object.');
    });
  }

  /**
   * Delete object.
   */
  async deleteObject(objectId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/objects/${objectId}`;
    return await fetch(url, options).then((response) => {
      if (response.ok) return;
      throw new Error('Something went wrong while trying to delete object.');
    });
  }

  /**
   * Change folder.
   */
  async changeFolder(objectId: string, folderId: string) {
    const moutingStructure = {
      objectId: objectId,
      folder_id: folderId
    };

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(moutingStructure)
    };

    const url = `${API_URL}/objects/${objectId}/changefolder`;
    const response = await fetch(url, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Something went wrong while trying to delete object.');
    }
  }
}
