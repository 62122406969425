export function setTypeBasedOnColumnName(currentName: string, currentType: string): string {
  const rawName: string = currentName.toLowerCase();

  // By
  if (rawName.includes('is') || rawName.includes('has')) {
    // pk can not be here
    return 'BOOLEAN';
  } else if (rawName.includes('txt') || rawName.includes('text')) {
    return 'STRING';
  } else if (
    rawName.includes('email') ||
    rawName.includes('address') ||
    rawName.includes('url') ||
    rawName.includes('link') ||
    rawName.includes('path') ||
    rawName.includes('name') ||
    rawName.includes('title')
  ) {
    return 'VARCHAR';
  } else if (rawName.includes('date') || rawName.includes('dt')) {
    if (rawName.includes('dttm') || rawName.includes('datetime')) {
      return 'TIMESTAMP';
    }
    return 'DATE';
  } else if (
    rawName.includes('rate') ||
    rawName.includes('perc') ||
    rawName.includes('percent') ||
    rawName.includes('currency') ||
    rawName.includes('price')
  ) {
    return 'DECIMAL';
  } else if (rawName.includes('time') || rawName.includes('hr')) {
    return 'TIME';
  } else if (
    rawName.includes('image') ||
    rawName.includes('img') ||
    rawName.includes('photo') ||
    rawName.includes('thumbnail') ||
    rawName.includes('thumb') ||
    rawName.includes('icon') ||
    rawName.includes('avatar')
  ) {
    return 'CLOB';
  } else if (
    rawName.includes('count') ||
    rawName.includes('number') ||
    rawName.includes('nr') ||
    rawName.includes('tel') ||
    rawName.includes('phone') ||
    rawName.includes('mobile') ||
    rawName.includes('by') ||
    rawName.includes('id AI') ||
    rawName.includes('id AI') ||
    rawName.includes('x') ||
    rawName.includes('y') ||
    rawName.includes('z')
  ) {
    return 'INTEGER';
  }

  return currentType;
}
