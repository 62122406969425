import React from 'react';
import TypePicker, { TYPE_PICKER_TYPES } from '../argument_picker/type_picker';
import ValuePicker from '../argument_picker/value_picker';
import ObjectNodePicker from '../../components/object_node_picker';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { Argument } from '../../../store/types/manifestsAndInputs';
import { ParameterEndpoint } from '../../../../../modules/logic_builder/types';

type RequestEditorProps = {
  param: ParameterEndpoint;
  paramValue: Argument;
  handleChange: (paramId: string, value: Argument) => void;
  actionUuid: string;
  isFromSideBar?: boolean;
};

function RequestEditor({
  param,
  paramValue,
  handleChange,
  actionUuid,
  isFromSideBar
}: RequestEditorProps) {
  const handleChangeType = (t: TYPE_PICKER_TYPES) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { type: t, value: '', objectNode: '' });
  };

  const handleChangeValue = (v: string) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { ...paramValue, value: v, objectNode: '' });
  };

  const handleChangeObjectNode = (o: string) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { ...paramValue, objectNode: o });
  };

  const filterByDataType = (state: FunctionEditorState, actionUuid: string, dataType: string) => {
    if (!dataType) return false;
    return (
      dataType.toUpperCase() === param?.type.toUpperCase() || dataType.toUpperCase() === 'OBJECT'
    );
  };

  return (
    <div
      className={`${
        isFromSideBar ? '' : 'd-flex flex-column text-white align-items-start justify-content-end'
      }`}
    >
      <span style={{ fontSize: '0.9rem' }}>Request</span>
      <div className={`${isFromSideBar ? '' : 'd-flex align-items-end'}`}>
        <p className={'text-secondary-emphasis mb-0 me-1'}>{param?.name} = </p>
        <TypePicker
          placeholder={'Select argument type'}
          label={''}
          value={paramValue?.type}
          handleChange={handleChangeType}
          argumentTypes={[TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM]}
          isFromSideBar={isFromSideBar}
        />
        <ValuePicker
          type={paramValue?.type}
          label={''}
          actionUuid={actionUuid}
          filterByList={param?.list}
          filterByDataType={filterByDataType}
          value={paramValue?.value}
          handleChange={handleChangeValue}
          isFromSideBar={isFromSideBar}
        />
        {paramValue?.type?.toUpperCase() === 'OBJECT' && (
          <ObjectNodePicker
            objectUuid={param.objectUuid ?? ''}
            filterByList={param?.list}
            value={paramValue?.objectNode ?? ''}
            handleChange={handleChangeObjectNode}
            isFromSideBar={isFromSideBar}
          />
        )}
      </div>
    </div>
  );
}

export default RequestEditor;
