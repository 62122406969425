import * as React from 'react';

import { Form } from 'react-bootstrap';
import { FetchType } from './relationsEnum';
import { useDispatch } from 'react-redux';
import { changeProperty } from 'modules/modeler/studio/store/actions/relationship';
import { Relationship } from 'modules/modeler/types';
import { useTranslation } from 'react-i18next';

type FetchOptionsProps = {
  relationship: Relationship;
};

type Options = {
  key: FetchType;
  value: string;
  status: boolean;
};

export default function FetchOptions({ relationship }: FetchOptionsProps) {
  const [options, setOptions] = React.useState<Options[]>([
    { key: FetchType.LAZY, value: 'LAZY', status: false },
    { key: FetchType.EAGER, value: 'EAGER', status: false }
  ]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const settingValue = (curr: string) => {
    switch (curr) {
      case 'LAZY':
        return [
          { key: FetchType.LAZY, value: 'LAZY', status: true },
          { key: FetchType.EAGER, value: 'EAGER', status: false }
        ];
      case 'EAGER':
        return [
          { key: FetchType.LAZY, value: 'LAZY', status: false },
          { key: FetchType.EAGER, value: 'EAGER', status: true }
        ];
      default:
        return [
          { key: FetchType.LAZY, value: 'LAZY', status: false },
          { key: FetchType.EAGER, value: 'EAGER', status: false }
        ];
    }
  };

  const handleChangeOption = (val: boolean, currItem: Options) => {
    // EAGER | LAZY
    dispatch(changeProperty(relationship.id, 'fetchType', currItem.value));
    if (currItem.key === FetchType.LAZY) {
      setOptions([
        { key: FetchType.LAZY, value: 'LAZY', status: true },
        { key: FetchType.EAGER, value: 'EAGER', status: false }
      ]);
    } else if (currItem.key === FetchType.EAGER) {
      setOptions([
        { key: FetchType.LAZY, value: 'LAZY', status: false },
        { key: FetchType.EAGER, value: 'EAGER', status: true }
      ]);
    }
  };

  React.useEffect(() => {
    setOptions(settingValue(relationship.fetchType!));
  }, [relationship.id]);

  return (
    <Form.Group>
      <Form.Label>{t('modeler.entity_editor.relations.FetchOpt')}</Form.Label>
      {options.map((item, index) => (
        <Form.Check
          key={index}
          type="radio"
          label={item.value}
          checked={item.status}
          onChange={(ev) => handleChangeOption(ev.target.checked, item)}
        />
      ))}
    </Form.Group>
  );
}
