import { Action } from 'redux';
import { ActionsState } from '../../types/actions';

export const UPDATE_ACTION = 'UPDATE_ACTION';

export interface UpdateActionAction extends Action {
  type: 'UPDATE_ACTION';
  payload: {
    uuid: string;
    key: string;
    value: any;
  };
}

export const updateAction = (uuid: string, key: string, value: any): UpdateActionAction => ({
  type: UPDATE_ACTION,
  payload: { uuid: uuid, key, value }
});

export function doUpdateAction(state: ActionsState, action: UpdateActionAction): ActionsState {
  const { uuid, key, value } = action.payload;
  state[uuid].data[key] = value;
  return state;
}
