import React from 'react';
import { ListGroup } from 'react-bootstrap';
import styles from './styles.module.css';

type SwitchProps = {
  switches: {
    label: string;
    onClick: () => void;
  }[];
  defaultActivatedIndex: number;
};

function Switch(props: SwitchProps) {
  return (
    <div>
      <div>
        <div className={`mt-3 mb-3 ${styles.orientation}`}>
          <ListGroup
            as="ul"
            defaultActiveKey={`#link${
              props.defaultActivatedIndex && props.defaultActivatedIndex.toString()
            }`}
            horizontal={'sm'}
          >
            {props.switches.map((switchItem, index) => (
              <ListGroup.Item
                id={`${switchItem.label.toLowerCase()}Button`}
                key={index}
                variant="secondary"
                href={`#link${index + 1}`}
                className={`text-center border-0 ${styles.orientationButton}`}
                as={'li'}
                action
                onClick={switchItem.onClick}
              >
                {switchItem.label}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </div>
    </div>
  );
}

export default Switch;
