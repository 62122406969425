import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import TableHeader from './table_header';
import TableBody from './table_body';
import BottomContent from './bottom_content';

type TranslationsTableProps = {
  selectedLanguage: string;
  mainLanguageId: string;
};

function TranslationsTable({ selectedLanguage, mainLanguageId }: TranslationsTableProps) {
  const [showAddTranslationModal, setShowAddTranslationModal] = useState<boolean>(false);
  const [translationsCount, setTranslationsCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);

  useEffect(() => {
    setSelectedPage(1);
  }, [selectedLanguage]);

  return (
    <>
      <Table
        className="table-responsive overflow-auto"
        hover
        striped
        style={{ marginTop: '32px', marginBottom: '24px', overflowY: 'auto' }}
      >
        <TableHeader />
        <TableBody
          selectedPage={selectedPage}
          selectedLanguage={selectedLanguage}
          mainLanguageId={mainLanguageId}
          setTranslationsCount={setTranslationsCount}
          showAddTranslationModal={showAddTranslationModal}
          setShowAddTranslationModal={setShowAddTranslationModal}
        />
      </Table>
      <BottomContent
        selectedLanguage={selectedLanguage}
        mainLanguageId={mainLanguageId}
        translationsCount={translationsCount}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        setShowAddTranslationModal={setShowAddTranslationModal}
      />
    </>
  );
}

export default TranslationsTable;
