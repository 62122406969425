import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { InterfaceStudioState, store } from '../../modules/designer/studio/store';
import { useParams } from 'react-router-dom';
import {
  EditorModeOptions,
  LayoutComponent,
  Message,
  MessageTypes,
  MoveComponentPayload,
  ViewUUID
} from '../../modules/designer/types';
import StoreUpdater from '../../modules/designer/studio/store/store_updater';
import ComponentsRenderer from '../../modules/designer/studio/exocode_components/components_renderer';
import { MoveSource, MoveTarget } from '../../modules/designer/studio/store/actions/links';
import {
  addAndMoveComponentsInto,
  addComponent,
  addComponentSet,
  addCustomComponent,
  addDetachedCustomComponent,
  ComponentPayload,
  CustomComponentsChildren,
  LinkInfo,
  LinkInfoModified,
  moveComponent
} from '../../modules/designer/studio/store/actions/root';
import { v4 as uuidv4 } from 'uuid';
import {
  COMPONENT_TYPES,
  COMPONENTS_TEMPLATE
} from '../../modules/designer/studio/exocode_components';
import {
  setDraggedComponent,
  setSelectedComponent,
  setSelectedView
} from '../../modules/designer/studio/store/actions/studio';
import { CustomComponentService, TemplatesService } from '../../modules/designer/services';
import { CHANGE_LAYOUT } from '../../modules/designer/studio/store/actions/views';
import SessionContext from '../../modules/auth/store';
import { Preferences } from '../../modules/auth/enum';
import { faker } from '@faker-js/faker';
import { VIEWS_TYPES } from 'modules/designer/studio/frames';
import {
  handleContainerFactory,
  handleGetGridGeneratedChilds
} from 'modules/designer/studio/exocode_components/grid/generatedChilds';

const READY_MESSAGE: Message = {
  type: MessageTypes.READY
};

function PreviewRenderer() {
  const [state, setState] = useState<InterfaceStudioState>();
  const [uuidToRender, setUuidToRender] = useState<string>();
  const [hasLayout, setHasLayout] = useState<boolean>(false);
  const theme = useSelector((state: InterfaceStudioState) => state.theme);
  const { uuid, module_id } = useParams();
  const dispatch = useDispatch();
  const session = useContext(SessionContext);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const editorModeRef = useRef<EditorModeOptions | null | undefined>(null);

  useEffect(() => {
    if (uuid && views) {
      editorModeRef.current = views[uuid]?.editorMode
        ? views[uuid].editorMode
        : EditorModeOptions.NORMAL;
    }
  }, [views, uuid]);

  useEffect(() => {
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);
    window.addEventListener('message', startListening);
    window.addEventListener('click', handleSelectView);
    window.parent.postMessage(READY_MESSAGE);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('message', startListening);
      window.removeEventListener('click', handleSelectView);
    };
  }, []);

  function startListening(event: MessageEvent<Message>) {
    if (event.origin !== window.location.origin) return;

    if (event.data.type === MessageTypes.LOAD) {
      setState(event.data.content as InterfaceStudioState);
      if (uuid) {
        const layout = event.data.content.views[uuid].layout_uuid;
        setHasLayout(layout ? true : false);
        setUuidToRender(layout ? layout : uuid);
      }

      return;
    }

    if (event.data.type === MessageTypes.ACTION) {
      const action = { ...event.data.content, stopReplay: true };
      if (action.type === CHANGE_LAYOUT) {
        setHasLayout(action.payload.layout_uuid ? true : false);
        setUuidToRender(
          action.payload.layout_uuid ? action.payload.layout_uuid : action.payload.uuid
        );
      }

      dispatch(action);
    }

    if (event.data.type === MessageTypes.PREFERENCES) {
      const showGrids = Preferences.SHOW_GRIDS;
      const showGridsValue = event.data.content[showGrids];
      session.changePreferences(showGrids, showGridsValue);
    }
  }

  function addStyleToHead(css: string) {
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);
  }

  function handleSelectView() {
    if (!uuid) return;
    dispatch(setSelectedView(uuid));
    dispatch(setSelectedComponent(null));
  }

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const rgbColor =
        parseInt(result[1], 16).toString() +
        ',' +
        parseInt(result[2], 16).toString() +
        ',' +
        parseInt(result[3], 16).toString();
      return rgbColor;
    } else {
      return null;
    }
  }

  useEffect(() => {
    const style = document.createElement('style');
    const bootstrapColors = [
      'primary',
      'secondary',
      'success',
      'danger',
      'warning',
      'info',
      'light',
      'dark',
      'muted',
      'white'
    ];

    if (theme['colors'] && theme['colors']['primary']) {
      let styleText = '';

      bootstrapColors.forEach((color) => {
        if (theme['colors'][color]) {
          // Overhide bootstrap button color variables
          // - background
          styleText += `.btn-${color} {--bs-btn-bg: ${theme['colors'][color]};} `;
          // - hover
          styleText += `.btn-${color} {--bs-btn-hover-bg: ${darkenHexColor(
            theme['colors'][color],
            90
          )}; --bs-btn-active-bg: ${darkenHexColor(theme['colors'][color], 70)}} `;

          document.documentElement.style.setProperty(
            `--bs-${color}-rgb`,
            `${hexToRgb(theme['colors'][color])}`
          );
        }

        addStyleToHead(styleText);
      });

      document.head.appendChild(style);
    }
  }, [theme]);

  useLayoutEffect(() => {
    const body = document.querySelector('body');
    if (body && Object.keys(theme).length) {
      body.style.backgroundColor = theme.colors.BACKGROUND_COLOR ?? body.style.backgroundColor;
      body.style.fontSize = theme.text.FONT_SIZE ?? body.style.fontSize;
      body.style.fontFamily = theme.text.GLOBAL_FONT ?? body.style.fontFamily;
      body.style.color = theme.text.GLOBAL_FONT_COLOR ?? body.style.color;
    }
  }, [theme]);

  function darkenHexColor(hexColor: string, amount: number): string {
    // Parse the input hex color string into RGB values
    const red = parseInt(hexColor.slice(1, 3), 16);
    const green = parseInt(hexColor.slice(3, 5), 16);
    const blue = parseInt(hexColor.slice(5, 7), 16);

    // Convert the percentage amount to a value between 0 and 255
    const value = Math.round(((100 - amount) / 100) * 255);

    // Calculate the new RGB values based on the calculated value
    const newRed = Math.max(0, red - value);
    const newGreen = Math.max(0, green - value);
    const newBlue = Math.max(0, blue - value);

    // Convert the new RGB values back into a hexadecimal color string
    const newHexColor = `#${pad(newRed.toString(16), 2)}${pad(newGreen.toString(16), 2)}${pad(
      newBlue.toString(16),
      2
    )}`;

    return newHexColor;
  }

  function pad(str: string, length: number): string {
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  const getCustomComponentData = React.useCallback(async (uuid: string) => {
    try {
      const custons = await TemplatesService.getTemplates(
        'createPage',
        VIEWS_TYPES.CUSTOMCOMPONENTS
      );
      const isTemplate = custons.some((Template) => Template.uuid === uuid);

      const customComponentData = await CustomComponentService.getCustomComponent(
        uuid,
        module_id!,
        isTemplate
      );

      return customComponentData;
    } catch (error) {
      console.log(error);
    }
  }, []);

  function handleDragOver(ev: DragEvent) {
    if (!ev.dataTransfer) return;
    ev.preventDefault();
    ev.dataTransfer.dropEffect = 'move';
  }

  async function handleDrop(ev: DragEvent) {
    if (!ev.dataTransfer) return;
    ev.preventDefault();
    ev.stopPropagation();

    const moveAction = ev.dataTransfer.getData('exocode/move-source');
    const addAction = ev.dataTransfer.getData('exocode/component-type');

    if (!uuid) return;

    if (moveAction) {
      const source: MoveSource = JSON.parse(moveAction);
      const target: MoveTarget = { parentUUID: uuid };

      // This check is duplicated in all views and ComponentWrapper.
      // moveComponent only if the parent of source component is not already the target.
      if (uuid !== source.parentUUID) {
        if (source.type !== COMPONENT_TYPES.GRID) {
          if (
            source.type !== COMPONENT_TYPES.CONTAINER &&
            editorModeRef.current === EditorModeOptions.NORMAL
          ) {
            handleAddContainerAndMoveComponentInto(source, target, uuid);
            dispatch(setDraggedComponent(null));
            return;
          }
        }
        dispatch(moveComponent(source, target));
      }
    } else if (addAction) {
      if (addAction === 'CUSTOM') {
        // The original custom component uuid.
        const customComponentUUID = ev.dataTransfer.getData('exocode/custom-component-uuid');

        const customComponent = await getCustomComponentData(customComponentUUID);
        if (!customComponent || !customComponent.components || !customComponent.links) return;
        if (customComponent.isHierarchy) {
          const link: LinkInfo = { viewUUID: uuid, parentUUID: uuid };
          const customComponentChildren: CustomComponentsChildren = {
            components: customComponent.components,
            links: customComponent.links
          };
          const newCustomComponentUUID = uuidv4();

          dispatch(
            addCustomComponent(
              customComponent.uuid,
              link,
              customComponentChildren,
              newCustomComponentUUID
            )
          );
        } else {
          const link: LinkInfoModified = { view: uuid, parent: uuid };
          const customComponentChildren: CustomComponentsChildren = {
            components: customComponent.components,
            links: customComponent.links
          };
          // Map between old ids and new ids. We send this to the backend because the ids from
          // both the backend and the frontend have to be the same.
          const oldIdsNewIds: Record<string, string> = {};
          oldIdsNewIds[customComponent.uuid] = uuidv4();
          for (const oldId of Object.keys(customComponent.links)) {
            const childrenIds = customComponent.links[oldId];
            for (const oldChildrenId of childrenIds) {
              oldIdsNewIds[oldChildrenId] = uuidv4();
            }
          }
          dispatch(
            addDetachedCustomComponent(
              customComponent.uuid,
              link,
              customComponentChildren,
              oldIdsNewIds
            )
          );
        }
      } else {
        const component: LayoutComponent = JSON.parse(
          JSON.stringify(Object.assign({}, COMPONENTS_TEMPLATE[addAction]))
        );
        component.uuid = uuidv4();
        component.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');

        const link: LinkInfo = { viewUUID: uuid, parentUUID: uuid };

        if (component.type === COMPONENT_TYPES.GRID) {
          const componentsSet: ComponentPayload[] = handleGetGridGeneratedChilds(
            component.uuid,
            link.viewUUID,
            component.data.columns.length
          );

          componentsSet.push({ component, link });
          dispatch(addComponentSet(componentsSet));
        }

        if (component.type !== COMPONENT_TYPES.GRID) {
          if (
            component.type !== COMPONENT_TYPES.CONTAINER &&
            editorModeRef.current === EditorModeOptions.NORMAL
          ) {
            const componentSet = handleAddContainerAroundTheComponent(component, link);
            dispatch(addComponentSet(componentSet));
          } else {
            dispatch(addComponent(component, link));
          }
        }
        dispatch(setSelectedComponent(component.uuid));
        dispatch(setSelectedView(null));
      }
    }
    dispatch(setDraggedComponent(null));
  }

  function handleAddContainerAroundTheComponent(
    component: LayoutComponent,
    link: LinkInfo
  ): ComponentPayload[] {
    const { component: containerComponent, link: containerLink } = generateContainerComponent(link);
    link.parentUUID = containerComponent.uuid;

    const componentSet: ComponentPayload[] = [];
    componentSet.push({ component, link });
    componentSet.push({ component: containerComponent, link: containerLink });

    return componentSet;
  }

  function handleAddContainerAndMoveComponentInto(
    source: MoveSource,
    target: MoveTarget,
    viewUUID: string
  ) {
    const movedComponentLink: LinkInfo = {
      parentUUID: target.parentUUID,
      viewUUID
    };

    const containerComponentPayload = generateContainerComponent(movedComponentLink);

    target.parentUUID = containerComponentPayload.component.uuid;

    const movePayload: MoveComponentPayload = {
      source: source,
      target: target
    };

    dispatch(addAndMoveComponentsInto(containerComponentPayload, [movePayload]));
  }

  function generateContainerComponent(link: LinkInfo): ComponentPayload {
    const containerComponentPayload: ComponentPayload = handleContainerFactory(
      link.viewUUID,
      link.parentUUID,
      ''
    );
    containerComponentPayload.component.data.verticalAlign = 'center';
    containerComponentPayload.component.data.horizontalAlign = 'center';
    containerComponentPayload.component.data.autoGenerated = true;

    return containerComponentPayload;
  }

  if (!state || !uuid) return <></>;

  return (
    <div
      style={{
        pointerEvents: hasLayout ? 'none' : 'all'
      }}
    >
      <StoreUpdater
        editor={state.studio.editor}
        initialTab={state.studio.designerMode}
        module_id={uuid}
        view_id={uuid}
        state={state}
      />
      <ComponentsRenderer viewUUID={uuidToRender as ViewUUID} />
    </div>
  );
}

function Preview() {
  return (
    <Provider store={store}>
      <PreviewRenderer />
      <menu id="contextmenu" />
    </Provider>
  );
}

export default Preview;
