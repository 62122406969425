import { sleep } from 'utils/utils';

export const API_URL = process.env.REACT_APP_API_URL;

export class CodePreviewRepo {
  /**
   * Returns the code preview of said function.
   */
  async runPreviewJob(appId: string, id: string, previewType: string, signal?: AbortSignal) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      signal: signal
    };

    const url = `${API_URL}/code/${appId}/preview/${previewType}/${id}`;

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong while trying to get function.');
      }
    } catch (error) {
      if (signal?.aborted) {
        console.warn('Request aborted');
      } else {
        console.error('Error:', error);
        throw error;
      }
    }
  }

  async getPreviewResult(appId: string, jobId: string, signal?: AbortSignal) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      signal: signal
    };

    const url = `${API_URL}/code/${appId}/preview/${jobId}/result`;

    try {
      let response = await fetch(url, options);
      console.log(signal?.aborted);
      while (response.status == 204 && !signal?.aborted) {
        await sleep(1000);
        response = await fetch(url, options);
      }

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error('Something went wrong while trying to get function.');
      }
    } catch (error) {
      if (signal?.aborted) {
        console.warn('Request aborted');
        return;
      } else {
        console.error('Error:', error);
        throw error;
      }
    }
  }
}
