import React, { useRef, useState } from 'react';
import styles from './styles.module.css';
import { Button, Card } from 'react-bootstrap';
import ActionIcon from './action_icon';
import ActionInlineInputs from './action_inline_inputs';
import ActionInputs from './action_inputs';
import ActionOptionsButtons from './action_options_buttons';
import Icon from '../../../../../icon';
import { FunctionActionManifest } from '../../../../store/types/manifestsAndInputs';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBlock } from '../../../../store/events/actions/delete_block';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { deleteAction } from '../../../../store/events/root/delete_action';
import Confirmation from 'web_ui/confirmation';
import { t } from 'i18next';

type ActionRootProps = {
  uuid: string;
  manifest: FunctionActionManifest;
  index: number;
  handleDrop: (e: React.DragEvent, index: number) => void;
};

function ActionRoot({ uuid, manifest, index, handleDrop }: ActionRootProps) {
  const function_id = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const action = useSelector((state: FunctionEditorState) => state.actions[uuid]);
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();

  const cardRef = useRef<HTMLDivElement>(null);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (cardRef.current) {
      // cardRef.current.style.height = '100px';
      cardRef.current.style.border = '1px dashed rgba(13, 110, 253)';
    }
  };

  const handleDragLeave = () => {
    if (cardRef.current) {
      cardRef.current.style.border = 'none';
    }
  };

  const handleOnDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (cardRef.current) {
      cardRef.current.style.border = 'none';
    }

    handleDrop(e, index);
  };

  const deleteCode = () => {
    if (!function_id) return;
    // delete action to treat in backend
    if (manifest?.openBlock && manifest?.type === 'BEGIN_IF') {
      return dispatch(deleteAction(function_id, action.uuid));
    }
    if (manifest?.openBlock) {
      return dispatch(deleteBlock(function_id, action.uuid, action.data.closeBlockActionUuid));
    }
    return dispatch(deleteAction(function_id, action.uuid));
  };

  const manifestInputs = manifest?.inputs?.filter((input) => input.showInEditor !== false);
  const manifestInlineInputs = manifest?.inlineInputs?.filter(
    (input) => input.showInEditor !== false
  );

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleOnDrop}
      // style={{ transition: 'height 1s' }}
    >
      <Card ref={cardRef}>
        <Card.Header id="emptyCard" className={`bg-body-primary ${styles.ActionHeader}`}>
          <ActionIcon icon={manifest?.icon} />
          {manifest?.inlineInputs?.length > 0 ? (
            <ActionInlineInputs uuid={uuid} inputs={manifest?.inlineInputs} />
          ) : (
            <span
              style={{
                marginBottom: '0px',
                textAlign: 'start',
                fontSize: '.9rem',
                flex: '1'
              }}
            >
              {manifest?.name}
            </span>
          )}
          <ActionOptionsButtons
            showMore={showMore}
            setShowMore={setShowMore}
            manifest={manifest}
            handleDelete={deleteCode}
          />
        </Card.Header>
        {showMore && <ActionInputs uuid={uuid} inputs={manifest?.inputs} />}
      </Card>
    </div>
  );
}

export default ActionRoot;
