import { SavedState } from 'modules/designer/types';
import { LogicBuilderConcept } from 'modules/logic_builder/types';

type LocalStorageStateType = {
  [key: string]: SavedState;
};

export class LocalStorageManager {
  static getValueLocalStorageState(key: string, module_id: string): LocalStorageStateType {
    const storedValue = window.localStorage.getItem(key);

    if (!storedValue) {
      return {
        [module_id]: {
          logicBuilder: {
            lastSelectedTab: LogicBuilderConcept.FUNCTION,
            lastSelectedService: '',
            lastSelectedController: '',
            folderState: {
              endpoints: {},
              functions: {},
              objects: {},
              schedulerJobs: {}
            }
          },
          interface: {
            lastSelectedTab: '',
            lastSelectedTabBehavior: '',
            lastSelectedView: '',
            lastSelectedComponent: '',
            lastSelectedViewport: '',
            lastSelectedLeftSidebarTab: 0,
            lastSelectedRightSidebarTab: 0,
            collapsedFolders: {},
            collapsedGroups: []
          },
          dbmodeler: {
            lastSelectedFrame: '',
            lastSelectedLeftSidebarTab: 0,
            enumFolders: {
              collapsedFolders: {}
            },
            tableFolders: {
              collapsedFolders: {}
            }
          }
        }
      };
    }

    const converted = JSON.parse(storedValue) as LocalStorageStateType;

    if (!converted[module_id]) {
      return {
        ...converted,
        [module_id]: {
          logicBuilder: {
            lastSelectedTab: LogicBuilderConcept.FUNCTION,
            lastSelectedService: '',
            lastSelectedController: '',
            folderState: {
              endpoints: {},
              functions: {},
              objects: {},
              schedulerJobs: {}
            }
          },
          interface: {
            lastSelectedTab: '',
            lastSelectedTabBehavior: '',
            lastSelectedView: '',
            lastSelectedComponent: '',
            lastSelectedViewport: '',
            lastSelectedLeftSidebarTab: 0,
            lastSelectedRightSidebarTab: 0,
            collapsedFolders: {},
            collapsedGroups: []
          },
          dbmodeler: {
            lastSelectedFrame: '',
            lastSelectedLeftSidebarTab: 0,
            enumFolders: {
              collapsedFolders: {}
            },
            tableFolders: {
              collapsedFolders: {}
            }
          }
        }
      };
    }

    return JSON.parse(storedValue) as LocalStorageStateType;
  }

  static setValueLocalStorageState(key: string, val: LocalStorageStateType) {
    window.localStorage.setItem(key, JSON.stringify(val));
  }
}
