import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { FRONTEND_VARIABLE_TYPES, FrontendProperty, LayoutComponent } from 'modules/designer/types';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { FrontendPropertiesService } from 'modules/designer/services';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { changeComponentProperty } from '../../store/actions/components';
import styles from './styles.module.css';
import { COMPONENT_TYPES } from '../../exocode_components';

const PropertyValueTypes = {
  VAR: 'Variable',
  PAGE_PARAM: 'Page Parameter',
  PROP: 'Property'
} as const;

type PropertyValueType = keyof typeof PropertyValueTypes;

type PropertyMapperDataItem = {
  // The property id.
  propertyId: string;
  // The type of the variable/page parameter being used as an argument for the property.
  propertyValueType?: PropertyValueType;
  // The variable/page parameter id.
  propertyValueId?: string;
};

type PropertyMapperDialogProps = {
  show: boolean;
  onClose: () => void;
  component: LayoutComponent;
};
function PropertyMapperDialog({ show, onClose, component }: PropertyMapperDialogProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [frontendProperties, setFrontendProperties] = useState<FrontendProperty[]>([]);
  const [mappedPropertiesData, setMappedPropertiesData] = useState<PropertyMapperDataItem[]>([]);
  const variables = useSelector((state: InterfaceStudioState) => state.variables);
  const properties = useSelector((state: InterfaceStudioState) => state.properties);
  const pageParameters = useSelector((state: InterfaceStudioState) => state.params);
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const components = useSelector((state: InterfaceStudioState) => state.components);

  /**
   * Verifica se:
   *   prop se chama `item`
   *   prop é do tipo `OBJECT`
   *   parent é do tipo LIST
   *   parent utiliza só a variável ou um objectItem
   *   o objectId da variável/objectItem é o mesmo da prop
   * @param prop
   */
  const shouldBlockPropItem = (prop: FrontendProperty) => {
    if (prop.name.toLowerCase() !== 'item' || prop.type !== FRONTEND_VARIABLE_TYPES.OBJECT) {
      return false;
    }

    const parentId = Object.keys(links).filter((id) => links[id].includes(component.uuid))[0];
    const parent = components[parentId];
    if (parent.type !== COMPONENT_TYPES.LIST) return false;

    const variableId = parent.data?.variable?.uuid ?? '';
    const objectItemId = parent.data?.variable?.objectItem ?? '';
    if (!variableId) return false;

    if (!objectItemId) return variables[variableId]?.objectUuid === prop.object;

    const variable = variables[variableId] ?? null;
    if (!variable) return false;
    const obj = objects[variable.objectUuid ?? ''];
    const objectItem = obj.objectItems?.filter((i) => i.uuid === objectItemId)[0];
    //TODO: check this in backend, as the type in FE differs from the data sent by the API
    // @ts-ignore
    return objectItem?.object?.id === prop.object;
  };

  const getAlreadyMappedProperty = useCallback(
    (propertyId: string): PropertyMapperDataItem | undefined => {
      if (!component.data || !component.data.mappedProperties) {
        return undefined;
      }
      const currentMappedProperties: PropertyMapperDataItem[] = component.data.mappedProperties;
      if (!currentMappedProperties) return undefined;
      return currentMappedProperties.find(
        (p: PropertyMapperDataItem) => p.propertyId === propertyId
      );
    },
    [component]
  );

  const findPropertyFromFetchedFrontendProperties = useCallback(
    (propertyId: string): FrontendProperty | undefined => {
      return frontendProperties.find((p) => p.uuid === propertyId);
    },
    [frontendProperties]
  );

  const getTypeName = (propInfo: FrontendProperty): string => {
    if (propInfo.type === 'OBJECT') {
      const findObject = Object.values(objects).find((o) => o.uuid === propInfo.object);
      if (findObject) {
        return findObject.name;
      } else {
        return '';
      }
    } else {
      return propInfo.type;
    }
  };

  // Reconstruct mappedProperties from 'data.mappedProperties' every time this dialog opens.
  const setupStates = useCallback(async () => {
    if (!component.custom_uuid) {
      return;
    }
    await FrontendPropertiesService.getFrontendPropertiesByCustomComponent(
      component.custom_uuid
    ).then((fetchedProperties) => {
      setFrontendProperties(fetchedProperties);
      const localMappedPropertiesData: PropertyMapperDataItem[] = [];
      for (const fetchedProperty of fetchedProperties) {
        // Check if this property is already mapped.
        let propertyDataItem: PropertyMapperDataItem;
        const alreadyMappedProperty = getAlreadyMappedProperty(fetchedProperty.uuid);
        if (alreadyMappedProperty) {
          propertyDataItem = {
            propertyId: fetchedProperty.uuid,
            propertyValueType: alreadyMappedProperty.propertyValueType,
            propertyValueId: alreadyMappedProperty.propertyValueId
          };
        } else {
          propertyDataItem = {
            propertyId: fetchedProperty.uuid,
            propertyValueId: '',
            propertyValueType: 'VAR'
          };
        }
        localMappedPropertiesData.push(propertyDataItem);
      }
      setMappedPropertiesData(localMappedPropertiesData);
    });
  }, [component.custom_uuid, getAlreadyMappedProperty]);

  useEffect(() => {
    if (!show) {
      return;
    }
    setupStates();
  }, [setupStates, show]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    dispatch(changeComponentProperty(component.uuid, 'mappedProperties', mappedPropertiesData));
    onClose();
  };

  const handleSelectType = (e: ChangeEvent<HTMLSelectElement>, propertyId: string) => {
    const newType = e.target.value as PropertyValueType;
    const nextMappedPropertiesData = mappedPropertiesData.map((p) => {
      if (p.propertyId !== propertyId) {
        return p;
      }
      return {
        ...p,
        propertyValueType: newType,
        propertyValueId: ''
      };
    });
    setMappedPropertiesData(nextMappedPropertiesData);
  };

  const handleSelectPropValue = (e: ChangeEvent<HTMLSelectElement>, propertyId: string) => {
    const newValueId = e.target.value || '';
    const nextMappedPropertiesData = mappedPropertiesData.map((p) => {
      if (p.propertyId !== propertyId) {
        return p;
      }
      return {
        ...p,
        propertyValueId: newValueId
      };
    });
    setMappedPropertiesData(nextMappedPropertiesData);
  };

  return (
    <>
      <Modal centered show={show} onHide={onClose}>
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Map Properties</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              {mappedPropertiesData.map((mappedProp) => {
                const propInfo = findPropertyFromFetchedFrontendProperties(mappedProp.propertyId);
                if (!propInfo) {
                  return null;
                }
                const blockPropItem = shouldBlockPropItem(propInfo);

                const typeName = getTypeName(propInfo);
                return blockPropItem ? (
                  <Row key={propInfo.uuid}>
                    <Col className="mb-3">
                      <div className={styles.PropertyDescription}>
                        <span>{propInfo?.name}</span>
                        <span>{propInfo?.required ? '' : '?'}</span>
                        <span>:</span>
                        <span>{typeName}</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row key={propInfo.uuid}>
                    <Col className="mb-3">
                      <div className={styles.PropertyDescription}>
                        <span>{propInfo?.name}</span>
                        <span>{propInfo?.required ? '' : '?'}</span>
                        <span>:</span>
                        <span>{typeName}</span>
                      </div>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Select
                          id="property-type-select"
                          onChange={(e) => handleSelectType(e, mappedProp.propertyId)}
                          value={mappedProp.propertyValueType}
                        >
                          {Object.keys(PropertyValueTypes).map((type) => (
                            <option key={type} value={type}>
                              {PropertyValueTypes[type as PropertyValueType]}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      {mappedProp.propertyValueType === 'VAR' && (
                        <>
                          <Form.Group>
                            <Form.Select
                              id="property-value-select"
                              onChange={(e) => handleSelectPropValue(e, mappedProp.propertyId)}
                              value={mappedProp.propertyValueId}
                            >
                              <option value="">---</option>
                              {Object.values(variables).map((variable) => {
                                let match = true;
                                if (propInfo.type !== variable.type) {
                                  match = false;
                                } else if (propInfo.list !== variable.list) {
                                  match = false;
                                }
                                if (!match) {
                                  return null;
                                }
                                return (
                                  <option key={variable.uuid} value={variable.uuid}>
                                    {variable.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </>
                      )}
                      {mappedProp.propertyValueType === 'PROP' && (
                        <>
                          <Form.Group>
                            <Form.Select
                              id="property-value-select"
                              onChange={(e) => handleSelectPropValue(e, mappedProp.propertyId)}
                              value={mappedProp.propertyValueId}
                            >
                              <option value="">---</option>
                              {Object.values(properties).map((property) => {
                                let match = true;
                                if (propInfo.type !== property.type) {
                                  match = false;
                                } else if (propInfo.list !== property.list) {
                                  match = false;
                                }
                                if (!match) {
                                  return null;
                                }
                                return (
                                  <option key={property.uuid} value={property.uuid}>
                                    {property.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </>
                      )}
                      {mappedProp.propertyValueType === 'PAGE_PARAM' && (
                        <>
                          <Form.Group>
                            <Form.Select
                              id="property-value-select"
                              onChange={(e) => handleSelectPropValue(e, mappedProp.propertyId)}
                              value={mappedProp.propertyValueId}
                            >
                              <option value="">---</option>
                              {Object.values(pageParameters).map((parameter) => {
                                let match = true;
                                if (propInfo.type !== 'STRING') {
                                  match = false;
                                }
                                if (!match) {
                                  return null;
                                }
                                return (
                                  <option key={parameter.uuid} value={parameter.uuid}>
                                    {parameter.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button id="saveButton" type="submit">
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

type PropertyMapperProps = {
  component?: LayoutComponent;
};
export function PropertyMapper({ component }: PropertyMapperProps) {
  const [showPropertyMapperDialog, setShowPropertyMapperDialog] = useState(false);

  if (!component) {
    return <></>;
  }
  if (!component.custom_uuid) {
    return <></>;
  }

  return (
    <>
      <Button
        className={`w-100 mt-2`}
        variant="primary"
        onClick={() => setShowPropertyMapperDialog(true)}
      >
        Map Properties
      </Button>
      <PropertyMapperDialog
        show={showPropertyMapperDialog}
        component={component}
        onClose={() => setShowPropertyMapperDialog(false)}
      />
    </>
  );
}
