import { CssClass } from 'web_ui/workboard/sidebar/controls/CssClassesControl/types';

export const API_URL = process.env.REACT_APP_API_URL;

export class CssStylesRepo {
  async createCssClass(appId: string, cssClass: CssClass): Promise<CssClass> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(cssClass)
    };

    const url = `${API_URL}/apps/${appId}/css-classes`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CssClass>;
      throw new Error('Something went wrong trying to create css class.');
    });
  }

  async getCssClassesByApp(appId: string): Promise<CssClass[]> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${appId}/css-classes`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CssClass[]>;
      throw new Error('Something went wrong while trying to fetch css classes.');
    });
  }

  async deleteCssClass(appId: string, cssClassId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${appId}/css-classes/${cssClassId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response;
      throw new Error('Something went wrong while trying to delete css class.');
    });
  }

  async updateCssClass(appId: string, cssClass: CssClass): Promise<CssClass> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(cssClass)
    };

    const url = `${API_URL}/apps/${appId}/css-classes`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CssClass>;
      throw new Error('Something went wrong while trying to update css class.');
    });
  }
}
