import produce from 'immer';
import { ActionsState } from '../types/actions';
import { ADD_ACTION, AddActionAction } from '../events/actions/add_action';
import { doAddAction } from '../events/actions/add_action';
import { doUpdateAction, UPDATE_ACTION, UpdateActionAction } from '../events/actions/update_action';
import { ADD_BLOCK, AddBlockAction, doAddBlock } from '../events/actions/add_block';
import { DELETE_BLOCK, DeleteBlockAction, doDeleteBlock } from '../events/actions/delete_block';
import { initialState } from '../initial_empty_state';
import { doReplaceEmpty, REPLACE_EMPTY, ReplaceEmptyAction } from '../events/actions/replace_empty';
import { CHANGE_RETURN, ChangeReturnAction, doChangeReturn } from '../events/actions/change_return';
import { CREATE_IF, CreateIfAction, doCreateIf } from '../events/actions/create_if';
import { CREATE_ELSE, CreateElseAction, doCreateElse } from '../events/actions/create_else';

type ActionsActions =
  | AddActionAction
  | UpdateActionAction
  | AddBlockAction
  | CreateIfAction
  | CreateElseAction
  | DeleteBlockAction
  | ReplaceEmptyAction
  | ChangeReturnAction;

export const actionsReducer = (
  state: ActionsState = initialState.actions,
  action: ActionsActions
): ActionsState => {
  return produce(state, (draft: ActionsState) => {
    switch (action.type) {
      case ADD_ACTION:
        return doAddAction(draft, action);
      case UPDATE_ACTION:
        return doUpdateAction(draft, action);
      case ADD_BLOCK:
        return doAddBlock(draft, action);
      case CREATE_IF:
        return doCreateIf(draft, action);
      case CREATE_ELSE:
        return doCreateElse(draft, action);
      case DELETE_BLOCK:
        return doDeleteBlock(draft, action);
      case REPLACE_EMPTY:
        return doReplaceEmpty(draft, action);
      case CHANGE_RETURN:
        return doChangeReturn(draft, action);
      default:
        return state;
    }
  });
};
