import React from 'react';
import styles from './styles.module.css';
import FileInput, { faIconName } from './fileInput';

interface InputFileDropzoneProps {
  width?: string;
  height?: string;
  color?: string;
  background?: string;

  icon?: faIconName;
  label?: string;
  buttonText?: string;
}

function InputFileDropzone({
  width,
  height,
  color,
  background,
  icon,
  label,
  buttonText
}: InputFileDropzoneProps) {
  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      className={`${styles.fileContainer} rounded p-3`}
      style={{
        border: '3px solid',
        borderColor: color ? color : '#027bff',
        width: width ? width : '360px',
        height: height ? height : 'fit-content',
        backgroundColor: background
      }}
    >
      <FileInput color={color} buttonText={buttonText} label={label} icon={icon as faIconName} />
    </div>
  );
}

export default InputFileDropzone;
