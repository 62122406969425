import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './styles.module.css';
import { DropdownMenu } from './DropdownMenu';
import { DropdownToggle } from './DropdownToggle';
import CreateViewDialog from 'modules/designer/studio/toolbars/views_toolbar/create_view_modal';
import { Page, Modal, Layout } from 'modules/designer/types';
import { useParams } from 'react-router-dom';

type BreadcrumbViewDropdownProps = {
  pagesList: Page[];
  modalsList: Modal[];
  layoutsList: Layout[];
  handleOnSelectView: (view: Page | Modal | Layout) => void;
  fetchViews: () => void;
};

export function BreadcrumbViewDropdown(props: BreadcrumbViewDropdownProps) {
  const [showCreateViewModal, setShowCreateViewModal] = React.useState(false);
  const [selectedView, setSelectedView] = React.useState<Page | Modal | Layout>();
  const { view_id } = useParams();

  const modalOnCloseRequest = React.useCallback(() => {
    setShowCreateViewModal(false);
  }, []);

  useEffect(() => {
    if (view_id) {
      // Set the selectedView (default value highlighted in the dropdown list).
      let selectedView: Page | Modal | Layout | undefined = props.pagesList.find(
        (x) => x.uuid === view_id
      );
      if (!selectedView) selectedView = props.modalsList.find((x) => x.uuid === view_id);
      if (!selectedView) selectedView = props.layoutsList.find((x) => x.uuid === view_id);
      setSelectedView(selectedView);
    }
  }, [props.layoutsList, props.modalsList, props.pagesList, view_id]);

  return (
    <Dropdown>
      <Dropdown.Toggle as={DropdownToggle} id="dropdown-toggle">
        {selectedView?.name}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={DropdownMenu}
        openModal={() => setShowCreateViewModal(true)}
        buttonLabel="New view"
        filterPlaceholder=""
      >
        {props.pagesList?.map((page) => (
          <Dropdown.Item
            key={page.uuid}
            eventKey={page.uuid}
            onClick={() => props.handleOnSelectView(page)}
            className={`${styles.DropdownItem} ${
              selectedView?.uuid === page.uuid && styles.DropdownItemSelected
            }`}
          >
            {/* This is used to filter pages,
                if you want filter to keep working this hidden <span>
                should stay as the first children of <Dropdown.Item> */}
            <span className={styles.HiddenFilterParameter}>{`${page.name}${page.route}`}</span>
            <div>
              {page.name}
              <span className={styles.PageRouteName}> {page.route}</span>
            </div>
            <div className={styles.ViewType}>Page</div>
          </Dropdown.Item>
        ))}
        {props.modalsList?.map((modal) => (
          <Dropdown.Item
            key={modal.uuid}
            eventKey={modal.uuid}
            onClick={() => props.handleOnSelectView(modal)}
            className={`${styles.DropdownItem} ${
              selectedView?.uuid === modal.uuid && styles.DropdownItemSelected
            }`}
          >
            {/* This is used to filter pages,
                if you want filter to keep working this hidden <span>
                should stay as the first children of <Dropdown.Item> */}
            <span className={styles.HiddenFilterParameter}>{`${modal.name}`}</span>
            <div>{modal.name}</div>
            <div className={styles.ViewType}>Modal</div>
          </Dropdown.Item>
        ))}
        {props.layoutsList?.map((layout) => (
          <Dropdown.Item
            key={layout.uuid}
            eventKey={layout.uuid}
            onClick={() => props.handleOnSelectView(layout)}
            className={`${styles.DropdownItem} ${
              selectedView?.uuid === layout.uuid && styles.DropdownItemSelected
            }`}
          >
            {/* This is used to filter pages,
                if you want filter to keep working this hidden <span>
                should stay as the first children of <Dropdown.Item> */}
            <span className={styles.HiddenFilterParameter}>{`${layout.name}`}</span>
            <div>{layout.name}</div>
            <div className={styles.ViewType}>Layout</div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
      <CreateViewDialog
        showDialog={showCreateViewModal}
        dialogOnClose={modalOnCloseRequest}
        handleUpdateViewsList={props.fetchViews}
        layoutsList={props.layoutsList}
        parentUuid=""
      />
    </Dropdown>
  );
}
