import { Action } from 'redux';
import { PropertiesState } from '../index';
import { FrontendProperty } from 'modules/designer/types';

export const SET_PROPERTIES = 'SET_PROPERTIES';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';

export interface SetPropertiesAction extends Action {
  type: 'SET_PROPERTIES';
  payload: {
    properties: PropertiesState;
  };
}

export interface AddPropertyAction extends Action {
  type: 'ADD_PROPERTY';
  payload: {
    property: FrontendProperty;
  };
}

export interface UpdatePropertyAction extends Action {
  type: 'UPDATE_PROPERTY';
  payload: {
    property: FrontendProperty;
  };
}

export interface DeletePropertyAction extends Action {
  type: 'DELETE_PROPERTY';
  payload: {
    property: string;
  };
}

export const setProperties = (properties: PropertiesState): SetPropertiesAction => ({
  type: SET_PROPERTIES,
  payload: { properties }
});

export const addProperty = (property: FrontendProperty): AddPropertyAction => ({
  type: ADD_PROPERTY,
  payload: { property }
});

export const updateProperty = (property: FrontendProperty): UpdatePropertyAction => ({
  type: UPDATE_PROPERTY,
  payload: { property }
});

export const deleteProperty = (propertyId: string): DeletePropertyAction => ({
  type: DELETE_PROPERTY,
  payload: { property: propertyId }
});
