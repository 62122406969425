import styles from './styles.module.css';
import React from 'react';
import { Index } from '../../../../../../../types';
import { useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../../../../store';
import IndexLine from '../index-line';

type IndexesListProps = {
  tableIndexes: Index[];
  selectedIndexID: string;
  selectIndex: React.Dispatch<React.SetStateAction<string>>;
};
export default function IndexesList({
  tableIndexes,
  selectedIndexID,
  selectIndex
}: IndexesListProps) {
  const indexes = useSelector((state: DatabaseStudioState) => state.indexes);
  const renderIndexesList = () => {
    return tableIndexes.map((index) => {
      if (!index || !index.id || !indexes[index.id]) return;
      return (
        <IndexLine
          key={index?.id}
          index={index}
          selectedIndexID={selectedIndexID}
          selectIndex={selectIndex}
        />
      );
    });
  };

  return (
    <div id="bodyModal" className={`card p-2 ${styles.IndexesListWrapper}`}>
      {renderIndexesList()}
    </div>
  );
}
