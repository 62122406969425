import React from 'react';
import { ControlProps } from '../index';
import ModalPictures from './Components/ModalPictures';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';

function SelectPicsControl(props: ControlProps) {
  // const [dataFromApi, setdataFromApi] = React.useState<fileMetaData[]>();
  // const [val, setVal] = React.useState<string>();
  // const API_URL = process.env.REACT_APP_API_URL;
  // const { app_id } = useParams();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { t } = useTranslation();

  // passes the event and for the value we pass the url already mounted to use in src
  // function handleOnChange(event: ChangeEvent<HTMLSelectElement>) {
  //   if (props.onChange) props.onChange(dataFromApi![Number(event.target.value)].url, props.id);
  // }

  return (
    <div style={{ marginBottom: '1rem' }}>
      <ModalPictures
        show={showModal}
        onHide={() => setShowModal(false)}
        controlPropsFromRoot={props}
      />
      <div style={{ display: 'flex' }}>
        <label htmlFor="select-pics">{props.label}</label>
        {props.tooltip && (
          <HelpPopover
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>
      <button
        className="btn btn-primary w-100 mt-2"
        onClick={(e) => setShowModal(true)}
        id="choosePictureButton"
      >
        {t('uploadImage.choosePicture')}
      </button>
    </div>
  );
}

export default SelectPicsControl;
