import React, { useState } from 'react';
import { t } from 'i18next';
import { Spinner } from 'react-bootstrap';
import HelpPopover from '../../../../workboard/sidebar/controls/components/Popover';
import { FunctionService } from '../../../../../modules/logic_builder/services';

type HeaderSignatureCheckProps = {
  functionId: string;
};

function HeaderSignatureCheck(props: HeaderSignatureCheckProps) {
  //const { view_id } = useParams();
  const [isValid, setIsValid] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleCheckSignature(functionId: string) {
    setIsLoading(true);
    // Back-End Function
    await FunctionService.checkDuplicateSignature(functionId)
      .then((isDuplicate) => {
        setIsValid(!isDuplicate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div style={{ marginLeft: '10px' }}>
      <HelpPopover
        placement={'top'}
        helpBoxProps={{
          title: t('logicBuilder.CheckFunctionSignature')!
        }}
      >
        <div
          id={`checkFunctionSignature`}
          className={`btn btn-md text-body-secondary bg-body-secondary border-0`}
          onClick={() => handleCheckSignature(props.functionId)}
        >
          {isLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          {!isLoading && (
            <span
              className={`fa fa-circle-check ${
                isValid !== undefined
                  ? isValid
                    ? `fa-solid text-success`
                    : `fa-solid text-danger`
                  : `fa-regular`
              }`}
            />
          )}
        </div>
      </HelpPopover>
    </div>
  );
}

export default HeaderSignatureCheck;
