import { AddInvite, ComplexMember } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

export default class Invitation {
  async createInviteOrg(orgId: number, data: AddInvite[]) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    const options: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };
    const request = new Request(`${API_URL}/orgs/${orgId}/invitations`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.status === 202) {
        return true;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async getInvitesOrg(orgId: number) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    const options: RequestInit = {
      method: 'GET',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include'
    };
    const request = new Request(`${API_URL}/orgs/${orgId}/invitations`, options);

    const response = await fetch(request);
    if (response.ok) {
      return (await response.json()) as Promise<ComplexMember[]>;
    }
    const message = await response.text();
    throw new Error(message);
  }

  async createInviteApp(appId: string, data: AddInvite[]) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    const options: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };
    const request = new Request(`${API_URL}/apps/${appId}/invitations`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.status === 202) {
        return true;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }

  async deleteInvite(orgId: number, inviteId: number) {
    const requestHeader = new Headers();
    requestHeader.append('Content-type', 'Application/json');

    // mouting opt
    const options: RequestInit = {
      method: 'DELETE',
      headers: requestHeader,
      mode: 'cors',
      credentials: 'include'
    };

    const URL_REQUEST = `${API_URL}/orgs/${orgId}/invitations/${inviteId}`;

    // new request
    const request = new Request(URL_REQUEST, options);

    // returning the objected fetched
    const response = await fetch(URL_REQUEST, options);

    if (response.status === 200) {
      return true;
    } else {
      throw new Error('impossible to delete this invitation!!');
    }
  }
}
