import React from 'react';
import { Card } from 'react-bootstrap';
import { TableAction } from '../../../data_table';
import ActionsBar from './actions_bar';

type ImageTypeRendererProps = {
  actions?: TableAction[];
  selectable?: boolean;
};

function ImageTypeRenderer({ actions, selectable }: ImageTypeRendererProps) {
  return (
    <Card className={'p-2 gap-2'} style={{ width: '200px' }}>
      <div
        id={'content-and-select'}
        className={'w-100 position-relative'}
        style={{ height: '180px' }}
      >
        <div
          id={'content'}
          className={'bg-secondary d-flex align-items-center justify-content-center h-100'}
        >
          <span
            className={'fa fa-file-image text-secondary-emphasis p-1'}
            style={{ fontSize: '5rem' }}
          />
        </div>
        {selectable && (
          <div id={'select-box'} className={'position-absolute top-0 end-0 pe-1'}>
            <input type={'checkbox'} className={'p-0'} />
          </div>
        )}
      </div>
      <ActionsBar actions={actions} />
    </Card>
  );
}

export default ImageTypeRenderer;
