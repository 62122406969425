import React, { ReactNode } from 'react';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

type SidebarContentProps = {
  children?: ReactNode;
};

function SidebarContent({ children }: SidebarContentProps) {
  return (
    <div
      className={'p-2 d-flex h-100'}
      style={{ width: '100%' }}
      id={WALKTHROUGH_STEPS_ELEMENTS['logic-left-sidebar']}
    >
      {children}
    </div>
  );
}

export default SidebarContent;
