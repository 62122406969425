import { ComponentUUID, ManifestLinks, ViewUUID } from 'modules/designer/types';
import React, { forwardRef, memo } from 'react';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';

export type NavData = {
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  links: ManifestLinks;
  direction?: 'left' | 'center' | 'right';
  column?: boolean;
  tooltip?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type NavStyles = {
  staticColor?: string;
  background?: string;
  color?: string;
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  fontWeight?: string;
  css?: string;
  classes?: string[];
};

export type NavComponentProps = {
  viewUUID: ViewUUID;
  uuid: ComponentUUID;
  data: NavData;
  styles: NavStyles;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function NavComponent(props: NavComponentProps, ref: React.Ref<any>) {
  function settingDirection(): string {
    if (props.data.direction === 'center') {
      return 'center';
    } else if (props.data.direction === 'right') {
      return 'flex-end';
    } else {
      return '';
    }
  }

  const style = {
    display: 'flex',
    justifyContent: settingDirection(),
    alignItems: 'center',
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.staticColor,
    background: props.styles.staticColor,
    fontWeight: `${props.styles.fontWeight}`,
    fontSize: `${props.styles.fontSize}px`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  const classes = props.styles.classes?.join(' ') || '';

  //props.uuid is the current component
  return (
    <div
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      title={props.data.tooltip}
      className={`${classes}`}
    >
      <ul className={`nav ${props.data.column && 'flex-column'} nav-pills`}>
        {props.data &&
          props.data.links &&
          props.data.links.children &&
          props.data.links.children[props.uuid] &&
          props.data.links.children[props.uuid].map((key: string, index: number) => {
            if (props.data.links.children[key]) {
              return (
                <>
                  <li className="nav-item dropdown" key={index}>
                    <a
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      {props.data.links.items[key].title}
                    </a>
                    <ul className="dropdown-menu">
                      {props.data.links.children[key] &&
                        props.data.links.children[key].map(
                          (keyChildrenY: string, indexy: number) => {
                            if (!props.data.links.items[keyChildrenY]) return null;
                            return (
                              <li key={indexy}>
                                <a className="dropdown-item" href="#">
                                  {props.data.links.items[keyChildrenY].title}
                                </a>
                              </li>
                            );
                          }
                        )}
                    </ul>
                  </li>
                </>
              );
            } else {
              return (
                <>
                  <li className={`nav-item`} key={index}>
                    <a
                      className={`nav-link ${props.data.links.items[key].hide && 'disabled'}  ${
                        index == 0 && 'active'
                      }`}
                      aria-current="page"
                      href={'#'}
                    >
                      {props.data.links.items[key].title}
                    </a>
                  </li>
                </>
              );
            }
          })}
      </ul>
    </div>
  );
}

export default memo(forwardRef(NavComponent));
export * from './manifest';
export * from './template';
