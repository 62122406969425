import React, { useState } from 'react';
import styles from './styles.module.css';
import { ControlProps } from '..';
import Switch from 'web_ui/workboard/sidebar/controls/components/Switch';
import OverflowItem from './OverflowItem';
import HelpIcon from '../components/HelpIcon';
import HelpPopover from '../components/Popover';
import { useTranslation } from 'react-i18next';

function OverflowControl(props: ControlProps) {
  const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>('vertical');
  const { t } = useTranslation();

  function handleSelectOverflowType(overflowType: string) {
    if (!props.value || !props.onChange) return;

    props.onChange(overflowType, orientation === 'horizontal' ? 'overflowx' : 'overflowy');
  }

  return (
    <div className="mb-3 pb-4 border-bottom text-body-secondary">
      <label className="position-relative mb-1 text-body">{props.label}</label>
      <HelpPopover
        helpBoxProps={{
          title:
            t('designer.right_side.controls.OverflowTitle') ||
            'designer.right_side.controls.OverflowTitle',
          description:
            t('designer.right_side.controls.OverflowDescription') ||
            'designer.right_side.controls.OverflowOverflowDescription',
          note: [
            t('designer.right_side.controls.OverflowNote01'),
            t('designer.right_side.controls.OverflowNote02'),
            t('designer.right_side.controls.OverflowNote03'),
            t('designer.right_side.controls.OverflowNote04')
          ]
        }}
        placement="top"
      >
        <HelpIcon />
      </HelpPopover>
      <Switch
        switches={[
          {
            label: 'horizontal',
            onClick: () => {
              setOrientation('horizontal');
            }
          },
          {
            label: 'vertical',
            onClick: () => {
              setOrientation('vertical');
            }
          }
        ]}
        defaultActivatedIndex={1}
      />
      <div className={`${styles.itemList} d-flex justify-content-between`}>
        <OverflowItem
          value={props.value}
          type="auto"
          label="Auto"
          selectType={handleSelectOverflowType}
          orientation={orientation}
        />
        <OverflowItem
          value={props.value}
          type="hidden"
          label="Hidden"
          selectType={handleSelectOverflowType}
          orientation={orientation}
        />
        <OverflowItem
          value={props.value}
          type="scroll"
          label="Scroll"
          selectType={handleSelectOverflowType}
          orientation={orientation}
        />
        <OverflowItem
          value={props.value}
          type="visible"
          label="Visible"
          selectType={handleSelectOverflowType}
          orientation={orientation}
        />
      </div>
    </div>
  );
}

export default OverflowControl;
