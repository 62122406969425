import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { OrganizationContext } from '../..';
import { OrganizationService } from 'modules/organization/services';
import { AdminRole, ManagerRole, RoleType, DevRole, BillingRole } from 'modules/organization/types';
import AuthoritiesList from 'modules/auth/components/authorities_list';
import { messageCallback } from 'routes/organizations';
import CreateCustomRoleModal from 'routes/organizations/components/create_custom_role_modal';

import styles from './styles.module.css';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

function OrganizationRoles() {
  const orgContext = useContext(OrganizationContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [indexSelectedRole, setIndexSelectedRole] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    findRoles();
  }, []);

  /**
   * Fetch custom roles of organization
   */
  const findRoles = async () => {
    setRoles([AdminRole, ManagerRole, DevRole, BillingRole]);
    try {
      setLoading(true);
      if (orgContext.organization) {
        const roles = await OrganizationService.getCustomRolesOfOrg(orgContext.organization.id);
        await roles
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((role) => (role.isCustom = true));
        setRoles((prev) => prev.concat(roles));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateRole = async () => {
    try {
      setLoading(true);
      if (orgContext.organization) {
        await OrganizationService.updateCustomRole(
          orgContext.organization.id,
          roles[indexSelectedRole]
        );
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = useCallback((message: messageCallback) => {
    if (message === 'success') {
      findRoles();
    }
    setShowCreateModal(false);
  }, []);

  const selectAuthorityForRole = (role: RoleType) => {
    setRoles((existItems) => {
      return [
        ...existItems.slice(0, indexSelectedRole),
        role,
        ...existItems.slice(indexSelectedRole + 1)
      ];
    });
  };

  return (
    <div>
      {/* Title */}
      <Row>
        <Col className={`d-flex align-items-center`}>
          <h4 style={{ fontWeight: '400', margin: 0 }}>{t('organizations.roles.RolesAccess')}</h4>
        </Col>
      </Row>
      {isLoading && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
      {/* Management Roles */}
      <Card className={`mt-3 border-0 ${styles.managmentRoles}`}>
        {!isLoading && (
          <Card.Body>
            <Row className="rounded border">
              <Col sm={4} className={`bg-body-tertiary ${styles.managmentRolesCurrent}`}>
                <div>
                  {/* Title */}
                  <div className="d-flex justify-content-between">
                    <h5 className="text-body-emphasis">{t('organizations.roles.CurrentRoles')}</h5>

                    <HelpPopover
                      placement={'top'}
                      helpBoxProps={{
                        title: t('organizations.roles.CreateRole')!
                      }}
                    >
                      <Button
                        size="sm"
                        id="createButton"
                        variant="primary"
                        onClick={() => setShowCreateModal(true)}
                      >
                        <Icon iconName="plus"></Icon>
                      </Button>
                    </HelpPopover>
                  </div>
                  {/* Button to create */}
                  <div className={'d-flex justify-content-end pt-4'}></div>
                  {/* Generic Roles */}
                  <div className="pt-3">
                    {roles
                      .filter((v) => isNaN(Number(v)))
                      .map((role, index) => {
                        return (
                          <div key={role.name} className={`d-flex mt-2 ms-4`}>
                            <input
                              id={`${role.name}`}
                              className="form-check-input"
                              type="radio"
                              checked={
                                roles.length > 0 && roles[indexSelectedRole].name === role.name
                              }
                              onChange={() => setIndexSelectedRole(index)}
                            />
                            <div
                              className={`${
                                roles.length > 0 && roles[indexSelectedRole].name === role.name
                                  ? 'text-body-emphasis'
                                  : 'text-body-tertiary'
                              } ps-3`}
                            >
                              {t(role.name)}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Col>
              {/* Authorities */}
              <Col sm={8} className={`bg-body ${styles.managmentRolesAuthorities}`}>
                {roles.length > 0 && (
                  <AuthoritiesList
                    // id={`authorityList_${indexSelectedRole}`}
                    role={roles[indexSelectedRole]}
                    onChangeAuthority={selectAuthorityForRole}
                  />
                )}
                {/* Save Update Button */}
                {roles.length > 0 && roles[indexSelectedRole].isCustom && (
                  <div className={styles.dialogSaveButton}>
                    <Button id="saveAuthorities" disabled={isLoading} onClick={updateRole}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className={
                          isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`
                        }
                      />
                      {t('Save')}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
      <CreateCustomRoleModal showModal={showCreateModal} onCloseRequest={handleCloseModal} />
    </div>
  );
}

export default OrganizationRoles;
