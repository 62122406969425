import * as React from 'react';
import { Button } from 'react-bootstrap';
import { JobInfo, JobStatus } from '../../../../modules/project/types';
import SessionContext from '../../../../modules/auth/store';
import { ProjectsService } from '../../../../modules/project/services';
import { useParams } from 'react-router-dom';
import CircleStatus from '../circle_status';
import { formattingDate } from '../../utils/DateFormat';
import { formattingTime } from '../../utils/TimeFormat';
import Icon from 'web_ui/icon';
import styles from '../../styles.module.css';
import Confirmation from '../../../../web_ui/confirmation';
import { useTranslation } from 'react-i18next';

type JobLineProps = {
  job: JobInfo;
};

export default function JobLine({ job }: JobLineProps) {
  const session = React.useContext(SessionContext);
  const { app_id } = useParams();
  const { t } = useTranslation();
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const [openingTab, setOpeningTab] = React.useState(false);

  async function deleteCode() {
    if (!app_id) return;
    await ProjectsService.deleteJob(app_id, job.id);
    const downloads = session.downloads;
    delete downloads[job.id];
    session.setDownloads({ ...downloads });
  }

  return (
    <>
      <tr className={styles.renderedTR} id="download">
        <td className="bg-body-secondary text-body" style={{ width: '4.5rem' }}>
          {job.number}
        </td>
        <td className="bg-body-secondary text-body" style={{ width: '10rem' }}>
          {job.status === JobStatus.SUCCESS ||
          job.status === JobStatus.WARNING ||
          job.status === JobStatus.FAILURE ? (
            <CircleStatus status={job.status} />
          ) : (
            <i className="fa-1x fas fa-spinner fa-spin"></i>
          )}
        </td>
        <td className="bg-body-secondary text-body">
          {job.user ? <p className={styles.user}>{job.user}</p> : <></>}
        </td>
        <td className="bg-body-secondary text-body" style={{ width: '15rem' }}>
          {job.creationTime && formattingDate(job.creationTime!.toString())}{' '}
          {job.creationTime && formattingTime(job.creationTime!.toString())}
        </td>
        <td className="bg-body-secondary text-body" style={{ width: '1rem' }}>
          <div className={styles.iconsWrapper}>
            <div
              id={`deleteButton-${job.number}`}
              className={styles.containerIcons}
              onClick={(ev) => {
                ev.stopPropagation();
                setShowConfirmationModal(true);
              }}
            >
              <Icon iconName="trash" />
            </div>
            <div
              id={`chevron-${job.number}`}
              className={styles.containerIcons}
              onClick={() => {
                setOpeningTab(!openingTab);
              }}
            >
              {openingTab ? (
                <Icon iconName="chevron-up" extraProps="" />
              ) : (
                <Icon iconName="chevron-down" extraProps="" />
              )}
            </div>
          </div>
        </td>
      </tr>
      {openingTab && (
        <tr
          style={
            session.preferences['exocode-theme']
              ? {
                  backgroundColor: '#d3d3d3',
                  color: 'white'
                }
              : {
                  backgroundColor: 'rgba(233, 236, 239, 0.3)',
                  color: '#d3d3d3'
                }
          }
        >
          <td colSpan={6}>
            <div className={styles.containerOpened}>
              <div className={styles.containerMsgOpened}>
                <p style={{ color: 'black', fontWeight: 600 }}>{`${t(
                  'jobsPage.openedTab.message'
                )}:`}</p>
                <span
                  id="statusMessage"
                  style={
                    job.status === JobStatus.SUCCESS
                      ? { color: '#78c964' }
                      : job.status === JobStatus.WARNING
                      ? { color: '#ff8c00' }
                      : { color: '#be5959' }
                  }
                >
                  {job.status ?? ''}
                </span>
              </div>
              <div className={styles.containerLogsOpened}>
                <p style={{ color: 'black', fontWeight: 600 }}>{`${t(
                  'jobsPage.openedTab.logs'
                )}:`}</p>
                <div
                  style={
                    job.status === JobStatus.SUCCESS
                      ? {
                          color: '#78c964',
                          backgroundColor: session.preferences['exocode-theme']
                            ? '#63686d'
                            : '#E9ECEF'
                        }
                      : job.status === JobStatus.WARNING
                      ? {
                          color: '#ff8c00',
                          backgroundColor: session.preferences['exocode-theme']
                            ? '#63686d'
                            : '#E9ECEF'
                        }
                      : {
                          color: '#d1d1d1',
                          backgroundColor: session.preferences['exocode-theme']
                            ? '#63686d'
                            : '#E9ECEF'
                        }
                  }
                  className={styles.containerLogs}
                  id="logMessage"
                >
                  {job.message ?? ''}
                </div>
                <div className={styles.containerButton}>
                  {(job.status === JobStatus.SUCCESS || job.status === JobStatus.WARNING) &&
                    job.resultUrl && (
                      <Button href={job.resultUrl}>
                        {job.resultUrl.includes('git') && (
                          <>
                            <i className="fa-solid fa-code-branch"></i> Open Branch
                          </>
                        )}
                        {!job.resultUrl.includes('git') && (
                          <>
                            <i className="fa-solid fa-download"></i> Download
                          </>
                        )}
                      </Button>
                    )}
                  {(job.status === JobStatus.SUCCESS || job.status === JobStatus.WARNING) &&
                    job.prUrl && (
                      <Button href={job.prUrl} style={{ marginLeft: '10px' }} target="_blank">
                        <i className="fa-solid fa-code-pull-request"></i> #{job.prNumber}
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
      <Confirmation
        message={t('appResume.DeleteDownload')}
        show={showConfirmationModal}
        onConfirmation={() => {
          deleteCode();
          setShowConfirmationModal(false);
        }}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
      />
    </>
  );
}
