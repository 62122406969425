import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import Dropdown from '../../components/dropdown';
import { updateAction } from '../../../store/events/actions/update_action';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import useFetchObjects from '../../utils/useFetchObjects';
import { ObjectSimple } from '../../../../../modules/logic_builder/types';
import { useParams } from 'react-router-dom';

function ObjectInput({
  placeholder,
  label,
  dataKey,
  actionUuid,
  options,
  isFromSideBar
}: ActionInputProps) {
  const { view_id } = useParams();
  // If the path contains an 'view_id' we're on the designer function editor,
  // otherwise we're on the backend function editor.
  const objs = useFetchObjects(!view_id, !!view_id);
  const state = useSelector((state: FunctionEditorState) => state);
  const action = state.actions[actionUuid];
  const value = action?.data[dataKey];
  const dispatch = useDispatch();

  const handleChange = (value: any) => {
    dispatch(updateAction(actionUuid, dataKey, value));
  };

  const filteredObjs = (): ObjectSimple[] => {
    if (!objs) return [];
    if (!options?.filter) return objs;
    let filtered = objs;
    if (options?.filterObjs)
      filtered = filtered.filter((obj) => options.filterObjs(state, actionUuid, obj));
    return filtered;
  };

  if (!action) return null;

  return (
    <Dropdown
      items={filteredObjs()}
      placeholder={placeholder}
      label={label}
      value={objs.filter((o) => o.uuid === value)[0]}
      handleChange={handleChange}
      isFromSideBar={isFromSideBar}
    />
  );
}

export default ObjectInput;
