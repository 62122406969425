import React, { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import { TableAction } from '../../../data_table';
import ActionsBar from './actions_bar';
import NoComponentsWarning from './no_components_warning';

type CardTypeRendererProps = {
  actions?: TableAction[];
  selectable?: boolean;
  componentRenderer?: ReactNode;
  numberOfColumns?: number;
};

function CardTypeRenderer({
  actions,
  selectable,
  componentRenderer,
  numberOfColumns
}: CardTypeRendererProps) {
  return (
    <Card
      className={'p-2 gap-2'}
      style={{ flex: '1', alignItems: `${numberOfColumns !== 1 ? 'center' : 'start'}` }}
    >
      {componentRenderer ? componentRenderer : <NoComponentsWarning />}
      <div id={'actions-and-select'} className={'w-100 d-flex gap-1'}>
        {selectable && (
          <div
            id={'select-box'}
            className={
              'rounded bg-secondary-subtle shadow-sm p-1 d-flex flex-column justify-content-center'
            }
          >
            <input type={'checkbox'} />
          </div>
        )}
        <ActionsBar actions={actions} />
      </div>
    </Card>
  );
}

export default CardTypeRenderer;
