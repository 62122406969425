import React from 'react';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import NavigateIcon from './navigate_icon';
import { t } from 'i18next';

export const NAVIGATE_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.NAVIGATE,
  name: t('Navigate'),
  description: `${t('ActionToNavigate')}`,
  color: 'warning',
  category: FRONTEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.FUNCTION,
  uncollapsible: true,
  icon: <NavigateIcon />,
  inlineInputs: [],
  inputs: [
    {
      label: t('Page'),
      placeholder: `${t('SelectAPage')}`,
      key: 'page',
      type: ACTION_INPUTS_TYPES.VIEW_PICKER,
      options: { mode: 'page' }
    },
    {
      label: t('ExternalPath'),
      placeholder: 'https://...',
      key: 'externalPath',
      type: ACTION_INPUTS_TYPES.TEXT
    }
  ]
};
