import { UserProfile, UserTotals } from '../types/auth_types';
import { AccountRepo } from '../repos';

export default class Account {
  async getAccountInfo(): Promise<UserProfile> {
    return await AccountRepo.getAccountInfo();
  }

  async changeUserPreferences(key: string, value: any): Promise<void> {
    await AccountRepo.changeUserPreferences(key, value);
  }

  async getTotalsUser(id: number): Promise<UserTotals> {
    return await AccountRepo.getTotalsOrg(id);
  }
}
