import styled from 'styled-components';

export const DefaultInput = styled.input`
  transition: ease-in-out 0.2s;
`;

export const DefaultTextArea = styled.textarea`
  transition: ease-in-out 0.2s;
`;

export const DefaultSelect = styled.select`
  transition: ease-in-out 0.2s;
`;

export const DefaultLabel = styled.label``;
