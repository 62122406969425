import { useEffect, useState } from 'react';
import { EndpointsRepository } from '../../../../../../modules/logic_builder/repos';
import { ItemType } from '../../../components/dropdown';

function useGetEndpoints(module_id?: string) {
  const [loading, setLoading] = useState(false);
  const [endpoints, setEndpoints] = useState<{ [key: string]: ItemType }>({});

  useEffect(() => {
    async function getEndpoints() {
      if (!module_id) return [];
      setLoading(true);
      const result = await EndpointsRepository.getEndpointsByModule(module_id);
      const mappedEndpoints = {} as { [key: string]: ItemType };
      result.length &&
        result.map(
          (e) => (mappedEndpoints[e.uuid] = { name: `${e.method} ${e.name}`, uuid: e.uuid })
        );
      setEndpoints(mappedEndpoints);
    }
    getEndpoints().then(() => setLoading(false));
  }, [module_id]);
  return { endpoints, loading };
}

export default useGetEndpoints;
