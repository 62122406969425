import { DataFrameManifest } from 'modules/modeler/types';

const manifest: DataFrameManifest = {
  type: 'TABLE',
  name: 'Table',
  descriptionId: 'Table_description',
  description:
    'Is a set of data arranged in an infinite number of columns and an unlimited number of rows.',
  icon: 'table'
};

export default manifest;
