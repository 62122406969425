import { useDispatch, useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../store';
import React, { memo, useContext } from 'react';
import IndexComponent from './components/indexes';
import { changeEnumProperty } from '../../store/actions/enums';
import SidebarSelection from '../../../../../web_ui/toolbar';
import { changeProperty } from '../../store/actions/relationship';
import { changeTableProperty } from '../../store/actions/frames';
import Icon from '../../../../../web_ui/icon';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';
import ImportExcelModal from '../../components/import_excel_modal';
import { ContextRole, RoleAuthorities } from '../../../../auth/types/auth_types';
import { Authorization } from '../../../../auth/session/authorization';
import RelationshipComponent from './components/relationships';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaMagic } from 'react-icons/fa';
import { useQuery } from 'hooks/useQuery';
import { AppContext } from '../../../../project/store/app_context';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

function DbModelerToolbar() {
  const { t } = useTranslation();
  const { module_id, app_id } = useParams();
  const selectedComponent = useSelector((state: DatabaseStudioState) => state.studio.selectedFrame);
  const selectedConnection = useSelector(
    (state: DatabaseStudioState) => state.studio.selectedConnector
  );
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const enums = useSelector((state: DatabaseStudioState) => state.enums);
  const relationships = useSelector((state: DatabaseStudioState) => state.relationships);
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const [showImportExcelDialog, setShowImportExcelDialog] = React.useState<boolean>(false);
  const [showCodePreviewDialog, setShowCodePreviewDialog] = React.useState<boolean>(false);
  const [showCodePreviewTableDialog, setShowCodePreviewTableDialog] =
    React.useState<boolean>(false);
  const queryParameters = useQuery();
  const navigate = useNavigate();
  const hasBackend = useContext(AppContext)?.projectInformation?.has_backend;

  const dispatch = useDispatch();

  function handleUpdateDescription(newDescription: string) {
    if (selectedComponent && !selectedConnection) {
      if (tables[selectedComponent]) {
        dispatch(changeTableProperty(selectedComponent, 'description', newDescription));
      } else if (enums[selectedComponent]) {
        dispatch(changeEnumProperty(selectedComponent, 'description', newDescription));
      }
    } else if (selectedConnection && !selectedComponent) {
      dispatch(changeProperty(selectedConnection, 'description', newDescription));
    }
  }

  const goToEntityGenerator = () => {
    if (!app_id || !module_id) return;
    navigate(`/app/${app_id}/module/${module_id}/logic/automation-wizard?entityGenerator=true`);
  };

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  return (
    <>
      {selectedComponent && tables[selectedComponent] && !selectedConnection && (
        <>
          <SidebarSelection
            selectedTitle={tables[selectedComponent].content.data.name}
            selectedIcon={'database'}
            selectedDescription={tables[selectedComponent].content.data.description}
            updateDescription={handleUpdateDescription}
          ></SidebarSelection>
          <div className={`${styles.sidebarContainer}`}>
            {hasBackend && (
              <HelpPopover
                placement={'left'}
                helpBoxProps={{
                  title: t('modeler.CRUDAssistant')!,
                  description: t('modeler.CRUDAssistantLabel')!
                }}
              >
                <Button
                  id="assistantButton"
                  onClick={(e) => {
                    navigate(
                      `/app/${app_id}/module/${module_id}/logic/automation-wizard?entity=${selectedComponent}${
                        isFromVsCodeExt() ? '&vscode=true' : ''
                      }`
                    );
                  }}
                  className={`btn btn-primary  ${styles.button}`}
                  disabled={
                    selectedComponent &&
                    tables[selectedComponent] &&
                    tables[selectedComponent].content.data.native
                  }
                >
                  <FaMagic
                    className={styles.icons}
                    size={13}
                    color="white"
                    style={{ marginRight: 4 }}
                  />
                  {t('CrudAssistant')}
                </Button>
              </HelpPopover>
            )}
            <HelpPopover
              placement={'left'}
              helpBoxProps={{
                title: t('CodePreview')!,
                description: t('CodePreviewLabel')!
              }}
            >
              <Button
                id="codePreview"
                onClick={(e) => {
                  setShowCodePreviewTableDialog(true);
                }}
                className={`btn btn-primary  ${styles.button}`}
                style={{ gap: 7 }}
              >
                <Icon iconName="fa-solid fa-code" margin="0 0.5rem 0 0" />
                {t('CodePreview')}
              </Button>
            </HelpPopover>
          </div>
        </>
      )}

      {selectedConnection && relationships[selectedConnection] && !selectedComponent && (
        <>
          <SidebarSelection
            selectedTitle={'Relationship'}
            selectedIcon={'sitemap'}
            selectedDescription={relationships[selectedConnection].description}
            updateDescription={handleUpdateDescription}
          ></SidebarSelection>
          <RelationshipComponent
            relationships={relationships}
            selectedRelationshipId={selectedConnection}
            tables={tables}
          ></RelationshipComponent>
        </>
      )}

      {selectedComponent && enums[selectedComponent] && !selectedConnection && (
        <SidebarSelection
          selectedTitle={enums[selectedComponent].content.data.name}
          selectedIcon={'key'}
          selectedDescription={enums[selectedComponent].content.data.description}
          updateDescription={handleUpdateDescription}
        ></SidebarSelection>
      )}

      {selectedComponent && tables[selectedComponent] && (
        <IndexComponent tables={tables} columns={columns} selectedTable={selectedComponent} />
      )}

      {/* Default */}
      {!selectedComponent && !selectedConnection && (
        <Authorization
          context={ContextRole.APP}
          allowedAuthorities={[RoleAuthorities.MANAGE_DB_MODELER]}
        >
          <>
            <div className={`${styles.sidebarContainer}`}>
              <HelpPopover
                placement={'left'}
                helpBoxProps={{
                  title: t('CodePreview')!,
                  description: t('CodePreviewLabel')!
                }}
              >
                <button
                  id="codePreview"
                  className={`btn btn-primary ${styles.importButton}`}
                  onClick={() => setShowCodePreviewDialog(true)}
                >
                  <Icon iconName="fa-solid fa-code" margin="0 0.5rem 0 0"></Icon>
                  <div>{t('CodePreview')}</div>
                </button>
              </HelpPopover>
              <HelpPopover
                placement={'left'}
                helpBoxProps={{
                  title: t('EntityGenerator')!,
                  description: t('EntityGeneratorLabel')!
                }}
              >
                <button
                  id="entityGenerator"
                  className={`btn btn-primary ${styles.importButton}`}
                  onClick={() => goToEntityGenerator()}
                >
                  <Icon iconName="fa-solid fa-robot" margin="0 0.5rem 0 0"></Icon>
                  <div>{t('EntityGenerator')}</div>
                </button>
              </HelpPopover>
              <HelpPopover
                placement={'left'}
                helpBoxProps={{
                  title: t('modeler.ImportExcel')!,
                  description: t('modeler.ImportExcelLabel')!
                }}
              >
                <button
                  id="importButton"
                  className={`btn btn-primary ${styles.importButton}`}
                  onClick={() => setShowImportExcelDialog(true)}
                >
                  <Icon iconName="fa-solid fa-file-import" margin="0 0.5rem 0 0"></Icon>
                  <div>{t('modeler.ImportExcel')}</div>
                </button>
              </HelpPopover>
            </div>
            <ImportExcelModal
              show={showImportExcelDialog}
              onHide={() => setShowImportExcelDialog(false)}
            />
            {module_id && (
              <CodeEditorModal
                show={showCodePreviewDialog}
                handleClose={() => setShowCodePreviewDialog(false)}
                id={module_id}
                previewType={CodePreviewType.MODULE_TABLE_ENUM}
              />
            )}
          </>
        </Authorization>
      )}
      {selectedComponent && (
        <CodeEditorModal
          show={showCodePreviewTableDialog}
          handleClose={() => setShowCodePreviewTableDialog(false)}
          id={selectedComponent}
          previewType={CodePreviewType.TABLE}
        />
      )}
    </>
  );
}

export default memo(DbModelerToolbar);
