import { ColumnData, ColumnStyles } from './index';
import { LayoutComponent } from '../../../types';

export const COLUMN_TEMPLATE: LayoutComponent<ColumnData, ColumnStyles> = {
  uuid: 'empty',
  type: 'COLUMN',
  data: {
    width: 3,
    widthUnit: 'grid',
    height: 'fit-content',
    heightUnit: 'none',
    maxHeight: 'none',
    minHeight: '100',
    minHeightUnit: 'px',
    overflowx: 'auto',
    overflowy: 'auto',
    verticalAlign: 'stretch',
    horizontalAlign: 'start',
    flexDirection: 'row'
  },
  styles: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    borderTop: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    css: ''
  }
};
