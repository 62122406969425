import produce from 'immer';
import { SET_ROLE_VIEW, SetRoleToView } from '../actions/roles';
import { ExoRole } from 'modules/logic_builder/types';

type RolesActions = SetRoleToView;

export const roleReducer = (state: ExoRole[] = [], action: RolesActions): ExoRole[] => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_ROLE_VIEW:
        return doSetRoleToView(draft, action);
      default:
        return draft;
    }
  });
};

function doSetRoleToView(state: ExoRole[], action: SetRoleToView): ExoRole[] {
  return state;
}
