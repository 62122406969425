import React from 'react';
import styles from './styles.module.css';
import { BehaviorTab } from '../../studio';
import { FunctionInstance } from './function_instance';
import { ObjectInstance } from './object_instance';
import { VariableInstance } from './variable_instance';
import { ErrorBoundary } from 'react-error-boundary';
import GenericFallback from 'error_boundaries/genericFallback';
import { globalReport } from 'error_boundaries';
import { PropertyInstance } from './property_instance';

type BehaviorModeProps = {
  selectedTab: BehaviorTab;
};

export function BehaviorMode({ selectedTab }: BehaviorModeProps) {
  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.BehaviorWrapper}>
          {selectedTab === 'FUNCTIONS' && (
            <ErrorBoundary
              key={'FUNCTIONS'}
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <GenericFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  title="designer.behaviour.Functions"
                />
              )}
              onError={globalReport}
            >
              <FunctionInstance />
            </ErrorBoundary>
          )}

          {selectedTab === 'OBJECTS' && (
            <ErrorBoundary
              key={'OBJECTS'}
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <GenericFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  title="designer.behaviour.Objects"
                />
              )}
              onError={globalReport}
            >
              <ObjectInstance />
            </ErrorBoundary>
          )}
          {selectedTab === 'VARIABLES' && (
            <ErrorBoundary
              key={'VARIABLE'}
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <GenericFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  title="designer.behaviour.Variables"
                />
              )}
              onError={globalReport}
            >
              <VariableInstance />
            </ErrorBoundary>
          )}
          {selectedTab === 'PROPERTIES' && (
            <ErrorBoundary
              key={'PROPERTIES'}
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <GenericFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  title="designer.behaviour.Properties"
                />
              )}
              onError={globalReport}
            >
              <PropertyInstance />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </>
  );
}
