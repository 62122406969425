import React from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function TextTransform(props: ControlProps) {
  const { t } = useTranslation();
  type textTransform = 'none' | 'uppercase' | 'capitalize' | 'capitalize' | 'lowercase';
  function handleChange(value: textTransform) {
    if (props.onChange) props.onChange(value, props.id);
  }

  return (
    <div style={{ display: 'flex' }}>
      {props.tooltip && (
        <HelpPopover
          helpBoxProps={{
            description: t(`${props.tooltip}`)!
          }}
          placement="top"
        >
          <HelpIcon />
        </HelpPopover>
      )}
      <div
        className="mb-3"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          className={` form-control form-control-sm`}
          style={{
            width: '6.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '0'
          }}
        >
          <button
            className={` ${styles.cleanButton}  btn btn-secondary`}
            onClick={() => handleChange('none')}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
          <button
            className={` ${styles.buttonContent}  btn btn-secondary`}
            onClick={() => handleChange('uppercase')}
          >
            <span>AA</span>
          </button>
          <button
            className={` ${styles.buttonContent} btn btn-secondary`}
            onClick={() => handleChange('capitalize')}
          >
            <span>Aa</span>
          </button>
          <button
            className={` ${styles.buttonContent}  btn btn-secondary`}
            onClick={() => handleChange('lowercase')}
          >
            <span>aa</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default TextTransform;
