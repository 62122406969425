import { Frame, FrameUUID } from 'web_ui/workboard/frame';
import { indexColumnState } from '../studio/store';

export type PreferenceId = string;

export type AssetURI = string;

export type TabID = string;

export type Tab = {
  id: TabID;
  label?: string;
};

export type EnumUUID = string;

export interface EnumFrame<T = any> {
  folder_id?: string;
  uuid: EnumUUID;
  type: string;
  content: T;
  posX?: number;
  posY?: number;
  moduleId?: string;
}

export type EnumColumnID = string;

export interface EnumColumn {
  id: EnumColumnID;
  idEnum: EnumUUID;
  literalValue: string;
  ordinalValue: number;
  description: string;
  columnOrder: number;
}

export type TableUUID = string;

export interface Table<T = any> {
  folder_id?: string;
  data: any;
  uuid: TableUUID;
  type: string;
  content: T;
  posX?: number;
  posY?: number;
  moduleId?: string;
}

export interface DataFrameManifest {
  type: string;
  name: string;
  descriptionId: string;
  description: string;
  icon?: string;
  preview?: AssetURI;
}

export interface FrameManifest {
  type: string;
  name: string;
  description: string;
  icon?: string;
  preview?: AssetURI;
  properties: FramePropField[];
  styles: FrameStyleField[];
}

export interface FramePropField {
  controlType: string;
  controlLabel: string;
  controlOptions?: string[];
  key: string;
}

export interface FrameStyleField {
  controlType: string;
  controlLabel: string;
  controlOptions?: string[];
  key: string;
}

export enum BackgroundTypes {
  Lines = 'lines',
  Dots = 'dots'
}

//
// Database

export type columnID = string;

export interface Column {
  uuid: string;
  tableUUID: string;
  name: string;
  type: string;
  columnOrder: number;
  nullable: boolean;
  defaultData: string;
  enumUUID?: EnumUUID;
  isPK: boolean;
  isFK: boolean;
  native: boolean;
  description: string;
  properties: { [key: string]: string };
}

export type fkColumnsMapper = { [key: TableUUID]: columnID };

export type Enum = {
  key: string;
  value: string;
};

export enum fetchType {
  LAZY = 'LAZY',
  EAGER = 'EAGER'
}

export enum cascadeType {
  PERSIST = 'PERSIST',
  MERGE = 'MERGE',
  REMOVE = 'REMOVE',
  REFRESH = 'REFRESH',
  DETACH = 'DETACH',
  ALL = 'ALL'
}

export type relationshipID = string;

export type ComponentMap = { [key: string]: string };

export interface externalRelationshipInfo {
  moduleUuid: string;
  moduleName: string;
  tableName: string;
  columnNames: string[];
  readOnly: boolean;
}

export interface Relationship<T = any> {
  id: string;
  type: string; // 1..1, 1..n, n..n
  content: T; // Description, Styles, Animations, etc...
  description: string;
  from: FrameUUID;
  to: FrameUUID;
  components: ComponentMap;
  name?: string;
  toName?: string;
  fromName?: string;
  bidirectional?: boolean;
  fetchType?: string;
  cascadeTypes: string[];
  info?: externalRelationshipInfo;
}

export type PreferenceID = string;

export interface Preference {
  key: string;
  value: boolean;
}

export interface PreferenceManifest {
  key: string;
  name: string;
  value: boolean;
  type: string;
  icon: string;
}

export type ConnectorID = string;

export enum columnContraints {
  Primary = 'PRIMARY',
  Foreing = 'FOREIGN',
  None = 'NONE'
}

export interface ColumnType {
  label: string;
  type: string;
  value: string;
}

export type Schema = {
  frames: Frame[];
  columns: Column[];
  indexes: SchemaIndex[];
  relationships: Relationship[];
};

export enum IndexType {
  PRIMARY = 'PRIMARY',
  INDEX = 'INDEX',
  UNIQUE = 'UNIQUE'
}

export type IndexColumn = {
  id: string;
  columnId: string;
  sortOrder: sortOrder;
  columnOrder: number;
};

export enum sortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type IndexID = string;

export type Index = {
  id: string;
  name: string;
  description: string;
  type: IndexType;
  columns: indexColumnState;
};

export type SchemaIndex = {
  id: string;
  name: string;
  type: IndexType;
  columns: IndexColumn[];
};

export type ColumnIndexType = {
  id: string;
  columnId: string;
  sortOrder: sortOrder;
  selected: boolean;
  columnOrder: number;
};

export const DataTypeFieldType = {
  ALL: 'ALL',
  KEYS: 'KEYS',
  FIELDS: 'FIELDS'
};

export type DataType = {
  id?: string;
  type: string;
  example?: string;
  name?: string;
  moduleName?: string;
  fieldType: keyof typeof DataTypeFieldType;
};

export type externalTablesList = {
  modules: ExternalModules[];
};

export type ExternalModules = {
  id: string;
  name: string;
  tables: externalTables[];
};

export type ExternalTableIndexColumn = {
  columnId: string;
  columnName: string;
  type: string;
};

export type ExternalTableIndex = {
  id: string;
  name: string;
  columns: ExternalTableIndexColumn[];
};

export type externalTables = {
  id: string;
  name: string;
  inUse?: boolean;
  indexes: ExternalTableIndex[];
};

export type externalColumn = {
  id: string;
  name: string;
  type: string;
};

export type columnProperty = {
  columnId: string;
  key: string;
  value: string;
};
