import { Action } from 'redux';
import {
  addColumnAction,
  addRelationshipAction,
  addTableAction,
  deleteRelationshipAction,
  duplicateTableAction
} from './root';
import { Column, columnID, columnProperty, EnumUUID } from '../../../types';

export const MOVE_COLUMN = 'MOVE_COLUMN';
export const SET_COLUMNS = 'SET_COLUMNS';
export const CHANGE_COLUMN_NAME = 'CHANGE_COLUMN_NAME';
export const CHANGE_COLUMN_DESCRIPTION = 'CHANGE_COLUMN_DESCRIPTION';
export const CHANGE_COLUMN_TYPE = 'CHANGE_COLUMN_TYPE';
export const CHANGE_COLUMN_NULLABLE = 'CHANGE_COLUMN_NULLABLE';
export const CHANGE_COLUMN_DEFAULT = 'CHANGE_COLUMN_DEFAULT';
export const DELETE_COLUMN = 'DELETE_COLUMN';
export const CHANGE_COLUMN_PROPERTY = 'CHANGE_COLUMN_PROPERTY';
export const UPDATE_PRIMARY_KEYS = 'UPDATE_PRIMARY_KEYS';
export const NUMERIC_FIELD_PROPERTIES = ['precision', 'scale'];
export const TEXT_FIELD_PROPERTIES = ['length'];
export const PROPERTY_LIMITS: Record<string, Record<string, number>> = {
  length: {
    min: 1,
    max: 10485760
  },
  precision: {
    min: 0,
    max: 1000
  },
  scale: {
    min: 0,
    max: 1000
  }
};

export const COLUMN_PROPERTY_BASED_ON_FIELD_TYPE: Record<string, string[]> = {
  NUMERIC: NUMERIC_FIELD_PROPERTIES,
  DECIMAL: NUMERIC_FIELD_PROPERTIES,
  FLOAT: NUMERIC_FIELD_PROPERTIES,
  DOUBLE: NUMERIC_FIELD_PROPERTIES,
  VARCHAR: TEXT_FIELD_PROPERTIES,
  STRING: TEXT_FIELD_PROPERTIES,
  CHAR: TEXT_FIELD_PROPERTIES
};

export type ColumnActions =
  | setColumnsAction
  | ChangeColumnNameAction
  | ChangeColumnTypeAction
  | ChangeColumnDescriptionAction
  | ChangeColumnPropertyAction
  | ChangeColumnNullableAction
  | ChangeColumnDefaultAction
  | addColumnAction
  | DeleteColumnAction
  | addTableAction
  | deleteRelationshipAction
  | addRelationshipAction
  | MoveColumnAction
  | duplicateTableAction
  | UpdatePrimaryKeysAction;

export interface MoveColumnAction extends Action {
  type: 'MOVE_COLUMN';
  payload: { table: string; column: string; direction: string; swappedColumn: string };
}

export interface setColumnsAction extends Action {
  type: 'SET_COLUMNS';
  payload: {
    columns: Column[];
  };
}

export interface ChangeColumnDescriptionAction extends Action {
  type: 'CHANGE_COLUMN_DESCRIPTION';
  payload: {
    id: columnID;
    value: string;
  };
}

export interface ChangeColumnNameAction extends Action {
  type: 'CHANGE_COLUMN_NAME';
  payload: {
    id: columnID;
    value: string;
  };
}

export interface ChangeColumnTypeAction extends Action {
  type: 'CHANGE_COLUMN_TYPE';
  payload: {
    id: columnID;
    value: string;
    enumUUID?: EnumUUID;
  };
}

export interface ChangeColumnNullableAction extends Action {
  type: 'CHANGE_COLUMN_NULLABLE';
  payload: {
    id: columnID;
    value: boolean;
  };
}

export interface ChangeColumnDefaultAction extends Action {
  type: 'CHANGE_COLUMN_DEFAULT';
  payload: {
    id: columnID;
    value: string;
  };
}

export interface DeleteColumnAction extends Action {
  type: 'DELETE_COLUMN';
  payload: {
    id: columnID;
  };
}

export interface ChangeColumnPropertyAction extends Action {
  type: 'CHANGE_COLUMN_PROPERTY';
  payload: {
    id: string;
    key: string;
    value: string;
  };
}

export interface UpdatePrimaryKeysAction extends Action {
  type: 'UPDATE_PRIMARY_KEYS';
  payload: {
    columns: { [key: columnID]: boolean };
  };
}

export const moveColumn = (
  table: string,
  column: string,
  direction: string,
  swappedColumn: string
): MoveColumnAction => ({
  type: MOVE_COLUMN,
  payload: { table, column, direction, swappedColumn }
});

export const setColumns = (columns: Column[]): setColumnsAction => ({
  type: SET_COLUMNS,
  payload: { columns }
});

export const changeColumnName = (id: columnID, value: string): ChangeColumnNameAction => ({
  type: CHANGE_COLUMN_NAME,
  payload: { id, value }
});

export const changeColumnDescription = (id: columnID, value: string) => ({
  type: CHANGE_COLUMN_DESCRIPTION,
  payload: { id, value }
});

export const changeColumnType = (
  id: columnID,
  value: string,
  enumUUID?: string
): ChangeColumnTypeAction => ({
  type: CHANGE_COLUMN_TYPE,
  payload: { id, value, enumUUID }
});

export const updatePrimaryKeys = (columns: {
  [key: columnID]: boolean;
}): UpdatePrimaryKeysAction => ({
  type: UPDATE_PRIMARY_KEYS,
  payload: { columns }
});

export const changeColumnNullable = (id: columnID, value: boolean): ChangeColumnNullableAction => ({
  type: CHANGE_COLUMN_NULLABLE,
  payload: { id, value }
});

export const changeColumnDefault = (id: columnID, value: string): ChangeColumnDefaultAction => ({
  type: CHANGE_COLUMN_DEFAULT,
  payload: { id, value }
});

export const deleteColumn = (id: columnID): DeleteColumnAction => ({
  type: DELETE_COLUMN,
  payload: { id: id }
});

export const changeColumnProperty = (
  columnProperty: columnProperty
): ChangeColumnPropertyAction => ({
  type: CHANGE_COLUMN_PROPERTY,
  payload: {
    id: columnProperty.columnId,
    key: columnProperty.key,
    value: columnProperty.value
  }
});
