import React from 'react';
import { FormControl } from 'react-bootstrap';

type Props = {
  name: string;
  handleChangeName: (val: string) => void;
};

function NameEditor({ name, handleChangeName }: Props) {
  return (
    <div style={{ width: '360px' }}>
      <FormControl value={name} onChange={(e) => handleChangeName(e.target.value)} />
    </div>
  );
}

export default NameEditor;
