import React, { useEffect, useState } from 'react';
import { Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import { ModuleDependencyInfo } from '../../../dashboard/types';
import { DashboardService } from '../../../dashboard/services';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface ModuleDependencyGroupProps {
  // The module being edited.
  moduleId?: string;
  onChange: (dependencies: ModuleDependencyInfo[]) => void;
  isCreating?: boolean;
  refreshAfterDeleted?: boolean;
}

/**
 * List of module dependencies with add/remove capabilities.
 *
 * @component
 */
function ModuleDependencyGroup(props: ModuleDependencyGroupProps) {
  const { onChange, moduleId } = props;
  const { t } = useTranslation();
  const { app_id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [dependencies, setDependencies] = useState<ModuleDependencyInfo[]>([]);
  const [chosenDependencies, setChosenDependencies] = useState<ModuleDependencyInfo[]>([]);
  const [currentDependenciesFromCurrentModule, setCurrentDependenciesFromCurrentModule] = useState<
    ModuleDependencyInfo[]
  >([]);

  useEffect(() => {
    setLoading(true);
    try {
      if (props.isCreating) {
        (async () => {
          if (app_id) {
            const allModulesByAppId = await DashboardService.getModulesByApp(app_id);
            setDependencies(allModulesByAppId);
          }
        })();
      } else {
        (async () => {
          if (app_id) {
            const allModulesByAppId = await DashboardService.getModuleDependencies(moduleId ?? '');
            const savingCurrentDependencyFromCurrentModule = allModulesByAppId.dependencies;
            setChosenDependencies(savingCurrentDependencyFromCurrentModule);
            setCurrentDependenciesFromCurrentModule(savingCurrentDependencyFromCurrentModule);
            const avaibleDependencies = await DashboardService.getModuleAvailableDependencies(
              moduleId ?? '',
              savingCurrentDependencyFromCurrentModule
            );
            setDependencies(avaibleDependencies);
          }
        })();
      }
    } finally {
      setLoading(false);
    }
  }, [app_id, moduleId, props.isCreating]);

  const changingCreatingModule = (id: string, name: string, checked: boolean) => {
    let next = [...chosenDependencies];
    if (checked) {
      next.push({ id: id, name: name });
    } else {
      next = next.filter((item) => item.id !== id);
    }
    setChosenDependencies(next);
    onChange(next);
  };

  return (
    <>
      {loading && (
        <div className={`fa-3x ${styles.spinner}`}>
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      )}
      {!loading && (
        <Form.Group>
          <div className={styles.dependencyHeader}>
            <Form.Label>{t('appResume.module.Dependencies')}</Form.Label>
          </div>
          {!dependencies.length && !currentDependenciesFromCurrentModule ? (
            <p>
              <Form.Text className="text-muted centered">
                {t('appResume.module.NoDependencies')}
              </Form.Text>
            </p>
          ) : (
            <ListGroup className={styles.dependencyList}>
              {props.isCreating &&
                dependencies.map((dep, index) => {
                  return (
                    <ListGroupItem as="li" className={styles.dependencyItem} key={dep.id}>
                      {dep.name}
                      <Form.Check
                        type="switch"
                        id={`switchDep-${index}`}
                        onChange={(e) => changingCreatingModule(dep.id, dep.name, e.target.checked)}
                      />
                    </ListGroupItem>
                  );
                })}
              {!props.isCreating &&
                dependencies.map((modDepAv, index) => (
                  <ListGroupItem as="li" className={styles.dependencyItem} key={index}>
                    {modDepAv.name}
                    <Form.Check
                      type="switch"
                      id={`${modDepAv.name}Check`}
                      onChange={(e) =>
                        changingCreatingModule(modDepAv.id, modDepAv.name, e.target.checked)
                      }
                    />
                  </ListGroupItem>
                ))}
              {!props.isCreating &&
                currentDependenciesFromCurrentModule.length > 0 &&
                currentDependenciesFromCurrentModule.map((item, index) => {
                  return (
                    <ListGroupItem as="li" className={styles.dependencyItem} key={index}>
                      {item.name}
                      <Form.Check
                        checked={chosenDependencies.includes(item)}
                        type="switch"
                        id={`${item.name}Check`}
                        onChange={(e) =>
                          changingCreatingModule(item.id, item.name, e.target.checked)
                        }
                      />
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          )}
        </Form.Group>
      )}
    </>
  );
}

export default ModuleDependencyGroup;
