import React from 'react';

function QueryIcon() {
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <i className="fas fa-database text-secondary-emphasis" style={{ fontSize: '1.4rem' }} />
      <i
        className="fas fa-code text-white"
        style={{
          fontSize: '.7rem',
          marginLeft: '-8px',
          borderRadius: '50%',
          padding: '0.2rem',
          background: 'var(--bs-secondary)'
        }}
      />
    </span>
  );
}

export default QueryIcon;
