import React, { useContext, useState } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from 'web_ui/icon';
import { OrganizationApiInfo } from 'modules/organization/types';

import styles from './styles.module.css';
import { textLastModification } from 'utils/dateUtils';
import { useTranslation } from 'react-i18next';
import SessionContext from '../../../../modules/auth/store';
import UpdateOrganizationModal from '../update_organization_modal';

export type OrganizationCardProps = {
  /** organization */
  org: OrganizationApiInfo;
  onClose: () => void;
};

/**
 * A card used to represent a organization that links to the organization's dashboard
 *
 * @component
 */
function OrganizationCard({ org, onClose }: OrganizationCardProps) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleCloseModal = React.useCallback(() => {
    setShowModal(false);
    onClose();
  }, []);

  return (
    <>
      <Card id={org.name} style={{ cursor: 'pointer' }}>
        <Link to={`/organization/${org.id}/overview`} state={{ org: org }} className="nav-link">
          <Card.Header
            className="text-center bg-body-tertiary
         fw-bold fs-5 text-bold"
          >
            {org.name}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6} className="text-center">
                <Icon iconName="window-restore" />
                <div>
                  {org.projects} {t('organizations.organization.Projects')}
                </div>
              </Col>
              <Col sm={6} className="text-center">
                <Icon iconName="user-group" />
                <div>
                  {org.members.length} {t('organizations.organization.Collaborators')}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Link>
        <Card.Footer className={`bg-body text-body-tertiary ${styles.cardFooter}`}>
          <small>{textLastModification(org.modificationTime, t, session)}</small>
          {org.owner.id === session.user?.id && (
            <>
              <Badge bg="secondary" className="ms-2">
                Owner
              </Badge>
              <Button
                id={`edit-${org.name}`}
                variant="body"
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  setShowModal(true);
                }}
                className={`${styles.iconButton} btn-body`}
              >
                <Icon iconName="pen-to-square" />
              </Button>
            </>
          )}
        </Card.Footer>
      </Card>
      <UpdateOrganizationModal
        showModal={showModal}
        onCloseRequest={handleCloseModal}
        onUpdate={handleCloseModal}
        preOrg={org}
      />
    </>
  );
}

export default OrganizationCard;
