import { PageServ } from './PageService';
import { LayoutServ } from './LayoutService';
import { ModalServ } from './ModalService';
import { CustomComponentServ } from './CustomComponentService';
import { ViewStateServ } from './ViewStateService';
import { AppLanguagesServ } from './AppLanguagesServ';
import { AppTranslatesServ } from './AppTranslatesServ';
import { ThemeServ } from './ThemeService';
import { FrontendPropertiesServ } from './FrontendPropertiesServ';
import { TemplatesServ } from './TemplatesServ';
import { FolderServ } from './FolderService';
import { AppPropertiesServ } from './AppPropertiesServ';
import { CssStylesServ } from './CssStylesServ';

export const LayoutService = new LayoutServ();
export const PageService = new PageServ();
export const ModalService = new ModalServ();
export const CustomComponentService = new CustomComponentServ();
export const ViewStateService = new ViewStateServ();
export const AppLanguagesService = new AppLanguagesServ();
export const AppTranslateService = new AppTranslatesServ();
export const ThemeService = new ThemeServ();
export const FrontendPropertiesService = new FrontendPropertiesServ();
export const TemplatesService = new TemplatesServ();
export const AppPropertiesService = new AppPropertiesServ();
export const FolderService = new FolderServ();
export const CssStylesService = new CssStylesServ();
