import { FunctionsState } from '../index';
import produce from 'immer';
import {
  ADD_FUNCTION,
  AddFunctionAction,
  DELETE_FUNCTION,
  DeleteFunctionAction,
  SET_FUNCTIONS,
  SetFunctionsAction,
  UPDATE_FUNCTION,
  UpdateFunctionAction
} from '../actions/functions';

type FunctionsActions =
  | SetFunctionsAction
  | AddFunctionAction
  | UpdateFunctionAction
  | DeleteFunctionAction;

export const functionsReducer = (
  state: FunctionsState = {},
  action: FunctionsActions
): FunctionsState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_FUNCTIONS:
        return doSetFunctions(draft, action);
      case ADD_FUNCTION:
        return doAddFunction(draft, action);
      case UPDATE_FUNCTION:
        return doUpdateFunction(draft, action);
      case DELETE_FUNCTION:
        return doDeleteFunction(draft, action);
      default:
        return draft;
    }
  });
};

function doSetFunctions(state: FunctionsState, action: SetFunctionsAction): FunctionsState {
  return action.payload.functions;
}

function doAddFunction(state: FunctionsState, action: AddFunctionAction): FunctionsState {
  state[action.payload.uuid] = action.payload;
  return state;
}

function doUpdateFunction(state: FunctionsState, action: UpdateFunctionAction): FunctionsState {
  state[action.payload.function.uuid] = action.payload.function;
  return state;
}

function doDeleteFunction(state: FunctionsState, action: DeleteFunctionAction): FunctionsState {
  delete state[action.payload.function];
  return state;
}
