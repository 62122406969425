import { Action } from 'redux';
import { EditorState } from '../../types/function_editor_state';

export const CUSTOM_CODE = 'CUSTOM_CODE';

export interface CustomCodeAction extends Action {
  type: 'CUSTOM_CODE';
  payload: boolean;
}

export const customCode = (isCustom: boolean): CustomCodeAction => ({
  type: CUSTOM_CODE,
  payload: isCustom
});

export function doCustomCode(state: EditorState, action: CustomCodeAction): EditorState {
  state.customCode = action.payload;
  return state;
}
