import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from '../../../../designer/types';
import { t } from 'i18next';

export const IMAGE_MANIFEST: ComponentManifest = {
  type: 'IMAGE',
  name: 'Image',
  descriptionId: 'ImageDescription',
  description: 'Imagens responsivas para adicionar aos seus layouts.',
  icon: 'fa-solid fa-image',
  group: ComponentGroups['MEDIA'],
  previewHeight: 100,
  previewWidth: 150,
  properties: [
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable',
      tooltip: 'designer.right_side.controls.components.image.var'
    },
    {
      controlType: ControlsTypes.SELECT_PICS,
      controlLabel: 'URL',
      key: 'source',
      tooltip: 'designer.right_side.controls.components.image.selectPics'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.image.tooltip'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CLICK', 'HOVER']
};
