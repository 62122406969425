import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import styles from './styles.module.css';
import { Frame, FrameUUID } from '.';
import { ContextMenuOption } from '../ContextMenuItem';
import FrameRenderer from './FrameRenderer';
import Background from '../background';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import {
  setSelectedComponent,
  setSelectedView
} from '../../../modules/designer/studio/store/actions/studio';
import { useParams } from 'react-router-dom';

import { useQuery } from 'hooks/useQuery';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import SessionContext from 'modules/auth/store';
import { useClickOutsideEvent } from 'hooks/useClickOutside';

interface FrameWrapperProps {
  frames: { [key: FrameUUID]: Frame };
  isLeftSidebarOpen: boolean;
  isRightSidebarOpen: boolean;
  fetchViews: () => void;
  children?: ReactNode;
  contextMenuItems?: ContextMenuOption[];
  showSettings?: boolean;
}

function FrameWrapper({
  frames,
  children,
  isRightSidebarOpen,
  isLeftSidebarOpen,
  fetchViews
}: FrameWrapperProps) {
  const queryParameters = useQuery();
  const selectedViewport = useSelector((state: InterfaceStudioState) => state.studio.viewportMode);
  const { view_id, custom_component_id } = useParams();
  const mainRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { pageVisits, updatePageVisits } = useContext(SessionContext);
  const driverInstance = useRef<any>();
  const currentContext = 'designer';

  function handleBackgroudClick() {
    dispatch(setSelectedComponent(null));
    if (view_id) dispatch(setSelectedView(view_id));
    if (custom_component_id) dispatch(setSelectedView(custom_component_id));
  }

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!pageVisits) {
      return;
    }
    if (!mainRef.current) return;

    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done',
      onClose: () => {
        updatePageVisits(currentContext);
      }
    });
  }, [pageVisits, updatePageVisits]);

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  return (
    <main
      ref={mainRef}
      style={{
        marginLeft: isLeftSidebarOpen ? '240px' : '0',
        width: isRightSidebarOpen
          ? isLeftSidebarOpen
            ? `calc(100vw - ${isFromVsCodeExt() ? '480px' : '545px'})`
            : `calc(100vw - ${isFromVsCodeExt() ? '240px' : '305px'})`
          : isLeftSidebarOpen
          ? `calc(100vw - ${isFromVsCodeExt() ? '240px' : '305px'})`
          : `calc(100vw - ${isFromVsCodeExt() ? '0px' : '65px'})`
      }}
      className={styles.frameWrapperContainer}
      onClick={handleBackgroudClick}
    >
      <div
        style={{
          overflow: 'auto',
          maxWidth: '100%',
          height: '100%',
          width: '98%',
          paddingRight: '10px',
          position: 'relative',
          zIndex: 1
        }}
      >
        <div
          className={`${styles.framesContainer} ${styles.activable}`}
          style={
            selectedViewport !== 'desktop' ? { display: 'flex', justifyContent: 'center' } : {}
          }
          id={WALKTHROUGH_STEPS_ELEMENTS['designer-main-workboard']}
        >
          <FrameRenderer frames={frames} />
          {children}
        </div>
      </div>

      <Background />
    </main>
  );
}

export default FrameWrapper;
