import useFetchEnums from '../../utils/useFetchEnums';
import useFetchObjects from '../../utils/useFetchObjects';
import { ReturnType } from '../../../store/types/functions';
import { useParams } from 'react-router-dom';

function useFetchDataTypes(rawTypes: string[]) {
  const enums = useFetchEnums();
  const { view_id } = useParams();
  // If the path contains an 'view_id' we're on the designer function editor,
  // otherwise we're on the backend function editor.
  const objects = useFetchObjects(!view_id, !!view_id);

  const processedTypes: ReturnType[] = [];
  rawTypes.forEach((t) => {
    if (t.toUpperCase() !== 'ENUM' && t.toUpperCase() !== 'OBJECT')
      processedTypes.push({ type: t.toUpperCase(), list: false });
  });
  objects.forEach((o) =>
    processedTypes.push({ type: 'OBJECT', objectUuid: o.uuid, list: false, name: o.name })
  );
  enums.forEach((e) =>
    processedTypes.push({
      type: 'ENUM',
      enumUuid: e.uuid,
      list: false,
      name: e.content.data.name
    })
  );
  return { fetchedTypes: processedTypes, objects, enums };
}

export default useFetchDataTypes;
