import { FunctionsState } from '../../types/functions';
import { CreateElseAction } from '../actions/create_else';
import { CreateIfAction } from '../actions/create_if';

export function doCreateElse(state: FunctionsState, action: CreateElseAction): FunctionsState {
  const { functionId, ifBeginAction, elseBeginAction, elseBodyAction, elseEndAction } =
    action.payload;

  state[functionId].actions.splice(
    elseBeginAction?.order ?? 0,
    0,
    elseBeginAction.uuid,
    elseBodyAction.uuid,
    elseEndAction.uuid
  );
  return state;
}
