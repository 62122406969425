import * as React from 'react';
import { Left } from 'react-bootstrap/lib/Media';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Icon from 'web_ui/icon';

const DefaultRoute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#212529' }}>
      <div className="mb-1 text-center d-flex align-items-center">
        <img
          width={200}
          height={150}
          src="/assets/horizontalWhiteLogo.svg"
          alt="Error image"
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            navigate('/');
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        <div>
          <img
            src="/assets/searching-route.svg"
            alt="Error image"
            style={{ width: 500, height: 500 }}
          />
        </div>
        <div>
          <h1
            className="w-100 text-primary d-inline"
            style={{ fontWeight: '200', fontSize: '3rem' }}
          >
            {t('pathNotFound')}
            <b style={{ fontWeight: '800' }}> {t('errors.Wrong')}!</b>
          </h1>
          <span
            className="text-info d-block mt-3 p-3 rounded w-100"
            style={{ backgroundColor: '#222c40' }}
          >
            {t('pathNotFoundTip')}
          </span>
          <div className="w-100">
            <button
              className="mt-4 btn btn-primary p-2 pe-5 ps-5"
              onClick={() => {
                // resetErrorBoundary();
                navigate('/');
              }}
            >
              <Icon iconName="refresh"></Icon> {t('errors.Refresh')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultRoute;
