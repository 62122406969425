import React from 'react';

import ComponentsRenderer from '../../exocode_components/components_renderer';

export type CustomComponentViewProps = {
  custom_uuid: string;
  new_custom_uuid: string;
};

function CustomComponentView(props: CustomComponentViewProps) {
  return (
    <section style={{ backgroundColor: 'rgb(255 255 255 / 20%)', height: 'fit-content' }}>
      <ComponentsRenderer
        custom_uuid={props.custom_uuid}
        new_custom_uuid={props.new_custom_uuid}
        viewUUID={props.custom_uuid}
        parentUUID={props.custom_uuid}
      />
    </section>
  );
}

export * from './manifest';
export default CustomComponentView;
