import { useEffect, useState } from 'react';
import { AllowedModes } from '../index';
import { ModalRepository, PageRepository } from '../../../../../../modules/designer/repos';
import { FRONTEND_VARIABLE_TYPES } from '../../../../../../modules/designer/types';

export type ViewParam = {
  uuid: string;
  name: string;
  type: string;
  list: boolean;
  objectUuid: string;
  required: boolean;
};

type ExtendedView = {
  uuid: string;
  name: string;
  params: { [key: string]: ViewParam };
};

function useGetExtendedView(viewId: string, mode: AllowedModes) {
  const [view, setView] = useState({} as ExtendedView);

  useEffect(() => {
    if (!viewId || !mode) return;
    const getExtendedView = async () => {
      const v = await allowedModesFunctions[mode]();
      setView(v);
    };
    getExtendedView();
  }, [viewId, mode]);

  const allowedModesFunctions: {
    [Property in AllowedModes]: () => Promise<ExtendedView>;
  } = {
    page: getExtendedPage,
    modal: getExtendedModal,
    layout: getExtendedLayout
  };

  async function getExtendedPage(): Promise<ExtendedView> {
    if (!viewId) return {} as ExtendedView;
    const page = await PageRepository.getPage(viewId);
    if (!page) return {} as ExtendedView;
    const extended = {} as ExtendedView;
    extended.uuid = page.uuid;
    extended.name = page.name;
    extended.params = {} as { [key: string]: ViewParam };
    if (page?.params?.length > 0) {
      page.params.forEach((param) => {
        extended.params[param.uuid] = {
          uuid: param.uuid,
          name: param.name,
          type: FRONTEND_VARIABLE_TYPES.STRING,
          list: false,
          objectUuid: '',
          required: true
        };
      });
    }
    return extended;
  }

  async function getExtendedModal(): Promise<ExtendedView> {
    if (!viewId) return {} as ExtendedView;
    const modal = await ModalRepository.getModal(viewId);
    if (!modal) return {} as ExtendedView;
    const extended = {} as ExtendedView;
    extended.uuid = modal.uuid;
    extended.name = modal.name;
    extended.params = {} as { [key: string]: ViewParam };
    if (modal?.properties && modal?.properties?.length > 0) {
      modal.properties.forEach((prop) => {
        extended.params[prop.uuid] = {
          uuid: prop.uuid,
          name: prop.name,
          type: prop.type.toUpperCase(),
          list: prop.list,
          objectUuid: '',
          required: prop.required
        };
      });
    }
    return extended;
  }

  async function getExtendedLayout(): Promise<ExtendedView> {
    // TODO: implement this
    return {} as ExtendedView;
  }

  return view;
}

export default useGetExtendedView;
