import React, { useRef } from 'react';

type ParametersSpacerRootProps = {
  index: number;
  handleDrop: (e: React.DragEvent, index: number) => void;
};

function ParametersSpacerRoot({ index, handleDrop }: ParametersSpacerRootProps) {
  const spacerRef = useRef<HTMLDivElement>(null);
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (!spacerRef?.current) return;
    spacerRef.current.style.height = '2rem';
    spacerRef.current.style.border = '1px dashed rgba(13,110,253)';
  };

  const handleDragLeave = () => {
    if (!spacerRef?.current) return;
    spacerRef.current.style.height = '0.25rem';
    spacerRef.current.style.border = 'none';
  };

  const handleOnDrop = (e: React.DragEvent) => {
    if (!spacerRef?.current) return;
    spacerRef.current.style.height = '0.25rem';
    spacerRef.current.style.border = 'none';

    handleDrop(e, index);
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleOnDrop}
      style={{ transition: 'height 1s' }}
      className={`d-flex flex-column justify-content-center align-items-center border-primary`}
    >
      <div
        ref={spacerRef}
        className={'d-flex justify-content-center align-items-center rounded-2'}
        style={{
          height: '0.25rem',
          width: '100%',
          transition: 'height 0.1s linear'
        }}
      ></div>
    </div>
  );
}

export default ParametersSpacerRoot;
