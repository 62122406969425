import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { Form, ProgressBar } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { NavigateWelcomeButtons } from '.';

interface Step2Props {
  selectedOptions: { [key: string]: string[] };
  setSelectedOptions: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  onStepChange: (step: number) => void;
  checkAllAnswered: (
    selectedOptions: {
      [x: string]: string[];
    },
    questions: {
      id: string;
      question: string;
      options: string[];
    }[]
  ) => boolean;
  answered: boolean;
  setAnswered: (x: boolean) => void;
  questions: {
    id: string;
    question: string;
    options: string[];
  }[];
}

export function Step2({
  selectedOptions,
  setSelectedOptions,
  onStepChange,
  checkAllAnswered,
  answered,
  setAnswered,
  questions
}: Step2Props) {
  const { t } = useTranslation();
  const [otherSoftware, setOtherSoftware] = useState('');

  useEffect(() => {
    setAnswered(checkAllAnswered(selectedOptions, questions));
  }, [selectedOptions]);

  const handleOptionClick = (questionId: string, option: string) => {
    setSelectedOptions((prevSelectedOptions: { [key: string]: string[] }) => {
      const selected = prevSelectedOptions[questionId] || [];
      if (selected.includes(option)) {
        return {
          ...prevSelectedOptions,
          [questionId]: selected.filter((selectedOption) => selectedOption !== option)
        };
      } else {
        return {
          ...prevSelectedOptions,
          [questionId]: [...selected, option]
        };
      }
    });
  };

  const handleSingleOptionClick = (questionId: string, option: string) => {
    setSelectedOptions((prevSelectedOptions: { [key: string]: string[] }) => {
      return {
        ...prevSelectedOptions,
        [questionId]: [option]
      };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onStepChange(3);
  };

  const isChecked = (questionId: string, option: string): boolean => {
    return selectedOptions[questionId]?.includes(option);
  };

  const description = t('login_page.welcomeForm.step2.description', { returnObjects: true });
  const descriptionArray = Array.isArray(description) ? description : [description];

  function handleOther(value: string): void {
    setOtherSoftware(value);
    setSelectedOptions((prevSelectedOptions: { [key: string]: string[] }) => {
      return {
        ...prevSelectedOptions,
        OtherText: [value]
      };
    });
  }

  return (
    <>
      <div className={styles.StepWrapper}>
        <div className={`${styles.DescriptionCard}`}>
          {descriptionArray.map((line: string, index: number) =>
            index === 0 ? (
              <h1 key={index}>{line}</h1>
            ) : index % 2 == 0 ? (
              <h3 key={index}>{line}</h3>
            ) : (
              <p key={index}>{line}</p>
            )
          )}
        </div>
        <div className={`${styles.FormCard}`}>
          <ProgressBar className={`mb-3`} variant={'info'} min={0} max={4} now={2} />
          <Form onSubmit={handleSubmit}>
            {questions.map((question, questionIndex) => (
              <Form.Group controlId={question.id} key={question.id}>
                <Form.Label>
                  {t(`login_page.welcomeForm.questions.${question.question}.question`)}
                </Form.Label>
                <div className={`${styles.OptionsGroupWrapper} mb-3`}>
                  {question.options.map((option, index) => (
                    <button
                      key={option}
                      type="button"
                      className={`${styles.btnOption} ${
                        isChecked(question.id, option) && styles.btnActive
                      }`}
                      onClick={() =>
                        questionIndex === 0
                          ? handleSingleOptionClick(question.id, option)
                          : handleOptionClick(question.id, option)
                      }
                    >
                      {t(
                        `login_page.welcomeForm.questions.${question.question}.options.${index + 1}`
                      )}
                    </button>
                  ))}
                  {question.question == 'softwareDevelopMost' &&
                    selectedOptions['softwareDevelopMost']?.find((q) => q == 'Other') && (
                      <Form.Control
                        placeholder={'Other What?'}
                        key={questions.find((q) => q.id == 'softwareDevelopMost')?.id}
                        className={styles.btnOption}
                        onChange={(e) => handleOther(e.target.value)}
                        value={otherSoftware}
                        maxLength={120}
                        style={{
                          width: '14rem',
                          marginLeft: '110px'
                        }}
                        onKeyDown={(k) => {
                          if (k.key == 'Enter') {
                            k.stopPropagation();
                            k.preventDefault();
                          }
                        }}
                      />
                    )}
                </div>
              </Form.Group>
            ))}
            <NavigateWelcomeButtons
              answered={answered}
              onStepChange={onStepChange}
              currentStep={2}
            />
          </Form>
        </div>
      </div>
    </>
  );
}
