import React from 'react';
import Icon from 'web_ui/icon';
import HelpPopover from '../Popover';
import { useTranslation } from 'react-i18next';
import { BootstrapColors } from '../../DataTableSource/types';

interface ErrorIconProps {
  title: string;
  description?: string;
  note?: string[];
}

function ErrorIcon({ title, description, note }: ErrorIconProps) {
  const { t } = useTranslation();
  const helpDescription = description ? t(description) || description : '';
  return (
    <HelpPopover
      helpBoxProps={{
        title: t(title) || title,
        description: helpDescription,
        note: note,
        variant: BootstrapColors.danger
      }}
      placement="top"
    >
      <span className={`text-danger me-1`} style={{ fontSize: '12px' }}>
        <Icon iconName="circle-exclamation" />
      </span>
    </HelpPopover>
  );
}

export default ErrorIcon;
