import React, { ChangeEvent, useEffect } from 'react';
import { ControlProps } from '..';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ApiAppInfo } from '../../../../../modules/project/types';
import { ProjectsService } from '../../../../../modules/project/services';
import { AppTranslateService } from '../../../../../modules/designer/services';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

type Translates = {
  key: string;
  value: string;
};

function SelectTranslation(props: ControlProps) {
  const { app_id } = useParams();
  const [dataFromApi, setdataFromApi] = React.useState<Translates[]>([]);
  const { t } = useTranslation();

  async function fetchTranslations() {
    if (!app_id) return;
    const { mainLanguageId }: ApiAppInfo = await ProjectsService.getProjectById(app_id);
    if (!mainLanguageId) return;
    const translations = await AppTranslateService.getTranslation(mainLanguageId);
    setdataFromApi(translations);
  }

  useEffect(() => {
    fetchTranslations().then();
  }, [props.id]);

  function handleOnChange(event: ChangeEvent<HTMLSelectElement>) {
    if (props.onChange) props.onChange(event.target.value, props.id);
  }

  return (
    <div style={{ marginBottom: '1rem' }}>
      <div style={{ display: 'flex' }}>
        <label>{props.label}</label>
        {props.tooltip && (
          <HelpPopover
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>
      <Form.Select
        id="selectTranslation"
        value={props.value}
        onChange={(evt) => handleOnChange(evt)}
      >
        <option>Choose one</option>
        {dataFromApi &&
          dataFromApi.map((item, index) => {
            return <option key={index}>{item.key}</option>;
          })}
      </Form.Select>
    </div>
  );
}

export default SelectTranslation;
