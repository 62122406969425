import { Column } from '../../../types';
import {
  CHANGE_COLUMN_DEFAULT,
  CHANGE_COLUMN_NULLABLE,
  CHANGE_COLUMN_PROPERTY,
  ChangeColumnDefaultAction,
  ChangeColumnNullableAction,
  ChangeColumnPropertyAction,
  ColumnActions,
  MOVE_COLUMN,
  MoveColumnAction,
  SET_COLUMNS,
  UPDATE_PRIMARY_KEYS,
  UpdatePrimaryKeysAction,
  setColumnsAction
} from '../actions/columns';
import { ColumnState, initialState } from '../index';
import produce from 'immer';
import {
  ADD_RELATIONSHIP,
  ADD_TABLE,
  addRelationshipAction,
  addTableAction,
  DUPLICATE_TABLE,
  duplicateTableAction
} from '../actions/root';
import { makeColumn } from 'routes/studio/data/elements/factory';

export const columnsReducer = (state = initialState.columns, action: ColumnActions) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_COLUMNS:
        return doSetColumns(draft, action);
      case CHANGE_COLUMN_NULLABLE:
        return doChangeColumnNullable(draft, action);
      case CHANGE_COLUMN_DEFAULT:
        return doChangeColumnDefault(draft, action);
      case CHANGE_COLUMN_PROPERTY:
        return doChangeColumnProperty(draft, action);
      case ADD_TABLE:
        return doAddTableColumn(draft, action);
      case ADD_RELATIONSHIP:
        return doAddRelationshipColumn(draft, action);
      case MOVE_COLUMN:
        return doMoveColumn(draft, action);
      case DUPLICATE_TABLE:
        return doDuplicateTableColumn(draft, action);
      case UPDATE_PRIMARY_KEYS:
        return doUpdatePrimaryKeys(draft, action);
      default:
        return draft;
    }
  });
};

// Update the columnOrder property for the swapped columns only.
// This update is not necessary to properly update the UI, which only depends on
// the content.data.columns (see root.ts - doMoveColumn), but we do it anyways.
function doMoveColumn(state: ColumnState, action: MoveColumnAction): ColumnState {
  const swappedColumnOrder = state[action.payload.swappedColumn].columnOrder;
  state[action.payload.swappedColumn].columnOrder = state[action.payload.column].columnOrder;
  state[action.payload.column].columnOrder = swappedColumnOrder;
  return state;
}

function doSetColumns(state: ColumnState, action: setColumnsAction): ColumnState {
  state = {};
  action.payload.columns.forEach((column: Column) => {
    state[column.uuid] = column;
  });
  return state;
}

function doChangeColumnNullable(
  state: ColumnState,
  action: ChangeColumnNullableAction
): ColumnState {
  state[action.payload.id].nullable = action.payload.value;
  return state;
}

function doChangeColumnDefault(state: ColumnState, action: ChangeColumnDefaultAction): ColumnState {
  state[action.payload.id].defaultData = action.payload.value;
  return state;
}

function doChangeColumnProperty(
  state: ColumnState,
  action: ChangeColumnPropertyAction
): ColumnState {
  if (!state[action.payload.id].properties) {
    state[action.payload.id].properties = { [action.payload.key]: action.payload.value };
  } else {
    state[action.payload.id].properties[action.payload.key] = action.payload.value;
  }
  return state;
}

function doAddTableColumn(state: ColumnState, action: addTableAction): ColumnState {
  const columnID = action.payload.idPkColumn;

  const column = {
    uuid: action.payload.idPkColumn,
    tableUUID: action.payload.uuid,
    name: 'id' + action.payload.name,
    columnOrder: 0,
    description: 'Primary key',
    type: 'AUTOINCREMENT',
    nullable: false,
    defaultData: '',
    isPK: true,
    isFK: false,
    native: false,
    properties: {}
  };
  state[columnID] = column;
  return state;
}

function doAddRelationshipColumn(state: ColumnState, action: addRelationshipAction): ColumnState {
  let pkColumnID = '';
  let fkColumnID = '';
  let fkTableID = '';

  Object.keys(action.payload.components).forEach((key: string) => {
    if (action.payload.type === 'ONE2MANY') {
      pkColumnID = key;
      fkColumnID = action.payload.components[key];
      fkTableID = action.payload.to;
    } else {
      pkColumnID = action.payload.components[key];
      fkColumnID = key;
      fkTableID = action.payload.from;
    }

    if (state[pkColumnID]) {
      let columnName = state[pkColumnID].name + '_FK';
      const columnCount = Object.values(state).filter((column) =>
        column.name.includes(columnName)
      ).length;

      if (columnCount > 0) {
        columnName += columnCount;
      }
      const columnType =
        state[pkColumnID].type === 'AUTOINCREMENT' ? 'BIGINT' : state[pkColumnID].type;
      const newColumn: Column = Object.assign(
        makeColumn(fkTableID, columnName, columnType, 0, '', false, '', false, true, fkColumnID)
      );
      state[fkColumnID] = newColumn;
      return state[fkColumnID];
    }
  });

  return state;
}

function doDuplicateTableColumn(state: ColumnState, action: duplicateTableAction): ColumnState {
  Object.keys(action.payload.columns).forEach((oldColumnId) => {
    const newColumnId = action.payload.columns[oldColumnId];
    const oldColumn = state[oldColumnId];
    state[newColumnId] = {
      uuid: newColumnId,
      tableUUID: action.payload.uuid,
      name: oldColumn.name,
      type: oldColumn.type,
      columnOrder: oldColumn.columnOrder,
      nullable: oldColumn.nullable,
      defaultData: oldColumn.defaultData,
      enumUUID: oldColumn.enumUUID ?? null,
      isPK: oldColumn.isPK,
      isFK: oldColumn.isFK,
      description: oldColumn.description,
      properties: oldColumn.properties,
      native: false
    } as Column;
  });

  return state;
}

function doUpdatePrimaryKeys(state: ColumnState, action: UpdatePrimaryKeysAction): ColumnState {
  Object.keys(action.payload.columns).forEach((columnID) => {
    if (state[columnID]) {
      if (action.payload.columns[columnID] === true) state[columnID].isPK = true;
      else state[columnID].isPK = false;
    }
  });
  return state;
}
