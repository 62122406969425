import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import FrontendFunctionEditor from './editor';
import StoreUpdater from './store/store_updater';
import { FunctionEditorState } from '../../../web_ui/function_editor/store/types/function_editor_state';

type DesignerFunctionEditorProps = {
  moduleId: string;
  functionId: string;
  state: FunctionEditorState;
};
function DesignerFunctionEditor({ moduleId, functionId, state }: DesignerFunctionEditorProps) {
  if (!state.editor.functionId) return null;
  return (
    <Provider store={store}>
      {moduleId && <StoreUpdater moduleId={moduleId} functionId={functionId} state={state} />}
      <FrontendFunctionEditor />
    </Provider>
  );
}

export default DesignerFunctionEditor;
