import { useState, useEffect } from 'react';

const useCorrectedUrl = (url?: string, baseUrl?: string) => {
  const [correctedUrl, setCorrectedUrl] = useState('');

  useEffect(() => {
    const generateCorrectedUrl = () => {
      if (!url) return;
      if (url.startsWith('http://') || url.startsWith('https://')) {
        setCorrectedUrl(url);
      } else {
        const apiUrl = baseUrl || process.env.REACT_APP_API_URL;
        if (apiUrl && apiUrl.startsWith('https')) {
          setCorrectedUrl(apiUrl + url); // Prod Mode
        } else {
          setCorrectedUrl(apiUrl + url); // Dev Mode
        }
      }
    };

    generateCorrectedUrl();
  }, [url, baseUrl]);

  return correctedUrl;
};

export default useCorrectedUrl;
