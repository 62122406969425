import React, { FormEvent, useEffect, useState } from 'react';
import { CustomComponentService } from 'modules/designer/services';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RESET_DESIGNER_STUDIO } from '../../store';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { validateCustomComponentName } from 'utils/inputValidation';
import { useQuery } from 'hooks/useQuery';

type CreateCustomComponentDialogProps = {
  show: boolean;
  onClose: () => void;
  appId: string;
  fetchCustomComponents: () => void;
};

export function CreateCustomComponentDialog(props: CreateCustomComponentDialogProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { module_id } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isHierarchy, setIsHierarchy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputNameErrorMessage, setInputNameErrorMessage] = useState<string>('');
  const queryParameters = useQuery();

  useEffect(() => {
    if (!props.show) return;
    setName('');
    setDescription('');
    setInputNameErrorMessage('');
  }, [props.show]);

  const onSubmit = async (event: FormEvent) => {
    if (isLoading) return;
    setIsLoading(true);
    event.preventDefault();

    const isValid = validateInputs();
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const customComponent = {
      name: name,
      description: description,
      appId: props.appId,
      isHierarchy: isHierarchy
    };

    const isFromVsCodeExt = (): boolean => {
      const itemFound = queryParameters.get('vscode');
      if (itemFound) {
        return Boolean(itemFound);
      } else {
        return false;
      }
    };

    await CustomComponentService.createEmptyCustomComponent(customComponent, props.appId)
      .then((createdComponent) => {
        props.onClose();
        props.fetchCustomComponents();
        dispatch({ type: RESET_DESIGNER_STUDIO });
        navigate(
          `/app/${props.appId}/module/${module_id}/ui/custom_component/${createdComponent.uuid}${
            isFromVsCodeExt() ? '?vscode=true' : ''
          }`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateInputs = (): boolean => {
    const isValidName = checkNameIsValid(name);
    return isValidName;
  };

  const checkNameIsValid = (input: string): boolean => {
    const { code, valid } = validateCustomComponentName(input);
    let errorMessage = '';
    if (code === 'CONTAIN_SPACES') {
      errorMessage = 'inputValidationErrorMessages.CustomComponentNameContainsSpaces';
    } else if (code === 'CONTAIN_SPECIAL_CHARACTERS') {
      errorMessage = 'inputValidationErrorMessages.CustomComponentNameContainsSpecialCharacters';
    } else if (code === 'EMPTY_STRING') {
      errorMessage = 'inputValidationErrorMessages.CustomComponentNameContainsEmptyString';
    } else if (code) {
      errorMessage = 'inputValidationErrorMessages.GenericErrorMessage';
    }
    setInputNameErrorMessage(errorMessage);
    return valid;
  };

  const handleNameChange = (name: string) => {
    checkNameIsValid(name);
    setName(name);
  };

  return (
    <>
      <Modal centered show={props.show} onHide={props.onClose}>
        <Form onSubmit={onSubmit} id="formModal">
          <Modal.Header closeButton>
            <Modal.Title>{t('designer.customComponents.CreateCustomComponent')}</Modal.Title>
          </Modal.Header>
          <Modal.Body id="bodyModal">
            <Form.Group className="mb-3">
              <Form.Label>{t('designer.customComponents.Name')}</Form.Label>
              <Form.Control
                type="text"
                id={'formName'}
                onChange={(e) => handleNameChange(e.target.value)}
                value={name}
                isInvalid={inputNameErrorMessage !== ''}
                maxLength={64}
                required
              />
              <Form.Control.Feedback type={'invalid'}>
                {t(inputNameErrorMessage)}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('designer.customComponents.Description')}</Form.Label>
              <Form.Control
                type="text"
                id="formDescription"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                maxLength={64}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                id="hierarchyCheck"
                label={t('designer.customComponents.HierarchyCheckbox')}
                onChange={(e) => setIsHierarchy(e.target.checked)}
                checked={isHierarchy}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button id={'saveButton'} type="submit">
              {t('designer.customComponents.Create')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
