export const COLUMN_SIZE_TO_SHORTCUTS_MAPPER: { [key: number]: number[][] } = {
  1: [[1], [3], [6], [9], [12]],
  2: [
    [6, 6],
    [3, 9],
    [9, 3],
    [2, 10],
    [10, 2]
  ],
  3: [
    [4, 4, 4],
    [3, 6, 3],
    [5, 2, 5],
    [6, 3, 3],
    [3, 3, 6]
  ],
  4: [
    [3, 3, 3, 3],
    [6, 2, 2, 2],
    [2, 2, 2, 6],
    [2, 4, 4, 2],
    [4, 2, 2, 4]
  ],
  5: [
    [1, 2, 6, 2, 1],
    [3, 1, 4, 1, 3],
    [1, 3, 4, 3, 1],
    [1, 1, 8, 1, 1],
    [2, 1, 6, 1, 2]
  ],
  6: [
    [1, 1, 4, 4, 1, 1],
    [4, 1, 1, 1, 1, 4],
    [1, 4, 1, 1, 4, 1],
    [4, 4, 1, 1, 1, 1],
    [1, 1, 1, 1, 4, 4]
  ]
};
