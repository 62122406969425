import { View } from '../../../types';

export const PAGE_TEMPLATE: View = {
  uuid: 'newView',
  type: 'PAGE',
  data: {
    title: 'New Page',
    description: '...',
    route: '/newPage'
  },
  styles: {
    backgroundColor: '#FFFFFF',
    globalFont: 'Arial',
    globalFontSize: 16,
    globalFontColor: '#000000',
    classes: []
  },
  functions: [],
  variables: [],
  params: [],
  properties: [],
  events: {},
  roles: []
};
