import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SchemaParams } from '../../../../../../../../../routes/studio/data';
import useCreateExternalRelationship from '../../hooks/useCreateExternalRelationship';
import { TableUUID } from '../../../../../../../types';
import useFetchAvailableTables from '../../hooks/useFetchAvailableTables';
import { Button, Form } from 'react-bootstrap';
import styles from '../../../indexes/components/index_editor/styles.module.css';
import { useTranslation } from 'react-i18next';

type NewRelationshipEditorProps = {
  tableID: TableUUID;
  setShowNewRelationship: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function NewRelationshipEditor({
  tableID,
  setShowNewRelationship
}: NewRelationshipEditorProps) {
  const { module_id } = useParams<SchemaParams>();
  const [selectedExternalModule, setSelectedExternalModule] = useState<number>(0);
  const [selectedExternalTable, setSelectedExternalTable] = useState<number>(0);
  const [selectedExternalTableIndex, setSelectedExternalTableIndex] = useState<number>(0);
  const { availableTables } = useFetchAvailableTables(tableID, module_id);
  const { t } = useTranslation();

  const handleCreateExternalRelationship = useCreateExternalRelationship(
    module_id ?? '',
    tableID,
    selectedExternalModule,
    selectedExternalTable,
    setSelectedExternalTable,
    selectedExternalTableIndex
  );

  const getModules = useCallback(() => {
    if (!availableTables) {
      return [];
    }
    if (!availableTables.modules) {
      return [];
    }
    return availableTables.modules;
  }, [availableTables]);

  const getTables = useCallback(() => {
    if (!availableTables) {
      return [];
    }
    if (!availableTables.modules[selectedExternalModule]) {
      return [];
    }
    if (!availableTables.modules[selectedExternalModule].tables) {
      return [];
    }
    return availableTables.modules[selectedExternalModule].tables;
  }, [availableTables, selectedExternalModule]);

  const getIndexes = useCallback(() => {
    if (!availableTables) {
      return [];
    }
    if (!availableTables.modules[selectedExternalModule]) {
      return [];
    }
    if (!availableTables.modules[selectedExternalModule].tables) {
      return [];
    }
    if (!availableTables.modules[selectedExternalModule].tables[selectedExternalTable]) {
      return [];
    }
    return availableTables.modules[selectedExternalModule].tables[selectedExternalTable].indexes;
  }, [availableTables, selectedExternalModule, selectedExternalTable]);

  return (
    <div className={styles.RelationshipEditorWrapper}>
      <Form.Group className="mb-2" controlId="externalModule">
        <Form.Label className={styles.SmallFont}>{t('modeler.Module')}</Form.Label>
        <Form.Select
          placeholder={`${t('modeler.Module')}`}
          value={selectedExternalModule}
          onChange={(e) => setSelectedExternalModule(+e.target.value)}
        >
          {getModules().map((module, index) => {
            return (
              <option key={module.id} value={index}>
                {module.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2" controlId="externalTable">
        <Form.Label className={styles.SmallFont}>{t('modeler.Table')}</Form.Label>
        <Form.Select
          placeholder={`${t('modeler.Table')}`}
          value={selectedExternalTable}
          onChange={(e) => setSelectedExternalTable(+e.target.value)}
        >
          {getTables().map((table, index) => {
            return (
              <option key={table.id} value={index}>
                {table.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2" controlId="externalTableIndex">
        <Form.Label className={styles.SmallFont}>{t('modeler.Index')}</Form.Label>
        <Form.Select
          placeholder={`${t('modeler.Index')}`}
          value={selectedExternalTableIndex}
          onChange={(e) => setSelectedExternalTableIndex(+e.target.value)}
        >
          {getIndexes().map((externalIndex, index) => {
            return (
              <option key={externalIndex.id} value={index}>
                {externalIndex.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <Button
        id={'deleteButton'}
        onClick={() => {
          setShowNewRelationship(false);
          handleCreateExternalRelationship().then(() => {});
        }}
        variant="outline-success"
        className={'mt-2'}
      >
        {t('modeler.Create')}
      </Button>
    </div>
  );
}
