import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableCssClasses, CssClasses } from './css_clases';
import { v4 as uuidv4 } from 'uuid';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import styles from './styles.module.css';
import { changeComponentStyle } from 'modules/designer/studio/store/actions/components';
import { changeViewStyle } from 'modules/designer/studio/store/actions/views';

interface CssClasesControlProps {
  elementId: string;
  elementType: string;
}

export const CssClassesControl = (props: CssClasesControlProps) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const componentClassNames = useSelector((state: InterfaceStudioState) => {
    return state.components[props.elementId]?.styles.classes;
  });
  const viewClassNames = useSelector((state: InterfaceStudioState) => {
    return state.views[props.elementId]?.styles.classes;
  });
  const globalSelectors = useSelector((state: InterfaceStudioState) => {
    return state.globalCssClasses;
  });

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        className="w-100 mb-3"
        style={{ height: 'fit-content' }}
      >
        {t('designer.cssClass.CssClasses')}
      </Button>
      <CssClassesControlModal
        elementId={props.elementId}
        elementType={props.elementType}
        show={showModal}
        onClose={() => setShowModal(false)}
        globalClasses={globalSelectors
          .filter((css) => css.cssSelector == '')
          .map((s) => `${s.cssName}${s.cssSelector}`)}
        elementClassNames={(componentClassNames || viewClassNames) ?? []}
      />
    </>
  );
};

interface ControlProps {
  show: boolean;
  onClose: () => void;
  elementId: string;
  elementType: string;
  elementClassNames: string[];
  globalClasses: string[];
}

export type SortableCssClass = {
  className: string;
  id: string;
};

const CssClassesControlModal = (props: ControlProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cssClasses, setCssClasses] = useState<SortableCssClass[]>([]);

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setCssClasses(props.elementClassNames.map((e) => ({ className: e, id: uuidv4() })));
  }, [props.elementClassNames, props.show]);

  const saveClasses = () => {
    const classes: string[] = cssClasses.map((e) => {
      if (e.className[0] === '.') {
        return e.className.slice(1);
      }
      return e.className;
    });
    if (props.elementType === 'component') {
      dispatch(changeComponentStyle(props.elementId, 'classes', classes));
    } else if (props.elementType === 'view') {
      dispatch(changeViewStyle(props.elementId, 'classes', classes));
    }
    props.onClose();
  };

  const updateClasses = (newClasses: SortableCssClass[]) => {
    setCssClasses(newClasses);
  };

  const addClass = (newClass: string) => {
    const newClasses = [...cssClasses, { className: newClass, id: uuidv4() }];
    setCssClasses(newClasses);
  };

  const removeClass = (id: string) => {
    const newClasses = cssClasses.filter((e) => e.id !== id);
    setCssClasses(newClasses);
  };

  return (
    <Modal centered show={props.show} onHide={props.onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('designer.cssClass.CssClassesControl')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`${styles.CssClassesWrapper}`}>
          <AvailableCssClasses availableClasses={props.globalClasses} addClass={addClass} />
        </div>
        <div className={`${styles.CssClassesWrapper}`}>
          <CssClasses
            classes={cssClasses}
            updateClasses={updateClasses}
            removeClass={removeClass}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button id="saveCssClasses" onClick={saveClasses}>
          {t('Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
