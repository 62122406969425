import React, { forwardRef, memo } from 'react';
import { ComponentUUID } from '../../../types';
import { Form } from 'react-bootstrap';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';

export type CheckData = {
  checkStyle?: 'checkbox' | 'switch';
  label?: string;
  tooltip?: string;
  defaultValue?: string;
  required?: boolean;
  readonly?: boolean;
  readonlyVar?: string;
  disabled?: boolean;
  disabledVar?: string;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  translateKey?: string;
  hasTranslation?: string;
  visible?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type CheckStyles = {
  staticColor?: string;
  themeColor?: string;
  fontSize?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  css?: string;
  classes?: string[];
};

export type CheckComponentProps = {
  uuid: ComponentUUID;
  data: CheckData;
  styles: CheckStyles;
};

function Check(props: CheckComponentProps, ref: React.Ref<any>) {
  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.staticColor,
    fontSize: `${props.styles.fontSize}px`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <div
      ref={ref}
      title={props.data.tooltip}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      className={`text-${props.styles.themeColor} ${classes}`}
    >
      <Form.Check
        type={props.data.checkStyle}
        readOnly={props.data.readonly}
        disabled={props.data.disabled}
        required={props.data.required}
        label={props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.label}
      />
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(Check));
