import { PaymentsRepository } from '../repos';

export class PaymentsServ {
  async getSubscriptionPlans(): Promise<any> {
    return await PaymentsRepository.getSubscriptionPlans();
  }

  async getUserActiveSubscriptions(userId: number): Promise<any> {
    return await PaymentsRepository.getUserActiveSubscriptions(userId);
  }

  async updateUserSubscription(
    userId: number,
    newPriceId: string,
    newLookupKey: string,
    subscriptionId: string
  ): Promise<any> {
    return await PaymentsRepository.updateUserSubscription(
      userId,
      newPriceId,
      newLookupKey,
      subscriptionId
    );
  }

  async cancelPlan(userId: number, when: string): Promise<any> {
    return await PaymentsRepository.cancelPlan(userId, when);
  }

  async continuePlan(userId: number): Promise<any> {
    return await PaymentsRepository.continuePlan(userId);
  }

  async createCheckoutSetupSession(userId: number): Promise<any> {
    return await PaymentsRepository.createCheckoutSetupSession(userId);
  }
}
