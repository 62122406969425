import { Action } from 'redux';
import { ObjectsState } from '../index';
import { SchemaObject } from '../../../../logic_builder/types';

export const SET_OBJECTS = 'SET_OBJECTS';
export const ADD_OBJECT = 'ADD_OBJECT';

export interface SetObjectsAction extends Action {
  type: 'SET_OBJECTS';
  payload: {
    objects: ObjectsState;
  };
}

export interface AddObjectAction extends Action {
  type: 'ADD_OBJECT';
  payload: {
    object: SchemaObject;
  };
}

export const setObjects = (objects: ObjectsState): SetObjectsAction => ({
  type: SET_OBJECTS,
  payload: { objects }
});

export const addObject = (object: SchemaObject): AddObjectAction => ({
  type: ADD_OBJECT,
  payload: { object }
});
