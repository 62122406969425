import React, { forwardRef, memo, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { ComponentUUID, VarComponent } from 'modules/designer/types';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import useCorrectedUrl from 'modules/designer/hooks/correctUrl';
import { InterfaceStudioState } from '../../store';
import { useSelector } from 'react-redux';
import { convertingCSS } from 'utils/utils';

export type ImagebuttonData = {
  label?: string;
  disabled?: boolean;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  tooltip?: string;
  source?: string;
  visible?: string;
  disabledVar?: string;
  readonly?: boolean;
  readonlyVar?: string;
  variable?: VarComponent;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type ImagebuttonStyles = {
  color?: string;
  fontSize?: number;
  textAlign?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  fontWeight?: string;
  lineHeight?: number | string;
  textDecoration?: string;
  textTransform?: string;
  fontFamily?: string;
  fontStyle?: string;
  borderColor?: string;
  boxshadow?: string;
  classes?: string[];
};

export type ImageButtonComponentProps = {
  uuid: ComponentUUID;
  data: ImagebuttonData;
  styles: ImagebuttonStyles;
};

function ImageButtonComponent(props: ImageButtonComponentProps, ref: React.Ref<any>) {
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  const API_URL = process.env.REACT_APP_API_URL;
  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.color,
    fontSize: `${props.styles.fontSize}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid`,
    borderRight: `${props.styles.borderRight}px solid`,
    borderBottom: `${props.styles.borderBottom}px solid`,
    borderLeft: `${props.styles.borderLeft}px solid`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    fontWeight: `${props.styles.fontWeight}`,
    lineHeight: `${props.styles.lineHeight}`,
    textDecoration: `${props.styles.textDecoration}`,
    TextTransform: `${props.styles.textTransform}`,
    fontFamily: `${props.styles.fontFamily}`,
    fontStyle: `${props.styles.fontStyle}`,
    borderColor:
      props.styles.borderColor &&
      convertingCSS(props.styles.borderColor, theme.colors[props.styles.borderColor]),
    boxShadow: props.styles.boxshadow,
    overflow: props.styles.boxshadow ? 'visible' : undefined
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);

  const classes = props.styles.classes?.join(' ') || '';

  const correctUrl = useCorrectedUrl(props.data.source);

  return (
    <div
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      title={props.data.tooltip}
      className={`${classes}`}
    >
      {props.data.variable && Object.keys(props.data.variable).length > 0 ? (
        <Form.Control
          type="image"
          disabled={props.data.disabled}
          src={'/assets/default-image-button.jpg'}
          alt="image button"
          readOnly={props.data.readonly}
        />
      ) : props.data.source ? (
        <>
          <Form.Control
            type="image"
            disabled={props.data.disabled}
            src={correctUrl}
            alt="image button"
            readOnly={props.data.readonly}
          />
        </>
      ) : (
        <>
          <Form.Control
            type="image"
            disabled={props.data.disabled}
            src={'/assets/default.png'}
            alt="image button"
            readOnly={props.data.readonly}
          />
        </>
      )}
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(ImageButtonComponent));
