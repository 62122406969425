import React from 'react';
import TypePicker, { TYPE_PICKER_TYPES } from '../argument_picker/type_picker';
import ValuePicker from '../argument_picker/value_picker';
import ObjectNodePicker from '../../components/object_node_picker';
import { Argument } from '../../../store/types/manifestsAndInputs';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { ParameterEndpoint } from '../../../../../modules/logic_builder/types';
import useCheckSameType from './hooks/useCheckSameType';
import { VariableTypes } from '../../../../../modules/designer/types';
import useCheckValueIsObject from '../argument_picker/useCheckValueIsObject';

type EndpointParameterEditorProps = {
  param: ParameterEndpoint;
  paramValue: Argument;
  handleChange: (paramId: string, value: Argument) => void;
  actionUuid: string;
  isFromSideBar?: boolean;
};

function EndpointParameterEditor({
  paramValue,
  param,
  handleChange,
  actionUuid,
  isFromSideBar
}: EndpointParameterEditorProps) {
  const { isObject, objectUuid } = useCheckValueIsObject(paramValue.type, paramValue.value);
  const validateType = useCheckSameType();
  const handleChangeType = (t: TYPE_PICKER_TYPES) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { type: t, value: '', objectNode: '' });
  };

  const handleChangeValue = (v: string) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { ...paramValue, value: v, objectNode: '' });
  };

  const handleChangeObjectNode = (o: string) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { ...paramValue, objectNode: o });
  };

  const filterByDataType = (state: FunctionEditorState, actionUuid: string, dataType: string) => {
    if (!dataType) return false;
    if (paramValue?.type == 'PAGE_PARAM') return param.type.toUpperCase() !== 'OBJECT';
    return (
      validateType(param?.type, dataType as VariableTypes) || dataType.toUpperCase() === 'OBJECT'
    );
  };

  return (
    <div className={isFromSideBar ? '' : 'd-flex align-items-end'}>
      <p className={'text-secondary-emphasis mb-0 me-1'} style={{ width: '8rem' }}>
        {param?.name} ={' '}
      </p>
      <TypePicker
        placeholder={'Select argument type'}
        label={''}
        value={paramValue?.type}
        handleChange={handleChangeType}
        argumentTypes={param?.list ? [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM] : []}
        isFromSideBar={isFromSideBar}
      />
      <ValuePicker
        type={paramValue?.type}
        label={''}
        actionUuid={actionUuid}
        filterByList={param?.list}
        filterByDataType={filterByDataType}
        value={paramValue?.value}
        handleChange={handleChangeValue}
        isFromSideBar={isFromSideBar}
      />
      {isObject && (
        <ObjectNodePicker
          paramType={param?.type}
          objectUuid={objectUuid}
          filterByList={param?.list}
          value={paramValue?.objectNode ?? ''}
          handleChange={handleChangeObjectNode}
          isFromSideBar={isFromSideBar}
        />
      )}
    </div>
  );
}

export default EndpointParameterEditor;
