import React, { MouseEvent, ReactNode, useCallback, useState } from 'react';
import styles from './styles.module.css';

export type ContextMenuOption = {
  label: string;
  icon: string;
  onClick: (
    e?: any,
    currentScale?: number,
    currentPosition?: { posX: number; posY: number },
    type?: string
  ) => void;
};

type ContextMenuItemProps = {
  label: string;
  icon: string;
  children?: ReactNode;
  subMenu?: Record<string, any>;
  onClick: (e?: any) => void;
  style?: [string];
  disabled?: boolean;
};

function ContextMenuItem(props: ContextMenuItemProps) {
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);

  const handleMouseEnter = () => {
    if (props.children) {
      setIsSubmenuVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (props.children) {
      setIsSubmenuVisible(false);
    }
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!props.disabled) {
      props.onClick(e);
    } else {
      e.stopPropagation();
    }
  };

  return (
    <li
      className={`${styles.contextMenuItem}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        id={props.label.replaceAll(' ', '')}
        className={`${props.style} dropdown-item ${
          props.disabled && `disabled ${styles.disabledContextMenuItem}`
        }`}
        onClick={handleClick}
      >
        <span className={`fa fa-${props.icon} me-2`} /> {props.label}
      </div>
      {isSubmenuVisible && props.subMenu && (
        <ul className={styles.subMenu}>
          {Object.values(props.subMenu).map((subMenuItem, index) => (
            <ContextMenuItem
              key={index}
              label={subMenuItem.label}
              icon={subMenuItem.icon}
              onClick={(e) => subMenuItem.onClick(e)}
              style={props.style}
              disabled={subMenuItem.disabled}
            />
          ))}
        </ul>
      )}
    </li>
  );
}

export default ContextMenuItem;
