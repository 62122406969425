import React from 'react';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import CustomCodeIcon from './custom_code_icon';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { t } from 'i18next';

export const CUSTOM_CODE_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.CUSTOM_CODE,
  name: t('CustomCode'),
  description: 'Action to add a custom code to the function',
  color: 'warning',
  category: FRONTEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.FUNCTION,
  icon: <CustomCodeIcon />,
  inlineInputs: [],
  inputs: [
    {
      label: t('CustomCode'),
      placeholder: '// Type your code here',
      type: ACTION_INPUTS_TYPES.CODE,
      key: 'code',
      options: { language: 'typescript' },
      showInToolbar: false
    }
  ]
};
