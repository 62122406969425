import React, { useRef, useState } from 'react';
import { ReturnType } from '../../../store/types/functions';
import styles from './styles.module.css';
import { Combobox } from '@headlessui/react';
import Icon from '../../../../icon';
import useHandleDisplayTypeName from '../../../hooks/useHandleDisplayTypeName';

type DataTypePicker = {
  handleChange: (type: ReturnType) => void;
  type: ReturnType;
  types: ReturnType[];
  disabled?: boolean;
  onFocus?: () => void;
};

function DataTypePicker({ handleChange, type, types, disabled = false, onFocus }: DataTypePicker) {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const typeName = useHandleDisplayTypeName(type, types);

  const filteredTypes =
    query === ''
      ? types
      : types?.filter((item) => {
          return item.name && item.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      onChange={(type: ReturnType) => {
        setQuery(type?.name ?? '');
        handleChange(type);
      }}
      value={type}
      disabled={disabled}
    >
      <div className={`${styles.ReturnTypeCombo} ${disabled ? 'bg-body-secondary' : ''}`}>
        <Combobox.Input
          id="selectType"
          ref={inputRef}
          className={`${styles.ReturnTypeComboInput} ${
            isOpen ? 'rounded-top-2 rounded-bottom-0' : 'rounded-2'
          }`}
          onChange={(e) => setQuery(e.target.value)}
          autoComplete={'off'}
          displayValue={() => typeName}
          onFocus={onFocus}
        />
        <Combobox.Button
          style={{
            fontSize: '0.7rem',
            float: 'right',
            height: '100%',
            paddingTop: '3px'
          }}
          as={'span'}
        >
          {({ open }) => {
            setIsOpen(open);
            if (open) {
              return <Icon iconName={'chevron-up'} />;
            } else {
              return <Icon iconName={'chevron-down'} />;
            }
          }}
        </Combobox.Button>
        <Combobox.Options className={`${styles.ReturnTypeComboOptions}`}>
          {filteredTypes?.map((item, index) => (
            <Combobox.Option
              className={({ active }) =>
                active
                  ? `bg-secondary ${styles.ReturnTypeComboOption}`
                  : `${styles.ReturnTypeComboOption}`
              }
              value={item}
              key={index}
            >
              {item.name ?? item.type}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

export default DataTypePicker;
