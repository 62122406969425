import { FunctionsState } from '../../types/functions';
import { DeleteBlockAction } from '../actions/delete_block';

export function doDeleteBlock(state: FunctionsState, action: DeleteBlockAction): FunctionsState {
  const { functionUuid, uuid, closeBlockActionUuid } = action.payload;

  const openBlockIndex = state[functionUuid].actions.indexOf(uuid);
  const closeBlockIndex = state[functionUuid].actions.indexOf(closeBlockActionUuid);

  state[functionUuid].actions.splice(closeBlockIndex, 1);
  state[functionUuid].actions.splice(openBlockIndex, 1);

  return state;
}
