import React, { ReactNode, useRef } from 'react';
import useDropZone from './drop_zone_hook';
import { ComponentUUID, ViewUUID } from '../../types';
import { COMPONENT_TYPES } from '.';

export type SectionWrapperProps = {
  section: string;
  uuid: ComponentUUID;
  type: string;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  custom_uuid?: string;
  children: ReactNode;
  data?: any;
};

function SectionWrapper(props: SectionWrapperProps) {
  const elementNode = useRef<HTMLDivElement>(null);

  useDropZone(
    elementNode,
    props.uuid,
    props.type,
    props.viewUUID,
    props.parentUUID,
    props.custom_uuid,
    props.section
  );

  return (
    <div
      className={`${
        props.type === COMPONENT_TYPES.COLUMN
          ? props.data?.width
            ? `col-md-${props.data.width}`
            : 'col'
          : ''
      } col-12 h-100`}
      ref={elementNode}
    >
      {props.children}
    </div>
  );
}

export default SectionWrapper;
