import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from '../../../types';
import { t } from 'i18next';

export const CHECKBOX_MANIFEST: ComponentManifest = {
  type: 'CHECK',
  name: 'Checkbox',
  descriptionId: 'CheckboxDescription',
  description:
    'Checkboxes are a common form control that allows the user to check and uncheck a box.',
  icon: 'fa-solid fa-square-check',
  group: ComponentGroups['INPUT'],
  previewHeight: 50,
  previewWidth: 100,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Label',
      key: 'label',
      tooltip: 'designer.right_side.controls.components.check.text'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Style',
      key: 'checkStyle',
      controlOptions: ['checkbox', 'switch'],
      tooltip: 'designer.right_side.controls.components.check.select'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.check.visibleVariable'
    },
    {
      controlType: ControlsTypes.VARIABLEREADONLY,
      controlLabel: 'ReadonlyVariable',
      key: 'readonlyVar',
      tooltip: 'designer.right_side.controls.components.check.readOnly'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.check.textArea'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Required',
      key: 'required',
      tooltip: 'designer.right_side.controls.components.check.required'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Readonly',
      key: 'readonly',
      tooltip: 'designer.right_side.controls.components.check.Readonly'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Disabled',
      key: 'disabled',
      tooltip: 'designer.right_side.controls.components.check.Disabled'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'DisabledVariable',
      key: 'disabledVar',
      tooltip: 'designer.right_side.controls.components.check.DisabledVar'
    },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.check.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.check.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      tooltip: 'designer.right_side.controls.components.check.themePicker'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.check.number'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CHANGE']
};
