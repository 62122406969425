import styles from './styles.module.css';
import React from 'react';
import { Index } from '../../../../../../../types';

type IndexProps = {
  index: Index;
  selectedIndexID: string;
  selectIndex: React.Dispatch<React.SetStateAction<string>>;
};

export default function IndexLine({ index, selectedIndexID, selectIndex }: IndexProps) {
  return (
    <div
      id={index.name}
      className={`${styles.IndexWrapper} ${selectedIndexID === index?.id && 'bg-body-tertiary'}`}
      key={index?.id}
      onClick={() => selectIndex(index?.id)}
    >
      <div className={`${styles.KeyValue} ${styles.SelectedIndex} ${styles.IndexDetailsWrapper}`}>
        <div className={styles.Key}>{index.name}</div>
      </div>
    </div>
  );
}
