import React from 'react';
import { SelectedTabs } from './index';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type AppTabNavItemProps = {
  tab: SelectedTabs;
  selected: boolean;
  handleClick: (tab: SelectedTabs) => void;
};

function AppTabNavItem({ tab, selected, handleClick }: AppTabNavItemProps) {
  const { t } = useTranslation();
  return (
    <Nav.Item>
      <Nav.Link
        id={`${tab.toLowerCase()}Tab`}
        active={selected}
        href="#"
        onClick={() => handleClick(tab)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {t(`appResume.${tab}`)}
      </Nav.Link>
    </Nav.Item>
  );
}

export default AppTabNavItem;
