import React, { forwardRef, memo } from 'react';
import { ComponentUUID, ViewUUID } from '../../../types';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';

export enum FormInputTypes {
  DATE = 'date',
  FILE = 'file',
  SELECT = 'select',
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  CHECKBOX = 'checkbox'
}

export type FormInput = {
  label: string;
  type: string;
  objectItemUuid: string;
  parentObject?: string;
};

export type FormContent = {
  variable: string;
  inputs: FormInput[];
  nestedObjectsPath: { [key: string]: FormNestedObjectInfo };
};

export type FormNestedObjectInfo = {
  parentObjectUuid: string;
  parentObjectItemUuid: string;
};

export type FormData = {
  content: FormContent;
  submitText: string;
  extraText?: string;
  visible?: string;
};

export type FormStyles = {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  classes?: string[];
};

export type FormComponentProps = {
  viewUUID: ViewUUID;
  uuid: ComponentUUID;
  data: FormData;
  styles: FormStyles;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function FormComponent(props: FormComponentProps, ref: React.Ref<any>) {
  const { t } = useTranslation();

  const style = {
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  removeDeclarationsWithUndefinedValue(style);

  const classes = props.styles.classes?.join(' ') || '';

  if (!props.data.content.variable) {
    return (
      <div
        ref={ref}
        className="p-5 border rounded-3 bg-light text-center"
        style={{ opacity: 0.75 }}
      >
        <h5>{t('designer.right_side.NoDataSource')}</h5>
        <p>{t('designer.right_side.FormNoDataSource')}</p>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      className={`${classes}`}
    >
      <Form>
        {props.data.content?.inputs
          .filter((input) => input.type !== 'OBJECT')
          .map((input) => (
            <Form.Group key={input.objectItemUuid} className="mb-3">
              {input.type === FormInputTypes.CHECKBOX && (
                <Form.Check type="checkbox" label={input.label} />
              )}
              {input.type === FormInputTypes.SELECT && (
                <>
                  <Form.Label>{input.label}</Form.Label>
                  <Form.Select />
                </>
              )}
              {input.type !== FormInputTypes.CHECKBOX && input.type !== FormInputTypes.SELECT && (
                <>
                  <Form.Label>{input.label}</Form.Label>
                  <Form.Control type={input.type} />
                </>
              )}
            </Form.Group>
          ))}
        {props.data.extraText && <Button variant="secondary">{props.data.extraText}</Button>}
        <Button variant="primary">{props.data.submitText}</Button>
      </Form>
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(FormComponent));
