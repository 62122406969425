import * as React from 'react';
import { ObjectSimple } from 'modules/logic_builder/types';
import { Form } from 'react-bootstrap';

type Props = {
  objects: ObjectSimple[];
  value: string;
  onChange: (value: string) => void;
  label: string;
};

export default function ObjectPicker(props: Props) {
  return (
    <Form.Group>
      <Form.Label>{props.label}</Form.Label>
      <Form.Select
        id={props.value}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        required
        style={{ marginBottom: '1rem' }}
      >
        <option selected hidden>
          Select one
        </option>
        {props.objects
          .filter((objItem) => objItem.entityUuid && objItem.entityUuid.length > 0)
          .map((objItem, index) => {
            return (
              <option key={index} value={objItem.uuid}>
                {objItem.name}
              </option>
            );
          })}
      </Form.Select>
    </Form.Group>
  );
}
