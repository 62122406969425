import React, { ChangeEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AccountService, AuthService } from 'modules/auth/services';
import styles from './styles.module.css';
import SessionContext, { initialPreferences } from 'modules/auth/store';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InputGroup from 'react-bootstrap/InputGroup';
import { validatePassword } from 'web_ui/password_fields';

type LoginFormProps = {
  isFirstLogin: boolean;
  setgettingValueError: React.Dispatch<React.SetStateAction<boolean>>;
  setValueError: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * User sign in process
 *
 * @component
 */
function LoginForm(props: LoginFormProps) {
  const [password, setPassword] = React.useState('');
  const [formValidity, setFormValidity] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordInputFieldType, setPasswordInputFieldType] = React.useState('password');
  const [validating, setValidating] = React.useState<boolean>();
  const [vscode, setVSCode] = React.useState<string>();

  const navigate = useNavigate();
  const session = React.useContext(SessionContext);

  const { t } = useTranslation();
  const email_input: string = t('Email');
  const password_input: string = t('Password');

  window.onmessage = function (e) {
    if (e.data && e.data.includes && e.data.includes('vscodeURL')) {
      setVSCode(e.data.split('=')[1]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const user = await AuthService.login(props.email.toLowerCase(), password);

      if (vscode) {
        //@ts-ignore
        // redirect to the VSCode URL
        window.location = vscode;
      }

      session.setUser(user);
      session.setLoggedIn(true);
      const account = await AccountService.getAccountInfo();
      session.setPreferences({ ...initialPreferences, ...account.preferences });
      session.setPageVisits(account.pageVisits ?? {});
      setIsLoading(false);
      setValidating(true);
      const linkFromSessionStorage = sessionStorage.getItem('directedURL');
      if (!user.welcomeForm) {
        navigate('/welcome');
      } else {
        // send to the previous link
        linkFromSessionStorage
          ? navigate(
              '/' + linkFromSessionStorage.split('/').slice(3).join('/'),
              props.isFirstLogin ? { state: { isFirst: true } } : { replace: true }
            )
          : navigate('/homepage');
      }
    } catch (error: any) {
      props.setgettingValueError(true);
      props.setValueError(error.message);
      setIsLoading(false);
    }
  };

  function handlePassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
    validatePassword(event.target.value);
  }

  const validateEmail = (nextEmail: string): boolean => {
    const validEmail = nextEmail.length > 0;
    const validPassword = password.length > 0;
    setFormValidity(validEmail && validPassword);
    return validEmail;
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    validateEmail(e.target.value);
    props.setEmail(e.target.value);
  };

  useEffect(() => {
    validateEmail(props.email);
  }, [props.email, password]);

  return (
    <>
      <Form onSubmit={handleSubmit} validated={validating}>
        <Container className={`text-white ${styles.LoginSignInText}`}>
          {t('login_page.SignIn')}
        </Container>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label className="text-secondary">{t('Email')}</Form.Label>
          <Form.Control
            name="formEmail"
            className={`bg-dark border-secondary text-white-50 ${styles.loginPlaceholder}`}
            onChange={handleEmailChange}
            type="text"
            placeholder={email_input}
            value={props.email}
            maxLength={50}
          />
        </Form.Group>
        <Form.Group controlId="formPassword">
          <Form.Label className="text-secondary">{t('Password')}</Form.Label>
          <InputGroup className={styles.MainInputGroup}>
            <Form.Control
              name="formPassword"
              className={`bg-dark border-secondary text-white-50 ${styles.passwordStyle} ${styles.loginPlaceholder}`}
              onChange={handlePassword}
              type={passwordInputFieldType}
              placeholder={password_input}
              value={password}
              maxLength={128}
            />
            <InputGroup.Text
              id={'inputEyePassword'}
              className={`bg-dark border-secondary text-white-50 ${styles.inputGroup}`}
              onClick={() => {
                passwordInputFieldType === 'password'
                  ? setPasswordInputFieldType('text')
                  : setPasswordInputFieldType('password');
              }}
            >
              {passwordInputFieldType === 'password' ? (
                <i id={'inputViewPassword'} className="fa-regular fa-eye-slash"></i>
              ) : (
                <i id={'inputnoViewPassword'} className="fa-regular fa-eye"></i>
              )}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Container className={styles.PasswordInputDetails}>
          <Form.Text className={`${styles.LoginExternalLink} ${styles.LoginForgotPasswordLink}`}>
            <Link
              className={`${styles.LoginExternalLink}`}
              id={'forgotPasswordLink'}
              to="/password_reset"
            >
              {t('login_page.ForgotPassword')}
            </Link>
          </Form.Text>
        </Container>
        <Button
          id={'signInButton'}
          variant="primary"
          type="submit"
          className={styles.LoginSubmitButton}
          disabled={isLoading || !formValidity}
        >
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className={isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`}
          />
          {t('login_page.signin')}
        </Button>
      </Form>
    </>
  );
}

export default LoginForm;
