import React from 'react';
import { ActionsManifests, ActionsType } from '../../../../store/types/manifestsAndInputs';
import { changeActionOrder } from '../../../../store/events/root/change_action_order';
import { v4 as uuidv4 } from 'uuid';
import { ActionsDataTypes, FunctionActionType } from '../../../../store/types/actions';
import { addBlock } from '../../../../store/events/actions/add_block';
import { addAction } from '../../../../store/events/actions/add_action';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { replaceEmpty } from '../../../../store/events/actions/replace_empty';
import { DeclareVariableDataInterface } from 'modules/designer/function_editor/function_actions/types/action_data';
import { FrontendActionsData } from 'modules/designer/function_editor/function_actions/types';
import { BackendActionsData } from 'modules/logic_builder/function_editor/function_actions/types';
import { doSelectAction } from 'web_ui/function_editor/store/events/editor/select_action';
import { createIf } from 'web_ui/function_editor/store/events/actions/create_if';
import { createElse } from 'web_ui/function_editor/store/events/actions/create_else';
import { setErrorMessage } from 'modules/modeler/studio/store/actions/studio';
import { BeginCycleDataInterface } from '../../../../../../modules/logic_builder/function_editor/function_actions/types/action_data';

function useHandleDropAction(
  functionActionsIDs: string[],
  manifests: ActionsManifests,
  currState?: FunctionEditorState
) {
  const functionId = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const editorMode = useSelector((state: FunctionEditorState) => state.editor.mode);
  const actions = useSelector((state: FunctionEditorState) => state.actions);
  const dispatch = useDispatch();

  const handleDrop = (e: React.DragEvent, index: number) => {
    if (!functionId) return;
    const draggedIndex = e.dataTransfer.getData('exocode/dragged-index');
    const draggedType = e.dataTransfer.getData('exocode/dragged-type');

    if (draggedIndex !== '') return handleReorder(Number.parseInt(draggedIndex), index);
    if (draggedType !== '') {
      return handleDropNew(draggedType as ActionsType, index);
    }
  };

  const getCopyOfActionData = (type: keyof ActionsDataTypes) => {
    if (editorMode === 'DESIGNER') {
      const copyOfActionData = JSON.stringify(FrontendActionsData[type]);
      return JSON.parse(copyOfActionData);
    } else if (editorMode === 'LOGIC_BUILDER') {
      const copyOfActionData = JSON.stringify(BackendActionsData[type]);
      return JSON.parse(copyOfActionData);
    }
  };

  const handleReorder = (originIndex: number, targetIndex: number) => {
    const originUuid = functionActionsIDs[originIndex];
    const targetUuid =
      targetIndex === functionActionsIDs.length
        ? functionActionsIDs[targetIndex - 1]
        : functionActionsIDs[targetIndex];
    return dispatch(changeActionOrder(functionId, originUuid, targetUuid));
  };

  const handleDropNew = (type: ActionsType, index: number = functionActionsIDs.length) => {
    if (manifests[type].openBlock && manifests[type].category !== 'CONDITION')
      return handleAddBlock(type, index);
    const targetActionId = functionActionsIDs[index];
    if (actions[targetActionId] && actions[targetActionId].type === 'EMPTY')
      return handleReplaceEmpty(type, index);
    if (manifests[type].beginElse && manifests[type].category === 'CONDITION') {
      return handleCreateElse(type, index);
    }
    if (manifests[type].createIf && manifests[type].category === 'CONDITION') {
      return handleCreateIf(type, index);
    }
    return handleAddAction(type, index);
  };

  const handleCreateIf = (type: ActionsType, index: number = functionActionsIDs.length) => {
    if (!functionId) return;
    const openBlockActionUuidIf = uuidv4();
    const closeBlockActionUuidIf = uuidv4();
    const openBlockActionUuidElse = uuidv4();
    const closeBlockActionUuidElse = uuidv4();

    const ifBeginAction: FunctionActionType = {
      uuid: openBlockActionUuidIf,
      type: 'BEGIN_IF',
      order: index,
      data: {
        if: 'a==b',
        then: {
          openBlockActionUuid: openBlockActionUuidIf,
          closeBlockActionUuid: closeBlockActionUuidIf
        },
        else: {
          openBlockActionUuid: openBlockActionUuidElse,
          closeBlockActionUuid: closeBlockActionUuidElse
        }
      }
    };

    const ifBodyAction: FunctionActionType = {
      uuid: uuidv4(),
      type: 'EMPTY',
      order: index + 1,
      data: {}
    };

    const ifEndAction: FunctionActionType = {
      uuid: closeBlockActionUuidIf,
      type: 'END_IF',
      order: index + 2,
      data: {
        openBlockActionUuid: openBlockActionUuidIf
      }
    };

    // finished the IF and initializing the ELSE

    const elseBeginAction: FunctionActionType = {
      uuid: openBlockActionUuidElse,
      type: 'BEGIN_ELSE',
      order: index + 3,
      data: {
        closeBlockActionUuid: closeBlockActionUuidElse
      }
    };

    const elseBodyAction: FunctionActionType = {
      uuid: uuidv4(),
      type: 'EMPTY',
      order: index + 4,
      data: {}
    };

    const elseEndAction: FunctionActionType = {
      uuid: closeBlockActionUuidElse,
      type: 'END_ELSE',
      order: index + 5,
      data: {
        openBlockActionUuid: openBlockActionUuidElse
      }
    };

    dispatch(
      createIf(
        functionId,
        ifBeginAction,
        ifBodyAction,
        ifEndAction,
        elseBeginAction,
        elseBodyAction,
        elseEndAction
      )
    );
  };

  const handleCreateElse = (type: ActionsType, index: number = functionActionsIDs.length) => {
    const openBlockActionUuidElse = uuidv4();
    const closeBlockActionUuidElse = uuidv4();

    const endIf = Object.values(actions)[index - 1];

    if (!endIf || endIf.type !== 'END_IF') {
      dispatch(setErrorMessage('Else can not work without an IF statement'));
      return;
    }

    // creating ELSE
    const elseBeginAction: FunctionActionType = {
      uuid: openBlockActionUuidElse,
      type: 'BEGIN_ELSE',
      order: index,
      data: {
        closeBlockActionUuid: closeBlockActionUuidElse
      }
    };

    const elseBodyAction: FunctionActionType = {
      uuid: uuidv4(),
      type: 'EMPTY',
      order: index + 1,
      data: {}
    };

    const elseEndAction: FunctionActionType = {
      uuid: closeBlockActionUuidElse,
      type: 'END_ELSE',
      order: index + 2,
      data: {
        openBlockActionUuid: openBlockActionUuidElse
      }
    };

    //endIf.data.openBlockActionUuid -> ID from my correct begin_if
    dispatch(
      createElse(
        functionId,
        endIf.data.openBlockActionUuid,
        elseBeginAction,
        elseBodyAction,
        elseEndAction
      )
    );
  };

  const handleAddBlock = (type: ActionsType, index: number = functionActionsIDs.length) => {
    if (!functionId) return;
    const openBlockActionUuid = uuidv4();
    const closeBlockActionUuid = uuidv4();
    const actionData = getCopyOfActionData(type as keyof ActionsDataTypes);
    if (type === 'BEGIN_CYCLE') {
      actionData.uuid = uuidv4();
      actionData.name = actionData.name + index;
    }
    const openBlockAction: FunctionActionType = {
      uuid: openBlockActionUuid,
      type,
      order: index,
      data: actionData
    };
    openBlockAction.data.closeBlockActionUuid = closeBlockActionUuid;

    const closeBlockAction: FunctionActionType = {
      uuid: closeBlockActionUuid,
      type: manifests[type].closeBlockType as ActionsType,
      order: index + 2,
      data: { openBlockActionUuid }
    };

    const emptyAction: FunctionActionType = {
      uuid: uuidv4(),
      type: 'EMPTY',
      order: index + 1,
      data: {}
    };
    dispatch(addBlock(functionId, openBlockAction, emptyAction, closeBlockAction));
  };

  const handleReplaceEmpty = (type: ActionsType, index: number) => {
    const emptyActionId = functionActionsIDs[index];
    const actionData = getCopyOfActionData(type as keyof ActionsDataTypes);
    // it needs to set the uuid if it's a declare variable action
    if (type === 'DECLARE_VARIABLE') {
      (actionData as DeclareVariableDataInterface).uuid = uuidv4();
    }
    dispatch(replaceEmpty(emptyActionId, type, actionData));
  };

  const handleAddAction = (type: ActionsType, index: number = functionActionsIDs.length) => {
    const action: FunctionActionType = { uuid: uuidv4(), type, data: {} };
    const actionData = getCopyOfActionData(type as keyof ActionsDataTypes);
    action.data = actionData;
    if (type === 'DECLARE_VARIABLE') {
      action.data.uuid = uuidv4();
      action.data.name = action.data.name + index;
    }
    dispatch(addAction(functionId, action.uuid, index, action.type, action.data));
  };

  return handleDrop;
}

export default useHandleDropAction;
