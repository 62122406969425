import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ControlProps } from '../index';
import { DefaultLabel } from '../styles';
import ModalComponent from './modal';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';

function ListLinksControl(props: ControlProps) {
  const [show, setShow] = useState(false);
  const rootComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const { t } = useTranslation();

  function handleButtonClick() {
    setShow(true);
  }

  return (
    <>
      <div className={styles.toolbarContainer}>
        <div style={{ display: 'flex' }}>
          <DefaultLabel className={styles.label}>{props.label}</DefaultLabel>
          {props.tooltip && (
            <HelpPopover
              helpBoxProps={{
                description: t(`${props.tooltip}`)!
              }}
              placement="top"
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </div>
        <Button variant="primary" onClick={handleButtonClick}>
          {t('designer.right_side.EditLinks')}
        </Button>
      </div>
      <ModalComponent
        show={show}
        setShow={setShow}
        setting={props}
        idNavbar={rootComponent || ''}
      />
    </>
  );
}

export default ListLinksControl;
