import { Action } from 'redux';
import { CustomComponentStateResponse, ViewStateResponse } from './schemas/ViewState';

export const API_URL = process.env.REACT_APP_API_URL;

export class ViewStateRepo {
  async updateViewStateAction(viewId: string, action: Action) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const body = action;

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    const url = `${API_URL}/states/views/${viewId}/action`;

    return await fetch(url, options).then((response) => response);
  }

  async updateCustomComponentAction(customComponentId: string, action: Action) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const body = action;

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    const url = `${API_URL}/states/custom-components/${customComponentId}/action`;

    return await fetch(url, options).then((response) => response);
  }

  /**
   * Fetch information about a specific view.
   */
  async getViewState(viewId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/states/views/${viewId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ViewStateResponse>;
      throw new Error('Something went wrong while trying to fetch view state.');
    });
  }

  /**
   * Fetch custom component state: components and links.
   */
  async getCustomComponentState(customComponentId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/states/custom-components/${customComponentId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CustomComponentStateResponse>;
      throw new Error(
        'Something went wrong while trying to fetch custom component (for custom component editor).'
      );
    });
  }
}
