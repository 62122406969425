import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';

export const OUTLET_COMPONENT_MANIFEST: ComponentManifest = {
  type: 'OUTLET',
  name: 'Outlet',
  descriptionId: 'OutletDescription',
  description: 'An Outlet should be used in a layout to render the page components.',
  icon: 'fa-solid fa-border-none',
  group: ComponentGroups['LAYOUT'],
  previewHeight: 100,
  previewWidth: 100,
  properties: [
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Padding Top',
      key: 'paddingTop',
      tooltip: 'designer.right_side.controls.components.outlet.numberPaddingTop'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Padding Right',
      key: 'paddingRight',
      tooltip: 'designer.right_side.controls.components.outlet.numberPaddingRight'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Padding Bottom',
      key: 'paddingBottom',
      tooltip: 'designer.right_side.controls.components.outlet.numberPaddingBottom'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Padding Left',
      key: 'paddingLeft',
      tooltip: 'designer.right_side.controls.components.outlet.numberPaddingLeft'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Margin Top',
      key: 'marginTop',
      tooltip: 'designer.right_side.controls.components.outlet.numberMarginTop'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Margin Right',
      key: 'marginRight',
      tooltip: 'designer.right_side.controls.components.outlet.numberMarginRight'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Margin Bottom',
      key: 'marginBottom',
      tooltip: 'designer.right_side.controls.components.outlet.numberMarginBottom'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Margin Left',
      key: 'marginLeft',
      tooltip: 'designer.right_side.controls.components.outlet.numberMarginLeft'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
