import React, { ReactNode } from 'react';
import { TableAction } from '../../../data_table';
import { ListItemType } from '../../types/list_item_type';
import CardTypeRenderer from './card_type_renderer';
import ItemTypeRenderer from './item_type_renderer';
import ImageTypeRenderer from './image_type_renderer';

type RepeaterMockProps = {
  gap: string;
  numberOfColumns?: number;
  type: keyof typeof ListItemType;
  actions?: TableAction[];
  selectable?: boolean;
  componentRenderer: () => ReactNode;
};

function RepeaterMock({
  gap,
  actions,
  selectable,
  componentRenderer,
  numberOfColumns,
  type
}: RepeaterMockProps) {
  const repeatComponent = (numberOfColumns: number) => {
    const repeated = [];
    for (let i = 0; i < numberOfColumns; i++) {
      if (type === ListItemType.CARD) {
        repeated.push(
          <CardTypeRenderer
            numberOfColumns={numberOfColumns}
            actions={actions}
            selectable={selectable}
            componentRenderer={componentRenderer()}
          />
        );
        continue;
      }
      if (type === ListItemType.ITEM) {
        repeated.push(
          <ItemTypeRenderer
            numberOfColumns={numberOfColumns}
            actions={actions}
            selectable={selectable}
            componentRenderer={componentRenderer()}
          />
        );
        continue;
      }
      if (type === ListItemType.IMAGE) {
        repeated.push(<ImageTypeRenderer actions={actions} selectable={selectable} />);
      }
    }
    return repeated;
  };
  return (
    <div className={'d-flex justify-content-center w-100 ps-3 pe-3'} style={{ gap: gap }}>
      {numberOfColumns ? (
        repeatComponent(numberOfColumns)
      ) : (
        <>
          {type === ListItemType.CARD && (
            <CardTypeRenderer
              actions={actions}
              selectable={selectable}
              componentRenderer={componentRenderer()}
            />
          )}
          {type === ListItemType.ITEM && (
            <ItemTypeRenderer
              actions={actions}
              selectable={selectable}
              componentRenderer={componentRenderer()}
            />
          )}
          {type === ListItemType.IMAGE && (
            <ImageTypeRenderer actions={actions} selectable={selectable} />
          )}
        </>
      )}
    </div>
  );
}

export default RepeaterMock;
