import { AuthRepo } from '../repos';
import { SocialNetwork } from '../enum';
import { UserProfile } from '../types/auth_types';

export default class Auth {
  async login(email: string, password: string): Promise<UserProfile> {
    // TODO: check email and password rules
    return AuthRepo.login(email, password);
  }

  async signup(
    email: string,
    username: string,
    pwd: string,
    promotionalCode: string,
    recaptchaToken: string | null
  ): Promise<boolean> {
    return AuthRepo.signUp(email, pwd, username, promotionalCode, recaptchaToken);
  }

  async logout() {
    await AuthRepo.logout();
  }

  async resendVerificationEmail(email: string) {
    await AuthRepo.resendVerificationEmail(email);
  }

  async resetPassword(email: string): Promise<boolean> {
    return await AuthRepo.resetPassword(email);
  }

  async updatePasswordWithToken(token: string, password: string): Promise<boolean> {
    return AuthRepo.updatePasswordWithToken(token, password);
  }

  async updateUserInfo(idUser: number, name: string): Promise<UserProfile> {
    return AuthRepo.updateUserInfo(idUser, name);
  }

  async updateOpenAIKey(idUser: number, openAIKey: string): Promise<UserProfile> {
    return AuthRepo.updateOpenAIKey(idUser, openAIKey);
  }

  async saveWelcomeForm(idUser: number, welcomeForm: string): Promise<UserProfile> {
    return AuthRepo.saveWelcomeForm(idUser, welcomeForm);
  }

  async updatePlan(plan: string): Promise<UserProfile> {
    return AuthRepo.updatePlan(plan);
  }

  async updatePasswordWithOldPassword(
    userId: number,
    newPassword: string,
    oldPassword: string
  ): Promise<boolean> {
    return AuthRepo.updatePasswordWithOldPassword(userId, newPassword, oldPassword);
  }

  async changeEmail(userId: number, newEmail: string): Promise<boolean> {
    return AuthRepo.changeEmail(userId, newEmail);
  }

  async deleteUser(userId: number, isUndo: boolean): Promise<boolean> {
    return AuthRepo.deleteUser(userId, isUndo);
  }

  async getAssociatedAccounts(): Promise<string[]> {
    return AuthRepo.getAssociatedAccounts();
  }

  async disconnectAssociatedAccounts(network: SocialNetwork): Promise<boolean> {
    return AuthRepo.disconnectAssociatedAccounts(network);
  }

  async updatePageVisits(userId: number, pageVisits: Record<string, number>): Promise<UserProfile> {
    return AuthRepo.updatePageVisits(userId, pageVisits);
  }
}
