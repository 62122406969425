import { parameterNameRegex } from '../../../utils/regex';
import { FORBIDDEN_PARAMS_NAMES } from '../store/types/parameters';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../store/types/function_editor_state';

function useValidateParamName() {
  const functionId = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const func = useSelector((state: FunctionEditorState) => state.functions[functionId]);
  const params = useSelector((state: FunctionEditorState) => state.parameters);

  return (name: string) => {
    if (!name) return false;
    if (!parameterNameRegex.test(name)) return false;
    if (FORBIDDEN_PARAMS_NAMES.includes(name.toLowerCase())) return false;
    for (const p of func.parameters) {
      if (params[p].name === name) {
        return false;
      }
    }
    return true;
  };
}

export default useValidateParamName;
