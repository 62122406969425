import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import Dropdown from '../../components/dropdown';
import { ActionInputProps, Argument } from '../../../store/types/manifestsAndInputs';
import { updateAction } from '../../../store/events/actions/update_action';
import { TYPE_PICKER_TYPES } from '../argument_picker/type_picker';
import useGetViews from './hooks/useGetViews';
import useGetExtendedView from './hooks/useGetExtendedView';
import ViewParameters from './view_parameters';
import { Spinner } from 'react-bootstrap';

export type AllowedModes = 'page' | 'modal' | 'layout';

type ViewPickerData = {
  uuid: string;
  params?: { [key: string]: Argument };
  properties?: { [key: string]: Argument };
};

function ViewPicker({
  label,
  placeholder,
  actionUuid,
  dataKey,
  options,
  isFromSideBar
}: ActionInputProps) {
  const data = useSelector(
    (state: FunctionEditorState) => state?.actions[actionUuid]?.data[dataKey]
  ) as ViewPickerData;
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();

  const views = useGetViews(options.mode as AllowedModes);
  const view = useGetExtendedView(selected, options.mode as AllowedModes);

  useEffect(() => {
    if (!data?.uuid) return;
    setSelected(data.uuid);
  }, []);

  useEffect(() => {
    const isSameView = view?.uuid && data?.uuid && view.uuid === data.uuid;
    if (isSameView) return;
    const params = {} as { [key: string]: Argument };
    if (view?.params) {
      for (const uuid of Object.keys(view.params)) {
        params[uuid] = {
          type: TYPE_PICKER_TYPES.VAR,
          value: ''
        };
      }
    }
    let newData = {} as ViewPickerData;
    if (options.mode === 'page') {
      newData = {
        uuid: view.uuid,
        params: { ...params }
      };
    }
    if (options.mode === 'modal') {
      newData = {
        uuid: view.uuid,
        properties: { ...params }
      };
    }
    if (options.mode === 'layout') {
      newData = { uuid: view.uuid };
    }
    newData.uuid && dispatch(updateAction(actionUuid, dataKey, newData));
  }, [view, actionUuid]);

  const handleChangeSelected = (uuid: string) => {
    if (!uuid) return;
    setSelected(uuid);
  };

  function handleParamChange(paramId: string, value: Argument) {
    if (!paramId) return;
    if (options.mode === 'page') {
      const params = {
        ...data.params,
        [paramId]: { value: value.value, type: value.type, objectNode: value.objectNode }
      };
      dispatch(updateAction(actionUuid, dataKey, { ...data, params }));
    }
    if (options.mode === 'modal') {
      const properties = { ...data.properties, [paramId]: value };
      dispatch(updateAction(actionUuid, dataKey, { ...data, properties }));
    }
  }

  if (Object?.keys(views).length <= 0)
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Spinner />
      </div>
    );

  return (
    <div className={isFromSideBar ? '' : 'd-flex flex-column gap-2'}>
      <div className={isFromSideBar ? '' : 'd-flex align-items-end'}>
        <Dropdown
          items={Object.values(views) ?? []}
          placeholder={placeholder}
          label={label}
          value={views[data.uuid]}
          handleChange={handleChangeSelected}
          isFromSideBar={isFromSideBar}
        />
      </div>
      {options?.mode === 'page' && view && view?.params && Object?.keys(view.params).length > 0 && (
        <ViewParameters
          params={view.params}
          paramsValues={data.params ?? ({} as { [key: string]: Argument })}
          actionUuid={actionUuid}
          handleChange={handleParamChange}
          mode={'page'}
          isFromSideBar={isFromSideBar}
        />
      )}
      {options?.mode === 'modal' &&
        view &&
        view?.params &&
        Object?.keys(view.params).length > 0 && (
          <ViewParameters
            params={view.params}
            paramsValues={data.properties ?? ({} as { [key: string]: Argument })}
            actionUuid={actionUuid}
            handleChange={handleParamChange}
            mode={'modal'}
            isFromSideBar={isFromSideBar}
          />
        )}
    </div>
  );
}

export default ViewPicker;
