import { Action } from 'redux';
import { ActionsState, FunctionActionType } from '../../types/actions';

export const CREATE_IF = 'CREATE_IF';

export interface CreateIfAction extends Action {
  type: 'CREATE_IF';
  payload: {
    functionId: string;
    ifBeginAction: FunctionActionType;
    ifBodyAction: FunctionActionType;
    ifEndAction: FunctionActionType;
    elseBeginAction: FunctionActionType;
    elseBodyAction: FunctionActionType;
    elseEndAction: FunctionActionType;
  };
}

// functionUuid: string,
// openBlockAction: FunctionActionType,
// emptyAction: FunctionActionType,
// closeBlockAction: FunctionActionType

export const createIf = (
  functionId: string,
  ifBeginAction: FunctionActionType,
  ifBodyAction: FunctionActionType,
  ifEndAction: FunctionActionType,
  elseBeginAction: FunctionActionType,
  elseBodyAction: FunctionActionType,
  elseEndAction: FunctionActionType
): CreateIfAction => ({
  type: CREATE_IF,
  payload: {
    functionId,
    ifBeginAction,
    ifBodyAction,
    ifEndAction,
    elseBeginAction,
    elseBodyAction,
    elseEndAction
  }
});

export function doCreateIf(state: ActionsState, action: CreateIfAction): ActionsState {
  const {
    ifBeginAction,
    ifBodyAction,
    ifEndAction,
    elseBeginAction,
    elseBodyAction,
    elseEndAction
  } = action.payload;

  // if
  state[ifBeginAction.uuid] = ifBeginAction;
  state[ifBodyAction.uuid] = ifBodyAction;
  state[ifEndAction.uuid] = ifEndAction;

  // else
  state[elseBeginAction.uuid] = elseBeginAction;
  state[elseBodyAction.uuid] = elseBodyAction;
  state[elseEndAction.uuid] = elseEndAction;

  return state;
}
