import React from 'react';

interface LinkFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function LinkFormatterPreview({ formatterParams, value }: LinkFormatterPreviewProps) {
  return <a href="#">{formatterParams.label ? formatterParams.label : value}</a>;
}

export default LinkFormatterPreview;
