import React from 'react';
import { driver, DriveStep } from 'driver.js';
import 'driver.js/dist/driver.css';
import { WALKTHROUGH_STEPS } from './constants';
import { applyStepActions } from './actions';
import { Trans } from 'react-i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { FaInfoCircle } from 'react-icons/fa';

export type WalkthroughContext =
  | 'modeler'
  | 'logic'
  | 'designer'
  | 'dashboard'
  | 'assistant'
  | 'modelerAdvandedTableModal'
  | 'functionEditor'
  | 'endpointEditor';

type WalkthroughProps = {
  customSteps?: DriveStep[];
  prevBtnText: string;
  nextBtnText: string;
  doneBtnText: string;
  context: WalkthroughContext;
  onClose?: () => void;
  // Actions map: element id -> action.
  actions?: Record<string, () => void>;
};

const Step1Translated = (step1Description: string): JSX.Element => {
  return (
    <>
      <Trans i18nKey={step1Description} />
      <br />
      <Trans
        i18nKey="walkthrough.step0"
        components={{
          icon: <FaInfoCircle />
        }}
      >
        By the way, you can hit the <FaInfoCircle /> button anytime to bring back the step-by-step
        guide.
      </Trans>
    </>
  );
};

export function startWalkthrough(props: WalkthroughProps, triggerElementId?: string) {
  let steps = [];

  if (props.customSteps) {
    steps = [...props.customSteps];
  } else {
    steps = [...(WALKTHROUGH_STEPS[props.context] ?? [])];

    const descriptionHtml = renderToStaticMarkup(
      Step1Translated(steps[0].popover?.description ?? '')
    );

    const firstStep: DriveStep = {
      element: steps[0].element,
      popover: {
        title: steps[0].popover?.title ?? '',
        description: descriptionHtml
      }
    };
    steps[0] = firstStep;
  }

  const driverInstance = driver({
    allowClose: true,
    onCloseClick: () => {
      props.onClose && props.onClose();
      driverInstance.destroy();
    },
    onDestroyStarted: () => {
      props.onClose && props.onClose();
      driverInstance.destroy();
    },
    showProgress: true,
    prevBtnText: props.prevBtnText,
    nextBtnText: props.nextBtnText,
    doneBtnText: props.doneBtnText,
    progressText: 'Step {{current}} of {{total}}',
    steps: props.actions
      ? applyStepActions(() => driverInstance.moveNext(), props.actions, steps)
      : steps
  });

  driverInstance.drive();
  return driverInstance;
}
