import { useEffect } from 'react';
import { FunctionEditorState } from '../../../../web_ui/function_editor/store/types/function_editor_state';
import { useDispatch } from 'react-redux';
import { setInitialFunctionEditorState } from '../../../../web_ui/function_editor/store/events/root/set_initial_state';

type StoreUpdaterProps = {
  moduleId: string;
  functionId: string;
  state: FunctionEditorState;
};

function StoreUpdater({ moduleId, functionId, state }: StoreUpdaterProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialFunctionEditorState(state, 'LOGIC_BUILDER'));
  }, [dispatch, moduleId, functionId, state]);

  return null;
}

export default StoreUpdater;
