import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';
import { ModuleInfo, ModuleSettings } from 'modules/dashboard/types/global_types';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_MODULES } from 'modules/project/types';

type Props = {
  module: ModuleInfo;
  choosingTheStorage: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  dataToAssetsModule: ModuleSettings;
  s3AccessKey: string;
  setS3AccessKey: React.Dispatch<React.SetStateAction<string>>;
  secretKey: string;
  setSecretKey: React.Dispatch<React.SetStateAction<string>>;
  region: string;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  bucketName: string;
  setBucketName: React.Dispatch<React.SetStateAction<string>>;
  blockSave: boolean;
  setBlockSave: React.Dispatch<React.SetStateAction<boolean>>;
  clearS3Fields: () => void;
  submitTheS3: () => void;
};

function EditModuleTemplate(props: Props) {
  const { t } = useTranslation();

  const verifying = (str: string): string => {
    if (str === 's3') {
      return 'S3';
    } else if (str === 'blob') {
      return 'Blob';
    } else if (str === 'file_system') {
      return 'File System';
    } else {
      return '';
    }
  };

  return (
    <div className={styles.appSettingsDialog}>
      {props.module.isAssets && (
        <Form.Group>
          <Form.Label>{t('appResume.module.storageType')}</Form.Label>
          <Form.Select
            className={styles.marginBetweenContainers}
            onChange={props.choosingTheStorage}
            value={verifying(props.dataToAssetsModule.value)}
          >
            <option disabled selected hidden>
              Select one
            </option>
            {Object.values(AVAILABLE_MODULES).map((moduleType, index) => (
              <option key={index} value={moduleType}>
                {moduleType}
              </option>
            ))}
          </Form.Select>
          {props.dataToAssetsModule.value === 's3' && (
            <div>
              <Form.Group className={styles.marginBetweenContainers}>
                <Form.Label>Access Key</Form.Label>
                <Form.Control
                  required
                  value={props.s3AccessKey}
                  onChange={(ev) => props.setS3AccessKey(ev.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={props.blockSave}
                />
              </Form.Group>
              <Form.Group className={styles.marginBetweenContainers}>
                <Form.Label>Secret Key</Form.Label>
                <Form.Control
                  required
                  value={props.secretKey}
                  onChange={(ev) => props.setSecretKey(ev.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={props.blockSave}
                />
              </Form.Group>
              <Form.Group className={styles.marginBetweenContainers}>
                <Form.Label>Region</Form.Label>
                <Form.Control
                  required
                  value={props.region}
                  onChange={(ev) => props.setRegion(ev.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={props.blockSave}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: '1rem' }}>
                <Form.Label>Bucket Name</Form.Label>
                <Form.Control
                  required
                  value={props.bucketName}
                  onChange={(ev) => props.setBucketName(ev.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={props.blockSave}
                />
              </Form.Group>
              <div className={styles.containerButtonS3Form}>
                <Button variant="danger" onClick={props.clearS3Fields}>
                  Clear
                </Button>
                <Button onClick={props.submitTheS3} disabled={props.blockSave}>
                  Save
                </Button>
              </div>
            </div>
          )}
        </Form.Group>
      )}
      {props.module.isAuth && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>{t('appResume.module.storageType')}</label>
          <Button
            id={'authenticationModule'}
            className={`${styles.clickedAndChoosenAssets}`}
            style={{ marginTop: '0.5rem' }}
          >
            <Icon iconName="shield-halved" /> Authentication
          </Button>
        </div>
      )}
      {props.module.isAssets === false && props.module.isAuth === false && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>{t('appResume.module.storageType')}</label>
          <Button
            id={'customModule'}
            className={`${styles.clickedAndChoosenAssets}`}
            style={{ marginTop: '0.5rem' }}
          >
            <Icon iconName="code" /> Custom
          </Button>
        </div>
      )}
    </div>
  );
}

export default EditModuleTemplate;
