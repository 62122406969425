import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';

type UserFeedbackProps = {
  show: boolean;
  onClose: () => void;
  userCreationTime: Date;
};

export default function UserFeedback(props: UserFeedbackProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentForm, setCurrentForm] = useState<string | null>(null);
  const [iframeTitle, setIframeTitle] = useState<string>('');

  useEffect(() => {
    if (!props.show) return;
    const accountCreationDate = new Date(props.userCreationTime);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - accountCreationDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let formUrl: string | null = null;
    if (diffDays <= 4) {
      formUrl = 'https://forms.office.com/e/DSdwnxBrUS?&lang=en-US';
      setIframeTitle('User Feedback Form - Days 1 to 4');
    } else if (diffDays <= 10) {
      formUrl = 'https://forms.office.com/e/vpS8p8ekbS?&lang=en-US';
      setIframeTitle('User Feedback Form - Days 5 to 10');
    } else if (diffDays <= 20) {
      formUrl = 'https://forms.office.com/e/G2s4drp5Vx?&lang=en-US';
      setIframeTitle('User Feedback Form - Days 11 to 20');
    } else {
      formUrl = 'https://forms.office.com/e/qyKGdE65Xg?&lang=en-US';
      setIframeTitle('User Feedback Form - After Day 21');
    }

    setCurrentForm(formUrl);
  }, [props.userCreationTime, props.show]);

  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={() => {
        props.onClose();
      }}
      centered
      scrollable
    >
      <div className={styles.feedbackTitle}>
        <p>Feedback</p>
        <button className={styles.close}>
          <Icon iconName="x" onClick={() => props.onClose()}></Icon>
        </button>
      </div>
      {currentForm && (
        <iframe
          title={iframeTitle}
          height="480px"
          src={`${currentForm}&embed=true`}
          frameBorder="0"
          style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
          allowFullScreen
        ></iframe>
      )}
    </Modal>
  );
}
