import { Action } from 'redux';
import { ActionsState } from '../../types/actions';
import { ActionsType } from '../../types/manifestsAndInputs';

export const ADD_ACTION = 'ADD_ACTION';

export interface AddActionAction extends Action {
  type: 'ADD_ACTION';
  payload: {
    functionUuid: string;
    uuid: string;
    order: number;
    type: ActionsType;
    data: any;
  };
}

export const addAction = (
  functionUuid: string,
  uuid: string,
  order: number,
  type: ActionsType,
  data: any
): AddActionAction => ({
  type: ADD_ACTION,
  payload: { functionUuid, uuid, order, type, data }
});

export function doAddAction(state: ActionsState, action: AddActionAction): ActionsState {
  const { uuid, type, data } = action.payload;
  state[uuid] = { uuid, type, data };
  return state;
}
