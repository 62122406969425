import { ConnectionCoordinate } from 'modules/modeler/studio/frames/table';
import {
  ComponentMap,
  EnumColumnID,
  Index,
  IndexType,
  cascadeType,
  externalRelationshipInfo,
  fetchType,
  fkColumnsMapper
} from 'modules/modeler/types';
import { v4 as uuidv4 } from 'uuid';

export const EnumTemplate = {
  uuid: 'NEW_ENUM',
  type: 'ENUM',
  content: {
    data: {
      name: 'NewEnum',
      description: '',
      columns: []
    }
  },
  posX: 300,
  posY: 300
};

export const ColumnTemplate = {
  uuid: 'NEW_COLUMN',
  tableUUID: 'TABLE',
  name: 'ColumnName',
  type: 'VARCHAR',
  description: '',
  size: 45,
  nullable: true,
  defaultData: '',
  isPK: false,
  isFK: false,
  extra: {}
};

export const TableTemplate = {
  uuid: 'NEW_TABLE',
  type: 'TABLE',
  content: {
    data: {
      name: 'NewTable',
      description: '',
      plural: '',
      columns: [],
      primaryKey: [],
      foreignKey: [],
      indexes: [],
      relationships: []
    }
  },
  posX: 300,
  posY: 300
};

export function makeColumn(
  tableUUID: string,
  name: string,
  type: string,
  columnOrder: number,
  description?: string,
  nullable?: boolean,
  defaultData?: string,
  isPK?: boolean,
  isFK?: boolean,
  uuid?: string,
  enumUUID?: string,
  fkColumns?: string[],
  properties?: Record<string, string>
) {
  const fkColumnsMapper: fkColumnsMapper = {};
  fkColumns &&
    fkColumns.map((tableUUID) => {
      fkColumnsMapper[tableUUID] = uuidv4();
      return null;
    });
  const newColumn = {
    uuid: uuid ? uuid : uuidv4(),
    tableUUID: tableUUID,
    name: name,
    description: description ?? '',
    type: type,
    nullable: nullable && !isPK ? nullable : false,
    defaultData: defaultData ?? '',
    properties,
    isPK: isPK ? isPK : false,
    isFK: isFK ? isFK : false,
    enumUUID: enumUUID ? enumUUID : null,
    fkColumns: fkColumns ? fkColumnsMapper : undefined,
    columnOrder: columnOrder
  };

  return newColumn;
}

export function makeRelationship(
  fromTableID: string,
  fromTableName: string,
  toTableID: string,
  components: ComponentMap,
  cascadeType: cascadeType[],
  connectionPoints: ConnectionCoordinate,
  type?: string,
  bidirectional?: boolean,
  fetchTypes?: fetchType,
  description?: string,
  info?: externalRelationshipInfo
) {
  return {
    id: uuidv4(),
    type: type ? type : 'ONE2MANY',
    name: fromTableName,
    content: connectionPoints,
    description: description ?? '',
    from: fromTableID,
    to: toTableID,
    components: components,
    bidirectional: bidirectional ?? true,
    fetchType: fetchTypes ?? 'LAZY',
    cascadeTypes: cascadeType,
    info: info
  };
}

export function makeIndex(name: string, type: IndexType): Index {
  return {
    id: uuidv4(),
    name: name,
    description: '',
    type: type,
    columns: {}
  };
}

export function makeEnumColumn(
  literalValue: string,
  ordinalValue: number,
  enumID: EnumColumnID,
  description: string,
  columnOrder: number
) {
  return {
    id: uuidv4(),
    idEnum: enumID,
    literalValue: literalValue,
    ordinalValue: ordinalValue,
    description: description,
    columnOrder: columnOrder
  };
}
