import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  forwardRef,
  useEffect,
  useState
} from 'react';
import { TableUUID } from '../../../../types';
import { Form } from 'react-bootstrap';
import { changeTableName } from '../../../store/actions/frames';
import { useDispatch, useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from '../../../store/actions/studio';
import { KEYS } from 'utils/keys';
import styles from './styles.module.css';

type TableHeaderProps = {
  tableID: TableUUID;
  tableName: string;
};

export const TableHeader = forwardRef<HTMLInputElement, TableHeaderProps>(
  ({ tableID, tableName }, ref) => {
    const [name, setName] = useState('');
    const tables = useSelector((state: DatabaseStudioState) => state.tables);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    function setErrorMessageIdentifier(message: string): void {
      if (!message) return;
      dispatch(setErrorMessage(message));
    }

    useEffect(() => {
      setName(tableName);
    }, [tableName]);

    const handleChangeName = (): void => {
      if (!tableID) return;
      if (tableName === name) {
        return;
      }

      if (!name || name.length < 2) {
        setErrorMessageIdentifier('modeler.NameTooShortMessage');
        setName(tableName);
      } else if (checkNameExists(name)) {
        setErrorMessageIdentifier('modeler.ExistingNameMessage');
        setName(tableName);
      } else {
        dispatch(changeTableName(tableID, name));
      }
    };

    const checkNameExists = (name: string): boolean => {
      const findTable = Object.keys(tables).find((tableID: TableUUID) => {
        return tables[tableID].content.data.name === name;
      });
      if (findTable) {
        return true;
      } else {
        return false;
      }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.value.length > 64) {
        setErrorMessageIdentifier('modeler.NameTooLongMessage');
      } else {
        setName(event.target.value);
      }
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      handleChangeName();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === KEYS.ENTER) {
        handleChangeName();
      }
    };

    return (
      <div className={'mb-4 d-flex justify-content-between align-items-center'}>
        <Form.Group controlId="formName" style={{ flex: '1' }}>
          <Form.Control
            ref={ref}
            value={name}
            type="text"
            size="sm"
            className={`border-0 p-0 ps-2 text-body-emphasis ${styles.TableName}`}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
          />
        </Form.Group>

        <Form.Group
          className="pe-2 d-flex text-body-tertiary align-items-start"
          controlId="columnCategory"
        >
          <Form.Label className={styles.SmallFont}>{t('modeler.Entity')}</Form.Label>
        </Form.Group>
      </div>
    );
  }
);
