import { ComponentType, ReactNode } from 'react';
import { Widget, WidgetType } from './types';
import { stickyNoteTemplate } from './sticky_note/template';
import StickyNoteWidget, { StickyNoteWidgetProps } from './sticky_note/sticky_note_widget';
import { WidgetWrapper } from './widget_wrapper';

export const WIDGETS_LIST: { [key: string]: ReactNode } = {
  [WidgetType.STICKY_NOTE]: WidgetWrapper(StickyNoteWidget as ComponentType<StickyNoteWidgetProps>)
};

export const WIDGETS_TEMPLATE: { [key: string]: Widget } = {
  [WidgetType.STICKY_NOTE]: stickyNoteTemplate
};
