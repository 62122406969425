import { SupportType } from 'routes/settings/sub_routes/support';

export const API_URL = process.env.REACT_APP_API_URL;

export default class SupportRepo {
  async contactSupport(support: SupportType): Promise<void> {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    const options: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(support)
    };
    const request = new Request(`${API_URL}/support`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return;
      }
      const message = await response.text();
      throw new Error(message);
    });
  }
}
