import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  DATETIME_MANIFEST,
  DateFormats,
  FormatterManifest,
  FormatterProps,
  FormatterTypes,
  Timezones
} from '../../types';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../../../components/Popover';
import HelpIcon from '../../../components/HelpIcon';

function DatetimeFormatter(props: FormatterProps) {
  const { t } = useTranslation();
  const [datetimeParams, setDatetimeParams] = useState<FormatterManifest>(DATETIME_MANIFEST);
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.DATETIME) {
      props.onChange && props.onChange(props.index, 'formatterParams', DATETIME_MANIFEST);
    }
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: string) {
    const updatedDatetimeParams = { ...datetimeParams };
    updatedDatetimeParams[field] = e.target.value;
    setDatetimeParams(updatedDatetimeParams);
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedDatetimeParams);
  }

  return (
    <div className="mb-3 border-bottom ">
      <div className="mb-3">
        <Form.Group>
          <Form.Label column>
            {t('designer.right_side.FormatterControls.DatetimeFormatterFormat')}
          </Form.Label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.FormatterControls.DatetimeFormatterFormatTitle') ||
                'designer.right_side.FormatterControls.DatetimeFormatterFormatTitle',
              description:
                t('designer.right_side.FormatterControls.DatetimeFormatterFormatDescription') ||
                'designer.right_side.FormatterControls.DatetimeFormatterFormatDescription',
              note: [
                t('designer.right_side.FormatterControls.DatetimeFormatterFormatNote01') ||
                  'designer.right_side.FormatterControls.DatetimeFormatterFormatNote01'
              ]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
          <Form.Select
            placeholder="dd/mm/yyyy"
            value={props.value && props.value.outputFormat}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              handleChange(e, 'outputFormat');
            }}
          >
            <option defaultChecked value={'dd/mm/yyyy'}>
              {'09/11/2023'}
            </option>
            {Object.keys(DateFormats).map((key) => (
              <option key={key} value={key}>
                {DateFormats[key as keyof typeof DateFormats]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label column>
            {t('designer.right_side.FormatterControls.DatetimeFormatterTimezone')}
          </Form.Label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.FormatterControls.DatetimeFormatterTimezoneTitle') ||
                'designer.right_side.FormatterControls.DatetimeFormatterTimezoneTitle',
              description:
                t('designer.right_side.FormatterControls.DatetimeFormatterTimezoneDescription') ||
                'designer.right_side.FormatterControls.DatetimeFormatterTimezoneDescription'
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
          <Form.Select
            placeholder="timezone"
            value={props.value && props.value.timezone}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              handleChange(e, 'timezone');
            }}
          >
            <option defaultChecked value={currentTimezone}>
              {currentTimezone}
            </option>
            {Object.values(Timezones).map(
              (timezone) =>
                currentTimezone !== timezone && (
                  <option key={timezone} value={timezone}>
                    {timezone}
                  </option>
                )
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label column>
            {t('designer.right_side.FormatterControls.DatetimeFormatterInvalidPlaceholder')}
          </Form.Label>
          <HelpPopover
            helpBoxProps={{
              title:
                t(
                  'designer.right_side.FormatterControls.DatetimeFormatterInvalidPlaceholderTitle'
                ) ||
                'designer.right_side.FormatterControls.DatetimeFormatterInvalidPlaceholderTitle',
              description:
                t(
                  'designer.right_side.FormatterControls.DatetimeFormatterInvalidPlaceholderDescription'
                ) ||
                'designer.right_side.FormatterControls.DatetimeFormatterInvalidPlaceholderDescription',
              note: [
                t(
                  'designer.right_side.FormatterControls.DatetimeFormatterInvalidPlaceholderNote01'
                ) ||
                  'designer.right_side.FormatterControls.DatetimeFormatterInvalidPlaceholderNote01'
              ]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
          <Form.Control
            type="text"
            placeholder="mailto:"
            value={props.value && props.value.invalidPlaceholder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e, 'invalidPlaceholder');
            }}
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default DatetimeFormatter;
