import React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
type SortMockProps = {
  sortable: boolean;
};

function SortMock({ sortable }: SortMockProps) {
  if (!sortable) return null;

  return (
    <InputGroup className={'w-auto float-end'}>
      <select className={'form-control'} style={{ appearance: 'none' }}>
        <option>Sort by...</option>
      </select>
      <Button variant={'light'}>
        <span className={'fa fa-filter text-primary'} />
      </Button>
    </InputGroup>
  );
}

export default SortMock;
