import { ObjectsState } from '../index';
import produce from 'immer';
import { ADD_OBJECT, AddObjectAction, SET_OBJECTS, SetObjectsAction } from '../actions/schemas';

type SchemasActions = SetObjectsAction | AddObjectAction;

export const objectsReducer = (state: ObjectsState = {}, action: SchemasActions): ObjectsState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_OBJECTS:
        return doSetObjects(draft, action);
      case ADD_OBJECT:
        return doAddObject(draft, action);
      default:
        return draft;
    }
  });
};

function doSetObjects(state: ObjectsState, action: SetObjectsAction): ObjectsState {
  return action.payload.objects;
}

function doAddObject(state: ObjectsState, action: AddObjectAction): ObjectsState {
  if (action.payload.object.uuid) state[action.payload.object.uuid] = action.payload.object;
  return state;
}
