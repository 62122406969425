import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Column, EnumColumn, EnumFrame, Index, Relationship, Table } from '../../types';
import { WidgetsState } from 'modules/designer/studio/store';
import { setInitialModelerState } from './actions/root';

type StudioUpdaterProps = {
  module_id: string;
  schema_id: string;
  tables: Table[];
  columns: Column[];
  indexes: Index[];
  enums: EnumFrame[];
  enumColumns: EnumColumn[];
  relationships: Relationship[];
  widgets: WidgetsState;
};

function StoreUpdater(props: StudioUpdaterProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const state: any = {};

    state.enum_columns = {};
    props.enumColumns.forEach((enumColumn: EnumColumn) => {
      state.enum_columns[enumColumn.id] = enumColumn;
    });

    state.enums = {};
    props.enums.forEach((enumFrame: EnumFrame) => {
      state.enums[enumFrame.uuid] = enumFrame;
    });

    state.indexes = {};
    props.indexes.forEach((index: Index) => {
      state.indexes[index.id] = index;
    });

    state.widgets = { ...props.widgets };

    state.columns = {};
    props.columns.forEach((column: Column) => {
      state.columns[column.uuid] = column;
    });

    state.relationships = {};
    props.relationships.forEach((relationship: Relationship) => {
      state.relationships[relationship.id] = relationship;
    });

    state.tables = {};
    props.tables.forEach((table: Table) => {
      state.tables[table.uuid] = table;
    });

    state.studio = {
      history: {
        undoStack: [],
        redoStack: [],
        lastValidEntry: null
      },
      selectedFrame: null,
      selectedConnector: null,
      schema_id: props.schema_id,
      module_id: props.module_id,
      errorMessage: ''
    };

    dispatch(setInitialModelerState(state));
  }, [
    dispatch,
    props.columns,
    props.enumColumns,
    props.enums,
    props.indexes,
    props.module_id,
    props.relationships,
    props.schema_id,
    props.tables,
    props.widgets
  ]);

  return null;
}

export default StoreUpdater;
