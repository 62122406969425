import React from 'react';
import { Navigate } from 'react-router-dom';
import useSession from 'hooks/useSession';

type SessionRedirectProps = {
  to: string;
  children: JSX.Element;
};

export const SessionRedirect = (props: SessionRedirectProps) => {
  const [session, loading] = useSession();

  if (loading) return null;
  if (session.isLoggedIn) return <Navigate to={props.to} />;

  return <>{props.children}</>;
};
