import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

export const ACCORDION_MANIFEST: ComponentManifest = {
  type: 'ACCORDION',
  name: 'Accordion',
  descriptionId: 'AccordionDescription',
  description: 'It is a component to insert accordions.',
  icon: 'fa-solid fa-sheet-plastic',
  group: ComponentGroups['CONTAINER'],
  allowDrop: true,
  previewHeight: 200,
  previewWidth: 200,
  properties: [
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.accordion.visible'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'ActiveKey',
      key: 'activeKey',
      tooltip: 'designer.right_side.controls.components.accordion.text'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.accordion.textArea'
    },
    {
      controlType: ControlsTypes.LIST,
      controlLabel: 'TransItems',
      key: 'items',
      tooltip: 'designer.right_side.controls.components.accordion.list'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Background',
      key: 'background',
      controlOptions: [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark'
      ],
      tooltip: 'designer.right_side.controls.components.accordion.selectBg'
    },
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('TextColor'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.accordion.colorPicker'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.accordion.number'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
