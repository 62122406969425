import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Table } from 'react-bootstrap';
import { FunctionExtended, FunctionParameter } from 'modules/logic_builder/types';
import { FunctionService } from 'modules/logic_builder/services';
import { FunctionParameterCreatorDialog } from '../function_parameter_creator_dialog';
import { FunctionParameterEditorDialog } from '../function_parameter_editor_dialog';
import { EnumFrame } from 'modules/modeler/types';
import Icon from 'web_ui/icon';

// todo: We need an updated list of variables as props here, synced with the other tab.
type LogicTabProps = {
  functionExtended: FunctionExtended;
  setFunctionExtended: (fn: FunctionExtended) => void;
  enumsList: EnumFrame[];
};

export function LogicTab(props: LogicTabProps) {
  const [parametersList, setParametersList] = useState<FunctionParameter[]>([]);

  const [showAddParameterDialog, setShowAddParameterDialog] = useState(false);
  const [showParameterEditorDialog, setShowParameterEditorDialog] = useState<FunctionParameter>();

  const fetchParameters = useCallback(async () => {
    if (!props.functionExtended.uuid) return;

    await FunctionService.getParameters(props.functionExtended.uuid).then((parameters) => {
      setParametersList(parameters);
    });
  }, [props.functionExtended.uuid]);

  useEffect(() => {
    fetchParameters();
  }, [fetchParameters]);

  return (
    <>
      <div className={styles.TableWrapper}>
        <div className={styles.TableTitle}>
          Parameters
          <Button
            id={'addParametersButton'}
            variant="primary"
            className="btn-sm"
            onClick={() => setShowAddParameterDialog(true)}
          >
            Add
          </Button>
        </div>
        <div>
          <Table size="sm" className={styles.Table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Required</th>
              </tr>
            </thead>
            <tbody>
              {parametersList
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .sort((a, b) => {
                  if (a.type < b.type) {
                    return -1;
                  }
                  if (a.type > b.type) {
                    return 1;
                  }
                  return 0;
                })
                .map((parameter) => (
                  <tr
                    key={parameter.uuid}
                    className={styles.Parameter}
                    onClick={() => setShowParameterEditorDialog(parameter)}
                  >
                    <td>{parameter.name}</td>
                    {parameter.list ? (
                      <td>{`List<${parameter.type}>`}</td>
                    ) : (
                      <td>{parameter.type}</td>
                    )}
                    <td>{parameter.required ? <Icon iconName="check" /> : ''}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>

      {props.functionExtended.uuid && (
        <FunctionParameterCreatorDialog
          show={showAddParameterDialog}
          onClose={() => setShowAddParameterDialog(false)}
          fetchParameters={fetchParameters}
          functionId={props.functionExtended.uuid}
          enumsList={props.enumsList}
        />
      )}
      {props.functionExtended.uuid && (
        <FunctionParameterEditorDialog
          parameter={showParameterEditorDialog}
          fetchParameters={fetchParameters}
          functionId={props.functionExtended.uuid}
          show={showParameterEditorDialog != null}
          onClose={() => setShowParameterEditorDialog(undefined)}
          enumsList={props.enumsList}
        />
      )}
    </>
  );
}
