import React from 'react';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import AddListIcon from './add_list_icon';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { FunctionVariableType } from '../../../../../web_ui/function_editor/store/types/variables';
import { FunctionParameterType } from '../../../../../web_ui/function_editor/store/types/parameters';
import { FRONTEND_VARIABLE_TYPES, FrontendProperty } from '../../../types';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { ObjectItemType } from '../../../../../web_ui/function_editor/store/types/object_items';
import { t } from 'i18next';

function filterByListDataType(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: keyof typeof FRONTEND_VARIABLE_TYPES
): boolean {
  const list = state.actions[actionUuid].data?.list;
  if (!list) return false;
  let listObject: FunctionVariableType | FunctionParameterType | ObjectItemType | FrontendProperty;
  const dataTypeUp = dataType?.toUpperCase();
  if (list.objectNode) {
    listObject = state.objects_items[list.objectNode];
    return dataTypeUp === listObject?.type?.toUpperCase();
  }
  switch (list?.type) {
    case TYPE_PICKER_TYPES.VAR:
      listObject = state.variables[list?.value];
      break;
    case TYPE_PICKER_TYPES.PARAM:
      listObject = state.parameters[list?.value];
      break;
    case TYPE_PICKER_TYPES.PROP:
      listObject = state.globals.properties
        ? state.globals.properties[list?.value]
        : ({} as FrontendProperty);
      break;
    default:
      listObject = {} as FunctionVariableType;
  }
  return dataTypeUp === listObject?.type?.toUpperCase() || dataTypeUp === 'OBJECT';
}

export const ADD_LIST_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.ADD_LIST,
  name: t('AddToList'),
  description: 'Action to add an item to a list',
  color: 'primary',
  category: FRONTEND_ACTIONS_CATEGORIES.LIST,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.LIST,
  icon: <AddListIcon />,
  inlineInputs: [
    {
      label: t('AddToList'),
      placeholder: '',
      key: 'list',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: {
        list: true,
        argumentTypes: [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM, TYPE_PICKER_TYPES.FIXED]
      }
    },
    {
      label: 'item',
      placeholder: '',
      key: 'object',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: { filterByDataType: filterByListDataType }
    }
  ],
  inputs: []
};
