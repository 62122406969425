import React from 'react';
import { FaCheck, FaRegWindowClose } from 'react-icons/fa';

interface TickCrossFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function TickCrossFormatterPreview({ formatterParams, value }: TickCrossFormatterPreviewProps) {
  return (
    <>
      {value.toLowerCase()[0] === 'a' ? (
        <FaCheck style={{ color: '#59bb59' }} />
      ) : (
        <FaRegWindowClose style={{ color: '#fb5050' }} />
      )}
    </>
  );
}

export default TickCrossFormatterPreview;
