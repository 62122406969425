import React from 'react';
import ActionCard from './action_card';

import { FunctionActionManifest } from '../../store/types/manifestsAndInputs';

type ActionsListProps = {
  actions: FunctionActionManifest[];
};

function ActionsList({ actions }: ActionsListProps) {
  return (
    <div style={{ overflowY: 'auto', overflowX: 'hidden', flex: '1' }}>
      {actions.map(
        (action) =>
          !action.closeBlock &&
          action.type !== 'EMPTY' && <ActionCard key={action.name} manifest={action} />
      )}
    </div>
  );
}

export default ActionsList;
