import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateAction } from '../../../store/events/actions/update_action';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import useSession from 'hooks/useSession';

function TextInput({
  label,
  placeholder,
  actionUuid,
  dataKey,
  options,
  inputValidation
}: ActionInputProps) {
  const dispatch = useDispatch();
  const isDarkTheme = useSession()[0].preferences['exocode-theme'];
  const state = useSelector((state: FunctionEditorState) => state);
  const action = state.actions[actionUuid];
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    setInputValue(action?.data[dataKey]);
  }, [action, dataKey]);

  const handleChange = (value: any) => {
    setInputValue(value);
    let inputIsValid = true;
    if (dataKey === 'if') {
      dispatch(updateAction(actionUuid, dataKey, value));
      return;
    }
    if (inputValidation) {
      const validationOutput = inputValidation(value);
      inputIsValid = validationOutput.valid;
    } else if (options?.validator) {
      inputIsValid = inputIsValid && !!options.validator(state, value);
    }
    if (inputIsValid) {
      dispatch(updateAction(actionUuid, dataKey, value));
    }
    setIsValid(inputIsValid);
  };

  const handleBlur = () => {
    setIsValid(true);
    setInputValue(action?.data[dataKey] ?? '');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
      <span className={styles.ActionInputLabel}>{label}</span>
      <input
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onBlur={handleBlur}
        className={`${
          isDarkTheme ? styles.InputDarkThemeVariables : styles.InputLightThemeVariables
        } ${styles.ActionInput} ${!isValid && styles.InvalidInput}`}
        placeholder={placeholder}
        autoComplete={'off'}
        value={inputValue}
      />
    </div>
  );
}

export default TextInput;
