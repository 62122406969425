import React from 'react';
import {
  CONTROL_REQUIREMENT_TYPES,
  CONTROL_REQUIREMENT_TYPES_MAPPER,
  ControlRequirement,
  ControlRequirementsTypes
} from './';
import { useDispatch } from 'react-redux';
import { setDesignerMode } from 'modules/designer/studio/store/actions/studio';
import { DesignerMode } from 'modules/designer/studio/store';
import { useTranslation } from 'react-i18next';

interface ControlRequirementsProps {
  controlRequirementType: ControlRequirementsTypes;
  onClick?: () => void;
}

export function ControlRequirements({ controlRequirementType, onClick }: ControlRequirementsProps) {
  const controlRequirement: ControlRequirement =
    CONTROL_REQUIREMENT_TYPES_MAPPER[controlRequirementType];
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleRedirectToBehaviorMode() {
    dispatch(setDesignerMode('BEHAVIOR' as DesignerMode));
  }

  if (!controlRequirement) return <></>;

  switch (controlRequirementType) {
    case CONTROL_REQUIREMENT_TYPES.FUNCTION:
      controlRequirement.onClick = onClick ?? handleRedirectToBehaviorMode;
      break;
    case CONTROL_REQUIREMENT_TYPES.VARIABLE:
      controlRequirement.onClick = onClick ?? handleRedirectToBehaviorMode;
      break;
    case CONTROL_REQUIREMENT_TYPES.LIST_VARIABLE:
      controlRequirement.onClick = onClick ?? handleRedirectToBehaviorMode;
      break;
  }

  return (
    <p className="m-0">
      {t(`${controlRequirement.message}`)}
      {controlRequirement.actionButtonMessage && (
        <span
          className="text-primary"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={controlRequirement.onClick}
        >
          {t(`${controlRequirement.actionButtonMessage}`)}
        </span>
      )}
    </p>
  );
}
