import React, { useCallback, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { AddItem, Item } from './item';
import { SortableItem } from './sortable_item';
import { SortableCssClass } from '.';

const Grid = ({ children, columns }: { children: React.ReactNode; columns: number }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridGap: 10,
        maxWidth: '800px',
        margin: '16px auto'
      }}
    >
      {children}
    </div>
  );
};

interface CssClassesProps {
  classes: SortableCssClass[];
  updateClasses: (classes: SortableCssClass[]) => void;
  removeClass: (id: string) => void;
}

export const CssClasses = ({ classes, updateClasses, removeClass }: CssClassesProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = classes.findIndex((e) => e.id === active.id.toString());
        const newIndex = classes.findIndex((e) => e.id === over.id.toString());
        const newClasses = arrayMove(classes, oldIndex, newIndex) as SortableCssClass[];
        updateClasses(newClasses);
      }
      setActiveId(null);
    },
    [classes, updateClasses]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const getClassLabel = (id: string) => {
    const label = classes.find((e) => e.id === id)?.className ?? '';
    return label;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={classes} strategy={rectSortingStrategy}>
        <Grid columns={4}>
          {classes.map((sortableClass) => (
            <SortableItem
              key={sortableClass.id}
              sortableClass={sortableClass}
              removeClass={removeClass}
            />
          ))}
        </Grid>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {activeId ? (
          <Item id={activeId} classNameLabel={getClassLabel(activeId)} isDragging />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export interface AddItemProps {
  availableClasses: string[];
  addClass: (className: string) => void;
}

export const AvailableCssClasses = (props: AddItemProps) => {
  return (
    <Grid columns={4}>
      {props.availableClasses.map((name, index) => {
        return <AddItem key={index} classNameLabel={name} addClass={() => props.addClass(name)} />;
      })}
    </Grid>
  );
};
