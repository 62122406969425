import { ScheduledsRepository } from '../repos';
import { SchedulerJob } from '../types';

export class ScheduledsServ {
  async createScheduled(moduleId: string, scheduled: SchedulerJob): Promise<SchedulerJob> {
    return await ScheduledsRepository.createScheduled(moduleId, scheduled);
  }

  async getScheduleds(moduleId: string): Promise<SchedulerJob[]> {
    return await ScheduledsRepository.getScheduleds(moduleId);
  }

  async getScheduled(moduleId: string, scheduledId: string): Promise<SchedulerJob> {
    return await ScheduledsRepository.getScheduled(moduleId, scheduledId);
  }

  async updateScheduled(
    scheduled: SchedulerJob,
    moduleId: string,
    scheduledJobId: string
  ): Promise<SchedulerJob> {
    return await ScheduledsRepository.updateScheduled(scheduled, moduleId, scheduledJobId);
  }

  async deleteScheduled(scheduledId: string, moduleId: string) {
    return await ScheduledsRepository.deleteScheduled(scheduledId, moduleId);
  }

  async changeFolder(jobId: string, folderId: string) {
    return await ScheduledsRepository.changeFolder(jobId, folderId);
  }
}
