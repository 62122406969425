import React, { ChangeEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { LayoutService, ModalService, PageService } from '../../../services';
import { Layout } from 'modules/designer/types';
import { Template } from 'modules/project/enum';

type CreateViewDialogProps = {
  dialogOnClose: () => void;
  showDialog: boolean;
  handleUpdateViewsList: () => void;
  layoutsList: Layout[];
  parentUuid: string;
};

function CreateViewDialog(props: CreateViewDialogProps) {
  const { module_id } = useParams();
  const [selectedView, setSelectedView] = useState('page');

  // New page inputs
  const [pageName, setPageName] = useState('');
  const [pageRoute, setPageRoute] = useState('/');

  // New modal inputs
  const [modalName, setModalName] = useState('');

  // New layout inputs
  const [layoutName, setLayoutName] = useState('');

  const [shouldClonePageNameInput, setShouldClonePageNameInput] = useState(true);
  const [selectedLayout, setSelectedLayout] = useState('default');

  const [template] = useState<Template>();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!module_id) return;

    if (selectedView === 'page') {
      await PageService.createPage(
        module_id,
        pageName,
        pageRoute,
        '',
        selectedLayout === 'default' ? '' : selectedLayout,
        template ?? '',
        props.parentUuid
      ).then(() => {
        props.handleUpdateViewsList();
        onClose();
      });
    } else if (selectedView === 'modal') {
      await ModalService.createModal(module_id, modalName, template ?? '', props.parentUuid).then(
        () => {
          props.handleUpdateViewsList();
          onClose();
        }
      );
    } else if (selectedView === 'layout') {
      await LayoutService.createLayout(
        module_id,
        layoutName,
        template ?? '',
        props.parentUuid
      ).then(() => {
        props.handleUpdateViewsList();
        onClose();
      });
    }
  };

  const handleLayoutSelect = async (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLayout(e.target.value);
  };

  const handlePageNameOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Clone pageName into pageRoute input.
      if (shouldClonePageNameInput) {
        const formattedPageRoute = event.target.value.replace(/\s/g, '_').toLowerCase();
        setPageRoute(`/${formattedPageRoute}`);
      }
      setPageName(event.target.value);
    },
    [shouldClonePageNameInput]
  );

  const handlePageRouteOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // If page route input has changed, then stop cloning pageName into pageRoute input.
      setShouldClonePageNameInput(false);
      setPageRoute(event.target.value);
    },
    []
  );

  const handleSelectViewType = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedView(event.target.value);
  };

  const onClose = () => {
    props.dialogOnClose();
    setPageName('');
    setPageRoute('/');
    setSelectedLayout('default');
    setModalName('');
    setLayoutName('');
    setSelectedView('page');
  };

  return (
    <Modal centered show={props.showDialog} onHide={onClose}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Form.Check
              onChange={handleSelectViewType}
              name="view"
              type="radio"
              value="page"
              label="Page"
              checked={selectedView === 'page'}
              id="page-checkbox"
              inline
            />
            <Form.Check
              onChange={handleSelectViewType}
              name="view"
              type="radio"
              value="modal"
              label="Modal"
              checked={selectedView === 'modal'}
              id="modal-checkbox"
              inline
            />
            <Form.Check
              onChange={handleSelectViewType}
              name="view"
              type="radio"
              value="layout"
              label="Layout"
              checked={selectedView === 'layout'}
              id="layout-checkbox"
              inline
            />
          </div>

          <div>
            {/* New page inputs */}
            {selectedView === 'page' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="PageNameInput"
                    onChange={handlePageNameOnChange}
                    value={pageName}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Route</Form.Label>
                  <Form.Control
                    type="text"
                    id="PageRouteInput"
                    onChange={handlePageRouteOnChange}
                    value={pageRoute}
                    required
                  />
                </Form.Group>
                {props.layoutsList && props.layoutsList.length > 0 && (
                  <Form.Group className="mb-3">
                    <Form.Label>Layout</Form.Label>
                    <Form.Select
                      aria-label="Layout select"
                      onChange={handleLayoutSelect}
                      value={selectedLayout}
                      required
                    >
                      <option value="default">Select Layout</option>
                      {props.layoutsList.map((layout) => {
                        return (
                          <option key={layout.uuid} value={layout.uuid}>
                            {layout.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                )}
              </>
            )}

            {/* New modal inputs */}
            {selectedView === 'modal' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="formName"
                    onChange={(e) => setModalName(e.target.value)}
                    value={modalName}
                    required
                  />
                </Form.Group>
              </>
            )}

            {/* New layout inputs */}
            {selectedView === 'layout' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="LayoutNameInput"
                    onChange={(e) => setLayoutName(e.target.value)}
                    value={layoutName}
                    required
                  />
                </Form.Group>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button id="saveButton" type="submit">
            Create View
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default CreateViewDialog;
