import React, { useEffect, useState } from 'react';
import { Button, Form, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { CrudBodyType, CrudBodyTypeKeys, CrudInputFunction } from 'modules/logic_builder/types';
import { useTranslation } from 'react-i18next';
import { CrudAutomationService } from 'modules/logic_builder/services';
import styles from './styles.module.css';
import { CrudData } from '..';
import { functionEditorCrud } from 'web_ui/function_editor/store/types/functions';

type AddFunctionsDialogProps = {
  crudData: CrudData;
  onChange: (val: CrudData) => void;
  serviceUuid: string;
  show: boolean;
  onClose: () => void;
  moduleId: string;
  loading?: (val: boolean) => void;
};

export function AddFunctionsDialog(props: AddFunctionsDialogProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState<string | undefined>();
  const [selectedRelatedEntityData, setRelatedEntityData] = useState<string>(
    CrudBodyType.PARENT_CHILD
  );
  const [isInvalid, setIsInvalid] = useState(false);

  const [switchSelections, setSwitchSelections] = useState({
    createTask: true,
    createTaskSingle: true,
    createTaskMulti: false,
    readTask: true,
    readTaskSingle: true,
    readTaskMulti: false,
    updateTask: true,
    updateTaskSingle: true,
    updateTaskMulti: false,
    deleteTask: true,
    deleteTaskSingle: true,
    deleteTaskMulti: false,
    deleteTaskMultiId: false
  });

  useEffect(() => {
    setSelectedObject(props.crudData.objects[0].uuid);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (!props.show) return;

    const isSwitchSelected =
      switchSelections.createTask ||
      switchSelections.readTask ||
      switchSelections.updateTask ||
      switchSelections.deleteTask;

    setIsInvalid(!isSwitchSelected);
    setIsLoading(false);
  }, [props.show, switchSelections]);

  const updateNewFunctionsNames = (newFunctions: functionEditorCrud[]) => {
    for (const newFunction of newFunctions) {
      const originalName: string = newFunction.name;
      let sameNameFunction = props.crudData.functions.find((createdFunction) => {
        if (createdFunction.name === originalName) {
          return true;
        }
        return false;
      });
      let counter = 0;
      let suffix = '';
      while (sameNameFunction) {
        suffix = '_' + counter;
        const newName = originalName + suffix;
        sameNameFunction = props.crudData.functions.find((createdFunction) => {
          if (createdFunction.name === newName) {
            return true;
          }
          return false;
        });
        counter++;
      }
      newFunction.name = originalName + suffix;
    }
  };

  function onSubmit() {
    if (isLoading) return;
    if (!props.moduleId || !props.crudData.entityUuid) return;

    props.loading && props.loading(true);
    setIsLoading(true);
    const crudInputFunction: CrudInputFunction = {
      crudBodyType: CrudBodyType[selectedRelatedEntityData as CrudBodyTypeKeys],
      objectUuid: selectedObject,
      create: {
        single: switchSelections.createTaskSingle,
        multi: switchSelections.createTaskMulti
      },
      read: {
        single: switchSelections.readTaskSingle,
        multi: switchSelections.readTaskMulti
      },
      update: {
        single: switchSelections.updateTaskSingle,
        multi: switchSelections.updateTaskMulti
      },
      delete: {
        single: switchSelections.deleteTaskSingle,
        multi: switchSelections.deleteTaskMulti,
        multiId: switchSelections.deleteTaskMultiId
      }
    };

    CrudAutomationService.getFunctionsPrototypes(
      props.moduleId,
      props.crudData.entityUuid,
      crudInputFunction
    )
      .then((crudFunctionList) => {
        crudFunctionList.forEach((func) => {
          func.isNew = true;
        });
        updateNewFunctionsNames(crudFunctionList);
        const oldfunc = props.crudData.functions;
        const crud = {
          ...props.crudData,
          functions: [...oldfunc, ...crudFunctionList],
          selectedObject: selectedObject // keep object selection for next step
        };

        props.onChange(crud);
      })
      .finally(() => {
        setIsLoading(false);
        props.loading && props.loading(false);
      });
    props.onClose();
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onClose();
      }}
      centered
    >
      {/* Modal Header */}
      <Modal.Header closeButton id="formModal">
        <Modal.Title>{t('automation.step3.addFunc')}</Modal.Title>
      </Modal.Header>
      <Modal.Body id="bodyModal">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <>
            <div style={{ width: 'fit-content' }}>{t('automation.step3.dataObjcet')}:</div>
            <div style={{ marginBottom: '1rem' }}>
              <Form.Select
                id="selectDataObject"
                onChange={(e) => setSelectedObject(e.target.value)}
              >
                {props.crudData.objects &&
                  Object.values(props.crudData.objects).map((object, index) => {
                    return (
                      <option key={index} value={object.uuid}>
                        {object.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>
            {/* Related Entity Data */}
            <div style={{ width: 'fit-content' }}>{t('automation.step3.RED')}:</div>
            <div style={{ marginBottom: '1rem' }}>
              <Form.Select
                id="selectRelatedEntity"
                onChange={(e) => setRelatedEntityData(e.target.value)}
                value={selectedRelatedEntityData}
              >
                <option id={CrudBodyType.OBJECT.toLowerCase()} value={CrudBodyType.OBJECT}>
                  {t('automation.step3.bodyTypeObject')}
                </option>
                <option
                  id={CrudBodyType.PARENT_CHILD.toLowerCase()}
                  value={CrudBodyType.PARENT_CHILD}
                >
                  {t('automation.step3.bodyTypeParentChild')}
                </option>
                <option
                  id={CrudBodyType.BUSINESS_RULES.toLowerCase()}
                  value={CrudBodyType.BUSINESS_RULES}
                >
                  {t('automation.step3.bodyTypeBusinessRules')}
                </option>
              </Form.Select>
            </div>
            <Form>
              <ListGroup>
                <ListGroup.Item className={styles.switchGroupItem}>
                  <span className={styles.rowNameItem} />
                  <Form.Label className={styles.rowSwitchItem}>One</Form.Label>
                  <Form.Label className={styles.rowSwitchItem}>Mult. Id</Form.Label>
                  <Form.Label className={styles.rowSwitchItem}>Multiple</Form.Label>
                </ListGroup.Item>
                <ListGroup.Item className={styles.switchGroupItem}>
                  <Form.Label
                    className={styles.rowNameItem}
                    style={{ maxWidth: '90%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    Create{' '}
                    {props.crudData.entities[props.crudData.entityUuid ?? '']?.entityName ?? ''}
                  </Form.Label>
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="createSingleCheck"
                    checked={switchSelections.createTaskSingle}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        createTask:
                          !e.target.checked && !switchSelections.createTaskMulti ? false : true,
                        createTaskSingle: e.target.checked
                      })
                    }
                  />
                  <span className={styles.rowSwitchItem} />
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="createMultiCheck"
                    checked={switchSelections.createTaskMulti}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        createTask:
                          !e.target.checked && !switchSelections.createTaskSingle ? false : true,
                        createTaskMulti: e.target.checked
                      })
                    }
                  />
                </ListGroup.Item>
                <ListGroup.Item className={styles.switchGroupItem}>
                  <Form.Label
                    className={styles.rowNameItem}
                    style={{ maxWidth: '80%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    Read{' '}
                    {props.crudData.entities[props.crudData.entityUuid ?? '']?.entityName ?? ''}
                  </Form.Label>
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="readSingleCheck"
                    checked={switchSelections.readTaskSingle}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        readTask:
                          !e.target.checked && !switchSelections.readTaskMulti ? false : true,
                        readTaskSingle: e.target.checked
                      })
                    }
                  />
                  <span className={styles.rowSwitchItem} />
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="readMultiCheck"
                    checked={switchSelections.readTaskMulti}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        readTask:
                          !e.target.checked && !switchSelections.readTaskSingle ? false : true,
                        readTaskMulti: e.target.checked
                      })
                    }
                  />
                </ListGroup.Item>
                <ListGroup.Item className={styles.switchGroupItem}>
                  <Form.Label
                    className={styles.rowNameItem}
                    style={{ maxWidth: '80%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    Update{' '}
                    {props.crudData.entities[props.crudData.entityUuid ?? '']?.entityName ?? ''}
                  </Form.Label>
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="updateSingleCheck"
                    checked={switchSelections.updateTaskSingle}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        updateTask:
                          !e.target.checked && !switchSelections.updateTaskMulti ? false : true,
                        updateTaskSingle: e.target.checked
                      })
                    }
                  />
                  <span className={styles.rowSwitchItem} />
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="updateMultiCheck"
                    checked={switchSelections.updateTaskMulti}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        updateTask:
                          !e.target.checked && !switchSelections.updateTaskSingle ? false : true,
                        updateTaskMulti: e.target.checked
                      })
                    }
                  />
                </ListGroup.Item>
                <ListGroup.Item className={styles.switchGroupItem}>
                  <Form.Label
                    className={styles.rowNameItem}
                    style={{ maxWidth: '80%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    Delete{' '}
                    {props.crudData.entities[props.crudData.entityUuid ?? '']?.entityName ?? ''}
                  </Form.Label>
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="deleteSingleCheck"
                    checked={switchSelections.deleteTaskSingle}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        deleteTask: !(
                          !e.target.checked &&
                          !switchSelections.deleteTaskMulti &&
                          !switchSelections.deleteTaskMultiId
                        ),
                        deleteTaskSingle: e.target.checked
                      })
                    }
                  />
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="deleteMultiByIdCheck"
                    checked={switchSelections.deleteTaskMultiId}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        deleteTask: !(
                          !e.target.checked &&
                          !switchSelections.deleteTaskSingle &&
                          !switchSelections.deleteTaskMulti
                        ),
                        deleteTaskMultiId: e.target.checked
                      })
                    }
                  />
                  <Form.Check
                    className={styles.rowSwitchItem}
                    type="switch"
                    id="deleteMultiCheck"
                    checked={switchSelections.deleteTaskMulti}
                    onChange={(e) =>
                      setSwitchSelections({
                        ...switchSelections,
                        deleteTask: !(
                          !e.target.checked &&
                          !switchSelections.deleteTaskSingle &&
                          !switchSelections.deleteTaskMultiId
                        ),
                        deleteTaskMulti: e.target.checked
                      })
                    }
                  />
                </ListGroup.Item>
              </ListGroup>
            </Form>
          </>
        )}
      </Modal.Body>
      {/* Footer buttons */}
      <Modal.Footer>
        <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
          {t('automation.step3.cancel')}
        </Button>
        <Button
          id="saveButton"
          variant="primary"
          type="submit"
          disabled={isInvalid}
          onClick={onSubmit}
        >
          {t('automation.step3.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
