import { ChildrenToManifest, LinkUUID, TreeProp } from 'modules/designer/types';
import React from 'react';
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';
import HelpPopover from '../../../components/Popover';

const chagingLInk = { id: '' };

export default function Tree({
  items,
  children,
  onSelected,
  idNav,
  onCreate,
  onRemove,
  setChildrenState,
  marginLeft
}: TreeProp) {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [changeIcon, setChangeIcon] = React.useState<boolean>(false);
  const [dragOver, setDragOver] = React.useState<boolean>(false);
  const currentNavbarID = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const { t } = useTranslation();

  function handlingReformObject(comeId: string, currentId: string) {
    // father to father -> change the position in navbar array
    if (
      currentNavbarID &&
      children &&
      children[currentNavbarID] &&
      children[currentNavbarID].includes(items[idNav].id) &&
      children[currentNavbarID].includes(comeId)
    ) {
      const comeIdIndex = children[currentNavbarID].indexOf(comeId);
      const currentIdIndex = children[currentNavbarID].indexOf(items[idNav].id);

      const newState = produce(children, (draft: ChildrenToManifest) => {
        draft[currentNavbarID].splice(currentIdIndex, 1, comeId);
        draft[currentNavbarID].splice(comeIdIndex, 1, currentId);
      });

      setChildrenState(newState);
    } else if (
      currentNavbarID &&
      children &&
      children[currentNavbarID] &&
      children[currentNavbarID].includes(comeId) // if what came is a father, it enters here
    ) {
      // one of the items are a father
      // current always will be the child
      // father to child -> insert in some children array and remove from father array
      if (children[currentNavbarID]) {
        children[currentNavbarID].forEach((keyFromRoot: string, index: number) => {
          if (children[keyFromRoot]) {
            children[keyFromRoot].forEach((keyChild: string, indexY: number) => {
              if (keyChild === currentId) {
                if (children && children[keyFromRoot]) {
                  const indexOfCurrentChild = children[keyFromRoot].indexOf(currentId);
                  const fatherWhoWasInsertedID = children[currentNavbarID].indexOf(comeId);
                  const newState = produce(children, (draft: ChildrenToManifest) => {
                    draft[keyFromRoot].splice(indexOfCurrentChild, 0, comeId); // inserting item
                    draft[currentNavbarID].splice(fatherWhoWasInsertedID, 1); // removing from father
                  });
                  setChildrenState(newState);
                }
              }
            });
          }
        });
      }
    } else if (
      currentNavbarID &&
      children &&
      children[currentNavbarID] &&
      children[currentNavbarID].includes(currentId) // cameid is not a father
    ) {
      // child to father -> remove from some children array and inser in father array
      // the item that came is the child
      // current is a father
      children[currentNavbarID].forEach((keyFromRoot: string, index: number) => {
        if (children && children[keyFromRoot]) {
          children[keyFromRoot].forEach((keyChild: string, indexY: number) => {
            if (keyChild === comeId) {
              const newState = produce(children, (draft: ChildrenToManifest) => {
                draft[currentNavbarID].splice(index + 1, 0, keyChild);
                draft[keyFromRoot].splice(indexY, 1);
              });
              setChildrenState(newState);
            }
          });
        }
      });
    } else {
      const allStates = { ...children };
      if (children) {
        // getting only the keys
        for (const key of Object.keys(children)) {
          if (children[key]) {
            if (children[key].includes(comeId) && children[key].includes(currentId)) {
              // children from the same father
              const newState = produce(children, (draft) => {
                draft[key].splice(children[key].indexOf(currentId), 1, comeId);
                draft[key].splice(children[key].indexOf(comeId), 1, currentId);
              });
              setChildrenState(newState);
              return;
            }
            // getting the children
            children[key].forEach((keyChild: string) => {
              if (comeId === keyChild) {
                // return the array without comeId
                const filtered = allStates[key].filter((current: string) => current !== comeId);
                allStates[key] = filtered;
              }
              if (currentId === keyChild) {
                // insert the item in the final array
                allStates[key] = [...allStates[key], comeId];
              }
            });
          }
        }
      }

      setChildrenState(allStates);
    }
  }

  function clickHandler(id: string) {
    onSelected(id);
    chagingLInk.id = id;
  }

  function onDragStartHandler(event: React.DragEvent<HTMLDivElement>) {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text', items[idNav].id);
  }

  function onDropItemHandler(event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();
    const comeid = event.dataTransfer.getData('text');
    handlingReformObject(comeid, items[idNav].id);
  }

  function handleShowSubtree() {
    setVisible(!visible);
    setChangeIcon(!changeIcon);
  }

  return (
    <>
      <ul className={styles.list} style={{ marginLeft: marginLeft + 'px' }}>
        {items[idNav] ? (
          <div
            className={styles.itemContainer}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickHandler(items[idNav].id);
            }}
            draggable={true}
            onDragStart={(event) => {
              onDragStartHandler(event);
            }}
            onDragOver={(event) => {
              event.preventDefault();
              setDragOver(true);
            }}
            onDragLeave={() => {
              setDragOver(false);
            }}
            onDragEnd={() => {
              setDragOver(false);
            }}
            onDrop={(event) => {
              setDragOver(false);
              onDropItemHandler(event);
            }}
            style={dragOver ? { borderBottom: '1px solid #5171d1' } : {}}
          >
            <div
              style={{ marginRight: '16px', color: '#000000' }}
              onClick={() => handleShowSubtree()}
            >
              {items[idNav] &&
              !items[idNav].isDivider &&
              children &&
              children[idNav] &&
              children[idNav].length > 0 &&
              !changeIcon ? (
                <Icon iconName="chevron-right" />
              ) : (
                <>
                  {items[idNav] &&
                  !items[idNav].isDivider &&
                  children &&
                  children[idNav] &&
                  children[idNav].length > 0 ? (
                    <Icon iconName="chevron-down" />
                  ) : (
                    <div style={{ width: '16px' }}></div>
                  )}
                  {items[idNav] && items[idNav].isDivider && <Icon iconName="minus" />}
                </>
              )}
            </div>
            <li
              className={`${styles.title} ${items[idNav].isDivider ? 'fst-italic' : ''}`}
              style={
                items[idNav].id === chagingLInk.id
                  ? { color: 'var(--bs-emphasis-color)' }
                  : { color: 'rgba(var(--bs-body-color-rgb),var(--bs-text-opacity))' }
              }
            >
              {items && items[idNav] && items[idNav].title && !items[idNav].isDivider
                ? items[idNav].title
                : 'Divider'}
            </li>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <HelpPopover
                placement="top"
                helpBoxProps={{
                  title: t('designer.right_side.controls.DeleteNavbarItem')!
                }}
              >
                <div className={styles.dotsTrashPlus} onClick={() => onRemove(items[idNav].id)}>
                  <Icon iconName="trash" />
                </div>
              </HelpPopover>
              <HelpPopover
                placement="top"
                helpBoxProps={{
                  title: t('designer.right_side.controls.AddNavbarItem')!
                }}
              >
                <div className={styles.dotsTrashPlus} onClick={() => onCreate(items[idNav].id)}>
                  <Icon iconName="plus" />
                </div>
              </HelpPopover>
            </div>
          </div>
        ) : (
          <></>
        )}
      </ul>
      {/* Children */}
      {visible &&
        children[idNav] &&
        children[idNav].length > 0 &&
        children[idNav].map((itemid: LinkUUID) => (
          <>
            <Tree
              items={items}
              // eslint-disable-next-line react/no-children-prop
              children={children}
              onSelected={onSelected}
              idNav={itemid}
              onCreate={(id) => onCreate(id)}
              onRemove={(id) => onRemove(id)}
              setChildrenState={setChildrenState}
              marginLeft={marginLeft + 16}
            />
          </>
        ))}
    </>
  );
}
