export enum ARGUMENT_TYPES {
  VAR = 'VAR',
  PARAM = 'PARAM',
  FIXED = 'FIXED'
}

interface DefaultArgument {
  type: ARGUMENT_TYPES;
  value: string;
  objectNode: null;
}

export interface CreateActionType {
  objectId: string;
  arguments: {
    entity: DefaultArgument;
  };
}

export interface ReadActionType {
  objectId: string;
  sortable: boolean;
  filterable: boolean;
  arguments: {
    id: DefaultArgument;
  };
}

export interface UpdateActionType {
  objectId: string;
  arguments: {
    entity: DefaultArgument;
  };
}

export interface DeleteActionType {
  objectId: string;
  arguments: {
    id: DefaultArgument;
    entity: DefaultArgument;
  };
}

export interface RunFunctionActionType {
  function: {
    functionId: string;
    arguments: any;
  };
}

export interface SetVariableActionType {
  arguments: {
    source: DefaultArgument;
    target: DefaultArgument;
  };
}

export interface DeclareVariableActionType {
  initialize: boolean;
  arguments: {
    input: DefaultArgument;
  };
}

export interface InitVariableActionType {
  arguments: {
    input: DefaultArgument;
  };
}

export interface AddListActionType {
  arguments: {
    list: DefaultArgument;
    object: DefaultArgument;
  };
}

export interface RemoveListActionType {
  arguments: {
    list: DefaultArgument;
    object: DefaultArgument;
  };
}

export interface ClearListActionType {
  arguments: {
    list: DefaultArgument;
  };
}

export interface BeginCycleActionType {
  arguments: {
    list: DefaultArgument;
    object: DefaultArgument;
  };
}

export interface CustomCodeActionType {
  code: string;
  imports: string[];
}

export interface CustomQueryActionType {
  query: string;
}

export type EndCycleActionType = any;

export type SaveFileActionType = {
  objectId: string;
  arguments: {
    entity: DefaultArgument;
    content: DefaultArgument;
  };
};

export type DeleteFileActionType = {
  objectId: string;
  arguments: {
    id: DefaultArgument;
  };
};

export type ReadFileActionType = {
  objectId: string;
  arguments: {
    id: DefaultArgument;
  };
};
