import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import light from './styles/themes/light';
import usePersistedState from './utils/usePersistedState';
import InterfaceStudioPage from './routes/studio/interface';
import DatabaseStudioPage from './routes/studio/data';
import DashboardPage from './routes/dashboard';
import Layout from './routes/layout';
import SessionWrapper from './modules/auth/store/wrapper';
import LoginPage from './routes/login';
import RedirectLoginPage from 'routes/login/redirect_login';
import WizardPage from './routes/wizard';
import Homepage from './routes/homepage';
import SignUp from './routes/signup';
import PasswordReset from './routes/password_reset';
import LogicBuilderStudio from 'routes/studio/logic';
import Organizations from 'routes/organizations';
import OrganizationPage from 'routes/organizations/organization_page';
import OrganizationDashboard from 'routes/organizations/organization_page/sub_routes/overview';
import OrganizationMembers from 'routes/organizations/organization_page/sub_routes/members';
import OrganizationRoles from 'routes/organizations/organization_page/sub_routes/roles';
import Settings from './routes/settings';
import Studios from './routes/settings/sub_routes/studios';
import General from './routes/settings/sub_routes/general';
import Account from './routes/settings/sub_routes/account';
import Preview from 'routes/preview';
import { AutomationWizard } from 'routes/automation_wizard';
import LanguageModules from './routes/dashboard/components/tabs/language_modules';
import AppAssets from './routes/dashboard/components/tabs/app_assets';
import AppModules from './routes/dashboard/components/tabs/app_modules';
import AppRoles from './routes/dashboard/components/tabs/app_roles';
import { Authorization } from 'modules/auth/session/authorization';
import {
  ContextRole,
  RoleAuthorities,
  SystemRoleAuthorityName
} from 'modules/auth/types/auth_types';
import { SessionRedirect } from './modules/auth/session/session_redirect';
import Jobs from 'routes/jobs';
import Billing from 'routes/settings/sub_routes/billing';
import LogicBuilderFunctionEditorPage from './routes/studio/logic/LogicBuilderFunctionEditorPage';
import { SHOW_ALL_URL } from 'modules/logic_builder/types';
import DesignerFunctionEditorPage from './routes/studio/interface/DesignerFunctionEditorPage';
import { ErrorBoundary } from 'react-error-boundary';
import GenericFallback from 'error_boundaries/genericFallback';
import { globalReport } from 'error_boundaries';
import Fallback from 'error_boundaries/fallbackError';
import NoAccessPage from 'routes/no_access';
import { WelcomeForm } from 'routes/login/welcome_form';
import { Support } from 'routes/settings/sub_routes/support';
import { AppContextWrapper } from 'modules/project/store/app_context_wrapper';
import ErrorFallbackIframe from 'error_boundaries/errorFallbackIframe';
import DefaultRoute from 'routes/default_router';
import SessionContext from 'modules/auth/store';
import OrganizationTeams from 'routes/organizations/organization_page/sub_routes/teams';

const WelcomeChecker = () => {
  const navigate = useNavigate();
  const session = React.useContext(SessionContext);

  useEffect(() => {
    const checkWelcomeForm = async () => {
      const user = session.user;
      if (user && !user.welcomeForm) {
        navigate('/welcome');
      }
    };

    checkWelcomeForm();
  }, [navigate, session.user]);

  return null;
};

function Exocode() {
  const [theme, setTheme] = usePersistedState<DefaultTheme>('theme', light);

  const toggleTheme = () => {
    setTheme(light);
  };

  const fallbackRoute = <Navigate to="/homepage" />;

  function report(error: Error, info: React.ErrorInfo) {
    process.env.NODE_ENV === 'development' &&
      console.error('Global Error Boundary: ', Error, '\nInfo:', info);
  }

  const savingTheFisrtLinkAccesed = (url: string) => {
    try {
      sessionStorage.setItem('directedURL', url);
    } catch (err) {
      throw new Error('Impossible to save into session storage');
    }
  };

  // save the first time opened
  useEffect(() => {
    const navigationEntry = window.performance.getEntriesByType('navigation')[0];

    // it will enter only if it came from a navigate
    //@ts-ignore
    if (navigationEntry.type === 'navigate') {
      savingTheFisrtLinkAccesed(document.URL);
    }
  }, []);

  return (
    <SessionWrapper>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={Fallback} onError={report}>
            <WelcomeChecker />
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/noacess" element={<NoAccessPage />} />
              <Route
                path="signup"
                element={
                  <SessionRedirect to="/homepage">
                    <SignUp />
                  </SessionRedirect>
                }
              />
              <Route path="welcome" element={<WelcomeForm />} />
              <Route
                path="login"
                element={
                  <SessionRedirect to="/homepage">
                    <LoginPage />
                  </SessionRedirect>
                }
              />
              <Route
                path="password_reset"
                element={
                  <SessionRedirect to="/homepage">
                    <PasswordReset />
                  </SessionRedirect>
                }
              />
              <Route
                path="oauth/redirect"
                element={
                  <SessionRedirect to="/homepage">
                    <RedirectLoginPage />
                  </SessionRedirect>
                }
              />
              <Route
                path="homepage"
                element={
                  <Authorization fallback={fallbackRoute}>
                    <Homepage />
                  </Authorization>
                }
              />
              <Route
                path="organizations"
                element={
                  <Authorization fallback={fallbackRoute}>
                    <Organizations />
                  </Authorization>
                }
              />
              <Route
                path="organization/:org_id"
                element={
                  <Authorization fallback={fallbackRoute} context={ContextRole.ORGANIZATION}>
                    <OrganizationPage />
                  </Authorization>
                }
              >
                <Route
                  path="overview"
                  element={
                    <Authorization fallback={fallbackRoute}>
                      <OrganizationDashboard />
                    </Authorization>
                  }
                />
                <Route
                  path="teams"
                  element={
                    <Authorization fallback={fallbackRoute}>
                      <OrganizationTeams />
                    </Authorization>
                  }
                />
                <Route
                  path="members"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.ORGANIZATION}
                      allowedAuthorities={[RoleAuthorities.INVITE_USER]}
                    >
                      <OrganizationMembers />
                    </Authorization>
                  }
                >
                  <Route
                    path="collaborators"
                    element={
                      <Authorization fallback={fallbackRoute}>
                        <OrganizationDashboard />
                      </Authorization>
                    }
                  />
                  <Route
                    path="pending"
                    element={
                      <Authorization fallback={fallbackRoute}>
                        <OrganizationDashboard />
                      </Authorization>
                    }
                  />
                </Route>
                <Route
                  path="roles"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.ORGANIZATION}
                      allowedAuthorities={[RoleAuthorities.CREATE_CUSTOM_ROLE]}
                    >
                      <OrganizationRoles />
                    </Authorization>
                  }
                />
              </Route>
              <Route
                path="wizard"
                element={
                  <Authorization
                    fallback={fallbackRoute}
                    allowedSystemAuthorities={[SystemRoleAuthorityName.ADD_APP]}
                  >
                    <WizardPage />
                  </Authorization>
                }
              />
              <Route
                path="app/:app_id/module/:module_id/preview/:uuid"
                element={
                  <ErrorBoundary
                    key={'Designer'}
                    FallbackComponent={({ error, resetErrorBoundary }) => (
                      <ErrorFallbackIframe
                        error={error}
                        resetErrorBoundary={resetErrorBoundary}
                        title="designer.Designer"
                      />
                    )}
                    onError={globalReport}
                    onReset={() => {}}
                  >
                    <Authorization fallback={fallbackRoute} context={ContextRole.APP}>
                      <Preview />
                    </Authorization>
                  </ErrorBoundary>
                }
              />
              <Route
                path="app/:app_id"
                element={
                  <Authorization fallback={fallbackRoute} context={ContextRole.APP}>
                    <AppContextWrapper>
                      <Layout />
                    </AppContextWrapper>
                  </Authorization>
                }
              >
                <Route
                  path="dashboard"
                  element={
                    <Authorization fallback={fallbackRoute}>
                      <DashboardPage />
                    </Authorization>
                  }
                >
                  <Route
                    path="modules"
                    element={
                      <Authorization fallback={fallbackRoute}>
                        <ErrorBoundary
                          key={'MODULES'}
                          FallbackComponent={({ error, resetErrorBoundary }) => (
                            <GenericFallback
                              error={error}
                              resetErrorBoundary={resetErrorBoundary}
                              title="appResume.MODULES"
                            />
                          )}
                          onError={globalReport}
                        >
                          <AppModules />
                        </ErrorBoundary>
                      </Authorization>
                    }
                  />
                  <Route
                    path="languages"
                    element={
                      <Authorization
                        fallback={fallbackRoute}
                        allowedSystemAuthorities={[SystemRoleAuthorityName.MANAGE_LANGUAGES]}
                      >
                        <ErrorBoundary
                          key={'LANGUAGES'}
                          FallbackComponent={({ error, resetErrorBoundary }) => (
                            <GenericFallback
                              error={error}
                              resetErrorBoundary={resetErrorBoundary}
                              title="appResume.LANGUAGES"
                            />
                          )}
                          onError={globalReport}
                        >
                          <LanguageModules />
                        </ErrorBoundary>
                      </Authorization>
                    }
                  />
                  <Route
                    path="assets"
                    element={
                      <Authorization fallback={fallbackRoute}>
                        <ErrorBoundary
                          key={'ASSETS'}
                          FallbackComponent={({ error, resetErrorBoundary }) => (
                            <GenericFallback
                              error={error}
                              resetErrorBoundary={resetErrorBoundary}
                              title="appResume.ASSETS"
                            />
                          )}
                          onError={globalReport}
                        >
                          <AppAssets />
                        </ErrorBoundary>
                      </Authorization>
                    }
                  />
                  <Route
                    path="roles"
                    element={
                      <Authorization fallback={fallbackRoute}>
                        <ErrorBoundary
                          key={'ROLES'}
                          FallbackComponent={({ error, resetErrorBoundary }) => (
                            <GenericFallback
                              error={error}
                              resetErrorBoundary={resetErrorBoundary}
                              title="appResume.ROLES"
                            />
                          )}
                          onError={globalReport}
                        >
                          <AppRoles />
                        </ErrorBoundary>
                      </Authorization>
                    }
                  />
                </Route>
                {/* Designer studio */}
                <Route
                  path="module/:module_id/ui"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_INTERFACE]}
                    >
                      <InterfaceStudioPage toggleTheme={toggleTheme} />
                    </Authorization>
                  }
                />
                {/* Designer studio */}
                <Route
                  path="module/:module_id/ui/:view_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_INTERFACE]}
                    >
                      <InterfaceStudioPage toggleTheme={toggleTheme} />
                    </Authorization>
                  }
                />
                <Route
                  path="module/:module_id/ui/:view_id/function-editor/:function_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_INTERFACE]}
                    >
                      <DesignerFunctionEditorPage toggleTheme={toggleTheme} />
                    </Authorization>
                  }
                />
                {/* Designer studio (Custom component editor) */}
                <Route
                  path="module/:module_id/ui/custom_component/:custom_component_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_INTERFACE]}
                    >
                      <InterfaceStudioPage toggleTheme={toggleTheme} />
                    </Authorization>
                  }
                />
                {/* Modeler studio */}
                <Route
                  path="module/:module_id/schema/:schema_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_DB_MODELER]}
                    >
                      <DatabaseStudioPage toggleTheme={toggleTheme} />
                    </Authorization>
                  }
                />
                {/* Logic builder */}
                <Route
                  path="module/:module_id/logic"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                    >
                      <LogicBuilderStudio />
                    </Authorization>
                  }
                />
                <Route
                  path="module/:module_id/logic/object"
                  element={<Navigate to={`./${SHOW_ALL_URL}`} />}
                />
                <Route
                  path="module/:module_id/logic/object/:obj_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                    >
                      <LogicBuilderStudio />
                    </Authorization>
                  }
                />
                <Route
                  path="module/:module_id/logic/service"
                  element={<Navigate to={`./${SHOW_ALL_URL}`} />}
                />
                <Route
                  path="module/:module_id/logic/service/:service_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                    >
                      <LogicBuilderStudio />
                    </Authorization>
                  }
                />
                <Route
                  path="module/:module_id/logic/controller"
                  element={<Navigate to={`./${SHOW_ALL_URL}`} />}
                />
                <Route
                  path="module/:module_id/logic/controller/:controller_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                    >
                      <LogicBuilderStudio />
                    </Authorization>
                  }
                />
                <Route
                  path="module/:module_id/logic/scheduler"
                  element={<Navigate to={`./${SHOW_ALL_URL}`} />}
                />
                <Route
                  path="module/:module_id/logic/scheduler/:scheduler_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                    >
                      <LogicBuilderStudio />
                    </Authorization>
                  }
                />
                <Route
                  path="module/:module_id/logic/automation-wizard"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                    >
                      <AutomationWizard />
                    </Authorization>
                  }
                />
                <Route
                  path="module/:module_id/logic/service/:service_id/function-editor/:function_id"
                  element={
                    <Authorization
                      fallback={fallbackRoute}
                      context={ContextRole.APP}
                      allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                    >
                      <LogicBuilderFunctionEditorPage toggleTheme={toggleTheme} />
                    </Authorization>
                  }
                />
                <Route path="jobs" element={<Jobs />} />
              </Route>
              <Route
                path="settings"
                element={
                  <Authorization fallback={fallbackRoute}>
                    <Settings />
                  </Authorization>
                }
              >
                <Route
                  path="account"
                  element={
                    <Authorization fallback={fallbackRoute}>
                      <Account />
                    </Authorization>
                  }
                />
                <Route
                  path="general"
                  element={
                    <Authorization fallback={fallbackRoute}>
                      <General />
                    </Authorization>
                  }
                />
                <Route
                  path="studios"
                  element={
                    <Authorization fallback={fallbackRoute}>
                      <Studios />
                    </Authorization>
                  }
                />
                <Route
                  path="billing"
                  element={
                    <Authorization fallback={fallbackRoute}>
                      <Billing />
                    </Authorization>
                  }
                />
                <Route
                  path="support"
                  element={
                    <Authorization fallback={fallbackRoute}>
                      <Support />
                    </Authorization>
                  }
                />
              </Route>
              <Route path="*" element={<DefaultRoute />} />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </SessionWrapper>
  );
}

export default Exocode;
