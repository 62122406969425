import React, { DragEvent, DragEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { COMPONENT_TYPES, COMPONENTS_MANIFEST } from '../../exocode_components';
import { setDraggedComponent } from '../../store/actions/studio';
import ReactDOM from 'react-dom';

interface ComponentItemDraggerProps {
  componentId?: string;
  componentType: string;
  children: React.ReactNode;
}

function ComponentItemDragger({ componentId, componentType, children }: ComponentItemDraggerProps) {
  const dispatch = useDispatch();

  const handleDragStart = (ev: DragEvent<HTMLDivElement>) => {
    if (ev.dataTransfer == null) return;
    ev.dataTransfer.setData('exocode/component-type', componentType);
    if (componentType === COMPONENT_TYPES.CUSTOM && componentId) {
      ev.dataTransfer.setData('exocode/custom-component-uuid', componentId);
    }
    ev.dataTransfer.dropEffect = 'copy';
    dispatch(
      setDraggedComponent({
        componentId: null,
        componentType: componentType
      })
    );
    // This snippet creates a ghost element with the same properties as the original element that is being dragged, when creating a component
    const ghostDiv = document.createElement('div');
    ghostDiv.id = 'previewElementImage';
    ghostDiv.style.position = 'absolute';
    ghostDiv.style.width = `${COMPONENTS_MANIFEST[componentType].previewWidth}px`;
    ghostDiv.style.height = `${COMPONENTS_MANIFEST[componentType].previewHeight}px`;
    ghostDiv.style.background = 'rgb(13 110 253 / 50%)';
    document.body.appendChild(ghostDiv);
    ev.dataTransfer.setDragImage(ghostDiv, 0, 0);
  };

  const handleDragEnd = (ev: DragEvent<HTMLDivElement>) => {
    // ev.preventDefault();

    dispatch(setDraggedComponent(null));
    const previewImage = document.getElementById('previewElementImage');
    if (previewImage) previewImage.remove();
    if (componentType !== COMPONENT_TYPES.CUSTOM) setOnLocalStorage(componentType);
  };

  function setOnLocalStorage(componentType: string) {
    const storedComponents = localStorage.getItem('recentComponents');
    let newRecentComponents = [];
    if (storedComponents) {
      const recentComponents = JSON.parse(storedComponents);

      newRecentComponents = [
        ...recentComponents.filter((type: string) => type !== componentType),
        componentType
      ];
    } else {
      newRecentComponents = [componentType];
    }
    if (newRecentComponents.length > 3) newRecentComponents.shift();
    localStorage.setItem('recentComponents', JSON.stringify(newRecentComponents));
    window.dispatchEvent(new Event('storage'));
  }

  return (
    <div onDragStart={(ev) => handleDragStart(ev)} onDragEnd={(ev) => handleDragEnd(ev)} draggable>
      {children}
    </div>
  );
}

export default ComponentItemDragger;
