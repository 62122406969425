import React, { useContext, useState } from 'react';
import { Button, Modal, Form, Container, Spinner } from 'react-bootstrap';
import { OrganizationService } from 'modules/organization/services';
import { AddRole, initalStateRole, RoleType } from 'modules/organization/types';
import { useTranslation } from 'react-i18next';
import { messageCallback } from 'routes/organizations';
import { OrganizationContext } from 'routes/organizations/organization_page';

import styles from './style.module.css';
import AuthoritiesList from 'modules/auth/components/authorities_list';

export type CreateCustomRoleModalProps = {
  showModal: boolean;
  onCloseRequest: (message: messageCallback) => void;
};

function CreateCustomRoleModal({ showModal, onCloseRequest }: CreateCustomRoleModalProps) {
  const orgContext = useContext(OrganizationContext);
  const [role, setRole] = useState<RoleType>(initalStateRole);
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const roleText: string = t('invitation.Role');

  const buildQuery = (): AddRole => {
    const newRole: AddRole = {
      name: role.name,
      authorities: role.authorities
    };
    return newRole;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!orgContext.organization) return;

    setLoading(true);
    const newRole = buildQuery();
    await OrganizationService.createCustomRoleOrg(orgContext.organization.id, newRole)
      .then(() => {
        onClose('success');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = (message: messageCallback) => {
    setRole({
      name: '',
      authorities: [],
      isCustom: true
    });
    onCloseRequest(message);
  };

  const changeAuthority = (role: RoleType) => {
    setRole((prev) => ({ ...prev, authorities: role.authorities }));
  };

  const handlerChangeName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const str = e.target.value.toUpperCase();
    if (str.match('^[A-Z]*$')) {
      setRole((prev) => ({ ...prev, name: str }));
    }
  };

  return (
    <Modal
      centered
      show={showModal}
      onHide={() => onClose('nothing')}
      backdrop={'static'}
      size={'lg'}
    >
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton className="bg-body-tertiary">
          <Modal.Title className={`text-body-emphasis ${styles.newOrgModalTitle}`}>
            <strong>{t('organizations.CreateRoleDialogTitle')}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form.Group className="mb-3">
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control
                type="text"
                id="formName"
                onChange={(e) => handlerChangeName(e)}
                placeholder={roleText}
                value={role.name}
                disabled={isLoading}
                required
              />
            </Form.Group>
            <AuthoritiesList role={role} onChangeAuthority={changeAuthority} />
            <div className={styles.dialogSaveButton}>
              <Button id="saveButton" type="submit" disabled={isLoading}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className={
                    isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`
                  }
                />
                {t('organizations.new_organization.Create')}
              </Button>
            </div>
          </Container>
        </Modal.Body>
      </form>
    </Modal>
  );
}

export default CreateCustomRoleModal;
