import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ViewManifest } from '../../../types';
import { validateDescriptionInputsForDesigner } from 'utils/inputValidation';

export const LAYOUT_MANIFEST: ViewManifest = {
  type: 'LAYOUT',
  name: 'Layout',
  description: '',
  icon: 'fa-desktop',
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Title',
      key: 'title'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Description',
      key: 'description',
      validation: (input: string) => validateDescriptionInputsForDesigner(input),
      errorMessages: {
        EXCEEDS_MAX_LENGTH: 'inputValidationErrorMessages.ViewDescriptionExceedsMaxLength'
      }
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Background',
      key: 'backgroundColor'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'GlobalFont',
      key: 'globalFont',
      controlOptions: ['Arial', 'Times New Roman', 'Roboto', 'Verdana', 'Roboto']
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'GlobalFontSize',
      key: 'globalFontSize'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'GlobalFontColor',
      key: 'globalFontColor'
    }
  ]
};
