import React, { ChangeEvent, useEffect, useState } from 'react';
import { COLOR_MANIFEST, FormatterManifest, FormatterProps, FormatterTypes } from '../../types';

function ColorFormatter(props: FormatterProps) {
  const [colorParams, setColorParams] = useState<FormatterManifest>(COLOR_MANIFEST);

  function handleChange(e: ChangeEvent<HTMLInputElement>, field: string) {
    const updatedImageParams = { ...colorParams };
    updatedImageParams[field] = e.target.value;
    setColorParams(updatedImageParams);
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedImageParams);
  }

  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.COLOR) {
      props.onChange && props.onChange(props.index, 'formatterParams', COLOR_MANIFEST);
    }
  }, []);

  return <></>;
}

export default ColorFormatter;
