import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from '../../../types';
import { t } from 'i18next';

export const SELECT_MANIFEST: ComponentManifest = {
  type: 'SELECT',
  name: 'Dropdown',
  descriptionId: 'DropdownDescription',
  description:
    'Dropdowns are a common form control that allows the user to select from a list of options.',
  icon: 'fa-solid fa-arrow-pointer',
  group: ComponentGroups['INPUT'],
  previewHeight: 75,
  previewWidth: 200,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Label',
      key: 'label',
      tooltip: 'designer.right_side.controls.components.select.text'
    },
    {
      controlType: ControlsTypes.VARIABLEREADONLY,
      controlLabel: 'ReadonlyVariable',
      key: 'readonlyVar',
      tooltip: 'designer.right_side.controls.components.select.readonlyVar'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable'
    },
    {
      controlType: ControlsTypes.TEXTFORMAT,
      controlLabel: 'DefaultValue',
      key: 'defaultValue'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.select.tooltip'
    },
    {
      controlType: ControlsTypes.LIST,
      controlLabel: 'Options',
      tooltip: 'designer.right_side.controls.components.select.numberOfColumns'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Required',
      key: 'required',
      tooltip: 'designer.right_side.controls.components.select.required'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Multiselect',
      key: 'multiselect',
      tooltip: 'designer.right_side.controls.components.select.multiselect'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'Disabled',
      key: 'disabled',
      tooltip: 'designer.right_side.controls.components.select.disabled'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'DisabledVariable',
      key: 'disabledVar'
    },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.select.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.select.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.select.color'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.select.fontSize'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CHANGE']
};
