import React from 'react';
import { Argument } from '../../../store/types/manifestsAndInputs';
import ParameterEditor from './parameter_editor';

type RunFunctionArgumentsProps = {
  args: { [key: string]: Argument };
  actionUuid: string;
  handleChange: (paramId: string, value: Argument) => void;
  options?: any;
  isFromSideBar?: boolean;
};

function RunFunctionArguments({
  args,
  handleChange,
  actionUuid,
  options,
  isFromSideBar
}: RunFunctionArgumentsProps) {
  return (
    <div className={'d-flex flex-column text-white align-items-start justify-content-end'}>
      {args && Object.keys(args).length > 0 && <span>Params:</span>}
      {args &&
        Object.keys(args).map((p, index) => (
          <ParameterEditor
            key={p}
            paramId={p}
            param={args[p]}
            label={''}
            actionUuid={actionUuid}
            handleChange={handleChange}
            options={options}
            isFromSideBar={isFromSideBar}
          />
        ))}
    </div>
  );
}

export default RunFunctionArguments;
