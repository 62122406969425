const lightTheme = {
  title: 'light',

  colors: {
    primary: '#0d6efd',
    secondary: '#032e6d',

    background: '#f5f5f5',
    studioBackground: '#cacaca',
    markerColorBackground: '#333',
    sidebarColor: '#fefefe',
    text: '#333'
  }
};

export default lightTheme;
