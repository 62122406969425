import { DriveStep } from 'driver.js';
import { WALKTHROUGH_STEPS_ELEMENTS } from './constants';

export const getAdvancedDialogActions = (fieldsTab: () => void) => {
  return {
    [`#${WALKTHROUGH_STEPS_ELEMENTS['entity-description']}`]: () => {
      fieldsTab();
    }
  };
};

export const getFunctionEditorActions = (editMode: () => void) => {
  return {
    [`#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-main']}`]: () => {
      editMode();
    }
  };
};

export const getEndpointEditorActions = (openLogicTab: () => void) => {
  return {
    [`#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-path']}`]: () => {
      openLogicTab();
    }
  };
};

// Sometimes we need to apply actions in between steps. For example: switch tabs, open edit
// function etc.
export const applyStepActions = (
  moveNext: () => void,
  actions: Record<string, () => void>,
  steps: DriveStep[]
) => {
  return steps.map((step: DriveStep) => {
    let elementId = '';
    if (step.element) {
      elementId = step.element.toString();
    } else {
      return step;
    }

    if (actions[elementId]) {
      return {
        ...step,
        popover: {
          ...step.popover,
          onNextClick: () => {
            actions[elementId]();
            moveNext();
          }
        }
      };
    }
    return step;
  });
};
