import { VariablesState } from '../index';
import produce from 'immer';
import {
  ADD_VARIABLE,
  AddVariableAction,
  CHANGE_VARIABLE_TYPE,
  ChangeVariableTypeAction,
  DELETE_VARIABLE,
  DeleteVariableAction,
  SET_VARIABLES,
  SetVariablesAction,
  UPDATE_VARIABLE,
  UpdateVariableAction
} from '../actions/variables';
import { AddFunctionAction } from '../actions/functions';

type VariablesActions =
  | SetVariablesAction
  | AddVariableAction
  | UpdateVariableAction
  | ChangeVariableTypeAction
  | DeleteVariableAction
  | AddFunctionAction;

export const variablesReducer = (
  state: VariablesState = {},
  action: VariablesActions
): VariablesState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_VARIABLES:
        return doSetVariables(draft, action);
      case ADD_VARIABLE:
        return doAddVariable(draft, action);
      case UPDATE_VARIABLE:
        return doUpdateVariable(draft, action);
      case CHANGE_VARIABLE_TYPE:
        return doChangeVariableType(draft, action);
      case DELETE_VARIABLE:
        return doDeleteVariable(draft, action);
      default:
        return draft;
    }
  });
};

function doSetVariables(state: VariablesState, action: SetVariablesAction): VariablesState {
  return action.payload.variables;
}

function doAddVariable(state: VariablesState, action: AddVariableAction): VariablesState {
  if (!action.payload) return state;
  state[action.payload.uuid] = action.payload;
  return state;
}

function doUpdateVariable(state: VariablesState, action: UpdateVariableAction): VariablesState {
  const {
    uuid,
    name,
    type,
    list,
    initialValueFixed,
    initialValueParamId,
    initialValuePropId,
    objectId
  } = action.payload;
  state[uuid] = {
    ...state[uuid],
    name,
    type,
    list,
    initialValueFixed,
    initialValueParamId,
    initialValuePropId,
    objectUuid: objectId
  };
  return state;
}

function doChangeVariableType(
  state: VariablesState,
  action: ChangeVariableTypeAction
): VariablesState {
  const { uuid, type, list, objectUuid } = action.payload;
  state[uuid] = {
    ...state[uuid],
    type,
    list,
    objectUuid: objectUuid
  };
  return state;
}

function doDeleteVariable(state: VariablesState, action: DeleteVariableAction): VariablesState {
  delete state[action.payload.uuid];
  return state;
}
