import React from 'react';
import styles from './styles.module.css';
import FunctionReturnType from './return_type';
import FunctionName from './function_name';
import ParametersList from './parameters_list';
import { FunctionSignatureProps } from '../index';

function JavaFunctionSignature({ types }: FunctionSignatureProps) {
  return (
    <div className={styles.FunctionSignature}>
      <FunctionReturnType types={types} />
      <FunctionName />
      <ParametersList types={types} />
    </div>
  );
}

export default JavaFunctionSignature;
