import { Template } from 'modules/project/enum';
import { AppTemplateOption, TemplateCategory } from './index';

export const TemplateCategoryList: TemplateCategory[] = [
  {
    name: 'Business',
    icon: 'briefcase'
  },
  {
    name: 'Design',
    icon: 'marker'
  }
];

export const TemplateList: AppTemplateOption[] = [
  {
    id: Template.BLANK,
    title: 'Blank',
    description: 'blanckProjetctDesc',
    image: '/assets/blank.jpg',
    categories: []
  },
  {
    id: Template.LIBRARY,
    title: 'Book Manager',
    description: 'bookManager',
    image: '/assets/library.jpg',
    categories: []
  },
  {
    id: Template.TODO,
    title: 'To-Do List',
    description: 'todo',
    image: '/assets/todo.jpg',
    categories: []
  }
];
