import * as React from 'react';

function ThreeColumnsIcon(props: any) {
  return (
    <svg
      style={{ width: '100%' }}
      viewBox="0 0 99 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.375 26.839a5 5 0 00-5 5v35a5 5 0 005 5h11.64a5 5 0 005-5v-35a5 5 0 00-5-5h-11.64z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 71.839a5 5 0 01-5-5v-35a5 5 0 015-5h11.64a5 5 0 015 5v35a5 5 0 01-5 5h-11.64z"
        fill="#79828A"
      />
      <path
        d="M43.5 26.839a5 5 0 00-5 5v35a5 5 0 005 5h11.64a5 5 0 005-5v-35a5 5 0 00-5-5H43.5z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.5 71.839a5 5 0 01-5-5v-35a5 5 0 015-5h11.64a5 5 0 015 5v35a5 5 0 01-5 5H43.5z"
        fill="#79828A"
      />
      <path
        d="M69.985 26.839a5 5 0 00-5 5v35a5 5 0 005 5h11.64a5 5 0 005-5v-35a5 5 0 00-5-5h-11.64z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.985 71.839a5 5 0 01-5-5v-35a5 5 0 015-5h11.64a5 5 0 015 5v35a5 5 0 01-5 5h-11.64z"
        fill="#79828A"
      />
    </svg>
  );
}

export default ThreeColumnsIcon;
