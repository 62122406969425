import {
  DataType,
  FunctionParameter,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import * as React from 'react';
import { ARGUMENT_TYPES } from '../../types';
import { Form } from 'react-bootstrap';
import { ComboboxInput } from 'web_ui/combobox';

type Props = {
  allowVars: boolean;
  allowParams: boolean;
  allowFixed: boolean;
  allowObject: boolean;
  allowDataTypes: DataType[];
  objectUuid?: string;
  params: FunctionParameter[];
  variables: FunctionVariable[];
  objects: ObjectSimple[];
  value: string;
  onChange: (val: string, typeChosen: ARGUMENT_TYPES) => void;
  label: string;
  onlyList: boolean;
  currentType?: ARGUMENT_TYPES;
};

const typesForChoose = ['PARAM', 'VAR', 'FIXED'];

export default function ArgumentPicker(props: Props) {
  const [auxVal, setAuxVal] = React.useState<string>('');
  const [selectedOption, setSelectedOption] = React.useState<ARGUMENT_TYPES | undefined>(
    props.currentType && props.currentType
  );

  React.useEffect(() => {
    setAuxVal(props.value);
  }, [props.value]);

  const getDisplayNameFromItem = (item: unknown): string => {
    const option = item as ARGUMENT_TYPES | undefined;
    return option ?? 'Select an option';
  };
  const getSelectedItem = (): ARGUMENT_TYPES | undefined => {
    return selectedOption;
  };
  const handleChangeSelectedItem = (item: unknown): void => {
    const option = item as ARGUMENT_TYPES;
    setAuxVal('');
    setSelectedOption(option);
  };
  const filteredPropsOptions = typesForChoose.filter((t) => {
    if (t === 'PARAM' && props.allowParams) return true;
    if (t === 'VAR' && props.allowVars) return true;
    if (t === 'FIXED' && props.allowFixed) return true;
    return false;
  });

  return (
    <>
      <Form.Group>
        <Form.Label>{props.label}</Form.Label>
        <ComboboxInput
          items={filteredPropsOptions}
          selectedItem={getSelectedItem()}
          getDisplayNameFromItem={getDisplayNameFromItem}
          handleChangeSelectedItem={handleChangeSelectedItem}
        />

        {(selectedOption === 'PARAM' ||
          props.params.filter((ite) => ite.uuid === auxVal).length > 0) && (
          <Form.Select
            id={props.value}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value, selectedOption!)}
            required
            style={{ marginBottom: '1rem' }}
          >
            <option>Select Option</option>

            {props.allowDataTypes.length > 0
              ? props.params
                  .filter((ite) => {
                    if (props.onlyList) {
                      for (const currentVal of props.allowDataTypes) {
                        // it needs to be equals the type gave
                        if (ite.type === currentVal && ite.list === true) return ite.type;
                      }
                    } else {
                      for (const currentVal of props.allowDataTypes) {
                        // it needs to be equals the type gave
                        if (ite.type === currentVal) return ite.type;
                      }
                    }
                  })
                  .filter((ite) => ite.objectUuid)
                  .map((parItem, index) => (
                    <option key={index} value={parItem.uuid}>
                      {parItem.name}
                    </option>
                  ))
              : props.params
                  .filter((ParIte) => {
                    if (props.onlyList) {
                      return ParIte.list;
                    }
                    return ParIte;
                  })
                  .map((parItem, index) => (
                    <option key={index} value={parItem.uuid}>
                      {parItem.name}
                    </option>
                  ))}
          </Form.Select>
        )}

        {(selectedOption === 'VAR' ||
          props.variables.filter((ite) => ite.uuid === auxVal).length > 0) && (
          <Form.Select
            id={props.value}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value, selectedOption!)}
            required
            style={{ marginBottom: '1rem' }}
          >
            <option id={props.value}>Select Option</option>
            {props.allowDataTypes.length > 0
              ? props.variables
                  .filter((ite) => {
                    if (props.onlyList) {
                      for (const currentVal of props.allowDataTypes) {
                        // it needs to be equals the type gave
                        if (ite.type === currentVal && ite.list === true) return ite.type;
                      }
                    } else {
                      for (const currentVal of props.allowDataTypes) {
                        // it needs to be equals the type gave
                        if (ite.type === currentVal) return ite.type;
                      }
                    }
                  })
                  .filter((fil) => fil.list === props.onlyList)
                  .map((varItem, index) => (
                    <option key={index} value={varItem.uuid}>
                      {varItem.name}
                    </option>
                  ))
              : props.variables
                  .filter((varIte) => {
                    if (props.onlyList) {
                      return varIte.list;
                    }
                    return varIte;
                  })
                  .map((varItem, index) => (
                    <option key={index} value={varItem.uuid}>
                      {varItem.name}
                    </option>
                  ))}
          </Form.Select>
        )}
      </Form.Group>
    </>
  );
}
