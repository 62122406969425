import { generate, parse, walk } from 'css-tree';

export const ASTToCSS = (ast: any): string => {
  return generate(ast);
};

// Transforms min-height -> minHeight.
const hyphensToCamelCase = (css: string): string => {
  return css.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

/**
 * Checks whether the first selector in the css string is a class selector.
 * E.g.: .Example {... -> returns true.
 *        p {... -> returns false
 */
export const isClassSelector = (css: string): boolean => {
  const ast = parse(css, {
    parseAtrulePrelude: false,
    parseRulePrelude: false,
    parseValue: false
  });
  walk(ast, (node) => {
    if (node.type === 'SelectorList') {
      if (node.children.first?.type === 'ClassSelector') {
        return true;
      }
    }
  });
  return false;
};

/**
 * Get css declarations and values (with added indentation).
 */
export const getCssDeclarations = (css: string, withIndentation = false): string => {
  let declarationValues = '';
  const ast = parse(css);
  walk(ast, (node) => {
    if (node.type === 'Declaration') {
      if (withIndentation) {
        declarationValues += '  ';
      }
      declarationValues += node.property + ': ' + generate(node.value) + ';\n';
    }
  });
  return declarationValues;
};
export const getPlainCssDeclarations = (css: string): string => {
  let declarationValues = '';
  const ast = parse(css);
  walk(ast, (node) => {
    if (node.type === 'Declaration') {
      declarationValues += node.property + ':' + generate(node.value) + ';';
    }
  });
  return declarationValues;
};

/**
 * Returns the list of selectors in the css string.
 * E.g.: .Example {... -> returns ['.Example'].
 */
export const getSelectorList = (css: string): string[] => {
  const selectorList: string[] = [];
  const ast = parse(css);
  walk(ast, (node) => {
    if (node.type === 'SelectorList') {
      selectorList.push(generate(node));
    }
  });
  return selectorList;
};

export const parseCustomCss = (css: string | undefined): Record<string, string> => {
  if (!css) return {};
  const customCss: Record<string, string> = {};
  // Record<'css declaration', 'css value'>
  // E.g.: { color: 'red' }. This is returned by this function and injected directly
  // in the styles attribute.
  // Wrap css with '.cssSelector' so that it is a valid css string and can be parsed
  // correctly.
  const ast = parse('.cssSelector{' + css + '}');
  walk(ast, (node) => {
    if (node.type === 'Declaration') {
      const declaration = hyphensToCamelCase(node.property);
      let value = generate(node.value);
      if (value && node.important) {
        value += ' !important';
      }
      if (value) {
        customCss[declaration] = value;
      }
    }
  });
  return customCss;
};

export const removeDeclarationsWithUndefinedValue = (
  styles: Record<string, string | number | undefined>
) => {
  for (const declaration of Object.keys(styles)) {
    if (styles[declaration] == null) {
      delete styles[declaration];
    }
  }
};
