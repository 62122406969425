import React from 'react';
import { UserProfile, UserWithRole } from 'modules/auth/types/auth_types';
import { Col } from 'react-bootstrap';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { useParams } from 'react-router-dom';

type Props = {
  user: UserProfile;
  addingMemberToRemove?: (member: UserWithRole) => void;
  isFromAddPeople: boolean;
  addingMemberToAdd?: (member: UserWithRole) => void;
  owner_id: number | undefined;
  membersToAdd?: UserWithRole[];
  membersToRemove?: UserWithRole[];
};

export const Member = ({
  user,
  addingMemberToRemove,
  isFromAddPeople,
  addingMemberToAdd,
  owner_id,
  membersToAdd,
  membersToRemove
}: Props) => {
  const { app_id } = useParams();

  const styleSelected = (): React.CSSProperties => {
    if (membersToAdd) {
      if (membersToAdd.filter((item) => item.id === user.id).length > 0) {
        return {
          backgroundColor: 'rgba(128, 128, 128, 0.4)'
        };
      }
    } else if (membersToRemove) {
      if (membersToRemove.filter((item) => item.id === user.id).length > 0) {
        return {
          backgroundColor: 'gray',
          opacity: '80%'
        };
      }
    } else {
      return {};
    }
    return {};
  };

  return (
    <div className={styles.container} style={styleSelected()}>
      <div className={styles.containerIconName}>
        <Col xs={'auto'} className={`d-flex align-items-center`}>
          <div className={`${styles.avatar} me-2}`}>
            {user.name
              ? user.name.substring(0, 2).toUpperCase()
              : user.username.substring(0, 2).toUpperCase()}
          </div>
        </Col>
        <div>
          <span className={styles.name}>
            {user.name
              ? user.name[0].toUpperCase() + user.name.substring(1)
              : user.username[0].toUpperCase() + user.username.substring(1)}
          </span>
        </div>
      </div>
      <div>
        {isFromAddPeople ? (
          <div
            className={styles.containerIcon}
            onClick={() => {
              if (membersToAdd && membersToAdd.filter((item) => item.id === user.id).length > 0) {
                return;
              }
              if (addingMemberToAdd && owner_id)
                addingMemberToAdd({
                  id: user.id,
                  roleInApp: user.roles.filter(
                    (item) => item.context === 'ORGANIZATION' && item.contextId === owner_id
                  )[0].name
                });
            }}
          >
            <i className="fa-regular fa-plus" style={{ color: '#824646' }}></i>
          </div>
        ) : (
          <div
            className={styles.containerIcon}
            onClick={() => {
              if (
                membersToRemove &&
                membersToRemove.filter((item) => item.id === user.id).length > 0
              ) {
                return;
              }
              if (addingMemberToRemove)
                addingMemberToRemove({
                  id: user.id,
                  roleInApp: user.roles.filter(
                    (item) => item.context === 'APP' && item.contextUuid === app_id!
                  )[0].name
                });
            }}
          >
            <i className="fa-regular fa-trash-can" style={{ color: '#824646' }}></i>
          </div>
        )}
      </div>
    </div>
  );
};
