import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../store/types/function_editor_state';
import { functionNameRegex } from '../../../utils/regex';
import { FORBIDDEN_FUNCTION_NAMES } from '../store/types/functions';

function useValidateFunctionName() {
  const functions = useSelector((state: FunctionEditorState) => state.functions);

  return (name: string) => {
    if (!name) return false;
    if (!functionNameRegex.test(name)) return false;
    if (FORBIDDEN_FUNCTION_NAMES.includes(name.toLowerCase())) return false;
    for (const f of Object.values(functions)) {
      if (f.name === name) {
        return false;
      }
    }
    return true;
  };
}

export default useValidateFunctionName;
