import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import React from 'react';
import BeginCycleIcon from './begin_cycle_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import {
  FORBIDDEN_VARIABLE_NAMES,
  FunctionVariableType
} from '../../../../../web_ui/function_editor/store/types/variables';
import { FunctionParameterType } from '../../../../../web_ui/function_editor/store/types/parameters';
import { ObjectItemType } from '../../../../../web_ui/function_editor/store/types/object_items';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { FRONTEND_VARIABLE_TYPES, FrontendProperty } from '../../../types';
import { t } from 'i18next';
import { DATA_TYPES } from '../../../../logic_builder/types';
import { variableNameRegex } from '../../../../../utils/regex';

function filterByListDataType(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: keyof typeof FRONTEND_VARIABLE_TYPES
): boolean {
  const list = state.actions[actionUuid].data?.list;
  if (!list) return false;
  let listObject: FunctionVariableType | FunctionParameterType | ObjectItemType | FrontendProperty;
  const dataTypeUp = dataType?.toUpperCase();
  if (list.objectNode) {
    listObject = state.objects_items[list.objectNode];
    return dataTypeUp === listObject?.type?.toUpperCase();
  }
  switch (list?.type) {
    case TYPE_PICKER_TYPES.VAR:
      listObject = state.variables[list?.value];
      break;
    case TYPE_PICKER_TYPES.PARAM:
      listObject = state.parameters[list?.value];
      break;
    case TYPE_PICKER_TYPES.PROP:
      listObject = state.globals.properties
        ? state.globals.properties[list?.value]
        : ({} as FrontendProperty);
      break;
    default:
      listObject = {} as FunctionVariableType;
  }
  return dataTypeUp === listObject?.type?.toUpperCase() || dataTypeUp === 'OBJECT';
}

function validateVariableName(state: FunctionEditorState, name: string): boolean {
  if (!name) return false;
  if (!variableNameRegex.test(name)) return false;
  if (FORBIDDEN_VARIABLE_NAMES.includes(name.toLowerCase())) return false;
  for (const v of Object.values(state.variables)) {
    if (v.name === name) return false;
  }
  return true;
}

export const BEGIN_CYCLE_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.BEGIN_CYCLE,
  name: t('For'),
  description: t('ForDescription'),
  color: 'secondary',
  category: FRONTEND_ACTIONS_CATEGORIES.CYCLE,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.CYCLE,
  icon: <BeginCycleIcon />,
  openBlock: true,
  closeBlockType: FRONTEND_ACTIONS_TYPES.END_CYCLE,
  inlineInputs: [
    {
      type: ACTION_INPUTS_TYPES.TEXT,
      label: t('ForEach'),
      placeholder: t('Item') ?? 'item',
      key: 'name',
      options: { validator: validateVariableName }
    },
    {
      type: ACTION_INPUTS_TYPES.DATA_TYPE_INPUT,
      label: t('ofType'),
      placeholder: t('type') ?? 'type',
      key: 'dataType',
      options: { dataTypes: DATA_TYPES }
    },
    {
      label: t('In'),
      placeholder: t('List') ?? 'list',
      key: 'list',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: {
        list: true,
        argumentTypes: [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM, TYPE_PICKER_TYPES.FIXED]
      }
    }
  ],
  inputs: []
};
