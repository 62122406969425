import React, { ChangeEvent, useEffect } from 'react';
import { FormatterProps, FormatterTypes, STARS_MANIFEST } from '../../types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../../../components/Popover';
import HelpIcon from '../../../components/HelpIcon';

function StarsFormatter(props: FormatterProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.STARS) {
      props.onChange && props.onChange(props.index, 'formatterParams', STARS_MANIFEST);
    }
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>, field: string) {
    const updatedDatetimeParams = { ...props.value };
    updatedDatetimeParams[field] = e.target.value;
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedDatetimeParams);
  }

  return (
    <div className="mb-3 border-bottom ">
      <Form.Group>
        <Form.Label column>{t('designer.right_side.FormatterControls.StarsFormatter')}</Form.Label>
        <HelpPopover
          helpBoxProps={{
            title:
              t('designer.right_side.FormatterControls.StarsFormatterTitle') ||
              'designer.right_side.FormatterControls.StarsFormatterTitle',
            description:
              t('designer.right_side.FormatterControls.StarsFormatterDescription') ||
              'designer.right_side.FormatterControls.StarsFormatterDescription'
          }}
          placement="top"
        >
          <HelpIcon />
        </HelpPopover>
        <Form.Control
          type="number"
          placeholder="e.g.: 5"
          value={props.value && props.value.stars}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleChange(e, 'stars');
          }}
        />
      </Form.Group>
    </div>
  );
}

export default StarsFormatter;
