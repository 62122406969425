import { ActionType } from 'modules/logic_builder/types';

export const displayingNameActions = (actionSTR: ActionType): string => {
  switch (actionSTR) {
    case 'RUN_FUNCTION':
      return 'Run';
    case 'READ_ENTITY':
      return 'Read';
    case 'CREATE_ENTITY':
      return 'Create';
    case 'DELETE_ENTITY':
      return 'Delete';
    case 'SET_VARIABLE':
      return 'Set variable';
    case 'UPDATE_ENTITY':
      return 'Update';
    case 'DECLARE_VARIABLE':
      return 'Declare variable';
    case 'ADD_LIST':
      return 'Add list';
    case 'BEGIN_CYCLE':
      return 'Begin cycle';
    case 'CLEAR_LIST':
      return 'Clear list';
    case 'END_CYCLE':
      return 'End cycle';
    case 'REMOVE_LIST':
      return 'Remove list';
    case 'SAVE_FILE':
      return 'Save file';
    case 'DELETE_FILE':
      return 'Delete file';
    case 'READ_FILE':
      return 'Read file';
    case 'CUSTOM_CODE':
      return 'Custom code';
    case 'CUSTOM_QUERY':
      return 'DB Query';
    default:
      return actionSTR;
  }
};
