import { Action } from 'redux';
import { FunctionsState } from '../../types/functions';

export const CHANGE_RETURN_TYPE = 'CHANGE_RETURN_TYPE';

export interface ChangeReturnTypeAction extends Action {
  type: 'CHANGE_RETURN_TYPE';
  payload: {
    uuid: string;
    returnType: string;
    object: string | null;
    enumId: string | null;
    list: boolean;
  };
}

export const changeReturnType = (
  uuid: string,
  returnType: string,
  object: string | null,
  enumId: string | null,
  list: boolean
): ChangeReturnTypeAction => ({
  type: CHANGE_RETURN_TYPE,
  payload: { uuid, returnType, object, enumId, list }
});

export function doChangeReturnType(
  state: FunctionsState,
  action: ChangeReturnTypeAction
): FunctionsState {
  const { uuid, returnType, object, enumId, list } = action.payload;
  state[uuid].returnType = {
    type: returnType,
    objectUuid: object ?? '',
    enumUuid: enumId ?? '',
    list
  };
  return state;
}
