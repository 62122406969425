import React, { useContext } from 'react';
import LogicBuilderContext from '../../../../store';
import { TagsService } from '../../../../services';
import styles from './styles.module.css';
import { TagItem } from '../tag_item';

type TagEditorProps = {
  // Function callback on selected tag.
  onClick: (tagId: number) => void;
  setSelectedTagId: (id: number) => void;
  setShowEdit: (val: boolean) => void;
  search: string;
};

export function TagEditor(props: TagEditorProps) {
  const { app_id, tags, fetchTags } = useContext(LogicBuilderContext);

  function handleDelete(tagId: number) {
    if (!app_id) return;
    TagsService.deleteTag(app_id, tagId).then(() => {
      fetchTags(app_id);
    });
  }

  function handleEdit(tagId: number) {
    if (!app_id) return;
    props.setSelectedTagId(tagId);
    props.setShowEdit(true);
  }

  return (
    <div className={`card p-2 ${styles.TagsWrapper}`}>
      {Object.values(tags)
        .filter((tag) => tag.name.includes(props.search))
        .map((tag) => {
          return (
            <TagItem
              tag={tag}
              key={tag.id}
              onClick={props.onClick}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          );
        })}
    </div>
  );
}
