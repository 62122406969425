import React, { createContext, useState, useEffect, useContext } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import SessionContext from 'modules/auth/store';
import { useTranslation } from 'react-i18next';
import { Button, Container, Nav, Spinner } from 'react-bootstrap';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import {
  AddOrganization,
  OrganizationApiInfo,
  OrgNavMenu,
  RoleType
} from 'modules/organization/types';
import { OrganizationService } from 'modules/organization/services';
import Navbar from 'web_ui/navbar';
import Icon from 'web_ui/icon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import UpdateOrganizationModal from '../components/update_organization_modal';
import useTitle from 'hooks/useTitle';

import styles from './styles.module.css';

type OrganizationContextType = {
  organization: OrganizationApiInfo | null;
  setOrganization: React.Dispatch<React.SetStateAction<OrganizationApiInfo | null>>;
  roles: RoleType[];
  setRoles: React.Dispatch<React.SetStateAction<RoleType[]>>;
};

const initialState: OrganizationContextType = {
  organization: null,
  setOrganization: () => {},
  roles: [],
  setRoles: () => {}
};

type OrganizationState = {
  org?: OrganizationApiInfo;
};

export const OrganizationContext = createContext<OrganizationContextType>(initialState);

export default function OrganizationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useTitle(t('tab.org'));

  const [organization, setOrganization] = useState<OrganizationApiInfo | null>(null);
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = React.useState<OrgNavMenu>();

  const { org_id } = useParams();
  const session = useContext(SessionContext);

  useTitle(organization?.name ? `${organization.name}` : 'Exocoding', organization);

  useEffect(() => {
    const location_option = location.pathname.split('/').pop();
    if (location_option === 'overview') setSelectedItem(OrgNavMenu.OVERVIEW);
    else if (location_option === 'collaborators' || location_option === 'pending')
      setSelectedItem(OrgNavMenu.MEMBERS);
    else if (location_option === 'roles') setSelectedItem(OrgNavMenu.ROLES);
  }, []);

  useEffect(() => {
    if (location.state) {
      const { org: selectOrganization } = location.state as OrganizationState;
      if (selectOrganization) {
        setOrganization(selectOrganization);
        return;
      }
    }
    if (!organization && org_id) {
      findOrgsById(+org_id);
    }
  }, []);

  /**
   * Fetch organizations from the current user
   */
  const findOrgsById = async (orgId: number) => {
    try {
      setLoading(true);
      const org = await OrganizationService.getOrganizationById(orgId);
      setOrganization(org);
    } catch (error) {
      console.error(error);
      navigate('/organizations');
    } finally {
      setLoading(false);
    }
  };

  function handleSelected(option: string) {
    if (option === OrgNavMenu.OVERVIEW) setSelectedItem(OrgNavMenu.OVERVIEW);
    else if (option === OrgNavMenu.MEMBERS) setSelectedItem(OrgNavMenu.MEMBERS);
    else if (option === OrgNavMenu.ROLES) setSelectedItem(OrgNavMenu.ROLES);
    else if (option === OrgNavMenu.TEAMS) setSelectedItem(OrgNavMenu.TEAMS);
    // else if (option === OrgNavMenu.SETTINGS) setSelectedItem(OrgNavMenu.SETTINGS);

    navigate(`/organization/${org_id}/${option.toLocaleLowerCase()}`);
  }

  const handleCloseModal = React.useCallback(() => {
    setShowModal(false);
    // navigate('/organizations');
  }, []);

  function updateOrg(newOrg: AddOrganization) {
    setOrganization({
      ...organization,
      name: newOrg.name,
      description: newOrg.description
    } as OrganizationApiInfo);
  }

  return (
    <OrganizationContext.Provider value={{ organization, setOrganization, roles, setRoles }}>
      <Navbar context="default" />
      <Container className={`p-4`} style={{ minHeight: 'calc(100vh - 60px)' }}>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        {!isLoading && organization && (
          <>
            <div
              className="bg-body-secondary rounded pe-2 p-3"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div
                className="bg-body-secondary rounded p-3"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  maxWidth: '60%'
                }}
              >
                <h2
                  id={organization.name}
                  className={`ps-3 pt-2 text-body-emphasis ${styles.title}`}
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {organization.name}
                </h2>
                <div style={{ overflow: 'hidden', maxWidth: '50rem' }}>
                  <p
                    id="descriptionField"
                    className={
                      organization.description.length > 77
                        ? `ps-3 pt-2 ${styles.overFlowText}`
                        : `ps-3 pt-2`
                    }
                  >
                    {organization.description}
                  </p>
                </div>
              </div>
              {organization.owner.id === session.user?.id && (
                <HelpPopover
                  placement={'top'}
                  helpBoxProps={{
                    title: t('appResume.Manage')!
                  }}
                >
                  <Button
                    id={'settingsButton'}
                    className="btn-secondary"
                    style={{ height: '2.5rem', marginRight: '2rem' }}
                    onClick={() => setShowModal(true)}
                  >
                    <Icon iconName="gear"></Icon>
                  </Button>
                </HelpPopover>
              )}
            </div>
            <Nav variant="pills" className={`pt-2`}>
              {Object.values(OrgNavMenu).map((option: string) => (
                <Authorization
                  key={option}
                  context={ContextRole.ORGANIZATION}
                  allowedAuthorities={
                    option === OrgNavMenu.MEMBERS
                      ? [RoleAuthorities.INVITE_USER]
                      : option === OrgNavMenu.ROLES
                      ? [RoleAuthorities.CREATE_CUSTOM_ROLE]
                      : undefined
                  }
                >
                  <Nav.Item>
                    <Nav.Link
                      id={`${option.toLowerCase()}Tab`}
                      className={`text-body-emphasis bg-body ${
                        selectedItem === option ? styles.active : ''
                      }`}
                      onClick={() => {
                        handleSelected(option);
                      }}
                    >
                      {t('organizations.organization.organization_options.' + option)}
                    </Nav.Link>
                  </Nav.Item>
                </Authorization>
              ))}
            </Nav>
            <div className="pt-4">
              <Outlet />
            </div>
          </>
        )}
      </Container>
      {organization ? (
        <UpdateOrganizationModal
          showModal={showModal}
          onCloseRequest={handleCloseModal}
          onUpdate={(org) => updateOrg(org)}
          preOrg={organization}
        />
      ) : (
        <></>
      )}
    </OrganizationContext.Provider>
  );
}
