import { FunctionsState } from '../../types/functions';
import { SetFilterAction } from '../parameters/set_filter';

export function doSetFilter(state: FunctionsState, action: SetFilterAction): FunctionsState {
  const { functionUuid, value, filterUuid } = action.payload;
  if (!state[functionUuid]) return state;
  if (value) {
    state[functionUuid].parameters.push(filterUuid);
    state[functionUuid].options.filterable = true;
    return state;
  }

  const filterIndex = state[functionUuid].parameters.indexOf(filterUuid);
  state[functionUuid].parameters.splice(filterIndex, 1);
  state[functionUuid].options.filterable = false;
  return state;
}
