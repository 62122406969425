import { FunctionEditorState, GlobalsState } from './types/function_editor_state';
import { EditorState } from './types/function_editor_state';
import { FunctionsState } from './types/functions';
import { ActionsState } from './types/actions';
import { ParametersState } from './types/parameters';
import { VariablesState } from './types/variables';
import { ObjectsState } from './types/objects';
import { ObjectsItemsState } from './types/object_items';
import { EnumsState } from './types/enums';
import { PageParamsState } from 'modules/designer/studio/store';

export const initialState: FunctionEditorState = {
  editor: {} as EditorState,
  globals: {} as GlobalsState,
  functions: {} as FunctionsState,
  actions: {} as ActionsState,
  parameters: {} as ParametersState,
  variables: {} as VariablesState,
  objects: {} as ObjectsState,
  objects_items: {} as ObjectsItemsState,
  enums: {} as EnumsState,
  page_params: {} as PageParamsState
};
