import React from 'react';
import useSession from 'hooks/useSession';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addParam, deleteParam } from '../../store/actions/params';
import { InterfaceStudioState } from '../../store';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import styles from './styles.module.css';

function ListParams() {
  const [session] = useSession();
  const dispatch = useDispatch();
  const { app_id, view_id } = useParams();
  const [newItem, setNewItem] = React.useState('');
  const referenceBottom = React.useRef<HTMLDivElement>(null);
  const params = useSelector((state: InterfaceStudioState) => state.params);

  const canEditParams = session.user?.roles.some((roleA) => {
    return (
      roleA.context === ContextRole.APP &&
      roleA.contextUuid?.toString() === app_id &&
      roleA?.roleAuthorities.includes(RoleAuthorities.MANAGE_ID_OPERATIONS)
    );
  });

  React.useEffect(() => {
    referenceBottom.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  function handleClick() {
    const allParams: string[] = [];
    Object.keys(params).forEach((uuid) => {
      allParams.push(params[uuid].name);
    });
    const mountingRoute = allParams.toString().replaceAll(',', '');
    dispatch(
      addParam({
        uuid: uuidv4(),
        name: newItem,
        native: false,
        viewid: view_id ?? '',
        route: mountingRoute,
        order: Object.keys(params).length
      })
    );
    setNewItem('');
  }

  function handleDelete(index: string, name: string) {
    if (view_id) {
      dispatch(deleteParam(index, view_id));
    }
  }

  return (
    <div className="mb-3">
      <div
        id="formLabel"
        className={`${styles.defaultLabel} form-label`}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '0.5rem'
        }}
      >
        {'Params'}
      </div>
      <ul style={{ overflow: 'scroll', maxHeight: 140, overflowX: 'hidden' }}>
        {params &&
          Object.keys(params).map((index) => {
            return (
              <React.Fragment key={index}>
                {params[index].name && (
                  <li key={`${index}-${index}`}>
                    {canEditParams && (
                      <>
                        <i
                          className="fa fa-trash text-danger"
                          onClick={() => handleDelete(index, params[index].name)}
                        />{' '}
                      </>
                    )}
                    {params[index].name}
                  </li>
                )}
              </React.Fragment>
            );
          })}
        <div ref={referenceBottom} />
      </ul>
      <Authorization
        context={ContextRole.APP}
        allowedAuthorities={[RoleAuthorities.MANAGE_ID_OPERATIONS]}
      >
        <div className="input-group input-group-sm mb-3">
          <input
            id="inputValue"
            type="text"
            className="form-control"
            onChange={(e) => setNewItem(e.target.value)}
            value={newItem}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={handleClick}
            disabled={!newItem}
          >
            Add
          </button>
        </div>
      </Authorization>
    </div>
  );
}

export default ListParams;
