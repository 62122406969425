import { Action } from 'redux';
import { EditorMode, FunctionEditorState } from '../../types/function_editor_state';

export const SET_INITIAL_FUNCTION_EDITOR_STATE = 'SET_INITIAL_FUNCTION_EDITOR_STATE';

export interface SetInitialFunctionEditorStateAction extends Action {
  type: 'SET_INITIAL_FUNCTION_EDITOR_STATE';
  state: FunctionEditorState;
  mode: EditorMode;
}

export const setInitialFunctionEditorState = (
  state: FunctionEditorState,
  mode: EditorMode
): SetInitialFunctionEditorStateAction => ({
  type: SET_INITIAL_FUNCTION_EDITOR_STATE,
  state,
  mode
});

export function doSetInitialFunctionEditorState(
  state: FunctionEditorState,
  action: SetInitialFunctionEditorStateAction
): FunctionEditorState {
  state = {
    ...action.state,
    editor: {
      ...action.state.editor,
      mode: action.mode
    }
  };
  return state;
}
