import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import React from 'react';
import EndCycleIcon from './end_cycle_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { t } from 'i18next';
import EndIfIcon from './end_if_icon';

export const END_IF_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.END_IF,
  name: 'EndIf',
  description: 'Action to close an "IF" statement',
  color: 'secondary',
  category: BACKEND_ACTIONS_CATEGORIES.CONDITION,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.CONDITION,
  icon: <EndIfIcon />,
  conditionStatement: true,
  closeBlock: true,
  inlineInputs: [],
  inputs: []
};
