import { Page } from '../types';
import { PageRepository } from '../repos';
import { CrudPage } from '../../logic_builder/types';
import { DuplicateViewInfo } from '../studio/toolbars/views_toolbar/duplicate_page_modal';

export class PageServ {
  async createPage(
    module_id: string,
    pageName: string,
    pageRoute: string,
    pageDescription: string,
    pageLayout: string,
    template: string,
    folderId: string
  ) {
    return await PageRepository.createPage(
      module_id,
      pageName,
      pageRoute,
      pageDescription,
      pageLayout,
      template,
      folderId
    );
  }

  async createPageAutomationCrud(
    pages: CrudPage[],
    moduleId: string,
    entityId: string
  ): Promise<void> {
    return await PageRepository.createPageAutomationCrud(pages, moduleId, entityId);
  }

  async duplicatePage(moduleId: string, duplicateView: DuplicateViewInfo) {
    return await PageRepository.duplicatePage(moduleId, duplicateView);
  }

  async getPagesByModule(moduleId: string) {
    return await PageRepository.getPagesByModule(moduleId);
  }

  async getPage(pageId: string) {
    return await PageRepository.getPage(pageId);
  }

  async updatePage(pageId: string, updatedPage: Page) {
    return await PageRepository.updatePage(pageId, updatedPage);
  }

  async movePage(pageId: string, moduleId: string, folderId: string) {
    return await PageRepository.movePage(pageId, moduleId, folderId);
  }

  async deletePage(pageId: string) {
    return await PageRepository.deletePage(pageId);
  }
}
