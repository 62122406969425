import { Action } from 'redux';
import { WidgetsState } from '..';
import { Widget, WidgetUUID } from 'web_ui/workboard/widgets/types';

export const SET_WIDGETS = 'SET_WIDGETS';
export const ADD_WIDGET = 'ADD_WIDGET';
export const CHANGE_WIDGET = 'CHANGE_WIDGET';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const UPDATE_WIDGET_POSITION = 'UPDATE_WIDGET_POSITION';

export interface SetWidgetsAction extends Action {
  type: typeof SET_WIDGETS;
  payload: {
    widgets: WidgetsState;
  };
}

export interface AddWidgetAction extends Action {
  type: typeof ADD_WIDGET;
  payload: {
    widget: Widget;
  };
}

export interface ChangeWidgetAction extends Action {
  type: typeof CHANGE_WIDGET;
  payload: {
    widget: Widget;
  };
}

export interface UpdateWidgetPositionAction extends Action {
  type: typeof UPDATE_WIDGET_POSITION;
  payload: {
    uuid: WidgetUUID;
    posX: number;
    posY: number;
  };
}

export interface DeleteWidgetAction extends Action {
  type: typeof DELETE_WIDGET;
  payload: {
    uuid: WidgetUUID;
  };
}

export const setWidgets = (widgets: WidgetsState): SetWidgetsAction => ({
  type: SET_WIDGETS,
  payload: { widgets }
});

export const addWidget = (widget: Widget): AddWidgetAction => ({
  type: ADD_WIDGET,
  payload: { widget }
});

export const changeWidget = (widget: Widget): ChangeWidgetAction => ({
  type: CHANGE_WIDGET,
  payload: { widget }
});

export const updateWidgetPosition = (
  uuid: WidgetUUID,
  posX: number,
  posY: number
): UpdateWidgetPositionAction => ({
  type: UPDATE_WIDGET_POSITION,
  payload: {
    uuid,
    posX,
    posY
  }
});

export const deleteWidget = (uuid: WidgetUUID): DeleteWidgetAction => ({
  type: DELETE_WIDGET,
  payload: { uuid }
});
