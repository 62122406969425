import { TemplateAutomationData } from 'routes/automation_wizard/components/wizard_steps/templates';
import { ModelGeneratorAutomationData } from 'routes/automation_wizard/components/wizard_steps/model_generator';
import { CrudData } from '../../../routes/automation_wizard/components/wizard_steps/crud';
import { ImportedObject } from '../../../routes/automation_wizard/types';
import { Errors } from '../types';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class AutomationsRepo {
  /**
   * Automatically create CRUD methods for a specific entity.
   */
  async createCrudAutomation(moduleId: string, crudSchema: CrudData, allowOverwrite = false) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(crudSchema)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud?allowOverwrite=${allowOverwrite}`;

    return await fetch(url, options).then((response) => {
      if (response.status === 201) return;

      if (response.status === 409) {
        throw new Error(Errors.CONFLICT);
      } else {
        throw new Error('Something went wrong while trying to create a CRUD automation.');
      }
    });
  }

  /**
   * Automatically create objects based
   * on the created entities.
   */
  async createObjectsAutomation(moduleId: string, importedObject: ImportedObject) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(importedObject)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/import-objects`;

    return await fetch(url, options).then((response) => {
      if (response.status === 201) return;

      throw new Error('Something went wrong while trying to import Objects.');
    });
  }

  /**
   * Automatically create templates based on the endpoints selected.
   */
  async createTemplatesAutomation(
    moduleId: string,
    templatesAutomationData: TemplateAutomationData
  ) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(templatesAutomationData)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/create-templates`;

    return await fetch(url, options).then((response) => {
      if (response.status === 201) return;

      throw new Error('Something went wrong while trying to create entities.');
    });
  }

  /**
   * Automatically create entities.
   */
  async modelGeneratorAutomation(
    appId: string,
    moduleId: string,
    data: ModelGeneratorAutomationData
  ) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(data)
    };

    const url = `${API_URL}/apps/${appId}/modules/${moduleId}/automations/generate-model-ai`;

    return await fetch(url, options).then((response) => {
      switch (response.status) {
        case 201:
          return;
        case 403:
          throw new Error('internalQuotaExceededException');
        case 400:
          throw new Error('externalKeyQuotaExceededException');
        case 401:
          throw new Error('externalKeyUnauthorizedException');
        default:
          throw new Error('genericErrorModelGenerator');
      }
    });
  }
}
