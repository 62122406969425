import React, { useContext, useEffect, useState } from 'react';
import {
  Column,
  Index,
  Relationship,
  sortOrder,
  EnumColumn,
  Table,
  TableUUID,
  EnumFrame
} from '../../../modules/modeler/types';
import { useNavigate, useParams } from 'react-router-dom';
import { DataService } from '../../../modules/modeler/services';
import { indexColumnState } from 'modules/modeler/studio/store';
import DatabaseStudio from 'modules/modeler/studio';
import { WidgetsState } from 'modules/designer/studio/store';
import AnimatedLoading from 'web_ui/animated_loading';
import { ModuleInfo } from 'modules/dashboard/types';
import { ApiAppInfo } from 'modules/project/types';
import { useTranslation } from 'react-i18next';
import { ProjectsService } from 'modules/project/services';
import useTitle from 'hooks/useTitle';
import { AppContext } from 'modules/project/store/app_context';

export type DatabaseStudioPageProps = {
  toggleTheme(): void;
};

export type SchemaParams = {
  module_id: TableUUID;
  schema_id: TableUUID;
  app_id: string;
};

function DatabaseStudioPage(props: DatabaseStudioPageProps) {
  const [tables, setTables] = useState<Table[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [indexes, setIndex] = useState<Index[]>([]);
  const [enums, setEnums] = useState<EnumFrame[]>([]);
  const [enumColumns, setEnumColumns] = useState<EnumColumn[]>([]);
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [widgets, setWidgets] = useState<WidgetsState>({});
  const { module_id, schema_id, app_id } = useParams<SchemaParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const appInfo = useContext(AppContext).projectInformation;

  useEffect(() => {
    if (appInfo && !appInfo.has_database) navigate('/homepage');
  }, [appInfo]);

  useEffect(() => {
    const fetchSchema = async () => {
      if (!module_id) {
        return;
      }
      setLoading(true);
      // TODO: use types for schema (DataService)
      const schema = await DataService.getSchema(module_id).finally(() => setLoading(false));
      setTables(schema.tables && Object.keys(schema).length !== 0 ? schema.tables : []);
      setColumns(schema.columns && Object.keys(schema).length !== 0 ? schema.columns : []);
      setEnums(schema.enums && Object.keys(schema).length !== 0 ? schema.enums : []);
      setEnumColumns(
        schema.enumColumns && Object.keys(schema).length !== 0 ? schema.enumColumns : []
      );
      setRelationships(
        schema.relationships && Object.keys(schema).length !== 0 && schema.relationships
          ? schema.relationships
          : []
      );
      setWidgets(schema.widgets);
      let schemaIndexColumns: indexColumnState = {};
      const schemaIndexes: Index[] = [];
      // TODO: use types SchemaIndex, IndexColumn
      if (schema.indexes) {
        for (const index of schema.indexes) {
          for (const columnIndex of index.columns) {
            schemaIndexColumns[columnIndex.id] = {
              id: columnIndex.id,
              columnId: columnIndex.columnId,
              sortOrder: columnIndex.sortOrder as sortOrder,
              columnOrder: columnIndex.columnOrder
            };
          }
          const contextIndex: Index = {
            id: index.id,
            name: index.name,
            description: index.description,
            type: index.type,
            columns: schemaIndexColumns
          };
          schemaIndexes.push(contextIndex);
          schemaIndexColumns = {};
        }
      }

      setIndex(
        schema.indexes && Object.keys(schema).length !== 0 && schemaIndexes ? schemaIndexes : []
      );
    };
    fetchSchema();
  }, [module_id]);

  if (!module_id) return null;

  if (!module_id || !schema_id) return null;

  enumColumns.sort((itemA, itemB) => Number(itemA.literalValue) - Number(itemB.literalValue));
  return loading ? (
    <AnimatedLoading />
  ) : (
    <DatabaseStudio
      module_id={module_id}
      schema_id={schema_id}
      tables={tables}
      columns={columns}
      indexes={indexes}
      relationships={relationships}
      enums={enums}
      enumColumns={enumColumns}
      widgets={widgets}
      toggleTheme={props.toggleTheme}
    />
  );
}

export default DatabaseStudioPage;
