import { useEffect, useState } from 'react';
import { DataRepo } from '../../../../modules/modeler/repos';
import { EnumFrame } from '../../../../modules/modeler/types';
import { useParams } from 'react-router-dom';

function useFetchEnums() {
  const { module_id } = useParams();
  const [enums, setEnums] = useState<EnumFrame[]>([]);

  useEffect(() => {
    const fetchObjects = async () => {
      const e = await DataRepo.getEnums(module_id ?? '');
      setEnums(e);
    };
    fetchObjects().then(() => {});
  }, [module_id]);

  return enums;
}

export default useFetchEnums;
