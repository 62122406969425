import { FunctionEditorState } from '../../store/types/function_editor_state';
import { ObjectsRepository } from '../../../../modules/logic_builder/repos';
import { DataRepo } from '../../../../modules/modeler/repos';
import { Column, Table } from '../../../../modules/modeler/types';

export async function filterByPrimaryKey(
  state: FunctionEditorState,
  actionUuid: string,
  dataKey: string,
  dataType: string,
  objectUuid: string
): Promise<boolean> {
  const entityUuid = await findEntityId(state, actionUuid, dataKey);
  if (!entityUuid) return false;

  const pks = await findEntityPks(state, entityUuid as string);
  if (!pks) return false;

  // Simple PK: filter by primary key type
  // Composed PK: filter by object with all the primary keys and nothing else
  return pks.length === 1
    ? filterByPkType(dataType, pks[0])
    : await filterByPkObject(
        objectUuid,
        pks.map((c) => c.uuid)
      );
}

async function findEntityId(
  state: FunctionEditorState,
  actionUuid: string,
  dataKey: string
): Promise<string | boolean> {
  // find objectId
  const objectId = state.actions[actionUuid]?.data[dataKey];
  if (!objectId) return false;

  // fetch object
  const obj = await ObjectsRepository.getObject(objectId);
  if (!obj) return false;

  // find entityUuid
  const entityUuid = obj?.entityUuid;
  if (!entityUuid) return false;
  return entityUuid;
}

async function findEntityPks(state: FunctionEditorState, entityUuid: string): Promise<Column[]> {
  // fetch entity ==> must use 'schema' endpoint to get it
  const { tables, columns } = await DataRepo.getSchema(state.editor.moduleId);
  if (!tables || !columns) return [];
  const entity: Table = tables.filter((t: Table) => t.uuid === entityUuid)[0];
  if (!entity) return [];

  // find primary key(s)
  const pks: Column[] = columns.filter((c: Column) => c.tableUUID === entityUuid && c.isPK);
  if (!pks) return [];
  return pks;
}

function filterByPkType(dataType: string, pk: Column) {
  return dataType?.toUpperCase() === pk?.type?.toUpperCase();
}

/**
 * The `objectUuid` must be of an object created by automation (native: true)
 * whose fields are exactly the PK columns from the `entityUuid`
 *
 * @param objectUuid
 * @param pks
 * @return Promise<boolean>
 */
async function filterByPkObject(objectUuid: string, pks: string[]): Promise<boolean> {
  const obj = await ObjectsRepository.getObject(objectUuid);
  if (!obj) return false;
  // if (!obj.native) return false;
  if (!Object.values(obj?.items)) return false;
  for (const item of Object.values(obj.items)) {
    if (!item.columnUuid) return false;
    if (!pks.includes(item.columnUuid)) return false;
  }
  return true;
}
