import * as React from 'react';
import { EndCycleActionType, ARGUMENT_TYPES } from '../../types';
import {
  Action,
  FunctionParameter,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import ArgumentPicker from '../ArgumentPicker';

type Props = {
  newAction: Action<EndCycleActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<EndCycleActionType>>>;
  objects: ObjectSimple[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
};

export default function RenderEndCycle(props: Props) {
  return (
    <ArgumentPicker
      allowDataTypes={[]}
      allowFixed={false}
      allowParams={false}
      allowVars={true}
      allowObject={false}
      label="Select Variable to return"
      onChange={(value, typeChosen) => {
        props.onChange((currentVal) => {
          const auxVal = { ...currentVal };
          auxVal.returnVariableUuid = value;
          return auxVal; // override the action
        });
      }}
      value={props.newAction.returnVariableUuid}
      variables={props.variables}
      params={props.parameters}
      objects={props.objects}
      onlyList={false}
      currentType={ARGUMENT_TYPES.VAR}
    />
  );
}
