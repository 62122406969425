import {
  EndpointResponseSimple,
  Method,
  ParameterEndpoint
} from '../../../../../../modules/logic_builder/types';
import { EndpointsRepository } from '../../../../../../modules/logic_builder/repos';

export type PickedEndpoint = {
  uuid: string;
  method: Method;
  name: string;
  pathParams: { [key: string]: ParameterEndpoint };
  queryParams: { [key: string]: ParameterEndpoint };
  request: ParameterEndpoint;
  response: EndpointResponseSimple;
};

export async function getExtendedEndpoint(endpointId: string) {
  if (!endpointId) return;
  const extended = await EndpointsRepository.getEndpoint(endpointId);
  if (!extended) return;

  const pickedEndpoint: PickedEndpoint = {
    uuid: '',
    method: 'GET',
    name: '',
    pathParams: {},
    queryParams: {},
    request: {} as ParameterEndpoint,
    response: {} as EndpointResponseSimple
  };
  pickedEndpoint.uuid = extended.uuid;
  pickedEndpoint.name = extended.name;
  pickedEndpoint.method = extended.method;

  for (const p of extended.parameters) {
    if (p.inputType === 'PATH') {
      pickedEndpoint.pathParams[p.uuid ?? ''] = p;
    }
    if (p.inputType === 'QUERY') pickedEndpoint.queryParams[p.uuid ?? ''] = p;
    if (p.inputType === 'BODY') pickedEndpoint.request = p;
  }

  const responses = await EndpointsRepository.getEndpointResponses(endpointId);
  if (!responses) {
    return pickedEndpoint;
  }
  for (const resp of responses) {
    if (
      resp.httpStatus.toString() === '200' ||
      resp.httpStatus.toString() === '201' ||
      resp.httpStatus.toString() === '204'
    ) {
      pickedEndpoint.response = resp;
      break;
    }
  }
  return pickedEndpoint;
}
