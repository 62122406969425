import React, { ReactNode, useEffect, useRef } from 'react';
import styles from './styles.module.css';

type ActionWrapperProps = {
  children: ReactNode;
  index: number;
  selected: boolean;
  handleDrop: (e: React.DragEvent, index: number) => void;
  handleSelect: (index: number) => void;
  indentation?: number;
};

function ActionWrapper({
  children,
  index,
  selected,
  indentation = 5,
  handleDrop,
  handleSelect
}: ActionWrapperProps) {
  const actionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selected) {
      actionRef?.current && actionRef?.current.focus();
    }
  }, [selected]);

  const handleDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData('exocode/dragged-index', String(index));
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  return (
    <div
      className={`${styles.actionWrapper} rounded-3`}
      style={{
        border: `${selected ? '1px solid white' : ''}`,
        marginLeft: `${indentation * 20}px`
      }}
      draggable
      onDragStart={handleDragStart}
      onDrop={(e) => handleDrop(e, index)}
      onDragOver={handleDragOver}
      onClick={() => handleSelect(index)}
    >
      {children}
    </div>
  );
}

export default ActionWrapper;
