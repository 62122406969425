import { PAGE_MANIFEST } from './page_view/manifest';
import { MODAL_MANIFEST } from './modal_view/manifest';
import { ViewManifest } from '../../types';
import { LAYOUT_MANIFEST } from './layout_view';
import { CUSTOM_COMPONENT_MANIFEST } from './custom_component_view';

export enum VIEWS_TYPES {
  PAGE = 'PAGE',
  MODAL = 'MODAL',
  LAYOUT = 'LAYOUT',
  CUSTOMCOMPONENTS = 'CUSTOMCOMPONENTS'
}

export const VIEWS_MANIFEST: { [key: string]: ViewManifest } = {
  [VIEWS_TYPES.PAGE]: PAGE_MANIFEST,
  [VIEWS_TYPES.MODAL]: MODAL_MANIFEST,
  [VIEWS_TYPES.LAYOUT]: LAYOUT_MANIFEST,
  [VIEWS_TYPES.CUSTOMCOMPONENTS]: CUSTOM_COMPONENT_MANIFEST
};
