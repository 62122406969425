import { Form } from 'react-bootstrap';
import styles from './styles.module.css';
import React, { useEffect, useState } from 'react';
import { Relationship } from '../../../../../../../types';
import { useDispatch } from 'react-redux';
import { changeProperty } from '../../../../../../store/actions/relationship';
import { useTranslation } from 'react-i18next';

type DescriptionInputProps = {
  relationship: Relationship;
};
export default function DescriptionInput({ relationship }: DescriptionInputProps) {
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => setDescription(relationship.description), [relationship.id]);

  const handleChangeDescription = () => {
    dispatch(changeProperty(relationship.id, 'description', description));
  };

  return (
    <Form.Group className="mb-2" controlId="relationDescription">
      <Form.Label className={styles.SmallFont}>{t('Description') ?? ''}</Form.Label>
      <Form.Control
        value={description}
        placeholder={t('Description') ?? ''}
        as={'textarea'}
        rows={2}
        size="sm"
        onBlur={(e) => handleChangeDescription()}
        onChange={(e) => setDescription(e.target.value)}
      />
    </Form.Group>
  );
}
