import React, { useEffect, useState } from 'react';
import { ControlProps } from '..';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { Badge, Form } from 'react-bootstrap';
import { SchemaObject } from '../../../../../modules/logic_builder/types';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../../../../modules/designer/studio/store';

function ListSortControl(props: ControlProps) {
  const { t } = useTranslation();
  const variables = useSelector((state: InterfaceStudioState) => state.variables);
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const [variableObject, setVariableObject] = useState<SchemaObject | null>(null);

  useEffect(() => {
    if (!props?.value?.variable?.uuid) return;
    const varId = props.value.variable.uuid;
    const variable = variables[varId];
    if (!variable?.objectUuid) return;
    const parentObj = objects[variable.objectUuid];
    if (props.value?.variable?.objectItem) {
      const objItemId = props.value.variable.objectItem;
      if (parentObj?.objectItems === undefined || parentObj?.objectItems?.length === 0) return;
      const objItem = parentObj.objectItems.filter((i) => i.uuid === objItemId)[0];
      //TODO: check this in backend, as the type in FE differs from the data sent by the API
      // @ts-ignore
      const targetObjId = objItem?.object.id ?? '.';
      const targetObj = objects[targetObjId];
      setVariableObject(targetObj);
    } else {
      setVariableObject(parentObj);
    }
  }, [props.value, variables, objects]);

  function handleSelectKeyItemAttributes(attrName: string) {
    const sortBy = props.value.sortBy
      ? props.value.sortBy.includes(attrName)
        ? props.value.sortBy.filter((keyItemName: string) => keyItemName !== attrName)
        : [...props.value.sortBy, attrName]
      : [attrName];

    props.onChange && props.onChange(sortBy, 'sortBy');
  }

  return (
    <div className="mb-3 pb-2 border-bottom text-body-secondary">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div style={{ display: 'flex' }}>
          <label className="position-relative mb-1 text-body">{props.label}</label>
          <HelpPopover
            helpBoxProps={{
              title:
                t('designer.right_side.controls.ListSortTitle') ||
                'designer.right_side.controls.ListSortTitle',
              description:
                t('designer.right_side.controls.ListSortDescription') ||
                'designer.right_side.controls.ListSortDescription',
              note: [t('designer.right_side.controls.SelectSortAttributeDescription')]
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </div>
        <Form.Check
          type="switch"
          id="custom-switch"
          checked={props.value?.sortable}
          value={props.value?.sortable}
          onChange={(e) => {
            props.onChange && props.onChange(e.target.checked, 'sortable');
          }}
          disabled={!variableObject}
        />
      </div>

      {props.value?.sortable && (
        <div>
          <div className="mb-3">
            <div style={{ display: 'flex' }}>
              <Form.Label>{t('designer.right_side.controls.SelectSortAttribute')}</Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.controls.SelectSortAttribute') ||
                    'designer.right_side.controls.SelectSortAttribute',
                  description:
                    t('designer.right_side.controls.SelectSortAttributeDescription') ||
                    'designer.right_side.controls.SelectSortAttributeDescription'
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
            </div>

            <div>
              {variableObject &&
                variableObject!.objectItems &&
                variableObject.objectItems.map((item, index: number) => (
                  <p
                    key={index}
                    className="d-inline me-2"
                    onClick={(e) => handleSelectKeyItemAttributes(item.name)}
                  >
                    <Badge
                      bg={`${
                        props.value.sortBy && props.value.sortBy.includes(item.name)
                          ? 'primary'
                          : 'secondary'
                      }`}
                      style={{ cursor: 'pointer' }}
                    >
                      {item.name}
                    </Badge>
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListSortControl;
