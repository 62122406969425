import React from 'react';
import TypePicker, { TYPE_PICKER_TYPES } from '../argument_picker/type_picker';
import ValuePicker from '../argument_picker/value_picker';
import ObjectNodePicker from '../../components/object_node_picker';
import { Argument } from '../../../store/types/manifestsAndInputs';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { ViewParam } from './hooks/useGetExtendedView';
import { PRIMITIVE_TYPES } from 'web_ui/function_editor/store/types/variables';
import { DATA_TYPES } from 'modules/logic_builder/types';
import useCheckValueIsObject from '../argument_picker/useCheckValueIsObject';
import { AllowedModes } from './index';

type ViewParameterEditorProps = {
  param: ViewParam;
  paramValue: Argument;
  handleChange: (paramId: string, value: Argument) => void;
  actionUuid: string;
  mode: AllowedModes;
  isFromSideBar?: boolean;
};

function ViewParameterEditor({
  paramValue,
  param,
  handleChange,
  actionUuid,
  mode,
  isFromSideBar
}: ViewParameterEditorProps) {
  const { isObject, objectUuid } = useCheckValueIsObject(paramValue.type, paramValue.value);

  const handleChangeType = (t: TYPE_PICKER_TYPES) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { type: t, value: '', objectNode: '' });
  };

  const handleChangeValue = (v: string) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { ...paramValue, value: v, objectNode: '' });
  };

  const handleChangeObjectNode = (o: string) => {
    if (!param.uuid) return;
    handleChange(param.uuid, { ...paramValue, objectNode: o });
  };

  const filterByDataType = (state: FunctionEditorState, actionUuid: string, dataType: string) => {
    if (!dataType) return false;
    const allowedDataTypes = getAllowedDataTypesByViewType(actionUuid, state);
    if (paramValue?.type === 'PAGE_PARAM') return param.type.toUpperCase() !== 'OBJECT';

    return allowedDataTypes.includes(dataType.toUpperCase());
  };

  const getAllowedDataTypesByViewType = (actionUuid: string, state: FunctionEditorState) => {
    if (!actionUuid || !state) return [];
    return Object.values(isURLParam(actionUuid, state) ? PRIMITIVE_TYPES : DATA_TYPES);
  };

  const isURLParam = (actionUuid: string, state: FunctionEditorState) => {
    const URLParamsActions = ['NAVIGATE', 'TOGGLE_MODAL'];
    return URLParamsActions.includes(state.actions[actionUuid].type);
  };

  return (
    <div className={isFromSideBar ? '' : 'd-flex align-items-end flex-wrap'}>
      <p className={'text-secondary-emphasis mb-0 me-1'}>
        {param?.name}
        {param.required && '*'} ={' '}
      </p>
      <TypePicker
        placeholder={'Select argument type'}
        label={''}
        value={paramValue?.type}
        handleChange={handleChangeType}
        argumentTypes={
          param?.uuid
            ? mode !== 'modal'
              ? [
                  TYPE_PICKER_TYPES.VAR,
                  TYPE_PICKER_TYPES.PARAM,
                  TYPE_PICKER_TYPES.FIXED,
                  TYPE_PICKER_TYPES.PAGEPARAM
                ]
              : [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.FIXED, TYPE_PICKER_TYPES.PAGEPARAM]
            : []
        }
        isFromSideBar={isFromSideBar}
      />
      <ValuePicker
        type={paramValue?.type}
        label={''}
        actionUuid={actionUuid}
        filterByList={param?.list}
        filterByDataType={filterByDataType}
        filterByGlobalVars={mode === 'modal'}
        value={paramValue?.value}
        handleChange={handleChangeValue}
        isFromSideBar={isFromSideBar}
      />

      {paramValue.value.length > 0 && isObject && (
        <ObjectNodePicker
          objectUuid={objectUuid}
          filterByList={param?.list}
          value={paramValue?.objectNode ?? ''}
          handleChange={handleChangeObjectNode}
          isFromSideBar={isFromSideBar}
        />
      )}
    </div>
  );
}

export default ViewParameterEditor;
