import { DashboardRepo } from '../repos';
import { ModuleDependencyInfo, ModuleSettings } from '../types';

export default class Dashboard {
  async getModulesByApp(appId: string) {
    return DashboardRepo.getModulesByApp(appId);
  }

  async getModuleDependencies(moduleId: string) {
    return DashboardRepo.getModuleDependencies(moduleId);
  }

  async getModuleAvailableDependencies(moduleId: string, currentSelection: ModuleDependencyInfo[]) {
    return DashboardRepo.getModuleAvailableDependencies(moduleId, currentSelection);
  }

  async createModuleSettings(moduleId: string, configuration: ModuleSettings) {
    return DashboardRepo.createModuleSettings(moduleId, configuration);
  }

  async updateModuleSettings(moduleId: string, configuration: ModuleSettings[]) {
    return DashboardRepo.updateModuleSettings(moduleId, configuration);
  }

  async getModuleSettings(moduleId: string) {
    return DashboardRepo.getModuleSettings(moduleId);
  }
}
