import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { SiGithubactions } from 'react-icons/si';
import Icon from '../../../../../web_ui/icon';
import { columnID, IndexID, IndexType, TableUUID } from '../../../types';
import { v4 as uuidv4 } from 'uuid';
import { deleteTable, dupIndex, dupIndexColumn, duplicateTable } from '../../store/actions/root';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DatabaseStudioState } from '../../store';
import Confirmation from '../../../../../web_ui/confirmation';
import { setSelectedFrame } from '../../store/actions/studio';
import styles from './styles.module.css';

type TableActionsDropdownProps = {
  // selected table id
  tableID: TableUUID | null;
  // whether the dropdown should be disabled (defaults to false)
  disabled?: boolean;
};

export default function TableActionsDropdown(props: TableActionsDropdownProps) {
  // context tables array
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  // context columns array
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  // context indexes array
  const indexes = useSelector((state: DatabaseStudioState) => state.indexes);
  // Used to show or hide the delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  function makeDuplicateTableName(oldName: string): string {
    const duplicateSuffix = '_COPY';
    for (
      let newName = oldName + duplicateSuffix, i = 1;
      ;
      newName = oldName + duplicateSuffix + '_' + i++
    ) {
      // Check for duplicates
      const isDuplicate =
        Object.values(tables).filter((table) => {
          const name = table.content.data.name as string;
          return name === newName;
        }).length > 0;
      if (!isDuplicate) {
        return newName;
      }
    }
  }

  function getRandomInt(minimum: number, range: number): number {
    return Math.floor(minimum + Math.random() * range);
  }

  const handleDuplicateTable = () => {
    if (!props.tableID) {
      return;
    }
    const oldTable = tables[props.tableID];

    // Select which columns to copy and set their new Ids
    const newColumns: Record<columnID, columnID> = {};
    const tableColumns: string[] = oldTable.content.data.columns;
    tableColumns
      .map((columnId) => columns[columnId])
      .filter((column) => !column.isFK) // exclude all FKs
      .forEach((column) => {
        newColumns[column.uuid] = uuidv4();
      });

    // Select which indexes to copy and define their new Ids (Indexes / IndexColumns)
    const newIndexes: Record<IndexID, dupIndex> = {};
    const tableIndexes: string[] = oldTable.content.data.indexes;
    tableIndexes
      .map((indexId) => indexes[indexId])
      .filter((index) => index.type === IndexType.PRIMARY) //only primary indexes
      .forEach((index) => {
        const newIndexColumns: Record<string, dupIndexColumn> = {};

        Object.values(index.columns).forEach((indexColumn) => {
          newIndexColumns[indexColumn.id] = {
            indexColumnId: uuidv4(),
            columnId: newColumns[indexColumn.columnId]
          } as dupIndexColumn;
        });

        newIndexes[index.id] = {
          indexId: uuidv4(),
          indexColumns: newIndexColumns
        } as dupIndex;
      });

    // Define the new table
    const newTable = {
      uuid: uuidv4(),
      type: 'TABLE',
      name: makeDuplicateTableName(oldTable.content.data.name),
      description: oldTable.content.description ?? '',
      posX: getRandomInt(oldTable.posX ? oldTable.posX + 400 : 0, 200),
      posY: getRandomInt(oldTable.posY ? oldTable.posY + 40 : 0, 100),
      indexes: newIndexes,
      columns: newColumns
    };
    // Dispatch this event to close open editors
    const openFrameEditorEvent = new Event('openFrameEditor');
    document.dispatchEvent(openFrameEditorEvent);

    // Dispatch duplicate action
    dispatch(duplicateTable(newTable));
    dispatch(setSelectedFrame(newTable.uuid));
  };

  function handleDeleteTable() {
    if (!props.tableID) return;
    // set selected frame as null and delete the current table/frame
    dispatch(setSelectedFrame(null));
    dispatch(deleteTable(props.tableID));
    setShowDeleteModal(false);
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="primary"
          id="actionsButton"
          className={`d-flex align-items-center ${styles.button}`}
          disabled={props?.disabled ?? false}
        >
          <SiGithubactions
            color="white"
            size={24}
            style={{ marginRight: 5 }}
            className={styles.icons}
          />
          {t('Actions')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item id="duplicateButton" href="#/action-1" onClick={handleDuplicateTable}>
            <Icon iconName="clone me-2"></Icon>
            {t('modeler.Duplicate')}
          </Dropdown.Item>
          <Dropdown.Item
            id="deleteButton"
            href="#/action-2"
            onClick={() => setShowDeleteModal(true)}
          >
            <Icon iconName="trash me-2"></Icon>
            {t('Delete')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Confirmation
        show={showDeleteModal}
        onConfirmation={handleDeleteTable}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        message={
          t('modeler.Delete Table') +
          ' (' +
          (props.tableID && tables[props.tableID] ? tables[props.tableID].content.data.name : '') +
          ')'
        }
      />
    </>
  );
}
