import React from 'react';
import { TableAction } from '../../../data_table';
import { IconList } from '../../../../../../../packages/react-fa-icon-picker';
import ActionIcon from './action_icon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type ActionsBarProps = {
  actions?: TableAction[];
};

function ActionsBar({ actions }: ActionsBarProps) {
  if (!actions || actions.length < 1) return null;
  return (
    <div id={'actions-bar'} className={'w-100 d-flex justify-content-end gap-1'}>
      {actions.map((a, i) => {
        const icon: IconList = (a.icon as IconList) ?? 'FaRegCaretSquareDown';
        return (
          <HelpPopover
            helpBoxProps={{
              title: a.title
            }}
            placement="top"
            key={i}
          >
            <button
              style={{ border: 'none', fontSize: '1rem' }}
              className={`text-${a.variant} bg-${a.variant}-subtle rounded p-1 d-flex justify-content-center shadow-sm`}
            >
              <ActionIcon icon={icon} style={{ height: `${a.iconHeight}` }} />
            </button>
          </HelpPopover>
        );
      })}
    </div>
  );
}

export default ActionsBar;
