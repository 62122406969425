import React from 'react';

interface TrafficLightFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function TrafficLightFormatterPreview({
  formatterParams,
  value
}: TrafficLightFormatterPreviewProps) {
  return (
    <>
      {value && (
        <div
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '50px',
            backgroundColor: '#AA2200'
          }}
        ></div>
      )}
    </>
  );
}

export default TrafficLightFormatterPreview;
