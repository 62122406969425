import React, { useState } from 'react';
import { Step1 } from './step1';
import { Step2 } from './step2';
import Navbar from 'web_ui/navbar';
import { Step3 } from './step3';
import useSession from 'hooks/useSession';
import { AuthService } from 'modules/auth/services';
import { Container } from 'react-bootstrap';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';

interface NavigateWelcomeButtonsProps {
  answered: boolean;
  onStepChange: (step: number) => void;
  currentStep: number;
}

export const NavigateWelcomeButtons: React.FC<NavigateWelcomeButtonsProps> = ({
  answered,
  onStepChange,
  currentStep
}) => {
  return (
    <div className={styles.navigateButtons}>
      <div className={answered ? styles.arrowButton : styles.arrowButtonDisabled}>
        <button
          type="button"
          className={styles.nextBtn}
          disabled={!answered}
          onClick={() => onStepChange(currentStep + 1)}
        >
          <Icon iconName="arrow-right" />
        </button>
      </div>
      {currentStep != 1 && (
        <div className={styles.arrowButton}>
          <button
            type="button"
            className={styles.nextBtn}
            onClick={() => onStepChange(currentStep - 1)}
          >
            <Icon iconName="arrow-left" />
          </button>
        </div>
      )}
    </div>
  );
};

const step1Questions = [
  {
    id: 'whereUseExocoding',
    question: 'whereUseExocoding',
    options: [
      'Freelance work',
      'Corporate work',
      'My own business',
      'Academic Projects',
      'Personal Projects',
      'Non-profit work'
    ]
  },
  {
    id: 'teamMembers',
    question: 'teamMembers',
    options: ['Just me', '1', '2-5', '6-10', '11-20', '50+']
  },
  {
    id: 'featuresInterested',
    question: 'featuresInterested',
    options: [
      'Modelador de dados',
      'Lógica/API',
      'Designer de interface',
      'Gerador de código',
      'Automatizar comportamentos similares'
    ]
  }
];

const step2Questions = [
  {
    id: 'yearsDeveloper',
    question: 'yearsDeveloper',
    options: ['<3', '3-5', '6-10', '11-15', '>15', 'I am a student']
  },
  {
    id: 'softwareDevelopMost',
    question: 'softwareDevelopMost',
    options: [
      'Mobile apps',
      'On Premise/Desktop',
      'Cloud/Web (FrontEnd)',
      'Cloud/Web (BackEnd)',
      'Other'
    ]
  },
  {
    id: 'howHearAboutExocoding',
    question: 'howHearAboutExocoding',
    options: [
      'Search engines',
      'Review sites (G2, producthunt...)',
      'Linkedin',
      'X (Twitter)',
      'Ads',
      'Friends and colleagues',
      'Quora',
      'Reddit',
      'Press/blogs',
      'Influencer Devs',
      'Community Devs (sites/forums)'
    ]
  }
];

export const WelcomeForm = () => {
  const [session] = useSession();
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [answered, setAnswered] = useState<boolean>(false);

  async function onStepChange(step: number) {
    window.scroll(0, 0);
    setCurrentStep(step);
    setAnswered(checkAllAnswered(selectedOptions, step1Questions));
    if (step === 3 && session.user) {
      await AuthService.saveWelcomeForm(session.user.id, JSON.stringify(selectedOptions));
    }
  }

  const checkAllAnswered = (
    selectedOptions: { [x: string]: string[] },
    questions: { id: string; question: string; options: string[] }[]
  ) => {
    return questions.every((question) => {
      const selected = selectedOptions[question.id];
      return selected && selected.length > 0 && selected.every((option) => option !== '');
    });
  };

  return (
    <>
      <Navbar />
      <Container>
        {currentStep === 1 && session.user && (
          <Step1
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            onStepChange={onStepChange}
            userName={session.user.name || session.user.username}
            checkAllAnswered={checkAllAnswered}
            answered={answered}
            setAnswered={setAnswered}
            questions={step1Questions}
          />
        )}
        {currentStep === 2 && (
          <Step2
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            onStepChange={onStepChange}
            checkAllAnswered={checkAllAnswered}
            answered={answered}
            setAnswered={setAnswered}
            questions={step2Questions}
          />
        )}
        {currentStep === 3 && session.user && (
          <Step3 userName={session.user.name || session.user.username} />
        )}
      </Container>
    </>
  );
};
