import React, { ComponentType, ReactNode } from 'react';

import { ComponentWrapper } from './component_wrapper';
import { ComponentManifest, LayoutComponent } from '../../types';

import ButtonComponent, { ButtonComponentProps, BUTTON_MANIFEST, BUTTON_TEMPLATE } from './button';
import InputComponent, { InputComponentProps, INPUT_MANIFEST, INPUT_TEMPLATE } from './input';
import RowComponent, { RowComponentProps, ROW_MANIFEST, ROW_TEMPLATE } from './row';
import ColumnComponent, { ColumnComponentProps, COLUMN_MANIFEST, COLUMN_TEMPLATE } from './column';
import ContainerComponent, {
  ContainerComponentProps,
  CONTAINER_MANIFEST,
  CONTAINER_TEMPLATE
} from './container';
import CustomComponent, {
  CustomComponentProps,
  CUSTOM_COMPONENT_MANIFEST,
  CUSTOM_COMPONENT_TEMPLATE
} from './custom';
import ImageComponent, { ImageComponentProps, IMAGE_MANIFEST, IMAGE_TEMPLATE } from './image';
import CheckComponent, { CheckComponentProps, CHECKBOX_MANIFEST, CHECKBOX_TEMPLATE } from './check';
import LabelComponent, { LabelComponentProps, LABEL_MANIFEST, LABEL_TEMPLATE } from './label';
import RadioComponent, { RadioComponentProps, RADIO_MANIFEST, RADIO_TEMPLATE } from './radio';
import SelectComponent, { SelectComponentProps, SELECT_MANIFEST, SELECT_TEMPLATE } from './select';
import OutletComponent, {
  OutletProps,
  OUTLET_COMPONENT_MANIFEST,
  OUTLET_COMPONENT_TEMPLATE
} from './outlet';
import CardsComponent, {
  CardProps,
  CARD_COMPONENT_MANIFEST,
  CARD_COMPONENT_TEMPLATE
} from './card';
import AlertComponent, { AlertComponentProps, ALERT_MANIFEST, ALERT_TEMPLATE } from './alert';
import ProgressBarComponent, {
  ProgressBarComponentProps,
  PROGRESS_BAR_MANIFEST,
  PROGRESS_BAR_TEMPLATE
} from './progress_bar';
import TabsComponent, { TabsComponentProps, TABS_MANIFEST, TABS_TEMPLATE } from './tabs';
import NavbarComponent, { NavbarComponentProps, NAVBAR_MANIFEST, NAVBAR_TEMPLATE } from './navbar';
import NavComponent, { NavComponentProps, NAV_MANIFEST, NAV_TEMPLATE } from './nav';
import AccordionComponent, {
  AccordionComponentProps,
  ACCORDION_MANIFEST,
  ACCORDION_TEMPLATE
} from './accordion';
import DataTableComponent, {
  DATA_TABLE_MANIFEST,
  DATA_TABLE_TEMPLATE,
  DataTableComponentProps
} from './data_table';
import FormComponent, { FORM_MANIFEST, FORM_TEMPLATE, FormComponentProps } from './form';
import InputPasswordComponent, {
  INPUT_PASSWORD_MANIFEST,
  InputPasswordComponentProps,
  INPUT_PASSWORD_TEMPLATE
} from './input_password';
import InputEmailComponent, {
  INPUT_EMAIL_TEMPLATE,
  InputEmailComponentProps,
  INPUT_EMAIL_MANIFEST
} from './input_email';
import InputSearchComponent, {
  InputSearchComponentProps,
  INPUT_SEARCH_MANIFEST,
  INPUT_SEARCH_TEMPLATE
} from './input_search';
import InputTextareaComponent, {
  InputTextareaComponentProps,
  INPUT_TEXTAREA_MANIFEST,
  INPUT_TEXTAREA_TEMPLATE
} from './input_textarea';
import InputNumberComponent, {
  InputNumberComponentProps,
  INPUT_NUMBER_MANIFEST,
  INPUT_NUMBER_TEMPLATE
} from './input_number';
import InputDateComponent, {
  InputDateComponentProps,
  INPUT_DATE_MANIFEST,
  INPUT_DATE_TEMPLATE
} from './input_date';
import InputTimeComponent, {
  InputTimeComponentProps,
  INPUT_TIME_MANIFEST,
  INPUT_TIME_TEMPLATE
} from './input_time';
import InputLinkComponent, {
  InputLinkComponentProps,
  INPUT_LINK_MANIFEST,
  INPUT_LINK_TEMPLATE
} from './input_link';
import InputPhoneComponent, {
  InputPhoneComponentProps,
  INPUT_PHONE_MANIFEST,
  INPUT_PHONE_TEMPLATE
} from './input_phone';
import InputCurrencyComponent, {
  InputCurrencyComponentProps,
  INPUT_CURRENCY_MANIFEST,
  INPUT_CURRENCY_TEMPLATE
} from './input_currency';
import ImageButtonComponent, {
  ImageButtonComponentProps,
  IMAGE_BUTTON_MANIFEST,
  IMAGE_BUTTON_TEMPLATE
} from './image_button';
import PaginationComponent, {
  PaginationComponentProps,
  PAGINATION_MANIFEST,
  PAGINATION_TEMPLATE
} from './pagination';
import InputFileComponent, {
  InputFileComponentProps,
  INPUT_FILE_MANIFEST,
  INPUT_FILE_TEMPLATE
} from './input_file';
import ListComponent, { ListProps, LIST_MANIFEST, LIST_TEMPLATE } from './list';

import SideMenuComponent, { SideMenuComponentProps } from './side_menu';
import { SIDE_MENU_MANIFEST } from './side_menu/manifest';
import { SIDE_MENU_TEMPLATE } from './side_menu/template';
import { BADGES_BUTTON_MANIFEST } from './badges_button/manifest';
import BadgesButton, { BADGES_BUTTON_TEMPLATE, BadgesButtonComponentProps } from './badges_button';
import { getGridManifest, GRID_MANIFEST } from './grid/manifest';
import GridComponent, { GridComponentProps } from './grid';
import { getGridTemplate, GRID_TEMPLATE } from './grid/template';

export const COMPONENT_TYPES = {
  BUTTON: 'BUTTON',
  CHECK: 'CHECK',
  ROW: 'ROW',
  COLUMN: 'COLUMN',
  CONTAINER: 'CONTAINER',
  GRID: 'GRID',
  GRID2: 'GRID2',
  GRID3: 'GRID3',
  GRID4: 'GRID4',
  IMAGE: 'IMAGE',
  INPUT: 'INPUT',
  LIST: 'LIST',
  LABEL: 'LABEL',
  RADIO: 'RADIO',
  SELECT: 'SELECT',
  CUSTOM: 'CUSTOM',
  OUTLET: 'OUTLET',
  CARD: 'CARD',
  ALERT: 'ALERT',
  PROGRESSBAR: 'PROGRESSBAR',
  TABS: 'TABS',
  NAVBAR: 'NAVBAR',
  NAV: 'NAV',
  ACCORDION: 'ACCORDION',
  DATATABLE: 'DATATABLE',
  FORM: 'FORM',
  INPUTPASSWORD: 'INPUTPASSWORD',
  INPUTEMAIL: 'INPUTEMAIL',
  INPUTSEARCH: 'INPUTSEARCH',
  INPUTTEXTAREA: 'INPUTTEXTAREA',
  INPUTNUMBER: 'INPUTNUMBER',
  INPUTDATE: 'INPUTDATE',
  INPUTTIME: 'INPUTTIME',
  INPUTLINK: 'INPUTLINK',
  INPUTPHONE: 'INPUTPHONE',
  INPUTCURRENCY: 'INPUTCURRENCY',
  IMAGEBUTTON: 'IMAGEBUTTON',
  PAGINATION: 'PAGINATION',
  INPUTFILE: 'INPUTFILE',
  SIDEMENU: 'SIDEMENU',
  BADGESBUTTON: 'BADGESBUTTON'
} as const;

export type ComponentTypes = keyof typeof COMPONENT_TYPES;

export const COMPONENTS_MANIFEST: { [key: string]: ComponentManifest } = {
  [COMPONENT_TYPES.GRID]: getGridManifest(1),
  [COMPONENT_TYPES.GRID2]: getGridManifest(2),
  [COMPONENT_TYPES.GRID3]: getGridManifest(3),
  [COMPONENT_TYPES.GRID4]: getGridManifest(4),
  [COMPONENT_TYPES.CONTAINER]: CONTAINER_MANIFEST,
  [COMPONENT_TYPES.BUTTON]: BUTTON_MANIFEST,
  [COMPONENT_TYPES.CHECK]: CHECKBOX_MANIFEST,
  [COMPONENT_TYPES.IMAGE]: IMAGE_MANIFEST,
  [COMPONENT_TYPES.INPUT]: INPUT_MANIFEST,
  [COMPONENT_TYPES.LIST]: LIST_MANIFEST,
  [COMPONENT_TYPES.LABEL]: LABEL_MANIFEST,
  [COMPONENT_TYPES.RADIO]: RADIO_MANIFEST,
  [COMPONENT_TYPES.SELECT]: SELECT_MANIFEST,
  [COMPONENT_TYPES.CUSTOM]: CUSTOM_COMPONENT_MANIFEST,
  [COMPONENT_TYPES.OUTLET]: OUTLET_COMPONENT_MANIFEST,
  [COMPONENT_TYPES.CARD]: CARD_COMPONENT_MANIFEST,
  [COMPONENT_TYPES.ALERT]: ALERT_MANIFEST,
  [COMPONENT_TYPES.PROGRESSBAR]: PROGRESS_BAR_MANIFEST,
  [COMPONENT_TYPES.TABS]: TABS_MANIFEST,
  [COMPONENT_TYPES.NAVBAR]: NAVBAR_MANIFEST,
  [COMPONENT_TYPES.NAV]: NAV_MANIFEST,
  [COMPONENT_TYPES.ACCORDION]: ACCORDION_MANIFEST,
  [COMPONENT_TYPES.DATATABLE]: DATA_TABLE_MANIFEST,
  [COMPONENT_TYPES.FORM]: FORM_MANIFEST,
  [COMPONENT_TYPES.INPUTPASSWORD]: INPUT_PASSWORD_MANIFEST,
  [COMPONENT_TYPES.INPUTEMAIL]: INPUT_EMAIL_MANIFEST,
  [COMPONENT_TYPES.INPUTSEARCH]: INPUT_SEARCH_MANIFEST,
  [COMPONENT_TYPES.INPUTTEXTAREA]: INPUT_TEXTAREA_MANIFEST,
  [COMPONENT_TYPES.INPUTNUMBER]: INPUT_NUMBER_MANIFEST,
  [COMPONENT_TYPES.INPUTDATE]: INPUT_DATE_MANIFEST,
  [COMPONENT_TYPES.INPUTTIME]: INPUT_TIME_MANIFEST,
  [COMPONENT_TYPES.INPUTLINK]: INPUT_LINK_MANIFEST,
  [COMPONENT_TYPES.INPUTPHONE]: INPUT_PHONE_MANIFEST,
  [COMPONENT_TYPES.INPUTCURRENCY]: INPUT_CURRENCY_MANIFEST,
  [COMPONENT_TYPES.IMAGEBUTTON]: IMAGE_BUTTON_MANIFEST,
  [COMPONENT_TYPES.BADGESBUTTON]: BADGES_BUTTON_MANIFEST,
  [COMPONENT_TYPES.PAGINATION]: PAGINATION_MANIFEST,
  [COMPONENT_TYPES.INPUTFILE]: INPUT_FILE_MANIFEST,
  [COMPONENT_TYPES.SIDEMENU]: SIDE_MENU_MANIFEST,
  [COMPONENT_TYPES.ROW]: ROW_MANIFEST,
  [COMPONENT_TYPES.COLUMN]: COLUMN_MANIFEST
};

export const COMPONENTS_LIST: { [key: string]: ReactNode } = {
  [COMPONENT_TYPES.CONTAINER]: ComponentWrapper(
    ContainerComponent as ComponentType<ContainerComponentProps>
  ),
  [COMPONENT_TYPES.ROW]: ComponentWrapper(RowComponent as ComponentType<RowComponentProps>),
  [COMPONENT_TYPES.COLUMN]: ComponentWrapper(
    ColumnComponent as ComponentType<ColumnComponentProps>
  ),
  [COMPONENT_TYPES.BUTTON]: ComponentWrapper(
    ButtonComponent as ComponentType<ButtonComponentProps>
  ),
  [COMPONENT_TYPES.INPUT]: ComponentWrapper(InputComponent as ComponentType<InputComponentProps>),
  [COMPONENT_TYPES.LIST]: ComponentWrapper(ListComponent as ComponentType<ListProps>),
  [COMPONENT_TYPES.IMAGE]: ComponentWrapper(ImageComponent as ComponentType<ImageComponentProps>),
  [COMPONENT_TYPES.CHECK]: ComponentWrapper(CheckComponent as ComponentType<CheckComponentProps>),
  [COMPONENT_TYPES.LABEL]: ComponentWrapper(LabelComponent as ComponentType<LabelComponentProps>),
  [COMPONENT_TYPES.RADIO]: ComponentWrapper(RadioComponent as ComponentType<RadioComponentProps>),
  [COMPONENT_TYPES.SELECT]: ComponentWrapper(
    SelectComponent as ComponentType<SelectComponentProps>
  ),
  [COMPONENT_TYPES.CUSTOM]: ComponentWrapper(
    CustomComponent as ComponentType<CustomComponentProps>
  ),
  [COMPONENT_TYPES.OUTLET]: ComponentWrapper(OutletComponent as ComponentType<OutletProps>),
  [COMPONENT_TYPES.CARD]: ComponentWrapper(CardsComponent as ComponentType<CardProps>),
  [COMPONENT_TYPES.ALERT]: ComponentWrapper(AlertComponent as ComponentType<AlertComponentProps>),
  [COMPONENT_TYPES.PROGRESSBAR]: ComponentWrapper(
    ProgressBarComponent as ComponentType<ProgressBarComponentProps>
  ),
  [COMPONENT_TYPES.TABS]: ComponentWrapper(TabsComponent as ComponentType<TabsComponentProps>),
  [COMPONENT_TYPES.NAVBAR]: ComponentWrapper(
    NavbarComponent as ComponentType<NavbarComponentProps>
  ),
  [COMPONENT_TYPES.NAV]: ComponentWrapper(NavComponent as ComponentType<NavComponentProps>),
  [COMPONENT_TYPES.ACCORDION]: ComponentWrapper(
    AccordionComponent as ComponentType<AccordionComponentProps>
  ),
  [COMPONENT_TYPES.DATATABLE]: ComponentWrapper(
    DataTableComponent as ComponentType<DataTableComponentProps>
  ),
  [COMPONENT_TYPES.FORM]: ComponentWrapper(FormComponent as ComponentType<FormComponentProps>),
  [COMPONENT_TYPES.INPUTPASSWORD]: ComponentWrapper(
    InputPasswordComponent as ComponentType<InputPasswordComponentProps>
  ),
  [COMPONENT_TYPES.INPUTEMAIL]: ComponentWrapper(
    InputEmailComponent as ComponentType<InputEmailComponentProps>
  ),
  [COMPONENT_TYPES.INPUTSEARCH]: ComponentWrapper(
    InputSearchComponent as ComponentType<InputSearchComponentProps>
  ),
  [COMPONENT_TYPES.INPUTTEXTAREA]: ComponentWrapper(
    InputTextareaComponent as ComponentType<InputTextareaComponentProps>
  ),
  [COMPONENT_TYPES.INPUTNUMBER]: ComponentWrapper(
    InputNumberComponent as ComponentType<InputNumberComponentProps>
  ),
  [COMPONENT_TYPES.INPUTDATE]: ComponentWrapper(
    InputDateComponent as ComponentType<InputDateComponentProps>
  ),
  [COMPONENT_TYPES.INPUTTIME]: ComponentWrapper(
    InputTimeComponent as ComponentType<InputTimeComponentProps>
  ),
  [COMPONENT_TYPES.INPUTLINK]: ComponentWrapper(
    InputLinkComponent as ComponentType<InputLinkComponentProps>
  ),
  [COMPONENT_TYPES.INPUTPHONE]: ComponentWrapper(
    InputPhoneComponent as ComponentType<InputPhoneComponentProps>
  ),
  [COMPONENT_TYPES.INPUTCURRENCY]: ComponentWrapper(
    InputCurrencyComponent as ComponentType<InputCurrencyComponentProps>
  ),
  [COMPONENT_TYPES.IMAGEBUTTON]: ComponentWrapper(
    ImageButtonComponent as ComponentType<ImageButtonComponentProps>
  ),
  [COMPONENT_TYPES.PAGINATION]: ComponentWrapper(
    PaginationComponent as ComponentType<PaginationComponentProps>
  ),
  [COMPONENT_TYPES.INPUTFILE]: ComponentWrapper(
    InputFileComponent as ComponentType<InputFileComponentProps>
  ),
  [COMPONENT_TYPES.SIDEMENU]: ComponentWrapper(
    SideMenuComponent as ComponentType<SideMenuComponentProps>
  ),
  [COMPONENT_TYPES.BADGESBUTTON]: ComponentWrapper(
    BadgesButton as ComponentType<BadgesButtonComponentProps>
  ),
  [COMPONENT_TYPES.GRID]: ComponentWrapper(GridComponent as ComponentType<GridComponentProps>)
};

export const COMPONENTS_TEMPLATE: { [key: string]: LayoutComponent } = {
  [COMPONENT_TYPES.ROW]: ROW_TEMPLATE,
  [COMPONENT_TYPES.COLUMN]: COLUMN_TEMPLATE,
  [COMPONENT_TYPES.CONTAINER]: CONTAINER_TEMPLATE,
  [COMPONENT_TYPES.BUTTON]: BUTTON_TEMPLATE,
  [COMPONENT_TYPES.INPUT]: INPUT_TEMPLATE,
  [COMPONENT_TYPES.LIST]: LIST_TEMPLATE,
  [COMPONENT_TYPES.IMAGE]: IMAGE_TEMPLATE,
  [COMPONENT_TYPES.CHECK]: CHECKBOX_TEMPLATE,
  [COMPONENT_TYPES.LABEL]: LABEL_TEMPLATE,
  [COMPONENT_TYPES.RADIO]: RADIO_TEMPLATE,
  [COMPONENT_TYPES.SELECT]: SELECT_TEMPLATE,
  [COMPONENT_TYPES.CUSTOM]: CUSTOM_COMPONENT_TEMPLATE,
  [COMPONENT_TYPES.OUTLET]: OUTLET_COMPONENT_TEMPLATE,
  [COMPONENT_TYPES.CARD]: CARD_COMPONENT_TEMPLATE,
  [COMPONENT_TYPES.ALERT]: ALERT_TEMPLATE,
  [COMPONENT_TYPES.PROGRESSBAR]: PROGRESS_BAR_TEMPLATE,
  [COMPONENT_TYPES.TABS]: TABS_TEMPLATE,
  [COMPONENT_TYPES.NAVBAR]: NAVBAR_TEMPLATE,
  [COMPONENT_TYPES.NAV]: NAV_TEMPLATE,
  [COMPONENT_TYPES.ACCORDION]: ACCORDION_TEMPLATE,
  [COMPONENT_TYPES.DATATABLE]: DATA_TABLE_TEMPLATE,
  [COMPONENT_TYPES.FORM]: FORM_TEMPLATE,
  [COMPONENT_TYPES.INPUTPASSWORD]: INPUT_PASSWORD_TEMPLATE,
  [COMPONENT_TYPES.INPUTEMAIL]: INPUT_EMAIL_TEMPLATE,
  [COMPONENT_TYPES.INPUTSEARCH]: INPUT_SEARCH_TEMPLATE,
  [COMPONENT_TYPES.INPUTTEXTAREA]: INPUT_TEXTAREA_TEMPLATE,
  [COMPONENT_TYPES.INPUTNUMBER]: INPUT_NUMBER_TEMPLATE,
  [COMPONENT_TYPES.INPUTDATE]: INPUT_DATE_TEMPLATE,
  [COMPONENT_TYPES.INPUTTIME]: INPUT_TIME_TEMPLATE,
  [COMPONENT_TYPES.INPUTLINK]: INPUT_LINK_TEMPLATE,
  [COMPONENT_TYPES.INPUTPHONE]: INPUT_PHONE_TEMPLATE,
  [COMPONENT_TYPES.INPUTCURRENCY]: INPUT_CURRENCY_TEMPLATE,
  [COMPONENT_TYPES.IMAGEBUTTON]: IMAGE_BUTTON_TEMPLATE,
  [COMPONENT_TYPES.PAGINATION]: PAGINATION_TEMPLATE,
  [COMPONENT_TYPES.INPUTFILE]: INPUT_FILE_TEMPLATE,
  [COMPONENT_TYPES.SIDEMENU]: SIDE_MENU_TEMPLATE,
  [COMPONENT_TYPES.BADGESBUTTON]: BADGES_BUTTON_TEMPLATE,
  [COMPONENT_TYPES.GRID]: getGridTemplate(1),
  [COMPONENT_TYPES.GRID2]: getGridTemplate(2),
  [COMPONENT_TYPES.GRID3]: getGridTemplate(3),
  [COMPONENT_TYPES.GRID4]: getGridTemplate(4)
};

export const INTERATIVE_COMPONENTS: string[] = [COMPONENT_TYPES.SIDEMENU, COMPONENT_TYPES.NAVBAR];
