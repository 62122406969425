import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

type ChooseValueDialogProps = {
  show: boolean;
  onClose: () => void;
};

const valueTypes = ['Param', 'Marap'];
const values = ['petData', 'atadTep'];
const types = ['JSON', 'RAW'];

export function ChooseValueDialog(props: ChooseValueDialogProps) {
  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  function handleValueTypeSelectOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    event.preventDefault();
  }

  function handleValueSelectOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    event.preventDefault();
  }

  function handleTypeSelectOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    event.preventDefault();
  }

  function handleCheckboxOnClick(event: React.MouseEvent<HTMLInputElement>) {
    event.stopPropagation();
  }

  return (
    <Modal show={props.show} onHide={props.onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Choose value</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Form.Group as={Row} className="mb-3" controlId="formParam">
                <Form.Label column sm={4}>
                  Param.
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" placeholder="Resources" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formValueType">
                <Form.Label column sm={4}>
                  Value Type
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    onChange={handleValueTypeSelectOnChange}
                    defaultValue={valueTypes[0]}
                  >
                    {valueTypes.map((valueType) => {
                      return (
                        <option key={valueType} value={valueType}>
                          {valueType}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formValue">
                <Form.Label column sm={4}>
                  Value
                </Form.Label>
                <Col sm={8}>
                  <Form.Select onChange={handleValueSelectOnChange} defaultValue={values[0]}>
                    {values.map((value) => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group as={Row} className="mb-3" controlId="formType">
                <Form.Label column sm={3}>
                  Type
                </Form.Label>
                <Col sm={9}>
                  <Form.Select onChange={handleTypeSelectOnChange} defaultValue={types[0]}>
                    {types.map((type) => {
                      return (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Row>
                <Col sm={{ span: 9, offset: 3 }}>
                  <Form.Group className="mb-3" controlId="formRequired">
                    <Form.Check
                      inline
                      label="Required"
                      type="checkbox"
                      onClick={handleCheckboxOnClick}
                      name="Required"
                      defaultChecked={false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 9, offset: 3 }}>
                  <Button variant="primary">JSON Node</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancelButton" variant="secondary" onClick={props.onClose}>
            cancelButton
          </Button>
          <Button id="saveButton" variant="primary" type="submit">
            saveButton
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
