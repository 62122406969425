import { Action } from 'redux';
import { FunctionParameterType, ParametersState } from '../../types/parameters';

export const SET_PAGINATION = 'SET_PAGINATION';

export interface SetPaginationAction extends Action {
  type: 'SET_PAGINATION';
  payload: {
    functionUuid: string;
    value: boolean;
    pageUuid: string;
    sizeUuid: string;
  };
}

export const setPagination = (
  functionUuid: string,
  value: boolean,
  pageUuid: string,
  sizeUuid: string
): SetPaginationAction => ({
  type: SET_PAGINATION,
  payload: { functionUuid, value, pageUuid, sizeUuid }
});

export function doSetPagination(
  state: ParametersState,
  action: SetPaginationAction
): ParametersState {
  const { value, pageUuid, sizeUuid } = action.payload;
  if (value) {
    state[pageUuid] = createPageParam(pageUuid);
    state[sizeUuid] = createSizeParam(sizeUuid);
    return state;
  }
  delete state[pageUuid];
  delete state[sizeUuid];
  return state;
}

const createPageParam = (pageUuid: string): FunctionParameterType => {
  return {
    uuid: pageUuid,
    name: 'page',
    type: 'INTEGER',
    desc: '',
    list: false,
    required: true,
    native: true
  };
};

const createSizeParam = (sizeUuid: string): FunctionParameterType => {
  return {
    uuid: sizeUuid,
    name: 'size',
    type: 'INTEGER',
    desc: '',
    list: false,
    required: true,
    native: true
  };
};
