import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import {
  EndpointResponse,
  EndpointResponseSimple,
  ObjectSimple
} from 'modules/logic_builder/types';
import Confirmation from 'web_ui/confirmation';
import { EndpointsService, ObjectsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HTTP_STATUS_CODES = [
  100, 103, 200, 201, 204, 206, 301, 302, 303, 304, 307, 308, 400, 401, 403, 404, 406, 407, 409,
  410, 412, 416, 418, 425, 451, 500, 501, 502, 503, 504
];

type ResponseDialogProps = {
  show: boolean;
  onClose: () => void;
  endpointId: string;
  fetchResponses: () => void;
  // We need editMode because this Dialog is being used to Edit and Create new responses.
  // We could also test if props.response == null, but this is more understandable.
  editMode: boolean;
  response?: EndpointResponseSimple;
};

// todo: sort list of fetchReponses() by httpStatus
export function ResponseDialog(props: ResponseDialogProps) {
  const { module_id } = useParams();
  const [response, setResponse] = useState<EndpointResponse>();
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const [objects, setObjects] = useState<ObjectSimple[]>([]);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const { t } = useTranslation();

  const fetchObjects = useCallback(async () => {
    if (!module_id) return;
    await ObjectsService.getObjectsByModule(module_id).then((fetchedObjects) => {
      setObjects(fetchedObjects);
    });
  }, [module_id]);

  useEffect(() => {
    if (!props.show) return;

    fetchObjects();
  }, [fetchObjects, props.show]);

  const fetchResponse = useCallback(async () => {
    if (!props.response) return;

    await EndpointsService.getEndpointResponse(props.endpointId, props.response.httpStatus).then(
      (fetchedResponse) => {
        setResponse(fetchedResponse);
      }
    );
  }, [props.endpointId, props.response]);

  useEffect(() => {
    if (!props.show) return;

    if (props.editMode) {
      fetchResponse();
    } else {
      setResponse({
        description: '',
        httpStatus: '',
        list: false,
        objectUuid: ''
      });
    }
  }, [fetchResponse, props.editMode, props.show]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();
    event.preventDefault();

    props.editMode ? onUpdate() : onCreate();
  }

  async function onCreate() {
    if (!response) return;

    await EndpointsService.createEndpointResponse(props.endpointId, response)
      .then(() => {
        props.fetchResponses();
        setErrorMessage(false);
        props.onClose();
      })
      .catch(() => {
        setErrorMessage(true);
      });
  }

  async function onUpdate() {
    if (!response) return;
    if (!props.response) return;

    await EndpointsService.updateEndpointResponse(
      props.endpointId,
      props.response.httpStatus,
      response
    )
      .then(() => {
        props.fetchResponses();
        setErrorMessage(false);
        props.onClose();
      })
      .catch(() => {
        setErrorMessage(true);
      });
  }

  async function onDelete() {
    if (!response?.httpStatus) return;

    await EndpointsService.deleteEndpointResponse(props.endpointId, response.httpStatus).then(
      () => {
        props.fetchResponses();
        setShowDeleteConfirmationDialog(false);
        props.onClose();
      }
    );
  }

  // function handleContextMenu(event: React.MouseEvent) {
  //   event.preventDefault();
  //   event.stopPropagation();
  // }

  return (
    <>
      <Modal
        show={props.show}
        className={styles.ResponseDialog}
        onHide={() => {
          setErrorMessage(false);
          props.onClose();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.editMode ? t('logicBuilder.ResponseEditor') : t('logicBuilder.ResponseCreator')}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {errorMessage && (
              <div style={{ marginBottom: '0.5rem' }}>
                <span className={styles.errorMsg}>{`*${t('logicBuilder.statusCode.error')}`}</span>
              </div>
            )}
            <Row className="mb-3">
              <Col sm={6}>
                <Form.Group controlId="formResponseStatus">
                  <Form.Label>{t('logicBuilder.StatusCode')}</Form.Label>
                  <Form.Select
                    value={response?.httpStatus ?? 'default'}
                    onChange={(e) =>
                      setResponse({ ...response, httpStatus: e.target.value } as EndpointResponse)
                    }
                    required
                  >
                    {!response?.httpStatus && (
                      <option value="default">{t('logicBuilder.SelectStatusCode')}</option>
                    )}
                    {HTTP_STATUS_CODES.map((code) => {
                      return (
                        <option key={code} value={code}>
                          {code}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formResponseDescription">
              <Form.Label>{t('logicBuilder.Description')}</Form.Label>
              <Form.Control
                as="textarea"
                placeholder={`${t('logicBuilder.ThisResponse')}`}
                rows={2}
                maxLength={64}
                value={response?.description ?? ''}
                onChange={(e) =>
                  setResponse({
                    ...response,
                    description: e.target.value
                  } as EndpointResponse)
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>{t('logicBuilder.Objects')}</Form.Label>
              <Form.Select
                required
                disabled={
                  !(Number(response?.httpStatus) >= 200 && Number(response?.httpStatus) <= 299)
                }
                onChange={(e) =>
                  setResponse({
                    ...response,
                    objectUuid: e.target.value === 'default' ? '' : e.target.value
                  } as EndpointResponse)
                }
                value={response?.objectUuid ?? 'default'}
              >
                {!response?.objectUuid && <option value={'default'}>Empty object</option>}
                {objects.map((object) => {
                  return (
                    <option key={object.uuid} value={object.uuid}>
                      {object.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {props.editMode && (
              <Button
                id="deleteButton"
                variant="danger"
                onClick={() => setShowDeleteConfirmationDialog(true)}
              >
                {t('logicBuilder.Delete')}
              </Button>
            )}
            <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
              {t('logicBuilder.Cancel')}
            </Button>
            <Button
              id="saveButton"
              variant="primary"
              type="submit"
              disabled={!response?.httpStatus}
            >
              {t('logicBuilder.Save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showDeleteConfirmationDialog}
        message={`${'logicBuilder.DeleteResponse'}`}
        onConfirmation={onDelete}
        onCancel={() => setShowDeleteConfirmationDialog(false)}
        onClose={() => setShowDeleteConfirmationDialog(false)}
      />
    </>
  );
}
