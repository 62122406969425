import React, { ReactNode, useState } from 'react';
import styles from './styles.module.css';
import SidebarCollapseButton from './sidebar_collapse_button';

enum SidebarPosition {
  LEFT,
  RIGHT
}

type SidebarRootProps = {
  children?: ReactNode;
  position?: SidebarPosition;
};

function SidebarRoot({ children, position = SidebarPosition.LEFT }: SidebarRootProps) {
  const [colapse, setColapse] = useState(false);

  const handleColapse = () => {
    setColapse((prevState) => !prevState);
  };

  const sidebarClassNames =
    position === SidebarPosition.LEFT
      ? `bg-body border-end ${styles.toolbarContainer}`
      : `bg-body border-start ${styles.toolbarContainer}`;

  return (
    <div className={sidebarClassNames} style={colapse ? { width: '0px', minWidth: '0px' } : {}}>
      {position === SidebarPosition.RIGHT && (
        <SidebarCollapseButton
          colapse={colapse}
          handleColapse={handleColapse}
          position={position}
        />
      )}
      <div className={'flex-grow-1'} style={{ width: '100%' }}>
        {!colapse && children}
      </div>
      {position === SidebarPosition.LEFT && (
        <SidebarCollapseButton
          colapse={colapse}
          handleColapse={handleColapse}
          position={position}
        />
      )}
    </div>
  );
}

export default SidebarRoot;
