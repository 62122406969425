import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppTranslation } from '../../../../../../../modules/designer/types';
import { AppLanguagesService } from '../../../../../../../modules/designer/services';

type setTranslationsCountType = (count: number) => void;

function useSetTableBodyData(
  selectedPage: number,
  selectedLanguage: string,
  mainLanguageId: string,
  setTranslationsCount: setTranslationsCountType
) {
  const { app_id } = useParams();
  const [translations, setTranslations] = useState<Map<string, AppTranslation>>(new Map());
  const [mainTranslationsList, setMainTranslationsList] = useState<AppTranslation[]>([]);

  useEffect(() => {
    setData().then();
  }, [app_id, selectedPage, selectedLanguage]);

  async function setData() {
    if (!app_id || !selectedLanguage || !mainLanguageId) return;
    if (selectedLanguage === mainLanguageId) {
      getTranslations(app_id, mainLanguageId).then((response) => {
        const { translations, count } = response;
        setMainTranslationsList(translations);
        setTranslations(mapTranslationsByKey(translations));
        setTranslationsCount(count);
      });
      return;
    }
    getTranslations(app_id, mainLanguageId).then((response) => {
      const { translations, count } = response;
      setMainTranslationsList(translations);
      setTranslationsCount(count);
      getTranslations(app_id, selectedLanguage).then((response) =>
        setTranslations(mapTranslationsByKey(response.translations))
      );
    });
  }

  async function getTranslations(appId: string, languageId: string) {
    const { translations, count } = await AppLanguagesService.getTranslations(
      appId,
      languageId,
      selectedPage - 1
    );
    return { translations, count };
  }

  function mapTranslationsByKey(translationsList: AppTranslation[]) {
    const mapped = new Map<string, AppTranslation>();
    if (!translationsList || translationsList?.length < 1) return mapped;
    translationsList.forEach((t) => {
      const translation = { id: t?.id ?? '', key: t.key, value: t?.value ?? '' };
      mapped.set(t.key, translation);
    });
    return mapped;
  }
  return { mainTranslationsList, translations, setData };
}

export default useSetTableBodyData;
