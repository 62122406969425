import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { ObjectSimple } from 'modules/logic_builder/types';
import { ActionsTool } from './actions_tool';
import { ObjectView } from './object_view';
import { PropertyEditor } from './property_editor';
import { v4 as uuidv4 } from 'uuid';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { ModuleInfo } from '../../../dashboard/types';
import { useParams } from 'react-router-dom';
import {
  addTableRelationshipsColumns,
  buildPropertiesListFromEntityColumns,
  ObjectItemSource,
  PreSelectedEntity,
  Property,
  SelectedProperty
} from './editor_utils';

type ObjectEditorProps = {
  objects: ObjectSimple[];
  setEntityMode: (entityMode: boolean) => void;
  onChange: (properties: Record<string, Property[]>) => void;
  properties: Record<string, Property[]> | undefined;
  rootObject: string;
  setRootObject: (root: string) => void;
  editMode: boolean;
  preSelectedEntity?: PreSelectedEntity;
  modules: ModuleInfo[];
  tables: Table[];
  enums: EnumFrame[];
  columns: Column[];
  relationships: Relationship[];
  setFieldErrorMessage?: (msg: string) => void;
  entityId?: string;
  loadingData?: boolean;
  fieldErrorMessage?: string;
  showExported: boolean;
};

export function ObjectEditor({
  objects,
  setEntityMode,
  onChange,
  properties,
  rootObject,
  setRootObject,
  editMode,
  preSelectedEntity,
  modules,
  tables,
  enums,
  columns,
  relationships,
  setFieldErrorMessage,
  entityId,
  loadingData,
  fieldErrorMessage,
  showExported
}: ObjectEditorProps) {
  const { module_id } = useParams();
  const [isObjectComplete, setIsObjectComplete] = useState(!editMode);
  const [selectedProperty, setSelectedProperty] = useState<SelectedProperty>();

  const handleSelectProperty = (selectedProperty: SelectedProperty | undefined) => {
    setSelectedProperty(selectedProperty);
  };

  const toggleIsObjectComplete = (isComplete: boolean): void => {
    const selectedTable = getSelectedRootTable();
    if (!selectedTable) {
      return;
    }
    setIsObjectComplete(isComplete);
    handleSelectRootTable(selectedTable.uuid, isComplete, true);
  };

  const handleAddEmptyProperty = useCallback((): void => {
    const newProperty: Property = {
      id: uuidv4(),
      name: `field_`,
      description: '',
      type: 'INTEGER',
      list: false,
      object: '',
      readOnly: false,
      exported: false,
      exportedName: '',
      fromEntity: false,
      checked: true,
      isFK: false,
      isPK: false,
      required: false,
      itemOrder: 0
    };

    const localProperties = { ...properties };

    if (selectedProperty) {
      const id = selectedProperty.id;
      const objectId = selectedProperty.objectId;
      newProperty.name += localProperties[objectId].length + 1;
      const index = localProperties[objectId].findIndex((p) => p.id === id);
      localProperties[objectId].splice(index + 1, 0, newProperty);

      // Update the 'order' property for every other property.
      for (let i = index + 1; i < localProperties[objectId].length; i++) {
        localProperties[objectId][i].itemOrder = i;
      }
    } else {
      if (!localProperties[rootObject]) {
        localProperties[rootObject] = [];
      }
      newProperty.name += localProperties[rootObject].length + 1;
      localProperties[rootObject].unshift(newProperty);

      // Update the 'order' property for every other property.
      for (let i = 0; i < localProperties[rootObject].length; i++) {
        localProperties[rootObject][i].itemOrder = i;
      }
    }

    onChange(localProperties);
    if (setFieldErrorMessage) setFieldErrorMessage('');
  }, [properties, rootObject, selectedProperty]);

  const handleUpdateProperty = (property: Property): void => {
    if (!selectedProperty) return;
    if (!properties) return;

    const index = properties[selectedProperty.objectId].findIndex((p) => p.id === property.id);

    if (index === -1) return;

    const localProperties = { ...properties };
    localProperties[selectedProperty.objectId][index] = property;
    onChange(localProperties);
  };

  const handleDeleteProperty = (property: string): void => {
    if (!selectedProperty || !properties) return;

    const localProperties = { ...properties };
    const index = localProperties[selectedProperty.objectId].findIndex((p) => p.id === property);

    if (index === -1) return;

    localProperties[selectedProperty.objectId].splice(index, 1);

    // Update the 'order' property for every other property.
    const initialOrder = index - 1 < 0 ? 0 : index;
    for (let i = initialOrder; i < localProperties[selectedProperty.objectId].length; i++) {
      localProperties[selectedProperty.objectId][i].itemOrder = i;
    }

    onChange(localProperties);
    setSelectedProperty(undefined);
  };

  const getSelectedProperty = (): Property | undefined => {
    if (!selectedProperty) return;
    if (!properties || !properties[selectedProperty.objectId]) return;

    return properties[selectedProperty.objectId].find((p) => p.id === selectedProperty?.id);
  };

  const handleCheckedProperty = (property: string, objectId: string, checked: boolean): void => {
    if (!properties) return;

    const localProperties = { ...properties };
    const index = localProperties[objectId].findIndex((p) => p.id === property);
    if (index !== -1) {
      localProperties[objectId][index].checked = checked;
      onChange(localProperties);
    }
  };

  const getSelectedRootTable = useCallback(() => {
    const entity = rootObject;

    if (entity) {
      return tables.find((t) => t.uuid === entity);
    } else {
      return undefined;
    }
  }, [rootObject]);

  const handleSelectRootTable = (
    table: string,
    isObjectComplete: boolean,
    showOldProperties: boolean
  ): void => {
    const propertiesTree: Record<string, Property[]> = {};
    const tablesToCheck: string[] = [table];
    const tablesChecked: string[] = [];
    while (tablesToCheck.length) {
      const propertiesList: Property[] = [];

      const tableId = tablesToCheck.pop();
      if (!tableId) break;
      tablesChecked.push(tableId);

      const order = buildPropertiesListFromEntityColumns(
        tableId,
        tables,
        columns,
        enums,
        relationships,
        isObjectComplete,
        propertiesList
      );
      if (isObjectComplete) {
        addTableRelationshipsColumns(
          tableId,
          module_id,
          modules,
          tables,
          relationships,
          tablesToCheck,
          tablesChecked,
          propertiesList,
          order
        );
      }
      // Add root entity fields (columns + relationships) to the tree
      propertiesTree[tableId] = propertiesList;
    }

    // Copy the fields of each table under their relationships
    for (const tableId of tablesChecked) {
      const tableRelationshipProps = Object.values(propertiesTree)
        .flatMap((value) => Object.values(value))
        .filter((prop) => prop.entityId === tableId) // only current table
        .filter((prop) => !prop.columnId)
        .filter((prop) => prop.relationshipId);

      for (const prop of tableRelationshipProps) {
        if (!prop.relationshipId || !prop.entityId || !prop.object) {
          continue;
        }
        const tableProps = propertiesTree[prop.entityId]
          .filter((prop) => prop.entityId !== table) // exclude root table
          .map((property) => {
            return {
              id: uuidv4(),
              name: property.name,
              objectName: property.objectName,
              description: property.description,
              type: property.type,
              list: property.list,
              object: property.object,
              enum: property.enum,
              enumName: property.enumName,
              readOnly: property.readOnly,
              exported: property.exported,
              exportedName: property.exportedName,
              checked: property.checked,
              entityName: property.entityName,
              fromEntity: property.fromEntity,
              isFK: property.isFK,
              isPK: property.isPK,
              columnId: property.columnId,
              entityId: property.entityId,
              relationshipId: property.relationshipId,
              itemOrder: property.itemOrder,
              showWhenObjectIsComplete: property.showWhenObjectIsComplete,
              required: property.relationshipId === prop.relationshipId ? true : property.required
            } as Property;
          });
        propertiesTree[prop.object] = tableProps;
      }
    }

    // Remove any non required columns (in object mode)
    // Children objects MUST include their parent id, as a required field
    // any other columns (connected to an entity, with relationshipId) should be removed.
    if (isObjectComplete) {
      for (const properties of Object.values(propertiesTree)) {
        for (const property of Object.values(properties)) {
          if (property.columnId && property.relationshipId && !property.required) {
            const index = properties.indexOf(property, 0);
            if (index > -1) {
              properties.splice(index, 1);
            }
          }
        }
      }
    }

    if (properties && properties[rootObject]) {
      const oldProperties: Property[] = [];
      let newOrder = properties[rootObject].length;
      for (const prop of properties[rootObject]) {
        if (prop.fromEntity) continue;
        newOrder++;
        prop.itemOrder = newOrder;
        oldProperties.push(prop);
      }
      if (showOldProperties) {
        propertiesTree[table] = [...oldProperties, ...propertiesTree[table]];
      } else {
        propertiesTree[table] = [...propertiesTree[table]];
      }
    }

    setEntityMode(true);
    setRootObject(table);
    setSelectedProperty(undefined);
    onChange(propertiesTree);
  };

  useEffect(() => {
    // if we have preSelected one we need to use this function to generate that first key
    if (rootObject && preSelectedEntity && preSelectedEntity.id) {
      handleSelectRootTable(rootObject, isObjectComplete, false);
    }
  }, [rootObject, preSelectedEntity]);

  // Select option 'No entity': delete all items that have the property fromEntity set to true.
  const handleUnselectTable = (): void => {
    const newId = uuidv4();

    const newPropertiesTree: Record<string, Property[]> = {};
    newPropertiesTree[newId] = [];

    if (!properties) {
      onChange(newPropertiesTree);
      return;
    }

    let newOrder = 0;
    for (const prop of properties[rootObject]) {
      if (prop.fromEntity) continue;
      newOrder++;
      prop.itemOrder = newOrder;
      newPropertiesTree[newId].push(prop);
    }

    setSelectedProperty(undefined);
    setEntityMode(false);
    setIsObjectComplete(false);
    setRootObject(newId);
    onChange(newPropertiesTree);
  };

  const moveProperty = (source: ObjectItemSource, target: ObjectItemSource) => {
    if (!properties) return;
    if (source.object !== target.object) return;

    const localProperties = { ...properties };

    const targetIndex = localProperties[source.object].findIndex((p) => p.id === target.property);

    let newOrder = 0;
    for (let i = 0; i < localProperties[source.object].length; i++) {
      if (localProperties[source.object][i].id === source.property) {
        localProperties[source.object][i].itemOrder = targetIndex;
        continue;
      }
      if (newOrder === targetIndex) {
        newOrder++;
      }
      localProperties[source.object][i].itemOrder = newOrder;
      newOrder++;
    }
    localProperties[source.object].sort((a, b) => a.itemOrder - b.itemOrder);
    onChange(localProperties);
  };

  return (
    <div id={'ObjectEditorWrapper'} className={styles.ObjectEditorWrapper}>
      <ActionsTool
        handleAddEmptyProperty={handleAddEmptyProperty}
        handleSelectRootTable={handleSelectRootTable}
        selectedTable={getSelectedRootTable()}
        selectedProperty={getSelectedProperty()}
        tables={tables}
        handleUnselectTable={handleUnselectTable}
        editMode={editMode}
        toggleIsObjectComplete={toggleIsObjectComplete}
        isObjectComplete={isObjectComplete}
        preSelectedEntityId={preSelectedEntity?.id}
        entityId={editMode ? entityId : ''}
        loadingData={loadingData}
        fieldErrorMessage={fieldErrorMessage}
      />
      <div className={styles.ViewAndPropertyWrapper}>
        <ObjectView
          properties={properties}
          rootObject={rootObject}
          handleSelectProperty={handleSelectProperty}
          selectedProperty={selectedProperty}
          handleCheckedProperty={handleCheckedProperty}
          isObjectComplete={isObjectComplete}
          moveProperty={moveProperty}
        />
        <div className={styles.PropertyEditorWrapper}>
          {selectedProperty && (
            <PropertyEditor
              selectedProperty={getSelectedProperty()}
              selectedTable={editMode ? entityId : getSelectedRootTable()?.uuid}
              relationships={relationships}
              handleUpdateProperty={handleUpdateProperty}
              objects={objects}
              isObjectComplete={isObjectComplete}
              enums={enums}
              handleDeleteProperty={handleDeleteProperty}
              showExported={showExported}
            />
          )}
        </div>
      </div>
    </div>
  );
}
