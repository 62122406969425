import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../../../../store';
import { Relationship } from '../../../../../../../types';
import { changeProperty } from '../../../../../../store/actions/relationship';
import DescriptionInput from './description-input';
import CardinalityInput from './cardinality-input';
import BidirectionalInput from './bidirectional-input';
import FromInput from './from-input';
import ToInput from './to-input';
import DeleteButton from './delete-button';
import { useTranslation } from 'react-i18next';
import FetchOptions from './fetch-options';
import CascadeOptions from './cascade-options';

type RelationshipEditorProps = {
  relationshipID: string;
};
export default function RelationshipEditor({ relationshipID }: RelationshipEditorProps) {
  const relationships = useSelector((state: DatabaseStudioState) => state.relationships);
  const [relationship, setRelationship] = useState({} as Relationship);
  const [fromName, setFromName] = useState('');
  const [toName, setToName] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!relationships[relationshipID]) return;
    setRelationship(relationships[relationshipID]);
    setFromName(relationships[relationshipID].fromName || '');
    setToName(relationships[relationshipID].toName || '');
  }, [relationshipID, relationships[relationshipID]]);

  const handleChangeFromName = () => {
    dispatch(changeProperty(relationship.id, 'fromName', fromName));
  };

  const handleChangeToName = () => {
    dispatch(changeProperty(relationship.id, 'toName', toName));
  };

  return (
    <div className={styles.RelationshipEditorWrapper}>
      <Row className="mb-2">
        <FromInput relationship={relationship} />
        <ToInput relationship={relationship} />
      </Row>
      <Row className="mb-2">
        <Form.Group className="col-sm-6" controlId="relationFromName">
          <Form.Label className={styles.SmallFont}>{t('modeler.FromName')}</Form.Label>
          <Form.Control
            value={fromName}
            placeholder={t('modeler.FromName') ?? 'modeler.FromName'}
            as={'textarea'}
            rows={1}
            size="sm"
            required
            maxLength={64}
            onBlur={(e) => handleChangeFromName()}
            onChange={(e) => setFromName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="col-sm-6" controlId="relationToName">
          <Form.Label className={styles.SmallFont}>{t('modeler.ToName')}</Form.Label>
          <Form.Control
            value={toName}
            placeholder={t('modeler.ToName') ?? 'modeler.ToName'}
            as={'textarea'}
            rows={1}
            size="sm"
            required
            maxLength={64}
            onBlur={(e) => handleChangeToName()}
            onChange={(e) => setToName(e.target.value)}
          />
        </Form.Group>
      </Row>
      <DescriptionInput relationship={relationship} />
      <Row className="mb-2">
        <FetchOptions relationship={relationship} />
      </Row>
      <Row className="mb-2">
        <CascadeOptions relationship={relationship} />
      </Row>
      <Row className="mb-2">
        <CardinalityInput relationship={relationship} />
        <BidirectionalInput relationship={relationship} />
      </Row>
      <DeleteButton relationship={relationship} />
    </div>
  );
}
