import React, { ChangeEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { AppLanguages } from '../../../designer/types';
import locations from '../../../../assets/locations.json';
import { AppLanguagesService } from '../../../designer/services';

export type CreateLanguageProps = {
  /** Modal visibility **/
  showModal: boolean;
  /** Callback on modal closing **/
  onCloseRequest: (languageId: string) => void;
  setShowModal: (state: boolean) => void;
};

/**
 * Create Module Modal.
 *
 * @component
 */
export default function CreateLanguageModal(props: CreateLanguageProps) {
  const { app_id } = useParams();
  const [location, setLocation] = useState<string>('default');
  const [name, setName] = useState<string>('');
  const [foundOnSave, setFoundOnSave] = useState<boolean>(false);
  const [select, setSelect] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleChanging = (e: ChangeEvent<HTMLSelectElement>) => {
    const split = e.target.value.split('-');
    setLocation(split[0]);
    setName(split[1]);
  };

  const onClose = () => {
    props.setShowModal(false);
    setFoundOnSave(false);
    setSelect(false);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!app_id) return;

    setFoundOnSave(false);
    setSelect(false);

    if (location === 'default') {
      setSelect(true);
    } else {
      const language: AppLanguages = { id: '', locale: location, name: name };
      const appLanguage = await AppLanguagesService.createLanguage(app_id, language);

      if (appLanguage == null) {
        setFoundOnSave(true);
      } else {
        props.onCloseRequest(appLanguage.id);
        onClose();
      }
    }
  };

  return (
    <Modal centered show={props.showModal} onHide={onClose}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton id="btn-close">
          <Modal.Title>{t('appResume.languages.LanguageRegistration')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.appSettingsDialog}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: '500' }}>
                {t('appResume.languages.Locale')}
              </Form.Label>
              <select
                id={'selectLanguages'}
                className={styles.select}
                onChange={(e) => {
                  handleChanging(e);
                }}
              >
                {Object.values(locations).map((location) => {
                  return (
                    <option
                      id={location.code}
                      key={location.code}
                      value={location.code + '-' + location.name}
                    >
                      {location.name}
                    </option>
                  );
                })}
              </select>
              {foundOnSave && (
                <Form.Label style={{ fontSize: '14px' }}>
                  {t('appResume.languages.LanguageRegistered')}
                </Form.Label>
              )}
              {select && (
                <Form.Label style={{ fontSize: '14px' }}>
                  {t('appResume.languages.SelectLanguage')}
                </Form.Label>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.dialogSaveButton}>
            <Button id="saveButton" type="submit">
              {t('appResume.languages.Create')}
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
