import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';
import { t } from 'i18next';
import Icon from 'web_ui/icon';

export type SearchBarProps = {
  text: string; //Text Filter
  setText: (text: string) => void; //Callback called when text is changed
  placeholder?: string; //Placeholder text. If empty, t('Search').
  id?: string; //Placeholder text. If empty, sbId.
  focus?: boolean; //Defines autoFocus on this component. If empty, false.
  disabled?: boolean; //Defines disable on this component. If empty, false.
};

function SearchBar(props: SearchBarProps) {
  const pHolder = props.placeholder ? props.placeholder : t('Search');
  const id = props.id ? props.id : 'idSb';
  return (
    <div className="input-group input-group-sm w-100">
      <Form.Group className="position-relative w-100">
        <Form.Control
          id={id}
          placeholder={pHolder}
          aria-label={pHolder}
          onChange={(e) => props.setText(e.target.value)}
          value={props.text}
          autoFocus={props.focus}
          disabled={props.disabled}
        />
        {props.text.length === 0 ? (
          <Icon iconName="magnifying-glass" extraProps={styles.searchIcon}></Icon>
        ) : (
          <Icon
            iconName="xmark"
            extraProps={styles.searchIcon}
            onClick={() => props.setText('')}
            role="button"
          ></Icon>
        )}
      </Form.Group>
    </div>
  );
}

export default SearchBar;
