import React from 'react';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { BootstrapColors } from '../../DataTableSource/types';

export type HelpBoxProps = {
  icon?: string;
  iconStyles?: Record<string, any>;
  title?: string;
  description?: string;
  note?: string[];
  variant?: BootstrapColors;
};

function HelpBox(props: HelpBoxProps) {
  return (
    <div
      className={`border border-${
        props.variant ? props.variant : 'primary'
      }-subtle rounded bg-body p-3 d-flex flex-direction-column`}
    >
      <span className={`${styles.textStyle} text-start`}>
        <b
          className={`d-flex justify-content-between text-${
            props.variant ? props.variant : 'primary'
          }-emphasis`}
        >
          {props.title}
          {props.icon && (
            <div style={props.iconStyles} className="d-inline ms-2">
              <Icon iconName={props.icon}></Icon>
            </div>
          )}
        </b>
        <p className={`text-${props.variant ? props.variant : 'primary'}-emphasis mb-1 mt-2`}>
          {props.description}
        </p>
        {props.note?.map((notee) => {
          return (
            <span key={notee} className={`${styles.note} d-block text-body-emphasis`}>
              {notee}
            </span>
          );
        })}
      </span>
    </div>
  );
}

export default HelpBox;
