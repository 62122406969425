import React from 'react';
import { Button, ProgressBar, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

type AutomationWizardProgressBarProps = {
  disabled: boolean;
  step: number;
  totalSteps: number;
  onStepChange: (step: number, isNext?: boolean) => void;
  onFinish: () => void;
  loadingsCrudAutomation?: boolean[];
  isLoading?: boolean;
};

function AutomationWizardProgressBar(props: AutomationWizardProgressBarProps) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.WizardProgressBarWrapper}`}>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.WizardProgressBar}`}
      >
        <Button
          id={'backButton'}
          variant="secondary"
          disabled={props.step === 0}
          onClick={() => props.onStepChange(props.step - 1)}
        >
          {t('navigation_options.Back')}
        </Button>
        <ProgressBar min={0} max={props.totalSteps} now={props.step} className="flex-grow-1 mx-2" />
        {props.step !== props.totalSteps ? (
          <Button
            id={'nextButton'}
            variant="primary"
            disabled={props.disabled}
            onClick={() => props.onStepChange(props.step + 1, true)}
          >
            {props.loadingsCrudAutomation &&
            props.loadingsCrudAutomation.filter((item) => item === true).length > 0 ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              <>{t('navigation_options.Next')}</>
            )}
          </Button>
        ) : (
          <Button
            id={'finishButton'}
            variant="primary"
            disabled={props.disabled}
            onClick={() => props.onFinish()}
          >
            {props.loadingsCrudAutomation &&
            props.loadingsCrudAutomation.filter((item) => item === true).length > 0 ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : props.isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" style={{ fontSize: 15, width: 25, height: 25 }} />
              </div>
            ) : (
              <>{t('navigation_options.Finish')}</>
            )}
          </Button>
        )}
      </div>
    </div>
  );
}

export default AutomationWizardProgressBar;
