import {
  ADD_ENUMCOLUMN,
  CHANGE_ENUM_PROPERTY,
  CHANGE_ENUM_NAME,
  DELETE_ENUM,
  DELETE_ENUMCOLUMN,
  SET_ENUM_POSITION,
  CHANGE_ENUM_FOLDER
} from '../actions/enums';
import { DatabaseStudioState, RESTORE_LAST_VALID_STATE, SAVE_LAST_VALID_STATE } from '../index';
import { Middleware } from 'redux';
import {
  CHANGE_COLUMN_DEFAULT,
  CHANGE_COLUMN_DESCRIPTION,
  CHANGE_COLUMN_NAME,
  CHANGE_COLUMN_NULLABLE,
  CHANGE_COLUMN_PROPERTY,
  CHANGE_COLUMN_TYPE,
  DELETE_COLUMN,
  MOVE_COLUMN
} from '../actions/columns';
import {
  ADD_ENUM,
  CHANGE_TABLE_NAME,
  SET_POSITION,
  CHANGE_TABLE_PROPERTY,
  CHANGE_TABLE_COLUMNS_ORDER,
  CHANGE_TABLE_FOLDER
} from '../actions/frames';
import {
  ADD_COLUMN,
  ADD_INDEX,
  ADD_KEY_COLUMN,
  ADD_RELATIONSHIP,
  ADD_TABLE,
  DELETE_INDEX,
  DELETE_RELATIONSHIP,
  DELETE_TABLE,
  DUPLICATE_TABLE
} from '../actions/root';
import { DataService } from '../../../services';
import {
  CHANGE_ENUMCOL_DESCRIPTION,
  CHANGE_ENUMCOL_LITERAL,
  CHANGE_ENUMCOL_ORDINAL,
  MOVE_ENUM_COLUMN
} from '../actions/enum_column';
import { CHANGE_INDEX_PROPERTY, UPDATE_INDEX_COLUMNS } from '../actions/indexes';
import { CHANGE_PROPERTY, CHANGE_RELATIONSHIP_TYPE } from '../actions/relationship';
import {
  ADD_WIDGET,
  CHANGE_WIDGET,
  DELETE_WIDGET,
  UPDATE_WIDGET_POSITION
} from 'modules/designer/studio/store/actions/widgets';
import { setErrorMessage } from '../actions/studio';

const actions = [
  // Root

  ADD_TABLE,
  DELETE_TABLE,
  DUPLICATE_TABLE,
  ADD_RELATIONSHIP,
  DELETE_RELATIONSHIP,
  ADD_COLUMN,
  ADD_KEY_COLUMN,
  ADD_INDEX,
  DELETE_INDEX,

  // Table
  CHANGE_TABLE_NAME,
  CHANGE_TABLE_FOLDER,
  CHANGE_TABLE_PROPERTY,
  CHANGE_TABLE_COLUMNS_ORDER,
  SET_ENUM_POSITION,
  MOVE_COLUMN,

  // Column
  CHANGE_COLUMN_DESCRIPTION,
  CHANGE_COLUMN_PROPERTY,
  CHANGE_COLUMN_NAME,
  CHANGE_COLUMN_TYPE,
  CHANGE_COLUMN_NULLABLE,
  CHANGE_COLUMN_DEFAULT,
  DELETE_COLUMN,

  // Enum

  ADD_ENUM,
  DELETE_ENUM,
  SET_POSITION,
  CHANGE_ENUM_PROPERTY,
  CHANGE_ENUM_NAME,
  CHANGE_ENUM_FOLDER,
  MOVE_ENUM_COLUMN,

  // Enum Columns

  ADD_ENUMCOLUMN,
  DELETE_ENUMCOLUMN,
  CHANGE_ENUMCOL_LITERAL,
  CHANGE_ENUMCOL_ORDINAL,
  CHANGE_ENUMCOL_DESCRIPTION,

  // Index

  CHANGE_INDEX_PROPERTY,
  UPDATE_INDEX_COLUMNS,

  // Relationship

  CHANGE_PROPERTY,
  CHANGE_RELATIONSHIP_TYPE,

  // Widgets

  ADD_WIDGET,
  CHANGE_WIDGET,
  UPDATE_WIDGET_POSITION,
  DELETE_WIDGET
];

let lastAction: string | null = null;
let timerRef: NodeJS.Timeout | null = null;

const MODELER_STUDIO_ERROR_MESSAGES: Record<string, string> = {
  ERROR_PROCESSING_ACTION: 'designer.errors.errorProcessingAction'
};

export const saveMiddleware: Middleware<Record<string, unknown>, DatabaseStudioState> = function (
  store
) {
  return (next) => (action) => {
    if (!actions.includes(action.type)) return next(action);

    if (lastAction === action.type && timerRef) {
      clearTimeout(timerRef);
    }

    lastAction = action.type;
    const nextAction = next(action);

    timerRef = setTimeout(() => {
      const { schema_id, module_id } = store.getState().studio;
      DataService.sendAction(module_id, schema_id, action).then(async (response) => {
        if (!response.ok) {
          store.dispatch({ type: RESTORE_LAST_VALID_STATE });
          const body: any = await response.json();

          const i18nKeyErrorMessage = MODELER_STUDIO_ERROR_MESSAGES.ERROR_PROCESSING_ACTION;
          store.dispatch(setErrorMessage(body?.message ?? i18nKeyErrorMessage));
        } else {
          store.dispatch({ type: SAVE_LAST_VALID_STATE });
        }
      });
    }, 350);

    return nextAction;
  };
};
