import React from 'react';
import Icon from '../../../icon';
import { SidebarPosition } from '../../../workboard/sidebar';
import styles from './styles.module.css';

type SidebarCollapseButtonProps = {
  colapse: boolean;
  handleColapse: () => void;
  position: SidebarPosition;
};

function SidebarCollapseButton({ position, colapse, handleColapse }: SidebarCollapseButtonProps) {
  const collapseArrowClassNames =
    position === SidebarPosition.LEFT ? styles.leftColapseArrow : styles.rightColapseArrow;

  const collapseArrowTransformStyles =
    position === SidebarPosition.LEFT
      ? { transform: 'rotate(180deg)', right: '-30px' }
      : { transform: 'rotate(180deg)', left: '-30px' };
  return (
    <div className={'h-100 w-1 d-flex flex-column align-center justify-content-center'}>
      <div
        className={collapseArrowClassNames}
        style={colapse ? { ...collapseArrowTransformStyles } : {}}
        onClick={handleColapse}
      >
        <Icon iconName={position === SidebarPosition.LEFT ? 'angles-left' : 'angles-right'}></Icon>
      </div>
    </div>
  );
}

export default SidebarCollapseButton;
