import EditorPanelRoot from './editor_panel_root';
import OptionsEditor from '../options_editor/options_editor';
import ReturnTypeAndNameEditor from './return_type_and_name_editor';
import ParameterListEditor from './parameter_list_editor';

export const EditorPanel = {
  Root: EditorPanelRoot,
  ParametersList: ParameterListEditor,
  ReturnTypeAndName: ReturnTypeAndNameEditor,
  Options: OptionsEditor
};
