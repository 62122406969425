import React from 'react';
import Dropdown from 'web_ui/function_editor/action_inputs/components/dropdown';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { FrontendProperty } from 'modules/designer/types';

type PropertyPickerProps = {
  label: string;
  actionUuid: string;
  value: string;
  handleChange: (text: string) => void;
  filterByList: boolean;
  filterByDataType?: (
    state: FunctionEditorState,
    actionUuid: string,
    dataType: string,
    objectUuid?: string
  ) => boolean;
  isFromSideBar?: boolean;
  isFromArgument?: boolean;
};

export function PropertyPicker({
  label,
  actionUuid,
  value,
  handleChange,
  filterByList,
  filterByDataType,
  isFromArgument,
  isFromSideBar
}: PropertyPickerProps) {
  const state = useSelector((state: FunctionEditorState) => state);

  const functionId = state.editor.functionId;
  const functionInfo = state.functions[functionId];
  const globalProperties = state.globals ? state.globals.properties : {};

  let filtered: FrontendProperty[] = [];
  if (functionInfo) {
    filtered = globalProperties ? Object.values(globalProperties) : [];
    if (filterByList) {
      filtered = filtered.filter((p) => p.list);
    }
    if (filterByDataType) {
      filtered = filtered.filter((p) => {
        return filterByDataType(state, actionUuid, p.type, p?.object);
      });
    }
  }

  return (
    <Dropdown
      items={filtered}
      placeholder={'Select a property'}
      label={label}
      value={globalProperties && globalProperties[value]}
      handleChange={handleChange}
      isFromArgument={isFromArgument}
      isFromSideBar={isFromSideBar}
    />
  );
}
