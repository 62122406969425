import React from 'react';

interface LookupFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function LookupFormatterPreview({ formatterParams, value }: LookupFormatterPreviewProps) {
  return <label>{formatterParams.values[value] ? formatterParams.values[value] : value}</label>;
}

export default LookupFormatterPreview;
