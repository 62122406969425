import React, { ChangeEvent } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import Form from 'react-bootstrap/Form';
import { FONTS } from 'modules/designer/studio/toolbars/appTheme_toolbar/theme_manager_modal/bootstrap/text_section';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function FontControl(props: ControlProps) {
  const { t } = useTranslation();

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    if (props.onChange) props.onChange(e.target.value, 'fontFamily');
  }

  return (
    <div
      className="mb-3"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <div style={{ display: 'flex', marginBottom: '.5rem' }}>
        <label title={props.label}>{props.label}</label>
        {props.tooltip && (
          <HelpPopover
            placement="top"
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>

      <Form.Select
        id={'selectInput'}
        className={`${styles.defaultInput} form-select form-select-sm`}
        value={props.value?.['fontFamily']}
        onChange={handleChange}
        style={{ fontFamily: props.value ? props.value['fontFamily'] : '' }}
      >
        {FONTS?.sort().map((item, index) => {
          return (
            <option key={index} value={item} style={{ fontFamily: 'georgia' }}>
              {item}
            </option>
          );
        })}
      </Form.Select>
    </div>
  );
}

export default FontControl;
