import { Controller } from '../types';
import { ControllerResponse, ControllerSchema } from './schemas/Controllers';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class ControllersRepo {
  /**
   * Returns the complete description of a controller.
   */
  async getController(controllerId: string): Promise<ControllerResponse> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/controllers/${controllerId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ControllerResponse>;

      throw new Error('Something went wrong while trying to fetch Controller.');
    });
  }

  /**
   * Get controllers (from a specific module).
   */
  async getControllers(moduleId: string, name?: string): Promise<ControllerResponse[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    let url = `${API_URL}/modules/${moduleId}/controllers`;
    if (name) url = url + `?name=${name}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ControllerResponse[]>;

      throw new Error('Something went wrong while trying to fetch Controllers.');
    });
  }

  /**
   * Create a new controller (attached to a module).
   */
  async createController(moduleId: string, controller: Controller): Promise<ControllerResponse> {
    const controllerSchema = controller as ControllerSchema;

    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(controllerSchema)
    };

    const url = `${API_URL}/modules/${moduleId}/controllers`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ControllerResponse>;

      throw new Error('Something went wrong while trying to create Controller.');
    });
  }

  /**
   * Delete controller.
   */
  async deleteController(controllerId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/controllers/${controllerId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete Controller.');
    });
  }

  /**
   * Update controller.
   */
  async updateController(
    controllerId: string,
    controller: Controller
  ): Promise<ControllerResponse> {
    const controllerSchema = controller as ControllerSchema;

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(controllerSchema)
    };

    const url = `${API_URL}/controllers/${controllerId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ControllerResponse>;

      throw new Error('Something went wrong while trying to update Controller.');
    });
  }

  /**
   * Update folder of a Controller.
   */
  async changeFolder(controllerId: string, folderId: string) {
    const schema = {
      controllerId: controllerId,
      folder_id: folderId
    };

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(schema)
    };

    const url = `${API_URL}/controllers/${controllerId}/changefolder`;
    const response = await fetch(url, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Something went wrong while trying to delete object.');
    }
  }
}
