import React, { useContext, useEffect, useState } from 'react';
import styles from 'modules/logic_builder/styles.module.css';
import { Button, Form, Col, Row } from 'react-bootstrap';
import LogicBuilderContext from '../../../store';
import { EndpointsList } from '../../../components/endpoints_list';
import { useTranslation } from 'react-i18next';
import EmptyMessage from 'web_ui/empty';
import { useParams } from 'react-router-dom';
import { EndpointCreatorDialog } from 'modules/logic_builder/components/dialogs/endpoint_creator_dialog';
import SearchBar from 'web_ui/search_bar';
import { ControllerEditorDialog } from 'modules/logic_builder/components/dialogs/controller_editor_dialog';
import Icon from 'web_ui/icon';
import { ControllerCreatorDialog } from 'modules/logic_builder/components/dialogs/controller_creator_dialog';
import { Table } from '../../../../modeler/types';
import { ObjectsService } from '../../../services';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import TagManagerModal from './components/tag_manager';

type LogicBuilderProps = Record<string, unknown>;

function ControllerEditor(props: LogicBuilderProps) {
  const [showControllerCreatorModal, setShowControllerCreatorModal] = useState(false);
  const [showControllerEditorModal, setShowControllerEditorModal] = useState(false);
  const [showEndpointCreatorDialog, setShowEndpointCreatorDialog] = useState(false);
  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);
  const { controller_id, module_id } = useParams();
  const { endpoints, controllers } = useContext(LogicBuilderContext);
  const [filter, setFilter] = useState('');
  const [controllerName, setControllerName] = useState('');
  const [controllerPath, setControllerPath] = useState('');
  const [tables, setTables] = useState<Table[]>([]);
  const selectedController = controllers[controller_id ?? ''];
  const [openTagManager, setOpenTagManager] = useState<boolean>(false);

  const { t } = useTranslation();
  const searchTxt = t('Search');

  useEffect(() => {
    if (!module_id) return;

    ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setTables(schema?.tables ? schema.tables : []);
    });
  }, [module_id]);

  useEffect(() => {
    const newController = controllers[controller_id ?? ''];
    setControllerName(newController?.name ?? '');
    setControllerPath(newController?.path ?? '');
  }, [controller_id, controllers]);

  const hideTagManager = () => {
    setOpenTagManager(false);
  };

  const openTagManagerFun = () => {
    setOpenTagManager(true);
  };

  return (
    <>
      <div className={styles.LogicEditorWrapper}>
        <Row className="mb-3">
          <Col sm={7}>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label style={{ marginRight: '1rem', minWidth: '5rem' }}>
                {t('automation.step1.Controller')}
              </Form.Label>
              <Form.Control
                id={'controllerName'}
                onChange={(e) => setControllerName(e.target.value)}
                value={controllerName}
                disabled
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label style={{ marginRight: '1rem', minWidth: '5rem' }}>
                {t('logicBuilder.newController.path')}
              </Form.Label>
              <Form.Control
                id={'controllerPath'}
                onChange={(e) => setControllerPath(e.target.value)}
                value={controllerPath}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={5}>
            <div className={styles.editButtonWrapper}>
              <Button
                id={'tagButton'}
                variant="primary"
                onClick={() => openTagManagerFun()}
                className="me-2"
                style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}
              >
                <Icon iconName="tag" />
                {t('logicBuilder.Tags')}
              </Button>
              {selectedController && !selectedController.native && (
                <Button
                  id={'editButtonController'}
                  variant="primary"
                  onClick={() => setShowControllerEditorModal(true)}
                  disabled={!selectedController}
                >
                  <Icon iconName="pen-to-square" extraProps="pe-1"></Icon>
                  {t('designer.componentCardDropdown.Edit')}
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <hr />
        {/* Endpoints Wrapper */}
        {selectedController?.uuid && (
          <div className={styles.LogicEditorListWrapper}>
            {/* Search bar*/}
            <div className={styles.LogicBuilderControl}>
              <Form id={'LogicBuilderControlSearch'} className={styles.LogicBuilderControlSearch}>
                <SearchBar
                  id={'searchField'}
                  placeholder={searchTxt}
                  text={filter}
                  setText={setFilter}
                  focus={true}
                />
              </Form>
              <div className={styles.LogicBuilderControlButtons}>
                {selectedController && !selectedController.native && (
                  <>
                    <Button
                      id="codePreview"
                      className={styles.ControlButton}
                      variant="primary"
                      onClick={() => setShowCodePreviewDialog(true)}
                    >
                      <Icon iconName="code" extraProps="pe-1"></Icon>
                      {t('CodePreview')}
                    </Button>
                    <Button
                      id={'createButton'}
                      className={styles.ControlButton}
                      variant="primary"
                      onClick={() => setShowEndpointCreatorDialog(true)}
                    >
                      <Icon iconName="plus" extraProps="pe-1"></Icon>
                      {t('organizations.new_organization.Create')}
                    </Button>
                  </>
                )}
              </div>
            </div>
            {/* Endpoints */}
            <div>
              <EndpointsList
                key={selectedController.uuid}
                search={filter.toLowerCase().trim()}
                endpoints={endpoints[selectedController.uuid] ?? []}
                onCreate={() => setShowEndpointCreatorDialog(true)}
                controllerPath={selectedController.path}
              />
              <EndpointCreatorDialog
                controllerUuid={selectedController.uuid}
                show={showEndpointCreatorDialog}
                onClose={() => setShowEndpointCreatorDialog(false)}
              />
              <ControllerEditorDialog
                controllerUuid={selectedController.uuid}
                show={showControllerEditorModal}
                onClose={() => setShowControllerEditorModal(false)}
              />{' '}
            </div>
          </div>
        )}

        {(!selectedController || !selectedController.uuid) && (
          <div className="w100 h100">
            <EmptyMessage
              message={t('logicBuilder.NoControllerSelected')}
              icon="arrow-left"
              actionMessage={t('logicBuilder.CreateController') ?? ''}
              linkAction={() => setShowControllerCreatorModal(true)}
            ></EmptyMessage>
            <ControllerCreatorDialog
              tables={tables}
              show={showControllerCreatorModal}
              onClose={() => setShowControllerCreatorModal(false)}
            />
          </div>
        )}

        <TagManagerModal open={openTagManager} onHide={hideTagManager} />
      </div>
      {selectedController?.uuid && (
        <CodeEditorModal
          show={showCodePreviewDialog}
          handleClose={() => setShowCodePreviewDialog(false)}
          id={selectedController.uuid}
          previewType={CodePreviewType.CONTROLLER}
        />
      )}
    </>
  );
}

export default ControllerEditor;
