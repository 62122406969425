import { TemplateRepository } from '../repos';
import { CrudTemplate, Template } from '../types';

export class TemplatesServ {
  async convertViewToTemplate(view: string, formData: FormData): Promise<Template> {
    return await TemplateRepository.convertViewToTemplate(view, formData);
  }

  async getTemplates(type: string, viewType: string, app?: string): Promise<Template[]> {
    return await TemplateRepository.getTemplates(type, viewType, app);
  }

  async getCrudTemplates(
    moduleId: string,
    entityId: string,
    objectId: string,
    controllerId: string
  ): Promise<CrudTemplate> {
    return await TemplateRepository.getCrudTemplates(moduleId, entityId, objectId, controllerId);
  }

  async getTemplateThumbnail(templateId: string) {
    return await TemplateRepository.getTemplateThumbnail(templateId);
  }
}
