import React from 'react';
import styles from './styles.module.css';
import { addComponentSet, ComponentPayload } from 'modules/designer/studio/store/actions/root';
import { ControlProps } from '../..';
import produce from 'immer';
import { GridColumnData } from 'modules/designer/studio/exocode_components/grid';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateContainer } from './gridColumn';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

interface GridColumnsQuantityCardProps {
  currentColumnQuantity: number;
  columnQuantity: number;
  settings: ControlProps;
  view_id: string;
}

function GridColumnsQuantityCard({
  currentColumnQuantity,
  columnQuantity,
  settings,
  view_id
}: GridColumnsQuantityCardProps) {
  const dispatch = useDispatch();
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const { t } = useTranslation();

  function handleUpdateGridSize(newSize: number) {
    if (!settings || !selectedComponent) return;

    const willCreateQuantity = newSize - currentColumnQuantity;
    const componentsToCreateList: ComponentPayload[] = [];

    const newChildrenState = produce(settings.value.columns, (draft: GridColumnData[]) => {
      const targetWidth = 12; // The total width must always be 12
      const totalCurrentWidth = draft.reduce((sum, column) => sum + column.width, 0);

      // Add new columns with width 1
      for (let i = 0; i < willCreateQuantity; i++) {
        const newColumnTitle =
          'COLUMN_' + (currentColumnQuantity + i) + uuidv4().toString().substring(0, 5);
        draft.push({ title: newColumnTitle, width: 1 });

        const containerComponentToCreate = handleCreateContainer(
          view_id,
          selectedComponent,
          newColumnTitle
        );
        componentsToCreateList.push(containerComponentToCreate);
      }

      // Recalculate the total width after adding new columns
      const totalNewWidth = draft.reduce((sum, column) => sum + column.width, 0);
      const excessWidth = totalNewWidth - targetWidth;

      // If the total width exceeds the target, adjust the column widths
      if (excessWidth > 0) {
        let remainingExcess = excessWidth;

        for (const column of draft) {
          if (column.width > 1 && remainingExcess > 0) {
            const reduction = Math.min(remainingExcess, column.width - 1);
            column.width -= reduction;
            remainingExcess -= reduction;
          }
          if (remainingExcess <= 0) break;
        }
      }

      // Ensure total width is exactly 12
      const finalTotalWidth = draft.reduce((sum, column) => sum + column.width, 0);
      let finalAdjustment = targetWidth - finalTotalWidth;

      if (finalAdjustment > 0) {
        for (const column of draft) {
          if (finalAdjustment > 0 && column.width > 1) {
            const increment = Math.min(finalAdjustment, 12 - finalTotalWidth);
            column.width += increment;
            finalAdjustment -= increment;
          }
          if (finalAdjustment <= 0) break;
        }
      }
    });

    // Apply changes to state
    settings.onChange && settings.onChange(newChildrenState, 'columns');
    dispatch(addComponentSet(componentsToCreateList));
  }

  return (
    <div className={`w-100 d-flex flex-column align-items-center me-2`}>
      <div
        className={`w-100 border border-body rounded row p-1 ${styles.columnSizeShortcutOption}`}
        style={{ height: '69px' }}
        onClick={() => handleUpdateGridSize(columnQuantity)}
      >
        {new Array(columnQuantity).fill(0).map((quantity, index) => {
          return (
            <div key={index} className="col h-100" style={{ padding: '4px' }}>
              <div className={`w-100 h-100 bg-body-secondary ${styles.shortcutColumn}`} />
            </div>
          );
        })}
      </div>
      <label className="text-body-tertiary" style={{ fontSize: '12px' }}>
        {columnQuantity} {t('designer.right_side.controls.components.grid.Column')}
        (s)
      </label>
    </div>
  );
}

export default GridColumnsQuantityCard;
