export enum AppType {
  WEBAPP = 'webapp',
  MOBILE = 'mobile'
}

export enum CustomApp {
  BACKEND = 'has_backend',
  FRONTEND = 'has_frontend',
  DATABASE = 'has_database'
}

export enum Architecture {
  MICROSERVICES = 'microservices',
  MONOLITH = 'monolith'
}

export enum Template {
  BLANK = 'Blank',
  LIBRARY = 'Book Manager',
  TODO = 'To Do List',
  NAVBAR = 'Navbar',
  LOGIN = 'Login',
  SETTINGS = 'Settings',
  DETAILS = 'Details'
}

export enum CountryCode {
  SAUDI_ARABIA = 'ar-SA',
  BANGLADESH = 'bn-BD',
  BANGLA_INDIA = 'bn-IN',
  CZECH_REPUBLIC = 'cs-CZ',
  DENMARK = 'da-DK',
  AUSTRIA = 'de-AT',
  GERMAN_SWITZERLAND = 'de-CH',
  GERMANY = 'de-DE',
  GREECE = 'el-GR',
  AUSTRALIA = 'en-AU',
  ENGLISH_CANADA = 'en-CA',
  UK = 'en-GB',
  IRELAND = 'en-IE',
  ENGLISH_INDIA = 'en-IN',
  NEW_ZELAND = 'en-NZ',
  ENGLISH_US = 'en-US',
  SOUTH_AFRICA = 'en-ZA',
  ARGENTINA = 'es-AR',
  CHILE = 'es-CL',
  COLUMBIA = 'es-CO',
  SPAIN = 'es-ES',
  MEXICO = 'es-MX',
  SPANISH_US = 'es-US',
  FINLAND = 'fi-FI',
  FRENCH_BELGIUM = 'fr-BE',
  FRENCH_CANADA = 'fr-CA',
  FRENCH_SWITZERLAND = 'fr-CH',
  FRANCE = 'fr-FR',
  ISRAEL = 'he-IL',
  HINDI_INDIA = 'hi-IN',
  HUNGARY = 'hu-HU',
  INDONESIA = 'id-ID',
  ITALIAN_SWITZERLAND = 'it-CH',
  ITALY = 'it-IT',
  JAPAN = 'jp-JP',
  KOREA = 'ko-KR',
  DUTCH_BELGIUM = 'nl-BE',
  NETHERLANDS = 'nl-NL',
  NORWAY = 'no-NO',
  POLAND = 'pl-PL',
  BRAZIL = 'pt-BR',
  PORTUGAL = 'pt-PT',
  ROMANIA = 'ro-RO',
  RUSSIA = 'ru-RU',
  SLOVAKIA = 'sk-SK',
  SWEDEN = 'sv-SE',
  TAMIL_INDIA = 'ta-IN',
  SRI_LANKA = 'ta-LK',
  THAILAND = 'th-TH',
  TURKEY = 'tr-TR',
  CHINA = 'zh-CN',
  HONG_KONG = 'zh-HK',
  TAIWAN = 'zh-TW'
}

export enum CountryLanguageName {
  SAUDI_ARABIA = 'المملكة العربية السعودية',
  PORTUGAL = 'Português (Portugal)',
  BRAZIL = 'Português (Brasil)',
  UK = 'English (UK)',
  ENGLISH_US = 'English (US)',
  SPAIN = 'Español'
}

export enum FrontendFramework {
  REACT = 'react',
  ANGULAR = 'angular'
}

export enum Dependency {
  BOOTSTRAP = 'bootstrap',
  MATERIAL_DESIGN = 'material_design'
}

export enum BackendFramework {
  SPRING = 'spring'
}

export enum AdditionalModules {
  AUTHENTICATION = 'Authentication',
  ASSETS = 'Assets'
}

export enum DataSource {
  MYSQL = 'mysql',
  POSTGRESQL = 'postgresql',
  H2 = 'h2'
}

export enum Theme {
  DARK = 'Dark',
  WHITE = 'Light'
}
