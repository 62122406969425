import * as React from 'react';

function OneColumnIcon(props: any) {
  return (
    <svg
      width="99"
      height="100"
      viewBox="0 0 99 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.375 26.8388C14.6136 26.8388 12.375 29.0774 12.375 31.8388V66.8388C12.375 69.6003 14.6136 71.8388 17.375 71.8388H82C84.7614 71.8388 87 69.6003 87 66.8388V49.3388V31.8388C87 29.0774 84.7614 26.8388 82 26.8388H17.375Z"
        fill="#ACB5BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 71.8388C14.6136 71.8388 12.375 69.6003 12.375 66.8388V31.8388C12.375 29.0774 14.6136 26.8388 17.375 26.8388H82C84.7614 26.8388 87 29.0774 87 31.8388V49.3388V66.8388C87 69.6003 84.7614 71.8388 82 71.8388H17.375Z"
        fill="#79828A"
      />
    </svg>
  );
}

export default OneColumnIcon;
