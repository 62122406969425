import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

export const FORM_MANIFEST: ComponentManifest = {
  type: 'FORM',
  name: 'Form',
  descriptionId: 'LayoutFormDescription',
  description: '',
  icon: 'fa-solid fa-keyboard',
  group: ComponentGroups['DATA'],
  previewHeight: 170,
  previewWidth: 300,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'SubmitText',
      key: 'submitText',
      tooltip: 'designer.right_side.controls.components.Form.submitText'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'ExtraText',
      key: 'extraText',
      tooltip: 'designer.right_side.controls.components.Form.extraText'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.Form.visible'
    },
    {
      controlType: ControlsTypes.FORM_CONTENT,
      controlLabel: 'Variable',
      key: 'content',
      tooltip: 'designer.right_side.controls.components.Form.content'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['SUBMIT', 'CLICK']
};
