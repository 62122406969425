import produce from 'immer';
import { VariablesState } from '../types/variables';
import { ADD_VARIABLE, AddVariableAction, doAddVariable } from '../events/variables/add_var';
import {
  DELETE_VARIABLE,
  DeleteVariableAction,
  doDeleteVariable
} from '../events/variables/delete_var';
import {
  doUpdateVariable,
  UPDATE_VARIABLE,
  UpdateVariableAction
} from '../events/variables/update_var';
import {
  CHANGE_VARIABLE_TYPE,
  ChangeVariableTypeAction,
  doChangeVariableType
} from '../events/variables/change_var_type';
import { initialState } from '../initial_empty_state';

type VariablesActions =
  | AddVariableAction
  | UpdateVariableAction
  | ChangeVariableTypeAction
  | DeleteVariableAction;

export const variablesReducer = (
  state: VariablesState = initialState.variables,
  action: VariablesActions
): VariablesState => {
  return produce(state, (draft: VariablesState) => {
    switch (action.type) {
      case ADD_VARIABLE:
        return doAddVariable(draft, action);
      case UPDATE_VARIABLE:
        return doUpdateVariable(draft, action);
      case CHANGE_VARIABLE_TYPE:
        return doChangeVariableType(draft, action);
      case DELETE_VARIABLE:
        return doDeleteVariable(draft, action);
      default:
        return state;
    }
  });
};
