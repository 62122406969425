import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import { UserProfile } from '../../types/auth_types';
import { useTranslation } from 'react-i18next';
import { AuthService } from '../../services';
import { Alert, Button, Form } from 'react-bootstrap';
import { PopupAlert, PopupAlertVariant } from '../../../../web_ui/popup_alert';
import SessionContext from '../../store';
import Confirmation from '../../../../web_ui/confirmation';
import { disablePortalCreator } from '../../../payments/types';

type DeleteAccountFormProps = {
  user: UserProfile;
};

function DeleteAccountForm(props: DeleteAccountFormProps) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isShowConfirmationDialog, setIsShowConfirmationDialog] = React.useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  const handleDeleteUser = async (isUndo: boolean) => {
    try {
      setIsLoading(true);
      const isOk = await AuthService.deleteUser(props.user.id, isUndo);
      if (isOk) {
        showSuccessPopup(
          isUndo
            ? 'account_settings.delete_account.DeleteAccountUnscheduled'
            : 'account_settings.delete_account.DeleteAccountScheduled'
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        showErrorPopup(error.message);
      }
    } finally {
      setIsLoading(false);
      setIsShowConfirmationDialog(false);
      session.reloadUser();
    }
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const showSuccessPopup = (message: string) => {
    setAlertVariant('success');
    setAlertMessage(message);
  };

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  return (
    <>
      {props.user.deleteByDate && (
        <Alert className={styles.Form} variant="danger">
          {t('account_settings.delete_account.DeleteAccountPending') +
            ' ' +
            new Date(props.user.deleteByDate).toLocaleDateString() +
            '.'}
          <Button onClick={() => handleDeleteUser(true)} variant="outline-danger">
            {t('account_settings.delete_account.Revert')}
          </Button>
        </Alert>
      )}
      {!props.user.deleteByDate && (
        <div className="border border-2 border-danger rounded p-2">
          <div className={styles.dangerZoneContainer}>
            <div className={styles.dangerZoneLabels}>
              <Form.Label id={'dangerZoneButton'} className={styles.dangerZoneTitle}>
                {t('account_settings.delete_account.DeleteAccount')}
              </Form.Label>
              <Form.Label className={styles.dangerZoneDescription}>
                {t('account_settings.delete_account.DeleteAccountDescription')}
              </Form.Label>
            </div>
            <Button
              id={'deleteButton'}
              variant="danger"
              disabled={isLoading || disablePortalCreator(props.user.email)}
              onClick={() => setIsShowConfirmationDialog(true)}
            >
              {t('account_settings.delete_account.Delete')}
            </Button>
          </div>
        </div>
      )}
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
      )}
      <Confirmation
        show={isShowConfirmationDialog}
        message={t('account_settings.delete_account.DeleteAccountQuestion')}
        showItemNameInput={true}
        itemName={props.user.email}
        onConfirmation={() => handleDeleteUser(false)}
        onCancel={() => setIsShowConfirmationDialog(false)}
        onClose={() => setIsShowConfirmationDialog(false)}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
        isLoading={isLoading}
      />
    </>
  );
}

export default DeleteAccountForm;
