import React, { useState } from 'react';
import Icon from '../../icon';
import styles from './styles.module.css';
import SessionContext from '../../../modules/auth/store';
import { useTranslation } from 'react-i18next';

type ErrorPaneProps = {
  error: string;
};

const ErrorPane = (props: ErrorPaneProps) => {
  const { t } = useTranslation();
  const session = React.useContext(SessionContext);
  const [showErrors, setShowErrors] = useState(false);

  function countOccurrences(separator: string): number {
    const cnt = props.error.split(separator).length;
    return cnt >= 1 ? cnt - 1 : 0;
  }

  const warnCnt = countOccurrences('WARNING:');
  const errorCnt = countOccurrences('ERROR:');

  return (
    <div style={{ paddingTop: '10px' }}>
      <div
        className={styles.headerContainer}
        style={
          showErrors
            ? {
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0
              }
            : {}
        }
        onClick={() => setShowErrors(!showErrors)}
      >
        <Icon
          iconName={showErrors ? 'chevron-up' : 'chevron-down'}
          extraProps={styles.headerIcon}
        />
        {t('WarningsErrors')}
        {(warnCnt > 0 || errorCnt > 0) && (
          <>
            {' '}
            [{warnCnt}/{errorCnt}]
          </>
        )}
      </div>
      {showErrors && (
        <div className={styles.bodyContainer}>
          <div
            className={`${styles.contentContainer} ${
              session.preferences['exocode-theme']
                ? styles.contentDarkTheme
                : styles.contentLightTheme
            }`}
          >
            {props.error}
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorPane;
