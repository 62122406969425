import React, { useEffect } from 'react';
import { TableUUID } from '../../../../../../../types';
import { useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../../../../../store';
import useFetchTableRelationships from '../../hooks/useFetchTableRelationships';
import styles from './styles.module.css';
import RelationshipLine from '../relationship-line';
import { isEmptyOrOnlySpaces } from '../../../../../../../../../utils/inputValidation';
import { useTranslation } from 'react-i18next';

type RelationshipsListProps = {
  tableID: TableUUID;
  selectedRelationshipID: string;
  selectRelationship: React.Dispatch<React.SetStateAction<string>>;
  newRelationship: boolean;
  searchText: string;
};
export default function RelationshipsList({
  tableID,
  selectedRelationshipID,
  selectRelationship,
  newRelationship,
  searchText
}: RelationshipsListProps) {
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const relationships = useSelector((state: DatabaseStudioState) => state.relationships);
  const { tableRelationships } = useFetchTableRelationships(tableID);

  useEffect(() => {
    if (!tableRelationships.includes(selectedRelationshipID))
      selectRelationship(tableRelationships[0]);
  }, [tableRelationships, selectedRelationshipID]);

  useEffect(() => {
    tableRelationships && selectRelationship(tableRelationships[0]);
  }, [tableID]);
  const { t } = useTranslation();
  const renderRelationshipsList = () => {
    return tableRelationships.map((relationshipID) => {
      if (!relationshipID || !relationships[relationshipID]) return;
      const rel = relationships[relationshipID];
      const fromTableName = tables[rel.from]
        ? tables[rel.from]?.content?.data?.name
        : rel?.info?.tableName;
      const toTableName = tables[rel.to]
        ? tables[rel.to]?.content?.data?.name
        : rel?.info?.tableName;
      if (
        !isEmptyOrOnlySpaces(searchText) &&
        !fromTableName?.toLowerCase().includes(searchText.toLowerCase()) &&
        !toTableName?.toLowerCase().includes(searchText.toLowerCase()) &&
        !rel.fromName?.toLowerCase().includes(searchText.toLowerCase()) &&
        !rel.toName?.toLowerCase().includes(searchText.toLowerCase()) &&
        !rel.description?.toLowerCase().includes(searchText.toLowerCase())
      )
        return;
      return (
        <RelationshipLine
          key={relationshipID}
          relationship={relationships[relationshipID]}
          selectedRelationshipID={selectedRelationshipID}
          selectRelationship={selectRelationship}
        />
      );
    });
  };
  return (
    <div className={`card p-2 ${styles.RelationshipsListWrapper}`}>
      {renderRelationshipsList()}
      {newRelationship && (
        <p className={'ms-0 text-muted bg-body-tertiary rounded-1 p-1'}>
          {t('modeler.NewRelationship')}
        </p>
      )}
    </div>
  );
}
