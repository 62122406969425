import { ComponentUUID, ManifestLinks, ViewUUID } from 'modules/designer/types';
import React, { forwardRef, memo, useRef, useState } from 'react';
import CollapseMenu from './components/collapse_menu';
import { IconText } from 'web_ui/workboard/sidebar/controls/IconTextControl';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import useCorrectedUrl from 'modules/designer/hooks/correctUrl';
import { MenuType } from 'web_ui/workboard/sidebar/controls/MenuTypeControl/menu_type_selector';
import SideMenu from './components/side_menu';

export type SideMenuData = {
  linkText: IconText;
  links: ManifestLinks;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  menuType: string;
  buttonOrientation?: string;
  imgSource?: string;
  roundedImg?: boolean;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type SideMenuStyle = {
  fontSize?: number;
  themeColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  staticColor?: string;
  color?: string;
  iconStaticColor: string;
  iconThemeColor: string;
  iconMargin: string;
  iconSize: string;
  css?: string;
  classes?: string[];
};

export type SideMenuComponentProps = {
  uuid: ComponentUUID;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  data: SideMenuData;
  styles: SideMenuStyle;
};

function SideMenuComponent(props: SideMenuComponentProps, ref: React.Ref<any>) {
  const mobileHideButtonRef = useRef<HTMLDivElement>(null);
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const correctUrl = useCorrectedUrl(props.data.imgSource);

  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    backgroundColor: props.styles.staticColor
  };

  removeDeclarationsWithUndefinedValue(style);

  const classes = props.styles.classes?.join(' ') || '';

  function handleToogleMenu() {
    if (ref && typeof ref === 'object') {
      if (isMenuHidden) {
        ref.current.style.display = 'block';
        if (mobileHideButtonRef.current)
          mobileHideButtonRef.current.style.transform = 'rotate(180deg)';
      } else {
        ref.current.style.display = 'none';
        if (mobileHideButtonRef.current)
          mobileHideButtonRef.current.style.transform = 'rotate(0deg)';
      }
      setIsMenuHidden(!isMenuHidden);
    }
  }

  return (
    <div
      className={`${styles.sideMenuWrapper} bg-${props.styles.themeColor} ${styles.menuHide} ${classes}`}
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
    >
      <div ref={mobileHideButtonRef} className={styles.menuHideIcon} onClick={handleToogleMenu}>
        <Icon iconName="chevron-right"></Icon>
      </div>

      <div className="h-100 d-flex flex-column">
        {props.data.imgSource && (
          <div className="d-flex flex-grow-0 justify-content-center align-items-center w-100 pt-4">
            <div
              className={styles.imgWrapper}
              style={{
                width: '72px',
                borderRadius: props.data.roundedImg ? '50%' : '0',
                overflow: props.data.roundedImg ? 'hidden' : 'visible'
              }}
            >
              <img
                ref={ref}
                src={correctUrl}
                className="w-100 h-100"
                alt={'sidemenu-logo'}
                title={'sidemenu-logo'}
              />
            </div>
          </div>
        )}

        {/* {props.data.links.children[props.uuid] && (
          <CollapseMenu
            data={props.data}
            styles={props.styles}
            uuid={props.uuid}
            viewUUID={props.viewUUID}
            parentUUID={props.parentUUID}
          />
        )} */}

        {props.data.links.children[props.uuid] &&
          (props.data.menuType === MenuType.SIDEBYSIDE ||
          props.data.menuType === MenuType.RIGHTSIDEBYSIDE ? (
            <SideMenu
              data={props.data}
              styles={props.styles}
              uuid={props.uuid}
              viewUUID={props.viewUUID}
              parentUUID={props.parentUUID}
            />
          ) : (
            <CollapseMenu
              data={props.data}
              styles={props.styles}
              uuid={props.uuid}
              viewUUID={props.viewUUID}
              parentUUID={props.parentUUID}
            />
          ))}
      </div>
    </div>
  );
}

export default memo(forwardRef(SideMenuComponent));
