import { LayoutComponent } from 'modules/designer/types';
import { getGridTemplate, GRID_TEMPLATE } from '../template';
import { CONTAINER_TEMPLATE } from '../../container';
import { v4 as uuidv4 } from 'uuid';
import { faker } from '@faker-js/faker';
import { LinkInfo } from 'modules/designer/studio/store/actions/root';
import { ComponentPayload } from './../../../store/actions/root';
import { GridColumnData } from '..';

export function handleGetGridGeneratedChilds(gridUUID: string, viewUUID: string, columns?: number) {
  const gridChilds: ComponentPayload[] = [];

  getGridTemplate(columns ? columns : 3).data?.columns.forEach((gridColumnData: GridColumnData) => {
    const componentInfo: ComponentPayload = handleContainerFactory(
      viewUUID,
      gridUUID,
      gridColumnData.title
    );
    gridChilds.push(componentInfo);
  });

  return gridChilds;
}

export function handleContainerFactory(
  viewUUID: string,
  parentUUID: string,
  section?: string
): ComponentPayload {
  const containerComponent: LayoutComponent = JSON.parse(
    JSON.stringify(Object.assign({}, CONTAINER_TEMPLATE))
  );

  containerComponent.uuid = uuidv4();
  containerComponent.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
  containerComponent.data = applyData(containerComponent.data);
  containerComponent.styles = applyStyles(containerComponent.styles);
  const link: LinkInfo = { viewUUID, parentUUID, section };

  return { component: containerComponent, link };
}

export const applyStyles = (styles: React.CSSProperties) => {
  return {
    ...styles,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  };
};

export const applyData = (data: any) => {
  return {
    ...data,
    height: '100',
    heightUnit: '%'
  };
};
