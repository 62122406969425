import { Widget, WidgetType } from '../types';

export const stickyNoteTemplate: Widget = {
  uuid: '',
  parent: '',
  type: WidgetType.STICKY_NOTE,
  posX: 0,
  posY: 0,
  data: {
    text: '',
    author: '',
    date: ''
  }
};
