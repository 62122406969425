import React, { useState } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultInput, DefaultLabel } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function TextControl({
  value,
  onChange,
  id,
  label,
  maxLength,
  validation,
  tooltip,
  errorMessages
}: ControlProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(value ?? '');
  const [isValid, setIsValid] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = React.useCallback(
    (value: string) => {
      setInputValue(value);
      if (validation) {
        const { valid, code } = validation(value);
        setIsValid(valid);
        if (errorMessages) {
          setErrorMessage(errorMessages[code ?? '']);
        }
        if (valid) {
          if (onChange) {
            onChange(value, id);
          }
        }
      } else {
        if (onChange) {
          onChange(value, id);
        }
      }
    },
    [errorMessages, id, onChange, validation]
  );

  const handleBlur = () => {
    setInputValue(value ?? '');
  };

  return (
    <div
      className="mb-3"
      style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
    >
      <div style={{ marginBottom: '.5rem' }}>
        <DefaultLabel
          style={{ display: 'flex', justifyContent: 'flex-start', whiteSpace: 'nowrap' }}
        >
          {label}
          {tooltip && (
            <HelpPopover
              placement="top"
              helpBoxProps={{
                description: t(`${tooltip}`) ?? ''
              }}
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </DefaultLabel>
      </div>
      <DefaultInput
        id={`form${label}`}
        onBlur={handleBlur}
        type="text"
        className={`${styles.defaultInput} form-control form-control-sm ${
          isValid === false && styles.invalidTextInput
        }`}
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
        maxLength={maxLength}
      />
      {errorMessage && <span className={styles.errorMsg}>{t(errorMessage)}</span>}
    </div>
  );
}

export default TextControl;
