import { Endpoint, FunctionParameterCrud } from 'modules/logic_builder/types';
import React from 'react';
import { CrudData } from '../../../..';
import { Form } from 'react-bootstrap';
import { Argument } from 'web_ui/function_editor/store/types/manifestsAndInputs';
import { TYPE_PICKER_TYPES } from 'web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import styles from './styles.module.css';
import useSession from 'hooks/useSession';

interface Props {
  ParamFunc: FunctionParameterCrud;
  crudData: CrudData;
  onChange: (data: CrudData) => void;
  currentEndpoint: Endpoint;
  allParam: FunctionParameterCrud[];
}

function MappingIntoEnpoint({ ParamFunc, crudData, onChange, currentEndpoint, allParam }: Props) {
  const [session] = useSession();
  const handleChangeMaping = (val: string) => {
    const endpointCopy = currentEndpoint;

    // pushing into the index signature
    // id param function -> id param endpoint
    const moutingStructure: { [key: string]: Argument } = {
      ...currentEndpoint.action.data.function.arguments,
      [ParamFunc.uuid!]: { type: TYPE_PICKER_TYPES.PARAM, value: val, objectNode: undefined }
    };

    endpointCopy.action.data.function.arguments = moutingStructure;

    // override
    const allEndpoints = crudData.endpoints;
    allEndpoints[allEndpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)] =
      endpointCopy;

    const newCrud = {
      ...crudData,
      endpoints: allEndpoints
    };

    onChange(newCrud);
  };

  const selectedEndpointParameters =
    currentEndpoint.action.data.function.arguments ?? ({} as { [key: string]: Argument });

  return (
    <div className={styles.container}>
      <p
        className={styles.paragraph}
        style={{
          color: session.preferences['exocode-theme'] ? '#FFFFFF' : '#1c2025'
        }}
      >
        {ParamFunc.name!}:
      </p>
      <Form.Select
        id="selectTable"
        onChange={(e) => {
          handleChangeMaping(e.target.value);
        }}
      >
        {currentEndpoint.parameters.map((item, index) => (
          <option
            key={item.uuid}
            value={item.uuid}
            selected={
              !!ParamFunc.uuid && selectedEndpointParameters[ParamFunc.uuid]?.value === item.uuid
            }
          >
            {item.name}
          </option>
        ))}
      </Form.Select>
      <span style={{ marginRight: '5px' }}>
        {allParam.length > 1
          ? allParam.indexOf(ParamFunc) === allParam.length - 1 // get the last one
            ? ''
            : ','
          : ''}
      </span>
    </div>
  );
}

export default MappingIntoEnpoint;
