import React, { DragEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Folder, FolderType } from 'modules/designer/types';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Icon from 'web_ui/icon';
import { useDispatch, useSelector } from 'react-redux';
import { DatabaseStudioState } from '../../store';
import { deleteTable } from '../../store/actions/root';
import { setSelectedFrame } from '../../store/actions/studio';
import AdvancedEditor from '../../components/advanced_editor';
import { LocalStorageManager } from 'utils/localStorage/localstorage';
import { ContextMenuItem, FolderItem, RenderFolders } from 'web_ui/folders';
import { deleteEnumFrame } from '../../store/actions/enums';
import {
  checkFolderNameAlreadyExists,
  deletefolder,
  mod_dragleave,
  mod_dragover,
  mod_dragstart,
  mod_drop,
  mod_saveitem,
  savefolder,
  updateFoldersOnCreate,
  updateFoldersOnDelete,
  updateFoldersOnMoveFolder,
  updateFoldersOnRename
} from 'web_ui/folders/callbacks';
import CreateFolderDialog from 'modules/designer/studio/toolbars/views_toolbar/create_folder_modal';
import { validateFolderName, validateFrameName } from 'utils/inputValidation';
import { DraggableResource } from 'web_ui/folders/draggable_resource/draggable_resource';
import styles from './styles.module.css';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

export interface ModelProps {
  folders?: Folder[];
  updateFolders: (folders: Folder[]) => void;
}

export function ModelToolbar({ folders, updateFolders }: ModelProps) {
  const { t } = useTranslation();
  const { module_id, app_id } = useParams();
  const [folderItems, setFolderItems] = useState<FolderItem[]>([]);
  const [showFolderDialog, setShowFolderDialog] = useState<string>();
  const getNestedItems = (): Record<string, boolean> => {
    if (!app_id || !module_id) return {};
    const getItems = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (getItems[module_id] && getItems[module_id].dbmodeler.tableFolders.collapsedFolders) {
      return getItems[module_id].dbmodeler.tableFolders.collapsedFolders;
    }
    return {};
  };
  const [showNested, setShowNested] = useState<Record<string, boolean>>(() => getNestedItems());
  const dispatch = useDispatch();
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const enums = useSelector((state: DatabaseStudioState) => state.enums);
  const selectedFrame = useSelector((state: DatabaseStudioState) => state.studio.selectedFrame);
  const [showAdvancedEditor, setShowAdvancedEditor] = useState<string>();

  const updateShowNestedLocalStorage = useCallback(
    (next: Record<string, boolean>): void => {
      if (!app_id || !module_id) {
        return;
      }
      const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      copying[module_id] = {
        ...copying[module_id],
        dbmodeler: {
          ...copying[module_id].dbmodeler,
          tableFolders: {
            collapsedFolders: next
          }
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, copying);
    },
    [app_id, module_id]
  );

  const toggleCollapseFolder = useCallback(
    (folderId: string, collapsed: boolean): void => {
      const next: Record<string, boolean> = {
        ...showNested,
        [folderId]: collapsed
      };
      setShowNested(next);
      updateShowNestedLocalStorage(next);
    },
    [showNested, updateShowNestedLocalStorage]
  );

  const reloadFolderItems = useCallback((): void => {
    const items: FolderItem[] = [];
    for (const enumInfo of Object.values(enums)) {
      items.push({ ...enumInfo, name: enumInfo.content.data.name ?? '' });
    }
    for (const tableInfo of Object.values(tables)) {
      items.push({ ...tableInfo, name: tableInfo.content.data.name ?? '' });
    }
    setFolderItems(items);
  }, [enums, tables]);

  useEffect(() => {
    reloadFolderItems();
  }, [reloadFolderItems]);

  const openAllFolders = useCallback((): void => {
    const next: Record<string, boolean> = {};
    for (const folder of folders ?? []) {
      next[folder.uuid] = true;
    }
    setShowNested(next);
    updateShowNestedLocalStorage(next);
  }, [folders, updateShowNestedLocalStorage]);

  const closeAllFolders = useCallback((): void => {
    setShowNested({});
    updateShowNestedLocalStorage({});
  }, [updateShowNestedLocalStorage]);

  useEffect(() => {
    if (!app_id || !module_id) {
      return;
    }
    const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (copying[module_id] && copying[module_id].dbmodeler?.lastSelectedFrame)
      dispatch(setSelectedFrame(copying[module_id].dbmodeler.lastSelectedFrame));
  }, [app_id, dispatch, module_id]);

  const handleSelectFrame = useCallback(
    (frameId: string): void => {
      if (!app_id || !module_id) {
        return;
      }
      const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      copying[module_id] = {
        ...copying[module_id],
        dbmodeler: {
          ...copying[module_id].dbmodeler,
          lastSelectedFrame: frameId
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, copying);
      dispatch(setSelectedFrame(frameId));
    },
    [app_id, dispatch, module_id]
  );

  const handleDeleteFrame = useCallback(
    (frameId: string, frameType: string): void => {
      if (frameType === 'ENUM') {
        dispatch(deleteEnumFrame(frameId));
      } else if (frameType === 'TABLE') {
        dispatch(deleteTable(frameId));
      }
    },
    [dispatch]
  );

  const handleShowAdvancedEditor = useCallback(
    (id: string): void => {
      handleSelectFrame(id);
      setShowAdvancedEditor(id);
    },
    [handleSelectFrame]
  );

  const folderNameIsValid = (nextParent: string, nextName: string) => {
    if (checkFolderNameAlreadyExists(nextParent, nextName, folders ?? [])) {
      return false;
    } else if (!validateFolderName(nextName)) {
      return false;
    }
    return true;
  };

  const getCallbacks = useCallback((): Record<string, Record<string, any>> | undefined => {
    if (!module_id || !folders) return undefined;
    const callbacks: Record<string, Record<string, any>> = {};
    // Folder items callbacks.
    for (const folderItem of folderItems) {
      callbacks[folderItem.uuid] = {};
      callbacks[folderItem.uuid].select = () => handleSelectFrame(folderItem.uuid);
      callbacks[folderItem.uuid].save = (newName: string) => {
        mod_saveitem(folderItem.uuid, folderItem.type as string, newName, dispatch);
      };
      callbacks[folderItem.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await mod_drop(e, 'folder', el, folderItem.folder_id ?? '', module_id, dispatch).then(
          (source) => {
            if (!source) return;
            updateFoldersOnMoveFolder(
              folders,
              source.sourceId,
              folderItem.folder_id ?? '',
              source.sourceType,
              updateFolders
            );
          }
        );
      callbacks[folderItem.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        mod_dragstart(
          e,
          folderItem.uuid,
          folderItem.folder_id ?? '',
          folderItem.type as string,
          'folderItem'
        );
      callbacks[folderItem.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        mod_dragleave(e, el);
      callbacks[folderItem.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        mod_dragover(e, el, 'folderItem');
      callbacks[folderItem.uuid].delete = () =>
        handleDeleteFrame(folderItem.uuid, folderItem.type as string);
      callbacks[folderItem.uuid].validate = (name: string): string => {
        const v = validateFrameName(name);
        if (!v.valid) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        }
        return '';
      };
    }
    // Folders callbacks.
    let checkFolders: Folder[] = [...folders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) continue;
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = {};
      callbacks[folder.uuid].save = (newName: string): void => {
        savefolder(module_id, folder.uuid, newName);
        updateFoldersOnRename(folders, folder.uuid, newName, updateFolders);
      };
      callbacks[folder.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await mod_drop(e, 'folder', el, folder.uuid, module_id, dispatch).then((source) => {
          if (!source) return;
          updateFoldersOnMoveFolder(
            folders,
            source.sourceId,
            folder.uuid,
            source.sourceType,
            updateFolders
          );
        });
      callbacks[folder.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        mod_dragstart(e, folder.uuid, folder.parent ?? '', '', 'folder');
      callbacks[folder.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        mod_dragleave(e, el);
      callbacks[folder.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        mod_dragover(e, el, 'folder');
      callbacks[folder.uuid].delete = async () =>
        await deletefolder(module_id, folder.uuid).then(() =>
          updateFoldersOnDelete(folders, folder.uuid, updateFolders)
        );
    }
    return callbacks;
  }, [
    dispatch,
    folderItems,
    folders,
    handleDeleteFrame,
    handleSelectFrame,
    module_id,
    updateFolders
  ]);

  const getMenuCallbacks = useCallback((): Record<string, ContextMenuItem[]> | undefined => {
    const callbacks: Record<string, ContextMenuItem[]> = {};
    if (!module_id || !folders) return undefined;
    // Folder callbacks.
    let checkFolders: Folder[] = [...folders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) {
        continue;
      }
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = [];
    }
    // Folder items callbacks.
    for (const itemInfo of folderItems) {
      callbacks[itemInfo.uuid] = [];
      if (itemInfo.type === 'TABLE') {
        callbacks[itemInfo.uuid].push({
          label: 'Edit',
          icon: 'pencil',
          onClick: () => handleShowAdvancedEditor(itemInfo.uuid)
        });
      }
    }
    return callbacks;
  }, [folderItems, folders, handleShowAdvancedEditor, module_id]);

  const getRootCallbacks = useCallback((): Record<string, Record<string, any>> => {
    const callbacks: Record<string, Record<string, any>> = {};
    if (!module_id || !folders) return callbacks;
    callbacks['root'] = {};
    callbacks['root'].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      await mod_drop(e, 'root', el, '', module_id, dispatch).then((source) => {
        if (!source) return;
        updateFoldersOnMoveFolder(folders, source.sourceId, '', source.sourceType, updateFolders);
      });
    callbacks['root'].dragstart = (e: DragEvent<HTMLDivElement>) =>
      mod_dragstart(e, '', '', '', 'root');
    callbacks['root'].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      mod_dragleave(e, el);
    callbacks['root'].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      mod_dragover(e, el, 'root');
    return callbacks;
  }, [dispatch, folders, module_id, updateFolders]);

  const rootCallbacks = getRootCallbacks()['root'];

  return (
    <>
      <div
        className={styles.paneToolbarContent}
        id={WALKTHROUGH_STEPS_ELEMENTS['modeler-folder-area']}
      >
        <div
          id="modelsLayersContainer"
          className={`font-weight-bold ms-3 pb-2 border-bottom border-3 border-body-emphasis me-2 mb-1 d-flex justify-content-between align-items-center`}
          style={{ fontWeight: 600 }}
        >
          <label>{'Models'}</label>
          <ListGroup horizontal>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.FolderCollapse')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0 me-2"
                onClick={() => closeAllFolders()}
              >
                <Icon iconName="down-left-and-up-right-to-center" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.FolderExpand')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0 me-2"
                onClick={() => openAllFolders()}
              >
                <Icon iconName="up-right-and-down-left-from-center" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.NewRootFolder')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0"
                onClick={() => setShowFolderDialog('')}
              >
                <Icon iconName="folder-plus" />
              </ListGroup.Item>
            </HelpPopover>
          </ListGroup>
        </div>
        {rootCallbacks && (
          <DraggableResource
            handleDrop={rootCallbacks.drop}
            handleDragStart={rootCallbacks.dragstart}
            handleDragOver={rootCallbacks.dragover}
            handleLeave={rootCallbacks.dragleave}
          >
            <div id="modelsLayers" className={`p-3 pt-0`} style={{ height: '100%' }}>
              <RenderFolders
                folders={folders ?? []}
                collapsedFolders={showNested}
                toggleCollapsedFolder={toggleCollapseFolder}
                items={folderItems}
                selectedItem={selectedFrame ?? ''}
                callbacks={getCallbacks()}
                itemFilter={''}
                contextMenuItems={getMenuCallbacks()}
                setFolders={updateFolders}
                checkFolderNameExists={(parentId: string, name: string, folderId: string) =>
                  checkFolderNameAlreadyExists(parentId, name, folders ?? [], folderId)
                }
              />
            </div>
          </DraggableResource>
        )}
      </div>
      {showFolderDialog != null && (
        <CreateFolderDialog
          show={showFolderDialog != null}
          onClose={() => setShowFolderDialog(undefined)}
          parentUuid={showFolderDialog}
          onCreate={(f: Folder) => updateFoldersOnCreate(folders ?? [], f, updateFolders)}
          type={FolderType.DB}
          validateFolderName={(parentId: string, name: string) => folderNameIsValid(parentId, name)}
        />
      )}
      {showAdvancedEditor && (
        <AdvancedEditor
          tableID={showAdvancedEditor}
          showModal={!!showAdvancedEditor}
          onCloseRequest={() => setShowAdvancedEditor(undefined)}
        />
      )}
    </>
  );
}
