import { Action } from 'modules/logic_builder/types';
import * as React from 'react';
import { CustomCodeActionType } from '../../types';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { Button, Form, InputGroup, Accordion } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { sleep } from 'utils/utils';
import SessionContext from 'modules/auth/store';

type Props = {
  newAction: Action<CustomCodeActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<CustomCodeActionType>>>;
};

export default function RenderCustomCode(props: Props) {
  const [value, setValue] = React.useState<string>('');
  const session = React.useContext(SessionContext);

  function handleEditorChange(value: string | undefined, event: editor.IModelContentChangedEvent) {
    props.onChange((currentVal) => {
      const auxVal = { ...currentVal };
      if (value) {
        auxVal.data.code = value;
      }
      // override the new Action
      return auxVal;
    });
  }

  function handleOnChangeImports() {
    if (!value || value == '') {
      return;
    }

    props.onChange((currentVAL) => {
      const aux = { ...currentVAL };
      //pushing of the value inserting into input
      aux.data.imports.push(value);
      // overriding
      return aux;
    });
    setValue('');
  }

  function deletingImports(id: number) {
    const gettingAllArray = [...props.newAction.data.imports];
    gettingAllArray.splice(id, 1);
    props.onChange((currentVal) => {
      const aux = { ...currentVal };
      aux.data.imports = gettingAllArray;
      return aux;
    });
    sleep(300);
  }

  return (
    <div>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {props.newAction.data.imports.length > 0
              ? 'import ' + props.newAction.data.imports[0]
              : 'Imports'}
          </Accordion.Header>
          <Accordion.Body>
            <div
              style={{ display: 'flex', marginTop: '1rem', width: '100%', marginBottom: '0.7rem' }}
            >
              <ul style={{ maxHeight: '5rem', overflowY: 'auto', flexGrow: '1' }}>
                {props.newAction.data.imports.map((importItem, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <li> import {importItem}</li>
                      <div
                        style={{ cursor: 'pointer', marginRight: '0.8rem' }}
                        onClick={() => {
                          deletingImports(index);
                        }}
                      >
                        <Icon iconName="trash" />
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Form>
          <InputGroup>
            <Form.Control
              value={value}
              onChange={(e) => setValue(e.target.value)}
              size="sm"
              placeholder="Insert the imports here"
              onKeyDown={(ev) => {
                // avoiding reload the page
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  ev.stopPropagation();
                  // including into array when clicked enter
                  handleOnChangeImports();
                }
              }}
            />
            <Button onClick={handleOnChangeImports}>Add</Button>
          </InputGroup>
        </Form>
      </div>
      <Editor
        height={'40vh'}
        defaultLanguage="java"
        defaultValue="// type your code here"
        onChange={handleEditorChange}
        value={props.newAction.data.code}
        theme={session.preferences['exocode-theme'] === true ? 'vs-dark' : 'light'}
      />
    </div>
  );
}
