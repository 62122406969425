import { FormData, FormStyles } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const FORM_TEMPLATE: LayoutComponent<FormData, FormStyles> = {
  uuid: 'empty',
  type: 'FORM',
  data: {
    content: {
      variable: '',
      inputs: [],
      nestedObjectsPath: {}
    },
    submitText: 'Submit'
  },
  styles: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    css: ''
  }
};
