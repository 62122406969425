import React, { ChangeEvent, useEffect } from 'react';
import { BUTTONTICK_MANIFEST, FormatterProps, FormatterTypes } from '../../types';
import { IconList, IconPicker } from 'packages/react-fa-icon-picker';

function ButtonTickFormatter(props: FormatterProps, field: string) {
  useEffect(() => {
    if (!props.value || props.value.type !== FormatterTypes.BUTTONTICK) {
      props.onChange && props.onChange(props.index, 'formatterParams', BUTTONTICK_MANIFEST);
    }
  }, []);

  function handleChange(e: string, field: string) {
    const updatedDatetimeParams = { ...props.value };
    updatedDatetimeParams[field] = e;
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedDatetimeParams);
  }

  return (
    <div className="mt-2">
      <div className="align-items-center">
        <label className="mb-1">Icon</label>
        <div
          className="border d-flex p-1 rounded align-items-center"
          style={{ width: 'fit-content' }}
        >
          <IconPicker
            value={(props.value && props.value.icon) || 'FaRegCaretSquareDown'}
            onChange={(v) => handleChange(v, 'icon')}
            containerStyles={{ left: '-210px', display: 'flex', top: '-260px' }}
            buttonIconStyles={{
              color: 'var(--bs-secondary-color)!important',
              border: '0',
              fontSize: '1.25rem',
              display: 'flex'
            }}
            buttonStyles={{
              border: 0,
              padding: '0',
              width: '1.5rem',
              height: '1.5rem',
              minHeight: '0',
              fontSize: '1rem'
            }}
            searchInputStyles={{
              display: 'flex'
            }}
          />
          <span className="ms-2">{props.value && props.value.icon}</span>
        </div>
      </div>
    </div>
  );
}

export default ButtonTickFormatter;
