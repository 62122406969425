import React from 'react';
import styles from './styles.module.css';
import useRenderInput from './hooks/useRenderInput';
import { FunctionActionInputType } from '../../../../store/types/manifestsAndInputs';

type ActionInlineInputsProps = {
  uuid: string;
  inputs: FunctionActionInputType[];
};

function ActionInlineInputs({ uuid, inputs }: ActionInlineInputsProps) {
  const { renderInput } = useRenderInput();

  return (
    <div className={styles.ActionInlineInputsWrapper}>
      {inputs.map((input) => renderInput(input, uuid))}
    </div>
  );
}

export default ActionInlineInputs;
