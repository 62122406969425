import { Argument } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { v4 as uuidv4 } from 'uuid';

export interface DataTypeInterface {
  type: string;
  objectUuid?: string;
  enumUuid?: string;
}

export interface CreateEntityDataInterface {
  objectId: string; // ID de um objeto que tenha entityId, ou seja, tenha uma entidade no modeler
  entity: Argument; // VAR/PAR do tipo OBJECT com objectUuid === objectId
  objectChildren: boolean; // Se deve considerar os objetos filhos ou não
}

export const CreateEntityData: CreateEntityDataInterface = {
  objectId: '',
  entity: { type: TYPE_PICKER_TYPES.VAR, value: '' },
  objectChildren: false
};

export interface ReadEntityDataInterface {
  objectId: string; // ID de um objeto que tenha entityId, ou seja, tenha uma entidade no modeler
  sortable: false;
  filterable: false;
  id: Argument; // VAR/PAR do tipo OBJECT que seja chave da entidade (chave simples ou composta (se for composta, é um objecto criado pelo automation))
  objectChildren: false;
}

export const ReadEntityData: ReadEntityDataInterface = {
  objectId: '',
  sortable: false,
  filterable: false,
  id: { type: TYPE_PICKER_TYPES.PARAM, value: '' },
  objectChildren: false
};

export interface UpdateEntityDataInterface {
  objectId: string; // ID de um objeto que tenha entityId, ou seja, tenha uma entidade no modeler
  entity: Argument; // VAR/PAR do tipo OBJECT com objectUuid === objectId
  objectChildren: boolean; // Se deve considerar os objetos filhos ou não
}

export const UpdateEntityData: UpdateEntityDataInterface = {
  objectId: '',
  entity: { type: TYPE_PICKER_TYPES.VAR, value: '' },
  objectChildren: false
};

export interface DeleteEntityDataInterface {
  objectId: string; // ID de um objeto que tenha entityId, ou seja, tenha uma entidade no modeler
  id: Argument; // VAR/PAR do tipo OBJECT que seja chave da entidade (chave simples ou composta (se for composta, é um objecto criado pelo automation))
  entity: Argument; // VAR/PAR do tipo OBJECT com objectUuid === objectId
}

export const DeleteEntityData: DeleteEntityDataInterface = {
  objectId: '',
  id: { type: TYPE_PICKER_TYPES.PARAM, value: '' },
  entity: { type: TYPE_PICKER_TYPES.VAR, value: '' }
};

export interface AddListDataInterface {
  list: Argument;
  object: Argument;
}

export const AddListData: AddListDataInterface = {
  list: { type: TYPE_PICKER_TYPES.VAR, value: '' },
  object: { type: TYPE_PICKER_TYPES.FIXED, value: 'myValue' }
};

export interface RemoveListDataInterface {
  list: Argument;
  object: Argument;
}

export const RemoveListData: RemoveListDataInterface = {
  list: { type: TYPE_PICKER_TYPES.VAR, value: '' },
  object: { type: TYPE_PICKER_TYPES.FIXED, value: 'myValue' }
};

export interface ClearListDataInterface {
  list: Argument;
}

export const ClearListData: ClearListDataInterface = {
  list: { type: TYPE_PICKER_TYPES.VAR, value: '' }
};

export interface DeclareVariableDataInterface {
  uuid: string;
  name: string;
  description: string;
  list: boolean;
  dataType: DataTypeInterface;
  initialize: boolean;
}

export const DeclareVariableData: DeclareVariableDataInterface = {
  uuid: uuidv4(),
  name: 'variable_',
  description: '',
  dataType: { type: 'STRING' },
  list: false,
  initialize: true
};

export interface SetVariableDataInterface {
  variable: Argument;
  value: Argument;
}

export const SetVariableData: SetVariableDataInterface = {
  variable: { type: TYPE_PICKER_TYPES.VAR, value: '' },
  value: { type: TYPE_PICKER_TYPES.FIXED, value: 'myValue' }
};

export interface BeginCycleDataInterface {
  list: Argument;
  uuid: string;
  name: string;
  dataType: DataTypeInterface;
  closeBlockActionUuid: string;
}

export const BeginCycleData: BeginCycleDataInterface = {
  uuid: uuidv4(),
  name: 'variable_',
  dataType: { type: 'STRING' },
  list: { type: TYPE_PICKER_TYPES.FIXED, value: 'myValue' },
  closeBlockActionUuid: ''
};

export interface BeginIfDataInterface {
  if: string;
  then: {
    openBlockActionUuid: string;
    closeBlockActionUuid: string;
  };
  else: {
    openBlockActionUuid: string;
    closeBlockActionUuid: string;
  };
}

export const BeginIfData: BeginIfDataInterface = {
  if: '',
  then: {
    openBlockActionUuid: '',
    closeBlockActionUuid: ''
  },
  else: {
    openBlockActionUuid: '',
    closeBlockActionUuid: ''
  }
};

export interface EndIfDataInterface {
  openBlockActionUuid: string;
}

export const EndIfData: EndCycleDataInterface = {
  openBlockActionUuid: ''
};

export interface BeginElseDataInterface {
  closeBlockActionUuid: string;
}

export const BeginElseData: BeginElseDataInterface = {
  closeBlockActionUuid: ''
};

export interface EndElseDataInterface {
  openBlockActionUuid: string;
}

export const EndElseData: EndElseDataInterface = {
  openBlockActionUuid: ''
};

export interface EndCycleDataInterface {
  openBlockActionUuid: string;
}

export const EndCycleData: EndCycleDataInterface = {
  openBlockActionUuid: ''
};

export interface RunFunctionDataInterface {
  function: {
    functionId: string;
    arguments?: { [key: string]: Argument };
  };
}

export const RunFunctionData: RunFunctionDataInterface = {
  function: {
    functionId: '',
    arguments: {}
  }
};

export interface CustomCode {
  code: string;
  imports?: string[];
}

export interface CustomCodeDataInterface {
  code: CustomCode;
}

export const CustomCodeData: CustomCodeDataInterface = {
  code: {
    code: '',
    imports: []
  }
};

export interface CustomQuery {
  query: string;
  vars?: string[];
}

export interface CustomQueryDataInterface {
  query: CustomQuery;
}

export const CustomQueryData: CustomQueryDataInterface = {
  query: {
    query: '',
    vars: []
  }
};

export interface ReturnTypeDataInterface {
  uuid: string;
  returnType: 'VARIABLE';
}

export const ReturnTypeData: ReturnTypeDataInterface = {
  uuid: '',
  returnType: 'VARIABLE'
};

export type EmptyDataInterface = Record<string, never>;

export const EmptyData: EmptyDataInterface = {};

export interface CommentDataInterface {
  comment: string;
}

export const CommentData: CommentDataInterface = {
  comment: ''
};
