import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ActionInputProps, Argument } from '../../../store/types/manifestsAndInputs';
import Dropdown from '../../components/dropdown';
import useGetEndpoints from './hooks/useGetEndpoints';
import EndpointParameters from './endpoint_parameters';
import { PickedEndpoint, getExtendedEndpoint } from './hooks/useGetExtendedEndpoint';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { updateAction } from '../../../store/events/actions/update_action';
import { TYPE_PICKER_TYPES } from '../argument_picker/type_picker';
import RequestEditor from './request_editor';
import ResponseEditor from './response_editor';
import EmptyMessage from '../../../../empty';
import { useTranslation } from 'react-i18next';

function EndpointPicker({ actionUuid, dataKey, isFromSideBar }: ActionInputProps) {
  const { module_id } = useParams();
  const { endpoints, loading } = useGetEndpoints(module_id);
  const [selected, setSelected] = useState('');
  const [endpoint, setEndpoint] = useState({} as PickedEndpoint);
  const data = useSelector(
    (state: FunctionEditorState) => state.actions[actionUuid]?.data[dataKey]
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!data?.endpoint) return;
    setSelected(data.endpoint);
  }, [data]);

  useEffect(() => {
    loadPickedEndpoint().then();
  }, [selected]);

  async function loadPickedEndpoint() {
    if (!selected) return;
    const isSameEndpoint = data?.endpoint && selected === data.endpoint;
    if (isSameEndpoint) {
      return getExtendedEndpoint(selected).then((e) => {
        if (!e) return;
        setEndpoint(e);
      });
    } else {
      return getExtendedEndpoint(selected).then((e) => {
        if (!e) return;
        setEndpoint(e);
        setNewEndpointData(e);
      });
    }
  }

  function setNewEndpointData(endpoint: PickedEndpoint) {
    const queryParams = {} as { [key: string]: Argument };
    if (endpoint?.queryParams)
      for (const uuid of Object.keys(endpoint.queryParams)) {
        queryParams[uuid] = { type: TYPE_PICKER_TYPES.FIXED, value: '' };
      }
    const pathParams = {} as { [key: string]: Argument };
    if (endpoint?.pathParams)
      for (const uuid of Object.keys(endpoint.pathParams)) {
        pathParams[uuid] = { type: TYPE_PICKER_TYPES.FIXED, value: '' };
      }
    const newData = {
      endpoint: endpoint.uuid ?? '',
      queryParams,
      pathParams,
      request: { type: TYPE_PICKER_TYPES.VAR, value: '' },
      response: ''
    };
    newData.endpoint && dispatch(updateAction(actionUuid, dataKey, newData));
  }

  function handlePathParamChange(paramId: string, value: Argument) {
    if (!paramId) return;
    const pathParams = { ...data.pathParams, [paramId]: value };
    dispatch(updateAction(actionUuid, dataKey, { ...data, pathParams }));
  }

  function handleQueryParamChange(paramId: string, value: Argument) {
    if (!paramId) return;
    const queryParams = { ...data.queryParams, [paramId]: value };
    dispatch(updateAction(actionUuid, dataKey, { ...data, queryParams }));
  }

  function handleRequestChange(paramId: string, value: Argument) {
    if (!paramId) return;
    dispatch(updateAction(actionUuid, dataKey, { ...data, request: value }));
  }

  function handleResponseChange(response: string) {
    dispatch(updateAction(actionUuid, dataKey, { ...data, response }));
  }

  if (loading)
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Spinner />
      </div>
    );

  if (Object?.keys(endpoints).length <= 0)
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <EmptyMessage
          message={t('designer.right_side.NoEndpoint') ?? 'No endpoints'}
          icon="exclamation"
          actionMessage={''}
          linkAction={() => {}}
        />
      </div>
    );

  return (
    <div className={'d-flex flex-column gap-2 w-100'}>
      <div className={'d-flex align-items-end'}>
        <Dropdown
          items={Object?.values(endpoints) ?? []}
          placeholder={`${t('SelectAnEndpoint')}`}
          label={'Endpoint'}
          value={endpoints[data.endpoint]}
          handleChange={(uuid: string) => setSelected(uuid)}
          isFromSideBar={isFromSideBar}
        />
      </div>

      {(endpoint?.pathParams !== undefined ||
        endpoint?.queryParams !== undefined ||
        endpoint?.response !== undefined) && (
        <>
          <div
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              alignItems: 'start'
            }}
          >
            {/*  path params */}
            {endpoint && endpoint?.pathParams && Object?.keys(endpoint.pathParams)?.length > 0 && (
              <EndpointParameters
                params={endpoint.pathParams}
                paramsValues={data.pathParams}
                actionUuid={actionUuid}
                handleChange={handlePathParamChange}
                label={'Path'}
                isFromSideBar={isFromSideBar}
              />
            )}
            {/* query params */}
            {endpoint &&
              endpoint?.queryParams &&
              Object?.keys(endpoint.queryParams)?.length > 0 && (
                <EndpointParameters
                  params={endpoint.queryParams}
                  paramsValues={data.queryParams}
                  actionUuid={actionUuid}
                  handleChange={handleQueryParamChange}
                  label={'Query'}
                  isFromSideBar={isFromSideBar}
                />
              )}
          </div>
          {/*  request: body */}
          {endpoint && endpoint?.request && endpoint.request?.uuid && (
            <RequestEditor
              param={endpoint.request}
              paramValue={data.request}
              handleChange={handleRequestChange}
              actionUuid={actionUuid}
              isFromSideBar={isFromSideBar}
            />
          )}
        </>
      )}
      {endpoint && endpoint?.response && endpoint.response?.uuid && (
        <ResponseEditor
          response={endpoint.response}
          value={data.response}
          handleChange={handleResponseChange}
          actionUuid={actionUuid}
          isFromSideBar={isFromSideBar}
        />
      )}
    </div>
  );
}

export default EndpointPicker;
