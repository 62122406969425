import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import { ContextMenuItem as ContextMenuItemType } from '..';
import ContextMenu from 'web_ui/workboard/ContextMenu';
import { useTranslation } from 'react-i18next';
import ContextMenuItem from 'web_ui/workboard/ContextMenuItem';
import Confirmation from 'web_ui/confirmation';
import { validateFolderName } from 'utils/inputValidation';

interface FolderProps {
  folderId: string;
  folderName: string;
  isSelected: boolean;
  isCollapsed: boolean;
  toggleCollapsedFolder: (collapsed: boolean) => void;
  className?: string;
  handleSaveName: (newName: string) => void;
  contextMenus: ContextMenuItemType[];
  canBeDeleted: boolean;
  deleteFolder: () => Promise<void>;
  updateFoldersOnRename: (newName: string) => void;
  checkFolderNameExists: (name: string, folder: string) => boolean;
}

export function Folder({
  folderId,
  folderName,
  isSelected,
  isCollapsed,
  toggleCollapsedFolder,
  className,
  handleSaveName,
  contextMenus,
  canBeDeleted,
  deleteFolder,
  updateFoldersOnRename,
  checkFolderNameExists
}: FolderProps) {
  const { t } = useTranslation();
  const [newFolderName, setNewFolderName] = useState('');
  const [currentFolderName, setCurrentFolderName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [showDeleteDialogConfirmation, setShowDeleteDialogConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentFolderName(folderName);
  }, [folderName]);

  const handleEditName = (): void => {
    setIsEditingName(true);
    setNewFolderName(currentFolderName);
    setErrorMessage('');
  };

  const handleInputBlur = (newFolderName: string): void => {
    setIsEditingName(false);
    setErrorMessage('');
    if (!(newFolderName === currentFolderName || errorMessage)) {
      setCurrentFolderName(newFolderName);
      updateFoldersOnRename(newFolderName);
      handleSaveName(newFolderName);
    }
  };

  const renameContextMenu: ContextMenuItemType = {
    label: 'designer.views.Rename',
    onClick: () => handleEditName(),
    icon: 'pencil'
  };

  const handleDelete = async () => {
    await deleteFolder();
    setShowDeleteDialogConfirmation(false);
  };

  const handleChangeFolderName = useCallback(
    (name: string) => {
      setNewFolderName(name);
      if (!validateFolderName(name).valid) {
        setErrorMessage('inputValidationErrorMessages.GenericErrorMessage');
      } else if (checkFolderNameExists(name.trim(), folderId)) {
        setErrorMessage('inputValidationErrorMessages.GenericErrorMessage');
      } else {
        setErrorMessage('');
      }
    },
    [checkFolderNameExists, folderId]
  );

  const handleDeleteClick = (e: MouseEvent<HTMLElement>) => {
    if (!canBeDeleted) {
      e.stopPropagation();
    } else {
      setShowDeleteDialogConfirmation(true);
    }
  };

  return (
    <>
      <div className={`${styles.viewItemContainer} ps-2 ${className}`} ref={wrapperRef}>
        <div
          id={currentFolderName}
          className={`${styles.viewItem} w-100 d-flex align-items-center justify-content-between p-2`}
        >
          <div className={`d-flex align-items-center ${styles.viewTitleData}`}>
            <div
              id={`collapse${currentFolderName}`}
              className={`me-2 d-flex align-items-center`}
              style={{ minWidth: '12.5px' }}
              onClick={() => toggleCollapsedFolder(!isCollapsed)}
            >
              {isCollapsed ? <Icon iconName="angle-down" /> : <Icon iconName="angle-right" />}
            </div>
            <div className="border-end border-2 pe-2 text-body-tertiary" style={{ cursor: 'grab' }}>
              <Icon iconName="ellipsis-vertical" />
            </div>
            <div className="ms-2">
              <Icon iconName={'folder'} />
            </div>
            {isEditingName ? (
              <InputGroup>
                <FormControl
                  id="formName"
                  size="sm"
                  type="text"
                  className={`ms-2 w-50 ${errorMessage && styles.invalidTextInput}`}
                  value={newFolderName}
                  onChange={(e) => handleChangeFolderName(e.target.value)}
                  onBlur={(e) => handleInputBlur(e.target.value)}
                  autoFocus
                />
              </InputGroup>
            ) : (
              <div
                id={currentFolderName}
                onDoubleClick={handleEditName}
                className={`${isSelected && 'text-body-emphasis'} ms-2`}
              >
                {currentFolderName}
              </div>
            )}
          </div>
          <div className={`d-flex`}>
            <Dropdown>
              <Dropdown.Toggle
                className={styles.dropdownButton}
                as={'span'}
                variant="success"
                id={`dropdownItem${currentFolderName}`}
              >
                <Icon iconName="ellipsis-h" />
              </Dropdown.Toggle>
              <div style={{ position: 'absolute' }}>
                <Dropdown.Menu>
                  {[renameContextMenu, ...contextMenus].map((menu, index) => {
                    return (
                      <Dropdown.Item id="renameButton" key={index} onClick={() => menu.onClick()}>
                        <Icon iconName={`${menu.icon ?? ''} me-2`} />
                        {`${t(menu.label)}`}
                      </Dropdown.Item>
                    );
                  })}
                  <Dropdown.Item
                    id="deleteButton"
                    onClick={handleDeleteClick}
                    disabled={!canBeDeleted}
                  >
                    <Icon iconName={`trash me-2`} />
                    {t('Delete')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <ContextMenu elementRef={wrapperRef}>
        {[renameContextMenu, ...contextMenus].map((menu, index) => {
          return (
            <ContextMenuItem
              key={index}
              icon={menu.icon ?? ''}
              label={t(menu.label)}
              onClick={() => menu.onClick()}
            />
          );
        })}
        <ContextMenuItem
          icon={'trash'}
          label={t('Delete')}
          onClick={() => setShowDeleteDialogConfirmation(true)}
          disabled={!canBeDeleted}
        />
      </ContextMenu>
      {canBeDeleted && (
        <Confirmation
          show={showDeleteDialogConfirmation}
          message={`${t('designer.views.DeleteFolder')} (${folderName})`}
          onConfirmation={async () => await handleDelete()}
          onCancel={() => {
            setShowDeleteDialogConfirmation(false);
          }}
          onClose={() => {
            setShowDeleteDialogConfirmation(false);
          }}
        />
      )}
    </>
  );
}
