import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { EnumsState } from '../types/enums';
import { initialState } from '../initial_empty_state';

type EnumsActions = PayloadAction;

export const enumsReducer = (
  state: EnumsState = initialState.objects,
  action: EnumsActions
): EnumsState => {
  return produce(state, (draft: EnumsState) => {
    switch (action.type) {
      default:
        return state;
    }
  });
};
