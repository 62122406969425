import { Action } from 'redux';
import { FunctionParameterType, ParametersState } from '../../types/parameters';

export const CHANGE_PARAM_TYPE = 'CHANGE_PARAM_TYPE';

export interface ChangeParamTypeAction extends Action {
  type: 'CHANGE_PARAM_TYPE';
  payload: FunctionParameterType;
}

export const changeParamType = (param: FunctionParameterType): ChangeParamTypeAction => ({
  type: CHANGE_PARAM_TYPE,
  payload: param
});

export function doChangeParamType(
  state: ParametersState,
  action: ChangeParamTypeAction
): ParametersState {
  const param = action.payload;
  state[param.uuid] = param;
  return state;
}
