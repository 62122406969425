import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ControlProps } from '../..';
import Column from './column';
import { GridColumnData } from 'modules/designer/studio/exocode_components/grid';
import produce from 'immer';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { LayoutComponent } from 'modules/designer/types';
import {
  addComponent,
  deleteComponent,
  LinkInfo
} from 'modules/designer/studio/store/actions/root';
import { faker } from '@faker-js/faker';
import { PopupAlert } from 'web_ui/popup_alert';

interface GridColumnProps {
  settings: ControlProps;
}

// TODO: Use handleContainerFactory instead this function.
export function handleCreateContainer(viewUUID: string, parentUUID: string, section?: string) {
  const newContainer: LayoutComponent = {
    uuid: uuidv4(),
    type: 'CONTAINER',
    data: {
      name: faker.random.words(2).toLowerCase().replace(' ', '_'),
      width: 100,
      widthUnit: '%',
      maxWidth: 'none',
      minWidth: 'none',
      height: '100',
      heightUnit: '%',
      maxHeight: 'none',
      minHeight: '100',
      minHeightUnit: 'px',
      overflowx: 'auto',
      overflowy: 'auto',
      breakpoint: 'fluid',
      flexDirection: 'column'
    },
    styles: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      borderTop: 0,
      borderBottom: 0,
      borderLeft: 0,
      borderRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 12,
      paddingRight: 12
    }
  };

  const link: LinkInfo = { viewUUID, parentUUID, section };
  return { component: newContainer, link };
}

function GridColumn({ settings }: GridColumnProps) {
  const rowRef = useRef<HTMLDivElement>(null);
  const { view_id } = useParams();
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  const handleGetColumnWidth = useCallback(() => {
    return rowRef.current?.clientWidth ? rowRef.current?.clientWidth / 12 : 60;
  }, [rowRef]);

  const handleCalculateTotalSize = useCallback(
    (columns = settings.value.columns) => {
      return columns.reduce((total: number, column: GridColumnData) => total + column.width, 0);
    },
    [settings.value.columns]
  );

  const [totalWidth, setTotalWidth] = useState<number>(() => handleCalculateTotalSize());
  useEffect(() => {
    setTotalWidth(handleCalculateTotalSize());
  }, [settings.value.columns, handleCalculateTotalSize]);

  const handleCheckGrid = (newChildrenState: any) => {
    const columnCount = handleCalculateTotalSize();
    return columnCount <= 12 && columnCount >= newChildrenState.length;
  };

  const handleUpdateSizes = (columnTitle: string, width: number) => {
    const newChildrenState = produce(settings.value.columns, (draft: GridColumnData[]) => {
      const columnIndex = draft.findIndex((column) => column.title === columnTitle && columnTitle);
      draft[columnIndex].width = width;
    });

    if (!newChildrenState || !handleCheckGrid(newChildrenState)) return;

    settings.onChange && settings.onChange(newChildrenState, 'columns');
    setTotalWidth(handleCalculateTotalSize(newChildrenState));
  };

  const handleAddColumn = () => {
    if (!view_id || !selectedComponent) return;
    let columnTitle = '';

    const newChildrenState = produce(settings.value.columns, (draft: GridColumnData[]) => {
      if (totalWidth + 1 > 12) return;

      const lastColumn = draft[draft.length - 1]?.title;
      columnTitle = !lastColumn
        ? 'COLUMN_0'
        : 'COLUMN_' +
          (parseInt(lastColumn.toUpperCase().split('COLUMN_')[1]) + 1) +
          uuidv4().toString().substring(0, 5);
      draft.push({ title: columnTitle, width: 1 });
    });

    settings.onChange && settings.onChange(newChildrenState, 'columns');
    const { component, link } = handleCreateContainer(view_id, selectedComponent, columnTitle);
    dispatch(addComponent(component, link));
    setTotalWidth(handleCalculateTotalSize(newChildrenState));
  };

  const handleRemoveColumn = (columnTitle: string) => {
    if (!view_id || !selectedComponent || !links) return;
    if (
      links[selectedComponent + '_' + columnTitle] &&
      links[selectedComponent + '_' + columnTitle].length > 0
    ) {
      links[selectedComponent + '_' + columnTitle].map((childColumnUUID) => {
        dispatch(deleteComponent(childColumnUUID));
      });
    }

    const newChildrenState = produce(settings.value.columns, (draft: GridColumnData[]) => {
      return draft.filter((column) => column.title !== columnTitle);
    });

    settings.onChange && settings.onChange(newChildrenState, 'columns');
  };

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 2,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '1rem'
      }}
    >
      <div className="row gx-2 m-0 radius w-100" ref={rowRef}>
        {settings.value.columns &&
          settings?.value?.columns.map((column: GridColumnData) => (
            <Column
              key={column.title}
              title={column.title}
              size={column.width}
              onResize={handleUpdateSizes}
              onDelete={handleRemoveColumn}
              columnWidth={handleGetColumnWidth()}
              totalWidth={totalWidth}
            />
          ))}

        {12 - handleCalculateTotalSize() > 0 && (
          <div
            className={`col-1 ${styles.newColumn} d-flex justify-content-center align-items-center rounded`}
            onClick={handleAddColumn}
          >
            <span className="text-body">
              <Icon iconName="plus" />
            </span>
          </div>
        )}
      </div>
      {alertMessage && (
        <PopupAlert
          i18nKey={alertMessage}
          onClose={() => {
            setAlertMessage(null);
          }}
        />
      )}
    </div>
  );
}

export default GridColumn;
