import React from 'react';
import { ProgressBar } from 'react-bootstrap';

interface ProgressBarFormatterPreviewProps {
  formatterParams: { [key: string]: any };
  value: string;
}

function ProgressBarFormatterPreview({ formatterParams, value }: ProgressBarFormatterPreviewProps) {
  return (
    <ProgressBar
      min={formatterParams.min ? formatterParams.min : 0}
      max={formatterParams.max ? formatterParams.max : 100}
      now={50}
      label={formatterParams.label ? `50%` : null}
      variant={formatterParams.variant}
      striped={formatterParams.striped}
      animated={formatterParams.animated}
    />
  );
}

export default ProgressBarFormatterPreview;
