import React from 'react';
import Dropdown from 'web_ui/function_editor/action_inputs/components/dropdown';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';

type PageParameterPickerProps = {
  label: string;
  value: string;
  handleChange: (text: string) => void;
  isFromSideBar?: boolean;
  isFromArgument?: boolean;
};

export function PageParameterPicker({
  label,
  value,
  handleChange,
  isFromArgument,
  isFromSideBar
}: PageParameterPickerProps) {
  const state = useSelector((state: FunctionEditorState) => state);

  const pageParameters = state.page_params;

  return (
    <Dropdown
      items={Object.values(pageParameters)}
      placeholder={'Select page parameter'}
      label={label}
      value={pageParameters[value]}
      handleChange={handleChange}
      isFromArgument={isFromArgument}
      isFromSideBar={isFromSideBar}
    />
  );
}
