import React, { useState } from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { CrudData } from './index';
import { Card, Form, InputGroup } from 'react-bootstrap';
import Icon from '../../../../../web_ui/icon';
import { Entity } from '../../../types';
import { Link, useParams } from 'react-router-dom';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import './dropdown.css';

type Step1Props = {
  crudData: CrudData;
  onChange: (crudData: CrudData) => void;
};

export function Step1Entity(props: Step1Props) {
  const { app_id, module_id } = useParams();
  const { t } = useTranslation();
  const [searchFilter, setSearchFilter] = useState<string>('');

  function handleFilterOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    event.stopPropagation();
    setSearchFilter(event.target.value);
  }

  function applyFilter(entity: Entity): boolean {
    return (
      !searchFilter || entity.entityName.toLowerCase().includes(searchFilter.toLowerCase().trim())
    );
  }

  function handleEntityOnClick(entityId: string): void {
    props.onChange({ ...props.crudData, entityUuid: entityId });
  }

  function handleKeyDown(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }

  return (
    <div className={styles.StepWrapper}>
      <div id="headerMessage" className={styles.Title}>
        {t('automation.Step1Title')}
        <HelpPopover
          helpBoxProps={{
            description: t('automation.Step1Tooltip') ?? ''
          }}
          placement="top"
        >
          <span>
            <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
          </span>
        </HelpPopover>
      </div>
      {/* Search */}
      <InputGroup className={styles.Search}>
        <InputGroup.Text>
          <Icon iconName="magnifying-glass" />
        </InputGroup.Text>
        <Form.Control
          id="searchField"
          type="search"
          placeholder="Search..."
          aria-label="Search"
          value={searchFilter}
          onChange={handleFilterOnChange}
          onKeyDown={(ev) => handleKeyDown(ev)}
        />
      </InputGroup>
      {/* Cards */}
      <div id="list-group" className={styles.CardsWrapper}>
        {Object.values(props.crudData.entities).length > 0 ? (
          Object.values(props.crudData.entities)
            .filter((entity) => applyFilter(entity))
            .map((entity) => {
              return (
                <Card
                  key={entity.entityUuid}
                  className={`${styles.Card}`}
                  onClick={() => handleEntityOnClick(entity.entityUuid)}
                >
                  <Card.Body id={`${entity.entityName.toLowerCase()}Card`} style={{ padding: 0 }}>
                    <Card.Title
                      id={`${entity.entityName.toLowerCase()}Title`}
                      className={`${styles.CardTitle}`}
                    >
                      <Icon iconName="database" />{' '}
                      <p className={styles.nameEntity}>{entity.entityName}</p>
                    </Card.Title>
                    {entity.entityDescription ? (
                      <Card.Text className={styles.CardText}>{entity.entityDescription}</Card.Text>
                    ) : (
                      <Card.Text
                        id={`${entity.entityName.toLowerCase()}Description`}
                        className={styles.CardText}
                      >{`${entity.entityName} ${t('automation.step1.Entity')}`}</Card.Text>
                    )}
                  </Card.Body>
                </Card>
              );
            })
        ) : (
          <div
            id="bodyMessage"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <span>{t('automation.Step1NoEntities')}</span>
            <Link
              id="ModelerLink"
              to={`/app/${app_id}/module/${module_id}/schema/829fdea7-75fd-4202-9d0c-d237567ef52f`}
            >
              <span>{t('automation.Step1AddEntity')}</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
