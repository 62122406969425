import React from 'react';
import { Teams } from 'modules/auth/types/auth_types';
import styles from './styles.module.css';
import { Col } from 'react-bootstrap';

type Props = {
  team: Teams;
  addingTeamToRemove?: (Team: number) => void;
  isFromAddTeam: boolean;
  addingTeamToAdd?: (Team: number) => void;
  owner_id: number | undefined;
  teamsToAdd?: number[];
  teamsToRemove?: number[];
};

export const Team = ({
  team,
  addingTeamToRemove,
  isFromAddTeam,
  addingTeamToAdd,
  owner_id,
  teamsToAdd,
  teamsToRemove
}: Props) => {
  const styleSelected = (): React.CSSProperties => {
    if (teamsToAdd) {
      if (teamsToAdd.filter((item) => item === team.id).length > 0) {
        return {
          backgroundColor: 'rgba(128, 128, 128, 0.4)'
        };
      }
    } else if (teamsToRemove) {
      if (teamsToRemove.filter((item) => item === team.id).length > 0) {
        return {
          backgroundColor: 'gray',
          opacity: '80%'
        };
      }
    } else {
      return {};
    }
    return {};
  };

  return (
    <div className={styles.container} style={styleSelected()}>
      <div className={styles.containerIconName}>
        <Col xs={'auto'} className={`d-flex align-items-center`}>
          <div className={`${styles.avatar} me-2}`}>{team.name.substring(0, 2).toUpperCase()}</div>
        </Col>
        <div>
          <span className={styles.name}>{team.name[0].toUpperCase() + team.name.substring(1)}</span>
        </div>
      </div>
      <div>
        {isFromAddTeam ? (
          <div
            className={styles.containerIcon}
            onClick={() => {
              if (teamsToAdd && teamsToAdd.filter((item) => item === team.id).length > 0) {
                return;
              }
              if (addingTeamToAdd) addingTeamToAdd(team.id);
            }}
          >
            <i className="fa-regular fa-plus" style={{ color: '#824646' }}></i>
          </div>
        ) : (
          <div
            className={styles.containerIcon}
            onClick={() => {
              if (teamsToRemove && teamsToRemove.filter((item) => item === team.id).length > 0) {
                return;
              }
              if (addingTeamToRemove) addingTeamToRemove(team.id);
            }}
          >
            <i className="fa-regular fa-trash-can" style={{ color: '#824646' }}></i>
          </div>
        )}
      </div>
    </div>
  );
};
