import React from 'react';
import { useTranslation } from 'react-i18next';

NoComponentsWarning.propTypes = {};

function NoComponentsWarning() {
  const { t } = useTranslation();
  return (
    <div
      id={'content'}
      className={
        'bg-secondary text-secondary-emphasis text-center p-2 w-100 flex-grow-1 d-flex flex-column justify-content-center'
      }
      style={{ height: '140px', maxWidth: '300px' }}
    >
      <h5>{t('designer.right_side.NoComponents')}</h5>
      <p style={{ textOverflow: 'wrap' }}>{t('designer.right_side.SelectComponents')}</p>
    </div>
  );
}

export default NoComponentsWarning;
