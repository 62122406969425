import React, { useCallback, useEffect, useState } from 'react';
import { CrudData } from './index';
import styles from './styles.module.css';
import { Badge, Button, Form } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FolderService, PageService } from 'modules/designer/services';
import { CrudPageInstance } from './Instances/crudPageInstance';
import CreateFolderDialog from 'modules/designer/studio/toolbars/views_toolbar/create_folder_modal';
import { Folder, FolderType } from 'modules/designer/types';
import AddPagesDialog from './add_pages_dialog';
import { useViews } from 'modules/designer/hooks/outletContext';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { checkFolderNameAlreadyExists } from 'web_ui/folders/callbacks';
import { validateFolderName } from 'utils/inputValidation';
import SessionContext from 'modules/auth/store';

type Step5Props = {
  crudData: CrudData;
  onChange: (crudData: CrudData) => void;
  setFormValidity: (validity: boolean) => void;
  loading?: (val: boolean) => void;
};

export function Step5Views(props: Step5Props) {
  const { t } = useTranslation();
  const { module_id } = useParams();
  const { layouts } = useViews();
  const [showPages, setShowPages] = useState<boolean>(false);
  const [showPagesCreatorDialog, setShowPagesCreatorDialog] = useState<boolean>(false);
  const [showFolderDialog, setShowFolderDialog] = useState<boolean>(false);
  const [newViewParent, setNewViewParent] = useState('');
  const session = React.useContext(SessionContext);

  async function getFoldersList() {
    if (!module_id || !props.crudData || !props.crudData.entityUuid) return;

    props.loading && props.loading(true);
    let foldersList = await FolderService.getFoldersByModule(module_id);
    foldersList = foldersList.sort((a, b) => {
      if (a.name === 'Pages') return -1;
      if (b.name === 'Pages') return 1;
      return a.name.localeCompare(b.name);
    });

    const crud = {
      ...props.crudData,
      folders: foldersList
    } as CrudData;

    if (foldersList[0] && foldersList[0].uuid) {
      crud.selectedFolder = foldersList[0];
    }

    props.onChange(crud);
    props.loading && props.loading(false);
  }

  async function getPagesList() {
    if (!module_id || !props.crudData.entityUuid) return;

    props.loading && props.loading(true);
    const pageList = await PageService.getPagesByModule(module_id);
    const crud = {
      ...props.crudData,
      pages: pageList
    } as CrudData;

    props.onChange(crud);
    props.loading && props.loading(false);
  }

  const fetchFolders = useCallback(
    async (newFolder: string) => {
      if (!module_id) return;
      props.loading && props.loading(true);
      await FolderService.getFoldersByModule(module_id).then((fetchedFolders: Folder[]) => {
        const crud = {
          ...props.crudData,
          folders: fetchedFolders
        } as CrudData;

        crud.selectedFolder = crud.folders.find((t) => t.uuid === newFolder);
        props.onChange(crud);
        props.loading && props.loading(false);
      });
    },
    [module_id]
  );

  const folderNameIsValid = (nextParent: string, nextName: string) => {
    if (checkFolderNameAlreadyExists(nextParent, nextName, props.crudData.folders ?? [])) {
      return false;
    } else if (!validateFolderName(nextName)) {
      return false;
    }
    return true;
  };

  function handleFolderChange(folderId: string) {
    const newCrud = {
      ...props.crudData,
      selectedFolder: props.crudData.folders.find((t) => t.uuid === folderId)
    };
    newCrud.crudPages.forEach((page) => {
      page.folderUuid = folderId;
    });
    props.onChange(newCrud);
  }

  useEffect(() => {
    const crud = {
      ...props.crudData,
      endpoints: []
    } as CrudData;

    props.onChange(crud);
  }, []);

  useEffect(() => {
    if (!props.crudData.selectedFolder) {
      getFoldersList();
      props.setFormValidity(false);
    } else {
      getPagesList();
      props.setFormValidity(true);
    }
  }, [props.crudData.selectedFolder]);

  function removePage(index: number) {
    const crud = {
      ...props.crudData
    } as CrudData;
    props.crudData.crudPages.splice(index, 1);
    props.onChange(crud);
  }

  return (
    <div className={styles.StepWrapper}>
      <div id="headerMessage" className={styles.Title}>
        {t('automation.Step5Title')}
        <HelpPopover
          helpBoxProps={{
            title: t('automation.Step5Tooltip') ?? ''
          }}
          placement="top"
        >
          <span>
            <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
          </span>
        </HelpPopover>
      </div>
      {/* Folders */}
      <div className={styles.DataObjectsHeader}>
        <div className={styles.SelectionWrapper}>
          <div style={{ width: 'fit-content', marginRight: '3rem' }}>
            {t('automation.step5.folder')}:
          </div>
          <div style={{ marginRight: '0.5rem', width: '20rem' }}>
            <Form.Select
              id="selectFolder"
              size="sm"
              value={props.crudData.selectedFolder?.uuid}
              onChange={(e) => {
                handleFolderChange(e.target.value);
              }}
              style={{
                height: '2.3rem',
                fontSize: '16px',
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '8px',
                width: '20rem'
              }}
            >
              <option selected disabled hidden>
                Folder:
              </option>
              {props.crudData.folders.length > 0 &&
                Object.values(props.crudData.folders).map((tag) => {
                  return (
                    <option key={tag.name} value={tag.uuid}>
                      {tag.name}
                    </option>
                  );
                })}
              ;
            </Form.Select>
          </div>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('createFolder') ?? ''
            }}
          >
            <Button
              id="createFolderButton"
              variant="primary"
              onClick={() => setShowFolderDialog(true)}
              style={{
                marginRight: '1rem',
                paddingTop: 6,
                paddingBottom: 6,
                paddingRight: 14,
                paddingLeft: 14
              }}
            >
              <Icon iconName="plus" />
            </Button>
          </HelpPopover>
          <div>{`(${
            props.crudData.pages.filter(
              (item) => item.folder_id === props.crudData.selectedFolder?.uuid
            ).length
          } ${t('automation.Step4Existing')})`}</div>
        </div>
        <div>
          {props.crudData.pages.filter((e) => e.folder_id === props.crudData.selectedFolder?.uuid)
            .length > 0 && (
            <Button id="showButton" variant="primary" onClick={() => setShowPages(!showPages)}>
              {showPages ? (
                <span style={{ display: 'flex' }}>
                  <p
                    className={styles.btnComboBox}
                    style={
                      session.preferences['exocode-theme']
                        ? {}
                        : {
                            color: 'white'
                          }
                    }
                  >
                    {t('automation.step4.hide')}
                  </p>
                  <Icon iconName="chevron-up" />
                </span>
              ) : (
                <span style={{ display: 'flex' }}>
                  <p
                    className={styles.btnComboBox}
                    style={
                      session.preferences['exocode-theme']
                        ? {}
                        : {
                            color: 'white'
                          }
                    }
                  >
                    {t('automation.step4.show')}
                  </p>
                  <Icon iconName="chevron-down" />
                </span>
              )}
            </Button>
          )}
        </div>
      </div>
      {showPages && (
        <div id="existingEndpoints" className={styles.EndpointsListWrapper}>
          {props.crudData.pages
            .filter((p) => p.folder_id === props.crudData.selectedFolder?.uuid)
            .map((page) => {
              return (
                <div
                  id="list-group"
                  key={page.uuid}
                  className={`bg-body-tertiary border ${styles.FunctionWrapper}`}
                >
                  <div id={page.description} className={`${styles.FunctionDescription}`}>
                    <Badge
                      className={`${styles.Badge} ${
                        session.preferences['exocode-theme']
                          ? styles.BadgeBackground
                          : styles.BadgeBackgroundWhite
                      }`}
                    >
                      <div className={styles.CodeFunctionDefinition}>{page.name}</div>
                      <div style={{ marginLeft: '30px', fontWeight: 'normal' }}>
                        Route: {page.data.route}
                      </div>
                    </Badge>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {/* New Pages */}
      <div className={styles.DataObjectHeaderNewEnd}>
        <div style={{ width: 'fit-content' }}>{t('automation.step5.newPages')}:</div>
        <HelpPopover
          placement={'top'}
          helpBoxProps={{
            title: t('createPages') ?? ''
          }}
        >
          <Button
            id="createButton"
            variant="primary"
            onClick={() => setShowPagesCreatorDialog(true)}
            disabled={!props.crudData.selectedFolder}
          >
            <Icon iconName="plus" extraProps="fa-lg" />
          </Button>
        </HelpPopover>
      </div>
      {/* Pages list. */}
      {props.crudData.crudPages.length > 0 && (
        <div id="list-group" className={styles.EndpointsListWrapper}>
          {props.crudData.crudPages.map((page, index) => {
            return (
              <CrudPageInstance
                key={page.uuid}
                crudData={props.crudData}
                page={page}
                onChange={props.onChange}
                onDelete={() => removePage(index)}
              />
            );
          })}
        </div>
      )}
      {/* New Pages Modal */}
      {props.crudData.entityUuid && module_id && showPagesCreatorDialog && (
        <AddPagesDialog
          showDialog={showPagesCreatorDialog}
          dialogOnClose={() => setShowPagesCreatorDialog(false)}
          onCreate={() => {
            getPagesList();
          }}
          onChange={props.onChange}
          layoutsList={layouts}
          parentUuid={newViewParent}
          crudData={props.crudData}
          loading={props.loading}
        />
      )}
      {/* New Folder Modal */}
      <CreateFolderDialog
        show={showFolderDialog}
        onClose={() => {
          setShowFolderDialog(false);
          setNewViewParent('');
        }}
        parentUuid={newViewParent}
        onCreate={(folder: Folder) => {
          fetchFolders(folder.uuid);
          setShowFolderDialog(false);
        }}
        loading={props.loading}
        validateFolderName={(parent: string, name: string) => folderNameIsValid(parent, name)}
        type={FolderType.UI}
      />
    </div>
  );
}
