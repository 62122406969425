import { Action } from 'redux';
import { InterfaceStudioState } from '..';

export const SET_INITIAL_DESIGNER_STATE = 'SET_INITIAL_DESIGNER_STATE';

export type DesignerStudioActions = SetInitialDesignerStateAction;

export interface SetInitialDesignerStateAction extends Action {
  type: 'SET_INITIAL_DESIGNER_STATE';
  state: InterfaceStudioState;
}

export const setInitialDesignerState = (
  state: InterfaceStudioState
): SetInitialDesignerStateAction => ({
  type: SET_INITIAL_DESIGNER_STATE,
  state
});
