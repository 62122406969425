import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import { ComponentUUID, ViewUUID } from '../../../types';
import ComponentsRenderer from '../components_renderer';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { VIEWS_TYPES } from '../../frames';
import { setSelectedComponent, setSelectedView } from '../../store/actions/studio';
import useDropZone from '../drop_zone_hook';
import { COMPONENT_TYPES } from '../index';
import { parseCustomCss } from 'utils/cssUtils';

export type OutletData = {
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  heightCalc?: number;
};

export type OutletStyles = {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
};

export type OutletContentProps = {
  uuid: ComponentUUID;
  data: OutletData;
  styles: OutletStyles;
  viewUUID: ViewUUID;
};

function OutletContent(props: OutletContentProps) {
  const elementRef = useRef<HTMLDivElement>(null);

  useDropZone(elementRef, props.uuid, COMPONENT_TYPES.OUTLET, props.viewUUID);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.addEventListener('dragover', (ev: DragEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
      });
      elementRef.current.addEventListener('dragstart', (ev: DragEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
      });
      elementRef.current.addEventListener('dragend', (ev: DragEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
      });
      elementRef.current.addEventListener('dragleave', (ev: DragEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
      });
    }
  }, []);

  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  return (
    <div ref={elementRef} style={{ ...style, pointerEvents: 'none' }}>
      <div style={{ pointerEvents: 'auto', position: 'relative' }}>
        <ComponentsRenderer viewUUID={props.viewUUID} parentUUID={props.viewUUID} />
      </div>
    </div>
  );
}

export type OutletProps = {
  uuid: ComponentUUID;
  data: OutletData;
  styles: OutletStyles;
};

function Outlet(props: OutletProps, ref: React.Ref<any>) {
  const [currentViewUUID, setCurrentViewUUID] = useState<ViewUUID>();
  const views = useSelector((state: InterfaceStudioState) => state.views);

  useEffect(() => {
    setCurrentViewUUID(Object.keys(views)[0]);
  }, [views]);

  if (!currentViewUUID || !views[currentViewUUID]) return <></>;

  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  if (props.data.widthCalc && props.data.width !== 'fit-content') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  if (props.data.heightCalc && props.data.height !== 'fit-content') {
    style.height = `calc(${style.height} - ${props.data.heightCalc}px)`;
  }

  if (views[currentViewUUID].type === VIEWS_TYPES.LAYOUT) {
    return (
      <div
        ref={ref}
        style={{
          ...style,
          backgroundColor: '#eeeeee',
          textAlign: 'center',
          verticalAlign: 'middle',
          ...parseCustomCss(props.styles.css)
        }}
      >
        PAGE CONTENT HERE
      </div>
    );
  }

  return <OutletContent {...props} viewUUID={currentViewUUID} />;
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(Outlet));
