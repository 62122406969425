import { Action } from 'redux';
import { VariablesState } from '../../types/variables';

export const DELETE_VARIABLE = 'DELETE_VARIABLE';

export interface DeleteVariableAction extends Action {
  type: 'DELETE_VARIABLE';
  payload: { uuid: string };
}

export const deleteVariable = (uuid: string): DeleteVariableAction => ({
  type: DELETE_VARIABLE,
  payload: { uuid }
});

export function doDeleteVariable(
  state: VariablesState,
  action: DeleteVariableAction
): VariablesState {
  const { uuid } = action.payload;
  if (!state[uuid]) return state;
  delete state[uuid];
  return state;
}
