import React, { ComponentType } from 'react';
import { Frame, FrameTypes, FramesList } from '.';
import { useParams } from 'react-router-dom';
import { CustomComponentViewProps } from 'modules/designer/studio/frames/custom_component_view';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { DatabaseStudioState } from 'modules/modeler/studio/store';
import WidgetsRenderer from '../widgets/widgets_renderer';

export type FrameRendererProps = {
  frames: { [key: string]: Frame };
};

function FrameRenderer(props: FrameRendererProps) {
  const { custom_component_id } = useParams();
  const widgets = useSelector((state: InterfaceStudioState | DatabaseStudioState) => state.widgets);

  const renderCustomComponentView = React.useMemo(() => {
    const FrameComponent = FramesList[
      FrameTypes.CUSTOM_COMPONENT
    ] as ComponentType<CustomComponentViewProps>;

    return (
      FrameComponent &&
      custom_component_id && (
        <FrameComponent custom_uuid={custom_component_id} new_custom_uuid={custom_component_id} />
      )
    );
  }, [custom_component_id]);

  const renderWidgets = React.useMemo(() => <WidgetsRenderer widgets={widgets} />, [widgets]);

  const renderFrames = React.useMemo(() => {
    return (
      props.frames &&
      Object.keys(props.frames).map((key) => {
        const frame = props.frames[key];
        if (!frame) return null;

        const FrameComponent = FramesList[frame.type] as ComponentType;
        return (
          FrameComponent &&
          !custom_component_id && <FrameComponent key={frame.uuid} posX={0} posY={0} {...frame} />
        );
      })
    );
  }, [custom_component_id, props.frames]);

  return (
    <>
      {renderCustomComponentView}
      {renderWidgets}
      {renderFrames}
    </>
  );
}

export default FrameRenderer;
