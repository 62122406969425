import React from 'react';

function UseResizeInput() {
  const resizeInput = (
    inputRef: React.RefObject<HTMLInputElement>,
    text: string,
    placeholder: string
  ) => {
    if (!inputRef?.current) return;
    if (!text || text === '') {
      inputRef.current.style.width = `${placeholder.length + 2}ch`;
    } else {
      inputRef.current.style.width = `${text.length + 4}ch`;
    }
  };

  return { resizeInput };
}

export default UseResizeInput;
