import { Action } from 'redux';
import { ComponentUUID, EventTypes, FrontendFunctionUUID, LayoutComponent } from '../../../types';

export const SET_COMPONENTS = 'SET_COMPONENTS';
export const CHANGE_COMPONENT_PROPERTY = 'CHANGE_COMPONENT_PROPERTY';
export const CHANGE_COMPONENT_STYLE = 'CHANGE_COMPONENT_STYLE';
export const ADD_COMPONENT_EVENT = 'ADD_COMPONENT_EVENT';
export const DELETE_COMPONENT_EVENT = 'DELETE_COMPONENT_EVENT';
export const COPY_COMPONENT = 'COPY_COMPONENT';
export const CHANGE_COMPONENT_PROPERTIES = 'CHANGE_COMPONENT_PROPERTIES';
export const CHANGE_ALL_COMPONENT_PROPERTIES = 'CHANGE_ALL_COMPONENT_PROPERTIES';

export type ChangePropertyPayload = {
  uuid: ComponentUUID;
  key: string;
  value: any;
};

export type AllProperties = {
  data: ChangePropertyPayload[];
  style: ChangePropertyPayload[];
};

export interface DuplicateComponentAction extends Action {
  type: 'COPY_COMPONENT';
  payload: {
    target: string;
    component: string;
    oldIdsNewIds: Record<string, string>;
  };
}

export interface SetComponentsAction extends Action {
  type: 'SET_COMPONENTS';
  payload: {
    components: { [key: ComponentUUID]: LayoutComponent };
  };
}

export interface ChangeComponentPropertyAction extends Action {
  type: 'CHANGE_COMPONENT_PROPERTY';
  payload: ChangePropertyPayload;
}

export interface ChangeComponentStyleAction extends Action {
  type: 'CHANGE_COMPONENT_STYLE';
  payload: {
    uuid: ComponentUUID;
    key: string;
    value: any;
  };
}

export interface AddComponentEventAction extends Action {
  type: 'ADD_COMPONENT_EVENT';
  payload: {
    component: ComponentUUID;
    event: EventTypes;
    function: FrontendFunctionUUID;
    customComponent?: string;
  };
}

export interface DeleteComponentEventAction extends Action {
  type: 'DELETE_COMPONENT_EVENT';
  payload: {
    component: ComponentUUID;
    event: EventTypes;
  };
}

export interface ChangeComponentPropertiesAction extends Action {
  type: 'CHANGE_COMPONENT_PROPERTIES';
  payload: ChangePropertyPayload[];
}

export interface ChangeAllComponentPropertiesAction extends Action {
  type: 'CHANGE_ALL_COMPONENT_PROPERTIES';
  payload: AllProperties;
}

export const duplicateComponent = (
  target: string,
  component: string,
  oldIdsNewIds: Record<string, string>
): DuplicateComponentAction => ({
  type: COPY_COMPONENT,
  payload: { target, component, oldIdsNewIds }
});

export const setComponents = (components: {
  [key: ComponentUUID]: LayoutComponent;
}): SetComponentsAction => ({
  type: SET_COMPONENTS,
  payload: { components }
});

export const changeComponentProperty = (
  uuid: ComponentUUID,
  key: string,
  value: any
): ChangeComponentPropertyAction => ({
  type: CHANGE_COMPONENT_PROPERTY,
  payload: { uuid, key, value }
});

export const changeComponentStyle = (
  uuid: ComponentUUID,
  key: string,
  value: any
): ChangeComponentStyleAction => ({
  type: CHANGE_COMPONENT_STYLE,
  payload: { uuid, key, value }
});

export const addComponentEvent = (
  component: ComponentUUID,
  event: EventTypes,
  functionUuid: FrontendFunctionUUID,
  customComponent?: string
): AddComponentEventAction => ({
  type: ADD_COMPONENT_EVENT,
  payload: { component, event, function: functionUuid, customComponent }
});

export const deleteComponentEvent = (
  component: ComponentUUID,
  event: EventTypes
): DeleteComponentEventAction => ({
  type: DELETE_COMPONENT_EVENT,
  payload: { component, event }
});

// Custom components.
export const CHANGE_CUSTOM_COMPONENT_VIEW_PROPERTY = 'CHANGE_CUSTOM_COMPONENT_VIEW_PROPERTY';

export interface ChangeCustomComponentViewPropertyAction extends Action {
  type: 'CHANGE_CUSTOM_COMPONENT_VIEW_PROPERTY';
  payload: {
    uuid: string;
    key: string;
    value: any;
  };
}

export const changeCustomComponentViewProperty = (
  uuid: string,
  key: string,
  value: any
): ChangeCustomComponentViewPropertyAction => ({
  type: CHANGE_CUSTOM_COMPONENT_VIEW_PROPERTY,
  payload: { uuid, key, value }
});

export const changeComponentProperties = (
  componentProperties: ChangePropertyPayload[]
): ChangeComponentPropertiesAction => ({
  type: CHANGE_COMPONENT_PROPERTIES,
  payload: componentProperties
});

export const changeAllComponentsProperties = (
  allComponentsProperties: AllProperties
): ChangeAllComponentPropertiesAction => ({
  type: CHANGE_ALL_COMPONENT_PROPERTIES,
  payload: allComponentsProperties
});
