import React, { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

export type ContextMenuProps = {
  elementRef?: React.RefObject<any>;
  offsetX?: number;
  offsetY?: number;
  children?: ReactNode;
};

function ContextMenu(props: ContextMenuProps) {
  let appRoot = document.getElementById('contextmenu') as HTMLElement;
  if (appRoot == null) {
    appRoot = document.getElementById('root') as HTMLElement;
  }
  const [showMenu, setShowMenu] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const location = useLocation();
  const iframeHeight = parseInt(location.search.split('&')[0].split('=')[1]);
  const iframeWidth = parseInt(location.search.split('&')[1]?.split('=')[1]);

  const close = useCallback(
    (ev: CustomEvent) => {
      if (props.elementRef == null || props.elementRef.current == null) return;

      if (ev.detail && ev.detail.ref === props.elementRef.current) return;

      setShowMenu(false);
      document.removeEventListener('click', close as EventListener);
      document.removeEventListener('contextmenu_close', close as EventListener);
    },
    [props.elementRef]
  );

  const closeOtherContextMenus = useCallback(() => {
    const event = new CustomEvent('contextmenu_close', {
      detail: {
        ref: props.elementRef?.current
      }
    });

    document.dispatchEvent(event);
  }, [props.elementRef]);

  const open = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();

      const offsetX = ev.pageX;
      const offsetY = ev.pageY;

      setPosition({
        x: offsetX > iframeWidth - 250 ? offsetX - 240 : offsetX,
        y: offsetY > iframeHeight - 250 ? offsetY - 240 : offsetY
      });

      // overwrite for correct value
      if (location && location.pathname && location.pathname.includes('schema')) {
        setPosition({
          x: ev.clientX - 65,
          y: ev.clientY - 65
        });
      }

      setShowMenu(true);

      closeOtherContextMenus();

      document.addEventListener('click', close as EventListener);
      document.addEventListener('contextmenu_close', close as EventListener);
    },
    [close, closeOtherContextMenus]
  );

  useEffect(() => {
    if (props.elementRef == null || props.elementRef.current == null) return;

    const node = props.elementRef.current;

    node.addEventListener('contextmenu', open);

    return () => {
      node.removeEventListener('contextmenu', open);
      document.removeEventListener('click', close as EventListener);
      document.removeEventListener('contextmenu_close', close as EventListener);
    };
  }, [close, open, props.elementRef]);

  function handleOnMouseDown(event: MouseEvent<HTMLUListElement>) {
    event.stopPropagation();
  }

  if (!showMenu || !props.elementRef || !props.elementRef.current) return null;

  return ReactDOM.createPortal(
    <ul
      className={`dropdown-menu ${props.children ? 'show' : 'hidden'}`}
      style={{ position: 'absolute', zIndex: 4, left: `${position.x}px`, top: `${position.y}px` }}
      onMouseDown={handleOnMouseDown}
    >
      {props.children}
    </ul>,
    appRoot
  );
}

export default ContextMenu;
