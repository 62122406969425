import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { FrontendFunction, FunctionTypes, ViewUUID } from '../../../types';
import { addFunction, updateFunction } from '../../store/actions/functions';
import { validateRegex } from 'utils/utils';
import { InterfaceStudioState } from '../../store';
import { useTranslation } from 'react-i18next';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { FunctionType } from 'web_ui/function_editor/store/types/functions';
import { setErrorMessage } from '../../store/actions/studio';

type CreateFunctionDialogProps = {
  view_id: ViewUUID;
  show: boolean;
  onClose: () => void;
  func?: FrontendFunction;
};

function CreateFunctionDialog(props: CreateFunctionDialogProps) {
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<FunctionTypes>();
  const [data, setData] = useState<any>();
  const [validated, setValidated] = useState(false);
  const [onError, setOnError] = useState<string>('');
  const [onSuccess, setOnSuccess] = useState<string>('');
  const dispatch = useDispatch();
  const functionNamePattern = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/;
  const { t } = useTranslation();
  const functionsKeyPair = useSelector((state: FunctionEditorState) => state.functions);
  const [functions, setFunctions] = useState<FunctionType[]>([]);

  useEffect(() => {
    setFunctions(Object.values(functionsKeyPair));
  }, [functionsKeyPair]);

  useEffect(() => {
    if (!props.func) {
      setName('');
      setType('NAVIGATE');
      setData({});
      setOnError('');
      setOnSuccess('');
    } else {
      setName(props.func.name);
      // setOnError(props.func.onError);
      // setOnSuccess(props.func.onSuccess);
    }
  }, [props]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    if (props.func?.uuid === undefined && type === 'REST' && (onError !== '' || onSuccess !== '')) {
      data.status = uuidv4();
    }

    setValidated(false);

    if (!type) return;

    const functionInfo: FrontendFunction = {
      uuid: props.func?.uuid ?? uuidv4(),
      name: name,
      description: '',
      customComponent: editor === 'CUSTOM_COMPONENT' ? props.view_id : '',
      view: editor !== 'CUSTOM_COMPONENT' ? props.view_id : ''
    };

    try {
      if (functions.findIndex((item) => item.name === functionInfo.name) != -1) {
        throw new Error('Name already exist');
      } // if it has a equal name of any inside the array throw error
      props.func?.uuid
        ? dispatch(updateFunction(functionInfo))
        : dispatch(addFunction(functionInfo));
    } catch (e) {
      dispatch(setErrorMessage(t('designer.functions.errorSameName')));
      return;
    }

    props.onClose();
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  function handleValidFunctionName(funcName: string) {
    if (name === '') {
      return false;
    }
    return validateRegex(functionNamePattern, funcName);
  }

  return (
    <Modal centered show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.func ? `${t('appResume.module.Update')}` : `${t('appResume.module.Create')}`}{' '}
          {t('appResume.module.function')}
        </Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body id="bodyModal">
          <Form.Group className="mb-3">
            <Form.Label>{t('appResume.module.Name')}</Form.Label>
            <Form.Control
              id="formName"
              type="text"
              value={name}
              onChange={onChangeName}
              required
              isValid={handleValidFunctionName(name)}
              isInvalid={!handleValidFunctionName(name)}
            />
            <Form.Control.Feedback type="invalid">
              {`${t('appResume.module.nameMustStart')}`}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={!handleValidFunctionName(name)}>
            {props.func ? `${t('appResume.module.Save')}` : `${t('appResume.module.Create')}`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateFunctionDialog;
