import React, { useEffect, useRef } from 'react';
import SearchModules from './search_modules';
import styles from './style.module.css';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import SessionContext from 'modules/auth/store';
import { useClickOutsideEvent } from 'hooks/useClickOutside';

function AppModules() {
  const { pageVisits, updatePageVisits } = React.useContext(SessionContext);
  const driverInstance = useRef<any>();
  const currentContext = 'dashboard';

  useEffect(() => {
    if (!pageVisits) {
      return;
    }
    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done',
      onClose: () => {
        updatePageVisits(currentContext);
      }
    });
  }, [pageVisits, updatePageVisits]);

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  return (
    <div className={`${styles.moduleListWrapper}`}>
      <SearchModules />
    </div>
  );
}

export default AppModules;
