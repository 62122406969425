import React, { useCallback } from 'react';
import { TYPE_PICKER_TYPES } from './type_picker';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import FixedValueInput from './fixed_value_input';
import { VariablePicker } from './value_pickers/variable_picker';
import { ParameterPicker } from './value_pickers/parameter_picker';
import { PropertyPicker } from './value_pickers/property_picker';
import { PageParameterPicker } from './value_pickers/page_parameter_picker';

type ValuePickerProps = {
  type: TYPE_PICKER_TYPES;
  label: string;
  actionUuid: string;
  value: string;
  handleChange: (text: string) => void;
  filterByList: boolean;
  filterByDataType?: (
    state: FunctionEditorState,
    actionUuid: string,
    dataType: string,
    objectUuid?: string
  ) => boolean;
  filterByGlobalVars?: boolean;
  isFromSideBar?: boolean;
};

function ValuePicker({
  type,
  label,
  actionUuid,
  value,
  handleChange,
  filterByList,
  filterByDataType,
  filterByGlobalVars,
  isFromSideBar
}: ValuePickerProps) {
  const renderValuePicker = useCallback(() => {
    switch (type) {
      case TYPE_PICKER_TYPES.VAR:
        return (
          <VariablePicker
            label={label}
            actionUuid={actionUuid}
            value={value}
            handleChange={handleChange}
            filterByList={filterByList}
            filterByDataType={filterByDataType}
            filterByGlobalVars={filterByGlobalVars}
            isFromSideBar={false}
            isFromArgument={isFromSideBar && true}
          />
        );
      case TYPE_PICKER_TYPES.PARAM:
        return (
          <ParameterPicker
            label={label}
            actionUuid={actionUuid}
            value={value}
            handleChange={handleChange}
            filterByList={filterByList}
            filterByDataType={filterByDataType}
            isFromSideBar={false}
            isFromArgument={isFromSideBar && true}
          />
        );
      case TYPE_PICKER_TYPES.FIXED:
        return (
          <FixedValueInput placeholder={'Type value'} handleChange={handleChange} value={value} />
        );
      case TYPE_PICKER_TYPES.PAGEPARAM:
        return (
          <PageParameterPicker
            label={label}
            value={value}
            handleChange={handleChange}
            isFromSideBar={false}
            isFromArgument={isFromSideBar && true}
          />
        );
      case TYPE_PICKER_TYPES.PROP:
        return (
          <PropertyPicker
            label={label}
            actionUuid={actionUuid}
            value={value}
            handleChange={handleChange}
            filterByList={filterByList}
            filterByDataType={filterByDataType}
            isFromSideBar={false}
            isFromArgument={isFromSideBar && true}
          />
        );
      default:
        throw new Error('Missing implementation for value picker.');
    }
  }, [
    actionUuid,
    filterByDataType,
    filterByGlobalVars,
    filterByList,
    handleChange,
    label,
    type,
    value
  ]);

  return <>{renderValuePicker()}</>;
}

export default ValuePicker;
