import React, { ChangeEvent, useEffect, useState } from 'react';
import { BADGE_MANIFEST, FormatterProps, FormatterTypes } from '../../types';
import { Col, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from '../../../components/Popover';
import HelpIcon from '../../../components/HelpIcon';
import Icon from 'web_ui/icon';
import Button from 'react-bootstrap/Button';

function BadgeFormatter(props: FormatterProps) {
  const { t } = useTranslation();
  const [newKey, setNewKey] = useState<string>('');
  const [newValue, setNewValue] = useState<string>('');

  const { onChange, index, value } = props;
  useEffect(() => {
    if (!value || value.type !== FormatterTypes.BADGE) {
      onChange && onChange(index, 'formatterParams', BADGE_MANIFEST);
    }
  }, [index, onChange, value]);

  function handleAddMapper() {
    const newValues: Record<string, any> = { ...props.value.values };
    newValues[newKey as string] = newValue;
    handleChange(newValues);
    setNewKey('');
    setNewValue('');
  }

  function handleRemoveMapper(keyToRemove: string) {
    const newValues: Record<string, any> = { ...props.value.values };
    for (const key in newValues) {
      if (key === keyToRemove) {
        delete newValues[key];
      }
    }
    handleChange(newValues);
  }

  function handleChange(newValues: Record<string, any>) {
    const updatedBadgeParams: Record<string, any> = {
      values: newValues,
      type: FormatterTypes.BADGE
    };
    if (props.onChange) props.onChange(props.index, 'formatterParams', updatedBadgeParams);
  }

  return (
    <div className="mb-3 border-bottom ">
      <div className="mb-3">
        <div className="mb-3 d-flex justify-content-between align-items-end">
          <Col sm={5}>
            <Form.Group>
              <Form.Label column>
                {t('designer.right_side.FormatterControls.BadgeFormatterNewKey')}
              </Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.BadgeFormatterNewKeyTitle') ||
                    'designer.right_side.FormatterControls.BadgeFormatterNewKeyTitle',
                  description:
                    t('designer.right_side.FormatterControls.BadgeFormatterNewKeyDescription') ||
                    'designer.right_side.FormatterControls.BadgeFormatterNewKeyDescription',
                  note: [
                    t('designer.right_side.FormatterControls.BadgeFormatterNewKeyNote01') ||
                      'designer.right_side.FormatterControls.BadgeFormatterNewKeyNote01'
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="text"
                placeholder="key"
                value={newKey}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewKey(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col sm={5}>
            <Form.Group>
              <Form.Label column>
                {t('designer.right_side.FormatterControls.BadgeFormatterNewValue')}
              </Form.Label>
              <HelpPopover
                helpBoxProps={{
                  title:
                    t('designer.right_side.FormatterControls.BadgeFormatterNewValueTitle') ||
                    'designer.right_side.FormatterControls.BadgeFormatterNewValueTitle',
                  description:
                    t('designer.right_side.FormatterControls.BadgeFormatterNewValueDescription') ||
                    'designer.right_side.FormatterControls.BadgeFormatterNewValueDescription',
                  note: [
                    t('designer.right_side.FormatterControls.BadgeFormatterNewValueNote01') ||
                      'designer.right_side.FormatterControls.BadgeFormatterNewValueNote01'
                  ]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
              <Form.Control
                type="color"
                placeholder="value"
                value={newValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Button
            variant="secondary"
            size="sm"
            disabled={newKey === '' || newValue === ''}
            onClick={handleAddMapper}
            style={{ height: '37px' }}
          >
            <Icon iconName="plus" />
          </Button>
        </div>

        <div className="">
          {props.value && props.value.values && Object.keys(props.value.values).length > 0 ? (
            <ListGroup>
              {Object.keys(props.value.values).map((mapperKey: string, index) => (
                <ListGroup.Item key={index} className="d-flex justify-content-between pe-4 ps-4">
                  <span>{mapperKey}</span>
                  <span className="text-body-tertiary">
                    <Icon iconName="arrow-right"></Icon>
                  </span>
                  <div>
                    <span
                      className="me-3 p-1 rounded"
                      style={{
                        backgroundColor: props.value.values[mapperKey],
                        color: '#333',
                        fontWeight: 700,
                        minWidth: '73px'
                      }}
                    >
                      {props.value.values[mapperKey]}
                    </span>
                    <span
                      onClick={(e) => handleRemoveMapper(mapperKey)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon iconName="trash" />
                    </span>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <span className="text-body-tertiary">Sem mapeamentos</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default BadgeFormatter;
