export enum Properties {
  // Database
  DB_URL = 'spring.datasource.url',
  DB_USERNAME = 'spring.datasource.username',
  DB_PASSWORD = 'spring.datasource.password',
  DB_DRIVER = 'spring.datasource.driver-class-name',
  DB_H2 = 'spring.h2.console.enabled',

  // Hibernate
  SHOW_SQL = 'spring.jpa.show-sql',
  HIBERNATE_SHOW_SQL = 'spring.jpa.properties.hibernate.show_sql',
  HIBERNATE_FORMAT_SQL = 'spring.jpa.properties.hibernate.format_sql',
  HIBERNATE_LOGGING_SQL = 'logging.level.org.hibernate.SQL',
  HIBERNATE_LOGGING_BASIC_BINDER = 'logging.level.org.hibernate.type.descriptor.sql.BasicBinder',
  HIBERNATE_LOGGING_EVENT_LISTENER = 'logging.level.org.hibernate.event.internal.DefaultPersistEventListener',

  // Server
  API_PORT = 'server.port',

  // Webapp
  WEBAPP_PORT = 'webapp.port',

  //Spring
  SPRING_SQL = 'spring.sql'
}
