export interface ParametersState {
  [key: string]: FunctionParameterType;
}

export interface FunctionParameterType {
  uuid: string;
  name: string;
  type: string;
  desc: string;
  objectUuid?: string;
  enumUuid?: string;
  list: boolean;
  required: boolean;
  native?: boolean;
  relationshipId?: string;
}

export interface ChangeFunctionParameterPayload {
  uuid: string;
  name: string;
  type: string;
}

export const FORBIDDEN_PARAMS_NAMES = ['sort', 'filter', 'page', 'size'];
