import React from 'react';
import styles from './styles.module.css';

export type ApplicationAvatarProps = {
  // The application name
  name?: string;
  // The avatar width
  width?: string;
  // The application name font size
  fontSize?: string;
  // The application avatar border radius
  borderRadius?: string;
  // Custom css styles
  className?: string;
};

/**
 * The application avatar
 *
 * @component
 */
function ApplicationAvatar(props: ApplicationAvatarProps) {
  const style = {
    width: props.width ?? '40px',
    height: props.width ?? '40px',
    fontSize: props.fontSize ?? '24px',
    borderRadius: props.borderRadius ?? '5px'
  };

  return (
    <div className={`${styles.Avatar} ${props.className}`} style={style}>
      {props.name?.substring(0, 2).toUpperCase()}
    </div>
  );
}

export default ApplicationAvatar;
