import { Action } from 'redux';
import { ActionsState } from '../../types/actions';

export const CHANGE_RETURN = 'CHANGE_RETURN';

export interface ChangeReturnAction extends Action {
  type: 'CHANGE_RETURN';
  payload: {
    uuid: string;
    returnVariableUuid: string;
  };
}

export const changeReturn = (uuid: string, returnVariableUuid: string): ChangeReturnAction => ({
  type: CHANGE_RETURN,
  payload: { uuid, returnVariableUuid }
});

export function doChangeReturn(state: ActionsState, action: ChangeReturnAction): ActionsState {
  const { uuid, returnVariableUuid } = action.payload;
  state[uuid].returnVariableUuid = returnVariableUuid;
  return state;
}
