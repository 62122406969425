import React, { ReactNode } from 'react';
import styles from '../editor_panel/styles.module.css';

type ParameterWrapperProps = {
  children: ReactNode;
  index: number;
  handleDrop: (e: React.DragEvent, index: number) => void;
};

function ParameterWrapper({ children, index, handleDrop }: ParameterWrapperProps) {
  const handleDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData('exocode/dragged-parameter-index', String(index));
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  return (
    <div
      className={`${styles.ParameterWrapper} rounded-3`}
      draggable
      onDragStart={handleDragStart}
      onDrop={(e) => handleDrop(e, index)}
      onDragOver={handleDragOver}
    >
      {children}
    </div>
  );
}

export default ParameterWrapper;
