import React, { useRef } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import styles from './style.module.css';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import HelpIcon from 'web_ui/workboard/sidebar/controls/components/HelpIcon';
import Icon from 'web_ui/icon';

interface InformationAppProps {
  name: string;
  appId: string;
  setName: (name: string) => void;
  description: string;
  handleDescriptionChange: (description: string) => void;
  domain: string;
  setDomain: (domain: string) => void;
  invalid: boolean;
  setInvalidated: (invalid: boolean) => void;
  usingAuth: boolean | undefined;
  domainValidation: (strDomain: string) => void;
  setShowConfirmationDialog: (show: boolean) => void;
  urlIcon?: string;
  setUrlIcon: (str: string) => void;
  newIcon?: File;
  setNewIcon: (val?: File) => void;
  descriptionInputErrorMessage: string;
}

function InformationApp(props: InformationAppProps) {
  const { t } = useTranslation();
  const formFileRef = useRef<HTMLInputElement>(null);

  /**
   * Handles the application's information change.
   */
  function changeDomain(value: string) {
    const text = value.toString().toLowerCase().trim().replace(/\s/g, '');
    props.domainValidation(text);
    props.setDomain(text);
  }

  // send url icon and new icon empty
  const deleteIco = () => {
    props.setUrlIcon('');
    props.setNewIcon(undefined);
    if (formFileRef.current) {
      formFileRef.current.value = '';
    }
  };

  // send url icon empty and new icon filled
  const uploadIco = (file: File) => {
    props.setUrlIcon('');
    props.setNewIcon(file);
  };

  // if url icon is filled do nothing

  const moutingCorrectUrlToShowPreview = () => {
    if (process.env.REACT_APP_API_URL && props.urlIcon) {
      return process.env.REACT_APP_API_URL + props.urlIcon;
    }
  };

  return (
    <div className="mt-3 ms-3 me-3">
      <Form.Group className="mb-3">
        <Form.Label>{t('Name')}</Form.Label>
        <Form.Control
          type="text"
          id="formName"
          onChange={(e) => props.setName(e.target.value)}
          value={props.name}
          maxLength={64}
          isInvalid={props.name.length === 0}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('Package')}</Form.Label>
        <Form.Control
          type="text"
          id="formDomain"
          placeholder="example.com"
          onChange={(e) => changeDomain(e.target.value)}
          value={props.domain}
          maxLength={128}
          pattern="[a-z._0-9]+\.[a-z_0-9]+$"
          isInvalid={props.invalid}
          required
        />
        {props.invalid && (
          <Form.Text style={{ color: 'red' }}>{t('new_application.step2.InvalidDomain')}</Form.Text>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          id="formDescription"
          onChange={(e) => props.handleDescriptionChange(e.target.value)}
          value={props.description}
          isInvalid={props.descriptionInputErrorMessage !== ''}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t(props.descriptionInputErrorMessage)}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" id="uploadFile">
        <Row className="align-items-start">
          <Col xs={8}>
            <Form.Label>
              {t('favicon')}
              <HelpPopover placement="top" helpBoxProps={{ description: t('favIconRule') || '' }}>
                <HelpIcon />
              </HelpPopover>
            </Form.Label>
            <Form.Control
              ref={formFileRef}
              id={'uploadFile'}
              type="file"
              required={false}
              onChange={(ev: any) => {
                if (ev.target.files[0]) {
                  uploadIco(ev.target.files[0]);
                }
              }}
              accept=".ico"
            />
          </Col>
          <Col className="d-flex justify-content-center" xs={4}>
            <div style={{ position: 'relative' }}>
              <Image
                alt=""
                src={
                  props.urlIcon
                    ? moutingCorrectUrlToShowPreview()
                    : props.newIcon
                    ? URL.createObjectURL(props.newIcon)
                    : '/assets/default.png'
                }
                style={{ width: 100, height: 100, borderRadius: 999 }}
              />
              {(props.urlIcon || props.newIcon) && (
                <div className={`${styles.deleteImgIcon} bg-danger`} onClick={() => deleteIco()}>
                  <Icon iconName="xmark" extraProps="text-black" />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Form.Group>

      <Authorization
        contextUuid={props.appId}
        context={ContextRole.APP}
        allowedAuthorities={[RoleAuthorities.DELETE_APP]}
      >
        <Form.Group className="mb-3">
          <Form.Label className={styles.dangerZoneTitle}>
            {t('appResume.AttentionWarning')}
          </Form.Label>
          <div className="border border-2 border-danger rounded p-2">
            <div className={styles.dangerZoneContainer}>
              <div className={styles.dangerZoneLabels}>
                <Form.Label id={'dangerZoneButton'} className={styles.dangerZoneTitle}>
                  {t('appResume.DeleteApplication')}
                </Form.Label>
                <Form.Label className={styles.dangerZoneDescription}>
                  {t('appResume.DeleteApplicationDescription')}
                </Form.Label>
              </div>
              <Button
                id={'deleteButton'}
                variant="danger"
                onClick={() => props.setShowConfirmationDialog(true)}
              >
                {t('Delete')}
              </Button>
            </div>
          </div>
        </Form.Group>
      </Authorization>
    </div>
  );
}

export default InformationApp;
