import React from 'react';
import { ManifestLinks } from 'modules/designer/types';
import { SideMenuStyle } from '../../..';
import styles from '../../../styles.module.css';
import * as FontIcon from 'react-icons/fa';
import * as Icons from 'react-icons/fa';
import { IconText } from 'web_ui/workboard/sidebar/controls/IconTextControl';

export type RecursiveCollapseProps = {
  linkUUID: string;
  links: ManifestLinks;
  styles: SideMenuStyle;
  showNested: any;
  toggleNested: (key: string) => void;
  iconStyle: { [key: string]: string };
  styleClasses: { [key: string]: string };
  linkText: string;
};

function RecursiveCollapse(props: RecursiveCollapseProps) {
  return (
    <div className="collapse multi-collapse" id={`menuColapse${props.linkUUID}`}>
      {props.links.children[props.linkUUID].map((key) => {
        const iconName = props.links.items[key].icon;
        const IconDiv = iconName ? Icons[iconName] : null;
        return (
          <ul className="ms-2 list-group list-group-flush" key={key}>
            <li>
              <div
                data-bs-toggle="collapse"
                data-bs-target={`#menuColapse${key}`}
                onClick={() => props.toggleNested(key)}
              >
                <span
                  className={`${!props.links.children[key] ? styles.hidden : ''} ${
                    styles.smoothRotate
                  } ${props.showNested[key] ? styles.openedItem : ''}`}
                >
                  <FontIcon.FaChevronRight></FontIcon.FaChevronRight>
                </span>

                {(props.linkText === IconText.BOTH || props.linkText === IconText.ICON) && (
                  <div
                    className={`d-inline  ${
                      props.links.items[key].title &&
                      props.links.items[key].title!.length > 0 &&
                      props.links.items[key].icon
                        ? 'ms-1'
                        : ''
                    } text-${props.styleClasses.iconThemeColor}`}
                    style={props.iconStyle}
                  >
                    {IconDiv && <IconDiv />}
                  </div>
                )}
                <button
                  className={`${styles.btnToggle} fw-normal btn btn-toggle align-items-center rounded`}
                  data-bs-toggle="collapse"
                  data-bs-target={`#menuColapse${key}`}
                  style={{ color: props.styles.color, fontSize: '0.875rem' }}
                >
                  {props.links.items[key].title}
                </button>
              </div>
              {props.links.children[key] && (
                <RecursiveCollapse
                  linkUUID={key}
                  links={props.links}
                  styles={props.styles}
                  showNested={props.showNested}
                  toggleNested={props.toggleNested}
                  iconStyle={props.iconStyle}
                  styleClasses={props.styleClasses}
                  linkText={props.linkText}
                />
              )}
            </li>
          </ul>
        );
      })}
    </div>
  );
}

export default RecursiveCollapse;
