import React from 'react';
import { ComboboxInput } from 'web_ui/combobox';

export enum TYPE_PICKER_TYPES {
  VAR = 'VAR',
  PARAM = 'PARAM',
  FIXED = 'FIXED',
  PAGEPARAM = 'PAGE_PARAM',
  PROP = 'PROP'
}

const types: TYPE_PICKER_TYPES[] = Object.values(TYPE_PICKER_TYPES);

type TypePickerProps = {
  placeholder: string;
  label: string;
  value: TYPE_PICKER_TYPES;
  argumentTypes?: TYPE_PICKER_TYPES[];
  handleChange: (text: TYPE_PICKER_TYPES) => void;
  isFromSideBar?: boolean;
};

function TypePicker({
  placeholder,
  label,
  value = TYPE_PICKER_TYPES.VAR,
  argumentTypes,
  handleChange,
  isFromSideBar
}: TypePickerProps) {
  const getDisplayNameFromItem = (item: unknown): string => {
    const itemType = item as TYPE_PICKER_TYPES | undefined;
    return itemType ?? '';
  };

  const handleChangeSelectedItem = (item: unknown): void => {
    const itemType = item as TYPE_PICKER_TYPES;
    handleChange(itemType);
  };

  if (argumentTypes?.length === 1) return null;

  const filteredItems = argumentTypes && argumentTypes.length > 0 ? argumentTypes : types;

  return (
    <>
      <ComboboxInput
        items={filteredItems}
        selectedItem={value}
        getDisplayNameFromItem={getDisplayNameFromItem}
        handleChangeSelectedItem={handleChangeSelectedItem}
        label={label}
        placeholder={placeholder}
        isFromSideBar={isFromSideBar}
      />
    </>
  );
}

export default TypePicker;
