import { FunctionsState } from '../types/functions';
import {
  doUpdateFunction,
  UPDATE_FUNCTION,
  UpdateFunctionAction
} from '../events/functions/update_function';
import {
  CHANGE_RETURN_TYPE,
  ChangeReturnTypeAction,
  doChangeReturnType
} from '../events/functions/change_return_type';
import produce from 'immer';
import {
  CHANGE_PARAM_ORDER,
  ChangeParamOrderAction,
  doChangeParamOrder
} from '../events/functions/change_param_order';
import { ADD_ACTION, AddActionAction } from '../events/actions/add_action';
import { doAddAction } from '../events/functions/add_action';
import { ADD_PARAM, AddParamAction } from '../events/parameters/add_param';
import { doAddParam } from '../events/functions/add_param';
import { ADD_BLOCK, AddBlockAction } from '../events/actions/add_block';
import { doAddBlock } from '../events/functions/add_block';
import { DELETE_BLOCK, DeleteBlockAction } from '../events/actions/delete_block';
import { doDeleteBlock } from '../events/functions/delete_block';
import { SET_PAGINATION, SetPaginationAction } from '../events/parameters/set_pagination';
import { doSetPagination } from '../events/functions/set_pagination';
import { SET_SORT, SetSortAction } from '../events/parameters/set_sort';
import { doSetSort } from '../events/functions/set_sort';
import { SET_FILTER, SetFilterAction } from '../events/parameters/set_filter';
import { doSetFilter } from '../events/functions/set_filter';
import { initialState } from '../initial_empty_state';
import { CREATE_IF, CreateIfAction } from '../events/actions/create_if';
import { doCreateIf } from '../events/functions/create_if';
import { CREATE_ELSE, CreateElseAction } from '../events/actions/create_else';
import { doCreateElse } from '../events/functions/create_else';

type FunctionsActions =
  | UpdateFunctionAction
  | ChangeReturnTypeAction
  | AddParamAction
  | ChangeParamOrderAction
  | AddActionAction
  | CreateIfAction
  | CreateElseAction
  | AddBlockAction
  | DeleteBlockAction
  | SetPaginationAction
  | SetSortAction
  | SetFilterAction;

export const functionsReducer = (
  state: FunctionsState = initialState.functions,
  action: FunctionsActions
): FunctionsState => {
  return produce(state, (draft: FunctionsState) => {
    switch (action.type) {
      case UPDATE_FUNCTION:
        return doUpdateFunction(draft, action);
      case CHANGE_RETURN_TYPE:
        return doChangeReturnType(draft, action);
      case ADD_PARAM:
        return doAddParam(draft, action);
      case CHANGE_PARAM_ORDER:
        return doChangeParamOrder(draft, action);
      case ADD_ACTION:
        return doAddAction(draft, action);
      case CREATE_IF:
        return doCreateIf(draft, action);
      case CREATE_ELSE:
        return doCreateElse(draft, action);
      case ADD_BLOCK:
        return doAddBlock(draft, action);
      case DELETE_BLOCK:
        return doDeleteBlock(draft, action);
      case SET_PAGINATION:
        return doSetPagination(draft, action);
      case SET_SORT:
        return doSetSort(draft, action);
      case SET_FILTER:
        return doSetFilter(draft, action);
      default:
        return state;
    }
  });
};
