import { ReactNode } from 'react';
import JavaFunctionSignature from './java_signature';
import TypescryptFunctionSignature from './typescript_signature';
import { ReturnType } from '../../../../store/types/functions';

export type AvailableFrameworks = 'spring' | 'react';

export type FunctionSignatureProps = {
  types: ReturnType[];
};

export const FunctionSignature: { [Property in AvailableFrameworks]: ReactNode } = {
  spring: JavaFunctionSignature,
  react: TypescryptFunctionSignature
};
