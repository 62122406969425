import { Action } from 'redux';
import { ComponentUUID } from '../../../types';
import { ComponentTypes } from '../../exocode_components';

export const SET_LINKS = 'SET_LINKS';

export interface SetLinksAction extends Action {
  type: 'SET_LINKS';
  payload: {
    links: { [key: ComponentUUID]: ComponentUUID[] };
  };
}

export type MoveSource = {
  parentUUID: ComponentUUID;
  uuid: ComponentUUID;
  type: ComponentTypes;
  section?: string;
  deletePastParent?: boolean;
};

export type MoveTarget = {
  parentUUID: ComponentUUID;
  uuid?: ComponentUUID;
  section?: string;
  adjacentSide?: string;
};

export type MoveInfo = {
  parentUUID: ComponentUUID;
  uuid: ComponentUUID;
  type: ComponentTypes;
};

export const setLinks = (links: { [key: ComponentUUID]: ComponentUUID[] }): SetLinksAction => ({
  type: SET_LINKS,
  payload: { links }
});
