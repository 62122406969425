const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export default class PaymentsRepo {
  /**
   * Fetch available subscription plans.
   */
  async getSubscriptionPlans(): Promise<any> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/subscription-plans`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<any>;

      throw new Error('Something went wrong while trying to fetch Subscription Plans.');
    });
  }

  /**
   * Fetch user active subscription(s).
   */
  async getUserActiveSubscriptions(userId: number): Promise<any> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/subscriptions/${userId}/active-subscriptions`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<any>;

      throw new Error('Something went wrong while trying to fetch Subscription Plans.');
    });
  }

  /**
   * Fetch user active subscription(s).
   */
  async updateUserSubscription(
    userId: number,
    newPriceId: string,
    newLookupKey: string,
    subscriptionId: string
  ): Promise<any> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST'
    };

    const url = `${API_URL}/subscriptions/${userId}/update-subscription?new_price_id=${newPriceId}&new_lookup_key=${newLookupKey}&subscription_id=${subscriptionId}`;

    return await fetch(url, options).then((response) => {
      return response;
    });
  }

  /**
   * Cancel subscription.
   */
  async cancelPlan(userId: number, when: string): Promise<any> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST'
    };

    const url = `${API_URL}/subscriptions/${userId}/cancel-subscription?when=${when}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<any>;
    });
  }

  /**
   * Continue cancelled (when == due_date) subscription.
   */
  async continuePlan(userId: number): Promise<any> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST'
    };

    const url = `${API_URL}/subscriptions/${userId}/continue-subscription`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<any>;
    });
  }

  async createCheckoutSetupSession(userId: number): Promise<any> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST'
    };

    const url = `${API_URL}/stripe/${userId}/create-setup-session`;

    return await fetch(url, options).then((response) => {
      return response;
    });
  }
}
