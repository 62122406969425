import React from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

export type ComponentHeaderProps = {
  name?: string;
  icon?: string;
};

function ComponentHeader(props: ComponentHeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="p-3 pt-0">
      <div
        className={`text-body-secondary
       ${styles.componentHeader}`}
      >
        <div className="d-flex ">
          <div className={styles.componentIcon}>
            <i className={`fa-solid ${props.icon}`} />
          </div>
          <div className={`d-flex align-items-center ${styles.componentType}`}>
            <p className="mb-0">{t('designer.components.' + props.name)}</p>
          </div>
        </div>
      </div>
      <hr className="m-0" />
    </div>
  );
}

export default ComponentHeader;
