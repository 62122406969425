import React from 'react';
import styles from './styles.module.css';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { useDispatch, useSelector } from 'react-redux';
import { updateAction } from '../../../store/events/actions/update_action';
import { FunctionEditorState } from '../../../store/types/function_editor_state';

function Checkbox({ label, actionUuid, dataKey }: ActionInputProps) {
  const checked = useSelector(
    (state: FunctionEditorState) => state.actions[actionUuid]?.data[dataKey]
  ) as boolean;
  const dispatch = useDispatch();

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateAction(actionUuid, dataKey, e.target.checked));
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <span className={styles.ActionInputLabel}>{label}</span>
      <label className={styles.switch} id={`${dataKey.replace(' ', '')}Check`}>
        <input checked={checked} className={styles.input} type="checkbox" onChange={handleCheck} />
        <span
          className={`${styles.slider} ${styles.round} ${
            checked ? 'bg-primary' : 'bg-body-secondary'
          }`}
        ></span>
      </label>
    </div>
  );
}

export default Checkbox;
