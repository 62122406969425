import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { sleep } from '../../utils/utils';
import styles from './style.module.css';
import Icon from '../icon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

export type SidebarSelectionProps = {
  selectedTitle: string | null;
  selectedIcon: string | null;
  selectedDescription: string | null;
  updateDescription?: (newDescription: string) => void;
};

function SidebarSelection(props: SidebarSelectionProps) {
  const [toggleEditDescription, setToggleEditDescription] = useState(false);
  const [description, setDescription] = useState('');

  function handleUpdateDescription(event: ChangeEvent<any>) {
    if (!props.updateDescription) return setToggleEditDescription(false);
    event.preventDefault();
    event.stopPropagation();
    setToggleEditDescription(false);
    props.updateDescription(event.target.value ? event.target.value : '');
  }

  useEffect(() => {
    props.selectedDescription ? setDescription(props.selectedDescription) : setDescription('');
  }, [props.selectedDescription]);

  function blockEventListener(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <div
      className={styles.s}
      onClick={(event) => {
        blockEventListener(event);
      }}
    >
      <div className={styles.sidebarHeader}>
        <div className={styles.topContent}>
          <div className={`text-body ${styles.icon}`}>
            {props.selectedIcon ? <Icon iconName={props.selectedIcon}></Icon> : null}
          </div>
          <div id="nameField" className={`text-body ${styles.title}`}>
            {props.selectedTitle ? props.selectedTitle : null}
          </div>
        </div>

        <div id="descriptionField" className={`text-body-secondary ${styles.bottomContent}`}>
          {toggleEditDescription ? (
            <input
              id="tableNameInput"
              type="text"
              value={description}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === 'Escape') {
                  handleUpdateDescription(event);
                }
              }}
              onBlur={(event) => {
                handleUpdateDescription(event);
              }}
            />
          ) : (
            <>
              <p
                onDoubleClick={async () => {
                  if (!props.updateDescription) return;
                  setToggleEditDescription(true);
                  await sleep(110);
                  document.getElementById('tableNameInput')?.focus();
                }}
                style={{ marginBottom: '0px', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {props.selectedDescription ? props.selectedDescription : ''}
              </p>
              <div
                className={styles.editIcon}
                data-toggle="tooltip"
                title="Double click to edit table description"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (!props.updateDescription) return;
                  setToggleEditDescription(true);
                  await sleep(110);
                  document.getElementById('tableNameInput')?.focus();
                }}
              >
                <Icon iconName="pencil-square"></Icon>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(SidebarSelection);
