import { CustomComponent } from '../types';
import { CustomComponentSchema } from './schemas';

export const API_URL = process.env.REACT_APP_API_URL;

export class CustomComponentRepo {
  private buildSchema(customComponent: CustomComponent) {
    const customComponentSchema: CustomComponentSchema = {
      name: customComponent.name
    };
    return customComponentSchema;
  }
  /**
   * Fetch all custom components for a specific application.
   */
  async getCustomComponents(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${appId}/custom-components`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CustomComponent[]>;
      throw new Error('Something went wrong while trying to fetch custom components.');
    });
  }

  async createEmptyCustomComponent(customComponent: any, appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(customComponent)
    };

    const url = `${API_URL}/apps/${appId}/custom-components`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CustomComponent>;
      throw new Error('Something went wrong while trying to create custom component.');
    });
  }

  /**
   * Fetch a single custom component template.
   */
  async getCustomComponent(customComponentId: string, moduleId: string, IsTemplate: boolean) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/module/${moduleId}/custom-components/${customComponentId}?template=${IsTemplate}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CustomComponent>;
      throw new Error('Something went wrong while trying to fetch custom component.');
    });
  }

  async updateCustomComponent(customComponentId: string, updatedCustomComponent: CustomComponent) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const customComponentSchema: CustomComponentSchema = this.buildSchema(updatedCustomComponent);

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(customComponentSchema)
    };

    const url = `${API_URL}/custom-components/${customComponentId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<CustomComponent>;
      throw new Error('Something went wrong while trying to update custom component.');
    });
  }

  async deleteCustomComponent(customComponentId: string) {
    const options: RequestInit = {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/custom-components/${customComponentId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response;
      throw new Error('Something went wrong while trying to delete custom component.');
    });
  }
}
