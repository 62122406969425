import React from 'react';

function EndCycleIcon() {
  return (
    <span className="fa-layers fa-fw" style={{ whiteSpace: 'nowrap' }}>
      <i
        className="fas fa-chevron-left text-secondary-emphasis"
        data-fa-transform="shrink-5 down-4 right-4"
      ></i>
      <label className={'text-secondary-emphasis text-lg-end'} style={{ fontSize: '1.2rem' }}>
        /EndFor
      </label>
      <i
        className="fas fa-chevron-right text-secondary-emphasis"
        data-fa-transform="shrink-5 down-4 right-4"
      ></i>
    </span>
  );
}

export default EndCycleIcon;
