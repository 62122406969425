import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { updateAction } from '../../../store/events/actions/update_action';
import Dropdown from '../../components/dropdown';
import React, { useEffect, useState } from 'react';
import useFetchEnums from '../../utils/useFetchEnums';

function EnumInput({ placeholder, label, actionUuid, dataKey }: ActionInputProps) {
  const enums = useFetchEnums();
  const action = useSelector((state: FunctionEditorState) => state.actions[actionUuid]);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!action) return;
    if (!action.data[dataKey]) return;
    setValue(action.data[dataKey].value);
  }, [actionUuid, dataKey]);

  const handleChange = (value: any) => {
    dispatch(updateAction(actionUuid, dataKey, value));
  };

  const listEnums = () => {
    return enums.map((e) => ({ name: e.content.data.name, uuid: e.uuid }));
  };

  const findValueInEnumsList = () => {
    const selectedEnum = enums.filter((e) => e.uuid === value)[0];
    return { uuid: selectedEnum.uuid, name: selectedEnum.content.data.name };
  };

  return (
    <Dropdown
      items={listEnums()}
      placeholder={placeholder}
      label={label}
      value={findValueInEnumsList()}
      handleChange={handleChange}
    />
  );
}

export default EnumInput;
