import { ControllersRepository } from '../repos';
import { ControllerResponse } from '../repos/schemas/Controllers';
import { Controller } from '../types';

export class ControllersServ {
  async getController(controllerId: string): Promise<ControllerResponse> {
    return await ControllersRepository.getController(controllerId);
  }

  async getControllers(moduleId: string, name?: string): Promise<ControllerResponse[]> {
    return await ControllersRepository.getControllers(moduleId, name);
  }

  async createController(moduleId: string, controller: Controller): Promise<ControllerResponse> {
    return await ControllersRepository.createController(moduleId, controller);
  }

  async deleteController(controllerId: string) {
    await ControllersRepository.deleteController(controllerId);
  }

  async updateController(
    controllerId: string,
    controller: Controller
  ): Promise<ControllerResponse> {
    return await ControllersRepository.updateController(controllerId, controller);
  }

  async changeFolder(controllerId: string, folderId: string) {
    return await ControllersRepository.changeFolder(controllerId, folderId);
  }
}
