import {
  cascadeType,
  ComponentMap,
  externalColumn,
  externalRelationshipInfo,
  ExternalTableIndexColumn,
  fetchType,
  Relationship,
  TableUUID
} from '../../../../../../types';
import { v4 as uuidv4 } from 'uuid';
import { ConnectionCoordinate, RelationshipTypes } from '../../../../../frames/table';
import { makeRelationship } from '../../../../../../../../routes/studio/data/elements/factory';
import { addExternalRelationshipColumn, addRelationship } from '../../../../../store/actions/root';
import { useDispatch } from 'react-redux';
import useFetchAvailableTables from './useFetchAvailableTables';

type useMakeNewRelationshipProps = {
  moduleID: string;
  fromTableID: TableUUID;
  toTableID: TableUUID;
  pkColumns: ExternalTableIndexColumn[];
  selectedExternalModule: number;
  selectedExternalTableIndex: number;
  selectedExternalTable: number;
};

/**
 * Create the foreign key column, create the relationship and add on the context
 * @param moduleID
 * @param fromTableID
 * @param toTableID
 * @param pkColumns
 * @param selectedExternalModule
 * @param selectedExternalTable
 * @param selectedExternalTableIndex
 */
export default function useMakeNewRelationship({
  moduleID,
  fromTableID,
  toTableID,
  pkColumns,
  selectedExternalModule,
  selectedExternalTable,
  selectedExternalTableIndex
}: useMakeNewRelationshipProps) {
  const { availableTables } = useFetchAvailableTables(fromTableID, moduleID);
  const dispatch = useDispatch();

  function createNewRelationship() {
    if (!fromTableID || !toTableID) return;

    const { components, fkColumns } = makeFK();
    const info = makeRelationshipObject();
    if (!info) return;
    const relationship = makeNewRelationship(components, info);

    dispatch(addRelationship(relationship));
    dispatch(addExternalRelationshipColumn(toTableID, fkColumns));
  }

  function makeFK() {
    // create a column as a foreign key on target table, and receive its identification key
    const components: ComponentMap = {};
    const fkColumns: externalColumn[] = [];

    pkColumns.map((fromColumn) => {
      const newFkUuid = uuidv4();
      components[newFkUuid] = fromColumn.columnId;
      fkColumns.push({ id: newFkUuid, name: fromColumn.columnName + '_FK', type: 'BIGINT' });
      return components;
    });

    return { components, fkColumns };
  }

  function makeRelationshipObject() {
    let info: externalRelationshipInfo | null = null;

    if (availableTables) {
      info = {
        moduleUuid: selectedExternalModule.toString(),
        moduleName: availableTables.modules[selectedExternalModule].name,
        tableName:
          availableTables.modules[selectedExternalModule].tables[selectedExternalTable].name,
        columnNames: availableTables.modules[selectedExternalModule].tables[
          selectedExternalTable
        ].indexes[selectedExternalTableIndex].columns.map((column) => {
          return column.columnName;
        }),
        readOnly: false
      };
    }

    return info;
  }

  function makeNewRelationship(components: ComponentMap, info: externalRelationshipInfo) {
    const coordinatePoints: ConnectionCoordinate = {
      originPoint: '4',
      targetPoint: '7'
    };

    const relationship: Relationship = Object.assign(
      {},
      makeRelationship(
        fromTableID,
        info.tableName,
        toTableID,
        components as ComponentMap,
        [cascadeType.PERSIST, cascadeType.MERGE, cascadeType.REMOVE] as cascadeType[],
        coordinatePoints,
        RelationshipTypes['MANY2ONE'],
        true,
        fetchType['LAZY'] as fetchType,
        '',
        info ?? undefined
      )
    );

    return relationship;
  }

  return createNewRelationship;
}
