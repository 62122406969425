import React from 'react';
import { Col } from 'react-bootstrap';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { PricingTable } from 'modules/payments/components/subscription_plan/pricing_table';
import useTitle from 'hooks/useTitle';

export const SUBSCRIPTION_INFO_TEMPORARY = {};

function Billing() {
  const { t } = useTranslation();
  useTitle(t('tab.settingsAcc.biling'));

  return (
    <>
      <Col className={styles.Section}>
        <Col className={styles.Section}>
          <div className={`border-bottom ${styles.SectionTitleDiv}`}>
            <span className={`text-body-emphasis ${styles.SectionTitle}`}>
              {t('billing.AvailableProducts')}
            </span>
          </div>

          <div className={`${styles.PricingCardsWrapper} row`}>
            <PricingTable context="SETTINGS_PAGE" />
          </div>
        </Col>
      </Col>
    </>
  );
}

export default Billing;
