import { Action } from 'redux';
import { FunctionsState } from '../../types/functions';

export const CHANGE_PARAM_ORDER = 'CHANGE_PARAM_ORDER';

export interface ChangeParamOrderAction extends Action {
  type: 'CHANGE_PARAM_ORDER';
  payload: {
    functionUuid: string;
    originUuid: string;
    targetUuid: string;
  };
}

export const changeParamOrder = (functionUuid: string, originUuid: string, targetUuid: string) => ({
  type: CHANGE_PARAM_ORDER,
  payload: { functionUuid, originUuid, targetUuid }
});

export function doChangeParamOrder(
  state: FunctionsState,
  action: ChangeParamOrderAction
): FunctionsState {
  const { functionUuid, originUuid, targetUuid } = action.payload;
  const originIndex = state[functionUuid]?.parameters.indexOf(originUuid);
  const targetIndex = state[functionUuid]?.parameters.indexOf(targetUuid);

  state[functionUuid]?.parameters.splice(originIndex, 1);
  state[functionUuid]?.parameters.splice(targetIndex, 0, originUuid);

  return state;
}
