import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { SchemaObject } from 'modules/logic_builder/types';
import { useSelector } from 'react-redux';
import ObjectItemSelector from './ObjectItemSelector';

interface NestedObjectItemMakerProps {
  id: any;
  value: any;
  parentObjectItem: any;
  onChange?: (value: any, key: any) => any;
  typeFilter: string[];
  listFilter: boolean;
}

function NestedObjectItemMaker({
  id,
  value,
  parentObjectItem,
  typeFilter,
  listFilter,
  onChange
}: NestedObjectItemMakerProps) {
  const [currentObject, setCurrentObject] = useState<SchemaObject>();
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const [selectedObjectItem, setSelectedObjectItem] = useState<any>();

  useEffect(() => {
    if (parentObjectItem && parentObjectItem.object && parentObjectItem.object.id)
      setCurrentObject(objects[parentObjectItem.object.id]);
  }, [parentObjectItem, objects]);

  useEffect(() => {
    if (!value || !value.nestedObjects || !parentObjectItem || !currentObject) return;

    const objectItemUuid = value.nestedObjects[parentObjectItem.uuid]?.objectItemUuid;
    if (!objectItemUuid) return;

    const objectItem = currentObject.objectItems?.find((item) => item.uuid === objectItemUuid);
    setSelectedObjectItem(objectItem);
  }, [value, parentObjectItem, currentObject]);

  const handleChangeNestedItem = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const newNestedObjectItems = {
        ...(value?.nestedObjects || {}),
        [parentObjectItem.uuid]: {
          objectUuid: parentObjectItem.object.id ?? '',
          objectItemUuid: e.target.value
        }
      };

      const updatedNestedObjectPath = value?.nestedObjects[parentObjectItem.uuid]
        ? removeObjectDependencies(
            newNestedObjectItems,
            value.nestedObjects[parentObjectItem.uuid].objectItemUuid
          )
        : newNestedObjectItems;

      onChange?.({ ...value, nestedObjects: updatedNestedObjectPath }, id);
    },
    [value, parentObjectItem, onChange, id]
  );

  function removeObjectDependencies(nestedObjectItems: any, rootObjectItemUuid: string) {
    const nestedObjectItemsCopy = { ...nestedObjectItems };

    let objectItemUuid = rootObjectItemUuid;
    while (objectItemUuid && nestedObjectItemsCopy[objectItemUuid]) {
      const objectItemChild = nestedObjectItemsCopy[objectItemUuid].objectItemUuid;
      delete nestedObjectItemsCopy[objectItemUuid];
      objectItemUuid = objectItemChild;
    }

    return nestedObjectItemsCopy;
  }

  const getCurrentNestedObject = useCallback(() => {
    return parentObjectItem?.uuid && value?.nestedObjects?.[parentObjectItem.uuid]?.objectItemUuid;
  }, [parentObjectItem, value]);

  return (
    <>
      {currentObject && (
        <ObjectItemSelector
          value={value && getCurrentNestedObject() ? getCurrentNestedObject() : ''}
          onChange={handleChangeNestedItem}
          listFilter={listFilter}
          typeFilter={typeFilter}
          currentObject={currentObject}
        />
      )}

      {/* Recursive call */}
      {value && selectedObjectItem && selectedObjectItem.object && (
        <NestedObjectItemMaker
          id={id}
          value={value}
          parentObjectItem={selectedObjectItem}
          onChange={onChange}
          typeFilter={typeFilter}
          listFilter={listFilter}
        />
      )}
    </>
  );
}

export default NestedObjectItemMaker;
