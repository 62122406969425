import * as React from 'react';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';

type Props = {
  execute: () => void;
};

export default function EditButton(props: Props) {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.execute();
  };

  return (
    <div
      id="editButton"
      className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
      onClick={(ev) => handleClick(ev)}
    >
      <Icon iconName="pen-to-square" extraProps="text-secondary h7" />
    </div>
  );
}
