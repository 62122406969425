import {
  Action,
  FunctionParameter,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import * as React from 'react';
import { CreateActionType, SetVariableActionType, ARGUMENT_TYPES } from '../../types';
import ArgumentPicker from '../ArgumentPicker';
import { Form } from 'react-bootstrap';

type Props = {
  newAction: Action<SetVariableActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<SetVariableActionType>>>;
  objects: ObjectSimple[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
};

export default function RenderSetVariableInputs(props: Props) {
  return (
    <div>
      <Form.Group>
        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Select Source"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.source.value = value;
              auxVal.data.arguments.source.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.source.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={props.newAction.data.arguments.source.type}
        />

        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={true}
          allowVars={true}
          allowObject={false}
          label="Select Target"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.data.arguments.target.value = value;
              auxVal.data.arguments.target.type = typeChosen;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.data.arguments.target.value}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={props.newAction.data.arguments.target.type}
        />

        <ArgumentPicker
          allowDataTypes={[]}
          allowFixed={false}
          allowParams={false}
          allowVars={true}
          allowObject={false}
          label="Select Variable to return"
          onChange={(value, typeChosen) => {
            props.onChange((currentVal) => {
              const auxVal = { ...currentVal };
              auxVal.returnVariableUuid = value;
              return auxVal; // override the action
            });
          }}
          value={props.newAction.returnVariableUuid}
          variables={props.variables}
          params={props.parameters}
          objects={props.objects}
          onlyList={false}
          currentType={ARGUMENT_TYPES.VAR}
        />
      </Form.Group>
    </div>
  );
}
