import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { LogicTab } from './logic_tab';
import { VariablesTab } from './variables_tab';
import { FunctionService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import { FunctionExtended, FunctionVariable } from 'modules/logic_builder/types';
import Confirmation from 'web_ui/confirmation';
import { useParams } from 'react-router-dom';
import { ReturnTab } from './return_tab';
import { ActionsTab } from './actions_tab';
import { EnumFrame } from 'modules/modeler/types';
import { DataService } from 'modules/modeler/services';
import { useTranslation } from 'react-i18next';
import { functionNameRegex } from '../../../../../utils/regex';

type FunctionEditorDialogProps = {
  show: boolean;
  onClose: () => void;
  functionId: string;
};

export function FunctionEditorDialog(props: FunctionEditorDialogProps) {
  const [selectedTab, setSelectedTab] = useState('definition');
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [functionExtended, setFunctionExtended] = useState<FunctionExtended>();
  const [variablesList, setVariablesList] = useState<FunctionVariable[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const { fetchFunctions } = useContext(LogicBuilderContext);
  const { module_id } = useParams();
  const [enumsList, setEnumsList] = useState<EnumFrame[]>([]);

  const fetchEnums = useCallback(async (moduleId: string) => {
    await DataService.getEnums(moduleId).then((enums) => {
      setEnumsList(enums);
    });
  }, []);

  useEffect(() => {
    if (!module_id) return;
    fetchEnums(module_id);
  }, [fetchEnums, module_id]);

  const fetchFunction = useCallback(async () => {
    await FunctionService.getFunction(props.functionId).then((fetchedFunction) => {
      setFunctionExtended(fetchedFunction);
    });
  }, [props.functionId]);

  useEffect(() => {
    fetchFunction();
  }, [fetchFunction, props.functionId]);

  async function onDelete() {
    if (isLoading) return;
    if (!module_id) return;

    setIsLoading(true);
    await FunctionService.deleteFunction(props.functionId)
      .then(() => {
        fetchFunctions(module_id);
        setShowConfirmationDialog(false);
        props.onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (isLoading) return;
    if (!functionExtended || !functionExtended.uuid || !module_id) return;

    setIsLoading(true);
    await FunctionService.updateFunction(functionExtended.uuid, functionExtended)
      .then(() => {
        fetchFunctions(module_id);
        props.onClose();
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const fetchVariables = useCallback(async () => {
    await FunctionService.getFunctionVariables(props.functionId).then((variables) => {
      setVariablesList(variables);
    });
  }, [props.functionId]);

  useEffect(() => {
    fetchVariables();
  }, [fetchVariables, functionExtended]);

  useEffect(() => {
    if (!props.show) return;

    setIsNameInvalid(false);
    setSelectedTab('definition');
  }, [props.show]);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.onClose();
        }}
        size="lg"
        centered
      >
        <Form onSubmit={onSubmit} className={styles.MainWrapperForm}>
          <Modal.Header closeButton>
            <Modal.Title>Function editor</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'hidden' }}>
            {/* Form */}
            <div>
              <Form.Group as={Row} className="mb-3" controlId="formName">
                <Form.Label column sm={2}>
                  Name
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={functionExtended?.name ?? ''}
                    isInvalid={isNameInvalid}
                    required
                    onChange={(e) => {
                      setFunctionExtended({
                        ...functionExtended,
                        name: e.target.value
                      } as FunctionExtended);
                      setIsNameInvalid(!functionNameRegex.test(e.target.value));
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please inform a valid function name
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`mb-3 ${styles.Gap}`} controlId="formDescription">
                <Form.Label column sm={2}>
                  Description
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    maxLength={64}
                    value={functionExtended?.description ?? ''}
                    onChange={(e) => {
                      setFunctionExtended({
                        ...functionExtended,
                        description: e.target.value
                      } as FunctionExtended);
                    }}
                  />
                </Col>
              </Form.Group>
            </div>

            {/* Logic/Variables/Other/Examples tabs */}
            <div className={styles.TabsWrapper}>
              <Tabs
                id="functionEditorTabs"
                activeKey={selectedTab}
                onSelect={(k) => setSelectedTab(k ?? 'definition')}
              >
                <Tab eventKey="definition" title="Definition" className={styles.TabWrapper}>
                  {functionExtended && (
                    <>
                      <div style={{ marginBottom: '2rem' }}>
                        <LogicTab
                          enumsList={enumsList}
                          functionExtended={functionExtended}
                          setFunctionExtended={(fn: FunctionExtended) => setFunctionExtended(fn)}
                        />
                      </div>
                      <div style={{ marginBottom: '2rem' }}>
                        <VariablesTab
                          enumsList={enumsList}
                          variablesList={variablesList}
                          fetchVariables={fetchVariables}
                          functionId={props.functionId}
                        />
                      </div>
                      <div>
                        <ReturnTab
                          variablesList={variablesList}
                          functionExtended={functionExtended}
                          setFunctionExtended={(fn: FunctionExtended) => setFunctionExtended(fn)}
                        />
                      </div>
                    </>
                  )}
                </Tab>
                {functionExtended && (
                  <Tab
                    eventKey="implementation"
                    title="Implementation"
                    className={styles.TabWrapper}
                  >
                    <ActionsTab functionExtended={functionExtended} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </Modal.Body>

          {/* Footer buttons */}
          <Modal.Footer>
            <Button
              id="deleteButton"
              variant="danger"
              onClick={() => setShowConfirmationDialog(true)}
            >
              {t('logicBuilder.createFunctionDialog.delete')}
            </Button>
            <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
              {t('logicBuilder.createFunctionDialog.cancel')}
            </Button>
            <Button
              id="saveButton"
              variant="primary"
              type="submit"
              disabled={isNameInvalid || !functionExtended?.name}
            >
              {t('logicBuilder.createFunctionDialog.save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.function')} (${functionExtended?.name})`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
