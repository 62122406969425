import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../store/types/function_editor_state';
import { Form } from 'react-bootstrap';
import { updateFunction } from '../../store/events/functions/update_function';
import { useTranslation } from 'react-i18next';

function FunctionDescription() {
  const state = useSelector((state: FunctionEditorState) => state);
  const functionId = state?.editor?.functionId;
  const func = state.functions[functionId];
  const name = func?.name ?? '';
  const desc = func?.desc ?? '';
  const crudType = func?.crudType ?? 'null';
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!functionId || !name || e.target.value === desc) return;
    e.preventDefault();
    dispatch(updateFunction(functionId, name, e.target.value, crudType));
  };

  return (
    <div>
      <h3 id="titleField">
        <span className={'fa fa-florin-sign me-1'} />
        {name ?? ''}
      </h3>
      <Form.Control
        id={'descriptionField'}
        as={'textarea'}
        placeholder={t('Description') ?? ''}
        value={desc}
        onChange={handleChangeDescription}
      />
      <hr />
    </div>
  );
}

export default FunctionDescription;
