import React, { useContext, useMemo } from 'react';
import { BackgroundTypes } from 'modules/modeler/types';
import { WorkboardContext } from '..';
import styles from './styles.module.css';

export interface BackgroundProps {
  type?: BackgroundTypes; // Points or line, ..., etc
  gap?: number; // space between each marker
  color?: string; // Background color
  size?: number; // size of points, lines ..., etc
}

const defaultColors = {
  [BackgroundTypes.Dots]: '#54ff89',
  [BackgroundTypes.Lines]: '#54ff89'
};

export const createGridDotsPath = (size: number, fill: string): React.ReactElement => {
  // fill={`url(#${fillClass})`}
  const fillStyle = {
    // fill: 'rgba(var(--bs-body-color-rgb),var(--bs-text-opacity))!important;' // URL reference to the gradient or pattern
    fill: 'var(--bs-success-text-emphasis)'
  };
  return <circle cx={size} cy={size} r={size} style={fillStyle} />;
};

function Background(props: BackgroundProps) {
  const workboard = useContext(WorkboardContext);
  const gap = props.gap ? props.gap : 15;
  const color = props.color ? props.color : defaultColors['dots'];
  const size = props.size ? props.size : 0.5;
  const sizeGap = gap * workboard.scale;
  const patternId = useMemo(() => `pattern-${Math.floor(Math.random() * 100000)}`, []);
  const currentTheme = JSON.parse(localStorage.getItem('theme') || '{}');
  const newColor = currentTheme ? currentTheme.colors.markerColorBackground : color;
  const path = createGridDotsPath(size * workboard.scale, newColor);

  return (
    // <BackgroundSVG>
    <svg className={`bg-body-tertiary ${styles.workboardBackground}`}>
      <pattern
        id={patternId}
        x={1}
        y={1}
        width={sizeGap}
        height={sizeGap}
        patternUnits="userSpaceOnUse"
      >
        {path}
      </pattern>
      <rect x="0" y="0" width="100%" height="100%" fill={`url(#${patternId})`} />
    </svg>
    // {/* </BackgroundSVG> */}
  );
}

export default Background;
