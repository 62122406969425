import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import { InterfaceStudioState } from '../store';
import { COMPONENTS_LIST } from './index';
import { ComponentUUID, ViewUUID } from '../../types';

type ComponentsRendererProps = {
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  // custom_uuid is being used to render the custom component.
  custom_uuid?: ComponentUUID;
  // new_custom_uuid is being used to delete custom components.
  new_custom_uuid?: ComponentUUID;
  section?: string;
};

function ComponentsRenderer(props: ComponentsRendererProps) {
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const key = `${props.parentUUID}${props.section ? '_' + props.section : ''}`;

  const childs = props.parentUUID ? links[key] : links[props.viewUUID];

  return (
    <>
      {childs &&
        childs.map((id) => {
          // TODO: define 'element' type
          const element = components[id];
          if (!element) return null;

          const ElementComponent = COMPONENTS_LIST[element.type] as ComponentType<any>;
          return (
            ElementComponent && (
              <ElementComponent
                // These props and ComponentWrapper's props (ComponentProps)
                // and LayoutComponent type should match.
                // TODO: define types if possible
                key={element.uuid}
                viewUUID={props.viewUUID}
                parentUUID={props.parentUUID}
                custom_uuid={props.custom_uuid}
                new_custom_uuid={props.new_custom_uuid}
                section={props.section}
                {...element}
              />
            )
          );
        })}
    </>
  );
}

export default ComponentsRenderer;
