import React, { useCallback } from 'react';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { ReturnType } from '../../../store/types/functions';
import { updateAction } from '../../../store/events/actions/update_action';
import useFetchDataTypes from './useFetchDataTypes';
import { ComboboxInput } from 'web_ui/combobox';

function DataTypeInput({
  placeholder,
  label,
  actionUuid,
  dataKey,
  options,
  isFromSideBar
}: ActionInputProps) {
  const dispatch = useDispatch();
  const data = useSelector(
    (state: FunctionEditorState) => state.actions[actionUuid]?.data[dataKey]
  );
  const { fetchedTypes } = useFetchDataTypes(Object.values(options.dataTypes));

  const findSelectedItem = useCallback((): ReturnType | undefined => {
    if (!data) return;

    return fetchedTypes.find((t) => {
      if (data.type === 'OBJECT') {
        return t.type === 'OBJECT' && t.objectUuid === data.objectUuid;
      } else if (data.type === 'ENUM') {
        return t.type === 'ENUM' && t.enumUuid === data.enumUuid;
      } else {
        return t.type === data.type;
      }
    });
  }, [data, fetchedTypes]);

  const getDisplayNameFromItem = useCallback((item: unknown) => {
    const returnType = item as ReturnType;
    return returnType.name ?? returnType.type;
  }, []);

  const handleSelectItem = (type: unknown) => {
    const selectedItem = type as ReturnType;
    const newType: ReturnType = { type: selectedItem.type };
    if (selectedItem.objectUuid) {
      newType.objectUuid = selectedItem.objectUuid;
    }
    if (selectedItem.enumUuid) {
      newType.enumUuid = selectedItem.enumUuid;
    }
    dispatch(updateAction(actionUuid, dataKey, newType));
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center'
        }}
      >
        <div style={{ marginRight: '1rem' }}>{label}</div>
        <ComboboxInput
          items={fetchedTypes}
          selectedItem={findSelectedItem()}
          handleChangeSelectedItem={handleSelectItem}
          getDisplayNameFromItem={getDisplayNameFromItem}
          isFromSideBar={isFromSideBar}
        />
      </div>
    </>
  );
}

export default DataTypeInput;
