import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import React from 'react';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { FunctionVariableType } from '../../../../../web_ui/function_editor/store/types/variables';
import { FunctionParameterType } from '../../../../../web_ui/function_editor/store/types/parameters';
import { ObjectItemType } from '../../../../../web_ui/function_editor/store/types/object_items';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { FRONTEND_VARIABLE_TYPES } from '../../../types';
import RestIcon from './rest_icon';
import { t } from 'i18next';

function filterByListDataType(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: keyof typeof FRONTEND_VARIABLE_TYPES
): boolean {
  const list = state.actions[actionUuid].data?.list;
  if (!list) return false;
  let listObject: FunctionVariableType | FunctionParameterType | ObjectItemType;
  const dataTypeUp = dataType?.toUpperCase();
  if (list.objectNode) {
    listObject = state.objects_items[list.objectNode];
    return dataTypeUp === listObject?.type?.toUpperCase();
  }
  listObject =
    list?.type === TYPE_PICKER_TYPES.VAR
      ? state.variables[list?.value]
      : state.parameters[list?.value];
  return dataTypeUp === listObject?.type?.toUpperCase() || dataTypeUp === 'OBJECT';
}

export const REST_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.REST,
  name: t('APICall'),
  description: 'Action to make a REST API call',
  color: 'warning',
  category: FRONTEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.FUNCTION,
  icon: <RestIcon />,
  uncollapsible: true,
  shouldHaveBackend: true,
  inlineInputs: [],
  inputs: [
    {
      label: '',
      placeholder: '',
      type: ACTION_INPUTS_TYPES.ENDPOINT_PICKER,
      key: 'endpoint'
    }
  ]
};
