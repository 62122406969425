import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import Dropdown, { ItemType } from '../../components/dropdown';
import { changeReturn } from '../../../store/events/actions/change_return';

function ReturnInput({ placeholder, label, actionUuid, isFromSideBar }: ActionInputProps) {
  const state = useSelector((state: FunctionEditorState) => state);
  const variables = state.variables;
  const value = state?.actions[actionUuid]?.returnVariableUuid;
  const dispatch = useDispatch();

  const noneVariable = {
    uuid: '',
    name: 'None'
  } as ItemType;
  const vars = [noneVariable];
  Object.values(variables).forEach((variable) =>
    vars.push({ uuid: variable.uuid, name: variable.name })
  );

  const handleChange = (newValue: string) => {
    dispatch(changeReturn(actionUuid, newValue));
  };

  const getSelectedItem = () => {
    const findItem = vars.find((v) => {
      return v.uuid === value;
    });
    if (findItem) return findItem;
    return vars[0];
  };

  return (
    <Dropdown
      items={vars}
      placeholder={placeholder}
      label={label}
      value={getSelectedItem()}
      handleChange={handleChange}
      isFromSideBar={isFromSideBar}
    />
  );
}

export default ReturnInput;
