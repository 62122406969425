import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ViewManifest } from '../../../types';
import { validateDescriptionInputsForDesigner } from 'utils/inputValidation';

export const MODAL_MANIFEST: ViewManifest = {
  type: 'MODAL',
  name: 'Modal',
  description: 'Used to create quick action modals.',
  icon: 'fa-window-restore ',
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Title',
      key: 'title'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Description',
      key: 'description',
      validation: (input: string) => validateDescriptionInputsForDesigner(input),
      errorMessages: {
        EXCEEDS_MAX_LENGTH: 'inputValidationErrorMessages.ViewDescriptionExceedsMaxLength'
      }
    },
    {
      controlType: ControlsTypes.MODAL_SIZE,
      controlLabel: 'Size',
      key: 'size'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Background',
      key: 'backgroundColor'
    }
  ]
};
