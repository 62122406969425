import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { createCustomComponent, createDetachedCustomComponent } from '../../store/actions/root';
import { ComponentUUID } from 'modules/designer/types';
import { useTranslation } from 'react-i18next';

export type ConvertCustomProps = {
  show: boolean;
  onClose: () => void;
  componentUUID: ComponentUUID;
};

export default function ConvertCustomModal(props: ConvertCustomProps) {
  const [customName, setCustomName] = useState('');
  const [customDescription, setCustomDescription] = useState('');
  const [isHierarchy, setIsHierarchy] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.show) return;
    setCustomName('');
    setCustomDescription('');
    setIsHierarchy(false);
  }, [props.show]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      const customComponentUUID = uuidv4();
      const custom_uuid = uuidv4();
      // Create a new custom component and convert the current component into a
      // custom component.
      if (isHierarchy) {
        dispatch(
          createCustomComponent(
            props.componentUUID,
            customComponentUUID,
            custom_uuid,
            customName,
            customDescription
          )
        );
        // Create a new custom component based on the converted component,
        // but doesn't convert it.
      } else {
        dispatch(createDetachedCustomComponent(props.componentUUID, customName, customDescription));
      }
      props.onClose();
    }
  };

  return (
    <Modal centered show={props.show} onHide={props.onClose}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t('designer.customComponents.ConvertToCustomComponent')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>{t('designer.customComponents.Name')}</Form.Label>
            <Form.Control
              type="text"
              id="CustomNameInput"
              onChange={(e) => setCustomName(e.target.value)}
              value={customName}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('designer.customComponents.Description')}</Form.Label>
            <Form.Control
              type="text"
              id="CustomDescriptionInput"
              onChange={(e) => setCustomDescription(e.target.value)}
              value={customDescription}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              id="Input"
              label={t('designer.customComponents.HierarchyCheckbox')}
              onChange={(e) => setIsHierarchy(e.target.checked)}
              checked={isHierarchy}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button id="saveButton" type="submit">
            {t('designer.customComponents.Convert')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
