import React from 'react';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import ToggleModalIcon from './toggle_modal_icon';
import { FRONTEND_VARIABLE_TYPES } from '../../../types';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { t } from 'i18next';

export const TOGGLE_MODAL_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.TOGGLE_MODAL,
  name: t('ToggleModal'),
  description: 'Action to open/close a modal',
  color: 'warning',
  category: FRONTEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.FUNCTION,
  uncollapsible: true,
  icon: <ToggleModalIcon />,
  inlineInputs: [],
  inputs: [
    {
      label: t('Modal'),
      placeholder: `${t('SelectModal')}`,
      key: 'modal',
      type: ACTION_INPUTS_TYPES.VIEW_PICKER,
      options: { mode: 'modal' }
    },
    {
      label: t('ToggleVariable'),
      placeholder: `${t('SelectAGlobalVariable')}`,
      key: 'toggleVariable',
      type: ACTION_INPUTS_TYPES.VARIABLES_INPUT,
      options: { dataType: FRONTEND_VARIABLE_TYPES.BOOLEAN, onlyGlobals: true }
    },
    {
      label: t('onHide'),
      placeholder: `${t('SelectAFunction')}`,
      key: 'function',
      type: ACTION_INPUTS_TYPES.FUNCTIONS_INPUT,
      options: {
        onlyGlobals: true,
        argumentTypes: [TYPE_PICKER_TYPES.FIXED, TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PROP]
      }
    }
  ]
};
