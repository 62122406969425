import Transform, { Identity } from './transform';
import Point from './point';

export default class Zoom {
  private elm: Element;
  private scaleExtent = [0, Infinity];
  private translateExtent = [
    [-Infinity, -Infinity],
    [Infinity, Infinity]
  ];
  private listeners: any = {};
  private hooks: any = {};
  private currentT = Identity;
  private mouse: Point[] = [];
  private wheelTimeout: NodeJS.Timeout | null = null;

  public previousMousePosition: { x: number; y: number } = { x: -1, y: -1 };

  constructor(elm: Element) {
    this.elm = elm;
    this.currentT.x = window.innerWidth / 2;
    this.elm.addEventListener('wheel', (e: any) => this.handleWheel.apply(this, [e]));
    this.elm.addEventListener('mousedown', (e: any) => this.handleMouseDown.apply(this, [e]));
  }

  setScaleExtent(min: number, max: number) {
    this.scaleExtent = [min, max];
  }

  setTranslateExtent(min_x: number, min_y: number, max_x: number, max_y: number) {
    this.translateExtent = [
      [min_x, min_y],
      [max_x, max_y]
    ];
  }

  on(event: string, listener: (t: Transform) => void) {
    this.listeners[event] = listener;
  }

  hook(event: string, listener: (e: any) => boolean) {
    this.hooks[event] = listener;
  }

  scaleBy(k: number) {
    const extent = [
      [0, 0],
      [this.elm.clientWidth, this.elm.clientHeight]
    ];
    const p0 = this.centroid(extent);
    const p1 = this.currentT.invert(p0);

    k = this.currentT.k * k;

    this.currentT = this.constrain(
      this.translate(this.scale(this.currentT, k), p0, p1),
      extent,
      this.translateExtent
    );
    this.listeners.zoom.apply(this, [this.currentT]);
  }

  setPosition(x: number, y: number) {
    this.currentT.x = x;
    this.currentT.y = y;
    this.currentT.k = 1;
    this.listeners.zoom.apply(this, [this.currentT]);
  }

  reset() {
    this.currentT = Identity;
    this.listeners.zoom.apply(this, [this.currentT]);
  }

  private handleWheel(e: any) {
    if (!e.ctrlKey) return;

    if (this.hooks.wheel && this.hooks.wheel.apply(this, [e]) === false) {
      return;
    }

    const delta = -e.deltaY * (e.deltaMode === 1 ? 0.05 : e.deltaMode ? 1 : 0.002);

    const p = this.pointer(e);
    const k = Math.max(
      this.scaleExtent[0],
      Math.min(this.scaleExtent[1], this.currentT.k * Math.pow(2, delta))
    );

    // If there were recent wheel events, reset the wheel idle timeout.
    if (this.wheelTimeout) {
      // If the mouse is in the same location as before, reuse it.
      if (this.mouse[0].x !== p.x || this.mouse[0].y !== p.y) {
        this.mouse[0] = p;
        this.mouse[1] = this.currentT.invert(this.mouse[0]);
      }
      clearTimeout(this.wheelTimeout);
    }
    // If this wheel event won’t trigger a transform change, ignore it.
    else if (this.currentT.k === k) {
      return;
    }
    // Otherwise, capture the mouse point and location at the start.
    else {
      this.mouse = [p, this.currentT.invert(p)];
    }

    this.wheelTimeout = setTimeout(() => {
      this.wheelTimeout = null;
    }, 150);

    const extent = [
      [0, 0],
      [this.elm.clientWidth, this.elm.clientHeight]
    ];

    this.currentT = this.constrain(
      this.translate(this.scale(this.currentT, k), this.mouse[0], this.mouse[1]),
      extent,
      this.translateExtent
    );
    this.listeners.zoom.apply(this, [this.currentT]);
  }

  private handleMouseDown(e: any) {
    if (this.hooks.mousedown && this.hooks.mousedown.apply(this, [e]) === false) {
      return;
    }

    const currentTarget = e.currentTarget;
    const p = this.pointer(e, currentTarget);
    const x0 = e.clientX;
    const y0 = e.clientY;

    this.mouse = [p, this.currentT.invert(p)];
    const extent = [
      [0, 0],
      [this.elm.clientWidth, this.elm.clientHeight]
    ];

    let moved = false;

    const mouseMoved = (ev: any) => {
      if (!moved) {
        const dx = ev.clientX - x0,
          dy = ev.clientY - y0;
        moved = dx * dx + dy * dy > 0;
      }

      this.mouse[0] = this.pointer(ev, currentTarget);
      this.currentT = this.constrain(
        this.translate(this.currentT, this.mouse[0], this.mouse[1]),
        extent,
        this.translateExtent
      );
      this.listeners.zoom.apply(this, [this.currentT]);
    };

    const mouseUp = () => {
      this.elm.removeEventListener('mousemove', mouseMoved);
      document.removeEventListener('mouseup', mouseUp);
    };

    this.elm.addEventListener('mousemove', mouseMoved);
    document.addEventListener('mouseup', mouseUp);
  }

  private pointer(event: any, node: any = null) {
    if (node === null) node = event.currentTarget;
    if (node) {
      if (node.getBoundingClientRect) {
        const rect = node.getBoundingClientRect();
        return new Point(
          event.clientX - rect.left - node.clientLeft,
          event.clientY - rect.top - node.clientTop
        );
      }
    }
    return new Point(event.pageX, event.pageY);
  }

  private centroid(extent: any) {
    return new Point((+extent[0][0] + +extent[1][0]) / 2, (+extent[0][1] + +extent[1][1]) / 2);
  }

  private scale(t: Transform, k: number) {
    k = Math.max(this.scaleExtent[0], Math.min(this.scaleExtent[1], k));
    return k === t.k ? t : new Transform(k, t.x, t.y);
  }

  private translate(t: Transform, p0: Point, p1: Point) {
    const x = p0.x - p1.x * t.k;
    const y = p0.y - p1.y * t.k;
    return x === t.x && y === t.y ? t : new Transform(t.k, x, y);
  }

  private constrain(transform: Transform, extent: any, translateExtent: any) {
    const dx0 = transform.invertX(extent[0][0]) - translateExtent[0][0],
      dx1 = transform.invertX(extent[1][0]) - translateExtent[1][0],
      dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1],
      dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];

    return transform.translate(
      dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
      dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
    );
  }
}
