import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

function TableHeader() {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <th className={'col-sm-2'}>#</th>
        <th className={'col-sm-4'}>
          {t('appResume.languages.Key')}
          <HelpPopover
            placement="top"
            helpBoxProps={{
              title: t('appResume.languages.IdentifierTip')!
            }}
          >
            <i className="fa-regular fa-circle-question" style={{ marginLeft: '0.3rem' }} />
          </HelpPopover>
        </th>
        <th className={'col-sm-4'}>
          {t('appResume.languages.Value')}{' '}
          <HelpPopover
            placement="top"
            helpBoxProps={{
              title: t('appResume.languages.IdentifierTip')!
            }}
          >
            <i className="fa-regular fa-circle-question" style={{ marginLeft: '0.3rem' }} />
          </HelpPopover>
        </th>
        <th className={'col-sm-2'}></th>
      </tr>
    </thead>
  );
}

export default TableHeader;
