import { FrontendPropertiesRepository } from '../repos';

export class FrontendPropertiesServ {
  async getFrontendPropertiesByCustomComponent(customComponent: string) {
    return await FrontendPropertiesRepository.getFrontendPropertiesByCustomComponent(
      customComponent
    );
  }

  async getFrontendPropertiesByModal(modal: string) {
    return await FrontendPropertiesRepository.getFrontendPropertiesByModal(modal);
  }
}
