import React from 'react';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import RunFunctionIcon from './run_function_icon';
import { t } from 'i18next';

export const RUN_FUNCTION_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.RUN_FUNCTION,
  name: t('RunFunction'),
  description: t('ActionToAdd'),
  color: 'warning',
  category: BACKEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.FUNCTION,
  icon: <RunFunctionIcon />,
  inlineInputs: [],
  inputs: [
    {
      label: t('RunFunction'),
      placeholder: `${t('SelectAFunction')}`,
      key: 'function',
      type: ACTION_INPUTS_TYPES.FUNCTIONS_INPUT
    },
    {
      label: t('ReturnVariable'),
      placeholder: `${t('ReturnVariable')}`,
      type: ACTION_INPUTS_TYPES.RETURN_INPUT
    }
  ]
};
