import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { EndpointExtended, METHODS, Method } from 'modules/logic_builder/types';
import { LogicTab } from './logic_tab';
import { SecurityTab } from './security_tab';
import { OtherTab } from './other_tab';
import { EndpointInstance } from '../../endpoints_list/endpoint_instance';
import { EndpointsService } from 'modules/logic_builder/services';
import { validatePath } from '../controller_creator_dialog';
import LogicBuilderContext from 'modules/logic_builder/store';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import { endpointNameRegex } from 'utils/regex';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import SessionContext from 'modules/auth/store';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import { getEndpointEditorActions } from 'web_ui/walkthrough/actions';
import { WalkthroughButton } from 'web_ui/walkthrough/walkthrough_button';
import { useClickOutsideEvent } from 'hooks/useClickOutside';
import { AppContext } from '../../../../project/store/app_context';

type EndpointEditorDialogProps = {
  show: boolean;
  onClose: () => void;
  endpointId: string;
};

export function EndpointEditorDialog(props: EndpointEditorDialogProps) {
  const appInfo = useContext(AppContext).projectInformation;
  const [endpointExtended, setEndpointExtended] = useState<EndpointExtended>();
  const [selectedTab, setSelectedTab] = useState('logic');
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isPathInvalid, setIsPathInvalid] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { module_id, fetchEndpoints } = useContext(LogicBuilderContext);
  const [isNative, setIsNative] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { pageVisits, updatePageVisits } = useContext(SessionContext);
  const driverInstance = useRef<any>();
  const currentContext = 'endpointEditor';

  const fetchEndpointExtended = useCallback(async () => {
    await EndpointsService.getEndpoint(props.endpointId).then((fetchedEndpoint) => {
      setEndpointExtended(fetchedEndpoint);
      setIsNative(fetchedEndpoint?.native ?? false);
      setIsPathInvalid(validatePath(fetchedEndpoint.path));
      setIsNameInvalid(!endpointNameRegex.test(fetchedEndpoint.name));
    });
  }, [props.endpointId]);

  useEffect(() => {
    if (!props.show) return;

    fetchEndpointExtended();
    setIsNameInvalid(false);
    setIsPathInvalid(false);
    setSelectedTab('logic');
  }, [fetchEndpointExtended, props.show]);

  async function onDelete() {
    if (isLoading) return;
    if (!module_id || !endpointExtended) return;

    setIsLoading(true);
    await EndpointsService.deleteEndpoint(endpointExtended.uuid)
      .then(() => {
        fetchEndpoints(module_id);
        setShowConfirmationDialog(false);
        props.onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (isLoading) return;
    if (isPathInvalid || isNameInvalid) return;
    if (!module_id || !endpointExtended) return;

    setIsLoading(true);
    await EndpointsService.updateEndpoint(endpointExtended.uuid, endpointExtended)
      .then(() => {
        fetchEndpoints(module_id);
        props.onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!props.show) {
      return;
    }
    if (!pageVisits) {
      return;
    }

    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    driverInstance.current = startWalkthrough(
      {
        context: currentContext,
        prevBtnText: 'Previous',
        nextBtnText: 'Next',
        doneBtnText: 'Done',
        onClose: () => {
          updatePageVisits(currentContext);
        },
        actions: getEndpointEditorActions(() => setSelectedTab('logic'))
      },
      'endpoint-editor-trigger-walkthrough'
    );
  }, [pageVisits, props.show, updatePageVisits]);

  function handleOpenWalkthrough() {
    driverInstance.current = startWalkthrough(
      {
        context: currentContext,
        prevBtnText: 'Previous',
        nextBtnText: 'Next',
        doneBtnText: 'Done',
        actions: getEndpointEditorActions(() => setSelectedTab('logic'))
      },
      'endpoint-editor-trigger-walkthrough'
    );
  }

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onClose}
        size="xl"
        centered
        scrollable
        id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-main']}
      >
        <Form onSubmit={onSubmit} className={styles.MainWrapperForm}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="d-flex justify-content-between align-items-center">
                <span className="me-2">{t('logicBuilder.EndpointEditor')}</span>
                <span id="endpoint-editor-trigger-walkthrough">
                  <WalkthroughButton onClick={handleOpenWalkthrough} />
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'auto' }}>
            {endpointExtended && (
              <EndpointInstance
                endpoint={endpointExtended}
                className={styles.Gap}
                showEndpointEditorOnClick={false}
                showEditBtn={false}
              />
            )}

            <Row>
              <Col sm={4}>
                <Form.Group as={Row} className="mb-3" controlId="formEndpointMethod">
                  <Form.Label column sm={4}>
                    {t('logicBuilder.Method')}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      value={endpointExtended?.method ?? METHODS.GET}
                      disabled={isNative}
                      onChange={(e) =>
                        setEndpointExtended({
                          ...endpointExtended,
                          method: e.target.value as Method
                        } as EndpointExtended)
                      }
                      required
                    >
                      {Object.keys(METHODS).map((method) => {
                        return (
                          <option key={method} value={method}>
                            {METHODS[method as Method]}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group as={Row} className="mb-3" controlId="formEndpointName">
                  <Form.Label column sm={2}>
                    {t('logicBuilder.Name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      placeholder={`${t('logicBuilder.Name')}`}
                      value={endpointExtended?.name ?? ''}
                      disabled={isNative}
                      isInvalid={isNameInvalid}
                      maxLength={56}
                      onChange={(e) => {
                        setEndpointExtended({
                          ...endpointExtended,
                          name: e.target.value
                        } as EndpointExtended);
                        setIsNameInvalid(!endpointNameRegex.test(e.target.value));
                      }}
                      required
                    />
                    <Form.Control.Feedback type={'invalid'}>
                      {endpointExtended?.name.length === 0 && t('RequiredField')}
                      {endpointExtended?.name.length !== 0 && t('InvalidField')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group as={Row} className="mb-3" controlId="formEndpointSummary">
              <Form.Label column sm={2}>
                {t('logicBuilder.Summary')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder={`${t('logicBuilder.Summary')}`}
                  value={endpointExtended?.summary ?? ''}
                  disabled={isNative}
                  maxLength={120}
                  onChange={(e) =>
                    setEndpointExtended({
                      ...endpointExtended,
                      summary: e.target.value
                    } as EndpointExtended)
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formEndpointPath">
              <Form.Label column sm={2}>
                {t('logicBuilder.Path')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder={`/${t('logicBuilder.Path')}`}
                  value={endpointExtended?.path ?? ''}
                  isInvalid={isPathInvalid}
                  disabled={isNative}
                  maxLength={150}
                  onChange={(e) => {
                    setEndpointExtended({
                      ...endpointExtended,
                      path: e.target.value
                    } as EndpointExtended);
                    setIsPathInvalid(validatePath(e.target.value));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('logicBuilder.ValidPath')}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* Logic/Security/Other/Examples tabs */}
            <div className={styles.TabsWrapper}>
              <Tabs
                id="endpointsEditorTabs"
                activeKey={selectedTab}
                onSelect={(k) => setSelectedTab(k ?? 'logic')}
              >
                <Tab
                  eventKey="logic"
                  title={`${t('logicBuilder.Logic')}`}
                  className={styles.TabWrapper}
                >
                  {endpointExtended && <LogicTab endpointExtended={endpointExtended} />}
                </Tab>
                {appInfo?.has_authentication && (
                  <Tab
                    eventKey="security"
                    title={`${t('logicBuilder.Security')}`}
                    className={styles.TabWrapper}
                  >
                    {endpointExtended && (
                      <SecurityTab
                        endpointExtended={endpointExtended}
                        setEndpointExtended={(ee) => setEndpointExtended(ee)}
                      />
                    )}
                  </Tab>
                )}
                <Tab
                  eventKey="other"
                  title={`${t('logicBuilder.Other')}`}
                  className={styles.TabWrapper}
                >
                  {endpointExtended && (
                    <OtherTab
                      endpointExtended={endpointExtended}
                      setEndpointExtended={(ee) => setEndpointExtended(ee)}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
          {/* Footer buttons */}
          <Modal.Footer>
            <Button
              id={'deleteButton'}
              variant="danger"
              onClick={() => setShowConfirmationDialog(true)}
              disabled={isNative}
            >
              {t('logicBuilder.createEndpointDialog.delete')}
            </Button>
            <Button id={'cancelButton'} variant="secondary" onClick={() => props.onClose()}>
              {t('logicBuilder.createEndpointDialog.cancel')}
            </Button>
            <Button
              id={'saveButton'}
              variant="primary"
              type="submit"
              disabled={isNameInvalid || isPathInvalid || isNative}
            >
              {t('logicBuilder.createEndpointDialog.save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.endpoint')} (${endpointExtended?.name ?? ''})`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
