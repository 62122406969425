/**
 * Generated by Exocoding 0.0.1.
 */

import React from 'react';
import { AccountService } from 'modules/auth/services';
import SessionContext, { initialPreferences, SessionContextType } from 'modules/auth/store';

const useSession = (): [SessionContextType, boolean] => {
  const session = React.useContext(SessionContext);
  const [loading, setLoading] = React.useState(true);

  const fetchAccount = React.useCallback(async () => {
    try {
      const account = await AccountService.getAccountInfo();
      session.setUser(account);
      session.setPreferences({ ...initialPreferences, ...account.preferences });
      session.setLoggedIn(true);
      session.setPageVisits(account.pageVisits ?? {});
    } catch (e) {
      session.setLoggedIn(false);
      session.setUser(null);
      session.setPageVisits(undefined);
    } finally {
      setLoading(false);
    }
  }, [session]);

  React.useEffect(() => {
    if (!session.isLoggedIn) {
      fetchAccount();
    } else {
      setLoading(false);
    }
  }, [fetchAccount, session.isLoggedIn]);

  return [session, loading];
};

export default useSession;
