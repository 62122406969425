import React, { ChangeEvent } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultLabel, DefaultTextArea } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function TextareaControl(props: ControlProps) {
  const { t } = useTranslation();

  function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    if (props.onChange) props.onChange(e.target.value, props.id);
  }

  return (
    <div className="mb-3" style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', marginBottom: '.5rem' }}>
        <DefaultLabel className={`${styles.defaultLabel}`}>{props.label}</DefaultLabel>
        {props.tooltip && (
          <HelpPopover
            helpBoxProps={{
              description: t(`${props.tooltip}`) ?? ''
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>
      <DefaultTextArea
        id="textArea"
        className={`${styles.defaultInput} form-control form-control-sm`}
        value={props.value ?? ''}
        onChange={handleChange}
      />
    </div>
  );
}

export default TextareaControl;
