import React from 'react';

function NavigateIcon() {
  return (
    <span className="fa-layers fa-fw text-secondary-emphasis" style={{ whiteSpace: 'nowrap' }}>
      <i className="fas fa-arrow-up-right-from-square" style={{ fontSize: '1.2rem' }}></i>
    </span>
  );
}

export default NavigateIcon;
