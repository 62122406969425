import React from 'react';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { ObjectSimple } from '../../../types';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import ReadEntityIcon from './read_entity_icon';
import { filterByPrimaryKey } from '../../../../../web_ui/function_editor/action_inputs/utils/filterByPrimaryKey';
import { t } from 'i18next';

function filterByEntityObject(
  state: FunctionEditorState,
  actionUuid: string,
  obj: ObjectSimple
): boolean {
  return !!obj?.entityUuid;
}

function filterByObject(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: string,
  objectUuid: string
) {
  return filterByPrimaryKey(state, actionUuid, 'objectId', dataType, objectUuid);
}

export const READ_ENTITY_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.READ_ENTITY,
  name: t('ReadEntity'),
  description: 'Action to make a repo call to read an entity',
  color: 'danger',
  category: BACKEND_ACTIONS_CATEGORIES.ENTITY,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.ENTITY,
  icon: <ReadEntityIcon />,
  shouldHaveDatabase: true,
  inlineInputs: [
    {
      label: t('ReadActionParam0'),
      placeholder: t('AnObject') ?? 'an object',
      key: 'objectId',
      type: ACTION_INPUTS_TYPES.OBJECTS_INPUT,
      options: { filterObjs: filterByEntityObject }
    },
    {
      label: t('ReadActionParam1'),
      placeholder: t('PrimaryKey') ?? 'Primary Key',
      key: 'relationshipId',
      type: ACTION_INPUTS_TYPES.RELATIONSHIP_INPUT
    },
    {
      label: t('ReadActionParam2'),
      placeholder: '',
      key: 'id',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: {
        argumentTypes: [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM],
        filterByDataType: filterByObject
      }
    },
    {
      label: t('ReadActionParam3'),
      placeholder: '',
      type: ACTION_INPUTS_TYPES.RETURN_INPUT
    }
  ],
  inputs: [
    {
      label: t('ConsiderChildren'),
      placeholder: '',
      key: 'objectChildren',
      type: ACTION_INPUTS_TYPES.CHECKBOX,
      showInEditor: false
    },
    {
      label: t('Sortable'),
      placeholder: '',
      key: 'sortable',
      type: ACTION_INPUTS_TYPES.CHECKBOX,
      showInEditor: false
    },
    {
      label: t('Filterable'),
      placeholder: '',
      key: 'filterable',
      type: ACTION_INPUTS_TYPES.CHECKBOX,
      showInEditor: false
    }
  ]
};
