import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import style from './style.module.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../../../project/types';

type ImportExcelModalProps = {
  show: boolean;
  onHide: () => void;
};

const formData = new FormData();

export default function ImportExcelModal(props: ImportExcelModalProps) {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const { module_id } = useParams();
  const [error, setError] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const uploadFile = async () => {
    setIsImporting(true);
    fetch(`${API_URL}/modules/${module_id}/schema`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include', // from cookie
      body: formData
    })
      .then((res: Response) => {
        setError('');
        setIsImporting(false);
        if (res.ok) {
          handleClose();
          // Reload full page
          window.location.reload();
        } else {
          res.json().then((error: ApiError) => {
            if (error.message) {
              setError(error.message);
              setIsInvalid(true);
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error('Failed to upload file!');
      });
  };

  function handleUploadFile() {
    if (!formData.has('file')) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
      uploadFile(); // callback to upload
    }
  }

  function handleClose() {
    setError('');
    setIsInvalid(false);
    setIsImporting(false);
    // clear file inside formData
    formData.delete('file');
    props.onHide();
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('modeler.ImportExcel')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className={'mb-3'}>
          <Form.Label>{t('modeler.SelectFileImport')}</Form.Label>
          <Form.Control
            id={'inputFile'}
            type="file"
            onChange={(ev: any) => {
              formData.delete('file');
              formData.append('file', ev.target.files[0]);
            }}
            isInvalid={isInvalid || error.length > 0}
            required
          />
          {isImporting && (
            <Form.Text>
              <Spinner animation="border" variant="secondary" size={'sm'} />
            </Form.Text>
          )}
          {!isImporting && <Form.Control.Feedback type={'invalid'}>{error}</Form.Control.Feedback>}
        </Form.Group>
        <Form.Text>
          {t('modeler.ExampleFileImport')}
          <a href={'/assets/ExcelSample.xlsx'}> link</a>.
        </Form.Text>
        <div className={style.containerButton}>
          <Button
            id={'uploadButton'}
            variant="success"
            disabled={isImporting}
            onClick={handleUploadFile}
          >
            {t('modeler.Import')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
