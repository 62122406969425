import React from 'react';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../store/types/function_editor_state';
import { ActionsManifests } from '../../store/types/manifestsAndInputs';
import ActionCard from '../actions_toolbar/action_card';
import ActionInputsEditor from './action_inputs_editor';

type ActionEditorProps = {
  manifests: ActionsManifests;
};

function ActionEditor({ manifests }: ActionEditorProps) {
  const state = useSelector((state: FunctionEditorState) => state);
  const actionId = state.editor.selectedAction;
  const action = state.actions[actionId];
  const manifest = manifests[action?.type];
  if (!actionId || !action || !manifest) return null;

  const manifestInputs = manifest.inputs.filter((input) => input.showInToolbar !== false);
  const manifestInlineInputs = manifest.inlineInputs.filter(
    (input) => input.showInToolbar !== false
  );

  return (
    <div style={{ width: 'fit-content', overflowY: 'auto', flex: '1' }}>
      <ActionCard manifest={manifest} />
      <ActionInputsEditor uuid={actionId} inputs={manifestInlineInputs} />
      <ActionInputsEditor uuid={actionId} inputs={manifestInputs} />
    </div>
  );
}

export default ActionEditor;
