import React from 'react';

function BeginCycleIcon() {
  return (
    <span className="fa-layers fa-fw" style={{ whiteSpace: 'nowrap' }}>
      <label
        className={'text-secondary-emphasis text-lg-end fas fa-arrows-rotate'}
        style={{ fontSize: '1.2rem' }}
      />
    </span>
  );
}

export default BeginCycleIcon;
