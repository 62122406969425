import { ComponentType, ReactNode } from 'react';
import { ConnectorWrapper } from './ConnectorWrapper';

import EdgeConnector, { EdgeConnectorProps } from './EdgeConnector';

export enum ConnectionTypes {
  EDGE = 'EDGE'
}

export type ConnectionTypesList = {
  [key: string]: ReactNode;
};

export const ConnectionList: ConnectionTypesList = {
  [ConnectionTypes.EDGE]: ConnectorWrapper(EdgeConnector as ComponentType<EdgeConnectorProps>)
};
