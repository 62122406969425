import {
  ComponentGroups,
  ComponentManifest,
  FRONTEND_VARIABLE_TYPES
} from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ListItemType } from './types/list_item_type';
import { t } from 'i18next';
export const LIST_MANIFEST: ComponentManifest = {
  type: 'LIST',
  name: 'List',
  descriptionId: 'LayoutListDescription',
  description:
    'List is a component that repeats all its internal components, while iterating through its array data input',
  icon: 'fa-solid fa-list',
  group: ComponentGroups['DATA'],
  allowDrop: true,
  maxChildren: 1,
  validChildren: ['CUSTOM'],
  previewHeight: 230,
  previewWidth: 400,
  properties: [
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: 'Type',
      key: 'type',
      controlOptions: [ListItemType.CARD, ListItemType.ITEM, ListItemType.IMAGE],
      tooltip: 'designer.right_side.controls.components.list.select'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.list.tooltip'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.list.visible'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable',
      controlOptions: ['list', FRONTEND_VARIABLE_TYPES.OBJECT]
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'NumberOfColumns',
      key: 'numberOfColumns',
      controlOptions: ['noUnit'],
      tooltip: 'designer.right_side.controls.components.list.numberOfColumns'
    },
    {
      controlType: ControlsTypes.GAP,
      controlLabel: 'Gap',
      key: 'gap'
    },
    {
      controlType: ControlsTypes.ACTION,
      controlLabel: 'Action'
    },
    {
      controlType: ControlsTypes.CONTEXT_MENU,
      controlLabel: 'Context Menu'
    },
    {
      controlType: ControlsTypes.LIST_SELECTOR,
      controlLabel: 'ListSelector'
    },
    {
      controlType: ControlsTypes.SEARCH,
      controlLabel: 'Search'
    },
    {
      controlType: ControlsTypes.PAGINATION,
      controlLabel: 'Pagination'
    },
    {
      controlType: ControlsTypes.LIST_SORTER,
      controlLabel: 'Sortable'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    }
  ]
};
