import React from 'react';
import Icon from '../../../../web_ui/icon';
import { API_URL } from '../../repos/auth';
import { Container } from 'react-bootstrap';
import { SocialNetwork } from '../../enum';

import styles from './styles.module.css';

const OAUTH2_REDIRECT_URI = process.env.REACT_APP_OAUTH2_REDIRECT_URI;

type SocialButtonsProps = {
  promotionalCode?: string;
};

/**
 * Social login buttons
 *
 * @component
 */
function SocialButtons(props: SocialButtonsProps) {
  const performExternalSignIn = (network: SocialNetwork) => {
    const url = new URL(`${API_URL}/oauth2/authorize/${network}`);
    if (OAUTH2_REDIRECT_URI) url.searchParams.append('redirect_uri', OAUTH2_REDIRECT_URI);
    if (props.promotionalCode !== undefined) {
      url.searchParams.append('code', props.promotionalCode);
    }
    window.location.replace(url);
  };

  return (
    <Container className={styles.SocialSignInButtons}>
      <button
        type="button"
        id={'loginGoogle'}
        className={`${styles.SocialButton} ${styles.SocialButtonGoogle}`}
        onClick={() => performExternalSignIn(SocialNetwork.GOOGLE)}
      >
        <Icon brands="brands" iconName="google" extraProps="fa-xl" />
      </button>
      <button
        type="button"
        id={'loginGithub'}
        className={`${styles.SocialButton} ${styles.SocialButtonGitHub}`}
        onClick={() => performExternalSignIn(SocialNetwork.GITHUB)}
      >
        <Icon brands="brands" iconName="github" extraProps="fa-xl" />
      </button>
    </Container>
  );
}

export default SocialButtons;
