import { AutomationsRepository } from '../repos';
import { CrudData } from '../../../routes/automation_wizard/components/wizard_steps/crud';
import { ImportedObject } from '../../../routes/automation_wizard/types';
import { TemplateAutomationData } from 'routes/automation_wizard/components/wizard_steps/templates';
import { ModelGeneratorAutomationData } from 'routes/automation_wizard/components/wizard_steps/model_generator';

export class AutomationsServ {
  async createCrudAutomation(moduleId: string, crudSchema: CrudData, allowOverwrite = false) {
    return await AutomationsRepository.createCrudAutomation(moduleId, crudSchema, allowOverwrite);
  }

  async createObjectsAutomation(moduleId: string, importedObject: ImportedObject) {
    return await AutomationsRepository.createObjectsAutomation(moduleId, importedObject);
  }

  async createTemplatesAutomation(
    moduleId: string,
    templatesAutomationData: TemplateAutomationData
  ) {
    return await AutomationsRepository.createTemplatesAutomation(moduleId, templatesAutomationData);
  }

  async modelGeneratorAutomation(
    appId: string,
    moduleId: string,
    data: ModelGeneratorAutomationData
  ) {
    return await AutomationsRepository.modelGeneratorAutomation(appId, moduleId, data);
  }
}
