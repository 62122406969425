import React from 'react';

function RunFunctionIcon() {
  return (
    <span className="fa-layers fa-fw text-secondary-emphasis" style={{ whiteSpace: 'nowrap' }}>
      <span style={{ fontSize: '1.4rem', fontWeight: 'bold', marginRight: '0.2rem' }}>{'{'}</span>
      <i className="fas fa-cogs text-warning" style={{ fontSize: '1.2rem' }}></i>
      <span style={{ fontSize: '1.4rem', fontWeight: 'bold', marginLeft: '0.2rem' }}>{'}'}</span>
    </span>
  );
}

export default RunFunctionIcon;
