import { EditorState } from '../types/function_editor_state';
import produce from 'immer';

import { initialState } from '../initial_empty_state';
import { doSelectAction, SELECT_ACTION, SelectActionAction } from '../events/editor/select_action';
import { doSelectParam, SELECT_PARAM, SelectParamAction } from '../events/editor/select_param';
import { CUSTOM_CODE, CustomCodeAction, doCustomCode } from '../events/editor/custom_code';

type EditorActions = SelectActionAction | SelectParamAction | CustomCodeAction;

export const editorReducer = (
  state: EditorState = initialState.editor,
  action: EditorActions
): EditorState => {
  return produce(state, (draft: EditorState) => {
    switch (action.type) {
      case SELECT_ACTION:
        return doSelectAction(draft, action);
      case SELECT_PARAM:
        return doSelectParam(draft, action);
      case CUSTOM_CODE:
        return doCustomCode(draft, action);
      default:
        return state;
    }
  });
};
