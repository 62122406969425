import { combineReducers } from '../../../../packages/redux-utils';
import { functionsReducer } from '../../../../web_ui/function_editor/store/reducers/functions';
import { applyMiddleware, compose, createStore } from 'redux';
import { rootReducer } from '../../../../web_ui/function_editor/store/reducers/root';
import { parametersReducer } from '../../../../web_ui/function_editor/store/reducers/parameters';
import { actionsReducer } from '../../../../web_ui/function_editor/store/reducers/actions';
import { variablesReducer } from '../../../../web_ui/function_editor/store/reducers/variables';
import { objectsReducer } from '../../../../web_ui/function_editor/store/reducers/objects';
import { objectItemsReducer } from '../../../../web_ui/function_editor/store/reducers/object_items';
import { enumsReducer } from '../../../../web_ui/function_editor/store/reducers/enums';
import { editorReducer } from '../../../../web_ui/function_editor/store/reducers/editor';
import { initialState } from '../../../../web_ui/function_editor/store/initial_empty_state';
import { saveMiddleware } from './middlewares/save';
import { globalsReducer } from '../../../../web_ui/function_editor/store/reducers/globals';
import { ParamsReducer } from 'modules/designer/studio/store/reducers/params';

const appReducers = combineReducers(
  {
    editor: editorReducer || (() => null),
    globals: globalsReducer || (() => null),
    functions: functionsReducer || (() => null),
    actions: actionsReducer || (() => null),
    parameters: parametersReducer || (() => null),
    variables: variablesReducer || (() => null),
    objects: objectsReducer || (() => null),
    objects_items: objectItemsReducer || (() => null),
    enums: enumsReducer || (() => null),
    page_params: ParamsReducer || (() => null)
  },
  rootReducer
);

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  appReducers,
  initialState,
  composeEnhancers(applyMiddleware(saveMiddleware))
);
