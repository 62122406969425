import React from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, useNavigate } from 'react-router-dom';
import Icon from 'web_ui/icon';

function Fallback({ error, resetErrorBoundary }: any) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#212529' }} role="alert">
      <div
        className="mb-1 text-center d-flex align-items-center"
        style={{ position: 'absolute', height: '100px', overflowY: 'hidden' }}
        onClick={() => {
          navigate('/');
          resetErrorBoundary();
        }}
      >
        <img width={200} height={150} src="/assets/horizontalWhiteLogo.svg" alt="Error image" />
      </div>
      <div
        className="d-flex justify-content-center align-items-center h-100 w-100"
        style={{ padding: '4rem' }}
      >
        <div className="row">
          <div className="col-12 col-xs-12 col-md-8 text-center">
            <img
              style={{ width: '80%' }}
              src="/assets/undraw_bug_fixing_oc-7-a.svg"
              alt="Error image"
            />
          </div>
          <div className="col-12 col-xs-12 col-md-4 d-flex align-items-center flex-column justify-content-center">
            <h1
              className="w-100 text-primary d-inline"
              style={{ fontWeight: '200', fontSize: '4rem' }}
            >
              {t('errors.SomethingWent')}
              <b style={{ fontWeight: '900' }}> {t('errors.Wrong')}!</b>
            </h1>
            <span
              className="text-info d-block mt-3 p-3 rounded w-100"
              style={{ backgroundColor: '#222c40' }}
            >
              {t('errors.GlobalError')}
            </span>
            <div className="w-100">
              <button
                className="mt-4 btn btn-primary p-2 pe-5 ps-5"
                onClick={() => {
                  resetErrorBoundary();
                  navigate('/');
                }}
              >
                <Icon iconName="refresh"></Icon> {t('errors.Refresh')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fallback;
