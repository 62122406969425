import React from 'react';
import { CrudPage } from '../../../../../../../../../modules/logic_builder/types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styles from '../styles.module.css';
import Icon from '../../../../../../../../../web_ui/icon';
import { PageParam } from '../../../../../../../../../modules/designer/types';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import ParamInstance from './param_instance';
import { Validation } from '..';

type Props = {
  page: CrudPage;
  onParamsChange: (params: PageParam[]) => void;
  validation: React.Dispatch<React.SetStateAction<Validation>>;
};

export default function PageParams(props: Props) {
  function handleChange(index: number, val: string) {
    props.page.params[index].name = val;
    props.onParamsChange(props.page.params);
  }

  function handleAdd() {
    const param: PageParam = {
      uuid: uuidv4(),
      name: `param${props.page.params.length}`,
      native: false,
      order: 0,
      viewid: props.page.uuid,
      route: ''
    };
    props.page.params.push(param);
    props.onParamsChange(props.page.params);
  }

  function handleRemove(index: number) {
    props.page.params.splice(index, 1);
    props.onParamsChange(props.page.params);
  }

  const { t } = useTranslation();

  return (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm="2">
        {t('automation.step4.param')}:
      </Form.Label>
      <Col sm="10" style={{ maxHeight: '170px', overflowY: 'auto' }}>
        {props.page.params.map((param, index) => (
          <ParamInstance
            handleChange={handleChange}
            handleRemove={handleRemove}
            index={index}
            param={param}
            key={index}
            validation={props.validation}
            isFromPageEditor={true}
          />
        ))}
        <HelpPopover
          placement={'top'}
          helpBoxProps={{
            title: t('NewParameter') ?? ''
          }}
        >
          <Button
            id="createParamButton"
            className={`${styles.AddParameterButton}`}
            onClick={handleAdd}
          >
            <Icon iconName={'plus'} />
          </Button>
        </HelpPopover>
      </Col>
    </Form.Group>
  );
}
