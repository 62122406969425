import React, { useEffect, useRef, useState } from 'react';
import { TableUUID } from '../../../types';
import { DatabaseStudioState } from '../../store';
import { useSelector } from 'react-redux';
import AdvancedEditor from '../../components/advanced_editor';
import styles from './style.module.css';
import SimpleColumnList from './components/simple_column_list';
import { TableHeader } from './components/table_header';
import TableActionsBar from './components/table_actions_bar';
import useFetchDataTypes from '../../hooks/useFetchDataTypes';

export type TableEditorProps = {
  tableID: TableUUID;
  columnList: string[];
  overflow: { overflowX: number; overflowY: number };
  editorScale: number;
  focusOnTableName: boolean;
};

/**
 * Float box for quick editing of tables
 * ex: (create columns, create pks, change type, etc.)
 *
 * @component
 */
function TableEditor({
  tableID,
  columnList,
  overflow,
  editorScale,
  focusOnTableName
}: TableEditorProps) {
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const selectedTable = useSelector((state: DatabaseStudioState) => state.studio.selectedFrame);
  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const editor = useRef<HTMLDivElement | null>(null);
  const tableNameInputRef = useRef<HTMLInputElement>(null);
  const fieldTypes = useFetchDataTypes();

  useEffect(() => {
    if (!tableNameInputRef.current) {
      return;
    }

    if (focusOnTableName) {
      tableNameInputRef.current.select();
    }
  }, [focusOnTableName]);

  function getTableColumns() {
    return columnList.map((colID) => columns[colID] && columns[colID]).filter((col) => !col.isPK);
  }

  function getTablePKColumns() {
    return columnList.map((colID) => columns[colID] && columns[colID]).filter((col) => col.isPK);
  }

  return (
    <>
      <section
        className={`${styles.columnModal} bg-body`}
        style={{
          transform: `translate(${overflow.overflowX}px, ${overflow.overflowY}px) scale(${editorScale})`,
          maxHeight: '75vh'
        }}
      >
        <div className={`${styles.columnContainer}`}>
          <div style={{ maxHeight: '62vh', overflowY: 'auto' }} ref={editor}>
            <TableHeader
              tableID={tableID}
              tableName={tables[tableID].content.data.name}
              ref={tableNameInputRef}
            />
            <SimpleColumnList
              tableID={tableID}
              tableColumns={getTablePKColumns()}
              selectedColumnID={selectedColumn}
              selectColumn={setSelectedColumn}
              fieldTypes={fieldTypes}
            />
            <hr />
            <SimpleColumnList
              tableID={tableID}
              tableColumns={getTableColumns()}
              selectedColumnID={selectedColumn}
              selectColumn={setSelectedColumn}
              fieldTypes={fieldTypes}
            />
          </div>
          <hr />
          <TableActionsBar tableID={tableID} showAdvancedEditor={setShowModal} />
        </div>
      </section>
      {selectedTable && tables[selectedTable] && (
        <AdvancedEditor
          tableID={selectedTable}
          showModal={showModal}
          onCloseRequest={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}

export default TableEditor;
