import { InterfaceStudioState } from 'modules/designer/studio/store';
import React from 'react';
import { useSelector } from 'react-redux';
import ColorIcon from './colorIcon';
import { defaultColors } from './defaultColorsPallete';

export interface ColorSelectorOverlayProps {
  selectedColor: string;
  onChange: (newColorValue: string) => void;
}

function ColorSelectorOverlay({ selectedColor, onChange }: ColorSelectorOverlayProps) {
  const theme = useSelector((state: InterfaceStudioState) => state.theme);
  const areThemeColors = true;

  const mountColorsList = (colorList: [string, string][], areTheme?: boolean) => (
    <>
      {colorList.map(([key, value], index) => (
        <div
          key={value + index}
          className="px-1 mt-2"
          style={{ width: 'fit-content', cursor: 'pointer' }}
        >
          <ColorIcon
            colorKey={areTheme ? key : undefined}
            colorValue={value}
            isSelected={areTheme ? selectedColor === key : selectedColor === value}
            onClick={onChange}
          />
        </div>
      ))}
    </>
  );

  return (
    <div className="d-flex flex-column">
      <span className="text-body">Theme colors</span>
      <div className="d-flex flex-wrap" style={{ maxWidth: '8.5rem' }}>
        {mountColorsList(Object.entries(theme.colors), areThemeColors)}
      </div>
      <span className="text-body mt-3">All colors</span>
      <div className="d-flex flex-wrap" style={{ maxWidth: '8.5rem' }}>
        {mountColorsList(Object.entries(defaultColors))}
      </div>
    </div>
  );
}

export default ColorSelectorOverlay;
