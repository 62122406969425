import React from 'react';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import TypePicker, { TYPE_PICKER_TYPES } from './type_picker';
import ValuePicker from './value_picker';
import useCheckValueIsObject from './useCheckValueIsObject';
import ObjectNodePicker from '../../components/object_node_picker';
import { useDispatch, useSelector } from 'react-redux';
import { updateAction } from '../../../store/events/actions/update_action';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { useParams } from 'react-router-dom';

function ArgumentPicker({ label, actionUuid, dataKey, options, isFromSideBar }: ActionInputProps) {
  const dispatch = useDispatch();
  const { view_id } = useParams();
  const action = useSelector((state: FunctionEditorState) => state.actions[actionUuid]);

  const actionType = action?.data[dataKey]?.type ?? 'VAR';
  const actionValue = action?.data[dataKey]?.value ?? '';
  const actionObjectNode = action?.data[dataKey]?.objectNode ?? '';

  const { isObject, objectUuid } = useCheckValueIsObject(actionType, actionValue);

  const handleChangeType = (t: TYPE_PICKER_TYPES): void => {
    dispatch(updateAction(actionUuid, dataKey, { type: t, value: '', objectNode: '' }));
  };

  const handleChangeValue = (v: string): void => {
    dispatch(updateAction(actionUuid, dataKey, { type: actionType, value: v, objectNode: '' }));
  };

  const handleChangeObjectNode = (n: string): void => {
    dispatch(
      updateAction(actionUuid, dataKey, { type: actionType, value: actionValue, objectNode: n })
    );
  };

  const getFilteredArgumentTypes = (): TYPE_PICKER_TYPES[] => {
    let filteredArgs: TYPE_PICKER_TYPES[] = options?.argumentTypes as TYPE_PICKER_TYPES[];
    if (view_id === undefined) {
      if (!options?.argumentTypes) {
        filteredArgs = [
          TYPE_PICKER_TYPES.FIXED,
          TYPE_PICKER_TYPES.VAR,
          TYPE_PICKER_TYPES.PARAM,
          TYPE_PICKER_TYPES.PAGEPARAM,
          TYPE_PICKER_TYPES.PROP
        ];
      }
      filteredArgs = filteredArgs.filter((value) => value !== TYPE_PICKER_TYPES.PAGEPARAM);
    }
    return filteredArgs;
  };

  return (
    <div className={'d-flex align-items-center flex-wrap gap-2'}>
      <TypePicker
        placeholder={'Select argument type'}
        label={label}
        value={actionType}
        argumentTypes={getFilteredArgumentTypes()}
        handleChange={handleChangeType}
        isFromSideBar={isFromSideBar}
      />
      <ValuePicker
        type={actionType}
        label={options?.argumentTypes?.length !== 1 ? '' : label}
        actionUuid={actionUuid}
        filterByList={!!options?.list}
        filterByDataType={options?.filterByDataType && options?.filterByDataType}
        value={actionValue}
        handleChange={handleChangeValue}
        filterByGlobalVars={options?.filterByGlobalVars}
        isFromSideBar={isFromSideBar}
      />
      {isObject && !options?.hideNodePicker && (
        <ObjectNodePicker
          objectUuid={objectUuid}
          filterByList={!!options?.list}
          value={actionObjectNode}
          handleChange={handleChangeObjectNode}
          isFromSideBar={isFromSideBar}
        />
      )}
    </div>
  );
}

export default ArgumentPicker;
