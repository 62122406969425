import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../studio/store';

const useGetColor = () => {
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  const getColor = useCallback(
    (colorValue: string) => {
      return theme.colors[colorValue] ? theme.colors[colorValue] : colorValue;
    },
    [theme]
  );

  return getColor;
};

export default useGetColor;
