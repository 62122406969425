import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import produce from 'immer';
import { Authorization } from 'modules/auth/session/authorization';
import OrganizationCard from './components/organization_card';
import { OrganizationService } from 'modules/organization/services';
import { FiltersOptions, OrganizationApiInfo } from 'modules/organization/types';
import CreateOrganizationModal from './components/create_organization_modal';
import Navbar from 'web_ui/navbar';
import { PopupAlert } from 'web_ui/popup_alert';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import useSession from 'hooks/useSession';
import useTitle from 'hooks/useTitle';

export type messageCallback = 'success' | 'nothing' | 'inviteError';

function Organizations() {
  const [organizations, setOrganizations] = useState<OrganizationApiInfo[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalError, setShowModalError] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<FiltersOptions>(FiltersOptions.NAME);
  const [searchText, setSearchText] = useState('');
  const [session] = useSession();
  const { t } = useTranslation();

  useTitle(t('tab.org'));

  const search = t('organizations.SearchOrganizations');

  const handleCloseModal = useCallback((message: messageCallback) => {
    if (message === 'success' || message === 'inviteError') {
      findOrgs();
      session.reloadUser();
      setShowModalError(message === 'inviteError');
    }
    setShowModal(false);
  }, []);

  useEffect(() => {
    findOrgs();
    session.reloadUser();
  }, []);

  /**
   * Fetch organizations from the current user
   */
  const findOrgs = async () => {
    try {
      setLoading(true);
      const organizations = await OrganizationService.getOrganizations();
      setIsOwner(organizations.find((org) => org.owner.id === session.user?.id) !== undefined);
      setOrganizations([...organizations]);
      changeFilterType(filterType);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  function changeFilterType(type: FiltersOptions) {
    setFilterType(type);
    if (type === FiltersOptions.NAME) {
      // filtertype does not exist
      setOrganizations((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        });
        return [...nextState];
      });
    } else if (type === FiltersOptions.DATE) {
      setOrganizations((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => (a.modificationTime < b.modificationTime ? 1 : -1));
        });
        return [...nextState];
      });
    }
  }

  const onAfterClose = () => {
    findOrgs();
  };

  return (
    <>
      <Navbar context="homepage" />
      <Container className={`p-4 pt-5`} style={{ minHeight: 'calc(100vh - 60px)' }}>
        <Row className="pt-4">
          <Col sm={9}>
            <h2 className={`text-body-emphasis`}>{t('organizations.Organizations')}</h2>
          </Col>
          <Col sm={3} className="d-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center justify-content-end">
              {!isOwner && (
                <Authorization
                  allowedSystemAuthorities={[SystemRoleAuthorityName.ADD_ORGANIZATION]}
                >
                  <Button id={'createButton'} size="sm" onClick={() => setShowModal(true)}>
                    {t('organizations.NewOrganization')}
                  </Button>
                </Authorization>
              )}
            </div>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col sm={4}>
            <input
              id={'searchField'}
              type={'search'}
              className="text-body-secondary bg-body form-control"
              placeholder={search}
              onChange={(ev) => setSearchText(ev.target.value)}
            />
          </Col>
          <Col sm={8} className="d-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center justify-content-end">
              <h6 className="ps-1 pe-1" style={{ margin: 0 }}>
                {t('OrderBy')}
              </h6>
              <div className="ps-1 ">
                <select
                  id={'filterDropdown'}
                  className={`form-select form-select-sm`}
                  defaultValue={FiltersOptions.NAME}
                  onChange={(ev) => changeFilterType(ev.target.value as FiltersOptions)}
                >
                  {Object.values(FiltersOptions)
                    .filter((v) => isNaN(Number(v)))
                    .map((type) => {
                      return (
                        <option id={type} key={type} value={type}>
                          {t('order_options.' + type)}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-5">
          {isLoading && (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}
          {!isLoading &&
            organizations
              .filter((org) => {
                return (
                  !searchText ||
                  org.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                );
              })
              .map((org) => (
                <Col key={org.id} sm={12} md={6} lg={4} className="mb-3">
                  <OrganizationCard org={org} onClose={onAfterClose} />
                </Col>
              ))}
        </Row>
      </Container>
      <CreateOrganizationModal showModal={showModal} onCloseRequest={handleCloseModal} />
      {showModalError && (
        <Modal show={showModalError ? true : false} centered={false}>
          <PopupAlert
            i18nKey={t('invitation.InviteError') ?? 'invitation.InviteError'}
            onClose={() => setShowModalError(false)}
            variant={'warning'}
          />
        </Modal>
      )}
    </>
  );
}

export default Organizations;
