import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { t } from 'i18next';

export const TABS_MANIFEST: ComponentManifest = {
  type: 'TABS',
  name: 'Tabs',
  descriptionId: 'TabsDescription',
  description: 'It is a component tabs for editable layouts.',
  icon: 'fa-solid fa-table-list',
  group: ComponentGroups['MENU'],
  allowDrop: true,
  hasSections: true,
  previewHeight: 160,
  previewWidth: 320,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'ActiveKey',
      key: 'activeKey',
      tooltip: 'designer.right_side.controls.components.tabs.activeKey'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.tabs.tooltip'
    },
    {
      controlType: ControlsTypes.LIST,
      controlLabel: 'TabsName',
      key: 'title',
      tooltip: 'designer.right_side.controls.components.tabs.title'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'Font Size',
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.tabs.fontSize'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
