import { v4 as uuidv4 } from 'uuid';

export const THEME_TEMPLATE = {
  name: 'New Theme',
  values: [
    {
      section: 'colors',
      key: 'primary',
      value: '#007bff'
    },
    {
      section: 'colors',
      key: 'secondary',
      value: '#6c757d'
    },
    {
      section: 'colors',
      key: 'success',
      value: '#28a745'
    },
    {
      section: 'colors',
      key: 'danger',
      value: '#dc3545'
    },
    {
      section: 'colors',
      key: 'warning',
      value: '#ffc107'
    },
    {
      section: 'colors',
      key: 'info',
      value: '#17a2b8'
    },
    {
      section: 'colors',
      key: 'light',
      value: '#f8f9fa'
    },
    {
      section: 'colors',
      key: 'dark',
      value: '#343a40'
    },
    {
      section: 'colors',
      key: 'BACKGROUND_COLOR',
      value: '#ffffff'
    },
    {
      section: 'text',
      key: 'GLOBAL_FONT',
      value: 'arial'
    },
    {
      section: 'text',
      key: 'FONT_SIZE',
      value: '16'
    },
    {
      section: 'text',
      key: 'GLOBAL_FONT_COLOR',
      value: '#000000'
    }
  ]
};

export const BOOTSTRAP_COLORS_ORDER = [
  'primary',
  'secondary',
  'warning',
  'success',
  'danger',
  'info',
  'light',
  'dark'
];
