import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { FunctionExtended, FunctionVariable } from 'modules/logic_builder/types';
import { Col, Form, Row } from 'react-bootstrap';

type ReturnTabProps = {
  variablesList: FunctionVariable[];
  functionExtended: FunctionExtended;
  setFunctionExtended: (fn: FunctionExtended) => void;
};

export function ReturnTab({
  variablesList,
  functionExtended,
  setFunctionExtended
}: ReturnTabProps) {
  useEffect(() => {
    const returnVariable = variablesList.find(
      (item) => item.uuid === functionExtended.returnVariableUuid
    );
    if (returnVariable == null) {
      setFunctionExtended({ ...functionExtended, returnVariableUuid: '' });
    }
  }, [functionExtended, setFunctionExtended, variablesList]);

  return (
    <div className={styles.SelectReturnVariable}>
      <div className={styles.TableTitle}>
        Return
        <p style={{ display: 'none' }}></p>
      </div>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Output type
        </Form.Label>
        <Col sm={3}>
          <Form.Select
            id={'return-variable-select'}
            value={functionExtended.returnVariableUuid || 'VOID'}
            onChange={(e) =>
              setFunctionExtended({
                ...functionExtended,
                returnVariableUuid: e.target.value === 'VOID' ? '' : e.target.value
              })
            }
          >
            <option value="VOID">void</option>
            {variablesList.map((variable) => {
              return (
                <option key={variable.uuid} value={variable.uuid}>
                  {variable.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
        <Col sm={2}>
          <Form.Check
            id={'Pageable'}
            className={styles.OtherCheckboxes}
            inline
            label="Pageable"
            type="checkbox"
            checked={functionExtended.pageable}
            onChange={(e) =>
              setFunctionExtended({ ...functionExtended, pageable: e.target.checked })
            }
            name="Pageable"
          />
        </Col>
        <Col sm={2}>
          <Form.Check
            id={'Sortable'}
            className={styles.OtherCheckboxes}
            inline
            label="Sortable"
            type="checkbox"
            checked={functionExtended.sortable}
            onChange={(e) =>
              setFunctionExtended({ ...functionExtended, sortable: e.target.checked })
            }
            name="Sortable"
          />
        </Col>
        <Col sm={2}>
          <Form.Check
            id={'Filterable'}
            className={styles.OtherCheckboxes}
            inline
            label="Filterable"
            type="checkbox"
            checked={functionExtended.filterable}
            onChange={(e) =>
              setFunctionExtended({ ...functionExtended, filterable: e.target.checked })
            }
            name="Filterable"
          />
        </Col>
      </Form.Group>
    </div>
  );
}
