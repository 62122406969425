import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';

type ActionsSpacerButtonProps = {
  handleClick: () => void;
};

function ActionsSpacerButton({ handleClick }: ActionsSpacerButtonProps) {
  return (
    <div
      id="addEmpty"
      onClick={handleClick}
      className={`${styles.ActionsSpacerButton} rounded-circle bg-body-secondary ps-1 pe-1`}
    >
      <Icon iconName={'plus'} />
    </div>
  );
}

export default ActionsSpacerButton;
