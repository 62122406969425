import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

export const CONTAINER_MANIFEST: ComponentManifest = {
  type: 'CONTAINER',
  name: 'Container',
  descriptionId: 'LayoutContainerDescription',
  description:
    'Containers are a fundamental building block that contain, pad, and align your content within a given device or viewport.',
  icon: 'fa-solid fa-border-none',
  group: ComponentGroups['LAYOUT'],
  previewHeight: 100,
  previewWidth: 200,
  allowDrop: true,
  properties: [
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'VisibleVariable',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.container.visible'
    },
    {
      controlType: ControlsTypes.POSITION,
      controlLabel: 'Position'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.SELECT_PICS,
      controlLabel: 'Background Image',
      key: 'url',
      tooltip: 'designer.right_side.controls.components.container.selectPics'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: 'Background',
      tooltip: 'designer.right_side.controls.components.container.themePicker'
    },
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      key: 'color',
      tooltip: 'designer.right_side.controls.components.container.colorPicker'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.container.number'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ]
};
