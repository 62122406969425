import React, { useEffect, useState } from 'react';
import { DataType, DataTypeFieldType } from '../../types';
import { DataService } from '../../services';
import { useParams } from 'react-router-dom';

function UseFetchDataTypes() {
  const [fieldTypeSpecificDataTypes, setFieldTypeSpecificDataTypes] = useState<{
    [key: string]: DataType[];
  }>({});
  const { module_id } = useParams();

  const fetchDataTypes = React.useCallback(async () => {
    if (!module_id) return;

    const dataTypes: DataType[] = await DataService.getDataTypes(module_id);

    const fieldSpecificDataTypes: { [key: string]: DataType[] } = {};
    // DataTypes that are common for both FIELDS and KEYS.
    fieldSpecificDataTypes['ALL'] = [];
    // FIELDS + ALL + ENUMS dataTypes.
    fieldSpecificDataTypes['FIELDS'] = [];
    // KEYS + ALL dataTypes.
    fieldSpecificDataTypes['KEYS'] = [];

    for (const type of dataTypes) {
      if (type.fieldType === DataTypeFieldType.ALL) {
        fieldSpecificDataTypes[DataTypeFieldType.ALL].push(type);
        fieldSpecificDataTypes[DataTypeFieldType.FIELDS].push(type);
        fieldSpecificDataTypes[DataTypeFieldType.KEYS].push(type);
      } else if (type.type === 'ENUM') {
        fieldSpecificDataTypes['FIELDS'].push(type);
      } else {
        fieldSpecificDataTypes[type.fieldType].push(type);
      }
    }

    setFieldTypeSpecificDataTypes(fieldSpecificDataTypes);
  }, [module_id]);

  useEffect(() => {
    fetchDataTypes().then(() => {});
  }, [fetchDataTypes]);

  return fieldTypeSpecificDataTypes;
}

export default UseFetchDataTypes;
