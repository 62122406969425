import { TabsData, TabsStyle } from './index';
import { LayoutComponent } from '../../../../designer/types';

export const TABS_TEMPLATE: LayoutComponent<TabsData, TabsStyle> = {
  uuid: 'empty',
  type: 'TABS',
  data: {
    title: ['Home', 'About'],
    activeKey: 'Home',
    width: 100,
    widthUnit: '%',
    maxWidth: 'none',
    minWidth: 'none',
    height: 'fit-content',
    heightUnit: '',
    maxHeight: 'none',
    minHeight: 'none',
    optionSizeWidth: 'full',
    optionSizeHeight: 'auto'
  },
  styles: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingBottom: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingLeft: 10,
    css: ''
  }
};
