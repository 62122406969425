import { FunctionsState } from '../../types/functions';
import { SetSortAction } from '../parameters/set_sort';

export function doSetSort(state: FunctionsState, action: SetSortAction): FunctionsState {
  const { functionUuid, value, sortUuid } = action.payload;
  if (!state[functionUuid]) return state;
  if (value) {
    state[functionUuid].parameters.push(sortUuid);
    state[functionUuid].options.sortable = true;
    return state;
  }

  const sortIndex = state[functionUuid].parameters.indexOf(sortUuid);
  state[functionUuid].parameters.splice(sortIndex, 1);
  state[functionUuid].options.sortable = false;
  return state;
}
