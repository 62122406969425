import { AppType, Architecture, CountryCode, CountryLanguageName, Template } from '../enum';
import { Backend, DataSourcesType, Frontend, FrontendInfo } from './wizard_types';
import { AppLanguages, AppProperties } from '../../designer/types';

export type Language = {
  name: CountryLanguageName;
  code: CountryCode;
};

export type AppFullInfo = {
  name: string;
  domain: string;
  description: string;
  mainLanguage: AppLanguages;
  type: AppType;
  template: Template;
  architecture: Architecture;
  backend: Backend;
  frontend: Frontend;
  data_sources: DataSourcesType[];
  has_authentication: boolean;
  has_assets: boolean;
  isSql: boolean;
  has_frontend: boolean;
  has_backend: boolean;
  has_database: boolean;
  gitRepository?: string;
  createRepo?: boolean;
  createPR?: boolean;
  autoMerge?: boolean;
  privateRepo?: boolean;
  repoDescription?: string;
  properties?: AppProperties[];
  favIcon?: File;
};

export type AppInfo = {
  name?: string;
  domain?: string;
  description?: string;
  mainLanguage?: CountryCode;
  languages?: CountryCode[];
  type?: AppType;
  template?: Template;
  architecture?: Architecture;
  backend?: Backend;
  frontend?: FrontendInfo;
  data_sources?: DataSourcesType[];
  has_authentication?: boolean;
};

export type ApiAppInfo = {
  id: string;
  name: string;
  domain: string;
  description: string;
  version: string;
  languages: string;
  mainLanguage: AppLanguages;
  mainLanguageId?: string;
  type: AppType;
  template: Template;
  architecture: Architecture;
  backend: Backend;
  frontend: Frontend;
  data_sources: DataSourcesType[];
  has_authentication: boolean;
  has_assets: boolean;
  owner_name: string;
  owner_id: number;
  is_owner_org: boolean;
  created_by: string;
  created_at: string;
  changed_at: string;
  changed_by: string;
  icon: string;
  disabled: boolean;
  isSql: boolean;
  has_frontend: boolean;
  has_backend: boolean;
  has_database: boolean;
  gitRepository?: string;
  createPR?: boolean;
  autoMerge?: boolean;
};

export type JobInfo = {
  id: string;
  type: string;
  status: JobStatus;
  number: number;
  creationTime?: Date;
  userId?: number;
  user?: string;
  message?: string;
  logs?: string;
  resultUrl?: string;
  prUrl?: string;
  prNumber?: number;
  prStatus?: PRStatus;
  merged?: boolean;
};

export enum PRStatus {
  PENDING = 'PENDING',
  OPEN = 'OPEN',
  PENDING_AUTO_MERGE = 'PENDING_AUTO_MERGE',
  TESTING_MERGE_COMMIT = 'TESTING_MERGE_COMMIT',
  CONFLICTS = 'CONFLICTS',
  CLOSED = 'CLOSED'
}

export enum JobStatus {
  QUEUED = 'QUEUED',
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CANCELLED = 'CANCELLED',
  WARNING = 'WARNING'
}

export type JobOptions = {
  frontend: boolean;
  backend: boolean;
  sql: boolean;
  prTitle: string;
  prDescription: string;
};

export type AppVersionInfo = {
  name: string;
  description: string;
  version: string;
};

export type ApiError = {
  /* HTTP Status returned by API */
  status: number;
  /* HTTP Status textual description */
  error: string;
  /* Error description returned by the backend */
  message?: string;
  /* Timestamp */
  timestamp: string;
  /* Path of request which produced the error */
  path: string;
};
