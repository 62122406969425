import * as React from 'react';
import {
  Action,
  FunctionParameter,
  FunctionType,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import { FunctionService } from 'modules/logic_builder/services';
import { Form } from 'react-bootstrap';
import ArgumentPicker from '../ArgumentPicker';
import { RunFunctionActionType } from '../../types';

type Props = {
  newAction: Action<RunFunctionActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<RunFunctionActionType>>>;
  objects: ObjectSimple[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
  functionsList: FunctionType[];
  functionId: string;
  editMode: boolean;
};

export default function RenderRunFunctionInputs(props: Props) {
  const [selectedFunctionParameters, setSelectedFunctionParameters] = React.useState<
    FunctionParameter[]
  >([]);

  const fetchSelectedFunctionParameters = React.useCallback(async (functionId: string) => {
    await FunctionService.getParameters(functionId).then((fetchedParameters) => {
      setSelectedFunctionParameters(fetchedParameters);
    });
  }, []);

  React.useEffect(() => {
    if (!props.editMode) return;
    fetchSelectedFunctionParameters(props.newAction.data.function.functionId);
  }, [fetchSelectedFunctionParameters, props.editMode, props.newAction.data.function.functionId]);

  function handleFunctionOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const localInputs = { ...props.newAction.data } as RunFunctionActionType;
    // Reset arguments.
    localInputs.function.arguments = {};
    // Update functionId.
    localInputs.function.functionId = e.target.value;
    // Initiate parameter properties.
    props.onChange({ ...props.newAction, data: localInputs });
    fetchSelectedFunctionParameters(localInputs.function.functionId);
  }

  const renderVariablesList = (
    onChange: any,
    value: string | undefined,
    variablesList: FunctionVariable[],
    required: boolean,
    disabled?: boolean
  ) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label>Select variable</Form.Label>
        <Form.Select
          aria-label="selectVariable"
          onChange={onChange}
          value={value ?? 'default'}
          required={required ?? false}
          disabled={disabled ?? false}
        >
          {!value && <option value="default">Select variable</option>}
          {variablesList.map((variable) => {
            return (
              <option key={variable.uuid} value={variable.uuid}>
                {variable.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    );
  };

  const renderParametersList = (
    onChange: any,
    value: string | undefined,
    parametersList: FunctionParameter[],
    required: boolean,
    disabled?: boolean
  ) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label>Select parameter</Form.Label>
        <Form.Select
          aria-label="selectParameter"
          onChange={onChange}
          value={value ?? 'default'}
          required={required ?? false}
          disabled={disabled ?? false}
        >
          {!value && <option value="default">Select parameter</option>}
          {parametersList.map((parameter) => {
            return (
              <option key={parameter.uuid} value={parameter.uuid}>
                {parameter.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    );
  };

  const actionData = { ...props.newAction.data } as RunFunctionActionType;
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Function</Form.Label>
        <Form.Select
          aria-label="object"
          onChange={handleFunctionOnChange}
          value={actionData.function.functionId ?? 'default'}
          required
        >
          {!actionData.function.functionId && <option value="default">Select function</option>}
          {props.functionsList.map((fn) => {
            if (fn.uuid === props.functionId) return null;
            return (
              <option key={fn.uuid} value={fn.uuid}>
                {fn.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      {selectedFunctionParameters.map((parameter) => {
        function handleListOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
          const parameterId = parameter.uuid ?? '';
          const localInputs = { ...props.newAction.data } as RunFunctionActionType;
          // Initiate property.
          if (!localInputs.function.arguments[parameterId])
            localInputs.function.arguments[parameterId] = {
              type: '',
              value: ''
            };
          localInputs.function.arguments[parameterId].value = e.target.value;
          props.onChange({ ...props.newAction, data: localInputs });
        }
        function handleFixedArgumentOnChange(e: React.ChangeEvent<HTMLInputElement>) {
          const parameterId = parameter.uuid ?? '';
          const localInputs = { ...props.newAction.data } as RunFunctionActionType;
          // Initiate property.
          if (!localInputs.function.arguments[parameterId])
            localInputs.function.arguments[parameterId] = {
              type: '',
              value: ''
            };
          localInputs.function.arguments[parameterId].value = e.target.value;
          props.onChange({ ...props.newAction, data: localInputs });
        }
        function handleTypeOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
          const parameterId = parameter.uuid ?? '';
          const localInputs = { ...props.newAction.data } as RunFunctionActionType;
          // Initiate property.
          if (!localInputs.function.arguments[parameterId])
            localInputs.function.arguments[parameterId] = {
              type: '',
              value: ''
            };
          localInputs.function.arguments[parameterId].type = e.target.value;
          localInputs.function.arguments[parameterId].value = ''; // Reset value.
          props.onChange({ ...props.newAction, data: localInputs });
        }

        const argument = actionData.function.arguments[parameter.uuid ?? ''];

        const filteredVariables = props.variables.filter((variable) => {
          return variable.type === parameter.type;
        });
        const filteredParameters = props.parameters.filter((param) => {
          return param.type === parameter.type;
        });
        return (
          <div key={parameter.uuid}>
            <Form.Label>Parameter {parameter.name}</Form.Label>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                aria-label="type"
                onChange={handleTypeOnChange}
                value={argument?.type ?? 'default'}
                required
              >
                {!argument?.type && <option value="default">Select type</option>}
                {['VAR', 'PARAM', 'FIXED'].map((type) => {
                  // If parameter has type OBJECT don't let user manually fill the input.
                  if (type === 'FIXED' && parameter.type === 'OBJECT') return null;
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            {argument?.type === 'VAR' &&
              renderVariablesList(
                handleListOnChange,
                argument?.value,
                filteredVariables,
                parameter.required
              )}

            {argument?.type === 'PARAM' &&
              renderParametersList(
                handleListOnChange,
                argument?.value,
                filteredParameters,
                parameter.required
              )}

            {argument?.type === 'FIXED' && (
              <Form.Control
                type="text"
                onChange={handleFixedArgumentOnChange}
                value={argument?.value ?? ''}
              />
            )}
          </div>
        );
      })}

      <ArgumentPicker
        allowDataTypes={[]}
        allowFixed={false}
        allowParams={false}
        allowVars={true}
        allowObject={false}
        label="Select Variable to return"
        onChange={(value, typeChosen) => {
          props.onChange((currentVal) => {
            const auxVal = { ...currentVal };
            auxVal.returnVariableUuid = value;
            return auxVal; // override the action
          });
        }}
        value={props.newAction.returnVariableUuid}
        variables={props.variables}
        params={props.parameters}
        key={props.newAction.uuid}
        objects={props.objects}
        onlyList={false}
      />
    </>
  );
}
