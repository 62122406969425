import produce from 'immer';
import { IndexesState, initialState } from '..';
import { columnID, Index, IndexColumn, IndexType, sortOrder } from '../../../types';

import {
  SET_INDEXES,
  SetIndexesAction,
  CHANGE_INDEX_PROPERTY,
  ChangeIndexPropertyAction,
  UPDATE_INDEX_COLUMNS,
  UpdateIndexColumnsAction
} from '../actions/indexes';
import {
  addTableAction,
  ADD_TABLE,
  addIndexAction,
  deleteIndexAction,
  ADD_INDEX,
  DELETE_INDEX,
  addKeyColumnAction,
  DUPLICATE_TABLE,
  duplicateTableAction
} from '../actions/root';

type IndexesActions =
  | SetIndexesAction
  | ChangeIndexPropertyAction
  | UpdateIndexColumnsAction
  | addTableAction
  | addIndexAction
  | deleteIndexAction
  | addKeyColumnAction
  | duplicateTableAction;

export const indexesReducer = (
  state: IndexesState = initialState.indexes,
  action: IndexesActions
): IndexesState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_INDEXES:
        return doSetIndexes(draft, action);
      case CHANGE_INDEX_PROPERTY:
        return doChangeIndexProperty(draft, action);
      case UPDATE_INDEX_COLUMNS:
        return doUpdateIndexColumns(draft, action);
      case ADD_TABLE:
        return doAddTableIndex(draft, action);
      case ADD_INDEX:
        return doAddIndex(draft, action);
      case DELETE_INDEX:
        return doDeleteIndex(draft, action);
      case DUPLICATE_TABLE:
        return doDuplicateTableIndex(draft, action);
      default:
        return draft;
    }
  });
};

function doSetIndexes(state: IndexesState, action: SetIndexesAction): IndexesState {
  const newState: IndexesState = {};
  action.payload.indexes.forEach((index: Index) => {
    newState[index.id] = index;
  });
  return newState;
}

function doAddIndex(state: IndexesState, action: addIndexAction): IndexesState {
  state[action.payload.index.id] = action.payload.index;
  return state;
}

function doDeleteIndex(state: IndexesState, action: deleteIndexAction): IndexesState {
  const IndexID = action.payload.id;
  delete state[IndexID];
  return state;
}

function doChangeIndexProperty(
  state: IndexesState,
  action: ChangeIndexPropertyAction
): IndexesState {
  // TODO: alterar "ts-ignore" para solução de tipagem de modo definitivo da linha abaixo.
  // @ts-ignore
  state[action.payload.id][action.payload.key] = action.payload.value;
  return state;
}

function doUpdateIndexColumns(state: IndexesState, action: UpdateIndexColumnsAction): IndexesState {
  state[action.payload.id].columns = action.payload.indexColumns;
  return state;
}

function doAddTableIndex(state: IndexesState, action: addTableAction): IndexesState {
  const indexColumn = {
    id: action.payload.idPkIndexColumn,
    columnId: action.payload.idPkColumn,
    sortOrder: 'ASC' as sortOrder,
    columnOrder: 0
  };

  const index = {
    id: action.payload.idPkIndex,
    name: 'PRIMARYKEY',
    type: IndexType['PRIMARY'],
    columns: {}
  } as Index;

  index.columns[action.payload.idPkIndexColumn] = indexColumn;

  state[action.payload.idPkIndex] = index;
  return state;
}

function doAddPrimaryIndex(state: IndexesState, action: addKeyColumnAction): IndexesState {
  const indexColumn = {
    id: action.payload.pkIndexColumnUUID,
    columnId: action.payload.column.UUID,
    sortOrder: 'ASC' as sortOrder,
    columnOrder: 0
  };

  const index = {
    id: action.payload.pkIndexUUID,
    name: 'IDX_' + action.payload.column.name,
    type: IndexType['PRIMARY'],
    columns: {}
  } as Index;

  index.columns[indexColumn.id] = indexColumn;

  state[index.id] = index;
  return state;
}

function doDuplicateTableIndex(state: IndexesState, action: duplicateTableAction): IndexesState {
  Object.keys(action.payload.indexes).forEach((oldIndexId) => {
    const oldIndex = state[oldIndexId];
    const newIdx = action.payload.indexes[oldIndexId];

    const indexColumnsMap: Record<columnID, IndexColumn> = {};
    Object.keys(newIdx.indexColumns).forEach((oldIndexColumnId) => {
      const oldIndexColumn = oldIndex.columns[oldIndexColumnId];
      const newIdxCol = newIdx.indexColumns[oldIndexColumnId];

      const newIndexColumn = {
        id: newIdxCol.indexColumnId,
        columnId: newIdxCol.columnId,
        sortOrder: oldIndexColumn.sortOrder,
        columnOrder: oldIndexColumn.columnOrder
      } as IndexColumn;

      indexColumnsMap[newIndexColumn.id] = newIndexColumn;
    });

    const newIndex = {
      id: newIdx.indexId,
      name: oldIndex.name,
      type: oldIndex.type,
      columns: indexColumnsMap
    } as Index;

    state[newIndex.id] = newIndex;
  });
  return state;
}
