import React from 'react';
import Dropdown from 'web_ui/function_editor/action_inputs/components/dropdown';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { FunctionParameterType } from 'web_ui/function_editor/store/types/parameters';
import { t } from 'i18next';

type ParameterPickerProps = {
  label: string;
  actionUuid: string;
  value: string;
  handleChange: (text: string) => void;
  filterByList: boolean;
  filterByDataType?: (
    state: FunctionEditorState,
    actionUuid: string,
    dataType: string,
    objectUuid?: string
  ) => boolean;
  isFromSideBar?: boolean;
  isFromArgument?: boolean;
};

export function ParameterPicker({
  label,
  actionUuid,
  value,
  handleChange,
  filterByList,
  filterByDataType,
  isFromSideBar,
  isFromArgument
}: ParameterPickerProps) {
  const state = useSelector((state: FunctionEditorState) => state);

  const functionId = state.editor.functionId;
  const functionInfo = state.functions[functionId];

  let filtered: FunctionParameterType[] = [];
  if (functionInfo) {
    filtered = functionInfo.parameters.map((paramId) => state.parameters[paramId]);
    if (filterByList) {
      filtered = filtered.filter((p) => p.list);
    }
    if (filterByDataType) {
      filtered = filtered.filter((p) => {
        return filterByDataType(state, actionUuid, p.type, p?.objectUuid);
      });
    }
  }

  return (
    <Dropdown
      items={filtered}
      placeholder={`${t('SelectAParameter')}`}
      label={label}
      value={state.parameters[value]}
      handleChange={handleChange}
      isFromArgument={isFromArgument}
      isFromSideBar={isFromSideBar}
    />
  );
}
