import { AuthService } from 'modules/auth/services';
import { SessionContextType } from 'modules/auth/store';
import { SystemRoleAuthorityName, UserProfile } from 'modules/auth/types/auth_types';
import React, { useCallback, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';

export interface OpenAiFormProps {
  session: SessionContextType;
}

function OpenAiForm({ session }: OpenAiFormProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [openAIKey, setOpenAIKey] = useState(session.user?.openAIKey);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!openAIKey || !session?.user?.id) return;
      const userInfo = await AuthService.updateOpenAIKey(session.user.id, openAIKey);
      session.setUser(userInfo);
      showSuccessPopup('UpdateOpenAIKeySuccess');
      setIsLoading(false);
    } catch (error) {
      showErrorPopup('UpdateOpenAIKeyError');
      setIsLoading(false);
    }
  };

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const showSuccessPopup = (message: string) => {
    setAlertVariant('success');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  return (
    <>
      <Form className="w-100" onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="4" md="3" lg="2">
            {t('account_settings.integrations.OpenAIKey')!}:
          </Form.Label>
          <Col sm="6" md="7" lg="8">
            <Form.Control
              value={openAIKey || ''}
              onChange={(e) => setOpenAIKey(e.target.value)}
              type="text"
              placeholder="<Your key>"
              disabled={isLoading}
            />
          </Col>
          <Col sm="2" className="text-end">
            <Button
              disabled={!openAIKey || openAIKey === session?.user?.openAIKey || isLoading}
              type="submit"
            >
              {t('navigation_options.Submit')}
            </Button>
          </Col>
        </Form.Group>
      </Form>
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
      )}
    </>
  );
}

export default OpenAiForm;
