import { setErrorMessage, setSelectedFrame } from 'modules/modeler/studio/store/actions/studio';
import { Relationship, TableUUID } from 'modules/modeler/types';
import React, { RefObject, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sleep } from 'utils/utils';
import styles from './style.module.css';
import ActionsBar from '../../shared/actions_bar';
import RelationshipsList from './components/relationships-list';
import RelationshipEditor from './components/relationship-editor';
import NewRelationshipEditor from './components/new-relationship-editor';
import { useParams } from 'react-router-dom';
import { SchemaParams } from '../../../../../../../routes/studio/data';
import useFetchAvailableTables from './hooks/useFetchAvailableTables';
import { useTranslation } from 'react-i18next';

type TableRelationsProps = {
  tableID: TableUUID;
  modalRef: RefObject<HTMLDivElement>;
};

function TableRelations({ tableID, modalRef }: TableRelationsProps) {
  const [selectedRelationship, setSelectedRelationship] = useState<string>('');
  const [showNewRelationship, setShowNewRelationship] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { module_id } = useParams<SchemaParams>();
  const { availableTables, fetchAvailableTables } = useFetchAvailableTables(tableID, module_id);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // TODO: add this to the right place
  // go to the other entity of the relationship (select it as the selected table to edit)
  async function handleGotoEntity(tableID: TableUUID) {
    if (!tableID || tableID === '') return;
    if (modalRef.current) {
      // visual effect to simulate frame shift
      // modalRef.current.style.opacity = '0';
      dispatch(setSelectedFrame(tableID));
      await sleep(200);
      dispatch(setSelectedFrame(tableID));
      // modalRef.current.style.opacity = '100';
    }
  }

  const handleShowNewRelationship = () => {
    fetchAvailableTables().then(() => {
      if (availableTables?.modules.length === 0) {
        return dispatch(setErrorMessage('modeler.NoMoreAvailableTables'));
      }
      setShowNewRelationship(true);
      setSelectedRelationship('');
    });
  };

  return (
    <div id={'RelationshipEditorWrapper'} className={styles.RelationshipEditorWrapper}>
      <ActionsBar
        addNew={() => handleShowNewRelationship()}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div className={styles.ViewAndPropertyWrapper}>
        <RelationshipsList
          tableID={tableID}
          selectRelationship={setSelectedRelationship}
          selectedRelationshipID={selectedRelationship}
          newRelationship={showNewRelationship}
          searchText={searchText}
        />
        {selectedRelationship && !showNewRelationship ? (
          <RelationshipEditor relationshipID={selectedRelationship} />
        ) : (
          <NewRelationshipEditor
            tableID={tableID}
            setShowNewRelationship={setShowNewRelationship}
          />
        )}
      </div>
    </div>
  );
}

export default TableRelations;
