import React, { useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import styles from '../../modules/designer/components/navbar/styles.module.css';
import { Button, Dropdown } from 'react-bootstrap';
import { JobInfo, JobStatus } from '../../modules/project/types';
import { ProjectsService } from '../../modules/project/services';
import { useLocation, useParams } from 'react-router-dom';
import Icon from '../../web_ui/icon';
import Confirmation from '../../web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type CodeDownloadListProps = {
  downloads: Record<string, JobInfo>;
};

function CodeDownloadList(props: CodeDownloadListProps) {
  // const [isHovered, setIsHovered] = useState(false);
  const { app_id } = useParams();
  const navigate = useNavigate();
  const loc = useLocation();

  function navigatingToJobsPage() {
    if (app_id) {
      navigate(`/app/${app_id}/jobs`, { state: { location: loc.pathname } });
    }
  }

  const renderingBadge = () => {
    const value = Object.keys(props.downloads).filter(
      (key) =>
        props.downloads[key].status === 'WAITING' ||
        props.downloads[key].status === 'IN_PROGRESS' ||
        props.downloads[key].status === 'QUEUED'
    ).length;

    if (value > 0) {
      return value;
    }

    return -1;
  };

  return (
    <>
      <div onClick={navigatingToJobsPage} className={styles.containerBadge} id="badgeNotify">
        {renderingBadge() > -1 && <p className={styles.qtdUpdateDownload}>{renderingBadge()}</p>}
        <Icon iconName="gears" extraProps={styles.icon} />
      </div>
    </>
  );
}

export default CodeDownloadList;
