import { ObjectType } from 'modules/logic_builder/types';
import { Property } from '../../../modules/logic_builder/components/object_editor/editor_utils';
import { t } from 'i18next';

export type TableType = {
  content: {
    data: {
      name: string;
      description: string;
      relationships: string[];
    };
  };
  uuid: string;
};

export type Relationship = {
  id: string;
  from: string;
  to: string;
  info?: { tableName: string };
};

export type EntityColumn = {
  name: string;
  isFK: boolean;
  uuid: string;
  tableUUID: string;
  type: string;
};

export type Entity = {
  entityName: string;
  entityUuid: string;
  entityDescription: string;
  entityColumns: EntityColumn[];
  relatedEntities: string[];
};

export type SelectedAutomation = {
  name: string;
  title: string;
  description: string;
  icon: string;
  numberOfSteps: number;
  stepsTitles: string[];
  stepsIcons: string[];
  tags: string[];
  needFrontend: boolean;
  needBackend: boolean;
  needDatabase: boolean;
};

export const AUTOMATION_TYPES = { CRUD: 'CRUD', ENTITIES: 'ENTITIES' } as const;

export const AUTOMATIONS_STEPS: Record<keyof typeof AUTOMATION_TYPES, SelectedAutomation> = {
  // CRUD: {
  //   name: 'CRUD',
  //   numberOfSteps: 5,
  //   stepsTitles: ['Automation Type', 'Details', 'Relations', 'Interfaces', 'Resume'],
  //   stepsIcons: ['gear', 'list-check', 'code-merge', 'window-maximize', 'rectangle-list']
  // },
  //CRUD: {
  //  name: 'CRUD',
  //  title: 'Crud',
  //  description: 'Make the create, read, update and delete functions',
  //  icon: '',
  //  numberOfSteps: 4,
  //  stepsTitles: ['Automation Type', 'Details', 'Interfaces', 'Resume'],
  //  stepsIcons: ['gear', 'list-check', 'window-maximize', 'rectangle-list']
  //},
  CRUD: {
    name: AUTOMATION_TYPES.CRUD,
    title: 'CRUD',
    description: t('automation.CRUD'),
    icon: '',
    numberOfSteps: 6,
    stepsTitles: [
      '0',
      '1 - Entity',
      '2 - Objects',
      '3 - Functions',
      '4 - Endpoints',
      '5 - Pages',
      '6 - Resume'
    ],
    stepsIcons: ['gear', 'gear', 'gear', 'gear', 'gear', 'gear', 'list-check'],
    tags: ['DB', 'Logic', 'Interface'],
    needFrontend: false,
    needBackend: true,
    needDatabase: true
  },
  ENTITIES: {
    name: AUTOMATION_TYPES.ENTITIES,
    title: t('automation.Entities'),
    description: t('automation.EntitiesDescription'),
    icon: '',
    numberOfSteps: 2,
    stepsTitles: ['Automation Type', 'Inputs'],
    stepsIcons: ['gear', 'list-check'],
    tags: ['DB'],
    needFrontend: false,
    needBackend: false,
    needDatabase: true
  }
  // PAGES: {
  //   name: AUTOMATION_TYPES.PAGES,
  //   title: 'Pages',
  //   description:
  //     'I want to generate new pages from a list of templates and define behavior by calling existing endpoints.',
  //   icon: '',
  //   numberOfSteps: 3,
  //   stepsTitles: ['Automation Type', 'Choose Endpoints', 'Choose Templates'],
  //   stepsIcons: ['gear', 'list-check', 'object-ungroup'],
  //   tags: ['Interface']
  // }
};

export type AutomationDetail = {
  type: 'CREATE_ENTITY' | 'READ_ENTITY' | 'UPDATE_ENTITY' | 'DELETE_ENTITY';
  multiplicity: 'ONE' | 'MANY';
  objectType: 'SIMPLE' | 'COMPLETE' | 'CUSTOM';
  objectUuid?: string; // Only needed if objectType === CUSTOM
  objectName?: string; // Only needed if objectType === CUSTOM
  pageable?: boolean; // Only needed if type === 'READ_ENTITY' && multiplicity === 'MANY'
  sortable?: boolean; // Only needed if type === 'READ_ENTITY' && multiplicity === 'MANY'
  filterable?: boolean; // Only needed if type === 'READ_ENTITY' && multiplicity === 'MANY'
};

export type AutomationPages = {
  templateName: string;
  pageName: string;
  pageRoute: string;
  selected: boolean;
  templateId: string;
  disabled: boolean;
};

export type ImportedObject = {
  entityUuid: string;
  complete: boolean;
  simple: boolean;
  custom: FullObject[];
  objects: ObjectType[][];
};

export type FullObject = {
  id: string;
  name: string;
  description: string;
  rootObject: string;
  properties: Record<string, Property[]>;
  isObjectComplete: boolean;
};
