import { Action } from 'redux';
import { CrudType, FunctionsState } from '../../types/functions';

export const UPDATE_FUNCTION = 'UPDATE_FUNCTION';

export interface UpdateFunctionAction extends Action {
  type: 'UPDATE_FUNCTION';
  payload: {
    uuid: string;
    name: string;
    desc: string;
    crudType: string;
  };
}

export const updateFunction = (
  uuid: string,
  name: string,
  desc: string,
  crudType: string
): UpdateFunctionAction => ({
  type: UPDATE_FUNCTION,
  payload: { uuid, name, desc, crudType }
});

export function doUpdateFunction(
  state: FunctionsState,
  action: UpdateFunctionAction
): FunctionsState {
  const { uuid, name, desc, crudType } = action.payload;
  state[uuid] = { ...state[uuid], name, desc, crudType };
  return state;
}
