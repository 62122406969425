import React from 'react';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../../store/types/function_editor_state';

function FunctionName() {
  const function_id = useSelector((state: FunctionEditorState) => state.editor.functionId);
  const name = useSelector(
    (state: FunctionEditorState) => state.functions[function_id ?? '']?.name
  );
  return <span className={styles.FunctionName}>{name}</span>;
}

export default FunctionName;
