import React, { useState } from 'react';
import styles from './styles.module.css';
import { ControlProps } from '..';
import HelpIcon from '../components/HelpIcon';
import HelpPopover from '../components/Popover';
import { useTranslation } from 'react-i18next';
import MenuTypeSelector from './menu_type_selector';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';

function MenuTypeControl(props: ControlProps) {
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent
  );
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const { t } = useTranslation();

  function handleSelectMenuType(menuType: string) {
    if (!props.value || !props.onChange) return;

    props.onChange(menuType, 'menuType');
  }

  return (
    <div className="mb-3 border-bottom text-body-secondary">
      <label className="position-relative mb-3 text-body">{props.label}</label>
      <HelpPopover
        helpBoxProps={{
          title:
            t('designer.right_side.controls.ListTypeTitle') ||
            'designer.right_side.controls.ListTypeTitle',
          description:
            t('designer.right_side.controls.ListTypeDescription') ||
            'designer.right_side.controls.ListTypeDescription',
          note: []
        }}
        placement="top"
      >
        <HelpIcon />
      </HelpPopover>

      <div className={`${styles.itemList} d-flex justify-content-between`}>
        {selectedComponent && components && (
          <MenuTypeSelector onChange={handleSelectMenuType} value={props.value} />
        )}
      </div>
    </div>
  );
}

export default MenuTypeControl;
