import * as React from 'react';
import { ARGUMENT_TYPES, DeleteActionType } from '../../types';
import {
  Action,
  FunctionParameter,
  FunctionVariable,
  ObjectSimple
} from 'modules/logic_builder/types';
import ArgumentPicker from '../ArgumentPicker';
import { Form } from 'react-bootstrap';
import ObjectPicker from '../ObjectPicker';

type Props = {
  newAction: Action<DeleteActionType>;
  onChange: React.Dispatch<React.SetStateAction<Action<DeleteActionType>>>;
  objects: ObjectSimple[];
  parameters: FunctionParameter[];
  variables: FunctionVariable[];
};

export default function RenderDeleteFile(props: Props) {
  return (
    <Form.Group>
      <ObjectPicker
        label="Select a object"
        objects={props.objects}
        onChange={(uuidObjt) => {
          props.onChange((currentVal) => {
            const aux = { ...currentVal };
            aux.data.objectId = uuidObjt;
            return aux; // override
          });
        }}
        value={props.newAction.data.objectId}
      />

      <ArgumentPicker
        allowDataTypes={[]}
        allowFixed={false}
        allowParams={true}
        allowVars={true}
        allowObject={false}
        label="Select id to Delete"
        onChange={(value, typeChosen) => {
          props.onChange((currentVal) => {
            const auxVal = { ...currentVal };
            auxVal.data.arguments.id.value = value;
            auxVal.data.arguments.id.type = typeChosen;
            return auxVal; // override the action
          });
        }}
        value={props.newAction.data.arguments.id?.value}
        variables={props.variables}
        params={props.parameters}
        objects={props.objects}
        onlyList={false}
        currentType={props.newAction.data.arguments.id?.type}
      />

      <ArgumentPicker
        allowDataTypes={[]}
        allowFixed={false}
        allowParams={false}
        allowVars={true}
        allowObject={false}
        label="Select Variable to return"
        onChange={(value) => {
          props.onChange((currentVal) => {
            const auxVal = { ...currentVal };
            auxVal.returnVariableUuid = value;
            return auxVal; // override the action
          });
        }}
        value={props.newAction.returnVariableUuid}
        variables={props.variables}
        params={props.parameters}
        objects={props.objects}
        onlyList={false}
        currentType={ARGUMENT_TYPES.VAR}
      />
    </Form.Group>
  );
}
