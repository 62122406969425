import React, { useEffect, useState } from 'react';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { ExoRole } from 'modules/logic_builder/types';
import { View } from 'modules/designer/types';
import { setRoleToView } from 'modules/designer/studio/store/actions/roles';
import { useDispatch } from 'react-redux';

type RoleType = 'All Roles' | 'Custom' | 'Public';

type SelectedRoleType = {
  viewId?: string;
  roleType?: RoleType;
};

type RoleSelectorProps = {
  roles: ExoRole[];
  viewSource: View;
  setViewSource: React.Dispatch<React.SetStateAction<View | undefined>>;
  selectedView: string;
};

const RoleSelector: React.FC<RoleSelectorProps> = ({
  roles,
  viewSource,
  setViewSource,
  selectedView
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedRoleType, setSelectedRoleType] = useState<SelectedRoleType>({});
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

  useEffect(() => {
    setSelectedRoles(viewSource.roles);
  }, [viewSource.roles]);

  useEffect(() => {
    if (!viewSource || !viewSource.roles) {
      return;
    }
    if (viewSource.uuid !== selectedView) {
      return;
    }

    let roleType: RoleType;
    if (selectedRoles.length === 0) {
      roleType = 'Public';
    } else if (selectedRoles.length === roles.length) {
      roleType = 'All Roles';
    } else {
      roleType = 'Custom';
    }

    setSelectedRoleType({
      viewId: selectedView,
      roleType
    });
  }, [roles.length, selectedRoles.length, selectedView, viewSource]);

  const checkSelected = (roleId?: number): boolean => {
    if (!roleId) return false;
    return selectedRoles.includes(roleId);
  };

  const handleRoleSelect = (roleId: number) => {
    let nextRoles: number[];

    if (selectedRoles.includes(roleId)) {
      nextRoles = selectedRoles.filter((id) => id !== roleId);
    } else {
      nextRoles = [...selectedRoles, roleId];
    }

    setSelectedRoles(nextRoles);
    const nextViewSource = { ...viewSource, roles: nextRoles };
    setViewSource(nextViewSource);
    dispatch(setRoleToView(nextRoles, selectedView));
  };

  return (
    <div>
      <ToggleButtonGroup
        type="radio"
        name="roles-options"
        value={selectedRoleType.roleType}
        size="sm"
        className="mb-3"
      >
        <ToggleButton
          id="publicButton"
          style={{ cursor: 'default' }}
          value={'Public'}
          onClick={() => {}}
        >
          {t('automation.step4.public')}
        </ToggleButton>
        {/* add tooltip when project have no roles: "Add roles to project" */}
        <ToggleButton
          id="allButton"
          style={{ cursor: 'default' }}
          value={'All Roles'}
          onClick={() => {}}
        >
          {t('automation.step4.allRoles')}
        </ToggleButton>
        <ToggleButton
          id="customButton"
          style={{ cursor: 'default' }}
          value={'Custom'}
          onClick={() => {}}
        >
          Custom
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={styles.ViewRoles}>
        {roles &&
          roles.map((role) => {
            return (
              <Form.Check
                key={role.id}
                label={role.name}
                name="view-roles"
                type="checkbox"
                id={role.name}
                checked={checkSelected(role.id)}
                value={role.id}
                onChange={() => handleRoleSelect(role.id ?? 0)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default RoleSelector;
