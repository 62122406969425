import React, { useRef } from 'react';

import styles from './styles.module.css';
import { useDispatch } from 'react-redux';
import { setSelectedComponent, setSelectedView } from '../../store/actions/studio';
import { ComponentUUID, ViewUUID } from '../../../types';

import Viewport from '../../components/viewport';

export type ViewData = {
  title: string;
  description: string;
  route: string;
};

export type ViewStyles = {
  backgroundColor: string;
  globalFont: string;
  globalFontSize: number;
  globalFontColor: string;
};

export type LayoutViewProps = {
  uuid: ViewUUID;
  data: ViewData;
  name: string;
  layout: ComponentUUID[];
  styles?: ViewStyles;
};

function LayoutView(props: LayoutViewProps) {
  const dispatch = useDispatch();
  const elementRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const style = {
    backgroundColor: props.styles?.backgroundColor,
    fontFamily: `${props.styles?.globalFont}`,
    fontSize: `${props.styles?.globalFontSize}px`,
    color: props.styles?.globalFontColor
  };

  function handleBrowserClick() {
    dispatch(setSelectedComponent(null));
  }

  function handleSelectView() {
    dispatch(setSelectedView(props.uuid));
    dispatch(setSelectedComponent(null));
  }

  return (
    <>
      <section
        className={`${styles.desktop} ${styles.browser} shadow`}
        ref={elementRef}
        onClick={handleSelectView}
      >
        <div className={styles.browserControls} onClick={handleBrowserClick}>
          <div className={styles.windowControls}>
            <span className={`${styles.windowControls} ${styles.close}`} />
            <span className={`${styles.windowControls} ${styles.minimize}`} />
            <span className={`${styles.windowControls} ${styles.maximise}`} />
          </div>
          <span className={styles.urlBar} />
        </div>
        <div style={{ overflowY: 'hidden', height: 'calc(100% - 50px)' }}>
          <Viewport uuid={props.uuid} styles={style} />
        </div>
      </section>
    </>
  );
}

export * from './manifest';
export default LayoutView;
