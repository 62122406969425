import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import React from 'react';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import EndElseIcon from './end_else_icon';

export const END_ELSE_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.END_ELSE,
  name: 'EndElse',
  description: 'Action to close an "ELSE" statement',
  color: 'secondary',
  category: FRONTEND_ACTIONS_CATEGORIES.CONDITION,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.CONDITION,
  icon: <EndElseIcon />,
  conditionStatement: true,
  closeBlock: true,
  inlineInputs: [],
  inputs: []
};
